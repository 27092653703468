define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceCacheData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/device360/SensorListing_EnvTemp',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/CustomIssueListing',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/DeviceIssueListing',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueTimings_Devices'
], function(SimAssuranceCacheData, UtilityFunctions,SimLokiDatabaseActions, SensorListing_EnvTemp, CustomIssueListing, DeviceIssueListing,
    HostIssueTimings_Devices){

  var patternList = [ 
       {pattern: /overall(Bad|Good|Fair)Count/, functionName: 'overallCount'},
       {pattern:/(access|ap|border|core|cp|distribution|wlc|wireless|router|others)Score/,functionName:'deviceRoleScore'},
       {pattern:/(access|ap|border|core|cp|distribution|wlc|wireless|router|others)TotalCount/,functionName:'deviceRoleTotalCount'},
       {pattern: /(access|ap|border|core|cp|distribution|wireless|wlc|router|others)GoodCount/, functionName: 'deviceRoleGoodCount'},
       {pattern: /(access|ap|border|core|cp|distribution|wireless|wlc|router|others)FairCount/, functionName: 'deviceRoleFairCount'},
       {pattern: /(access|ap|border|core|cp|distribution|wireless|wlc|router|others)BadCount/, functionName: 'deviceRoleBadCount'},
       {pattern: /(access|ap|border|core|cp|distribution|wireless|wlc|router|others)UnmonCount/, functionName: 'deviceRoleUnmonCount'},
       {pattern: /metric(Bad|Good|Fair)Count/, functionName: 'metricCount'},
       {pattern: /(max_cpu|max_memory|envtemp_fn)/, functionName: 'device360Info'},
       {pattern: /(cpu|memory|tcpConn|error|freeMbuf|freeTimer|packetPool|upLink|wqePool|overall)Score/, functionName: 'device360Info'},
       {pattern: /(averageHealthScore|rssi|snr|ssid|frequency|channel|channelWidth|apName|rxBytes|txBytes)/, functionName: 'client360Info'},
       {pattern: /(applicationDelay|latency)/, functionName: 'appDelayIssue'},
       {pattern: /(count)/, functionName: 'countHandler'},
       {pattern: /ap_(cpu|memory|radio)_utilization/, functionName: 'accesspointHandler'},
       {pattern: /(wlc_ap_maxCount|wlc_ap_inUseCount)/, functionName: 'wlcHandler'},
       {pattern: /avg(Input|Output)Errors/, functionName: 'connectivityRouter'},
       {pattern: /avg(Input|Output)Util/, functionName: 'connectivityRouter'},
       {pattern: /(input|output)Rate/, functionName: 'connectivityRouter'},
       {pattern: /ifavail/, functionName: 'interfaceStatus'},
       {pattern: /max_tcam_util/, functionName: 'getTcamInfo'},
       {pattern: /clientCountRecent|slot1clCount|slot0clCount/, functionName: 'clientCount'},
       {pattern: /utilization/, functionName: 'apAggregation'},
       {pattern: /ap_radio_air_quality_stats_max/, functionName: 'apRadioAirQuality'},
       {pattern: /ap_radio_interference_stats_max/, functionName: 'apRadioInterference'},
       {pattern: /wlc_entity_cpu_avg|entity_information/, functionName: 'wlc_cpu_entity'},
       {pattern: /avg(Input|Output)Discards/, functionName: 'discards'}

    //   {pattern: /wlc_main_stats_up_down|ap_up_down/, functionName: 'getDeviceStatus'}

  ];

  var fieldHandler = {
       appDelayIssue: function(urlAction, filter, cachedSummaryData, fieldName) {
           return appDelayMetricData(cachedSummaryData, filter, fieldName);
       },
       overallScore: function(urlAction, filter, cachedSummaryData, fieldName) {
           if(urlAction.restPayload.entity != undefined) {
               if(urlAction.restPayload.entity._id != undefined && urlAction.restPayload.entity._id == "__global__") {
                   return cachedSummaryData.latestHealthScore;
               } else if(urlAction.restPayload.entity.managementIpAddr != undefined) {
                   filter = { key: 'managementIpAddress', value: urlAction.restPayload.entity.managementIpAddr};
               } else if(urlAction.restPayload.entity._id != undefined) {
                   filter = { key: 'id', value: urlAction.restPayload.entity._id};
               } else if(urlAction.restPayload.entity._type != undefined &&
                          urlAction.restPayload.entity._type == 'NetworkDevice' ) {
                   if(urlAction.restPayload.entity._id != undefined)
                       filter = { key: 'id', value: urlAction.restPayload.entity._id};
                   else if(urlAction.restPayload.entity.macAddress != undefined)
                       filter = { key: 'macAddress', value: urlAction.restPayload.entity.macAddress.toLowerCase()};
               } else if(urlAction.restPayload.entity.uuid != undefined) {
                    filter = { key: 'id', value: urlAction.restPayload.entity.uuid};
                }

               return getDeviceValue(cachedSummaryData, filter, fieldName);
           }

           return cachedSummaryData.latestHealthScore;
       },
       overallCount: function(urlAction, filter, cachedSummaryData, fieldName) {
           return cachedSummaryData[fieldName] == undefined || cachedSummaryData[fieldName] == 0 ? -1: cachedSummaryData[fieldName];
       },
       deviceRoleScore: function(urlAction, filter, cachedSummaryData, fieldName) {
           var keyName = fieldName.substr(0, fieldName.indexOf('Score'));
           keyName = keyName.toUpperCase();
           return cachedSummaryData[keyName]['Score'];
       },
       deviceRoleTotalCount:  function(urlAction, filter, cachedSummaryData, fieldName) {
           var keyName = fieldName.substr(0, fieldName.indexOf('TotalCount'));
           keyName = keyName.toUpperCase();
           return cachedSummaryData[keyName]['TotalCount'];
           return 0;
       },
       deviceRoleGoodCount: function(urlAction, filter, cachedSummaryData, fieldName) {
           var keyName = fieldName.substr(0, fieldName.indexOf('GoodCount'));
           keyName = keyName.toUpperCase();
           return cachedSummaryData[keyName]['GoodCount'];
           return 0;
       },

       deviceRoleFairCount: function(urlAction, filter, cachedSummaryData, fieldName) {
           var keyName = fieldName.substr(0, fieldName.indexOf('FairCount'));
           keyName = keyName.toUpperCase();
           return cachedSummaryData[keyName]['FairCount'];

           return 0;
       },
       deviceRoleBadCount: function(urlAction, filter, cachedSummaryData, fieldName) {
           var keyName = fieldName.substr(0, fieldName.indexOf('BadCount'));
           keyName = keyName.toUpperCase();
           return cachedSummaryData[keyName]['BadCount'];

           return 0;
       },
       deviceRoleUnmonCount: function(urlAction, filter, cachedSummaryData, fieldName) {
        var keyName = fieldName.substr(0, fieldName.indexOf('UnmonCount'));
        keyName = keyName.toUpperCase();
        return cachedSummaryData[keyName]['UnmonCount'];

        return 0;
        },
      
       metricCount: function(urlAction, filter, cachedSummaryData, fieldName) {
           //takes care of control plane/data plane/system health -- metric related functionality
           if(urlAction.restPayload.dimensions != undefined && Array.isArray(urlAction.restPayload.dimensions) ) {
               var roleName = '', metricName;
               if(urlAction.restPayload.dimensions[0].name == 'category') {
                   roleName = urlAction.restPayload.dimensions[0].value;
               }
               if(roleName != '') {
                   roleName = roleName.toUpperCase();
                   roleName = roleName == 'ROUTERS' ? "ROUTER" : roleName;
               }
               //Added for AP Devices
               if(roleName.trim().length==0) {
                   roleName="AP";
               }
               if(urlAction.restPayload.dimensions.length == 2 && urlAction.restPayload.dimensions[1].name == 'metricName'){
                   metricName = urlAction.restPayload.dimensions[1].value;
               }

               var keyName = fieldName.substr(6, fieldName.length-1);
               // Added for AP Link issue
               if(cachedSummaryData[roleName] != undefined) {
                   return cachedSummaryData[roleName][keyName];
               }
           }
           return 0;
       },

       notInFabricCnt: function(urlAction, filter, cachedSummaryData) {
           return 0;
       },

       device360Info: function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime) {
           var filter = {};
           if(urlAction.restPayload.entity != undefined) {
               if(urlAction.restPayload.entity.managementIpAddr != undefined) {
                   filter = { key: 'managementIpAddress', value: urlAction.restPayload.entity.managementIpAddr};
               } else if(urlAction.restPayload.entity._id != undefined) {
                   filter = { key: 'id', value: urlAction.restPayload.entity._id};
               } else if(urlAction.restPayload.entity._type != undefined &&
                          urlAction.restPayload.entity._type == 'NetworkDevice' ) {
                   filter = { key: 'id', value: urlAction.restPayload.entity._id};
               } else if(urlAction.restPayload.entity.uuid ) {
                    filter = { key: 'id', value: urlAction.restPayload.entity.uuid};
                }
           }
           return getDeviceValue(cachedSummaryData, filter, fieldName, graphStartDateTime, graphCurrentDateTime, urlAction.restPayload.dimensions);
       },
       client360Info: function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime) {
           var filter = {};
           if(urlAction.restPayload.entity != undefined) {
               if(urlAction.restPayload.entity._id != undefined) {
                   var tId = urlAction.restPayload.entity._id.replace(/_/g,':');

                   filter = { key: 'id', value: tId};
               }
           }
           return getClient360Value(cachedSummaryData, filter, fieldName, graphStartDateTime, graphCurrentDateTime);
       },
       countHandler: function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
           var min = 5;
           var  max = 25;
           return UtilityFunctions.getRandomIntForRangeValues(min, max);
       },
       accesspointHandler: function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
           var min = 5, max = 25;

           var networkDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':urlAction.restPayload.entity['macAddress'].toLowerCase()});
           var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
           var apConfig = getAPConfig(networkDevice[0].id,summaryData);

           if(fieldName == 'ap_cpu_utilization') {
               min =apConfig.cpu.min; max = apConfig.cpu.max;
           } else if(fieldName == 'ap_memory_utilization') {
               min = apConfig.memory.min; max = apConfig.memory.max;
           }else if(fieldName == 'ap_radio_utilization_stats_max') {
              if(urlAction.restPayload.dimensions[0].value=="0")  {
                min = 93; max = 98;
              } else {
                min = 70; max = 85;
              }

              var entity={}, val;
              entity['key'] = 'macAddress', entity['value'] = urlAction.restPayload.entity['macAddress'].toLowerCase();
              val = getUpdatedIssueValue(entity, urlAction.restPayload.name, urlAction.restPayload.field, graphCurrentDateTime);
              if(val!=undefined){
                  min = val-2, max = val+3;
              }

           }
           return UtilityFunctions.getRandomIntForRangeValues(min, max).toString();
       },
       wlcHandler : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
          var wlc_ap_inUseCount=0;
          var  wlc_ap_maxCount =50;
          if(fieldName == 'wlc_ap_inUseCount') {
            if(urlAction.restPayload.entity.managementIpAddr != undefined) {
               var ipAddress= urlAction.restPayload.entity.managementIpAddr;
               var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':ipAddress});
             return data.length;
            }
           return wlc_ap_inUseCount;
          } else {
           return wlc_ap_maxCount;
          }

       },
       connectivityRouter: function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            //for deviceInterfaceStatsAggregation (connectivity)
            var min = 0, max = 5;
            //var ifName = urlAction.restPayload.entity._id.substring(urlAction.restPayload.entity._id.indexOf("-")+1);
            var ifName = urlAction.restPayload.entity._id.substring(urlAction.restPayload.entity._id.lastIndexOf("-")+1);
            var ifDetail = cachedSummaryData.interfaceMetricData[ifName];
            var offset = 5;
            if(!ifDetail) {
                return 0;
            }
            if(fieldName == 'avgInputErrors') {
                if(ifDetail){
                    min = ifDetail.avg.rxErr-offset;
                    max = ifDetail.avg.rxErr+offset;
                }
            } else if(fieldName == 'avgOutputErrors') {
                if(ifDetail){
                    min = ifDetail.avg.txErr-offset;
                    max = ifDetail.avg.txErr+offset;
                }
            } else if(fieldName == 'avgInputUtil') {
                if(ifDetail){
                    min = ifDetail.avg.rx-offset;
                    max = ifDetail.avg.rx+offset;
                }
            } else if(fieldName == 'avgOutputUtil') {
                if(ifDetail){
                    min = ifDetail.avg.tx-offset;
                    max = ifDetail.avg.tx+offset;
                }
            } else if(fieldName == 'inputRate') {
                if(ifDetail){
                    min = ifDetail.avg.rx-offset;
                    max = ifDetail.avg.rx+offset;
                }
            } else if(fieldName == 'outputRate') {
                if(ifDetail){
                    min = ifDetail.avg.tx-offset;
                    max = ifDetail.avg.tx+offset;
                }
            }
            if(['outputRate','inputRate'].indexOf(fieldName)>-1) {
                if(ifDetail){
                    if(ifDetail){
                        if(ifDetail.avg.rx<10) { min=10, max=100 }
                        else if(ifDetail.avg.rx<20) { min=200, max=400 }
                        else if(ifDetail.avg.rx<30) { min=400, max=600 }
                        else if(ifDetail.avg.rx<40) { min=600, max=800 }
                        else if(ifDetail.avg.rx<50) { min=800, max=1000 }
                        else if(ifDetail.avg.rx<60) { min=1000, max=1100 }
                        else if(ifDetail.avg.rx<70) { min=1100, max=1300 }
                        else if(ifDetail.avg.rx<80) { min=1500, max=1700 }
                        else if(ifDetail.avg.rx<90) { min=1800, max=1900 }
                        else { min=2000, max=3000 }
                    }
                } else {
                    min=10, max=100;
                }
            }
            return (UtilityFunctions.getRandomIntForRangeValues(min, max)).toString();
        },
        discards : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime) {
            return "0";
        },
         interfaceStatus : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            //if the logic is changed here, please update the same in getInterfaceStatus method of SimNetworkDeviceData too..
            var deviceIp = urlAction.filter['managementIpAddr'], type = "DEFAULT";
            if(deviceIp == undefined) {
                deviceIp = urlAction.restPayload.entity.managementIpAddr;
            }
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':deviceIp});
            var cat9kTypes = ["cisco catalyst 9200 switch stack", "cisco catalyst 9300 switch", "cisco catalyst 9404r switch", "cisco catalyst 9500 switch"];
            if(deviceDetail.length>0 && cat9kTypes.indexOf(deviceDetail[0].type.toLowerCase()) > -1) {
                type = "CAT 9K";
            }

            var ifAvail = [10,20];//[100,100];
            var ifName = urlAction.restPayload.dimensions[0].value;
            var index = cachedSummaryData.interfaces[type].indexOf(ifName);//this call is not made for BORDER ROUTER
            var val = (index%2 == 0)?ifAvail[0]:ifAvail[1];
            return val;
        },

        getTcamInfo : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            var val= 3.0;
            var value = urlAction.restPayload.dimensions[0].value;

            if(value =="l2")  {
             val=0.0;

            } else if(value=="l3")  {
              val=4.0;
            } else if (value=="sgacl") {
               //to handle issue settings updation usecase
               var entity={};
               entity['key'] = 'managementIpAddress', entity['value'] = urlAction.restPayload.entity.managementIpAddr
               val = getUpdatedIssueValue(entity, urlAction.restPayload.name, urlAction.restPayload.field, graphCurrentDateTime);
               if(val==undefined) {
                   val=97.0;
               }
            }

            return val;

        },

        clientCount : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            var count = 0, hosts = [], frequency = '5.0';
            //MAC address in payload is in capitals and hence doesnt match the DB entry. Hence ToLowereCase is used.
            var networkDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':urlAction.restPayload.entity['macAddress'].toLowerCase()});
            if(urlAction.restPayload.dimensions[0].name =="slot" && urlAction.restPayload.dimensions[0].value=="0" ||
            urlAction.restPayload.fields[0] == 'slot0clCount')  {
                frequency = '2.4';
            }
            if(networkDevice.length) {
                hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',
                    {'$and': [ {'connectedNetworkDeviceId':networkDevice[0].id}, {'frequency':frequency} ]});
            }
            count = UtilityFunctions.getRandomIntForRangeValues(0,hosts.length);
            return count.toString();
        },

        apAggregation : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            return 0.0;
        },

        apRadioAirQuality : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
              var min = 98; max = 99;
              if(urlAction.restPayload.dimensions[0].name =="slot" && urlAction.restPayload.dimensions[0].value=="0")  {
                  min = 93; max = 96;
              }
              return UtilityFunctions.getRandomIntForRangeValues(min,max).toString();
        },

        apRadioInterference : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            var min = 70; max = 75;
            if(urlAction.restPayload.dimensions[0].name =="slot" && urlAction.restPayload.dimensions[0].value=="0")  {
                min = 75; max = 80;
            }
            return UtilityFunctions.getRandomIntForRangeValues(min,max).toString();
        },

        wlc_cpu_entity : function(urlAction, filter, cachedSummaryData, fieldName, graphStartDateTime, graphCurrentDateTime){
            var avg = 10;
            if(urlAction.restPayload.dimensions[0].value){
                min = avg-5; max = avg+5;
                // if(urlAction.restPayload.entity.managementIpAddr == '10.30.200.4'){
                //     var avg = 80; // Added and Commented by Karan for 1.3.0 for CPU usage in Device 360
                //     min = avg-5; max = avg+5;    
                // }else{
                //     min = avg-5; max = avg+5;
                // }
                return UtilityFunctions.getRandomIntForRangeValues(min,max);
            } else {
                if(fieldName == 'wlc_entity_cpu_avg'){
                    return avg;
                } else {
                    return 'CPU 1';
                }
            }
        }

  };//end of function table..

  return {
      init: function(){
      },

      getDeviceHealthScoreAgg: function(deviceObj, urlAction, graphStartDateTime, graphCurrentDateTime, i) {
          if(deviceObj.length && deviceObj[0].family == 'Unified AP') {
              return getAccessPointHealthScoreAggregationAvg(deviceObj, graphStartDateTime, graphCurrentDateTime,urlAction, i);
          } else if(deviceObj.length && deviceObj[0].family == 'Wireless Controller'){
            return getWLCDeviceHealthScoreAggregationAvg(deviceObj, graphStartDateTime, graphCurrentDateTime);
          }
          else {
              return getDeviceHealthScoreAggregationAvg(deviceObj, urlAction, graphStartDateTime, graphCurrentDateTime);
          }
      },

      getClientHealthAggregationAvgScore: function(clientObj, graphStartDateTime, graphCurrentDateTime, randomizeAvgScore, disconnectObj) {
          return getClientHealthAggregationAvgScore(clientObj, graphStartDateTime, graphCurrentDateTime, randomizeAvgScore, disconnectObj);
      },

      getClientMetricCountForIssues: function(clientObj, urlAction) {
          return clientIssueMetricCountHandler(clientObj, urlAction);
      },

      getClientOnboardTimes: function(clientObj, urlAction) {
          return getClientOnboardTimes(clientObj, urlAction);
      },

      getCategoryAggregation: function(urlAction) {
          return getCategoryAggregation(urlAction);
      },

      getKpiAggregation: function(urlAction) {
          return getKpiAggregation(urlAction);
      },

      getHealthScoreAggregation: function(urlAction) {
          return getHealthScoreAggregation(urlAction);
      },

      getData: function(fieldName, urlAction, filter, graphStartDateTime, graphCurrentDateTime) {
          var summaryData = SimAssuranceCacheData.getCacheData(urlAction);

          if(fieldHandler[fieldName] != undefined) {
              return fieldHandler[fieldName](urlAction, filter, summaryData, fieldName, graphStartDateTime, graphCurrentDateTime);
          } else {
              var t = checkPatternMatches(fieldName);
              if(t.matches == true) {
                  return fieldHandler[t.functionName](urlAction, filter, summaryData, fieldName, graphStartDateTime, graphCurrentDateTime);
              }
              return 10;
          }
      },

      getFabricWlcSessionStatus : function (urlAction) {

            return {fabricWlcSessionStatus:1,sessionIdentifier:urlAction.restPayload.dimensions[0].value};
       },
      getDeviceStatus :function (urlAction) {

       return{value:0};
      },
      getAPConnectivityChart(urlAction) {

       var macAddress  =  urlAction.restPayload.entity.macAddress.toLowerCase();
       var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':macAddress});
       var associatedWlcIp = sourceDevice[0].associatedWlcIp;
       var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':associatedWlcIp} );
       var apUpDownStatus = "DOWN";
       if(sourceDevice[0].reachabilityStatus=="Reachable") {

            apUpDownStatus="UP";
       }
       //1.3.1 Ap down issue response format changed
       /*if(urlAction.restPayload.field !=undefined && urlAction.restPayload.field == 'apUpDownStatus') {
        return {value:apUpDownStatus};
       }*/
       return {apUpDownStatus:apUpDownStatus,connectedWlc:wlcDevice[0].managementIpAddress,connectedWlcName : wlcDevice[0].hostname,connectedWlcUuid:wlcDevice[0].id};

      }

      ,
      getApNoise: function(urlAction) {
      var obj = {};
      var slot = urlAction.restPayload.dimensions[0].value;
      //var field = urlAction.restPayload.fields[0];
      /* var noise_0 = [-92, -93, -93, -94, -95, -96, -97];
      var noise_1 = [-79, -81, -88, -90, -90, -91, -88, -88];//frequency off 88 is more */
      var noise_0 = [-93,-93,-93,-93,-94,-93,-93,-93,-93,-93,-93,-93,-93,-94,-94,-93,-93,-93,-92,-93,-94,-93,-92,-93,-92,-93,-93,-93,-93,-93,-92,-93,-92,-93,-93,-93,-94,-93,-92,-93,-93,-94,-93,-93,-92,-92,-93,-94,-94,-93,-94,-93,-93,-93,-93,-94,-93,-93,-93,-94,-93,-92,-93,-94,-93,-93,-93,-92,-93,-93,-94,-93,-95,-94,-93,-93,-92,-93,-93,-93,-92,-92,-91,-93,-94,-93,-93,-94,-93,-94,-93,-93,-93,-94,-94,-93,-93,-94,-94,-94,-93,-94,-94,-95,-95,-94,-94,-93,-93,-92,-92,-94,-93,-93,-93,-93,-92,-93,-94,-94,-93,-93,-93,-93,-93,-93,-94,-94,-95,-93,-93,-94,-93,-94,-93,-94,-94,-93,-93,-92,-94,-94,-94,-93,-93,-94,-93,-94,-93,-94,-93,-93,-94,-93,-94,-93,-94,-94,-93,-94,-94,-94,-94,-93,-94,-93,-94,-93,-94,-93,-92,-93,-94,-93,-94,-95,-94,-93,-93,-93,-94,-94,-93,-94,-94,-94,-94,-93,-94,-94,-93,-93,-95,-93,-93,-92,-93,-94,-94,-93,-94,-94,-95,-94,-93,-94,-94,-93,-93,-93,-92,-93,-93,-93,-93,-94,-94,-95,-93,-92,-92,-93,-94,-93,-93,-94,-93,-94,-94,-93,-93,-94,-93,-94,-94,-94,-95,-94,-94,-94,-94,-94,-94,-93,-93,-94,-94,-94,-94,-94,-93,-93,-92,-92,-94,-93,-94,-94,-92,-93,-95,-93,-94,-95,-94,-94,-93,-92,-92,-94,-93,-93,-93,-93,-93,-93,-93,-92,-93,-93,-93,-93,-94,-94,-94,-93,-93,-93];
      var noise_1 = [-93,-93,-93,-93,-93,-92,-92,-92,-92,-92,-94,-95,-95,-95,-95,-93,-93,-93,-93,-93,-91,-91,-92,-93,-93,-94,-94,-93,-93,-93,-93,-93,-93,-93,-93,-93,-93,-95,-95,-92,-92,-91,-90,-90,-92,-92,-94,-95,-93,-92,-92,-92,-92,-92,-93,-89,-87,-86,-90,-93,-93,-95,-95,-93,-93,-94,-95,-94,-93,-93,-94,-95,-94,-93,-93,-95,-95,-93,-93,-92,-92,-92,-93,-93,-93,-93,-93,-93,-93,-93,-93,-90,-87,-87,-92,-93,-94,-95,-94,-93,-93,-93,-93,-91,-89,-89,-94,-95,-93,-93,-89,-86,-85,-89,-92,-92,-92,-92,-95,-95,-92,-92,-94,-95,-94,-94,-94,-92,-92,-92,-92,-92,-87,-86,-89,-91,-92,-93,-94,-95,-95,-94,-94,-94,-94,-94,-90,-87,-87,-90,-92,-93,-93,-94,-95,-94,-93,-93,-93,-93,-93,-95,-95,-93,-93,-94,-94,-90,-88,-87,-91,-94,-93,-92,-92,-94,-95,-92,-90,-90,-93,-94,-91,-91,-92,-92,-92,-95,-95,-92,-92,-92,-93,-93,-93,-93,-92,-92,-91,-91,-91,-92,-92,-92,-95,-95,-92,-92,-94,-95,-93,-92,-92,-89,-89,-90,-91,-91,-91,-91,-92,-93,-93,-93,-93,-94,-95,-93,-92,-92,-92,-92,-93,-93,-90,-87,-87,-93,-94,-83,-81,-80,-90,-92,-93,-93,-93,-93,-92,-91,-91,-92,-93,-90,-88,-88,-91,-93,-92,-91,-91,-93,-93,-93,-93,-93,-93,-93,-95,-95,-92,-92,-91,-91,-91,-92,-93,-93,-93,-93,-95,-95,-92,-91,-91,-93,-93,-91];
      if(slot=="0") {
        obj[urlAction.restPayload.fields[0]] = noise_0[ UtilityFunctions.getRandomIntForRangeValues(0, noise_0.length-1) ];
      } else {
        obj[urlAction.restPayload.fields[0]] = noise_1[ UtilityFunctions.getRandomIntForRangeValues(0, noise_1.length-1) ];
      }
      return obj;
     },

     apRadioStatsAggregationTraffic: function(urlAction){
        var obj = {};
        var count = 0, hosts = [], frequency = '5.0';
            //MAC address in payload is in capitals and hence doesnt match the DB entry. Hence ToLowereCase is used.
        var networkDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':urlAction.restPayload.entity['macAddress'].toLowerCase()});
        if(urlAction.restPayload.dimensions[0].name =="slot" && urlAction.restPayload.dimensions[0].value=="0")  {
            frequency = '2.4';
        }
        if(networkDevice.length) {
            hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',
                {'$and': [ {'connectedNetworkDeviceId':networkDevice[0].id}, {'frequency':frequency} ]});
        }
        
        var ap_24_list = DeviceIssueListing.issuesTemplate["radio_util_trigger"].devices;
        var ap_5_list = DeviceIssueListing.issuesTemplate["radio_util_trigger_ap"].devices;
        var isAp24Issue=false, isAp5Issue=false;
        ap_24_list.forEach(function (dev) {
            if(networkDevice[0].hostname == dev.hostName && frequency == '2.4') {
                isAp24Issue = true;
                return;
            }
        });
        ap_5_list.forEach(function (dev) {
            if(networkDevice[0].hostname == dev.hostName && frequency == '5.0') {
                isAp5Issue = true;
                return;
            }
        });
        if(hosts.length>0) {

          var rxBytes = [33457, 33816, 35524, 36515, 36435, 38133, 39176, 39457, 41100, 41371, 41587, 41792, 42730, 43303, 43210, 43353, 44633, 44970, 45039, 46072, 48280, 48471, 49784, 50191, 50370, 50512, 51037, 51202, 51818, 52084, 52201, 53949, 53572, 54275, 54637, 55481, 55377, 56179, 56807, 57702, 58805, 59959, 63974, 60140, 64040, 67256,66820,72036,76841,76750, 80715, 84045];
          var txBytes = [2696,2717,3213,3264,3600,3640,3916,4201,4232,4336,4382,4452,4636,4856,4956,4960,5080,5424,5440,5598,5700,5768,5802,5916,6068,6092,6620,6662,6702,6782,6802,6842,6952,7108,7120,7184,7354,7576,7604,7780,7957,8137,8228,8416,8854,9138,9861,10946,15560,21244];

          var rxBytes = rxBytes[ UtilityFunctions.getRandomIntForRangeValues(0, rxBytes.length - 1) ];
          var txBytes = txBytes[ UtilityFunctions.getRandomIntForRangeValues(0, txBytes.length - 1) ];
          if(isAp24Issue || isAp5Issue) {
            rxBytes += 50000;
            txBytes += 30000;
          }
            obj.rxBytes=`${rxBytes}`;
            obj.txBytes=`${txBytes}`;
       } else {
         obj.rxBytes="0";
         obj.txBytes="0";
       }
      return obj;
     },

     getAPConfiguration :function(urlAction) {

     var networkDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':urlAction.restPayload.entity['macAddress'].toLowerCase()});
           var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
      return getAPConfig(networkDevice[0].id,summaryData);
     }
  };

  function checkPatternMatches(fieldName) {

      for(var i = 0; i < patternList.length; i++) {
          if( patternList[i].pattern.test(fieldName) ) {
              return { matches: true, functionName: patternList[i].functionName };
          }
      }

      return {matches:false};
  }

  function getCategoryAggregation(urlAction) {
     var roleName = "", obj = {};
      if(urlAction.restPayload.dimensions != undefined && Array.isArray(urlAction.restPayload.dimensions)) {
          for(var i = 0; i < urlAction.restPayload.dimensions.length; i++) {
              if(urlAction.restPayload.dimensions[i].name == 'category') {
                  roleName = urlAction.restPayload.dimensions[i].value;
                  break;
              }
          }
          var tName = urlAction.restPayload.name;
          if(tName == "fabricCategoryAggregation") {
              roleName = roleName.substr(6, roleName.length - 6);
              roleName = roleName.toUpperCase();
              if(roleName == 'EDGE') {
                  roleName = "ACCESS";
              }
          } else if(tName == "globalCategoryAggregation" || tName =="nwHealthSiteCategoryAggregation") {
              roleName = roleName.toUpperCase();
              if(roleName == 'ROUTERS') {
                  roleName = "ROUTER";
              }
          }
          var cachedSummaryData = SimAssuranceCacheData.getCacheData(urlAction);
          if(roleName != "" && cachedSummaryData[roleName] != undefined) {
              var iLen = urlAction.restPayload.fields.length;
              for(var i = 0; i < iLen; i++) {
                 var fieldName = urlAction.restPayload.fields[i];
                 keyName = fieldName.substr(2, fieldName.length - 1);
                 var tVal = cachedSummaryData[roleName][keyName];
                 if(keyName == 'cp') {
                     obj[fieldName] = tVal == undefined || tVal == 0 ? -1 : tVal;
                 } else {
                     obj[fieldName] = tVal;
                 }
              }
          }
      }
      return obj;
  }

  function getKpiAggregation(urlAction) {
      var roleName = "", obj = {}, isFabricRole=false;
      var deviceIssues=SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'entityType': 'Device' });
     // var deviceList=SimLokiDatabaseActions.getAll('network-device');
      if(urlAction.restPayload.dimensions != undefined && Array.isArray(urlAction.restPayload.dimensions)) {
          for(var i = 0; i < urlAction.restPayload.dimensions.length; i++) {
              if(urlAction.restPayload.dimensions[i].name == 'category') {
                  roleName = urlAction.restPayload.dimensions[i].value;
                  break;
              }
          }
          var tName = urlAction.restPayload.name;
          if(tName == "fabricKpiAggregation") {
              if(roleName.indexOf('fabric')>-1){
                isFabricRole=true; 
              }
              roleName = roleName.substr(6, roleName.length - 6);
              roleName = roleName.toUpperCase();
              if(roleName == 'EDGE') {
                  roleName = "ACCESS";
              }
              if(roleName == 'CONTROL PLANE') {
                  roleName = "CP";
              }
          } else if(tName == "globalKpiAggregation" || tName == "siteKpiAggregation") {
              roleName = roleName.toUpperCase();
              if(roleName == 'ROUTERS') {
                  roleName = "ROUTER";
              }
              if(roleName == '') {
                  roleName = "AP";
              }
          }
          var cachedSummaryData = SimAssuranceCacheData.getCacheData(urlAction);
          var metricName;
          /*if(roleName != "" && cachedSummaryData[roleName] != undefined) {
              var iLen = urlAction.restPayload.fields.length;
              for(var i = 0; i < iLen; i++) {
                 var fieldName = urlAction.restPayload.fields[i];
                 keyName = fieldName.substr(2, fieldName.length - 1);
                 var tVal = cachedSummaryData[roleName][keyName];
                 if(keyName == 'cp') {
                     obj[fieldName] = tVal == undefined || tVal == 0 ? -1 : tVal;
                 } else {
                     obj[fieldName] = tVal;
                 }
              }
          }*/
          // Added for AP Link issue
          for(var a=0;a<urlAction.restPayload.dimensions.length;a++){
            if(urlAction.restPayload.dimensions[a].name == 'metricName'){
               metricName = urlAction.restPayload.dimensions[a].value;
             }
          }
           var devlist; 
          if(cachedSummaryData[roleName] != undefined) {

            if(roleName==="ACCESS"){
                var deviceList = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('ACCESSS') >= 0) ||
                           itm.role == 'ACCESS';});
                devlist=[];
                if(!isFabricRole){
                for(var z=0;z<deviceList.length;z++)
                {
                    if(deviceList[z].family!='Wireless Controller' && deviceList[z].family!='Unified AP')
                    {
                        devlist.push(deviceList[z])
                    }
                }
            }
            else{
                devlist=deviceList;
            }
            }
            else if(roleName==="DISTRIBUTION"){
                devlist =cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('DISTRIBUTION') >= 0) ||
                           itm.role == 'DISTRIBUTION';});
                 }
            else if(roleName==="CORE"){
                devlist = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('CORE') >= 0) ||
                           itm.role == 'CORE';});
            }
            else if(roleName==="AP"){
                devlist = cachedSummaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP"; });
            }
            else if(roleName==="ROUTER"){
                devlist = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('ROUTER') >= 0) ||
                           itm.role == 'ROUTER' || itm.role == 'BORDER ROUTER';});
            }
            else if(roleName==="CP"){
                devlist = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('CP') >= 0) ||
                           itm.role == 'CP';});
            }
            else if(roleName==='WLC' || roleName==='WIRELESS CONTROLLER'){
               devlist = cachedSummaryData.network_devices.filter(function(itm){ return itm.family == "Wireless Controller"; });
            }
            else if(roleName==="BORDER"){
                devlist = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf('BORDER') >= 0) ||
                           itm.role == 'BORDER';});
            }
            else{
                devlist = cachedSummaryData.network_devices.filter(function(itm){
                    return (Array.isArray(itm.role) &&  itm.role.indexOf(roleName) >= 0) ||
                           itm.role == roleName;});
            }

        }
            if(metricName==="errorScore"){
                  //link issues
                var issueCount=0;
                var deviceissueIpMacs=[];
                var linkIssues=cachedSummaryData.mappedIssues.link_issues;
                  for(var j=0;j<linkIssues.length;j++){
                      for(var k=0;k<deviceIssues.length;k++){
                          if(linkIssues[j]===deviceIssues[k].issueNamekey){
                            for(var h=0;h<devlist.length;h++)
                            {
                                if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                             {
                                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }
                                 }
                             }
                          }
                      }

                  }
                  obj['metricGoodCount']=devlist.length-issueCount;
                  obj['metricFairCount']=0;
                  obj['metricBadCount']=issueCount;
            }
            else if(metricName==="memoryScore"){
               var issueCount=0;
               var deviceissueIpMacs=[];
                var memoryIssues=cachedSummaryData.mappedIssues.memory_issues;
                for(var j=0;j<memoryIssues.length;j++){
                    for(var k=0;k<deviceIssues.length;k++){
                        if(memoryIssues[j]===deviceIssues[k].issueNamekey){
                          for(var h=0;h<devlist.length;h++)
                          {
                            if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                            {
                                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }
                                }
                            }
                        }
                    }

                }
                obj['metricGoodCount']=devlist.length-issueCount;
                 obj['metricFairCount']=0;
                 obj['metricBadCount']=issueCount;
        }
            else if(metricName==="cpuScore"){
               var issueCount=0;
               var deviceissueIpMacs=[];
              var cpuIssues=cachedSummaryData.mappedIssues.cpu_issues;
                for(var j=0;j<cpuIssues.length;j++){
                    for(var k=0;k<deviceIssues.length;k++){
                        if(cpuIssues[j]===deviceIssues[k].issueNamekey){
                          for(var h=0;h<devlist.length;h++)
                          {
                            if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                             {
                                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }
                                }
                            }
                        }
                    }

                }
                 obj['metricGoodCount']=devlist.length-issueCount;
                 obj['metricFairCount']=0;
                 obj['metricBadCount']=issueCount;

            }
            else if(metricName==="upLinkScore"){
                var deviceissueIpMacs=[];
                //interface_issues
                var issueCount=0;
               var interfaceIssues=cachedSummaryData.mappedIssues.interface_issues;
                 for(var j=0;j<interfaceIssues.length;j++){
                     for(var k=0;k<deviceIssues.length;k++){
                         if(interfaceIssues[j]===deviceIssues[k].issueNamekey){
                           for(var h=0;h<devlist.length;h++)
                           {
                            if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                            {
                                 if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }

                                }
                            }
                         }
                     }

                 }
                 obj['metricGoodCount']=devlist.length-issueCount;
                 obj['metricFairCount']=0;
                 obj['metricBadCount']=issueCount;

             }
             else if(metricName==="utilizationScore"){
                var issueCount=0;
                var deviceissueIpMacs=[];
                var utilizationIssues=cachedSummaryData.mappedIssues.utilization_issues;
                  for(var j=0;j<utilizationIssues.length;j++){
                      for(var k=0;k<deviceIssues.length;k++){
                          if(utilizationIssues[j]===deviceIssues[k].issueNamekey){
                            for(var h=0;h<devlist.length;h++)
                            {
                             if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                             {
                                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }
                                 }
                             }
                          }
                      }

                  }
                  obj['metricGoodCount']=devlist.length-issueCount;
                  obj['metricFairCount']=0;
                  obj['metricBadCount']=issueCount;
            }
            else if(metricName==="channelNoiseScore"){
                var issueCount=0;
                var deviceissueIpMacs=[];
                var noiseIssues=cachedSummaryData.mappedIssues.noise_issues;
                  for(var j=0;j<noiseIssues.length;j++){
                      for(var k=0;k<deviceIssues.length;k++){
                          if(noiseIssues[j]===deviceIssues[k].issueNamekey){
                            for(var h=0;h<devlist.length;h++)
                            {
                             if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4)
                             {
                                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0)    {
                                    deviceissueIpMacs.push(deviceIssues[k].entity);
                                    issueCount=issueCount+1;
                                 }
                                 }
                             }
                           }
                      }

                  }
                  obj['metricGoodCount']=devlist.length-issueCount;
                  obj['metricFairCount']=0;
                  obj['metricBadCount']=issueCount;
            }

            else{
                var iLen = urlAction.restPayload.fields.length;
                var tVal;
                for(var i = 0; i < iLen; i++) {
                    var fieldName = urlAction.restPayload.fields[i];
                    var keyName = fieldName.substr(6, fieldName.length-1);
                    if(keyName==="GoodCount"){
                         tVal  = cachedSummaryData[roleName]["TotalCount"];
                    }
                    else{
                         tVal = 0;
                    }
                   obj[fieldName] = tVal;
                }

            }

        }


      return obj;
  }

  function getHealthScoreAggregation(urlAction) {
     var iLen = urlAction.restPayload.fields.length, obj = {}, filter = undefined;
     var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
     for(var i = 0; i < iLen; i++) {
         var fieldName = urlAction.restPayload.fields[i];
         var t = checkPatternMatches(fieldName);
         if(t.matches == true) {
             var value = fieldHandler[t.functionName](urlAction, filter, summaryData, fieldName);
             obj[fieldName] = value;
         } else {
             obj[fieldName] = -1;
         }
     }
     return obj;
  }

  function getDeviceValue(cachedSummaryData, filter, fieldName, graphStartDateTime, graphCurrentDateTime, dimensions) {
      if(fieldName == 'tcpConnScore')  return -1;
      if(fieldName == 'envtemp_fn') {
          var minVal=2500, maxVal=2800, indx=0;
          var keysTemplate = SensorListing_EnvTemp.Template.records;
          var key= dimensions[0].name, val=dimensions[0].value;
          for(var i=0; i< keysTemplate.length; i++) {
              if(keysTemplate[i][key]==val){
                indx = i;
                break;
              }
          }
          minVal = minVal+(indx*1000);
          maxVal = maxVal+(indx*1000);
          return UtilityFunctions.getRandomIntForRangeValues(minVal, maxVal);
      }
      var filterMod = {}; filterMod[filter.key]=filter.value;
      var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',filterMod)
      //var tObj = cachedSummaryData.network_devices.filter(function(itm){ return itm[filter.key] == filter.value });
      var deviceScore = deviceObj.length ? deviceObj[0].assuranceHealthScore : 0;

      var pattern = /(cpu|memory|error|freeMbuf|freeTimer|packetPool|upLink|wqePool|overall)Score/;
      if(fieldName=="overallScore") {
          return deviceScore.toString();
        /* if(10 >= deviceScore && deviceScore >= 8) {
            deviceScore =UtilityFunctions.getRandomIntForRangeValues(8,10).toString();
        } else if(7 >= deviceScore && deviceScore >= 4) {
            deviceScore =UtilityFunctions.getRandomIntForRangeValues(4,7).toString();
        } else if(3 >= deviceScore && deviceScore >= 1) {
            deviceScore =UtilityFunctions.getRandomIntForRangeValues(1,3).toString();
        } */
      }
      if(pattern.test(fieldName)) {
          return deviceScore;
      }

      var scores = [
        {minRange: 1, maxRange: 3, memoryMin: 90, memoryMax: 98, cpuMin: 90, cpuMax: 99},
        {minRange: 4, maxRange: 7, memoryMin: 88, memoryMax: 98, cpuMin: 35, cpuMax: 45},
        {minRange: 8, maxRange: 10, memoryMin: 65, memoryMax: 85, cpuMin: 2, cpuMax: 4}
      ];

      var min = 0, max = 0;
      for(var i =0; i < scores.length; i++) {
          if(deviceScore >= scores[i].minRange && deviceScore <= scores[i].maxRange) {
              if(fieldName == 'max_memory') {
                  min = scores[i].memoryMin; max = scores[i].memoryMax;
              } else if(fieldName == 'max_cpu') {
                  min = scores[i].cpuMin; max = scores[i].cpuMax;
              }
              break;
          }
      }

      return UtilityFunctions.getRandomIntForRangeValues(min, max);
  }

  function getAverageHealthScoreForClient(tClientObj, graphStartDateTime, graphCurrentDateTime) {
     //average health score need to support time travel, hence seprate function for special case..
     var hostIssueTime =  SimLokiDatabaseActions.getFilteredRecordHandler('sim-issue-timing', { 'hostId': tClientObj[0].id });

     if(hostIssueTime.length>0) {
          var c = new Date(graphCurrentDateTime);
          var s= new Date(graphStartDateTime);
          var start = hostIssueTime[0].start;
          var end =hostIssueTime[0].end;
          var d = new Date();
          var n = s.getTime();
          var dayDiff = UtilityFunctions.date_diff_indays(s,d) ;
          var startTime =  dayDiff==1? (n +start*60*60*1000) : (n +(start+144)*60*60*1000) ;
          var endTime  =  dayDiff ==1 ? (n +end*60*60*1000)  : (n +(end +144)*60*60*1000);
          hostIssueTime[0].graphStartDateTime =n;
          SimLokiDatabaseActions.update('sim-issue-timing', hostIssueTime);

          if(tClientObj[0].id == '6C:19:C0:BD:87:C9' || tClientObj[0].id == '6C:19:C0:BD:87:C7' ) {

              var minStartTime =   dayDiff ==1? n + 3*60*60*1000+180000 : n + (144+3)*60*60*1000+180000;
              var minEndTime  =    dayDiff ==1? n + 3*60*60*1000 +420000 :  n + (144+3)*60*60*1000 +420000;

           return (c.getTime() >=  startTime && c.getTime() <= endTime || c.getTime() >=  minStartTime && c.getTime() <= minEndTime)  ? 1 : 10;
          } else if(tClientObj[0].id=="6C:19:C0:BD:93:AB" || tClientObj[0].id=="6C:18:C0:BD:93:00" || tClientObj[0].id=="6C:18:C0:BD:81:00") {
               if(c.getMinutes() == 30 || c.getMinutes() == 60) {
                return 1
               }
               else if( c.getMinutes() == 35 || c.getMinutes() == 5) {
                return 0
               }else {
                return 10
               }

          } else {

                return c.getTime() >=  startTime && c.getTime() <= endTime ? 1 : tClientObj[0].healthScore[0].score;
          }
     }
     else {
         return tClientObj.length ? tClientObj[0].healthScore[0].score : 0;
     }
  }


  function appDelayMetricData(cachedSummaryData, filter, fieldName) {
      var min = 0, max = 0;
      if(fieldName == 'applicationDelay') {
          min = 13;
          max = 750;
      } else if(fieldName == 'latency') {
          min = 22;
          max = 140;
      }
      return UtilityFunctions.getRandomIntForRangeValues(min, max);
  }

  function getClientHealthAggregationAvgScore(clientObj, graphStartDateTime, graphCurrentDateTime, randomizeAvgScore, disconnectObj) {
      var rxBytes = [33457, 33816, 35524, 36515, 36435, 38133, 39176, 39457, 41100, 41371, 41587, 41792, 42730, 43303, 43210, 43353, 44633, 44970, 45039, 46072, 48280, 48471, 49784, 50191, 50370, 50512, 51037, 51202, 51818, 52084, 52201, 53949, 53572, 54275, 54637, 55481, 55377, 56179, 56807, 57702, 58805, 59959, 63974, 60140, 64040, 67256,66820,72036,76841,76750, 80715, 84045];
      var txBytes = [2696,2717,3213,3264,3600,3640,3916,4201,4232,4336,4382,4452,4636,4856,4956,4960,5080,5424,5440,5598,5700,5768,5802,5916,6068,6092,6620,6662,6702,6782,6802,6842,6952,7108,7120,7184,7354,7576,7604,7780,7957,8137,8228,8416,8854,9138,9861,10946,15560,21244];
      var rxRate = [0.2607880689458457,23.871519677347401,27.871519688347401,32.871517895847401,35.871519683847401,44.9466418582345,48.9466418582318,52.11859320609771,54.996654283670594,55.22477642398415,57.96170698037333,60.71064161815017,61.774416231466475,63.96170698037333,70.11859320609771,76.11859320609331,77.11859320000771,79.11859203609771,82.11859320609771,86.11859320907771,]
      var txRate = [239.17404360098493,242.10234567854324,246.14368885421044,254.66274321402835,257.66274321402835,261.3910641364401,267.17404360098493,270.4604117894231,273.89399123596854,276.7979349261616,278.14724957963006,287.2542685740655,289.6463423074261,290.86104636403496,297.3096324765794,294.3910641364401,298.6081113510919,299.14724957963006,301.2305660603878,302.17404360098493,307.3858522829543,309.17404360098493,55078.387387087096]
      var tRssi = clientObj[0].rssi == undefined ? -50 : clientObj[0].rssi;
      tRssi = parseInt(clientObj[0].rssi);
      var rssiMin = tRssi >= -72 ? tRssi : tRssi - 10;
      var rssiMax = tRssi + 5;
      tRssi = UtilityFunctions.getRandomIntForRangeValues(rssiMin, rssiMax);
      var channel = "153";

      /* var tSnr = clientObj[0].snr == undefined ? 8 : clientObj[0].snr;
      tSnr = parseInt(clientObj[0].snr); */
      //if the client has higher values, it doesnt show in the graph. having all clients under snr of 10 hampers client snr dashlet in client health dashboard. hence this manipulation.
      var tSnr = 8; 
      var tSnrMin = tSnr - 1, tSnrMax = tSnr + 1;
      tSnr = UtilityFunctions.getRandomIntForRangeValues(tSnrMin,tSnrMax);

      var rxPackets = UtilityFunctions.getRandomIntForRangeValues(20,50);

      var tAverageHealthScore = getAverageHealthScoreForClient(clientObj, graphStartDateTime, graphCurrentDateTime);
      if(randomizeAvgScore && clientObj[0].id!="6C:19:C0:BD:93:AB" && clientObj[0].id!="6C:18:C0:BD:93:00" && clientObj[0].id!="6C:18:C0:BD:81:00") {
          tAverageHealthScore = getRandomSimulatedScoreForGraph(tAverageHealthScore, clientObj[0].healthScore[0].score);
      }

      if(disconnectObj.isEmptySpecificTime) {
          tAverageHealthScore = 0;
          var t = {"time":"","deviceType":null,"rssi":null,"rxBytes":null,"channel":null,"averageHealthScore":tAverageHealthScore,"dataRate":null,"ssid":null,"apName":null,"frequency":null,"txBytes":null,"snr":null,"channelWidth":null,"rxRate":null,"txRate":null};
          return t;
      }

      var rxBytes = rxBytes[ UtilityFunctions.getRandomIntForRangeValues(0, rxBytes.length - 1) ];
      var txBytes = txBytes[ UtilityFunctions.getRandomIntForRangeValues(0, txBytes.length - 1) ];
      var tSsid = clientObj.length ? clientObj[0].ssid: "GUEST";
      var str = clientObj[0].hostType.toLowerCase();
      var deviceType = str.charAt(0).toUpperCase() + str.slice(1);
      var tFrequency = clientObj.length ? clientObj[0].frequency : "5.0";
      var tApName = clientObj.length ? clientObj[0].clientConnection : ''
        //added to map for client 360 health chart
      var rxRate = rxRate[ UtilityFunctions.getRandomIntForRangeValues(0, rxRate.length - 1) ];
      
      if(clientObj[0].hostName == 'Camera1'){
        var txRate = UtilityFunctions.getRandomIntForRangeValues(4,5)*1000000;
      }
      else {
        var txRate = txRate[ UtilityFunctions.getRandomIntForRangeValues(0, txRate.length - 1) ];
      }
     

      if(disconnectObj.isDeviceDisconnectHandled){
          if(disconnectObj.disconnectPart!=disconnectObj.totalDisconnects) {
              var part = disconnectObj.disconnectPart - 1;
              tApName = disconnectObj.preConnectedDevices[part].name;
          } else {
              channel = "140";
          }
      }

      var t = {"time":"","deviceType":deviceType,"rssi":tRssi,"rxBytes":rxBytes,"channel":channel,"averageHealthScore":tAverageHealthScore,"dataRate":173.0,"ssid":tSsid,"apName":tApName,"frequency":tFrequency,"txBytes":txBytes,"snr":tSnr,"channelWidth":"20","rxRate":rxRate,"txRate":txRate,"rxPackets":rxPackets};
      return t;
  }

  function getDeviceHealthScoreAggregationAvg(deviceObj, urlAction, graphStartDateTime, graphCurrentDateTime) {
     var tScore = deviceObj.length ? deviceObj[0].assuranceHealthScore : 0;
     var mem = 0, cpu = 0;
     if(10 >= tScore && tScore >= 8) {
         mem = UtilityFunctions.getRandomIntForRangeValues(20,40);
         cpu = UtilityFunctions.getRandomIntForRangeValues(20,40);
         //tScore =UtilityFunctions.getRandomIntForRangeValues(8,10);
     } else if(7 >= tScore && tScore >= 4) {
         mem = UtilityFunctions.getRandomIntForRangeValues(60,80);
         cpu = UtilityFunctions.getRandomIntForRangeValues(60,80);
         //tScore =UtilityFunctions.getRandomIntForRangeValues(4,7);
     } else if(3 >= tScore && tScore >= 1) {
         mem = UtilityFunctions.getRandomIntForRangeValues(90,99);
         cpu = UtilityFunctions.getRandomIntForRangeValues(90,99);
         //tScore =UtilityFunctions.getRandomIntForRangeValues(1,3);
     }

     var tMetric = `MEM=${mem}::CPU=${cpu}`
     var allMetric = `MEM=I_${mem}::CPU=I_${cpu}::RE=I_::INCON=I_::MRPC=I_::LE=I_${tScore}::DISC=I_${tScore}::AAA=I_`
     var obj = {"upLink":"","tcpConn":"","memory":mem,"intfError":"","overallScore":tScore,"cpu":cpu,"intfDiscard":"","upLinkScore":tScore,
     "memoryScore":tScore,"allMetricValue":tMetric,"allThresholds":allMetric,"discardScore":tScore,"tcpConnScore":"-1","cpuScore":tScore,"errorScore":tScore}

     /* Commenting the below code as it is creating issue on mousehovering timeTravel graph*/
     /*var iLen = urlAction.restPayload.fields.length, obj = {}, filter = undefined;
     var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
     for(var i = 0; i < iLen; i++) {
         var fieldName = urlAction.restPayload.fields[i];
         if(["allMetricValue","allThresholds","cpu","memory","upLinkScore","intfDiscard","intfError"].indexOf(fieldName) > -1 ) {
              obj["allMetricValue"] = tMetric
              obj["allThresholds"] = allMetric
              obj["cpu"] = cpu
              obj["memory"] = mem
              obj["upLinkScore"] = tScore
              obj["intfDiscard"] = ""
              obj["intfError"] = ""
             continue;
         }
         var t = checkPatternMatches(fieldName);
         if(t.matches == true) {
             var value = fieldHandler[t.functionName](urlAction, filter, summaryData, fieldName);
             obj[fieldName] = value;
         } else {
             obj[fieldName] = -1;
         }
     }*/
     return obj;
  }

  function getWLCDeviceHealthScoreAggregationAvg(deviceObj, graphStartDateTime, graphCurrentDateTime) {
     var tScore = deviceObj.length ? deviceObj[0].assuranceHealthScore : 0;
     var mem = 0, cpu = 0;
     if(10 >= tScore && tScore >= 8) {
         mem = UtilityFunctions.getRandomIntForRangeValues(20,40);
         cpu = UtilityFunctions.getRandomIntForRangeValues(20,40);
         //tScore =UtilityFunctions.getRandomIntForRangeValues(8,10);
     } else if(7 >= tScore && tScore >= 4) {
         mem = UtilityFunctions.getRandomIntForRangeValues(60,80);
         cpu = UtilityFunctions.getRandomIntForRangeValues(60,80);
        // tScore =UtilityFunctions.getRandomIntForRangeValues(4,7);
     } else if(3 >= tScore && tScore >= 1) {
         mem = UtilityFunctions.getRandomIntForRangeValues(90,99);
         cpu = UtilityFunctions.getRandomIntForRangeValues(90,99);
         //tScore =UtilityFunctions.getRandomIntForRangeValues(1,3);
     }
   //  var tScore = UtilityFunctions.getRandomIntForRangeValues(6,8);

     var wqeScore = UtilityFunctions.getRandomIntForRangeValues(87,93);
     var fmbScore = UtilityFunctions.getRandomIntForRangeValues(78,85);
     var ftScore = UtilityFunctions.getRandomIntForRangeValues(10,14);
     var pckScore = UtilityFunctions.getRandomIntForRangeValues(10,14);

     var tMetric =  "RE=0::CPU="+cpu+".0::MEM="+mem+".0::FMB="+fmbScore+".0::FT="+ftScore+".0::WQE="+wqeScore+".0::PCK="+pckScore+".0";
     var obj = {overallScore:tScore,memoryScore:tScore,freeTimerScore:tScore,freeMbufScore:tScore,errorScore:tScore,wqePoolScore:tScore,packetPoolScore:tScore,tcpConnScore:-1,allMetricValue:tMetric};

     return obj;
  }

    function getAccessAverageHealthScoreForDevice(tscore,tDevicetObj, graphStartDateTime, graphCurrentDateTime) {
        //average health score need to support time travel, hence seprate function for special case..
        var hostIssueTime =  SimLokiDatabaseActions.getFilteredRecordHandler('sim-issue-timing-device', { 'hostName': tDevicetObj[0].hostname });

        if(hostIssueTime.length>0) {
            var c = new Date(graphCurrentDateTime);
            var s= new Date(graphStartDateTime);
            var start = hostIssueTime[0].start;
            var end =hostIssueTime[0].end;
            var d = new Date();
            var n = s.getTime();
            var dayDiff = UtilityFunctions.date_diff_indays(s,d) ;
            var startTime =  dayDiff==1? (n +start*60*60*1000) : (n +(start+144)*60*60*1000) ;
            var endTime  =  dayDiff ==1 ? (n +end*60*60*1000)  : (n +(end +144)*60*60*1000);
            hostIssueTime[0].graphStartDateTime =n;
            SimLokiDatabaseActions.update('sim-issue-timing-device', hostIssueTime);
            return c.getTime() >=  startTime && c.getTime() <= endTime ? 1 : UtilityFunctions.getRandomIntForRangeValues(1,6);
        }
        else {
            return tscore;
        }
    }


  function getAPConfig(deviceId,summaryData) {

    var ap_24 =false;
    var ap_5 =false;

    for(var i=0;i<summaryData.highInterfaceApList24.length;i++) {
       if(summaryData.highInterfaceApList24[i].id==deviceId) {
         ap_24=true;
       }
    }

    for(var i=0;i<summaryData.highInterfaceApList5.length;i++) {
       if(summaryData.highInterfaceApList5[i].id==deviceId) {
         ap_5=true;
       }
    }
    if(ap_24 && ap_5) {
     return summaryData.apConfig.HighInterference_Both;
    } else if(ap_24) {
     return summaryData.apConfig.HighInterference_24;
    } else  if(ap_5) {
     return summaryData.apConfig.HighInterference_5;
    } else {
     return summaryData.apConfig.Default
    }

  }

  function getAccessPointHealthScoreAggregationAvg(deviceObj, graphStartDateTime, graphCurrentDateTime,urlAction, i) {
        var tScore = deviceObj.length ? deviceObj[0].assuranceHealthScore : 0;
        var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
        var highInterfaceAPList=summaryData.highInterfaceApList;
        var apConfig= summaryData.apConfig.Default;
        if(highInterfaceAPList.indexOf(deviceObj[0].id)>=0){
            apConfig =getAPConfig(deviceObj[0].id,summaryData);
        } else if(deviceObj[0].hostname != "SJC01_9136_12"){
             if(10 >= tScore && tScore >= 8) {
                 tScore =UtilityFunctions.getRandomIntForRangeValues(8,10);
             } else if(7 >= tScore && tScore >= 4) {
                 tScore =UtilityFunctions.getRandomIntForRangeValues(4,7);
             } else if(3 >= tScore && tScore >= 1) {
                 tScore =UtilityFunctions.getRandomIntForRangeValues(1,3);
             }
        }

        if(['slot0clCount','slot1clCount'].indexOf(urlAction.restPayload.fields[0]) > -1 ) {
            var fieldName = urlAction.restPayload.fields[0], obj = {};
              var count = 0, hosts = [], frequency = '5.0';
            //MAC address in payload is in capitals and hence doesnt match the DB entry. Hence ToLowereCase is used.
            var networkDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':urlAction.restPayload.entity['macAddress'].toLowerCase()});
            if(urlAction.restPayload.fields[0] == 'slot0clCount')  {
                frequency = '2.4';
            }
            if(networkDevice.length) {
                hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',
                    {'$and': [ {'connectedNetworkDeviceId':networkDevice[0].id}, {'frequency':frequency} ]});
            }
            count = UtilityFunctions.getRandomIntForRangeValues(0,hosts.length);
            obj[urlAction.restPayload.fields[0]] = count.toString();
            return obj

        }

        if(deviceObj.length){
            var mem = 0, cpu = 0, interference_0 = 0,interference_1 = 0, util_0 = 0, 
            util_1 = 0, noise_0 =0, noise_1 = 0 ;
            var  tAPScore =  tScore;
             /*mem = UtilityFunctions.getRandomIntForRangeValues(apConfig.memory.min,apConfig.memory.max);
             cpu = UtilityFunctions.getRandomIntForRangeValues(apConfig.cpu.min,apConfig.cpu.max);*/
             var splPointsData = HostIssueTimings_Devices.Modified_TimeTravel[deviceObj[0].hostname]
             if(splPointsData){ 
                tAPScore = splPointsData.highPoints.indexOf(i)>-1 ? 10 : 
                    (splPointsData.mediumPoints.indexOf(i)>-1 ? 5 : (splPointsData.lowPoints.indexOf(i)>-1 ? 1 : tAPScore));
                tScore =  tAPScore
             }
             if(3 >= tScore && tScore >= 1){
                mem = UtilityFunctions.getRandomIntForRangeValues(90,99).toString();
                cpu = UtilityFunctions.getRandomIntForRangeValues(90,99).toString();
                interference_0 = UtilityFunctions.getRandomIntForRangeValues(75,80).toString();
                interference_1 = UtilityFunctions.getRandomIntForRangeValues(75,80).toString();
                util_0 = UtilityFunctions.getRandomIntForRangeValues(85,90).toString();
                util_1= UtilityFunctions.getRandomIntForRangeValues(85,90).toString();                

             }else if(7 >= tScore && tScore >= 4){
                mem = UtilityFunctions.getRandomIntForRangeValues(60,90).toString();
                cpu = UtilityFunctions.getRandomIntForRangeValues(60,90).toString();
                interference_0 = UtilityFunctions.getRandomIntForRangeValues(80,90).toString();
                interference_1 = UtilityFunctions.getRandomIntForRangeValues(70,80).toString();
                util_0 = UtilityFunctions.getRandomIntForRangeValues(80,85).toString();
                util_1 = UtilityFunctions.getRandomIntForRangeValues(80,85).toString();
               
             }else {
                mem = UtilityFunctions.getRandomIntForRangeValues(20,60).toString();
                cpu = UtilityFunctions.getRandomIntForRangeValues(20,60).toString();
                interference_0 = UtilityFunctions.getRandomIntForRangeValues(85,90).toString();
                interference_1 = UtilityFunctions.getRandomIntForRangeValues(70,80).toString();
                util_0 = UtilityFunctions.getRandomIntForRangeValues(20,60).toString();
                util_1 = UtilityFunctions.getRandomIntForRangeValues(25,60).toString();               
             }
             //tScore = getAccessAverageHealthScoreForDevice(tScore,deviceObj, graphStartDateTime, graphCurrentDateTime);

            
            var airQuality1 = UtilityFunctions.getRandomIntForRangeValues(apConfig.airQuality_1.min,apConfig.airQuality_1.max);
            var airQuality2 = UtilityFunctions.getRandomIntForRangeValues(apConfig.airQuality_2.min,apConfig.airQuality_2.max);
            var airValue1 =UtilityFunctions.getRandomIntForRangeValues(apConfig.itfValue_1.min,apConfig.itfValue_1.max);
            var airValue2 = UtilityFunctions.getRandomIntForRangeValues(apConfig.itfValue_2.min,apConfig.itfValue_2.max);
            var noise_0=UtilityFunctions.getRandomFloatForRangeValues(apConfig.noise_0.min,apConfig.noise_0.max,1);
            var noise_1=UtilityFunctions.getRandomFloatForRangeValues(apConfig.noise_1.min,apConfig.noise_1.max,1);
            var tMetric=`MEM=${mem}::CPU=${cpu}::NE=${noise_0},${noise_1}::UTIL=${util_0},${util_1}::AQ=${airQuality1},${airQuality2}::ITF=${interference_0},${interference_1}`;
            var allThresholds = `MEM=I_${mem}::CPU=I_${cpu}::ITF=E_${interference_0},I_${interference_1}::NE=E_${noise_0},I_${noise_1}::UTIL=I_${util_0},I_${util_1}::AQ=E_${airQuality1},I_${airQuality2}::LE=I_1`
            // var tMetric = "UTIL="+airValue1+".0,"+airValue2+".0,-1::ITF="+airValue1+".0,"+airValue2+".0,-1::AQ="+airQuality1+".0,"+airQuality2+".0,-1::CPU="+cpu+".0::MEM="+mem+".0::NE="+noise_0+".0,"+noise_1+".0,-1";
            
            var obj = {"memory":mem,"intfError":"null",
                    "channelAirQualityScore":tScore,"interferenceScore":tScore,"slot1band":"5","slot2chUtil":"null",
                    "slot2chNs":"null","slot0chUtil":util_0,"slot0chAQ":airQuality1,"channelNoiseScore":tScore,
                    "slot2band":"null","slot1chNs":noise_1,"overallScore":tScore,"slot0chNs":noise_0,
                    "cpu":cpu,"slot1chAQ":airQuality2,"slot0chIntfr":interference_0,"memoryScore":tScore,"slot0band":"24",
                    "slot1chIntfr":interference_1,"slot1chUtil":util_1,"slot2chIntfr":"null","slot2chAQ":"null",
                    "utilizationScore":tScore,"errorScore":tScore,"cpuScore":tScore, "tMetric" : tMetric,
                     "allThresholds":allThresholds}

            return obj;
        }
  }

  function getClient360Value(cachedSummaryData, filter, fieldName, graphStartDateTime, graphCurrentDateTime) {
      var tObj = cachedSummaryData.hostsList.filter(function(itm){ return itm[filter.key] == filter.value });

      var rxBytes = [33457, 33816, 35524, 36515, 36435, 38133, 39176, 39457, 41100, 41371, 41587, 41792, 42730, 43303, 43210, 43353, 44633, 44970, 45039, 46072, 48280, 48471, 49784, 50191, 50370, 50512, 51037, 51202, 51818, 52084, 52201, 53949, 53572, 54275, 54637, 55481, 55377, 56179, 56807, 57702, 58805, 59959, 63974, 60140, 64040, 67256,66820,72036,76841,76750, 80715, 84045];
      var txBytes = [2696,2717,3213,3264,3600,3640,3916,4201,4232,4336,4382,4452,4636,4856,4956,4960,5080,5424,5440,5598,5700,5768,5802,5916,6068,6092,6620,6662,6702,6782,6802,6842,6952,7108,7120,7184,7354,7576,7604,7780,7957,8137,8228,8416,8854,9138,9861,10946,15560,21244];

      var tRssi = tObj[0].rssi == undefined ? -50 : tObj[0].rssi;
      tRssi = parseInt(tObj[0].rssi);
      var rssiMin = tRssi >= -72 ? tRssi : tRssi - 10;
      var rssiMax = tRssi + 5;
      tRssi = UtilityFunctions.getRandomIntForRangeValues(rssiMin, rssiMax);

      var tSnr = tObj[0].snr == undefined ? 38 : tObj[0].snr;
      tSnr = parseInt(tObj[0].snr);
      var tSnrMin = tSnr - 5, tSnrMax = tSnr + 5;
      tSnr = UtilityFunctions.getRandomIntForRangeValues(tSnrMin,tSnrMax);

      var tAverageHealthScore = getAverageHealthScoreForClient(tObj, graphStartDateTime, graphCurrentDateTime);

      var client360KeyValue = {
        'apName': tObj.length ? tObj[0].clientConnection : '',
        'averageHealthScore': tAverageHealthScore,
        'channel': "153",
        'channelWidth': "20",
        'frequency': tObj.length ? tObj[0].frequency : "5.0",
        'rssi': tRssi,
        'rxBytes': rxBytes[ UtilityFunctions.getRandomIntForRangeValues(0, rxBytes.length - 1) ],
        'txBytes': txBytes[ UtilityFunctions.getRandomIntForRangeValues(0, txBytes.length - 1) ],
        'snr': tSnr,
        'ssid': tObj.length ? tObj[0].ssid: "GUEST"
      };

      var t = client360KeyValue[fieldName];
      return t != undefined ? t : 0;
  }

  //this function host device issue count different metric
  function clientIssueMetricCountHandler(clientObj, urlAction){
      var obj = {};
      var min = 5, max = 25;

      if(urlAction.restPayload.name == 'onboarding30MinFailExcessive') {
               min = 5; max = 12;
      } else if(urlAction.restPayload.name == 'clientOnboardingAttemptsSiteGroup30Min') {
               min = 5; max = 20;
      } else if(urlAction.restPayload.name == 'clientOnboardingAttempts') {
          var min = 1, max = 10;
          if(urlAction.restPayload.dimensions.length) {
              if(urlAction.restPayload.dimensions[0].name == 'frequency' &&
                  urlAction.restPayload.dimensions[1].name == 'siteGroup') {
                  min = 1; max = 9;
                  //obj.frequency = "2.4";//clientObj[0].frequency;
                  var tI = UtilityFunctions.getRandomIntForRangeValues(1, 2);
                  obj.frequency = tI == 1 ? "2.4" : "5.0";
              }
              else if(urlAction.restPayload.dimensions[0].name == 'siteGroup') {
                       min = 28; max = 70;
              }
              else if(urlAction.restPayload.dimensions[0].name == 'siteId') {
                       min = 39; max = 66;
              } else if(urlAction.restPayload.dimensions[0].name == 'rootCause') {
                  if(urlAction.restPayload.dimensions.length == 2 &&
                      urlAction.restPayload.dimensions[1].name == 'wlcName') {
                     min = 1; max = 50;
                  }
                  else if(urlAction.restPayload.dimensions[0].value == 'AUTH_AAA_REJECTED') {
                      min = 1; max = 5;
                  }
                  else if( urlAction.restPayload.dimensions[0].value == 'AUTH_CL_TIMEOUT_OR_RF') {
                      min = 1; max = 10;
                  } else if( urlAction.restPayload.dimensions[0].value == "AUTH_SEC_PARAM_MISMATCH") {
                      if(urlAction.restPayload.dimensions.length == 3) {
                          if(urlAction.restPayload.dimensions[1].name == 'ssid' &&
                              urlAction.restPayload.dimensions[2].name == 'osCategory' &&
                              urlAction.restPayload.dimensions[3].name == 'deviceCategory'
                            ) {
                                min = 22; max = 50;
                                obj.deviceCategory = "Unclassified";
                                obj.osCategory = "Unknown";
                            }
                      } else {
                          min = 1; max =4;
                      }
                  }
                  else {
                      min = 29, max = 45;
                  }
              }else if(urlAction.restPayload.dimensions[0].name == 'ssid') {
                  min = 23; max = 55;
              }
              else if(urlAction.restPayload.dimensions[0].name == 'wlcName') {
                  min = 36; max = 78;
              }
          }
      }
      else if( urlAction.restPayload.name == 'clientOnboardingAttemptsSsid30Min') {
          min = 5; max = 6;
      }
      else if( urlAction.restPayload.name == 'clientOnboardingAttemptsWlc30Min') {
          min = 7; max = 11;
      }
      else if( urlAction.restPayload.name == 'onboarding30MinFailExcessive') {
          min = 1; max = 1;
      }

      obj.count = UtilityFunctions.getRandomIntForRangeValues(min, max);
      return obj;
  }

  function getClientOnboardTimes(clientObj, urlAction) {
      var failureTime = UtilityFunctions.getRandomIntForRangeValues(0, 5630);
      //var dhcpTime = UtilityFunctions.getRandomIntForRangeValues(0,67);
      var dhcpTime = UtilityFunctions.getRandomIntForRangeValues(1000,7000);

      //var authTime = UtilityFunctions.getRandomIntForRangeValues(344,3000);
      var authTime = UtilityFunctions.getRandomIntForRangeValues(1000,40000);
      var totalTime = UtilityFunctions.getRandomIntForRangeValues(3800,6400);
      //var assocTime = UtilityFunctions.getRandomIntForRangeValues(.16,.75);
      var assocTime = UtilityFunctions.getRandomIntForRangeValues(1000,8000);

      return {"avgFailureTime":failureTime,"avgDhcpTime":dhcpTime,"avgAuthTime":authTime,"avgTotalTime":totalTime,"avgAssocTime":assocTime};
  }

  function getRandomSimulatedScoreForGraph(score, originalscore) {
      var min = 0, max = 0;
      if(score == undefined || score == 0) {
          score = originalscore;
      }

      if(score >= 7 && score <= 10) {
          min = 6;
          max = 10;
      }else if(score >= 7 && score <= 10) {
          min = 7;
          max = 3;
      }
      else if(score >= 3 && score <= 1) {
          min = 1;
          max = 5;
      } else  {
          min = 1;
          max = 2;
      }
      return UtilityFunctions.getRandomIntForRangeValues(min, max);
  }

  function getUpdatedIssueValue(entity, metricName, fieldName, timestamp) {
    //get device info from ip addr
    var value = entity.value, devices = [], isFound = false;
    if(entity.key=='managementIpAddress') {
        devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':value});
    }else if(entity.key=='macAddress') {
        devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':value});
    }
    if(devices.length==0) {
        return;
    }
    var issueName, kpi, threshold;
    //get issue name from field/metric
    if(metricName=='tcam_util' || fieldName=='max_tcam_util') {
        issueName = 'tcam_util_high';
        kpi = 'tcamUtilization';
    } else if(metricName=='apRadioStatsAggregation' || fieldName=='ap_radio_utilization_stats_max') {
        issueName = 'radio_util_trigger';
        kpi = 'radioChannelUtilizationAP';
    }

    //check if the device is part of custom issue to handle
    for(var issueNameAsKey in CustomIssueListing.issuesTemplate) {
        if(issueNameAsKey == issueName) {
            var deviceList = CustomIssueListing.issuesTemplate[issueNameAsKey].devices;
            for(var i=0; i<deviceList.length; i++) {
                if(devices[0].hostname==deviceList[i].hostName) {
                    isFound = true; break;
                }
            }
        }
        if(isFound) { break; }
    }
    if(!isFound) { return; }

    //get the updated threshold values
    var customSettings = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', 
        {'$and': [{'isEnabled': "true"}, {'issueName': issueName}] });
    if(customSettings.length>0) {
        for(var i=0; i<customSettings.length && kpi==customSettings[i].kpi; i++) {
            threshold = customSettings[i].threshold;
            break;
        }
        if(threshold!=undefined) {
            var val = threshold+5 > 100? threshold : threshold+5;
            return val;
        }
    }
    
  }

});
