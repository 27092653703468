define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimIpPoolData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGlobalCredentialData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/parseUrl'
], function (UtilityFunctions, SimLokiDatabaseActions, SimIpPoolData,SimGlobalCredentialData,parseUrl) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction) {
            var data;
            console.log(urlAction);
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                default:
                    data = processRequest(urlAction);
                    break;
            }

            return data;
        }
    };


    function processGetRequest(urlAction){
        if(urlAction.action.count ==true && urlAction.service.indexOf('ippool')>=0 && urlAction.filter.indexOf('siteId')>=0 && urlAction.filter.poolType != "ipv6" && urlAction.filter['ipPoolCidr']>-1) {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and': [{'context.contextKey':'siteId'},{'context.contextValue':urlAction.filter.siteId}]}).length;
        }else if(urlAction.action.count ==true && urlAction.service.indexOf('ippool')>=0 && urlAction.filter.indexOf('parentUuid')>=0 && urlAction.filter['ipv6']=='true') {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and':[{'parentUuid': "root"},{'ipv6':true}]}).length;
        }else if(urlAction.action.count ==true && urlAction.service.indexOf('ippool')>=0 && urlAction.filter.indexOf('parentUuid')>=0 && urlAction.filter['ipv6']=='false') {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and':[{'parentUuid': "root"},{'ipv6':false}]}).length;
        }else if(urlAction.action.count ==true && urlAction.filter.indexOf('ipPoolName')>=0){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolName']:{'$regex':urlAction.filter['ipPoolName']}})
            return record.length
        }else if(urlAction.action.count ==true && urlAction.filter.indexOf('ipPoolCidr')>=0){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolCidr']:{'$regex':urlAction.filter['ipPoolCidr']}})
            return record.length
        }else if(urlAction.action.count ==true && urlAction.filter.indexOf('siteId')>=0 && urlAction.filter.indexOf('ipPoolCidr')>=0){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolCidr']:{'$regex':urlAction.filter['ipPoolCidr']}})
            return record.length
        }else if(urlAction.action.count ==true && urlAction.service.indexOf('ippool')>=0 && urlAction.filter.indexOf('parentUuid')>=0) {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'parentUuid': "root"}).length;
        }else if(urlAction.action.count ==true && urlAction.service.indexOf('group')>=0 && urlAction.filter.poolType == "ipv6"){
            return 0
        }else if(urlAction.service.indexOf('ippool') >=0 && urlAction.filter.indexOf('poolUuid')>=0) {
            var response; 
            if(urlAction.filter.status == "assigned") {
                response = (urlAction.action.count)? 0 : [];
            }
            else if(urlAction.filter.status == "blocklisted") {
                response = (urlAction.action.count) ? 0 : [];
            }
            return response
        }else if(urlAction.action.count && urlAction.filter.indexOf('siteId')>=0) {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and': [{'context.contextKey':'siteId'},{'context.contextValue':urlAction.filter.siteId}]}).length;
        }else if(urlAction.action.count) {
            return SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'parentUuid': "root"}).length;
        }else if(urlAction.filter['instanceUuid']){
           var filterEntries = parseUrl.getUrlVarsWithSplitParem(urlAction.url,'&');
           var instanceUuid,record,ipRecords = [];
           var ids = filterEntries['instanceUuid'];
           ids = ids.split(',')

           for(var idIndex in ids)
           {
             record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id': ids[idIndex]});
             ipRecords.push(record[0]);
           }
           return ipRecords;

        } else if ((urlAction.filter['contextkey'] && urlAction.filter['contextvalue']) || 
          (urlAction.filter['contextKey'] && urlAction.filter['contextValue']) ) {
            var contextKey = urlAction.filter['contextKey'] ? urlAction.filter['contextKey'] : urlAction.filter['contextkey'];
            var contextValue = urlAction.filter['contextValue'] ? urlAction.filter['contextValue'] : urlAction.filter['contextvalue'];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', { '$and': [{ 'context.contextKey': contextKey }, { 'context.contextValue': contextValue }] });
            console.log(record);
            return record;
        } else if ((urlAction.filter['contextvalue'] || urlAction.filter['contextValue']) && 
          (!urlAction.filter['contextKey'] && !urlAction.filter['contextkey'])) {
            var contextValue = urlAction.filter['contextValue'] ? urlAction.filter['contextValue'] : urlAction.filter['contextvalue'];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', { 'context.contextValue': contextValue });
            console.log(record);
            return record;
        } else if (urlAction.filter['contextkey'] || urlAction.filter['contextKey']) {
            var contextKey = urlAction.filter['contextKey'] ? urlAction.filter['contextKey'] : urlAction.filter['contextkey'];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', { 'context.contextKey': contextKey });
            console.log(record);
            return record;
        } else if (urlAction.service.indexOf('ippool')>=0 && urlAction.filter['ipv6']=='true'){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and':[{'parentUuid': "root"},{'ipv6':true}]});
        return record
       }else if (urlAction.service.indexOf('ippool')>=0 && urlAction.filter['ipv6']=='false'){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and':[{'parentUuid': "root"},{'ipv6':false}]});
        return record
       }else if (urlAction.service.indexOf('ippool')>=0 && urlAction.filter['siteId']=='parentUuid'){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'parentUuid': "root"});
        return record
       }else if(urlAction.service.indexOf('group')>=0 && urlAction.filter.poolType == "ipv6"){
            return []
       }else if (urlAction.service.indexOf('ippool')>=0 && urlAction.filter.indexOf('siteId')>=0 && urlAction.service.indexOf('group')>=0){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'$and': [{'context.contextKey':'siteId'},{'context.contextValue':urlAction.filter.siteId}]});
            if(urlAction.filter.indexOf('ipPoolCidr')>=0){
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolCidr']:{'$regex':urlAction.filter['ipPoolCidr']}});
            }
            var ipPoolArr=[],ipPoolObj={};
            for(var i=0;i<record.length;i++){
                ipPoolObj={};
                ipPoolObj["id"]=record[i].id;
                ipPoolObj["groupName"]= record[i].ipPoolName;
                ipPoolObj["ipPools"]=[];
                if(record[i].siteId != 'undefined'){
                    var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': record[i].siteId});
                    ipPoolObj['type']=record[i].type
                    ipPoolObj.ipPools.push(record[i]);
                    //duplicating this code bcz of some of record[i].siteId="undifined"
                    if(siteObj.length ==0){
                        var poolname=record[i].ipPoolName;
                        var objs=poolname.split('_');
                        var ind=objs.indexOf('Pool');
                        var siteName=objs[ind+1];
                        var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': siteName});
                        record[i].context[1]={"owner": "DNAC", "contextKey": "siteId", "contextValue": siteObj[0].id};
                        ipPoolObj.ipPools.push(record[i]);
                        if(poolname.indexOf("Lan")>=0){
                            ipPoolObj['type'] = "lan"
                        }else if(poolname.indexOf("Wan")>=0) {
                            ipPoolObj['type'] = "wan";
                        }else if(poolname.indexOf("Man")>=0) {
                            ipPoolObj['type'] = "management";
                        }else if(poolname.indexOf("Service")>=0) {
                            ipPoolObj['type'] = "service";
                        }else{
                            ipPoolObj['type']='generic'
                        }
                    }
                }else{
                    var poolname=record[i].ipPoolName;
                    var objs=poolname.split('_');
                    var ind=objs.indexOf('Pool');
                    var siteName=objs[ind+1];
                    var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': siteName});
                    record[i].context[1]={"owner": "DNAC", "contextKey": "siteId", "contextValue": siteObj[0].id};
                    ipPoolObj.ipPools.push(record[i]);
                    if(poolname.indexOf("Lan")>=0){
                        ipPoolObj['type'] = "lan"
                    }else if(poolname.indexOf("Wan")>=0) {
                        ipPoolObj['type'] = "wan";
                    }else if(poolname.indexOf("Man")>=0) {
                        ipPoolObj['type'] = "management";
                    }else if(poolname.indexOf("Service")>=0) {
                        ipPoolObj['type'] = "service";
                    }else{
                        ipPoolObj['type']='generic'
                    }
                }
                ipPoolObj['siteHierarchy']=siteObj[0].groupNameHierarchy;
                ipPoolObj['siteId']=siteObj[0].id;
                
                ipPoolArr.push(ipPoolObj);
            }
            var ipPoolFilterArr = []
            if(urlAction.filter.type){
                var filterType = urlAction.filter.type
                filterType = filterType.replace(/[^\w\s]/gi, '')
                for(var k=0; k<ipPoolArr.length; k++){
                    if(filterType === ipPoolArr[k].type){
                        ipPoolFilterArr.push(ipPoolArr[k])
                    }
                }
                return ipPoolFilterArr
            }
            if(urlAction.filter.groupName){
                var filterString = urlAction.filter.groupName
                filterString = filterString.replace(/[^\w\s]/gi, '')
                for(var k=0; k<ipPoolArr.length; k++){
                    if(ipPoolArr[k].groupName.indexOf(filterString) === 0){
                        ipPoolFilterArr.push(ipPoolArr[k])
                    }
                }
                return ipPoolFilterArr
            }

        return ipPoolArr
        }else if(urlAction.service.indexOf('group')>=0){
           var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'parentUuid': "root"});
           var ipPoolArr=[],ipPoolObj={};
           for(var i=0;i<record.length;i++){
            ipPoolObj={};
            ipPoolObj["id"]=record[i].id;
            ipPoolObj["groupName"]= record[i].ipPoolName;
            ipPoolObj["ipPools"]=[];
            record[i].context=[];
            record[i].context[0]={"owner": "DNAC", "contextKey": "reserved_by", "contextValue": "DNAC"}
            var poolname=record[i].ipPoolName;
            var objs=poolname.split('_');
            var ind=objs.indexOf('Pool');
            var siteName=objs[ind+1];
            var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': siteName});
            record[i].context[1]={"owner": "DNAC", "contextKey": "siteId", "contextValue": siteObj[0].id}
            ipPoolObj.ipPools.push(record[i]);
            ipPoolObj['siteHierarchy']=siteObj[0].groupNameHierarchy;
            ipPoolObj['siteId']=siteObj[0].id;
            ipPoolArr.push(ipPoolObj);      
            }
           return ipPoolArr;
        }else if(urlAction.service.indexOf('ippool') >=0 && urlAction.filter.indexOf('ipPoolName')>=0 && urlAction.filter.indexOf('ipPoolCidr')>=0){
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{'$and':[{['ipPoolName']:{'$regex':urlAction.filter['ipPoolName']}},{['ipPoolCidr']:{'$regex':urlAction.filter['ipPoolCidr']}},
        ]})
            return record
        }else if(urlAction.service.indexOf('ippool') >=0 && urlAction.filter.indexOf('ipPoolName')>=0){
           var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolName']:{'$regex':urlAction.filter['ipPoolName']}})
           return record
        }else if(urlAction.service.indexOf('ippool') >=0 && urlAction.filter.indexOf('ipPoolCidr')>=0){
            if(urlAction.filter.indexOf('id') >=0){
                record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{'id':urlAction.filter['id']});
                return record
            }
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{['ipPoolCidr']:{'$regex':urlAction.filter['ipPoolCidr']}})
            return record
        }
        else{
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'parentUuid': "root"});
            return record;
        }
    }

    function processRequest(urlAction) {
        return SimIpPoolData.processIpPool(urlAction);
    }

});
