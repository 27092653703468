define({
    "Scheduled_Job_Template": [
        {
            "startTime": -1,
            "externalSchedule": {
              "notificationURL": "/api/v2/data/customer-facing-service/eta",
              "notificationMethod": "POST",
              "notificationBody": [
                {
                  "name": "Enable_6bb07e6c-e6b5-4d68-b7fb-666b43d757e7",
                  "deviceIds": [
                    "16732659-4d39-4108-9dac-7fd3a75e52af"
                  ],
                  "etaEnabled": true,
                  "namespace": "eta_6bb07e6c-e6b5-4d68-b7fb-666b43d757e7",
                  "siteId": "6bb07e6c-e6b5-4d68-b7fb-666b43d757e7"
                }
              ],
              "notificationHeader": {
                "Content-Type": "application/json",
                "SchedulingHook_Key_ServiceName": "spf-service-manager-service"
              },
              "module": "SSA",
              "username": "admin"
            },
            "timeZone": "Asia/Calcutta",
            "endTime": 0,
            "repeatInterval": 0,
            "repeatCount": 0,
            "description": "North America - Enable",
            "taskId": "0f2400b4-ec95-41f2-9b5e-9810471679c3",
            "id": "556a89b5-a23d-4184-a585-c9c80f22550f",
            "source": "EXTERNAL",
            "triggeredJobs": [
              {
                "id": "cb150303-f1f7-4f4d-88bd-a7af7ff7111d",
                "triggeredJobTaskId": "797e37e7-1ac2-4621-8657-194da9ada399",
                "triggeredTime": 1559824019771,
                "status": "COMPLETED",
                "triggeredJobId": "cb150303-f1f7-4f4d-88bd-a7af7ff7111d"
              }
            ],
            "scheduledDevices": [
              {
                "id": "2c1c98d9-b82e-4ac0-8cb3-95a8f09cd39f",
                "deviceId": "16732659-4d39-4108-9dac-7fd3a75e52af",
                "hostname": "CAT9300-SSA-ETA.cisco.local",
                "managementIpAddress": "10.16.36.3"
              }
            ],
            "scheduleState": "COMPLETED",
            "prevTriggerTime": 0,
            "nextTriggerTime": 0,
            "jobsRunning": 0,
            "jobsCompleted": 1,
            "jobsFailed": 0,
            "totalJobs": 1,
            "createTime": 1559824018965,
            "lastUpdateTime": 1559824073695,
            "scheduledId": "556a89b5-a23d-4184-a585-c9c80f22550f"
          }
        ],
      "Schedule_Job_Template_2":{
          "SSA": "SSA",
          "UMBRELLA": "UMBRELLA",
          "GLOBAL_CREDENTIAL_UPDATE": "GLOBAL CREDENTIAL UPDATE",
          "OS_UPDATE": "OS UPDATE",
          "NFV_PROVISIONING": "NFV PROVISIONING",
          "APP_POLICY": "APPLICATION POLICY",
          "PROVISION": "PROVISION",
          "DISCOVERY": "DISCOVERY",
          "DEVICE_REPLACEMENT": "DEVICE REPLACEMENT",
          "SECURITY_ADVISORIES": "SECURITY ADVISORIES",
          "IP_ACCESS_POLICY": "IP ACCESS POLICY",
          "DEVICE_CONTROLLABILITY_AND_TELEMETRY": "DEVICE CONTROLLABILITY AND TELEMETRY",
          "ROUTER_PROVISIONING": "ROUTER PROVISIONING",
          "LICENSE": "LICENSE"
        },

    "Scheduled_Job_Template_Extras" : {
        "endTime": 0,
        "externalSchedule": {
            "type": "DEFAULT"
        },
        "id": "18081d4f-56f7-44e5-aebb-67b9f69b98c1",
        "jobsCompleted": 1,
        "jobsFailed": 0,
        "jobsRunning": 0,
        "lastUpdateTime": 1602743380819,
        "nextTriggerTime": 0,
        "paramNamesAndValues": [],
        "prevTriggerTime": 0,
        "recurring": false,
        "repeatCount": 0,
        "repeatInterval": 0,
        "scheduledId": "18081d4f-56f7-44e5-aebb-67b9f69b98c1",
        "scheduleState": "COMPLETED",
        "source": "EXTERNAL",
        "taskId": "01bf6bec-d1d8-4106-b7ba-8e79b48b82cc",
        "totalJobs": 1,
        "triggeredJobs": [
            {
                "id": "73ab9a6d-b519-4c15-aa41-0a5bcb05641f",
                "status": "COMPLETED",
                "triggeredJobId": "73ab9a6d-b519-4c15-aa41-0a5bcb05641f",
                "triggeredJobTaskId": "ab8acb8a-ec0c-40db-b435-021f0e3baf3d",
                "triggeredTime": 1602741935905
            }
        ]
    },
    "Schedule_Job_Template_3":
    {
      "response": [{
      "startTime": 1620691200000,
      "externalSchedule": {
      "notificationURL": "/api/v1/security-advisory/inference/psirt",
      "notificationMethod": "POST",
      "notificationHeader": {},
      "module": "SECURITY_ADVISORIES",
      "type": "DEFAULT",
      "username": "SYSTEM"
      },
      "timeZone": "Etc/UTC",
      "endTime": 0,
      "repeatInterval": 604800000,
      "repeatCount": -1,
      "recurring": true,
      "description": "Scan network for security advisories",
      "operation": "Schedule Regular Security Scan",
      "origin": "Reasoner Service: PSIRT-System Scheduled Job",
      "paramNamesAndValues": {
      "editable": "false"
      },
      "taskId": "11c4a2d4-cf68-45ec-8a11-3732c6c8c602",
      "id": "6f4cd784-6cc5-4b46-bf28-896cc09f6343",
      "source": "EXTERNAL",
      "triggeredJobs": [{
      "id": "da863a9d-7275-426f-9f54-ed5b3801e0b1",
      "triggeredJobTaskId": "f5c5be5e-c44b-474f-bafd-9dc1e202ae44",
      "triggeredTime": 1633996802892,
      "status": "COMPLETED",
      "triggeredJobId": "da863a9d-7275-426f-9f54-ed5b3801e0b1"
      }, {
      "id": "ccfda31a-d03c-40b5-867f-022a331104f1",
      "triggeredJobTaskId": "f9388467-a2ae-4725-8397-a390839e4952",
      "triggeredTime": 1624320000311,
      "status": "COMPLETED",
      "triggeredJobId": "ccfda31a-d03c-40b5-867f-022a331104f1"
      }, {
      "id": "91ade96f-22d4-48fc-b359-2a76b0d1736a",
      "triggeredJobTaskId": "33329218-1edc-41ac-b7ec-8330f4ec2721",
      "triggeredTime": 1644278400127,
      "status": "COMPLETED",
      "triggeredJobId": "91ade96f-22d4-48fc-b359-2a76b0d1736a"
      }, {
      "id": "e364f7a3-a1c8-43c4-b610-d88e6aadec35",
      "triggeredJobTaskId": "bd146d93-16a9-476a-b170-b7568c65f726",
      "triggeredTime": 1629763200173,
      "status": "COMPLETED",
      "triggeredJobId": "e364f7a3-a1c8-43c4-b610-d88e6aadec35"
      }, {
      "id": "7fca9abd-d5bd-4690-9679-112dfaeed881",
      "triggeredJobTaskId": "ad789343-93ad-4fa9-ad35-b3268c84489c",
      "triggeredTime": 1650931201909,
      "status": "COMPLETED",
      "triggeredJobId": "7fca9abd-d5bd-4690-9679-112dfaeed881"
      }, {
      "id": "714a7543-4d29-447b-bdce-4daf3dde36a0",
      "triggeredJobTaskId": "82939194-01ab-4f05-8285-0eac35463779",
      "triggeredTime": 1623110402157,
      "status": "COMPLETED",
      "triggeredJobId": "714a7543-4d29-447b-bdce-4daf3dde36a0"
      }, {
      "id": "e5bae775-3080-4c88-8e2f-02bae0489721",
      "triggeredJobTaskId": "0eb47f92-7d39-42b4-964b-5924e3929317",
      "triggeredTime": 1625529600171,
      "status": "COMPLETED",
      "triggeredJobId": "e5bae775-3080-4c88-8e2f-02bae0489721"
      }, {
      "id": "63867505-c920-4640-8e54-9aad18fb0bb9",
      "triggeredJobTaskId": "40c7932a-8f9e-454a-a44b-ee75f0ddd703",
      "triggeredTime": 1643068800716,
      "status": "COMPLETED",
      "triggeredJobId": "63867505-c920-4640-8e54-9aad18fb0bb9"
      }, {
      "id": "7a5b83fc-4542-4036-9ced-c3628be02afe",
      "triggeredJobTaskId": "76e86fa4-0af8-48b5-85e2-c0eeea64e7a5",
      "triggeredTime": 1643673600637,
      "status": "COMPLETED",
      "triggeredJobId": "7a5b83fc-4542-4036-9ced-c3628be02afe"
      }, {
      "id": "4a6e96c8-1f16-4683-9a11-eb1482f109d6",
      "triggeredJobTaskId": "5ccef8ba-bf9f-4c73-8085-e4274eb90639",
      "triggeredTime": 1651536000209,
      "status": "COMPLETED",
      "triggeredJobId": "4a6e96c8-1f16-4683-9a11-eb1482f109d6"
      }, {
      "id": "fe03ed70-2ab2-416d-b5be-50675cf47fb6",
      "triggeredJobTaskId": "6814c810-e9ec-4ee8-8fe1-5d3608c5b97a",
      "triggeredTime": 1644883200187,
      "status": "COMPLETED",
      "triggeredJobId": "fe03ed70-2ab2-416d-b5be-50675cf47fb6"
      }, {
      "id": "aaf68f21-a303-4c2d-9c05-324894a80376",
      "triggeredJobTaskId": "7a4c23ea-7efb-4abd-95b3-65fb2a891fe6",
      "triggeredTime": 1652745600174,
      "status": "COMPLETED",
      "triggeredJobId": "aaf68f21-a303-4c2d-9c05-324894a80376"
      }, {
      "id": "050bf116-6cd3-49ad-832c-82b93ec00547",
      "triggeredJobTaskId": "a6d4ce9e-5ae4-4d47-8b95-c935fd1b6961",
      "triggeredTime": 1642464003287,
      "status": "COMPLETED",
      "triggeredJobId": "050bf116-6cd3-49ad-832c-82b93ec00547"
      }, {
      "id": "762358f8-251b-44cd-8b12-a22cf7cce0ae",
      "triggeredJobTaskId": "8450bf03-8f3d-4519-8da2-2a3c0a54bd40",
      "triggeredTime": 1621296000141,
      "status": "COMPLETED",
      "triggeredJobId": "762358f8-251b-44cd-8b12-a22cf7cce0ae"
      }, {
      "id": "3d8f2ccd-3650-48c6-91a9-ca8015c838c3",
      "triggeredJobTaskId": "458f3039-6af1-4d44-9c8b-b6d95ad1db97",
      "triggeredTime": 1646697600197,
      "status": "COMPLETED",
      "triggeredJobId": "3d8f2ccd-3650-48c6-91a9-ca8015c838c3"
      }, {
      "id": "00b58491-53a2-49b4-a67a-3b5560deb7b1",
      "triggeredJobTaskId": "3ec4a512-031e-4a7d-a950-7885c7ef8f78",
      "triggeredTime": 1622505600141,
      "status": "COMPLETED",
      "triggeredJobId": "00b58491-53a2-49b4-a67a-3b5560deb7b1"
      }, {
      "id": "8e886a0b-4c67-47b0-9002-7fe64efe574f",
      "triggeredJobTaskId": "4a401d97-93dc-48e0-a9e8-d861d5ebfd6d",
      "triggeredTime": 1645488000125,
      "status": "COMPLETED",
      "triggeredJobId": "8e886a0b-4c67-47b0-9002-7fe64efe574f"
      }, {
      "id": "00eb0f3a-94e5-4d3b-8c36-a512731f2daa",
      "triggeredJobTaskId": "5118c52f-4967-4b54-9077-65191bf351cd",
      "triggeredTime": 1653350400125,
      "status": "COMPLETED",
      "triggeredJobId": "00eb0f3a-94e5-4d3b-8c36-a512731f2daa"
      }, {
      "id": "ccd7c0aa-9af9-418e-9aae-7196defeeb27",
      "triggeredJobTaskId": "b705ff15-f069-42dd-ba8c-9205981fe48a",
      "triggeredTime": 1630368000191,
      "status": "COMPLETED",
      "triggeredJobId": "ccd7c0aa-9af9-418e-9aae-7196defeeb27"
      }, {
      "id": "3d1be112-6968-4e5d-a452-12092b1ed285",
      "triggeredJobTaskId": "2557d314-96f1-4c04-a2a3-9b2d9a3919f2",
      "triggeredTime": 1646092800156,
      "status": "COMPLETED",
      "triggeredJobId": "3d1be112-6968-4e5d-a452-12092b1ed285"
      }, {
      "id": "7be86ae6-8747-4f03-82f4-99f46aa04450",
      "triggeredJobTaskId": "81910562-d303-4d69-8cc0-2981708fe869",
      "triggeredTime": 1652140800142,
      "status": "COMPLETED",
      "triggeredJobId": "7be86ae6-8747-4f03-82f4-99f46aa04450"
      }, {
      "id": "b7480a58-89da-4b9b-9842-41f12127212f",
      "triggeredJobTaskId": "1adbf310-0d7f-4356-a8d8-d76043518917",
      "triggeredTime": 1647302400217,
      "status": "COMPLETED",
      "triggeredJobId": "b7480a58-89da-4b9b-9842-41f12127212f"
      }, {
      "id": "1cf190e1-4bc8-4528-aa81-645ce3b08034",
      "triggeredJobTaskId": "b92598a9-d9cc-45e1-a484-33e978266170",
      "triggeredTime": 1634601600880,
      "status": "COMPLETED",
      "triggeredJobId": "1cf190e1-4bc8-4528-aa81-645ce3b08034"
      }, {
      "id": "cc072a5f-e8cb-4fe1-a973-9c5bf0fff0ea",
      "triggeredJobTaskId": "2aa3bdc4-3f4a-42b5-84a9-fd49ff13e9cf",
      "triggeredTime": 1621900800292,
      "status": "COMPLETED",
      "triggeredJobId": "cc072a5f-e8cb-4fe1-a973-9c5bf0fff0ea"
      }, {
      "id": "e71da3cb-2064-43c9-9356-51f61eac64b9",
      "triggeredJobTaskId": "0bbff4ee-ef21-4597-aeaa-931a138dd879",
      "triggeredTime": 1637020800121,
      "status": "COMPLETED",
      "triggeredJobId": "e71da3cb-2064-43c9-9356-51f61eac64b9"
      }, {
      "id": "e23003fa-5706-4909-8901-f7afb831d1a9",
      "triggeredJobTaskId": "32315ccb-5cf2-4dfc-86f5-cafdb415062c",
      "triggeredTime": 1647907200288,
      "status": "COMPLETED",
      "triggeredJobId": "e23003fa-5706-4909-8901-f7afb831d1a9"
      }, {
      "id": "6901a225-e2e4-41c3-9561-87dd627934e0",
      "triggeredJobTaskId": "d8a9ce00-4160-48ed-be76-f29c93e17f69",
      "triggeredTime": 1623715201528,
      "status": "COMPLETED",
      "triggeredJobId": "6901a225-e2e4-41c3-9561-87dd627934e0"
      }, {
      "id": "3f615573-02be-4253-a169-2dd4950a7354",
      "triggeredJobTaskId": "e859ce45-a0e9-4086-a39a-79ecd86f956f",
      "triggeredTime": 1649721600123,
      "status": "COMPLETED",
      "triggeredJobId": "3f615573-02be-4253-a169-2dd4950a7354"
      }, {
      "id": "ee88fd04-5a75-4939-8eeb-2ffd7d79bd4a",
      "triggeredJobTaskId": "66c1b0ab-a66f-407a-94f0-822febe78b79",
      "triggeredTime": 1650326400128,
      "status": "COMPLETED",
      "triggeredJobId": "ee88fd04-5a75-4939-8eeb-2ffd7d79bd4a"
      }, {
      "id": "7bc70552-9009-4734-b312-9c60be1eddd1",
      "triggeredJobTaskId": "ba9aac96-22e3-4e8a-986b-7a64b4d51fa7",
      "triggeredTime": 1648512000211,
      "status": "COMPLETED",
      "triggeredJobId": "7bc70552-9009-4734-b312-9c60be1eddd1"
      }, {
      "id": "637bdacb-cb23-48ca-8e8e-fb52b7b56061",
      "triggeredJobTaskId": "ad333f90-0910-49f1-a362-08b513d6b04a",
      "triggeredTime": 1649116801068,
      "status": "COMPLETED",
      "triggeredJobId": "637bdacb-cb23-48ca-8e8e-fb52b7b56061"
      }, {
      "id": "882d6a85-2adb-4fe5-bf9f-1c8aaf3d0631",
      "triggeredJobTaskId": "98c312a5-bd59-48a8-92f1-ed2a539afa19",
      "triggeredTime": 1624924800446,
      "status": "COMPLETED",
      "triggeredJobId": "882d6a85-2adb-4fe5-bf9f-1c8aaf3d0631"
      }, {
      "id": "0873c39b-a482-4208-873b-a780fe900d91",
      "triggeredJobTaskId": "7fc72e14-fb23-4dc7-aafe-67c8a6aea41f",
      "triggeredTime": 1630972800166,
      "status": "COMPLETED",
      "triggeredJobId": "0873c39b-a482-4208-873b-a780fe900d91"
      }, {
      "id": "105cf486-0ecc-4ec6-9965-efccffa02c59",
      "triggeredJobTaskId": "3a4cdf0c-e803-4566-a782-9ec4ea179734",
      "triggeredTime": 1620691202677,
      "status": "COMPLETED",
      "triggeredJobId": "105cf486-0ecc-4ec6-9965-efccffa02c59"
      }, {
      "id": "c8571f24-6238-4dbd-87dc-718c00440d8e",
      "triggeredJobTaskId": "a261a215-3283-459b-94d7-a74e471bde0a",
      "triggeredTime": 1631577600421,
      "status": "COMPLETED",
      "triggeredJobId": "c8571f24-6238-4dbd-87dc-718c00440d8e"
      }, {
      "id": "7f23bea2-3847-4235-96cf-15411bcd85df",
      "triggeredJobTaskId": "7f5f46b9-0fc2-4c2c-b930-3f41d2632436",
      "triggeredTime": 1626134400241,
      "status": "COMPLETED",
      "triggeredJobId": "7f23bea2-3847-4235-96cf-15411bcd85df"
      }, {
      "id": "126675d6-47ec-4e56-8b89-ddddf794ade6",
      "triggeredJobTaskId": "e84812bc-f18b-4970-b740-c8cf3b90e865",
      "triggeredTime": 1637625600162,
      "status": "COMPLETED",
      "triggeredJobId": "126675d6-47ec-4e56-8b89-ddddf794ade6"
      }, {
      "id": "b45613f9-2c51-4463-8e95-3a3743b6dba0",
      "triggeredJobTaskId": "20d74f0d-51ba-4246-81a1-8d275722b60d",
      "triggeredTime": 1640044800321,
      "status": "COMPLETED",
      "triggeredJobId": "b45613f9-2c51-4463-8e95-3a3743b6dba0"
      }, {
      "id": "2d38b9a2-551d-4975-b515-f58b0b1dcc17",
      "triggeredJobTaskId": "5333448a-cb96-49c5-af48-0681aae0fa1b",
      "triggeredTime": 1632182400274,
      "status": "COMPLETED",
      "triggeredJobId": "2d38b9a2-551d-4975-b515-f58b0b1dcc17"
      }, {
      "id": "dcc739ee-9cbc-491a-bdff-61462e65c49f",
      "triggeredJobTaskId": "8a3868e6-786e-4524-b050-312c1c6f4361",
      "triggeredTime": 1635206400231,
      "status": "COMPLETED",
      "triggeredJobId": "dcc739ee-9cbc-491a-bdff-61462e65c49f"
      }, {
      "id": "2f4acb18-9cef-4231-9278-1961fc6d7b59",
      "triggeredJobTaskId": "5c1404a9-39b8-408b-8e1c-84e46ec8c226",
      "triggeredTime": 1638230400122,
      "status": "COMPLETED",
      "triggeredJobId": "2f4acb18-9cef-4231-9278-1961fc6d7b59"
      }, {
      "id": "22128e03-1094-45f5-a5c2-48c42cc86f79",
      "triggeredJobTaskId": "d2818144-f234-47e9-952b-3e42b08ccc9a",
      "triggeredTime": 1626739200179,
      "status": "COMPLETED",
      "triggeredJobId": "22128e03-1094-45f5-a5c2-48c42cc86f79"
      }, {
      "id": "f0f08e85-0f5a-4f72-827d-0fd17831da6e",
      "triggeredJobTaskId": "9e2c7845-fc77-463f-a60a-62ac433070e5",
      "triggeredTime": 1635811200241,
      "status": "COMPLETED",
      "triggeredJobId": "f0f08e85-0f5a-4f72-827d-0fd17831da6e"
      }, {
      "id": "374a249b-6886-49fe-a4ba-eea23fb59dd9",
      "triggeredJobTaskId": "28b44744-dee6-4022-b967-418d3ac1ae5a",
      "triggeredTime": 1632787200219,
      "status": "COMPLETED",
      "triggeredJobId": "374a249b-6886-49fe-a4ba-eea23fb59dd9"
      }, {
      "id": "93827d09-29da-45f1-9f11-2ecd0bd91b3d",
      "triggeredJobTaskId": "c8232be7-6f4b-4b92-895e-3751f64b9482",
      "triggeredTime": 1640649600128,
      "status": "COMPLETED",
      "triggeredJobId": "93827d09-29da-45f1-9f11-2ecd0bd91b3d"
      }, {
      "id": "71084ca6-af76-443c-9a7c-aad9cf182c5e",
      "triggeredJobTaskId": "d0081410-2b9a-444f-a092-f35805b90ff7",
      "triggeredTime": 1627344000152,
      "status": "COMPLETED",
      "triggeredJobId": "71084ca6-af76-443c-9a7c-aad9cf182c5e"
      }, {
      "id": "0853e432-4d06-451a-99a0-7bfe3115f3b2",
      "triggeredJobTaskId": "72d55585-f601-40eb-b332-f2013d9ae632",
      "triggeredTime": 1638835200353,
      "status": "COMPLETED",
      "triggeredJobId": "0853e432-4d06-451a-99a0-7bfe3115f3b2"
      }, {
      "id": "cec4a233-08f4-4a56-80f3-49bbc86a7de1",
      "triggeredJobTaskId": "6acb8ae8-b8c1-491e-9439-053e647a4ef8",
      "triggeredTime": 1633392000251,
      "status": "COMPLETED",
      "triggeredJobId": "cec4a233-08f4-4a56-80f3-49bbc86a7de1"
      }, {
      "id": "481a5e40-9ff5-4f21-828a-2376cfbddc28",
      "triggeredJobTaskId": "dcbba230-e2ff-4fd7-aa48-eee1e4dd24b8",
      "triggeredTime": 1627948800194,
      "status": "COMPLETED",
      "triggeredJobId": "481a5e40-9ff5-4f21-828a-2376cfbddc28"
      }, {
      "id": "e2c8076b-509f-4662-8c9b-2029f7bd08c1",
      "triggeredJobTaskId": "8223ad09-dbe9-404b-a207-ae9783123045",
      "triggeredTime": 1641254400217,
      "status": "COMPLETED",
      "triggeredJobId": "e2c8076b-509f-4662-8c9b-2029f7bd08c1"
      }, {
      "id": "95bab9eb-3571-44e6-a6f6-9f78309e9646",
      "triggeredJobTaskId": "6930290e-dd5d-47d7-a7a9-5a86bc05595e",
      "triggeredTime": 1636416000209,
      "status": "COMPLETED",
      "triggeredJobId": "95bab9eb-3571-44e6-a6f6-9f78309e9646"
      }, {
      "id": "406b83b1-a75c-4e30-a2fb-9f790fb01f0a",
      "triggeredJobTaskId": "9fbbb05f-6bcb-429d-bd05-8f7938e9861d",
      "triggeredTime": 1628553602859,
      "status": "COMPLETED",
      "triggeredJobId": "406b83b1-a75c-4e30-a2fb-9f790fb01f0a"
      }, {
      "id": "57bb72b4-bf79-4c07-a422-dc231138d9c2",
      "triggeredJobTaskId": "395cce37-77b4-43ee-bbbb-d0ca5487c881",
      "triggeredTime": 1639440000116,
      "status": "COMPLETED",
      "triggeredJobId": "57bb72b4-bf79-4c07-a422-dc231138d9c2"
      }, {
      "id": "8a68db95-9cc4-4344-85e9-871ecb12bb87",
      "triggeredJobTaskId": "727d8812-d907-4092-af16-031a66eaed0b",
      "triggeredTime": 1629158400186,
      "status": "COMPLETED",
      "triggeredJobId": "8a68db95-9cc4-4344-85e9-871ecb12bb87"
      }],
      "scheduledDevices": [],
      "scheduleState": "ACTIVE",
      "prevTriggerTime": 1653350400000,
      "nextTriggerTime": 1653955200000,
      "jobsRunning": 0,
      "jobsCompleted": 54,
      "jobsFailed": 0,
      "totalJobs": 54,
      "createTime": 1620631504291,
      "lastUpdateTime": 1653350400125,
      "scheduledId": "6f4cd784-6cc5-4b46-bf28-896cc09f6343"
      }],
      "version": "1.0"
      }
      
})