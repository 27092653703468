define({
  "Template": {
  "version": "1.0",
  "response": [
  {
    "id": "AWjGqcDZsIPgh8_7RLKs",
    "name": "wireless_client_excess_auth_client_timeout_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entity": "5C:5F:67:CD:D3:C7",
    "groupBy": "2572a60e-c5d0-4e9a-a332-28881db6bdc8",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless clients took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - Excessive time due to RF issues",
    "scope": "LOCALIZED",
    "priority": null,
    "rootCause": "EXCESSIVE_AUTH_FAIL_CLIENT",
    "timestamp": 1549521173197,
    "description": "This client is taking longer than expected time to connect to SSID 'SSID@ToChange' due to excessive authentication time.<ul><li>Onboarding took 11.7 seconds (expected time should be less than 10.0 seconds).</li><li>802.11 Association took less than a second (expected time should be less than 2.0 seconds).</li><li>Authentication and Key Exchange took 7.1 seconds (expected time should be less than 3.0 seconds)</li><li>IP Addressing took 0 seconds (expected time should be less than 5.0 seconds)</li><li>This client tried to onboard 2 times which took 4.6 seconds </li></ul><br>The authentication delay is because the client is slow to respond to authentication messages.The client was connecting to SSID 'SSID@ToChange' on  Band@ToChange GHz radio on AP 'AP@ToChange' in 'Site@ToChange'. The AP was connected to WLC 'Wlc@ToChange'.",
    "suggestions": [
      {
        "message": "Check whether the client moved during the authentication phase, since a moving client may cause packet losses and retries.",
        "steps": [

        ]
      },
      {
        "message": "Check for a recent OS update, since this may have been fixed in an update.",
        "steps": [

        ]
      },
      {
        "message": "Check the RF conditions at the client location. Since bad RF condition will leads to packet losses and higher retries.",
        "steps": [

        ]
      },
      {
        "message": "Check if fragmentation is occurring in the network, since this may lead to additional processing delays.",
        "steps": [

        ]
      },
      {
        "message": "Check WLC EAP Identity request timeout as the client may have not responded to this request in time.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "Interface:5C:5F:67:CD:D3:C7:5C:5F:67:CD:D3:C7:EXCESSIVE_AUTH_FAIL_CLIENT:2572a60e-c5d0-4e9a-a332-28881db6bdc8:true"
      },
      {
        "key": "hostName",
        "value": "19FUKU0MF7TUR"
      },
      {
        "key": "groupType",
        "value": "Site"
      },
      {
        "key": "assocTime",
        "value": "1"
      },
      {
        "key": "authTime",
        "value": "7055"
      },
      {
        "key": "groupBy",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "slot",
        "value": "1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_4_WAY_KEY_TIMEOUT"
      },
      {
        "key": "previousApName",
        "value": "AP4800-8DCE"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP4800.8DAC"
      },
      {
        "key": "frequency",
        "value": "5.0"
      },
      {
        "key": "reasonCodes",
        "value": "2,122,0,103,0,0,1,0,0,1,0,0,0,0,89,0,0,233"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "EXCESSIVE_AUTH_FAIL_CLIENT"
      },
      {
        "key": "aaaServerIp",
        "value": ""
      },
      {
        "key": "resultType",
        "value": "cl_FAIL"
      },
      {
        "key": "__suppression__",
        "value": "0"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "isRoaming",
        "value": "true"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "11686"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "iCap_AP"
      },
      {
        "key": "dhcpServerIp",
        "value": "10.13.4.1"
      },
      {
        "key": "apMac",
        "value": "F4:DB:E6:86:F1:E0"
      },
      {
        "key": "entityId",
        "value": "5C:5F:67:CD:D3:C7"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_L2KEY_DONE"
      },
      {
        "key": "priority",
        "value": "P4"
      },
      {
        "key": "eventTypes",
        "value": "1,2,13,5,14,3,1,2,3,1,2,3,12,3,4,5,9,7"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "osCategory",
        "value": "Windows"
      },
      {
        "key": "deviceCategory",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "eventTimestamps",
        "value": "1549521166141,1,3847,3208,0,105,3405,0,7,845,1,4,71,0,161,31,0,35"
      },
      {
        "key": "resultTypes",
        "value": "0,0,0,5,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
      },
      {
        "key": "siteId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "_id",
        "value": "5C:5F:67:CD:D3:C7"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-07T06:32:53.197+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "5C:5F:67:CD:D3:C7"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "SAJO/SJ23/F1"
  }
 ]
}
})