define({
    "Assurance_NetworkDevice_Template": {
      page: {
        pageSize: 500,
        offset: 1,
        totalCount: 0
      },
      response: [], 
      version: "1.0"
    }
});
