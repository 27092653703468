//asr1000rp1 Cisco ASR 1004 Router
define({

"runningImageList":[  
    {  
       "name":"asr1000rp1-adventerprisek9.03.16.07.S.155-3.S7-ext.bi",
       "imageUuid":"051f0264-d32b-4fe0-9c02-3d5eab03296d",
       "version":"03.16.07.S",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"asr1000rp1",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  
       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":true,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"asr1000rp1-adventerprisek9.03.17.04.S.156-1.S4-std.b",
       "imageUuid":"afc318e2-1ff3-429a-ad5d-a4ab9c76c60c",
       "version":"03.17.04.S",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"asr1000rp1",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":true,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    }
 ]
});