define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWKbiSmxUVFRaeYF-MUm",
        "name": "wireless_client_onboarding",
        "entityType": "Client",
        "entity": "B8:27:EB:D8:BB:5E",
        "groupBy": "192.168.139.168",
        "category": "Onboarding",
        "severity": "HIGH",
        "priority": "P1",
        "summary": "Wireless client took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - Excessive time for Authentication due to AAA server or Network delays",
        "scope": "GLOBAL",
        "rootCause": "EXCESSIVE_AUTH_SERVER",
        "timestamp": 1523027792010,
        "description": "This client is taking longer than expected time to connect to 'SSID@ToChange' SSID due to excessive authentication time.<ul><li>Onboarding took 19.9 seconds (expected time should be less than 10.0 seconds).</li><li>Authentication and Key Exchange took 3.9 seconds (expected time should be less than 3.0 seconds).</li></ul><br>The reason for the longer authentication time is delays from the AAA server or the network.The client itself is responding in time.The client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP in 'Site@ToChange'. The AP was connected to Wlc@ToChange WLC.",
        "suggestions": [
          {
            "message": "Verify the network connectivity to the AAA server. Since there may be network glitch causing retries that leads to texcessive onboarding times.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the AAA server is responding to requests.",
            "steps": [

            ]
          },
          {
            "message": "Check the network load between the AP and the WLC, and between the WLC and the AAA server. Since excessive traffic can cause additional delays.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "assocTime",
            "value": "0"
          },
          {
            "key": "type",
            "value": "issue"
          },
          {
            "key": "reasonType",
            "value": "cl_NO_REASON"
          },
          {
            "key": "previousApName",
            "value": "LA2-AP3702-39"
          },
          {
            "key": "apName",
            "value": "LA2-AP1815-33"
          },
          {
            "key": "frequency",
            "value": "2.4"
          },
          {
            "key": "reasonCodes",
            "value": "13,89,103,0,1,0,0,0,0,89,0,0"
          },
          {
            "key": "hostType",
            "value": "WIRELESS"
          },
          {
            "key": "rootCause",
            "value": "EXCESSIVE_AUTH_SERVER"
          },
          {
            "key": "resultType",
            "value": "cl_SUCCESS"
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "totalTime",
            "value": "19935"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "apMac",
            "value": "04:DA:D2:91:F6:C0"
          },
          {
            "key": "eventType",
            "value": "PLACEHOLDER_FOR_INDEX_0"
          },
          {
            "key": "wlcName",
            "value": "LA1-WLC5520-3"
          },
          {
            "key": "resultTypes",
            "value": "0,0,5,0,0,0,0,0,0,0,0,0"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "hostName",
            "value": "Grace.Smith-PC"
          },
          {
            "key": "groupType",
            "value": "AAA Server"
          },
          {
            "key": "authTime",
            "value": "3903"
          },
          {
            "key": "groupBy",
            "value": "192.168.139.168"
          },
          {
            "key": "slot",
            "value": "0"
          },
          {
            "key": "ssid",
            "value": "LA-Corporate3"
          },
          {
            "key": "vlan",
            "value": "0"
          },
          {
            "key": "_id",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "tenantid",
            "value": "TNT0"
          },
          {
            "key": "aaaServerIp",
            "value": "192.168.139.168"
          },
          {
            "key": "dhcpTime",
            "value": "0"
          },
          {
            "key": "hostOSType",
            "value": "Linux-Workstation"
          },
          {
            "key": "isRoaming",
            "value": "false"
          },
          {
            "key": "previousWlcName",
            "value": ""
          },
          {
            "key": "apGroup",
            "value": "LA1"
          },
          {
            "key": "dhcpServerIp",
            "value": ""
          },
          {
            "key": "entityId",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "eventTypes",
            "value": "3,4,5,14,1,2,3,12,3,4,5,9"
          },
          {
            "key": "eventTimestamps",
            "value": "1523027772075,9150,3494,0,3388,0,2,2145,0,1696,60,0"
          },
          {
            "key": "siteId",
            "value": "f4fa7637-85d5-4301-9336-5c3d81c7c058"
          },
          {
            "key": "category",
            "value": "onboarding"
          },
          {
            "key": "macAddr",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "timestring",
            "value": "2018-04-06T15:16:32.010+0000"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": "USA/LA/Level21"
      }
    ]
  }
})