//CAT3K_CAA Cisco Catalyst 36xx stack-able ethernet switch
define({
    "runningImageList":[
    {  
       "name":"Install Mode (03.06.06E)",
       "imageUuid":"2388ee1b-f145-4d4e-93ab-f092eeea0aa0",
       "version":"03.06.06E",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[ 
       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (03.06.05E)",
       "imageUuid":"07076e3d-4ed7-47d4-9b63-6c6dbf4785fe",
       "version":"03.06.05E",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.6.2)",
       "imageUuid":"65fec3d4-650c-4403-87c5-25eb4404349e",
       "version":"16.6.2",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.6.1)",
       "imageUuid":"bda90ee9-99e6-431d-9add-425debb6ae29",
       "version":"16.6.1",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.3.3)",
       "imageUuid":"ebe29899-0942-4ceb-9750-e68cda2edf1a",
       "version":"16.3.3",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {
        "name": "cat3k_caa-universalk9.16.08.01a.SPA.bin",
        "imageUuid": "0d16556a-62ea-4a78-a68e-c70381d510c5",
        "version": "Fuji-16.8.1a",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "ce4dd8f684f11a6605c6194395f28487",
        "shaCheckSum": "18219691aebd6d54237924bb3f4f75ac2836a1fae3dc83bb37658fcb779d7832983753546d81e02c39e50e5b29f7ed1832a62bfae1a51b19c5d935cc453687cd",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 425928073,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "06/Apr/2018"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "425928073"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "286285449,286285439"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "5A6D9D8747B3840F964FBF8AF89C863DFD5F0819"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141220457"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3850-24XU-E VID: V03, SN: FOC2129U11V"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.16.06.04.SPA.bin",
        "imageUuid": "12503596-05cf-4102-9b6a-4cdba60b68a3",
        "version": "Everest-16.6.4",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "552e349473ae1f7752ed53d4843e43c0",
        "shaCheckSum": "3f0cea9a5a6278997d0cd59c02a8bb8037772ce55dffba6e8f9d879743928088c1b83a5221d9e27b4a1fcfcdd6dc075485a7494076547e06e12f257f05347285",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 410893739,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 2,
        "imageAttributes": [
          {
            "propertyName": "image_guid",
            "propertyValue": "ABD6BE21E0766347B613B784CB0EFDE1845F7E1C"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "410893739"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "09/Jul/2018"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.SPA.03.06.09.E.152-2.E9.bin",
        "imageUuid": "1c3f2156-a9e3-485f-a3a2-a991be1ab423",
        "version": "3.6.9E",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "2759381dbce1e95a05bda1769f6eafe2",
        "shaCheckSum": "78b4722817218488b83ce8b8eaeb530c96e1b5069d8353ad67818617143fa298955f6fe89f48ffd8edfc518705e7d6e92a64db1d66f4dbb002997a2b4c59d47d",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 304991176,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "image_size",
            "propertyValue": "304991176"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,284846122"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "11/Sep/2018"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "C96D9E9FF17C5102BF422ADF83079409B2A8BD70"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9.16.03.07.SPA.bin",
        "imageUuid": "2a4357aa-54ad-4231-95a1-dfd95d38b7b7",
        "version": "Denali-16.3.7",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "0c29b6f652d85c69c90b5c921a5a343b",
        "shaCheckSum": "d8f24e74544234e3ae2a7355b16a38573c33ad5456c885589acf5b985d511a764960eb554365babb40871989045942e89c6e53c55981923381530b2366814c11",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 537489817,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "image_guid",
            "propertyValue": "3D3FFA3434204AA3446AD575479A1881735A15A1"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "537489817"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "04/Aug/2018"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.16.09.01.SPA.bin",
        "imageUuid": "31c6115c-8739-4923-a033-40bac6ce514c",
        "version": "Fuji-16.9.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "09e988238256a95910485e97beb2b3c0",
        "shaCheckSum": "f42e47f0a66e92f2f97cb655396cfeaaa6f27a1ae28187aa1afcace3a41c2f4ad52aba816e6f2a5b4eeada57cdf212d2c41ed3848c5f67a07f05246b0d7d27be",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 453688608,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "18/Jul/2018"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "26F17E94CE556E3F79B2ADC966A78E2152323B14"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "453688608"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9.16.03.06.SPA.bin",
        "imageUuid": "49a371f7-fc3e-4469-9f9f-529bc7e493a4",
        "version": "Denali-16.3.6",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "a65a6308ec728a92f8463e5bfb6b79f0",
        "shaCheckSum": "3dd65d743da7600c65a38e8c287b8b2b10a01ecd1d766142cce7b495e7d5a5eaa6145813ec8789fed89dea69c3ff19ad084fdd9e225e65938def7b550718e39b",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 537577577,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "02/Mar/2018"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "35E3005AC664301A3E96440BBD0E0DC2D80C8B63"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "537577577"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": false
      },
      {
        "name": "cat3k_caa-universalk9ldpe.16.03.06.SPA.bin",
        "imageUuid": "4b89e807-af2b-40bd-b50b-265d55d57238",
        "version": "Denali-16.3.6",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "0d0076ca9b3f0e596bc99295b30a86d5",
        "shaCheckSum": "b12f5f01f32c1f3581f6315f7d164f463c4227189b52b10baa8a6a3bccf89ec558a8f898dd5ccc4bcb0001885a3cf4bc2054b8859b9c808dafb843baaec51705",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 537646690,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "02/Mar/2018"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "537646690"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "084F8CE63328398B82F67B5BF7927106FE4195BD"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": false
      },
      {
        "name": "cat3k_caa-universalk9.SPA.03.06.09.E.152-2.E9.bin",
        "imageUuid": "5a373eb2-222d-47fe-867c-03d2b3e23ce0",
        "version": "3.6.9E",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "b3528873f35aaa8b92855ca72955cc63",
        "shaCheckSum": "b4da39bc4f77fdd66a4bf4fab837d2768aac64b7cbfadaa12ff0e81b46f62ebe696ac8ee004e7ea8b7a6b32a0ad8a2d7cb67a3261cad4f95fd073bab3c3ae57a",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 304991272,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,284846122"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "11/Sep/2018"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "DE08B8D434ED252003C0442CBDF5D606CC13B072"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "304991272"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9.16.06.04.SPA.bin",
        "imageUuid": "5e0eaa99-4c4e-4c13-84ae-b22007e69174",
        "version": "Everest-16.6.4",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "8635172564abd2e6c7a48791f2695e14",
        "shaCheckSum": "fad57ff2edb47ac877c6b3c9739beb4f73fc2aca35c03c4df38e6d32f92f2303687b4d7b471366dd0ee27215b8729b33dad646a3992c809c831c7f6536791f94",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 410885614,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 2,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "AA1E0090A279FFB31FDC2FAEE1887C366E24C384"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "410885614"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "09/Jul/2018"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.16.03.07.SPA.bin",
        "imageUuid": "7eedfc09-1e43-47a3-967f-83f782259f76",
        "version": "Denali-16.3.7",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "5d370835a2b6add55063c811da00acb0",
        "shaCheckSum": "e56044cab4ac7024d4afafb6f7962c843dd8b59127c15f9c5182e9c36df1f7e437873eb843887f1993452c08f8c7023247e26d1a8a6a15d4f1beeebd60d896b4",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 537477785,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "04/Aug/2018"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "537477785"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "666E20C9A97E713EC36E42A3982B5D4E20BC843D"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.16.08.01a.SPA.bin",
        "imageUuid": "7f166475-7332-43fc-a778-42fe459dfadb",
        "version": "Fuji-16.8.1a",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "f3b52f18bbcb5a16ae0fe5407ed68a35",
        "shaCheckSum": "6b65bec2e61dd7a4ad66063ed2db761e8d243597b53f8317aca62880fc35dcccde8db5ba6fffa6edbd5f602cd9631a9a81b407433b2b570d19ab784c9b8654bd",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 425937972,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "06/Apr/2018"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "425937972"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "286285449,286285439"
          },
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "D8BA66A437EF90DECCFCAE1115A792DEE6CE0B8A"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141220457"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3850-24XU-E VID: V03, SN: FOC2129U11V"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9.16.09.01.SPA.bin",
        "imageUuid": "ce1699bf-5938-495a-b5fa-2f0f82c58276",
        "version": "Fuji-16.9.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "41e56e88bb058ca08386763404b3ccb6",
        "shaCheckSum": "b05f814a46ef6c962843445016f2d6e99ecb93e837df02a7589bce828d43e1353fd582210db0a407381e9dadc3685850adc2ab1d9fe74078658d06a867171c2e",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 453688163,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "18/Jul/2018"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "453688163"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "EF7C12787E1E7BDA82FFB16C9774C07A983A1ED9"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,286285449,286285439,284846122"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat3k_caa-universalk9ldpe.SPA.03.06.08.E.152-2.E8.bin",
        "imageUuid": "d7373759-d7ca-49ad-80be-989301a9db96",
        "version": "3.6.8E",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "1c92058ddc31fb2cc6aaf7f14008314b",
        "shaCheckSum": "975dc8629dd8da0875aec3287f634319cc13777f99eb62aadb7a13a509c7548f15467082a36c88f1eecdf2b2100aa9e3c8a55e58465041e5f0331c4ed7579cc9",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 305290172,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,284846122"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "0577A83D97A56FFD1511B89A4E7AB4326F068920"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "305290172"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "30/Jan/2018"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": false
      },
      {
        "name": "cat3k_caa-universalk9.SPA.03.06.08.E.152-2.E8.bin",
        "imageUuid": "dd92048e-52e5-4b87-9a53-03019a77b3ff",
        "version": "3.6.8E",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "a04a54d69cb2b4d2867ed369e73598ae",
        "shaCheckSum": "565fd0730441dd9a9e98207ff78580c9a3f7c1ce5a3c50529fb605d9f17e413e868fb2c2181a555fb7d2e0b55289da6814cfd6c54df75d4d3949e093f39fd684",
        "createdTime": "2018-09-12 02:20:33.508",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT3K_CAA",
        "vendor": "CISCO",
        "filesize": 305292424,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "284850621,284846122"
          },
          {
            "propertyName": "udi",
            "propertyValue": "PID: WS-C3650-24PD-E VID: V01, SN: FDO1852E264"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "4096"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "122355"
          },
          {
            "propertyName": "field_notice_url",
            "propertyValue": "https://www.cisco.com/en/US/products/ps13133/prod_field_notices_list.html"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "30/Jan/2018"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT3850/3650 UNIVERSAL"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "8245B4B0CF80FB3C15EB4A10DB47CF6DD7703726"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "305292424"
          },
          {
            "propertyName": "min_flash",
            "propertyValue": "2048"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "141202187"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": false
      }
 ]});