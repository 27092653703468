define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimAssuranceDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/NetworkDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/DeviceImageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInfoTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/InterfaceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceConfigStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/wireless/WlanTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/AccessPointTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/LearnDeviceConfigTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SensorTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceConfiguration',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/FunctionCapabilityTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceConfiguration1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/DeviceIssueListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/CustomIssueListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInterfaceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceFilterTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/dnamaps/ElementTypeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimPoeAnalyticsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/WirelessHostListing_more1',
], function (SimLokiDatabaseActions, DefaultConfig, SimDefaultDataGenerator, SimAssuranceDataGenerator,
             NetworkDeviceTemplate, DeviceImageTemplate, DeviceInfoTemplate, InterfaceTemplate,
             UtilityFunctions, DeviceConfigStatusTemplate, SimTaskDataGenerator, WlanTemplate,
             SimTopologyData, AccessPointTemplate, LearnDeviceConfigTemplate,SensorTemplate, DeviceConfiguration,
             FunctionCapabilityTemplate, DeviceConfiguration1, DeviceIssueListing, CustomIssueListing,SimInterfaceData,
             DeviceFilterTemplate, SimulationUtility, ElementTypeTemplate, SimPoeAnalyticsData, WirelessHostListing_more1) {

    var AUTH_MODE = {"web_consent":"WEBCONSENT","web_auth":"web_auth","wpa2_personal":"WPA2PERSONAL","wpa2_enterprise":"WPA2ENTERPRISE","open":"OPEN"};
    var endpoint_proxy = 'ENDPOINT_PROXY';
    var edgenode = 'EDGENODE';

    function getGroupNameHierarchy(siteId){
        var groupNameHierarchy = '';
        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': siteId });
        if (recordObj && recordObj.length){
            groupNameHierarchy = recordObj[0].groupNameHierarchy;
        }
        return groupNameHierarchy;
    }

    function getManagedSites(inetworkDeviceId) {
        var managedSites = [], i, j;
        var tObjs = SimLokiDatabaseActions.getAll('site');
        for(i=0; i < tObjs.length; i++){
            for(j=0; j < tObjs[i].additionalInfo.length; j++){
                if(tObjs[i].additionalInfo[j].attributes != undefined &&
                    tObjs[i].additionalInfo[j].attributes.primaryWlc != undefined &&
                    tObjs[i].additionalInfo[j].attributes.primaryWlc == inetworkDeviceId){
                    managedSites.push(tObjs[i].id);
                }
            }
        }
        return managedSites;
    }

    function createDeviceInfo(jsonObj) {
        var tJson = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Info_Template));
        var id = UtilityFunctions.generate_uuid();
        var managedSitesArr = getManagedSites(jsonObj.networkDeviceId);

        tJson.id = id;
        tJson.namespace = id;
        tJson.networkDeviceId = jsonObj.networkDeviceId;
        tJson.roles = (jsonObj.roles != undefined && jsonObj.roles.length >0) ? jsonObj.roles:  [];
        tJson.name = jsonObj.name;
        tJson.connectivityDomain = [];
        tJson.profileId = jsonObj.profileId;
        tJson.profileVersion = jsonObj.profileVersion;
        tJson.managedSites = managedSitesArr;
        tJson.siteId = jsonObj.siteId;
        tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
        var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': jsonObj.networkDeviceId});

        var tIfceContainerId = UtilityFunctions.generate_uuid();
        tJson.deviceInterfaceInfoContainer = {idRef: tIfceContainerId};//added in DNAC1..3.0 

        if(obj && obj.length) {
            tJson.managementIpAddress = obj[0].managementIpAddress;//as exception ip addr needed at some calls from DeviceInfo also.. 

            if( obj[0].family != DefaultConfig.NetworkDevice.ECA_FAMILY && tJson.roles != undefined &&
                tJson.roles.length == 0) {
                tJson.roles = [edgenode];
            }
        }

        if(jsonObj.rapUpgradeCfsProperty && jsonObj.rapUpgradeCfsProperty.enableRapUpgrade) {
            //this is for WLC. and is used during image upgrade for WLC for rolling AP
            tJson.rapUpgradeCfsProperty = jsonObj.rapUpgradeCfsProperty;
        }
        SimLokiDatabaseActions.insert('DeviceInfo', tJson);

        if(['SFO12-C9800-01','MEL03-C9800-01'].indexOf(tJson.name) > -1) {
            //exceptional temporary code witten
            return;
        }

        var profileId = tJson.profileId;

        if(obj && obj.length && (obj[0].family == DefaultConfig.NetworkDevice.WLC_FAMILY || (obj[0].family != DefaultConfig.NetworkDevice.ECA_FAMILY && tJson.roles.indexOf(endpoint_proxy) >=0))) { //WLCECA
            createWlan(tJson.networkDeviceId, profileId,tJson.siteId);
        }
    }

    function createDeviceConfig(jsonObj, isAddDevice) {
        var tJson = JSON.parse(JSON.stringify(DeviceConfigStatusTemplate.Device_Config_Status_Template));
        var id = UtilityFunctions.generate_uuid();

        tJson.id = id;
        tJson.instanceUuid = id;
        tJson.deviceId = jsonObj.deviceId;
        tJson.status = jsonObj.status;

        tJson.role =  jsonObj.role;
        tJson.family =  jsonObj.family;
        tJson.hostname =  jsonObj.hostname;
        tJson.managementIpAddress = jsonObj.managementIpAddress;

        if(DefaultConfig.BAD_DEVICE.indexOf(jsonObj.hostname) >= 0) {
            tJson.status = 'FAILED';
        }

        //Need this setting to display the Image version and name
        tJson.type = jsonObj.type;
        tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
        tJson.lastProvisionedTime = UtilityFunctions.getTimeStamp();

        if(jsonObj.family != DefaultConfig.NetworkDevice.WLC_FAMILY && jsonObj.family != DefaultConfig.NetworkDevice.AP_FAMILY &&
                jsonObj.family != DefaultConfig.NetworkDevice.ECA_FAMILY && jsonObj.family != DefaultConfig.NetworkDevice.NFVIS_FAMILY &&
                !isAddDevice) { //WLCECA
            tJson.aggregatedStatus = 'Success';
        }
        if(tJson.aggregatedStatus == 'Not Provisioned'){
            tJson.provisioningFeature=null
        }
        //tJson.namespace = jsonObj.cfsNamespace;
        tJson.workflowId=  UtilityFunctions.generate_uuid();
        SimLokiDatabaseActions.insert( 'DeviceConfigStatus', tJson);
    }

    function updateNetworkDevice(jsonObj) {
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': jsonObj.id});
        if (recordObj && recordObj.length) {
            recordObj.role = jsonObj.role;
            //console.log(recordObj);
            SimLokiDatabaseActions.update('network-device', recordObj);
        }
    }

    function deleteDeviceInfo(jsonObj) {
        var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'id' : jsonObj.id });
        if(deviceObj && deviceObj.length)  {
            SimLokiDatabaseActions.delete('DeviceInfo', deviceObj);

            var networkDeviceId = deviceObj[0].networkDeviceId;
            var networkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': networkDeviceId});
            if (networkObj && networkObj.length) {
                networkObj[0].siteId = '';
                SimLokiDatabaseActions.update('network-device', networkObj);

                var configObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'deviceId': networkDeviceId});
                if (configObj && configObj.length) {
                    configObj[0].status = '';
                    configObj[0].lastUpdateTime = '';
                    configObj[0].lastProvisionedTime = '';
                    configObj[0].aggregatedStatus = 'Not Provisioned';
                    SimLokiDatabaseActions.update('DeviceConfigStatus', configObj);
                }
            }

            var fdObjs = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain');
            if(fdObjs && fdObjs.length)  {
                for(var i = 0, len = fdObjs.length; i < len; i++) {
                    for(var j = 0; j < fdObjs[i].deviceInfo.length;) {
                        if(fdObjs[i].deviceInfo[j].idRef == jsonObj.id){
                            fdObjs[i].deviceInfo.splice(j, 1);
                            SimLokiDatabaseActions.update('ConnectivityDomain', fdObjs);
                            return;
                        } else {
                            j++;
                        }
                    }
                }
            }
        }
    }

    function updateDeviceInfo(jsonObj) {
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': jsonObj.id});
        if (recordObj && recordObj.length) {
            //Commenting this idRef not coming...
          //  if(jsonObj.deviceInterfaceInfo.length == 0) {
                recordObj[0].deviceInterfaceInfo.length = 0;
           // }

             // updating the provisioning time
             var configObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'deviceId': recordObj[0].networkDeviceId});
                if (configObj && configObj.length) {
                    configObj[0].lastUpdateTime =  UtilityFunctions.getTimeStamp();
                    configObj[0].lastProvisionedTime = UtilityFunctions.getTimeStamp();
                    //configObj[0].provisioningState = "DEPLOYED";
                    configObj[0].aggregatedStatus = 'Success'
                    SimLokiDatabaseActions.update('DeviceConfigStatus', configObj);
                }

            for(var i = 0, len = jsonObj.deviceInterfaceInfo.length; i < len; i++) {
                if(jsonObj.deviceInterfaceInfo.idRef){
                    continue;
                }
                recordObj[0].deviceInterfaceInfo.push(jsonObj.deviceInterfaceInfo[i]);
            }

            // add the new key value pairs in the db
            for(var key in jsonObj) {
                if(!(key in recordObj[0]) || recordObj[0][key] == undefined) {
                    //profileId is sent in the payload to be copied to the db, after provisioning
                    //incase template is present/matches for that device, customProvisions will have info in USER_CLI_TEMPLATE_PROVISION
                    if(['profileVersion','profileId','customProvisions'].indexOf(key) > -1) {
                        //updateDeviceConfigStatus(jsonObj);
                        recordObj[0].profileId = jsonObj.profileId;
                        recordObj[0].profileVersion = jsonObj.profileVersion;
                        if(jsonObj.customProvisions && jsonObj.customProvisions.length > 0)
                            recordObj[0].customProvisions = jsonObj.customProvisions;
                        break;
                    }
                }
            }
            var isEcaFromEdgetoEndPointProxy = false;
            //Eca switch first provisioned with wireless disabled(so assigned with role, edgenode) and then provisioned with wireless(assigned with endpoint_proxy). There is an update in roles.. There may be an update in profileid and profileversion, which is ahandled above
            if(jsonObj.roles.indexOf(endpoint_proxy) >= 0 && jsonObj.roles.indexOf(edgenode) >= 0)
            {
                for(var index=0;index<jsonObj.roles.length;index++)
                {
                 if(jsonObj.roles[index] == edgenode)
                 {
                   jsonObj.roles.splice(index,1);
                   isEcaFromEdgetoEndPointProxy = true;
                   break;
                 }
                }
                recordObj[0].roles = jsonObj.roles;
                var managedSitesArr = getManagedSites(jsonObj.networkDeviceId);
                recordObj[0].managedSites = managedSitesArr;
            }
            if(jsonObj.rapUpgradeCfsProperty && jsonObj.rapUpgradeCfsProperty.enableRapUpgrade) {
                //this is for WLC. and is used during image upgrade for WLC for rolling AP
                recordObj[0].rapUpgradeCfsProperty = jsonObj.rapUpgradeCfsProperty;
            }
            SimLokiDatabaseActions.update('DeviceInfo', recordObj);
            if(isEcaFromEdgetoEndPointProxy == true)
            {
              createWlan(jsonObj.networkDeviceId, jsonObj.profileId,jsonObj.siteId);
            }
        }
    }



    return {
        init: function () {

            var devices = SimDefaultDataGenerator.getNetworkDevices();

            var len = devices.length;
            var i;
            for (i = 0; i < len; i++) {
                var iStr = (i + 1).toString();
                var deviceConfigObj = {};
                deviceConfigObj.deviceId =  devices[i].id;
                deviceConfigObj.status = '';
                deviceConfigObj.type =  devices[i].type;
                deviceConfigObj.role =  devices[i].role;
                deviceConfigObj.family =  devices[i].family;//WLCECA
                deviceConfigObj.hostname =  devices[i].hostname;
                deviceConfigObj.managementIpAddress = devices[i].managementIpAddress;

                if (devices[i].isDefaultFD) {
                    deviceConfigObj.status = 'SUCCESS';

                    var tJson = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Info_Template));
                    tJson.id = devices[i].deviceInfoId;
                    tJson.namespace = devices[i].deviceInfoId;
                    tJson.networkDeviceId = devices[i].id;
                    // commenting this line..Roles will be allocated During adding device to fabric domian ,
                    //tJson.roles = ['EDGENODE'];
                    tJson.name = devices[i].hostname;
                    tJson.connectivityDomain = [DefaultConfig.DEFAULT_UUID.ConnectivityDomain];
                    tJson.instanceId = tJson.instanceId + iStr;
                    tJson.authEntityId = tJson.authEntityId + iStr;
                    tJson.platformId = devices[i].platformid;

                    tJson.healthScore= devices[i].healthScore[0].score;

                    tJson.managementIpAddress = devices[i].managementIpAddress;//added as a exception as ip addr needed at some calls from DeviceInfo also..
                    var tIfceContainerId = UtilityFunctions.generate_uuid();
                    tJson.deviceInterfaceInfoContainer = {idRef: tIfceContainerId};//added in DNAC1..3.0
                    if(tJson.name == "IE-3300_6") {
                        tJson.customProvisions = [{
                            "type":"USER_CLI_TEMPLATE_PROVISION",
                            "payload":"W3sidGVtcGxhdGVJZCI6IjFlMGUyNGM2LTExMTEtNDYxZS1iOWU3LTViMGQwYTZmOTdlYiIsIm1haW5UZW1wbGF0ZUlkIjoiMWUwZTI0YzYtMTExMS00NjFlLWI5ZTctNWIwZDBhNmY5N2ViIiwidGFyZ2V0SW5mbyI6W3siaWQiOiI2MWNkMmViMC1mNTdlLWJjM2EtMDZkNC0wYWJiOWMxOTAxMjgiLCJob3N0TmFtZSI6IklFMzQwMF8xIiwidHlwZSI6Ik1BTkFHRURfREVWSUNFX1VVSUQiLCJwYXJhbXMiOnt9LCJyZXNvdXJjZVBhcmFtcyI6W119XSwiZm9yY2VQdXNoVGVtcGxhdGUiOmZhbHNlLCJjb3B5aW5nQ29uZmlnIjp0cnVlLCJpc0NvbXBvc2l0ZSI6ZmFsc2V9XQ=="
                        }]
                    }
                    if(devices[i].family != DefaultConfig.NetworkDevice.WLC_FAMILY && devices[i].family != DefaultConfig.NetworkDevice.ECA_FAMILY ) { //WLCECA
                        SimLokiDatabaseActions.insert('DeviceInfo', tJson);
                    }
                }
                this.addDevice([devices[i]]);
                createDeviceConfig(deviceConfigObj);
            }
            createfunctionalCapability(); //for stealthwatch
            this.assignSiteToDevicesDuringInit();
            this.updateMemberInfoForAllSites();
            createHostDevices();
            this.addBrownDeviceConfig()
            this.associatedWlcIp();
            this.addWifi6Attribs();
            SimInterfaceData.init();
            SimAssuranceDataGenerator.init();
        },

        associatedWlcIp:function() {
             var accessDeviceList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Unified AP"});
            // var wlcDevice =  SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{hostname:DefaultConfig.Wlc_Provision_list[0]});
           
              for(var i=0;i<accessDeviceList.length;i++) {
                var parentSiteId=SimLokiDatabaseActions.getFilteredRecordHandler('site',{id:accessDeviceList[i].siteId})[0].parentId;
                var wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                {'$and': [{family:'Wireless Controller'}, {siteId:parentSiteId}]
                });
               if(wlcDevice.length>0 && wlcDevice[0].managementIpAddress){ 
                    var ipAddress=  wlcDevice[0].managementIpAddress;
                   accessDeviceList[i].associatedWlcIp=ipAddress;
                   accessDeviceList[i].apManagerInterfaceIp=ipAddress;
               }else {
                    //there is no WLC in the site. Hance assigning the IP of WLC in SJC01 - temporary fix
                    var defLoc = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'SJC01'});
                    wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                                                                                    {'$and': [{family:'Wireless Controller'}, {siteId:defLoc[0].id}] });
                    if(wlcDevice.length==0) {
                        defLoc = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':"Flr-SJC1-1"});
                        wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': [{family:'Wireless Controller'}, {siteId:defLoc[0].id}] });
                    }
                    var ipAddress=  wlcDevice[0].managementIpAddress;
                    accessDeviceList[i].associatedWlcIp=ipAddress;
                    accessDeviceList[i].apManagerInterfaceIp=ipAddress;
               }

               accessDeviceList[i].apFlexGroupName = {
                   apGroupName:'default-group', flexGroupName:'default-flex-group',ledFlashDuration:0,ledFlashEnabled:"Enabled",ledFlashRemainingTime:0
               }
             }

        },

        addWifi6Attribs: function(specificDeviceName) {
            var devices;
            if(specificDeviceName) {
                devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':specificDeviceName});
            } else {
                //init
                devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', 
                    {'family':{'$in':[DefaultConfig.NetworkDevice.WLC_FAMILY, DefaultConfig.NetworkDevice.AP_FAMILY]}} );
            }
            var summarizeProtocol = {};
            SimulationUtility.PROTOCOLS.forEach(obj => {
                summarizeProtocol[obj.dnacVal] = obj.value;
            });
            for(var i=0; i<devices.length; i++) {
                var isApplicableForWifi6 = SimulationUtility.validateWifi6Config(devices[i].family, devices[i].softwareVersion,
                    devices[i].platformId);
                if(devices[i].family == DefaultConfig.NetworkDevice.WLC_FAMILY) {
                    // Karan to check from wifi6 Dashboard
                    if(isApplicableForWifi6) { //Not clear of values to give in this section, for now.
                        devices[i].wifi6status = 2.0
                        devices[i].wlcBand5Wifi6 = 2.0
                        devices[i].wlcBand24Wifi6 = 2.0
                        devices[i].protocol_wifi6 = "5";
                    } else {
                        devices[i].wifi6status = 2.0
                        devices[i].wlcBand5Wifi6 = 2.0
                        devices[i].wlcBand24Wifi6 = 2.0
                        devices[i].protocol_wifi6 = "4";
                    }
                } else if(devices[i].family == DefaultConfig.NetworkDevice.AP_FAMILY) {
                    //Karan to check from wifi6 Dashboard
                    //For reference: Enabled:2.0, Disabled:1.0, Not Supported:0.0, --:-1.0
                    if(isApplicableForWifi6) {
                        devices[i].radio0wifi6status = 2.0;
                        devices[i].radio1wifi6status = 2.0;
                        devices[i].radio2wifi6status = -1.0
                        devices[i].wifi6Status = 2.0
                    } else {
                        devices[i].radio0wifi6status = 0.0;
                        devices[i].radio1wifi6status = 0.0;
                        devices[i].radio2wifi6status = "Cisco 4800 Series Unified Access Points" == devices[i].type ? 0.0 : -1.0;
                        devices[i].wifi6Status = 2.0
                    }
                    var moreDtls = getMaxProtocolFreqForAp(devices[i].id, isApplicableForWifi6);
                    devices[i].protocol_wifi6 = moreDtls.protocol;
                    devices[i].slot_wifi6 = moreDtls.slot;
                    if(!isApplicableForWifi6) {
                        devices[i].protocol_wifi6 = "4";
                        if(devices[i].platformId == "AIR-AP1852I-B-K9") {
                            //this is just to have atleast one AP with 11n. 4800, 3802 should be 11ac (as per feedback shared)
                            devices[i].protocol_wifi6 = "2";
                        }
                    }
                    // Adding wifi6E for only C9136 Devices in Groot release
                    if(devices[i].platformId.indexOf('C9136I') >= 0){
                        devices[i].protocol_wifi6 = "7";
                        devices[i].radio3wifi6status = 2.0
                    }
                }
            }
            if(devices.length>0) SimLokiDatabaseActions.update('network-device', devices);
            console.log("adding WiFi6 attribs to APs, WLCs")
        },

        addDevice :function(deviceDetails) { //NOTE: change here
            var t_Json = JSON.parse(JSON.stringify(NetworkDeviceTemplate.Network_Device_Template));
            var id = deviceDetails[0].id;
            t_Json.id = id;
            t_Json.instanceUuid = id;
            t_Json.name =deviceDetails[0].hostname;
            t_Json.hostname = deviceDetails[0].hostname;
            t_Json.managementIpAddress = deviceDetails[0].managementIpAddress;
            t_Json.serialNumber = deviceDetails[0].serialnumber;
            t_Json.platformId =deviceDetails[0].platformid;
            t_Json.family = deviceDetails[0].family;
            t_Json.type = deviceDetails[0].type;
            t_Json.macAddress =deviceDetails[0].macAddress;
            t_Json.softwareVersion = deviceDetails[0].swversion;
            t_Json.series = deviceDetails[0].series;

            var tLastUpdateTime = UtilityFunctions.getTimeStamp();
            t_Json.lastUpdateTime = tLastUpdateTime;
            t_Json.lastUpdated = UtilityFunctions.getGivenTimeInGivenFormat(tLastUpdateTime);

            //CAUTION -- deviceCreationDate is not comes from backend, it is for assurance simulation purpose
            //deletion will break assurance simulation
            var tDeviceCreationDate = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(8);
            t_Json.deviceCreationDate = new Date(tDeviceCreationDate);

            var min = Math.floor((Math.random() * 23) + 1).toString();
            var sec = Math.floor((Math.random() * 23) + 1).toString();
            var millisec = Math.floor((Math.random() * 64) + 1).toString();
            var day = Math.floor((Math.random() * 3) + 1)
            var hours = Math.floor((Math.random() * 23) + 1)
            t_Json.upTime = day +' days, '+ hours +':'+((min.length == 1)? '0'+min:min)+':'+((sec.length == 1)? '0'+sec:sec)+'.'+((millisec.length == 1)? '0'+millisec:millisec);
            t_Json.uptimeSeconds = Math.floor(parseInt(day) * (60*60*24)) + Math.floor(parseInt(hours) * (60*60)) + Math.floor(parseInt(min) * 60) + parseInt(sec) + Math.floor((parseInt(millisec)/1000) % 60)

           if(DefaultConfig.AP_Down_List.indexOf(deviceDetails[0].hostname) >= 0){
                t_Json.reachabilityStatus= "UnReachable";
            } else if(DefaultConfig.Devices_Down.indexOf(deviceDetails[0].hostname) >= 0){
                t_Json.reachabilityStatus= "UnReachable";
                t_Json.errorCode = "DEV-UNREACHED";
                t_Json.errorDescription = "SNMP timeouts are occurring with this device. Either the SNMP credentials are not "+
                    "correctly provided to controller or the device is responding slow and SNMP timeout is low. If it is a timeout issue, "+
                    "controller will attempt to progressively adjust the timeout in subsequent collection cycles to get device to "+
                    "managed state. User can also run discovery again only for this device using the discovery feature after adjusting the "+
                    "timeout and SNMP credentials as required. Or user can update the timeout and SNMP credentials as required using update "+
                    "credentials.";
                t_Json.reachabilityFailureReason = t_Json.errorDescription;
            } else if(deviceDetails[0].reachabilityStatus) t_Json.reachabilityStatus= deviceDetails[0].reachabilityStatus;
            let pltfmId = deviceDetails[0].platformid.split(',');
            if(pltfmId.length > 1 || deviceDetails[0].serialnumber == 'FXS2131Q4ZB' || deviceDetails[0].serialnumber == 'FXS2131Q4ZD'){
                t_Json.stackCount = {"stackSupported": true};
            }


            //Added for Assurance
            /*if(DefaultConfig.BAD_DEVICE.indexOf(deviceDetails[0].hostname) >= 0){
                t_Json.healthScore= 1;
            }
            else{
                t_Json.healthScore= deviceDetails[0].healthScore[0].score;
            }*/
            t_Json.assuranceHealthScore = deviceDetails[0].healthScore[0].score;
            t_Json.role = deviceDetails[0].role;
            //handling here again as pnp devices wont have this field..
            t_Json.vrf = deviceDetails[0].vrf == undefined ? "" : deviceDetails[0].vrf;
            t_Json.vlan = deviceDetails[0].vlan == undefined ? "" : deviceDetails[0].vlan;
            t_Json.routing = deviceDetails[0].routing == undefined ? "" : deviceDetails[0].routing;

            if(t_Json.family!="Unified AP") {
                t_Json.apFlexGroupName = { ledFlashDuration:0,ledFlashEnabled:"Disabled",ledFlashRemainingTime:0 }
            }
            t_Json.blueBeaconStoreStatus = {"beaconLedStatus" : "Not Supported"}
            SimLokiDatabaseActions.insert('network-device', t_Json);
        },

        UpdateSiteonDevices: function (siteId, deviceIds) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            for (var i = 0; i < deviceIds.length; i++) {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceIds[i] });
                if (recordObj && recordObj.length) {
                    recordObj[0].siteId = siteId;
                    if(recordObj[0].family==="Unified AP"){
                        var locationInfo="",wlcDevice=[];
                        var site=SimLokiDatabaseActions.getFilteredRecordHandler('site',{id:siteId})[0];
                        for(var k=0;k<site.additionalInfo.length;k++){
                            if(site.additionalInfo[k].nameSpace==="Location"){
                                locationInfo=site.additionalInfo[k].attributes['type'];
                            }
                        }
                        if(locationInfo==="floor"){
                            var parentSiteId=SimLokiDatabaseActions.getFilteredRecordHandler('site',{id:recordObj[0].siteId})[0].parentId;
                            wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                            {'$and': [{family:'Wireless Controller'}, {siteId:parentSiteId}]
                            }); 
                        }
                        else{
                            wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                        {'$and': [{family:'Wireless Controller'}, {siteId:siteId}] });
                        }
                        if(wlcDevice.length>0 && wlcDevice[0].managementIpAddress){
                            var ipAddress=  wlcDevice[0].managementIpAddress;
                            recordObj[0].associatedWlcIp=ipAddress;
                            recordObj[0].apManagerInterfaceIp=ipAddress;
                        } else {
                            //there is no WLC in the site. Hance assigning the IP of WLC in SJC01 - temporary fix
                            var defLoc = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'SJC01'});
                            wlcDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                                            {'$and': [{family:'Wireless Controller'}, {siteId:defLoc[0].id}] });
                            var ipAddress=  wlcDevice[0].managementIpAddress;
                            recordObj[0].associatedWlcIp=ipAddress;
                            recordObj[0].apManagerInterfaceIp=ipAddress;
                        }
                        //update ap to display in floor map too
                        this.createAccessPointForFloor(deviceIds[i],siteId);
                    }
                    SimLokiDatabaseActions.update('network-device', recordObj);
                     // Multisite - updating site id the device object
                     SimTopologyData.updateSiteDetails(deviceIds[i],siteId);
                }
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        deleteSiteonDevice: function (deviceId) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId });
            if (recordObj && recordObj.length) {
                recordObj[0].siteId = "";
                SimLokiDatabaseActions.update('network-device', recordObj);
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        CreateOrUpdateDeviceInfo: function (type, operation, jsonObj) {
            if (operation == 'post') {
                if (type == 'DeviceInfo') {
                    createDeviceInfo(jsonObj);
                    this.updateDeviceConfigStatus(jsonObj);
                }

            }
            else if (operation == 'put') {
                if (type == 'network-device') {
                    updateNetworkDevice(jsonObj);
                }
                else if (type == 'DeviceInfo') {
                    updateDeviceInfo(jsonObj);
                    this.extendedNode(jsonObj);
                    this.sdwanShortDemoUsecase(jsonObj);
                }
            }
            else if(operation == 'delete') {
                if(type == 'DeviceInfo'){
                    deleteDeviceInfo(jsonObj);
                }
            }
        },

        createDeviceConfigStatus: function(jsonObj) {
            createDeviceConfig(jsonObj)
        },

        updateRoleOnDevice: function(jsonObj) {
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': jsonObj.id});
            if (recordObj && recordObj.length) {
                recordObj[0].role = jsonObj.role;
                recordObj[0].roleSource = jsonObj.roleSource;
                SimLokiDatabaseActions.update('network-device', recordObj);
                SimTopologyData.updateRoleDetails(jsonObj);
            }
        },

        updateMgmtIpAddressOnDevice: function(jsonObj) {
            var duprecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': jsonObj.updateMgmtIPaddressList[0].newMgmtIpAddress});
            if(duprecord.length==0){
             var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': jsonObj.ipAddress[0]});
             if (recordObj && recordObj.length) {
             recordObj[0].managementIpAddress = jsonObj.updateMgmtIPaddressList[0].newMgmtIpAddress;
             SimLokiDatabaseActions.update('network-device', recordObj);
            }
            return "success";
        }
        else{
            return "fail";
        }
        },

        deleteNetworkDevice: function(jsonObj) {
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id' : jsonObj.id });
            if(recordObj && recordObj.length)  {
                SimLokiDatabaseActions.delete('network-device', recordObj);
                SimTopologyData.deleteDevice(jsonObj.id);
            }
        },

        addDeviceConfig: function(jsonObj){
           if(jsonObj != undefined && jsonObj.length > 0){
               jsonObj[0].status = "SUCCESS";
               jsonObj[0].deviceId = UtilityFunctions.generate_uuid();
               createDeviceConfig(jsonObj[0]);
           }
        },

        addDeviceInfo :function(jsonObj) {

          createDeviceInfo(jsonObj)
        }
        ,


        updateDeviceConfigStatus: function(jsonObj){
            var deviceConfigObjTable = 'DeviceConfigStatus', record, networkDevId;
            if( jsonObj.networkDeviceId == undefined && jsonObj.apMacAddrList != undefined
               && jsonObj.apMacAddrList.length > 0 ) {
                record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                                                                       {'macAddress': jsonObj.apMacAddrList[0]});
                if(record && record.length >0){
                    networkDevId =record[0].id;
                }
            }
            else {
                networkDevId =jsonObj.networkDeviceId;
            }
            if(networkDevId != undefined){
                record = SimLokiDatabaseActions.getFilteredRecordHandler(deviceConfigObjTable ,
                                                                         {'deviceId': networkDevId});
                //This is called after provisioning is done for devices
                if(record && record.length) {
                    record[0].aggregatedStatus = "Success";
                    record[0].lastProvisionedTime = UtilityFunctions.getTimeStamp();
                    record[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                    SimLokiDatabaseActions.update(deviceConfigObjTable, record);
                }
            }
        },
        extendedNode :function(jsonObj) {
           // Step1 : creating new device with role "SUBTENDED_NODE".
           // Step2 : Adding site to the device.
           // Step3 : Adding device to fabric site.
            var extDevice =[];

            if(jsonObj.roles.indexOf("SUBTENDED_NODE")>=0) {

            return;
            }
            for(var i = 0, len = jsonObj.deviceInterfaceInfo.length; i < len; i++) {

               if(jsonObj.deviceInterfaceInfo.idRef){
                   continue;
               }

               if(jsonObj.deviceInterfaceInfo[i].connectedDeviceType=="EXTENDED_NODE") {
                    //  Checking whether device created for the interface id
                    var otherDevice = jsonObj.otherDevice;
                    var extendedList = [];
                    for (var j=0;j<otherDevice.length;j++) {
                       extendedList.push(otherDevice[j].idRef);

                    }
                    if(extendedList.length>0) {
                         var objs = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {
                                                            '$and': [ {
                                                                      'id': { '$containsAny' :  extendedList}
                                                                    },
                                                                    {
                                                                        'deviceInterfaceInfo.interfaceId': jsonObj.deviceInterfaceInfo[i].interfaceId
                                                                    }, {
                                                                         'roles':  { '$contains' : "SUBTENDED_NODE" }
                                                                    }]
                                                             });
                         if(objs.length>0) {
                               continue;
                         }
                    }
                   // Getting the IPAdreess
                   var segment =   SimLokiDatabaseActions.getFilteredRecordHandler('Segment',{'id':jsonObj.deviceInterfaceInfo[i].segment[0].idRef});
                   var ipPoolObj =SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{'id':segment[0].ipPoolId});
                   var ipPoolCidr =ipPoolObj[0].ipPoolCidr.split("/");
                   var ipAddress = ipPoolCidr[0];
                   var extendedNodeDeviceId = UtilityFunctions.generate_uuid();
                   var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':jsonObj.networkDeviceId});
                   var deviceDetails = SimDefaultDataGenerator.inventoryDevice(deviceRecord[0],ipAddress,extendedNodeDeviceId,"Extended_node");

                   var extendedNode= DefaultConfig.Default_Exetended_Node;
                   deviceDetails[0].type =extendedNode.type;
                   deviceDetails[0].macAddress =extendedNode.macAddress;
                   deviceDetails[0].serialnumber =extendedNode.serialnumber;
                   deviceDetails[0].series =extendedNode.series;
                   deviceDetails[0].platformid =extendedNode.platformid;
                   // Creating new device
                   this.addDevice(deviceDetails);
                   var deviceConfigObj = {};
                    deviceConfigObj.deviceId =  deviceDetails[0].id;
                    deviceConfigObj.status = 'SUCCESS';
                    deviceConfigObj.type =  deviceDetails[0].type;
                    deviceConfigObj.role =  deviceDetails[0].role;
                    deviceConfigObj.family =  deviceDetails[0].family;
                    deviceConfigObj.hostname =  deviceDetails[0].hostname;
                  //  Creating the device config
                   createDeviceConfig(deviceConfigObj);
                   // adding new device to topology
                   SimTopologyData.addDevice(jsonObj.networkDeviceId,deviceDetails);
                   // Updating the site details
                   var extDeviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':extendedNodeDeviceId});
                       extDeviceRecord[0].siteId = deviceRecord[0].siteId;
                   SimLokiDatabaseActions.update('network-device', extDeviceRecord);
                   SimTopologyData.updateSiteDetails(extendedNodeDeviceId,deviceRecord[0].siteId);
                  // Creating the device info
                   createDeviceInfo({'networkDeviceId':extendedNodeDeviceId,'name':deviceDetails[0].hostname});

                   var sourceDeviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId' : jsonObj.networkDeviceId });
                   var extDeviceInfoObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId' : extendedNodeDeviceId});
                   extDeviceInfoObj[0].connectivityDomain =sourceDeviceInfo[0].connectivityDomain;
                   extDeviceInfoObj[0].roles = ["SUBTENDED_NODE"];
                   var interfaceDetails = [];
                   interfaceDetails.push(jsonObj.deviceInterfaceInfo[i]);
                   extDeviceInfoObj[0].deviceInterfaceInfo =interfaceDetails;
                   SimLokiDatabaseActions.update('DeviceInfo', extDeviceInfoObj);
                    // Updating the extended device id in source device
                   sourceDeviceInfo[0].otherDevice.push({ 'idRef' : extDeviceInfoObj[0].id });
                   SimLokiDatabaseActions.update('DeviceInfo', sourceDeviceInfo);
                   // Adding the fabric domain
                   var recordFdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': sourceDeviceInfo[0].connectivityDomain[0]});
                   recordFdObj[0].deviceInfo.push({idRef: extDeviceInfoObj[0].id});
                   SimLokiDatabaseActions.update('ConnectivityDomain', recordFdObj);
             }
            }
        },
        addBrownDeviceConfig : function() {
          var deviceNmaes= ['Campus_WLC4','Campus_WLC5'];
          var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': "Flr-SE-1"});
          var data = JSON.parse(JSON.stringify(LearnDeviceConfigTemplate.Learn_Device_Config_Template));

          for(var i=0;i<data.length;i++) {
             var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': deviceNmaes[i]});

              // updating the site id
              var configObject = data[i].CONFIG;
               for(var j=0;j<configObject.length;j++) {
                   if(configObject[j].key=="dhcp.server") {
                     configObject[j].groupUuid=record[0].id;
                   }
               }

              var config= {"id":recordObj[0].id,value:data[i]};
             SimLokiDatabaseActions.insert('learn-device-device', config);
          }
        },

        assignSiteToDevicesDuringInit: function () {
           var site_config = DefaultConfig.Site_Config;

           for(var i=0;i<site_config.length;i++) {
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': site_config[i].site});
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': { '$containsAny' : site_config[i].deviceList}});
                for(var k=0;k<recordObj.length;k++) {
                        recordObj[k].siteId = record[0].id;
                        var totalCount = record[0].additionalInfo[0].attributes['member.count.total'] ;
                        totalCount = parseInt(totalCount)+1;
                        record[0].additionalInfo[0].attributes['member.count.total'] =totalCount;
                        SimLokiDatabaseActions.update('site', record);
                        // updating site id the device object
                        //SimTopologyData.updateSiteDetails(recordObj[k].id,record[0].id);
                        //Updating site in device info
                         updateSiteInfo(recordObj[k].id,record[0].id);
                         SimLokiDatabaseActions.update('network-device', recordObj);

                }
           }


            // Appilcation policy
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'role':"BORDER ROUTER"});
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': "AU1"});
            for(var k=0;k<recordObj.length;k++) {
                if(DefaultConfig.APPLICATION_POLICY_DEVICE.indexOf(recordObj[k].hostname) >= 0) {
                    recordObj[k].siteId = record[0].id;
                    var totalCount = record[0].additionalInfo[0].attributes['member.count.total'] ;
                    totalCount = parseInt(totalCount)+1;
                    record[0].additionalInfo[0].attributes['member.count.total'] =totalCount;
                    SimLokiDatabaseActions.update('site', record);
                    // updating site id the device object
                 //   SimTopologyData.updateSiteDetails(recordObj[k].id,record[0].id);
                    //Updating site in device info
                     updateSiteInfo(recordObj[k].id,record[0].id);
                     SimLokiDatabaseActions.update('network-device', recordObj);
                 }
            }

            // Access Point
            /* This is added to Show Access points in other site's floor map except for SJC01,
            *SJC01 is handled in below for loop*/
            var excludeAps = [];
            site_config = DefaultConfig.AP_Site_Config;            
            for(var i=0;i<site_config.length;i++) {                
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': site_config[i].site});       var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': { '$containsAny' : site_config[i].deviceList}}); 
                excludeAps = excludeAps.concat(site_config[i].deviceList);               
                for(var k=0;k<recordObj.length;k++) {                    
                    recordObj[k].siteId = record[0].id;                    
                    var totalCount = record[0].additionalInfo[0].attributes['member.count.total'] ;                    totalCount = parseInt(totalCount)+1;                    
                    record[0].additionalInfo[0].attributes['member.count.total'] =totalCount;                    SimLokiDatabaseActions.update('site', record);                    
                    // updating site id the device object                    
                    //SimTopologyData.updateSiteDetails(recordObj[k].id,record[0].id);                    
                    //Updating site in device info                     
                    updateSiteInfo(recordObj[k].id,record[0].id);                     
                    SimLokiDatabaseActions.update('network-device', recordObj);
                    this.createAccessPointForFloor(recordObj[k].id,record[0].id);                
                }            
            }

            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and': [ {'family':"Unified AP"} ,{ 'hostname' : {'$nin':excludeAps } }]});
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': "Flr-SJC1-1"});
            for(var k=0;k<recordObj.length;k++) {
                    recordObj[k].siteId = record[0].id;
                    var totalCount = record[0].additionalInfo[0].attributes['member.count.total'] ;
                    totalCount = parseInt(totalCount)+1;
                    record[0].additionalInfo[0].attributes['member.count.total'] =totalCount;
                    SimLokiDatabaseActions.update('site', record);
                    // updating site id the device object
                    //SimTopologyData.updateSiteDetails(recordObj[k].id,record[0].id);
                    //Updating site in device info
                     updateSiteInfo(recordObj[k].id,record[0].id);
                     SimLokiDatabaseActions.update('network-device', recordObj);
                     this.createAccessPointForFloor(recordObj[k].id,record[0].id);
            }

           // Sensor

           var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Wireless Sensor"});
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': "Flr-SJC1-1"});
            for(var k=0;k<recordObj.length;k++) {
                    recordObj[k].siteId = record[0].id;
                    var totalCount = record[0].additionalInfo[0].attributes['member.count.total'] ;
                    totalCount = parseInt(totalCount)+1;
                    record[0].additionalInfo[0].attributes['member.count.total'] =totalCount;
                    SimLokiDatabaseActions.update('site', record);
                    // updating site id the device object
                    //SimTopologyData.updateSiteDetails(recordObj[k].id,record[0].id);
                    //Updating site in device info
                     updateSiteInfo(recordObj[k].id,record[0].id);
                     SimLokiDatabaseActions.update('network-device', recordObj);
                     createSensorForFloor(recordObj[k].id,record[0].id);
            }
        }
        ,

        getAPforAirsense :function(urlAction) {

           var deviceId = urlAction.action.id;
           var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
           var apDetails =  { "macAddress": recordObj[0].macAddress,
              "lradName": recordObj[0].name,
              "apLocation": "default location",
              "softwareVersion": recordObj[0].softwareVersion,
              "model": recordObj[0].platformid,
              "serialNumber": recordObj[0].serialNumber,
              "ethernetMac": recordObj[0].macAddress,
              "apType": recordObj[0].type,
              "ipAddress": recordObj[0].managementIpAddress,
              "rawServiceDomainId": "59062",
              "rawManagingMneId": "1210209"
            };

            return  apDetails;
        },

        getAPforAirsense13 :function(urlAction) {
            console.log(urlAction)
           var deviceId = urlAction.action.id || urlAction.filter.id;
           var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
           var apDetails =  { "macAddress": recordObj[0].macAddress,
              "ethMacAddress": recordObj[0].macAddress,
              "lradName": recordObj[0].name,
              "apLocation": "default location",
              "softwareVersion": recordObj[0].softwareVersion,
              "model": recordObj[0].platformid,
              "serialNumber": recordObj[0].serialNumber,
              "ethernetMac": recordObj[0].macAddress,
              "apType": recordObj[0].type,
              "ipAddress": recordObj[0].managementIpAddress,
              "rawServiceDomainId": "59062",
              "rawManagingMneId": "1210209"
            };

            return  [apDetails];
        },

        getFunctionalCapability :function(urlAction){
            var tJsonObj = [] //For StealthWatch
            var tJson = JSON.parse(JSON.stringify(FunctionCapabilityTemplate.Function_Capability_Template));
            var dbObj = SimLokiDatabaseActions.getAll('functional-capability')
            var device_id_length = urlAction.filter.deviceId.split(',')
            dbObj[0].deviceId = device_id_length[0]
            var etaanalytics =  {
                "functionName": "EtAnalytics",
                "functionOpState": "ENABLED",
                "functionDetails": [],
                "id": "a8626c2a-4271-47b1-ba71-b8e41be7e6aa"
            }
            // for (k=0;k<device_id_length.length;k++){
            //     var tJson = JSON.parse(JSON.stringify(FunctionCapabilityTemplate.Function_Capability_Template[0]));
            //     tJson.deviceId = device_id_length[k]
            //     tJsonObj.push(tJson)
            // }
            var len = device_id_length.length<tJson.length ? device_id_length.length : tJson.length;
            for(var i = 0; i < len; i++) {
                    tJson[i].deviceId = device_id_length[i]   
                    tJsonObj.push(tJson[i])
            }
            SimLokiDatabaseActions.update('functional-capability', dbObj)
            if (dbObj[0].current_status == "ENABLED"){
                for(j = 0; j < tJsonObj[0].functionalCapability.length; j++){
                    if(tJsonObj[0].functionalCapability[j].functionName == "etaEnabled"){
                        tJsonObj[0].functionalCapability[j].functionOpState = "ENABLED"
                        tJsonObj[0].functionalCapability.push(etaanalytics)
                    }
                }
            }
            return tJsonObj
        },

        createSensor:function(deviceId,siteId){
          createSensorForFloor(deviceId,siteId);
        },

        getDeviceConfig: function(deviceId) {
            // updated the code for 1.3 by Karan
            var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
            var deviceFamily = device[0].family, tJson;
            if(deviceFamily == 'Routers'){
                tJson = JSON.parse(JSON.stringify(DeviceConfiguration1.Routers_Config_Template));
            } else if(deviceFamily == 'Switches and Hubs'){
                //tJson = JSON.parse(JSON.stringify(DeviceConfiguration1.Swithes_Config_Template));
                let templateName = device[0].platformId.startsWith("IE-") ? "IOT_Config_Template_New" : "Switch_Config_Template_New";
                if(device[0].platformId.startsWith("IE-3400")){
                    templateName="IOT_Config_Template_Detail"
                }
                tJson = JSON.parse(JSON.stringify(DeviceConfiguration1[templateName]));
            } else {
                tJson = JSON.parse(JSON.stringify(DeviceConfiguration.CONFIGURATION));
            }
            tJson = updateConfigFields(tJson, device[0]);
            return updateSpecificDeviceConfig(device[0].hostname, device[0].id, tJson);
        },

        createWlan: function(networkDeviceId,profileId,siteId) {
            createWlan(networkDeviceId,profileId,siteId);
        },

        updateMemberInfoForAllSites : function() {
            var memberCountObj = {};
            var devRecord = SimLokiDatabaseActions.getAll('network-device');
            devRecord.forEach(device => {
                var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':device.siteId});
                if(siteRecord.length>0) {
                    var sites = siteRecord[0].groupNameHierarchy.split('/');
                    for(var i=0; i<sites.length; i++) {
                        if(sites[i]=='Global') continue;
                        if(memberCountObj[sites[i]] == undefined) {
                            memberCountObj[sites[i]] = {'total':0, 'direct':0};
                        }
                        if(memberCountObj[sites[i]]) memberCountObj[sites[i]].total += 1;
                        if(i==sites.length-1) memberCountObj[sites[i]].direct += 1;
                    }
                }
            });
    
            Object.keys(memberCountObj).forEach(function(key) {
                var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': key});
                siteRecord[0].additionalInfo[0].attributes['member.count.total'] = memberCountObj[key].total;
                siteRecord[0].additionalInfo[0].attributes['member.count.direct'] = memberCountObj[key].direct;
                SimLokiDatabaseActions.update('site', siteRecord);
            });
        },
    
        updateMemberInfoForSite : function(siteId) {
            var memberCountObj = {};
            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':siteId});
            if(siteRecord.length>0) {
                var sites = siteRecord[0].groupNameHierarchy.split('/');
                for(var i=0; i<sites.length; i++) {
                    if(sites[i]=='Global') continue;
                    memberCountObj[sites[i]] = {'total':0, 'direct':0};
                    memberCountObj[sites[i]].total += 1;
                    if(i==sites.length-1) memberCountObj[sites[i]].direct += 1;
                }
            }
            Object.keys(memberCountObj).forEach(function(key) {
                var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': key});
                siteRecord[0].additionalInfo[0].attributes['member.count.total'] += memberCountObj[key].total;
                siteRecord[0].additionalInfo[0].attributes['member.count.direct'] += memberCountObj[key].direct;
                SimLokiDatabaseActions.update('site', siteRecord);
            });
        },

        createAccessPointForFloor: function(deviceId,siteId) {

            var floorMap = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid': siteId});
            var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : deviceId });
    
            var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
            var tJson = JSON.parse(JSON.stringify( AccessPointTemplate.Access_Point_Template));
    
            tJson.attributes.instanceUuid = UtilityFunctions.generate_uuid();
            tJson.attributes.name = deviceDetails[0].name;
            tJson.attributes.ipAddress= deviceDetails[0].managementIpAddress;
            tJson.attributes.macaddress =deviceDetails[0].macAddress;
            tJson.attributes.model = deviceDetails[0].platformId;
            tJson.attributes.typeString = deviceDetails[0].platformId;
            
            var apModels = ElementTypeTemplate.ApTypeTemplate.items;
            var currentAptype=  apModels.filter(e => e.name == deviceDetails[0].platformId)
            tJson.attributes.type = currentAptype[0] ? currentAptype[0].ordinal : 93;
            tJson.attributes.locationGroup =siteId;
            tJson.attributes.siteName=floorMap[0].siteName;
            tJson.attributes.buildingName=floorMap[0].buildingName;
            tJson.attributes.floorName=floorMap[0].name;
            tJson.attributes.heirarchyName=site[0].groupNameHierarchy;
            var defaultPoistionList = DefaultConfig.Default_Access_Point;
            for(var i=0;i<defaultPoistionList.length;i++){
                if(defaultPoistionList[i].name==deviceDetails[0].name) {
                    tJson.position = defaultPoistionList[i].position;
                }
            }
    
            tJson.metrics.softwareVersion = deviceDetails[0].softwareVersion;
            tJson.metrics.productTypeString = deviceDetails[0].type;
   
            //To remove the ap from existing site
            var existingApDetails = SimLokiDatabaseActions.getFilteredRecordHandler('access-point', {'attributes.macaddress' : tJson.attributes.macaddress });
            SimLokiDatabaseActions.delete('access-point', existingApDetails);

            SimLokiDatabaseActions.insert('access-point', tJson);
        },

        getFamilyCount: function() {
            var records = SimLokiDatabaseActions.getAll('network-device');
            var data = {};
            var devices = records.filter(dev => { return dev.family == 'Switches and Hubs'});
            data['Switches and Hubs'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'Routers'});
            data['Routers'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'Unified AP'});
            data['Unified AP'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'Wireless Controller'});
            data['Wireless Controller'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'Wireless Sensor'});
            data['Wireless Sensor'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'NFVIS'});
            data['NFVIS'] = devices.length;
            devices = records.filter(dev => { return dev.family == 'ECA Switch'});
            data['ECA Switch'] = devices.length;
            return data;
        },

        getProvisionStatusStore :function(urlAction) {
            var configStatus = SimLokiDatabaseActions.getAll('DeviceConfigStatus');
            var items=[];
            configStatus.map((obj,index)=>{
                let item = {}
                item['id'] = obj.id
                item['deviceId'] = obj.deviceId
                item['lastUpdateTime'] = new Date().getTime()
                item['aggregatedStatus'] = obj.aggregatedStatus
                item['credentialStatus'] = obj.credentialStatus
                item['aggregatedRollbackStatus'] = 'Not Started'
                item['hasTemporaryFailures'] = false
                item['__storeIdx'] = index
                items.push(item)
            })
            return {"items": items,
                      "totalCount":configStatus.length,
                      "metadata": {"stores": {"ProvisionStatusStore": new Date()},
                        "query": {"query": {"name": urlAction.filter.name,"__preventCache": urlAction.filter.__preventCache},
                        "timeTaken": "0.00secs"}}}
        },

        getallDeviceStoreData :function(urlAction){
            var data = {"response": []};
            var fields = ["family","reachabilityStatus","deviceSupportLevel","collectionStatus","role",
                "deviceImage.upgradeStatus","deviceImage.deviceImageUpgradeStatus","deviceImage.readyForUpgrade",
                "provisionStatus.aggregatedStatus","provisionStatus.credentialStatus"];
            if(urlAction.filter['fields'] && (urlAction.filter['fields'].split(',')).length==1)
                fields = [(urlAction.filter['fields'].split(','))[0]];
            fields.forEach(field => {
                var refTableName, fieldName = field;
                switch (field) {
                    case "deviceImage.upgradeStatus":
                    case "deviceImage.deviceImageUpgradeStatus":
                    case "deviceImage.readyForUpgrade":
                        refTableName = "device-image";
                        break;
                    case "provisionStatus.aggregatedStatus":
                    case "provisionStatus.credentialStatus":
                        refTableName = "DeviceConfigStatus";
                        break;
                    case "site.groupNameHierarchy":
                        refTableName = "site";
                        break;
                    case "complianceStoreStatus.complianceStatus":
                        refTableName = "device-compliance";
                        break;
                    default:
                        refTableName = "network-device";
                        break;
                }
                if(field.split(".").length > 1) {
                    //so far we we have only upto level 1 in the fields incoming
                    fieldName = field.split(".")[1];
                }

                var values = SimLokiDatabaseActions.getDistinctValues(refTableName,fieldName);
                var obj = {"field":field, "values":[]};

                values.forEach(value => {
                    if(value == undefined || value == null) return;
                    var count = (SimLokiDatabaseActions.getFilteredRecordHandler(refTableName, {fieldName:value})).length;
                    var valObj = { "id":value.toLowerCase(), "text":value, "count":count };
                    obj.values.push(valObj);
                });
                data.response.push(obj);
            });
            return data;
        },

        getEquipmentDetails(type, deviceId) {
            let data = [];
            let device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
            let partPid = (((device[0].platformId.split('/'))[0].split('-'))[0]).toUpperCase();//some pid have '/' and some '-'
            if(type == 'PowerSupply') {
                //this tab is present for Switches And Hubs, Routers only.
                if(device[0].family == 'Routers') {
                    data = JSON.parse(JSON.stringify(NetworkDeviceTemplate.POWER_SUPPLY_TEMPLATE_ROUTER));
                } else {
                    data = JSON.parse(JSON.stringify(NetworkDeviceTemplate.POWER_SUPPLY_TEMPLATE_SWITCH));
                }
                data.forEach(power => {
                    if(['normal','on'].includes(power.operationalStateCode)) power.serialNumber=UtilityFunctions.generateSerialNo();
                    if(power.vendorEquipmentType) power.vendorEquipmentType = power.vendorEquipmentType.replace('$PID_PART',partPid);
                    if(power.description) power.description = power.description.replace('$PID_PART',partPid);
                });
            } else if(type == 'Fan') {
                //this tab is present for Switches And Hubs, Routers only.
                if(device[0].family == 'Routers') {
                    data = JSON.parse(JSON.stringify(NetworkDeviceTemplate.FAN_TEMPLATE_ROUTER));
                } else {
                    data = JSON.parse(JSON.stringify(NetworkDeviceTemplate.FAN_TEMPLATE_SWITCH));
                }
                data.forEach(fan => {
                    if(fan.description) {
                        fan.description = fan.description.replace('$PID',device[0].platformId);
                        fan.description = fan.description.replace('$PID_PART',partPid);
                    }
                    if(fan.vendorEquipmentType) fan.vendorEquipmentType = fan.vendorEquipmentType.replace('$PID_PART',partPid);
                });
            } 
            return data;
        },

        getInventoryInsight(urlAction) {
            var siteId = urlAction.action.id, childrenSites=[], locations=[];
            SimulationUtility.getChildrenSites(siteId, childrenSites);
            locations = childrenSites.map(e=>e.id);
            locations.push(siteId);
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('inventory-insight',{'simSiteId': {'$containsAny':locations} } );
            if(urlAction.filter.category) {
                if(urlAction.filter.category=="speed-duplex") {
                    records = records.filter(e=>e.instanceTenantId == "5f35ea5f15de5300c6a0162a");
                } else if(urlAction.filter.category=="vlan") {
                    records = records.filter(e=>e.instanceTenantId == "5f35ea5f15de5300c6a0162b");
                }
            }
            if(urlAction.action.count) return records.length;
            return records;
        },

        /**
        * Attach SDWAN Device from SDWAN, there after in DNAC, map them to SJC04 site, provision the two ISR routers.
        * On completion of this step, add 9300 stackeddevice to inventory. 
        * And, have these 3 devices shown in topology of Fabric site SJC04.
        */
        sdwanShortDemoUsecase: function(jsonObj) {
            let sdwanDevices = ['SJC04-ISR4K-01','SJC04-ISR4K-02'];//to list the ISR Router names from SDWAN
            var id1 = UtilityFunctions.generate_uuid(), id2 = UtilityFunctions.generate_uuid();
            let autoAddDev = { id : id1, deviceId:id1, isDefaultFD : true,
                deviceDefinedAs: 'edge', deviceInfoId: id2, hostname: "SJC04-C9300Stack-1", type: 'Cisco Catalyst 9300 Switch', 
                platformid : 'C9300-24U,C9300-48UXM', macAddress: "ef:ab:1a:2b:9c:7e", managementIpAddress: '192.168.66.59', 
                serialnumber: 'JPG543702AA,JPG546702BA', swversion: '16.12.3sprd3', family: 'Switches and Hubs', role: 'ACCESS', 
                healthScore:[{healthType: "OVERALL", reason: "", score: 10}], series:'Cisco Catalyst 9300 Series Switches',
                vrf: "", vlan: "", routing: "", "isStack":true};
            let deviceConfigObj = { 'deviceId':id1, 'status': 'Success', 'type': autoAddDev.type, 'role': autoAddDev.role, 
                'family': autoAddDev.family, 'hostname': autoAddDev.hostname, 'managementIpAddress': autoAddDev.managementIpAddress};

            let devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': jsonObj.networkDeviceId });
            if(devices.length>0) {
                //once device is provisioned, it should be Managed and Reachable
                devices[0].reachabilityStatus = 'Reachable';
                devices[0].collectionStatus = 'Managed';
                delete devices[0].errorCode;
                delete devices[0].errorDescription;
                SimLokiDatabaseActions.update('network-device', devices);
            }
            
            let autoAddDevRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'hostname': autoAddDev.hostname });
            if(autoAddDevRec.length>0) return; //device already added and use case is already taken care of.

            var records = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',
                {'$and':[{'hostname': {'$in':sdwanDevices}},{'aggregatedStatus':'Success'}]});
            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': 'SJC04' });
            var siteId = siteRecord[0].id;
            if(sdwanDevices.length == records.length) {
                //all Devices are provisioned (the 2 ISRs)
                this.addDevice([autoAddDev]);//add the device to inventory
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': id1 });
                recordObj[0].siteId = siteId;//update site info
                SimLokiDatabaseActions.update('network-device', recordObj);
                createDeviceInfo({ 'networkDeviceId': id1, 'name': autoAddDev.hostname, siteId:siteId });
                createDeviceConfig(deviceConfigObj);//provision the device
                updatePoeData([deviceConfigObj]);//add poe data
                SimInterfaceData.associateInterfacesToSpecificDevice(recordObj, true);
                this.updateMemberInfoForAllSites();
                createSdwanUsecaseTopology(sdwanDevices,[autoAddDev.hostname]);
            }
        }

    };

    function getHostRelatedInformation(hostObj, wirelessDevices, wiredDevices,counter, mustDevices, mustApCounter) {
        var t = {}, i;
        var len = hostObj.hostType == 'WIRELESS' ? wirelessDevices.length : wiredDevices.length;
        var indx = UtilityFunctions.getRandomIntForRangeValues(1, len) - 1;
        //exclude wired client connection for listed devices 
        wiredDeviceExclusions = ["BLD1-FLR2-ACCESS","BLD2-FLR2-ACCESS","BLD2-FLR1-ACCESS","p1.edge1-sda1.local",
            "EDGE01-SJC23.cisco.local", "IE4K-POE_ExtNode", "IE4K-ExtNode", "IE3400_1", "IE-3300_6", "IE3400_2", "IE3400_3"];

        //if same userId has other client exists, assign same ap, location, vlanid etc..
        var record = []
        if(hostObj.userId != null){
            record = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'userId': hostObj.userId });
        }
        for(i = 0; i < record.length; i++) {
            if(hostObj.hostType == record[i].hostType) {
                t.connectedNetworkDeviceId = record[i].connectedNetworkDeviceId;
                t.location = record[i].location;
                t.clientConnection = record[i].clientConnection;
                t.vlanId = record[i].vlanId;
                return t;
            }
            t.location = record[0].location;
            t.vlanId = record[0].vlanId;
            if(hostObj.hostType == 'WIRELESS') {
                //Below if condtion is added to all Grace Smith devices are connected to SJC01_9136_1 AP
                if(hostObj.userId == "Grace.Smith") {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "SJC01_9136_1"});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
                } else if(counter<8 ||  hostObj.userId=="ethan") {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "AP4800"});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
                } else if (hostObj.frequency=='2.4' && mustApCounter['ap_24']<mustDevices['ap_24'].length) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': mustDevices['ap_24'][mustApCounter['ap_24']]});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
                    mustApCounter['ap_24']++;
                } else if (hostObj.frequency=='5.0' && mustApCounter['ap_5']<mustDevices['ap_5'].length) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': mustDevices['ap_5'][mustApCounter['ap_5']]});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
                    mustApCounter['ap_5']++;
                } else {
                    t.connectedNetworkDeviceId = wirelessDevices[indx].id;
                    t.clientConnection = wirelessDevices[indx].hostname;
                }

            } else {
                if(counter<8 ||  hostObj.userId==="becky") {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD1-FLR2-ACCESS"});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
        
                }
                else if(counter<8 ||  hostObj.userId==="dnac.ise") {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD2-FLR2-ACCESS"});
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.clientConnection = recordObj[0].hostname;
                }
                else{
                    //dont connect any host to these devices - as requested by Satpal
                    wiredDevices= wiredDevices.filter(function (itm) { 
                        return ( wiredDeviceExclusions.indexOf(itm.hostname) == -1) 
                    });
                    indx = UtilityFunctions.getRandomIntForRangeValues(1, wiredDevices.length) - 1;
                    t.connectedNetworkDeviceId = wiredDevices[indx].id;
                    t.clientConnection = wiredDevices[indx].hostname;
                }
            }
            return t;
        }

        if(hostObj.hostType == 'WIRELESS') {
            //Below if condtion is added to all Grace Smith devices are connected to SJC01_9136_1 AP
            if(hostObj.userId == "Grace.Smith") {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "SJC01_9136_1"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.clientConnection = recordObj[0].hostname;
            } else if(counter<8 ||  hostObj.userId=="ethan") {
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "AP4800"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            } else if (hostObj.frequency=='2.4' && mustApCounter['ap_24']<mustDevices['ap_24'].length) {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': mustDevices['ap_24'][mustApCounter['ap_24']]});
                if(recordObj[0]!=undefined){
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.location = getGroupNameHierarchy(recordObj[0].siteId);
                    t.clientConnection = recordObj[0].hostname;
                    mustApCounter['ap_24']++;
                }
            } else if (hostObj.frequency=='5.0' && mustApCounter['ap_5']<mustDevices['ap_5'].length) {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': mustDevices['ap_5'][mustApCounter['ap_5']]});
                if(recordObj[0]) {
                    t.connectedNetworkDeviceId = recordObj[0].id;
                    t.location = getGroupNameHierarchy(recordObj[0].siteId);
                    t.clientConnection = recordObj[0].hostname;
                }                
                mustApCounter['ap_5']++;
            } else {
                t.connectedNetworkDeviceId = wirelessDevices[indx].id;
                t.location = getGroupNameHierarchy(wirelessDevices[indx].siteId);
                t.clientConnection = wirelessDevices[indx].hostname;
            }

        } else {
            //to ensure IP phones connect to specified client below, make sure their userId is unique
            if(hostObj.hostName == 'SEP190C5D21F515') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "IE4K-POE_ExtNode"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            } else if(hostObj.hostName == 'SEP191C5D21F520') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "IE3400_3"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            } else if(hostObj.hostName == 'Camera1') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "IE-3300_6"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
                hostObj.port="GigabitEthernet1/6"
            } else if(hostObj.hostName == 'Camera2') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "IE-3300_6"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
                hostObj.port="GigabitEthernet1/5"
            }  else if(counter<8 ||  hostObj.userId==="becky") {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD1-FLR2-ACCESS"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
       
            }
            else if(counter<8 ||  hostObj.userId==="dnac.ise") {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD2-FLR2-ACCESS"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            } else if(hostObj.hostName == 'SEPF02572788A69') { //for POE requirement
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD1-FLR1-DST1"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            } else if(hostObj.hostName == '0022BDDC3D49') { //for POE requirement
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': "BLD1-FLR2-DST1"});
                t.connectedNetworkDeviceId = recordObj[0].id;
                t.location = getGroupNameHierarchy(recordObj[0].siteId);
                t.clientConnection = recordObj[0].hostname;
            }
            else{
                wiredDevices= wiredDevices.filter(function (itm) { 
                    return ( wiredDeviceExclusions.indexOf(itm.hostname) == -1) 
                });
                indx = UtilityFunctions.getRandomIntForRangeValues(1, wiredDevices.length) - 1;
                t.connectedNetworkDeviceId = wiredDevices[indx].id;
                t.location = getGroupNameHierarchy(wiredDevices[indx].siteId);
                t.clientConnection = wiredDevices[indx].hostname;
            }
             
        }

        var vlanId = 100;
        if(t.location && t.location.indexOf('SJC01') >= 0) {
            vlanId = 120;
        } else if(t.location && t.location.indexOf('SJC05') >= 0) {
            vlanId = 140;
        }

        t.vlanId = vlanId;
        return t;
    }

    function createHostDevices() {
        var f1 = 'Switches and Hubs', f2 = 'Routers', f3 = 'Unified AP';
        // var devices = SimLokiDatabaseActions.getAll('network-device');
        // adding specific devices should not have clients for special use case
        // added two AP ("SFO11-AP4800-01","SFO11-AP4800-02") also to ignore as part of demo should not be shown in topology hence let no device connect to it.
        // If client device can be connected, these two AP can be removed..
        var devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{ 'hostname' : {'$nin':["SJC06-C9300-01","SJC06-C9300-02","SJC06-C9300-03","SJC06-C9300-05","SJC06-C9300-04","SFO11-C9300-Edge-01","SFO11-AP4800-01","SFO11-AP4800-02","PLS06-C9300-01"]}}); 

        //var accessDevices = devices.filter(function(v){return v.role == 'ACCESS' });
        //var wiredDevices = accessDevices.filter(function(v){return v.family == f1 || v.family == f2 });
        //var wirelessDevices = accessDevices.filter(function(v){return v.family == f3 });
        var wiredDevices = devices.filter(function(v){return v.role == 'ACCESS' && (v.family == f1 || v.family == f2) });
        var wirelessDevices = devices.filter(function(v){return v.family == f3 });

        //var hosts = SimAssuranceDataGenerator.createAssuranceHostsAndUser();
        var hosts = SimAssuranceDataGenerator.createHosts();
        var tHosts = hosts.hosts;

        // LAB-AP00F2.8B27.B788- to ensure always connected to one client atleast, explicitly to have high utilization in kairos-heatMap
        var mustDevices = {'ap_24':["LAB-AP00F2.8B27.B788"], 'ap_5':["LAB-AP00F2.8B27.B788","SJC01_9136_12"]}, counter;
        var ap_24_list = DeviceIssueListing.issuesTemplate["radio_util_trigger"].devices;
        var ap_5_list = DeviceIssueListing.issuesTemplate["radio_util_trigger_ap"].devices;
        ap_24_list.forEach(function (dev) {
            mustDevices['ap_24'].indexOf(dev.hostName)==-1 ? mustDevices['ap_24'].push(dev.hostName):'';
        });
        ap_5_list.forEach(function (dev) {
            mustDevices['ap_5'].indexOf(dev.hostName)==-1 ? mustDevices['ap_5'].push(dev.hostName):'';
        });
        ap_24_list = CustomIssueListing.issuesTemplate["radio_util_trigger"].devices;
        ap_5_list = CustomIssueListing.issuesTemplate["radio_util_trigger_ap"].devices;
        ap_24_list.forEach(function (dev) {
            mustDevices['ap_24'].indexOf(dev.hostName)==-1 ? mustDevices['ap_24'].push(dev.hostName):'';
        });
        ap_5_list.forEach(function (dev) {
            mustDevices['ap_5'].indexOf(dev.hostName)==-1 ? mustDevices['ap_5'].push(dev.hostName):'';
        });        
        counter = {'ap_24':0, 'ap_5':0};

        for(var i = 0; i < tHosts.length; i++) {
            

            //This hack to match ssid in overall client graph
            if (i >= 0 && i <= 3) {

                tHosts[i].apGroup = 'Wave2_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = '@CorpSSID';
                    tHosts[i].frequency = '2.4';
                    // tHosts[i].dot11Protocol = 'PROTOCOL_802_11_B';
                    // tHosts[i].dot11ProtocolCapability = "802.11abg";
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AC';
                    tHosts[i].dot11ProtocolCapability = "802.11ac";
                    tHosts[i].dhcpServerIp="192.168.152.1"
                    tHosts[i].aaaServerIp="106.235.200.202"
                }
            }
            else if (i <= 5 && i > 3) {

                tHosts[i].apGroup = 'Wave2_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = '@CorpSSID';
                    tHosts[i].frequency = '5.0';
                    // tHosts[i].dot11Protocol = 'PROTOCOL_802_11_A';
                    // tHosts[i].dot11ProtocolCapability = "802.11abg";
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AC';
                    tHosts[i].dot11ProtocolCapability = "802.11ac";
                    tHosts[i].dhcpServerIp="100.30.189.51"
                    tHosts[i].aaaServerIp="109.7.150.69"
                }
            } else if (i > 5 && i < 14) {

                tHosts[i].apGroup = 'Wave1_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = 'c9800AP11AC';
                    tHosts[i].frequency = '2.4';
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AC';
                    tHosts[i].dot11ProtocolCapability = "802.11ac";
                    tHosts[i].dhcpServerIp="104.194.73.167"
                    tHosts[i].aaaServerIp="14.10.181.87"
                }

            } else if (i === 14) {

                tHosts[i].apGroup = 'Wave1_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = 'c9800AP11AC';
                    tHosts[i].frequency = '5.0';
                    // tHosts[i].dot11Protocol = 'PROTOCOL_802_11_G';
                    // tHosts[i].dot11ProtocolCapability = "802.11abg";
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AC';
                    tHosts[i].dot11ProtocolCapability = "802.11ac";
                    tHosts[i].dhcpServerIp="140.102.148.249"
                    tHosts[i].aaaServerIp="140.102.148.249"
                }

            }
            else if (i >= 15 && i <= 18) {

                tHosts[i].apGroup = 'Wave2_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = 'c9800AP11AX';
                    tHosts[i].frequency = '2.4';
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AX_24';
                    tHosts[i].dot11ProtocolCapability = "802.11ax";
                    tHosts[i].dhcpServerIp="116.140.161.52"
                    tHosts[i].aaaServerIp="158.128.154.123"
                }
            }
            //Adding wifi6 hosts from 19 to 30
            else if (i >= 19 && i <= 30) {

                tHosts[i].apGroup = 'Wave1_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = 'c9800AP11AX';
                    tHosts[i].frequency = '6.0';
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AX_6';
                    tHosts[i].dot11ProtocolCapability = "802.11ax6";
                    tHosts[i].dhcpServerIp="116.140.161.52"
                    tHosts[i].aaaServerIp="158.128.154.123"
                }
            }

            else if (i >= tHosts.length - 1) {

                tHosts[i].apGroup = 'Wave2_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = '';
                    tHosts[i].frequency = '2.4';
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_N_24';
                    tHosts[i].dot11ProtocolCapability = "802.11n";
                    tHosts[i].dhcpServerIp="118.130.12.121"
                    tHosts[i].aaaServerIp="170.204.94.112"
                }
            } else {
                tHosts[i].apGroup = 'Wave2_APs';
                if (tHosts[i].hostType != "WIRED") {
                    tHosts[i].ssid = 'c9800AP11AX';
                    tHosts[i].frequency = '5.0';
                    tHosts[i].dot11Protocol = 'PROTOCOL_802_11_AX_5';
                    tHosts[i].dot11ProtocolCapability = "802.11ax";
                    tHosts[i].dhcpServerIp="104.194.73.167"
                    tHosts[i].aaaServerIp="173.219.75.50"
                }
            }
            //tHosts[i].aaaServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
            //tHosts[i].aaaServerLatency=UtilityFunctions.getRandomIntForRangeValues(5,10)
            var t = getHostRelatedInformation(tHosts[i], wirelessDevices, wiredDevices, i, mustDevices, counter);

            /*var len = tHosts[i].hostType == 'WIRELESS' ? wirelessDevices.length : wiredDevices.length;
            var indx = UtilityFunctions.getRandomIntForRangeValues(1, len) - 1;

            if(tHosts[i].hostType == 'WIRELESS') {
                tHosts[i].connectedNetworkDeviceId = wirelessDevices[indx].id;
                tHosts[i].location = getGroupNameHierarchy(wirelessDevices[indx].siteId);
                tHosts[i].clientConnection = wirelessDevices[indx].hostname;
            } else {
                tHosts[i].connectedNetworkDeviceId = wiredDevices[indx].id;
                tHosts[i].location = getGroupNameHierarchy(wiredDevices[indx].siteId);
                tHosts[i].clientConnection = wiredDevices[indx].hostname;
            }

            var vlandId = 100;
            if(tHosts[i].location.indexOf('SJC01') >= 0) {
                vlanId = 200;
            } else if(tHosts[i].location.indexOf('SJC01') >= 0) {
                vlanId = 300;
            }
            tHosts[i].vlanId = vlanId;*/
            //udnInfo of Host device
            if(tHosts[i].ssid == "@CorpSSID"){
                if(tHosts[i].upnId == undefined){
                    tHosts[i].upnId = "16724112"
                    tHosts[i].upnName = "Grace Smith's upn"
                    tHosts[i].regUpnOwner = "Grace Smith"
                }
            }

            tHosts[i].connectedNetworkDeviceId = t.connectedNetworkDeviceId;
            tHosts[i].location = t.location;
            tHosts[i].clientConnection = t.clientConnection;
            tHosts[i].vlanId = t.vlanId;

            var noOfDay = UtilityFunctions.getRandomIntForRangeValues(1, 3);
           // var lastUpdated = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(noOfDay);
          //  tHosts[i].lastUpdated = lastUpdated;
            tHosts[i].lastUpdated = new Date().getTime();
            tHosts[i].onboarding.assocDoneTime = tHosts[i].onboarding.authDoneTime= tHosts[i].onboarding.dhcpDoneTime=tHosts[i].onboardingTime= new Date().getTime();

            if(tHosts[i].subType.indexOf('iPhone') >= 0 
                || tHosts[i].subType.indexOf('iPad') >= 0){
                tHosts[i].iosCapable = true;
            }
            else {
                tHosts[i].iosCapable = false;
            }
            var devData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':t.connectedNetworkDeviceId});
            var connectedDevice = [{
                "type": null,
                "name": devData[0].hostname,
                "mac": devData[0].macAddress,
                "id": devData[0].id,
                "ip address": null,
                "mgmtIp": devData[0].managementIpAddress,
                "band": null,
                "mode": "Unknown"
            }]
            tHosts[i].connectedDevice = connectedDevice
            tHosts[i].identifier = tHosts[i].hostName
            //Adding additional data to the Host in Ghost
            // var additionalHostData = JSON.parse(JSON.stringify(WirelessHostListing_more1.Additional_HostData))
            // additionalHostData.identifier = tHosts[i].hostName
            // tHosts[i].push(additionalHostData)
            SimLokiDatabaseActions.insert('host', tHosts[i]);
        }

    }

    function createWlan(networkDeviceId,profileId,siteId) {

        if(networkDeviceId!=undefined && profileId != '' && profileId != undefined) {
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'networkDeviceId': networkDeviceId});
            var profile = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'id': profileId});
            var profileAttributes = profile[0].profileAttributes;
            var ssidName ="";
            var profileObject, i, j;
            var siteSettingsObject;
            var siteDetails;
            for(i=0;i<profileAttributes.length;i++) {
                if(profileAttributes[i].key!=undefined && profileAttributes[i].key =="wireless.ssid") {
                    ssidName =profileAttributes[i].value;
                    profileObject= profileAttributes[i].attribs;
                }
                if(profileAttributes[i].key!=undefined && profileAttributes[i].key =="site.settings") {

                    siteDetails=profileAttributes[i].attribs;
                    for(j=0; j < siteDetails.length; j++) {
                        if(siteDetails[j].key != undefined && siteDetails[j].value == siteId) {
                            siteSettingsObject =siteDetails[j].attribs[0].attribs;
                        }
                    }
                }
            }
            var temp ="wlan.info."
            var key = temp.concat(ssidName)
            var wlanObject = SimLokiDatabaseActions.getFilteredRecordHandler('wlan', {'key': key});
          /*  var ssidArray =   wlanObject[0].value;
            var wlanProfileObject;
            for(i=0;i<ssidArray.length;i++) {
                if(ssidArray[i].ssid ==ssidName) {
                    wlanProfileObject =ssidArray[i];
                }
            } */
            var wlanProfileObject= wlanObject[0].value[0];
            var wlanId =  UtilityFunctions.generate_uuid();
            //updating the wlan reference in the device info.
            recordObj[0].wlan.push({ "idRef":wlanId });
            SimLokiDatabaseActions.update('DeviceInfo', recordObj);

            var tJson = JSON.parse(JSON.stringify(WlanTemplate.Wlan_Template));
            // setting the value (reference SPFUtil.java)
            var switchingMode = getKeyValue(profileObject,"wireless.trafficSwitchingMode");
            if(switchingMode=="local")  {
              switchingMode ="LOCAL_SWITCH";
            } else {
              switchingMode ="CENTRAL_SWITCH";
            }

            var trafficType = wlanProfileObject.trafficType;

            if(trafficType=="data")  {
              trafficType ="DATA";
            } else {
              trafficType ="VOICE_VIDEO";
            }

            //setting the AAA server details

            var primaryAddrObject = getAttributeValue(siteSettingsObject,"global.aaa.server.primary");
            var secondaryAddrObject = getAttributeValue(siteSettingsObject,"global.aaa.server.secondary");

            // setting wireless auth
            tJson.wirelessAuth.id =UtilityFunctions.generate_uuid();
            if(wlanProfileObject.authType=="web_consent") {
                 tJson.wirelessAuth.redirectUrl =wlanProfileObject.redirectUrl;
                 tJson.wirelessAuth.authServer =wlanProfileObject.authServer;
            } else if(wlanProfileObject.authType=="web_auth"){
               if(wlanProfileObject.externalAuthIpAddress!=undefined) {
                    var serverUrl = wlanProfileObject.externalAuthIpAddress.toString();
                    tJson.wirelessAuth.authServerIse =false;
                    tJson.wirelessAuth.authServerUrl =serverUrl;
                    tJson.wirelessAuth.authServerIp =serverUrl.substr(7, serverUrl.length);
               }else {
                   if((primaryAddrObject!=undefined && getKeyValue(primaryAddrObject,"is.ise.enabled")==true)||
                      (secondaryAddrObject!=undefined && getKeyValue(secondaryAddrObject,"is.ise.enabled")==true)) {
                     tJson.wirelessAuth.authServerIse =true;
                   } else {
                      tJson.wirelessAuth.authServerIse =false;
                   }
               }
            } else if(wlanProfileObject.authType=="wpa2_personal"){
                 tJson.wirelessAuth.passphrase=wlanProfileObject.passphrase;
            }else if(wlanProfileObject.authType=="wpa2_enterprise"){
                  tJson.wirelessAuth.authServer=getKeyValue(primaryAddrObject,"ip.address");
                  tJson.wirelessAuth.secAuthServer=getKeyValue(secondaryAddrObject,"ip.address");
                  tJson.wirelessAuth.isMacFilteringEnabled =wlanProfileObject.isMacFilteringEnabled;
            }else if(wlanProfileObject.authType=="open"){

            }

            tJson.id =   wlanId;
            tJson.instanceUuid = wlanId;
            tJson.profileName= generateWlanProfileName(wlanProfileObject.ssid,getKeyValue(profileObject,"wireless.fabric"),getKeyValue(profileObject,"wireless.flexConnect"));
            tJson.managedSites= getSiteList(profile[0]);
            tJson.networkDeviceId =  networkDeviceId;
            tJson.authMode =AUTH_MODE[wlanProfileObject.authType];
            tJson.fastTransition =wlanProfileObject.fastTransition;
            tJson.isEnabled =wlanProfileObject.isEnabled;
            tJson.isFastLane =wlanProfileObject.isFastLaneEnabled;
            tJson.isMacFilteringEnabled =wlanProfileObject.isMacFilteringEnabled;
            tJson.peerIp =wlanProfileObject.peerIp;
            tJson.ssid =wlanProfileObject.ssid;
            tJson.trafficType =trafficType;
            tJson.wlanType=wlanProfileObject.wlanType;
            tJson.switchingMode =switchingMode;
            tJson.isFlexConnect =getKeyValue(profileObject,"wireless.flexConnect");
            tJson.isFabric =getKeyValue(profileObject,"wireless.fabric");
            tJson.flexAuthMode =getKeyValue(profileObject,"wireless.authMode");
            //tJson.wlanId =getKeyValue(profileObject,"wireless.vlanId");
            tJson.wlanId = wlanId; //Please check if it is correct or not.. above line looks to be not correct
            tJson.vlanId =getKeyValue(profileObject,"wireless.vlanId");
            tJson.scalableGroupTag = "";
            SimLokiDatabaseActions.insert('wlan-ssid', tJson);
        }
    }

    function getKeyValue(profileObject, key) {
        var  value = "", i;
        if(profileObject != undefined && profileObject.length > 0) {
            for(i=0; i < profileObject.length; i++) {
                if(profileObject[i].key == key) {
                    value = profileObject[i].value;
                }
            }
        }
        return value;
    }

    function getAttributeValue(siteSettingObject,key) {
        var  attribs ="", i;
        if(siteSettingObject!=undefined) {
            for(i=0;i<siteSettingObject.length;i++) {
                if(siteSettingObject[i].key==key) {
                    attribs =siteSettingObject[i].attribs;
                }
            }
        }
        return attribs;
    }

    // for getting the site list for the profile
    function getSiteList(profile) {
        var siteList =[];
        var i;
        for(i=0;i<profile.sites.length;i++) {
            siteList.push(profile.sites[i].uuid);
        }
        return siteList;
    }

    function generateWlanProfileName(ssid,isFabric,isFlexConnect) {

       var profileName = "";
       var fabricString = "_NF";
       var uuid = UtilityFunctions.generate_uuid().toString().substr(0, 5);
        if (isFabric ==true) {
            fabricString = "_F";
        }
        if (isFlexConnect==true) {
            fabricString = "_FL";
        }

        return  profileName = ssid + fabricString + "_" + uuid;
    }

    function isFloor(siteId) {
        var isFloor =false;
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
        for (var i=0;i<siteRecord[0].additionalInfo.length ;i++) {
            if(siteRecord[0].additionalInfo[i].nameSpace== "Location" &&
                siteRecord[0].additionalInfo[i].attributes.type=="floor") {
                isFloor=true;
            }
        }
        return isFloor;
    }


     function createSensorForFloor(deviceId,siteId) {

        var floorMap = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid': siteId});
        var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : deviceId });

        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
        var tJson = JSON.parse(JSON.stringify( SensorTemplate.Sensor_Template));

        tJson.attributes.instanceUuid = UtilityFunctions.generate_uuid();
        tJson.attributes.name = deviceDetails[0].name;
        tJson.attributes.ipAddress= deviceDetails[0].managementIpAddress;
        tJson.attributes.macaddress =deviceDetails[0].macAddress;
        tJson.attributes.locationGroup =siteId;
        tJson.attributes.siteName=floorMap[0].siteName;
        tJson.attributes.buildingName=floorMap[0].buildingName;
        tJson.attributes.floorName=floorMap[0].name;
        tJson.attributes.heirarchyName=site[0].groupNameHierarchy;
        var defaultPoistionList = DefaultConfig.Default_Sensor;
        for(var i=0;i<defaultPoistionList.length;i++){
            if(defaultPoistionList[i].name==deviceDetails[0].name) {
                tJson.position = defaultPoistionList[i].position;
            }
        }
        SimLokiDatabaseActions.insert('sim-sensor', tJson);
    }
    function deleteAccessPointForFloor(siteId) {
        var tJson = SimLokiDatabaseActions.getFilteredRecordHandler('access-point', {'locationGroup' : siteId });
        if(site.length>0) {
            SimLokiDatabaseActions.delete('access-point', tJson);
        }
    }

    function updateSiteInfo(networkDeviceId,siteId) {

        var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId' :  networkDeviceId});

         if(deviceObj!=undefined && deviceObj.length>0) {
            deviceObj[0].siteId= siteId;
            SimLokiDatabaseActions.update('DeviceInfo', deviceObj);
         }
    }
    function createfunctionalCapability(){
        // Creating for StealthWatch
        var dbObj = {}
        var id = UtilityFunctions.generate_uuid();
        dbObj['deviceId'] = id
        dbObj['current_status'] = "DISABLED"
        SimLokiDatabaseActions.insert('functional-capability', dbObj)  
    }

    function getMaxProtocolFreqForAp(connectedNetworkDeviceId, isApplicableForWifi6) {
        var moreDtls = {};
        var hosts = SimLokiDatabaseActions.getFilteredRecordHandler("host", {"connectedNetworkDeviceId":connectedNetworkDeviceId});
        
        var protocolsAssociated = hosts.map(host => host.dot11Protocol);
        
        var maxProtocol = protocolsAssociated.filter(val => 
            SimulationUtility.WIFI_6_PROTOCOL_MAPPING_INFO['7.0'].protocolsCovered.includes(val));
        if(maxProtocol.length > 0) {
            moreDtls["protocol"] = "7";
        } else {
            maxProtocol = protocolsAssociated.filter(val => 
                SimulationUtility.WIFI_6_PROTOCOL_MAPPING_INFO['5.0'].protocolsCovered.includes(val));
            if(maxProtocol.length > 0) {
                moreDtls["protocol"] = "5";
            } else{
                maxProtocol = protocolsAssociated.filter(val => 
                    SimulationUtility.WIFI_6_PROTOCOL_MAPPING_INFO['4.0'].protocolsCovered.includes(val));
                if(maxProtocol.length > 0) {
                    moreDtls["protocol"] = "4";
                } else {
                    maxProtocol = protocolsAssociated.filter(val => 
                        SimulationUtility.WIFI_6_PROTOCOL_MAPPING_INFO['2.0'].protocolsCovered.includes(val));
                    if(maxProtocol.length > 0) {
                        moreDtls["protocol"] = "2";
                    } else {
                        maxProtocol = protocolsAssociated.filter(val => 
                            SimulationUtility.WIFI_6_PROTOCOL_MAPPING_INFO['1.0'].protocolsCovered.includes(val));
                        if(maxProtocol.length > 0) {
                            moreDtls["protocol"] = "1";
                        } else {
                            moreDtls["protocol"] = "0";
                        }
                    }
                }
            }
            
        }
        var freqAssociated = hosts.map(host => host.frequency);
        if(freqAssociated.indexOf("6.0") > -1){
            moreDtls["slot"] = "3";
        }else if(freqAssociated.indexOf("2.4") > -1 && freqAssociated.indexOf("5.0") > -1 ) {
            moreDtls["slot"] = "2";
        } else if(freqAssociated.indexOf("5.0") > -1) {
            moreDtls["slot"] = "1";
        } else if(freqAssociated.indexOf("2.4") > -1 ) {
            moreDtls["slot"] = "0";
        } else {
            moreDtls["slot"] = "-1";
        }
        if(hosts.length == 0) {
            if(isApplicableForWifi6) {
                moreDtls["protocol"] = "5";
            } else {
                moreDtls["protocol"] = "4";
            }
            moreDtls["slot"] = "-1";
        }
        return moreDtls;
    }

    function updateSpecificDeviceConfig(hostname, networkDeviceId, tJson) {
        let configKey = "IE3k_Config_Template";
        let provDeviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler("DeviceInfo", {"networkDeviceId":networkDeviceId});
        if(provDeviceInfo.length>0 && provDeviceInfo[0].customProvisions) {
            //device is reprovisioned with template
            tJson = tJson.replace("$IOT_Template", DeviceConfiguration1[configKey]);
        } else {
            tJson = tJson.replace("$IOT_Template", "");//return original configuration
        }
        return tJson;
    }

    function updateConfigFields(tJson, device) {
        var img = SimLokiDatabaseActions.getFilteredRecordHandler('device-image',{'deviceId':device.id});
        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':device.siteId});
        var interfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', {'$and':[
            {'deviceId':device.id}, {'interfaceType':'Physical'} ] });
        var portChlIntfs = SimLokiDatabaseActions.getFilteredRecordHandler('interface-port-channel',{'networkDeviceId':device.id});
        var allMemberIntfs = {}, isIotTemplateUsecase = false;
        let provDeviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler("DeviceInfo", {"networkDeviceId":device.id});

        if(provDeviceInfo.length>0 && provDeviceInfo[0].customProvisions) {
            //device is reprovisioned with template
            tJson = tJson.replace("$IOT_Template", DeviceConfiguration1.IE3k_Config_Template_1);
            isIotTemplateUsecase = true;
        } else {
            tJson = tJson.replace("$IOT_Template", "");//return original configuration
        }
        if(portChlIntfs.length>0) {
            portChlIntfs = portChlIntfs[0].interfaceInfo;
            portChlIntfs.forEach(e => {
                let port = e.interfaceName, portChlIdx = port.match(/\d+/)[0], memberIntf = e.memberInterfaces;
                memberIntf = memberIntf.map(i => i.interfaceName);
                memberIntf.forEach(x => {
                    allMemberIntfs[x] = portChlIdx;
                })  ;              
            })
        }
        let bootVersion = img.length>0 ? img[0].deviceInstalledInfo.name : device.version;
        let ipArr = device.managementIpAddress.split(".");
        let updatedIp1 = ipArr[0]+"."+ipArr[1]+".20.20", updatedIp2 = ipArr[0]+"."+ipArr[1]+"."+ipArr[2]+".30";
        var portChannelInterface = "", interfaceEntry = "";

        tJson = tJson.replace(/\$hostname/g, device.name);
        tJson = tJson.replace(/\$version/g, device.softwareVersion);
        tJson = tJson.replace(/\$aaa_server/g, "171.71.10.10");//"171.71.186.86"
        tJson = tJson.replace(/\$ipAddr/g, device.managementIpAddress);
        tJson = tJson.replace(/\$boot_version/g, bootVersion);
        tJson = tJson.replace(/\$pid/g, device.platformId);
        tJson = tJson.replace(/\$updatedIpAddr/g, updatedIp1);
        tJson = tJson.replace(/\$updatedIpAddr2/g, updatedIp2);
        tJson = tJson.replace(/\$sitename/g, "Demo-Lab "+(site.length>0 ? site[0].name : "San Jose"));
        if(portChlIntfs.length>0) {
            for(let p=1; p<=portChlIntfs.length; p++) {
                var portChl = JSON.parse(JSON.stringify(DeviceConfiguration1.PortChannelInterfaceTemplate));
                portChl = portChl.replace("Port-channel1","Port-channel"+p);
                portChannelInterface += portChl;
            }
        } else {
            portChannelInterface = "!";
        }
        tJson = tJson.replace("$portChannelInterface", portChannelInterface);
        interfaces.forEach(intf => {
            if('GigabitEthernet0/0' == intf.portName) return;
            if(allMemberIntfs[intf.portName]) {
                var tIntf = DeviceConfiguration1.InterfaceWithPortChannelTemplate;
                tIntf = tIntf.replace('GigabitEthernet1/9', intf.portName);
                tIntf = tIntf.replace('$PortChannelNo', allMemberIntfs[intf.portName]);
                interfaceEntry += tIntf;
            } else {
                var tIntf = DeviceConfiguration1.InterfaceTemplate;
                tIntf = tIntf.replace('GigabitEthernet1/1/3', intf.portName);
                interfaceEntry += tIntf;
            }
            if(isIotTemplateUsecase && "GigabitEthernet1/1" == intf.portName) {
                interfaceEntry += DeviceConfiguration1.IE3k_Config_Template_2;
            }
        });
        tJson = tJson.replace("$interfaceEntry", interfaceEntry);
        return tJson;
    }

    function updatePoeData(records) {
        var validRecords = [], validTypes= ['Cisco Catalyst 9300 Switch','Cisco Catalyst WS-C3850-12X48U-S Switch',
        'Cisco Catalyst 9404R Switch','Cisco Catalyst 9300L Switch Stack'];
        records.forEach(record => {
          if(validTypes.indexOf(record.type) > -1) {
            validRecords.push(record);
          }
        });
        if(validRecords.length>0) SimPoeAnalyticsData.createPoeDetails(validRecords);
    }

    function createSdwanUsecaseTopology(sdwanDevices, autoAddDev) {
        var routerDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': {'$in': sdwanDevices}});
        var switchDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': {'$in': autoAddDev}});
        var topologySite = [];
        routerDevices.forEach(router => {
            //ISR routers connected to Cloud icon
            SimTopologyData.addDevice(DefaultConfig.DEFAULT_UUID.TopologyCloudNode, [router]);
            SimTopologyData.updateSiteDetails(router.id, router.siteId);
        });
        switchDevices.forEach(switchDev => {
            routerDevices.forEach(router => {
                //Each Switch connected to all Routers above
                SimTopologyData.addDevice(router.id, [switchDev]);
                SimTopologyData.updateSiteDetails(switchDev.id, switchDev.siteId);
                topologySite.push({"source":[router.hostname], "target":[switchDev.hostname]});
            });
        });
        SimTopologyData.updateTopology(topologySite);
    }

});
