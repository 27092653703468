define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/GlobalCredentialTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
], function(SimLokiDatabaseActions, GlobalCredentialTemplate,SimTaskDataGenerator,UtilityFunctions){



 return {
      init: function(){
      },

      getCredential : function (urlAction) {
        var credObj;
        if(urlAction.filter.indexOf('credentialSubType') >= 0){
            credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', { 'credentialSubType' : urlAction.filter['credentialSubType'] });
        }
        if(urlAction.service.indexOf('credential-update') >= 0){
            var credObj = JSON.parse(JSON.stringify(GlobalCredentialTemplate.Credential_Update_Info_Template ));
        }
        return credObj != undefined ? credObj:[];
      } ,

      createCredential:function (urlAction) {

           var resultObj = SimTaskDataGenerator.createTask('SPFService');
           var inputData =urlAction.restPayload;
           if(urlAction.service.indexOf("cli")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.CLI_Info_Template ));
                console.log(tJsonArr);
               var id = UtilityFunctions.generate_uuid();
               tJsonArr.password =inputData[0].password;
               tJsonArr.enablePassword =inputData[0].enablePassword;
               tJsonArr.username =inputData[0].username;
               tJsonArr.description =inputData[0].description;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );

          }  else if(urlAction.service.indexOf("http-read")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.HTTP_Info_Template ));
                console.log(tJsonArr);
                var id = UtilityFunctions.generate_uuid();
               tJsonArr.password =inputData[0].password;
               tJsonArr.username =inputData[0].username;
               tJsonArr.description =inputData[0].description;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               tJsonArr.port =inputData[0].port;
               tJsonArr.secure =inputData[0].secure;
               tJsonArr.credentialSubType ="HTTP_READ";


               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );


          } else if(urlAction.service.indexOf("http-write")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.HTTP_Info_Template ));
                console.log(tJsonArr);
               var id = UtilityFunctions.generate_uuid();
               tJsonArr.password =inputData[0].password;
               tJsonArr.username =inputData[0].username;
               tJsonArr.description =inputData[0].description;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               tJsonArr.port =inputData[0].port;
               tJsonArr.secure =inputData[0].secure;
               tJsonArr.credentialSubType ="HTTP_WRITE";


               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );


          }else if(urlAction.service.indexOf("snmpv3")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP3_Info_Template ));
                console.log(tJsonArr);
                var id = UtilityFunctions.generate_uuid();
               tJsonArr.username =inputData[0].username;
               tJsonArr.authType =inputData[0].authType;
               tJsonArr.authPassword =inputData[0].authPassword;
               tJsonArr.snmpMode =inputData[0].snmpMode;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               tJsonArr.privacyPassword =inputData[0].privacyPassword;
               tJsonArr.privacyType =inputData[0].privacyType;
               tJsonArr.description =inputData[0].description;
               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );


          }else if(urlAction.service.indexOf("snmpv2-write-community")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Write_Info_Template ));
                console.log(tJsonArr);
                var id = UtilityFunctions.generate_uuid();
               tJsonArr.writeCommunity =inputData[0].writeCommunity;
               tJsonArr.description =inputData[0].description;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );


          }else if(urlAction.service.indexOf("snmpv2-read-community")>=0) {
               var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Read_Info_Template ));
                console.log(tJsonArr);
               var id = UtilityFunctions.generate_uuid();
               tJsonArr.readCommunity =inputData[0].readCommunity;
               tJsonArr.description =inputData[0].description;
               tJsonArr.instanceUuid =id;
               tJsonArr.id =id;
               console.log(tJsonArr);
               SimLokiDatabaseActions.insert('global-credential', tJsonArr );
           }

           SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
           return resultObj;
      },

       updateCredential :function (urlAction) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var inputData = urlAction.restPayload;
            var id = inputData.id;
            var tJsonArr = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', {'id': id});

                       console.log(tJsonArr);

               if(urlAction.service.indexOf("cli")>=0) {
                   tJsonArr[0].password =inputData.password;
                   tJsonArr[0].enablePassword =inputData.enablePassword;
                   tJsonArr[0].username =inputData.username;
                   tJsonArr[0].description =inputData.description;
              }  else if(urlAction.service.indexOf("http-read")>=0) {
                   tJsonArr[0].password =inputData.password;
                   tJsonArr[0].username =inputData.username;
                   tJsonArr[0].description =inputData.description;
                   tJsonArr[0].port =inputData.port;
                   tJsonArr[0].secure =inputData.secure;
              } else if(urlAction.service.indexOf("http-write")>=0) {
                   tJsonArr[0].password =inputData.password;
                   tJsonArr[0].username =inputData.username;
                   tJsonArr[0].description =inputData.description;
                   tJsonArr[0].port =inputData.port;
                   tJsonArr[0].secure =inputData.secure;
              }else if(urlAction.service.indexOf("snmpv3")>=0) {
                   tJsonArr[0].username =inputData.username;
                   tJsonArr[0].authType =inputData.authType;
                   tJsonArr[0].authPassword =inputData.authPassword;
                   tJsonArr[0].snmpMode =inputData.snmpMode;
                   tJsonArr[0].privacyPassword =inputData.privacyPassword;
                   tJsonArr[0].privacyType =inputData.privacyType;
                   tJsonArr[0].description =inputData.description;
              }else if(urlAction.service.indexOf("snmpv2-write-community")>=0) {
                   tJsonArr[0].writeCommunity =inputData.writeCommunity;
                   tJsonArr[0].description =inputData.description;
              }else if(urlAction.service.indexOf("snmpv2-read-community")>=0) {
                   tJsonArr[0].readCommunity =inputData.readCommunity;
                   tJsonArr[0].description =inputData.description;
               }

                SimLokiDatabaseActions.update('global-credential', tJsonArr);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return resultObj;
        },

      deleteCredential :function (urlAction) {

            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var id = urlAction.action.id;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', {'id': id});
            SimLokiDatabaseActions.delete('global-credential', record);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
      }


  };
});
