define({

    "WIFI6_TROUBLESHOOT_METADATA": [
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/TestPingNFLoop/0.0.0",
      "displayName": "PingTestNF",
      "description": "Algorithm to start to ping",
      "id": "test_loop_ping",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/InterfaceFlapAssuranceOntology/1.2.0",
      "displayName": "Interface Flap",
      "description": "Analyze why an interface flap is taking place on a device",
      "id": "infra_link_down",
      "continueOnError": true,
      "networkImpact": "L"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceL2BroadcastStorm/2.0.0",
      "displayName": "L2 STP Loop Detection",
      "description": "L2 STP Loop Detection",
      "id": "mac_flap_trigger",
      "continueOnError": true,
      "networkImpact": "XS"
    },
    /*{
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcClientIseSyslogs/0.0.0",
      "displayName": "Analyze Client ISE syslog messages to derive root cause of the issue",
      "description": "Analyze client ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "wireless_client_auth_fail_aaa_timeout_trigger",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AAAAssuranceIssueTrigger/1.0.0",
      "displayName": "AssuranceAAAAuthIssueTrigger",
      "description": "Validate the issue trigger from Assurance",
      "id": "wired_client_auth_fail_mab_trigger",
      "continueOnError": true,
      "networkImpact": "L"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/PoeFaultTroubleShoot/1.0.0",
      "displayName": "PoeFaultTroubleShooting",
      "description": "Validate the issue trigger from Assurance",
      "id": "network_device_controller_poe_trigger",
      "continueOnError": true,
      "networkImpact": "XS"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceTroubleshootCPU/1.0.0",
      "displayName": "CPU Utilization",
      "description": "Troubleshoot causes for high CPU Utilization on a device",
      "id": "snmp_cpu_high_80",
      "continueOnError": true,
      "networkImpact": "XS"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcClientIseSyslogs/0.0.0",
      "displayName": "Analyze Client ISE syslog messages to derive root cause of the issue",
      "description": "Analyze client ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "wireless_client_excess_auth_aaa_fail_trigger",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceDHCPIssueTrigger/1.0.0",
      "displayName": "AssuranceDHCPIssueTrigger",
      "description": "Validate the issue trigger from Assurance",
      "id": "dhcp_reachability_issue",
      "continueOnError": true,
      "networkImpact": "L"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcGlobalIseSyslogs/0.0.0",
      "displayName": "Analyze ISE syslog messages to derive root cause of the issue",
      "description": "Analyze ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "global_wireless_client_excess_auth_aaa_fail_trigger",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcClientIseSyslogs/0.0.0",
      "displayName": "Analyze Client ISE syslog messages to derive root cause of the issue",
      "description": "Analyze client ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "wireless_client_auth_fail_aaa_rej_trigger",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceTroubleshootCPU/1.0.0",
      "displayName": "CPU Utilization",
      "description": "Troubleshoot causes for high CPU Utilization on a device",
      "id": "switch_cpu_high",
      "continueOnError": true,
      "networkImpact": "XS"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcGlobalIseSyslogs/0.0.0",
      "displayName": "Analyze ISE syslog messages to derive root cause of the issue",
      "description": "Analyze ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "global_wireless_client_auth_fail_aaa_rej_trigger",
      "networkImpact": "S"
    },*/
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceTroubleshootPS/1.1.0",
      "displayName": "Power Supply",
      "description": "Determine the root cause for power supply problems on a device",
      "id": "switch_issue_power",
      "continueOnError": true,
      "networkImpact": "XS"
    },/*
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/RcGlobalIseSyslogs/0.0.0",
      "displayName": "Analyze ISE syslog messages to derive root cause of the issue",
      "description": "Analyze ISE syslog messages from various servers to derive the possible root causes which could have triggered the issue",
      "id": "global_wireless_client_auth_fail_aaa_timeout_trigger",
      "networkImpact": "S"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceTroubleshootCPU/1.0.0",
      "displayName": "CPU Utilization",
      "description": "Troubleshoot causes for high CPU Utilization on a device",
      "id": "router_cpu_high",
      "continueOnError": true,
      "networkImpact": "XS"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AAAAssuranceIssueTrigger/1.0.0",
      "displayName": "AssuranceAAAAuthIssueTrigger",
      "description": "Validate the issue trigger from Assurance",
      "id": "wired_client_auth_fail_dot1x_trigger",
      "continueOnError": true,
      "networkImpact": "L"
    },*/
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceDHCP/0.0.0",
      "description": "Identify the client MAC address.",
      "id": "wireless_client_excessive_onboarding"
    },
    {
      "ontologyVersionIri": "http://www.cisco.com/CNSR/AssuranceDHCP/0.0.0",
      "description": "Identify the client MAC address.",
      "id": "wireless_client_onboarding"
    }
  ]

});


//This Template is about issues which supports MRE. Commented some of the issues which are not supported for MRE from our simulation.