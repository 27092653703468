define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "b3c92e43-bd59-4d31-8684-f429732f94cb",
    "name": "network_device_poe_err_disabled_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.27.108.12",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "PoE port on DeviceNamePlaceHolder InterfaceDownPlaceHolder is in error disabled state",
    "groupId": "PoE_port_failure_grouping",
    "groupName": "PoE failures on network device",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "PM-4-ERR_DISABLE",
    "timestamp": 1587693800873,
    "description": "PoE port on DeviceNamePlaceHolder InterfaceDownPlaceHolder is in error disabled state. Reason: Power error detected.",
    "suggestions": [
      {
        "message": "Check operational details of the affected interface Gi10/0/1. Compare actual consumption and negotiated values",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Detailed inline power status",
            "command": "sh power inline InterfaceDownPlaceHolder detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check inline power status",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Inline power status of all interfaces",
            "command": "show power inline",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check PoE policing status and power cutoff",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "PoE policing status",
            "command": "show power inline police",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Review the vendor specifications of the powered device to confirm its IEEE standard compliance"
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "172.27.108.12"
      },
      {
        "key": "_name",
        "value": "network_device_poe_err_disabled_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.27.108.12:Gi10/0/1:Gi10/0/1"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "messageType",
        "value": "PM-4-ERR_DISABLE"
      },
      {
        "key": "areaName",
        "value": "Global"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "PM-4-ERR_DISABLE"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.27.108.12"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9410R Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "10cd0dc9-b120-4b48-a319-631e5d2067a9"
      },
      {
        "key": "messageText",
        "value": "804: 000804: Apr 23 19:03:20.727 PDT:  inline-power error detected on Gi10/0/1, putting Gi10/0/1 in err-disable state"
      },
      {
        "key": "param5",
        "value": ""
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "1800"
      },
      {
        "key": "deviceOS",
        "value": "17.2.1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "ewcheung_10slot.network.stanfordmed.org"
      },
      {
        "key": "_eventTime",
        "value": "1587693800873"
      },
      {
        "key": "_mostRecent",
        "value": "1587693800873"
      },
      {
        "key": "entityId",
        "value": "172.27.108.12"
      },
      {
        "key": "eventUniqueId",
        "value": "172.27.108.12"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "param3",
        "value": ""
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "param4",
        "value": ""
      },
      {
        "key": "param1",
        "value": "Gi10/0/1"
      },
      {
        "key": "param2",
        "value": "Gi10/0/1"
      },
      {
        "key": "buildingId",
        "value": "65d0c2c1-62c5-48b9-b60f-5a8173cbc257"
      },
      {
        "key": "buildingName",
        "value": "Global/ewcheung-sjc"
      },
      {
        "key": "areaId",
        "value": "Global"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/ewcheung-sjc"
      },
      {
        "key": "deviceModel",
        "value": "FXS2118Q1GM"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2020-04-24T02:03:20.873+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})