define({
  "CategoriesInfo_TEMPLATE" : {
    "list": [
      {
        "role": "Routers",
        "totalKPI": 7,
        "inUseKPI": 7,
        "displayKey": "Routers"
      },
      {
        "role": "Switches and Hubs",
        "totalKPI": 6,
        "inUseKPI": 6,
        "displayKey": "Switches_and_Hubs"
      },
      {
        "role": "Wireless Controller",
        "totalKPI": 7,
        "inUseKPI": 6,
        "displayKey": "Wireless_Controller"
      },
      {
        "role": "Unified AP",
        "totalKPI": 11,
        "inUseKPI": 9,
        "displayKey": "Unified_AP"
      }
    ]
  },
  
  "RouterKPIList_TEMPLATE": [
    {
      "_id": null,
      "siteName": "Global",
      "siteId": "global",
      "ctTenantId": null,
      "saveType": "DEFAULT",
      "deviceType": "Routers",
      "deviceRole": "Routers",
      "kpi": "cpuUtilizationThreshold",
      "displayKey": "ct_hs_cpu_utilization",
      "includeInDevHealthScore": true,
      "lastUpdated": null,
      "version": "1.0",
      "tenantId": null,
      "notes": "",
      "thresholdType": "NetworkHealthScore",
      "innerData": {
        "unit": "%",
        "poorValue": 90,
        "poorDefaultValue": 95,
        "poorMinValue": 0,
        "poorMaxValue": 100,
        "poorOperator": ">",
        "goodValue": 90,
        "goodDefaultValue": 95,
        "goodMinValue": 0,
        "goodMaxValue": 100,
        "goodOperator": "<=",
        "goodValueString": "",
        "poorValueString": "",
        "fairValueString": ""
      }
    }
  ]

})