define({
    "ScalableGroup_Network_Application_Template":

      {
      "id": "00059d6a-7131-4f53-8601-3852e98c25f4",
      "instanceId": 4879,
      "displayName": "4879",
      "instanceTenantId": "5ae9b91d010d180084de7523",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationType": "DEFAULT",
      "engineId": "3",
      "identitySourceType": "NBAR",
      "name": "npmp-trap",
      "parentScalableGroup": "e8f41560-7b78-4672-8089-bb51ed77b6a8",
      "parentScalableGroupId": 4047,
      "parentScalableGroupName": "network-management",
      "rank": 65535,
      "trafficClass": "OPS_ADMIN_MGMT"
    }

    });