define({
    "getIpListTemplate":
    {
              "isIPv6": false,
              "hasMoreThanLimit": false,
              "ipList": [{ "ipAddress": "192.168.0.113",
                        "hostType": "Camera1"

              }, { "ipAddress": "10.16.32.45",
                    "hostType": "IP Phone"
              }, {
                    "ipAddress": "10.70.1.102",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.1.93",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.0.194",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "192.168.151.176",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.10.125.30",
                    "hostType": "Wired"
              }, {
                    "ipAddress": "10.70.15.188",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.1.95",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.43.233",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "192.168.151.177",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "192.168.151.181",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.1.101",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "192.168.151.178",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.1.96",
                    "hostType": "Wireless"
              }, {
                    "ipAddress": "10.70.15.187",
                    "hostType": "Wireless"
              }]
        }

})