define({
  "AppAggbyIdentifierMacTemplate": {
    version: "1.0",
    response: [
      {
        identifier: "00:50:56:AE:C1:58",
        name: "cisco lightt2",
        totalTimeMilis: 44450957,
        score: 7.0,
        count: 50,
      },
    ],
  },

  "AppAggbyIdentifierApTemplate": {
    version: "1.0",
    response: [
      {
        identifier: "8f54f9df-310d-4f56-9c52-8901c9768d7b",
        name: "C9200-L2-SW.cisco.com",
        totalTimeMilis: 44450957,
        score: 7.0,
        count: 50,
      },
    ],
  },
  "AppApDeviceAnalytics": {
    version: "1.0",
    response: [
      {
        identifier: "8f54f9df-310d-4f56-9c52-8901c9768d7b",
        name: "C9200-L2-SW.cisco.com",
        value: 5624.0,
        valueUnit: "bytes",
      },
      {
        identifier: "2dc303fb-1ffl-4bab-b0be-57a8db52cc37",
        name: "SW_TREX.cisco.com",
        value: 3320.0,
        valueUnit: "bytes",
      },
      {
        identifier: "9ecf96c5-65c0-4ac6-9f68-3d61e77d48cb",
        name: "CAt9300-L2-SW.cisco.com",
        value: 900.0,
        valueUnit: "bytes",
      },
    ],
  },
  "AppAggregateTemplate":
  {
    "version": "1.0",
    "response": {
        "msteamsAggregateMinutes": {
            "poorMinutes": 0,
            "fairMinutes": 741,
            "goodMinutes": 0,
            "activeClients": 1
        },
        "msteamsGraphResult" : []
    }
},
"HealthTrendInfoTemplate":
{
    "version": "1.0",
    "response": {
        "summary": {
            "averageRate": 11.19,
            "totalUsage": 11746,
            "activeClients": 1
        },
        "trend": [
            {
                "collToolsAppName": "ms-teams-app-sharing",
                "minHealthScore": null,
                "avgHealthScore": null,
                "businessRelevance": [
                    "business-relevant"
                ],
                "trafficClass": [
                    "multimedia-conferencing"
                ],
                "healthTrend": [
                    {
                        "time": "2022-11-21T11:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": "",
                        "usage": 600,
                        "rate": 8
                    }
                ],
                "category": [
                    "collaboration-apps"
                ]
            },
            {
                "collToolsAppName": "ms-teams-video",
                "minHealthScore": null,
                "avgHealthScore": null,
                "businessRelevance": [
                    "business-relevant"
                ],
                "trafficClass": [
                    "multimedia-conferencing"
                ],
                "healthTrend": [
                    {
                        "time": "2022-11-15T17:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": "",
                        "usage": 300,
                        "rate": 4
                    }
                ],
                "category": [
                    "collaboration-apps"
                ]
            },
            {
                "collToolsAppName": "ms-teams-audio",
                "minHealthScore": null,
                "avgHealthScore": null,
                "businessRelevance": [
                    "business-relevant"
                ],
                "trafficClass": [
                    "multimedia-conferencing"
                ],
                "healthTrend": [
                    {
                        "time": "2022-11-14T23:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": "",
                        "usage": 488,
                        "rate": 6.51
                    }
                ],
                "category": [
                    "collaboration-apps"
                ]
            },
            {
                "collToolsAppName": "total",
                "minHealthScore": null,
                "avgHealthScore": null,
                "businessRelevance": [
                    "business-relevant"
                ],
                "trafficClass": [
                    "multimedia-conferencing"
                ],
                "healthTrend": [
                    {
                        "time": "2022-11-21T11:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 600,
                        "rate": 8
                    },
                    {
                        "time": "2022-11-21T06:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 316,
                        "rate": 4.21
                    },
                    {
                        "time": "2022-11-21T00:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-20T22:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 7326,
                        "rate": 97.68
                    },
                    {
                        "time": "2022-11-20T19:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-20T13:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 316,
                        "rate": 4.21
                    },
                    {
                        "time": "2022-11-15T17:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-15T15:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-15T05:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-15T04:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-15T01:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-14T23:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 488,
                        "rate": 6.51
                    },
                    {
                        "time": "2022-11-14T20:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    },
                    {
                        "time": "2022-11-14T17:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "clientPacketLoss": null,
                        "serverPacketLoss": null,
                        "jitter": null,
                        "clientJitter": null,
                        "serverJitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": 300,
                        "rate": 4
                    }
                ],
                "category": [
                    "collaboration-apps"
                ]
            }
        ]
    }
}

});
