define({
    //For KPIs that use MetaData, a mapping is created
    "MetaData_Node_Template": {
        "__typename":"PcMetadata36GetPcType",
        "ncubes":[],
        "pcId":"a685ef77f009977f4723",//to update
        "sample":[]
    },

    "MetaData_nCube_Template": {
        "__typename":"SmartDashboardStats36sWithCommonVariableType",
        "commonVariables":[{"__typename":"NCubeCommonVariables","value":"5 GHz","variable":"frequencyBand"}],
        "count":35,
        "customerId":"ldz6ctrh",
        "endTimestamp":"2020-06-20T00:00:00",
        "entityId":"a4416245dfd4af5b63e7",//update with nCubeId
        "kpiName":"logTotalBitsPerSec",
        "mean":2.25909376144409,//mean related to nCubeId from pcRecords
        "ncubeDescription":"{\"cluster_centers\": {\"mean\": 2.4171474491808955, \"percentile_10\": 0.0, \"percentile_25\": 0.0, \"percentile_50\": 4.926614671774132e-16, \"percentile_75\": -8.881784197001252e-15, \"percentile_90\": 14.733990357432715}, \"silhouette\": 0.9024600789320778}",
        "percentiles":[
            {"__typename":"DistributionPercentile","percentile":10,"value":0},
            {"__typename":"DistributionPercentile","percentile":25,"value":0},
            {"__typename":"DistributionPercentile","percentile":50,"value":0},
            {"__typename":"DistributionPercentile","percentile":75,"value":0},
            {"__typename":"DistributionPercentile","percentile":90,"value":15.162914276123}
        ],
        "startTimestamp":"2020-06-13T00:00:00",
        "variance":31.9010200500488
    },

    "MetaData_Sample_Template": {
        "__typename":"SmartDashboardNCubes36SampleType",
        "aesApGroup":"aes:hemb-WaD3ucwhKIbWT7JUDu-G5jhZDri",
        "aesApName":"aes:ji9T6vaSHbxXWm-ViNomtisWxUbJPSOlBnVliEEoeF6iJw==",//to update randomly
        "apModel":"AIR-CAP3602I-A-K9",//to update randomly
        "countEntities":1,
        "groupingField":"radioEntityId",
        "groupingFieldValue":"029bb2b1d884bb76a7d4",
        "kpiStringCount":"{}",
        "ncubeId":"a4416245dfd4af5b63e7",//update with nCubeId
        "pcId":"a685ef77f009977f4723",//update pcId from restpayload
        "percentiles":[
            {"__typename":"DistributionPercentile","percentile":10,"value":1},
            {"__typename":"DistributionPercentile","percentile":25,"value":1},
            {"__typename":"DistributionPercentile","percentile":50,"value":1},
            {"__typename":"DistributionPercentile","percentile":75,"value":1},
            {"__typename":"DistributionPercentile","percentile":90,"value":1}//update the count in value
        ],
        "radioProtocol":"IEEE 802.11N",//to update randomly
        "siteId":"010395cc-901f-438b-9886-c583c6515a05",//update siteId from restpayload
        "timestamp":"2020-06-19T00:00:00"//update timestamp from restpayload
    },

    "pcDistributionDetailRange_Template": {
        "__typename":"PcDistribution36type",
        "countEntities":12,//random number
        "groupingFieldValue":"ecc8765c-0042-4ae8-9b4d-425eb7893868",//to update
        "kpiStringCount":"{}",
        "ncubeId":"a4416245dfd4af5b63e7",//to update
        "pcId":"a685ef77f009977f4723",//to update
        "percentiles":[
            {"__typename":"DistributionPercentile","percentile":10,"value":0},
            {"__typename":"DistributionPercentile","percentile":25,"value":0},
            {"__typename":"DistributionPercentile","percentile":50,"value":0},
            {"__typename":"DistributionPercentile","percentile":75,"value":0},
            {"__typename":"DistributionPercentile","percentile":90,"value":0}
        ],
        "timestamp":"2019-11-28T00:00:00"//to update
    },

    "DayTemplate1": {
        0:"Low", 1:"Low", 2:"High", 3:"High", 4:"Medium", 5:"High", 6:"Low"
    },

    "DayTemplate2": {
        0:"Low", 1:"High", 2:"Medium", 3:"High", 4:"Medium", 5:"Low", 6:"Low"
    },

    "DayTemplate3": {
        0:"Low", 1:"High", 2:"Medium", 3:"Medium", 4:"Low", 5:"Low", 6:"Low"
    },

    "DayTemplate4": {
        0:"Low", 1:"Medium", 2:"High", 3:"Medium", 4:"Low", 5:"Medium", 6:"Low"
    },

    "DayTemplate5": {
        0:"Low", 1:"Low", 2:"Low", 3:"Medium", 4:"High", 5:"Low", 6:"Low"
    },

    "Site_Day_Mapping": {
        "siteId":"",
        "siteName":"",
        "dayTemplate":""
    }

})