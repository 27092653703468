define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "5a1fcaad-0721-4840-baae-a9bcb38fe0b7",
    "name": "switch_unreachable",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.23.221.106",
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "HIGH",
    "summary": "Network Device DeviceIPPlaceHolder is Unreachable From Cisco DNA Center",
    "groupId": "device_unreachable_grouping",
    "groupName": "Network device unreachable from controller",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "Unknown",
    "timestamp": 1564115115239,
    "description": "This network device DeviceNamePlaceHolder is unreachable from Cisco DNA Center. The device role is ACCESS.",
    "suggestions": [
      {
        "message": "From the Cisco DNA Center, verify whether the last hop is reachable."
      },
      {
        "message": "Verify that the physical port(s) on the network device associated with the network device discovery(IP) is UP."
      },
      {
        "message": "Verify access to the device."
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "172.23.221.106"
      },
      {
        "key": "fabricOrDeviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_name",
        "value": "switch_unreachable"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.23.221.106"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "7"
      },
      {
        "key": "areaName",
        "value": "Global/SanJose"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.23.221.106"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 3650-48PQ-E Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "56812e49-8d73-4920-a7a3-ab5fab78651f"
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "03.06.03E"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "CAT3650-A11-R21-S11.a.cc"
      },
      {
        "key": "_eventTime",
        "value": "1564115115239"
      },
      {
        "key": "_mostRecent",
        "value": "1564118715234"
      },
      {
        "key": "entityId",
        "value": "172.23.221.106"
      },
      {
        "key": "eventUniqueId",
        "value": "172.23.221.106"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "buildingId",
        "value": "9a9e25ad-15bc-4cda-a903-1f0ec692dc5d"
      },
      {
        "key": "buildingName",
        "value": "Global/SanJose/SJC4"
      },
      {
        "key": "areaId",
        "value": "94b8dfa9-63ac-4954-8f5c-b980ddd1b4e7"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/SanJose/SJC4"
      },
      {
        "key": "_source",
        "value": "internal"
      },
      {
        "key": "deviceModel",
        "value": "FDO1944E21E"
      },
      {
        "key": "category",
        "value": "Availability"
      },
      {
        "key": "timestring",
        "value": "2019-07-26T04:25:15.239+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})