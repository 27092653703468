define({
  "Template": {
    groupUuid: "-1",
    deviceFamily: "",
    deviceTypeOrdinal: 0,
    softwareType: "",
    runningImageList: [],
    deviceAvailable: true,
    productType: null,
    productTypeOrdinal: -1,
    supervisorType: null,
    supervisorTypeOrdinal: -1,
    productFamily: null,
    productFamilyOrdinal: -1,
    productSeries: null,
    productSeriesOrdinal: -1,
    "baseImages": {
      "c6848x-adventerprisek9-mz.SPA.155-1.SY6.bin": "15.5(1)SY6",
      "cat9k_iosxe.17.06.01.SPA.bin": "17.06.01.0.250",
      "AIR-CT5520-K9-8-10-151-0.aes": "8.10.151.0",
      "C9800-L-universalk9_wlc.17.04.01.SPA.bin": "17.04.01.0.173",
      "C9800-40-universalk9_wlc.17.02.01.SPA.bin": "17.02.01.0.306",
      "cat9k_iosxe.17.04.01.SPA.bin": "17.04.01.0.173",
      "cat9k_lite_iosxe.17.03.01.SPA.bin": "17.03.01.0.351",
      "cat9k_iosxe.17.03.02a.SPA.bin": "17.03.02a.0.3793",
      "C9800-L-universalk9_wlc.17.06.01.SPA.bin": "17.06.01.0.250",
      "C9800-40-universalk9_wlc.17.03.02a.SPA.bin": "17.03.02a.0.3793",
      "cat9k_iosxe.17.03.03.SPA.bin": "17.03.03.0.4762",
      "C9800-L-universalk9_wlc.17.03.01.SPA.bin": "17.03.01.0.351",
      "C9800-40-universalk9_wlc.17.06.01.SPA.bin": "17.06.01.0.250",
      "cat9k_iosxe.17.03.01.SPA.bin": "17.03.01.0.351",
      "C9800-L-universalk9_wlc.17.03.03.SPA.bin": "17.03.03.0.4762",
      "AIR-CT5520-K9-8-8-130-0.aes": "8.8.130.0",
      "C9800-L-universalk9_wlc.17.05.01.SPA.bin": "17.05.01.0.144",
      "C9800-40-universalk9_wlc.17.03.03.SPA.bin": "17.03.03.0.4762"
    },
    kickstartImages: {},
    smuImages: {},
    rommonImages: {},
    apspImages: {},
    apdpImages: {},
    deviceUuids: [],
    goldenImageUuids: [],
    virtualVendors: []
  }
});
