define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHostHealthDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHealthTrendLine1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceZeroScoreHostHealthDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHostHealthDetail_Client_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceZeroScoreHostHealthDetail_Client_Template',
    'core/../../../public/libs/node_modules/moment',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/TimeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SiteHealthThreshold',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/NetworkServicesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/WebexIntegrationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/WebexIntegrationTemplate1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/DHCPServerTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ApplicationThousandEyesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceFabricTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthTrendGraphTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ApplicationAppAggbyIdentifier',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/MsTeamsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SimIpListTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SimViewTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SimIssueTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SimServiceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SimEventIdTemplate' ,
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ClientSummaryWlccTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/AnalyticsCollectionTemplate'
], function(SimLokiDatabaseActions, AssuranceHostHealthDetailTemplate, AssuranceHealthTrendLine,
             AssuranceZeroScoreHostHealthDetail, AssuranceHostHealthDetail_Client_Template,
             AssuranceZeroScoreHostHealthDetail_Client_Template, moment, TimeTemplate, UtilityFunctions, SiteHealthThreshold, 
             SimulationUtility, NetworkServicesTemplate, WebexIntegrationTemplate, WebexIntegrationTemplate1,DHCPServerTemplate,
             ApplicationThousandEyesTemplate, AssuranceFabricTemplate, ClientHealthTrendGraphTemplate, ApplicationAppAggbyIdentifier, MsTeamsTemplate, SimIpListTemplate,SimViewTemplate,
             SimIssueTemplate,SimServiceTemplate,SimEventIdTemplate, ClientSummaryWlccTemplate, AnalyticsCollectionTemplate){

  return {
      init: function(){
      },

      getTimeDetails: function() {
          var tTime = UtilityFunctions.getTimeStamp();
          var tObj =  JSON.parse(JSON.stringify( TimeTemplate.TIME_Template ));
          tObj.response[0].time = tTime;
          tObj.response[1].time = tTime;
          tObj.response[2].time = tTime;
          tObj.response[3].time = tTime;
          tObj.response[4].time = tTime;
          return tObj;
      },


  getHostHealthDetail: function(urlAction) {
        var i,j;
        var t = JSON.parse(JSON.stringify(AssuranceZeroScoreHostHealthDetail_Client_Template.HostHealthDetails));
        t.response[0].siteId = 'global';
    
        var iWiredCount = 0, iWirelessCount = 0;
    
        var frequency="0"; var ssids=[];
    
        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency !=""){
            frequency=urlAction.restPayload.typeList.filters.frequency;
        }
    
        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
            ssids=urlAction.restPayload.typeList.filters.ssid;
        }

    if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && 
        urlAction.restPayload.typeList.filters.ssid != undefined &&
        urlAction.restPayload.typeList.filters.isTrendSummary == true){
            var tJson = JSON.parse(JSON.stringify(ClientHealthTrendGraphTemplate.Template));
            return tJson; 
        }
            
            var t1 = JSON.parse(JSON.stringify(AssuranceHostHealthDetail_Client_Template.HostHealthDetails));
            var startTime = urlAction.restPayload.typeList.startTime, endTime = urlAction.restPayload.typeList.endTime;
    
            var tHosts=getHostCount(urlAction);
                 for(var j = 0; j <  t.response[0].scoreDetail.length; j++) {
              if( t.response[0].scoreDetail[j].scoreCategory.scoreCategory == 'CLIENT_TYPE') {
                   t.response[0].scoreDetail[j].starttime = startTime;
                   t.response[0].scoreDetail[j].endtime = endTime;
    
                  var s = getHostStatsGivenAListOfHosts( t.response[0].scoreDetail[j].scoreCategory.value, tHosts, frequency);
                   t.response[0].scoreDetail[j].scoreValue = s.overallScore;
                   t.response[0].scoreDetail[j].clientCount = s.clientCount;
                   t.response[0].scoreDetail[j].clientUniqueCount = s.clientUniqueCount;
                   if(  t.response[0].scoreDetail[j].clientCount>0 &&   t.response[0].scoreDetail[j].clientUniqueCount>0 ){
                   for(var k = 0; k <  t.response[0].scoreDetail[j].scoreList.length; k++) {
                      var type =  t.response[0].scoreDetail[j].scoreList[k].scoreCategory.value;
                      var tStat = getHostStatsGivenAListOfHostsAndHealthType(tHosts,  t.response[0].scoreDetail[j].scoreCategory.value, type);
                      if(type==="POOR" && t.response[0].scoreDetail[j].scoreCategory.value==="WIRELESS"){
                       var poorStats=getPoorDevicesCount(urlAction);
                       t.response[0].scoreDetail[j].scoreList[k].scoreList[1].clientCount=poorStats.aaaCount;
                       t.response[0].scoreDetail[j].scoreList[k].scoreList[0].clientCount=tStat.clientCount-poorStats.aaaCount;
                      }
                  //first arg is wired or wireless , 2nd arg is 'poor'/'fair'/'good'/'idle'.
                       t.response[0].scoreDetail[j].scoreList[k].clientCount = tStat.clientCount;
                       t.response[0].scoreDetail[j].scoreList[k].scoreValue = tStat.scoreValue;
                       t.response[0].scoreDetail[j].scoreList[k].clientUniqueCount = tStat.clientUniqueCount;
                       t.response[0].scoreDetail[j].scoreList[k].starttime = startTime;
                       t.response[0].scoreDetail[j].scoreList[k].endtime = endTime;
                  }
                  }
                  else{
                      t.response[0].scoreDetail[j].scoreList=[];
                  }
              }
          }
        return t;
},
    getSiteThresholdDetails : function(urlAction) {
        if(urlAction.service.indexOf('overallNetworkHealth') >-1) {
            return SiteHealthThreshold.SITE_THRESHOLD_NETWORK_TEMPLATE;
        } else if(urlAction.service.indexOf('clientHealth') >-1) {
            return SiteHealthThreshold.SITE_THRESHOLD_CLIENT_TEMPLATE;
        }
    },
    getScaleThresholdDetails : function(urlAction) {
       //return {"version":"1.0","response":{"reachingNonFeasibility":false,"feasibility":true,"clientCount":null,"apCount":13,"switchCount":15,"apRadioCount":null,"clientSessionCount":null,"feasibilityApDeviceCountThreshold":"ENV_FEASIBILTY_AP_SWITCH_COUNTS : [13000, 5000]","feasibilityNearingPercentageThreshold":"ENV_NEARING_FEASIBILTY_PERCENTAGE : 0.9","reason":null}}
       return AssuranceFabricTemplate.Scale_Threshold_Template;
    },
    getHealthScoreSetting : function(urlAction){
        var RecordObj = SimLokiDatabaseActions.getFilteredRecordHandler('health-threshold-setting', {'deviceType': urlAction.filter.role})
        return RecordObj
    },
  getThousandEyesToken : function(urlAction) {
        console.log("call invoked");
        return {"version": "1.0","response":{"status": "ENABLED"}}
    },
    getThousandEyesSummaryData: function(urlAction){
        var tData = JSON.parse(JSON.stringify(ApplicationThousandEyesTemplate.ThousandEyesTemplate));
        return tData;
    },
    getNetflowSummaryData:function(){
        var tJson = JSON.parse(JSON.stringify(ApplicationThousandEyesTemplate.NetflowTemplate));
        return tJson;
    },
    getIpList:function(){
        var tJson = JSON.parse(JSON.stringify(SimIpListTemplate.getIpListTemplate));
        return tJson;
    },
    getView:function(){
        var tJson = JSON.parse(JSON.stringify(SimViewTemplate.getViewTemplate));
        return tJson;
    },
    getIssue:function(){
        var tJson = JSON.parse(JSON.stringify(SimIssueTemplate.getIssueTemplate));
        return tJson;
    },
    getService:function(){
        var tJson = JSON.parse(JSON.stringify(SimServiceTemplate.getServiceTemplate));
        return tJson;
    },
    getEventId:function(){
        var tJson = JSON.parse(JSON.stringify(SimEventIdTemplate.getEventIdTemplate));
        return tJson;
    },
    getAppAggbyIdentifier:function(){
        var tJson = JSON.parse(JSON.stringify(ApplicationAppAggbyIdentifier.AppAggbyIdentifierMacTemplate));
        return tJson;
    },
    getClientSummaryWlccData: function (urlAction) {
        var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.ClientSummaryWlccData));
        var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device');
        var currentTime = urlAction.restPayload.currentTime;
        var startTime = urlAction.restPayload.startTime;
        var endTime = urlAction.restPayload.endTime;
        var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
        //console.log(timediffereceObj,"timediffereceObj")
        var obj = tJson.data[0];
        tJson.data = [];
        while (startTime <= endTime) {
            startTime += 300000;
            obj.time = startTime;
            //obj.values.nestedData.wlcName = c9800-40-TMEDNAC.cisco.com;
            obj.values.nestedData[0].wlcUUID = wlcDevice.id;
            tJson.data.push(JSON.parse(JSON.stringify(obj)))
        }
        return tJson;
    },
      getClientSummaryEntityTypeData: function (urlAction) {
          var groupHierarchyPath = "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1";
          var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.ClientSummaryEntityTypeData));
          let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
          var obj = tJson.data[0];
          tJson.data = [];
          obj.values.nestedData[0].values.siteHierarchyGraphId = siteRec[0].groupHierarchy;
          obj.values.nestedData[0].values.siteHierarchy = siteRec[35].groupNameHierarchy;
          obj.values.nestedData[0].entityId = siteRec[0].groupHierarchy;
          tJson.data.push(JSON.parse(JSON.stringify(obj)));
          return tJson;
      },
      getClientAnalyticsSummaryData: function (urlAction) {
          var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.ClientAnalyticsSummaryData));
          var obj = tJson.data;
          for (var i = 0; i < obj.length; i++) {
              if (i == 0) {
                  obj[i].dataType = "summary";

                  obj[i].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                  obj[i].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                  obj[i].values.nestedData[0].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                  obj[i].values.nestedData[0].values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[0].values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[0].values.nestedData[1].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[0].values.nestedData[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[0].values.nestedData[2].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[0].values.nestedData[2].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                  obj[i].values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                  obj[i].values.nestedData[1].values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(10, 40);
                  obj[i].values.nestedData[1].values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(10, 40);
                  obj[i].values.nestedData[1].values.nestedData[1].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(10, 40);
                  obj[i].values.nestedData[1].values.nestedData[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[1].values.nestedData[2].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[1].values.nestedData[2].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                  obj[i].values.nestedData[2].values.nestedData[0].values.aggregatePercentage = UtilityFunctions.getRandomIntForRangeValues(99, 100);
                  obj[i].values.nestedData[2].values.nestedData[0].values.aggregateValue = UtilityFunctions.getRandomIntForRangeValues(10.00, 70.00);
                  obj[i].values.nestedData[2].values.nestedData[1].values.aggregatePercentage = UtilityFunctions.getRandomIntForRangeValues(1.00, 10.00);
                  obj[i].values.nestedData[2].values.nestedData[1].values.aggregateValue = UtilityFunctions.getRandomIntForRangeValues(100000, 800000);
                  obj[i].values.nestedData[2].values.nestedData[2].values.aggregatePercentage = UtilityFunctions.getRandomIntForRangeValues(80, 90);
                  obj[i].values.nestedData[2].values.nestedData[2].values.aggregateValue = UtilityFunctions.getRandomIntForRangeValues(-10, -50);
                  obj[i].values.nestedData[2].values.nestedData[2].values.aggregatePercentage = UtilityFunctions.getRandomIntForRangeValues(90, 100);
                  obj[i].values.nestedData[2].values.nestedData[2].values.aggregateValue = UtilityFunctions.getRandomIntForRangeValues(10.00, 50.00);
                  obj[i].values.nestedData[0].values.nestedData[0].values.nestedData[0].values.reasonCode = UtilityFunctions.getRandomIntForRangeValues(1000, 2000);
              }
          }
          return tJson;
      },
      getConnectivityData: function (urlAction) {
          var tJson = JSON.parse(JSON.stringify(AnalyticsCollectionTemplate.ConnectivityTrendData));
          let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
          var currentTime = urlAction.restPayload.currentTime;
          var startTime = urlAction.restPayload.startTime;
          var endTime = urlAction.restPayload.endTime;
          var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
          var obj = tJson.data[0];
          tJson.data = [];
          for (var i = 0; startTime <= endTime; i++) {
              for (var j = 0; j < siteRec.length; j++) {
                  startTime += 400000;
                  obj.time = startTime;
                  obj.values.__timestamp = startTime;
                  obj.values.nestedData[0].values.dataRate = UtilityFunctions.getRandomIntForRangeValues(0, 15);
                  obj.values.nestedData[0].values.dataRateThreshold = 9;
                  obj.values.nestedData[0].values.frequency = 5;
                  obj.values.nestedData[0].values.latencyBe = UtilityFunctions.getRandomIntForRangeValues(0, 250);
                  obj.values.nestedData[0].values.latencyBeThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[0].values.latencyBgThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[0].values.latencyVideoThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 250);
                  obj.values.nestedData[0].values.latencyVoice = UtilityFunctions.getRandomIntForRangeValues(0, 250);
                  obj.values.nestedData[0].values.latencyVoiceThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[0].values.rssi = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                  obj.values.nestedData[0].values.rssiThreshold = 9;
                  obj.values.nestedData[0].values.rxRetries = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                  obj.values.nestedData[0].values.snr = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                  obj.values.nestedData[0].values.snrThreshold = 9;
                  obj.values.nestedData[1].entityType = 'site';
                  obj.values.nestedData[1].values.dataRate = UtilityFunctions.getRandomIntForRangeValues(0, 15);
                  obj.values.nestedData[1].values.dataRateThreshold = 9;
                  obj.values.nestedData[1].values.frequency = 5;
                  obj.values.nestedData[1].values.latencyBe = UtilityFunctions.getRandomIntForRangeValues(0, 250);
                  obj.values.nestedData[1].values.latencyBeThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[1].values.latencyBgThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[1].values.latencyVideoThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[1].values.latencyVoice = UtilityFunctions.getRandomIntForRangeValues(0, 250);
                  obj.values.nestedData[1].values.latencyVoiceThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  obj.values.nestedData[1].values.rssi = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                  obj.values.nestedData[1].values.rssiThreshold = 9;
                  obj.values.nestedData[1].values.rxRetries = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                  obj.values.nestedData[1].values.snr = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                  obj.values.nestedData[1].values.snrThreshold = 9;
                  obj.values.nestedData[1].values.siteHierarchy = siteRec[35].groupNameHierarchy;
                  tJson.data.push(JSON.parse(JSON.stringify(obj)));
              }
          }
          return tJson;
      },
      getConnectivityFalseData: function (urlAction) {
          var tJson = JSON.parse(JSON.stringify(AnalyticsCollectionTemplate.ConnectivityTrendFalseData));
          var startTime = urlAction.restPayload.startTime;
          var endTime = urlAction.restPayload.endTime;
          var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
          var obj = tJson.data[0];
          tJson.data = [];
          for (var i = 0; startTime <= endTime; i++) {
              startTime += 400000;
              obj.time = startTime;
              obj.values.__timestamp = startTime;
              obj.values.nestedData[0].values.dataRate = UtilityFunctions.getRandomIntForRangeValues(15, 20);
              obj.values.nestedData[0].values.dataRateThreshold = UtilityFunctions.getRandomIntForRangeValues(10, 15);
              obj.values.nestedData[0].values.frequency = 5;
              obj.values.nestedData[0].values.latencyBe = UtilityFunctions.getRandomIntForRangeValues(0, 10);
              obj.values.nestedData[0].values.latencyBeThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 50);
              obj.values.nestedData[0].values.latencyBgThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
              obj.values.nestedData[0].values.latencyVideoThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 10);
              obj.values.nestedData[0].values.latencyVoice = UtilityFunctions.getRandomIntForRangeValues(0, 25);
              obj.values.nestedData[0].values.latencyVoiceThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 50);
              obj.values.nestedData[0].values.rssi = UtilityFunctions.getRandomIntForRangeValues(0, 50);
              obj.values.nestedData[0].values.rssiThreshold = 9;
              obj.values.nestedData[0].values.rxRetries = UtilityFunctions.getRandomIntForRangeValues(0, 100);
              obj.values.nestedData[0].values.snr = UtilityFunctions.getRandomIntForRangeValues(0, 50);
              obj.values.nestedData[0].values.snrThreshold = UtilityFunctions.getRandomIntForRangeValues(0, 15);
              tJson.data.push(JSON.parse(JSON.stringify(obj)));
          }
          return tJson;
      },
      getIncludeSiteTrendData: function (urlAction) {
        var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.IncludeSiteTrendData));
        let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
        var currentTime = urlAction.restPayload.currentTime;
        var startTime = urlAction.restPayload.startTime;
        var endTime = urlAction.restPayload.endTime;
        var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
        var obj = tJson.data[0];
        tJson.data = [];
        for (var i = 0; startTime <= endTime; i++) {
            for (var j = 0; j < siteRec.length; j++) {
                startTime += 300000;
                obj.time = startTime;
                obj.values.__timestamp = startTime;
                obj.values.nestedData[1].values.siteHierarchy = siteRec[35].groupNameHierarchy;
                obj.values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                obj.values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                obj.values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                obj.values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                obj.values.nestedData[1].values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[1].values.nestedData[0].values.nestedData[0].reasonCode = UtilityFunctions.getRandomIntForRangeValues(1000, 1500);
                obj.values.nestedData[1].values.nestedData[1].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[2].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[2].values.nestedData[0].values.nestedData[0].reasonCode = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[2].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
            }
        }
        return tJson;
    },
    getIncludeSiteTrendFalseData: function (urlAction) {
        var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.IncludeSiteFalseData));
        var startTime = urlAction.restPayload.startTime;
        var endTime = urlAction.restPayload.endTime;
        var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
        var obj = tJson.data[0];
        tJson.data = [];
        if (timediffereceObj <= 24) {
            for (var i = 0; i < 62; i++) {
                startTime += 3000000;
                obj.time = startTime;
                obj.values.__timestamp = startTime;
                obj.values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 5);
                obj.values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(1, 5);
                obj.values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[1].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                obj.values.nestedData[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 2);
                obj.values.nestedData[2].values.nestedData[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 2);
                obj.values.nestedData[2].values.nestedData[1].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 3);
                obj.values.nestedData[2].values.nestedData[1].values.nestedData[0].values.nestedData[0].reasonCode = UtilityFunctions.getRandomIntForRangeValues(1000, 2000);
                obj.values.nestedData[2].values.nestedData[1].values.nestedData[0].values.nestedData[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 3);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
        }
        return tJson;
    },
    getApMacdata: function (urlAction) {
        var tJson = JSON.parse(JSON.stringify(ClientSummaryWlccTemplate.ApMacData));
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{ 'family': "Unified AP" }] });
        let switches = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"$and": [{"family":"Switches and Hubs"}]})
        var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"$and": [{"family":"Wireless Controller"}]} );
        var currentTime = urlAction.restPayload.currentTime;
        var startTime = urlAction.restPayload.startTime;
        var endTime = urlAction.restPayload.endTime;
        var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
        var obj = tJson.data[0];
        tJson.data = [];
        for (var i = 0; i < data.length; i++) {
            while (startTime <= endTime) {
                startTime += 3000000;
                obj.time = startTime;
                obj.values.__timestamp = startTime;
                obj.values.nestedData[0].apName = data[i].hostname;
                obj.values.nestedData[0].apMac = data[i].macAddress;
                obj.values.nestedData[0].apUuid = data[i].id;
                obj.values.nestedData[0].switchName = switches[0].name;
                obj.values.nestedData[0].switchUUID = switches[0].id;
                obj.values.nestedData[0].wlcName = wlcDevice[0].name;
                obj.values.nestedData[0].wlcUUID = wlcDevice[0].id;
                obj.values.nestedData[0].firstSeenTimestamp = startTime;
                obj.values.nestedData[0].lastSeenTimestamp = endTime;

                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
        }
        return tJson;
    },
      getApName: function (urlAction) {
          var tJson = JSON.parse(JSON.stringify(AnalyticsCollectionTemplate.ApNameData));
          if (urlAction.restPayload.queryList[0].query.groupBy[0] == 'apName') {
              var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{ 'family': "Unified AP" }] });
              var startTime = urlAction.restPayload.queryList[0].startTime;
              var endTime = urlAction.restPayload.queryList[0].endTime;
              var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
              obj = tJson;
              tJson = [];
              for (var i = 0; i < obj.length; i++) {
                  if (i == 0) {
                      obj[0].data[0].apName = data[0].hostname;
                      obj[0].data[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[0].data[0].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[0].data[1].apName = data[0].hostname;
                      obj[0].data[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[0].data[1].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[0].data[2].apName = data[0].hostname;
                      obj[0].data[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[0].data[2].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[0].data[3].apName = data[0].hostname;
                      obj[0].data[3].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[0].data[3].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[0].data[4].apName = data[0].hostname;
                      obj[0].data[4].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[0].data[4].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  }
                  else if (i == 1) {
                      var tSsids = SimLokiDatabaseActions.getAll('wlan-ssid');
                      obj[1].data[0].ssid = tSsids[0].ssid;
                      obj[1].data[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 50);
                      obj[1].data[0].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  }
                  else if (i == 2) {
                      obj[2].data[0].band = "5.0";
                      obj[2].data[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 100);
                      obj[2].data[0].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[2].data[1].band = "6.0";
                      obj[2].data[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 100);
                      obj[2].data[1].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[2].data[2].band = "2.4";
                      obj[2].data[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 100);
                      obj[2].data[2].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  }
                  else if (i == 3) {
                      var host = SimLokiDatabaseActions.getFilteredRecordHandler('host')
                      obj[3].data[0].hostOs = host[0].hostOs;
                      obj[3].data[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                      obj[3].data[0].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[3].data[1].hostOs = host[3].hostOs;
                      obj[3].data[1].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 100);
                      obj[3].data[1].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[3].data[2].hostOs = host[4].hostOs;
                      obj[3].data[2].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 100);
                      obj[3].data[2].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[3].data[3].hostOs = host[5].hostOs;
                      obj[3].data[3].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                      obj[3].data[3].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[3].data[4].hostOs = host[11].hostOs;
                      obj[3].data[4].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                      obj[3].data[4].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                      obj[3].data[5].hostOs = host[16].hostOs;
                      obj[3].data[5].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                      obj[3].data[5].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                  }
                  else {
                      let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
                      obj[4].data[0].values.siteHierarchy = siteRec[35].groupNameHierarchy;
                      obj[4].data[0].values.attemptCount = UtilityFunctions.getRandomIntForRangeValues(0, 200);
                      obj[4].data[0].values.clientCount = UtilityFunctions.getRandomIntForRangeValues(0, 10);

                  }
              }
              tJson = obj
          }
          return tJson;
      },
    // time differ function
    getHoursDiff: function (startTime, endTime) {
        var startDate = new Date(startTime);
        var endDate = new Date(endTime);
        var differenceHours = (endDate.getTime() - startDate.getTime()) / 1000;
        differenceHours /= (60 * 60);
        return differenceHours;
    },
    getApAppAggbyIdentifier:function(urlAction, gethoursValue){
        // same response return by the byScore ==true and false api
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "name": { '$in': ["SJC01_9136_1", "SCJ01_9136_2", "SCJ01_9136_3"] } });
        var tJson = JSON.parse(JSON.stringify(ApplicationAppAggbyIdentifier.AppAggbyIdentifierApTemplate));
        var tempGraph = tJson.response[0];
        tJson.response = [];
        data.map((e) => {
            tempGraph.identifier = e.id;
            tempGraph.name = e.name;
            // totalTimeMilis=gethoursValue >1?totalTimeMilis:UtilityFunctions.getRandomIntForRangeValues(10,43);
            return tJson.response.push(JSON.parse(JSON.stringify(tempGraph)));
        })
        return tJson;
    },
    getApDeviceAnalytics: function (urlAction, gethoursValue) {
        // same response return by the viewBy =="usage" and "health_Score" api
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "name": { '$in': ["SJC01_9136_1", "SCJ01_9136_2", "SCJ01_9136_3"] } });
        var tJson = JSON.parse(JSON.stringify(ApplicationAppAggbyIdentifier.AppApDeviceAnalytics));
        var tempGraph = tJson.response[0];
        tJson.response = [];
        data.map((e) => {
            tempGraph.identifier = e.id;
            tempGraph.name = e.name;
            // tempGraph.value=gethoursValue>1?tempGraph.value:UtilityFunctions.getRandomIntForRangeValues(10,43);
            return tJson.response.push(JSON.parse(JSON.stringify(tempGraph)));
        })
        return tJson;
    },
    getAppAggregate:function(urlAction){
        let startDate = urlAction.filter.startTime;
        let endDate = urlAction.filter.endTime;
        let coeff = 1000 * 60 * 5;
        let windowTime = urlAction.filter.sliceWindow;        
        let tJson = JSON.parse(JSON.stringify(ApplicationAppAggbyIdentifier.AppAggregateTemplate));
        let tempObj = {};
        tJson.response.msteamsGraphResult = [];
        //let currentTime = new Date();
        //3 * 60 * 60 * 1000
        let currentTime = Math.round(startDate / coeff) * coeff; ;
        while(currentTime < endDate){
            tempObj = {
                startDateTime : currentTime,
                fairMinutes : null,
                goodMinutes : null,
                poorMinutes : null,
                avgSessionScore : null,
                avgAudioScore : null,
                avgVideoScore : null,
                avgShareScore : null,
                avgNetworkSessionScore : null,
                count : null
            }
            if((((endDate - currentTime) >= 60*60*1000) && ((endDate - currentTime) <= 2*60*60*1000)) ||
                        (((endDate - currentTime) >= 2.5*60*60*1000) && ((endDate - currentTime) <= 3*60*60*1000)) || 
                        (((endDate - currentTime) >= 3*60*60*1000) && ((endDate - currentTime) <= 4*60*60*1000)))  {                
                tempObj.fairMinutes = UtilityFunctions.getRandomIntForRangeValues(25, 30);
                tempObj.goodMinutes= UtilityFunctions.getRandomIntForRangeValues(25, 30);
                tempObj.poorMinutes = UtilityFunctions.getRandomIntForRangeValues(1, 3);
                tempObj.avgSessionScore = UtilityFunctions.getRandomIntForRangeValues(3,8);
                tempObj.avgAudioScore = UtilityFunctions.getRandomIntForRangeValues(3,8);
                tempObj.avgVideoScore = UtilityFunctions.getRandomIntForRangeValues(3,8);
                tempObj.avgShareScore = UtilityFunctions.getRandomIntForRangeValues(3,8);
                tempObj.avgNetworkSessionScore = UtilityFunctions.getRandomIntForRangeValues(3,8);
                tempObj.count = UtilityFunctions.getRandomIntForRangeValues(3, 5);
            }
            tJson.response.msteamsGraphResult.push(JSON.parse(JSON.stringify(tempObj)));
            currentTime += windowTime;
        }
        return tJson;
    }, 

    getNetworkServices : function(urlAction){
        if(urlAction.restPayload.serverType=="OVERALL"){  
            var tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.overall)) 
            calculateTransaction(tJson,urlAction)  
            return {"version":"1.0","response":tJson }     
        }else if(urlAction.restPayload.serverType=="AAA" && urlAction.restPayload.apiType=="SUMMARY" ){
            var tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.overall))
            calculateTransaction(tJson,urlAction)  
            return {"version":"1.0","response":tJson }
        }else if(urlAction.restPayload.serverType=="DHCP" && urlAction.restPayload.apiType=="SUMMARY" ){
            var tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.overall))
            calculateTransaction(tJson,urlAction)
            return {"version":"1.0","response":tJson }
        }else if(urlAction.restPayload.apiType=="TREND"){
            var startTime=urlAction.restPayload.startTime
            endTime=urlAction.restPayload.endTime
            var tdata=[]
            while(startTime<=endTime){
                var tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.TREND))
                tJson.time=UtilityFunctions.getGivenDateAndTimeInValidFormat(startTime)
                tJson.successfulTransactions=UtilityFunctions.getRandomIntForRangeValues(1,5)
                tJson.failedTransactions=UtilityFunctions.getRandomIntForRangeValues(1,3)
                tJson.totalTransactions=tJson.successfulTransactions+tJson.failedTransactions
                startTime+=300000
                tdata.push(tJson)
            }
            return  {"version":"1.0","response":{"trend":tdata}}
        }else if(urlAction.service.indexOf('analysis')>-1){
            if(urlAction.restPayload.aggVar=="TOTAL_AVG_LATENCY"){
                let tdata=[];
                if(urlAction.restPayload.filter.serverType=="AAA"){
                    var sites=["Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","Global/North America/USA/California/San Francisco/SFO10/Flr-SFO10-1","Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1","Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1","Global/North America/USA/California/San Francisco/SFO15/Flr-SFO15-1"]
                    var site=["San Jose/SJC01/Flr-SJC1-1","San Francisco/SFO10/Flr-SFO10-1","San Jose/SJC22/Flr-SJC22-1","Pleasanton/PLS06/Flr-PLS06-1","San Francisco/SFO15/Flr-SFO15-1"]
                }else{
                    var sites=["Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","Global/North America/USA/California/San Francisco/SFO15/Flr-SFO15-1","Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1"]
                    var site=["San Jose/SJC01/Flr-SJC1-1","San Francisco/SFO10/Flr-SFO10-1","San Jose/SJC22/Flr-SJC22-1"]
                }
                for(i=0;i<sites.length;i++){
                    var siteId=SimLokiDatabaseActions.getFilteredRecordHandler('site',{"groupNameHierarchy":sites[i]})
                    let tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.TotalAvgLatency));
                    tJson.rankValue=site[i];
                    tJson.varValue=UtilityFunctions.getRandomIntForRangeValues(6,9)
                    tJson.rankVar=siteId[0].id
                    tdata.push(tJson)
                }
                return {"version":"1.0","response":{"detailList":tdata}}
            }else if(urlAction.restPayload.aggVar=="FAILED_TRANSACTION"){
                //return {"version":"1.0","response":null};
                let tdata=[];
                if(urlAction.restPayload.filter.serverType=="AAA"){
                    var sites=["Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1","Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"]
                    var site=["San Jose/SJC1/Flr-SJC1-1","San Francisco/SFO10/Flr-SFO10-1","San Jose/SJC22/Flr-SJC22-1","Pleasanton/PLS06/Flr-PLS06-1","San Francisco/SFO15/Flr-SFO15-1"]
                }else{
                    var sites=["Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","Global/North America/USA/California/San Francisco/SFO15/Flr-SFO15-1","Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1"]
                    var site=["San Jose/SJC01/Flr-SJC1-1","San Francisco/SFO10/Flr-SFO10-1","San Jose/SJC22/Flr-SJC22-1"]
                }
                for(i=0;i<sites.length;i++){
                    var siteId=SimLokiDatabaseActions.getFilteredRecordHandler('site',{"groupNameHierarchy":sites[i]})
                    let tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.TotalAvgLatency));
                    tJson.rankValue=site[i];
                    tJson.varValue=UtilityFunctions.getRandomIntForRangeValues(6,9)
                    tJson.rankVar=siteId[0].id
                    tJson.varName="aaaFailedTransactions_sum";
                    tdata.push(tJson)
                }
                return {"version":"1.0","response":{"detailList":tdata}}
            }else if( urlAction.restPayload.aggVar=="ALL_TYPE_OF_LATENCY"){
                
                let tJson;
                if(urlAction.restPayload.filter.serverType=="AAA"){
                    tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.ALL_TYPE_OF_LATENCY));
                }else{
                     tJson=JSON.parse(JSON.stringify(DHCPServerTemplate.ALL_TYPE_OF_LATENCY));
                }
                var startTime=urlAction.restPayload.filter.startTime
                var endTime=urlAction.restPayload.filter.endTime
                for(i=0;i<tJson.detailList.length;i++){
                    for(j=0;j<tJson.detailList[i].detailList.length;j++){
                    var tdata=[];
                        startTime=urlAction.restPayload.filter.startTime;
                        while(startTime<=endTime){
                            var tobj={};
                            tobj.rankValue=UtilityFunctions.getGivenDateAndTimeInValidFormat(startTime)
                            tobj.varValue=UtilityFunctions.getRandomIntForRangeValues(0,j*3+4)
                            tobj.varName=tJson.detailList[i].detailList[j].varName
                            tobj.rankVar=null
                            tdata.push(tobj)
                            startTime+=300000  
                        }
                        tJson.detailList[i].detailList[j].detailList = tdata;
                    }
                }
                        return {"version":"1.0","response":tJson}
            }else if(urlAction.restPayload.aggVar=="ALL_TYPE_OF_TRANSACTION"){
                let tJson
                if(urlAction.restPayload.filter.serverType=="AAA"){
                    tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.ALL_TYPE_OF_LATENCY));
                }else{
                    tJson=JSON.parse(JSON.stringify(DHCPServerTemplate.ALL_TYPE_OF_LATENCY));
                }
                var startTime=urlAction.restPayload.filter.startTime
                var endTime=urlAction.restPayload.filter.endTime
                var tdata=[]
                for(i=0;i<tJson.detailList.length;i++){
                    
                    tJson.detailList[i].varName=tJson.detailList[i].detailList[0].varName=i==0?"totalTransactions":i==1?"totalSuccessTransactions":"totalFailedTransactions"
                    if(urlAction.restPayload.filter.serverType=="DHCP"){
                        tJson.detailList[i].varName=tJson.detailList[i].detailList[0].varName=i==0?"totalTransactions":i==1?"totalFailedTransactions":"totalSuccessTransactions"

                    }
                    for(j=0;j<tJson.detailList[i].detailList.length;j++){
                        
                        var tdata=[];
                        startTime=urlAction.restPayload.filter.startTime;
                        while(startTime<=endTime){
                            var tobj={};
                            tobj.rankValue=UtilityFunctions.getGivenDateAndTimeInValidFormat(startTime)
                            tobj.varValue=UtilityFunctions.getRandomIntForRangeValues(0,j*2+4)
                            tobj.varName=tJson.detailList[i].detailList[j].varName
                            tobj.rankVar=null
                            tdata.push(tobj)
                            startTime+=300000  
                        }
                        tJson.detailList[i].detailList[j].detailList = tdata;
                    }
                }
                return {"version":"1.0","response":tJson}
            }
        }else if(urlAction.service.indexOf('servers')>-1){
            let tJson,tdata=[]
            let url=urlAction.url
            let urlComponents=url.split("/")
            if(urlComponents[6]){
                startTime=urlAction.restPayload.startTime
                endTime=urlAction.restPayload.endTime
                tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.IndividualServer))
                tJson.latencyTrends.detailList[0].detailList[0].rankValue=urlComponents[6]
                tJson.transactionTrends.detailList[0].detailList[0].rankValue=urlComponents[6]
                for(i=0;i<2;i++){
                    while(startTime<=endTime){
                        var tobj={};
                        tobj.rankValue=UtilityFunctions.getGivenDateAndTimeInValidFormat(startTime)
                        tobj.varValue=UtilityFunctions.getRandomIntForRangeValues(5,12)
                        tobj.varName=(i==0)?tJson.latencyTrends.detailList[0].detailList[0].varName:tJson.transactionTrends.detailList[0].detailList[0].varName
                        tobj.rankVar=null
                        tobj.detailList=[{},{}]

                        tobj.detailList[0].rankvalue=startTime
                        tobj.detailList[0].rankVar=null
                        tobj.detailList[0].varName=(i==0)?"totalFailedTransactions":"eapAvgLatency"
                        tobj.detailList[0].varValue=UtilityFunctions.getRandomIntForRangeValues(0,4)
                        tobj.detailList[1].rankvalue=startTime
                        tobj.detailList[1].rankVar=null
                        tobj.detailList[1].varName=(i==0)?"totalSuccessTransactions":"mabAvgLatency"
                        tobj.detailList[1].varValue=tobj.varValue-tobj.detailList[0].varValue
                        tdata.push(tobj)
                        startTime+=3600000 
                    }
                    if(i==0)
                        tJson.latencyTrends.detailList[0].detailList[0].detailList=tdata
                    else
                        tJson.transactionTrends.detailList[0].detailList[0].detailList=tdata
                }
               // tJson.latencyTrends.detailList[0].detailList[0].detailList=tdata
                //tJson.transactionTrends.detailList[0].detailList[0].detailList=tdata
                return {"version":"1.0","response":tJson};
            }

            if(urlAction.restPayload.filter.serverType=="AAA"){
                tJson=JSON.parse(JSON.stringify(NetworkServicesTemplate.server));
            }else{
                tJson=JSON.parse(JSON.stringify(DHCPServerTemplate.server));
            }                       
            return {"version":"1.0","response":tJson.response,"page":
            {"pageSize":1,"offset":0,"totalCount":tJson.response.length,"randomMacCount":null,"duidCount":null,"nextKey":null,"topN":null}}
        }
    },

    webexIntegration: function(urlAction) {
        if(urlAction.service.indexOf('meetings')>-1) {
            let resp = {"version":"1.0", "response":[]};
            if(urlAction.filter['participantEmail']!='gracesmith@cisco.com') return resp;
            var startTime = urlAction.filter['startTime'], endTime = urlAction.filter['endTime'];
            var coeff = 1000 * 60 * 5;
            if(startTime<UtilityFunctions.getTimeStamp()-(165*60*1000) && endTime>UtilityFunctions.getTimeStamp()-(105*60*1000)) {
                //for an hr, about 165 min back onwards meeting started
                if(!resp.response) resp.response = [];
                var obj = JSON.parse(JSON.stringify(WebexIntegrationTemplate1.WEBEX_MEETINGS[0]));
                obj.starTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(165*60*1000)) / coeff) * coeff).getTime();
                obj.endTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime+(60*60*1000), "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.starTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime, "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.duration = "01:00";
                resp.response.push(obj);
            }
            if(startTime<UtilityFunctions.getTimeStamp()-(220*60*1000) && endTime>UtilityFunctions.getTimeStamp()-(190*60*1000)) {
                //for half an hr, about 220 min back onwards meeting started
                if(!resp.response) resp.response = [];
                var obj = JSON.parse(JSON.stringify(WebexIntegrationTemplate.WEBEX_MEETINGS[1]));
                obj.starTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(220*60*1000)) / coeff) * coeff).getTime();
                obj.endTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime+(30*60*1000), "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.starTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime, "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.duration = "00:30";
                resp.response.push(obj);
            }
            if(startTime<UtilityFunctions.getTimeStamp()-(240*60*1000) && endTime>UtilityFunctions.getTimeStamp()-(225*60*1000)) {
                //for 15 min
                if(!resp.response) resp.response = [];
                var obj = JSON.parse(JSON.stringify(WebexIntegrationTemplate.WEBEX_MEETINGS[2]));
                obj.starTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(240*60*1000)) / coeff) * coeff).getTime();
                obj.endTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime+(15*60*1000), "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.starTime = UtilityFunctions.getGivenTimeInGivenFormat(obj.starTime, "YYYY-MM-DDTHH:mm:ss")+'Z';
                obj.duration = "00:15";
                resp.response.push(obj);
            }
            return resp;
        } else if(urlAction.service.indexOf('meeting-qualities')>-1) {
            var resp = {"response":[],"version":"1.0"};
            var coeff = 1000 * 60 * 5;
            var obj1 = JSON.parse(JSON.stringify(WebexIntegrationTemplate1.WEBEX_DETAILS[0]));
            resp.response = obj1;
            var startTime, endTime, currTime, formattedTime, temp;
            if(urlAction.filter['meetingInstanceId']==WebexIntegrationTemplate1.WEBEX_MEETINGS[0].meetingInstanceId) {
                startTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(165*60*1000)) / coeff) * coeff).getTime();
                endTime = startTime + (60*60*1000);
                resp.response[0].duration = "01:00";
            } else if(urlAction.filter['meetingInstanceId']==WebexIntegrationTemplate.WEBEX_MEETINGS[1].meetingInstanceId) {
                startTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(220*60*1000)) / coeff) * coeff).getTime();
                endTime = startTime + (30*60*1000);
                resp.response[0].duration = "00:30";
            } else {
                startTime = new Date(Math.round((UtilityFunctions.getTimeStamp()-(240*60*1000)) / coeff) * coeff).getTime();
                endTime = startTime + (15*60*1000);
                resp.response[0].duration = "00:15";
            }
            currTime = startTime;
            resp.response[0].starTime = UtilityFunctions.getGivenTimeInGivenFormat(startTime, "YYYY-MM-DDTHH:mm:ss.000000")+'Z';
            resp.response[0].endTime = UtilityFunctions.getGivenTimeInGivenFormat(endTime, "YYYY-MM-DDTHH:mm:ss.000000")+'Z';
            var tempObj = resp.response[0].videoIn[0];
            resp.response[0].videoIn.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].videoIn.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].videoOut[0];
            resp.response[0].videoOut.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].videoOut.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].audioIn[0];
            resp.response[0].audioIn.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].audioIn.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].audioOut[0];
            resp.response[0].audioOut.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].audioOut.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].shareIn[0];
            resp.response[0].shareIn.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].shareIn.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].shareOut[0];
            resp.response[0].shareOut.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].shareOut.push(JSON.parse(JSON.stringify(tempObj)));
            tempObj = resp.response[0].cpuUtilization[0];
            resp.response[0].cpuUtilization.push(JSON.parse(JSON.stringify(tempObj)));
            resp.response[0].cpuUtilization.push(JSON.parse(JSON.stringify(tempObj)));
            var len = 0;
            for(var j=0;j<resp.response[0].videoIn.length && endTime>=currTime; j++, currTime += 5*60*1000, len++) {
                formattedTime = UtilityFunctions.getGivenTimeInGivenFormat(currTime,"YYYY-MM-DDTHH:mm:ss")+'Z';
                resp.response[0].videoIn[j].time=formattedTime;
                resp.response[0].videoOut[j].time=formattedTime;
                resp.response[0].audioIn[j].time=formattedTime;
                resp.response[0].audioOut[j].time=formattedTime;
                resp.response[0].shareIn[j].time=formattedTime;
                resp.response[0].shareOut[j].time=formattedTime;
            }
            resp.response[0].videoIn = resp.response[0].videoIn.slice(0,len-1);
            resp.response[0].videoOut = resp.response[0].videoOut.slice(0,len-1);
            resp.response[0].audioIn = resp.response[0].audioIn.slice(0,len-1);
            resp.response[0].audioOut = resp.response[0].audioOut.slice(0,len-1);
            resp.response[0].shareIn = resp.response[0].shareIn.slice(0,len-1);
            resp.response[0].shareOut = resp.response[0].shareOut.slice(0,len-1);
            resp.response[0].cpuUtilization = resp.response[0].cpuUtilization.slice(0,len-1);
            return resp;
        }
    },

    getWebexIntegTrendDtls:function(urlAction) {
        let resp = {"version":"1.0","response":[]};
        resp.response = JSON.parse(JSON.stringify(WebexIntegrationTemplate1.TREND_TEMPLATE[0]));
        var startTime = urlAction.filter['startTime'], endTime = urlAction.filter['endTime'];
        
        var currTime=startTime, temp, isDegradeHealth=false;
        if(endTime-startTime==(60*60*1000)) {
            //video quality - medium health    
            isDegradeHealth = true;
            console.log("health degraded..")
        }
        startTime = currTime;
        resp.response.forEach((e1, i)=>{
            if(!isDegradeHealth) {
                e1.avgHealthScore = 9;
                e1.minHealthScore = 9;
            }
            var len = 0;
            var testObj = e1.healthTrend[e1.healthTrend.length-1];
            e1.healthTrend.push(JSON.parse(JSON.stringify(testObj)));
            e1.healthTrend.push(JSON.parse(JSON.stringify(testObj)));
            for(var k=0; k<e1.healthTrend.length && endTime>=currTime; k++, len++) {
                e1.healthTrend[k].time = UtilityFunctions.getGivenTimeInGivenFormat(currTime, "YYYY-MM-DDTHH:mm:ss.000+0000");
                e1.healthTrend[k].clientPacketLoss = e1.healthTrend[k].packetLoss;
                e1.healthTrend[k].serverPacketLoss = e1.healthTrend[k].packetLoss;
                e1.healthTrend[k].serverJitter = e1.healthTrend[k].jitter; 
                e1.healthTrend[k].clientJitter = e1.healthTrend[k].jitter; 
                currTime += 5*60*1000;
            }
            e1.healthTrend = e1.healthTrend.slice(0,len-1);
            currTime = startTime;//reset for every webexappname
        });
        return resp;
    },

    getMsTeamsTrendDtls : function(urlAction){
        var fullResponse = MsTeamsTemplate.msTeamsMeetingDetails;
        var meetingStartTime = urlAction.filter['startTime'], meetingEndTime = urlAction.filter['endTime'];
        var isthisfirstMeeting=false;
        fullResponse.response.forEach((collToolsApp) => {
            meetingStartTime = urlAction.filter['startTime']
            var tempObj = collToolsApp.healthTrend[0];
            collToolsApp.healthTrend = []
            //To Identify the first meting
            
            if(meetingStartTime < UtilityFunctions.getTimeStamp() - (150 * 60 * 1000)){
                console.log("2nd & 3rd Meeting")
            } else {
                console.log("First meetings")
                isthisfirstMeeting = true;
            }
            
            var counter = 0;
            while (meetingStartTime < meetingEndTime ) {
                if(isthisfirstMeeting && counter > 7){
                    tempObj.packetLoss = 0;
                    tempObj.clientPacketLoss = 5.0;
                    tempObj.serverPacketLoss = 0.6;
                    tempObj.jitter = 0;
                    tempObj.clientJitter = 0;
                    tempObj.serverJitter = 0;
                } else {
                    tempObj.packetLoss = 0;
                    tempObj.clientPacketLoss = 0;
                    tempObj.serverPacketLoss = 0.6;
                    tempObj.jitter = 0;
                    tempObj.clientJitter = 0;
                    tempObj.serverJitter = 0;
                }                
                tempObj.time = UtilityFunctions.getGivenTimeInGivenFormat(meetingStartTime, "YYYY-MM-DDTHH:mm:ss.000+0000");
                meetingStartTime += 5*60*1000;
                collToolsApp.healthTrend.push(JSON.parse(JSON.stringify(tempObj)));
                counter++;
            }
        })
        return fullResponse
    }
};


    function isSiteHasDevices(siteId){
        var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : siteId });
        return obj && obj.length ? true : false;
    }

    function getCountOfWiredWirelessHosts(devices) {
       var count = 0;
       for(var i =0; i < devices.length; i++){
           if(devices[i].role == 'ACCESS') {
               count++;
           }
       }
       return {wiredCount: count * 2, wirelessCount: 0};
    }

    function getPoorDevicesCount(urlAction){
        var poorStats={"aaaCount":0,"overallCount":0};
        var wirelesshosts=[];
        if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !="" ){
            var frequency=urlAction.restPayload.typeList.filters.frequency;
            wirelesshosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
        }
        else{
            wirelesshosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'hostType':"WIRELESS"});
        }

        if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.ssid!=undefined && urlAction.restPayload.typeList.filters.ssid.length >0 ){
            var ssids=urlAction.restPayload.typeList.filters.ssid;
            var tempList=[];
            for(var i=0;i<ssids.length;i++){
                for(var j=0;j<wirelesshosts.length;j++){
                    if(wirelesshosts[j].ssid===ssids[i]){
                        tempList.push(wirelesshosts[j]);
                    }
                }
            }
            wirelesshosts=tempList;
        }

        var poorHosts=[];for(var i=0;i<wirelesshosts.length;i++){if(wirelesshosts[i].healthScore[0].score>0 && wirelesshosts[i].healthScore[0].score<=3)poorHosts.push(wirelesshosts[i])}
        var aaaCount=0
        for(var j=0;j<poorHosts.length;j++){
        if(poorHosts[j].onboarding.maxAuthDuration==="0"){
            aaaCount++;
        }
        }
        poorStats.aaaCount=aaaCount;
        poorStats.overallCount=poorHosts.length-aaaCount;
        return poorStats;
    }

    function calculateTransaction(tJson,urlAction){
        for(j=0;j<tJson.summary.length;j++){
           if(tJson.summary[j].serverType=="AAA"){
                var tJson1=JSON.parse(JSON.stringify(NetworkServicesTemplate.server));
            }if(tJson.summary[j].serverType=="DHCP"||urlAction.restPayload.serverType=="DHCP"){
                var  tJson1=JSON.parse(JSON.stringify(DHCPServerTemplate.server));
            }
            var totalTransactions=0,successfultransactions=0,failedTransactions=0,totalAvgLatency=0
            for(i=0;i<tJson1.response.length;i++){
                failedTransactions+=tJson1.response[i].failedTransaction
                totalTransactions+=tJson1.response[i].totalTransaction
                successfultransactions=totalTransactions-failedTransactions
                totalAvgLatency+=tJson1.response[i].totalAvgLatency
            }
            tJson.summary[j].serverCount=tJson1.response.length;
            tJson.summary[j].transactions.total=totalTransactions           
            tJson.summary[j].transactions.successful=successfultransactions
            tJson.summary[j].transactions.failed=failedTransactions
            tJson.summary[j].transactions.percentageSuccessful=Math.round(successfultransactions/totalTransactions*100)
            tJson.summary[j].transactions.percentageFailed=Math.round(failedTransactions/totalTransactions*100)
            tJson.summary[j].latency.averageLatency=totalAvgLatency
            if(urlAction.restPayload.serverType=="AAA" ||urlAction.restPayload.serverType=="DHCP"){
                tJson.summary=[tJson.summary[j]]
                break
            }
        }
        }
    function getHostCount(urlAction) {
        var result = { wiredCount: 0, wirelessCount: 0,
                 wiredPoorCount: 0, wiredFairCount: 0, wiredGoodCount: 0, wiredIdleCount: 0,
                 wirelessPoorCount: 0, wirelessFairCount: 0, wirelessGoodCount: 0, wirelessIdleCount: 0
              };

        var hosts=[];
        var siteId = 'global';
        if(urlAction.restPayload.selectedTypeIdList && urlAction.restPayload.selectedTypeIdList.length>0) {
            siteId = SimulationUtility.validSiteId(urlAction.restPayload.selectedTypeIdList[0]);
        }
        if(siteId == 'global'){
            var wirelesshosts=[],wiredhosts=[];
            if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !=""){
             var frequency=urlAction.restPayload.typeList.filters.frequency;
             if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                ssids=urlAction.restPayload.typeList.filters.ssid;
                wirelesshosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
                wiredhosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'hostType':"WIRED"});
               var tempList=[];
               for(var i=0;i<ssids.length;i++){
                   for(var j=0;j<wirelesshosts.length;j++){
                       if(wirelesshosts[j].ssid===ssids[i]){
                           tempList.push(wirelesshosts[j]);
                       }
                   }
               }
               wirelesshosts=tempList;
            }
            else{
                wirelesshosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
                wiredhosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'hostType':"WIRED"});

            }


             hosts=wirelesshosts;
             for(var i=0;i<wiredhosts.length;i++){
                 hosts.push(wiredhosts[i]);
             }
         }
         else{
             hosts=[];
            if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                ssids=urlAction.restPayload.typeList.filters.ssid;
                hosts = SimLokiDatabaseActions.getAll('host');
                 var tempList=[];
               for(var i=0;i<ssids.length;i++){
                   for(var j=0;j<hosts.length;j++){
                       if(hosts[j].ssid===ssids[i]){
                           tempList.push(hosts[j]);
                       }
                   }
               }
               hosts=tempList;
            }
            else{
              hosts = SimLokiDatabaseActions.getAll('host');
            }
         }
        }
        else{
          var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
          var siteLocation=siteObj[0].groupNameHierarchy;
            var hostsList=[];
          if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !=""){
            var frequency=urlAction.restPayload.typeList.filters.frequency;
            if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                ssids=urlAction.restPayload.typeList.filters.ssid;
               hostsList=SimLokiDatabaseActions.getFilteredRecordHandler('host', {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
                var tempList=[];
               for(var i=0;i<ssids.length;i++){
                   for(var j=0;j<hostsList.length;j++){
                       if(hostsList[j].ssid===ssids[i]){
                           tempList.push(hostsList[j]);
                       }
                   }
               }
               hostsList=tempList;
            }
            else{
             hostsList=SimLokiDatabaseActions.getFilteredRecordHandler('host', {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
            }
           }
          else{
           if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                ssids=urlAction.restPayload.typeList.filters.ssid;
                hostsList = SimLokiDatabaseActions.getAll('host');
                 var tempList=[];
               for(var i=0;i<ssids.length;i++){
                   for(var j=0;j<hostsList.length;j++){
                       if(hostsList[j].ssid===ssids[i]){
                           tempList.push(hostsList[j]);
                       }
                   }
               }
               hostsList=tempList;
            }
            else{
                hostsList = SimLokiDatabaseActions.getAll('host');
            }
          }

          hosts=[];
          for(var i=0;i<hostsList.length;i++){
              if(hostsList[i].location != undefined){
                var hostLocation=hostsList[i].location;
              }
              if(hostLocation.indexOf(siteLocation)==0)
              {
                  hosts.push(hostsList[i]);
              }
          }
        }

        for(var i =0; i < hosts.length; i++){
            var type = hosts[i].hostType == 'WIRED' ? 'wired' : 'wireless';
            var hostType = type + "Count";
            result[hostType]++; //increment wiredCount or wirelessCount
            var countType = type + getScoreType(hosts[i].healthScore[0].score) + 'Count';
            result[countType]++;//increment host count based on score e.g. fair, good,
        }
//shabeena code here for ssid and band filter
        //inflated clients for demo purpose..
        //if(urlAction.restPayload.selectedTypeIdList.length == 0 || urlAction.restPayload.selectedTypeIdList[0]==="__global__"){
        if(siteId =="global") {
            var hostsList = SimLokiDatabaseActions.getAll('host');
            var tempWireless=250; var tempWired=56; var allSsids=[];
            for (var i = 0; i < hostsList.length; i++) {
                if(hostsList[i].ssid!=undefined || hostsList[i].ssid!=null)
                allSsids.push(hostsList[i].ssid);
            }

            var ssids = [...new Set(allSsids)];

            if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !=""){
                var frequency=urlAction.restPayload.typeList.filters.frequency;
                if(frequency==="2.4"){
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                   tempWireless=34;
                }
                else if(frequency==="5.0"){
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                    tempWireless=216;
                }
            }

            if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                var s = [], totalsum = 0, index = -1, count=0;
                for(var k=0;k<ssids.length;k++){s[k]=0};
                for(var j=0;j<hostsList.length;j++){
                    for(var k=0;k<ssids.length;k++){
                        if(hostsList[j].ssid===ssids[k]){
                            s[k]++;
                        }
                    }
                }

                for (var i = 0; i < ssids.length; i++) {
                    if (ssids[i] === "c9800AP11AX") {
                        index = i;
                        continue;
                    }
                    s[i] += Math.round(tempWireless * 0.06);
                    totalsum += s[i];

                }
                if (index >= 0) {
                    s[index] = tempWireless - totalsum;
                }
                else {
                    s[s.length - 1] = tempWireless - totalsum;
                }

                for(var k=0;k<urlAction.restPayload.typeList.filters.ssid.length;k++){
                for(var z=0;z<ssids.length;z++){
                    if(urlAction.restPayload.typeList.filters.ssid[k]===ssids[z]){
                        count=count+s[z];
                    }
                }
                }
                tempWireless=count;
             }

            if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !=""){
                var frequency=urlAction.restPayload.typeList.filters.frequency;

                var poorCount=0;

                if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                    var ssids=urlAction.restPayload.typeList.filters.ssid;
                    if(ssids.indexOf("c9800AP11AX")>=0 && frequency==="5.0"){
                        poorCount=result['wirelessPoorCount']+4;
                    }
                    else{
                        poorCount=result['wirelessPoorCount'];
                    }

                }
                else{
                    if(frequency==="2.4"){
                        poorCount=result['wirelessPoorCount']
                       }   else{
                        poorCount=13;
                        }
                   }
                result['wirelessPoorCount']=poorCount;

                 if(frequency==="2.4"){
                    var iWireless = tempWireless - result['wirelessGoodCount']-result['wirelessPoorCount']-result['wirelessIdleCount']-result['wirelessFairCount'];;
                    result['wirelessGoodCount'] += iWireless;
                    result['wirelessCount'] = tempWireless;
                }
                else if(frequency==="5.0"){
                    var iWireless = tempWireless - result['wirelessGoodCount']-result['wirelessPoorCount']-result['wirelessIdleCount']-result['wirelessFairCount'];
                    result['wirelessGoodCount'] += iWireless;
                    result['wirelessCount'] = tempWireless;
                }
                var iWired = tempWired - result['wiredCount'];
                result['wiredGoodCount'] += iWired;
                result['wiredCount'] = tempWired;
              }
        else {
            var poorCount=0;
            if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                var ssids=urlAction.restPayload.typeList.filters.ssid;
                if(ssids.indexOf("c9800AP11AX")>=0){
                    poorCount=result['wirelessPoorCount']+4;
                }
                else{
                    poorCount=result['wirelessPoorCount'];
                }

            }
            else{
               poorCount= 15;
            }
        var iWired = tempWired - result['wiredCount'];
        result['wiredGoodCount'] += iWired;
        result['wiredCount'] = tempWired;
        result['wirelessPoorCount']=poorCount;
        var iWireless = tempWireless - result['wirelessPoorCount']-result['wirelessIdleCount']-result['wirelessFairCount'];
        result['wirelessGoodCount'] = iWireless;
        result['wirelessCount'] = tempWireless;
        }
    }
        return result;
    }



    function getHostStatsGivenAListOfHostsAndHealthType(hostStats, hostType, scoreType) {

        var s = scoreType.toLowerCase();
        var scoreType = s[0].toUpperCase() + s.slice(1);
        var hostType = hostType.toLowerCase();
        var clientCountType = hostType + scoreType + 'Count';
        var tCount = hostStats[clientCountType];
        if(hostType == 'all') {
            var scoreValue=Math.round((tCount/(hostStats.wiredCount+hostStats.wirelessCount)) * 100);
        }
        else if(hostType == 'wired') {
            var scoreValue=Math.round((tCount/(hostStats.wiredCount)) * 100);
        }
        else if(hostType == 'wireless') {
            var scoreValue=Math.round((tCount/(hostStats.wirelessCount)) * 100);
        }

        return {clientCount: tCount, clientUniqueCount: tCount, scoreValue: scoreValue};
    }

    function getHostStatsGivenAListOfHosts(hostType, tHosts, frequency) {
        if(hostType == 'ALL') {
            var overallScore = Math.round( ((tHosts.wiredGoodCount+tHosts.wirelessGoodCount) / (tHosts.wiredCount+tHosts.wirelessCount)) * 100);
            clientCount = tHosts.wiredCount+tHosts.wirelessCount;
            clientUniqueCount = tHosts.wiredCount+tHosts.wirelessCount;
        } else if(hostType == 'WIRED') {
            var overallScore = Math.round( ((tHosts.wiredGoodCount) / (tHosts.wiredCount)) * 100);
            clientCount = tHosts.wiredCount;
            clientUniqueCount = tHosts.wiredCount;
        } else if(hostType == 'WIRELESS') {
            if(frequency==="2.4"){
                var overallScore = Math.round( ((tHosts.wirelessGoodCount) / (tHosts.wirelessCount)) * 100);
            }
            else{
                var overallScore = Math.round( ((tHosts.wirelessGoodCount) / (tHosts.wirelessCount-1)) * 100);
            }
            clientCount = tHosts.wirelessCount;
            clientUniqueCount = tHosts.wirelessCount;
        }
        return {overallScore:overallScore, clientCount:clientCount, clientUniqueCount: clientCount};
    }

    function getScoreType(hostScore) {
        var scores = [
        {minRange: 0, maxRange: 0, scoreType: "Idle"},
        {minRange: 1, maxRange: 3, scoreType: "Poor"},
        {minRange: 4, maxRange: 7, scoreType: "Fair"},
        {minRange: 8, maxRange: 10, scoreType: "Good"}
      ];

      for(var i =0; i < scores.length; i++) {
          if(hostScore >= scores[i].minRange && hostScore <= scores[i].maxRange) {
              return scores[i].scoreType;
          }
      }
    }

});
