//Family: Nexus 7700 10-Slot Switch - random templated update with image details
define({
    "runningImageList": [
        {
            "name": "n7700-s2-dk9.8.3.2.bin",
            "imageUuid": "1a07dc94-e5a7-49b2-a09f-357ca0bc65ac",
            "version": "8.3(2)",
            "softwareType": null,
            "imageType": "kickstart",
            "isTaggedGolden": "false",
            "md5Checksum": "ecf1ea32a167ae07e4be1d6b693b7b93",
            "shaCheckSum": "a864a33cd6a8be2e4b163a15d5aaf7fc80e4595a58233925d12b54aa2df20e29b62c33f9a0c951a4cd5ba7687f8e33da4d0df29b794685f98f3b9ee2dfbdaf94",
            "createdTime": "2019-04-28 04:26:13.64",
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a12d786c-345c-43e8-aa32-f2619cebe23c",
            "imageSeries": null,
            "imageSource": null,
            "imageSourceType": null,
            "imageFamily": "Nexus7700",
            "vendor": null,
            "filesize": 529109733,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "18/Dec/2018"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Nexus 7700 Supervisor 2 System Software Image for 8.3(2)"
                }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": false
        }
    ]
})