define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomain',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/parseUrl',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/SimDataGenerationInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/activityLog/activityLogTemplate'
], function (SimScheduledCaptureData, SimLokiDatabaseActions, UtilityFunctions, SimTaskDataGenerator, SimConnectivityDomain, parseUrl,
    SimDataGenerationInit, activityLogTemplate) {

    return {
        init: function () {

        },

        doProcessRestRequest: function (urlAction) {           
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };
    function processGetRequest(urlAction) {
        if (urlAction.service.indexOf('parent-records') >= 0 || (urlAction.service.indexOf('audit-logs') >= 0 && urlAction.action.summary != true)) {
            var data = []
            for(var i = 0; i < 1; i++){
                var record = SimLokiDatabaseActions.getAll('sim-activity-log');
                for(var j=0;j<record.length;j++){
                    record[j].instanceId = UtilityFunctions.generate_uuid();
                    record[j].timestamp = (urlAction.filter.endTime)-6000
                    data.push(record[j])
                }
            }
            return data;
        }else if (urlAction.service.indexOf('child-records') >= 0){
            if(urlAction.filter['parentInstanceId'] == 'e208768a-159c-4966-8461-93e9ff2705f2'){
                var tempObj = JSON.parse(JSON.stringify(activityLogTemplate.Activity_Rogue_Chaild_2));

            }else if(urlAction.filter['parentInstanceId'] == '5a03579f-18a0-4f83-9a5d-793efdac9ea6'){
                var tempObj = JSON.parse(JSON.stringify(activityLogTemplate.Activity_Rogue_Chaild_3));
            }
            else{
                var tempObj = JSON.parse(JSON.stringify(activityLogTemplate.Activity_Rogue_Chaild_Log_Temp));
            }
            for(var i = 0;i< tempObj.length; i++){
                tempObj[i].timestamp = UtilityFunctions.getTimeStamp() 
            }
            return tempObj
        }else if(urlAction.service.indexOf('audit-log') >= 0){
            var record = SimLokiDatabaseActions.getAll('sim-activity-log');
            var data = [{"count":record.length,"maxTimestamp":urlAction.filter.startTime,"minTimestamp":urlAction.filter.endTime}]
            return data
        }

    }
});