define({
    "WEBEX_MEETINGS": [
        {
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206871415672044074",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-10-08T07:53:12Z",
            "endTime": "2021-10-08T08:37:41Z",
            "duration": "00:44",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        },
        {
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206866592884095900",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-10-07T09:01:31Z",
            "endTime": "2021-10-07T10:12:35Z",
            "duration": "01:11",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        },
        {
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206863206265682683",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-10-07T08:24:29Z",
            "endTime": "2021-10-07T08:55:56Z",
            "duration": "00:31",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        }
    ],

    "WEBEX_DETAILS": [
        [
            {
                "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206871415672044074",
                "meetingNumber": null,
                "meetingTitle": null,
                "webexUserEmail": "gracesmith@cisco.com",
                "starTime": "2021-10-08T07:53:13.000000Z",
                "endTime": "2021-10-08T08:37:51.000000Z",
                "duration": "00:45",
                "participants": 2,
                "status": "ENDED",
                "audioQuality": 10,
                "videoQuality": 10,
                "shareQuality": 10,
                "videoIn": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 109,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 0,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    }
                ],
                "videoOut": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 16,
                        "mediaBitRate": 411993,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 11,
                        "mediaBitRate": 413204,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 8,
                        "mediaBitRate": 44471,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 109,
                        "jitter": 16,
                        "mediaBitRate": 42347,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 11,
                        "mediaBitRate": 43556,
                        "signalQuality": 10
                    }
                ],
                "audioIn": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": 499,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 12,
                        "mediaBitRate": 6247,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 3894,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 14737,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": 0.01,
                        "latency": 0,
                        "jitter": 12,
                        "mediaBitRate": 24837,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": 0.09,
                        "latency": 0,
                        "jitter": 11,
                        "mediaBitRate": 12831,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 8247,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": 0.05,
                        "latency": 0,
                        "jitter": 11,
                        "mediaBitRate": 7948,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 8333,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": 0.36,
                        "latency": 0,
                        "jitter": 17,
                        "mediaBitRate": 1375,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 17,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    }
                ],
                "audioOut": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareIn": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": -1,
                        "latency": 97,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": -1,
                        "latency": 97,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": -1,
                        "latency": 97,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareOut": [
                    {
                        "time": "2021-10-08T07:54:13Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-08T07:55:00Z",
                        "packetLoss": 0,
                        "latency": 97,
                        "jitter": 36,
                        "mediaBitRate": 302603,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:00:00Z",
                        "packetLoss": 0,
                        "latency": 97,
                        "jitter": 39,
                        "mediaBitRate": 824,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:05:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 38,
                        "mediaBitRate": 822,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:10:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 38,
                        "mediaBitRate": 837,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:15:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 38,
                        "mediaBitRate": 814,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:20:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 38,
                        "mediaBitRate": 810,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:25:00Z",
                        "packetLoss": 0,
                        "latency": 97,
                        "jitter": 37,
                        "mediaBitRate": 817,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 39,
                        "mediaBitRate": 816,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 23,
                        "mediaBitRate": 1254593,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-08T08:37:41Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 10,
                        "mediaBitRate": 1812795,
                        "signalQuality": 10
                    }
                ],
                "cpuUtilization": [
                    {
                        "systemAverage":10,
                        "processAverage":10,
                        "time": "2021-10-08T08:37:41Z"
                    }
                ]
            }
        ],
        [
            {
                "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206866592884095900",
                "meetingNumber": null,
                "meetingTitle": null,
                "webexUserEmail": "gracesmith@cisco.com",
                "starTime": "2021-10-07T09:01:32.000000Z",
                "endTime": "2021-10-07T10:12:45.000000Z",
                "duration": "01:11",
                "participants": 2,
                "status": "ENDED",
                "audioQuality": 10,
                "videoQuality": 10,
                "shareQuality": 10,
                "videoIn": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": -1,
                        "latency": 109,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    }
                ],
                "videoOut": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": 0,
                        "latency": 109,
                        "jitter": 11,
                        "mediaBitRate": 230902,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    }
                ],
                "audioIn": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 3,
                        "mediaBitRate": 2608,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 9,
                        "mediaBitRate": 6211,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 8,
                        "mediaBitRate": 3658,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 9,
                        "mediaBitRate": 3291,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 7,
                        "mediaBitRate": 2700,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 8,
                        "mediaBitRate": 3339,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 11,
                        "mediaBitRate": 4279,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 3797,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 3149,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 12,
                        "mediaBitRate": 3403,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 8,
                        "mediaBitRate": 3489,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 2938,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 744,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 10,
                        "mediaBitRate": 0,
                        "signalQuality": 10
                    }
                ],
                "audioOut": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareIn": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": -1,
                        "latency": 100,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": -1,
                        "latency": 97,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareOut": [
                    {
                        "time": "2021-10-07T09:02:32Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 31,
                        "mediaBitRate": 64898,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:05:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 34,
                        "mediaBitRate": 810,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:10:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 34,
                        "mediaBitRate": 812,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:15:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 34,
                        "mediaBitRate": 810,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:20:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 34,
                        "mediaBitRate": 816,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:25:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 35,
                        "mediaBitRate": 817,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:30:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 34,
                        "mediaBitRate": 21366,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:35:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 37,
                        "mediaBitRate": 826,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:40:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 37,
                        "mediaBitRate": 810,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:45:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 36,
                        "mediaBitRate": 823,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:50:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 37,
                        "mediaBitRate": 820,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T09:55:00Z",
                        "packetLoss": 0,
                        "latency": 100,
                        "jitter": 37,
                        "mediaBitRate": 810,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:00:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 37,
                        "mediaBitRate": 16056,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:05:00Z",
                        "packetLoss": 0,
                        "latency": 97,
                        "jitter": 22,
                        "mediaBitRate": 11328,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:10:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 15,
                        "mediaBitRate": 5402,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T10:12:36Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 14,
                        "mediaBitRate": 5448,
                        "signalQuality": 10
                    }
                ]
            }
        ],
        [
            {
                "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206863206265682683",
                "meetingNumber": null,
                "meetingTitle": null,
                "webexUserEmail": "gracesmith@cisco.com",
                "starTime": "2021-10-07T08:24:29.000000Z",
                "endTime": "2021-10-07T08:56:06.000000Z",
                "duration": "00:32",
                "participants": 2,
                "status": "ENDED",
                "audioQuality": 10,
                "videoQuality": 10,
                "shareQuality": 10,
                "videoIn": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": -1,
                        "latency": 110,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 30,
                        "mediaBitRate": 178689,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "videoOut": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 10,
                        "mediaBitRate": 395425,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": -1,
                        "latency": -1,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": null
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 6,
                        "mediaBitRate": 23768,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 7,
                        "mediaBitRate": 19508,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 6,
                        "mediaBitRate": 19516,
                        "signalQuality": 10
                    }
                ],
                "audioIn": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": 393,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": -1,
                        "latency": 0,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "audioOut": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": 0,
                        "latency": 0,
                        "jitter": 0,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareIn": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": -1,
                        "latency": 110,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": -1,
                        "latency": 110,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": -1,
                        "latency": 110,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": -1,
                        "latency": 97,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": -1,
                        "latency": 94,
                        "jitter": -1,
                        "mediaBitRate": -1,
                        "signalQuality": 10
                    }
                ],
                "shareOut": [
                    {
                        "time": "2021-10-07T08:25:29Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 33,
                        "mediaBitRate": 11102,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:30:00Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 35,
                        "mediaBitRate": 888,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:35:00Z",
                        "packetLoss": 0,
                        "latency": 110,
                        "jitter": 35,
                        "mediaBitRate": 879,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:40:00Z",
                        "packetLoss": 0.29,
                        "latency": 97,
                        "jitter": 36,
                        "mediaBitRate": 888,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:45:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 37,
                        "mediaBitRate": 869,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:50:00Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 36,
                        "mediaBitRate": 867,
                        "signalQuality": 10
                    },
                    {
                        "time": "2021-10-07T08:55:56Z",
                        "packetLoss": 0,
                        "latency": 94,
                        "jitter": 36,
                        "mediaBitRate": 845,
                        "signalQuality": 10
                    }
                ]
            }
        ]
    ],

    "TREND_TEMPLATE": [

        [
            {
                "webexAppName": "cisco-spark",
                "minHealthScore": 6,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 6,
                        "packetLoss": 1.79,
                        "jitter": null,
                        "latency": 87.33333333333333,
                        "applicationDelay": 4333.666666666667,
                        "clientNetworkDelay": 2,
                        "serverNetworkDelay": 85.33333333333333,
                        "dscp": null,
                        "usage": null,
                        "rate": 611.31
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 87,
                        "applicationDelay": 3619.3333333333335,
                        "clientNetworkDelay": 1.3333333333333333,
                        "serverNetworkDelay": 85.66666666666667,
                        "dscp": null,
                        "usage": null,
                        "rate": 361.24
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13265,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 402.48
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13636,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 143.12
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14790,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 127.15
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14247,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 143.68
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14192,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 132.83
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13861,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 133.87
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 12998,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 134.91
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 4692.333333333333,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 76,
                        "dscp": null,
                        "usage": null,
                        "rate": 285.83
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 4692.333333333333,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 76,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-video",
                "minHealthScore": 6,
                "avgHealthScore": 7,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 8777,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 30551.21
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 8154,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 19265.33
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 7498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 6,
                        "packetLoss": 5,
                        "jitter": 50498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 16898.56
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-audio",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2956,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 495.49
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3692,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 12954.69
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3310,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10010.51
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 4059,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 34360.88
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0.05,
                        "jitter": 3496,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 52880.85
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0.04,
                        "jitter": 3844,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 25212.51
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3296,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 18051.28
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0.05,
                        "jitter": 4004,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 19642.19
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 6,
                        "packetLoss": 5,
                        "jitter": 50498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 16898.56
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-meeting",
                "minHealthScore": 9,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 91.4,
                        "applicationDelay": 100.8,
                        "clientNetworkDelay": 1.2,
                        "serverNetworkDelay": 90.2,
                        "dscp": null,
                        "usage": null,
                        "rate": 1249.06
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 223.5,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1552.43
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 76.5,
                        "applicationDelay": 139,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 75.5,
                        "dscp": null,
                        "usage": null,
                        "rate": 749.58
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 1075.02
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 940.16
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 974.36
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 1,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 948.49
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 969.58
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 1,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 974.79
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 76.5,
                        "applicationDelay": 109,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 75.5,
                        "dscp": null,
                        "usage": null,
                        "rate": 1263.75
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 76.5,
                        "applicationDelay": 109,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 75.5,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-media",
                "minHealthScore": 7,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 7,
                        "packetLoss": 0.46,
                        "jitter": null,
                        "latency": 78,
                        "applicationDelay": 20,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 77,
                        "dscp": null,
                        "usage": null,
                        "rate": 324.46
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 291,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 159051.65
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 132,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2921.39
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 152,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3000.76
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 313,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2926.15
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 193,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3042.97
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 160,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2821.33
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 79,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3168.4
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 115,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 22506.76
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 455,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 471379.57
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 455,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-app-sharing",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-08T07:53:12.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 103.375,
                        "applicationDelay": 0.75,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 102.375,
                        "dscp": null,
                        "usage": null,
                        "rate": 619.24
                    },
                    {
                        "time": "2021-10-08T07:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1081.74
                    },
                    {
                        "time": "2021-10-08T08:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 938.88
                    },
                    {
                        "time": "2021-10-08T08:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1135.45
                    },
                    {
                        "time": "2021-10-08T08:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1351.84
                    },
                    {
                        "time": "2021-10-08T08:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1088.43
                    },
                    {
                        "time": "2021-10-08T08:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 943.08
                    },
                    {
                        "time": "2021-10-08T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1070.61
                    },
                    {
                        "time": "2021-10-08T08:30:00.000+0000",
                        "healthScore": 6,
                        "packetLoss": 5,
                        "jitter": 50498,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 35268.36
                    },
                    {
                        "time": "2021-10-08T08:35:00.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 16898.56
                    },
                    {
                        "time": "2021-10-08T08:37:41.000+0000",
                        "healthScore": 7,
                        "packetLoss": 5,
                        "jitter": 50210,
                        "latency": 200,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            }
        ],
        [
            {
                "webexAppName": "cisco-spark",
                "minHealthScore": 7,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 7,
                        "packetLoss": 0.37,
                        "jitter": null,
                        "latency": 92.5,
                        "applicationDelay": 4599.666666666667,
                        "clientNetworkDelay": 1.5,
                        "serverNetworkDelay": 91,
                        "dscp": null,
                        "usage": null,
                        "rate": 701.09
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 91,
                        "applicationDelay": 4727,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 90,
                        "dscp": null,
                        "usage": null,
                        "rate": 287.72
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14192,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 452.83
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13945,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 162.23
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13231,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 161.95
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14230,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 162.97
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14640,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 191.29
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 78,
                        "applicationDelay": 7092.5,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 77,
                        "dscp": null,
                        "usage": null,
                        "rate": 167.29
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13815,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 100.15
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 92,
                        "applicationDelay": 4621.333333333333,
                        "clientNetworkDelay": 0.5,
                        "serverNetworkDelay": 91.5,
                        "dscp": null,
                        "usage": null,
                        "rate": 179.21
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13528,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 181.48
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14476,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 165.73
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14367,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 190.11
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13622,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 180.35
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 6869,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 76,
                        "dscp": null,
                        "usage": null,
                        "rate": 316.64
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 6869,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 76,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-video",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 9634,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 7050.67
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1233.04
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1318.51
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1273.68
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1260.03
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1335.57
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 865.16
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1205.84
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1338.72
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 892.46
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1261.79
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1215.97
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1422.03
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1188.77
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 546.37
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-audio",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3156,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3723.44
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2425,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 15526.83
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2127,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 11178.77
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3423,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10119.07
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2795,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 8739.36
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 1983,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10343.47
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2588,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 7983.98
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 1976,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10963.87
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2742,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 9718.64
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2554,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10894.72
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2436,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10307.31
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3394,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 9038.61
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 4876,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 9680.35
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 3813,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10284.24
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2659,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 6625.99
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 2659,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-meeting",
                "minHealthScore": 9,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 91.6,
                        "applicationDelay": 96.4,
                        "clientNetworkDelay": 1.4,
                        "serverNetworkDelay": 90.2,
                        "dscp": null,
                        "usage": null,
                        "rate": 1857.59
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 609.6
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1190.17
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 0,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1170.16
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 1,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 1157.07
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 0,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1175.08
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1171.73
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 121.1923076923077,
                        "applicationDelay": 1.105263157894737,
                        "clientNetworkDelay": 1.2307692307692308,
                        "serverNetworkDelay": 119.96153846153847,
                        "dscp": null,
                        "usage": null,
                        "rate": 172.61
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1197.79
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 1161.96
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1294.36
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 1187.78
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 1095.48
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 114,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 113,
                        "dscp": null,
                        "usage": null,
                        "rate": 1132.08
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 76,
                        "applicationDelay": 106.5,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 75,
                        "dscp": null,
                        "usage": null,
                        "rate": 1434.94
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 76,
                        "applicationDelay": 106.5,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 75,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-media",
                "minHealthScore": 8,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 8,
                        "packetLoss": 0.17,
                        "jitter": null,
                        "latency": 77.5,
                        "applicationDelay": 97,
                        "clientNetworkDelay": 0.5,
                        "serverNetworkDelay": 77,
                        "dscp": null,
                        "usage": null,
                        "rate": 4083.05
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 115,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3277.36
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 177,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2645.09
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 136,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3179.01
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 177,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2816.03
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 162,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3228.64
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 104,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 10573.09
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 154,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 4038.56
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 127,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2883.12
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 170,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2836.16
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 120,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 3162.97
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 143,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 2825.79
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 141,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 12936.01
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 78,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 9777.92
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 137,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 6618.32
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 137,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-app-sharing",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T09:01:31.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 104.625,
                        "applicationDelay": 1.125,
                        "clientNetworkDelay": 1.25,
                        "serverNetworkDelay": 103.375,
                        "dscp": null,
                        "usage": null,
                        "rate": 897.26
                    },
                    {
                        "time": "2021-10-07T09:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1093.96
                    },
                    {
                        "time": "2021-10-07T09:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1055.54
                    },
                    {
                        "time": "2021-10-07T09:15:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1040.69
                    },
                    {
                        "time": "2021-10-07T09:20:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1007.24
                    },
                    {
                        "time": "2021-10-07T09:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1051.91
                    },
                    {
                        "time": "2021-10-07T09:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 785.12
                    },
                    {
                        "time": "2021-10-07T09:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1091.86
                    },
                    {
                        "time": "2021-10-07T09:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 989.76
                    },
                    {
                        "time": "2021-10-07T09:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 415.09
                    },
                    {
                        "time": "2021-10-07T09:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1004.55
                    },
                    {
                        "time": "2021-10-07T09:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1071.82
                    },
                    {
                        "time": "2021-10-07T10:00:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1348.57
                    },
                    {
                        "time": "2021-10-07T10:05:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1573.56
                    },
                    {
                        "time": "2021-10-07T10:10:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1139.36
                    },
                    {
                        "time": "2021-10-07T10:12:35.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            }
        ],
        [
            {
                "webexAppName": "cisco-spark",
                "minHealthScore": 6,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 90.5,
                        "applicationDelay": 13478,
                        "clientNetworkDelay": 0.5,
                        "serverNetworkDelay": 90,
                        "dscp": null,
                        "usage": null,
                        "rate": 346.73
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 6,
                        "packetLoss": 1.9,
                        "jitter": null,
                        "latency": 92,
                        "applicationDelay": 3420.25,
                        "clientNetworkDelay": 0.5,
                        "serverNetworkDelay": 91.5,
                        "dscp": null,
                        "usage": null,
                        "rate": 435.17
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13697,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 447.39
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13089,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 309.56
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14457,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 178.19
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 14430,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 155.33
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 13972,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 161.52
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 81,
                        "applicationDelay": 7028,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 80,
                        "dscp": null,
                        "usage": null,
                        "rate": 367.5
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 81,
                        "applicationDelay": 7028,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 80,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-video",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 73.28
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 5854,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 24220.66
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1699.15
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1930.88
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1708.99
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 9004,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 9671.71
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 11823,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 212702.85
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 13122,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 14278.57
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 13122,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-audio",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 0,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 42.67
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": 5706,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 940.89
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1340.64
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1399.12
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1267.31
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1275.23
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1306.45
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 289.11
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": null,
                        "packetLoss": null,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-meeting",
                "minHealthScore": 9,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 97.2,
                        "applicationDelay": 132.5,
                        "clientNetworkDelay": 1.8,
                        "serverNetworkDelay": 95.4,
                        "dscp": null,
                        "usage": null,
                        "rate": 791.09
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 120,
                        "applicationDelay": 142.66666666666666,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 119,
                        "dscp": null,
                        "usage": null,
                        "rate": 1288.52
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 120,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 119,
                        "dscp": null,
                        "usage": null,
                        "rate": 392.11
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 121,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 120,
                        "dscp": null,
                        "usage": null,
                        "rate": 409.95
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 400.61
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 1,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 409.37
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 115,
                        "applicationDelay": 0,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 114,
                        "dscp": null,
                        "usage": null,
                        "rate": 567.76
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 112,
                        "clientNetworkDelay": 2,
                        "serverNetworkDelay": 75,
                        "dscp": null,
                        "usage": null,
                        "rate": 1161.68
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": 9,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 77,
                        "applicationDelay": 112,
                        "clientNetworkDelay": 2,
                        "serverNetworkDelay": 75,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-media",
                "minHealthScore": 3,
                "avgHealthScore": 9,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": 6,
                        "packetLoss": 6.82,
                        "jitter": null,
                        "latency": 84,
                        "applicationDelay": 0.5,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 83,
                        "dscp": null,
                        "usage": null,
                        "rate": 212.55
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 8,
                        "packetLoss": 0.23,
                        "jitter": null,
                        "latency": 47,
                        "applicationDelay": 395,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 46,
                        "dscp": null,
                        "usage": null,
                        "rate": 3451.74
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 0.5,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1427.67
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 1,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1468.59
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": 3,
                        "packetLoss": 0.75,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 85.5,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1715.72
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 149,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1478.35
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 1312,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 1752.64
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 1543,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 5617.72
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": 1543,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            },
            {
                "webexAppName": "webex-app-sharing",
                "minHealthScore": 10,
                "avgHealthScore": 10,
                "healthTrend": [
                    {
                        "time": "2021-10-07T08:24:29.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 119.42857142857143,
                        "applicationDelay": 0.7142857142857143,
                        "clientNetworkDelay": 1.4285714285714286,
                        "serverNetworkDelay": 118,
                        "dscp": null,
                        "usage": null,
                        "rate": 385.09
                    },
                    {
                        "time": "2021-10-07T08:25:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": 47,
                        "applicationDelay": 1,
                        "clientNetworkDelay": 1,
                        "serverNetworkDelay": 46,
                        "dscp": null,
                        "usage": null,
                        "rate": 803.04
                    },
                    {
                        "time": "2021-10-07T08:30:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 964
                    },
                    {
                        "time": "2021-10-07T08:35:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 928.94
                    },
                    {
                        "time": "2021-10-07T08:40:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 928.76
                    },
                    {
                        "time": "2021-10-07T08:45:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 948.39
                    },
                    {
                        "time": "2021-10-07T08:50:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 881.37
                    },
                    {
                        "time": "2021-10-07T08:55:00.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": 409.06
                    },
                    {
                        "time": "2021-10-07T08:55:56.000+0000",
                        "healthScore": 10,
                        "packetLoss": 0,
                        "jitter": null,
                        "latency": null,
                        "applicationDelay": null,
                        "clientNetworkDelay": null,
                        "serverNetworkDelay": null,
                        "dscp": null,
                        "usage": null,
                        "rate": null
                    }
                ]
            }
        ]
    ]


});