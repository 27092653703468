define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimIpPoolData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGlobalCredentialData'
], function (UtilityFunctions, SimLokiDatabaseActions, SimIpPoolData, SimGlobalCredentialData) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction) {
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                //PUT or POST or DELETE case will come in default..
                default:
                    data = processRequest(urlAction);
                    break;
            }

            return data;
        }
    };

    /* var urlAction = {
                          method: '', service: [], serviceName: '',
                          isDefaultHandling : true, jsonTemplate: '', filter: {}, 
                          action: {
                            id: '', count: false, summary: false, child: false
                          },
                          decoration: {
                              sortBy: false, order : 'asc', limit: 500, offset: 0
                          }
                        };*/
    function processGetRequest(urlAction){
        var tObj;
        if(urlAction.action.id != '') {
            tObj = SimLokiDatabaseActions.getFilteredRecordHandler(urlAction.serviceName, {'id' : urlAction.id });
            data = tObj && tObj.length ? tObj : [];
        } else {
            tObj = SimLokiDatabaseActions.getAll(urlAction.serviceName,urlAction.filter,urlAction);
        }

        if(urlAction.action.count) {
            return tObj && tObj.length ? tObj.length : 0;
        }

        //FIXME  -- Below code should move to SimAssuranceHostData file..
        if(urlAction.serviceName == 'host') {
            tObj = SimLokiDatabaseActions.getAll(urlAction.serviceName);//To avoid limit/offset related problem -- read again from lokijs

            //CAUTION -- FIXME
            //In UI some places e.g. path trace attribute hostIp is used, hence functionality to work temp hack.. 
            for(var i = 0; i < tObj.length; i++) {
                tObj[i].hostIp = tObj[i].hostIpV4;
            }
        }

        //ToDo apply any filters further..
        //console.log(tObj)

        var data = tObj && tObj.length ? tObj : [];
        //apply any decoration..
        console.log(data);

        return data;
    }

    function processRequest(urlAction) {
        switch(urlAction.serviceName){
            case 'global-credential':
                return SimGlobalCredentialData.processCredential(urlAction);
        }

        /*
        for(var key in urlAction.restPayload){
            if(urlAction.restPayload.hasOwnProperty(key)){
                urlAction.jsonTemplate.key = urlAction.restPayload[key];     
            }
        }
        urlAction.jsonTemplate.id = UtilityFunctions.generate_uuid();
        SimLokiDatabaseActions.insert(urlAction.serviceName, urlAction.jsonTemplate);*/

    }

});
