define({
  "DEFAULT_UUID": {
      "ConnectivityDomain": "067ed90c-6487-4d51-abbb-13701eb02fe8",
      "VirtualNetworkContext": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
      "VirtualNetwork": "50f6ad82-4f61-41e7-8ff4-4f7b3fd52dea",
      "TopologyCloudNode": "3381a950-879f-46f3-bbf3-383094b901f0",
      "GlobalGroup": "80b4fcb8-6f56-4986-b520-3b6ce972464a"
  },
  "DiscoveredDevice": {
      "small": 29,
      "medium": 40,
      "large": 60
  },
  "HostDevice": {
      "IPADDRESS_PREFIX": "192.100.100.", 
      "MACADDRESS_PREFIX": "BC:9F:EF:9E:27:"
  },
  //Network devices to be present during init
  "NetworkDevice1": {
    "access": {count:32,role:"ACCESS",ipAddr:"10.41.54.172",macAddr:"00:f6:79:e6:13:30",prefix: "Access",random:true,
      names: ['p1.edge1-sda1.local', 'BLD1-FLR2-ACCESS', 'BLD2-FLR1-ACCESS', 'BLD2-FLR2-ACCESS','BLD3-FLR1-ACCESS',
        'BLD3-FLR2-ACCESS',"SJC06-C9300-01","SJC06-C9300-02","SJC06-C9300-03","EDGE01-SJC23.cisco.local","SFO15-C9300-01","SFO15-C9300-02","SFO15-C9300-03","SFO15-C9200-01","SFO15-C9200-02",
        "IE4K-ExtNode", "IE4K-POE_ExtNode", "IE-3300_6","SJC06-C9300-05","SJC06-C9300-04","SFO11-C9300-Edge-01", "PLS06-C9300-01", 
        "FE1-9300-03.cisco.com", "FE2-9300-04.cisco.com", "pb-fusion-router","SFO13-A3850-1", "IE3400_1", "IE3400_2","IE3400_3", "SJC01-Stack-01", "SJC01-C9300-01", "SJC01-C9300-02"],
        deviceinfo: [{"platformid":"c9300-24P", "swversion":"17.6.1", "vrf":"Mgmt-vrf"},{"platformid":"c9300-24P", "swversion":"17.6.1", "vrf":"Mgmt-vrf"},
        {"platformid":"WS-C4507R+E", "swversion":"03.08.00.E"},{"platformid":"c9300-24P", "swversion":"17.6.1", "vlan": "Vlan1"},
        {"platformid":"c9300-24P", "swversion":"17.6.1", "vlan": "Vlan1"},{"platformid":"WS-C4507R+E", "swversion":"03.08.00.E"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"c9300-24P", "swversion":"17.9.1"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"C9300-48U", "swversion":"17.9.1"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"c9300-24P", "swversion":"17.9.1"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"C9200-24T", "swversion":"17.9.1"},
        {"platformid":"C9200-24T", "swversion":"17.9.1"},{"platformid":"IE-4000-8T4G-E", "swversion":"15.2.8E2"},
        {"platformid":"IE-4000-4GS8GP4G-E", "swversion":"15.2.8E2"},{"platformid":"IE-3300-8P2S", "swversion":"17.9.1"},
        {"platformid":"C9200-24T", "swversion":"17.9.1"},{"platformid":"c9300-24P", "swversion":"17.9.1"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"c9300-24P", "swversion":"17.9.1"},
        {"platformid":"c9300-24P", "swversion":"17.9.1"}, {"platformid":"c9300-24P", "swversion":"17.9.1"},
        {"platformid":"WS-C3650-48PD-E", "swversion":"16.12.8"},{"platformid":"WS-C3850-12X48U-S", "swversion":"16.12.8"},
        {"platformid":"IE-3400-8P2S", "swversion":"17.9.1"},{"platformid":"IE-3400-8P2S", "swversion":"17.9.1"}, {"platformid":"IE-3400-8P2S", "swversion":"17.9.1"},{"platformid":"C9300-24U,C9300-48UXM,C9300-24P,C9300-48P",
        "swversion":"16.12.3sprd3","isStack":true},
        {"platformid":"c9300-24P", "swversion":"17.9.1"},{"platformid":"c9300-24P", "swversion":"17.9.1"}
        ]
    },
    "core": { count: 8,role:"CORE",ipAddr:"10.0.255.31",macAddr:"00:f6:63:b8:23:30",prefix: "Core",random:true,
      names: ['C9404R-border_cp_sda.local','CAMPUS-CORE2','CAMPUS-CORE3', "SJC06-C9600-01","SJC06-C9600-02","nfvis-1", "nfvis-2",'BORDER1-SJC23.cisco.local'],
      deviceinfo:[{"platformid":"C9404R", "swversion":"17.9.1", "routing":"isis"}, {"platformid":"C9404R", "swversion":"17.9.1"}, 
      {"platformid":"C9404R", "swversion":"17.9.1"}, {"platformid":"C9606R", "swversion":"17.9.1"}, 
      {"platformid":"C9606R", "swversion":"17.9.1"}, {"platformid":"ENCS5412/K9", "swversion":"4.9.1"}, 
      {"platformid":"ENCS5412/K9", "swversion":"4.9.1"}, {"platformid":"ISR4451-X/K9", "swversion":"17.9.1"} ]
    },
    "distribution": {count: 12,role:"DISTRIBUTION",ipAddr:"10.201.80.68",macAddr:"00:f6:45:f7:72:50",prefix:"Dist",random:true,
      names: ['BLD1-FLR1-DST1','BLD1-FLR2-DST1','BLD2-FLR1-DST1','BLD2-FLR2-DST1','BLD3-FLR1-DST1','BLD3-FLR2-DST1', 
      'INT-BOR.cisco.com','CONTROL-PLANE.cisco.com', "SFO13-D9300-1","SFO13-D9300-2","SFO15-C9500-Core-01","SFO15-C9500-Core-02" ],
      deviceinfo: [{"platformid":"c9300-24P", "swversion":"17.9.1"}, {"platformid":"c9300-24P", "swversion":"17.9.1"}, 
      {"platformid":"c9300-24P", "swversion":"17.9.1"}, {"platformid":"c9300-24P", "swversion":"17.9.1"}, 
      {"platformid":"c9300-24P", "swversion":"17.9.1"}, {"platformid":"c9300-24P", "swversion":"17.9.1"},
      {"platformid":"WS-C3850-12X48U-E", "swversion":"16.12.8"}, {"platformid":"c9300-24P", "swversion":"17.9.1"},
      {"platformid":"C9300-24U", "swversion":"17.9.1"},{"platformid":"C9300-24U", "swversion":"17.9.1"}, {"platformid":"C9500-16X, C9500-16X", "swversion":"17.9.1", "isStack":true}, 
      {"platformid":"C9500-16X", "swversion":"17.9.1", "isStack":true}]
    },
    "edge": { count: 13,role:"BORDER ROUTER",prefix:"Edge",ipAddr:"10.32.168.29", macAddr:"ac:f2:c5:23:70:90",random:true,
      names: ['WAN_EDGE1','WAN_EDGE2','WAN_EDGE3','IR1101-ISR-Rugged','WAN_EDGE4','WAN_EDGE5','WAN_EDGE6',"SJC06-ISR4461-01","SJC06-ISR4461-02","SFO15-ISR4461-01","SFO15-ISR4461-02","FUSION1-SJC23.cisco.local","FUSION1-SFO11.cisco.local"],
      deviceinfo: [{"platformid":"ISR4451-X/K9", "swversion":"17.9.1", "routing":"isis"}, {"platformid":"ASR1001-X", "swversion":"17.9.1"}, 
      {"platformid":"ASR1002-X", "swversion":"17.9.1", "vrf":"Mgmt-intf"}, {"platformid":"IR1101-K9", "swversion":"17.9.1"}, 
      {"platformid":"ISR4461-X/K9", "swversion":"17.9.1"}, {"platformid":"ISR4331/K9", "swversion":"17.9.1"}, 
      {"platformid":"ISR4331/K9", "swversion":"17.9.1"}, {"platformid":"ISR4461-X/K9", "swversion":"17.9.1"}, 
      {"platformid":"ISR4461-X/K9", "swversion":"17.9.1"}, {"platformid":"ISR4461-X/K9", "swversion":"17.9.1"}, 
      {"platformid":"ISR4461-X/K9", "swversion":"17.9.1"}, {"platformid":"ISR4331/K9", "swversion":"17.9.1"}, 
      {"platformid":"ISR4331/K9", "swversion":"17.9.1"} ]
    },
    "wlc": { count: 9,role:"ACCESS",prefix:"WLC",ipAddr:"10.30.200.3",macAddr:"00:f6:63:69:11:8b",random:true,
      names:['WLC-9800','WLC-FABRIC-01','Campus_WLC3','Campus_WLC4','Campus_WLC5',"SJC06-WLC-ISSU","SJC06-vWLC-9800", "PLS06-WLC-9800", "WLC-3504"],
      deviceinfo: [{"platformid":"C9800-40-K9", "swversion":"17.9.1"}, {"platformid":"C9800-40-K9", "swversion":"17.9.1"}, 
      {"platformid":"C9800-40-K9", "swversion":"17.9.1"}, {"platformid":"C9800-40-K9", "swversion":"17.9.1"}, 
      {"platformid":"C9800-40-K9", "swversion":"17.9.1"}, {"platformid":"C9800-CL-K9", "swversion":"17.6.1"}, 
      {"platformid":"C9800-40-K9", "swversion":"17.9.1"}, {"platformid":"C9800-40-K9", "swversion":"17.9.1"},
      {"platformid":"AIR-CT3504-K9", "swversion":"8.10.181.0"} ]
    },
    "ap": { count: 42,role:"ACCESS",prefix:"AP4800",ipAddr:"10.85.0.21",macAddr:"a0:f8:49:7e:c0:40",random:false,
      names:['SJC01_9136_1','AP0081.C424.3CE2','LAB-AP00F2.8B27.B788','SJC01_9136_9','SJC01_9136_10','SJC01_9136_11','AP4800',/*"SFO15-AP4800-01",
      "SFO15-AP4800-02",*/"SFO15-C9124-03","SFO15-C9136-04","SFO15-C9120-04","SFO10-AP3800-01","SFO10-AP3800-02","SJC01_9136_12",
      "SJC01_9136_13","PLS06-AP3800-01", "AP70B3.1733.D6D4", "APF4DB.E6CE.4F24", "PLS06-9120-01",
      /*"SFO15-C9105-01","SFO15-C9136-05",*/"SFO15-C9120-01","SFO15-C9120-02","SFO15-C9120-03","SFO15-C9124-01","SFO15-C9124-02",
      "SFO15-C9136-01","SFO15-C9136-02","SFO15-C9136-03", "SJC01_9136_14", "SJC01_9136_15", "SJC01_9136_16", "AP9136_1", "SJC01_9136_17",/* add APs here */"SCJ01_9136_2", "SCJ01_9136_3", "SCJ01_9136_4", "SCJ01_9136_5", "SCJ01_9136_6", "SCJ01_9136_7", "SCJ01_9136_8",
      "AP9120_3","SFO11-AP4800-01","SFO11-AP4800-02"],
      deviceinfo: [{"platformid":"C9136I-B", "swversion":"17.9.1.8"}, 
      {"platformid":"AIR-AP1852I-B-K9", "swversion":"8.10.181.0"}, {"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.181.0"}, 
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, 
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.181.0"},
      /*{"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.151.0"}, {"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.151.0"},*/
      {"platformid":"C9124AXI", "swversion":"15.3.3-JPN"},{"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      {"platformid":"C9120AXP-B", "swversion":"17.9.1"}, {"platformid":"AIR-AP3802I-B-K9", "swversion":"8.10.151.0"}, 
      {"platformid":"AIR-AP3802I-B-K9", "swversion":"8.10.151.0"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, 
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"AIR-AP3802I-B-K9", "swversion":"8.10.151.0"}, 
      {"platformid":"AIR-AP4800-A-K9", "swversion":"8.10.181.0"}, {"platformid":"AIR-AP4800-A-K9", "swversion":"8.10.181.0"}, 
      {"platformid":"C9120AXP-B", "swversion":"17.9.1"}, /*{"platformid":"C9105AXI-A", "swversion":"15.3.3-JPK1"},
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"},*/ {"platformid":"C9120AXE-A", "swversion":"15.3.3-JPN"},
      {"platformid":"C9120AXE-B", "swversion":"15.3.3-JPN"}, {"platformid":"C9120AXE-C", "swversion":"15.3.3-JPN"},
      {"platformid":"C9124AXI", "swversion":"15.3.3-JPN"},{"platformid":"C9124AXI", "swversion":"15.3.3-JPN"},
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"},{"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      /* add the added AP configuration here to be in sync */
      {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"}, {"platformid":"C9136I-B", "swversion":"17.9.1.8"},
      {"platformid":"C9120AXP-B", "swversion":"17.9.1"},
      {"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.181.0"}, {"platformid":"AIR-AP4800-B-K9", "swversion":"8.10.181.0"}]
    },
    "sensor": {count: 4,role: "ACCESS",prefix: "SEN",ipAddr: "10.32.168.118",macAddr: "70:f3:5a:78:6b:00",random:true,
      names: ['AP1800S_Sensor_01', 'AP1800S_Sensor_02', 'AP1800S_Sensor_03','AP1800S_Sensor_04'],
      deviceinfo: [{"platformid":"AIR-AP1800S-B-K9", "swversion":"2.3.4.0"}, {"platformid":"AIR-AP1800S-B-K9", "swversion":"2.3.4.0"},
       {"platformid":"AIR-AP1800S-B-K9", "swversion":"2.3.4.0"},{"platformid":"AIR-AP1800S-B-K9", "swversion":"2.3.4.0"}]
    },
    "eca": { count: 1,role:"ACCESS",prefix:"ECA",ipAddr:"10.225.117.130",macAddr:"ec:1d:8b:0a:d8:00",random:true,
      names:['ECA00EB.D510.3F18', 'ECA0081.C424.3CE2','ECA00F2.8B27.B788'],deviceinfo:[
        {"platformid":"C9300-48UXM", "swversion":"17.9.1"}, {"platformid":"C9300-48UXM", "swversion":"17.9.1"}, 
        {"platformid":"C9300-48UXM", "swversion":"17.9.1"} ]
    }
},
  "NetworkDevice": {
      "Default_CD_AllocatedDeviceInPercent": 35,
      "CloudNodeEnabled": true,
      "core": 4,
      "distribution": 9,
      "edge": 4,
      "wlc": 3,
      "host": 2,
      "ap": 3,
      "eca": 3,
      "eca_names": ['ECA00EB.D510.3F18', 'ECA0081.C424.3CE2','ECA00F2.8B27.B788'],
      "core_names": ['C9404R-border_cp_sda.local', 'CAMPUS-CORE2', 'CAMPUS-CORE3','BORDER1-SJC23.cisco.local'],
      "dist_names": ['BLD1-FLR1-DST1', 'BLD1-FLR2-DST1', 'BLD2-FLR1-DST1', 'BLD2-FLR2-DST1', 'BLD3-FLR1-DST1', 'BLD3-FLR2-DST1'],
      "edge_names": ['WAN_EDGE1', 'WAN_EDGE2', 'WAN_EDGE3',"FUSION1-SJC23.cisco.local", 'FUSION1-SFO11.cisco.local'],
      "access_names": ['p1.edge1-sda1.local', 'BLD1-FLR2-ACCESS', 'BLD2-FLR1-ACCESS', 'BLD2-FLR2-ACCESS', 'BLD3-FLR1-ACCESS', 'BLD3-FLR2-ACCESS',"EDGE01-SJC23.cisco.local", "SJC01-C9300-01", "SJC01-C9300-02"],
      "wlc_names": ['WLC-9800','WLC-FABRIC-01','Campus_WLC3'],
      "host_names": ['192.168.101.12', '192.168.101.103'],
      "ap_names": ['SJC01_9136_1', 'AP0081.C424.3CE2','LAB-AP00F2.8B27.B788'],
      "ACCESS_PREFIX": "Access_",
      "CORE_PREFIX": "Core_",
      "DISTRIBUTION_PREFIX": "Dist_",
      "EDGE_PREFIX": "Edge_",
      "HOST_PREFIX": "Host_",
      "WLC_PREFIX": "WLC_",
      "AP_PREFIX": "AP_",
      "IPADDRESS_PREFIX": "10.41.54.172-10.41.54.176 10.41.54.187-10.41.54.190 10.0.255.31-10.0.255.33 10.201.80.68-10.201.80.72 10.201.80.83-10.201.80.83 10.32.168.29-10.32.168.31 10.30.200.3-10.30.200.5 10.85.0.21-10.85.0.23 10.225.117.130-10.225.117.132",
      "MACADDRESS_PREFIX": "20:bb:c0:5f:d8:",
      "EDGE_PLATFORM_ID": "CISCO891W-AGN-A-K",
      "CORE_PLATFORM_ID": "WS-C3850-12XS-E",
      "DIST_PLATFORM_ID": "WS-C3850-12XS-E",
      "ACCESS_PLATFORM_ID": "WS-C3850-12XS-E",
      "WLC_PLATFORM_ID": "AIR-CT5520-K9",
      "AP_PLATFORM_ID": "UNKNOWN",
      "EDGE_DEVICE_TYPE": "Cisco 891,891W Integrated Services Router G2",
      "EDGE_DEVICE_VERSION":"15.1(4)M2",
      "ACCESS_DEVICE_TYPE": "Cisco Catalyst 36xx stack-able ethernet switch",
      "ACCESS_DEVICE_VERSION": "16.6.20170926:081947",
      "DIST_DEVICE_TYPE": "Cisco Catalyst38xx stack-able ethernet switch",
      "DIST_DEVICE_VERSION": "16.6.2",
      "CORE_DEVICE_TYPE": "Cisco Catalyst38xx stack-able ethernet switch",
      "CORE_DEVICE_VERSION": "16.5.20170425:052249",
      "WLC_DEVICE_TYPE": "Cisco 5520 Series Wireless Controllers",
      "WLC_DEVICE_VERSION":"8.5.103.0",
      "HOST_DEVICE_TYPE": "wired",
      "HOST_DEVICE_VERSION":"",
      "AP_DEVICE_TYPE": "UNKNOWN",
      "AP_DEVICE_VERSION": "8.5.107.71",
      "EDGE_FAMILY": "Routers",
      "CORE_FAMILY": "Switches and Hubs",
      "DIST_FAMILY": "Switches and Hubs",
      "ACCESS_FAMILY": "Switches and Hubs",
      "WLC_FAMILY": "Wireless Controller",
      "AP_FAMILY": "Unified AP",
      "SENSOR_FAMILY": "Wireless Sensor",
      "HOST_FAMILY": "WIRED",
      "ECA_DEVICE_TYPE": "Cisco 5520 Series Wireless Controllers",
      "ECA_FAMILY": "ECA Switch",
      "ECA_PLATFORM_ID": "WS-C3850-12XS-E",
      "ECA_PREFIX": "ECA_",
      "ECA_DEVICE_VERSION":"8.5.107.0",
      //Serial Number Prefix
      "ECA_SN_PREFIX":"FJK",
      "CORE_SN_PREFIX":"FCW",
      "ACCESS_SN_PREFIX":"FDO",
      "DIST_SN_PREFIX":"FOC",
      "WLC_SN_PREFIX":"FCH",
      "EDGE_SN_PREFIX":"FTX",
      "NFVIS_FAMILY":"NFVIS"
  },
  "TopologyCloudNode": {
      deviceType: "cloud node",
      label: "cloud node",
      ip: "UNKNOWN",
      softwareVersion: "UNKNOWN",
      nodeType: "cloud node",
      family: "cloud node",
      platformId: "UNKNOWN",
      tags: ["cloud node"],
      role: "cloud node",
      roleSource: "AUTO",
      customParam: {},
      id: "",
      topology_type: "node"
  },
  /*
  Below DEFAULT_NAME is used for Create VN
  if we want add new VN add here and in SimVirtualNetworkContextData.js
  in return statmnt add as per the pattern 
  */
  "DEFAULT_NAME": {
      "ConnectivityDomain": 'Default_LAN_Fabric',
      "VirtualNetwork": 'DEFAULT_VN',
      "VirtualNetworkInfra":"INFRA_VN",
      "VirtualNetworkEmployee":"Employee_VN",
      "VirtualNetworkIOT":"IOT_VN"
  },
  "Segment": {
      "Default_IpPool_Create": 5,
      "IPPool_Address_Prefix": '10',
      "IPPool_Name_Prefix": 'pool',
      "IPPool_Mask": 16
  },

  //IP Pool creation during init
  "POOL_DEFAULT_DATA": [
      {
        "ipPoolName": "Campus_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["172.16.101.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "172.16.101.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Corporate_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.10.0.1"],"totalIpAddressCount": 65536,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.10.0.0/16","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Underlay_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.14.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount":0 ,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.14.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Extended_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.15.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.15.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.12.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.12.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Lan_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": [ "192.168.20.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0, "parentUuid": "root",  "dnsServerIps": [ "192.168.101.105"],"ipPoolCidr": "192.168.20.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Infra_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.18.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.18.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "AP_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.19.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.19.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Lan_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.0.255.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.0.255.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Service_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.0.254.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.0.254.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Man_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.0.253.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.0.253.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Wan_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.0.252.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.0.252.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "RP_Pool_SJC01","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.0.252.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.0.258.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Campus_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["172.16.102.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "172.16.102.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Corporate_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.10.1.1"],"totalIpAddressCount": 65536,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.10.1.0/16","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Underlay_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.24.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount":0 ,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.24.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Extended_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.25.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.25.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.22.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.22.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Lan_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": [ "192.168.30.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0, "parentUuid": "root",  "dnsServerIps": [ "192.168.101.105"],"ipPoolCidr": "192.168.30.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Infra_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.28.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.28.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "AP_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.29.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.29.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Multicast_Pool_SJC05","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.29.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.31.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Campus_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["172.16.103.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "172.16.103.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Corporate_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.10.3.1"],"totalIpAddressCount": 65536,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.10.3.0/16","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Underlay_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.34.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount":0 ,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.34.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.32.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.32.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SJC23","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.32.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.65.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Lan_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": [ "192.168.40.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0, "parentUuid": "root",  "dnsServerIps": [ "192.168.101.105"],"ipPoolCidr": "192.168.40.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Infra_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.38.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.38.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "AP_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.39.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"], "ipPoolCidr": "192.168.39.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "InsideThings_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.45.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.45.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "OutsideThings_Pool_SJC06","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.46.1"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root", "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.46.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SJC22","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.56.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.56.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SFO11","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.33.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.35.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SFO12","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.33.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.33.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "Border_Pool_SFO15","dhcpServerIps": ["192.168.102.105"],"gateways": ["192.168.33.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.102.105"],"ipPoolCidr": "192.169.33.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "IPv6_Pool_1","dhcpServerIps": ["192.168.102.105"],"gateways": ["fe80::1"],"totalIpAddressCount": 4294967296,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.102.105"],"ipPoolCidr": "2001:db8:abcd:0012::0/96","configureExternalDhcp":false,"ipv6":true
      },
      {
        "ipPoolName": "IPv6_Pool_2","dhcpServerIps": ["192.168.102.105"],"gateways": ["fe80::1"],"totalIpAddressCount": 4294967296,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.102.105"],"ipPoolCidr": "2001:db8:abcd:0013::0/96","configureExternalDhcp":false,"ipv6":true
      },
      {
        "ipPoolName": "IPv6_Pool_3","dhcpServerIps": ["192.168.102.105"],"gateways": ["fe80::1"],"totalIpAddressCount": 4294967296,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.102.105"],"ipPoolCidr": "2001:db8:abcd:0014::0/96","configureExternalDhcp":false,"ipv6":true
      },
      {
        "ipPoolName": "IPv6_Pool_4","dhcpServerIps": ["192.168.102.105"],"gateways": ["fe80::1"],"totalIpAddressCount": 4294967296,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.102.105"],"ipPoolCidr": "2001:db8:abcd:0015::0/96","configureExternalDhcp":false,"ipv6":true
      },
      {
        "ipPoolName": "Border_Pool_SJC04","dhcpServerIps": ["192.168.101.105"],"gateways": ["192.168.66.0"],"totalIpAddressCount": 256,
        "usedIpAddressCount": 0,"parentUuid": "root","dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "192.168.66.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Lan_Pool_SJC04","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.5.255.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.5.255.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Service_Pool_SJC04","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.5.254.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.5.254.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Man_Pool_SJC04","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.5.253.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.5.253.0/24","configureExternalDhcp":false,"ipv6":false
      },
      {
        "ipPoolName": "NFV_Wan_Pool_SJC04","dhcpServerIps": ["192.168.101.105"],"gateways": ["10.5.252.1"],"totalIpAddressCount": 256,"usedIpAddressCount": 0,"parentUuid": "root",
        "dnsServerIps": ["192.168.101.105"],"ipPoolCidr": "10.5.252.0/24","configureExternalDhcp":false,"ipv6":false
      }
  ],
  "BAD_DEVICE": [
      "Access_1",
      "WAN_EDGE1",
      "C9404R-border_cp_sda.local",
      "AP0081.C424.3CE2"
   ],
   // Commenting below RF Profile for Enhanced RRM in frey
  //  "RF_PROFILE": [{"rfProfileName":"LOW","parentProfileA":"LOW","parentProfileB":"LOW","defaultRfProfile": false,"isARadioType":true,"isBRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","dataRatesA":"6,9,11,12,18,24,36,48,54","dataRatesB":"1,2,5.5,6,9,11,12,18,24,36,48,54","isCustom":false,"isBrownField": false,"mandatoryDataRatesA": "1,2,5.5,11","mandatoryDataRatesB": "12","minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":-10,"maxPowerLevelB":30,"powerThresholdV1A":-60,"powerThresholdV1B":-65,"rxSopThresholdA":"LOW","rxSopThresholdB":"LOW"},
  //             {"rfProfileName":"HIGH","parentProfileA":"HIGH","parentProfileB":"HIGH","defaultRfProfile": false,"isARadioType":true,"isBRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","dataRatesA":"12,18,24,36,48,54","dataRatesB":"9,12,18,24,36,48,54","isCustom":false, "isBrownField": false,"mandatoryDataRatesA": "12,24","mandatoryDataRatesB": "12","minPowerLevelA":7,"maxPowerLevelA":30,"minPowerLevelB":7,"maxPowerLevelB":30,"powerThresholdV1A":-65,"powerThresholdV1B":-70,"rxSopThresholdA":"MEDIUM","rxSopThresholdB":"MEDIUM"},
  //             {"rfProfileName":"TYPICAL","parentProfileA":"TYPICAL","parentProfileB":"TYPICAL","defaultRfProfile": false,"isARadioType":true,"isBRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","dataRatesA":"6,9,12,18,24,36,48,54","dataRatesB":"9,12,18,24,36,48,54","isCustom":false,"isBrownField": false,"mandatoryDataRatesA": "6,12,24","mandatoryDataRatesB": "12","minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":-10,"maxPowerLevelB":30,"powerThresholdV1A":-70,"powerThresholdV1B":-70,"rxSopThresholdA":"AUTO","rxSopThresholdB":"AUTO"}],

    "RF_PROFILE":[{"rfProfileName":"LOW","parentProfileA":"LOW","parentProfileB":"LOW","parentProfileC":"LOW","isARadioType":true,"isBRadioType":true,"isCRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","cRadioChannels":"5,21,37,53,69,85,101,117,133,149,165,181,197,213,229","dataRatesA":"6,9,11,12,18,24,36,48,54","dataRatesB":"1,2,5.5,6,9,11,12,18,24,36,48,54","dataRatesC":"6,9,12,18,24,36,48,54","mandatoryDataRatesA":"6,12,24","mandatoryDataRatesB":"1,2,5.5,11","mandatoryDataRatesC":"6,12,24","isCustom":false,"minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":-10,"maxPowerLevelB":30,"minPowerLevelC":-10,"maxPowerLevelC":30,"powerThresholdV1A":-60,"powerThresholdV1B":-65,"powerThresholdV1C":-70,"rxSopThresholdA":"LOW","rxSopThresholdB":"LOW","rxSopThresholdC":"AUTO","defaultRfProfile":false,"isBrownField":false,"isAiRfProfile":false,"isFraEnabled":false,"isDcaEnabledA":false,"isDcaEnabledB":false,"isDbsEnabledA":false,"isTpcEnabledA":false,"isTpcEnabledB":false,"busyHourPriority":0,"busyStartHour":0,"busyStartMinute":0,"busyEndHour":0,"busyEndMinute":0,"dbsMaxWidth":"80","maxRadioClientsA":200,"maxRadioClientsB":200,"maxRadioClientsC":200},
    {"rfProfileName":"HIGH","parentProfileA":"HIGH","parentProfileB":"HIGH","parentProfileC":"HIGH","isARadioType":true,"isBRadioType":true,"isCRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","cRadioChannels":"5,21,37,53,69,85,101,117,133,149,165,181,197,213,229","dataRatesA":"12,18,24,36,48,54","dataRatesB":"9,12,18,24,36,48,54","dataRatesC":"6,9,12,18,24,36,48,54","mandatoryDataRatesA":"12,24","mandatoryDataRatesB":"12","mandatoryDataRatesC":"6,12,24","isCustom":false,"minPowerLevelA":7,"maxPowerLevelA":30,"minPowerLevelB":7,"maxPowerLevelB":30,"minPowerLevelC":-10,"maxPowerLevelC":30,"powerThresholdV1A":-65,"powerThresholdV1B":-70,"powerThresholdV1C":-70,"rxSopThresholdA":"MEDIUM","rxSopThresholdB":"MEDIUM","rxSopThresholdC":"AUTO","defaultRfProfile":false,"isBrownField":false,"isAiRfProfile":false,"isFraEnabled":false,"isDcaEnabledA":false,"isDcaEnabledB":false,"isDbsEnabledA":false,"isTpcEnabledA":false,"isTpcEnabledB":false,"busyHourPriority":0,"busyStartHour":0,"busyStartMinute":0,"busyEndHour":0,"busyEndMinute":0,"dbsMaxWidth":"80","maxRadioClientsA":200,"maxRadioClientsB":200,"maxRadioClientsC":200},
    {"rfProfileName":"TYPICAL","parentProfileA":"TYPICAL","parentProfileB":"TYPICAL","parentProfileC":"TYPICAL","isARadioType":true,"isBRadioType":true,"isCRadioType":true,"channelWidth":"20","aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161","bRadioChannels":"1,6,11","cRadioChannels":"5,21,37,53,69,85,101,117,133,149,165,181,197,213,229","dataRatesA":"6,9,12,18,24,36,48,54","dataRatesB":"9,12,18,24,36,48,54","dataRatesC":"6,9,12,18,24,36,48,54","mandatoryDataRatesA":"6,12,24","mandatoryDataRatesB":"12","mandatoryDataRatesC":"6,12,24","isCustom":false,"minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":-10,"maxPowerLevelB":30,"minPowerLevelC":-10,"maxPowerLevelC":30,"powerThresholdV1A":-70,"powerThresholdV1B":-70,"powerThresholdV1C":-70,"rxSopThresholdA":"AUTO","rxSopThresholdB":"AUTO","rxSopThresholdC":"AUTO","defaultRfProfile":false,"isBrownField":false,"isAiRfProfile":false,"isFraEnabled":false,"isDcaEnabledA":false,"isDcaEnabledB":false,"isDbsEnabledA":false,"isTpcEnabledA":false,"isTpcEnabledB":false,"busyHourPriority":0,"busyStartHour":0,"busyStartMinute":0,"busyEndHour":0,"busyEndMinute":0,"dbsMaxWidth":"80","maxRadioClientsA":200,"maxRadioClientsB":200,"maxRadioClientsC":200},
    {"rfProfileName":"5GHZ_RF_PROFILE","parentProfileA":"CUSTOM","parentProfileB":"","parentProfileC":"CUSTOM","isARadioType":true,"isBRadioType":false,"isCRadioType":false,"channelWidth":"40","aRadioChannels":"36,40,44,48,52,56,60,64,100,104,108,112,116,120,124,128,132,136,140,144,149,153,157,161,169,173","bRadioChannels":"","cRadioChannels":"5,21,37,53,69,85,101,117,133,149,165,181,197,213,229","dataRatesA":"6,9,12,18,24,36,48,54","dataRatesB":"","dataRatesC":"6,9,12,18,24,36,48,54","mandatoryDataRatesA":"12,24","mandatoryDataRatesB":"","mandatoryDataRatesC":"6,12,24","isCustom":true,"minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":0,"maxPowerLevelB":0,"minPowerLevelC":-10,"maxPowerLevelC":30,"powerThresholdV1A":-60,"powerThresholdV1B":0,"powerThresholdV1C":-70,"rxSopThresholdA":"LOW","rxSopThresholdB":"","rxSopThresholdC":"AUTO","defaultRfProfile":false,"isBrownField":false,"isAiRfProfile":false,"isFraEnabled":false,"isDcaEnabledA":false,"isDcaEnabledB":false,"isDbsEnabledA":false,"isTpcEnabledA":false,"isTpcEnabledB":false,"busyHourPriority":0,"busyStartHour":0,"busyStartMinute":0,"busyEndHour":0,"busyEndMinute":0,"dbsMaxWidth":"80","maxRadioClientsA":200,"maxRadioClientsB":200,"maxRadioClientsC":200},
    {"rfProfileName":"RRM_RF_PROFILE","parentProfileA":"CUSTOM","parentProfileB":"CUSTOM","parentProfileC":"CUSTOM","isARadioType":true,"isBRadioType":true,"isCRadioType":true,"channelWidth":"best","aRadioChannels":"36,40,44,48,52,56,60,64,100,104,108,112,116,120,124,128,132,136,140,144,149,153,157,161","bRadioChannels":"1,6,11","cRadioChannels":"1,5,9,13,17,21,25,29,33,37,41,45,49,53,57,61,65,69,73,77,81,85,89,93,97,101,105,109,113,117,121,125,129,133,137,141,145,149,153,157,161,165,169,173,177,181,185,189,193,197,201,205,209,213,217,221,225,229,233","dataRatesA":"6,9,12,18,24,36,48,54","dataRatesB":"6,9,12,18,24,36,48,54","dataRatesC":"6,9,12,18,24,36,48,54","mandatoryDataRatesA":"12,24","mandatoryDataRatesB":"6","mandatoryDataRatesC":"6,12,24","isCustom":true,"minPowerLevelA":-10,"maxPowerLevelA":30,"minPowerLevelB":-10,"maxPowerLevelB":30,"minPowerLevelC":-10,"maxPowerLevelC":30,"powerThresholdV1A":-60,"powerThresholdV1B":-70,"powerThresholdV1C":-70,"rxSopThresholdA":"LOW","rxSopThresholdB":"HIGH","rxSopThresholdC":"AUTO","defaultRfProfile":false,"isBrownField":false,"isAiRfProfile":true,"isFraEnabled":true,"isDcaEnabledA":true,"isDcaEnabledB":true,"isDbsEnabledA":true,"isDbsEnabledC": true,"isDcaEnabledC": true,"isTpcEnabledA":true,"isTpcEnabledB":true,"busyHourPriority":2,"busyStartHour":8,"busyStartMinute":0,"busyEndHour":17,"busyEndMinute":0,"dbsMaxWidth":"20","dbsMaxWidthC": "20","dbsMinWidthC": "20","maxRadioClientsA":200,"maxRadioClientsB":200,"maxRadioClientsC":200, "zerowaitDfsEnable": false}],   

    "APPLICATION_POLICY_DEVICE":["WAN_EDGE4","WAN_EDGE5","WAN_EDGE6"],

    //Fabric Roles of devices, if needed to be present, durint init
    "DEFAULT_FABRIC_DEVICE_LIST": [ 
      //{ "siteName":"Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric", "deviceList" : [/* {"deviceName":"WAN_EDGE2","roles": ["TRANSIT_MAPSERVER","BORDERNODE"]}, */ {"deviceName":"BLD1-FLR1-DST1","roles": ["TRANSIT_MAPSERVER","BORDERNODE"],"isPubSub":false},
      //  /* {"deviceName":"BLD2-FLR2-ACCESS","roles": ["EDGENODE"]},{"deviceName":"BLD1-FLR2-ACCESS","roles": ["EDGENODE"]}, */{"deviceName":"WLC-FABRIC-01","roles": ["ENDPOINT_PROXY"]},{"deviceName":"C9404R-border_cp_sda.local","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC01_Sub"},{"deviceName":"CAMPUS-CORE2","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC01_Sub"}]},
      { "siteName":"Global/North_America/USA/California/San_Jose/SJC23_SanJose_Fabric", "deviceList" : [{"deviceName":"BORDER1-SJC23.cisco.local","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC23_Sub"}]},
      { "siteName":"Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric", "deviceList" : [{"deviceName":"SJC06-vWLC-9800","roles": ["ENDPOINT_PROXY"]}, {"deviceName":"SJC06-WLC-ISSU","roles": ["ENDPOINT_PROXY"]},{"deviceName":"SJC06-C9300-01","roles": ["EDGENODE"]},{"deviceName":"SJC06-C9300-02","roles": ["EDGENODE"]},{"deviceName":"SJC06-C9300-03","roles": ["EDGENODE"]}, {"deviceName":"SJC06-C9300-05","roles": ["TRANSIT_MAPSERVER","BORDERNODE"]},
          {"deviceName":"SJC06-C9600-01","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC06_Sub"},{"deviceName":"SJC06-C9600-02","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC06_Sub"}, {"deviceName":"IE4K-ExtNode","roles": ["SUBTENDED_NODE"]}, {"deviceName":"IE4K-POE_ExtNode","roles": ["SUBTENDED_NODE"]}, {"deviceName":"IE3400_1","roles": ["SUBTENDED_NODE"]}, {"deviceName":"IE3400_2","roles": ["SUBTENDED_NODE"]}, {"deviceName":"IE3400_3","roles": ["SUBTENDED_NODE"]},{"deviceName":"IE-3300_6","roles": ["SUBTENDED_NODE"]}, {"deviceName":"SJC06-C9300-04","roles": ["SUBTENDED_NODE"]} ]},//SJC06-C9300-04 as extended node is part of C9300 as PEN usecase
      { "siteName":"Global/North_America/USA/California/San_Jose/SJC22_SanJose_Fabric", "deviceList" : [{"deviceName":"INT-BOR.cisco.com","roles": ["BORDERNODE"],"ipPoolName": "Border_Pool_SJC23_Sub"}, {"deviceName":"CONTROL-PLANE.cisco.com","roles":["MAPSERVER"],"ipPoolName": "Border_Pool_SJC23_Sub"}, {"deviceName":"FE1-9300-03.cisco.com","roles": ["EDGENODE"]}, {"deviceName":"FE2-9300-04.cisco.com","roles": ["EDGENODE"]}, {"deviceName":"WLC-3504","roles": ["ENDPOINT_PROXY"]} ]},
      { "siteName":"Global/North_America/USA/California/San_Francisco/SFO11_SanJose_Fabric", "deviceList":[]},
      { "siteName":"Global/North_America/USA/California/San_Francisco/SFO15_SanJose_Fabric", "deviceList":[{"deviceName":"SFO15-C9500-Core-01","roles": ["MAPSERVER","BORDERNODE","ENDPOINT_PROXY"],"ipPoolName": "Border_Pool_SFO15_Sub"},{"deviceName":"SFO15-C9500-Core-02","roles": ["MAPSERVER","BORDERNODE","ENDPOINT_PROXY"],"ipPoolName": "Border_Pool_SFO15_Sub"},{"deviceName":"SFO15-C9300-01","roles": ["EDGENODE"]},{"deviceName":"SFO15-C9300-02","roles": ["EDGENODE"]},{"deviceName":"SFO15-C9300-03","roles": ["EDGENODE"]},{"deviceName":"SFO15-C9200-01","roles": ["EDGENODE"]},{"deviceName":"SFO15-C9200-02","roles": ["EDGENODE"]}]},
      { "siteName":"Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1", "deviceList" : [
          {"deviceName":"BLD2-FLR2-ACCESS","roles": ["EDGENODE"]},{"deviceName":"BLD1-FLR2-ACCESS","roles": ["EDGENODE"]},
          {"deviceName":"BLD1-FLR1-DST1","roles": ["TRANSIT_MAPSERVER","BORDERNODE"],"isPubSub":false},
          {"deviceName":"WLC-FABRIC-01","roles": ["ENDPOINT_PROXY"]},{"deviceName":"C9404R-border_cp_sda.local","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC01_Sub"},{"deviceName":"CAMPUS-CORE2","roles": ["MAPSERVER","BORDERNODE"],"ipPoolName": "Border_Pool_SJC01_Sub"}
        ],
          "parentFabric":"Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric" } ],
    "Transit_Domain":[{  "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"Transit_IP","domainType":"TRANSIT","transitType":"IP","transitMetaData":[{"name":"BGP","value":"1"}],"deviceInfo":[],"roles": []},
                        //{ "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"Transit_IP_SJC","domainType":"TRANSIT","transitType":"IP","transitMetaData":[{"name":"BGP","value":"3000"}],"deviceInfo":["WAN_EDGE2"],"roles": []},
                        //{ "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"Transit_SDA","domainType":"TRANSIT","transitType":"SDA","transitMetaData":[],"deviceInfo":["BLD1-FLR1-DST1"],"roles": ["TRANSIT_MAPSERVER","BORDERNODE"]}, //Transit PubSub/List changes
                        {  "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"SJC22 Transit IP","domainType":"TRANSIT","transitType":"IP","transitMetaData":[{"name":"BGP","value":"65000"}],"deviceInfo":[],"roles": []},
                        { "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"SJC01-SDA-PubSub-Transit","domainType":"TRANSIT","transitType":"SDA","transitMetaData":[],"deviceInfo":["BLD1-FLR1-DST1"],"roles": ["TRANSIT_MAPSERVER","BORDERNODE"],"isPubSub":true}, //Transit PubSub/List changes
                        { "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"SJC06-SDA-BGP-Transit","domainType":"TRANSIT","transitType":"SDA","transitMetaData":[],"deviceInfo":["SJC06-C9300-05"],"roles": ["TRANSIT_MAPSERVER","BORDERNODE"],"isPubSub":false}, //Transit PubSub/List changes
                        { "type": "ConnectivityDomain","description": "","enableMonitoring": true,"name":"SDWAN","domainType":"TRANSIT","transitType":"SDWAN","transitMetaData":[{"name": "vManage IP Host Name","value": "172.29.58.90"}],"deviceInfo":[],"roles": [],"isPubSub":false}
                      ],
    //"FabricZone":[{"domainType":"FABRIC_ZONE", "name":"Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1", 
      //"isDefault": false, "site":"Flr-SJC1-1", "deviceList":[{"deviceName":"BLD2-FLR2-ACCESS","roles": ["EDGENODE"]},{"deviceName":"BLD1-FLR2-ACCESS","roles": ["EDGENODE"]}], "parentFabric":"Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric"}],

    "Default_Segment": [  { "ipPoolName": "Corporate_Pool_SJC01_Sub", "VNname":"DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric","segment":{"type": "Segment","name": "172_16_101_0-DEFAULT_VN","trafficType": "VOICE_VIDEO","ipPoolId": "50511063-d3d9-47f4-704e-fe0c1b60b1e4","isCriticalPool": false,"isFloodAndLearn": true,"isApProvisioning": false,"isDefaultEnterprise": false,"scalableGroupId": "","isExtendedProvisioning": false,"connectivityDomain": {"idRef": ""}}},
                          { "ipPoolName": "AP_Pool_SJC01_Sub", "VNname":"INFRA_VN-Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric","segment":  {"type": "Segment","name": "192_168_19_0-INFRA_VN","trafficType": "DATA","ipPoolId": "bae8a0f8-7bf9-5ae3-2ea4-39d8ccc6cf1c","isCriticalPool": false,  "isFloodAndLearn": true,"isApProvisioning": true,"isDefaultEnterprise": false,"scalableGroupId": "","isExtendedProvisioning": false,"connectivityDomain": {"idRef": ""}}}
                       ],

    "Default_Interface" :{"name": "172_16_101_0-INFRA_VN","deviceName":"p1.edge1-sda1.local"},
    "Default_Access_Point" :[{"name":'SJC01_9136_1',"position": {"x": 70.97132,"y": 51.378315,"z": 4}},{"name":"AP0081.C424.3CE2","position": {"x":19.687876,"y": 80.37615,"z": 4}} ,{"name":'LAB-AP00F2.8B27.B788',"position": {"x": 19.32773,"y": 21.976791,"z": 4}},{"name":"SJC01_9136_12","position": {"x":170.687876,"y": 80.37615,"z": 4}} ,{"name":"AP9120_2","position": {"x":200.687876,"y": 80.37615,"z": 4}},{"name":"AP9120_3","position": {"x":230.687876,"y": 80.37615,"z": 4}} ,{"name":"AP9115","position": {"x":260.687876,"y": 80.37615,"z": 4}} /*,{"name":'SFO15-AP4800-01',"position": {"x": 30.97132,"y": 10.378315,"z": 4}}, {"name":'SFO15-AP4800-02',"position": {"x": 30.97132,"y": 51.378315,"z": 4}}*/, {"name":'SFO15-C9124-03',"position": {"x": 90.97132,"y": 10.378315,"z": 4}}, {"name":'',"position": {"x": 90.97132,"y": 51.378315,"z": 4}}, {"name":'SFO15-C9120-04',"position": {"x": 150.97132,"y": 71.378315,"z": 4}}, {"name":'SFO10-AP3800-01',"position": {"x": 80.97132,"y": 10.378315,"z": 4}}, {"name":'SFO10-AP3800-02',"position": {"x": 10.97132,"y": 90.378315,"z": 4}}, {"name":'SFO11-AP4800-01',"position": {"x": 90.97132,"y": 30.378315,"z": 4}}, {"name":'SFO11-AP4800-02',"position": {"x": 30.97132,"y": 90.378315,"z": 4}}] //changed by Ram
                            .concat(new Array(9).fill(1).map(function(v,i){return {"name":'AP4800_'+(i+1),"position": {"x": i*30+43,"y": 21.976791,"z": 4}}}))  //changed by Ram
                            .concat(new Array(9).fill(1).map(function(v,i){return {"name":'AP4800_'+(i+10),"position": {"x":i*30+43,"y": 80.37615,"z": 4}}})),  //changed by Ram

    "Default_Sensor" :[{"name":'AP1800S_Sensor_01',"position": {"x": 150.238434,"y": 51.378315,"z": 4.0}},{"name":'AP1800S_Sensor_02',"position": {"x": 90.238434,"y": 35.378315,"z": 34.0}},
    {"name":'AP1800S_Sensor_03',"position": {"x": 270.238434,"y": 70.378315,"z": 88.0}},{"name":'AP1800S_Sensor_04',"position": {"x": 96.238434,"y": 89.378315,"z": 23.0}}],
    //sub-pools in location
    "Default_Sub_Pool" : [{"site":"SJC01",poolList:["Campus_Pool_SJC01","Corporate_Pool_SJC01","Underlay_Pool_SJC01","Extended_Pool_SJC01","Border_Pool_SJC01","Lan_Pool_SJC01","Infra_Pool_SJC01","AP_Pool_SJC01","NFV_Lan_Pool_SJC01","NFV_Service_Pool_SJC01","NFV_Man_Pool_SJC01","NFV_Wan_Pool_SJC01","RP_Pool_SJC01"]},
                          {"site":"SJC05",poolList:["Campus_Pool_SJC05","Corporate_Pool_SJC05","Underlay_Pool_SJC05","Extended_Pool_SJC05","Border_Pool_SJC05","Lan_Pool_SJC05","Infra_Pool_SJC05","AP_Pool_SJC05","Multicast_Pool_SJC05"]},
                          {"site":"SJC06",poolList:["Campus_Pool_SJC06","Corporate_Pool_SJC06","Underlay_Pool_SJC06","Extended_Pool_SJC06","Border_Pool_SJC06","Lan_Pool_SJC06","Infra_Pool_SJC06","AP_Pool_SJC06","InsideThings_Pool_SJC06","OutsideThings_Pool_SJC06"]},
                          {"site":"SJC22",poolList:["Border_Pool_SJC22"]},
                          {"site":"SJC23",poolList:["Border_Pool_SJC23"]},
                          {"site":"SFO11",poolList:["Border_Pool_SFO11"]},
                          {"site":"SFO12",poolList:["Border_Pool_SFO12"]},
                          {"site":"SFO15",poolList:["Border_Pool_SFO15"]},
                          {"site":"SJC04",poolList:["Border_Pool_SJC04","NFV_Lan_Pool_SJC04","NFV_Service_Pool_SJC04","NFV_Man_Pool_SJC04","NFV_Wan_Pool_SJC04"]}
                        ],
    "Default_Exetended_Node":{"type":"Cisco IE-4000-4GS8GP4G-E Industrial Ethernet Switch","macAddress": "28:ac:9e:78:e8:00","serialnumber": "FDO2205U0AJ","series":"Cisco IE 4000 Series Switches","platformid":"IE-4000-4GS8GP4G-E"},

    //Device to Site mapping info
    "Site_Config" : [{"site":"SJC01",deviceList:[/* "WAN_EDGE1","WAN_EDGE2", *//* "C9404R-border_cp_sda.local", *//* "CAMPUS-CORE2", *//* "BLD1-FLR1-DST1", *//* "BLD1-FLR2-DST1","BLD2-FLR1-DST1","BLD2-FLR2-DST1", *//* "p1.edge1-sda1.local", */ /* "BLD1-FLR2-ACCESS", */ /* "BLD2-FLR1-ACCESS", */ /* "BLD2-FLR2-ACCESS", *//* "WLC-9800", *//* "WLC-FABRIC-01",*/ /* "SJC01-Stack-01", "SJC01-C9300-01", "SJC01-C9300-02" */]},
                     {"site":"SJC06",deviceList:['SJC06-C9300-01','SJC06-C9300-02','SJC06-C9300-03','SJC06-C9600-01','SJC06-C9600-02','SJC06-ISR4461-01',"SJC06-ISR4461-02","SJC06-vWLC-9800","SJC06-WLC-ISSU",'SJC06-C9300-05','SJC06-C9300-04','IE4K-ExtNode','IE4K-POE_ExtNode','IE-3300_6','IE3400_1','IE3400_2', 'IE3400_3']},
                     {"site":"SJC05",deviceList:["WAN_EDGE3","CAMPUS-CORE3","BLD3-FLR1-DST1","BLD3-FLR2-DST1",'BLD3-FLR1-ACCESS','BLD3-FLR2-ACCESS', "Access_1", "Access_2","Access_3","Campus_WLC3"]},
                     {"site":"SJC23",deviceList:["FUSION1-SJC23.cisco.local","BORDER1-SJC23.cisco.local","EDGE01-SJC23.cisco.local"]},
                     {"site":"NY1",deviceList:['ECA00EB.D510.3F18', 'ECA0081.C424.3CE2','ECA00F2.8B27.B788']},
                     {"site":"SE1",deviceList:['Campus_WLC4','Campus_WLC5']},
                     {"site":"SFO10",deviceList:["SFO10-AP3800-01","SFO10-AP3800-02"]},
                     {"site":"SFO12",deviceList:[]},
                     {"site":"SFO11",deviceList:["FUSION1-SFO11.cisco.local", "SFO11-C9300-Edge-01","SFO11-C9300-Edge-01", "SFO11-AP4800-01","SFO11-AP4800-02"]},
                     {"site":"SFO15",deviceList:["SFO15-ISR4461-01","SFO15-ISR4461-02","SFO15-C9500-Core-01","SFO15-C9500-Core-02","SFO15-C9300-01","SFO15-C9300-02","SFO15-C9300-03","SFO15-C9200-01","SFO15-C9200-02",/*"SFO15-AP4800-01","SFO15-AP4800-02",*/"SFO15-C9124-03","SFO15-C9136-04","SFO15-C9120-04"]},
                     {"site":"SFO13",deviceList:["SFO13-A3850-1","SFO13-D9300-1","SFO13-D9300-2"]},
                     {"site":"SA01",deviceList:["IR1101-ISR-Rugged"]},
                     {"site":"PLS06",deviceList:["PLS06-C9300-01", "PLS06-WLC-9800"]},
                     {"site":"SJC22",deviceList:["FE1-9300-03.cisco.com","FE2-9300-04.cisco.com","pb-fusion-router","INT-BOR.cisco.com","CONTROL-PLANE.cisco.com","WLC-3504"]},
                     {"site":"SJC04",deviceList:["nfvis-1","nfvis-2"]},
                     {"site":"Flr-SJC1-1",deviceList:["p1.edge1-sda1.local","BLD2-FLR1-ACCESS","BLD1-FLR2-ACCESS","BLD2-FLR2-ACCESS", "BLD1-FLR1-DST1", "WLC-FABRIC-01", "C9404R-border_cp_sda.local", "CAMPUS-CORE2", "WAN_EDGE1","WAN_EDGE2","BLD1-FLR2-DST1","BLD2-FLR1-DST1","BLD2-FLR2-DST1", "WLC-9800","SJC01-Stack-01", "SJC01-C9300-01", "SJC01-C9300-02"]}
                    ],
                    
    "AP_Site_Config" : [{"site":"Flr-SFO15-1",deviceList:[/*'SFO15-AP4800-01','SFO15-AP4800-02',"SFO15-C9105-01",*/
    "SFO15-C9120-01","SFO15-C9120-02","SFO15-C9120-03","SFO15-C9120-04",
    "SFO15-C9124-01","SFO15-C9124-02",'SFO15-C9124-03',"SFO15-C9136-01","SFO15-C9136-02","SFO15-C9136-03", "SFO15-C9136-04"/*,"SFO15-C9136-05"*/]},
                      // {"site":"Flr-SJC6-1",deviceList:["SJC06-AP4800-01","SJC06-AP4800-02","SJC06-AP4800-03"]},
                      {"site":"Flr-SFO10-1",deviceList:["SFO10-AP3800-01","SFO10-AP3800-02"]},
                      {"site":"Flr-SFO11-1",deviceList:["SFO11-AP4800-01","SFO11-AP4800-02"]},
                      {"site":"Flr-PLS06-1",deviceList:["PLS06-AP3800-01","PLS06-9120-01"]},
                      {"site":"Flr-SJC22-1",deviceList:["AP70B3.1733.D6D4","APF4DB.E6CE.4F24"]}
                      //other APs will be mapped to SJC01 floor
    ],

     "Wlc_Provision_list" :['WLC-9800','WLC-FABRIC-01',"SJC06-WLC-ISSU","SJC06-vWLC-9800", "WLC-3504"],
    
     //"AP_Down_List" :["AP0081.C424.3CE2",'AP4800_1', 'AP4800'],
     // Removed AP4800 from AP down list as per Sonya request need to show AP4800 up
     "AP_Down_List" :["AP0081.C424.3CE2",'AP4800_1',"PLS06-9120-01"],

     //Device Down (Other than AP) - For Mark for Replacement Usecase
     "Devices_Down" : ["PLS06-C9300-01"],

     "Ignore_Device_In_Topology":["SFO11-AP4800-01","SFO11-AP4800-02"],

     //Fabric Topology details
     "Topology_Config" :[
                          /*{"source":['SFO15-C9300-01'],"target":['SFO15-AP4800-01']},
                          {"source":['SFO15-C9300-02'],"target":['SFO15-AP4800-02']},*/
                          {"source":['SFO15-C9300-03'],"target":['SFO15-C9124-03']},
                          {"source":['SFO15-C9200-01'],"target":['']},
                          {"source":['SFO15-C9200-02'],"target":['SFO15-C9120-04']},
                          {"source":['FUSION1-SFO11.cisco.local'],"target":['SFO11-C9300-Edge-01']},
                          {"source":['FUSION1-SJC23.cisco.local'],"target":['BORDER1-SJC23.cisco.local']},
                          {"source":['BORDER1-SJC23.cisco.local'],"target":['EDGE01-SJC23.cisco.local']},
                          {"source":['SJC06-ISR4461-01'],"target":['SJC06-C9600-01']},
                          {"source":['SJC06-ISR4461-02'],"target":['SJC06-C9600-02']},
                          {"source":['SJC06-ISR4461-01'],"target":['SJC06-C9600-02']},
                          {"source":['SJC06-ISR4461-02'],"target":['SJC06-C9600-01']},
                          {"source":['SJC06-C9600-01'],"target":['SJC06-C9300-01']},
                          {"source":['SJC06-C9600-01'],"target":['SJC06-C9300-02']},
                          {"source":['SJC06-C9600-01'],"target":['SJC06-C9300-03']},
                          {"source":['SJC06-C9600-01'],"target":['SJC06-WLC-ISSU']},
                          {"source":['SJC06-C9600-01'],"target":['SJC06-C9300-05']},
                          {"source":['SJC06-C9600-02'],"target":['SJC06-C9300-01']},
                          {"source":['SJC06-C9600-02'],"target":['SJC06-C9300-02']},
                          {"source":['SJC06-C9600-02'],"target":['SJC06-C9300-03']},
                          {"source":['SJC06-C9600-02'],"target":['SJC06-C9300-05']},
                          {"source":['SJC06-C9600-02'],"target":['SJC06-vWLC-9800']},
                          {"source":['SJC06-C9300-01'],"target":['IE4K-ExtNode']},
                          {"source":['SJC06-C9300-01'],"target":['IE4K-POE_ExtNode']},
                          {"source":['IE4K-ExtNode'],"target":['IE-3300_6']},
                          {"source":['SJC06-C9300-02'],"target":['IE3400_1']},
                          {"source":['IE3400_1'],"target":['IE3400_2']},
                          {"source":['IE3400_2'],"target":['IE3400_3']},                 
                          {"source":['SJC06-C9300-03'],"target":['SJC06-C9300-04']},
                         /* {"source":['SJC06-C9300-01'],"target":['SJC06-AP4800-01']},
                          {"source":['SJC06-C9300-02'],"target":['SJC06-AP4800-02']},
                          {"source":['SJC06-C9300-03'],"target":['SJC06-AP4800-03']} */
                          {"source":['INT-BOR.cisco.com'],"target":['pb-fusion-router']},
                          {"source":['INT-BOR.cisco.com'],"target":['CONTROL-PLANE.cisco.com']},
                          {"source":['INT-BOR.cisco.com'],"target":['FE1-9300-03.cisco.com']},
                          {"source":['INT-BOR.cisco.com'],"target":['FE2-9300-04.cisco.com']},
                          {"source":['CONTROL-PLANE.cisco.com'],"target":['FE1-9300-03.cisco.com']},
                          {"source":['CONTROL-PLANE.cisco.com'],"target":['FE2-9300-04.cisco.com']},

                          {"source":['SFO13-A3850-1'],"target":['SFO13-D9300-1']},
                          {"source":['SFO13-A3850-1'],"target":['SFO13-D9300-2']},
                          {"source":['SFO13-D9300-1'],"target":['SFO13-D9300-2'],
                            "linkInfo":[
                              {"start": "GigabitEthernet1/0/13","end": "GigabitEthernet1/0/13","status":"up"},
                              {"start": "GigabitEthernet1/0/1","end": "GigabitEthernet1/0/1","status":"down"}
                            ]},
                          /* {"source":['WLC-5520'],"target":['PLS06-C9300-01']},
                          {"source":['PLS06-C9300-01'],"target":['PLS06-AP3800-01']} */ //not sure if it is needed and correct
                          ],
                          
      //PnP devices to load on start based on usecases2
      "PNP_DEVICE_DETAILS" : {
        "SF12Demo": {
          //Usecase: Claim the 10 devices on SFO12, followed by provisioning and adding to fabric (auto after all devices), from ACME portal
          "network": {
            "Switch": [
              {
                preClaimDeviceName: ['JPG2227023C','JPG2227023D','JPG2227023E'],
                hostnames: ["SFO12-C9200-01", "SFO12-C9200-02", "SFO12-C9200-03"],
                deviceInfo: {type:"Cisco Catalyst 9200 Switch Stack",series:"Cisco Catalyst 9200 Series Switches",macAddr:"ab:01:6e:ef:bc:3b", platformid:"C9200",swversion:"17.9.1",family:"Switches and Hubs",serialno:"JPG2227023C",ipAddress:"10.10.10.10", role: "ACCESS"},
                isNamingConventioneFollowed: true
              },
              {
                preClaimDeviceName: ['JPG2227026A'],
                hostnames: ["SFO12-C9300-01"],
                deviceInfo: {type:"Cisco Catalyst 9300 Switch",series:"Cisco Catalyst 9300 Series Switches",macAddr:"bc:12:6e:ef:bc:60", platformid:"c9300-24P",swversion:"17.9.1",family:"Switches and Hubs",serialno:"JPG2227026A",ipAddress:"10.10.10.20", role: "ACCESS"},
                isNamingConventioneFollowed: true
              },
              {
                preClaimDeviceName: ['FXS2131Q5ZA','FXS2131Q5ZB'],
                hostnames: ["SFO12-C9500-01","SFO12-C9500-02"],
                deviceInfo: {type:"Cisco Catalyst 9500 Switch",series:"Cisco Catalyst 9500 Series Switches",macAddr:"de:34:6e:ef:bc:60", platformid:"C9500-16X",swversion:"17.9.1",family:"Switches and Hubs",serialno:"FXS2131Q5ZA",ipAddress:"10.10.10.40", role: "CORE"},
                isNamingConventioneFollowed: true
              },
              {
                preClaimDeviceName: ['FDO2205U1BJ'],
                hostnames: ["SFO12-IE3300-01"],
                deviceInfo: {type:"Cisco Catalyst IE-3300-8P2S Rugged Switch",series:"Cisco Catalyst IE3300 Rugged Series",macAddr:"ef:45:9e:78:e8:10", platformid:"IE-3300-8P2S",swversion:"17.9.1",family:"Switches and Hubs",serialno:"FDO2205U1BJ",ipAddress:"10.10.10.50", role: "ACCESS"},
                isNamingConventioneFollowed: true
              }
            ],
            "WLC": [
              {
                preClaimDeviceName: ['FCH2034V5G2'],
                hostnames: ["SFO12-C9800-01"],
                deviceInfo: {type:"Cisco Catalyst 9800-40 Wireless Controller",series:"Cisco Catalyst 9800 Series Wireless Controllers",macAddr:"cd:23:63:69:11:9b", platformid:"C9800-40-K9",swversion:"17.8.1",family:"Wireless Controller",serialno:"FCH2034V5G2",ipAddress:"10.10.10.30", role: "ACCESS"},
                isNamingConventioneFollowed: true
              }
            ],
            "Router" : [
              {
                preClaimDeviceName: ['FJC2027D162','FJC2027D163'],
                hostnames: ["SFO12-ISR4461-01","SFO12-ISR4461-02"],
                deviceInfo: {type: "Cisco 4461 Series Integrated Services Router",series: "Cisco 4400 Series Integrated Services Routers",macAddr:"01:56:6e:ef:bc:60", platformid: "ISR4461-X/K9",swversion: "17.9.1",family: "Routers",serialno: "FJC2027D162",ipAddress:"10.10.10.60", role: "BORDER ROUTER"},
                isNamingConventioneFollowed: true
              }
            ]
          }
        },
        "1800SensorDemo": {
          "network": {
            "Sensor": [
              //Usecase - Device claim on SJC01, followed by Image upgrade
              {
                //preClaimDeviceName: ['AP1800S_Sensor_02'],
                hostnames: ["AP1800S_Sensor_10"],
                deviceInfo: {type: "Cisco Aironet 1800S Active Sensor",series: "Cisco Aironet 1800S Active Sensor",macAddr:"12:67:5a:78:6b:02", platformid: "AIR-AP1800S-B-K9",swversion: "1.3.3.0",family: "Wireless Sensor",serialno: "KWC212100QQ",ipAddress:"10.10.10.70", role: "ACCESS"},
                isNamingConventioneFollowed: false
              }
            ]
          }
        },
        "StackedPnP": {
          "network": {
            "StackedSwitch": [
              //Usecase: Stacked device claim on SJC04
              {
                hostnames: ["FCW2152L07D"],
                deviceInfo: {type:"Cisco Catalyst 9300 Switch",series:"Cisco Catalyst 9300 Series Switches",macAddr:"50:1c:b0:66:ea:00", platformid:"C9300-24UX",swversion:"17.9.1",family:"Switches and Hubs",serialno:"FCW2152L07D",ipAddress:"10.10.10.80", role: "ACCESS"},
                isNamingConventioneFollowed: false,
                stackedDtls: [[{serialNo: 'FCW2152L07D',role: 'Active', priority: 10, macAddr: '501c.b066.ea00'},
                            {serialNo: 'FCW2152L07T',role: 'Standby', priority: 10, macAddr: '501c.b079.d000'},
                            {serialNo: 'FCW2210L01G',role: 'Member', priority: 1, macAddr: '682c.7b22.ea80'}]]
              }
            ]
          }
        },
        "MobilityExpress": {
          "network": {
            "Switch": [
              {
                hostnames: ["SFO10-C9200-01"],
                deviceInfo: {type:"Cisco Catalyst 9200 Switch Stack",series:"Cisco Catalyst 9200 Series Switches",macAddr:"23:78:6e:ef:bc:4b", platformid:"C9200",swversion:"17.9.1",family:"Switches and Hubs",serialno:"JPG2227014C",ipAddress:"10.10.10.90", role: "ACCESS"},
                isNamingConventioneFollowed: true
              }
            ],
            "Router" : [
              {
                hostnames: ["SFO10-ISR4321-01"],
                deviceInfo:
                {type: "Cisco 4321 Integrated Services Router",series: "Cisco 4000 Series Integrated Services Routers",macAddr:"34:89:6e:ef:bc:60", platformid: "ISR4321-SEC/K9",swversion: "17.6.1",family: "Routers",serialno: "FJC2027DO66",ipAddress:"10.10.10.100", role: "BORDER ROUTER"},
                isNamingConventioneFollowed: true
              }
            ]
          }
        },
        "DevReplacement": {
          "network": {
            "Switch": [
              {
                hostnames: ["PLS06-C9300-10"],
                deviceInfo: {type:"Cisco Catalyst 9300 Switch",series:"Cisco Catalyst 9300 Series Switches",macAddr:"45:9a:6e:fe:bc:90", platformid:"c9300-24P",swversion:"17.9.1",family:"Switches and Hubs",serialno:"JPG2337026A",ipAddress:"10.10.10.110", role: "ACCESS"},
                isNamingConventioneFollowed: true
              }
            ],
            "AP": [
              {
                hostnames: ["PLS06-9120-10"],
                deviceInfo: {type:"Cisco Catalyst 9120AXP Unified Access Point",series:"Cisco Catalyst 9120 Series Access Points",macAddr:"12:12:6e:fe:bc:90",platformid:"C9120AXP-B",swversion:"17.9.1",family:"Unified AP",serialno:"FKB2030FBFZ",ipAddress:"20.20.10.110", role: "ACCESS"},
                isNamingConventioneFollowed: true
              }
            ]
          }
        },
        "DEMO_9800": {
          "network": {
            "WLC": [
              {
                hostnames: ["C9800-PnP"],
                deviceInfo: {type:"Cisco Catalyst 9800-L-F Wireless Controller",series:"Cisco Catalyst 9800 Series Wireless Controllers",macAddr:"f4:bd:9e:57:c7:cb", platformid:"C9800-L-F-K9",swversion:"17.9.1",family:"Wireless Controller",serialno:"FCL241200E5",ipAddress:"10.4.170.30", role: "ACCESS"},
                isNamingConventioneFollowed: false
              }
            ]
          }
        }
      },

      //used in PnP - add PnP, picking image based on mdfId
      "MDF_DEVICE_TYPE_MAPPING" : [
        {mdfId:"286320865", type:"Cisco Catalyst 9200 Switch Stack", series:"Cisco Catalyst 9200 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9200-24T","C9200-24PXG","C9200-24P","C9200-48T",
          "C9200-48PXG","C9200-48P"]},
        {mdfId:"286006221", type:"Cisco 4321 Integrated Services Router", series:"Cisco 4000 Series Integrated Services Routers",
          swversion: "17.6.1", family: "Routers", pids:["ISR4321-SEC/K9","ISR4321/K9","ISR4321-V/K9","ISR4321-AXV/K9",
          "SI-ISR4321-IWAN/K9","ISR4321-AX/K9","ISR4321-VSEC/K9"]},
        {mdfId:"284397235", type:"Cisco 5760 Wireless LAN Controller", series:"Cisco 5760 Series Wireless LAN Controller",
          swversion: "3.7.5E", family: "Wireless Controller", pids:["AIR-CT5760-00-K9","AIR-CT5760-10-K9","AIR-CT5760-25-K9",
          "AIR-CT5760-50-K9","AIR-CT5760-100-K9","AIR-CT5760-RK-MNT","AIR-CT5760-1K-K9","AIR-CT5760-250-K9","AIR-CT5760-500-K9",
          "AIR-CT5760-K9","AIR-CT5760-HA-K9","AIR-CT5760"]},
        {mdfId:"286288455", type:"Cisco Aironet 1850", series:"Cisco Mobility Express", swversion: "8.10.181.0",
          family: "Wireless Controller", pids:["AIR-AP1852E-D-K9","AIR-AP1852I-K-K9","AIR-AP1852E-B-K9","AIR-AP1852E-F-K9",
          "AIR-AP1852I-I-K9","AIR-AP1852E-H-K9","AIR-AP1852I-Z-K9","AIR-AP1852I-Q-K9","AIR-AP1852I-S-K9","AIR-AP1852E-N-K9",
          "AIR-AP1852E-UXK9","AIR-AP1852I-A-K9","AIR-AP1852I-E-K9","AIR-AP1852E-T-K9","AIR-AP1852I-C-K9","AIR-AP1852E-R-K9",
          "AIR-AP1852E-Z-K9","AIR-AP1852E-E-K9","AIR-AP1852E-C-K9","AIR-AP1852I-F-K9","AIR-AP1852E-I-K9","AIR-AP1852I-H-K9",
          "AIR-AP1852I-N-K9","AIR-AP1852E-A-K9","AIR-AP1852I-R-K9","AIR-AP1852I-T-K9","AIR-AP1852E-Q-K9","AIR-AP1852I-B-K9",
          "AIR-AP1852E-K-K9","AIR-AP1852I-D-K9","AIR-AP1852I-UXK9","AIR-AP1852E-S-K9"]},
        {mdfId:"999991178", type:"Cisco Aironet 2700 Series Access Point", series:"Autonomous AP", swversion: "16.6.1",
          family: "Autonomous AP", pids:["AIR-CAP2702e-D-KAIR-AP2702i-D-K9","AIR-CAP2702e-K-K9","AIR-AP2702e-K-K9",
          "AIR-CAP2702i-A-K9","AIR-CAP2702e-Z-K9","AIR-CAP2702i-E-K9","AIR-AP2702e-C-K9","AIR-CAP2702e-S-K9","AIR-CAP2702e-C-K9",
          "AIR-CAP2702e-N-KAIR-AP2702i-N-K9","AIR-AP2702e-S-K9","AIR-CAP2702i-Q-K9","AIR-AP2702e-H-K9","AIR-AP2702i-K-K9",
          "AIR-CAP2702i-I-K9","AIR-AP2702i-S-K9","AIR-CAP2702i-D-K9","AIR-CAP2702i-H-K9","AIR-AP2702i-Z-K9","AIR-CAP2702e-R-K9",
          "AIR-AP2702i-C-K9","AIR-AP2702e-D-K9","AIR-CAP2702e-F-K9","AIR-CAP2702e-B-K9","AIR-AP2702e-I-K9","AIR-CAP2702i-N-K9",
          "AIR-AP2702i-UXK9","AIR-CAP2702i-R-K9","AIR-CAP2702i-C-K9","AIR-AP2702i-R-K9","AIR-CAP2702e-A-K9","AIR-AP2702i-F-K9",
          "AIR-AP2702e-A-K9","AIR-AP2702i-B-K9","AIR-AP2702e-E-K9","AIR-CAP2702e-H-KAIR-AP2702i-H-K9","AIR-CAP2702e-I-K9",
          "AIR-AP2702e-Q-K9","AIR-CAP2702e-E-K9","AIR-AP2702e-UXK9","AIR-AP2702e-F-K9","AIR-CAP2702i-S-K9","AIR-AP2702i-I-K9",
          "AIR-CAP2702i-K-K9","AIR-CAP2702i-B-K9","AIR-AP2702i-E-K9","AIR-CAP2702i-F-K9","AIR-CAP2702i-Z-K9","AIR-AP2702e-B-K9",
          "AIR-AP2702i-A-K9","AIR-AP2702e-Z-K9","AIR-AP2702e-N-K9","AIR-CAP2702e-Q-KAIR-AP2702i-Q-K9","AIR-AP2702e-R-K9"]},
        {mdfId:"286320207", type:"Cisco Aironet 4800", series:"Cisco Mobility Express", swversion: "8.10.181.0",
          family: "Wireless Controller", pids:["AIR-AP4800-B-K9"]},//is this right?? AP or WLC?
        {mdfId:"282993672", type:"Cisco ASR 1001 Router", series:"Cisco ASR 1000 Series Aggregation Services Routers",
          swversion: "17.2.1r", family: "Routers", pids:["ASR1001", "ASR1001="]},//it is actually 3.16.10S version
        {mdfId:"281782371", type:"Cisco ASR 1002 Router", series:"Cisco ASR 1000 Series Aggregation Services Routers",
          swversion: "17.2.1r", family: "Routers", pids:["ASR1002", "ASR1002="]},//it is actually 03.17.04.S version
        {mdfId:"284146581", type:"Cisco ASR 1002-X Router", series:"Cisco ASR 1000 Series Aggregation Services Routers",
          swversion: "17.9.1", family: "Routers", pids:["ASR1002X-5G-VPNK9","ASR1002X-5G-K9-WS","ASR1002X-SB","ASR1002X-CB-RF",
          "ASR1002X-10G-HA-K9","ASR1002X-20G-SECK9","ASR1002X-10G-K9-WS","ASR1002X-10G-VPNK9","ASR1002X-5G-HA-K9","ASR1002X-20G-HA-K9",
          "ASR1002X-36G-SECK9","ASR1002X-36G-K9","ASR1002X-36G-HA-K9","ASR1002X-5G-SECK9","ASR1002X-36G-SHAK9","ASR1002X-CB",
          "ASR1002X-10G-K9","ASR1002X-20G-VPNK9","ASR1002-X=","ASR1002X-36G-VPNK9","ASR1002-10G/K9-WS","ASR1002X-10G-K9-RF",
          "ASR1002X-10G-SHAK9","ASR1002X-20G-SHAK9","ASR1002X10GHAK9-RF","ASR1002X-AIS-AX-RF","ASR1002X-SB-WS","ASR1002X10GVPNK9RF",
          "ASR1002-10G/K9","ASR1002-X-RF","ASR1002X-36G-NB","C1-ASR1002-X/K9","ASR1002X-AES-AX","ASR1002X-5G-K9","ASR1002-X",
          "ASR1002X5GVPNK9-WS","ASR1002X-10G-SECK9","ASR1002X-5G-SHAK9","ASR1002X-20G-K9","ASR1002X-AIS-AX","ASR1002X-CB-WS"]},
        {mdfId:"286315874", type:"Cisco Catalyst 9300 Switch", series:"Cisco Catalyst 9300 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9300-48P", "C9300-24UX", "c9300-24P", "C9300-48UXM",
          "C9300-48T", "C9300-48U", "C9300-24T", "C9300-24UB", "C9300-24S", "C9300-24U", "C9300-48S", "C9300-24UXB", "C9300-48UB",
          "C9300-48H", "C9300-48UN", "C9300-24H"]},
        {mdfId:"286322029", type:"Cisco Catalyst 9300L Switch Stack", series:"Cisco Catalyst 9300 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9300L-48UXG-4X","C9300L-48T-4G",
          "C9300L-24T-4G","C9300L-48UXG-2Q","C9300L-24P-4X","C9300L-48P-4X","C9300L-48PF-4G","C9300L-48T-4X",
          "C9300L-24T-4X","C9300L-48P-4G","C9300L-24P-4G","C9300L-24UXG-2Q","C9300L-48PF-4X","C9300L-24UXG-4X"]},
        {mdfId:"286315691", type:"Cisco Catalyst 9407R Switch", series:"Cisco Catalyst 9400 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9407R", "C9405R"]},
        {mdfId:"277241600", type:"Cisco Catalyst 4507R Switch", series:"Cisco Catalyst 4500 Series Switches",
          swversion: "16.6.1", family: "Switches and Hubs", pids:["WS-C4507R"]},
        {mdfId:"284389362", type:"Cisco 4451 Series Integrated Services Router",
          series:"Cisco 4400 Series Integrated Services Routers", swversion: "17.9.1", family: "Routers",
          pids:["ISR4451-X-VSEC/K9","ISR4451-X-AX/K9","ISR4451-X/K9-RF","ISR4451-X/K9","ISR4451-X-V/K9","ISR4451-X-AXV/K9"]},
        {mdfId:"286320564", type:"Cisco 4461 Series Integrated Services Router", 
          series:"Cisco 4000 Series Integrated Services Routers", swversion: "17.6.1", family: "Routers", 
          pids:["ISR4461-X/K9"]},
        {mdfId:"223415874", type:"Cisco Catalyst 9200 Switch Stack", series:"Cisco Catalyst 9200 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9200"]},
        {mdfId:"286318948", type:"Cisco Aironet 1800S Active Sensor", series:"Cisco Aironet 1800S Active Sensor",
          swversion: "2.2.1.0", family: "Wireless Sensor", pids:["AIR-AP1800S-B-K9","AIR-AP1800S-D-K9"]},//pids  are not listed in lated mdf
        {mdfId:"286320758", type:"Cisco Catalyst IE-3400-8P2S Rugged Switch", series:"Cisco Catalyst IE3400 Rugged Series",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["IE-3400-8P2S"]},
        {mdfId:"286320755", type:"Cisco Catalyst IE-3300-8P2S Rugged Switch", series:"Cisco Catalyst IE3300 Rugged Series",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["IE-3300-8P2S"]},
        {mdfId:"286316412", type:"Cisco Catalyst 9800-40 Wireless Controller", series:"Cisco Catalyst 9800 Series Wireless Controllers",
          swversion: "17.9.1", family: "Wireless Controller", pids:["C9800-40-K9"]},
        {mdfId:"286315863", type:"Cisco Catalyst 9500 Switch", series:"Cisco Catalyst 9500 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9500-16X","C9500-24Q","C9500-12Q","C9500-40X"]},
        {mdfId:"286320244", type:"Cisco Catalyst 9404R Switch", series:"Cisco Catalyst 9400 Series Switches",
          swversion: "17.9.1", family: "Switches and Hubs", pids:["C9404R"]},
        {mdfId:"286284588", type:"Cisco 5520 Series Wireless Controllers", series:"Cisco 5500 Series Wireless LAN Controllers",
          swversion: "8.10.151.0", family: "Wireless Controller", pids:["AIR-CT5520-K9"]},
        {mdfId:"286321399", type:"Cisco Catalyst 9800-L-F Wireless Controller", series:"Cisco Catalyst 9800 Series Wireless Controllers",
          swversion: "17.9.1", family: "Wireless Controller", pids:["C9800-L-F-K9"]},
        {mdfId:"286323294", type:"Cisco Catalyst 9120AXP Unified Access Point", series:"Cisco Catalyst 9120 Series Access Points",
          swversion: "17.9.1", family: "Unified AP", pids:["C9120AXP-B","C9120AXP-A"]}
        //add further mdf ids as per requirement. else, by default it will take 9300 switch
      ],

    //used for getting details of device type based on PID - during initialisation of devices on start
    "DeviceConfigDetails": {
      "AIR-AP1800S-B-K9":	{type: "Cisco Aironet 1800S Active Sensor",series: "Cisco Aironet 1800S Active Sensor",platformid: "AIR-AP1800S-B-K9",family: "Wireless Sensor",serialno: "KWC212100QQ"},
      "AIR-AP1852I-B-K9":	{"type":"Cisco 1850I Unified Access Point","platformid":"AIR-AP1852I-B-K9","series":"Cisco 1850I Series Unified Access Point","family":"Unified AP","serialno":"KWC193901RS"},
      "AIR-AP3802I-B-K9":	{"type":"Cisco 3800I Unified Access Point","platformid":"AIR-AP3802I-B-K9","series":"Cisco 3800i Series Unified Access Point","family":"Unified AP","serialno":"FCW2146NG8P"},
      "AIR-AP4800-A-K9":	{"type":"Cisco 4800 Series Unified Access Points","platformid":"AIR-AP4800-A-K9","series":"Cisco 4800 Series Unified Access Points","family":"Unified AP","serialno":"FJC2306M2FZ"},
      "AIR-AP4800-B-K9":	{"type":"Cisco 4800 Series Unified Access Points","platformid":"AIR-AP4800-B-K9","series":"Cisco 4800 Series Unified Access Points","family":"Unified AP","serialno":"FOC21461RV6"},
      "AIR-CAP3602I-A-K9":	{type:"Cisco 3600I Unified Access Point",platformid:"AIR-CAP3602I-A-K9",series:"Cisco 3600I Series Unified Access Point",family:"Unified AP",serialno:"RFD0P5BT059"},
      "AIR-CT3504-K9": {type:"Cisco 3504 Wireless LAN Controller",platformid:"AIR-CT3504-K9",series:"Cisco 3500 Series Wireless LAN Controller",family:"Wireless Controller",serialno:"FCW2221M0JT"},
      "ASR1001-X":	{type:"Cisco ASR 1001-X Router",series:"Cisco ASR 1000 Series Aggregation Services Routers",platformid:"ASR1001-X",family:"Routers",serialno:"FXS2111Q3HG"},
      "ASR1002-X":	{type:"Cisco ASR 1002-X Router",series:"Cisco ASR 1000 Series Aggregation Services Routers",platformid:"ASR1002-X",family:"Routers",serialno:"FOX2008GBAT"},
      //"C9115AXE-x":	{type:"Cisco Catalyst 9115AXE Access Point",platformid:"C9115AXE-x",series:"Cisco Catalyst 9115AXE Series Access Point",family:"Unified AP",serialno:"KWC193902RS"},//this pid is not present in mdf
      "C9115AXE-B":	{type:"Cisco Catalyst 9115AXE Unified Access Point",platformid:"C9115AXE-B",series:"Cisco Catalyst 9115AXE Series Unified Access Points",family:"Unified AP",serialno:"KWC313902RS"},
      //"C9120AXP-x":	{type:"Cisco Catalyst 9120AX Access Point",platformid:"C9120AXP-x",series:"Cisco Catalyst 9120 Series Access Points",family:"Unified AP",serialno:"FKM2025FBFQ"},//pid not seen in nfv mdf
      "C9120AXP-B":	{type:"Cisco Catalyst 9120AXP Unified Access Point",platformid:"C9120AXP-B",series:"Cisco Catalyst 9120 Series Access Points",family:"Unified AP",serialno:"FKM2030FBFQ"},
      "C9200-24T":	{type: "Cisco Catalyst 9200 Switch Stack",series:"Cisco Catalyst 9200 Series Switches",platformid:"C9200-24T",family:"Switches and Hubs",serialno:"JPG2227013C"},
      "c9300-24P":	{type:"Cisco Catalyst 9300 Switch",series:"Cisco Catalyst 9300 Series Switches",platformid:"c9300-24P",family:"Switches and Hubs",serialno:"FCW2125L0GW"},
      "C9300-48U":	{"type":"Cisco Catalyst 9300 Switch","series":"Cisco Catalyst 9300 Series Switches","platformid":"C9300-48U","family":"Switches and Hubs","serialno":"FCW2125L1GW"},
      "C9300-48UXM":	{"type":"Cisco Catalyst 9300 Switch","series":"Cisco Catalyst 9300 Series Switches","platformid":"C9300-48UXM","family":"ECA Switch","serialno":"N32QZAX7N9Z"},
      "C9300L-24T-4G":	{type: "Cisco Catalyst 9300L Switch Stack",series:"Cisco Catalyst 9300 Series Switches",platformid:"C9300L-24T-4G",family:"Switches and Hubs",serialno:"JPG2228103C"},
      "C9404R":	{type:"Cisco Catalyst 9404R Switch",series:"Cisco Catalyst 9400 Series Switches",platformid:"C9404R",family:"Switches and Hubs",serialno:"FXS2131Q3YF"},
      "C9500-16X":	{type:"Cisco Catalyst 9500 Switch",series:"Cisco Catalyst 9500 Series Switches",platformid:"C9500-16X",family:"Switches and Hubs",serialno:"FXS2131Q4ZA"},
      "C9606R":	{type:"Cisco Catalyst 9606R Switch",series:"Cisco Catalyst 9600 Series Switches",platformid:"C9606R",family:"Switches and Hubs",serialno:"FXS2131Q4AA"},
      "C9800-40-K9":	{type:"Cisco Catalyst 9800-40 Wireless Controller",platformid:"C9800-40-K9",series:"Cisco Catalyst 9800 Series Wireless Controllers",family:"Wireless Controller",serialno:"FCH2034V4F2"},
      "C9800-CL-K9":	{type:"Cisco Catalyst 9800-CL Wireless Controller for Cloud",platformid:"C9800-CL-K9",series:"Cisco Catalyst 9800 Series Wireless Controllers for Cloud",family:"Wireless Controller",serialno:"99TDET4NIUC"},
      //"AIR-CT5520-K9":	{type:"Cisco 5520 Series Wireless Controllers",platformid:"AIR-CT5520-K9",series:"Cisco 5500 Series Wireless LAN Controllers",family:"Wireless Controller",serialno:"FCJ2034V4F2"},
      "ENCS5412/K9":	{"type":"NFVIS","series":"NFVIS","platformid":"ENCS5412/K9","family":"NFVIS","serialno":"FGL2141822U"},//as ref cluster has this, retaining this
      //"ENCS5412/K9":	{"type":"Cisco 5400 Enterprise Network Compute System","series":"Cisco Network Functions Virtualization","platformid":"ENCS5412/K9","family":"NFVIS","serialno":"FGL2141822U"},
      "IE-3300-8P2S":	{type:"Cisco Catalyst IE-3300-8P2S Rugged Switch",series:"Cisco Catalyst IE3300 Rugged Series",platformid:"IE-3300-8P2S",family:"Switches and Hubs",serialno: "FOC2213V0EF"},
      "IE-3400-8P2S":	{type:"Cisco Catalyst IE-3400-8P2S Rugged Switch",series:"Cisco Catalyst IE3400 Rugged Series",platformid:"IE-3400-8P2S",family:"Switches and Hubs",serialno: "FOC2329V15G"},
      "IE-4000-4GS8GP4G-E":	{type:"Cisco IE-4000-4GS8GP4G-E Industrial Ethernet Switch",series:"Cisco IE 4000 Series Switches",platformid:"IE-4000-4GS8GP4G-E",family:"Switches and Hubs",serialno: "FDO2205U0AJ"},
      "IE-4000-8T4G-E":	{type:"Cisco IE-4000-8T4G-E Industrial Ethernet Switch",series:"Cisco IE 4000 Series Switches",platformid:"IE-4000-8T4G-E",family:"Switches and Hubs",serialno: "FDO1840T0BZ"},
      "IR1101-K9":	{type:"Cisco 1101 Industrial Integrated Services Router",platformid:"IR1101-K9",series:"Cisco 1101 Series Industrial Integrated Services Routers",family:"Routers",serialno:"FCW223700AH"},
      "ISR4331/K9":	{"type":"Cisco 4331 Integrated Services Router","platformid":"ISR4331/K9","series":"Cisco 4000 Series Integrated Services Routers","family":"Routers","serialno":"FJC2027DO62"},
      "ISR4451-X/K9":	{type:"Cisco 4451 Series Integrated Services Router",platformid:"ISR4451-X/K9",series:"Cisco 4400 Series Integrated Services Routers",family:"Routers",serialno:"FJC2027DO63"},
      "ISR4461-X/K9":	{type:"Cisco 4461 Series Integrated Services Router",platformid:"ISR4461-X/K9",series:"Cisco 4400 Series Integrated Services Routers",family:"Routers",serialno:"FJC2027DO64"},
      "WS-C4507R+E":	{type:"Cisco Catalyst 4507R plus E Switch",series:"Cisco Catalyst 4500 Series Switches",platformid:"WS-C4507R+E",family:"Switches and Hubs",serialno:"FOX1530GF5X"},
      "WS-C3650-48PD-E": {type:"Cisco Catalyst 3650-48PD-E Switch",series:"Cisco Catalyst 3650 Series Switches",platformid:"WS-C3650-48PD-E",family:"Switches and Hubs",serialno:"FDO1735Q0FR"},
      "WS-C3850-12X48U-E": {type:"Cisco Catalyst WS-C3850-12X48U-E Switch",series:"Cisco Catalyst 3850 Series Ethernet Stackable Switch",platformid:"WS-C3850-12X48U-E",family:"Switches and Hubs",serialno:"FCW1934C1D8"},
      "WS-C3850-12X48U-S": {type:"Cisco Catalyst WS-C3850-12X48U-S Switch",series:"Cisco Catalyst 3850 Series Ethernet Stackable Switch",platformid:"WS-C3850-12X48U-S",family:"Switches and Hubs",serialno:"FCW2125C0JG"},
      "C9800-L-F-K9": {type:"Cisco Catalyst 9800-L-F Wireless Controller", series:"Cisco Catalyst 9800 Series Wireless Controllers",platformid:"C9800-L-F-K9",family: "Wireless Controller", serialno:"FCL241200E9"},
      "C9300-24U": {type:"Cisco Catalyst 9300 Switch",series:"Cisco Catalyst 9300 Series Switches",platformid:"C9300-24U",family:"Switches and Hubs",serialno:"FCW2239L0XY"},
      "C9105AXI-A": { type: "Cisco Catalyst 9105AXI Unified Access Point", series: "Cisco Catalyst 9105AXI Unified Access Point", platformid: "C9105AXI-A", family: "Unified AP", serialno: "ZWL193112ST" },
      "C9105AXI-B": { type: "Cisco Catalyst 9105AXI Unified Access Point", series: "Cisco Catalyst 9105AXI Unified Access Point", platformid: "C9105AXI-B", family: "Unified AP", serialno: "ASC193113VS" },
      "C9120AXE-A": { type: "Cisco Catalyst 9120AXE Unified Access Point", series: "Cisco Catalyst 9120AXE Unified Access Point", platformid: "C9120AXE-A", family: "Unified AP", serialno: "KSC183114DM" },
      "C9120AXE-B": { type: "Cisco Catalyst 9120AXE Unified Access Point", series: "Cisco Catalyst 9120AXE Unified Access Point", platformid: "C9120AXE-B", family: "Unified AP", serialno: "RMC183124GC" },
      "C9120AXE-C": { type: "Cisco Catalyst 9120AXE Unified Access Point", series: "Cisco Catalyst 9120AXE Unified Access Point", platformid: "C9120AXE-C", family: "Unified AP", serialno: "DMC183134RK" },
      "C9124AXI": { type: "Cisco Catalyst 9124AXI Unified Access Point", series: "Cisco Catalyst 9124AXI Unified Access Point", platformid: "C9124AXI", family: "Unified AP", serialno: "SRM193643TE" },
      "C9130AXI-B1": { type: "Cisco Catalyst 9130AXI Unified Access Point", series: "Cisco Catalyst 9130AXI Unified Access Point", platformid: "C9130AXI-B1", family: "Unified AP", serialno: "SRH265745VB" },
      "C9130AXI-B": { type: "Cisco Catalyst 9130AXI Unified Access Point", series: "Cisco Catalyst 9130AXI Unified Access Point", platformid: "C9130AXI-B", family: "Unified AP", serialno: "SRH265745VC" },
      "C9130AXE-B": { type: "Cisco Catalyst 9130AXE Unified Access Point", series: "Cisco Catalyst 9130AXE Unified Access Point", platformid: "C9130AXE-B", family: "Unified AP", serialno: "SRH267845VD" },
      "C9130AXE": { type: "Cisco Catalyst C9130AXE Unified Access Point", series: "Cisco Catalyst 9130E Unified Access Point", platformid: "C9130AXE", family: "Unified AP", serialno: "SRH260845VE" },
      "C9136I-B": { type: "Cisco Catalyst 9136I Unified Access Point", series: "Cisco Catalyst 9136I  Series Unified Access Points", platformid: "C9136I-B", family: "Unified AP", serialno: "SRH266745VB" }
    },
    // added for HealthScore Threshold Setting for 1.3.3 in Assurance --> Manage--> Health Score Settings Page
    "HealthScoreSetting":{
      "Router_HealthScoreSetting": [
        {kpi:"cpuUtilizationThreshold", displayKey:"ct_hs_cpu_utilization", poorValue:90, poorDefaultValue: 95, poorMinValue: 0, poorMaxValue: 100, goodValue: 90, goodDefaultValue: 95, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"memoryUtilizationThreshold", displayKey:"ct_hs_memory_utilization", poorValue:95, poorDefaultValue: 95, poorMinValue: 0, poorMaxValue: 100, goodValue: 95, goodDefaultValue: 95, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"linkErrorThreshold", displayKey:"ct_hs_linkError", poorValue:1, poorDefaultValue: 1, poorMinValue: 0, poorMaxValue: 100, goodValue: 1, goodDefaultValue: 1, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"fabricReachability", displayKey:"ct_hs_fabricReachability", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "fabricReachability_good",poorValueString: "fabricReachability_poor",fairValueString: "fabricReachability_fail"},
        {kpi:"linkUtilizationThreshold", displayKey:"ct_hs_linkUtilization", poorValue:70, poorDefaultValue: 90, poorMinValue: 0, poorMaxValue: 100, goodValue: 70, goodDefaultValue: 90, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"infraLinkAvailabilityThreshold", displayKey:"infraLinkAvailabilityThreshold", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "all_infraLink_up",poorValueString: "all_infraLink_down",fairValueString: "parital_infraLink_down"}],
      "Switch_HealthScoreSetting": [
        {kpi:"cpuUtilizationThreshold", displayKey:"ct_hs_cpu_utilization", poorValue:30, poorDefaultValue: 95, poorMinValue: 0, poorMaxValue: 100, goodValue: 30, goodDefaultValue: 95, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"memoryUtilizationThreshold", displayKey:"ct_hs_memory_utilization", poorValue:95, poorDefaultValue: 95, poorMinValue: 0, poorMaxValue: 100, goodValue: 95, goodDefaultValue: 95, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"linkErrorThreshold", displayKey:"ct_hs_linkError", poorValue:1, poorDefaultValue: 1, poorMinValue: 0, poorMaxValue: 100, goodValue: 1, goodDefaultValue: 1, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"fabricReachability", displayKey:"ct_hs_fabricReachability", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "fabricReachability_good",poorValueString: "fabricReachability_poor",fairValueString: "fabricReachability_fail"},
        {kpi:"infraLinkAvailabilityThreshold", displayKey:"infraLinkAvailabilityThreshold", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "all_infraLink_up",poorValueString: "all_infraLink_down",fairValueString: "parital_infraLink_down"}],
      "WLC_HealthScoreSetting": [
        {kpi:"fabricReachability", displayKey:"ct_hs_fabricReachability_wlc", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "fabricReachability_good",poorValueString: "fabricReachability_poor",fairValueString: "fabricReachability_fail"},
        {kpi:"memoryUtilizationThreshold", displayKey:"ct_hs_memory_util_wlc", poorValue:85, poorDefaultValue: 95, poorMinValue: 0, poorMaxValue: 100, goodValue: 85, goodDefaultValue: 95, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"linkErrorThreshold", displayKey:"ct_hs_linkError_wlc", poorValue:1, poorDefaultValue: 1, poorMinValue: 0, poorMaxValue: 100, goodValue: 1, goodDefaultValue: 1, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"freeTimerThreshold", displayKey:"ct_hs_freeTimer", poorValue:20, poorDefaultValue: 20, poorMinValue: 0, poorMaxValue: 100, goodValue: 20, goodDefaultValue: 20, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"freeMbufThreshold", displayKey:"ct_hs_freeMbuf", poorValue:20, poorDefaultValue: 20, poorMinValue: 0, poorMaxValue: 0, goodValue: 20, goodDefaultValue: 20, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"WQEPool", displayKey:"ct_hs_WQEPool", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "wqePool_good",poorValueString: "wqePool_poor",fairValueString: "wqePool_fail"},
        {kpi:"packetPool", displayKey:"ct_hs_packetPool", poorValue:0, poorDefaultValue: 0, poorMinValue: 0, poorMaxValue: 0, goodValue: 0, goodDefaultValue: 0, goodMinValue: 0, goodMaxValue: 100, goodValueString: "packetPool_good",poorValueString: "packetPool_poor",fairValueString: "packetPool_fail"}],
      "UnifiedAP_HealthScoreSetting": [
        {kpi:"cpuUtilizationThreshold", displayKey:"ct_hs_cpu_ap", poorValue:70, poorDefaultValue: 90, poorMinValue: 0, poorMaxValue: 100, goodValue: 70, goodDefaultValue: 90, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"memoryUtilizationThreshold", displayKey:"ct_hs_memory_util_ap", poorValue:90, poorDefaultValue: 90, poorMinValue: 0, poorMaxValue: 100, goodValue: 90, goodDefaultValue: 90, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"linkErrorThreshold", displayKey:"ct_hs_linkError_ap", poorValue:1, poorDefaultValue: 1, poorMinValue: 0, poorMaxValue: 100, goodValue: 1, goodDefaultValue: 1, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_interference_2_4GThreshold", displayKey:"ct_hs_rf_interference_2_4G", poorValue:50, poorDefaultValue: 50, poorMinValue: 0, poorMaxValue: 100, goodValue: 50, goodDefaultValue: 50, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_interference_5GThreshold", displayKey:"ct_hs_rf_interference_5G", poorValue:20, poorDefaultValue: 20, poorMinValue: 0, poorMaxValue: 100, goodValue: 20, goodDefaultValue: 20, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_noise_2_4GThreshold", displayKey:"ct_hs_rf_noise_2_4G", poorValue: -70, poorDefaultValue: -81, poorMinValue: -120, poorMaxValue: 0, goodValue: -70, goodDefaultValue: -81, goodMinValue: -120, goodMaxValue: 0, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_noise_5GThreshold", displayKey:"ct_hs_rf_noise_5G", poorValue: -83, poorDefaultValue: -83, poorMinValue: -120, poorMaxValue: 0, goodValue: -83, goodDefaultValue: -83, goodMinValue: -120, goodMaxValue: 0, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_utilization_2_4GThreshold", displayKey:"ct_hs_rf_utilization_2_4G", poorValue:30, poorDefaultValue: 70, poorMinValue: 0, poorMaxValue: 100, goodValue: 30, goodDefaultValue: 70, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_utilization_5GThreshold", displayKey:"ct_hs_rf_utilization_5G", poorValue:40, poorDefaultValue: 70, poorMinValue: 0, poorMaxValue: 100, goodValue: 40, goodDefaultValue: 70, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_airQuality_2_4GThreshold", displayKey:"ct_hs_rf_airQuality_2_4G", poorValue:60, poorDefaultValue: 60, poorMinValue: 0, poorMaxValue: 100, goodValue: 60, goodDefaultValue: 60, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""},
        {kpi:"rf_airQuality_5GThreshold", displayKey:"ct_hs_rf_airQuality_5G", poorValue:75, poorDefaultValue: 75, poorMinValue: 0, poorMaxValue: 100, goodValue: 75, goodDefaultValue: 75, goodMinValue: 0, goodMaxValue: 100, goodValueString: "",poorValueString: "",fairValueString: ""}]
      },
      
      "EMBEDDED_WIRELESS_IMAGENAME": 'cat9k_iosxe.17.09.01.SPA.bin',
      //prev used values: 'cat9k_iosxe.17.03.03.SPA.bin','cat9k_iosxe.17.03.01.SPA.bin', "C9800-SW-iosxe-wlc.17.02.01.SPA.bin", 'cat9k_iosxe.16.10.01e.SPA.bin',

      "SDWAN_DEVICES": {"platformid":"ISR4331/K9", "swversion":"17.6.1", "type":"Cisco 4331 Integrated Services Router",
        "series":"Cisco 4000 Series Integrated Services Routers","family":"Routers","hostname":"SDWAN-ISR4k-0X",
        "macAddress":"ac:fe:dd:23:45:90","role":"BORDER ROUTER","healthScore":[{"healthType":"OVERALL","score":10}]
      }

});
