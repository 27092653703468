define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/modelExtended/RogueManagementTemplate',

    ],function(UtilityFunctions,SimLokiDatabaseActions, RogueManagementTemplate) {

    
    return {
        init: function() {
            createRogueManagementData();
            createRogueThreatTypeData();
            createRogueRuleData();
            createRogueRuleProfile();
            createRogueAllowRule();
        }
    };
    // added by Karan for Rogue Management 1.3.1
    function createRogueManagementData() {
        var grouphierarchy = "Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"
        var SiteRecordObj =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy': grouphierarchy});
        var apDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{siteId:SiteRecordObj[0].id});
        for(var i=0; i<73;i++) {
            var tJson=JSON.parse(JSON.stringify(RogueManagementTemplate.RogueManagement_Template[0][0]));
            var tempMac = tJson.properties.ro_macAddress[0].value;
            // var t = new Date().getTime();
            var ts = new Date();
            var t = ts.toISOString();
            tJson.timestamp = t
            var macAddr =  UtilityFunctions.incrementMacAddrres(tempMac, i);
            tJson.properties.ro_macAddress[0].value = macAddr;
            tJson.id = ('NormalizedRogueAp_'+macAddr+'__4');
            tJson.id = tJson.id.replace(/:/g, "_");
            tJson.properties.ro_strongestRssi[0].value = '-'+UtilityFunctions.getRandomIntForRangeValues(10, 90);
            tJson.properties.ro_strongestDetectingApName[0].value = apDevice[0].name;
            tJson.properties.ro_strongestDetectingApMacAddress[0].value = apDevice[0].macAddress.toUpperCase();
            tJson.properties.ro_strongestDetectingApSiteId[0].value = apDevice[0].siteId;
            // tJson.clientCount = 0;
            if(i == 4){
                tJson.properties.ro_strongestThreatScore[0].value = 15001;
                tJson.properties.ro_threatSsid[0].value = "DNAC_APPLE_ROUGE";
                tJson.properties.ro_vendor[0].value = "Apple, Inc.";
                tJson.properties.ro_strongestRssi[0].value = '-21';
                tJson.properties.ro_threatType[0].value = "Rogue on Wire";
                tJson.properties.ro_threatLevel[0].value = "High";
                delete tJson.properties.ro_isTarget
            }
            if(i >= 0 && i <=3 || i >= 5 && i <=12){
                tJson.properties.ro_strongestThreatScore[0].value = 910002;
                tJson.properties.ro_threatType[0].value = "Association Flood"
                tJson.properties.ro_threatLevel[0].value = "High";
                if(i >= 7 && i <=12){
                    tJson.properties.ro_strongestThreatScore[0].value = 910007;
                    tJson.properties.ro_threatType[0].value = "Deauthentication Broadcast"
                    tJson.properties.ro_threatLevel[0].value = "High";
                }
                if(i == 5 ){
                    tJson.properties.ro_strongestThreatScore[0].value = 910001;
                    tJson.properties.ro_threatType[0].value = "Authentication Flood"
                    tJson.properties.ro_threatLevel[0].value = "High";
                }
                if(i == 6 ){
                    tJson.properties.ro_strongestThreatScore[0].value = 910006;
                    tJson.properties.ro_threatType[0].value = "Deauthentication Flood"
                    tJson.properties.ro_threatLevel[0].value = "High";
                }
                tJson.properties.ro_threatSsid[0].value = 'HIDDEN';
                tJson.properties.ro_vendor[0].value = 'XEROX CORPORATION';
                tJson.properties.ro_strongestRssi[0].value = '-999';
                tJson.properties.ro_threatLevel[0].value = "informational";
                delete tJson.properties.ro_cnt
            }
            if(i>12 && i<18){
                delete tJson.properties.ro_isTarget
            }
            if(i>18 && i<=66){
                tJson.properties.ro_strongestThreatScore[0].value = 5001;
                tJson.properties.ro_threatType[0].value = "Rogue on Wire"
                delete tJson.properties.ro_isTarget
            }
            if(i >67){
                tJson.properties.ro_strongestThreatScore[0].value = 1901;
                tJson.properties.ro_threatType[0].value = "Rogue on Wire"
                delete tJson.properties.ro_isTarget
            }
            // if(i == 10 || i == 25){
            //     tJson.clientCount = 2;
            // }
            SimLokiDatabaseActions.insert('rogue-management', tJson);
        }
    }

    // Function to generate Thread Rule Data for Rogue
    function createRogueThreatTypeData() {
        var tJson=JSON.parse(JSON.stringify(RogueManagementTemplate.Rogue_autocomplete_threattype));
        SimLokiDatabaseActions.insert('rogue-threattype', tJson);
    }

    // Function to generate Rogue Rule
    function createRogueRuleData(){
        for(var i=0;i<3;i++){
            var tJson=JSON.parse(JSON.stringify(RogueManagementTemplate.AddRule_Tempalte[0]));
            tJson.rule.ruleDefinition.ruleName = "TestRule"+(i+1)
            tJson.rule.lastUpdateInfo.timestamp = Math.floor(UtilityFunctions.getTimeStamp())/1000;
            tJson.rule.threatScore = Math.floor(18001+i);
            var threadTemp = {}
            threadTemp.value = tJson.rule.threatScore;
            threadTemp.name = tJson.rule.ruleDefinition.ruleName;
            threadTemp.label = tJson.rule.ruleDefinition.ruleName;
            threadTemp.isCustom = true;
            threadTemp.isDeleted = false;
            //   tObj.ruleProfiles
            SimLokiDatabaseActions.insert('rogue-rule', tJson);
            SimLokiDatabaseActions.insert('rogue-threattype', threadTemp)
        }
    }

    // Function to generate Rogue Rule Profile
    function createRogueRuleProfile(){
        for(var i=0;i<3;i++){
            var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.AddProfileRule_Template[0]));
            tObj.lastUpdateInfo.timeStamp =Math.floor(UtilityFunctions.getTimeStamp())/1000;
            tObj.profileName = "TestProfile"+(i+1);
            tObj.rules[0].ruleName = "TestRule"+(i+1);

            // updating rule profile in Rule
            var ruleObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':tObj.rules[0].ruleName});
            ruleObj[0].ruleProfiles.push({profileName: tObj.profileName})
            
            SimLokiDatabaseActions.insert('rogue-rule-profile', tObj);
            SimLokiDatabaseActions.update('rogue-rule', ruleObj);
        }
    }

    // Function to generate Rogue Allowed Rule
    function createRogueAllowRule(){
        for(var i=0;i<3;i++){
            var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.VendorsProfile_Template[0]));
            tObj.lastChanged = Math.floor(UtilityFunctions.getTimeStamp())/1000;
            var grouphierarchy = "Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"
            var SiteRecordObj =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy': grouphierarchy});
            
            tObj.profileName = "TestList"+(i+1);
            tObj.sitesName = []
            tObj.sitesName.push(grouphierarchy)
            tObj.sites = []
            tObj.sites.push(SiteRecordObj[0].id)
            tObj.vendorName = "OpenVendor"+(i+1);
            SimLokiDatabaseActions.insert('rogue-allow-rule', tObj)
        }
    }
});
