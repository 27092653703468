define({
  "Template": {
    id:"",
    name:"",
    entityType:"Device",
    entity:"",
    groupBy:"",
    severity:"",
    category:"",
    summary:"",
    rootCause:"",
    timestamp:0,
    occurrences:139,
    scope:"LOCALIZED",
    status:{status:"ACTIVE",updatedBy:"Unknown",notes:"Unknown",source:"Unknown",updatedAt:null},
    totalOccurrences:{count:139,startTime:0,endTime:0},
    historyOccurrences:{count:139,startTime:0,endTime:0},
    priority:"P3",
    device: "10.31.255.100",
    deviceId: "4c8d8024-6da2-45e9-8780-504b134a5ac8",
    deviceName: "TO-3850-ACC-1.corp.local",
    deviceRole: "",
    instances: "",
    lastOccurence:0,
    isAiDriven:false,
    kairosId:""
  }
})
