define({
    //applicationUuid-TopologyControllerApp_Template's id (fixed), pageUuid- TopologyControllerPage_Template's id for specific site record.
    "SCJ06_Page_View_Template": [
      {"id":"b23f5b1f-f884-46e4-bfe1-762e7d401c45","name":"ArrangedView","applicationUuid":"f5efade8-cd44-48c6-87bd-40a8ad1e728d","pageUuid":"69428d3d-1860-49b3-b92b-b7270949c549"}
    ],

    "CustomViewCoordinates": [
      {
        "id":"b23f5b1f-f884-46e4-bfe1-762e7d401c45",
        "name":"ArrangedView",
        "applicationUuid":"f5efade8-cd44-48c6-87bd-40a8ad1e728d",
        "pageUuid":"69428d3d-1860-49b3-b92b-b7270949c549",
        "topology":{
          "nodes":[
            {"id":"3381a950-879f-46f3-bbf3-383094b901f0","customParam":{"x":9,"y":0,"parentNodeId":null}},
            {"id":"00:22:BD:DC:3D:63","customParam":{"x":2,"y":16,"parentNodeId":null}},
            {"id":"0C:75:BD:2D:5D:C1","customParam":{"x":5,"y":16,"parentNodeId":null}},            
            {"id":"18:9C:5D:21:F5:17","customParam":{"x":1,"y":14,"parentNodeId":null}},
            {"id":"18:9C:5D:21:F5:19","customParam":{"x":8,"y":17,"parentNodeId":null}},
            {"id":"<SJC06-C9300-01>","customParam":{"x":4,"y":9,"parentNodeId":null}},
            {"id":"<SJC06-C9300-02>","customParam":{"x":8,"y":9,"parentNodeId":null}},
            {"id":"<SJC06-C9300-03>","customParam":{"x":12,"y":9,"parentNodeId":null}},
            {"id":"<IE4K-ExtNode>","customParam":{"x":4,"y":12,"parentNodeId":null}},
            {"id":"<IE4K-POE_ExtNode>","customParam":{"x":1,"y":12,"parentNodeId":null}},
            {"id":"<IE-3300_6>","customParam":{"x":4,"y":14,"parentNodeId":null}},
            {"id":"<SJC06-C9300-05>","customParam":{"x":15,"y":9,"parentNodeId":null}},
            {"id":"<SJC06-C9300-04>","customParam":{"x":12,"y":11,"parentNodeId":null}},
            {"id":"<IE3400_1>","customParam":{"x":8,"y":11,"parentNodeId":null}},
            {"id":"<IE3400_2>","customParam":{"x":8,"y":13,"parentNodeId":null}},
            {"id":"<IE3400_3>","customParam":{"x":8,"y":15,"parentNodeId":null}},
            {"id":"<SJC06-C9600-01>","customParam":{"x":10,"y":6,"parentNodeId":null}},
            {"id":"<SJC06-C9600-02>","customParam":{"x":7,"y":6,"parentNodeId":null}},
            {"id":"<SJC06-ISR4461-01>","customParam":{"x":7,"y":3,"parentNodeId":null}},
            {"id":"<SJC06-ISR4461-02>","customParam":{"x":10,"y":3,"parentNodeId":null}},
            {"id":"<SJC06-WLC-ISSU>","customParam":{"x":13,"y":5,"parentNodeId":null}},
            {"id":"<SJC06-vWLC-9800>","customParam":{"x":4,"y":5,"parentNodeId":null}}
          ],
          "links":[]
        }
      }
    ]

});
