define({
    "Queuing_Profile_Template": {
      "id": "92f229e7-4603-4faa-bfb8-dd87b90ad6b4",
      "instanceId": 85088,
      "authEntityId": 85088,
      "displayName": "85088",
      "authEntityClass": 1713273470,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1524484153531,
      "deployed": false,
      "description": "Cisco Validated Design Queuing Profile",
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1524484153531,
      "name": "CVD_QUEUING_PROFILE",
      "provisioningState": "UNKNOWN",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "contract",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "genId": 0,
      "internal": true,
      "clause": [
        {
          "id": "870e7b27-6771-423e-8945-607c8d5087a8",
          "instanceId": 86089,
          "authEntityId": 86089,
          "displayName": "86089",
          "authEntityClass": 639298852,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "priority": 1,
          "type": "DSCP_CUSTOMIZATION",
          "tcDscpSettings": [
            {
              "id": "60bba9db-d861-4759-9ca7-d7c5b70f8ce8",
              "instanceId": 88097,
              "authEntityId": 88097,
              "displayName": "88097",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "34",
              "trafficClass": "MULTIMEDIA_CONFERENCING"
            },
            {
              "id": "32acc59c-0b3f-4a0b-afc1-9fbb3d20a7e3",
              "instanceId": 88096,
              "authEntityId": 88096,
              "displayName": "88096",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "46",
              "trafficClass": "VOIP_TELEPHONY"
            },
            {
              "id": "5b31ff36-6dad-4ebb-a0da-920aeb6f35d5",
              "instanceId": 88099,
              "authEntityId": 88099,
              "displayName": "88099",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "0",
              "trafficClass": "BEST_EFFORT"
            },
            {
              "id": "2d1ba332-e4c0-45b9-88fd-2ffd79a3cafb",
              "instanceId": 88098,
              "authEntityId": 88098,
              "displayName": "88098",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "24",
              "trafficClass": "SIGNALING"
            },
            {
              "id": "52e7e90d-a034-4570-80a4-14a04e99cc53",
              "instanceId": 88089,
              "authEntityId": 88089,
              "displayName": "88089",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "8",
              "trafficClass": "SCAVENGER"
            },
            {
              "id": "919e8878-8925-47a7-8323-cc570494f566",
              "instanceId": 88088,
              "authEntityId": 88088,
              "displayName": "88088",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "40",
              "trafficClass": "BROADCAST_VIDEO"
            },
            {
              "id": "f5d61427-8130-4796-99d7-b69f4c70681c",
              "instanceId": 88091,
              "authEntityId": 88091,
              "displayName": "88091",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "16",
              "trafficClass": "OPS_ADMIN_MGMT"
            },
            {
              "id": "31c6c31e-42d2-4b89-b640-520391f6b40b",
              "instanceId": 88090,
              "authEntityId": 88090,
              "displayName": "88090",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "10",
              "trafficClass": "BULK_DATA"
            },
            {
              "id": "d11a5b2a-1ee8-4af2-9c0a-5b7f4a7f90f7",
              "instanceId": 88093,
              "authEntityId": 88093,
              "displayName": "88093",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "32",
              "trafficClass": "REAL_TIME_INTERACTIVE"
            },
            {
              "id": "8e2b0838-99f2-4e98-a116-1e1a17f66a00",
              "instanceId": 88092,
              "authEntityId": 88092,
              "displayName": "88092",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "26",
              "trafficClass": "MULTIMEDIA_STREAMING"
            },
            {
              "id": "cf00cd17-30f7-4143-935f-cd93e6bc349e",
              "instanceId": 88095,
              "authEntityId": 88095,
              "displayName": "88095",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "18",
              "trafficClass": "TRANSACTIONAL_DATA"
            },
            {
              "id": "9ce3b2d4-e80a-40ca-bfcd-c68e151655db",
              "instanceId": 88094,
              "authEntityId": 88094,
              "displayName": "88094",
              "authEntityClass": 1915161817,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "dscp": "48",
              "trafficClass": "NETWORK_CONTROL"
            }
          ]
        },
        {
          "id": "02d8bca2-cd96-4717-a878-73a16884ade9",
          "instanceId": 86090,
          "authEntityId": 86090,
          "displayName": "86090",
          "authEntityClass": 1869142581,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BANDWIDTH",
          "isCommonBetweenAllInterfaceSpeeds": true,
          "interfaceSpeedBandwidthClauses": [
            {
              "id": "6d4ac97a-8ddc-4b50-b22f-ab58cb1a1fef",
              "instanceId": 89089,
              "authEntityId": 89089,
              "displayName": "89089",
              "authEntityClass": -381877821,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "interfaceSpeed": "ALL",
              "tcBandwidthSettings": [
                {
                  "id": "684ef84a-ee30-4c92-814c-30de5af2b1f3",
                  "instanceId": 90097,
                  "authEntityId": 90097,
                  "displayName": "90097",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 3,
                  "trafficClass": "NETWORK_CONTROL"
                },
                {
                  "id": "8e1eef11-eb8f-4e5c-8522-8d1caf044231",
                  "instanceId": 90096,
                  "authEntityId": 90096,
                  "displayName": "90096",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 10,
                  "trafficClass": "TRANSACTIONAL_DATA"
                },
                {
                  "id": "3916e590-d7ae-4daf-ba95-b7831d8c44a8",
                  "instanceId": 90099,
                  "authEntityId": 90099,
                  "displayName": "90099",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 10,
                  "trafficClass": "MULTIMEDIA_STREAMING"
                },
                {
                  "id": "0fedeace-29be-4849-b004-268f89c9db1f",
                  "instanceId": 90098,
                  "authEntityId": 90098,
                  "displayName": "90098",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 10,
                  "trafficClass": "BROADCAST_VIDEO"
                },
                {
                  "id": "a9faafc9-dba2-4073-8bca-4e5100452ec8",
                  "instanceId": 90101,
                  "authEntityId": 90101,
                  "displayName": "90101",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 4,
                  "trafficClass": "BULK_DATA"
                },
                {
                  "id": "cfa1659b-1949-4a34-ae83-5001aed5cc55",
                  "instanceId": 90100,
                  "authEntityId": 90100,
                  "displayName": "90100",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 2,
                  "trafficClass": "OPS_ADMIN_MGMT"
                },
                {
                  "id": "b0512b9e-7d82-4c5a-bbde-5e25cb3b68c0",
                  "instanceId": 90091,
                  "authEntityId": 90091,
                  "displayName": "90091",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 2,
                  "trafficClass": "SIGNALING"
                },
                {
                  "id": "7f14655f-6f4b-4c35-8f31-959e07a6b36b",
                  "instanceId": 90090,
                  "authEntityId": 90090,
                  "displayName": "90090",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 1,
                  "trafficClass": "SCAVENGER"
                },
                {
                  "id": "3810c8cb-5aa4-43d1-bb3b-7a6058a8fdd8",
                  "instanceId": 90093,
                  "authEntityId": 90093,
                  "displayName": "90093",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 13,
                  "trafficClass": "REAL_TIME_INTERACTIVE"
                },
                {
                  "id": "47ec6bcc-d841-43a2-b6f2-3d6fdcfc4cd3",
                  "instanceId": 90092,
                  "authEntityId": 90092,
                  "displayName": "90092",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 25,
                  "trafficClass": "BEST_EFFORT"
                },
                {
                  "id": "a94e0a1b-9844-49db-9425-d72f177fb747",
                  "instanceId": 90095,
                  "authEntityId": 90095,
                  "displayName": "90095",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 10,
                  "trafficClass": "MULTIMEDIA_CONFERENCING"
                },
                {
                  "id": "ef1bb4ba-05ed-4dc1-931e-811408916c81",
                  "instanceId": 90094,
                  "authEntityId": 90094,
                  "displayName": "90094",
                  "authEntityClass": 2028335825,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "bandwidthPercentage": 10,
                  "trafficClass": "VOIP_TELEPHONY"
                }
              ]
            }
          ]
        }
      ],
      "contractClassifier": [

      ]
    }
});
