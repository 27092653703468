define({
 "NSA_TOPOLOGY_Template":
  {
   "meta": {
     "title" : "",
     "subtitle" : ""
   },
   "nodes":[
      {
         "id":0,
         "ip":"",
         "name":"",
         "healthScore": 0,
         "role":"",
         "level":0,
         "nwDeviceId":"",
         "family":"",
         "softwareVersion":""
      }
   ],
   "links":[
      {
         "source":0,
         "target":1,
      }
   ]
}
})
