define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "time": "2018-08-02T09:00:00.000+0000",
        "healthScore": 10,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:00:00.000+0000",
        "healthScore": 0.06,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:30:00.000+0000",
        "healthScore": 0.34,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:00:00.000+0000",
        "healthScore": 0.43,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:00:00.000+0000",
        "healthScore": 0.22,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:30:00.000+0000",
        "healthScore": 0.15,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:30:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:30:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:00:00.000+0000",
        "healthScore": 0.13,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:30:00.000+0000",
        "healthScore": 0.21,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:00:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:30:00.000+0000",
        "healthScore": 0.2,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },{
        "time": "2018-08-02T09:00:00.000+0000",
        "healthScore": 0.5,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T08:00:00.000+0000",
        "healthScore": 0.06,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:30:00.000+0000",
        "healthScore": 0.34,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T07:00:00.000+0000",
        "healthScore": 0.43,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T06:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T05:00:00.000+0000",
        "healthScore": 0.22,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:30:00.000+0000",
        "healthScore": 0.15,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T04:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T03:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T02:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:30:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T01:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-02T00:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T23:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T22:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:30:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T21:00:00.000+0000",
        "healthScore": 0.13,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:30:00.000+0000",
        "healthScore": 0.21,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T20:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T19:00:00.000+0000",
        "healthScore": 0.14,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:30:00.000+0000",
        "healthScore": 0.2,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T18:00:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:50:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:30:00.000+0000",
        "healthScore": 0.07,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:10:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T17:05:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:55:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:45:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:40:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:35:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:30:00.000+0000",
        "healthScore": 0.08,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:25:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:20:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      },
      {
        "time": "2018-08-01T16:15:00.000+0000",
        "healthScore": 10.0,
        "packetLoss": 0.0,
        "latency": 0.0,
        "applicationDelay": 0.0
      }
    ]
  }
});