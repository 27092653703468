define({
"Heatmapdailytemplate":
{
        "data": {
            "metrics": {
                "__typename": "Radio36DailyHeatmapTypesConnection",
                "nodes": [
                    {
                        "__typename": "Radio36DailyHeatmapType",
                        "heatmapData": [
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -45.8691940307617,
                                "avgClientSnr": 52.158992767334,
                                "channelChangeCount": 0,
                                "datarate": 181,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 4.44507927617623,
                                "logMediaBitsPerSec": 8.57307495378099,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.1292542649769,
                                "maxClientCount": 2,
                                "maxClientRssi": -42,
                                "maxClientSnr": 56,
                                "maxDatarate": 753,
                                "maxInterference": 21,
                                "maxTraffic": 1,
                                "maxUtilization": 22,
                                "minClientRssi": -48.0588226318359,
                                "minClientSnr": 50.0526313781738,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "2",
                                "numSuccessOnboarding": "10",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 10,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -52.2888870239258,
                                "avgClientSnr": 49.2416648864746,
                                "channelChangeCount": 0,
                                "datarate": 1644,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 4.91030645370483,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 8.65539932250977,
                                "maxClientCount": 4,
                                "maxClientRssi": -47.7000007629395,
                                "maxClientSnr": 57,
                                "maxDatarate": 5361,
                                "maxInterference": 22,
                                "maxTraffic": 2,
                                "maxUtilization": 24,
                                "minClientRssi": -58.6666679382324,
                                "minClientSnr": 39.3333320617676,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "10",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 9,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.06666672229767,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -48.8703708648682,
                                "avgClientSnr": 50.5555553436279,
                                "channelChangeCount": 0,
                                "datarate": 94,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 12.3706169175071,
                                "logMediaBitsPerSec": 9.49528627966686,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.6199598468352,
                                "maxClientCount": 2,
                                "maxClientRssi": -47.6666679382324,
                                "maxClientSnr": 56,
                                "maxDatarate": 647,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -52,
                                "minClientSnr": 46,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -47.8052635192871,
                                "avgClientSnr": 52.7298240661621,
                                "channelChangeCount": 0,
                                "datarate": 1322.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 17,
                                "logCloudBitsPerSec": 4.69747736225,
                                "logMediaBitsPerSec": 8.89349709751037,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3619243778792,
                                "maxClientCount": 2,
                                "maxClientRssi": -42,
                                "maxClientSnr": 56.3333320617676,
                                "maxDatarate": 6973,
                                "maxInterference": 19,
                                "maxTraffic": 1,
                                "maxUtilization": 20,
                                "minClientRssi": -53,
                                "minClientSnr": 49.5789489746094,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "7",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 18
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -49.2666664123535,
                                "avgClientSnr": 49.8500003814697,
                                "channelChangeCount": 0,
                                "datarate": 77.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 17,
                                "logCloudBitsPerSec": 6.36005216315681,
                                "logMediaBitsPerSec": 4.40508487477359,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.74881900888841,
                                "maxClientCount": 2,
                                "maxClientRssi": -47.4000015258789,
                                "maxClientSnr": 57,
                                "maxDatarate": 312,
                                "maxInterference": 19,
                                "maxTraffic": 1,
                                "maxUtilization": 20,
                                "minClientRssi": -52.5,
                                "minClientSnr": 45.5,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "7",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "9",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 18
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -46.9041652679443,
                                "avgClientSnr": 51.5958347320557,
                                "channelChangeCount": 0,
                                "datarate": 235.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 17,
                                "logCloudBitsPerSec": 6.45315176053344,
                                "logMediaBitsPerSec": 10.7065073456385,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.2358362536726,
                                "maxClientCount": 2,
                                "maxClientRssi": -42,
                                "maxClientSnr": 56,
                                "maxDatarate": 1922,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": -48,
                                "minClientSnr": 50,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 18
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -50.4210510253906,
                                "avgClientSnr": 52.6458320617676,
                                "channelChangeCount": 0,
                                "datarate": 213,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 17,
                                "logCloudBitsPerSec": 6.46851082928182,
                                "logMediaBitsPerSec": 8.69283644776156,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.83649923094735,
                                "maxClientCount": 2,
                                "maxClientRssi": -47.7368431091309,
                                "maxClientSnr": 57,
                                "maxDatarate": 502,
                                "maxInterference": 19,
                                "maxTraffic": 1,
                                "maxUtilization": 20,
                                "minClientRssi": -53,
                                "minClientSnr": 48.6666679382324,
                                "numClientsSuccessFailOnboarding": "4",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "10",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "10",
                                "packetFailureRate": 0,
                                "radioClientCount": 7,
                                "radioDistinctAppCount": 10,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0.9375,
                                "utilization": 18
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -48.0500011444092,
                                "avgClientSnr": 53.0347232818604,
                                "channelChangeCount": 0,
                                "datarate": 916.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 17,
                                "logCloudBitsPerSec": 11.4249155065391,
                                "logMediaBitsPerSec": 8.57186202037355,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.6966079595498,
                                "maxClientCount": 2,
                                "maxClientRssi": -42,
                                "maxClientSnr": 57,
                                "maxDatarate": 4618,
                                "maxInterference": 19,
                                "maxTraffic": 1,
                                "maxUtilization": 20,
                                "minClientRssi": -53,
                                "minClientSnr": 49.8333320617676,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "8",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 7,
                                "radioDistinctAppCount": 10,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 18
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -47.5223693847656,
                                "avgClientSnr": 51.8350868225098,
                                "channelChangeCount": 0,
                                "datarate": 109.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 12.4494796091275,
                                "logMediaBitsPerSec": 8.20069690813204,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.7116583527038,
                                "maxClientCount": 2,
                                "maxClientRssi": -47,
                                "maxClientSnr": 56,
                                "maxDatarate": 300,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -47.7894744873047,
                                "minClientSnr": 50.2105255126953,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "6",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -50.0833339691162,
                                "avgClientSnr": 49.2958316802979,
                                "channelChangeCount": 0,
                                "datarate": 1146.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 4.90325252060892,
                                "logMediaBitsPerSec": 9.40313501847477,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.4122828486619,
                                "maxClientCount": 2,
                                "maxClientRssi": -47,
                                "maxClientSnr": 53.5,
                                "maxDatarate": 6009,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -59,
                                "minClientSnr": 39,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "8",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -51.0060176849365,
                                "avgClientSnr": 49.6444454193115,
                                "channelChangeCount": 0,
                                "datarate": 276,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 10.9743957081773,
                                "logMediaBitsPerSec": 8.81295437537574,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.6817167049307,
                                "maxClientCount": 3,
                                "maxClientRssi": -47.4500007629395,
                                "maxClientSnr": 57,
                                "maxDatarate": 935,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -57.5,
                                "minClientSnr": 40.5,
                                "numClientsSuccessFailOnboarding": "4",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "13",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 14,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -49.5611114501953,
                                "avgClientSnr": 49.7916679382324,
                                "channelChangeCount": 0,
                                "datarate": 402,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 12.0643352296361,
                                "logMediaBitsPerSec": 7.82210690545418,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.2971529104321,
                                "maxClientCount": 3,
                                "maxClientRssi": -47.5999984741211,
                                "maxClientSnr": 56,
                                "maxDatarate": 4287,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -52,
                                "minClientSnr": 46,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -46.8453941345215,
                                "avgClientSnr": 51.1769752502441,
                                "channelChangeCount": 0,
                                "datarate": 141.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 6.5722161385837,
                                "logMediaBitsPerSec": 10.258760019115,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.9014134017105,
                                "maxClientCount": 1,
                                "maxClientRssi": -43,
                                "maxClientSnr": 55,
                                "maxDatarate": 989,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -48.75,
                                "minClientSnr": 49.25,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -45.9679489135742,
                                "avgClientSnr": 52.990385055542,
                                "channelChangeCount": 0,
                                "datarate": 97,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.89465622747189,
                                "maxClientCount": 2,
                                "maxClientRssi": -41,
                                "maxClientSnr": 57,
                                "maxDatarate": 576,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -50.3333320617676,
                                "minClientSnr": 49.6923065185547,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -49.5995197296143,
                                "avgClientSnr": 50.1544494628906,
                                "channelChangeCount": 0,
                                "datarate": 159,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 4.51068064284306,
                                "logMediaBitsPerSec": 8.65026990362417,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.73592080941296,
                                "maxClientCount": 2,
                                "maxClientRssi": -47.2857131958008,
                                "maxClientSnr": 53,
                                "maxDatarate": 555,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -52,
                                "minClientSnr": 46.75,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "9",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -48.9289455413818,
                                "avgClientSnr": 52.0161914825439,
                                "channelChangeCount": 0,
                                "datarate": 1511.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 13.2447291315516,
                                "logMediaBitsPerSec": 8.31851731077376,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 13.3410013266974,
                                "maxClientCount": 3,
                                "maxClientRssi": -46,
                                "maxClientSnr": 56,
                                "maxDatarate": 12515,
                                "maxInterference": 21,
                                "maxTraffic": 1,
                                "maxUtilization": 22,
                                "minClientRssi": -53,
                                "minClientSnr": 50.4000015258789,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "9",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 7,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0.84375,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -50.6166667938232,
                                "avgClientSnr": 51.9099216461182,
                                "channelChangeCount": 0,
                                "datarate": 34,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 19,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.40512273441957,
                                "maxClientCount": 1,
                                "maxClientRssi": -48.2000007629395,
                                "maxClientSnr": 56,
                                "maxDatarate": 116,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -53,
                                "minClientSnr": 45,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "6",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 7,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 20
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -49.923246383667,
                                "avgClientSnr": 50.7989768981934,
                                "channelChangeCount": 0,
                                "datarate": 301.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029b868e12c821a5ecbd",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 9.3979457022448,
                                "logMediaBitsPerSec": 8.98953010870246,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.6407269364277,
                                "maxClientCount": 2,
                                "maxClientRssi": -47.7894744873047,
                                "maxClientSnr": 56.5,
                                "maxDatarate": 619,
                                "maxInterference": 20,
                                "maxTraffic": 1,
                                "maxUtilization": 21,
                                "minClientRssi": -52,
                                "minClientSnr": 46.75,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "7",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -41.21875,
                                "avgClientSnr": 52.15625,
                                "channelChangeCount": 0,
                                "datarate": 49,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 9.2576288826786,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.3092693559736,
                                "maxClientCount": 1,
                                "maxClientRssi": -40,
                                "maxClientSnr": 54.5,
                                "maxDatarate": 200,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -42.375,
                                "minClientSnr": 50.625,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 5,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.4210510253906,
                                "avgClientSnr": 56.6315803527832,
                                "channelChangeCount": 0,
                                "datarate": 60,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.4210510253906,
                                "maxClientSnr": 56.6315803527832,
                                "maxDatarate": 271,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -36.4210510253906,
                                "minClientSnr": 56.6315803527832,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 21,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.7171726226807,
                                "avgClientSnr": 56.1237373352051,
                                "channelChangeCount": 0,
                                "datarate": 77.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 2.80000837366962,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.68007432232026,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.0909080505371,
                                "maxClientSnr": 57,
                                "maxDatarate": 173,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -37,
                                "minClientSnr": 55.6363639831543,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -38.8202381134033,
                                "avgClientSnr": 54.5151519775391,
                                "channelChangeCount": 0,
                                "datarate": 63,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 6.89912168942987,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.64042154784233,
                                "maxClientCount": 2,
                                "maxClientRssi": -36.6666679382324,
                                "maxClientSnr": 56.3333320617676,
                                "maxDatarate": 191,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -42.7142868041992,
                                "minClientSnr": 50.5,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 1,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 8.07954373253318,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.64945601083209,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 17,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.7999992370605,
                                "avgClientSnr": 53.4545459747314,
                                "channelChangeCount": 0,
                                "datarate": 23,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.7999992370605,
                                "maxClientSnr": 55.9090919494629,
                                "maxDatarate": 139,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -36.7999992370605,
                                "minClientSnr": 51,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.8888893127441,
                                "avgClientSnr": 56.1111106872559,
                                "channelChangeCount": 0,
                                "datarate": 38.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 5.91254342102576,
                                "logMediaBitsPerSec": 5.50560871366856,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.21830766597509,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.8888893127441,
                                "maxClientSnr": 56.1111106872559,
                                "maxDatarate": 296,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -36.8888893127441,
                                "minClientSnr": 56.1111106872559,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -43.1666679382324,
                                "avgClientSnr": 50.5714302062988,
                                "channelChangeCount": 0,
                                "datarate": 12,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -43.1666679382324,
                                "maxClientSnr": 50.5714302062988,
                                "maxDatarate": 135,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -43.1666679382324,
                                "minClientSnr": 50.5714302062988,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -38.2725696563721,
                                "avgClientSnr": 54.6592483520508,
                                "channelChangeCount": 0,
                                "datarate": 95,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 9.08854658753346,
                                "logMediaBitsPerSec": 5.49213799904261,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.45550045654787,
                                "maxClientCount": 2,
                                "maxClientRssi": -36.8888893127441,
                                "maxClientSnr": 56.1111106872559,
                                "maxDatarate": 214,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -42.3125,
                                "minClientSnr": 50.6875,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -39.7416667938232,
                                "avgClientSnr": 53.1856060028076,
                                "channelChangeCount": 0,
                                "datarate": 986.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 8.10887307612564,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.67132489238058,
                                "maxClientCount": 2,
                                "maxClientRssi": -36.6666679382324,
                                "maxClientSnr": 56.3333320617676,
                                "maxDatarate": 6912,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -43.25,
                                "minClientSnr": 49.75,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -37.0500011444092,
                                "avgClientSnr": 55.9499988555908,
                                "channelChangeCount": 0,
                                "datarate": 81.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 7.47742527388363,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.32188792911827,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.9000015258789,
                                "maxClientSnr": 56.0999984741211,
                                "maxDatarate": 188,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -37.2000007629395,
                                "minClientSnr": 55.7999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 3.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 21,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.9000015258789,
                                "avgClientSnr": 56.0999984741211,
                                "channelChangeCount": 0,
                                "datarate": 28,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 12.8552114234629,
                                "logMediaBitsPerSec": 9.86618687547471,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 13.2637441822053,
                                "maxClientCount": 2,
                                "maxClientRssi": -36.7999992370605,
                                "maxClientSnr": 56.2000007629395,
                                "maxDatarate": 169,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -37,
                                "minClientSnr": 56,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 10,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.1657905578613,
                                "avgClientSnr": 56.8342094421387,
                                "channelChangeCount": 0,
                                "datarate": 62908,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 11.866010025986,
                                "logMediaBitsPerSec": 19.6610048530869,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.6735475626318,
                                "maxClientCount": 2,
                                "maxClientRssi": -35.6315803527832,
                                "maxClientSnr": 57.3684196472168,
                                "maxDatarate": 117166,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -36.7000007629395,
                                "minClientSnr": 56.2999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 15,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -36.6666679382324,
                                "avgClientSnr": 55.9000015258789,
                                "channelChangeCount": 0,
                                "datarate": 125,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02cebe9ad4314ef55213",
                                "frequencyBand": "5 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.6666679382324,
                                "maxClientSnr": 55.9000015258789,
                                "maxDatarate": 504,
                                "maxInterference": 2,
                                "maxTraffic": 1,
                                "maxUtilization": 3,
                                "minClientRssi": -36.6666679382324,
                                "minClientSnr": 55.9000015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -34.3125,
                                "avgClientSnr": 60.0625,
                                "channelChangeCount": 0,
                                "datarate": 25.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -32,
                                "maxClientSnr": 63,
                                "maxDatarate": 54,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -38,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -34.75,
                                "avgClientSnr": 60.1666679382324,
                                "channelChangeCount": 0,
                                "datarate": 9,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 2,
                                "maxClientRssi": -33,
                                "maxClientSnr": 62.5,
                                "maxDatarate": 30,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -36.5,
                                "minClientSnr": 58,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -37.75,
                                "avgClientSnr": 58.0833320617676,
                                "channelChangeCount": 0,
                                "datarate": 31,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.15101074837116,
                                "maxClientCount": 1,
                                "maxClientRssi": -36.5,
                                "maxClientSnr": 61,
                                "maxDatarate": 54,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -39,
                                "minClientSnr": 56,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -36.5833339691162,
                                "avgClientSnr": 57.3055553436279,
                                "channelChangeCount": 0,
                                "datarate": 19,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.24116217095228,
                                "maxClientCount": 2,
                                "maxClientRssi": -34,
                                "maxClientSnr": 60,
                                "maxDatarate": 81,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -40,
                                "minClientSnr": 53,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.0444431304932,
                                "avgClientSnr": 52.9833335876465,
                                "channelChangeCount": 0,
                                "datarate": 124,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 4.16921217120071,
                                "logMediaBitsPerSec": 9.56404766524095,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.4776230934793,
                                "maxClientCount": 3,
                                "maxClientRssi": -35,
                                "maxClientSnr": 60.1666679382324,
                                "maxDatarate": 559,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -50.4000015258789,
                                "minClientSnr": 43.2000007629395,
                                "numClientsSuccessFailOnboarding": "4",
                                "numClientsSuccessFailRoamIn": "4",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 7,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -37.3178558349609,
                                "avgClientSnr": 57.8898811340332,
                                "channelChangeCount": 0,
                                "datarate": 23,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.06004727012224,
                                "maxClientCount": 1,
                                "maxClientRssi": -36,
                                "maxClientSnr": 60.5,
                                "maxDatarate": 54,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -38.5714302062988,
                                "minClientSnr": 55.1428565979004,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.625,
                                "avgClientSnr": 60.0416660308838,
                                "channelChangeCount": 0,
                                "datarate": 39.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -34,
                                "maxClientSnr": 61.25,
                                "maxDatarate": 80,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37,
                                "minClientSnr": 59.5,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -36.0833339691162,
                                "avgClientSnr": 57.8999996185303,
                                "channelChangeCount": 1,
                                "datarate": 26.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.75447198654236,
                                "maxClientCount": 1,
                                "maxClientRssi": -35,
                                "maxClientSnr": 60,
                                "maxDatarate": 76,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37,
                                "minClientSnr": 56,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "10",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.1666660308838,
                                "avgClientSnr": 60.0555553436279,
                                "channelChangeCount": 0,
                                "datarate": 27,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.24505606267548,
                                "maxClientCount": 2,
                                "maxClientRssi": -31.5,
                                "maxClientSnr": 63.5,
                                "maxDatarate": 80,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -36.75,
                                "minClientSnr": 56.5,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.25,
                                "avgClientSnr": 59.9055557250977,
                                "channelChangeCount": 0,
                                "datarate": 25.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 4.57746220328827,
                                "maxClientCount": 3,
                                "maxClientRssi": -32,
                                "maxClientSnr": 63,
                                "maxDatarate": 56,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37,
                                "minClientSnr": 57.4000015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -34.5972213745117,
                                "avgClientSnr": 59.780158996582,
                                "channelChangeCount": 0,
                                "datarate": 32,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.37221695465384,
                                "maxClientCount": 2,
                                "maxClientRssi": -34,
                                "maxClientSnr": 61,
                                "maxDatarate": 84,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -35.3333320617676,
                                "minClientSnr": 57.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.4166660308838,
                                "avgClientSnr": 59.125,
                                "channelChangeCount": 0,
                                "datarate": 36.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.33786960423329,
                                "maxClientCount": 2,
                                "maxClientRssi": -34,
                                "maxClientSnr": 61,
                                "maxDatarate": 82,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37,
                                "minClientSnr": 56,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.9611110687256,
                                "avgClientSnr": 59.1833343505859,
                                "channelChangeCount": 0,
                                "datarate": 28.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.09611210305485,
                                "maxClientCount": 1,
                                "maxClientRssi": -32,
                                "maxClientSnr": 64,
                                "maxDatarate": 52,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37.4000015258789,
                                "minClientSnr": 55.5999984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -36.1875,
                                "avgClientSnr": 59.9930553436279,
                                "channelChangeCount": 0,
                                "datarate": 31.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.31397130873812,
                                "maxClientCount": 2,
                                "maxClientRssi": -34,
                                "maxClientSnr": 63,
                                "maxDatarate": 83,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37.5,
                                "minClientSnr": 56.8333320617676,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -34.1666660308838,
                                "avgClientSnr": 61.5694446563721,
                                "channelChangeCount": 0,
                                "datarate": 23.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 2,
                                "maxClientRssi": -33,
                                "maxClientSnr": 63,
                                "maxDatarate": 78,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -35.3333320617676,
                                "minClientSnr": 59.75,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -35.1805553436279,
                                "avgClientSnr": 59.6736125946045,
                                "channelChangeCount": 0,
                                "datarate": 27,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.36507815240535,
                                "maxClientCount": 2,
                                "maxClientRssi": -32,
                                "maxClientSnr": 63,
                                "maxDatarate": 59,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -37.5,
                                "minClientSnr": 58.3333320617676,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -36.1458339691162,
                                "avgClientSnr": 58.375,
                                "channelChangeCount": 0,
                                "datarate": 28.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.26936585298767,
                                "maxClientCount": 1,
                                "maxClientRssi": -32,
                                "maxClientSnr": 63,
                                "maxDatarate": 82,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -39,
                                "minClientSnr": 56,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -34.7999992370605,
                                "avgClientSnr": 60.6499996185303,
                                "channelChangeCount": 0,
                                "datarate": 26.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02003fcfc52f1ab80a33",
                                "frequencyBand": "5 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.0763882596023,
                                "maxClientCount": 2,
                                "maxClientRssi": -32,
                                "maxClientSnr": 64,
                                "maxDatarate": 54,
                                "maxInterference": 1,
                                "maxTraffic": 1,
                                "maxUtilization": 2,
                                "minClientRssi": -36,
                                "minClientSnr": 57,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -42.6888885498047,
                                "avgClientSnr": 57.6119041442871,
                                "channelChangeCount": 0,
                                "datarate": 76,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.03762629607281,
                                "maxClientCount": 3,
                                "maxClientRssi": -38.7999992370605,
                                "maxClientSnr": 60.6666679382324,
                                "maxDatarate": 154,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": -45.3333320617676,
                                "minClientSnr": 53.5714302062988,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "2",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1.40487861633301,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.2857151031494,
                                "avgClientSnr": 58.6111106872559,
                                "channelChangeCount": 0,
                                "datarate": 55.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.95863625384436,
                                "maxClientCount": 3,
                                "maxClientRssi": -39,
                                "maxClientSnr": 61,
                                "maxDatarate": 182,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": -44.1428565979004,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1.29898011684418,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.4277763366699,
                                "avgClientSnr": 57.9253978729248,
                                "channelChangeCount": 0,
                                "datarate": 65,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.83362097253887,
                                "maxClientCount": 2,
                                "maxClientRssi": -38.6666679382324,
                                "maxClientSnr": 60.7142868041992,
                                "maxDatarate": 142,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -44,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1.51865673065186,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -40.1416664123535,
                                "avgClientSnr": 57.9690475463867,
                                "channelChangeCount": 0,
                                "datarate": 71.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 2,
                                "maxClientRssi": -38.75,
                                "maxClientSnr": 61,
                                "maxDatarate": 209,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": -42.6666679382324,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1.50860506296158,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.4166660308838,
                                "avgClientSnr": 58.3252639770508,
                                "channelChangeCount": 0,
                                "datarate": 72,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.72973403303348,
                                "maxClientCount": 3,
                                "maxClientRssi": -38.6666679382324,
                                "maxClientSnr": 61.25,
                                "maxDatarate": 205,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": -45,
                                "minClientSnr": 54.4000015258789,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.57670801877975,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.8083343505859,
                                "avgClientSnr": 57.8916664123535,
                                "channelChangeCount": 0,
                                "datarate": 83,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.75024930342486,
                                "maxClientCount": 2,
                                "maxClientRssi": -39.5,
                                "maxClientSnr": 60.3333320617676,
                                "maxDatarate": 200,
                                "maxInterference": 88,
                                "maxTraffic": 3,
                                "maxUtilization": 91,
                                "minClientRssi": -44,
                                "minClientSnr": 55.4000015258789,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "6",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.69736838340759,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.5,
                                "avgClientSnr": 58.2599201202393,
                                "channelChangeCount": 0,
                                "datarate": 61,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.30248154219372,
                                "maxClientCount": 2,
                                "maxClientRssi": -39,
                                "maxClientSnr": 61.6666679382324,
                                "maxDatarate": 145,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": -45,
                                "minClientSnr": 54.4285697937012,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1.52089840173721,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -42.0961322784424,
                                "avgClientSnr": 57.5767860412598,
                                "channelChangeCount": 0,
                                "datarate": 107.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.50156290134259,
                                "maxClientCount": 2,
                                "maxClientRssi": -39.1428565979004,
                                "maxClientSnr": 60.2857131958008,
                                "maxDatarate": 280,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": -43.6666679382324,
                                "minClientSnr": 55.6666679382324,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1.40588235855103,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.6027793884277,
                                "avgClientSnr": 58.582540512085,
                                "channelChangeCount": 0,
                                "datarate": 86,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.19464738816409,
                                "maxClientCount": 2,
                                "maxClientRssi": -38.6666679382324,
                                "maxClientSnr": 61.4285697937012,
                                "maxDatarate": 205,
                                "maxInterference": 87,
                                "maxTraffic": 2,
                                "maxUtilization": 89,
                                "minClientRssi": -44.25,
                                "minClientSnr": 55.4000015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "2",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1.84188693761826,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.0250015258789,
                                "avgClientSnr": 58.2714290618896,
                                "channelChangeCount": 0,
                                "datarate": 76,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.72504424322764,
                                "maxClientCount": 2,
                                "maxClientRssi": -38.75,
                                "maxClientSnr": 60.4285697937012,
                                "maxDatarate": 225,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": -43,
                                "minClientSnr": 56.4000015258789,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1.71259397268295,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -42.075927734375,
                                "avgClientSnr": 57.2481479644775,
                                "channelChangeCount": 0,
                                "datarate": 141,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.89023063950035,
                                "maxClientCount": 2,
                                "maxClientRssi": -39.2000007629395,
                                "maxClientSnr": 60.2000007629395,
                                "maxDatarate": 235,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -44.2222213745117,
                                "minClientSnr": 55.2222213745117,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1.77360105514526,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.3194446563721,
                                "avgClientSnr": 58.2690467834473,
                                "channelChangeCount": 0,
                                "datarate": 151,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 3,
                                "maxClientRssi": -39.75,
                                "maxClientSnr": 60.4000015258789,
                                "maxDatarate": 280,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -43,
                                "minClientSnr": 56.2857131958008,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "11",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1.46505618095398,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.1557540893555,
                                "avgClientSnr": 58.1117725372314,
                                "channelChangeCount": 0,
                                "datarate": 115,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.36153444246327,
                                "maxClientCount": 2,
                                "maxClientRssi": -39.1428565979004,
                                "maxClientSnr": 60.25,
                                "maxDatarate": 306,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -44,
                                "minClientSnr": 55.5,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "11",
                                "numSuccessRoamOut": "9",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.41232877969742,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -42.4583320617676,
                                "avgClientSnr": 56.2380962371826,
                                "channelChangeCount": 0,
                                "datarate": 76.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.30704394289528,
                                "maxClientCount": 2,
                                "maxClientRssi": -40.75,
                                "maxClientSnr": 58.6666679382324,
                                "maxDatarate": 189,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -45,
                                "minClientSnr": 52.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.47992765903473,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.4396820068359,
                                "avgClientSnr": 57.8714275360107,
                                "channelChangeCount": 0,
                                "datarate": 104,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.07648315335311,
                                "maxClientCount": 2,
                                "maxClientRssi": -38.2000007629395,
                                "maxClientSnr": 61.1428565979004,
                                "maxDatarate": 221,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": -44,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "1",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "10",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1.58838385343552,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.3474216461182,
                                "avgClientSnr": 57.9974212646484,
                                "channelChangeCount": 0,
                                "datarate": 131.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.38558425711251,
                                "maxClientCount": 3,
                                "maxClientRssi": -39,
                                "maxClientSnr": 60.4285697937012,
                                "maxDatarate": 306,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -44.4000015258789,
                                "minClientSnr": 54.7999992370605,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "2",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1.63108110427856,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.6666679382324,
                                "avgClientSnr": 58.2888889312744,
                                "channelChangeCount": 0,
                                "datarate": 75.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.30694267040243,
                                "maxClientCount": 2,
                                "maxClientRssi": -39,
                                "maxClientSnr": 61,
                                "maxDatarate": 165,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": -45,
                                "minClientSnr": 55.4000015258789,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.26617068052292,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -41.0555572509766,
                                "avgClientSnr": 57.3333320617676,
                                "channelChangeCount": 0,
                                "datarate": 104,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d65587774c9e2a78c",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 6.01663494110108,
                                "maxClientCount": 1,
                                "maxClientRssi": -39,
                                "maxClientSnr": 59.8333320617676,
                                "maxDatarate": 187,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": -44.5,
                                "minClientSnr": 54.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.18840575218201,
                                "utilization": 88
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -43.9562492370605,
                                "avgClientSnr": 51.5437507629395,
                                "channelChangeCount": 0,
                                "datarate": 28.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.11404315027678,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.125,
                                "maxClientSnr": 53.875,
                                "maxDatarate": 56,
                                "maxInterference": 38,
                                "maxTraffic": 1,
                                "maxUtilization": 39,
                                "minClientRssi": -47.2000007629395,
                                "minClientSnr": 47.7999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44.1255950927734,
                                "avgClientSnr": 50.8619060516357,
                                "channelChangeCount": 0,
                                "datarate": 14,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.92092860709774,
                                "maxClientCount": 2,
                                "maxClientRssi": -43.2857131958008,
                                "maxClientSnr": 51.7142868041992,
                                "maxDatarate": 27,
                                "maxInterference": 38,
                                "maxTraffic": 2,
                                "maxUtilization": 40,
                                "minClientRssi": -45.75,
                                "minClientSnr": 49.2000007629395,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.08823531866074,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42.1458339691162,
                                "avgClientSnr": 52.8541660308838,
                                "channelChangeCount": 0,
                                "datarate": 25.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.32860509217608,
                                "maxClientCount": 2,
                                "maxClientRssi": -38,
                                "maxClientSnr": 57,
                                "maxDatarate": 55,
                                "maxInterference": 38,
                                "maxTraffic": 1,
                                "maxUtilization": 39,
                                "minClientRssi": -46.25,
                                "minClientSnr": 48.75,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -41.783332824707,
                                "avgClientSnr": 54.0486106872559,
                                "channelChangeCount": 0,
                                "datarate": 32,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.97615989028093,
                                "maxClientCount": 2,
                                "maxClientRssi": -41,
                                "maxClientSnr": 55,
                                "maxDatarate": 78,
                                "maxInterference": 39,
                                "maxTraffic": 1,
                                "maxUtilization": 40,
                                "minClientRssi": -42.3333320617676,
                                "minClientSnr": 52.6666679382324,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44.0916652679443,
                                "avgClientSnr": 52.8591270446777,
                                "channelChangeCount": 0,
                                "datarate": 20,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 38,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.87178884376696,
                                "maxClientCount": 2,
                                "maxClientRssi": -43,
                                "maxClientSnr": 55.5,
                                "maxDatarate": 31,
                                "maxInterference": 39,
                                "maxTraffic": 1,
                                "maxUtilization": 40,
                                "minClientRssi": -46,
                                "minClientSnr": 51.3333320617676,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0.878431379795074,
                                "utilization": 39
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44.158332824707,
                                "avgClientSnr": 50.75,
                                "channelChangeCount": 0,
                                "datarate": 26.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.94360879555276,
                                "maxClientCount": 2,
                                "maxClientRssi": -42.5999984741211,
                                "maxClientSnr": 52.3333320617676,
                                "maxDatarate": 54,
                                "maxInterference": 38,
                                "maxTraffic": 2,
                                "maxUtilization": 39,
                                "minClientRssi": -47.3333320617676,
                                "minClientSnr": 47.6666679382324,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.10000002384186,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -43.4902782440186,
                                "avgClientSnr": 52.2805557250977,
                                "channelChangeCount": 0,
                                "datarate": 30,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 37,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.5408126346941,
                                "maxClientCount": 2,
                                "maxClientRssi": -42,
                                "maxClientSnr": 56,
                                "maxDatarate": 55,
                                "maxInterference": 39,
                                "maxTraffic": 2,
                                "maxUtilization": 40,
                                "minClientRssi": -45.1666679382324,
                                "minClientSnr": 49.8333320617676,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "9",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1.05555558204651,
                                "utilization": 38
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -41.6875,
                                "avgClientSnr": 54.4861106872559,
                                "channelChangeCount": 0,
                                "datarate": 21,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 36,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -41,
                                "maxClientSnr": 56,
                                "maxDatarate": 54,
                                "maxInterference": 37,
                                "maxTraffic": 1,
                                "maxUtilization": 38,
                                "minClientRssi": -42,
                                "minClientSnr": 53,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 37
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -43.0833339691162,
                                "avgClientSnr": 54.0753974914551,
                                "channelChangeCount": 0,
                                "datarate": 33.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 36,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.28334564810468,
                                "maxClientCount": 1,
                                "maxClientRssi": -42.5,
                                "maxClientSnr": 57.3333320617676,
                                "maxDatarate": 80,
                                "maxInterference": 38,
                                "maxTraffic": 1,
                                "maxUtilization": 39,
                                "minClientRssi": -43.8333320617676,
                                "minClientSnr": 51.1666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 37
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44.1833343505859,
                                "avgClientSnr": 52.6416664123535,
                                "channelChangeCount": 0,
                                "datarate": 20.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 36,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.27401320388966,
                                "maxClientCount": 1,
                                "maxClientRssi": -42.4000015258789,
                                "maxClientSnr": 56,
                                "maxDatarate": 56,
                                "maxInterference": 37,
                                "maxTraffic": 1,
                                "maxUtilization": 38,
                                "minClientRssi": -45,
                                "minClientSnr": 51.25,
                                "numClientsSuccessFailOnboarding": "3",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "11",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 37
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -45.0535717010498,
                                "avgClientSnr": 52.0089282989502,
                                "channelChangeCount": 0,
                                "datarate": 33,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 36,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.99125706942358,
                                "maxClientCount": 2,
                                "maxClientRssi": -42.7142868041992,
                                "maxClientSnr": 57,
                                "maxDatarate": 58,
                                "maxInterference": 37,
                                "maxTraffic": 1,
                                "maxUtilization": 38,
                                "minClientRssi": -46.25,
                                "minClientSnr": 48.75,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 37
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -41.7194442749023,
                                "avgClientSnr": 53.7805557250977,
                                "channelChangeCount": 0,
                                "datarate": 23,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 36,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.32419060477396,
                                "maxClientCount": 2,
                                "maxClientRssi": -39.7777786254883,
                                "maxClientSnr": 55.5,
                                "maxDatarate": 53,
                                "maxInterference": 37,
                                "maxTraffic": 1,
                                "maxUtilization": 38,
                                "minClientRssi": -44.5999984741211,
                                "minClientSnr": 50.4000015258789,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "9",
                                "numSuccessRoamOut": "9",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 37
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -43.9571437835693,
                                "avgClientSnr": 51.5388889312744,
                                "channelChangeCount": 0,
                                "datarate": 23,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 38,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.85155466836998,
                                "maxClientCount": 2,
                                "maxClientRssi": -42.4285697937012,
                                "maxClientSnr": 53.2222213745117,
                                "maxDatarate": 53,
                                "maxInterference": 42,
                                "maxTraffic": 2,
                                "maxUtilization": 43,
                                "minClientRssi": -46,
                                "minClientSnr": 49,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.09375,
                                "utilization": 40
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44.625,
                                "avgClientSnr": 50.787202835083,
                                "channelChangeCount": 0,
                                "datarate": 35,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 40,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.5,
                                "maxClientSnr": 53.2857131958008,
                                "maxDatarate": 79,
                                "maxInterference": 43,
                                "maxTraffic": 2,
                                "maxUtilization": 44,
                                "minClientRssi": -47,
                                "minClientSnr": 48,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "8",
                                "numSuccessRoamOut": "8",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.21139705181122,
                                "utilization": 42
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -44,
                                "avgClientSnr": 51.1805553436279,
                                "channelChangeCount": 0,
                                "datarate": 20.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 47,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.55588433292566,
                                "maxClientCount": 2,
                                "maxClientRssi": -41.6666679382324,
                                "maxClientSnr": 54.5,
                                "maxDatarate": 54,
                                "maxInterference": 52,
                                "maxTraffic": 2,
                                "maxUtilization": 53,
                                "minClientRssi": -46.5,
                                "minClientSnr": 48.5,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "5",
                                "numSuccessRoamIn": "6",
                                "numSuccessRoamOut": "6",
                                "packetFailureRate": 0,
                                "radioClientCount": 6,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1.0625,
                                "utilization": 49
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -41.6309509277344,
                                "avgClientSnr": 54.1819438934326,
                                "channelChangeCount": 0,
                                "datarate": 29.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 48,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.38546955263341,
                                "maxClientCount": 1,
                                "maxClientRssi": -40.5,
                                "maxClientSnr": 56,
                                "maxDatarate": 80,
                                "maxInterference": 52,
                                "maxTraffic": 2,
                                "maxUtilization": 53,
                                "minClientRssi": -43.5,
                                "minClientSnr": 52.875,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "10",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1.16041666269302,
                                "utilization": 49
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -40.8541660308838,
                                "avgClientSnr": 54.6000003814697,
                                "channelChangeCount": 0,
                                "datarate": 19.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 26,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.71002579768385,
                                "maxClientCount": 1,
                                "maxClientRssi": -40.25,
                                "maxClientSnr": 55.4000015258789,
                                "maxDatarate": 77,
                                "maxInterference": 47,
                                "maxTraffic": 2,
                                "maxUtilization": 48,
                                "minClientRssi": -41.6666679382324,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "5",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.19791668653488,
                                "utilization": 27
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -45.0833320617676,
                                "avgClientSnr": 51.9444427490234,
                                "channelChangeCount": 0,
                                "datarate": 8,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0278b88055e5686d6d11",
                                "frequencyBand": "5 GHz",
                                "interference": 14,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 7.47310161590576,
                                "maxClientCount": 2,
                                "maxClientRssi": -44.6666679382324,
                                "maxClientSnr": 56,
                                "maxDatarate": 25,
                                "maxInterference": 16,
                                "maxTraffic": 1,
                                "maxUtilization": 17,
                                "minClientRssi": -45.5,
                                "minClientSnr": 49.5,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "3",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "7",
                                "numSuccessRoamOut": "7",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 3,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 15
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 6,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 27,
                                "maxInterference": 84,
                                "maxTraffic": 13,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 11.401083946228,
                                "utilization": 94
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -44,
                                "avgClientSnr": 54,
                                "channelChangeCount": 0,
                                "datarate": 6,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 1,
                                "maxClientRssi": -44,
                                "maxClientSnr": 54,
                                "maxDatarate": 27,
                                "maxInterference": 83,
                                "maxTraffic": 12,
                                "maxUtilization": 95,
                                "minClientRssi": -44,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 10.9024391174316,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 14,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 12.2307691574097,
                                "utilization": 95
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 1,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 30,
                                "maxInterference": 85,
                                "maxTraffic": 14,
                                "maxUtilization": 97,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 12.2097401618958,
                                "utilization": 95
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -42,
                                "avgClientSnr": 54,
                                "channelChangeCount": 0,
                                "datarate": 6,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -42,
                                "maxClientSnr": 54,
                                "maxDatarate": 34,
                                "maxInterference": 84,
                                "maxTraffic": 14,
                                "maxUtilization": 96,
                                "minClientRssi": -42,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 11.4795632362366,
                                "utilization": 94
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 9,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 36,
                                "maxInterference": 83,
                                "maxTraffic": 13,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 11.7078948020935,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 1.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 20,
                                "maxInterference": 83,
                                "maxTraffic": 14,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 11.8429288864136,
                                "utilization": 94
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 15,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 12.186005115509,
                                "utilization": 95
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 14,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 12.5270581245422,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -45.5,
                                "avgClientSnr": 52.75,
                                "channelChangeCount": 0,
                                "datarate": 22.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -45.5,
                                "maxClientSnr": 54,
                                "maxDatarate": 64,
                                "maxInterference": 81,
                                "maxTraffic": 13,
                                "maxUtilization": 93,
                                "minClientRssi": -45.5,
                                "minClientSnr": 51.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 11.5364217758179,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 13,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 11.3591265678406,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 12,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 11.4694395065308,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 14,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 12.2271065711975,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 2,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 28,
                                "maxInterference": 82,
                                "maxTraffic": 13,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 12.0769228935242,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 4.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 27,
                                "maxInterference": 82,
                                "maxTraffic": 13,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 11.3971848487854,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -40.25,
                                "avgClientSnr": 56.8333339691162,
                                "channelChangeCount": 0,
                                "datarate": 23.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -39,
                                "maxClientSnr": 58,
                                "maxDatarate": 111,
                                "maxInterference": 84,
                                "maxTraffic": 13,
                                "maxUtilization": 96,
                                "minClientRssi": -41.5,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "5",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 11.3322386741638,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -41.75,
                                "avgClientSnr": 56.75,
                                "channelChangeCount": 0,
                                "datarate": 8.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.06043194727275,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.5,
                                "maxClientSnr": 57,
                                "maxDatarate": 78,
                                "maxInterference": 84,
                                "maxTraffic": 13,
                                "maxUtilization": 96,
                                "minClientRssi": -42,
                                "minClientSnr": 56.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 11.5839428901672,
                                "utilization": 94
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": -43.5,
                                "avgClientSnr": 57,
                                "channelChangeCount": 0,
                                "datarate": 9,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023d17e0554fbf8c9610",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -42,
                                "maxClientSnr": 57.3333320617676,
                                "maxDatarate": 29,
                                "maxInterference": 81,
                                "maxTraffic": 14,
                                "maxUtilization": 94,
                                "minClientRssi": -45,
                                "minClientSnr": 56.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 12.1965551376343,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.6236839294434,
                                "avgClientSnr": 40.1166648864746,
                                "channelChangeCount": 0,
                                "datarate": 463,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 8.24126815795899,
                                "logMediaBitsPerSec": 9.64886474609375,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 11.0500574111938,
                                "maxClientCount": 2,
                                "maxClientRssi": -51.9473686218262,
                                "maxClientSnr": 43.3499984741211,
                                "maxDatarate": 565,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -53.2999992370605,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 22,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0555553436279,
                                "avgClientSnr": 41.2434196472168,
                                "channelChangeCount": 0,
                                "datarate": 43258.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 10.3568871983253,
                                "logMediaBitsPerSec": 19.5914525319382,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.6022451392513,
                                "maxClientCount": 2,
                                "maxClientRssi": -51.2105255126953,
                                "maxClientSnr": 44.4736824035645,
                                "maxDatarate": 106447,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -53.2222213745117,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 15,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0526313781738,
                                "avgClientSnr": 43.4000015258789,
                                "channelChangeCount": 0,
                                "datarate": 196,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 8.97895815245806,
                                "logMediaBitsPerSec": 9.59791223402527,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.1198119612357,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.0526313781738,
                                "maxClientSnr": 43.4000015258789,
                                "maxDatarate": 595,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0526313781738,
                                "minClientSnr": 43.4000015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -53.1000003814697,
                                "avgClientSnr": 42.25,
                                "channelChangeCount": 0,
                                "datarate": 24,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 6.76187621127501,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.9649612585677,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.9000015258789,
                                "maxClientSnr": 42.2999992370605,
                                "maxDatarate": 121,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -53.2999992370605,
                                "minClientSnr": 42.2000007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -53.0750007629395,
                                "avgClientSnr": 43.1749992370605,
                                "channelChangeCount": 0,
                                "datarate": 25,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 3.40175172979524,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.7704177490246,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.9000015258789,
                                "maxClientSnr": 43.5999984741211,
                                "maxDatarate": 114,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -53.25,
                                "minClientSnr": 42.75,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0499992370605,
                                "avgClientSnr": 43.5499992370605,
                                "channelChangeCount": 0,
                                "datarate": 186.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 7.78938162344043,
                                "logMediaBitsPerSec": 9.82470426917352,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.7812689112501,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.0499992370605,
                                "maxClientSnr": 43.5499992370605,
                                "maxDatarate": 385,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 43.5499992370605,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52,
                                "avgClientSnr": 44,
                                "channelChangeCount": 0,
                                "datarate": 237.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 6.20867347820395,
                                "logMediaBitsPerSec": 9.84242031257354,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.7562149968106,
                                "maxClientCount": 1,
                                "maxClientRssi": -52,
                                "maxClientSnr": 44,
                                "maxDatarate": 522,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52,
                                "minClientSnr": 44,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0499992370605,
                                "avgClientSnr": 44.2999992370605,
                                "channelChangeCount": 0,
                                "datarate": 441.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 8.72937153362586,
                                "logMediaBitsPerSec": 9.89129807521649,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.2224260275497,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.0499992370605,
                                "maxClientSnr": 44.2999992370605,
                                "maxDatarate": 4480,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 44.2999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.1500015258789,
                                "avgClientSnr": 43.8499984741211,
                                "channelChangeCount": 0,
                                "datarate": 237,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 8.8237212469676,
                                "logMediaBitsPerSec": 3.53051481596672,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3694229258954,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.1500015258789,
                                "maxClientSnr": 43.8499984741211,
                                "maxDatarate": 484,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.1500015258789,
                                "minClientSnr": 43.8499984741211,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -53.2676753997803,
                                "avgClientSnr": 42.924243927002,
                                "channelChangeCount": 0,
                                "datarate": 32,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 2.52940446489297,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.2146860047718,
                                "maxClientCount": 1,
                                "maxClientRssi": -53.0909080505371,
                                "maxClientSnr": 43.1818199157715,
                                "maxDatarate": 168,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -53.4444427490234,
                                "minClientSnr": 42.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0249996185303,
                                "avgClientSnr": 43.875,
                                "channelChangeCount": 0,
                                "datarate": 443.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 9.35509690203077,
                                "logMediaBitsPerSec": 9.71361968618455,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 12.0042970763888,
                                "maxClientCount": 1,
                                "maxClientRssi": -52,
                                "maxClientSnr": 43.9000015258789,
                                "maxDatarate": 536,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 43.8499984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0249996185303,
                                "avgClientSnr": 43.7888889312744,
                                "channelChangeCount": 0,
                                "datarate": 182,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 9.35450932459652,
                                "logMediaBitsPerSec": 1.93105263524156,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3808938675615,
                                "maxClientCount": 1,
                                "maxClientRssi": -52,
                                "maxClientSnr": 43.7999992370605,
                                "maxDatarate": 418,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 43.7777786254883,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -51.9340896606445,
                                "avgClientSnr": 43.8090896606445,
                                "channelChangeCount": 0,
                                "datarate": 210,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 9.5732680281335,
                                "logMediaBitsPerSec": 2.48885777864345,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.5093795866527,
                                "maxClientCount": 2,
                                "maxClientRssi": -51.8181800842285,
                                "maxClientSnr": 43.8181800842285,
                                "maxDatarate": 612,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 43.7999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.0499992370605,
                                "avgClientSnr": 44,
                                "channelChangeCount": 0,
                                "datarate": 281,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 9.49132757560793,
                                "logMediaBitsPerSec": 7.62630927658722,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.6156134448422,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.0499992370605,
                                "maxClientSnr": 44,
                                "maxDatarate": 710,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.0499992370605,
                                "minClientSnr": 44,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.1750011444092,
                                "avgClientSnr": 43.625,
                                "channelChangeCount": 0,
                                "datarate": 456,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 9.99228383224192,
                                "logMediaBitsPerSec": 8.19070271138015,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.3984443955518,
                                "maxClientCount": 1,
                                "maxClientRssi": -52.1500015258789,
                                "maxClientSnr": 43.75,
                                "maxDatarate": 856,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.2000007629395,
                                "minClientSnr": 43.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": -52.3596496582031,
                                "avgClientSnr": 43.7748527526855,
                                "channelChangeCount": 0,
                                "datarate": 320,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0234ce650e0137db2383",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 7.42103623646001,
                                "logMediaBitsPerSec": 9.98653688992831,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.8950267967607,
                                "maxClientCount": 2,
                                "maxClientRssi": -52.0526313781738,
                                "maxClientSnr": 44.1052627563477,
                                "maxDatarate": 717,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": -52.6666679382324,
                                "minClientSnr": 43.4444427490234,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 10,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": -48.0499992370605,
                                "avgClientSnr": 44.9500007629395,
                                "channelChangeCount": 0,
                                "datarate": 235,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 9.03592940656529,
                                "logMediaBitsPerSec": 2.44124745766105,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.7837365722895,
                                "maxClientCount": 1,
                                "maxClientRssi": -48.0499992370605,
                                "maxClientSnr": 44.9500007629395,
                                "maxDatarate": 679,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": -48.0499992370605,
                                "minClientSnr": 44.9500007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 2,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": -41.25,
                                "avgClientSnr": 51.8333320617676,
                                "channelChangeCount": 0,
                                "datarate": 107,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025f692f77913b730f93",
                                "frequencyBand": "5 GHz",
                                "interference": 9,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.25,
                                "maxClientSnr": 51.8333320617676,
                                "maxDatarate": 249,
                                "maxInterference": 9,
                                "maxTraffic": 1,
                                "maxUtilization": 10,
                                "minClientRssi": -41.25,
                                "minClientSnr": 51.8333320617676,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 10
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -49.4166679382324,
                                "avgClientSnr": 39.0277786254883,
                                "channelChangeCount": 0,
                                "datarate": 94.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -48,
                                "maxClientSnr": 44.1111106872559,
                                "maxDatarate": 363,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -50.8333320617676,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 10.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 4.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.17117132590162,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -49,
                                "avgClientSnr": 48,
                                "channelChangeCount": 0,
                                "datarate": 2.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -49,
                                "maxClientSnr": 48,
                                "maxDatarate": 23,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -49,
                                "minClientSnr": 48,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 2,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -46,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 11,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -46,
                                "maxClientSnr": 35,
                                "maxDatarate": 26,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -46,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 8.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 25,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -50,
                                "avgClientSnr": 47,
                                "channelChangeCount": 0,
                                "datarate": 6.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -50,
                                "maxClientSnr": 47,
                                "maxDatarate": 25,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -50,
                                "minClientSnr": 47,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -50.5142841339111,
                                "avgClientSnr": 44.5035724639893,
                                "channelChangeCount": 0,
                                "datarate": 833,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 13.2045360632179,
                                "logMediaBitsPerSec": 8.95797889617363,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 13.3452464716704,
                                "maxClientCount": 1,
                                "maxClientRssi": -50.4285697937012,
                                "maxClientSnr": 44.8571434020996,
                                "maxDatarate": 7699,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -50.5999984741211,
                                "minClientSnr": 44.1500015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "4",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -51.0833320617676,
                                "avgClientSnr": 44,
                                "channelChangeCount": 0,
                                "datarate": 53.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -51.0833320617676,
                                "maxClientSnr": 44,
                                "maxDatarate": 222,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -51.0833320617676,
                                "minClientSnr": 44,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 97.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 1430,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -50.7828941345215,
                                "avgClientSnr": 44.668420791626,
                                "channelChangeCount": 0,
                                "datarate": 1702,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 12.6622209319163,
                                "logMediaBitsPerSec": 10.4098714873554,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 13.0178631687663,
                                "maxClientCount": 1,
                                "maxClientRssi": -50,
                                "maxClientSnr": 47,
                                "maxDatarate": 14996,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -51.25,
                                "minClientSnr": 43.5999984741211,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "6",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 13,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -51.3333320617676,
                                "avgClientSnr": 39.0833320617676,
                                "channelChangeCount": 0,
                                "datarate": 25.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -51.3333320617676,
                                "maxClientSnr": 43.1666679382324,
                                "maxDatarate": 316,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -51.3333320617676,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": -46.5,
                                "avgClientSnr": 43,
                                "channelChangeCount": 0,
                                "datarate": 12,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025b31180fb7934fb4b3",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.35253897102768,
                                "maxClientCount": 0,
                                "maxClientRssi": -46.5,
                                "maxClientSnr": 43,
                                "maxDatarate": 25,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -46.5,
                                "minClientSnr": 43,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -43.2631568908691,
                                "avgClientSnr": 48.7368431091309,
                                "channelChangeCount": 0,
                                "datarate": 107200,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 8.96870517730713,
                                "logMediaBitsPerSec": 19.6357402801514,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 19.6395378112793,
                                "maxClientCount": 1,
                                "maxClientRssi": -43.2631568908691,
                                "maxClientSnr": 48.7368431091309,
                                "maxDatarate": 107992,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -43.2631568908691,
                                "minClientSnr": 48.7368431091309,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 14,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -43.9500007629395,
                                "avgClientSnr": 48.0499992370605,
                                "channelChangeCount": 0,
                                "datarate": 66,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 7.19556635250914,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.19818838002067,
                                "maxClientCount": 1,
                                "maxClientRssi": -42,
                                "maxClientSnr": 50,
                                "maxDatarate": 222,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -44.7000007629395,
                                "minClientSnr": 47.2999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.2000007629395,
                                "avgClientSnr": 47.7999992370605,
                                "channelChangeCount": 0,
                                "datarate": 15,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 4.84174938301277,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 5.70019318742455,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.2000007629395,
                                "maxClientSnr": 47.7999992370605,
                                "maxDatarate": 96,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -44.2000007629395,
                                "minClientSnr": 47.7999992370605,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.8500003814697,
                                "avgClientSnr": 47.1499996185303,
                                "channelChangeCount": 0,
                                "datarate": 68.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 8.36235312959598,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.46863780866911,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.7999992370605,
                                "maxClientSnr": 47.2000007629395,
                                "maxDatarate": 194,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -44.9000015258789,
                                "minClientSnr": 47.0999984741211,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.8499984741211,
                                "avgClientSnr": 47.1500015258789,
                                "channelChangeCount": 0,
                                "datarate": 48,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 8.0426930201053,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.3798020990034,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.5999984741211,
                                "maxClientSnr": 47.4000015258789,
                                "maxDatarate": 211,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.0999984741211,
                                "minClientSnr": 46.9000015258789,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.8999996185303,
                                "avgClientSnr": 47.1000003814697,
                                "channelChangeCount": 0,
                                "datarate": 87.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 6.72997589007062,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.23649270604963,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.5,
                                "maxClientSnr": 47.5,
                                "maxDatarate": 251,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.2999992370605,
                                "minClientSnr": 46.7000007629395,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.2388896942139,
                                "avgClientSnr": 46.8838386535645,
                                "channelChangeCount": 0,
                                "datarate": 87,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 8.13301128974215,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.33733411284181,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.7777786254883,
                                "maxClientSnr": 47.2222213745117,
                                "maxDatarate": 223,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.7000007629395,
                                "minClientSnr": 46.5454559326172,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 5,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.2000007629395,
                                "avgClientSnr": 47,
                                "channelChangeCount": 0,
                                "datarate": 54.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -45.2000007629395,
                                "maxClientSnr": 47,
                                "maxDatarate": 202,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.2000007629395,
                                "minClientSnr": 47,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.9500007629395,
                                "avgClientSnr": 47.1363639831543,
                                "channelChangeCount": 0,
                                "datarate": 94.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 2.14186874375349,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.43566552688576,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.9000015258789,
                                "maxClientSnr": 47.2727279663086,
                                "maxDatarate": 215,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45,
                                "minClientSnr": 47,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.7111110687256,
                                "avgClientSnr": 47.3434352874756,
                                "channelChangeCount": 0,
                                "datarate": 111,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 7.27007222813755,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 8.53879652050971,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.2000007629395,
                                "maxClientSnr": 47.9090919494629,
                                "maxDatarate": 229,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.2222213745117,
                                "minClientSnr": 46.7777786254883,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.2555561065674,
                                "avgClientSnr": 46.8535346984863,
                                "channelChangeCount": 0,
                                "datarate": 87,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 7.15623464576922,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.88931131301098,
                                "maxClientCount": 1,
                                "maxClientRssi": -45.1111106872559,
                                "maxClientSnr": 46.8888893127441,
                                "maxDatarate": 211,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.4000015258789,
                                "minClientSnr": 46.8181800842285,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 2.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 23,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -43.4593849182129,
                                "avgClientSnr": 48.8531150817871,
                                "channelChangeCount": 0,
                                "datarate": 129,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 9.89493918521896,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3081413982337,
                                "maxClientCount": 2,
                                "maxClientRssi": -36,
                                "maxClientSnr": 56,
                                "maxDatarate": 647,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.2727279663086,
                                "minClientSnr": 46.7272720336914,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 5,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.3111114501953,
                                "avgClientSnr": 47.7525253295898,
                                "channelChangeCount": 0,
                                "datarate": 127.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 9.98611387597713,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.1896818803615,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.2222213745117,
                                "maxClientSnr": 47.7777786254883,
                                "maxDatarate": 1455,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -44.4000015258789,
                                "minClientSnr": 47.7272720336914,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -44.5555572509766,
                                "avgClientSnr": 47.4444427490234,
                                "channelChangeCount": 0,
                                "datarate": 37,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 9.7115405842779,
                                "logMediaBitsPerSec": 5.50687857368386,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.83159555971486,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.5555572509766,
                                "maxClientSnr": 47.4444427490234,
                                "maxDatarate": 321,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -44.5555572509766,
                                "minClientSnr": 47.4444427490234,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.7000007629395,
                                "avgClientSnr": 47.3863639831543,
                                "channelChangeCount": 0,
                                "datarate": 25,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 2,
                                "maxClientRssi": -44.9000015258789,
                                "maxClientSnr": 47.5,
                                "maxDatarate": 152,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -46.5,
                                "minClientSnr": 47.2727279663086,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.3535346984863,
                                "avgClientSnr": 46.6464653015137,
                                "channelChangeCount": 0,
                                "datarate": 52.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 8.91883646056954,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.18214077570426,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.8888893127441,
                                "maxClientSnr": 47.1111106872559,
                                "maxDatarate": 175,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.8181800842285,
                                "minClientSnr": 46.1818199157715,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -45.3444442749023,
                                "avgClientSnr": 46.7828273773193,
                                "channelChangeCount": 0,
                                "datarate": 46.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "026370d23374b8620dde",
                                "frequencyBand": "5 GHz",
                                "interference": 24,
                                "logCloudBitsPerSec": 2.53051474578754,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.92578826815341,
                                "maxClientCount": 1,
                                "maxClientRssi": -44.8888893127441,
                                "maxClientSnr": 47.1111106872559,
                                "maxDatarate": 192,
                                "maxInterference": 24,
                                "maxTraffic": 1,
                                "maxUtilization": 25,
                                "minClientRssi": -45.7999992370605,
                                "minClientSnr": 46.4545440673828,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 25
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -54,
                                "avgClientSnr": 39.5,
                                "channelChangeCount": 0,
                                "datarate": 1,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -54,
                                "maxClientSnr": 39.5,
                                "maxDatarate": 23,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -54,
                                "minClientSnr": 39.5,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 4.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -51,
                                "avgClientSnr": 36.4166660308838,
                                "channelChangeCount": 0,
                                "datarate": 8,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -49,
                                "maxClientSnr": 40.6666679382324,
                                "maxDatarate": 50,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -53,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "2",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "4",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 7,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 4.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -52.375,
                                "avgClientSnr": 40.0476188659668,
                                "channelChangeCount": 0,
                                "datarate": 10.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.33342352483352,
                                "maxClientCount": 1,
                                "maxClientRssi": -51.25,
                                "maxClientSnr": 40.4285697937012,
                                "maxDatarate": 26,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -53.5,
                                "minClientSnr": 39.6666679382324,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -51,
                                "avgClientSnr": 41,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -51,
                                "maxClientSnr": 41,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -51,
                                "minClientSnr": 41,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -54,
                                "avgClientSnr": 39.5,
                                "channelChangeCount": 0,
                                "datarate": 3.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "027bb20c293421965639",
                                "frequencyBand": "5 GHz",
                                "interference": 6,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -54,
                                "maxClientSnr": 39.5,
                                "maxDatarate": 24,
                                "maxInterference": 6,
                                "maxTraffic": 0,
                                "maxUtilization": 6,
                                "minClientRssi": -54,
                                "minClientSnr": 39.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 6
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -65.606616973877,
                                "avgClientSnr": 31.8933820724487,
                                "channelChangeCount": 0,
                                "datarate": 27776.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 7.68382128834741,
                                "logMediaBitsPerSec": 18.8531505532839,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 18.8581578211222,
                                "maxClientCount": 1,
                                "maxClientRssi": -63,
                                "maxClientSnr": 33,
                                "maxDatarate": 79051,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.1764678955078,
                                "minClientSnr": 30.8235301971436,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 13,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -66.0588226318359,
                                "avgClientSnr": 30.9411764144897,
                                "channelChangeCount": 0,
                                "datarate": 44375.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.0963288382109,
                                "logMediaBitsPerSec": 19.6116637118534,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.6176756672552,
                                "maxClientCount": 1,
                                "maxClientRssi": -63,
                                "maxClientSnr": 33,
                                "maxDatarate": 210837,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.2352905273438,
                                "minClientSnr": 30,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 9,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -66.3062515258789,
                                "avgClientSnr": 31.2854166030884,
                                "channelChangeCount": 0,
                                "datarate": 37415.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.2545512264122,
                                "logMediaBitsPerSec": 19.448082079926,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.4565392519744,
                                "maxClientCount": 2,
                                "maxClientRssi": -64,
                                "maxClientSnr": 33.2000007629395,
                                "maxDatarate": 97771,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.5,
                                "minClientSnr": 29.6666660308838,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -67.1500015258789,
                                "avgClientSnr": 30.8500003814697,
                                "channelChangeCount": 0,
                                "datarate": 58280.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.1323708084204,
                                "logMediaBitsPerSec": 20.5745821708789,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.5785896588993,
                                "maxClientCount": 1,
                                "maxClientRssi": -67,
                                "maxClientSnr": 31,
                                "maxDatarate": 114509,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.3000030517578,
                                "minClientSnr": 30.7000007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 17,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -65.375,
                                "avgClientSnr": 32.5,
                                "channelChangeCount": 0,
                                "datarate": 48.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.98630956960864,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.37554187677487,
                                "maxClientCount": 1,
                                "maxClientRssi": -65.375,
                                "maxClientSnr": 32.5,
                                "maxDatarate": 203,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -65.375,
                                "minClientSnr": 32.5,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -67.1052627563477,
                                "avgClientSnr": 30.8947372436523,
                                "channelChangeCount": 0,
                                "datarate": 43138.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -67.1052627563477,
                                "maxClientSnr": 30.8947372436523,
                                "maxDatarate": 136135,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.1052627563477,
                                "minClientSnr": 30.8947372436523,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -66.4375,
                                "avgClientSnr": 31.5208330154419,
                                "channelChangeCount": 0,
                                "datarate": 94791,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.663296979115,
                                "logMediaBitsPerSec": 20.453099636198,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.4592562269912,
                                "maxClientCount": 2,
                                "maxClientRssi": -65.1666641235352,
                                "maxClientSnr": 32.6666679382324,
                                "maxDatarate": 151851,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.1500015258789,
                                "minClientSnr": 30.8500003814697,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 4,
                                "radioDistinctAppCount": 19,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -66.6861114501953,
                                "avgClientSnr": 30.8752527236938,
                                "channelChangeCount": 0,
                                "datarate": 42726,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.0293206905352,
                                "logMediaBitsPerSec": 19.2628940627361,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.2694209994057,
                                "maxClientCount": 1,
                                "maxClientRssi": -65,
                                "maxClientSnr": 31.3999996185303,
                                "maxDatarate": 123066,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.3000030517578,
                                "minClientSnr": 30.5454540252686,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -67.1578941345215,
                                "avgClientSnr": 30.8499994277954,
                                "channelChangeCount": 0,
                                "datarate": 116721.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.7092868520359,
                                "logMediaBitsPerSec": 20.673141922224,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.6778026436652,
                                "maxClientCount": 1,
                                "maxClientRssi": -67.1052627563477,
                                "maxClientSnr": 30.8999996185303,
                                "maxDatarate": 193771,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.2105255126953,
                                "minClientSnr": 30.7999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 15,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -67.1111145019531,
                                "avgClientSnr": 30.8888893127441,
                                "channelChangeCount": 0,
                                "datarate": 25836.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.7447460903349,
                                "logMediaBitsPerSec": 19.4063226481971,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.4209806014517,
                                "maxClientCount": 1,
                                "maxClientRssi": -67.1111145019531,
                                "maxClientSnr": 30.8888893127441,
                                "maxDatarate": 97642,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.1111145019531,
                                "minClientSnr": 30.8888893127441,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 9,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -66.5829544067383,
                                "avgClientSnr": 31.2503786087036,
                                "channelChangeCount": 0,
                                "datarate": 73364.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.0846078365774,
                                "logMediaBitsPerSec": 19.7564885215428,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.7639863005752,
                                "maxClientCount": 2,
                                "maxClientRssi": -65,
                                "maxClientSnr": 32.3333320617676,
                                "maxDatarate": 132359,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.1500015258789,
                                "minClientSnr": 30.8500003814697,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 9,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -67.5631561279297,
                                "avgClientSnr": 30.4250001907349,
                                "channelChangeCount": 0,
                                "datarate": 134520,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 14.5984342902585,
                                "logMediaBitsPerSec": 20.7736604889774,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.8039348672942,
                                "maxClientCount": 1,
                                "maxClientRssi": -67.5263137817383,
                                "maxClientSnr": 30.4500007629395,
                                "maxDatarate": 185445,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -67.5999984741211,
                                "minClientSnr": 30.3999996185303,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 19,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -68.1578941345215,
                                "avgClientSnr": 29.8499994277954,
                                "channelChangeCount": 0,
                                "datarate": 43465,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 16.4534191601968,
                                "logMediaBitsPerSec": 18.8321145007626,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.1119666046669,
                                "maxClientCount": 1,
                                "maxClientRssi": -67.9473648071289,
                                "maxClientSnr": 30.0499992370605,
                                "maxDatarate": 147368,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -68.3684234619141,
                                "minClientSnr": 29.6499996185303,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 19,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -68.684211730957,
                                "avgClientSnr": 29.2999992370605,
                                "channelChangeCount": 0,
                                "datarate": 7364,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02f4ca390940a9323d54",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.4298782348633,
                                "logMediaBitsPerSec": 9.84507846832275,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 12.9812088012695,
                                "maxClientCount": 1,
                                "maxClientRssi": -68.684211730957,
                                "maxClientSnr": 29.2999992370605,
                                "maxDatarate": 11138,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -68.684211730957,
                                "minClientSnr": 29.2999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 19,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 17.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 175,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -41.5263175964355,
                                "avgClientSnr": 55.7000007629395,
                                "channelChangeCount": 0,
                                "datarate": 175,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 8.98346200973639,
                                "logMediaBitsPerSec": 5.42238274550888,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.2904847902019,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.5263175964355,
                                "maxClientSnr": 55.7000007629395,
                                "maxDatarate": 517,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": -41.5263175964355,
                                "minClientSnr": 55.7000007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": -41.5499992370605,
                                "avgClientSnr": 55.4500007629395,
                                "channelChangeCount": 0,
                                "datarate": 450.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 9.22218802430491,
                                "logMediaBitsPerSec": 2.17348283981039,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3291086629841,
                                "maxClientCount": 1,
                                "maxClientRssi": -41.5499992370605,
                                "maxClientSnr": 55.4500007629395,
                                "maxDatarate": 2236,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": -41.5499992370605,
                                "minClientSnr": 55.4500007629395,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029c9db8ae95012bcca3",
                                "frequencyBand": "5 GHz",
                                "interference": 18,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 18,
                                "maxTraffic": 1,
                                "maxUtilization": 19,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 19
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 54.5,
                                "channelChangeCount": 0,
                                "datarate": 11.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -42,
                                "maxClientSnr": 54.5,
                                "maxDatarate": 104,
                                "maxInterference": 87,
                                "maxTraffic": 2,
                                "maxUtilization": 89,
                                "minClientRssi": -42,
                                "minClientSnr": 54.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.86153036355972,
                                "utilization": 81
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 55,
                                "channelChangeCount": 0,
                                "datarate": 21,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 75,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -42,
                                "maxClientSnr": 55,
                                "maxDatarate": 46,
                                "maxInterference": 80,
                                "maxTraffic": 2,
                                "maxUtilization": 82,
                                "minClientRssi": -42,
                                "minClientSnr": 55,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1.77881997823715,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": 54,
                                "channelChangeCount": 0,
                                "datarate": 0.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 54,
                                "maxDatarate": 9,
                                "maxInterference": 85,
                                "maxTraffic": 2,
                                "maxUtilization": 87,
                                "minClientRssi": null,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.59270548820496,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 54,
                                "channelChangeCount": 0,
                                "datarate": 18,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 73,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -42,
                                "maxClientSnr": 54,
                                "maxDatarate": 48,
                                "maxInterference": 83,
                                "maxTraffic": 2,
                                "maxUtilization": 85,
                                "minClientRssi": -42,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1.64304280281067,
                                "utilization": 75
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 36,
                                "maxInterference": 83,
                                "maxTraffic": 2,
                                "maxUtilization": 85,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1.54644805192947,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": 54.5,
                                "channelChangeCount": 0,
                                "datarate": 10.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 54.5,
                                "maxDatarate": 43,
                                "maxInterference": 86,
                                "maxTraffic": 2,
                                "maxUtilization": 88,
                                "minClientRssi": null,
                                "minClientSnr": 54.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1.87762606143951,
                                "utilization": 82
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -49,
                                "avgClientSnr": 54.25,
                                "channelChangeCount": 0,
                                "datarate": 12,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -49,
                                "maxClientSnr": 57,
                                "maxDatarate": 41,
                                "maxInterference": 86,
                                "maxTraffic": 2,
                                "maxUtilization": 88,
                                "minClientRssi": -49,
                                "minClientSnr": 51.5,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.8953310251236,
                                "utilization": 82
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 52.75,
                                "channelChangeCount": 0,
                                "datarate": 13.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -42,
                                "maxClientSnr": 53.5,
                                "maxDatarate": 41,
                                "maxInterference": 84,
                                "maxTraffic": 2,
                                "maxUtilization": 85,
                                "minClientRssi": -42,
                                "minClientSnr": 52,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "3",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "4",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1.63470160961151,
                                "utilization": 80
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 87,
                                "maxTraffic": 2,
                                "maxUtilization": 89,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1.80364453792572,
                                "utilization": 81
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 2,
                                "maxUtilization": 88,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1.76323163509369,
                                "utilization": 83
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 87,
                                "maxTraffic": 2,
                                "maxUtilization": 89,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1.80026990175247,
                                "utilization": 83
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1.74374431371689,
                                "utilization": 83
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 53.75,
                                "channelChangeCount": 0,
                                "datarate": 7,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -42,
                                "maxClientSnr": 53.75,
                                "maxDatarate": 104,
                                "maxInterference": 88,
                                "maxTraffic": 3,
                                "maxUtilization": 90,
                                "minClientRssi": -42,
                                "minClientSnr": 53.75,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1.90121275186539,
                                "utilization": 81
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": -42,
                                "avgClientSnr": 54.1666660308838,
                                "channelChangeCount": 0,
                                "datarate": 30,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -42,
                                "maxClientSnr": 54.3333320617676,
                                "maxDatarate": 96,
                                "maxInterference": 85,
                                "maxTraffic": 2,
                                "maxUtilization": 87,
                                "minClientRssi": -42,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "3",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.83108109235764,
                                "utilization": 79
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": 54,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 54,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": 54,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1.95999997854233,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 18,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 82,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 51,
                                "maxInterference": 88,
                                "maxTraffic": 2,
                                "maxUtilization": 90,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "2",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1.68296229839325,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 84,
                                "maxTraffic": 2,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.51430296897888,
                                "utilization": 81
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 13,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02414345ff4e1105beb6",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 45,
                                "maxInterference": 85,
                                "maxTraffic": 2,
                                "maxUtilization": 87,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.44594597816467,
                                "utilization": 80
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -63.0526313781738,
                                "avgClientSnr": 28.9473686218262,
                                "channelChangeCount": 0,
                                "datarate": 54518,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -63.0526313781738,
                                "maxClientSnr": 28.9473686218262,
                                "maxDatarate": 166985,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -63.0526313781738,
                                "minClientSnr": 28.9473686218262,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -63,
                                "avgClientSnr": 29,
                                "channelChangeCount": 0,
                                "datarate": 82174.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.0871655853573,
                                "logMediaBitsPerSec": 20.6275052131564,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.6324190540286,
                                "maxClientCount": 1,
                                "maxClientRssi": -63,
                                "maxClientSnr": 29,
                                "maxDatarate": 147340,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -63,
                                "minClientSnr": 29,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 15,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -63.1764717102051,
                                "avgClientSnr": 28.8235301971436,
                                "channelChangeCount": 0,
                                "datarate": 37997,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 11.1622793383164,
                                "logMediaBitsPerSec": 18.9637937152117,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 18.9732617753677,
                                "maxClientCount": 1,
                                "maxClientRssi": -63.1764717102051,
                                "maxClientSnr": 28.8235301971436,
                                "maxDatarate": 132502,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -63.1764717102051,
                                "minClientSnr": 28.8235301971436,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -59.8333320617676,
                                "avgClientSnr": 32.2857131958008,
                                "channelChangeCount": 0,
                                "datarate": 374337.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 18.9508313993694,
                                "logMediaBitsPerSec": 7.65302817795554,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 21.8647979699219,
                                "maxClientCount": 1,
                                "maxClientRssi": -59.8333320617676,
                                "maxClientSnr": 32.2857131958008,
                                "maxDatarate": 3189975,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -59.8333320617676,
                                "minClientSnr": 32.2857131958008,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -62.1666679382324,
                                "avgClientSnr": 28.75,
                                "channelChangeCount": 0,
                                "datarate": 41370,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.93561666937837,
                                "logMediaBitsPerSec": 9.3373540703966,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 14.8894337122851,
                                "maxClientCount": 1,
                                "maxClientRssi": -60.3333320617676,
                                "maxClientSnr": 29.5,
                                "maxDatarate": 269704,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -64,
                                "minClientSnr": 28,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 5,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -63,
                                "avgClientSnr": 28,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02c0b83e8912c387f9b4",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -63,
                                "maxClientSnr": 28,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -63,
                                "minClientSnr": 28,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 74,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 1,
                                "maxUtilization": 78,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 75
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 79,
                                "maxTraffic": 1,
                                "maxUtilization": 80,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 78
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 80,
                                "maxTraffic": 1,
                                "maxUtilization": 81,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 1,
                                "maxUtilization": 84,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 78
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 78,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 1,
                                "maxUtilization": 84,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 79
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 1,
                                "maxUtilization": 84,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 80
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 78,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 1,
                                "maxUtilization": 83,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 79
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 1,
                                "maxUtilization": 83,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 78
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 83,
                                "maxTraffic": 1,
                                "maxUtilization": 84,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 80
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 79,
                                "maxTraffic": 1,
                                "maxUtilization": 80,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 78
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 80,
                                "maxTraffic": 1,
                                "maxUtilization": 81,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 78,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 81,
                                "maxTraffic": 1,
                                "maxUtilization": 82,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 79
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 80,
                                "maxTraffic": 1,
                                "maxUtilization": 81,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 81,
                                "maxTraffic": 1,
                                "maxUtilization": 82,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 78
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 79,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 1,
                                "maxUtilization": 83,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 80
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 80,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 82,
                                "maxTraffic": 1,
                                "maxUtilization": 83,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 81
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 78,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 81,
                                "maxTraffic": 1,
                                "maxUtilization": 82,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 79
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 6,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0224a607e01cf727c1e9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 76,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 33,
                                "maxInterference": 81,
                                "maxTraffic": 1,
                                "maxUtilization": 82,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.735294342041,
                                "avgClientSnr": 35.9313716888428,
                                "channelChangeCount": 0,
                                "datarate": 31495.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 8.33580724981063,
                                "logMediaBitsPerSec": 19.0918871949656,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.095230771142,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.470588684082,
                                "maxClientSnr": 36.529411315918,
                                "maxDatarate": 92381,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -56,
                                "minClientSnr": 35.3333320617676,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -50.7272720336914,
                                "avgClientSnr": 41.2727279663086,
                                "channelChangeCount": 0,
                                "datarate": 400887.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 22.3837073101463,
                                "logMediaBitsPerSec": 18.4005569188613,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 22.5763266009262,
                                "maxClientCount": 1,
                                "maxClientRssi": -50.7272720336914,
                                "maxClientSnr": 41.2727279663086,
                                "maxDatarate": 4667408,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -50.7272720336914,
                                "minClientSnr": 41.2727279663086,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": 26,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 4.61169719813881,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.9703422283034,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 26,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": 26,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -54.8944454193115,
                                "avgClientSnr": 36.9646453857422,
                                "channelChangeCount": 0,
                                "datarate": 58410.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 9.09934191808072,
                                "logMediaBitsPerSec": 19.9321189043037,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.9344115350704,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.8888893127441,
                                "maxClientSnr": 37.1111106872559,
                                "maxDatarate": 167559,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -54.9000015258789,
                                "minClientSnr": 36.8181800842285,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.8999996185303,
                                "avgClientSnr": 36.1000003814697,
                                "channelChangeCount": 0,
                                "datarate": 48274,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 11.3757995727948,
                                "logMediaBitsPerSec": 20.4817614164659,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.486172380762,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.7999992370605,
                                "maxClientSnr": 36.2000007629395,
                                "maxDatarate": 87858,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -56,
                                "minClientSnr": 36,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 16,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.8249988555908,
                                "avgClientSnr": 36.1750011444092,
                                "channelChangeCount": 0,
                                "datarate": 95762.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 12.1588689429788,
                                "logMediaBitsPerSec": 20.4525889027505,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.458955085957,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.7999992370605,
                                "maxClientSnr": 36.2000007629395,
                                "maxDatarate": 145629,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.8499984741211,
                                "minClientSnr": 36.1500015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 15,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.8888893127441,
                                "avgClientSnr": 36,
                                "channelChangeCount": 0,
                                "datarate": 29115,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.8888893127441,
                                "maxClientSnr": 36,
                                "maxDatarate": 129934,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.8888893127441,
                                "minClientSnr": 36,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.1764717102051,
                                "avgClientSnr": 36.8235282897949,
                                "channelChangeCount": 0,
                                "datarate": 52043.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 8.36145654826931,
                                "logMediaBitsPerSec": 19.2916701862696,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.2946990511838,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.1764717102051,
                                "maxClientSnr": 36.8235282897949,
                                "maxDatarate": 137259,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.1764717102051,
                                "minClientSnr": 36.8235282897949,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.544116973877,
                                "avgClientSnr": 36.3725490570068,
                                "channelChangeCount": 0,
                                "datarate": 51020,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 11.2130826665287,
                                "logMediaBitsPerSec": 19.0563266514875,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.0650050718956,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.5,
                                "maxClientSnr": 36.4117660522461,
                                "maxDatarate": 111692,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.5882339477539,
                                "minClientSnr": 36.3333320617676,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.514705657959,
                                "avgClientSnr": 36.4019603729248,
                                "channelChangeCount": 0,
                                "datarate": 49789,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 10.9795869552654,
                                "logMediaBitsPerSec": 19.2509807821915,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.2580093506654,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.5,
                                "maxClientSnr": 36.470588684082,
                                "maxDatarate": 116344,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.529411315918,
                                "minClientSnr": 36.3333320617676,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -55.5,
                                "avgClientSnr": 35.3333320617676,
                                "channelChangeCount": 0,
                                "datarate": 572,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d020acdbd6ef349585",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.5,
                                "maxClientSnr": 35.3333320617676,
                                "maxDatarate": 8579,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -55.5,
                                "minClientSnr": 35.3333320617676,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -72.514705657959,
                                "avgClientSnr": 18.0686273574829,
                                "channelChangeCount": 0,
                                "datarate": 28025.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 7.54744274642644,
                                "logMediaBitsPerSec": 8.10198119178889,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 11.0533161428349,
                                "maxClientCount": 1,
                                "maxClientRssi": -72.5,
                                "maxClientSnr": 18.470588684082,
                                "maxDatarate": 123715,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -72.529411315918,
                                "minClientSnr": 17.6666660308838,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 9,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -71.0882339477539,
                                "avgClientSnr": 19.0784320831299,
                                "channelChangeCount": 0,
                                "datarate": 30920,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 11.4486192181489,
                                "logMediaBitsPerSec": 19.1864991526573,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.2051739720228,
                                "maxClientCount": 1,
                                "maxClientRssi": -70,
                                "maxClientSnr": 19.3333339691162,
                                "maxDatarate": 102812,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -72.1764678955078,
                                "minClientSnr": 18.8235301971436,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -72.3888854980469,
                                "avgClientSnr": 18.5263156890869,
                                "channelChangeCount": 0,
                                "datarate": 34428.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -72.3888854980469,
                                "maxClientSnr": 18.5263156890869,
                                "maxDatarate": 137535,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -72.3888854980469,
                                "minClientSnr": 18.5263156890869,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -72.0789489746094,
                                "avgClientSnr": 18.8999996185303,
                                "channelChangeCount": 0,
                                "datarate": 71413,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 12.3040519151334,
                                "logMediaBitsPerSec": 20.6268530552295,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 20.6342430248039,
                                "maxClientCount": 1,
                                "maxClientRssi": -72,
                                "maxClientSnr": 19,
                                "maxDatarate": 205474,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -72.1578979492188,
                                "minClientSnr": 18.7999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 16,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -72.1666679382324,
                                "avgClientSnr": 18.8333330154419,
                                "channelChangeCount": 0,
                                "datarate": 56409,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 11.2093181951258,
                                "logMediaBitsPerSec": 19.6924321990094,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.6978566921307,
                                "maxClientCount": 1,
                                "maxClientRssi": -72,
                                "maxClientSnr": 19,
                                "maxDatarate": 156005,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -72.3333358764648,
                                "minClientSnr": 18.6666660308838,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 8,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -71.2727279663086,
                                "avgClientSnr": 19.7272720336914,
                                "channelChangeCount": 0,
                                "datarate": 33692.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -71.2727279663086,
                                "maxClientSnr": 19.7272720336914,
                                "maxDatarate": 167010,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -71.2727279663086,
                                "minClientSnr": 19.7272720336914,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -71.1111145019531,
                                "avgClientSnr": 19.8888893127441,
                                "channelChangeCount": 0,
                                "datarate": 21762,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 11.1165061066275,
                                "logMediaBitsPerSec": 19.1759477177702,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 19.1836038022903,
                                "maxClientCount": 1,
                                "maxClientRssi": -71.1111145019531,
                                "maxClientSnr": 19.8888893127441,
                                "maxDatarate": 140374,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": -71.1111145019531,
                                "minClientSnr": 19.8888893127441,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 7,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02e3fd41e54fa625aaf2",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 24,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 23,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": -53.5,
                                "avgClientSnr": 38.5,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -53,
                                "maxClientSnr": 39,
                                "maxDatarate": 24,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": -54,
                                "minClientSnr": 38,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:42:A0",
                                "aesApName": "SJC14-TME-AP10",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03edf5816b465cf2f36a",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0244f9ddc42c5b2664ce",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 3
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.5540943145752,
                                "avgClientSnr": 37.4722213745117,
                                "channelChangeCount": 0,
                                "datarate": 201.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 7.39346194482806,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.2725311350047,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.0526313781738,
                                "maxClientSnr": 38,
                                "maxDatarate": 258,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.0555572509766,
                                "minClientSnr": 36.9444427490234,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.1310157775879,
                                "avgClientSnr": 37.9444446563721,
                                "channelChangeCount": 0,
                                "datarate": 175.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 3.2210177538289,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.139062421161,
                                "maxClientCount": 1,
                                "maxClientRssi": -53.9090919494629,
                                "maxClientSnr": 38.1666679382324,
                                "maxDatarate": 264,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.3529396057129,
                                "minClientSnr": 37.7222213745117,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55.1052627563477,
                                "avgClientSnr": 37,
                                "channelChangeCount": 0,
                                "datarate": 206,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.48342180252075,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 9.161865234375,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.1052627563477,
                                "maxClientSnr": 37,
                                "maxDatarate": 303,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.1052627563477,
                                "minClientSnr": 37,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55.1929817199707,
                                "avgClientSnr": 37.0750007629395,
                                "channelChangeCount": 0,
                                "datarate": 1923,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 7.64526626419656,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 15.3340411228289,
                                "maxClientCount": 2,
                                "maxClientRssi": -54.2631568908691,
                                "maxClientSnr": 37.7999992370605,
                                "maxDatarate": 25990,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -57.6666679382324,
                                "minClientSnr": 35.2000007629395,
                                "numClientsSuccessFailOnboarding": "2",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 3,
                                "radioDistinctAppCount": 16,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.4263153076172,
                                "avgClientSnr": 37.625,
                                "channelChangeCount": 0,
                                "datarate": 196.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 3.36363840701094,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.0090196696157,
                                "maxClientCount": 1,
                                "maxClientRssi": -53.7999992370605,
                                "maxClientSnr": 38.2000007629395,
                                "maxDatarate": 263,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.0526313781738,
                                "minClientSnr": 37.0499992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.8590908050537,
                                "avgClientSnr": 37.1409091949463,
                                "channelChangeCount": 0,
                                "datarate": 182,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.66309163078301,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.1633704161721,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.8181800842285,
                                "maxClientSnr": 37.1818199157715,
                                "maxDatarate": 407,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.9000015258789,
                                "minClientSnr": 37.0999984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.7000007629395,
                                "avgClientSnr": 37.2999992370605,
                                "channelChangeCount": 0,
                                "datarate": 217,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 5.89648699998594,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.198406952554,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.6500015258789,
                                "maxClientSnr": 37.3499984741211,
                                "maxDatarate": 352,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.75,
                                "minClientSnr": 37.25,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.5263156890869,
                                "avgClientSnr": 37.5500011444092,
                                "channelChangeCount": 0,
                                "datarate": 233.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 8.83485720531451,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.7715406172409,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.4210510253906,
                                "maxClientSnr": 37.6500015258789,
                                "maxDatarate": 819,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.6315803527832,
                                "minClientSnr": 37.4500007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.6357154846191,
                                "avgClientSnr": 37.4499988555908,
                                "channelChangeCount": 0,
                                "datarate": 207,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 7.37047445672582,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3047544855484,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.5714302062988,
                                "maxClientSnr": 37.5999984741211,
                                "maxDatarate": 417,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.7000007629395,
                                "minClientSnr": 37.2999992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.5551948547363,
                                "avgClientSnr": 37.4448051452637,
                                "channelChangeCount": 0,
                                "datarate": 139,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 3.21757540894699,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.21280725559211,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.1818199157715,
                                "maxClientSnr": 37.8181800842285,
                                "maxDatarate": 368,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.9285697937012,
                                "minClientSnr": 37.0714302062988,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55.25,
                                "avgClientSnr": 36.75,
                                "channelChangeCount": 0,
                                "datarate": 234,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.10219159373228,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.1973589651946,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.2000007629395,
                                "maxClientSnr": 36.7999992370605,
                                "maxDatarate": 477,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.2999992370605,
                                "minClientSnr": 36.7000007629395,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.9736843109131,
                                "avgClientSnr": 37.0565776824951,
                                "channelChangeCount": 0,
                                "datarate": 219.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 5.42820187358114,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.1565326690928,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.7368431091309,
                                "maxClientSnr": 37.2631568908691,
                                "maxDatarate": 325,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.2105255126953,
                                "minClientSnr": 36.8499984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55.2368412017822,
                                "avgClientSnr": 36.875,
                                "channelChangeCount": 0,
                                "datarate": 220,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 7.9569602280307,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.3946243829624,
                                "maxClientCount": 1,
                                "maxClientRssi": -55.2105255126953,
                                "maxClientSnr": 36.9000015258789,
                                "maxDatarate": 346,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.2631568908691,
                                "minClientSnr": 36.8499984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.6770324707031,
                                "avgClientSnr": 37.4416656494141,
                                "channelChangeCount": 0,
                                "datarate": 194,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 14.8140254201352,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 14.8409028525888,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.2631568908691,
                                "maxClientSnr": 37.7999992370605,
                                "maxDatarate": 391,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.0909080505371,
                                "minClientSnr": 37.0833320617676,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55,
                                "avgClientSnr": 37.1000003814697,
                                "channelChangeCount": 0,
                                "datarate": 280.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 12.8467203840136,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 13.0468869457208,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.9473686218262,
                                "maxClientSnr": 37.1500015258789,
                                "maxDatarate": 1110,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.0526313781738,
                                "minClientSnr": 37.0499992370605,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -55.4545459747314,
                                "avgClientSnr": 36.7333335876465,
                                "channelChangeCount": 0,
                                "datarate": 145.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 5.34324999505426,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 9.21068795681041,
                                "maxClientCount": 1,
                                "maxClientRssi": -55,
                                "maxClientSnr": 37.1333351135254,
                                "maxDatarate": 262,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -55.9090919494629,
                                "minClientSnr": 36.3333320617676,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 6,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.6750011444092,
                                "avgClientSnr": 37.3249988555908,
                                "channelChangeCount": 0,
                                "datarate": 225.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 8.70605498374986,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.6011312839774,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.4500007629395,
                                "maxClientSnr": 37.5499992370605,
                                "maxDatarate": 362,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.9000015258789,
                                "minClientSnr": 37.0999984741211,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 12,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "6C:8D:77:2E:04:20",
                                "aesApName": "SJC14-F1-9164-3",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03bd6249ab882c666ba1",
                                "avgClientRssi": -54.3999996185303,
                                "avgClientSnr": 37.6000003814697,
                                "channelChangeCount": 0,
                                "datarate": 218.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eab9429b6050ed20ed",
                                "frequencyBand": "6 GHz",
                                "interference": 4,
                                "logCloudBitsPerSec": 6.09329722427632,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 10.176865761546,
                                "maxClientCount": 1,
                                "maxClientRssi": -54.2000007629395,
                                "maxClientSnr": 37.7999992370605,
                                "maxDatarate": 292,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": -54.5999984741211,
                                "minClientSnr": 37.4000015258789,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 11,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -40.5714302062988,
                                "avgClientSnr": 58.4285697937012,
                                "channelChangeCount": 0,
                                "datarate": 17,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 74,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -40.5714302062988,
                                "maxClientSnr": 58.4285697937012,
                                "maxDatarate": 106,
                                "maxInterference": 81,
                                "maxTraffic": 3,
                                "maxUtilization": 82,
                                "minClientRssi": -40.5714302062988,
                                "minClientSnr": 58.4285697937012,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.86309522390366,
                                "utilization": 75
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 81,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 3,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.71764707565308,
                                "utilization": 83
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 67,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 2,
                                "maxUtilization": 72,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1.90151512622833,
                                "utilization": 69
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 69,
                                "maxTraffic": 3,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.80474674701691,
                                "utilization": 68
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 71,
                                "maxTraffic": 4,
                                "maxUtilization": 72,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 2.04365074634552,
                                "utilization": 69
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -38.8125,
                                "avgClientSnr": 60.1875,
                                "channelChangeCount": 0,
                                "datarate": 50.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -38.8125,
                                "maxClientSnr": 60.1875,
                                "maxDatarate": 302,
                                "maxInterference": 71,
                                "maxTraffic": 4,
                                "maxUtilization": 72,
                                "minClientRssi": -38.8125,
                                "minClientSnr": 60.1875,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 2.14539706707001,
                                "utilization": 68
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 69,
                                "maxTraffic": 3,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 2.22910439968109,
                                "utilization": 68
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -40.4285697937012,
                                "avgClientSnr": 58.5714302062988,
                                "channelChangeCount": 0,
                                "datarate": 18.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 68,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -40.4285697937012,
                                "maxClientSnr": 58.5714302062988,
                                "maxDatarate": 109,
                                "maxInterference": 73,
                                "maxTraffic": 3,
                                "maxUtilization": 74,
                                "minClientRssi": -40.4285697937012,
                                "minClientSnr": 58.5714302062988,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.77941179275513,
                                "utilization": 69
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 67,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 2,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1.8926767706871,
                                "utilization": 69
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 69,
                                "maxTraffic": 3,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 2.00769233703613,
                                "utilization": 68
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 66,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 2,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1.80547213554382,
                                "utilization": 68
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 3,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 2.09209084510803,
                                "utilization": 66
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 65,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 3,
                                "maxUtilization": 71,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1.984375,
                                "utilization": 67
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 3,
                                "maxUtilization": 72,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 2.10266220569611,
                                "utilization": 66
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 60,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 3,
                                "maxUtilization": 65,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.73404037952423,
                                "utilization": 62
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": -41,
                                "avgClientSnr": 58,
                                "channelChangeCount": 0,
                                "datarate": 9.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 55,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": -41,
                                "maxClientSnr": 58,
                                "maxDatarate": 152,
                                "maxInterference": 64,
                                "maxTraffic": 3,
                                "maxUtilization": 66,
                                "minClientRssi": -41,
                                "minClientSnr": 58,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.90579611063004,
                                "utilization": 57
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 73,
                                "maxTraffic": 3,
                                "maxUtilization": 75,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 2.10975611209869,
                                "utilization": 66
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:65:A0",
                                "aesApName": "SJC14-F1-9166-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e234e91e4c64ea6ed4",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0219f1e3ff6e838e8a1e",
                                "frequencyBand": "2.4 GHz",
                                "interference": 74,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 79,
                                "maxTraffic": 3,
                                "maxUtilization": 81,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 2.02222228050232,
                                "utilization": 76
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": -49,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 9,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 6.60644236778806,
                                "maxClientCount": 0,
                                "maxClientRssi": -49,
                                "maxClientSnr": 35,
                                "maxDatarate": 51,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": -49,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "2",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 2,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 1,
                                "maxUtilization": 87,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 87,
                                "maxTraffic": 1,
                                "maxUtilization": 88,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 28,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "2",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 0.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 16,
                                "maxInterference": 84,
                                "maxTraffic": 1,
                                "maxUtilization": 85,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "1",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 2,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 31,
                                "maxInterference": 84,
                                "maxTraffic": 1,
                                "maxUtilization": 85,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 4,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 31,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "1",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 1.5,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 27,
                                "maxInterference": 85,
                                "maxTraffic": 2,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1.10000002384186,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 1,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 27,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "2",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "2",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 88,
                                "maxTraffic": 1,
                                "maxUtilization": 88,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0.940677970647812,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 1,
                                "maxUtilization": 87,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": 35,
                                "channelChangeCount": 0,
                                "datarate": 7,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": 35,
                                "maxDatarate": 54,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": 35,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "1",
                                "numFailOnboarding": "1",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "2",
                                "numSuccessRoamIn": "1",
                                "numSuccessRoamOut": "3",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 4,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 83,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 42,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 84
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:5B:20",
                                "aesApName": "SJC14-TME-AP7",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03724daeae79e361df2b",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "023c0ef6ec59dd011180",
                                "frequencyBand": "2.4 GHz",
                                "interference": 84,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 85,
                                "maxTraffic": 1,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 85
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": -48.125,
                                "avgClientSnr": 43.875,
                                "channelChangeCount": 0,
                                "datarate": 32,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 7.80038208791279,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 7.92042569617798,
                                "maxClientCount": 1,
                                "maxClientRssi": -47.75,
                                "maxClientSnr": 44.25,
                                "maxDatarate": 138,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": -48.5,
                                "minClientSnr": 43.5,
                                "numClientsSuccessFailOnboarding": "1",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "1",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 2,
                                "radioDistinctAppCount": 5,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021aab64473013fd9947",
                                "frequencyBand": "5 GHz",
                                "interference": 3,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 1,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 4
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02d4fe57d39e1b022cdc",
                                "frequencyBand": "2.4 GHz",
                                "interference": 99,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 99,
                                "maxTraffic": 1,
                                "maxUtilization": 100,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1,
                                "utilization": 100
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "022d9c0c750aee102006",
                                "frequencyBand": "6 GHz",
                                "interference": 5,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 5
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.33636367321014,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1.89921849966049,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.59182691574097,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 94,
                                "maxTraffic": 2,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1.84973120689392,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 94,
                                "maxTraffic": 2,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1.75409954786301,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 2,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 2,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.9268091917038,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 2,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1.93103450536728,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 85,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 2,
                                "utilization": 87
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": -37.2857131958008,
                                "avgClientSnr": 60.7142868041992,
                                "channelChangeCount": 0,
                                "datarate": 11,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": -37.2857131958008,
                                "maxClientSnr": 60.7142868041992,
                                "maxDatarate": 125,
                                "maxInterference": 94,
                                "maxTraffic": 2,
                                "maxUtilization": 96,
                                "minClientRssi": -37.2857131958008,
                                "minClientSnr": 60.7142868041992,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1.85657250881195,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 3,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 1,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 125,
                                "maxInterference": 93,
                                "maxTraffic": 3,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 1,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 2.00609755516052,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 3,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 2.060431599617,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.88690477609634,
                                "utilization": 88
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 87,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 3,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.87459856271744,
                                "utilization": 89
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 3,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 2.0302197933197,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 2,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 2,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FD:68:80",
                                "aesApName": "SJC14-F1-9166-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03e39acd1d1ba33983e9",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02eb9b418501d5148129",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 94,
                                "maxTraffic": 2,
                                "maxUtilization": 96,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.63414633274078,
                                "utilization": 90
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:06:50",
                                "aesApName": "SJC14-F1-9136-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0320fc013cb53b7fc359",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0229c61d56d8a02e3109",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 65,
                                "maxTraffic": 0,
                                "maxUtilization": 65,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 6,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 64
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 65,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 69,
                                "maxTraffic": 0,
                                "maxUtilization": 69,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 18,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 65
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 12,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 16,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 10,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 67,
                                "maxTraffic": 0,
                                "maxUtilization": 67,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 64
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 4,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 63,
                                "maxTraffic": 0,
                                "maxUtilization": 63,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 16,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 63,
                                "maxTraffic": 0,
                                "maxUtilization": 63,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 16,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 63,
                                "maxTraffic": 0,
                                "maxUtilization": 63,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 10,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 63,
                                "maxTraffic": 0,
                                "maxUtilization": 63,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 12,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 63,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 64,
                                "maxTraffic": 0,
                                "maxUtilization": 64,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 6,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 63
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 67,
                                "maxTraffic": 0,
                                "maxUtilization": 67,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 12,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 64
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 68,
                                "maxTraffic": 0,
                                "maxUtilization": 68,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 64
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "A4:53:0E:7D:35:80",
                                "aesApName": "SJC14-TME-AP6",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0350ee1b25998c0c6953",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02ddeae4ead2533bc6d2",
                                "frequencyBand": "2.4 GHz",
                                "interference": 64,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 68,
                                "maxTraffic": 0,
                                "maxUtilization": 68,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 14,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 64
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:B7:40",
                                "aesApName": "SJC14-TME-AP9",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036074198833ae5240e5",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "025d598e7fb4121debe9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 77,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 77,
                                "maxTraffic": 0,
                                "maxUtilization": 77,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 77
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 1.82078468799591,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 1.9146341085434,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 91,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 1.65769231319427,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 3,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 1.74122804403305,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 2,
                                "maxUtilization": 95,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 2,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 91,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 93,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 1.57139778137207,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 91,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 1.75772386789322,
                                "utilization": 93
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 92,
                                "maxTraffic": 2,
                                "maxUtilization": 94,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 1.75005280971527,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 91,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 1.74238991737366,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 1.75621122121811,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 1.68124294281006,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 1.67692309617996,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 1.78375715017319,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 1.88090765476227,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 2,
                                "utilization": 92
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 89,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 90,
                                "maxTraffic": 2,
                                "maxUtilization": 92,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 1.73330241441727,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 90,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 91,
                                "maxTraffic": 2,
                                "maxUtilization": 93,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 1.84617918729782,
                                "utilization": 91
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:AC:C0",
                                "aesApName": "SJC14-F1-9164-1",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03c8b76fb778d9693b1f",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021ba11c49bae8bc455f",
                                "frequencyBand": "2.4 GHz",
                                "interference": 88,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 89,
                                "maxTraffic": 2,
                                "maxUtilization": 91,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 1.58717948198318,
                                "utilization": 90
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:F9:20:FE:9D:40",
                                "aesApName": "SJC14-F1-9164-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "0322d3ce2aa48adc7845",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "029a186e706dade508a1",
                                "frequencyBand": "2.4 GHz",
                                "interference": 86,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 86,
                                "maxTraffic": 0,
                                "maxUtilization": 86,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 86
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 1
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 1
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 2,
                                "maxTraffic": 0,
                                "maxUtilization": 2,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 1
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 1
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 5,
                                "maxTraffic": 0,
                                "maxUtilization": 5,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 1,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 1
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 4,
                                "maxTraffic": 0,
                                "maxUtilization": 4,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021a31ccf887db5f0b89",
                                "frequencyBand": "6 GHz",
                                "interference": 2,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 3,
                                "maxTraffic": 0,
                                "maxUtilization": 3,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 3,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 2
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "AC:4A:56:AE:82:A0",
                                "aesApName": "SJC14-TME-AP4-old",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "032132c2ffd4f3047f85",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0254028b5cb11358b05f",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "0273d5c09d608601dff9",
                                "frequencyBand": "2.4 GHz",
                                "interference": 70,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 70,
                                "maxTraffic": 0,
                                "maxUtilization": 70,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 0,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 70
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "10:B3:C6:24:9E:60",
                                "aesApName": "SJC14-TME-AP11",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "03cd20d25cff1ea97f0c",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "021c8c9b74ee483e7cbc",
                                "frequencyBand": "5 GHz",
                                "interference": 22,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 22,
                                "maxTraffic": 0,
                                "maxUtilization": 22,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 1,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 22
                            },
                            
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T14:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 0,
                                "logMediaBitsPerSec": 0,
                                "logSocialBitsPerSec": 0,
                                "logTotalBitsPerSec": 0,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T23:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T22:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T21:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T20:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T19:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T18:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T17:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T16:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T15:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T13:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T12:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T11:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T10:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T09:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T08:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T07:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                            {
                                "__typename": "Radio36DailyWithGroupType",
                                "aesApGroup": null,
                                "aesApMac": "68:7D:B4:5F:6E:C0",
                                "aesApName": "SJC14-F1-9136-2",
                                "aesWlcId": "172.20.224.55",
                                "apEntityId": "036546c69d7bffa2b536",
                                "avgClientRssi": null,
                                "avgClientSnr": null,
                                "channelChangeCount": 0,
                                "datarate": 0,
                                "deltaQueueDiscarded": "0",
                                "deltaQueueFailed": "0",
                                "entityId": "02dc0be30158d752b002",
                                "frequencyBand": "5 GHz",
                                "interference": 0,
                                "logCloudBitsPerSec": 1,
                                "logMediaBitsPerSec": 1,
                                "logSocialBitsPerSec": 1,
                                "logTotalBitsPerSec": 1,
                                "maxClientCount": 0,
                                "maxClientRssi": null,
                                "maxClientSnr": null,
                                "maxDatarate": 0,
                                "maxInterference": 0,
                                "maxTraffic": 0,
                                "maxUtilization": 0,
                                "minClientRssi": null,
                                "minClientSnr": null,
                                "numClientsSuccessFailOnboarding": "0",
                                "numClientsSuccessFailRoamIn": "0",
                                "numFailOnboarding": "0",
                                "numFailRoamIn": "0",
                                "numFailRoamOut": "0",
                                "numSuccessOnboarding": "0",
                                "numSuccessRoamIn": "0",
                                "numSuccessRoamOut": "0",
                                "packetFailureRate": 0,
                                "radioClientCount": 0,
                                "radioDistinctAppCount": 0,
                                "radioProtocol": null,
                                "radioResetCount": 0,
                                "siteHierarchyArray": [
                                    "San Jose",
                                    "Building 14",
                                    "Floor1"
                                ],
                                "siteId": "d927416c-fc2f-4344-bb6c-cd07c658c665",
                                "slot": 2,
                                "timestamp": "2022-12-05T06:30:00",
                                "traffic": 0,
                                "utilization": 0
                            },
                        ],
                        "impactedEntityHeatmapData": null
                    }
                ]
            }
        }
    }
});