define({
    "AuditPolicy_Template": [  
      {  
         "id":"06b356f5-088c-45b4-baf9-6d0829acf739",
         "instanceUuid":"06b356f5-088c-45b4-baf9-6d0829acf739",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"5ff8af2f-3165-468e-bb64-87bc7aa440b9",
            "name":"Employees-BYOD",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048995,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"0d3b17e1-8d0f-4d24-bc71-e15a9fdbadae",
         "instanceUuid":"0d3b17e1-8d0f-4d24-bc71-e15a9fdbadae",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"cf04b930-ed4d-44ae-ac53-8d097ed564de",
            "name":"HR-Development_Servers",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048995,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"91cd2eee-9630-4d2f-8565-9e4a5efa13e5",
         "instanceUuid":"91cd2eee-9630-4d2f-8565-9e4a5efa13e5",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"d89e0eba-3b77-4603-b57b-ca18b3089950",
            "name":"HR-BYOD",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048995,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"f6fc0fe9-8fb1-4fce-8401-6384f1596432",
         "instanceUuid":"f6fc0fe9-8fb1-4fce-8401-6384f1596432",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"c9a6d440-8cdb-48ed-b5d3-93d5281f0696",
            "name":"Guests-Employees",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048995,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"99ccb06b-3f8e-48d0-90a1-c9f91320124a",
         "instanceUuid":"99ccb06b-3f8e-48d0-90a1-c9f91320124a",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"097f142e-574d-4bbf-8c55-fccb43794dbe",
            "name":"Development_Servers-HR",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048994,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"9851c582-db3d-4bb2-948f-692c367289ff",
         "instanceUuid":"9851c582-db3d-4bb2-948f-692c367289ff",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"8b473d7e-61ae-4b1c-8fd3-b186abf53b55",
            "name":"Employees-HR",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048994,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"0970e938-ce5e-40bd-a779-4e2065eb9c27",
         "instanceUuid":"0970e938-ce5e-40bd-a779-4e2065eb9c27",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"55d9dabc-f9f9-4153-add0-4f5841304e90",
            "name":"Employees-Guests",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048994,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"29f1b249-a512-4a80-9f2b-5fd7d35564bb",
         "instanceUuid":"29f1b249-a512-4a80-9f2b-5fd7d35564bb",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"94fcdbcc-0409-49b8-9a2c-3e5f4fa2db94",
            "name":"Employees-Auditors",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048994,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"6b9a3d4d-be61-41bc-be34-33fad6f6e819",
         "instanceUuid":"6b9a3d4d-be61-41bc-be34-33fad6f6e819",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"a49604d2-b231-47a6-860f-0b46f83be4aa",
            "name":"Developers-HR",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048993,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"5c6403c3-0ddc-4fa6-8f63-72bd6c919f73",
         "instanceUuid":"5c6403c3-0ddc-4fa6-8f63-72bd6c919f73",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"506d5d77-b8d5-499d-ab41-638bc17eadba",
            "name":"Developers-BYOD",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048993,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"4b62a390-7753-4c75-9da5-f6f7539837f2",
         "instanceUuid":"4b62a390-7753-4c75-9da5-f6f7539837f2",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"0e45aabc-d8f8-4ab2-adcf-eb7136a814fb",
            "name":"Development_Servers-BYOD",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048993,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"19841566-3178-4759-b770-97a7d74e87e0",
         "instanceUuid":"19841566-3178-4759-b770-97a7d74e87e0",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"24f97216-0052-404e-b277-81c1a51d2aaf",
            "name":"CompanyB-CompanyA",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048993,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"7585a96c-c359-4b02-be21-178503045050",
         "instanceUuid":"7585a96c-c359-4b02-be21-178503045050",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"aae3d9a7-6a2c-43f0-8f32-ba6661fd3cd8",
            "name":"CompanyA-CompanyB",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048992,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"bfca5644-40c8-4a75-8f25-e51c4ac9503b",
         "instanceUuid":"bfca5644-40c8-4a75-8f25-e51c4ac9503b",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"3dedf9c8-cbbf-49d9-a8bd-4a87a589ce51",
            "name":"BYOD-Employees",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048988,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"19be6363-4291-4795-ab95-64fefb135671",
         "instanceUuid":"19be6363-4291-4795-ab95-64fefb135671",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"02406818-b7cc-4619-8e77-46f7aa90b87f",
            "name":"BYOD-Developers",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048988,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"d73d12a5-643d-408f-bcf1-faa19e698c42",
         "instanceUuid":"d73d12a5-643d-408f-bcf1-faa19e698c42",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"c6d7b86b-0e81-4d8a-8429-d103f1280ad0",
            "name":"Camera-HVAC",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048988,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"992f155a-f46e-454b-8ce1-66fb305179ae",
         "instanceUuid":"992f155a-f46e-454b-8ce1-66fb305179ae",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"be3068fe-46e3-4df2-a290-28cfe1a33a29",
            "name":"BYOD-Development_Servers",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048988,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      },
      {  
         "id":"8096a304-d800-4f8b-8ca7-0b7bdd3e15c1",
         "instanceUuid":"8096a304-d800-4f8b-8ca7-0b7bdd3e15c1",
         "tag":"Policy V2",
         "auditDescription":"Deployed Policy in APIC-EM. Policy configuration failed to push to devices, returned COA status is NO_REQUEST_AVAILABLE",
         "auditId":"30973b23-33b7-4f68-829c-c9deab035a9a",
         "auditParameters":{  
            "COA status":"NO_REQUEST_AVAILABLE",
            "policyscope":"TRUSTSEC",
            "policytype":"Access",
            "policyuuid":"e270dc7f-d9af-484c-aa59-b56bad8589ae",
            "name":"Auditors-BYOD",
            "description":"Policy Created",
            "operation":"Brownfield Policy",
            "results":"true",
            "version":"1",
            "status":"DEPLOYED"
         },
         "auditParentId":"",
         "auditRequestor":"",
         "createdDateTime":1513906048987,
         "derivedParentId":"",
         "deviceName":"",
         "deviceIP":"",
         "hasChildren":false,
         "hasParent":true,
         "siteName":""
      }
   ]
});
