define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "ba35d956-5643-4d61-b3f4-a35fde2ad90a",
    "name": "fabric_reachability_control_border_underlay_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.28.110.31",
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "HIGH",
    "summary": "Fabric Edge DeviceIPPlaceHolder Lost Connectivity to the Co-located Fabric Border and Control Plane 192.168.130.1 in the Physical Network",
    "groupId": "fabric_reachability_grouping",
    "groupName": "Fabric Reachability Failure",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "172.28.110.38_172.25.33.68_MapServerBorderUnderlay",
    "timestamp": 1588975677977,
    "description": "There is a connectivity failure between the Fabric Edge DeviceNamePlaceHolder to Co-located Fabric Border and Control Plane border_DeviceNamePlaceHolder in the Physical Network. This can prevent many of the services from functioning correctly.",
    "suggestions": [
      {
        "message": "Check the route from Fabric Node to Co-located Fabric Border and Control Plane",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip route to 192.168.130.1",
            "command": "show ip route 192.168.130.1",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip cef route to 192.168.130.1",
            "command": "show ip cef 192.168.130.1",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify IGP adjacencies from this node to upstream switches",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify IGP adjacencies",
            "command": "show isis neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify IGP adjacencies",
            "command": "show ospf neighbor",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify IGP adjacencies",
            "command": "sh eigrp address-family ipv4 neighbors",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "destIp",
        "value": "172.25.33.68"
      },
      {
        "key": "managementIpAddr",
        "value": "172.28.110.31"
      },
      {
        "key": "_name",
        "value": "fabric_reachability_control_border_underlay_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.28.110.31"
      },
      {
        "key": "uuid",
        "value": "5a0f2137-b415-4247-80e4-28cd7006c52c"
      },
      {
        "key": "sourceRole",
        "value": "Fabric Edge"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "2"
      },
      {
        "key": "areaName",
        "value": "Global"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "172.28.110.38_172.25.33.68_MapServerBorderUnderlay"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.28.110.31"
      },
      {
        "key": "sessionType",
        "value": "MapServerBorderUnderlay"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst38xx stack-able ethernet switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "5a0f2137-b415-4247-80e4-28cd7006c52c"
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "16.12.3sprd1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "destMgmtIp",
        "value": "172.25.33.65"
      },
      {
        "key": "nwDeviceName",
        "value": "stack-3850"
      },
      {
        "key": "_eventTime",
        "value": "1588975064528"
      },
      {
        "key": "_mostRecent",
        "value": "1588975677977"
      },
      {
        "key": "entityId",
        "value": "172.28.110.31"
      },
      {
        "key": "eventUniqueId",
        "value": "172.28.110.31"
      },
      {
        "key": "vrf",
        "value": ""
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "sessionIdentifier",
        "value": "172.28.110.38_172.25.33.68_MapServerBorderUnderlay"
      },
      {
        "key": "buildingId",
        "value": "91288ca2-968a-4794-b53a-ee64c9b4464e"
      },
      {
        "key": "buildingName",
        "value": "Global/RAJ-SJC20-3K"
      },
      {
        "key": "areaId",
        "value": "Global"
      },
      {
        "key": "sourceIp",
        "value": "172.28.110.38"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/RAJ-SJC20-3K"
      },
      {
        "key": "deviceModel",
        "value": "FOC2123L2DG"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2020-05-08T21:57:44.528+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})