define({

    "Template" :
    {
        "version":"1.0",
        "response":{
           "detailList":[
              {
                 "itemType":"ALL",
                 "timestamp":"2022-03-24T12:29:00.000+0000",
                 "count":192,
                 "nestedItems":[
                    {
                       "itemType":"15.4 W PoE",
                       "timestamp":"2022-03-24T12:29:00.000+0000",
                       "count":0,
                       "nestedItems":[
                        {
                           "itemType":"Free Ports",
                           "timestamp":"2022-03-24T12:29:00.000+0000",
                           "count":0
                        },
                        {
                           "itemType":"Used Ports",
                           "timestamp":"2022-03-24T12:29:00.000+0000",
                           "count":0
                        },
                        {
                            "itemType":"Port Availability (%)",
                            "timestamp":"2022-03-24T12:29:00.000+0000",
                            "count":0
                         }
                      ]
                   },
                   {
                    "itemType":"30 W PoE",
                    "timestamp":"2022-03-24T12:29:00.000+0000",
                    "count":72,
                    "nestedItems":[
                       {
                          "itemType":"Free Ports",
                          "timestamp":"2022-03-24T12:29:00.000+0000",
                          "count":72
                       },
                       {
                        "itemType":"Used Ports",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":0
                     },
                     {
                        "itemType":"Port Availability (%)",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":100
                     }
                    ]
                },
                {
                   "itemType":"60 W PoE",
                   "timestamp":"2022-03-24T12:29:00.000+0000",
                   "count":120,
                   "nestedItems":[
                      {
                         "itemType":"Free Ports",
                         "timestamp":"2022-03-24T12:29:00.000+0000",
                         "count":118
                      },
                      {
                        "itemType":"Used Ports",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":2
                     },
                     {
                        "itemType":"Port Availability (%)",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":98
                     }
                    ]
                },
                {
                   "itemType":"90 W PoE",
                   "timestamp":"2022-03-24T12:29:00.000+0000",
                   "count":0,
                   "nestedItems":[
                      {
                         "itemType":"Free Ports",
                         "timestamp":"2022-03-24T12:29:00.000+0000",
                         "count":0
                      },
                      {
                        "itemType":"Used Ports",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":0
                     },
                     {
                        "itemType":"Port Availability (%)",
                        "timestamp":"2022-03-24T12:29:00.000+0000",
                        "count":0
                     }
                  ]
               }
            ]
         }
      ]
   }
    }
})