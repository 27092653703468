define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/AccessPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ContractPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/UserDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/CopyPoliciesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/AuditPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/NetworkApplicationCategoryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/TCP_UDP_Template_1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/QueuingProfileTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyPreviewTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/GBACISEPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/DNACGBACPolicies',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/SimISESyncher',
], function (SimLokiDatabaseActions, UtilityFunctions, SimDefaultDataGenerator, ScalableGroupIseTemplate,
    AccessPolicyTemplate, ContractPolicyTemplate, UserDeviceTemplate, CopyPoliciesTemplate, AuditPolicyTemplate, NetworkApplicationCategoryTemplate,
    TCP_UDP_Template_1,QueuingProfileTemplate,ApplicationTemplate,ApplicationPolicyTemplate,SimulationUtility,DefaultConfig,ApplicationPolicyPreviewTemplate,
    GBACISEPolicyTemplate, DNACGBACPolicies, ISESyncher) {


    function updatePolicy(siteList,namespace) {

         var siteIdList =[];
         var deviceIdList=[];

         for(var i=0;i<siteList.length;i++) {

             siteIdList.push(siteList[i].id);
         }
         var deviceList =   SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId': { '$containsAny' :  siteIdList}});
         for(var i=0;i<deviceList.length;i++) {

             deviceIdList.push(deviceList[i].id);
         }

         var deviceConfigList =  SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'deviceId': { '$containsAny' :  deviceIdList}});

         for(var i=0;i<deviceConfigList.length;i++) {
              deviceConfigList[i].namespace =namespace;
              deviceConfigList[i].cfsVersion =1;


         }
        // updating the status.
         SimLokiDatabaseActions.update( 'DeviceConfigStatus', deviceConfigList);

     return deviceList.length;


    }


    function getCount(namespace,status) {
      var data=  SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {'$and': [ {
                                                                  'namespace': namespace
                                                                },
                                                                 {
                                                                   'status': status}]});
     return data.length;
    }

    function generateNumericId(size) {
        var id = "";
        var validSet = "0123456789";
        for (var i = 0; i < size; i++) {
            id += validSet.charAt(Math.floor(Math.random() * validSet.length));
        }
        return id;
    }

    function getColorPattern(value) {
        if(value=="Deny IP"){
            return "color:#C9706B,pattern:P0";
        } else if(value=="Permit IP"){
            return "color:#8AA949,pattern:P0";
        } else if(value=="PCI_Allowed_Traffic"){
            return "color:#5586BB,pattern:P0";
        } else{
            return undefined;
        }
    }

    return {
        init: function () {
            var i;
            /*for (i = 0; i < ScalableGroupIseTemplate.ScalableGroupIsel_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroupIse_Template[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.insert('scalablegroup', tJson);
            }*/
            for (i = 0; i < ContractPolicyTemplate.ContractPolicy_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(ContractPolicyTemplate.ContractPolicy_Template[i]));
                var jsonObj = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                tJson.dateDeployed = UtilityFunctions.getTimeStamp();
                var displayName = UtilityFunctions.getRandomIntForRangeValues(1188202, 1188299);
                tJson.displayName = displayName.toString()
                tJson.instanceId = displayName;
                tJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                tJson.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                tJson.clause[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                tJson.clause[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                // if(tJson.contractClassifier[0] != undefined && jsonObj.length){
                //     tJson.contractClassifier[0].access = "DENY";
                //     tJson.contractClassifier[0].scalableGroup.idRef=jsonObj[i].id;
                // }
                SimLokiDatabaseActions.insert('access-contract', tJson);
            }

            for (i = 0; i < ContractPolicyTemplate.IPAccessContract.length; i++) {
                var tJson = JSON.parse(JSON.stringify(ContractPolicyTemplate.IPAccessContract[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                tJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                tJson.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.clause[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                tJson.clause[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();

                SimLokiDatabaseActions.insert('ip-access-contract', tJson);
            }

            for (i = 0; i < CopyPoliciesTemplate.Application_set.length; i++) {
                var tJson = JSON.parse(JSON.stringify(CopyPoliciesTemplate.Application_set[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.insert('application-set', tJson);
            }

            /*for (i = 0; i < UserDeviceTemplate.UserDevice_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(UserDeviceTemplate.UserDevice_Template[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.insert('USER_DEVICE', tJson);
            }*/

            for (i = 0; i < CopyPoliciesTemplate.PolicySummary_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(CopyPoliciesTemplate.PolicySummary_Template[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.insert('policy-summary', tJson);
            }

            for (i = 0; i < NetworkApplicationCategoryTemplate.NetworkApplicationCategory_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(NetworkApplicationCategoryTemplate.NetworkApplicationCategory_Template[i]));
                SimLokiDatabaseActions.insert('networkapplicationcategory', tJson);
            }
            var accessContract = SimLokiDatabaseActions.getAll('access-contract');
            var scalablegroup = SimLokiDatabaseActions.getAll('scalablegroup');

            for (i = 0; i < AccessPolicyTemplate.AccessPolicy_Template_1_3_1.length; i++) {
                var tJson = JSON.parse(JSON.stringify(AccessPolicyTemplate.AccessPolicy_Template_1_3_1[i]));
                // var groupName= tJson.name;
                // var name = groupName.split("-");
                // tJson.createTime = UtilityFunctions.getTimeStamp();
                // tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();

                // if(tJson.consumer.scalableGroup[0].idRef != undefined){
                //    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'name' : name[0]});
                //    if(recordObj.length ){
                //        tJson.consumer.scalableGroup[0].idRef = recordObj[0].id;
                //    }
                // }
                // if(tJson.contract.idRef != undefined){
                //     tJson.contract.idRef = accessContract[0].id;
                // }
                // if(tJson.producer.scalableGroup[0].idRef != undefined){
                //      var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'name' : name[1]});
                //      if(recordObj.length ){
                //          tJson.producer.scalableGroup[0].idRef = recordObj[0].id;
                //      }

                // }
                SimLokiDatabaseActions.insert('access-policy', tJson);
            }

            //  Adding Queuing Profile
             var tJson =  QueuingProfileTemplate. Queuing_Profile_Template;
             SimLokiDatabaseActions.insert('application-contract', tJson);

           // Adding applications

             for (var i = 0; i < ApplicationTemplate.Application_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(ApplicationTemplate.Application_Template[i]));
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.insert('application-policy', tJson);
            }

            //Adding default policy

             var input={createList:[]};

             var global = ApplicationTemplate.Application_Global_Template;
             var policyName= global.policyScope;
             var advancedPolicyScope = global.advancedPolicyScope;

             var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': "AU1"});
             global.advancedPolicyScope=advancedPolicyScope;
             advancedPolicyScope.advancedPolicyScopeElement[0].groupId=[record[0].id];
             for (var i = 0; i < ApplicationTemplate.Application_Template.length; i++) {
                var tJson = JSON.parse(JSON.stringify(ApplicationTemplate.Application_Template[i]));
                tJson.name= policyName+"_"+tJson.producer.displayName;
                tJson.policyScope =   global.policyScope;
                tJson.advancedPolicyScope= advancedPolicyScope
                input.createList.push(tJson);
            }
            input.createList.push(global);
            this.CUDApplicationPolicy("post",input);

            //to move the DNAC-ISE communication related to a seperate file in next release..
            //Synching DNAC Access Contracts with ISE
            let jsonString = ScalableGroupIseTemplate["ACL_Template1.3.1"];
            let cJsonString = ContractPolicyTemplate["ContractPolicy_Template"];
            if(Array.isArray(jsonString)){
                for(let item of jsonString) {
                    for(let cItem of cJsonString) {
                        if(item.name == cItem.name){
                            item.id = cItem.id;
                            let splitted = item.aces.split('@');
                            item.aces = splitted[0] + "@" + cItem.id.slice(-8);
                            break;
                        }
                    }
                }
            }
            const iseACLResponse = fetch("https://171.71.186.80:7027/user/simulation/securityGroupACL/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonString)
            });

            //Synching DNAC Security Groups with ISE
            let dnacScalableGroupsList = SimLokiDatabaseActions.getAll('scalablegroup');
            let jsonStringSP = [];
            let val = undefined;
            dnacScalableGroupsList.map(function(item) {
                val = JSON.parse(JSON.stringify(ScalableGroupIseTemplate["SecurityGroups1.3.1"][0]));
                val.id = item.id;
                val.name = item.name;
                val.description = item.description == undefined ? "" : item.description;
                let values = item['securityGroupTag'].split("0x");
                    let text = "0000";
                    text = text.slice(values[1].length);
                    let representation = parseInt(values[1]);
                    let numericSplit = values[0].split("/");
                val.value = parseInt(numericSplit[0]);
                    let hexRep = values[1];
                    //values[0].toString(16);
                val.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                val.generationId = Math.floor(Math.random(10)*10);
                jsonStringSP.push(val);
            });
            const iseSecurityGroupsResponse = fetch("https://171.71.186.80:7027/user/simulation/securityGroups/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonStringSP)
            });
            console.log("Response for dnac-ise : ACL List synch ",iseACLResponse);
            console.log("Response for dnac-ise : Security Groups List synch ",iseSecurityGroupsResponse);

    /////////////////////////////////////////////////////////////////////////////////////////////////
    // ******************** Constructing Dynamic Payload for ISE Matrix Page ************************
    /////////////////////////////////////////////////////////////////////////////////////////////////
    // *** XY Payload represents the total count of scalable groups for row and column Headers
    // *** Horizontal Headers Payload (HHP) - Column Header Values are got in 11 values per call
    // *** Vertical Headers Payload (VHP) - Row Header Values are got in 6 values per call
    // *** Content payload (CP) - Matrix Cells with actual scalable group values mapped to each other
    // * Matrix Cell is constructed in Row Retrieval Fashion in arrays with settings and values 
    // * Values for "CP" are based on (x & y) Start Indices and Count values present in URL Headers
    // * Below code will support for DNAC default scalable groups of length 26 scalable up to 30
    /////////////////////////////////////////////////////////////////////////////////////////////////


    // Note: GBAC Matrix Page payloads are named in the order of url calls observed in network console
    // Note: CP represents Content Payload in the comments
    
    // File Naming Order as follows:-
    // Horizontal Headers : 
    // 1- HorizontalResponse_Initial 
    // 2- HorizontalResponse_NextSet 
    // 3- HorizontalResponse_HScroll

    // Vertical Headers : 
    // 1- VerticalResponse_Initial 
    // 2- VerticalResponse_NextSet 
    // 3- VerticalResponse_VScrollSet1 
    // 4- VerticalResponse_VScrollSet2 
    // 5- VerticalResponse_Empty

    // Initial Page View - "CP" : 
    // 1- ContentResponse_Initial 
    // 2- ContentResponse_NextSet1 
    // 3- ContentResponse_NextSet2 
    // 4- ContentResponse_NextSet3

    // Horizontal Scrolls towards Right Side Page View - "CP" : 
    // Note: For each HScroll specific set of payloads will be required - watch url response order for clarification
    // 1- ContentResponse_HScrollSet1 
    // 2- ContentResponse_HScrollSet2 
    // 3- ContentResponse_HScrollSet3 
    // 4- ContentResponse_HScrollSet4
    
    // Vertical Scrolls towards Bottom Page View - "CP" : 
    // Note: For each VScroll specific set of payloads will be required - watch url response order for clarification
    // 1- ContentResponse_VScrollSet1 
    // 2- ContentResponse_VScrollSet2 
    // 3- ContentResponse_VScrollSet3 
    // 4- ContentResponse_VScrollSet4 
    // 5- ContentResponse_Empty

            //********** Constructing XY Response Payload for total size

            let xyResponsePayload = {
                "xyResponse": {
                    "srcCount": dnacScalableGroupsList.length,
                    "dstCount": dnacScalableGroupsList.length
                }
            }

            console.log("xyresponse payload : ",xyResponsePayload);


            console.log("dnacScalableGroupsList : ",dnacScalableGroupsList);

            let headerContentItem = GBACISEPolicyTemplate.headerTemplate;
            let headerHorizontalPayload1 = {
                horizontalResponse : {
                    items: []
                }
            };
            let headerHorizontalPayload2 = JSON.parse(JSON.stringify(headerHorizontalPayload1));
            let headerHorizontalPayload3 = JSON.parse(JSON.stringify(headerHorizontalPayload1));
            let value1 = value2 = value3 = value4 = value5 = undefined;

            //********** Constructing horizontal response initial, nextset and hscroll for at most 30 headers

            for(let i=0; i<dnacScalableGroupsList.length; i++){
                let temp = JSON.parse(JSON.stringify(headerContentItem));
                temp.id = dnacScalableGroupsList[i].id;
                temp.name = dnacScalableGroupsList[i].name;
                temp.description = dnacScalableGroupsList[i].description;

                //********** Converting numeric to hexadecimal representation
                let values = dnacScalableGroupsList[i].securityGroupTag.split("0x");
                let text = "0000";
                text = text.slice(values[1].length);
                let representation = parseInt(values[1]);
                let numericSplit = values[0].split("/");
                let hexRep = values[1];
                let valueFlag = 0;
                if(i>=0 && i<11){
                    value1 = temp;
                    value1.value = parseInt(numericSplit[0]);
                    value1.numericRepresentation = numericSplit[0]+"/"+text+hexRep;    
                    valueFlag = 1;
                } else if(i>10 && i<22){
                    value2 = temp;
                    value2.value = parseInt(numericSplit[0]);
                    value2.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 2;
                } else if(i>21 && i<dnacScalableGroupsList.length){
                    value3 = temp;
                    value3.value = parseInt(numericSplit[0]);
                    value3.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 3;
                }

                let value = valueFlag==1 ? value1 
                                : valueFlag==2 ? value2 
                                                   :value3;
                if(valueFlag ==1) {
                    headerHorizontalPayload1.horizontalResponse.items.push(value);    
                } else if(valueFlag ==2) {
                    headerHorizontalPayload2.horizontalResponse.items.push(value);    
                } else if(valueFlag ==3) {
                    headerHorizontalPayload3.horizontalResponse.items.push(value);    
                }
                
            }
            // console.log("header payload initial : ",headerHorizontalPayload1);
            // console.log("header payload nextset : ",headerHorizontalPayload2);
            // console.log("header payload hscroll : ",headerHorizontalPayload3);

            let headerVerticalPayload1 = {
                verticalResponse : {
                    items: []
                }
            };
            let headerVerticalPayload2 = JSON.parse(JSON.stringify(headerVerticalPayload1));
            let headerVerticalPayload3 = JSON.parse(JSON.stringify(headerVerticalPayload1));
            let headerVerticalPayload4 = JSON.parse(JSON.stringify(headerVerticalPayload1));
            let headerVerticalPayload5 = JSON.parse(JSON.stringify(headerVerticalPayload1));
            value1 = value2 = value3 = value4 = value5 = undefined;

            //********** Constructing vertical response initial, nextset and vscroll for at most 30 headers

            for(let i=0; i<dnacScalableGroupsList.length; i++){
                let temp = JSON.parse(JSON.stringify(headerContentItem));
                temp.id = dnacScalableGroupsList[i].id;
                temp.name = dnacScalableGroupsList[i].name;
                temp.description = dnacScalableGroupsList[i].description;

                //********** Converting numeric to hexadecimal representation
                let values = dnacScalableGroupsList[i].securityGroupTag.split("0x");
                let text = "0000";
                text = text.slice(values[1].length);
                let representation = parseInt(values[1]);
                let numericSplit = values[0].split("/");
                let hexRep = values[1];
                let valueFlag = 0;
                if(i>=0 && i<6){
                    value1 = temp;
                    value1.value = parseInt(numericSplit[0]);
                    value1.numericRepresentation = numericSplit[0]+"/"+text+hexRep;    
                    valueFlag = 1;
                } else if(i>5 && i<12){
                    value2 = temp;
                    value2.value = parseInt(numericSplit[0]);
                    value2.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 2;
                } else if(i>11 && i<18){
                    value3 = temp;
                    value3.value = parseInt(numericSplit[0]);
                    value3.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 3;
                } else if(i>17 && i<24){
                    value4 = temp;
                    value4.value = parseInt(numericSplit[0]);
                    value4.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 4;
                } else if(i>23 && i<30){
                    value5 = temp;
                    value5.value = parseInt(numericSplit[0]);
                    value5.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    valueFlag = 5;
                }
                
                let value = valueFlag==1 ? value1 
                                : valueFlag==2 ? value2 
                                    : valueFlag==3 ? value3 
                                        : valueFlag==4 ? value4 
														    :value5;
                if(valueFlag ==1) {
                    headerVerticalPayload1.verticalResponse.items.push(value);    
                } else if(valueFlag ==2) {
                    headerVerticalPayload2.verticalResponse.items.push(value);    
                } else if(valueFlag ==3) {
                    headerVerticalPayload3.verticalResponse.items.push(value);    
                } else if(valueFlag ==4) {
					headerVerticalPayload4.verticalResponse.items.push(value);    
				} else if(valueFlag ==5) {
					headerVerticalPayload5.verticalResponse.items.push(value);    
				}
                
            }
            // console.log("header payload initial : ",headerVerticalPayload1);
            // console.log("header payload nextset : ",headerVerticalPayload2);
            // console.log("header payload vscroll1 : ",headerVerticalPayload3);
            // console.log("header payload vscroll2 : ",headerVerticalPayload4);
            // console.log("header payload vscroll3 : ",headerVerticalPayload5);


            dnacScalableGroups = dnacScalableGroupsList;
            let gbacPoliciesResponse = DNACGBACPolicies.GBACPolicies;
            let gbacPolicies = gbacPoliciesResponse.response[0].acaGBPSummary;
            let contentItem = GBACISEPolicyTemplate.contentTemplate;
            let contentPayload1 = {
                settings: {},
                items: [
                ]
            }
            let contentPayload2 = JSON.parse(JSON.stringify(contentPayload1));
            let contentPayload3 = JSON.parse(JSON.stringify(contentPayload1));
            let contentPayload4 = JSON.parse(JSON.stringify(contentPayload1));

            // console.log("gbacPolicies : ",gbacPolicies);
            // console.log("contentItem : ",contentItem);
            let l = 0;

            //********** Constructing content response initial
            for(let i=0; i<dnacScalableGroups.length; i++) {
                let items = [];
                //"Developers to Guests" ---> mapped to initial content response
                let trArray1 = JSON.parse(JSON.stringify(items));
                let trArray2 = JSON.parse(JSON.stringify(items));
                let trArray3 = JSON.parse(JSON.stringify(items));
                let trArray4 = JSON.parse(JSON.stringify(items));
                let trArray5 = undefined;
                let trArray6 = undefined;
                let trArray7 = undefined;
                let trArray8 = undefined;

            //********** 6 which is the difference between 5 & 12 depicts 
            //** the limit of row values viewable on the gabc policies page at any instant
            //********** 11 is the difference between the j & k iterators' values which denotes 
                //** column headers supported in the gbac policies matrix page view
                let value = value2 = undefined;

                for(let j=11, k=0; j<22 && k<11; j++, k++){
                    value = JSON.parse(JSON.stringify(contentItem));
                    value2 = JSON.parse(JSON.stringify(contentItem));
                    value.sourceSgtName = dnacScalableGroups[i].name;
                    value.destinationSgtName = dnacScalableGroups[j].name;
                    value.sourceSgtId = dnacScalableGroups[i].id;
                    value.destinationSgtId = dnacScalableGroups[j].id;
                    value2.sourceSgtName = dnacScalableGroups[i].name;
                    value2.destinationSgtName = dnacScalableGroups[k].name;
                    value2.sourceSgtId = dnacScalableGroups[i].id;
                    value2.destinationSgtId = dnacScalableGroups[k].id;

                    //********** Check for the efficient code optimization for similar 'for' loops below
                    for(let x=0; x<gbacPolicies.length; x++){
                        // console.log("checking " + gbacPolicies[k] + " with " + dnacScalableGroups[i].name + " and "+ dnacScalableGroups[j].name);
                        if(gbacPolicies[x].producerId == dnacScalableGroups[i].id
                            && gbacPolicies[x].consumerId == dnacScalableGroups[j].id){
                                value.status = "ENABLED";
                                value.sgaclNameList = gbacPolicies[x].contractName;
                                value.cellContent = "MatrixCellContent";
                                value.type = "MatrixCell";
                                // value.colorPattern = getColorPattern(value.sgaclNameList);
                                if(value.sgaclNameList=="Deny IP"){
                                    value.colorPattern = "color:#C9706B,pattern:P0";
                                } else if(value.sgaclNameList=="Permit IP"){
                                    value.colorPattern = "color:#8AA949,pattern:P0";
                                } else if(value.sgaclNameList=="PCI_Allowed_Traffic"){
                                    value.colorPattern = "color:#5586BB,pattern:P0";
                                }
                                break;
                        }
                    }
                    for(let x=0; x<gbacPolicies.length; x++){
                        if(gbacPolicies[x].producerId == dnacScalableGroups[i].id
                                && gbacPolicies[x].consumerId == dnacScalableGroups[k].id){
                                    value2.status = "ENABLED";
                                    value2.sgaclNameList = gbacPolicies[x].contractName;
                                    value2.cellContent = "MatrixCellContent";
                                    value2.type = "MatrixCell";
                                    // value2.colorPattern = getColorPattern(value2.sgaclNameList);
                                    if(value2.sgaclNameList=="Deny IP"){
                                        value2.colorPattern = "color:#C9706B,pattern:P0";
                                    } else if(value2.sgaclNameList=="Permit IP"){
                                        value2.colorPattern = "color:#8AA949,pattern:P0";
                                    } else if(value2.sgaclNameList=="PCI_Allowed_Traffic"){
                                        value2.colorPattern = "color:#5586BB,pattern:P0";
                                    }
                                    break;
                            }
                    }
                    if(i<6) {
                        trArray2.push(value);
                        trArray4.push(value2);
                    } else if(i>5 && i<12) {
                        trArray1.push(value);
                        trArray3.push(value2);
                    } else if(i>11 && i<18) {
                        // rest of the content payload construction comes here
                        // trArray5.push(value);
                        // trArray7.push(value2);
                    } else if(i>17 && i<24) {
                        // rest of the content payload construction comes here
                        // trArray6.push(value);
                        // trArray8.push(value2);
                    }
                    // console.log("Item constructed : ",value);
                }
                // console.log(i + "trArray1 : " + trArray1);
                // console.log(i + "trArray2 : " + trArray2);
                // console.log(i + "trArray3 : " + trArray3);
                // console.log(i + "trArray4 : " + trArray4);
                if(i>5 && i<12){
                    if(trArray1.length>0){
                        let trItem = JSON.parse(JSON.stringify({
                            tr:trArray1,
                            rowId: l,
                            allowMonitoring: false,
                            allowMultipleSgacls: false,
                            showSGTNumbers: false
                        }));
                        contentPayload1.items.push(trItem);
                    }
                    if(trArray3.length>0){
                        let trItem = JSON.parse(JSON.stringify({
                            tr:trArray3,
                            rowId: l,
                            allowMonitoring: false,
                            allowMultipleSgacls: false,
                            showSGTNumbers: false
                        }));
                        contentPayload3.items.push(trItem);
                    }
                    l++;
                }
                else if(i<6) {
                    if(trArray2.length>0){
                        let trItem = JSON.parse(JSON.stringify({
                            tr:trArray2,
                            rowId: l,
                            allowMonitoring: false,
                            allowMultipleSgacls: false,
                            showSGTNumbers: false
                        }));
                        contentPayload2.items.push(trItem);
                    }
                    if(trArray4.length>0){
                        let trItem = JSON.parse(JSON.stringify({
                            tr:trArray4,
                            rowId: l,
                            allowMonitoring: false,
                            allowMultipleSgacls: false,
                            showSGTNumbers: false
                        }));
                        contentPayload4.items.push(trItem);
                    }
                    l++;
                }
                else if(i>11 && i<18) {
                    // rest of the content payload construction comes here
                } else if(i>17 && i<24) {
                    // rest of the content payload construction comes here
                } else if(i>23 && i<30) {
                    // rest of the content payload construction comes here
                }
            }
            contentPayload1.settings = JSON.parse(JSON.stringify(GBACISEPolicyTemplate.contentSettings));
            contentPayload2.settings = JSON.parse(JSON.stringify(GBACISEPolicyTemplate.contentSettings));
            contentPayload3.settings = JSON.parse(JSON.stringify(GBACISEPolicyTemplate.contentSettings));
            contentPayload4.settings = JSON.parse(JSON.stringify(GBACISEPolicyTemplate.contentSettings));
            let contentResponsePayload1 = { contentResponse : contentPayload1 };
            let contentResponsePayload2 = { contentResponse : contentPayload2 };
            let contentResponsePayload3 = { contentResponse : contentPayload3 };
            let contentResponsePayload4 = { contentResponse : contentPayload4 };
            console.log("content payload 1 : ",contentResponsePayload1);
            console.log("content payload 2 : ",contentResponsePayload2);
            console.log("content payload 3 : ",contentResponsePayload3);
            console.log("content payload 4 : ",contentResponsePayload4);

            // Sending gbac matrices payload from DNAC to ISE

            const iseXYResponse = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'XYTotalCountsResponse'
                },
                body: JSON.stringify(xyResponsePayload)
            });
            console.log("Response for XY Payload Sync ",iseXYResponse);
            
            const iseVerticalResponse1 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'VerticalResponse_NextSet'
                },
                body: JSON.stringify(headerVerticalPayload1)
            });
            console.log("Response for Vertical Payload Sync 1 ",iseVerticalResponse1);
            
            const iseVerticalResponse2 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'VerticalResponse_Initial'
                },
                body: JSON.stringify(headerVerticalPayload2)
            });
            console.log("Response for Vertical Payload Sync 2 ",iseVerticalResponse2);
			
            const iseVerticalResponse3 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'VerticalResponse_VScrollSet2'
                },
                body: JSON.stringify(headerVerticalPayload3)
            });
            console.log("Response for Vertical Payload Sync 3 ",iseVerticalResponse3);
			
            const iseVerticalResponse4 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'VerticalResponse_VScrollSet1'
                },
                body: JSON.stringify(headerVerticalPayload4)
            });
            console.log("Response for Vertical Payload Sync 4 ",iseVerticalResponse4);
			
            const iseVerticalResponse5 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'VerticalResponse_VScrollSet3'
                },
                body: JSON.stringify(headerVerticalPayload5)
            });
            console.log("Response for Vertical Payload Sync 5 ",iseVerticalResponse5);
			
            const iseHorizontalResponse1 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'HorizontalResponse_NextSet'
                },
                body: JSON.stringify(headerHorizontalPayload1)
            });
            console.log("Response for Horizontal Payload Sync 1 ",iseHorizontalResponse1);
			
            const iseHorizontalResponse2 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'HorizontalResponse_Initial'
                },
                body: JSON.stringify(headerHorizontalPayload2)
            });
            console.log("Response for Horizontal Payload Sync 2 ",iseHorizontalResponse2);
			
            const iseHorizontalResponse3 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'HorizontalResponse_HScroll'
                },
                body: JSON.stringify(headerHorizontalPayload3)
            });
            console.log("Response for Horizontal Payload Sync 3 ",iseHorizontalResponse3);

            const iseContentResponse1 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'ContentResponse_Initial'
                },
                body: JSON.stringify(contentResponsePayload1)
            });
            console.log("Response for Content Payload Sync 1 ",iseContentResponse1);

            const iseContentResponse2 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'ContentResponse_NextSet1'
                },
                body: JSON.stringify(contentResponsePayload2)
            });
            console.log("Response for Content Payload Sync 2 ",iseContentResponse2);

            const iseContentResponse3 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'ContentResponse_NextSet2'
                },
                body: JSON.stringify(contentResponsePayload3)
            });
            console.log("Response for Content Payload Sync 3 ",iseContentResponse3);

            const iseContentResponse4 = fetch("https://171.71.186.80:7027/user/simulation/gbacPolicies/post", {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                    'Content-Type': 'application/json',
                    'filename': 'ContentResponse_NextSet3'
                },
                body: JSON.stringify(contentResponsePayload4)
            });
            console.log("Response for Content Payload Sync 4 ",iseContentResponse4);
        },
        CUDPolicyAccess: function (operation, jsonObj) {

            if (operation == 'put') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'id': jsonObj.id});
                console.log("policyObj to be deleted = " + JSON.stringify(policyObj));
                // if (policyObj && policyObj.length) {
                //     SimLokiDatabaseActions.delete('access-policy', policyObj);
                // }
                console.log("inside createAccessPolicy");
                var jsonArray = [];
                var Object = {};
                if(!Array.isArray(jsonObj)) {
                    jsonArray.push(jsonObj)
                } else {
                    jsonArray = jsonObj
                }
                for(let i =0; i<jsonArray.length; i++) {
                    Object = jsonArray[i];
                    console.log("new jsonObj = " + JSON.stringify(Object));
                    var oldAccessPolicy = JSON.parse(JSON.stringify(AccessPolicyTemplate.AccessPolicy_Template_1_3_1[0]));
                    console.log("oldAccessPolicy = " + JSON.stringify(oldAccessPolicy));
                    //Produce = Source, Consumer= destination from the UI
                    
                    /*Adding Temp fix for Fury release as requested by Sonia, from Overview when we create access contract 
                    need to be fixed, hotfix */ 

                    if(Object.producer.scalableGroup[0].idRef != '356a1413-b079-4e9d-a1b9-58ebc2144876'){
                        var consumerId = Object.consumer.scalableGroup[0].idRef
                        var producerId = Object.producer.scalableGroup[0].idRef
                        var contractId = Object.contract.idRef
                        var contract_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'id':contractId});
                        var consumer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : consumerId});
                        var producer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : producerId});
                        oldAccessPolicy.description = Object.description;
                        oldAccessPolicy.priority = Object.priority;
                        oldAccessPolicy.contractId = contractId
                        oldAccessPolicy.contractName = contract_recordObj[0].name
                        oldAccessPolicy.contractIseInstanceUuid = contract_recordObj[0].iseInstanceUuid
                        oldAccessPolicy.consumerId = consumerId
                        oldAccessPolicy.consumerName = consumer_recordObj[0].name
                        oldAccessPolicy.name = producer_recordObj[0].name+"-"+consumer_recordObj[0].name 
                        oldAccessPolicy.producerName = producer_recordObj[0].name
                        oldAccessPolicy.producerId = producer_recordObj[0].id
                    }
                    else{
                        var consumerId = oldAccessPolicy.consumerId
                        var producerId = oldAccessPolicy.producerId
                        var accObj = SimLokiDatabaseActions.getAll('access-contract');
                        var contractId = accObj.slice(-1)[0].id
                        var contract_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'id':contractId});
                        var consumer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : consumerId});
                        var producer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : producerId});
                        oldAccessPolicy.description = Object.description;
                        oldAccessPolicy.priority = Object.priority;
                        oldAccessPolicy.contractId = contractId
                        oldAccessPolicy.contractName = contract_recordObj[0].name
                        oldAccessPolicy.contractIseInstanceUuid = contract_recordObj[0].iseInstanceUuid
                        oldAccessPolicy.consumerId = consumerId
                        oldAccessPolicy.consumerName = consumer_recordObj[0].name
                        oldAccessPolicy.name = producer_recordObj[0].name+"-"+consumer_recordObj[0].name 
                        oldAccessPolicy.producerName = producer_recordObj[0].name
                        oldAccessPolicy.producerId = producer_recordObj[0].id
                    }
                    var policyId = UtilityFunctions.generate_uuid();
                    oldAccessPolicy.id = policyId;
                    SimLokiDatabaseActions.insert('access-policy', oldAccessPolicy);
                    }

                    //Commented by Karan in Ghost before fixing any other issue in this please contact
                    /* Commenting the actuall code for fury fix, please revert in shockwave release*/
                    // var consumerId = Object.consumer.scalableGroup[0].idRef
                    // var producerId = Object.producer.scalableGroup[0].idRef
                    // var contractId = jsonObj.contract.idRef//Object.contract.idRef
                    // var contract_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'id':contractId});
                    // var consumer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : consumerId});
                    // var producer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : producerId});
                    // oldAccessPolicy.description = Object.description;
                    // oldAccessPolicy.priority = Object.priority;
                    // oldAccessPolicy.contractId = contractId
                    // oldAccessPolicy.contractName = contract_recordObj[0].name
                    // oldAccessPolicy.contractIseInstanceUuid = contract_recordObj[0].iseInstanceUuid
                    // oldAccessPolicy.consumerId = consumerId
                    // oldAccessPolicy.consumerName = consumer_recordObj[0].name
                    // oldAccessPolicy.name = producer_recordObj[0].name+"-"+consumer_recordObj[0].name 
                    // oldAccessPolicy.producerName = producer_recordObj[0].name
                    // oldAccessPolicy.producerId = producer_recordObj[0].id
                    // var policyId = UtilityFunctions.generate_uuid();
                    // oldAccessPolicy.id = policyId;
                    // SimLokiDatabaseActions.insert('access-policy', oldAccessPolicy);
                    
                    
            }

            else if (operation == 'post') {
                console.log("inside createAccessPolicy");
                var jsonArray = [];
                var Object = {};
                if(!Array.isArray(jsonObj)) {
                    jsonArray.push(jsonObj)
                } else {
                    jsonArray = jsonObj
                }
                for(let i =0; i<jsonArray.length; i++) {
                    Object = jsonArray[i];
                    console.log("new jsonObj = " + JSON.stringify(Object));
                    var oldAccessPolicy = JSON.parse(JSON.stringify(AccessPolicyTemplate.AccessPolicy_Template_1_3_1[0]));
                    console.log("oldAccessPolicy = " + JSON.stringify(oldAccessPolicy));
                    //Produce = Source, Consumer= destination from the UI
                    var consumerId = Object.consumer.scalableGroup[0].idRef
                    var producerId = Object.producer.scalableGroup[0].idRef
                    var contractId = Object.contract.idRef
                    var contract_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'id':contractId});
                    var consumer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : consumerId});
                    var producer_recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : producerId});
                    oldAccessPolicy.description = Object.description;
                    oldAccessPolicy.priority = Object.priority;
                    oldAccessPolicy.contractId = contractId
                    oldAccessPolicy.contractName = contract_recordObj[0].name
                    oldAccessPolicy.contractIseInstanceUuid = contract_recordObj[0].iseInstanceUuid
                    oldAccessPolicy.consumerId = consumerId
                    oldAccessPolicy.consumerName = consumer_recordObj[0].name
                    oldAccessPolicy.name = producer_recordObj[0].name+"-"+consumer_recordObj[0].name 
                    oldAccessPolicy.producerName = producer_recordObj[0].name
                    oldAccessPolicy.producerId = producer_recordObj[0].id
                    var policyId = UtilityFunctions.generate_uuid();
                    oldAccessPolicy.id = policyId;
                    SimLokiDatabaseActions.insert('access-policy', oldAccessPolicy);

                    // Code for adding reference count in scalble group acaGBPListDestViewSummary, acaGBPListSourceViewSummary 
                    if(producer_recordObj[0].acaGBPListSourceViewSummary.length > 0){
                        var sgref_count = producer_recordObj[0].acaGBPListSourceViewSummary[0].sgRefCount
                        var contractref_count = producer_recordObj[0].acaGBPListSourceViewSummary[0].contractRefCount
                        producer_recordObj[0].acaGBPListSourceViewSummary[0].sgRefCount = sgref_count+1
                        producer_recordObj[0].acaGBPListSourceViewSummary[0].contractRefCount = contractref_count+1
                    }else {
                        var SourceViewSummary = {};
                        SourceViewSummary.id = producer_recordObj[0].id
                        SourceViewSummary.instanceId = producer_recordObj[0].instanceId
                        SourceViewSummary.displayName = producer_recordObj[0].displayName
                        SourceViewSummary.instanceVersion = producer_recordObj[0].instanceVersion
                        SourceViewSummary.contractRefCount = 1
                        SourceViewSummary.sgName = producer_recordObj[0].name
                        SourceViewSummary.sgRefCount = 1
                        SourceViewSummary.tenantintsegment = producer_recordObj[0].tenantintsegment
                        SourceViewSummary.tenantlongsegment = producer_recordObj[0].tenantlongsegment
                        producer_recordObj[0].acaGBPListSourceViewSummary.push(SourceViewSummary)
                    }
                    SimLokiDatabaseActions.update('scalablegroup', producer_recordObj)
                    if(consumer_recordObj[0].acaGBPListDestViewSummary.length > 0){
                        var sgref_count = consumer_recordObj[0].acaGBPListDestViewSummary[0].sgRefCount
                        var contractref_count = consumer_recordObj[0].acaGBPListDestViewSummary[0].contractRefCount
                        consumer_recordObj[0].acaGBPListDestViewSummary[0].sgRefCount = sgref_count+1
                        consumer_recordObj[0].acaGBPListDestViewSummary[0].contractRefCount = contractref_count+1
                    }else {
                        var SourceViewSummary = {};
                        SourceViewSummary.id = consumer_recordObj[0].id
                        SourceViewSummary.instanceId = consumer_recordObj[0].instanceId
                        SourceViewSummary.displayName = consumer_recordObj[0].displayName
                        SourceViewSummary.instanceVersion = consumer_recordObj[0].instanceVersion
                        SourceViewSummary.contractRefCount = 1
                        SourceViewSummary.sgName = consumer_recordObj[0].name
                        SourceViewSummary.sgRefCount = 1
                        SourceViewSummary.tenantintsegment = consumer_recordObj[0].tenantintsegment
                        SourceViewSummary.tenantlongsegment = consumer_recordObj[0].tenantlongsegment
                        consumer_recordObj[0].acaGBPListDestViewSummary.push(SourceViewSummary)
                    }
                    SimLokiDatabaseActions.update('scalablegroup', consumer_recordObj)
                    
                    var oldAccessPolicy =  JSON.parse(JSON.stringify(AuditPolicyTemplate.AuditPolicy_Template[0]));
                    oldAccessPolicy.id = UtilityFunctions.generate_uuid();
                    oldAccessPolicy.auditParameters.policyuuid = policyId;
                    oldAccessPolicy.auditParameters.name = Object.name;
                    oldAccessPolicy.auditParameters.status = "DEPLOYED";
                    oldAccessPolicy.auditRequestor = "admin";
                    oldAccessPolicy.createdDateTime = UtilityFunctions.getTimeStamp();
                    oldAccessPolicy.persistDateTime = UtilityFunctions.getTimeStamp();
                    oldAccessPolicy.auditParameters.description = "Policy Created";
                    SimLokiDatabaseActions.insert( 'audit', oldAccessPolicy );

                }
                
            } else if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'id': jsonObj.id });
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('access-policy', policyObj);
                    var oldAudit = SimLokiDatabaseActions.getFilteredRecordHandler('audit', {'auditParameters.policyuuid': jsonObj.id });
                    var oldAccessPolicy =  JSON.parse(JSON.stringify(AuditPolicyTemplate.AuditPolicy_Template[0]));
                    oldAccessPolicy.id = UtilityFunctions.generate_uuid();
                    oldAccessPolicy.auditParameters.policyuuid = policyId;
                    oldAccessPolicy.auditParameters.name = policyObj[0].name;
                    oldAccessPolicy.auditParameters.status = "DELETED";
                    if(oldAudit[0].auditParameters.version != undefined){
                        oldAccessPolicy.auditParameters.version = parseInt(oldAudit[0].auditParameters.version) + 1+"";
                    }
                    oldAccessPolicy.createdDateTime = UtilityFunctions.getTimeStamp();
                    oldAccessPolicy.persistDateTime = UtilityFunctions.getTimeStamp();
                    oldAccessPolicy.auditParameters.description = "Policy Deleted";
                    SimLokiDatabaseActions.insert( 'audit', oldAccessPolicy );
                }
            }
        },

        CUDCopyPolicy: function (operation, jsonObj) {
            if (operation == 'post') {
                console.log("inside createAccessPolicy");
                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldAccessPolicy = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyPolicy_Template[0]));
                console.log("oldAccessPolicy = " + JSON.stringify(oldAccessPolicy));
                oldAccessPolicy.name = jsonObj.name;
                oldAccessPolicy.description = jsonObj.description;
                oldAccessPolicy.priority = jsonObj.priority;
                oldAccessPolicy.contract = jsonObj.contract;
                oldAccessPolicy.consumer.scalableGroup = jsonObj.consumer.scalableGroup;
                oldAccessPolicy.producer.scalableGroup = jsonObj.producer.scalableGroup;
                oldAccessPolicy.isEnabled = jsonObj.isEnabled;
                oldAccessPolicy.policyScope = jsonObj.policyScope;
                oldAccessPolicy.id = UtilityFunctions.generate_uuid();
                oldAccessPolicy.createTime = UtilityFunctions.getTimeStamp();
                oldAccessPolicy.lastUpdateTime = UtilityFunctions.getTimeStamp();
                console.log("created AccessPolicy = " + JSON.stringify(oldAccessPolicy));
                SimLokiDatabaseActions.insert('copy-policy', oldAccessPolicy);

                var copyPolicyCount = SimLokiDatabaseActions.getCount('copy-policy');
                var tObj = SimLokiDatabaseActions.getAll('copy-policy-summary');
                if(tObj.length) {
                    tObj[0].successfulPoliciesCount = copyPolicyCount;
                    SimLokiDatabaseActions.update('copy-policy-summary', tObj);
                } else {
                    var copyPolicySummary = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyPolicySummary_Template[0]));
                    copyPolicySummary.id = UtilityFunctions.generate_uuid();
                    var singlePolicyStatus = CopyPoliciesTemplate.CopyPolicySummary_Template[0].singlePolicyStatus;
                    singlePolicyStatus.policyId = oldAccessPolicy.id;
                    singlePolicyStatus.status = "SAVED";
                    copyPolicySummary.singlePolicyStatus.push({policyId: oldAccessPolicy.id, status: "SAVED"});
                    copyPolicySummary.createTime = UtilityFunctions.getTimeStamp();
                    copyPolicySummary.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    copyPolicySummary.successfulPoliciesCount = copyPolicyCount;
                    console.log("created Audit  = " + JSON.stringify(copyPolicySummary));
                    SimLokiDatabaseActions.insert('copy-policy-summary', copyPolicySummary);
                }
            } else if (operation == 'put') {

                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-policy', {'id': jsonObj.id});
                console.log("Copy Policy to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('copy-policy', policyObj);
                }

                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldAccessPolicy = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyPolicy_Template[0]));
                console.log("oldAccessPolicy = " + JSON.stringify(oldAccessPolicy));
                oldAccessPolicy.name = jsonObj.name;
                oldAccessPolicy.description = jsonObj.description;
                oldAccessPolicy.priority = jsonObj.priority;
                oldAccessPolicy.contract = jsonObj.contract;
                oldAccessPolicy.consumer.scalableGroup = jsonObj.consumer.scalableGroup;
                oldAccessPolicy.producer.scalableGroup = jsonObj.producer.scalableGroup;
                oldAccessPolicy.isEnabled = jsonObj.isEnabled;
                oldAccessPolicy.policyScope = jsonObj.policyScope;
                oldAccessPolicy.id = jsonObj.id;
                oldAccessPolicy.createTime = UtilityFunctions.getTimeStamp();
                oldAccessPolicy.lastUpdateTime = UtilityFunctions.getTimeStamp();
                console.log("created AccessPolicy = " + JSON.stringify(oldAccessPolicy));
                SimLokiDatabaseActions.insert('copy-policy', oldAccessPolicy);

            } else if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-policy', {'id': jsonObj.id});
                console.log("Copy Policy to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('copy-policy', policyObj);
                }
                var tObj = SimLokiDatabaseActions.getAll('copy-policy-summary');
                if(tObj.length) {
                    var copyPolicyCount = SimLokiDatabaseActions.getCount('copy-policy');
                    tObj[0].successfulPoliciesCount = copyPolicyCount;
                    SimLokiDatabaseActions.update('copy-policy-summary', tObj);
                }
            }
        },

        CUDCopyContract: function (operation, jsonObj) {
            if (operation == 'put') {

                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-contract', {'id': jsonObj.id});
                console.log("ContractAccess to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('copy-contract', policyObj);
                }

                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldCopyContract = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyContract_Template[0]));
                console.log("oldAccessPolicy = " + JSON.stringify(oldCopyContract));
                oldCopyContract.id = jsonObj.id;
                oldCopyContract.type = jsonObj.type;
                oldCopyContract.name = jsonObj.name;
                oldCopyContract.description = jsonObj.description;
                oldCopyContract.qualifier = jsonObj.qualifier;
                oldCopyContract.clause[0].copyDestination = jsonObj.clause[0].copyDestination;
                oldCopyContract.createTime = UtilityFunctions.getTimeStamp();
                oldCopyContract.lastUpdateTime = UtilityFunctions.getTimeStamp();
                oldCopyContract.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                console.log("created ContractAccess = " + JSON.stringify(oldCopyContract));
                SimLokiDatabaseActions.insert('copy-contract', oldCopyContract);
            }

            else if (operation == 'post') {
                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldCopyContract = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyContract_Template[0]));
                console.log("oldAccessPolicy = " + JSON.stringify(oldCopyContract));
                oldCopyContract.id = UtilityFunctions.generate_uuid();
                oldCopyContract.type = jsonObj.type;
                oldCopyContract.name = jsonObj.name;
                oldCopyContract.description = jsonObj.description;
                oldCopyContract.qualifier = jsonObj.qualifier;
                oldCopyContract.clause[0].copyDestination = jsonObj.clause[0].copyDestination;
                oldCopyContract.clause[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                oldCopyContract.clause[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                oldCopyContract.createTime = UtilityFunctions.getTimeStamp();
                oldCopyContract.lastUpdateTime = UtilityFunctions.getTimeStamp();
                oldCopyContract.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                oldCopyContract.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                console.log("created ContractAccess = " + JSON.stringify(oldCopyContract));
                SimLokiDatabaseActions.insert('copy-contract', oldCopyContract);

            } else if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-contract', {'id': jsonObj.id});
                console.log("ContractAccess to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('copy-contract', policyObj);
                }

                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-policy', {'contract.idRef': jsonObj.id});
                if (recordObj && recordObj.length) {
                    SimLokiDatabaseActions.delete('copy-policy', recordObj);
                    console.log("copy policy to be updated = " + JSON.stringify(recordObj));
                    recordObj.contract = {};
                    console.log("updated copy-policy = " + JSON.stringify(recordObj));
                }

            }

        },

        CUDCopyDestination: function (operation, jsonObj) {

            if (operation == 'post') {

                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldCopyContract = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyDestination_Template[0]));
                console.log("oldCopyDestination = " + JSON.stringify(oldCopyContract));
                oldCopyContract.id = UtilityFunctions.generate_uuid();
                oldCopyContract.deviceID = jsonObj.deviceID;
                oldCopyContract.name = jsonObj.name;
                oldCopyContract.description = jsonObj.description;
                oldCopyContract.ipAddress = jsonObj.ipAddress;
                oldCopyContract.interfaces = jsonObj.interfaces;
                oldCopyContract.createTime = UtilityFunctions.getTimeStamp();
                oldCopyContract.lastUpdateTime = UtilityFunctions.getTimeStamp();
                console.log("created CopyDestination = " + JSON.stringify(oldCopyContract));
                SimLokiDatabaseActions.insert('copy-destination', oldCopyContract);
                console.log("Inserted to copy-destination");

            } else if (operation == 'put') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-destination', {'id': jsonObj.id});
                console.log("copy-destination to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    policyObj.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    SimLokiDatabaseActions.delete('copy-destination', policyObj);
                }

                console.log("new jsonObj = " + JSON.stringify(jsonObj));
                var oldCopyContract = JSON.parse(JSON.stringify(CopyPoliciesTemplate.CopyDestination_Template[0]));
                console.log("oldAccessPolicy = " + JSON.stringify(oldCopyContract));
                oldCopyContract.id = jsonObj.id;
                oldCopyContract.deviceID = jsonObj.deviceID;
                oldCopyContract.name = jsonObj.name;
                oldCopyContract.description = jsonObj.description;
                oldCopyContract.ipAddress = jsonObj.ipAddress;
                oldCopyContract.interfaces = jsonObj.interfaces;
                oldCopyContract.lastUpdateTime = UtilityFunctions.getTimeStamp();

                console.log("created ContractAccess = " + JSON.stringify(oldCopyContract));
                SimLokiDatabaseActions.insert('copy-destination', oldCopyContract);
            } else if (operation == 'delete') {

                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('copy-destination', {'id': jsonObj.id});
                console.log("copy-destination to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('copy-destination', policyObj);
                }

            }

        },
        // Updated this Policy -->GBAC -->Access Contract
        CUDContractAccess: function (operation, jsonObj) {
            if (operation == 'put') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract', {'id': jsonObj.id});
                console.log("policyObj to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('access-contract', policyObj);
                }
                this.createGBPAccessPolicy(jsonObj)
                // console.log("inside update contract access");
                // console.log("new jsonObj = " + JSON.stringify(jsonObj));
                // var oldContractAccess = JSON.parse(JSON.stringify(ContractPolicyTemplate.ContractPolicy_Template[0]));
                // console.log("oldAccessPolicy = " + JSON.stringify(oldContractAccess));
                // oldContractAccess.type = jsonObj.type
                // oldContractAccess.name = jsonObj.name;
                // oldContractAccess.description = jsonObj.description;
                // oldContractAccess.clause[0].access = jsonObj.clause[0].access;
                // oldContractAccess.id = jsonObj.id;
                // oldContractAccess.lastUpdateTime = UtilityFunctions.getTimeStamp();

                // console.log("jsonObj.contractClassifier.length = " + jsonObj.contractClassifier.length);
                // for (var i = 0; i < jsonObj.contractClassifier.length; i++) {
                //     if (oldContractAccess.contractClassifier[i] == undefined) {
                //         console.log("oldContractAcces s.contractClassifier[i] == undefined");
                //         var classifier = {};
                //         console.log("before classifier = " + classifier);
                //         classifier.access = jsonObj.contractClassifier[i].access;
                //         console.log("after classifier = " + classifier);
                //         oldContractAccess.contractClassifier.push(classifier);
                //     } else {
                //         oldContractAccess.contractClassifier[i].access = jsonObj.contractClassifier[i].access;
                //     }
                // }
                // SimLokiDatabaseActions.insert('access-contract', oldContractAccess);
            }

            if (operation == 'post') {
                this.createGBPAccessPolicy(jsonObj)
            }

            if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract', {'id': jsonObj.id});
                console.log("ContractAccess to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('access-contract', policyObj);
                }
            }
        },
        createGBPAccessPolicy : function(jsonObj){
            genId = generateNumericId(6)
            var oldContractAccess = JSON.parse(JSON.stringify(ContractPolicyTemplate.ContractPolicy_Template[0]));
            if(jsonObj.id != undefined){
                oldContractAccess.id = jsonObj.id
            }
            else{
                oldContractAccess.id = UtilityFunctions.generate_uuid();
            }
            oldContractAccess.type = jsonObj.type
            oldContractAccess.name = jsonObj.name;
            oldContractAccess.description = jsonObj.description;
            oldContractAccess.iseReserved = false
            oldContractAccess.policyRefCount = 0
            oldContractAccess.pushed = false
            oldContractAccess.syncStatus = "CREATE"
            oldContractAccess.clause[0].access = jsonObj.clause[0].access;
            oldContractAccess.clause[0].logging = jsonObj.clause[0].logging;
            oldContractAccess.clause[0].id = UtilityFunctions.generate_uuid();
            oldContractAccess.clause[0].instanceId = genId;
            oldContractAccess.clause[0].displayName = "0"
            oldContractAccess.clause[0].instanceVersion = 0
            oldContractAccess.clause[0].priority = 1
            oldContractAccess.clause[0].internal = true
            oldContractAccess.createTime = UtilityFunctions.getTimeStamp();
            oldContractAccess.lastUpdateTime = UtilityFunctions.getTimeStamp();
            oldContractAccess.contractClassifier = [] // Making empty before adding new Contracts

            for (var i = 0; i < jsonObj.contractClassifier.length; i++) {
                var classifier = {};
                classifier.id = UtilityFunctions.generate_uuid();
                classifier.instanceId = genId+1;
                classifier.displayName = "0";
                classifier.instanceVersion = 0;
                classifier.additionalActions = [];
                classifier.priority = generateNumericId(10)
                classifier.sourcePorts = [];
                classifier.logging = jsonObj.contractClassifier[i].logging;
                classifier.access = jsonObj.contractClassifier[i].access;
                classifier.applicationName = jsonObj.contractClassifier[i].applicationName;
                var destNet = jsonObj.contractClassifier[i].dstNetworkIdentities;
                
                var destNet_list = []
                var srcNet_list = []
                if(destNet!=undefined && destNet.length >0){
                    for(var j = 0; j <destNet.length; j++){
                        var destNetId = {};
                        destNetId.id = UtilityFunctions.generate_uuid();
                        destNetId.instanceId = genId+2;
                        destNetId.displayName = "0";
                        destNetId.instanceVersion = 0;
                        destNetId.ipv4Subnet = [];
                        destNetId.ipv6Subnet = [];
                        destNetId.lowerPort = 0;
                        destNetId.ports = destNet[j].ports
                        destNetId.protocol = destNet[j].protocol
                        destNetId.upperPort = 0
                        destNet_list.push(destNetId);
                    }
                }
                oldContractAccess.type = jsonObj.type
                oldContractAccess.name = jsonObj.name;
                oldContractAccess.description = jsonObj.description;
                oldContractAccess.iseReserved = false
                oldContractAccess.policyRefCount = 0
                oldContractAccess.pushed = false
                oldContractAccess.syncStatus = "CREATE"
                oldContractAccess.clause[0].access = jsonObj.clause[0].access;
                oldContractAccess.clause[0].logging = jsonObj.clause[0].logging;
                oldContractAccess.clause[0].id = UtilityFunctions.generate_uuid();
                oldContractAccess.clause[0].instanceId = genId;
                oldContractAccess.clause[0].displayName = "0"
                oldContractAccess.clause[0].instanceVersion = 0
                oldContractAccess.clause[0].priority = 1
                oldContractAccess.clause[0].internal = true
                oldContractAccess.createTime = UtilityFunctions.getTimeStamp();
                oldContractAccess.lastUpdateTime = UtilityFunctions.getTimeStamp();
                oldContractAccess.contractClassifier = [] // Making empty before adding new Contracts

                for (var i = 0; i < jsonObj.contractClassifier.length; i++) {
                    var classifier = {};
                    classifier.id = UtilityFunctions.generate_uuid();
                    classifier.instanceId = genId+1;
                    classifier.displayName = "0";
                    classifier.instanceVersion = 0;
                    classifier.additionalActions = [];
                    classifier.priority = generateNumericId(10)
                    classifier.sourcePorts = [];
                    classifier.logging = jsonObj.contractClassifier[i].logging;
                    classifier.access = jsonObj.contractClassifier[i].access;
                    classifier.applicationName = jsonObj.contractClassifier[i].applicationName;
                    var destNet = jsonObj.contractClassifier[i].dstNetworkIdentities;
                    
                    var destNet_list = []
                    var srcNet_list = []
                    if(destNet!=undefined && destNet.length >0){
                        for(var j = 0; j <destNet.length; j++){
                            var destNetId = {};
                            destNetId.id = UtilityFunctions.generate_uuid();
                            destNetId.instanceId = genId+2;
                            destNetId.displayName = "0";
                            destNetId.instanceVersion = 0;
                            destNetId.ipv4Subnet = [];
                            destNetId.ipv6Subnet = [];
                            destNetId.lowerPort = 0;
                            destNetId.ports = destNet[j].ports
                            destNetId.protocol = destNet[j].protocol
                            destNetId.upperPort = 0
                            destNet_list.push(destNetId);
                        }
                    }
                    classifier.dstNetworkIdentities = destNet_list
                    var srcNet = jsonObj.contractClassifier[i].srcNetworkIdentities;
                    if(srcNet !=undefined && srcNet.length >0){
                        for(var j = 0; j <srcNet.length; j++){
                            var srcNetId = {};
                            srcNetId.id = UtilityFunctions.generate_uuid();
                            srcNetId.instanceId = genId+3;
                            srcNetId.displayName = "0";
                            srcNetId.instanceVersion = 0;
                            srcNetId.ipv4Subnet = [];
                            srcNetId.ipv6Subnet = [];
                            srcNetId.lowerPort = 0;
                            srcNetId.ports = srcNet[j].ports
                            srcNetId.protocol = srcNet[j].protocol
                            srcNetId.upperPort = 0
                            srcNet_list.push(srcNetId); 
                        }
                    }
                    classifier.srcNetworkIdentities = srcNet_list
                    oldContractAccess.contractClassifier.push(classifier);
                    oldContractAccess.ruleCount = oldContractAccess.contractClassifier.length
                    // if (oldContractAccess.contractClassifier[i] == undefined) {
                    //     var classifier = {};
                    //     classifier.id = UtilityFunctions.generate_uuid();
                    //     classifier.instanceId = genId+1;
                    //     classifier.displayName = "0";
                    //     classifier.instanceVersion = 0;
                    //     classifier.additionalActions = [];
                    //     classifier.priority = generateNumericId(10)
                    //     classifier.sourcePorts = [];
                    //     classifier.logging = jsonObj.contractClassifier[i].logging;
                    //     classifier.access = jsonObj.contractClassifier[i].access;
                    //     classifier.applicationName = jsonObj.contractClassifier[i].applicationName;
                    //     var destNet = jsonObj.contractClassifier[i].dstNetworkIdentities;
                        
                    //     var destNet_list = []
                    //     var srcNet_list = []
                    //     if(destNet!=undefined && destNet.length >0){
                    //         for(var j = 0; j <destNet.length; j++){
                    //             var destNetId = {};
                    //             destNetId.id = UtilityFunctions.generate_uuid();
                    //             destNetId.instanceId = genId+2;
                    //             destNetId.displayName = "0";
                    //             destNetId.instanceVersion = 0;
                    //             destNetId.ipv4Subnet = [];
                    //             destNetId.ipv6Subnet = [];
                    //             destNetId.lowerPort = 0;
                    //             destNetId.ports = destNet[j].ports
                    //             destNetId.protocol = destNet[j].protocol
                    //             destNetId.upperPort = 0
                    //             destNet_list.push(destNetId);
                    //         }
                    //     }
                    //     classifier.dstNetworkIdentities = destNet_list
                    //     var srcNet = jsonObj.contractClassifier[i].srcNetworkIdentities;
                    //     if(srcNet !=undefined && srcNet.length >0){
                    //         for(var j = 0; j <srcNet.length; j++){
                    //             var srcNetId = {};
                    //             srcNetId.id = UtilityFunctions.generate_uuid();
                    //             srcNetId.instanceId = genId+3;
                    //             srcNetId.displayName = "0";
                    //             srcNetId.instanceVersion = 0;
                    //             srcNetId.ipv4Subnet = [];
                    //             srcNetId.ipv6Subnet = [];
                    //             srcNetId.lowerPort = 0;
                    //             srcNetId.ports = srcNet[j].ports
                    //             srcNetId.protocol = srcNet[j].protocol
                    //             srcNetId.upperPort = 0
                    //             srcNet_list.push(srcNetId); 
                    //         }
                    //     }
                    //     classifier.srcNetworkIdentities = srcNet_list
                    //     oldContractAccess.contractClassifier.push(classifier);
                    //     oldContractAccess.ruleCount = oldContractAccess.contractClassifier.length
                    // } else {
                    //     oldContractAccess.contractClassifier[i].access = jsonObj.contractClassifier[i].access;
                    //     oldContractAccess.contractClassifier[i].scalableGroup = jsonObj.contractClassifier[i].scalableGroup;
                    // }
                }
                classifier.srcNetworkIdentities = srcNet_list
                oldContractAccess.contractClassifier.push(classifier);
                oldContractAccess.ruleCount = oldContractAccess.contractClassifier.length
                // if (oldContractAccess.contractClassifier[i] == undefined) {
                //     var classifier = {};
                //     classifier.id = UtilityFunctions.generate_uuid();
                //     classifier.instanceId = genId+1;
                //     classifier.displayName = "0";
                //     classifier.instanceVersion = 0;
                //     classifier.additionalActions = [];
                //     classifier.priority = generateNumericId(10)
                //     classifier.sourcePorts = [];
                //     classifier.logging = jsonObj.contractClassifier[i].logging;
                //     classifier.access = jsonObj.contractClassifier[i].access;
                //     classifier.applicationName = jsonObj.contractClassifier[i].applicationName;
                //     var destNet = jsonObj.contractClassifier[i].dstNetworkIdentities;
                    
                //     var destNet_list = []
                //     var srcNet_list = []
                //     if(destNet!=undefined && destNet.length >0){
                //         for(var j = 0; j <destNet.length; j++){
                //             var destNetId = {};
                //             destNetId.id = UtilityFunctions.generate_uuid();
                //             destNetId.instanceId = genId+2;
                //             destNetId.displayName = "0";
                //             destNetId.instanceVersion = 0;
                //             destNetId.ipv4Subnet = [];
                //             destNetId.ipv6Subnet = [];
                //             destNetId.lowerPort = 0;
                //             destNetId.ports = destNet[j].ports
                //             destNetId.protocol = destNet[j].protocol
                //             destNetId.upperPort = 0
                //             destNet_list.push(destNetId);
                //         }
                //     }
                //     classifier.dstNetworkIdentities = destNet_list
                //     var srcNet = jsonObj.contractClassifier[i].srcNetworkIdentities;
                //     if(srcNet !=undefined && srcNet.length >0){
                //         for(var j = 0; j <srcNet.length; j++){
                //             var srcNetId = {};
                //             srcNetId.id = UtilityFunctions.generate_uuid();
                //             srcNetId.instanceId = genId+3;
                //             srcNetId.displayName = "0";
                //             srcNetId.instanceVersion = 0;
                //             srcNetId.ipv4Subnet = [];
                //             srcNetId.ipv6Subnet = [];
                //             srcNetId.lowerPort = 0;
                //             srcNetId.ports = srcNet[j].ports
                //             srcNetId.protocol = srcNet[j].protocol
                //             srcNetId.upperPort = 0
                //             srcNet_list.push(srcNetId); 
                //         }
                //     }
                //     classifier.srcNetworkIdentities = srcNet_list
                //     oldContractAccess.contractClassifier.push(classifier);
                //     oldContractAccess.ruleCount = oldContractAccess.contractClassifier.length
                // } else {
                //     oldContractAccess.contractClassifier[i].access = jsonObj.contractClassifier[i].access;
                //     oldContractAccess.contractClassifier[i].scalableGroup = jsonObj.contractClassifier[i].scalableGroup;
                // }
            }
            SimLokiDatabaseActions.insert('access-contract', oldContractAccess);

            let iseJsonString = ScalableGroupIseTemplate["ACL_Template1.3.1"][0];
            iseJsonString.name = jsonObj.name;
            iseJsonString.id = oldContractAccess.id;
            iseJsonString.description = jsonObj.description;

        }, 

        CUDApplicationContract : function (operation, jsonObj) {
            if (operation == 'post') {
                delete jsonObj.$loki;
                delete jsonObj.meta;
                jsonObj.id = UtilityFunctions.generate_uuid();
                jsonObj.namespace = jsonObj.id;
                var genId = generateNumericId(5);
                jsonObj.instanceId = genId;
                jsonObj.authEntityId = genId;
                jsonObj.displayName = 0;
                jsonObj.provisioningState = "DEFINED";
                jsonObj.internal = false;
                jsonObj.qualifier = "application";
                jsonObj.pushed = false;
                jsonObj.deployed = false;
                for(var i=0; i<jsonObj.clause.length; i++) {
                    jsonObj.clause[i].id = UtilityFunctions.generate_uuid();
                    genId = generateNumericId(5);
                    jsonObj.clause[i].instanceId = genId;
                    jsonObj.clause[i].authEntityId = genId;
                    jsonObj.clause[i].displayName = 0;
                    if(jsonObj.clause[i].type=="DSCP_CUSTOMIZATION") {
                        for(var j=0; j<jsonObj.clause[i].tcDscpSettings.length; j++) {
                            var tJsonDscp = jsonObj.clause[i].tcDscpSettings[j];
                            tJsonDscp.id = UtilityFunctions.generate_uuid();
                            genId = generateNumericId(5);
                            tJsonDscp.instanceId = genId;
                            tJsonDscp.authEntityId = genId;
                            tJsonDscp.displayName = 0;
                        }
                    }
                }
                SimLokiDatabaseActions.insert('application-contract', jsonObj);
            } else if(operation == 'delete') {
                var appQObj = SimLokiDatabaseActions.getFilteredRecordHandler('application-contract', {'id': jsonObj.id});
                if(appQObj && appQObj.length>0) {
                    SimLokiDatabaseActions.delete('application-contract', appQObj);
                }
            } else if(operation == 'put') {
                var appQObj = SimLokiDatabaseActions.getFilteredRecordHandler('application-contract', {'id': jsonObj.id});
                if(appQObj && appQObj.length) {
                    appQObj[0].clause = jsonObj.clause;
                    SimLokiDatabaseActions.update('application-contract', appQObj);
                }
            }
        },

        CUDApplicationPolicy : function (operation, jsonObj) {
            if (operation == 'post') {
                if(jsonObj.createList!=undefined && jsonObj.deleteList ==undefined && jsonObj.updateList ==undefined) {
                    var policyList= jsonObj.createList;
                    this.createList(policyList);
                    //creating application policy
                    var tJson = JSON.parse(JSON.stringify(ApplicationPolicyTemplate.ApplicationPolicySummary_Template));
                    var summaryObject = policyList[policyList.length-1];
                    var namespace ="policy:application:"+summaryObject.policyScope;

                    tJson.policyId =  UtilityFunctions.generate_uuid();
                    console.log(tJson.policyId);
                    tJson.policyName =summaryObject.policyScope;
                    tJson.namespace = namespace;

                    var groupId= summaryObject.advancedPolicyScope.advancedPolicyScopeElement[0].groupId;
                    var childrenSites= [];
                    var siteList =SimulationUtility.getChildrenSites(groupId[0],childrenSites);
                    var siteObj=[];
                    if(DefaultConfig.DEFAULT_UUID.GlobalGroup==groupId[0]) {
                     siteObj.push({"id":groupId[0]});

                    } else {
                     siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': groupId[0]});
                    }
                    siteList.push(siteObj[0]);
                    tJson.applicationPolicyScopeSummary.applicationPolicyScopeSiteSummary.total=siteList.length;

                    if(summaryObject.policyScope.indexOf("draft")>=0) {
                         tJson.status="DRAFT";
                         tJson.type="DRAFT";
                    } else {
                        var count = updatePolicy(siteList,namespace);
                        tJson.applicationPolicyScopeSummary.applicationPolicyScopeDeviceSummary.total=count;
                        tJson.applicationPolicyScopeSummary.applicationPolicyScopeDeviceSummary.success=getCount(namespace,"SUCCESS");
                        tJson.applicationPolicyScopeSummary.applicationPolicyScopeDeviceSummary.failed=getCount(namespace,"FAILED");
                    }

                    var policyObj = SimLokiDatabaseActions.getAll('policy-summary');
                    policyObj[0].applicationPolicySummary.push(tJson);
                    SimLokiDatabaseActions.update('policy-summary', policyObj);

                } else if(jsonObj.createList!=undefined && jsonObj.updateList !=undefined) {
                    var policyObj = SimLokiDatabaseActions.getAll('policy-summary');
                    var summaryObject = jsonObj.createList;
                    var policyName =summaryObject[0].policyScope;
                     for(var i=0;i<policyObj[0].applicationPolicySummary.length;i++) {
                        if(policyObj[0].applicationPolicySummary[i].policyName==policyName) {

                          policyObj[0].applicationPolicySummary[i].version  = policyObj[0].applicationPolicySummary[i].version+1;
                        }
                     }
                     SimLokiDatabaseActions.update('policy-summary', policyObj);
                     this.updateList(jsonObj.updateList);
                } else if(jsonObj.createList!=undefined && jsonObj.deleteList !=undefined) {
                  this.createList(jsonObj.createList);
                  this.deleteList(jsonObj.deleteList);

                } else if(jsonObj.deleteList!=undefined) {

                    var policyObj = SimLokiDatabaseActions.getAll('policy-summary');
                    var appPolicy = SimLokiDatabaseActions.getFilteredRecordHandler('application-policy', {'id': jsonObj.deleteList[0]});

                    var policyName =appPolicy[0].policyScope;
                     for(var i=0;i<policyObj[0].applicationPolicySummary.length;i++) {
                        if(policyObj[0].applicationPolicySummary[i].policyName==policyName) {
                            policyObj[0].applicationPolicySummary.splice(i,1);
                        }
                     }
                     SimLokiDatabaseActions.update('policy-summary', policyObj);

                   this.deleteList(jsonObj.deleteList);
                }

           }
        },

        createList :function(policyList) {

            for(var i =0;i<policyList.length;i++) {
                    var tJson = JSON.parse(JSON.stringify(ApplicationTemplate.Application_Template[0]));

                    if(policyList[i].producer!=undefined) {
                     tJson.id = UtilityFunctions.generate_uuid();
                     tJson.name= policyList[i].name;
                     tJson.description= policyList[i].description;
                     tJson.isSeeded= policyList[i].isSeeded;
                     tJson.isStale= policyList[i].isStale;
                     tJson.targetIdList= policyList[i].targetIdList;
                     tJson.customProvisions= policyList[i].customProvisions;
                     tJson.policyScope= policyList[i].policyScope;
                     tJson.customProvisions =policyList[i].customProvisions;
                     tJson.producer.scalableGroup=policyList[i].producer.scalableGroup;
                     tJson.exclusiveContract =policyList[i].exclusiveContract;
                     tJson.advancedPolicyScope =policyList[i].advancedPolicyScope;
                     if(policyList[i].advancedPolicyScope.advancedPolicyScopeElement[0].ssid==undefined) {
                       tJson.advancedPolicyScope.advancedPolicyScopeElement[0].ssid=[];
                     }
                    } else {

                     tJson.id = UtilityFunctions.generate_uuid();
                     tJson.name= policyList[i].name;
                     tJson.exclusiveContract =policyList[i].exclusiveContract;
                     tJson.isEnabled =policyList[i].isEnabled;
                     tJson.policyScope= policyList[i].policyScope;
                     tJson.advancedPolicyScope =policyList[i].advancedPolicyScope;
                     if(policyList[i].advancedPolicyScope.advancedPolicyScopeElement[0].ssid==undefined) {
                       tJson.advancedPolicyScope.advancedPolicyScopeElement[0].ssid=[];
                     }
                     tJson.exclusiveContract.clause.type=policyList[i].type;
                     tJson.exclusiveContract.clause.hostTrackingEnabled=policyList[i].hostTrackingEnabled;
                    }
                   SimLokiDatabaseActions.insert('application-policy', tJson);
            }

        },

        updateList :function(policyList) {

           SimLokiDatabaseActions.update('application-policy', policyList);

        }
        ,

        deleteList:function(policyList) {
            var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('application-policy', {'id': { '$containsAny' :  policyList}});

            SimLokiDatabaseActions.delete('application-policy', policyObj);

        },

        CUDApplicationPreviewPolicy : function (operation, jsonObj) {
            var tJson = JSON.parse(JSON.stringify(ApplicationPolicyPreviewTemplate.Application_Policy_Preview_Template));
               tJson.id = UtilityFunctions.generate_uuid();
               tJson.namespace=jsonObj.namespace;
               tJson.cfsList=jsonObj.cfsList;
            SimLokiDatabaseActions.insert('application-policy-preview', tJson);

        },
        CUDIPAccessContract: function (operation, jsonObj){
            if (operation == 'post'|| operation == 'POST'){
                this.createIPAccessContract(jsonObj)
            }
            if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-contract', {'id': jsonObj.id});
                console.log("IPContractAccess to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('ip-access-contract', policyObj);
                }
            }
            if (operation == 'put' || operation == 'PUT') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-contract', {'id': jsonObj.id});
                console.log("Ip-policyObj to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('ip-access-contract', policyObj);
                }
                this.createIPAccessContract(jsonObj)
            }
        },
        createIPAccessContract: function (jsonObj){
            var tJson = JSON.parse(JSON.stringify(ContractPolicyTemplate.IPAccessContract[0]));
            if(jsonObj.id != undefined){
                tJson.id = jsonObj.id
            }
            else{
                tJson.id = UtilityFunctions.generate_uuid();
            }
            tJson.instanceId = UtilityFunctions.getRandomIntForRangeValues(4007,4020);
            tJson.displayName = tJson.instanceId;
            tJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
            tJson.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
            tJson.createTime = UtilityFunctions.getTimeStamp();
            tJson.description = jsonObj.description;
            tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
            tJson.name = jsonObj.name;
            tJson.type = jsonObj.type;
            tJson.internal = false;
            tJson.clause[0].id = UtilityFunctions.generate_uuid();
            tJson.clause[0].instanceId = (tJson.instanceId)+1000;
            tJson.clause[0].displayName = tJson.clause[0].instanceId;
            tJson.clause[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
            tJson.clause[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
            tJson.clause[0].access = jsonObj.clause[0].access;

            for (var j = 0; j < jsonObj.contractClassifier.length; j++) {
                    var classifier = {};
                    classifier.id = UtilityFunctions.generate_uuid();
                    classifier.instanceId = UtilityFunctions.getRandomIntForRangeValues(5000,50000);
                    classifier.displayName = "0";
                    classifier.instanceVersion = 0;
                    classifier.additionalActions = [];
                    classifier.priority = generateNumericId(10)
                    classifier.sourcePorts = [];
                    classifier.srcNetworkIdentities = []
                    classifier.logging = "OFF"
                    classifier.access = jsonObj.contractClassifier[j].access;
                    classifier.scalableGroup = jsonObj.contractClassifier[j].scalableGroup;
                    tJson.contractClassifier.push(classifier);
            }
            SimLokiDatabaseActions.insert('ip-access-contract', tJson);
        },

        CUDIPAccessControl: function (taskId, operation, jsonObj){
            if (operation == 'post'|| operation == 'POST'){
                this.createIPAccessControl(taskId, jsonObj)
            }
            if (operation == 'delete') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-control', {'id': jsonObj.id});
                console.log("IPContractAccess to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('ip-access-control', policyObj);
                }
            }
            if (operation == 'put' || operation == 'PUT') {
                var policyObj = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-control', {'id': jsonObj.id});
                console.log("Ip-policyObj to be deleted = " + JSON.stringify(policyObj));
                if (policyObj && policyObj.length) {
                    SimLokiDatabaseActions.delete('ip-access-control', policyObj);
                }
                this.createIPAccessControl(jsonObj)
            }
        },
        createIPAccessControl: function (taskId, jsonObj){
            if(jsonObj.deleteList){
                for(k=0;k<jsonObj.deleteList.length;k++){
                    var rec1 = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-control-policy',{'id':jsonObj.deleteList[k]});
                    var namespace = 'policy:ipaccess:'+rec1[0].name;
                    var rec2 = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-control',{'namespace':namespace});
                    if(rec2.length>0){
                        SimLokiDatabaseActions.delete('ip-access-control',rec2);
                    }
                    SimLokiDatabaseActions.delete('ip-access-control-policy',rec1);
                }
            }else{
                var tJson = JSON.parse(JSON.stringify(ContractPolicyTemplate.IPBasedAcessContorl_Policy[0]));
                if(jsonObj.id != undefined){
                    tJson.id = jsonObj.id
                }
                else{
                    tJson.id = UtilityFunctions.generate_uuid();
                }
                var groupId = jsonObj.createList[0].advancedPolicyScope.advancedPolicyScopeElement[0].groupId[0] 
                tJson.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                tJson.displayName = tJson.instanceId;
                tJson.createTime = UtilityFunctions.getTimeStamp();
                tJson.description = jsonObj.createList[0].description;
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                tJson.namespace = 'policy:ipaccess:'+jsonObj.createList[0].name;
                tJson.groupId = groupId;
                tJson.workflowId = UtilityFunctions.generate_uuid();
                tJson.taskId = taskId
                var siteobj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':groupId});
                tJson.groupNameHierarchy = siteobj[0].groupNameHierarchy;
                var ssid = jsonObj.createList[0].advancedPolicyScope.advancedPolicyScopeElement[0].ssid[0]
                // ssidObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile',{'name':ssid});need to review Karan
                var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and': [ {'siteId': { '$in': groupId }},{ 'role':"ACCESS" }]});
                // var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and': [{ 'role':"ACCESS" },{'family':'Wireless Controller'}]});
                if(deviceObj.length >0){
                    tJson.deviceId = deviceObj[0].id;
                    tJson.hostname = deviceObj[0].hostname;
                    tJson.managementIpAddress = deviceObj[0].managementIpAddress;
                    tJson.type = deviceObj[0].type;
                }

                for (var i = 0; i < jsonObj.createList.length; i++){
                    var tJson1 = JSON.parse(JSON.stringify(ContractPolicyTemplate.IPBasedAccessControl_Policy_1[0]));
                    tJson1.id = UtilityFunctions.generate_uuid();
                    tJson1.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                    tJson1.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.createTime = UtilityFunctions.getTimeStamp();
                    tJson1.description = jsonObj.createList[i].description;
                    tJson1.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    tJson1.name = jsonObj.createList[i].name;
                    tJson1.namespace = 'policy:ipaccess:'+jsonObj.createList[i].name;
                    tJson1.policyScope = jsonObj.createList[0].name;
                    tJson1.advancedPolicyScope.id =  UtilityFunctions.generate_uuid();
                    tJson1.advancedPolicyScope.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                    tJson1.advancedPolicyScope.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.advancedPolicyScope.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.advancedPolicyScope.name = jsonObj.createList[0].name;
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].id = UtilityFunctions.generate_uuid();
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].instanceId = UtilityFunctions.generate_uuid();
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].groupId[0] = jsonObj.createList[i].advancedPolicyScope.advancedPolicyScopeElement[0].groupId[0];
                    tJson1.advancedPolicyScope.advancedPolicyScopeElement[0].ssid[0] = jsonObj.createList[i].advancedPolicyScope.advancedPolicyScopeElement[0].ssid[0];
                    tJson1.contract.idRef = jsonObj.createList[i].contract.idRef;
                    tJson1.identitySource.id = UtilityFunctions.generate_uuid();
                    tJson1.identitySource.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                    tJson1.identitySource.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.identitySource.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                    tJson1.identitySource.displayName = tJson1.identitySource.instanceId;
                    if(jsonObj.createList[i].consumer != undefined){
                        tJson1.consumer = {}
                        tJson1.consumer.scalableGroup = []
                        tJson1.consumer.id = UtilityFunctions.generate_uuid();
                        tJson1.consumer.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                        tJson1.consumer.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                        tJson1.consumer.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                        tJson1.consumer.scalableGroup['idRef'] = jsonObj.createList[0].consumer.scalableGroup[0].idRef;
                        
                    }
                    if(jsonObj.createList[i].producer != undefined){
                        tJson1.producer = {}
                        tJson1.producer.scalableGroup = []
                        tJson1.producer.id = UtilityFunctions.generate_uuid();
                        tJson1.producer.instanceId = UtilityFunctions.getRandomIntForRangeValues(2863875,2873875);
                        tJson1.producer.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                        tJson1.producer.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                        tJson1.producer.scalableGroup['idRef'] = jsonObj.createList[0].producer.scalableGroup[0].idRef;
                        
                    }
                    SimLokiDatabaseActions.insert('ip-access-control-policy', tJson1);
                }
                SimLokiDatabaseActions.insert('ip-access-control', tJson);
            }
        }

    };
});
