define({
    "Assurance_Issue_Template": {
      version: '1.0',
      response:[{
        id:"WD83FWpzLWYdEAu1qw",
        name:"wlc_power_status_secondary",
        entityType:"network_device",
        entity:"192.168.103.3",
        groupBy:"N/A",
        severity:"HIGH",
        category:"Availability",
        summary:"Secondary power supply has failed on this WLC METRO-WLC .",
        timestamp:0,
        occurrences: 0,
        scope:"GLOBAL",
        totalOccurrences:{count: 626, startTime: 1516070100000, endTime: 1516460100000}
      },
      {
        id:"AWD83FWpzLWYdEAu1rue",
        name:"switch_interface_excess_rx_tx_util",
        entityType:"network_device",
        entity:"",
        groupBy:"Unknown",
        severity:"MEDIUM",
        category:"Connected",
        timestamp:0,
        occurrences: 626,
        scope:"LOCALIZED",
        rootCause:"Unknown",
        status:{notes:"Unknown",source:"Unknown",status:"ACTIVE",updatedAt:null,updatedBy:"Unknown"},
        summary:"High input/output utilization on interface 'TenGigabitEthernet1/0/2'",
        timestamp:0,
        totalOccurrences:{count: 626, startTime: 1516070100000, endTime: 1516460100000}
      },
      {
        id:"AWDrpHa3zLWYdEAu1qF2",
        name:"switch_port_flaps",
        entityType:"Unknown",
        entity:"",
        groupBy:"Unknown",
        severity:"HIGH",
        category:"Device",
        summary:"TenGigabitEthernet1/0/1 is flapping",
        timestamp:0,
        occurrences: 378,
        scope:"LOCALIZED",
        rootCause:"Unknown",
        status:{notes:"Unknown",source:"Unknown",status:"ACTIVE",updatedAt:null,updatedBy:"Unknown"},
        timestamp:0,
        totalOccurrences:{count: 378, startTime: 1516070100000, endTime: 1516460100000}
      },
      {
        id:"AWEhJHtrCTgf5hgfUjn",
        name:"fabric_reachability_session",
        entityType:"network_device",
        entity:"",
        groupBy:"Unknown",
        severity:"HIGH",
        category:"Connected",
        summary:"Fabric Border Lost Connectivity to the DNS Server 192.168.101.105 in the Physical Network",
        timestamp:0,
        occurrences: 145,
        scope:"LOCALIZED",
        rootCause:"7.1.1.1_192.168.99.13_DnsUnderlay",
        status:{notes:"Unknown",source:"Unknown",status:"ACTIVE",updatedAt:null,updatedBy:"Unknown"},
        timestamp:0,
        totalOccurrences:{count: 145, startTime: 1516070100000, endTime: 1516460100000}
      },
      {
        id:"AWEhTHtsCTne5hgfUjn_",
        name:"fabric_reachability_session",
        entityType:"network_device",
        entity:"",
        groupBy:"Unknown",
        severity:"HIGH",
        category:"Connected",
        summary:"Fabric Border Lost Connectivity to the DHCP Server 192.168.101.105 in the Virtual Network Campus",
        timestamp:0,
        occurrences: 145,
        scope:"LOCALIZED",
        rootCause:"7.1.1.1_192.168.101.105_DhcpOverlay_Campus",
        status:{notes:"Unknown",source:"Unknown",status:"ACTIVE",updatedAt:null,updatedBy:"Unknown"},
        timestamp:0,
        totalOccurrences:{count: 145, startTime: 1516070100000, endTime: 1516460100000}
      },
      {
        id:"AWEhTHtrCTne5hgfUjn9",
        name:"fabric_reachability_session",
        entityType:"network_device",
        entity:"",
        groupBy:"Unknown",
        severity:"HIGH",
        category:"Connected",
        summary:"Fabric Border Lost Connectivity to the DNS Server 192.168.101.105 in the Virtual Network Campus",
        timestamp:0,
        occurrences: 145,
        scope:"LOCALIZED",
        rootCause:"7.1.1.1_192.168.99.13_DnsOverlay_Campus",
        status:{notes:"Unknown",source:"Unknown",status:"ACTIVE",updatedAt:null,updatedBy:"Unknown"},
        timestamp:0,
        totalOccurrences:{count: 145, startTime: 1516070100000, endTime: 1516460100000}
      }
      ]
    },
});
