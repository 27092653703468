define({
"GlobalTemplate":
{
  "wireless_client_onboarding_issues_grouping": {
    "displayName": "Wireless Client Onboarding Issues",
    "description": "Wireless Client Onboarding Issues",
    "isGlobal":"false",
    "triggerList": [
      "wireless_client_onboarding",
      "wireless_client_excessive_onboarding",
      "wireless_client_auth_fail_sec_param_mismatch_trigger",
      "wireless_client_roam_auth_fail_sec_param_mismatch_trigger",
      "wireless_client_auth_fail_aaa_rej_trigger",
      "wireless_client_roam_auth_fail_aaa_rej_trigger",
      "wireless_client_auth_fail_aaa_timeout_trigger",
      "wireless_client_roam_auth_fail_aaa_timeout_trigger",
      "wireless_client_auth_fail_client_timeout_trigger",
      "wireless_client_roam_auth_fail_client_timeout_trigger",
      "wireless_client_auth_fail_wlc_error_trigger",
      "wireless_client_roam_auth_fail_wlc_error_trigger",
      "wireless_client_auth_fail_wlc_internal_trigger",
      "wireless_client_roam_auth_fail_wlc_internal_trigger",
      "wireless_client_auth_fail_pmk_notfound_trigger",
      "wireless_client_roam_auth_fail_pmk_notfound_trigger",
      "wireless_client_dhcp_fail_dhcp_timeout_trigger",
      "wireless_client_dhcp_fail_server_timeout_trigger",
      "wireless_client_dhcp_fail_client_timeout_trigger",
      "wireless_client_roam_fail_gen_error_trigger",
      "wireless_client_roam_fail_wlc_config_trigger",
      "wireless_client_excess_auth_server_trigger",
      "wireless_client_excess_auth_wlc_trigger",
      "wireless_client_excess_auth_aaa_fail_trigger",
      "wireless_client_exclude_ip_theft_trigger",
      "wireless_client_exclude_roam_fail_trigger",
      "wireless_client_exclude_wrong_psk_trigger",
      "wireless_client_excess_auth_network_trigger",
      "wireless_client_excess_dhcp_server_fail_trigger"
    ]
  },
  "wired_client_onboarding_failures_grouping": {
    "displayName": "Wired client DHCP issue or authentication failures",
    "description": "Wired client has failed to obtain an IPv4 address from DHCP server or dot1X/MAC Authentication failed",
    "isGlobal":"false",
    "triggerList": [
      "dhcp_reachability_issue",
      "wired_client_onboarding_failures_trigger",
      "wired_client_auth_fail_mab_trigger",
      "wired_client_auth_fail_dot1x_trigger"
    ]
  },
  "device_unreachable_grouping": {
    "displayName": "Network device unreachable from controller",
    "description": "Network device unreachable from controller",
    "isGlobal":"true",
    "triggerList": [
      "snmp_device_down",
      "switch_unreachable",
      "router_unreachable",
      "wlc_unreachable"

    ]
  },
  "device_high_disk_grouping": {
    "displayName": "Network device experiencing high disk usage",
    "description": "Network device experiencing high disk usage",
    "isGlobal":"true",
    "triggerList": [
      "nwDevice_disk_threshold"
    ]
  },
  "tcam_utilization_high_grouping": {
    "displayName": "TCAM Utilization High",
    "description": "Issues for TCAM exhaustion in layer 2, layer 3, QoS and SGACL",
    "isGlobal":"true",
    "triggerList": [
      "tcam_util_high"
    ]
  },
  "high_cpu_utilization_grouping": {
    "displayName": "Device experiencing high CPU utilization",
    "description": "Device experiencing high CPU utilization",
    "isGlobal":"true",
    "triggerList": [
      "snmp_cpu_high_80",
      "switch_cpu_high",
      "router_cpu_high"
    ]
  },
  "high_memory_utilization_grouping": {
    "displayName": "Device experiencing high memory utilization",
    "description": "Device experiencing high memory utilization",
    "isGlobal":"true",
    "triggerList": [
      "snmp_memory_high_80",
      "switch_memory_high",
      "router_memory_high",
      "wlc_memory_high"
    ]
  },
  "syslog_events_issue_grouping": {
    "displayName": "Device Experiencing High Temperature",
    "description": "Device Experiencing High Temperature",
    "isGlobal":"true",
    "triggerList": [
      "default_syslog_event_trigger",
      "network_device_poe_trigger",
      "network_device_high_temp_trigger"
    ]
  },
  "switch_power_supply_failure_grouping": {
    "displayName": "Power supply failure on switch",
    "description": "Switch power failure",
    "isGlobal":"true",
    "triggerList": [
      "switch_issue_power"
    ]
  },
  "wlc_power_supply_failure_grouping": {
    "displayName": "Power supply failure on Wlc",
    "description": "Wlc power failure",
    "isGlobal":"true",
    "triggerList": [
      "wlc_power_supply_trigger"
    ]
  },
  "switch_reboot_issue_grouping": {
    "displayName": "Switch has rebooted due to a hardware or software crash",
    "description": "Switch has rebooted due to a hardware or software crash",
    "isGlobal":"true",
    "triggerList": [
      "switch_issue_reboot"
    ]
  },
  "trap_events_issue_grouping": {
    "displayName": "Stack Member Removal",
    "description": "Stack member was removed",
    "isGlobal":"true",
    "triggerList": [
      "default_trap_event_trigger"
    ]
  },
  "switch_incompatible_image_grouping": {
    "displayName": "Stack Member Running Incompatible Image",
    "description": "Stack member is running an incompatible image",
    "isGlobal":"true",
    "triggerList": [
      "stack_switch_version_mismatch_trigger"
    ]
  },
  "switch_fan_failure_grouping": {
    "displayName": "Fan failure on switch",
    "description": "Fan failure on switch",
    "isGlobal":"true",
    "triggerList": [
      "switch_issue_fan"
    ]
  },
  "interface_connectivity_failure_grouping": {
    "displayName": "Network Connectivity Failures on Network Device",
    "description": "Network Connectivity Failures on Network Device",
    "isGlobal":"true",
    "triggerList": [
      "network_connectivity_trigger",
      "network_connectivity_trigger_BGP",
      "network_ipsla_trigger",
      "network_interface_connectivity",
      "BGP_Flap",
      "OSPF_Down",
      "isis_adjacency_failure",
      "EIGRP_Peering"
    ]
  },
  "ap_down_grouping": {
    "displayName": "AP went down",
    "description": "AP went down",
    "isGlobal":"true",
    "triggerList": [
      "ap_down"
    ]
  },
  "Application_Experience_Issues_trigger_grouping": {
    "displayName": "Network Latency is above threshold",
    "description": "Network Latency is above threshold",
    "isGlobal":"false",
    "triggerList": [
      "Application_Experience_Issues_trigger"
    ]
  },
  "ap_flap_grouping": {
    "displayName": "AP has flapped",
    "description": "AP has flapped",
    "isGlobal":"true",
    "triggerList": [
      "ap_flap"
    ]
  },
  "ap_coverage_hole_grouping": {
    "displayName": "AP has coverage hole",
    "description": "AP has coverage hole",
    "isGlobal":"true",
    "triggerList": [
      "ap_coverage_hole"
    ]
  },
  "radio_util_high_grouping": {
    "displayName": "2.4/5 GHz radio on AP(s) are experiencing high utilization.",
    "description": "2.4/5 GHz radio on AP(s) are experiencing high utilization.",
    "isGlobal":"true",
    "triggerList": [
      "radio_24_util_trigger",
      "radio_5_util_trigger"
    ]
  },
  "interface_io_utilization_high_grouping": {
    "displayName": "High I/O Utilization on network device interface",
    "description": "High I/O Utilization on network device interface",
    "isGlobal":"true",
    "triggerList": [
      "router_interface_excess_rx_tx_util",
      "switch_interface_excess_rx_tx_util"
    ]
  },
  "interface_io_error_high_grouping": {
    "displayName": "High I/O error on network device interface",
    "description": "High I/O error on network device interface",
    "isGlobal":"true",
    "triggerList": [
      "switch_interface_input_output_errors",
      "router_interface_input_output_errors"
    ]
  },
  "ap_cpu_threshold_grouping": {
    "displayName": "AP is experiencing high CPU utilization",
    "description": "AP is experiencing high CPU utilization",
    "isGlobal":"true",
    "triggerList": [
      "ap_cpu_threshold_trigger"
    ]
  },
  "ap_memory_threshold_grouping": {
    "displayName": "AP is experiencing high memory utilization",
    "description": "AP is experiencing high memory utilization",
    "isGlobal":"true",
    "triggerList": [
      "ap_memory_threshold_trigger"
    ]
  },
  "fabric_reachability_grouping": {
    "displayName": "Fabric Reachability Failure",
    "description": "Fabric Reachability Failure",
    "isGlobal": "true",
    "triggerList": [
      "fabric_wlc_reachability",
      "fabric_reachability_session",
      "fabric_reachability_border_underlay_trigger",
      "fabric_reachability_border_overlay_trigger",
      "fabric_reachability_control_underlay_trigger",
      "fabric_reachability_control_border_underlay_trigger",
      "fabric_reachability_ise_trigger",
      "fabric_reachability_dhcp_overlay_trigger",
      "fabric_reachability_dhcp_underlay_trigger",
      "fabric_reachability_dns_overlay_trigger",
      "fabric_reachability_dns_underlay_trigger",
      "fabric_reachability_external_url_trigger"
    ]
  },
  "fabric_map_cache_grouping": {
    "displayName": "Fabric Map Cache Limit Exceeded",
    "description": "Fabric Map Cache Limit Exceeded",
    "isGlobal": "true",
    "triggerList": [
      "snmp_map_cache_limit_reached"
    ]
  },
  "snmp_map_cache_grouping": {
    "displayName": "Map Cache Limit Exceeded",
    "description": "Map Cache Limit Exceeded",
    "isGlobal": "true",
    "triggerList": [
      "snmp_map_cache_limit_reached"
    ]
  },
  "infra_link_down_grouping": {
    "displayName": "Interface Connecting Network Devices is Down",
    "description": "Interface Connecting Network Devices is Down",
    "isGlobal": "true",
    "triggerList": [
      "infra_link_down"
    ]
  },
  "ha_switch_over_grouping": {
    "displayName": "Network Device HA Switch Over",
    "description": "Network Device HA Switch Over",
    "isGlobal": "true",
    "triggerList": [
      "snmp_trap_ha_switchover"
    ]
  },
  "network_device_interface_flaps_grouping": {
    "displayName": "Interface Is Flapping On Network Device",
    "description": "Interface Is Flapping On Network Device",
    "isGlobal": "true",
    "triggerList": [
      "switch_port_flaps"
    ]
  },
  "wlc_issues_grouping": {
    "displayName": "Wireless Controller Issues",
    "description": "Wireless Controller Issues",
    "isGlobal": "true",
    "triggerList": [
      "wlc_system_down",
      "wlc_reboot_crash_trigger",
      "wlc_monitor",
      "wlc_ap_license_exhausted"
    ]
  },
  "ap_reboot_crash_grouping": {
    "displayName": "AP has rebooted due to hardware or software crash",
    "description": "AP has rebooted due to hardware or software crash",
    "isGlobal": "true",
    "triggerList": [
      "ap_reboot_crash_trigger"
    ]
  },
  "policy_error_grouping": {
    "displayName": "Policy Errors",
    "description": "Policy installation/download issues",
    "isGlobal": "true",
    "triggerList": [
      "download_sgacl_aces",
      "sgacl_download_source_list_error",
      "sgacl_install_error",
      "sgacl_uninstall_error",
      "sgacl_install_error_ace"
    ]
  },
  "global_wireless_throughput_issues_grouping": {
    "displayName": "Drop in total radio throughput",
    "description": "Drop in total radio throughput (detected by ML engine)",
    "isGlobal":"true",
    "triggerList": [
      "global_wireless_kairos_radio_throughput_drop_trigger"
    ]
  },
  "global_wireless_social_throughput_issues_grouping": {
    "displayName": "Drop in radio throughput for Social Applications",
    "description": "Drop in radio throughput for Social Applications (detected by ML engine)",
    "isGlobal":"true",
    "triggerList": [
      "global_wireless_kairos_radio_social_app_throughput_drop_trigger"
    ]
  },
  "global_wireless_media_throughput_issues_grouping": {
    "displayName": "Drop in radio throughput for Media Applications",
    "description": "Drop in radio throughput for Media Applications (detected by ML engine)",
    "isGlobal":"true",
    "triggerList": [
      "global_wireless_kairos_radio_media_app_throughput_drop_trigger"
    ]
  },
  "global_wireless_cloud_throughput_issues_grouping": {
    "displayName": "Drop in radio throughput for Cloud Applications",
    "description": "Drop in radio throughput for Cloud Applications (detected by ML engine)",
    "isGlobal":"true",
    "triggerList": [
      "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger"
    ]
  },
  "global_wireless_client_onboarding_issues_grouping": {
    "displayName": "Global Wireless Client Onboarding Issues",
    "description": "Global Wireless Client Onboarding Issues - many wireless clients are having onboarding issues",
    "isGlobal":"true",
    "triggerList": [
      "client_global_issue_by_aaa_30min_5percent_5",
      "client_global_issue_by_dhcp_30min_5percent_5",
      "client_global_issue_by_ssid_30min_10percent_20",
      "client_global_issue_by_site_30min",
      "client_global_issue_by_site_30min_5percent_5",
      "client_global_issue_by_ssid_30min",
      "client_global_issue_by_wlc_15min",
      "client_global_issue_by_wlc_30min",
      "client_global_issue_by_wlc_30min_5percent_5",
      "client_mobility_wlc_config_global",
      "global_wireless_client_auth_fail_sec_param_mismatch_trigger",
      "global_wireless_client_auth_fail_aaa_rej_trigger",
      "global_wireless_client_auth_fail_aaa_timeout_trigger",
      "global_wireless_client_auth_fail_client_timeout_trigger",
      "global_wireless_client_auth_fail_wlc_error_trigger",
      "global_wireless_client_auth_fail_wlc_internal_trigger",
      "global_wireless_client_auth_fail_pmk_notfound_trigger",
      "global_wireless_client_dhcp_fail_dhcp_timeout_trigger",
      "global_wireless_client_dhcp_fail_server_timeout_trigger",
      "global_wireless_client_dhcp_fail_client_timeout_trigger",
      "global_wireless_client_roam_fail_gen_error_trigger",
      "global_wireless_client_roam_fail_wlc_config_trigger",
      "global_wireless_client_excess_auth_server_trigger",
      "global_wireless_client_excess_auth_wlc_trigger",
      "global_wireless_client_excess_auth_aaa_fail_trigger",
      "global_wireless_client_excess_dhcp_server_fail_trigger",
      "global_wireless_client_excess_auth_network_trigger",
      "global_wireless_client_exclude_ip_theft_trigger",
      "global_wireless_client_exclude_roam_fail_trigger",
      "global_wireless_client_exclude_wrong_psk_trigger",
      "client_global_onboarding_time_issue_by_site_baseline_ml",
      "global_wireless_kairos_excessive_onboarding_time_trigger",
      "global_wireless_kairos_excessive_dhcp_time_trigger",
      "global_wireless_kairos_excessive_aaa_time_trigger",
      "global_wireless_kairos_excessive_assoc_time_trigger",
      "global_wireless_kairos_excessive_onboarding_failure_trigger",
      "global_wireless_kairos_excessive_assoc_failure_trigger",
      "global_wireless_kairos_excessive_dhcp_failure_trigger",
      "global_wireless_kairos_excessive_aaa_failure_trigger"
    ]
  },
  "ios_client_connectivity_issues_grouping": {
    "displayName": "IOS Client Connectivity Issues",
    "description": "IOS Client Connectivity Issues",
    "isGlobal":"false",
    "triggerList": [
      "ios_client_disassoc_single",
      "ios_client_disassoc_fail_internal_trigger",
      "ios_client_disassoc_fail_decrypt_fail_trigger",
      "ios_client_disassoc_fail_captive_portal_trigger"
    ]
  },
  "global_ios_client_connectivity_issues_grouping": {
    "displayName": "Global IOS Client Connectivity Issues",
    "description": "Global IOS Client Connectivity Issues - many IOS Clients are disconnecting",
    "isGlobal":"false",
    "triggerList": [
      "ios_client_disassoc_global",
      "global_ios_client_disassoc_fail_captive_portal_trigger",
      "global_ios_client_disassoc_fail_decrypt_fail_trigger"
    ]
  },
  "stp_l2storm_grouping": {
    "displayName": "Layer 2 loop symptoms",
    "description": "Layer 2 loop symptoms",
    "isGlobal":"true",
    "triggerList": [
      "mac_flap_trigger"
    ]
  },
  "global_sensor_issues_grouping":{
    "displayName": "Global Sensor Issues",
    "description": "Global Sensor Issues - Issues reported by multiple sensors",
    "isGlobal":"true",
    "triggerList": [
      "sensor_test_result_global_issue",
      "global_sensor_onboarding_fail_trigger",
      "global_sensor_onboarding_auth_slow_trigger",
      "global_sensor_onboarding_assoc_slow_trigger",
      "global_sensor_onboarding_assoc_fail_trigger",
      "global_sensor_onboarding_ipv4_fail_trigger",
      "global_sensor_onboarding_ipv4_slow_trigger",
      "global_sensor_dns_ping_fail_trigger",
      "global_sensor_dns_fail_trigger",
      "global_sensor_dns_ping_slow_trigger",
      "global_sensor_dns_slow_trigger",
      "global_sensor_host_ping_fail_trigger",
      "global_sensor_host_ping_slow_trigger",
      "global_sensor_onboarding_gw_ping_slow_trigger",
      "global_sensor_onboarding_gw_fail_trigger",
      "global_sensor_radius_server_unreach_trigger",
      "global_sensor_radius_ping_slow_trigger",
      "global_sensor_radius_auth_slow_trigger",
      "global_sensor_radius_auth_fail_trigger",
      "global_sensor_outlook_server_unreach_trigger",
      "global_sensor_outlook_server_ping_slow_trigger",
      "global_sensor_outlook_server_gw_slow_trigger",
      "global_sensor_outlook_resp_slow_trigger",
      "global_sensor_outlook_fail_trigger",
      "global_sensor_web_server_unreach_trigger",
      "global_sensor_web_server_ping_slow_trigger",
      "global_sensor_web_server_gw_slow_trigger",
      "global_sensor_web_server_slow_trigger",
      "global_sensor_web_server_fail_trigger",
      "global_sensor_mailserver_unreach_trigger",
      "global_sensor_mailserver_ping_slow_trigger",
      "global_sensor_mailserver_slow_trigger",
      "global_sensor_mailserver_fail_trigger",
      "global_sensor_ftp_unreach_trigger",
      "global_sensor_ftp_ping_slow_trigger",
      "global_sensor_ftp_transfer_fail_trigger",
      "global_sensor_ftp_fail_trigger",
      "global_sensor_ftp_slow_trigger",
      "global_sensor_webauth_fail_trigger",
      "global_sensor_webauth_config_fail_trigger",
      "global_sensor_webauth_http_fail_trigger",
      "global_sensor_webauth_slow_trigger",
      "global_sensor_speed_fail_trigger",
      "global_sensor_speed_error_trigger",
      "global_sensor_speed_http_error_trigger",
      "global_sensor_speed_url_error_trigger",
      "global_sensor_speed_timeout_trigger",
      "global_sensor_speed_ndt_error_trigger",
      "global_sensor_speed_socket_error_trigger",
      "global_sensor_ipsla_fail_trigger",
      "global_sensor_ipsla_unsupported_probe_trigger",
      "global_sensor_ipsla_socket_trigger",
      "global_sensor_ipsla_noip_trigger",
      "global_sensor_ipsla_noresponse_trigger",
      "global_sensor_uplink_timeout_trigger",
      "global_sensor_uplink_proxy_error_trigger"
      ]
  },
  "device_time_grouping": {
    "displayName": "Device time has drifted from DNAC",
    "description": "Device time has drifted from DNAC",
    "isGlobal":"true",
    "triggerList": [
      "device_time_drift"
    ]
  }
},

"IssueGlobalBaseTemplate":
 {
      "priority": "P2",
      "name": "AP_Down",
      "displayName": "AP Down",
      "issueCategory": "Network",
      "issueCount": 30,
      "deviceCount": 3,
      "areaCount": null,
      "buildingCount": null,
      "floorCount": null,
      "deviceRole": "Unknown",
      "deviceRoleDisplay": "Access Point",
      "isAIDriven": false,
      "category": "availability",
      "deviceNames": [],
      "lastOccurrence": 1563870518912
 },



"GlobalDetailTemplate":
{
  "version": "1.0",
  "response": [],
  "page": {
    "pageSize": 3,
    "offset": 1,
    "totalCount": 3
  },
  "impactedInfo": {
    "issueCount": 3,
    "deviceCount": 3,
    "areaCount": 1,
    "buildingCount": 1,
    "floorCount": 1
  }
},
"GlobalDetailBaseTemplate":
{
      "issueId": "0156cb6c-292c-4f23-9248-bf370825754b",
      "issueMessage": "The 5 GHz radio 1 on AP \"Icap-row11-rack16\" is experiencing high utilization.",
      "deviceId": "8bea589a-8752-4a6c-9962-12a4a197c531",
      "deviceName": "Icap-row11-rack16",
      "deviceType": "Cisco 4800 Series Unified Access Points",
      "impactedLocation": "Global/San Jose/SJC04/Lab252",
      "lastOccurrence": 1563877800000,
      "lastUpdateTime": null,
      "occurrenceCount": 12,
      "instanceCount": null,
      "startTime": 1674009823677,
      "switchName": null,
      "switchUUID": null,
      "updatedBy": null,
      "portId": null,
      "groupBy": null
},

"IssueSiteHierachy":
{
      "hierarchy": "Global/San Jose/SJC04",
      "siteName": "SJC04",
      "siteId": "6a0f8481-ce49-4cba-ae83-16e3280ccee8",
      "parentSiteId": "34cb4756-6b14-4611-9cd0-9d1ecd89c1c4",
      "parentSiteName": "San Jose/SJC04",
      "siteType": "Building",
      "latitude": 37.414334,
      "longitude": -121.933046,
      "issues": {
        "P1": 7,
        "total": 32,
        "P2": 10,
        "P3": 15,
        "P4": 20
      }
},
"AllBuildingTemplate":
[
    {
      "hierarchy": null,
      "siteName": " All Sites",
      "siteId": " All Sites",
      "parentSiteId": null,
      "parentSiteName": null,
      "siteType": null,
      "latitude": null,
      "longitude": null,
      "issues": {
        "P1": 7,
        "P2": 16,
        "total": 32,
        "P3": 9,
        "P4": 10
      }
    },
    {
      "hierarchy": null,
      "siteName": " All Buildings",
      "siteId": " All Buildings",
      "parentSiteId": null,
      "parentSiteName": null,
      "siteType": null,
      "latitude": null,
      "longitude": null,
      "issues": {
        "P1": 7,
        "P2": 16,
        "total": 32,
        "P3": 9
      }
    }
],

"IssueTopCountPriority":
{
  "version": "1.0",
  "response": {
    "PRIORITY": [
      {
        "time": "2019-08-12T10:29:59+0000",
        "entries": [
          {
            "id": "P1",
            "name": "P1",
            "itemType": "Priority",
            "count": 44
          },
          {
            "id": "P2",
            "name": "P2",
            "itemType": "Priority",
            "count": 44
          },
          {
            "id": "P3",
            "name": "P3",
            "itemType": "Priority",
            "count": 44
          },
          {
            "id": "P4",
            "name": "P4",
            "itemType": "Priority",
            "count": 44
          },
          {
            "id": "Total",
            "name": "Total",
            "itemType": "Total",
            "count": 44
          },
          {
            "id": "AIDriven",
            "name": "AIDriven",
            "itemType": "AIDriven",
            "count": 0
          },
          {
            "id": "ThresholdDriven",
            "name": "ThresholdDriven",
            "itemType": "ThresholdDriven",
            "count": 0
          }
        ]
      }
    ]
  }
},

"TopCountSiteTemplate":
{
  "version": "1.0",
  "response": {
    "SITE": [
      {
        "time": "2019-08-12T10:30:00+0000",
        "entries": []
      }
    ]
  }
},


"TopCountSiteEntriesTemplate":
 {
            "id": "5355a2bd-ccd1-4b67-8250-8516a223d30b",
            "name": "Global/San Jose/SJC04/eCA",
            "itemType": "Floor",
            "count": 44,
            "nestedCounts": [
              {
                "id": "P1",
                "name": "P1",
                "itemType": "Priority",
                "count": 44
              },
              {
                "id": "P2",
                "name": "P2",
                "itemType": "Priority",
                "count": 44
              },
              {
                "id": "P3",
                "name": "P3",
                "itemType": "Priority",
                "count": 44
              },
              {
                "id": "P4",
                "name": "P4",
                "itemType": "Priority",
                "count": 44
              },

            ]
 },


"TopCountProrityInterval":
{
        "time": "2019-08-26T07:00:00+0000",
        "entries": [
          {
            "id": "P1",
            "name": "P1",
            "itemType": "Priority",
            "count": 1
          },
          {
            "id": "P2",
            "name": "P2",
            "itemType": "Priority",
            "count": 2
          },
          {
            "id": "P3",
            "name": "P3",
            "itemType": "Priority",
            "count": 1
          },
          {
            "id": "P4",
            "name": "P4",
            "itemType": "Priority",
            "count": 1
          },
          {
            "id": "Total",
            "name": "Total",
            "itemType": "Total",
            "count": 5
          }
        ]
}



})


