define({
"Template":
{
  "version": "1.0",
  "response": [{
    "id": "ec9881d1-c12c-44bf-ad31-23d9df7c8aa0",
    "name": "global_wireless_kairos_excessive_onboarding_failure_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entityName": "Unknown",
    "entity": "a198acd938cdf278341c",
    "groupBy": "a198acd938cdf278341c",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Excessive failures to connect - At least 11% increase in failures on SSID-SSID@ToChange in Site@ToChange.",
    "groupId": "global_wireless_client_onboarding_issues_grouping",
    "groupName": "Global Wireless Client Onboarding Issues",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1607603370695,
    "description": "Error: issue details are missing. Please check connectivity to AI Network Analytics cloud.",
    "suggestions": null,
    "additionalParams": [
      {
        "key": "groupType",
        "value": "site_ssid"
      },
      {
        "key": "_name",
        "value": "global_wireless_kairos_excessive_onboarding_failure_trigger"
      },
      {
        "key": "_entity_type",
        "value": "global"
      },
      {
        "key": "eventSource",
        "value": "Client"
      },
      {
        "key": "groupBy",
        "value": "a198acd938cdf278341c"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "no_suppression"
      },
      {
        "key": "ssid",
        "value": "SSID-RUpA"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/SITE-6Zc_"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "no_suppression"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "triggerSource",
        "value": "kairos"
      },
      {
        "key": "clientCount",
        "value": "181"
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceRole",
        "value": "Wireless"
      },
      {
        "key": "isAIDriven",
        "value": "Yes"
      },
      {
        "key": "kairosIssueId",
        "value": "a198acd938cdf278341c"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "_eventTime",
        "value": "1607603370695"
      },
      {
        "key": "_mostRecent",
        "value": "1607603370695"
      },
      {
        "key": "entityId",
        "value": "a198acd938cdf278341c"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "pctDeviation",
        "value": "11"
      },
      {
        "key": "buildingId",
        "value": "db37b510-43e0-4d90-84c6-43c27e157af6"
      },
      {
        "key": "orgTriggerTime",
        "value": "1607713619246456"
      },
      {
        "key": "buildingName",
        "value": "Global/SITE-6Zc_/BLD-fbnQ"
      },
      {
        "key": "areaId",
        "value": "8545e0a3-9647-4518-a4ad-4cc144c17b96"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/SITE-6Zc_/BLD-fbnQ"
      },
      {
        "key": "category",
        "value": "Onboarding"
      },
      {
        "key": "timestring",
        "value": "2020-12-10T12:29:30.695+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }]
}

})