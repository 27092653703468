define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWWtPqGWAAQ7MfF7gU39",
    "name": "default_syslog_event_trigger",
    "entityType": "network_device",
    "entity": "192.168.120.1",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "POE power controller 1 error.",
    "scope": "GLOBAL",
    "priority": "P3",
    "groupId": "syslog_events_issue_grouping",
    "groupName": "Device Experiencing High Temperature",
    "rootCause": "ILPOWER-3-CONTROLLER_ERR",
    "timestamp": 1536184687000,
    "description": "Controller error Controller number 1: Switch DeviceNamePlaceHolder",
    "suggestions": [
      {
        "message": "Verify the device logs for power controller related event messages",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "messageText",
        "value": "Controller error Controller number 1: Switch 2\u0000"
      },
      {
        "key": "managementIpAddr",
        "value": "192.168.120.1"
      },
      {
        "key": "param5",
        "value": ""
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "192.168.120.1"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "param3",
        "value": ""
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "param4",
        "value": ""
      },
      {
        "key": "param1",
        "value": "1"
      },
      {
        "key": "param2",
        "value": "Switch 2\u0000"
      },
      {
        "key": "messageType",
        "value": "ILPOWER-3-CONTROLLER_ERR"
      },
      {
        "key": "rootCause",
        "value": "ILPOWER-3-CONTROLLER_ERR"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2018-09-05T21:58:07.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})