
define({
  "License_Purchase_Template": {
    "timelineData": [],
    "deviceModelList": [],
    "licenseTenure": [],
    "virtualAccounts": []
  },

  "SEGMENT_TEMPLATE": {
    "segments": [
      {
        "licenseTimeLineStatus": "success",
        "dnacLicense": 2,
        "dnaLevel": "Essentials",
        "expiresIn": "4 year 9 month",
        "virtualAccount": "EFT FIELD SEVT",
        "end": "2023-08-09",
        "term": "N year",
        "isExpired": false,
        "start": "2018-08-11",
        "model": "Cisco Catalyst 9300 Series Switches"
      }
    ],
    "category": "Cisco Catalyst 9300 Series Switches"
  }

});