let baseUrl = "/";
if (typeof window !== "undefined" && window.platformUI && window.platformUI.config) {
  baseUrl = window.platformUI.config.basePath;
}

module.exports = {
  baseUrl,
  waitSeconds: 0,
  paths: {
    c3: "libs/c3/c3.min",
    d3: "libs/d3/d3.min",
    clipboard: "libs/node_modules/clipboard/dist/clipboard.min", // used by cisco.dna.ncp.settings
    "@cisco-dna/react-components": "libs/dna-react-components/dna-react-components"
  },
  map: {
    "*": {
      _: "underscore",
      "apic-em-widgets": "apicem-widgets",
      moments: "moment",
      // TODO some external things still use es6! :(
      es6: "core/utils/es6Stub",
      // unfortunately some plugins use apicem/ to reference core/apicem
      apicem: "core/apicem"
    }
  },
  shim: {
    "jquery.mockjax": {
      deps: ["jquery"]
    },
    c3: {
      deps: ["d3"],
      exports: "c3"
    }
  },
  isBuild: false,
  inlineText: false
};
