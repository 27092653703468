define({
    "Ap_Template": {
        "id": "59b5cd4d-9783-4685-952a-ffe69b199fa1",
        "label": "NetworkDevice",
        "timestamp": "2018-12-07T06:37:09.000+0000",
        "isDeleted": false,
        "type": "vertex",
        "properties": {
            "managementIpAddr": [
                {
                "value": "10.10.10.58"
                }
            ],
            "serialNumber": [
                {
                "value": "FOC21461PGE"
                }
            ],
            "communicationState": [
                {
                "value": "REACHABLE"
                }
            ],
            "nwDeviceName": [
                {
                "value": "AP4800-3"
                }
            ],
            "opState": [
                {
                "value": "4"
                }
            ],
            "platformId": [
                {
                "value": "AIR-AP4800-B-K9"
                }
            ],
            "nwDeviceId": [
                {
                "value": "59b5cd4d-9783-4685-952a-ffe69b199fa1"
                }
            ],
            "mode": [
                {
                "value": "FlexConnect"
                }
            ],
            "resetReason": [
                {
                "value": "Found Configured or Master Controller"
                }
            ],
            "nwDeviceRole": [
                {
                "value": "ACCESS"
                }
            ],
            "upTime": [
                {
                "value": "1544164460"
                }
            ],
            "nwDeviceFamily": [
                {
                "value": "Unified AP"
                }
            ],
            "macAddress": [
                {
                "value": "70:69:5A:38:27:20"
                }
            ],
            "collectionStatus": [
                {
                "value": "Managed"
                }
            ],
            "deviceSeries": [
                {
                "value": "Cisco 4800 Series Unified Access Points"
                }
            ],
            "connectedTime": [
                {
                "value": "1544164584"
                }
            ],
            "softwareVersion": [
                {
                "value": "8.8.104.83"
                }
            ],
            "subMode": [
                {
                "value": "Local"
                }
            ],
            "connectivityStatus": [
                {
                "value": 100
                }
            ],
            "nwDeviceType": [
                {
                "value": "Cisco 4800 Series Unified Access Points"
                }
            ]
        }
    }
});