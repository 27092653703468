define({
"Template":{
  "version": "1.0",
  "response": {
    "id": "AWja1XE-KBEs-UKu0g0U",
    "name": "switch_memory_high",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "10.30.255.103",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Device experiencing high memory utilization",
    "scope": "GLOBAL",
    "priority": null,
    "groupId": "high_memory_utilization_grouping",
    "groupName": "Switch experiencing high memory utilization",
    "rootCause": "Unknown",
    "timestamp": 1549858800000,
    "description": "Network Device DeviceNamePlaceHolder is Experiencing High Memory Utilization 98.22 %",
    "suggestions": [
      {
        "message": "On Switch, verify which process is consuming high memory",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify which process is consuming high memory",
            "command": "show memory platform",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify which process is consuming high memory",
            "command": "show processes memory platform sorted",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify which process is consuming high memory",
            "command": "show platform software status control-processor brief",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the release notes for known bugs, if it is known issue, check if SMU is available and apply on the switch",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.30.255.103"
      },
      {
        "key": "memory",
        "value": "98.22376372904515"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "platformType",
        "value": "Switches and Hubs"
      },
      {
        "key": "entityId",
        "value": "10.30.255.103"
      },
      {
        "key": "threshold",
        "value": "95"
      },
      {
        "key": "message",
        "value": "This Device is experiencing high Memory utilization"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2019-02-11T04:20:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})