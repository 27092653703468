define({
  "Template": {
  "version": "1.0",
  "response": [
   {
    "id": "AWjGQhBUsIPgh8_7RIN9",
    "name": "sensor_test_result_global_issue",
    "enabled": true,
    "flattened": true,
    "entityType": "Sensor",
    "entity": "2572a60e-c5d0-4e9a-a332-28881db6bdc8",
    "groupBy": "Unknown",
    "category": "Sensor Test",
    "severity": "HIGH",
    "summary": "Failed to get an IPv4 address from \"Site@ToChange\"",
    "scope": "GLOBAL",
    "priority": null,
    "rootCause": "sensor_dhcp_global_multiple_failures",
    "timestamp": 1549513800000,
    "description": "3 sensors from \"Site@ToChange\" have failed to get an IPv4 address from DHCP server.",
    "suggestions": [
      {
        "message": "Verify if DHCP pools has enough available IP addresses.",
        "steps": [

        ]
      },
      {
        "message": "Verify if the DHCPv4 service is active.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "summary",
        "value": "Multiple sensor tests failed"
      },
      {
        "key": "locationName",
        "value": "Global/SAJO/SJ23/F1"
      },
      {
        "key": "test",
        "value": "dhcp"
      },
      {
        "key": "entityType",
        "value": "Sensor"
      },
      {
        "key": "__entity_type__",
        "value": "global"
      },
      {
        "key": "failure_count",
        "value": "3.0"
      },
      {
        "key": "entityId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "sensor_dhcp_global_multiple_failures"
      },
      {
        "key": "location",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "category",
        "value": "Sensor Test"
      },
      {
        "key": "device",
        "value": "sensor"
      },
      {
        "key": "timestring",
        "value": "2019-02-07T04:30:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
 ]
}
})