define({
"Template":
{
  "version": "1.0",
  "response": [
    {
      "id": "6C:AD:F8:1C:6B:E6",
      "regUpnName": "Joe's upn",
      "regUpnId": "16724112",
      "regUpnOwner": "Joe Snow",
      "curUpnName": "Joe's upn",
      "curUpnId": "16724112",
      "curUpnOwner": "Joe Snow",
      "status": "Active",
      "upnAllDevice": []
    }
  ]
}
})