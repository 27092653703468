define({
 "Intent_Template": {
    "response": {
        "permissions": [
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b6a2825f5008dbee44b",
                "name": "Read Resource of fusion.apic-em-jboss-ejbca",
                "description": null,
                "resourceName": "fusion.apic-em-jboss-ejbca.default.resource",
                "serviceName": "fusion.apic-em-jboss-ejbca",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b892825f5008dbee9a3",
                "name": "API docs",
                "description": null,
                "resourceName": "network.spf-api-docs",
                "serviceName": "fusion.spf-service-manager-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c52825f5008dbee2a2",
                "name": "Manage mongo-ui",
                "description": null,
                "resourceName": "maglev-system.mongo-ui.default.resource",
                "serviceName": " mongo-ui ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075ae82825f5008dbee3cd",
                "name": "Manage Task-service",
                "description": null,
                "resourceName": "network.task-service",
                "serviceName": "fusion.task-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b6c2825f5008dbee46e",
                "name": "Read Resource of fusion.maintenance-service",
                "description": null,
                "resourceName": "fusion.maintenance-service.default.resource",
                "serviceName": "fusion.maintenance-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.14.0.64036",
                "permissionId": "5a075b602825f5008dbee3f2",
                "name": "Manage Heatmap Computation Swagger Operations",
                "description": null,
                "resourceName": "network.heatmap.docs",
                "serviceName": "fusion.heatmap-service",
                "operationNameSet": [
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b822825f5008dbee84c",
                "name": "Manage Resource of fusion.network-validation-service",
                "description": null,
                "resourceName": "fusion.network-validation-service.default.resource",
                "serviceName": "fusion.network-validation-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b932825f5008dbeeb85",
                "name": "Manage network all elements",
                "description": null,
                "resourceName": "network.inventory-default",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c42825f5008dbee2a0",
                "name": "Manage ndp-flink",
                "description": null,
                "resourceName": "maglev-system.ndp-flink.default.resource",
                "serviceName": " ndp-flink ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075e902825f5008dbeebd2",
                "name": "Manage Resource of ndp.schemaregistry",
                "description": null,
                "resourceName": "ndp.schemaregistry.default.resource",
                "serviceName": "ndp.schemaregistry",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075fba2825f5008dbeed0e",
                "name": "Manage Image-Management",
                "description": null,
                "resourceName": "network.swim-service",
                "serviceName": "fusion.swim-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c32825f5008dbee29a",
                "name": "Manage kafka-ui",
                "description": null,
                "resourceName": "maglev-system.kafka-ui.default.resource",
                "serviceName": " kafka-ui ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b872825f5008dbee958",
                "name": "Get Device Config Status",
                "description": null,
                "resourceName": "network.device-config-status",
                "serviceName": "fusion.spf-service-manager-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b6d2825f5008dbee46f",
                "name": "Manage Resource of fusion.maintenance-service",
                "description": null,
                "resourceName": "fusion.maintenance-service.default.resource",
                "serviceName": "fusion.maintenance-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7a2825f5008dbee6a7",
                "name": "Read Resource of fusion.messaging-persistence-scanner-service",
                "description": null,
                "resourceName": "fusion.messaging-persistence-scanner-service.default.resource",
                "serviceName": "fusion.messaging-persistence-scanner-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f352825f5008dbeec11",
                "name": "Manage Resource of ndp.pipelineadmin",
                "description": null,
                "resourceName": "ndp.pipelineadmin.default.resource",
                "serviceName": "ndp.pipelineadmin",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b722825f5008dbee4cc",
                "name": "Read Resource of fusion.resource-manager-service",
                "description": null,
                "resourceName": "fusion.resource-manager-service.default.resource",
                "serviceName": "fusion.resource-manager-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7c2825f5008dbee723",
                "name": "Manage Templates",
                "description": null,
                "resourceName": "network.template-programmer",
                "serviceName": "fusion.template-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b842825f5008dbee8bb",
                "name": "Manage Resource of fusion.identity-manager-pxgrid-service",
                "description": null,
                "resourceName": "fusion.identity-manager-pxgrid-service.default.resource",
                "serviceName": "fusion.identity-manager-pxgrid-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.4.504",
                "permissionId": "5a0758c72825f5008dbee313",
                "name": "Manage Resource of maglev-system.workflow-ui",
                "description": null,
                "resourceName": "maglev-system.workflow-ui.default.resource",
                "serviceName": "maglev-system.workflow-ui",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b842825f5008dbee8ac",
                "name": "Manage IV Settings",
                "description": null,
                "resourceName": "network.iv.settings",
                "serviceName": "fusion.integrity-verification-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b942825f5008dbeeb89",
                "name": "Manage network global credential",
                "description": null,
                "resourceName": "network.global-credential",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f472825f5008dbeec1c",
                "name": "Manage Resource of ndp.queryengine",
                "description": null,
                "resourceName": "ndp.queryengine.default.resource",
                "serviceName": "ndp.queryengine",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "2.1.0.64036",
                "permissionId": "5a075b772825f5008dbee59c",
                "name": "Manage Licenses for licensemanager for network admin CCO",
                "description": null,
                "resourceName": "network.cco.licensemanager",
                "serviceName": "fusion.licensemanager",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c22825f5008dbee298",
                "name": "Manage kube-dashboard",
                "description": null,
                "resourceName": "maglev-system.kube-dashboard.default.resource",
                "serviceName": " kube-dashboard ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8a2825f5008dbeea04",
                "name": "Manage network programmer api docs",
                "description": null,
                "resourceName": "network.programmer-api-docs",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b722825f5008dbee4cd",
                "name": "Read Resource of fusion.search-service",
                "description": null,
                "resourceName": "fusion.search-service.default.resource",
                "serviceName": "fusion.search-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b952825f5008dbeeb95",
                "name": "Manage network prime credential",
                "description": null,
                "resourceName": "network.prime-credential",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075ae72825f5008dbee3c7",
                "name": "ManageFiles",
                "description": null,
                "resourceName": "network.file-service",
                "serviceName": "fusion.file-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.4.504",
                "permissionId": "5a0758b42825f5008dbee309",
                "name": "Manage Resource of maglev-system.platformui",
                "description": null,
                "resourceName": "maglev-system.platformui.default.resource",
                "serviceName": "maglev-system.platformui",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.14.0.64036",
                "permissionId": "5a075b622825f5008dbee429",
                "name": "Manage Wireless Maps Swagger operations",
                "description": null,
                "resourceName": "network.wireless-maps-docs",
                "serviceName": "fusion.dna-maps-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "2.1.0.64036",
                "permissionId": "5a075b762825f5008dbee581",
                "name": "Manage Licenses for CCO credentials apis in licensemanager",
                "description": null,
                "resourceName": "network.cco.licensemanager",
                "serviceName": "fusion.licensemanager",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b882825f5008dbee987",
                "name": "CFS Interrupt",
                "description": null,
                "resourceName": "network.cfs-interrupt",
                "serviceName": "fusion.spf-service-manager-service",
                "operationNameSet": [
                    "gCreate"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b952825f5008dbeeb93",
                "name": "Manage network location",
                "description": null,
                "resourceName": "network.location",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b812825f5008dbee838",
                "name": "Read Resource of fusion.network-validation-service",
                "description": null,
                "resourceName": "fusion.network-validation-service.default.resource",
                "serviceName": "fusion.network-validation-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b852825f5008dbee8d3",
                "name": "Manage IPpool",
                "description": null,
                "resourceName": "network.ipam-service",
                "serviceName": "fusion.ipam-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b962825f5008dbeeb97",
                "name": "Manage network segment",
                "description": null,
                "resourceName": "network.segment",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075ae02825f5008dbee325",
                "name": "Read Resource of fusion.distributed-cache-service",
                "description": null,
                "resourceName": "fusion.distributed-cache-service.default.resource",
                "serviceName": "fusion.distributed-cache-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b872825f5008dbee942",
                "name": "ManageSettings",
                "description": null,
                "resourceName": "network.design",
                "serviceName": "fusion.network-design-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075efe2825f5008dbeebe8",
                "name": "Manage Resource of ndp.pipelineruntime-taskmgr",
                "description": null,
                "resourceName": "ndp.pipelineruntime-taskmgr.default.resource",
                "serviceName": "ndp.pipelineruntime-taskmgr",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8e2825f5008dbeea63",
                "name": "Manage network programmer template np",
                "description": null,
                "resourceName": "network.programmer-template-np",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b802825f5008dbee825",
                "name": "Read Resource of fusion.apic-em-event-service",
                "description": null,
                "resourceName": "fusion.apic-em-event-service.default.resource",
                "serviceName": "fusion.apic-em-event-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b942825f5008dbeeb8d",
                "name": "Manage network host",
                "description": null,
                "resourceName": "network.host",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b882825f5008dbee973",
                "name": "Manage Groups",
                "description": null,
                "resourceName": "network.group",
                "serviceName": "fusion.grouping-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b782825f5008dbee5f1",
                "name": "Read Resource of fusion.spf-device-manager-service",
                "description": null,
                "resourceName": "fusion.spf-device-manager-service.default.resource",
                "serviceName": "fusion.spf-device-manager-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b842825f5008dbee8ab",
                "name": "Read unmodifiable cert objects",
                "description": null,
                "resourceName": "network.unmod-pki-broker",
                "serviceName": "fusion.apic-em-pki-broker-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755d02825f5008dbee2fd",
                "name": "Update user password ",
                "description": null,
                "resourceName": "maglev-system.user.default.resource",
                "serviceName": "identitymgmt",
                "operationNameSet": [
                    "gUpdate"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c42825f5008dbee29e",
                "name": "Manage grafana",
                "description": null,
                "resourceName": "maglev-system.grafana.default.resource",
                "serviceName": " grafana ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b6a2825f5008dbee44c",
                "name": "Manage Resource of fusion.apic-em-jboss-ejbca",
                "description": null,
                "resourceName": "fusion.apic-em-jboss-ejbca.default.resource",
                "serviceName": "fusion.apic-em-jboss-ejbca",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c52825f5008dbee2a3",
                "name": "Manage identitymgmt-auth",
                "description": null,
                "resourceName": "maglev-system.identitymgmt-auth.default.resource",
                "serviceName": " identitymgmt-auth ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.14.0.64036",
                "permissionId": "5a075b622825f5008dbee428",
                "name": "Manage Wireless Maps entities and operations",
                "description": null,
                "resourceName": "network.wireless-maps",
                "serviceName": "fusion.dna-maps-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b822825f5008dbee86c",
                "name": "Manage IV Network Devices",
                "description": null,
                "resourceName": "network.iv.network-device",
                "serviceName": "fusion.integrity-verification-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8b2825f5008dbeea22",
                "name": "Manage network programmer manager",
                "description": null,
                "resourceName": "network.programmer-manager",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.9.0.64036",
                "permissionId": "5a0764fe2825f5008dbeeea1",
                "name": "Read Resource of fusion.nfv-provisioning-service",
                "description": null,
                "resourceName": "fusion.nfv-provisioning-service.default.resource",
                "serviceName": "fusion.nfv-provisioning-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075dff2825f5008dbeebbc",
                "name": "ManagePolicyAnalysis",
                "description": null,
                "resourceName": "network.policy-analysis-service",
                "serviceName": "fusion.policy-analysis-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b932825f5008dbeeb87",
                "name": "Manage network device",
                "description": null,
                "resourceName": "network.device",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c32825f5008dbee29d",
                "name": "Manage kibana",
                "description": null,
                "resourceName": "maglev-system.kibana.default.resource",
                "serviceName": " kibana ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b962825f5008dbeeb99",
                "name": "Manage network tag",
                "description": null,
                "resourceName": "network.tag",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b832825f5008dbee89e",
                "name": "Manage Topology Vlan and Vrf",
                "description": null,
                "resourceName": "network.topology-service.vlan-vrf",
                "serviceName": "fusion.topology-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f112825f5008dbeebf3",
                "name": "Manage Resource of ndp.pipelineruntime-taskmgr-data",
                "description": null,
                "resourceName": "ndp.pipelineruntime-taskmgr-data.default.resource",
                "serviceName": "ndp.pipelineruntime-taskmgr-data",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075e5a2825f5008dbeebc7",
                "name": "Manage Resource of ndp.collector-manager",
                "description": null,
                "resourceName": "ndp.collector-manager.default.resource",
                "serviceName": "ndp.collector-manager",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7a2825f5008dbee67b",
                "name": "Manage Resource of fusion.spf-device-manager-service",
                "description": null,
                "resourceName": "fusion.spf-device-manager-service.default.resource",
                "serviceName": "fusion.spf-device-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c32825f5008dbee29b",
                "name": "Manage catalog-api",
                "description": null,
                "resourceName": "maglev-system.catalog-api.default.resource",
                "serviceName": " catalog-api ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.14.0.64036",
                "permissionId": "5a075b602825f5008dbee3f4",
                "name": "Manage Heatmap Computation",
                "description": null,
                "resourceName": "network.heatmap",
                "serviceName": "fusion.heatmap-service",
                "operationNameSet": [
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b832825f5008dbee87d",
                "name": "Manage cert store objects",
                "description": null,
                "resourceName": "network.pki-broker",
                "serviceName": "fusion.apic-em-pki-broker-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7f2825f5008dbee7c9",
                "name": "Manage Config-Archive",
                "description": null,
                "resourceName": "network.config-archive",
                "serviceName": "fusion.config-archive-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8c2825f5008dbeea2d",
                "name": "Manage network programmer config service",
                "description": null,
                "resourceName": "network.programmer-config-service",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.430",
                "permissionId": "5a075f9e2825f5008dbeec3c",
                "name": "ManageWirelessCollector",
                "description": null,
                "resourceName": "data.wirelesscollector",
                "serviceName": "assurance-backend.wirelesscollector",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b882825f5008dbee96e",
                "name": "Customer Facing Service",
                "description": null,
                "resourceName": "network.customer-facing-service",
                "serviceName": "fusion.spf-service-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b952825f5008dbeeb91",
                "name": "Manage network license info",
                "description": null,
                "resourceName": "network.licenseinfo",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b812825f5008dbee836",
                "name": "Manage network poller",
                "description": null,
                "resourceName": "network.poller",
                "serviceName": "fusion.network-poller-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.14.0.64036",
                "permissionId": "5a075b612825f5008dbee412",
                "name": "Manage Matlab Computation",
                "description": null,
                "resourceName": "network.matlab",
                "serviceName": "fusion.matlab-service",
                "operationNameSet": [
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8c2825f5008dbeea3f",
                "name": "Manage network programmer tester",
                "description": null,
                "resourceName": "network.programmer-inventory-tester",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c12825f5008dbee297",
                "name": "Manage identitymgmt",
                "description": null,
                "resourceName": "maglev-system.identitymgmt.default.resource",
                "serviceName": " identitymgmt ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c42825f5008dbee29f",
                "name": "Manage maglev-api",
                "description": null,
                "resourceName": "maglev-system.maglev-api.default.resource",
                "serviceName": " maglev-api ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7b2825f5008dbee6dc",
                "name": "Manage Resource of fusion.orchestration-engine-service",
                "description": null,
                "resourceName": "fusion.orchestration-engine-service.default.resource",
                "serviceName": "fusion.orchestration-engine-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.3.0.64036",
                "permissionId": "5a0764fe2825f5008dbeee84",
                "name": "Manage Resource of fusion.dna-common-service",
                "description": null,
                "resourceName": "fusion.dna-common-service.default.resource",
                "serviceName": "fusion.dna-common-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b812825f5008dbee83c",
                "name": "Manage IV APIs",
                "description": null,
                "resourceName": "network.iv",
                "serviceName": "fusion.integrity-verification-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f232825f5008dbeebfe",
                "name": "Manage Resource of ndp.pipelineruntime-taskmgr-timeseries",
                "description": null,
                "resourceName": "ndp.pipelineruntime-taskmgr-timeseries.default.resource",
                "serviceName": "ndp.pipelineruntime-taskmgr-timeseries",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.3.0.64036",
                "permissionId": "5a0764fd2825f5008dbeee80",
                "name": "Manage NwOrch Session",
                "description": null,
                "resourceName": "network.orchestration-service",
                "serviceName": "fusion.network-orchestration-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.430",
                "permissionId": "5a075f9f2825f5008dbeec5c",
                "name": "ManageAssuranceSite",
                "description": null,
                "resourceName": "data.assurancesite",
                "serviceName": "assurance-backend.nsa-webapp",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.3.0.64036",
                "permissionId": "5a0764fd2825f5008dbeee65",
                "name": "Read Resource of fusion.dna-common-service",
                "description": null,
                "resourceName": "fusion.dna-common-service.default.resource",
                "serviceName": "fusion.dna-common-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b952825f5008dbeeb8f",
                "name": "Manage network interface",
                "description": null,
                "resourceName": "network.interface",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b822825f5008dbee86a",
                "name": "Manage Topology Apps",
                "description": null,
                "resourceName": "network.topology-service.apps",
                "serviceName": "fusion.topology-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gRead",
                    "gCreate"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b882825f5008dbee996",
                "name": "CFS Intent",
                "description": null,
                "resourceName": "network.cfs-intent",
                "serviceName": "fusion.spf-service-manager-service",
                "operationNameSet": [
                    "gCreate"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7b2825f5008dbee6ef",
                "name": "Manage Resource of fusion.messaging-persistence-scanner-service",
                "description": null,
                "resourceName": "fusion.messaging-persistence-scanner-service.default.resource",
                "serviceName": "fusion.messaging-persistence-scanner-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075ae82825f5008dbee3cb",
                "name": "Manage Telemetry",
                "description": null,
                "resourceName": "network.telemetry-service",
                "serviceName": "fusion.telemetry-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c22825f5008dbee299",
                "name": "Manage license-api",
                "description": null,
                "resourceName": "maglev-system.license-api.default.resource",
                "serviceName": " license-api ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c32825f5008dbee29c",
                "name": "Manage zipkin",
                "description": null,
                "resourceName": "maglev-system.zipkin.default.resource",
                "serviceName": " zipkin ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c62825f5008dbee2a4",
                "name": "Manage workflow-ui",
                "description": null,
                "resourceName": "maglev-system.workflow-ui.default.resource",
                "serviceName": " workflow-ui ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b842825f5008dbee8aa",
                "name": "Read Resource of fusion.identity-manager-pxgrid-service",
                "description": null,
                "resourceName": "fusion.identity-manager-pxgrid-service.default.resource",
                "serviceName": "fusion.identity-manager-pxgrid-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8a2825f5008dbee9f9",
                "name": "Manage network programmer",
                "description": null,
                "resourceName": "network.programmer",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b942825f5008dbeeb8b",
                "name": "Manage device discovery",
                "description": null,
                "resourceName": "network.discovery",
                "serviceName": "fusion.apic-em-inventory-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.17.0.64036",
                "permissionId": "5a075fd02825f5008dbeedf2",
                "name": "Manage Resource of fusion.pnp-service",
                "description": null,
                "resourceName": "fusion.pnp-service.default.resource",
                "serviceName": "fusion.pnp-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.9.0.64036",
                "permissionId": "5a0764fe2825f5008dbeeea5",
                "name": "Manage Resource of fusion.nfv-provisioning-service",
                "description": null,
                "resourceName": "fusion.nfv-provisioning-service.default.resource",
                "serviceName": "fusion.nfv-provisioning-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8d2825f5008dbeea54",
                "name": "Manage network programmer api docs template",
                "description": null,
                "resourceName": "network.programmer-api-docs-template",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b752825f5008dbee551",
                "name": "Manage Scheduled Jobs",
                "description": null,
                "resourceName": "network.scheduled-job",
                "serviceName": "fusion.scheduler-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b8b2825f5008dbeea18",
                "name": "Manage network programmer pi",
                "description": null,
                "resourceName": "network.programmer-pi",
                "serviceName": "fusion.apic-em-network-programmer-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b832825f5008dbee883",
                "name": "Manage IV Profiles",
                "description": null,
                "resourceName": "network.iv.profiles",
                "serviceName": "fusion.integrity-verification-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.430",
                "permissionId": "5a075f9f2825f5008dbeec5b",
                "name": "ManageAssurance",
                "description": null,
                "resourceName": "data.assurance",
                "serviceName": "assurance-backend.nsa-webapp",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b892825f5008dbee998",
                "name": "Manage Group Members",
                "description": null,
                "resourceName": "network.group-member",
                "serviceName": "fusion.grouping-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075eeb2825f5008dbeebdd",
                "name": "Manage Resource of ndp.pipelineruntime-jobmgr",
                "description": null,
                "resourceName": "ndp.pipelineruntime-jobmgr.default.resource",
                "serviceName": "ndp.pipelineruntime-jobmgr",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.17.0.64036",
                "permissionId": "5a075fd02825f5008dbeedf1",
                "name": "Read Resource of fusion.pnp-service",
                "description": null,
                "resourceName": "fusion.pnp-service.default.resource",
                "serviceName": "fusion.pnp-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f582825f5008dbeec27",
                "name": "Manage Resource of ndp.nls",
                "description": null,
                "resourceName": "ndp.nls.default.resource",
                "serviceName": "ndp.nls",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b732825f5008dbee4d6",
                "name": "Manage Resource of fusion.resource-manager-service",
                "description": null,
                "resourceName": "fusion.resource-manager-service.default.resource",
                "serviceName": "fusion.resource-manager-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b832825f5008dbee87c",
                "name": "Manage network poller api docs",
                "description": null,
                "resourceName": "network.poller-api-docs",
                "serviceName": "fusion.network-poller-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b7b2825f5008dbee6ce",
                "name": "Read Resource of fusion.orchestration-engine-service",
                "description": null,
                "resourceName": "fusion.orchestration-engine-service.default.resource",
                "serviceName": "fusion.orchestration-engine-service",
                "operationNameSet": [
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b732825f5008dbee4da",
                "name": "Manage Resource of fusion.search-service",
                "description": null,
                "resourceName": "fusion.search-service.default.resource",
                "serviceName": "fusion.search-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c52825f5008dbee2a1",
                "name": "Manage rabbitmq-api",
                "description": null,
                "resourceName": "maglev-system.rabbitmq-api.default.resource",
                "serviceName": " rabbitmq-api ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075ae02825f5008dbee326",
                "name": "Manage Resource of fusion.distributed-cache-service",
                "description": null,
                "resourceName": "fusion.distributed-cache-service.default.resource",
                "serviceName": "fusion.distributed-cache-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b782825f5008dbee5e2",
                "name": "ManageTesseractConnector",
                "description": null,
                "resourceName": "network.tesseract-settings",
                "serviceName": "fusion.tesseract-connector",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "2.1.0.64036",
                "permissionId": "5a075b762825f5008dbee566",
                "name": "Manage Licenses for licensemanager",
                "description": null,
                "resourceName": "network.licensemanager",
                "serviceName": "fusion.licensemanager",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.0",
                "permissionId": "5a0755c62825f5008dbee2a5",
                "name": "Manage system-updater-api",
                "description": null,
                "resourceName": "maglev-system.system-updater-api.default.resource",
                "serviceName": " system-updater-api ",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "1.0.5.105",
                "permissionId": "5a075f6c2825f5008dbeec32",
                "name": "Manage Resource of ndp.pegasus-backend",
                "description": null,
                "resourceName": "ndp.pegasus-backend.default.resource",
                "serviceName": "ndp.pegasus-backend",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.3.0.64036",
                "permissionId": "5a0764fe2825f5008dbeeedd",
                "name": "Manage Wireless Functions",
                "description": null,
                "resourceName": "network.wireless-service",
                "serviceName": "fusion.dna-wireless-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b822825f5008dbee85a",
                "name": "Manage IV KGV DB",
                "description": null,
                "resourceName": "network.iv.kgvdb",
                "serviceName": "fusion.integrity-verification-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            },
            {
                "serviceVersion": "7.1.0.64036",
                "permissionId": "5a075b802825f5008dbee82d",
                "name": "Manage Resource of fusion.apic-em-event-service",
                "description": null,
                "resourceName": "fusion.apic-em-event-service.default.resource",
                "serviceName": "fusion.apic-em-event-service",
                "operationNameSet": [
                    "gUpdate",
                    "gRemove",
                    "gCreate",
                    "gRead"
                ]
            }
        ]
    }
  }
});
