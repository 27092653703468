define({
    "runningImageList":[  
        {  
           "name":"c6880x-adventerprisek9-mz.SPA.155-1.SY1.bin",
           "imageUuid":"9a7c4e53-3d52-4c70-af4f-2f79e7205e47",
           "version":"15.5.1-SY1",
           "softwareType":null,
           "imageType":"SYSTEM_SW",
           "isTaggedGolden":"false",
           "md5Checksum":"26a63c49812cd6a1fd6ac2683eb4152b",
           "shaCheckSum":"17a35db9373490927ed88045a0aaa3698f21c5959f1653e46bee428deef48bc199456b2e8a4990ed044ce1c3b75fc6dc5b1a7a47df9e012ad748fee26e2f8468",
           "createdTime":"",
           "applicationType":"UNKNOWN",
           "feature":null,
           "fileServiceId":null,
           "imageSeries":null,
           "imageSource":"CCO",
           "imageSourceType":"Imported from CCO",
           "imageFamily":"C6880X",
           "vendor":"CISCO",
           "filesize":149416600,
           "deviceCount":0,
           "deviceUuidList":[],
           "smuCount":0,
           "imageAttributes":[  
              {  
                 "propertyName":"release_fcs_date",
                 "propertyValue":"08/Mar/2018"
              },
              {  
                 "propertyName":"image_description",
                 "propertyValue":"ADV ENT SERVICES FULL ENCRYPT"
              },
              {  
                 "propertyName":"release_doc_url",
                 "propertyValue":"https://www.cisco.com/c/en/us/td/docs/switches/lan/catalyst6500/ios/15-5SY/release_notes/release_notes_15_5_SY.html"
              },
              {  
                 "propertyName":"release_doc_name",
                 "propertyValue":"Release Notes for 15.5(1)SY1"
              },
              {  
                 "propertyName":"image_guid",
                 "propertyValue":"8B16BD80909DA3CC5FE6F0274FA1703FDCFD9888"
              },
              {  
                 "propertyName":"mdf_id",
                 "propertyValue":"284855056"
              },
              {  
                 "propertyName":"deviceMNEId",
                 "propertyValue":"163177"
              },
              {  
                 "propertyName":"image_size",
                 "propertyValue":"149416600"
              },
              {  
                 "propertyName":"metadata_trans_id",
                 "propertyValue":"121674410"
              },
              {  
                 "propertyName":"udi",
                 "propertyValue":"PID: C6880-X-LE VID: V01, SN: SAL1944SCRX"
              },
              {  
                 "propertyName":"min_dram",
                 "propertyValue":"2048"
              },
              {  
                 "propertyName":"min_flash",
                 "propertyValue":"2048"
              },
              {  
                 "propertyName":"encryption_software_indicator",
                 "propertyValue":"Y"
              }
           ],
           "isSMUApplicable":false,
           "goldenTagInheritedFromSiteUuid":null,
           "goldenTagInheritedFromSiteName":null,
           "importSupported":true,
           "reasonForNotSupportingImport":null,
           "tagList":[  
              {  
                 "taggedGolden":false,
                 "role":"ALL",
                 "inheritedSiteName":"",
                 "inheritedSiteUuid":""
              },
              {  
                 "taggedGolden":false,
                 "role":"CORE",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"DISTRIBUTION",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"BORDER ROUTER",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"UNKNOWN",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"ACCESS",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              }
           ],
           "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
           "imageCategory":"PHYSICAL",
           "imageIntegrityStatus":null,
           "ccorecommended":false,
           "ccolatest":true
        }
     ]
});