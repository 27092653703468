define({
    "AllDeviceStore_GraphQl_Template":
        {
            "data": {
                "allDeviceStore": {
                    "items": [],
                    "__typename": "allDeviceStoreResponse"
                }
            }
        }
});