define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/ippool/IpPoolTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',

], function (SimLokiDatabaseActions, DefaultConfig, IpPoolTemplate, UtilityFunctions, SimTaskDataGenerator,SimDesignData) {
  var subnetCollectionsOfAllParents = [];
  function getIpPoolCidr(addrStart, mask) {
        var tAddr = addrStart + '.' + addrStart + '.0.0';
      return tAddr + '/' + mask;
  }

  function getIpPoolName(addrStart, mask, nameSuffix) {
      var tAddr = addrStart + '.' + addrStart + '.0.0';
      return tAddr + '-' + nameSuffix;
  }

  //Returns the next available range of the subnet like (0-7,8-15,16-31,etc for /29 subne mask)
  //@maxNoOfHosts - No of hosts
  //@subnetToConsider - subnet to consider if it fits in the range and unused
  //@mask Parent cidr Mask to determine if its class A or B or C
  function getNextSubnetRange(maxNoOfHosts, subnetToConsider,mask){
   //2^32/2^16 = HostsLimit...
    var hostsLimit = Math.pow(2,32)/Math.pow(2,mask);
    if(subnetToConsider == null)
    {
      subnetToConsider = {start:0,end:(maxNoOfHosts-1)};
    }
    else
    {
       subnetToConsider = {start:subnetToConsider.end+1,end:subnetToConsider.end+maxNoOfHosts};
    }
    if(subnetToConsider.end >= hostsLimit)
      return null;
    return subnetToConsider;
  }

  //Returns the next available range of the subnet like (0-7,8-15,16-31,etc for /29 subne mask)
  //@maxNoOfHosts - No of hosts
  //@subPoolCidr - TCidrValue of the subpool
  //@mask Parent cidr Mask to determine if its class A or B or C
  function getExactSubnetRange(maxNoOfHosts, subPoolCidr,mask){
   //2^32/2^16 = HostsLimit...
    var hostsLimit = Math.pow(2,32)/Math.pow(2,mask);
    var subnetToConsider ={};
    if(subPoolCidr != undefined )
    {
      var parts = subPoolCidr.split('.');
      if(parts.length > 0 &&  parts[3] != undefined)
      {//Class B
       if(mask <24 && parts[2] != undefined)
       {
         var startingNo = Number(parts[2])*256;
         startingNo = startingNo + Number(parts[3]);
         subnetToConsider = {start:startingNo,end:Number(startingNo)+Number(maxNoOfHosts)};
       }
       else
       {//Class A
        subnetToConsider = {start:parts[3],end:Number(parts[3])+Number(maxNoOfHosts)};
       }
      }
    }
    // if(subnetToConsider.end < hostsLimit) //Need to Revisit previous >= Karan
    //   return null;
    return subnetToConsider;
  }



//Check if the subnet fits in the range..
//@maxNoOfHosts - No of hosts
//@parentCidr cidr of parent
//@subPoolCidr cidr of the subpool
  function getSubnetMaskRange(maxNoOfHosts, parentCidr,subPoolCidr)
  {
      var subnetRangeToConsider = null;
      var octets='',mask = '',isExactSubnetAsked = false;
      if(parentCidr != undefined && parentCidr.length >0)
      {
       octets = parentCidr[0];
       mask = parentCidr[1];
      }
      if(subPoolCidr!= undefined && !subPoolCidr.startsWith('0.0'))
      {
      //When there is a choice of subnet wanted, this flag isset
       isExactSubnetAsked = true;
       subnetRangeToConsider = getExactSubnetRange(maxNoOfHosts,subPoolCidr,mask);
       if(subnetRangeToConsider == null){
         return 'CONFLICT';
       }
      }
      else
      {
       subnetRangeToConsider = getNextSubnetRange(maxNoOfHosts, subnetRangeToConsider, mask);
      }
      var subnetCollections = subnetCollectionsOfAllParents[octets];
      if(subnetCollections != undefined && subnetCollections.length > 0)
      {
       while(subnetRangeToConsider != null)
       {
        for(var subnetIndex in subnetCollections)
        {
           //InBetween range
          if((Number(subnetRangeToConsider.start) > Number(subnetCollections[subnetIndex].end) && ((subnetIndex < subnetCollections.length-1) ?  Number(subnetRangeToConsider.end) < Number(subnetCollections[Number(subnetIndex)+1].start) : false)) ||
              //Lowest range
              (Number(subnetRangeToConsider.end) < Number(subnetCollections[subnetIndex].start) && subnetIndex == 0) ||
              //Highest range
              (Number(subnetRangeToConsider.start) > Number(subnetCollections[subnetIndex].end) && subnetIndex == subnetCollections.length -1))
            {
             subnetCollections.push(subnetRangeToConsider);
             subnetCollections.sort(function(a, b){return a.start-b.start});
             console.log(' Adding .....' +subnetRangeToConsider.start +' '+subnetRangeToConsider.end);
             subnetCollectionsOfAllParents[octets] = subnetCollections;
             return subnetRangeToConsider;
            }
        }
        if(isExactSubnetAsked)// When the user gives specific subnet to be used, this code is covered
        {
          return 'CONFLICT';
        }
        subnetRangeToConsider = getNextSubnetRange(maxNoOfHosts, subnetRangeToConsider,mask);
       }
      }
      else
      {
        subnetCollections = [subnetRangeToConsider];
        subnetCollectionsOfAllParents[octets] = subnetCollections;
        return subnetRangeToConsider;
      }
      return null;
  }

//Function forms the full Address after determining the range..
  function getSubnetMask(maxNoOfHosts,parentCidr,cidrNo,cidrAddr)
  {
     var rangeBeginner = getSubnetMaskRange(maxNoOfHosts,parentCidr,cidrAddr);
     var subnetMask = ''
     if(rangeBeginner != null && rangeBeginner == 'CONFLICT')
     {
       return 'CONFLICT';
     }
     if(rangeBeginner != null && parentCidr != null && parentCidr.length > 1)
     {
     //ParentCidr = 172.168.102.0/24  Now get the next subnet beginning and form the mask from the parentCidr as 172.168.102.<rangeBeginner>
       var noOfOctets = Math.floor(parentCidr[1]/8);
       var octets = parentCidr[0].split('.');

       var index= 0,subnetMask = '';
       while(index < noOfOctets)
       {
         subnetMask = subnetMask+octets[index]+'.';
         index++;
       }
       //noOfOctets shows how many octets are fixed. Meant as class A or Class B or Class C Address
       //Class C
       if(noOfOctets == 3)
       {
        subnetMask = subnetMask+rangeBeginner.start;
       }
       //Class B
       else if (noOfOctets == 2)
       {
        noOfSubnetsCovering = Number((Number(rangeBeginner.end)+1) - rangeBeginner.start)/256;
        var thirdOctet = Math.round(Number(rangeBeginner.start)/256);
        var fourthOctet = Math.round(Number(rangeBeginner.start)%256);
        subnetMask = subnetMask+thirdOctet+'.'+fourthOctet;
       }
     }
     return subnetMask;
  }


  function isValidateJsonObj(ippoolObj){
     var subnetMaskVal = null;
     var parentObj;
     if(ippoolObj != null && ippoolObj.parentUuid != undefined && (ippoolObj.ipPoolCidr != undefined || ippoolObj.numberOfIps != undefined))
        {
          parentObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id' : ippoolObj.parentUuid });

          var cidrNo,parentCidrNo,maxNoOfSubnets,maxNoOfHosts,cidrAddr=null;
          if(parentObj!= undefined && parentObj[0].ipPoolCidr != undefined)
          {
            var parentCidr = parentObj[0].ipPoolCidr.split('/');
            //Calculate Max no of Hosts and max no of subnets from the cidr values of parent pool and the pool to be created.
            if(ippoolObj.ipPoolCidr != undefined)
            {
             var cidrValue = ippoolObj.ipPoolCidr.split('/');
             if(cidrValue.length > 1 && parentCidr.length > 1)
             {
               parentCidrNo = parentCidr[1];
               cidrNo = cidrValue[1];
               cidrAddr = cidrValue[0];
               maxNoOfSubnets = (cidrNo - parentCidrNo);
               maxNoOfSubnets = Math.pow(2,maxNoOfSubnets);
               maxNoOfHosts = (32-cidrNo);
               maxNoOfHosts = Math.pow(2,maxNoOfHosts);
             }
            }//If user specifies the number of ips needed only, following is used
            else if(ippoolObj.numberOfIps != undefined)
            {
               var maxNoOfHosts = ippoolObj.numberOfIps;
               for(var hostsIndex=0;hostsIndex<32;hostsIndex++)
               {
                 if(maxNoOfHosts > Math.pow(2,hostsIndex))
                 {
                  continue;
                 }
                 else if(maxNoOfHosts == Math.pow(2,hostsIndex) || maxNoOfHosts < Math.pow(2,hostsIndex))
                 {
                   cidrNo = hostsIndex;
                   break;
                 }
               }
               //Assign the maximum number of hosts If requested for 10, assign 16 hosts...
               maxNoOfHosts = Math.pow(2,cidrNo);
               //Calculating the number of 1s in the bit formation of subnetmask.
               if(cidrNo <= 8 )    //Class C
               {
                 cidrNo = 8-cidrNo;
                 cidrNo = 24+cidrNo
               }
               else if(cidrNo <= 16 ) //Class B
               {
                 cidrNo = 16-cidrNo;
                 cidrNo = 16+cidrNo
               }
            }
            subnetMaskVal = getSubnetMask(maxNoOfHosts,parentCidr,cidrNo,cidrAddr);
            if(subnetMaskVal != null && subnetMaskVal != 'CONFLICT')
            {
              subnetMaskVal = (subnetMaskVal != '') ? (subnetMaskVal +'/'+cidrNo) : '';
            }
           }
        }else{

          if(ippoolObj.ipPoolCidr != undefined)
            {
             var cidrValue = ippoolObj.ipPoolCidr.split('/');
             if(cidrValue.length > 1)
             {
               cidrNo = cidrValue[1];
               cidrAddr = cidrValue[0];
               maxNoOfSubnets = Math.pow(2,cidrNo);
               maxNoOfHosts = (32-cidrNo);
               maxNoOfHosts = Math.pow(2,maxNoOfHosts);
             }
             subnetMaskVal = ippoolObj.ipPoolCidr;
            }
        }
      var data=[],data1=[];
      if(subnetMaskVal != '')
      {
        var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolName' : ippoolObj.ipPoolName });
        var tObj1 = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolCidr' : ippoolObj.subnetMaskVal });

        data = tObj && tObj.length ? tObj : [];
        data1 = tObj1 && tObj1.length ? tObj1 : [];
      }

      if(subnetMaskVal != '' && data.length == 0 && data1.length == 0 && subnetMaskVal != 'CONFLICT') {
          return { isValid: true, subnetMask: subnetMaskVal, totalIpAddressCount: maxNoOfHosts,progress: 'Creating ip pool ' + ippoolObj.ipPoolCidr};
      } else {
          var tFailureReason = '';
          var tProgress = '';
          var tErrorCode = '';
         if(subnetMaskVal != null && subnetMaskVal == 'CONFLICT')
          {
              tFailureReason = 'Proposed subpool '+ippoolObj.ipPoolCidr +' conflicts with existing subpools in parent pool '+parentObj[0].ipPoolCidr;
              tErrorCode = "ResourceConflict";
              tProgress = "Failed to reserve IP Pool";
              deleteEntryInSubCollections(parentObj[0].ipPoolCidr,subnetMaskVal);
          }
          else if(subnetMaskVal == '' || data1.length)
          {
              tFailureReason = "The parent pool "+ parentObj[0].ipPoolName+" does not have sufficient IPs to accommodate the proposed subpool "+ippoolObj.ipPoolName;
              tErrorCode = "ResourceConflict";
              tProgress = "Failed to reserve IP Pool";
          }
          else if(data.length) {
              tFailureReason = "A subpool called "+ippoolObj.ipPoolName+" already exists " ;
              tErrorCode = "ResourceConflict";
              tProgress = "Failed to reserve IP Pool";
              deleteEntryInSubCollections(parentObj[0].ipPoolCidr,subnetMaskVal);
          }


          return { isValid : false, failureReason: tFailureReason, progress: tProgress, errorCode: tErrorCode };
      }
  }

  function createIpPool(urlAction){

      console.log(urlAction.restPayload);
      var ippoolObj = urlAction.restPayload;
      var ippoolObj1 = urlAction.restPayload;
      var ippoolid =UtilityFunctions.generate_uuid();
      var resultObj = SimTaskDataGenerator.createTask('Ipam Service',{ id: ippoolid });
      var ipPoolCidr = urlAction.restPayload.ipPoolCidr;
      if(urlAction.service.indexOf("group") >=0){
        var ippoolObj = urlAction.restPayload.ipPools[0]
        var ipPoolCidr = urlAction.restPayload.ipPoolCidr;
      }
      
      if (ipPoolCidr != undefined){
        if (ipPoolCidr.indexOf(":") >=0){
          var tValidObj = ippoolObj
          tValidObj.isValid = true
        }else{
          var tValidObj = isValidateJsonObj(ippoolObj);
        }
      }else{
        var tValidObj = isValidateJsonObj(ippoolObj);
      }
      if(tValidObj.isValid) {
          var tJson = JSON.parse(JSON.stringify(IpPoolTemplate.IPPool_Template));
          tJson.id = ippoolid;
          tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
          tJson.createTime = UtilityFunctions.getTimeStamp();
          tJson.ipPoolCidr = tValidObj.subnetMask;
          tJson.ipPoolName = ippoolObj.ipPoolName;
          tJson.gateways   = ippoolObj.gateways;
          tJson.dhcpServerIps    = ippoolObj.dhcpServerIps;
          tJson.dnsServerIps     = ippoolObj.dnsServerIps;
          tJson.totalIpAddressCount = tValidObj.totalIpAddressCount;
          tJson.totalAssignableIpAddressCount = ippoolObj.totalAssignableIpAddressCount;
          tJson.availableIpAddressCount = ippoolObj.availableIpAddressCount;
          if (ipPoolCidr != undefined){
            if (ipPoolCidr.indexOf(":") >=0){
              tJson.ipv6 = true 
              tJson.ipPoolCidr = tValidObj.ipPoolCidr
              tJson.totalIpAddressCount = 4294967296
              tJson.usedIpAddressCount = 0
              tJson.usedPercentage = 0
            }
          }

          if(urlAction.service.indexOf("subpool") >=0){
            tJson.parentUuid = ippoolObj.parentUuid;
            tJson.owner =ippoolObj.ipPoolOwner;
            tJson.context =[{"owner": ippoolObj.ipPoolOwner,"contextKey": "reserved_by","contextValue": ippoolObj.ipPoolOwner},
                            {owner: ippoolObj.ipPoolOwner, contextKey: ippoolObj.context[0].contextKey, contextValue: ippoolObj.context[0].contextValue}  ];
            tJson.siteId="undefined"
          }else if(urlAction.service.indexOf("group") >=0){
            tJson.parentUuid = ippoolObj.parentUuid;
            tJson.owner =ippoolObj.ipPoolOwner;
            tJson.context =[{"owner": ippoolObj.ipPoolOwner,"contextKey": "reserved_by","contextValue": ippoolObj.ipPoolOwner},
                            {owner: ippoolObj.ipPoolOwner, contextKey: "siteId", contextValue: ippoolObj1.siteId}  ];
            tJson.type = ippoolObj1.type
            tJson.siteId = ippoolObj1.siteId
            tJson.groupName = ippoolObj1.groupName
            tJson.ipPoolName = ippoolObj1.groupName
          }
          SimLokiDatabaseActions.insert('ippool', tJson);
          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
      } else {
          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: true, failureReason: tValidObj.failureReason, errorCode: tValidObj.errorCode });
      }
      resultObj.statusCode= 202;
      return resultObj;
  }

  function updateIpPool(urlAction) {
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        var id = urlAction.action.id;
        var reqObject = urlAction.restPayload;
        var ippoolObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id': id});
        ippoolObj[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
        if(urlAction.service.indexOf("group") >=0){
          ippoolObj[0].gateways   = reqObject.ipPools.gateways;
          ippoolObj[0].ipPoolName = reqObject.groupName;
          ippoolObj[0].dhcpServerIps    = reqObject.ipPools.dhcpServerIps;
          ippoolObj[0].dnsServerIps     = reqObject.ipPools.dnsServerIps;
          // ippoolObj[0].overlapping= reqObject.overlapping
          ippoolObj[0].overlapping = false
        }else{
          ippoolObj[0].gateways   = reqObject.gateways;
          ippoolObj[0].ipPoolName = reqObject.ipPoolName;
          ippoolObj[0].dhcpServerIps    = reqObject.dhcpServerIps;
          ippoolObj[0].dnsServerIps     = reqObject.dnsServerIps;
          // ippoolObj[0].overlapping= reqObject.overlapping
          ippoolObj[0].overlapping = false
          console.log(ippoolObj);
        }
        SimLokiDatabaseActions.update('ippool', ippoolObj);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
      return resultObj;
  }

//Splits IPMask for the given cidr
//@ipPoolCidr cidr value
  function splitIPMask(ipPoolCidr){
     ipAddr = ipPoolCidr.split('/');
     ipMask = ipAddr[0].split('.');
     return {addr: ipAddr, mask: ipMask};
  }

//Deletes the pool entry from the subnetCollections variable.
//ipPoolCidr cidr of the parent
//subPoolCidr cidr of the subpool
  function deleteEntryInSubCollections(ipPoolCidr,subPoolCidr){

    if(ipPoolCidr != undefined && ipPoolCidr.length > 0 && subPoolCidr != undefined && subPoolCidr.length > 0)
    {
        var ipObj = splitIPMask(ipPoolCidr);
        if(ipObj != undefined && ipObj.addr != undefined )
        {   //If any IP pool is released, the corresponding entry form the subnetCollections need to be removed, so that the subnet can be allocated for any other request..
          var ipAddr = ipObj.addr;
          var subnetCollections = subnetCollectionsOfAllParents[ipAddr[0]];
          var subIpObj = splitIPMask(subPoolCidr);
          if(subIpObj != undefined &&  subIpObj.mask != undefined)
          {
          var subIpMask = subIpObj.mask;
          if(Number(ipAddr[1]) < 24)
          {//Class B
            ipPoolValueToCheck = Number(Number(subIpMask[2]*256)+Number(subIpMask[3]));
          }
          else
          {//Class C
           ipPoolValueToCheck = subIpMask[3] != undefined ? subIpMask[3] :'';
          }
          if(subnetCollections != undefined)
          {
             for(var subnetIndex in subnetCollections)
             {
              if(subnetCollections[subnetIndex].start == ipPoolValueToCheck)
              {
                console.log(' Removing .....' +subnetCollections[subnetIndex].start  +' '+subnetCollections[subnetIndex].end);
                subnetCollections.splice(subnetIndex,1);
                break;
              }
            }
          }
         }
       }
      }
  }
//Deletes the pool entry from the database
  function deleteIpPool(urlAction) {

        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        var id = urlAction.action.id;
        var ippoolObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id': id});
        SimLokiDatabaseActions.delete('ippool', ippoolObj);

        if(ippoolObj.length >= 0 &&  ippoolObj[0].parentUuid != undefined && ippoolObj[0].parentUuid != 'root'){
            var parentObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id' : ippoolObj[0].parentUuid });
            var ipPoolCidr = parentObj[0].ipPoolCidr;
            var subPoolCidr = ippoolObj[0].ipPoolCidr;
            deleteEntryInSubCollections(ipPoolCidr,subPoolCidr);
        }

        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return resultObj;
  }

function getSiteId(ipPoolName) {

 var defaultData = DefaultConfig.Default_Sub_Pool;
  for(var i=0;i<defaultData.length;i++) {
   var poolList = defaultData[i].poolList;

   if(poolList.indexOf(ipPoolName)>=0) {
      var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:defaultData[i].site});

    return siteRecord[0].id;
   }

  }
return "";
}
  return {

      init: function () {
          // This code create simulated ip pools
          /*var len = DefaultConfig.Segment.Default_IpPool_Create;
          var start = DefaultConfig.Segment.IPPool_Address_Prefix;
          var tNameSuffix = DefaultConfig.Segment.IPPool_Name_Suffix;
          var tMask = DefaultConfig.Segment.IPPool_Mask;
          for (var i = 0; i < len; i++) {
              var tJson = JSON.parse(JSON.stringify(IpPoolTemplate.IPPool_Template));
              tJson.id = UtilityFunctions.generate_uuid();
              tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
              tJson.createTime = UtilityFunctions.getTimeStamp();
              tJson.ipPoolCidr = getIpPoolCidr(start, tMask);
              tJson.ipPoolName = getIpPoolName(start, tMask, tNameSuffix);
              start++;
              SimLokiDatabaseActions.insert('ippool', tJson);
          }*/
 
          // IPPool ip pool sub _Sub _sub
           var defaultData = DefaultConfig.POOL_DEFAULT_DATA;
          //  var ipPoolObj = {}
           for(var i=0;i<defaultData.length; i++) {
               var tJson = JSON.parse(JSON.stringify(IpPoolTemplate.IPPool_Sub_Template));
               var parentName = defaultData[i].ipPoolName;
               var ippoolObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolName': parentName});
               var siteId =getSiteId(parentName);
                   tJson.restPayload.context[0].contextValue=siteId;
                   tJson.restPayload.ipPoolName=parentName+"_Sub";
                   tJson.restPayload.parentUuid=ippoolObj[0].id;
                   tJson.restPayload.dhcpServerIps=ippoolObj[0].dhcpServerIps;
                   tJson.restPayload.dnsServerIps=ippoolObj[0].dnsServerIps;
                   tJson.restPayload.gateways=ippoolObj[0].gateways;
                   var subnet = ippoolObj[0].ipPoolCidr.split("/");
                   //tJson.restPayload.ipPoolCidr=ippoolObj[0].ipPoolCidr; 10.10.0.0/16
                   tJson.restPayload.ipPoolCidr="0.0.0.0/"+subnet[1];
                   tJson.restPayload.availableIpAddressCount = ippoolObj[0].availableIpAddressCount;
                   tJson.restPayload.totalAssignableIpAddressCount = ippoolObj[0].totalAssignableIpAddressCount;
                   createIpPool(tJson);
               var subPoolObject= SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolName': parentName+"_Sub"});
               var setting = JSON.parse(JSON.stringify(IpPoolTemplate.Common_Setting_Template));
                    setting.url= "/api/v1/commonsetting/global/"+siteId;
                    setting.action.id =siteId;
                    setting.restPayload[0].groupUuid=  siteId;
                    if(parentName.indexOf("Lan")>=0) {
                      setting.restPayload[0].key= "ip.pool.lan."+subPoolObject[0].id;
                      setting.restPayload[0].value[0].type = "lan";
                    } else if(parentName.indexOf("Wan")>=0) {
                      setting.restPayload[0].key= "ip.pool.wan."+subPoolObject[0].id;
                      setting.restPayload[0].value[0].type = "wan";
                    } else if(parentName.indexOf("Man")>=0) {
                      setting.restPayload[0].key= "ip.pool.management."+subPoolObject[0].id;
                      setting.restPayload[0].value[0].type = "management";
                    } else if(parentName.indexOf("Service")>=0) {
                      setting.restPayload[0].key= "ip.pool.service."+subPoolObject[0].id;
                      setting.restPayload[0].value[0].type = "service";
                    } else {
                      setting.restPayload[0].key= "ip.pool.generic."+subPoolObject[0].id;
                    }
                    setting.restPayload[0].value[0].objReferences.push(subPoolObject[0].id);
                    SimDesignData.updateServers(setting);
           }

      },

      processIpPool: function(urlAction){

          switch(urlAction.method){
          case 'POST':
                  return createIpPool(urlAction);
                  break;

           case 'PUT':
                  return updateIpPool(urlAction);
                  break;

           case 'DELETE':
                  return deleteIpPool(urlAction);
                  break;
          }
      }

  };
});
