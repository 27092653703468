define({
    "NetworkDevice_Template":
    {
  "input": "NetworkDevice 52926da0-742d-482a-6729-07f29b796ffd",
  "bookIDS": [
    {
      "namespace": "assurance",
      "name": "assurance",
      "version": "1.0.0"
    },
    {
      "namespace": "fusion",
      "name": "core",
      "version": "1.0.0"
    }
  ],
  "clientApp": "dna",
  "interpretations": [
    {
      "bookID": {
        "namespace": "assurance",
        "name": "assurance",
        "version": "1.0.0"
      },
      "requestString": "NetworkDevice 52926da0-742d-482a-6729-07f29b796ffd",
      "processingTime": 41,
      "inputTokens": {
        "tokens": [

        ]
      },
      "rankedInputTokens": {
        "tokens": [

        ]
      },
      "entities": [

      ],
      "TN": 3,
      "component": "assurance"
    },
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.0"
      },
      "requestString": "52926da0-742d-482a-6729-07f29b796ffd",
      "processingTime": 79,
      "inputTokens": {
        "tokens": [
          {
            "value": "52926da0-742d-482a-6729-07f29b796ffd",
            "propMap": {

            },
            "tag": "NetworkDevice",
            "type": "string",
            "instance": true,
            "keyNames": [
              "instanceuuid"
            ],
            "altEntityKeys": [
              {
                "keyNames": [
                  "id"
                ],
                "keyValues": [
                  "52926da0-742d-482a-6729-07f29b796ffd"
                ]
              }
            ],
            "membershipResult": {
              "altKeys": [
                {
                  "keyNames": [
                    "id"
                  ],
                  "keyValues": [
                    "52926da0-742d-482a-6729-07f29b796ffd"
                  ]
                }
              ],
              "key": [
                "52926da0-742d-482a-6729-07f29b796ffd"
              ],
              "keyNames": [
                "instanceuuid"
              ],
              "resourceTag": "NetworkDevice",
              "resourceName": "R1"
            },
            "tokenID": 343303,
            "traversalSourceTokenID": 0
          }
        ]
      },
      "rankedInputTokens": {
        "tokens": [
          {
            "value": "52926da0-742d-482a-6729-07f29b796ffd",
            "propMap": {

            },
            "tag": "NetworkDevice",
            "type": "string",
            "instance": true,
            "keyNames": [
              "instanceuuid"
            ],
            "altEntityKeys": [
              {
                "keyNames": [
                  "id"
                ],
                "keyValues": [
                  "52926da0-742d-482a-6729-07f29b796ffd"
                ]
              }
            ],
            "membershipResult": {
              "altKeys": [
                {
                  "keyNames": [
                    "id"
                  ],
                  "keyValues": [
                    "52926da0-742d-482a-6729-07f29b796ffd"
                  ]
                }
              ],
              "key": [
                "52926da0-742d-482a-6729-07f29b796ffd"
              ],
              "keyNames": [
                "instanceuuid"
              ],
              "resourceTag": "NetworkDevice",
              "resourceName": "R1"
            },
            "tokenID": 343303,
            "traversalSourceTokenID": 0
          }
        ]
      },
      "entities": [
        {
          "tag": "NetworkDevice",
          "component": "fusion",
          "key": "instanceuuid",
          "value": "52926da0-742d-482a-6729-07f29b796ffd",
          "content": {
            "response": {
              "serialNumber": "FOC1706V0NB",
              "family": "Switches and Hubs",
              "type": "Cisco Catalyst38xx stack-able ethernet switch",
              "role": "ACCESS",
              "snmpLocation": "",
              "softwareType": "IOS-XE",
              "softwareVersion": "16.6.2",
              "tagCount": "0",
              "upTime": "43 days, 5:09:02.30",
              "associatedWlcIp": "",
              "bootDateTime": "2017-12-13 02:53:55",
              "collectionInterval": "05:00:00",
              "collectionStatus": "Managed",
              "hostname": "LA1-3850-ACC-1.corp.local",
              "interfaceCount": "35",
              "inventoryStatusDetail": "<status><general code=\"FAILED_FEAT\"/><topCause code=\"UNKNOWN\"/>\n</status>",
              "lastUpdateTime": 1516867538456,
              "lastUpdated": "2018-01-25 08:05:38",
              "apManagerInterfaceIp": "",
              "managementIpAddress": "10.30.255.105",
              "lineCardCount": "2",
              "lineCardId": "ef3fd764-0b9a-4ed6-becf-72a078d45db1, c7d054e5-56c4-4aef-b3a4-8e04de31aff4",
              "macAddress": "20:bb:c0:5f:32:00",
              "memorySize": "873745296",
              "platformId": "WS-C3850-24P-S",
              "reachabilityFailureReason": "",
              "reachabilityStatus": "Reachable",
              "roleSource": "MANUAL",
              "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
              "snmpContact": "",
              "instanceUuid": "52926da0-742d-482a-6729-07f29b796ffd",
              "id": "52926da0-742d-482a-6729-07f29b796ffd"
            },
            "version": "1.0",
            "tokenID": 343303,
            "visible_attributes": [
              "managementIpAddress",
              "hostname",
              "lastUpdated",
              "reachabilityStatus",
              "type",
              "series",
              "softwareVersion"
            ]
          }
        }
      ],
      "TN": 0,
      "component": "fusion"
    }
  ],
  "entities": [
    {
      "reference": {
        "tag": "NetworkDevice",
        "key": "instanceuuid",
        "value": "52926da0-742d-482a-6729-07f29b796ffd"
      },
      "data": [
        {
          "tag": "NetworkDevice",
          "component": "fusion",
          "key": "instanceuuid",
          "value": "52926da0-742d-482a-6729-07f29b796ffd",
          "content": {
            "response": {
              "serialNumber": "FOC1706V0NB",
              "family": "Switches and Hubs",
              "type": "Cisco Catalyst38xx stack-able ethernet switch",
              "role": "ACCESS",
              "snmpLocation": "",
              "softwareType": "IOS-XE",
              "softwareVersion": "16.6.2",
              "tagCount": "0",
              "upTime": "43 days, 5:09:02.30",
              "associatedWlcIp": "",
              "bootDateTime": "2017-12-13 02:53:55",
              "collectionInterval": "05:00:00",
              "collectionStatus": "Managed",
              "hostname": "LA1-3850-ACC-1.corp.local",
              "interfaceCount": "35",
              "inventoryStatusDetail": "<status><general code=\"FAILED_FEAT\"/><topCause code=\"UNKNOWN\"/>\n</status>",
              "lastUpdateTime": 1516867538456,
              "lastUpdated": "2018-01-25 08:05:38",
              "apManagerInterfaceIp": "",
              "managementIpAddress": "10.30.255.105",
              "lineCardCount": "2",
              "lineCardId": "ef3fd764-0b9a-4ed6-becf-72a078d45db1, c7d054e5-56c4-4aef-b3a4-8e04de31aff4",
              "macAddress": "20:bb:c0:5f:32:00",
              "memorySize": "873745296",
              "platformId": "WS-C3850-24P-S",
              "reachabilityFailureReason": "",
              "reachabilityStatus": "Reachable",
              "roleSource": "MANUAL",
              "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
              "snmpContact": "",
              "instanceUuid": "52926da0-742d-482a-6729-07f29b796ffd",
              "id": "52926da0-742d-482a-6729-07f29b796ffd"
            },
            "version": "1.0",
            "tokenID": 343303,
            "visible_attributes": [
              "managementIpAddress",
              "hostname",
              "lastUpdated",
              "reachabilityStatus",
              "type",
              "series",
              "softwareVersion"
            ]
          }
        }
      ]
    }
  ]
}

});
