define({
  "runningImageList": [
    {
      "name": "C9800-CL-universalk9.17.09.01.SPA.bin",
      "imageUuid": "fdf388ac-bbcc-5e31-9746-5e8cdb6ea1ca",
      "version": "17.9.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "de4fc47d69cd9cc948f740ebcf4bea67",
      "shaCheckSum": "76665c68bc55dbb1dd580b07327acb59062b55178e578a2f57e0eb498f45b830e7b12b14a87b7f185a10817529a81ba18037923e73b13de78c1be0c8186b3fb6",
      "createdTime": "2022-08-01 10:12:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "523aaf4d-bb09-aacc-acec-715a1be089f5",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.360.60851//localdisk/Staging/C9800-CL-universalk9.17.09.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "C9800-CL",
      "vendor": "CISCO",
      "filesize": 1067315426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "0"
        },
        {
          "propertyName": "Description",
          "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "0"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": ""
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "FULL_VERSION",
          "propertyValue": "17.09.01.0.178"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "0"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": false,
      "reasonForNotSupportingImport": "Import from Cisco Catalyst 9800-CL Wireless Controller for Cloud is not supported. Please import from url or local file.",
      "tagList": [
        {
          "taggedGolden": true,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286322605",
          "productName": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
          "productId": [
            "SC9800CLAMIK9-1611",
            "SC9800CLTARK9-173",
            "SC9800CLAMIK9-1610",
            "SC9800CLTARK9-171",
            "LIC-C9800L-PERF",
            "SC980080K9-1611",
            "L-C9800-CL-K9",
            "AIR-DNA",
            "C9300-24P-A",
            "SC9800CLQCOK9-171",
            "AIR-DNA-E",
            "SC9800CLAMIK9-171",
            "SC9800CLQCOK9-173",
            "SC9800CLAMIK9-173",
            "SC9800CLQCOK9-1612",
            "SC9800LK9-171",
            "SC9800CLISOK9-1610",
            "SC9800CLOVFK9-1610",
            "SC9800CLISOK9-1611",
            "SC9800CLGCPK9-173",
            "AIR-DNA-MSP",
            "SC9800CLGCPK9-171",
            "SC980040K9-1611",
            "SC9800CLOVAK9-1612",
            "SC9800CLOVAK9-173",
            "SC9800CLOVAK9-1611",
            "SC9800CLBINK9-1610",
            "SC9800CLBINK9-1611",
            "C9800-CL-K9",
            "AIR-DNA-MSP-E",
            "AIR-DNA-MSP-E-T",
            "SC9800CLQCOK9-1611",
            "SC9800CLTARK9-1611",
            "SC9800CLISOK9-171",
            "SC9800CLISOK9-173",
            "SC9800CLGCPK9-1612",
            "SC9800CLOVAK9-171",
            "LIC-C9800L-PERF=",
            "SC9800CLAMIK9-1612",
            "SC9800CLTARK9-1612"
          ],
          "sites": [],
          "show": true,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false,
      "ccoreverseSync": true
    },{
      "name": "C9800-CL-universalk9.17.08.01.SPA.bin",
      "imageUuid": "fdf388ac-aaae-5e31-9746-5e8cdb6ea1ca",
      "version": "17.8.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "de4fc47d69cd9cc948f740ebcf4bea67",
      "shaCheckSum": "76665c68bc55dbb1dd580b07327acb59062b55178e578a2f57e0eb498f45b830e7b12b14a87b7f185a10817529a81ba18037923e73b13de78c1be0c8186b3fb6",
      "createdTime": "2022-08-05 10:12:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "523aaf4d-bb09-462b-acec-715a1be089f5",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.360.60851//localdisk/Staging/C9800-CL-universalk9.17.03.02a.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "C9800-CL",
      "vendor": "CISCO",
      "filesize": 1067315426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "0"
        },
        {
          "propertyName": "Description",
          "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "0"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": ""
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "FULL_VERSION",
          "propertyValue": "17.08.02a.0.3793"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "0"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": false,
      "reasonForNotSupportingImport": "Import from Cisco Catalyst 9800-CL Wireless Controller for Cloud is not supported. Please import from url or local file.",
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286322605",
          "productName": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
          "productId": [
            "SC9800CLAMIK9-1611",
            "SC9800CLTARK9-173",
            "SC9800CLAMIK9-1610",
            "SC9800CLTARK9-171",
            "LIC-C9800L-PERF",
            "SC980080K9-1611",
            "L-C9800-CL-K9",
            "AIR-DNA",
            "C9300-24P-A",
            "SC9800CLQCOK9-171",
            "AIR-DNA-E",
            "SC9800CLAMIK9-171",
            "SC9800CLQCOK9-173",
            "SC9800CLAMIK9-173",
            "SC9800CLQCOK9-1612",
            "SC9800LK9-171",
            "SC9800CLISOK9-1610",
            "SC9800CLOVFK9-1610",
            "SC9800CLISOK9-1611",
            "SC9800CLGCPK9-173",
            "AIR-DNA-MSP",
            "SC9800CLGCPK9-171",
            "SC980040K9-1611",
            "SC9800CLOVAK9-1612",
            "SC9800CLOVAK9-173",
            "SC9800CLOVAK9-1611",
            "SC9800CLBINK9-1610",
            "SC9800CLBINK9-1611",
            "C9800-CL-K9",
            "AIR-DNA-MSP-E",
            "AIR-DNA-MSP-E-T",
            "SC9800CLQCOK9-1611",
            "SC9800CLTARK9-1611",
            "SC9800CLISOK9-171",
            "SC9800CLISOK9-173",
            "SC9800CLGCPK9-1612",
            "SC9800CLOVAK9-171",
            "LIC-C9800L-PERF=",
            "SC9800CLAMIK9-1612",
            "SC9800CLTARK9-1612"
          ],
          "sites": [],
          "show": true,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false,
      "ccoreverseSync": true
    },{
      "name": "C9800-CL-universalk9.17.06.01.SPA.bin",
      "imageUuid": "fdf388ac-aaae-4d20-9746-5e8cdb6ea1ca",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "de4fc47d69cd9cc948f740ebcf4bea67",
      "shaCheckSum": "76665c68bc55dbb1dd580b07327acb59062b55178e578a2f57e0eb498f45b830e7b12b14a87b7f185a10817529a81ba18037923e73b13de78c1be0c8186b3fb6",
      "createdTime": "2021-10-18 10:12:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "523aaf4d-aaf8-462b-acec-715a1be089f5",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.360.60851//localdisk/Staging/C9800-CL-universalk9.17.03.02a.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "C9800-CL",
      "vendor": "CISCO",
      "filesize": 1067315426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "0"
        },
        {
          "propertyName": "Description",
          "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "0"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": ""
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "FULL_VERSION",
          "propertyValue": "17.03.02a.0.3793"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "0"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": false,
      "reasonForNotSupportingImport": "Import from Cisco Catalyst 9800-CL Wireless Controller for Cloud is not supported. Please import from url or local file.",
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286322605",
          "productName": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
          "productId": [
            "SC9800CLAMIK9-1611",
            "SC9800CLTARK9-173",
            "SC9800CLAMIK9-1610",
            "SC9800CLTARK9-171",
            "LIC-C9800L-PERF",
            "SC980080K9-1611",
            "L-C9800-CL-K9",
            "AIR-DNA",
            "C9300-24P-A",
            "SC9800CLQCOK9-171",
            "AIR-DNA-E",
            "SC9800CLAMIK9-171",
            "SC9800CLQCOK9-173",
            "SC9800CLAMIK9-173",
            "SC9800CLQCOK9-1612",
            "SC9800LK9-171",
            "SC9800CLISOK9-1610",
            "SC9800CLOVFK9-1610",
            "SC9800CLISOK9-1611",
            "SC9800CLGCPK9-173",
            "AIR-DNA-MSP",
            "SC9800CLGCPK9-171",
            "SC980040K9-1611",
            "SC9800CLOVAK9-1612",
            "SC9800CLOVAK9-173",
            "SC9800CLOVAK9-1611",
            "SC9800CLBINK9-1610",
            "SC9800CLBINK9-1611",
            "C9800-CL-K9",
            "AIR-DNA-MSP-E",
            "AIR-DNA-MSP-E-T",
            "SC9800CLQCOK9-1611",
            "SC9800CLTARK9-1611",
            "SC9800CLISOK9-171",
            "SC9800CLISOK9-173",
            "SC9800CLGCPK9-1612",
            "SC9800CLOVAK9-171",
            "LIC-C9800L-PERF=",
            "SC9800CLAMIK9-1612",
            "SC9800CLTARK9-1612"
          ],
          "sites": [],
          "show": true,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false,
      "ccoreverseSync": true
    },
    {
      "name": "C9800-CL-universalk9.17.03.02a.SPA.bin",
      "imageUuid": "fdf388ac-2d1e-4d20-9746-5e8cdb6ea1ca",
      "version": "17.03.02a",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7e699ef5ef13251cf076ca9a658c58d2",
      "shaCheckSum": "4f433b27e86b8fc2f2ea95e445038e69554a31386f75c3949a264b627d9aee6c87712632a3750683e867f190c366d364b9c0ff13e90f0624a6f7b3688fed918e",
      "createdTime": "2021-04-12 10:12:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "523d2f4d-aaf8-462b-acec-715a1be089f5",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.360.60851//localdisk/Staging/C9800-CL-universalk9.17.03.02a.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "C9800-CL",
      "vendor": "CISCO",
      "filesize": 1067315426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "0"
        },
        {
          "propertyName": "Description",
          "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "0"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": ""
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "FULL_VERSION",
          "propertyValue": "17.03.02a.0.3793"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "0"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": false,
      "reasonForNotSupportingImport": "Import from Cisco Catalyst 9800-CL Wireless Controller for Cloud is not supported. Please import from url or local file.",
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286322605",
          "productName": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
          "productId": [
            "SC9800CLAMIK9-1611",
            "SC9800CLTARK9-173",
            "SC9800CLAMIK9-1610",
            "SC9800CLTARK9-171",
            "LIC-C9800L-PERF",
            "SC980080K9-1611",
            "L-C9800-CL-K9",
            "AIR-DNA",
            "C9300-24P-A",
            "SC9800CLQCOK9-171",
            "AIR-DNA-E",
            "SC9800CLAMIK9-171",
            "SC9800CLQCOK9-173",
            "SC9800CLAMIK9-173",
            "SC9800CLQCOK9-1612",
            "SC9800LK9-171",
            "SC9800CLISOK9-1610",
            "SC9800CLOVFK9-1610",
            "SC9800CLISOK9-1611",
            "SC9800CLGCPK9-173",
            "AIR-DNA-MSP",
            "SC9800CLGCPK9-171",
            "SC980040K9-1611",
            "SC9800CLOVAK9-1612",
            "SC9800CLOVAK9-173",
            "SC9800CLOVAK9-1611",
            "SC9800CLBINK9-1610",
            "SC9800CLBINK9-1611",
            "C9800-CL-K9",
            "AIR-DNA-MSP-E",
            "AIR-DNA-MSP-E-T",
            "SC9800CLQCOK9-1611",
            "SC9800CLTARK9-1611",
            "SC9800CLISOK9-171",
            "SC9800CLISOK9-173",
            "SC9800CLGCPK9-1612",
            "SC9800CLOVAK9-171",
            "LIC-C9800L-PERF=",
            "SC9800CLAMIK9-1612",
            "SC9800CLTARK9-1612"
          ],
          "sites": [],
          "show": true,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 1,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false,
      "ccoreverseSync": true
    },
    {
      "name": "C9800-CL-universalk9.17.03.01.SPA.bin",
      "imageUuid": "bfe36cd0-bf2b-4660-877a-2b75f7a8de3d",
      "version": "17.03.01",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "04096a2f02349de12a1682f82b44ca5b",
      "shaCheckSum": "848d2a15d9b1bd36fa44628855fe8fe981b02c9b9a2a901a5f16d69ecc342435c6b9a676cb68049037fd78b6f66d9f4947b729580d03be176e778994c018a3a7",
      "createdTime": "2021-05-21 11:17:25.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "ae4341a9-00a6-427a-86e1-e715ead81f51",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.360.60851//localdisk/Staging/C9800-CL-universalk9.17.03.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "C9800-CL",
      "vendor": "CISCO",
      "filesize": 1065836995,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "0"
        },
        {
          "propertyName": "Description",
          "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "0"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": ""
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "0"
        },
        {
          "propertyName": "FULL_VERSION",
          "propertyValue": "17.03.01.0.351"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": false,
      "reasonForNotSupportingImport": "Import from Cisco Catalyst 9800-CL Wireless Controller for Cloud is not supported. Please import from url or local file.",
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286322605",
          "productName": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
          "productId": [
            "SC9800CLAMIK9-1611",
            "SC9800CLTARK9-173",
            "SC9800CLAMIK9-1610",
            "SC9800CLTARK9-171",
            "LIC-C9800L-PERF",
            "SC980080K9-1611",
            "L-C9800-CL-K9",
            "AIR-DNA",
            "C9300-24P-A",
            "SC9800CLQCOK9-171",
            "AIR-DNA-E",
            "SC9800CLAMIK9-171",
            "SC9800CLQCOK9-173",
            "SC9800CLAMIK9-173",
            "SC9800CLQCOK9-1612",
            "SC9800LK9-171",
            "SC9800CLISOK9-1610",
            "SC9800CLOVFK9-1610",
            "SC9800CLISOK9-1611",
            "SC9800CLGCPK9-173",
            "AIR-DNA-MSP",
            "SC9800CLGCPK9-171",
            "SC980040K9-1611",
            "SC9800CLOVAK9-1612",
            "SC9800CLOVAK9-173",
            "SC9800CLOVAK9-1611",
            "SC9800CLBINK9-1610",
            "SC9800CLBINK9-1611",
            "C9800-CL-K9",
            "AIR-DNA-MSP-E",
            "AIR-DNA-MSP-E-T",
            "SC9800CLQCOK9-1611",
            "SC9800CLTARK9-1611",
            "SC9800CLISOK9-171",
            "SC9800CLISOK9-173",
            "SC9800CLGCPK9-1612",
            "SC9800CLOVAK9-171",
            "LIC-C9800L-PERF=",
            "SC9800CLAMIK9-1612",
            "SC9800CLTARK9-1612"
          ],
          "sites": [],
          "show": true,
          "userDefined": true
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 1,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false,
      "ccoreverseSync": true
    }
  ]
});