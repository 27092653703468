define({
    "issuesTemplate": {
       "latency_issue": {
        hostNames:['Grace.Smith-PC','Shaggy-PC']
      },
      "latency_issue_webx": {
        hostNames:['Grace.Smith-iPad','Gordon.Thomson-iPad','Grace.Smith-PC','Shaggy-PC', 'Grace.Smith-Galaxy-S20']
      },
      "latency_issue_MedicalRecords": {
        hostNames:['Grace.Smith-iPad', 'Grace.Smith-Galaxy-S20']
      },
      "latency_issue_ms_office_365": {
        hostNames:['Grace.Smith-iPad','Gordon.Thomson-iPad', 'Grace.Smith-Galaxy-S20']
      },
      "excessive_auth_fail_client": {
        hostNames: ['Grace.Smith-iPad','Gordon.Thomson-iPad','George-PC','Shaggy-PC','James-PC', 'Grace.Smith-Galaxy-S20']
      },
      "auth_aaa_rejected": {
        hostNames: ['George-PC','Shaggy-PC']
      },
      "auth_cl_timeout_or_rf": {
        hostNames: ['Grace.Smith-iPad','George-PC','Shaggy-PC','James-PC',"Luther.Knight","Aaliya.Khan","Mia.Smith", 'Grace.Smith-Galaxy-S20']
      },
      "auth_sec_param_mismatch": {
          hostNames: ['George-PC','Shaggy-PC','James-PC']
      },
      "excessive_assoc_fail": {
          hostNames: ['Grace.Smith-iPad','Gordon.Thomson-iPad','George-PC','Shaggy-PC','James-PC', 'Grace.Smith-Galaxy-S20']
      },
      "excessive_auth_server": {
        hostNames: ['George-PC','Shaggy-PC','James-PC']
      },
      "excessive_auth_fail_server": {
        hostNames: ['George-PC','Shaggy-PC','James-PC',"Luther.Knight"]
      },
      "excessive_auth_fail_wlc": {
          hostNames: ['George-PC','Shaggy-PC','James-PC']
      },
      //as issue doesn't show data (1.3)
      /*"excessive_dhcp": {
          hostNames: ['Grace.Smith-iPad','Gordon.Thompson-iPad','George-PC','Shaggy-PC','James-PC']
      },*/
      "excessive_total": {
          hostNames: ['George-PC','Shaggy-PC','James-PC',"Luther.Knight"]
      },
      "dhcp_server_or_client_timeout": {
        hostNames: ['George-PC','Shaggy-PC','James-PC'],
        issueDetailFileName: 'dhcp_server_or_client_timeout.json'
      },
      "ios_client_disassoc_single": {
        hostNames: ['Grace.Smith-iPhone', 'DR.Dogood-iPhone']
      },
      "client_poor_band_selection": {
        hostNames: ['Grace.Smith-iPhone','SAMSUNG-SM-G930V','Scotts-iPhone','DR.Dogood-iPhone']
      },
      "Unknown": {
        hostNames: ['DESKTOP-9GPLJB7','Ingrid-PC','Becky-PC']
      },
      "Unknown_connected_poor_rssi": {
        hostNames:  ['Grace.Smith-iPad','Gordon.Thompson-iPad','George-PC','Shaggy-PC','James-PC', 'Grace.Smith-Galaxy-S20']
      },
      "sensor_connected_dns_global_server_availability_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_global_mailserver_not_reachable": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_global_mailserver_test_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_global_onboarding_wireless_status_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_global_webserver_not_reachable": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_global_webserver_test_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_connected_server_global_ping_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_dhcp_global_multiple_failures": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "sensor_global_webauth_failed": {
        hostNames: ['ethan-sensor1','csgsensor']
      },
      "global_wireless_kairos_excessive_onboarding_failure_trigger": {
        hostNames: ['Shaggy-PC'],
        AiDriven:true
      },
      "global_wireless_kairos_excessive_onboarding_time_trigger": {
        hostNames: ['Shaggy-PC'],
        AiDriven:true
      },
      "global_wireless_kairos_excessive_dhcp_time_trigger": {
        hostNames: ['Shaggy-PC'],
        AiDriven:true
      }
    }
})
