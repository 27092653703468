define({
    "runningImageList":[  
        {
            "name": "cat9k_iosxe.17.09.01.SPA.bin",
            "imageUuid": "c7c3adac-1212-416f-9e8b-21704458702b",
            "version": "17.9.1",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "true",
            "md5Checksum": "89c98b1ed44cf6cb1190eca977edb9a5",
            "shaCheckSum": "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
            "createdTime": "2022-08-01 19:31:12.0",
            "applicationType": null,
            "feature": "",
            "fileServiceId": "59fbd410-3333-4a6b-a3a0-fa8071f39733",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.09.01.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "CAT9K",
            "vendor": "CISCO",
            "filesize": 901741775,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 1,
            "imageAttributes": [
              {
                  "propertyName": "MEDIA",
                  "propertyValue": "5"
              },{
                  "propertyName": "COMPRESSION_CODE",
                  "propertyValue": "-1"
              },{
                  "propertyName": "deviceMNEId",
                  "propertyValue": "23030007"
              },{
                  "propertyName": "udi",
                  "propertyValue": "PID: C9500-24Y4C VID: V01, SN: CAT2345L1LR"
              },{
                  "propertyName": "metadata_trans_id",
                  "propertyValue": "330079917"
              },{
                  "propertyName": "MINFLASHSIZE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "image_description",
                  "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
              },{
                  "propertyName": "DEFAULT_BOOTROM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "release_fcs_date",
                  "propertyValue": "01/Aug/2022"
              },{
                  "propertyName": "BOOTROM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "RAM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "encryption_software_indicator",
                  "propertyValue": "Y"
              },{
                  "propertyName": "image_size",
                  "propertyValue": "901741775"
              },{
                  "propertyName": "field_notices",
                  "propertyValue": "Field Notices"
              },{
                  "propertyName": "DEFAULT_MINFLASHSIZE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "FEATURE",
                  "propertyValue": ""
              },{
                  "propertyName": "DEFAULT_RAM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "min_dram",
                  "propertyValue": "8192"
              },{
                  "propertyName": "Description",
                  "propertyValue": "Cisco IOS Software, IOS-XE Software"
              },{
                  "propertyName": "release_doc_url",
                  "propertyValue": "https://www.cisco.com/c/en/us/td/docs/wireless/controller/9800/17-3/release-notes/rn-17-3-9800.html"
              },{
                  "propertyName": "release_doc_name",
                  "propertyValue": "Release Notes for Cupertino-17.9.1"
              },{
                  "propertyName": "min_flash",
                  "propertyValue": "16384"
              },{
                  "propertyName": "image_guid",
                  "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
              },{
                  "propertyName": "GAIA_FEATURE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "FULL_VERSION",
                  "propertyValue": "17.09.01.0.178"
              }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                  "taggedGolden": true,
                  "role": "ALL",
                  "inheritedSiteName": "",
                  "inheritedSiteUuid": ""
              }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "VERIFIED",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286319595",
                    "productName": "Cisco Catalyst C9500-24Y4C Switch",
                    "productId": [
                        "C9500H-4PT-KIT=",
                        "L-C9500-24Y4C-EA-3",
                        "L-C9500-24Y4C-EA-7",
                        "C9500-24Y4C=",
                        "C9500-60C12D-A",
                        "C9500H-ACCKIT-19I=",
                        "C9500-24Y4C"
                    ],
                    "sites": [],
                    "show": false,
                    "userDefined": false
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccorecommended": false,
            "ccolatest": true,
            "ccoreverseSync": true
          },{
            "name": "cat9k_iosxe.17.03.03.SPA.bin",
            "imageUuid": "c7c3adac-3333-416f-9e8b-21704458702b",
            "version": "17.3.3",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "89c98b1ed44cf6cb1190eca977edb9a5",
            "shaCheckSum": "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
            "createdTime": "2021-03-09 19:31:12.0",
            "applicationType": null,
            "feature": "",
            "fileServiceId": "59fbd410-3333-4a6b-a3a0-fa8071f39733",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "CAT9K",
            "vendor": "CISCO",
            "filesize": 901741775,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 1,
            "imageAttributes": [
              {
                  "propertyName": "MEDIA",
                  "propertyValue": "5"
              },{
                  "propertyName": "COMPRESSION_CODE",
                  "propertyValue": "-1"
              },{
                  "propertyName": "deviceMNEId",
                  "propertyValue": "23030007"
              },{
                  "propertyName": "udi",
                  "propertyValue": "PID: C9500-24Y4C VID: V01, SN: CAT2345L1LR"
              },{
                  "propertyName": "metadata_trans_id",
                  "propertyValue": "330079917"
              },{
                  "propertyName": "MINFLASHSIZE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "image_description",
                  "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
              },{
                  "propertyName": "DEFAULT_BOOTROM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "release_fcs_date",
                  "propertyValue": "05/Mar/2021"
              },{
                  "propertyName": "BOOTROM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "RAM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "encryption_software_indicator",
                  "propertyValue": "Y"
              },{
                  "propertyName": "image_size",
                  "propertyValue": "901741775"
              },{
                  "propertyName": "field_notices",
                  "propertyValue": "Field Notices"
              },{
                  "propertyName": "DEFAULT_MINFLASHSIZE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "FEATURE",
                  "propertyValue": ""
              },{
                  "propertyName": "DEFAULT_RAM",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "min_dram",
                  "propertyValue": "8192"
              },{
                  "propertyName": "Description",
                  "propertyValue": "Cisco IOS Software, IOS-XE Software"
              },{
                  "propertyName": "release_doc_url",
                  "propertyValue": "https://www.cisco.com/c/en/us/td/docs/wireless/controller/9800/17-3/release-notes/rn-17-3-9800.html"
              },{
                  "propertyName": "release_doc_name",
                  "propertyValue": "Release Notes for Amsterdam-17.3.3"
              },{
                  "propertyName": "min_flash",
                  "propertyValue": "16384"
              },{
                  "propertyName": "image_guid",
                  "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
              },{
                  "propertyName": "GAIA_FEATURE",
                  "propertyValue": "UNKNOWN"
              },{
                  "propertyName": "FULL_VERSION",
                  "propertyValue": "17.03.03.0.4762"
              }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                  "taggedGolden": false,
                  "role": "ALL",
                  "inheritedSiteName": "",
                  "inheritedSiteUuid": ""
              }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "VERIFIED",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286319595",
                    "productName": "Cisco Catalyst C9500-24Y4C Switch",
                    "productId": [
                        "C9500H-4PT-KIT=",
                        "L-C9500-24Y4C-EA-3",
                        "L-C9500-24Y4C-EA-7",
                        "C9500-24Y4C=",
                        "C9500-60C12D-A",
                        "C9500H-ACCKIT-19I=",
                        "C9500-24Y4C"
                    ],
                    "sites": [],
                    "show": false,
                    "userDefined": false
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccorecommended": false,
            "ccolatest": true,
            "ccoreverseSync": true
          },
          {  
           "name":"cat9k_iosxe.16.08.01a.SPA.bin",
           "imageUuid":"b07cd7dd-53ca-402f-883e-e0d4ffe51674",
           "version":"16.8.1a",
           "softwareType":null,
           "imageType":"SYSTEM_SW",
           "isTaggedGolden":"false",
           "md5Checksum":"5a7ebf6cfc15b83125819b13feec25a9",
           "shaCheckSum":"4a4d092b0e63fe3fca88233dc33b1f948842ee432e4235007441d647d2d0b1bef2a5528faa193ff98e3440eff9587f42fea7646113d0a2983de01e63173aea5e",
           "createdTime":"2018-04-30 23:08:55.0",
           "applicationType":null,
           "feature":"",
           "fileServiceId":"7295cf38-179c-473b-ba8e-21fd33f7fb34",
           "imageSeries":null,
           "imageSource":"file:/opt/maglev/services/swim-service/7.1.9.60029//localdisk/Staging/cat9k_iosxe.16.08.01a.SPA.bin",
           "imageSourceType":"Imported from File System",
           "imageFamily":"CAT9K",
           "vendor":"CISCO",
           "filesize":667244461,
           "deviceCount":0,
           "deviceUuidList":[  
               
           ],
           "smuCount":0,
           "imageAttributes":[  
              {  
                 "propertyName":"release_fcs_date",
                 "propertyValue":"06/Apr/2018"
              },
              {  
                 "propertyName":"MEDIA",
                 "propertyValue":"5"
              },
              {  
                 "propertyName":"COMPRESSION_CODE",
                 "propertyValue":"-1"
              },
              {  
                 "propertyName":"DEFAULT_RAM",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"FEATURE",
                 "propertyValue":""
              },
              {  
                 "propertyName":"DEFAULT_MINFLASHSIZE",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"min_dram",
                 "propertyValue":"8192"
              },
              {  
                 "propertyName":"image_description",
                 "propertyValue":"CAT9300/9400/9500 Universal"
              },
              {  
                 "propertyName":"image_size",
                 "propertyValue":"667244461"
              },
              {  
                 "propertyName":"metadata_trans_id",
                 "propertyValue":"121782052"
              },
              {  
                 "propertyName":"MINFLASHSIZE",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"deviceMNEId",
                 "propertyValue":"163197"
              },
              {  
                 "propertyName":"image_guid",
                 "propertyValue":"20085AFC7D135C514E68061F51B4F5EB9733EDE4"
              },
              {  
                 "propertyName":"min_flash",
                 "propertyValue":"16384"
              },
              {  
                 "propertyName":"DEFAULT_BOOTROM",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"udi",
                 "propertyValue":"PID: C9300-48U VID: V01, SN: FOC2146Z0HK"
              },
              {  
                 "propertyName":"BOOTROM",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"mdf_id",
                 "propertyValue":"286315874"
              },
              {  
                 "propertyName":"encryption_software_indicator",
                 "propertyValue":"Y"
              },
              {  
                 "propertyName":"RAM",
                 "propertyValue":"UNKNOWN"
              },
              {  
                 "propertyName":"GAIA_FEATURE",
                 "propertyValue":"UNKNOWN"
              }
           ],
           "isSMUApplicable":true,
           "goldenTagInheritedFromSiteUuid":null,
           "goldenTagInheritedFromSiteName":null,
           "importSupported":true,
           "reasonForNotSupportingImport":null,
           "tagList":[  
              {  
                 "taggedGolden":false,
                 "role":"ALL",
                 "inheritedSiteName":"",
                 "inheritedSiteUuid":""
              },
              {  
                 "taggedGolden":false,
                 "role":"CORE",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"DISTRIBUTION",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"BORDER ROUTER",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"UNKNOWN",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"ACCESS",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              }
           ],
           "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
           "imageCategory":"PHYSICAL",
           "imageIntegrityStatus":"UNKNOWN",
           "ccorecommended":false,
           "ccolatest":false
        }
     ]
});