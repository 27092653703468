require("../../../public/libs/amcharts/amcharts");
require("../../../public/libs/amcharts/serial");
require("../../../public/libs/amcharts/themes/light");
require("../../../public/libs/amcharts/pie");
require("../../../public/libs/amcharts/plugins/responsive/responsive.min");
require("../../../public/libs/amcharts/plugins/export/export.min");
require("../../../public/libs/amcharts/xy");
require("../../../public/libs/amcharts/gantt");

window.AmCharts_path = "/libs/amcharts/";
window.AmCharts.isReady = true;

module.exports = window.AmCharts;
