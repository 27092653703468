define({
"Template":
{
  "failureReasonsByTestType": {
    "failureReasons": [
      {
        "errorCode": 1064,
        "errorTitle": "Deauthenticated because Sensor is leaving",
        "testType": "ASSOC",
        "testCategory": "ONBOARDING"
      },
      {
        "errorCode": 1806,
        "errorTitle": "No DHCP OFFER Received",
        "testType": "DHCP",
        "testCategory": "ONBOARDING"
      },
      {
        "errorCode": 1064,
        "errorTitle": "Undefined Error",
        "testType": "AUTH",
        "testCategory": "ONBOARDING"
      },
      {
        "errorCode": 0,
        "errorTitle": "RX datarate less than 10 Mbps",
        "testType": "DATA_RATE",
        "testCategory": "RF_ASSESSMENT"
      },
      {
        "errorCode": 5003,
        "errorTitle": "Undefined Error",
        "testType": "DNS",
        "testCategory": "NETWORK_SERVICES"
      },
      {
        "errorCode": 13009,
        "errorTitle": "RADIUS: Server Access Timeout",
        "testType": "RADIUS",
        "testCategory": "NETWORK_SERVICES"
      },
      {
        "errorCode": 8004,
        "errorTitle": "DNS Resolve Fail",
        "testType": "WEBSERVER",
        "testCategory": "APP_CONNECTIVITY"
      },
      {
        "errorCode": 11006,
        "errorTitle": "No response from IP SLA responder AP",
        "testType": "IPSLASENDER",
        "testCategory": "PERFORMANCE"
      },
      {
        "errorCode":9006,
        "errorTitle":"OWA: Connection Fail",
        "testType":"OUTLOOKSERVER",
        "testCategory":"EMAIL"
      }
    ]
  },
  "failureReasonsByLocation": {
    "failureReasons": [
      {
        "location": "Global/San Jose",
        "failurePercent": 0.00,
        "testType": "DHCP",
        "testCategory": "ONBOARDING"
      },
      {
        "location": "Global/San Jose",
        "failurePercent": 0.00,
        "testType": "SNR",
        "testCategory": "RF_ASSESSMENT"
      },
      {
        "location": "Global/San Jose",
        "failurePercent": 30.06,
        "testType": "DNS",
        "testCategory": "NETWORK_SERVICES"
      },
      {
        "location": "Global/San Francisco",
        "failurePercent": 0.87,
        "testType": "WEBSERVER",
        "testCategory": "APP_CONNECTIVITY"
      },
      {
        "location": "Global/San Jose",
        "failurePercent": 0.28,
        "testType": "IPSLASENDER",
        "testCategory": "PERFORMANCE"
      },
      {
        "location": "Global/San Jose",
        "failurePercent": 100,
        "testType": "OUTLOOKSERVER",
        "testCategory": "EMAIL"
      }

    ]
  }
}

})