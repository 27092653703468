
define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_4451_Series_Integrated_Services_Router',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_5508_Wireless_LAN_Controller',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_36xx_stack_able_ethernet_switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_38xx_stack_able_ethernet_switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9300_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9404R_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9200_Switch_Stack',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_3650_24PD_E',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_ASR_1001_X_Router',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_ASR_1002_X_Router',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Nexus_7000_10_Slot_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Nexus_7000_9_Slot_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_ASR_1002_Router',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_ASR_1004_Router',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_5520_Series_Wireless_Controllers',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9500_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_6880_X_LE_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_C9500H_24Y4C_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_1800S_Unified_Access_Point_Sensor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_6840_X_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_6807_XL_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/NFVIS',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9800_40_Wireless_Controller',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Aironet_1800S_Active_Sensor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_IE_3400_8P2S_Rugged_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_IE_3300_8P2S_Rugged_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Nexus_7700_10_Slot_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Mobility_Express_for_Catalyst_9115AX_Access_Points',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_5400_Enterprise_Network_Compute_System',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9300L_Switch_Stack',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9606R_Switch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9800_L_F_Wireless_Controller',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Cisco_Catalyst_9800_CL_Wireless_Controller_for_Cloud',
], function (
             Cisco_4451_Series_Integrated_Services_Router,
             Cisco_5508_Wireless_LAN_Controller,
              Cisco_Catalyst_36xx_stack_able_ethernet_switch, Cisco_Catalyst_38xx_stack_able_ethernet_switch, Cisco_Catalyst_9300_Switch, Cisco_Catalyst_9404R_Switch,
              Cisco_Catalyst_9200_Switch_Stack, Cisco_Catalyst_3650_24PD_E, Cisco_ASR_1001_X_Router, Cisco_ASR_1002_X_Router, Cisco_Nexus_7000_10_Slot_Switch,
Cisco_Nexus_7000_9_Slot_Switch, Cisco_ASR_1002_Router, Cisco_ASR_1004_Router, Cisco_5520_Series_Wireless_Controllers,Cisco_Catalyst_9500_Switch,
Cisco_Catalyst_6880_X_LE_Switch,Cisco_Catalyst_C9500H_24Y4C_Switch,Cisco_1800S_Unified_Access_Point_Sensor,Cisco_Catalyst_6840_X_Switch,Cisco_Catalyst_6807_XL_Switch,
NFVIS, Cisco_Catalyst_9800_40_Wireless_Controller, Cisco_Aironet_1800S_Active_Sensor, Cisco_Catalyst_IE_3400_8P2S_Rugged_Switch,
Cisco_Catalyst_IE_3300_8P2S_Rugged_Switch, Nexus_7700_10_Slot_Switch, Cisco_Mobility_Express_for_Catalyst_9115AX_Access_Points,
Cisco_5400_Enterprise_Network_Compute_System, Cisco_Catalyst_9300L_Switch_Stack, Cisco_Catalyst_9606R_Switch, Cisco_Catalyst_9800_L_F_Wireless_Controller,
    Cisco_Catalyst_9800_CL_Wireless_Controller_for_Cloud) {

  return {
    init: function() {
    },

    getRunningImageList: function(type) {
        var response = eval(type);
        return response != undefined ? response.runningImageList : [];
    },

    imageInApplicableDevices: function(applicableDevicesForImage, devPlatformId) {
      devPlatformId = devPlatformId.split(',');
      if(applicableDevicesForImage) {
          for(var j=0; j<applicableDevicesForImage.length; j++) {
            for(var i=0; i< devPlatformId.length; i++) {
                if(applicableDevicesForImage[j].productId && applicableDevicesForImage[j].productId.indexOf(devPlatformId[i])>-1){
                    return true;
                }
            }
          }
      } else {
          return true;
      }
      return false;
    }
  };
});
