define({
  Template: {
    version: "1.0",
    response: {
      summary: {
        averageRate: 578187.63,
        totalUsage: 4853032690,
        activeClients: 1,
      },
      trend: [
        {
          collToolsAppName: "ms-teams-app-sharing",
          minHealthScore: 7,
          avgHealthScore: 7,
          businessRelevance: ["business-relevant"],
          trafficClass: ["multimedia-conferencing"],
          healthTrend: [
            {
              time: "2022-11-07T10:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T20:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T17:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T15:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 616,
              rate: 4.11,
            },
            {
              time: "2022-11-06T14:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.74,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 27021.545454545456,
              clientJitter: null,
              serverJitter: null,
              latency: 39.2,
              applicationDelay: 2.9,
              clientNetworkDelay: 0.6999999999999993,
              serverNetworkDelay: 38.5,
              dscp: "",
              usage: 913632272,
              rate: 1107688.67,
            },
            {
              time: "2022-11-06T13:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.7,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26238.8,
              clientJitter: null,
              serverJitter: null,
              latency: 36.725,
              applicationDelay: 6,
              clientNetworkDelay: 0.9749999999999996,
              serverNetworkDelay: 35.75,
              dscp: "",
              usage: 753605808,
              rate: 1004807.74,
            },
            {
              time: "2022-11-06T12:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 25553.909090909092,
              clientJitter: null,
              serverJitter: null,
              latency: 32.1625,
              applicationDelay: 3.3636363636363638,
              clientNetworkDelay: 0.5375000000000014,
              serverNetworkDelay: 32,
              dscp: "",
              usage: 1025995087,
              rate: 621817.02,
            },
            {
              time: "2022-11-06T11:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.74,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 25970.5,
              clientJitter: null,
              serverJitter: null,
              latency: 36.35,
              applicationDelay: 0,
              clientNetworkDelay: 0.8500000000000014,
              serverNetworkDelay: 35.5,
              dscp: "",
              usage: 139022576,
              rate: 926817.17,
            },
            {
              time: "2022-11-06T07:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26750.428571428572,
              clientJitter: null,
              serverJitter: null,
              latency: 36.1375,
              applicationDelay: 6.714285714285714,
              clientNetworkDelay: 0.5124999999999993,
              serverNetworkDelay: 36,
              dscp: "",
              usage: 566845280,
              rate: 1079390.53,
            },
            {
              time: "2022-11-06T06:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.72,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26024.5,
              clientJitter: null,
              serverJitter: null,
              latency: 36.2625,
              applicationDelay: 23.25,
              clientNetworkDelay: 0.3874999999999993,
              serverNetworkDelay: 36,
              dscp: "",
              usage: 141875746,
              rate: 945838.31,
            },
            {
              time: "2022-11-03T15:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.8,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 27288,
              clientJitter: null,
              serverJitter: null,
              latency: 35.9875,
              applicationDelay: 3,
              clientNetworkDelay: 0.8625000000000007,
              serverNetworkDelay: 35.5,
              dscp: "",
              usage: 591854577,
              rate: 1127342.05,
            },
          ],
          category: ["collaboration-apps"],
        },
        {
          collToolsAppName: "ms-teams-video",
          minHealthScore: 8,
          avgHealthScore: 8,
          businessRelevance: ["business-relevant"],
          trafficClass: ["multimedia-conferencing"],
          healthTrend: [
            {
              time: "2022-11-07T05:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T23:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T14:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.61,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 470.8181818181818,
              clientJitter: null,
              serverJitter: null,
              latency: 38.375,
              applicationDelay: 4.85,
              clientNetworkDelay: 0.875,
              serverNetworkDelay: 37.5,
              dscp: "",
              usage: 98034935,
              rate: 118830.22,
            },
            {
              time: "2022-11-06T13:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.72,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 432.25,
              clientJitter: null,
              serverJitter: null,
              latency: 35.875,
              applicationDelay: 0,
              clientNetworkDelay: 0.875,
              serverNetworkDelay: 35,
              dscp: "",
              usage: 90486647,
              rate: 100540.72,
            },
            {
              time: "2022-11-06T12:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.64,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 148.25,
              clientJitter: null,
              serverJitter: null,
              latency: 32.8,
              applicationDelay: 0.045454545454545456,
              clientNetworkDelay: 0.8000000000000007,
              serverNetworkDelay: 32,
              dscp: "",
              usage: 108003754,
              rate: 120004.17,
            },
            {
              time: "2022-11-06T11:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.63,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 1721,
              clientJitter: null,
              serverJitter: null,
              latency: 36.075,
              applicationDelay: 0.25,
              clientNetworkDelay: 0.5749999999999993,
              serverNetworkDelay: 35.5,
              dscp: "",
              usage: 20856933,
              rate: 139046.22,
            },
            {
              time: "2022-11-06T07:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.81,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 52,
              clientJitter: null,
              serverJitter: null,
              latency: 36.75,
              applicationDelay: 0,
              clientNetworkDelay: 0.75,
              serverNetworkDelay: 36.5,
              dscp: "",
              usage: 57613915,
              rate: 95840.51,
            },
            {
              time: "2022-11-06T06:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.64,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 881,
              clientJitter: null,
              serverJitter: null,
              latency: 36.9,
              applicationDelay: 0,
              clientNetworkDelay: 0.3999999999999986,
              serverNetworkDelay: 36.5,
              dscp: "",
              usage: 19566452,
              rate: 130443.01,
            },
            {
              time: "2022-11-03T16:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: "",
              usage: 1052,
              rate: 28.05,
            },
            {
              time: "2022-11-03T15:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.3,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 4306.714285714285,
              clientJitter: null,
              serverJitter: null,
              latency: 39.4875,
              applicationDelay: 20.875,
              clientNetworkDelay: 2.4875000000000007,
              serverNetworkDelay: 37,
              dscp: "",
              usage: 65038522,
              rate: 108397.54,
            },
          ],
          category: ["collaboration-apps"],
        },
        {
          collToolsAppName: "ms-teams-audio",
          minHealthScore: 8,
          avgHealthScore: 8,
          businessRelevance: ["business-relevant"],
          trafficClass: ["multimedia-conferencing"],
          healthTrend: [
            {
              time: "2022-11-06T14:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.74,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 568.6818181818182,
              clientJitter: null,
              serverJitter: null,
              latency: 38.55,
              applicationDelay: 0,
              clientNetworkDelay: 0.5500000000000007,
              serverNetworkDelay: 38,
              dscp: "",
              usage: 55546468,
              rate: 67329.05,
            },
            {
              time: "2022-11-06T13:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 669.25,
              clientJitter: null,
              serverJitter: null,
              latency: 36,
              applicationDelay: 0,
              clientNetworkDelay: 0.3000000000000007,
              serverNetworkDelay: 35.75,
              dscp: "",
              usage: 48147423,
              rate: 64196.56,
            },
            {
              time: "2022-11-06T12:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.72,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 676.6363636363636,
              clientJitter: null,
              serverJitter: null,
              latency: 32.775,
              applicationDelay: 0.09090909090909091,
              clientNetworkDelay: 0.4750000000000014,
              serverNetworkDelay: 32.5,
              dscp: "",
              usage: 62496379,
              rate: 75753.19,
            },
            {
              time: "2022-11-06T11:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 675.25,
              clientJitter: null,
              serverJitter: null,
              latency: 36.1,
              applicationDelay: 0.25,
              clientNetworkDelay: 0.6000000000000014,
              serverNetworkDelay: 35.5,
              dscp: "",
              usage: 9856066,
              rate: 65707.11,
            },
            {
              time: "2022-11-06T07:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.69,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 646.1428571428571,
              clientJitter: null,
              serverJitter: null,
              latency: 36.9,
              applicationDelay: 0.07142857142857142,
              clientNetworkDelay: 0.3999999999999986,
              serverNetworkDelay: 36.5,
              dscp: "",
              usage: 35192174,
              rate: 62512.78,
            },
            {
              time: "2022-11-06T06:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.77,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 604.75,
              clientJitter: null,
              serverJitter: null,
              latency: 36.3,
              applicationDelay: 0.25,
              clientNetworkDelay: 0.3000000000000007,
              serverNetworkDelay: 36,
              dscp: "",
              usage: 10001569,
              rate: 66677.13,
            },
            {
              time: "2022-11-03T15:00:00.000+0000",
              healthScore: 8,
              packetLoss: 1.29,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 679,
              clientJitter: null,
              serverJitter: null,
              latency: 32.575,
              applicationDelay: 41.25,
              clientNetworkDelay: 0.6583333333333339,
              serverNetworkDelay: 31.916666666666664,
              dscp: "",
              usage: 39356939,
              rate: 65594.9,
            },
          ],
          category: ["collaboration-apps"],
        },
        {
          collToolsAppName: "total",
          minHealthScore: 7,
          avgHealthScore: 7,
          businessRelevance: ["business-relevant"],
          trafficClass: ["multimedia-conferencing"],
          healthTrend: [
            {
              time: "2022-11-07T10:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-07T05:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T23:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T20:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T17:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 300,
              rate: 4,
            },
            {
              time: "2022-11-06T15:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 616,
              rate: 4.11,
            },
            {
              time: "2022-11-06T14:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.74,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 27021.545454545456,
              clientJitter: null,
              serverJitter: null,
              latency: 39.2,
              applicationDelay: 4.85,
              clientNetworkDelay: 0.875,
              serverNetworkDelay: 38.5,
              dscp: null,
              usage: 1067213675,
              rate: 1293847.94,
            },
            {
              time: "2022-11-06T13:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26238.8,
              clientJitter: null,
              serverJitter: null,
              latency: 36.725,
              applicationDelay: 6,
              clientNetworkDelay: 0.9749999999999996,
              serverNetworkDelay: 35.75,
              dscp: null,
              usage: 892239878,
              rate: 1169545.02,
            },
            {
              time: "2022-11-06T12:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.73,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 25553.909090909092,
              clientJitter: null,
              serverJitter: null,
              latency: 32.8,
              applicationDelay: 3.3636363636363638,
              clientNetworkDelay: 0.8000000000000007,
              serverNetworkDelay: 32.5,
              dscp: null,
              usage: 1196495220,
              rate: 817574.38,
            },
            {
              time: "2022-11-06T11:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.74,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 25970.5,
              clientJitter: null,
              serverJitter: null,
              latency: 36.35,
              applicationDelay: 0.25,
              clientNetworkDelay: 0.8500000000000014,
              serverNetworkDelay: 35.5,
              dscp: null,
              usage: 169735575,
              rate: 1131570.5,
            },
            {
              time: "2022-11-06T07:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.81,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26750.428571428572,
              clientJitter: null,
              serverJitter: null,
              latency: 36.9,
              applicationDelay: 6.714285714285714,
              clientNetworkDelay: 0.75,
              serverNetworkDelay: 36.5,
              dscp: null,
              usage: 659651369,
              rate: 1237743.82,
            },
            {
              time: "2022-11-06T06:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.77,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 26024.5,
              clientJitter: null,
              serverJitter: null,
              latency: 36.9,
              applicationDelay: 23.25,
              clientNetworkDelay: 0.3999999999999986,
              serverNetworkDelay: 36.5,
              dscp: null,
              usage: 171443767,
              rate: 1142958.45,
            },
            {
              time: "2022-11-03T16:00:00.000+0000",
              healthScore: null,
              packetLoss: null,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: null,
              clientJitter: null,
              serverJitter: null,
              latency: null,
              applicationDelay: null,
              clientNetworkDelay: null,
              serverNetworkDelay: null,
              dscp: null,
              usage: 1052,
              rate: 28.05,
            },
            {
              time: "2022-11-03T15:00:00.000+0000",
              healthScore: 7,
              packetLoss: 1.8,
              clientPacketLoss: null,
              serverPacketLoss: null,
              jitter: 27288,
              clientJitter: null,
              serverJitter: null,
              latency: 39.4875,
              applicationDelay: 41.25,
              clientNetworkDelay: 2.4875000000000007,
              serverNetworkDelay: 37,
              dscp: null,
              usage: 696250038,
              rate: 1301334.49,
            },
          ],
          category: ["collaboration-apps"],
        },

        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T31:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T30:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T29:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T28:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T27:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T26:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T25:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T24:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T23:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T22:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T21:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T20:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T18:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T17:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T16:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T15:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T12:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T11:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T10:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T09:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T08:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T07:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 1,
          numberOfFairApps: 9,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T06:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T05:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T04:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T03:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 10,
          numberOfGoodApps: 13,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T02:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T01:00:00.000+0000",
          healthyAppsPercent: 45.33,
          numberOfPoorApps: 0,
          numberOfFairApps: 9,
          numberOfGoodApps: 14,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T00:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 4,
          numberOfGoodApps: 15,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T23:00:00.000+0000",
          healthyAppsPercent: 34.67,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T22:00:00.000+0000",
          healthyAppsPercent: 34,
          numberOfPoorApps: 4,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T21:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T20:00:00.000+0000",
          healthyAppsPercent: 75,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T19:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T18:00:00.000+0000",
          healthyAppsPercent: 80,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-20T17:00:00.000+0000",
          healthyAppsPercent: 66.67,
          numberOfPoorApps: 0,
          numberOfFairApps: 1,
          numberOfGoodApps: 10,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T14:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
        {
          time: "2018-05-21T13:00:00.000+0000",
          healthyAppsPercent: 50,
          numberOfPoorApps: 2,
          numberOfFairApps: 9,
          numberOfGoodApps: 12,
          numberOfUnmonitoredApps: 0,
        },
      ],
      usage: {
        applicationCount: {
          "business-irrelevant": 7,
          "business-relevant": 12,
          default: 4,
        },
        usageDetails: {
          "business-irrelevant": {
            applicationGroup: {
              all: {
                distribution: 16607657,
                topApplications: [
                  {
                    applicationName: "disney-web-portal",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 83.164,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-conferencing",
                  },
                  {
                    applicationName: "bing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 13.602,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "netflix",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 4.9,
                    applicationGroup: "multimedia-streaming",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "skype",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-live-accounts",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.3,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              data: {
                distribution: 16607657,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "bing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 13.602,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "disney-web-portal",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 83.164,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-conferencing",
                  },
                ],
              },
            },
            trafficClass: {
              all: {
                distribution: 16607657,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "netflix",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 0,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-streaming",
                  },
                  {
                    applicationName: "disney-web-portal",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 83.164,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-conferencing",
                  },
                  {
                    applicationName: "ms-live-accounts",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 0,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "bing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 13.602,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              "bulk-data": {
                distribution: 16607657,
                topApplications: [
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "ms-update",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 56.85,
                    applicationGroup: "control-plane",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "binary-over-http",
                    businessRelevance: "default",
                    bandwidthUsage: 12.98,
                    applicationGroup: "control-plane",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "outlook-web-service",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 29.93,
                    applicationGroup: "control-plane",
                    trafficClass: "bulk-data",
                  },
                ],
              },
              "transactional-data": {
                distribution: 16607657,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "bing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 13.602,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-live-accounts",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 0,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ssl",
                    businessRelevance: "default",
                    bandwidthUsage: 21.163,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
            },
          },
          all: {
            applicationGroup: {
              all: {
                distribution: 229554536,
                topApplications: [
                  {
                    applicationName: "ms-office-365",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 32.508,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 31.43,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 22.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "citrix-static",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 21.696,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-streaming",
                  },
                  {
                    applicationName: "ms-teams",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 21,
                    applicationGroup: "video",
                    trafficClass: "multimedia-conferencing",
                  },

                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.864,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "binary-over-http",
                    businessRelevance: "default",
                    bandwidthUsage: 12.98,
                    applicationGroup: "control-plane",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "skype",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              data: {
                distribution: 80740352,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              "control-plane": {
                distribution: 80740352,
                topApplications: [
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.96,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 44.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
            },
            trafficClass: {
              all: {
                distribution: 22417428,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.96,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 44.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
              "ops-admin-mgmt": {
                distribution: 22417428,
                topApplications: [
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ms-office-365",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.508,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.96,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 30.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
              "bulk-data": {
                distribution: 22417428,
                topApplications: [
                  {
                    applicationName: "apple-services",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 1.47,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "outlook-web-service",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 29.93,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "binary-over-http",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 12.98,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "ms-update",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 56.85,
                    applicationGroup: "data",
                    trafficClass: "bulk-data",
                  },
                ],
              },
              "transactional-data": {
                distribution: 22417428,
                topApplications: [
                  {
                    applicationName: "espn-browsing",
                    businessRelevance: "business-irrelevant",
                    bandwidthUsage: 2.46,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
            },
          },
          "business-relevant": {
            applicationGroup: {
              all: {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "ms-office-365",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 32.508,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 31.43,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 22.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "citrix-static",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 21.696,
                    applicationGroup: "control-plane",
                    trafficClass: "multimedia-streaming",
                  },
                  {
                    applicationName: "ms-teams",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 21,
                    applicationGroup: "video",
                    trafficClass: "multimedia-conferencing",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.96,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.6,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "skype",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
              data: {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              "control-plane": {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 15.96,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 30.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
            },
            trafficClass: {
              all: {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 23.31,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
              "ops-admin-mgmt": {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "netbios-ns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 5.03,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "snmp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 33.59,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ntp",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 10.509,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "dns",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 19.404,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                  {
                    applicationName: "ssh",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 30.33,
                    applicationGroup: "control-plane",
                    trafficClass: "ops-admin-mgmt",
                  },
                ],
              },
              "transactional-data": {
                distribution: 56601266,
                topApplications: [
                  {
                    applicationName: "ms-office-web-apps",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 13.245,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "webex-meeting",
                    businessRelevance: "business-relevant",
                    bandwidthUsage: 2.49,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
            },
          },
          default: {
            applicationGroup: {
              all: {
                distribution: 14596456,
                topApplications: [
                  {
                    applicationName: "binary-over-http",
                    businessRelevance: "default",
                    bandwidthUsage: 68.98,
                    applicationGroup: "control-plane",
                    trafficClass: "bulk-data",
                  },
                  {
                    applicationName: "ssl",
                    businessRelevance: "default",
                    bandwidthUsage: 11.63,
                    applicationGroup: "control-plane",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 10.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 9.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              data: {
                distribution: 7298088,
                topApplications: [
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
            },
            trafficClass: {
              all: {
                distribution: 7298088,
                topApplications: [
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
              "transactional-data": {
                distribution: 7298088,
                topApplications: [
                  {
                    applicationName: "http",
                    businessRelevance: "default",
                    bandwidthUsage: 36.22,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                  {
                    applicationName: "ms-services",
                    businessRelevance: "default",
                    bandwidthUsage: 55.61,
                    applicationGroup: "data",
                    trafficClass: "transactional-data",
                  },
                ],
              },
            },
          },
        },
        topApplications: [
          {
            applicationName: "netbios-ns",
            businessRelevance: "business-relevant",
            bandwidthUsage: 5.03,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "ms-office-365",
            businessRelevance: "business-relevant",
            bandwidthUsage: 10.508,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "http",
            businessRelevance: "default",
            bandwidthUsage: 36.22,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "ms-services",
            businessRelevance: "default",
            bandwidthUsage: 55.61,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "apple-services",
            businessRelevance: "business-irrelevant",
            bandwidthUsage: 1.47,
            applicationGroup: null,
            trafficClass: "bulk-data",
          },
          {
            applicationName: "ms-office-web-apps",
            businessRelevance: "business-relevant",
            bandwidthUsage: 13.245,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "ntp",
            businessRelevance: "business-relevant",
            bandwidthUsage: 10.509,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "snmp",
            businessRelevance: "business-relevant",
            bandwidthUsage: 19.404,
            applicationGroup: null,
            trafficClass: null,
          },
          {
            applicationName: "ssh",
            businessRelevance: "business-relevant",
            bandwidthUsage: 30.33,
            applicationGroup: null,
            trafficClass: null,
          },
        ],
        appUsageTimeRange: {
          displayTime: 1546421100000,
          startTime: 1546420500000,
          endTime: 1546421100000,
        },
        distribution: {
          "business-irrelevant": 99614720,
          "business-relevant": 115343360,
          default: 14596456,
        },
      },

      latestCounts: {
        poor: 0,
        fair: 0,
        good: 2,
        healthyAppsPercent: 100,
        unknown: 1,
      },

      lowHealthApp: {
        poor: [
          {
            applicationName: "ms-office-365",
            healthScore: 2,
          },
          {
            applicationName: "webex-meeting",
            healthScore: 2,
          },
        ],
        fair: [
          {
            applicationName: "outlook-web-service",
            healthScore: 5,
          },
          {
            applicationName: "snmp",
            healthScore: 4,
          },
          {
            applicationName: "ms-lync",
            healthScore: 5,
          },
          {
            applicationName: "ntp",
            healthScore: 4,
          },
          {
            applicationName: "ssh",
            healthScore: 4,
          },
        ],
        good: [
          {
            applicationName: "netbios-ns",
            healthScore: 9,
          },
          {
            applicationName: "ms-office-web-apps",
            healthScore: 10,
          },
          {
            applicationName: "skype",
            healthScore: 9,
          },
          {
            applicationName: "ssl",
            healthScore: 8,
          },
          {
            applicationName: "http",
            healthScore: 9,
          },
        ],
        unknown: [
          {
            applicationName: "capwap-control",
            healthScore: null,
          },
        ],
      },
      trendUsage: {
        totalUsage: 26032366,
        numberOfBusinessRelevantApps: 8,
        topAppsBandwidthOverTime: [
          {
            time: "2022-04-04T07:00:00.000+0000",
            appBandwidth: {
              amazon: 2.9419444444444443,
              "ms-update": 687.9497222222222,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 14.1675,
              "ms-services": 50.755,
            },
          },
          {
            time: "2022-04-04T08:00:00.000+0000",
            appBandwidth: {
              amazon: 2.9008333333333334,
              "ms-update": 0.4438888888888889,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.0325,
              "ms-services": 28.511111111111113,
            },
          },
          {
            time: "2022-04-04T09:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8961111111111113,
              "ms-update": 0.44722222222222224,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 25.914166666666667,
            },
          },
          {
            time: "2022-04-04T10:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8986111111111112,
              "ms-update": 0,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 28.83138888888889,
            },
          },
          {
            time: "2022-04-04T11:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8969444444444443,
              "ms-update": 3.735,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 27.89666666666667,
              "ms-services": 36.99805555555555,
            },
          },
          {
            time: "2022-04-04T12:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 1.2136111111111112,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 23.343055555555555,
            },
          },
          {
            time: "2022-04-04T13:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 4.148611111111111,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 27.85388888888889,
            },
          },
          {
            time: "2022-04-04T14:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8852777777777776,
              "ms-update": 0.7155555555555555,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 14.0875,
            },
          },
          {
            time: "2022-04-04T15:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 0.8397222222222223,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 26.660833333333333,
            },
          },
          {
            time: "2022-04-04T16:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 0.8916666666666667,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 18.825,
            },
          },
          {
            time: "2022-04-04T17:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 2.7969444444444442,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.501666666666667,
              "ms-services": 34.251666666666665,
            },
          },
          {
            time: "2022-04-04T18:00:00.000+0000",
            appBandwidth: {
              amazon: 2.9008333333333334,
              "ms-update": 0.9536111111111111,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.034722222222222,
              "ms-services": 22.111666666666668,
            },
          },
          {
            time: "2022-04-04T19:00:00.000+0000",
            appBandwidth: {
              amazon: 3.0880555555555556,
              "ms-update": 0.49277777777777776,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 32.217777777777776,
            },
          },
          {
            time: "2022-04-04T20:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 635.0066666666667,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.014166666666667,
              "ms-services": 45.03638888888889,
            },
          },
          {
            time: "2022-04-04T21:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8658333333333332,
              "ms-update": 0.7922222222222223,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 33.723055555555554,
            },
          },
          {
            time: "2022-04-04T22:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8986111111111112,
              "ms-update": 1.416111111111111,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 28.525277777777777,
            },
          },
          {
            time: "2022-04-04T23:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8658333333333332,
              "ms-update": 0.8547222222222223,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.372777777777777,
              "ms-services": 24.032777777777778,
            },
          },
          {
            time: "2022-04-05T00:00:00.000+0000",
            appBandwidth: {
              amazon: 2.9008333333333334,
              "ms-update": 0.8627777777777778,
              "edgedl.me.gvt1.com": 2029.4369444444444,
              "ms-live-accounts": 0,
              "ms-services": 23.574444444444445,
            },
          },
          {
            time: "2022-04-05T01:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 0.7825,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 22.558333333333334,
            },
          },
          {
            time: "2022-04-05T02:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 2821.6041666666665,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 7.032222222222222,
              "ms-services": 45.98555555555556,
            },
          },
          {
            time: "2022-04-05T03:00:00.000+0000",
            appBandwidth: {
              amazon: 2.8969444444444443,
              "ms-update": 0.44305555555555554,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 22.829444444444444,
            },
          },
          {
            time: "2022-04-05T04:00:00.000+0000",
            appBandwidth: {
              amazon: 2.879166666666667,
              "ms-update": 0.16444444444444445,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 24.2625,
            },
          },
          {
            time: "2022-04-05T05:00:00.000+0000",
            appBandwidth: {
              amazon: 2.9094444444444445,
              "ms-update": 0.5119444444444444,
              "edgedl.me.gvt1.com": 0,
              "ms-live-accounts": 0,
              "ms-services": 22.779166666666665,
            },
          },
        ],
        totalBandwidthOverTime: [
          {
            timestamp: 1649055600000,
            totalBandwidth: 764.7666666666667,
          },
          {
            timestamp: 1649059200000,
            totalBandwidth: 46.09,
          },
          {
            timestamp: 1649062800000,
            totalBandwidth: 36.93333333333333,
          },
          {
            timestamp: 1649073600000,
            totalBandwidth: 32.0175,
          },
          {
            timestamp: 1649077200000,
            totalBandwidth: 39.52444444444444,
          },
          {
            timestamp: 1649080800000,
            totalBandwidth: 31.225555555555555,
          },
          {
            timestamp: 1649091600000,
            totalBandwidth: 49.187222222222225,
          },
          {
            timestamp: 1649095200000,
            totalBandwidth: 37.613055555555555,
          },
          {
            timestamp: 1649098800000,
            totalBandwidth: 43.4625,
          },
          {
            timestamp: 1649102400000,
            totalBandwidth: 697.5836111111112,
          },
          {
            timestamp: 1649106000000,
            totalBandwidth: 113.14777777777778,
          },
          {
            timestamp: 1649109600000,
            totalBandwidth: 37.125277777777775,
          },
          {
            timestamp: 1649113200000,
            totalBandwidth: 39.865,
          },
          {
            timestamp: 1649116800000,
            totalBandwidth: 2068.15,
          },
          {
            timestamp: 1649120400000,
            totalBandwidth: 27.735277777777778,
          },
          {
            timestamp: 1649124000000,
            totalBandwidth: 2882.1275,
          },
          {
            timestamp: 1649127600000,
            totalBandwidth: 30.986944444444443,
          },
          {
            timestamp: 1649131200000,
            totalBandwidth: 38.29083333333333,
          },
          {
            timestamp: 1649134800000,
            totalBandwidth: 34.151666666666664,
          },
        ],
        topAppsUsage: {
          amazon: 239971,
          "ms-update": 15001441,
          "edgedl.me.gvt1.com": 7305973,
          "ms-live-accounts": 306188,
          "ms-services": 2389205,
        },
      },
    },
  },
});
