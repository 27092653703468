//asr1002x family Cisco ASR 1002-X Router
define({
  "runningImageList":
  [
    {
      "name": "asr1002x-universalk9.17.09.01a.SPA.bin",
      "imageUuid": "5b999975-501d-1111-bdfb-81fe407284e3",
      "version": "17.9.1",
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "true",
      "md5Checksum": "e7325a7b20b38beb04b7c0e1ef08ae81",
      "shaCheckSum": "b870243e02af435b3a20b7b90378d9170d7ffa494075e786664d696ca15f441220343d67b5112e5e73ee4af14c37525c4655866f6482256db4043a9e3e4f824b",
      "createdTime": "2022-08-24 13:50:00.0",
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12346789-3333-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 706283597 ,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": true,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },{
      "name": "asr1002x-universalk9.17.06.01a.SPA.bin",
      "imageUuid": "5b999975-501d-11aa-bdfb-81fe407284e3",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "e7325a7b20b38beb04b7c0e1ef08ae81",
      "shaCheckSum": "b870243e02af435b3a20b7b90378d9170d7ffa494075e786664d696ca15f441220343d67b5112e5e73ee4af14c37525c4655866f6482256db4043a9e3e4f824b",
      "createdTime": "2021-09-14 13:50:00.0",
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12346789-3333-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 706283597 ,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.17.03.03.SPA.bin",
      "imageUuid": "5b999975-501d-11aa-bbbb-81fe407284e3",
      "version": "17.3.3",//Amsterdam-17.3.3
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "1262c12b864bd416ef206ce7f3a0e320",
      "shaCheckSum": "426bb8b1600dd1ef907ce754fbe161d905d3885a53afa5344ab4daea49f9a345f05437c026c83067e30df454c9915599a3a954ddffe8ebae3ba0dd4f38cf3eaf",
      "createdTime": "2021-03-05 13:50:00.0",//5 March 2021 Release date
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12346789-3333-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 859647475,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.17.03.01a.SPA.bin",
      "imageUuid": "5b009975-501d-11aa-bbbb-81fe407284e3",
      "version": "17.3.1a",//Amsterdam-17.3.1a
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "0a4b47f3ab58bffa208c194f783c138e",
      "shaCheckSum": "14eb5b518a30c62f7b8c6d4e2aa43116b95bc1ded6079a1fb8b83366b4667fd080d44aa778455fe3fa3a3d9ac0e52a545f205a8b10b56a4fc22dbf3ae30746ae",
      "createdTime": "2020-10-16 13:50:00.0",//16 Aug 2020 Release date
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12348765-3333-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 862425396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.17.02.01r.SPA.bin",
      "imageUuid": "5b009975-501d-12ab-aaaa-81fe407284e3",
      "version": "17.2.1r",//Amsterdam-17.2.1r
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "2903ebe85aa333f73c8cfc3298495407",
      "shaCheckSum": "86b9ea560be76bbbc1caa107e1400c37e43f923b361f5aab282b7ab3ba714ae31de4011895a2976b29ab40e161465bd3935cc9f4a416f5c75cb9a931dfe5e6e7",
      "createdTime": "2020-04-28 17:50:00.0",//14 Apr 2020 Release date
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12348765-abcd-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 858075097,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.16.12.02t.SPA.bin",
      "imageUuid": "5b009975-501d-12ab-aacc-81fe407284e3",
      "version": "16.12.2t",//Gibraltar-16.12.2t
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "fe5c9998f3a61b80a3a58951b0ea1b0f",
      "shaCheckSum": "e9c28a10d393ef67d8327bdbf3204ba484e7be054fb61bdbebb70cdd67526102a1eceff10825a7219a9afb38210e3218598be7e935b1cf256f5e5726a5327d58",
      "createdTime": "2020-02-12 10:30:00.0",
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12348765-abcd-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 767979426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.17.01.01.SPA.bin",
      "imageUuid": "5b009975-501d-12ab-bdca-81fe407284e3",
      "version": "17.1.1",//Amsterdam-17.1.1
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "4fc799c37363d9fbfbf86d09b8873c73",
      "shaCheckSum": "17cc563aac91fa741fc5772d7499d01645f603cdbbf498e9030d14b32df8c58adb615aa575fc13badf038db0f3de8bb374eedede0d5f803dbcbd617cbe99e88d",
      "createdTime": "2019-11-23 10:30:00.0",
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12348765-abcd-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 808073431,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1002x-universalk9.16.12.01s.SPA.bin",
      "imageUuid": "5b009975-501d-12ab-a9cb-81fe407284e3",
      "version": "16.12.1s",
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": "37fa95837b24703c9775e1cb537069bc",
      "shaCheckSum": "d24357e571e4a4645fb04325e7aba1fd86118a228110729aae1299674cc5cecfb39234996c2d678aeff953380f31ee6014091f8901d6b991dfb5a407f9ce771d",
      "createdTime": "2018-09-06 10:30:00.0",
      "applicationType": null,
      "feature": null,
      "fileServiceId": "12348765-abcd-1234-5678-abcd3456",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1002x",
      "vendor": null,
      "filesize": 767979426,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": false,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    }
  ]
});
