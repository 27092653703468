define({
"Template":
{
  "name": "fabric_wlc_reachability",
  "severity": "HIGH",
  "id": "AWXj7K67ARj6IWfUaZbl",
  "time": "2018-09-16T19:47:43.593+0000",
  "endTime": "2018-09-16T19:57:43.591+0000",
  "count": 3,
  "attributes": [
    {
      "key": "destIp",
      "value": "10.4.30.30"
    },
    {
      "key": "managementIpAddr",
      "value": "10.4.154.237"
    },
    {
      "key": "type",
      "value": "issue"
    },
    {
      "key": "uuid",
      "value": ""
    },
    {
      "key": "sourceRole",
      "value": "WLC"
    },
    {
      "key": "scope",
      "value": "global"
    },
    {
      "key": "rootCause",
      "value": "10.4.154.237_10.4.30.30_MapServer"
    },
    {
      "key": "sessionType",
      "value": "MapServer"
    },
    {
      "key": "redundancy",
      "value": ""
    },
    {
      "key": "deviceType",
      "value": "ENTERPRISE"
    },
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "entityId",
      "value": "10.4.154.237"
    },
    {
      "key": "sessionIdentifier",
      "value": "10.4.154.237_10.4.30.30_MapServer"
    },
    {
      "key": "sourceIp",
      "value": "10.4.154.237"
    },
    {
      "key": "category",
      "value": "connected"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})