define({
    "Template":
    {
        "request": {
          "key": "ms-office-web-apps",
          "type": "Application",
          "label": "ms-office-web-apps",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "0963a4cb-f614-49c4-b500-9fa770f8a9a9"
            }
          ]
        },
        "data": {
          "response": {
            "id": "0963a4cb-f614-49c4-b500-9fa770f8a9a9",
            "instanceId": 4784,
            "authEntityId": 4784,
            "displayName": "4784",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265513420,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265513420,
            "name": "ms-office-web-apps",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ms-office-web-apps",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "6957175c-f28d-4f3b-b723-1fe24cf73b17",
                "instanceId": 11732,
                "authEntityId": 11732,
                "displayName": "11732",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "ms-cloud-group",
                "applicationType": "DEFAULT",
                "categoryId": "2748fc1c-a274-42da-a4c2-8c2ec3bfe875",
                "createTime": 1528265513420,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Web-based versions of Microsoft Word, Excel, PowerPoint and OneNote",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265513420,
                "longDescription": "Microsoft Office Web Apps is the web-based version of the Microsoft Office productivity suite. It includes the web-based versions of Microsoft Word, Microsoft Excel, Microsoft PowerPoint and Microsoft OneNote. The web applications allow users to access their documents within a web browser and collaborate with other users online.",
                "name": "ms-office-web-apps",
                "nbarId": "1500",
                "p2pTechnology": "false",
                "popularity": 10,
                "rank": 65535,
                "references": "http://office.microsoft.com/en-us/web-apps/",
                "selectorId": "563",
                "subCategory": "e373d05f-238a-4e17-885c-68369ae7e390",
                "trafficClass": "TRANSACTIONAL_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "cde64f37-2c5b-43ef-90a4-d4e7ac254170",
                "instanceId": 13992,
                "authEntityId": 13992,
                "displayName": "13992",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "1b837c33-add3-4be9-86ee-2330af629f49",
                "instanceId": 13993,
                "authEntityId": 13993,
                "displayName": "13993",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "779cbca2-a54b-4666-a10f-66d02be871dc"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "0963a4cb-f614-49c4-b500-9fa770f8a9a9",
              "instanceId": 4784,
              "authEntityId": 4784,
              "displayName": "4784",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265513420,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265513420,
              "name": "ms-office-web-apps",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ms-office-web-apps",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "6957175c-f28d-4f3b-b723-1fe24cf73b17",
                  "instanceId": 11732,
                  "authEntityId": 11732,
                  "displayName": "11732",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "ms-cloud-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "2748fc1c-a274-42da-a4c2-8c2ec3bfe875",
                  "createTime": 1528265513420,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Web-based versions of Microsoft Word, Excel, PowerPoint and OneNote",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265513420,
                  "longDescription": "Microsoft Office Web Apps is the web-based version of the Microsoft Office productivity suite. It includes the web-based versions of Microsoft Word, Microsoft Excel, Microsoft PowerPoint and Microsoft OneNote. The web applications allow users to access their documents within a web browser and collaborate with other users online.",
                  "name": "ms-office-web-apps",
                  "nbarId": "1500",
                  "p2pTechnology": "false",
                  "popularity": 10,
                  "rank": 65535,
                  "references": "http://office.microsoft.com/en-us/web-apps/",
                  "selectorId": "563",
                  "subCategory": "e373d05f-238a-4e17-885c-68369ae7e390",
                  "trafficClass": "TRANSACTIONAL_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "cde64f37-2c5b-43ef-90a4-d4e7ac254170",
                  "instanceId": 13992,
                  "authEntityId": 13992,
                  "displayName": "13992",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "1b837c33-add3-4be9-86ee-2330af629f49",
                  "instanceId": 13993,
                  "authEntityId": 13993,
                  "displayName": "13993",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "779cbca2-a54b-4666-a10f-66d02be871dc"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });