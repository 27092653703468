define({
    "Topology_Link_Template": {
        source: "",
        startPortID: "",
        startPortName: "GigabitEthernet1/0/3",
        startPortSpeed: "999999",
        target: "",
        endPortID: "3147e714-856f-4b09-bf1c-c41d1ea91d9f",
        endPortName: "GigabitEthernet1/0/5",
        endPortIpv4Address: "192.168.29.2",
        endPortIpv4Mask: "255.255.255.252",
        startPortIpv4Address: "192.168.29.2",
        startPortIpv4Mask: "255.255.255.252",
        endPortSpeed: "1000000",
        linkStatus: "up",
        additionalInfo: {},
        id: "174195",
        topology_type: "link"
    }
});
