define({
    "getRfCoverageSummaryLatest01_24ghz_Template":[
      {
        "apDensity": "High",
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "connectivitySnr": 35,
        "connectivitySnrDensity": "High",
        "frequencyBand": 1,
        "totalApCount":23,
        "totalClients":42,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:30:00",
        "timestampMs": "1648103400000"
      }
    ],
    "getRfPerformanceSummaryLatest01_24ghz_Template":[
      {
        "apPercentageWithHighCci": 9,
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealthScore": 40,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000",
        "totalRrmChangesV2": 0
      }
    ],
    "getFraLatestSummary01_24ghz_Template":
        {
          "buildingId": "624b038c-e463-4365-836c-9581e844b059",
          "siteId": "972ffb67-2cd3-48ab-94c5-6aba2ba90fd2",
          "timestamp": "2023-03-29T05:00:00",
          "timestampMs": "1680066000000",
          "total24Radios": 10,
          "total5Radios": 10,
          "total6Radios": 0,
          "totalFraCapableRadios": 20,
          "totalFraEnabledRadios": 18,
          "totalMonitorRadios": 0,
          "totalRadios": 21
      
    },
    "getCurrentInsights01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "description": "Consider changing the configured Busy Hours for RRM to be more effective.",
        "frequencyBand": 0,
        "insightType": "busy-hours",
        "insightValue": "[\"11:00\",\"15:00\"]",
        "reason": "Based on analyzing the wireless client association and usage patterns, we recommend this busy hour interval.",
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-09T00:00:00",
        "timestampMs": "1646784000000"
      }
    ],
    "getRrmChangesLatestSummaryV201_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 22,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:30:00",
        "timestampMs": "1648103400000"
      }
    ],
    "getRrmHealthLatestSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 18,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getCoChannelInterferenceLatestSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 17,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 3,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 2,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getUtilizationPerChannelLatestSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000",
        "utilByChannelV2": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0,
            "txRxMaxUtilization": 0,
            "txRxMinUtilization": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0,
            "txRxMaxUtilization": 0,
            "txRxMinUtilization": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0,
            "txRxMaxUtilization": 0,
            "txRxMinUtilization": 0
          }
        ]
      }
    ],
    "getApRogueAndRadarPerChannelLatestSummary01_24ghz_Template":[
      {
        "apByChannel": [
          {
            "apCount": 7,
            "channel": 1,
            "radarCount": 7
          },
          {
            "apCount": 8,
            "channel": 6,
            "radarCount": 7
          },
          {
            "apCount": 7,
            "channel": 11,
            "radarCount": 8
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getApSpatialDensityLatestSummary01_24ghz_Template":[
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getPowerDistributionLatestSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 7
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 3
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getApSpatialDensityTrendSummary01_24ghz_Template":[
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:00:00",
        "timestampMs": "1647882000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:00:00",
        "timestampMs": "1647885600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:00:00",
        "timestampMs": "1647889200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:30:00",
        "timestampMs": "1647891000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T20:00:00",
        "timestampMs": "1647892800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:30:00",
        "timestampMs": "1647927000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:00:00",
        "timestampMs": "1647928800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:30:00",
        "timestampMs": "1647930600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:00:00",
        "timestampMs": "1647932400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:30:00",
        "timestampMs": "1647934200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:00:00",
        "timestampMs": "1647936000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:30:00",
        "timestampMs": "1647937800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:00:00",
        "timestampMs": "1647939600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:30:00",
        "timestampMs": "1647941400000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:00:00",
        "timestampMs": "1647943200000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:30:00",
        "timestampMs": "1647945000000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:30:00",
        "timestampMs": "1647948600000"
      },
      {
        "apSpatialDensity": [
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "<5"
          },
          {
            "nbrApCount": 10,
            "nbrClientCount": 0,
            "nbrCountBin": "5-10"
          },
          {
            "nbrApCount": 8,
            "nbrClientCount": 0,
            "nbrCountBin": "10-15"
          },
          {
            "nbrApCount": 2,
            "nbrClientCount": 0,
            "nbrCountBin": "15-20"
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getCoChannelInterferenceTrendSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 53,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 31,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 54,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 17,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 32,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 31,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 20,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 30,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 18,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 51,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 31,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 51,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 21,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 30,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 31,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 54,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 21,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 53,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 21,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 51,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 21,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 54,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 26,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 54,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 21,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 20,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 26,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 20,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 26,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 17,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 55,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 19,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 58,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 17,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 26,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 17,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 26,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 16,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 27,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:00:00",
        "timestampMs": "1647928800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 59,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:30:00",
        "timestampMs": "1647930600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 58,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:00:00",
        "timestampMs": "1647932400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 57,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 15,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:30:00",
        "timestampMs": "1647934200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 61,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 12,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:00:00",
        "timestampMs": "1647936000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 30,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:30:00",
        "timestampMs": "1647937800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 30,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:00:00",
        "timestampMs": "1647939600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 60,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 14,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:30:00",
        "timestampMs": "1647941400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 61,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:00:00",
        "timestampMs": "1647943200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 61,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:30:00",
        "timestampMs": "1647945000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 61,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 29,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 61,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 14,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:30:00",
        "timestampMs": "1647948600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 62,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 13,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 28,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getPowerDistributionTrendSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 5,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 9,
            "powerRadioCount": 25
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 6
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 38
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 11
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 9,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 2,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 11,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 36
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 11,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 11,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 36
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 11
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 11
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 11,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:00:00",
        "timestampMs": "1647882000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 11,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:00:00",
        "timestampMs": "1647885600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 2,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 32
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 4,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:00:00",
        "timestampMs": "1647889200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 4,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:30:00",
        "timestampMs": "1647891000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 4,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T20:00:00",
        "timestampMs": "1647892800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 32
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 1,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 2,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 4,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 36
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 7
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 4,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 0,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 31
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 1,
            "powerRadioCount": 12
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 3,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 35
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 8
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 38
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 2,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 33
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 10
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:30:00",
        "timestampMs": "1647927000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:00:00",
        "timestampMs": "1647928800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:30:00",
        "timestampMs": "1647930600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 5,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:00:00",
        "timestampMs": "1647932400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:30:00",
        "timestampMs": "1647934200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:00:00",
        "timestampMs": "1647936000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 13,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:30:00",
        "timestampMs": "1647937800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:00:00",
        "timestampMs": "1647939600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 9,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:30:00",
        "timestampMs": "1647941400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:00:00",
        "timestampMs": "1647943200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:30:00",
        "timestampMs": "1647945000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:30:00",
        "timestampMs": "1647948600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 1,
            "powerRadioCount": 34
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 3,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 3,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 6,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 10,
            "powerRadioCount": 9
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 37
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 12,
            "powerRadioCount": 12
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getRrmChangesTrendSummaryV201_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 87,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 1,
          "totalImpactedRadios": 5,
          "totalRadios": 106,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 103,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 2,
          "totalRadios": 103,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 105,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 3,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 105,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 1,
          "totalImpactedRadios": 2,
          "totalRadios": 105,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 104,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 104,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 2,
          "totalRadios": 104,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 1,
          "totalImpactedRadios": 6,
          "totalRadios": 104,
          "txPowerChangeCount": 4
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 4,
          "totalRadios": 103,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 103,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 5,
          "totalRadios": 103,
          "txPowerChangeCount": 5
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 2,
          "totalRadios": 103,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 103,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 103,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 1,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:00:00",
        "timestampMs": "1647928800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:30:00",
        "timestampMs": "1647930600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:00:00",
        "timestampMs": "1647932400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:30:00",
        "timestampMs": "1647934200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:00:00",
        "timestampMs": "1647936000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:30:00",
        "timestampMs": "1647937800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:00:00",
        "timestampMs": "1647939600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:30:00",
        "timestampMs": "1647941400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:00:00",
        "timestampMs": "1647943200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:30:00",
        "timestampMs": "1647945000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:30:00",
        "timestampMs": "1647948600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmChanges": {
          "channelChangeCount": 0,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 0,
          "totalRadios": 103,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getRrmHealthTrendSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 27,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 16,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 26,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 26,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 19,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 27,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 61,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 26,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 27,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 57,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 28,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 56,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 28,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 14,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 61,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 15,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 21,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 22,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 20,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 57,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 21,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 23,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 57,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 21,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 22,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 21,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 21,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 22,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 20,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 59,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 20,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 20,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 58,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:00:00",
        "timestampMs": "1647928800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 61,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T06:30:00",
        "timestampMs": "1647930600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 60,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:00:00",
        "timestampMs": "1647932400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 19,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 59,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T07:30:00",
        "timestampMs": "1647934200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 15,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 64,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:00:00",
        "timestampMs": "1647936000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T08:30:00",
        "timestampMs": "1647937800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 17,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:00:00",
        "timestampMs": "1647939600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 61,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T09:30:00",
        "timestampMs": "1647941400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:00:00",
        "timestampMs": "1647943200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 16,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 63,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T10:30:00",
        "timestampMs": "1647945000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 24,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 16,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 63,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:00:00",
        "timestampMs": "1647946800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 23,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 18,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 62,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T11:30:00",
        "timestampMs": "1647948600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "rrmHealth": [
          {
            "apCount": 25,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 15,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 63,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T12:00:00",
        "timestampMs": "1647950400000"
      }
    ],
    "getUtilizationPerChannelTrendSummary01_24ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T08:00:00",
        "timestampMs": "1648022400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T08:30:00",
        "timestampMs": "1648024200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 2,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T09:00:00",
        "timestampMs": "1648026000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T09:30:00",
        "timestampMs": "1648027800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 2,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T10:00:00",
        "timestampMs": "1648029600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 2,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T10:30:00",
        "timestampMs": "1648031400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T11:00:00",
        "timestampMs": "1648033200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 132,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 136,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 140,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 144,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 149,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 153,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 157,
            "interference": 1,
            "txRxUtil": 0
          },
          {
            "channel": 161,
            "interference": 1,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T11:30:00",
        "timestampMs": "1648035000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T12:00:00",
        "timestampMs": "1648036800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T12:30:00",
        "timestampMs": "1648038600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T13:00:00",
        "timestampMs": "1648040400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T13:30:00",
        "timestampMs": "1648042200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 2,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T14:00:00",
        "timestampMs": "1648044000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T14:30:00",
        "timestampMs": "1648045800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T15:00:00",
        "timestampMs": "1648047600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T15:30:00",
        "timestampMs": "1648049400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T16:00:00",
        "timestampMs": "1648051200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 5,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T16:30:00",
        "timestampMs": "1648053000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T17:00:00",
        "timestampMs": "1648054800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T17:30:00",
        "timestampMs": "1648056600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T18:00:00",
        "timestampMs": "1648058400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T18:30:00",
        "timestampMs": "1648060200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T19:00:00",
        "timestampMs": "1648062000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T19:30:00",
        "timestampMs": "1648063800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T20:00:00",
        "timestampMs": "1648065600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T20:30:00",
        "timestampMs": "1648067400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T21:00:00",
        "timestampMs": "1648069200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T21:30:00",
        "timestampMs": "1648071000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T22:00:00",
        "timestampMs": "1648072800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T22:30:00",
        "timestampMs": "1648074600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T23:00:00",
        "timestampMs": "1648076400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T23:30:00",
        "timestampMs": "1648078200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T00:00:00",
        "timestampMs": "1648080000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T00:30:00",
        "timestampMs": "1648081800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 3,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T01:00:00",
        "timestampMs": "1648083600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T01:30:00",
        "timestampMs": "1648085400000",
        "utilByChannel": [
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T02:00:00",
        "timestampMs": "1648087200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T02:30:00",
        "timestampMs": "1648089000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T03:00:00",
        "timestampMs": "1648090800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T03:30:00",
        "timestampMs": "1648092600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T04:00:00",
        "timestampMs": "1648094400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T04:30:00",
        "timestampMs": "1648096200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 3,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T05:00:00",
        "timestampMs": "1648098000000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T05:30:00",
        "timestampMs": "1648099800000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:00:00",
        "timestampMs": "1648101600000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:30:00",
        "timestampMs": "1648103400000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T07:00:00",
        "timestampMs": "1648105200000",
        "utilByChannel": [
          {
            "channel": 1,
            "interference": 5,
            "txRxUtil": 0
          },
          {
            "channel": 6,
            "interference": 4,
            "txRxUtil": 0
          },
          {
            "channel": 11,
            "interference": 4,
            "txRxUtil": 0
          }
        ]
      }
    ],
    "getApRogueRadarPerChannelTrendSummary01_24ghz_Template":[
      {
        "apByChannel": [
          {
            "apCount": 7,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 8,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 7,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T10:00:00",
        "timestampMs": "1648029600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 34,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 38,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T10:30:00",
        "timestampMs": "1648031400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 31,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 41,
            "channel": 11,
            "radarCount": 0
          },
          {
            "apCount": 1,
            "channel": 144,
            "radarCount": 0
          },
          {
            "apCount": 1,
            "channel": 161,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T11:00:00",
        "timestampMs": "1648033200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T11:30:00",
        "timestampMs": "1648035000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T12:00:00",
        "timestampMs": "1648036800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T12:30:00",
        "timestampMs": "1648038600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T13:00:00",
        "timestampMs": "1648040400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 30,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T13:30:00",
        "timestampMs": "1648042200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T14:00:00",
        "timestampMs": "1648044000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T14:30:00",
        "timestampMs": "1648045800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T15:00:00",
        "timestampMs": "1648047600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T15:30:00",
        "timestampMs": "1648049400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T16:00:00",
        "timestampMs": "1648051200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T16:30:00",
        "timestampMs": "1648053000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T17:00:00",
        "timestampMs": "1648054800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T17:30:00",
        "timestampMs": "1648056600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T18:00:00",
        "timestampMs": "1648058400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T18:30:00",
        "timestampMs": "1648060200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T19:00:00",
        "timestampMs": "1648062000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T19:30:00",
        "timestampMs": "1648063800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T20:00:00",
        "timestampMs": "1648065600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T20:30:00",
        "timestampMs": "1648067400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T21:00:00",
        "timestampMs": "1648069200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T21:30:00",
        "timestampMs": "1648071000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T22:00:00",
        "timestampMs": "1648072800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T22:30:00",
        "timestampMs": "1648074600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T23:00:00",
        "timestampMs": "1648076400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-23T23:30:00",
        "timestampMs": "1648078200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T00:00:00",
        "timestampMs": "1648080000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T00:30:00",
        "timestampMs": "1648081800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T01:00:00",
        "timestampMs": "1648083600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T01:30:00",
        "timestampMs": "1648085400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T02:00:00",
        "timestampMs": "1648087200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T02:30:00",
        "timestampMs": "1648089000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T03:00:00",
        "timestampMs": "1648090800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T03:30:00",
        "timestampMs": "1648092600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T04:00:00",
        "timestampMs": "1648094400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T04:30:00",
        "timestampMs": "1648096200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T05:00:00",
        "timestampMs": "1648098000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T05:30:00",
        "timestampMs": "1648099800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:00:00",
        "timestampMs": "1648101600000"
      },
      {
        "apByChannel": [
          {
            "apCount": 32,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 40,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T06:30:00",
        "timestampMs": "1648103400000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T07:00:00",
        "timestampMs": "1648105200000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T07:30:00",
        "timestampMs": "1648107000000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T08:00:00",
        "timestampMs": "1648108800000"
      },
      {
        "apByChannel": [
          {
            "apCount": 33,
            "channel": 1,
            "radarCount": 0
          },
          {
            "apCount": 29,
            "channel": 6,
            "radarCount": 0
          },
          {
            "apCount": 39,
            "channel": 11,
            "radarCount": 0
          }
        ],
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-24T08:30:00",
        "timestampMs": "1648110600000"
      }
    ],
    "getRrmChangesLatestDetail01_24ghz_Template": [
      {
        "apMac": "70:DB:98:07:A5:60",
        "apName": "csg-bgl18-52a-cap37",
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
        "rrmChanges": [
          {
            "changeCategory": "Channel Change",
            "changeReasons": [
              "[System Driven Channel Change - Slot 0] Channel is changed to 11 from 1."
            ],
            "isChanged": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "slot": 0,
        "timestamp": "2022-03-31T04:00:00",
        "timestampMs": "1648699200000"
      }
    ],
    "getRrmChangesTrendDetail01_24ghz_Template": [
      {
        "apMac": "68:7D:B4:5F:EA:20",
        "apName": "csg-bgl18-61a-cap23",
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 1,
        "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
        "rrmChanges": [
          {
            "changeCategory": "Tx Power Change",
            "changeReasons": [
              "[System Driven Tx Power Change]",
              " -Current Tx Power Level 3 (15 dBm) is lower than optimal Tx Power Level 2 (18 dBm).",
              " -Channel Max Power is 21 dBm and threshold is -70."
            ],
            "isChanged": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "slot": 0,
        "timestamp": "2022-03-30T19:00:00",
        "timestampMs": "1648666800000"
      }
    ],
   "getRrmHealthLatestDetail01_24ghz_Template" : [
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 67,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 24 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:9D:3C:20",
      "apName": "csg-bgl18-51a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 6 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 6 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 15% (Good)",
            " -[Channel Interference] Interference RSSI is -73 and Duty Cycle is 23% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 67,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "68:7D:B4:5F:6C:50",
      "apName": "csg-bgl18-62a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 82,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -68 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 82
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 82,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:C4:BA:A0",
      "apName": "csg-bgl18-31a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:61:B9:00",
      "apName": "csg-bgl18-41a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "539afb61-e2fe-409b-a5d9-b5464073c81f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 96,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:84:C1:E0",
      "apName": "csg-bgl18-42a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:95:3F:60",
      "apName": "csg-bgl18-71a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 18 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 18 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -71 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:13:C9:C0",
      "apName": "csg-bgl18-02a-cap27",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 4 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 4 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:20:9D:00",
      "apName": "csg-bgl18-52a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "00:5D:73:AE:3F:40",
      "apName": "csg-bgl18-12a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 72,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -69 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:61:53:00",
      "apName": "csg-bgl18-41a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 68,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 9% (Good)",
            " -[Channel Interference] Interference RSSI is -58 and Duty Cycle is 12% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 68
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 68,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:10:CD:80",
      "apName": "csg-bgl18-02a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 97,
      "frequencyBand": 1,
      "healthScore": 97,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:7D:B9:A5:45:E0",
      "apName": "csg-bgl18-01a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "495db500-2b16-4f52-a6e7-50cf471dbac0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:84:C5:20",
      "apName": "csg-bgl18-41a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 82,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 8% (Good)",
            " -[Channel Interference] Interference RSSI is -68 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 82
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "47ef8e0e-0e0c-4447-a1d6-9d001c7c3db7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 82,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:51:C0",
      "apName": "csg-bgl18-72a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:E0",
      "apName": "csg-bgl18-11a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 15 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -80 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "00:A7:42:AC:C6:60",
      "apName": "csg-bgl18-51a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 14% (Good)",
            " -[Channel Interference] Interference RSSI is -73 and Duty Cycle is 23% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
      "noiseSameChannel": 100,
      "sameChannelInterference": 67,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:70:8B:B5:EA:A0",
      "apName": "csg-bgl18-52a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 69,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -73 and Duty Cycle is 22% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 69
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 69,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F8:B7:E2:C3:02:00",
      "apName": "csg-bgl18-11a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:84:CE:A0",
      "apName": "csg-bgl18-41a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 84,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -76 and Duty Cycle is 14% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 84
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 84,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F8:0B:CB:E8:A2:A0",
      "apName": "csg-bgl18-32a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 95,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 29% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 95
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "92860f29-0f78-4128-8e1b-711fab79e74b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 95,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:84:5C:40",
      "apName": "csg-bgl18-42a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -78 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 91
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 91,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:BE:E3:A0",
      "apName": "csg-bgl18-32a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "34c32ce0-e88a-4b90-a141-982cfb6c1ee1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:8D:60",
      "apName": "csg-bgl18-82a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "00:5D:73:B6:96:C0",
      "apName": "csg-bgl18-12a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:13:D0:C0",
      "apName": "csg-bgl18-01a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 88,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 88
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "133f0dc1-0b0d-4c04-9ba5-ef26d0c104b1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 94,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "68:7D:B4:5F:E5:10",
      "apName": "csg-bgl18-61a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 63,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -43 and Duty Cycle is 11% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 63
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 63,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:2C:79:C0",
      "apName": "csg-bgl18-02a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:2C:64:40",
      "apName": "csg-bgl18-02a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -90 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:BF:5D:80",
      "apName": "csg-bgl18-31a-cap20",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -87 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 99
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4b47cf0-cb10-4280-92bf-286347b7e469",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:85:71:20",
      "apName": "csg-bgl18-41a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:70:C0",
      "apName": "csg-bgl18-72a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 72,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 24 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:7D:B9:A6:EE:C0",
      "apName": "csg-bgl18-01a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 84,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 8% (Good)",
            " -[Channel Interference] Interference RSSI is -77 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 84
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 91,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:76:C7:C0",
      "apName": "csg-bgl18-72a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 21 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 21 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:07:A5:60",
      "apName": "csg-bgl18-52a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:BE:EC:E0",
      "apName": "csg-bgl18-31a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 3 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 3 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -92 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:61:57:C0",
      "apName": "csg-bgl18-42a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -96 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:8E:80",
      "apName": "csg-bgl18-82a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:20:9C:60",
      "apName": "csg-bgl18-52a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -72 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:8F:C0",
      "apName": "csg-bgl18-82a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -71 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:90:E0",
      "apName": "csg-bgl18-81a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "00:5D:73:AE:59:A0",
      "apName": "csg-bgl18-12a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 72,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -70 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "68:7D:B4:5F:4B:E0",
      "apName": "csg-bgl18-62a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 98,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F4:DB:E6:44:8D:C0",
      "apName": "csg-bgl18-42a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 85,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 15 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -64 and Duty Cycle is 7% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 85
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
      "noiseSameChannel": 100,
      "sameChannelInterference": 85,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:DB:98:07:AA:A0",
      "apName": "csg-bgl18-32a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -80 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
      "noiseSameChannel": 100,
      "sameChannelInterference": 92,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:70:8B:B5:E0:80",
      "apName": "csg-bgl18-32a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -83 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 99
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "B4:DE:31:9D:33:60",
      "apName": "csg-bgl18-51a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 3 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 3 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 6% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:6F:C0",
      "apName": "csg-bgl18-72a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 23 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:B5:00",
      "apName": "csg-bgl18-82a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 9 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:A5:A0",
      "apName": "csg-bgl18-71a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 76,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 12 out of 40 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 12 out of 40 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 12% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 76
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
      "noiseSameChannel": 100,
      "sameChannelInterference": 98,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "F8:0B:CB:E8:A1:E0",
      "apName": "csg-bgl18-32a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -97 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:AA:00",
      "apName": "csg-bgl18-71a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 97,
      "frequencyBand": 1,
      "healthScore": 70,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 10 out of 28 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 10 out of 28 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 15% (Good)",
            " -[Channel Interference] Interference RSSI is -92 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 70
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -67 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:83:80",
      "apName": "csg-bgl18-81a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -85. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:AA:80",
      "apName": "csg-bgl18-81a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "D4:E8:80:19:9E:80",
      "apName": "csg-bgl18-82a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "00:5D:73:72:28:A0",
      "apName": "csg-bgl18-12a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "68:7D:B4:5F:EA:20",
      "apName": "csg-bgl18-61a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -79 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    }
    ],
   "getRrmHealthTrendDetail01_24ghz_Template": [
    {
      "apMac": "70:EA:1A:C7:03:80",
      "apName": "csg-bgl18-11a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:A5:A0",
      "apName": "csg-bgl18-71a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 76,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 25 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 12% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 76
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
      "noiseSameChannel": 100,
      "sameChannelInterference": 97,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:84:C1:E0",
      "apName": "csg-bgl18-42a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:8F:C0",
      "apName": "csg-bgl18-82a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -65 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 92,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:BF:5D:80",
      "apName": "csg-bgl18-31a-cap20",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 7% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4b47cf0-cb10-4280-92bf-286347b7e469",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "68:7D:B4:5F:E5:10",
      "apName": "csg-bgl18-61a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -91 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:13:D0:C0",
      "apName": "csg-bgl18-01a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "133f0dc1-0b0d-4c04-9ba5-ef26d0c104b1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:72:00",
      "apName": "csg-bgl18-72a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 31,
      "frequencyBand": 1,
      "healthScore": 31,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 23 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 31
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -77 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 90
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:90:E0",
      "apName": "csg-bgl18-81a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -85. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:9E:80",
      "apName": "csg-bgl18-82a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 9 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:3F:60",
      "apName": "csg-bgl18-71a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 97,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:2C:71:A0",
      "apName": "csg-bgl18-51a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 20,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -47 and Duty Cycle is 24% on the current Channel (Poor)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 20
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f093a28e-db79-4f30-a192-fd9d569be79c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 20,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:7D:B9:A6:EE:C0",
      "apName": "csg-bgl18-01a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 15% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 98,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:61:57:C0",
      "apName": "csg-bgl18-42a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:84:C5:20",
      "apName": "csg-bgl18-41a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 84,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 8% (Good)",
            " -[Channel Interference] Interference RSSI is -72 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 84
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "47ef8e0e-0e0c-4447-a1d6-9d001c7c3db7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 85,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:07:A5:60",
      "apName": "csg-bgl18-52a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 58,
      "frequencyBand": 1,
      "healthScore": 58,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 9 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 58
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -66 and Duty Cycle is 13% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 74
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 74,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:70:8B:B5:EA:A0",
      "apName": "csg-bgl18-52a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 4 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 4 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 13% (Good)",
            " -[Channel Interference] Interference RSSI is -71 and Duty Cycle is 21% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 67,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:23:80",
      "apName": "csg-bgl18-71a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:56:C0",
      "apName": "csg-bgl18-72a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 17,
      "frequencyBand": 1,
      "healthScore": 17,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 9 out of 23 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 9 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 17
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:73:00",
      "apName": "csg-bgl18-72a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 42,
      "frequencyBand": 1,
      "healthScore": 42,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 24 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 42
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 67,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:61:B9:00",
      "apName": "csg-bgl18-41a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -79 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "539afb61-e2fe-409b-a5d9-b5464073c81f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 98,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:E0",
      "apName": "csg-bgl18-11a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:1A:C0",
      "apName": "csg-bgl18-71a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 64,
      "frequencyBand": 1,
      "healthScore": 64,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 64
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:A7:C0",
      "apName": "csg-bgl18-71a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 23 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:72:C0",
      "apName": "csg-bgl18-72a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 24 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:26:E0",
      "apName": "csg-bgl18-71a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 21 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 21 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:72:2E:20",
      "apName": "csg-bgl18-12a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 13 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:AE:54:C0",
      "apName": "csg-bgl18-12a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:5B:A0",
      "apName": "csg-bgl18-72a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 8,
      "frequencyBand": 1,
      "healthScore": 8,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 8
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FF:A0",
      "apName": "csg-bgl18-11a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 28,
      "frequencyBand": 1,
      "healthScore": 28,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 28
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F8:B7:E2:C3:06:40",
      "apName": "csg-bgl18-12a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 19 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 19 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:8E:C0",
      "apName": "csg-bgl18-71a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 78,
      "frequencyBand": 1,
      "healthScore": 14,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 26 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 26 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 78
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 43% (Poor)",
            " -[Channel Interference] Interference RSSI is -87 and Duty Cycle is 24% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 14
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
      "noiseSameChannel": 100,
      "sameChannelInterference": 94,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C7:04:E0",
      "apName": "csg-bgl18-11a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 17 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "ec50026a-6daf-4e03-9150-06ef37c43c49",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:C4:BA:A0",
      "apName": "csg-bgl18-31a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 0,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 0,
      "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
      "noiseSameChannel": 0,
      "sameChannelInterference": 0,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FD:E0",
      "apName": "csg-bgl18-11a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 18 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 18 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:AA:80",
      "apName": "csg-bgl18-81a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:76:C7:C0",
      "apName": "csg-bgl18-72a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:6F:C0",
      "apName": "csg-bgl18-72a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 25 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "68:7D:B4:5F:EA:20",
      "apName": "csg-bgl18-61a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 19 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 19 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:AE:3F:40",
      "apName": "csg-bgl18-12a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 78,
      "frequencyBand": 1,
      "healthScore": 78,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 78
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:70:C0",
      "apName": "csg-bgl18-72a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 72,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 24 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:64:80",
      "apName": "csg-bgl18-72a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 1,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 90
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 94,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:2C:64:40",
      "apName": "csg-bgl18-02a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -97 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:84:5C:40",
      "apName": "csg-bgl18-42a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 91,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -78 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 91
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 91,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:61:53:00",
      "apName": "csg-bgl18-41a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 65,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 9% (Good)",
            " -[Channel Interference] Interference RSSI is -58 and Duty Cycle is 13% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 65
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 65,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:AE:3F:C0",
      "apName": "csg-bgl18-12a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 12 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "68:7D:B4:5F:4B:E0",
      "apName": "csg-bgl18-62a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F8:B7:E2:C3:02:00",
      "apName": "csg-bgl18-11a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:8D:60",
      "apName": "csg-bgl18-82a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:AE:21:A0",
      "apName": "csg-bgl18-12a-cap34",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 10 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FA:40",
      "apName": "csg-bgl18-11a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 17,
      "frequencyBand": 1,
      "healthScore": 17,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 17
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:73:40",
      "apName": "csg-bgl18-72a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 21 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 21 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:24:80",
      "apName": "csg-bgl18-71a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 19,
      "frequencyBand": 1,
      "healthScore": 19,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 19
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:44:8D:C0",
      "apName": "csg-bgl18-42a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 82,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -66 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 82
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
      "noiseSameChannel": 100,
      "sameChannelInterference": 82,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:B6:96:C0",
      "apName": "csg-bgl18-12a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:2C:79:C0",
      "apName": "csg-bgl18-02a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:9D:33:60",
      "apName": "csg-bgl18-51a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 3 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 3 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:72:60",
      "apName": "csg-bgl18-71a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 47,
      "frequencyBand": 1,
      "healthScore": 47,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 47
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 10% (Good)",
            " -[Channel Interference] Interference RSSI is -74 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 80
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
      "noiseSameChannel": 100,
      "sameChannelInterference": 96,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:70:8B:B5:E0:80",
      "apName": "csg-bgl18-32a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 0,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 0,
      "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
      "noiseSameChannel": 0,
      "sameChannelInterference": 0,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:0E:68:00",
      "apName": "csg-bgl18-12a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 17,
      "frequencyBand": 1,
      "healthScore": 17,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 17
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 29% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 64
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "67272642-ee03-4e98-a2e6-04ce785ef82a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 64,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:5F:20",
      "apName": "csg-bgl18-72a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -76. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 87
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "noiseSameChannel": 87,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:BE:EC:E0",
      "apName": "csg-bgl18-31a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 2 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 2 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -90 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:84:CE:A0",
      "apName": "csg-bgl18-41a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 11% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:AE:59:A0",
      "apName": "csg-bgl18-12a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 69,
      "frequencyBand": 1,
      "healthScore": 69,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 69
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:19:20",
      "apName": "csg-bgl18-71a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "cdb8c59c-ec65-4811-a70b-a6a6d39f8f5a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FE:C0",
      "apName": "csg-bgl18-11a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 13 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:6D:20",
      "apName": "csg-bgl18-71a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 1,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 26 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 26 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 16% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 68
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "dd770860-991d-4458-809c-4630e6c18609",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:B5:00",
      "apName": "csg-bgl18-82a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:BE:E3:A0",
      "apName": "csg-bgl18-32a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "34c32ce0-e88a-4b90-a141-982cfb6c1ee1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FF:60",
      "apName": "csg-bgl18-11a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "b955254c-ed55-4229-a1af-10789663a572",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F8:0B:CB:E8:A2:A0",
      "apName": "csg-bgl18-32a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 95,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 34% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 95
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "92860f29-0f78-4128-8e1b-711fab79e74b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 95,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:4B:80",
      "apName": "csg-bgl18-72a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 42,
      "frequencyBand": 1,
      "healthScore": 42,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 10 out of 24 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 10 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 42
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:7D:B9:A5:45:E0",
      "apName": "csg-bgl18-01a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "495db500-2b16-4f52-a6e7-50cf471dbac0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:8E:80",
      "apName": "csg-bgl18-82a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:AA:00",
      "apName": "csg-bgl18-71a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 80,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 10% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 80
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:95:23:60",
      "apName": "csg-bgl18-71a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 24 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -82 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "D4:E8:80:19:83:80",
      "apName": "csg-bgl18-81a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "68:7D:B4:5F:6C:50",
      "apName": "csg-bgl18-62a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 1,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 15 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -80 and Duty Cycle is 16% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 87
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 87,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:10:CD:80",
      "apName": "csg-bgl18-02a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 9 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -90 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FC:00",
      "apName": "csg-bgl18-11a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:74:C0",
      "apName": "csg-bgl18-72a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 47,
      "frequencyBand": 1,
      "healthScore": 47,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 47
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:13:C9:C0",
      "apName": "csg-bgl18-02a-cap27",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 3 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 3 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "B4:DE:31:9D:3C:20",
      "apName": "csg-bgl18-51a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 70,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 13% (Good)",
            " -[Channel Interference] Interference RSSI is -72 and Duty Cycle is 20% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 70
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 70,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:07:AA:A0",
      "apName": "csg-bgl18-32a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -83 and Duty Cycle is 13% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
      "noiseSameChannel": 100,
      "sameChannelInterference": 92,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:AC:A0",
      "apName": "csg-bgl18-71a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 36,
      "frequencyBand": 1,
      "healthScore": 36,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 22 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 36
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 90
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:A7:42:AC:C6:60",
      "apName": "csg-bgl18-51a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 13% (Good)",
            " -[Channel Interference] Interference RSSI is -73 and Duty Cycle is 20% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
      "noiseSameChannel": 100,
      "sameChannelInterference": 72,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:74:E0",
      "apName": "csg-bgl18-71a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 14,
      "frequencyBand": 1,
      "healthScore": 14,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 24 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 14
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 27% (Fair)",
            " -[Channel Interference] Interference RSSI is -93 and Duty Cycle is 14% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 46
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:20:9D:00",
      "apName": "csg-bgl18-52a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 67,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -87 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "0C:D0:F8:94:97:60",
      "apName": "csg-bgl18-71a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 56,
      "frequencyBand": 1,
      "healthScore": 46,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 56
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 27% (Fair)",
            " -[Channel Interference] Interference RSSI is -80 and Duty Cycle is 18% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 46
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 85,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:51:C0",
      "apName": "csg-bgl18-72a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 18 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 18 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F8:B7:E2:C2:E4:C0",
      "apName": "csg-bgl18-11a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 9 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "49aba360-d4f9-431d-8213-3840fef4fe63",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:DB:98:20:9C:60",
      "apName": "csg-bgl18-52a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 7% (Good)",
            " -[Channel Interference] Interference RSSI is -71 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:69:5A:78:64:60",
      "apName": "csg-bgl18-72a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "70:EA:1A:C6:FB:E0",
      "apName": "csg-bgl18-11a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 15 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "00:5D:73:72:28:A0",
      "apName": "csg-bgl18-12a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:85:71:20",
      "apName": "csg-bgl18-41a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F8:0B:CB:E8:A1:E0",
      "apName": "csg-bgl18-32a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -97 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    },
    {
      "apMac": "F4:DB:E6:61:9E:20",
      "apName": "csg-bgl18-41a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2ae4cf9d-abd7-4cbb-8f76-0f1c593f731b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:30:00",
      "timestampMs": "1648686600000"
    }
  ],
  "getCoChannelInterferenceLatestDetail01_24ghz_Template": [
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -100,
      "dutyCycle": 0,
      "frequencyBand": 1,
      "impactScore": 33,
      "impactScoreState": "Medium",
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:97:60",
      "apName": "csg-bgl18-71a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 6,
      "coChannelInterference": -78,
      "dutyCycle": 18,
      "frequencyBand": 1,
      "impactScore": 47,
      "impactScoreState": "Medium",
      "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:4B:80",
      "apName": "csg-bgl18-72a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -73,
      "dutyCycle": 2,
      "frequencyBand": 1,
      "impactScore": 56,
      "impactScoreState": "Medium",
      "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:95:1A:C0",
      "apName": "csg-bgl18-71a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 6,
      "coChannelInterference": -100,
      "dutyCycle": 0,
      "frequencyBand": 1,
      "impactScore": 42,
      "impactScoreState": "Medium",
      "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:64:60",
      "apName": "csg-bgl18-72a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -72,
      "dutyCycle": 2,
      "frequencyBand": 1,
      "impactScore": 44,
      "impactScoreState": "Medium",
      "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:64:80",
      "apName": "csg-bgl18-72a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 1,
      "coChannelInterference": -71,
      "dutyCycle": 2,
      "frequencyBand": 1,
      "impactScore": 50,
      "impactScoreState": "Medium",
      "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:6D:20",
      "apName": "csg-bgl18-71a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -84,
      "dutyCycle": 9,
      "frequencyBand": 1,
      "impactScore": 53,
      "impactScoreState": "Medium",
      "memberId": "dd770860-991d-4458-809c-4630e6c18609",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:74:C0",
      "apName": "csg-bgl18-72a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -90,
      "dutyCycle": 0,
      "frequencyBand": 1,
      "impactScore": 53,
      "impactScoreState": "Medium",
      "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:5F:20",
      "apName": "csg-bgl18-72a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 6,
      "coChannelInterference": -100,
      "dutyCycle": 0,
      "frequencyBand": 1,
      "impactScore": 44,
      "impactScoreState": "Medium",
      "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 1,
      "coChannelInterference": -33,
      "dutyCycle": 1,
      "frequencyBand": 1,
      "impactScore": 47,
      "impactScoreState": "Medium",
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 1,
      "coChannelInterference": -84,
      "dutyCycle": 0,
      "frequencyBand": 1,
      "impactScore": 58,
      "impactScoreState": "Medium",
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    },
    {
      "apMac": "0C:D0:F8:94:72:60",
      "apName": "csg-bgl18-71a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 11,
      "coChannelInterference": -70,
      "dutyCycle": 8,
      "frequencyBand": 1,
      "impactScore": 58,
      "impactScoreState": "Medium",
      "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    }
  ],
   "getCoChannelInterferenceTrendDetail01_24ghz_Template": [
    {
      "apMac": "D4:E8:80:19:83:80",
      "apName": "csg-bgl18-81a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:61:B9:00",
      "apName": "csg-bgl18-41a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "539afb61-e2fe-409b-a5d9-b5464073c81f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 96,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:84:C5:20",
      "apName": "csg-bgl18-41a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 7% (Good)",
            " -[Channel Interference] Interference RSSI is -71 and Duty Cycle is 9% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "47ef8e0e-0e0c-4447-a1d6-9d001c7c3db7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:61:57:C0",
      "apName": "csg-bgl18-42a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 6 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 6 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:72:2E:20",
      "apName": "csg-bgl18-12a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 13 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:73:00",
      "apName": "csg-bgl18-72a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 42,
      "frequencyBand": 1,
      "healthScore": 42,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 23 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 42
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 16 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -77. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 90
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "noiseSameChannel": 90,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:20:9C:60",
      "apName": "csg-bgl18-52a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 88,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 4 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 4 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -70 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 88
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 92,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:90:E0",
      "apName": "csg-bgl18-81a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 97,
      "frequencyBand": 1,
      "healthScore": 97,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:24:80",
      "apName": "csg-bgl18-71a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 19,
      "frequencyBand": 1,
      "healthScore": 19,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 19
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 21 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 21 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FC:00",
      "apName": "csg-bgl18-11a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -52 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 87
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
      "noiseSameChannel": 100,
      "sameChannelInterference": 87,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "68:7D:B4:5F:4B:E0",
      "apName": "csg-bgl18-62a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 4% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:56:C0",
      "apName": "csg-bgl18-72a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 17,
      "frequencyBand": 1,
      "healthScore": 17,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 9 out of 23 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 9 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 17
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:00",
      "apName": "csg-bgl18-82a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:70:8B:B5:E0:80",
      "apName": "csg-bgl18-32a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -82 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "68:7D:B4:5F:EA:20",
      "apName": "csg-bgl18-61a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -93 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:9D:3C:20",
      "apName": "csg-bgl18-51a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 14% (Good)",
            " -[Channel Interference] Interference RSSI is -74 and Duty Cycle is 21% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 72,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:70:8B:B5:EA:A0",
      "apName": "csg-bgl18-52a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 4 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 4 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 12% (Good)",
            " -[Channel Interference] Interference RSSI is -72 and Duty Cycle is 22% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 67,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:97:60",
      "apName": "csg-bgl18-71a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 56,
      "frequencyBand": 1,
      "healthScore": 56,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 56
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 22% (Fair)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 19% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 56
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:A7:42:AC:C6:60",
      "apName": "csg-bgl18-51a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 14% (Good)",
            " -[Channel Interference] Interference RSSI is -73 and Duty Cycle is 19% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
      "noiseSameChannel": 100,
      "sameChannelInterference": 73,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:73:40",
      "apName": "csg-bgl18-72a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -83 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:72:00",
      "apName": "csg-bgl18-72a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 31,
      "frequencyBand": 1,
      "healthScore": 31,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 23 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 31
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:AA:80",
      "apName": "csg-bgl18-81a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 8 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 8 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:74:C0",
      "apName": "csg-bgl18-72a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 47,
      "frequencyBand": 1,
      "healthScore": 47,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 47
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F8:0B:CB:E8:A1:E0",
      "apName": "csg-bgl18-32a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F8:0B:CB:E8:A2:A0",
      "apName": "csg-bgl18-32a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 25% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "92860f29-0f78-4128-8e1b-711fab79e74b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 96,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "68:7D:B4:5F:6C:50",
      "apName": "csg-bgl18-62a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 15 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -83 and Duty Cycle is 22% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 87
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 87,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:1A:C0",
      "apName": "csg-bgl18-71a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 64,
      "frequencyBand": 1,
      "healthScore": 64,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 64
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:72:28:A0",
      "apName": "csg-bgl18-12a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:13:C9:C0",
      "apName": "csg-bgl18-02a-cap27",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 3 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 3 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -74 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:74:E0",
      "apName": "csg-bgl18-71a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 14,
      "frequencyBand": 1,
      "healthScore": 14,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 24 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 14
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 26% (Fair)",
            " -[Channel Interference] Interference RSSI is -93 and Duty Cycle is 19% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 48
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FB:E0",
      "apName": "csg-bgl18-11a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 15 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:76:C7:C0",
      "apName": "csg-bgl18-72a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:BE:EC:E0",
      "apName": "csg-bgl18-31a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 2 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 2 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:BE:E3:A0",
      "apName": "csg-bgl18-32a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "34c32ce0-e88a-4b90-a141-982cfb6c1ee1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:AA:00",
      "apName": "csg-bgl18-71a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 80,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 10% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 80
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:64:60",
      "apName": "csg-bgl18-72a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:6F:C0",
      "apName": "csg-bgl18-72a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 86,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 24 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:6D:20",
      "apName": "csg-bgl18-71a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 53,
      "frequencyBand": 1,
      "healthScore": 53,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 26 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 26 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 53
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 17% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 66
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "dd770860-991d-4458-809c-4630e6c18609",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FF:A0",
      "apName": "csg-bgl18-11a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 28,
      "frequencyBand": 1,
      "healthScore": 28,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 28
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F8:B7:E2:C2:E4:C0",
      "apName": "csg-bgl18-11a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 9 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "49aba360-d4f9-431d-8213-3840fef4fe63",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 64,
      "frequencyBand": 1,
      "healthScore": 64,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 22 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 64
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:07:AA:A0",
      "apName": "csg-bgl18-32a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -79 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 93
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
      "noiseSameChannel": 100,
      "sameChannelInterference": 93,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:72:60",
      "apName": "csg-bgl18-71a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 47,
      "frequencyBand": 1,
      "healthScore": 47,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 47
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 11% (Good)",
            " -[Channel Interference] Interference RSSI is -70 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 78
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
      "noiseSameChannel": 100,
      "sameChannelInterference": 97,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:85:71:20",
      "apName": "csg-bgl18-41a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:AE:3F:C0",
      "apName": "csg-bgl18-12a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 12 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:0E:68:00",
      "apName": "csg-bgl18-12a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 17,
      "frequencyBand": 1,
      "healthScore": 17,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 17
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 41% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 62
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "67272642-ee03-4e98-a2e6-04ce785ef82a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 62,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:07:A5:60",
      "apName": "csg-bgl18-52a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 61,
      "frequencyBand": 1,
      "healthScore": 61,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 9 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 61
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -72 and Duty Cycle is 11% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 84
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 84,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FE:C0",
      "apName": "csg-bgl18-11a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 13 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:AE:21:A0",
      "apName": "csg-bgl18-12a-cap34",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 10 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:AE:3F:40",
      "apName": "csg-bgl18-12a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:61:53:00",
      "apName": "csg-bgl18-41a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 65,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 9% (Good)",
            " -[Channel Interference] Interference RSSI is -58 and Duty Cycle is 13% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 65
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
      "noiseSameChannel": 100,
      "sameChannelInterference": 65,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:7D:B9:A6:EE:C0",
      "apName": "csg-bgl18-01a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 97,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 17% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 97,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:5F:20",
      "apName": "csg-bgl18-72a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 1,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:9D:33:60",
      "apName": "csg-bgl18-51a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 3 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 3 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -90 and Duty Cycle is 11% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:4B:80",
      "apName": "csg-bgl18-72a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 42,
      "frequencyBand": 1,
      "healthScore": 42,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 10 out of 24 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 10 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 42
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:72:C0",
      "apName": "csg-bgl18-72a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 24 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -86 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:10:CD:80",
      "apName": "csg-bgl18-02a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 99,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -85 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 99
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:AC:A0",
      "apName": "csg-bgl18-71a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 36,
      "frequencyBand": 1,
      "healthScore": 36,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 36
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -70 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:2C:64:40",
      "apName": "csg-bgl18-02a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 7 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 7 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -92 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F8:B7:E2:C3:02:00",
      "apName": "csg-bgl18-11a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 10 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 10 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:8D:60",
      "apName": "csg-bgl18-82a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:8F:C0",
      "apName": "csg-bgl18-82a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 75,
      "frequencyBand": 1,
      "healthScore": 75,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 75
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -68 and Duty Cycle is 2% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
      "noiseSameChannel": 100,
      "sameChannelInterference": 96,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:23:60",
      "apName": "csg-bgl18-71a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 23 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 23 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:5B:A0",
      "apName": "csg-bgl18-72a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 8,
      "frequencyBand": 1,
      "healthScore": 8,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 25 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 8
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -87. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:E0",
      "apName": "csg-bgl18-11a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 89,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:7D:B9:A5:45:E0",
      "apName": "csg-bgl18-01a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "495db500-2b16-4f52-a6e7-50cf471dbac0",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:2C:71:A0",
      "apName": "csg-bgl18-51a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 43,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -50 and Duty Cycle is 17% on the current Channel (Fair)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 43
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "f093a28e-db79-4f30-a192-fd9d569be79c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 43,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:19:20",
      "apName": "csg-bgl18-71a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "cdb8c59c-ec65-4811-a70b-a6a6d39f8f5a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:64:80",
      "apName": "csg-bgl18-72a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 1,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 21 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 21 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FD:E0",
      "apName": "csg-bgl18-11a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 17 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:3F:60",
      "apName": "csg-bgl18-71a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 97,
      "frequencyBand": 1,
      "healthScore": 96,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 17 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 97
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -60 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:BF:5D:80",
      "apName": "csg-bgl18-31a-cap20",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 5 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 5 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "d4b47cf0-cb10-4280-92bf-286347b7e469",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:8E:80",
      "apName": "csg-bgl18-82a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 94,
      "frequencyBand": 1,
      "healthScore": 94,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 13 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 13 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "68:7D:B4:5F:E5:10",
      "apName": "csg-bgl18-61a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -94 and Duty Cycle is 1% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:B6:96:C0",
      "apName": "csg-bgl18-12a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 81,
      "frequencyBand": 1,
      "healthScore": 81,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 14 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 81
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:84:5C:40",
      "apName": "csg-bgl18-42a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -78 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 92,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:84:C1:E0",
      "apName": "csg-bgl18-42a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -93 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:A5:A0",
      "apName": "csg-bgl18-71a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 80,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 25 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 25 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 10% (Good)",
            " -[Channel Interference] Interference RSSI is -84 and Duty Cycle is 3% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 80
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
      "noiseSameChannel": 100,
      "sameChannelInterference": 98,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:13:D0:C0",
      "apName": "csg-bgl18-01a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 8 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 8 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -95. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "133f0dc1-0b0d-4c04-9ba5-ef26d0c104b1",
      "noiseSameChannel": 100,
      "sameChannelInterference": 99,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FA:40",
      "apName": "csg-bgl18-11a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 22,
      "frequencyBand": 1,
      "healthScore": 22,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 15 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 22
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:AE:59:A0",
      "apName": "csg-bgl18-12a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 78,
      "frequencyBand": 1,
      "healthScore": 78,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 7 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 7 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 78
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -91. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:70:C0",
      "apName": "csg-bgl18-72a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 72,
      "frequencyBand": 1,
      "healthScore": 72,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 24 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 24 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 72
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:A7:C0",
      "apName": "csg-bgl18-71a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 33,
      "frequencyBand": 1,
      "healthScore": 33,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 22 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 22 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 33
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:20:9D:00",
      "apName": "csg-bgl18-52a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 67,
      "frequencyBand": 1,
      "healthScore": 67,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 67
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -82 and Duty Cycle is 8% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 95
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
      "noiseSameChannel": 100,
      "sameChannelInterference": 95,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:26:E0",
      "apName": "csg-bgl18-71a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -80 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "B4:DE:31:C4:BA:A0",
      "apName": "csg-bgl18-31a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 92,
      "frequencyBand": 1,
      "healthScore": 92,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 5 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 5 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -96. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "00:5D:73:AE:54:C0",
      "apName": "csg-bgl18-12a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -92. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:61:9E:20",
      "apName": "csg-bgl18-41a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -90 and Duty Cycle is 5% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "2ae4cf9d-abd7-4cbb-8f76-0f1c593f731b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C6:FF:60",
      "apName": "csg-bgl18-11a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 14 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 14 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "b955254c-ed55-4229-a1af-10789663a572",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C7:03:80",
      "apName": "csg-bgl18-11a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 16 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 16 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -88. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:94:8E:C0",
      "apName": "csg-bgl18-71a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 78,
      "frequencyBand": 1,
      "healthScore": 14,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 8 out of 26 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 8 out of 26 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 78
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 43% (Poor)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 28% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 14
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -100. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
      "noiseSameChannel": 100,
      "sameChannelInterference": 95,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "0C:D0:F8:95:23:80",
      "apName": "csg-bgl18-71a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 7 out of 20 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 7 out of 20 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 4% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 92
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -97. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:44:8D:C0",
      "apName": "csg-bgl18-42a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 89,
      "frequencyBand": 1,
      "healthScore": 79,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 3 out of 11 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 3 out of 11 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 89
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 5% (Good)",
            " -[Channel Interference] Interference RSSI is -65 and Duty Cycle is 10% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 79
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
      "noiseSameChannel": 100,
      "sameChannelInterference": 79,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:EA:1A:C7:04:E0",
      "apName": "csg-bgl18-11a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 4 out of 17 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 4 out of 17 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 2% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 96
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -89. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "ec50026a-6daf-4e03-9150-06ef37c43c49",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "D4:E8:80:19:9E:80",
      "apName": "csg-bgl18-82a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 9 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 9 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -86. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:69:5A:78:51:C0",
      "apName": "csg-bgl18-72a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 83,
      "frequencyBand": 1,
      "healthScore": 83,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 5 out of 18 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 5 out of 18 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 83
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -89 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "70:DB:98:2C:79:C0",
      "apName": "csg-bgl18-02a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 95,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 12 neighbors are on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 12 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -88 and Duty Cycle is 30% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 95
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -93. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
      "noiseSameChannel": 100,
      "sameChannelInterference": 95,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F8:B7:E2:C3:06:40",
      "apName": "csg-bgl18-12a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 0,
      "frequencyBand": 1,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 6 out of 19 neighbors are on Co Channel (Poor)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 6 out of 19 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 3% (Good)",
            " -[Channel Interference] Interference RSSI is -81 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 94
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    },
    {
      "apMac": "F4:DB:E6:84:CE:A0",
      "apName": "csg-bgl18-41a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 1,
      "healthScore": 86,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 6 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 6 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 6% (Good)",
            " -[Channel Interference] Interference RSSI is -75 and Duty Cycle is 11% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 86
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -94. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 0 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
      "noiseSameChannel": 100,
      "sameChannelInterference": 86,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    }
  ],
  "getApSpatialDensityLatestDetail01_24ghz_Template": [
    {
      "apMac": "70:69:5A:78:73:40",
      "apName": "csg-bgl18-72a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 10,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -52,
      "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
      "nbrCoChannel": 42,
      "onChannelNeighbourCount": 6,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:64:60",
          "neighbourName": "csg-bgl18-72a-cap28",
          "neighbourRssi": -81
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "70:69:5A:78:56:C0",
          "neighbourName": "csg-bgl18-72a-cap33",
          "neighbourRssi": -81
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -73
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -36
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -57
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -53
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "00:5D:73:AE:3F:C0",
      "apName": "csg-bgl18-12a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 11,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -44,
      "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "00:5D:73:72:2E:20",
          "neighbourName": "csg-bgl18-12a-cap39",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -70
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "00:5D:73:AE:59:A0",
          "neighbourName": "csg-bgl18-12a-cap38",
          "neighbourRssi": -23
        },
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "00:5D:73:72:2E:20",
          "neighbourName": "csg-bgl18-12a-cap39",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "00:5D:73:72:28:A0",
          "neighbourName": "csg-bgl18-12a-cap37",
          "neighbourRssi": -38
        },
        {
          "neighbourMac": "00:5D:73:B6:96:C0",
          "neighbourName": "csg-bgl18-12a-cap41",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -36
        },
        {
          "neighbourMac": "00:5D:73:72:2D:00",
          "neighbourName": "csg-bgl18-12a-cap35",
          "neighbourRssi": -27
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -68
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 10,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -56,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "nbrCoChannel": 92,
      "onChannelNeighbourCount": 3,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -60
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -25
        },
        {
          "neighbourMac": "F8:B7:E2:C3:02:00",
          "neighbourName": "csg-bgl18-11a-cap02",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -60
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "0C:D0:F8:95:3F:60",
      "apName": "csg-bgl18-71a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -60,
      "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
      "nbrCoChannel": 94,
      "onChannelNeighbourCount": 2,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -77
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -60
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -37
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -59
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "0C:D0:F8:95:23:60",
      "apName": "csg-bgl18-71a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 17,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -43,
      "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
      "nbrCoChannel": 25,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "0C:D0:F8:94:AA:00",
          "neighbourName": "csg-bgl18-71a-cap05",
          "neighbourRssi": -76
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -70
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -34
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -63
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:69:5A:78:5B:A0",
      "apName": "csg-bgl18-72a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 11,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -43,
      "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
      "nbrCoChannel": 14,
      "onChannelNeighbourCount": 3,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -68
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:69:5A:78:51:C0",
          "neighbourName": "csg-bgl18-72a-cap25",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "70:69:5A:76:C7:C0",
          "neighbourName": "csg-bgl18-72a-cap29",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -46
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -65
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -42
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "0C:D0:F8:94:AC:A0",
      "apName": "csg-bgl18-71a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 16,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -44,
      "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
      "nbrCoChannel": 36,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -72
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -74
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "0C:D0:F8:94:A5:A0",
          "neighbourName": "csg-bgl18-71a-cap01",
          "neighbourRssi": -41
        },
        {
          "neighbourMac": "0C:D0:F8:94:AA:00",
          "neighbourName": "csg-bgl18-71a-cap05",
          "neighbourRssi": -30
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "0C:D0:F8:94:6D:20",
          "neighbourName": "csg-bgl18-71a-cap02",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -66
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "0C:D0:F8:94:A7:C0",
      "apName": "csg-bgl18-71a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 17,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": -52,
      "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
      "nbrCoChannel": 64,
      "onChannelNeighbourCount": 6,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -92
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -53
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "0C:D0:F8:94:AA:00",
          "neighbourName": "csg-bgl18-71a-cap05",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -39
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 18
    },
    {
      "apMac": "70:69:5A:78:72:C0",
      "apName": "csg-bgl18-72a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 17,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -43,
      "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:69:5A:78:64:80",
          "neighbourName": "csg-bgl18-72a-cap31",
          "neighbourRssi": -79
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -43
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 12,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -45,
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "nbrCoChannel": 47,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:64:80",
          "neighbourName": "csg-bgl18-72a-cap31",
          "neighbourRssi": -83
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -60
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -41
        },
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -28
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -59
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:EA:1A:C6:FF:60",
      "apName": "csg-bgl18-11a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 12,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -34,
      "memberId": "b955254c-ed55-4229-a1af-10789663a572",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -34
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -70
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -53
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -24
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -24
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:7D:B9:A6:EE:C0",
          "neighbourName": "csg-bgl18-01a-cap17",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -34
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "0C:D0:F8:95:26:E0",
      "apName": "csg-bgl18-71a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 18,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -37,
      "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -37
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -71
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -37
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -28
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -33
        },
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -26
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -45
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "0C:D0:F8:95:19:20",
      "apName": "csg-bgl18-71a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 15,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -35,
      "memberId": "cdb8c59c-ec65-4811-a70b-a6a6d39f8f5a",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -57
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -72
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -35
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -27
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -29
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -57
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -32
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -55
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "70:69:5A:78:73:00",
      "apName": "csg-bgl18-72a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 12,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -44,
      "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
      "nbrCoChannel": 36,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -77
        },
        {
          "neighbourMac": "70:69:5A:78:51:C0",
          "neighbourName": "csg-bgl18-72a-cap25",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:69:5A:78:64:80",
          "neighbourName": "csg-bgl18-72a-cap31",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -44
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:51:C0",
          "neighbourName": "csg-bgl18-72a-cap25",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:69:5A:76:C7:C0",
          "neighbourName": "csg-bgl18-72a-cap29",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -65
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:69:5A:78:64:80",
          "neighbourName": "csg-bgl18-72a-cap31",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -38
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -61
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 16
    },
    {
      "apMac": "0C:D0:F8:95:24:80",
      "apName": "csg-bgl18-71a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 15,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -42,
      "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
      "nbrCoChannel": 19,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "68:7D:B4:5F:E5:10",
          "neighbourName": "csg-bgl18-61a-cap14",
          "neighbourRssi": -75
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -53
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -37
        },
        {
          "neighbourMac": "0C:D0:F8:94:8E:C0",
          "neighbourName": "csg-bgl18-71a-cap24",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -37
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -51
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:69:5A:78:70:C0",
      "apName": "csg-bgl18-72a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 14,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -56,
      "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
      "nbrCoChannel": 75,
      "onChannelNeighbourCount": 2,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -56
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 11,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "70:69:5A:78:5B:C0",
          "neighbourName": "csg-bgl18-72a-cap39",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -57
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -28
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -28
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 11
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -60,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "nbrCoChannel": 89,
      "onChannelNeighbourCount": 3,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:76:C7:C0",
          "neighbourName": "csg-bgl18-72a-cap29",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -62
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 12,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:69:5A:78:70:C0",
          "neighbourName": "csg-bgl18-72a-cap44",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:78:73:40",
          "neighbourName": "csg-bgl18-72a-cap45",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "70:69:5A:78:5B:A0",
          "neighbourName": "csg-bgl18-72a-cap38",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:69:5A:78:74:C0",
          "neighbourName": "csg-bgl18-72a-cap30",
          "neighbourRssi": -46
        },
        {
          "neighbourMac": "70:69:5A:78:72:C0",
          "neighbourName": "csg-bgl18-72a-cap42",
          "neighbourRssi": -41
        },
        {
          "neighbourMac": "70:69:5A:78:4B:80",
          "neighbourName": "csg-bgl18-72a-cap47",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:69:5A:78:4E:A0",
          "neighbourName": "csg-bgl18-72a-cap43",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "70:69:5A:78:5F:20",
          "neighbourName": "csg-bgl18-72a-cap49",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "70:69:5A:78:73:00",
          "neighbourName": "csg-bgl18-72a-cap36",
          "neighbourRssi": -38
        },
        {
          "neighbourMac": "70:69:5A:78:4F:A0",
          "neighbourName": "csg-bgl18-72a-cap50",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "70:69:5A:76:C7:C0",
          "neighbourName": "csg-bgl18-72a-cap29",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "70:69:5A:78:72:00",
          "neighbourName": "csg-bgl18-72a-cap48",
          "neighbourRssi": -69
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "00:5D:73:72:2E:20",
      "apName": "csg-bgl18-12a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -43,
      "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -72
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:C0",
          "neighbourName": "csg-bgl18-12a-cap36",
          "neighbourRssi": -44
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 12,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:C0",
          "neighbourName": "csg-bgl18-12a-cap36",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "00:5D:73:72:2D:00",
          "neighbourName": "csg-bgl18-12a-cap35",
          "neighbourRssi": -28
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "00:5D:73:B6:96:C0",
          "neighbourName": "csg-bgl18-12a-cap41",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "00:5D:73:72:28:A0",
          "neighbourName": "csg-bgl18-12a-cap37",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "00:5D:73:AE:59:A0",
          "neighbourName": "csg-bgl18-12a-cap38",
          "neighbourRssi": -34
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "70:EA:1A:C6:FA:40",
      "apName": "csg-bgl18-11a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 11
        }
      ],
      "maxOnChannelNeighbourRssi": -42,
      "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
      "nbrCoChannel": 14,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -72
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -70
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -42
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 12,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -22
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -65
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -27
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -57
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "70:EA:1A:C6:FE:C0",
      "apName": "csg-bgl18-11a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 12
        }
      ],
      "maxOnChannelNeighbourRssi": -34,
      "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -34
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -49
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 12,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -34
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -40
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "70:7D:B9:A6:EE:C0",
          "neighbourName": "csg-bgl18-01a-cap17",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -60
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -26
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -26
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -35
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "00:5D:73:AE:3F:40",
      "apName": "csg-bgl18-12a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 14,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -52,
      "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
      "nbrCoChannel": 72,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -71
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -70
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "00:5D:73:AE:59:A0",
          "neighbourName": "csg-bgl18-12a-cap38",
          "neighbourRssi": -52
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 13,
      "strongNeighbourList": [
        {
          "neighbourMac": "00:5D:73:B6:96:C0",
          "neighbourName": "csg-bgl18-12a-cap41",
          "neighbourRssi": -19
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -57
        },
        {
          "neighbourMac": "00:5D:73:72:2D:00",
          "neighbourName": "csg-bgl18-12a-cap35",
          "neighbourRssi": -38
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:C0",
          "neighbourName": "csg-bgl18-12a-cap36",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "F8:B7:E2:C3:02:00",
          "neighbourName": "csg-bgl18-11a-cap02",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "00:5D:73:AE:59:A0",
          "neighbourName": "csg-bgl18-12a-cap38",
          "neighbourRssi": -52
        },
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -56
        },
        {
          "neighbourMac": "00:5D:73:72:2E:20",
          "neighbourName": "csg-bgl18-12a-cap39",
          "neighbourRssi": -41
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -29
        },
        {
          "neighbourMac": "00:5D:73:72:28:A0",
          "neighbourName": "csg-bgl18-12a-cap37",
          "neighbourRssi": -64
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "00:5D:73:B6:96:C0",
      "apName": "csg-bgl18-12a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 13,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -40,
      "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
      "nbrCoChannel": 81,
      "onChannelNeighbourCount": 5,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "F8:B7:E2:C3:02:00",
          "neighbourName": "csg-bgl18-11a-cap02",
          "neighbourRssi": -73
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "00:5D:73:72:28:A0",
          "neighbourName": "csg-bgl18-12a-cap37",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "00:5D:73:72:2D:00",
          "neighbourName": "csg-bgl18-12a-cap35",
          "neighbourRssi": -40
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -65
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 13,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -65
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "00:5D:73:72:2E:20",
          "neighbourName": "csg-bgl18-12a-cap39",
          "neighbourRssi": -26
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -30
        },
        {
          "neighbourMac": "00:5D:73:72:28:A0",
          "neighbourName": "csg-bgl18-12a-cap37",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -25
        },
        {
          "neighbourMac": "00:5D:73:72:2D:00",
          "neighbourName": "csg-bgl18-12a-cap35",
          "neighbourRssi": -40
        },
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "00:5D:73:AE:59:A0",
          "neighbourName": "csg-bgl18-12a-cap38",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:C0",
          "neighbourName": "csg-bgl18-12a-cap36",
          "neighbourRssi": -52
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "70:EA:1A:C6:FF:A0",
      "apName": "csg-bgl18-11a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 14,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -33,
      "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
      "nbrCoChannel": 19,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "70:7D:B9:A6:EE:C0",
          "neighbourName": "csg-bgl18-01a-cap17",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -33
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 13,
      "strongNeighbourList": [
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -62
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -16
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -22
        },
        {
          "neighbourMac": "70:7D:B9:A6:EE:C0",
          "neighbourName": "csg-bgl18-01a-cap17",
          "neighbourRssi": -68
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -33
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -28
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -22
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "0C:D0:F8:95:1A:C0",
      "apName": "csg-bgl18-71a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 14,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -45,
      "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
      "nbrCoChannel": 61,
      "onChannelNeighbourCount": 6,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -71
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -73
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -45
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 13,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "0C:D0:F8:94:A5:A0",
          "neighbourName": "csg-bgl18-71a-cap01",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "0C:D0:F8:94:AA:00",
          "neighbourName": "csg-bgl18-71a-cap05",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:94:9B:E0",
          "neighbourName": "csg-bgl18-71a-cap08",
          "neighbourRssi": -42
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:95:19:20",
          "neighbourName": "csg-bgl18-71a-cap17",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "68:7D:B4:5F:E5:10",
          "neighbourName": "csg-bgl18-61a-cap14",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -40
        },
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -42
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "70:EA:1A:C6:FB:E0",
      "apName": "csg-bgl18-11a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 15,
          "minTotalNeighbourCount": 14
        }
      ],
      "maxOnChannelNeighbourRssi": -43,
      "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -43
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 14,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -43
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -30
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -54
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -34
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "00:5D:73:B6:96:C0",
          "neighbourName": "csg-bgl18-12a-cap41",
          "neighbourRssi": -65
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -50
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 18
    },
    {
      "apMac": "A0:F8:49:8B:C2:E0",
      "apName": "csg-bgl18-11a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 15,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -53,
      "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
      "nbrCoChannel": 81,
      "onChannelNeighbourCount": 4,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "00:5D:73:AE:21:A0",
          "neighbourName": "csg-bgl18-12a-cap34",
          "neighbourRssi": -70
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 14,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FD:E0",
          "neighbourName": "csg-bgl18-11a-cap18",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "F8:B7:E2:C3:02:00",
          "neighbourName": "csg-bgl18-11a-cap02",
          "neighbourRssi": -47
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "70:7D:B9:A5:45:E0",
          "neighbourName": "csg-bgl18-01a-cap07",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -23
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -61
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -45
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -34
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 16
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 17,
          "minTotalNeighbourCount": 14
        }
      ],
      "maxOnChannelNeighbourRssi": -50,
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "nbrCoChannel": 56,
      "onChannelNeighbourCount": 2,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "0C:D0:F8:94:A5:A0",
          "neighbourName": "csg-bgl18-71a-cap01",
          "neighbourRssi": -50
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 14,
      "strongNeighbourList": [
        {
          "neighbourMac": "0C:D0:F8:94:74:E0",
          "neighbourName": "csg-bgl18-71a-cap22",
          "neighbourRssi": -51
        },
        {
          "neighbourMac": "0C:D0:F8:94:97:60",
          "neighbourName": "csg-bgl18-71a-cap03",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "0C:D0:F8:94:A7:C0",
          "neighbourName": "csg-bgl18-71a-cap23",
          "neighbourRssi": -53
        },
        {
          "neighbourMac": "0C:D0:F8:94:72:60",
          "neighbourName": "csg-bgl18-71a-cap10",
          "neighbourRssi": -17
        },
        {
          "neighbourMac": "0C:D0:F8:95:26:E0",
          "neighbourName": "csg-bgl18-71a-cap18",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:80",
          "neighbourName": "csg-bgl18-71a-cap11",
          "neighbourRssi": -36
        },
        {
          "neighbourMac": "0C:D0:F8:94:AC:A0",
          "neighbourName": "csg-bgl18-71a-cap06",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "0C:D0:F8:95:24:80",
          "neighbourName": "csg-bgl18-71a-cap19",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "0C:D0:F8:94:6D:20",
          "neighbourName": "csg-bgl18-71a-cap02",
          "neighbourRssi": -35
        },
        {
          "neighbourMac": "0C:D0:F8:95:1A:C0",
          "neighbourName": "csg-bgl18-71a-cap14",
          "neighbourRssi": -40
        },
        {
          "neighbourMac": "0C:D0:F8:95:23:60",
          "neighbourName": "csg-bgl18-71a-cap15",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "0C:D0:F8:95:3F:60",
          "neighbourName": "csg-bgl18-71a-cap16",
          "neighbourRssi": -58
        },
        {
          "neighbourMac": "0C:D0:F8:94:AA:00",
          "neighbourName": "csg-bgl18-71a-cap05",
          "neighbourRssi": -18
        },
        {
          "neighbourMac": "0C:D0:F8:94:A5:A0",
          "neighbourName": "csg-bgl18-71a-cap01",
          "neighbourRssi": -50
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "70:EA:1A:C6:FD:E0",
      "apName": "csg-bgl18-11a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 1,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 15,
          "minTotalNeighbourCount": 13
        }
      ],
      "maxOnChannelNeighbourRssi": -36,
      "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
      "nbrCoChannel": 0,
      "onChannelNeighbourCount": 6,
      "onChannelNeighbourList": [
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "00:5D:73:AE:3F:40",
          "neighbourName": "csg-bgl18-12a-cap42",
          "neighbourRssi": -72
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -36
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:DB:98:13:D0:C0",
          "neighbourName": "csg-bgl18-01a-cap15",
          "neighbourRssi": -73
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "strongNeighbourCount": 14,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:EA:1A:C6:FF:60",
          "neighbourName": "csg-bgl18-11a-cap17",
          "neighbourRssi": -36
        },
        {
          "neighbourMac": "00:5D:73:AE:54:C0",
          "neighbourName": "csg-bgl18-12a-cap43",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "F8:B7:E2:C3:06:40",
          "neighbourName": "csg-bgl18-12a-cap45",
          "neighbourRssi": -39
        },
        {
          "neighbourMac": "F8:B7:E2:C2:E4:C0",
          "neighbourName": "csg-bgl18-11a-cap13",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:EA:1A:C6:FE:C0",
          "neighbourName": "csg-bgl18-11a-cap19",
          "neighbourRssi": -29
        },
        {
          "neighbourMac": "70:EA:1A:C7:04:E0",
          "neighbourName": "csg-bgl18-11a-cap15",
          "neighbourRssi": -30
        },
        {
          "neighbourMac": "70:EA:1A:C6:FF:A0",
          "neighbourName": "csg-bgl18-11a-cap16",
          "neighbourRssi": -26
        },
        {
          "neighbourMac": "70:EA:1A:C7:03:80",
          "neighbourName": "csg-bgl18-11a-cap22",
          "neighbourRssi": -49
        },
        {
          "neighbourMac": "70:EA:1A:C6:FC:00",
          "neighbourName": "csg-bgl18-11a-cap21",
          "neighbourRssi": -31
        },
        {
          "neighbourMac": "70:EA:1A:C6:FB:E0",
          "neighbourName": "csg-bgl18-11a-cap23",
          "neighbourRssi": -44
        },
        {
          "neighbourMac": "70:EA:1A:C6:FA:40",
          "neighbourName": "csg-bgl18-11a-cap24",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:A0",
          "neighbourName": "csg-bgl18-11a-cap10",
          "neighbourRssi": -64
        },
        {
          "neighbourMac": "B4:DE:31:0E:68:00",
          "neighbourName": "csg-bgl18-12a-cap44",
          "neighbourRssi": -67
        },
        {
          "neighbourMac": "A0:F8:49:8B:C2:E0",
          "neighbourName": "csg-bgl18-11a-cap06",
          "neighbourRssi": -62
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 19
    }
  ],
  "getApSpatialDensityTrendDetail01_24ghz_Template": [
        {
          "apMac": "F8:0B:CB:E8:A1:E0",
          "apName": "csg-bgl18-32a-cap42",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -73,
          "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
          "nbrCoChannel": 81,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:70:8B:B5:E0:80",
              "neighbourName": "csg-bgl18-32a-cap29",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "68:7D:B4:5F:4B:E0",
          "apName": "csg-bgl18-62a-cap44",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -78,
          "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:2C:71:A0",
              "neighbourName": "csg-bgl18-51a-cap02",
              "neighbourRssi": -94
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -93
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -92
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -80
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "B4:DE:31:9D:3C:20",
          "apName": "csg-bgl18-51a-cap21",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -83,
          "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:2C:71:A0",
              "neighbourName": "csg-bgl18-51a-cap02",
              "neighbourRssi": -83
            },
            {
              "neighbourMac": "B4:DE:31:9D:33:60",
              "neighbourName": "csg-bgl18-51a-cap17",
              "neighbourRssi": -83
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "68:7D:B4:5F:EA:20",
          "apName": "csg-bgl18-61a-cap23",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -81,
          "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:6C:50",
              "neighbourName": "csg-bgl18-62a-cap37",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "70:DB:98:20:9D:00",
              "neighbourName": "csg-bgl18-52a-cap47",
              "neighbourRssi": -92
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -83
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -86
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "68:7D:B4:5F:E5:10",
              "neighbourName": "csg-bgl18-61a-cap14",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 19
        },
        {
          "apMac": "B4:DE:31:BF:5D:80",
          "apName": "csg-bgl18-31a-cap20",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -77,
          "memberId": "d4b47cf0-cb10-4280-92bf-286347b7e469",
          "nbrCoChannel": 92,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "B4:DE:31:BE:EC:E0",
              "neighbourName": "csg-bgl18-31a-cap07",
              "neighbourRssi": -77
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "F4:DB:E6:61:57:C0",
          "apName": "csg-bgl18-42a-cap19",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -82,
          "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:5C:40",
              "neighbourName": "csg-bgl18-42a-cap32",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "70:70:8B:B5:E0:80",
              "neighbourName": "csg-bgl18-32a-cap29",
              "neighbourRssi": -95
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "B4:DE:31:9D:33:60",
          "apName": "csg-bgl18-51a-cap17",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -81,
          "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:9D:3C:20",
              "neighbourName": "csg-bgl18-51a-cap21",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 0,
          "strongNeighbourList": [
            {
              "neighbourMac": null,
              "neighbourName": null,
              "neighbourRssi": null
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 3
        },
        {
          "apMac": "00:A7:42:AC:C6:60",
          "apName": "csg-bgl18-51a-cap13",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -78,
          "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
          "nbrCoChannel": 94,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:C4:BA:A0",
              "neighbourName": "csg-bgl18-31a-cap13",
              "neighbourRssi": -78
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:9D:33:60",
              "neighbourName": "csg-bgl18-51a-cap17",
              "neighbourRssi": -69
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "D4:E8:80:19:B5:00",
          "apName": "csg-bgl18-82a-cap48",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -71,
          "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -83
            },
            {
              "neighbourMac": "D4:E8:80:19:83:80",
              "neighbourName": "csg-bgl18-81a-cap07",
              "neighbourRssi": -79
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:90:E0",
              "neighbourName": "csg-bgl18-81a-cap21",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 11
        },
        {
          "apMac": "70:7D:B9:A5:45:E0",
          "apName": "csg-bgl18-01a-cap07",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -82,
          "memberId": "495db500-2b16-4f52-a6e7-50cf471dbac0",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:13:C9:C0",
              "neighbourName": "csg-bgl18-02a-cap27",
              "neighbourRssi": -82
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:13:D0:C0",
              "neighbourName": "csg-bgl18-01a-cap15",
              "neighbourRssi": -69
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "70:DB:98:13:C9:C0",
          "apName": "csg-bgl18-02a-cap27",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -81,
          "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:7D:B9:A5:45:E0",
              "neighbourName": "csg-bgl18-01a-cap07",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:2C:64:40",
              "neighbourName": "csg-bgl18-02a-cap31",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 3
        },
        {
          "apMac": "D4:E8:80:19:83:80",
          "apName": "csg-bgl18-81a-cap07",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -79,
          "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
          "nbrCoChannel": 67,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:B5:00",
              "neighbourName": "csg-bgl18-82a-cap48",
              "neighbourRssi": -79
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:9E:80",
              "neighbourName": "csg-bgl18-82a-cap33",
              "neighbourRssi": -63
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "B4:DE:31:BE:EC:E0",
          "apName": "csg-bgl18-31a-cap07",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -76,
          "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:BF:5D:80",
              "neighbourName": "csg-bgl18-31a-cap20",
              "neighbourRssi": -76
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:C4:BA:A0",
              "neighbourName": "csg-bgl18-31a-cap13",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 2
        },
        {
          "apMac": "70:DB:98:2C:64:40",
          "apName": "csg-bgl18-02a-cap31",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -77,
          "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "70:DB:98:10:CD:80",
              "neighbourName": "csg-bgl18-02a-cap37",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:13:C9:C0",
              "neighbourName": "csg-bgl18-02a-cap27",
              "neighbourRssi": -59
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "F4:DB:E6:84:5C:40",
          "apName": "csg-bgl18-42a-cap32",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -81,
          "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "70:70:8B:B5:E0:80",
              "neighbourName": "csg-bgl18-32a-cap29",
              "neighbourRssi": -92
            },
            {
              "neighbourMac": "F4:DB:E6:61:57:C0",
              "neighbourName": "csg-bgl18-42a-cap19",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "68:7D:B4:5F:E5:10",
          "apName": "csg-bgl18-61a-cap14",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -79,
          "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "00:A7:42:AC:C6:60",
              "neighbourName": "csg-bgl18-51a-cap13",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "68:7D:B4:5F:EA:20",
              "neighbourName": "csg-bgl18-61a-cap23",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -80
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:DB:98:2C:71:A0",
          "apName": "csg-bgl18-51a-cap02",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -76,
          "memberId": "f093a28e-db79-4f30-a192-fd9d569be79c",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:4B:E0",
              "neighbourName": "csg-bgl18-62a-cap44",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "B4:DE:31:9D:3C:20",
              "neighbourName": "csg-bgl18-51a-cap21",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -88
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -78
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:70:8B:B5:EA:A0",
              "neighbourName": "csg-bgl18-52a-cap28",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "D4:E8:80:19:90:E0",
          "apName": "csg-bgl18-81a-cap21",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -77,
          "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "D4:E8:80:19:8F:C0",
              "neighbourName": "csg-bgl18-82a-cap43",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -77
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 1,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:B5:00",
              "neighbourName": "csg-bgl18-82a-cap48",
              "neighbourRssi": -69
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "70:DB:98:07:AA:A0",
          "apName": "csg-bgl18-32a-cap44",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -71,
          "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:44:8D:C0",
              "neighbourName": "csg-bgl18-42a-cap36",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A1:E0",
              "neighbourName": "csg-bgl18-32a-cap42",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -66
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "70:DB:98:10:CD:80",
          "apName": "csg-bgl18-02a-cap37",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -75,
          "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:DB:98:2C:64:40",
              "neighbourName": "csg-bgl18-02a-cap31",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -75
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -66
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "F4:DB:E6:84:C5:20",
          "apName": "csg-bgl18-41a-cap16",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": 0,
          "memberId": "47ef8e0e-0e0c-4447-a1d6-9d001c7c3db7",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 0,
          "onChannelNeighbourList": [],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:61:9E:20",
              "neighbourName": "csg-bgl18-41a-cap08",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "F4:DB:E6:61:53:00",
              "neighbourName": "csg-bgl18-41a-cap17",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "70:70:8B:B5:E0:80",
          "apName": "csg-bgl18-32a-cap29",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -75,
          "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
          "nbrCoChannel": 86,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A1:E0",
              "neighbourName": "csg-bgl18-32a-cap42",
              "neighbourRssi": -75
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "B4:DE:31:BE:E3:A0",
              "neighbourName": "csg-bgl18-32a-cap25",
              "neighbourRssi": -56
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "B4:DE:31:BE:E3:A0",
          "apName": "csg-bgl18-32a-cap25",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": 0,
          "memberId": "34c32ce0-e88a-4b90-a141-982cfb6c1ee1",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 0,
          "onChannelNeighbourList": [],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:61:57:C0",
              "neighbourName": "csg-bgl18-42a-cap19",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:70:8B:B5:E0:80",
              "neighbourName": "csg-bgl18-32a-cap29",
              "neighbourRssi": -49
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 3
        },
        {
          "apMac": "D4:E8:80:19:8F:C0",
          "apName": "csg-bgl18-82a-cap43",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -77,
          "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -77
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:8E:80",
              "neighbourName": "csg-bgl18-82a-cap38",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "D4:E8:80:19:B5:00",
              "neighbourName": "csg-bgl18-82a-cap48",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "70:70:8B:B5:EA:A0",
          "apName": "csg-bgl18-52a-cap28",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -76,
          "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
          "nbrCoChannel": 92,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -76
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:20:9C:60",
              "neighbourName": "csg-bgl18-52a-cap29",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:DB:98:2C:71:A0",
              "neighbourName": "csg-bgl18-51a-cap02",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "70:DB:98:20:9C:60",
          "apName": "csg-bgl18-52a-cap29",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -74,
          "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -74
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:70:8B:B5:EA:A0",
              "neighbourName": "csg-bgl18-52a-cap28",
              "neighbourRssi": -53
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "F4:DB:E6:61:53:00",
          "apName": "csg-bgl18-41a-cap17",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": 0,
          "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 0,
          "onChannelNeighbourList": [],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:C5:20",
              "neighbourName": "csg-bgl18-41a-cap16",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "F4:DB:E6:85:71:20",
              "neighbourName": "csg-bgl18-41a-cap18",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "D4:E8:80:19:AA:80",
          "apName": "csg-bgl18-81a-cap02",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -71,
          "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:83:80",
              "neighbourName": "csg-bgl18-81a-cap07",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "D4:E8:80:19:9E:80",
              "neighbourName": "csg-bgl18-82a-cap33",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:DB:98:20:9D:00",
          "apName": "csg-bgl18-52a-cap47",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -68,
          "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
          "nbrCoChannel": 67,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:EA:20",
              "neighbourName": "csg-bgl18-61a-cap23",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "F4:DB:E6:44:8D:C0",
              "neighbourName": "csg-bgl18-42a-cap36",
              "neighbourRssi": -80
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "68:7D:B4:5F:4B:E0",
              "neighbourName": "csg-bgl18-62a-cap44",
              "neighbourRssi": -69
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 11
        },
        {
          "apMac": "F4:DB:E6:44:8D:C0",
          "apName": "csg-bgl18-42a-cap36",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -78,
          "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:07:AA:A0",
              "neighbourName": "csg-bgl18-32a-cap44",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "F4:DB:E6:84:CE:A0",
              "neighbourName": "csg-bgl18-41a-cap15",
              "neighbourRssi": -93
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 2,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:85:71:20",
              "neighbourName": "csg-bgl18-41a-cap18",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "F4:DB:E6:84:C1:E0",
              "neighbourName": "csg-bgl18-42a-cap30",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "D4:E8:80:19:8D:60",
          "apName": "csg-bgl18-82a-cap29",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -73,
          "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "D4:E8:80:19:83:80",
              "neighbourName": "csg-bgl18-81a-cap07",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "D4:E8:80:19:8E:80",
              "neighbourName": "csg-bgl18-82a-cap38",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "B4:DE:31:C4:BA:A0",
          "apName": "csg-bgl18-31a-cap13",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -77,
          "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
          "nbrCoChannel": 92,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:A7:42:AC:C6:60",
              "neighbourName": "csg-bgl18-51a-cap13",
              "neighbourRssi": -77
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:BF:5D:80",
              "neighbourName": "csg-bgl18-31a-cap20",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "B4:DE:31:BE:EC:E0",
              "neighbourName": "csg-bgl18-31a-cap07",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -67
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "F4:DB:E6:61:9E:20",
          "apName": "csg-bgl18-41a-cap08",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": 0,
          "memberId": "2ae4cf9d-abd7-4cbb-8f76-0f1c593f731b",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 0,
          "onChannelNeighbourList": [],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:CE:A0",
              "neighbourName": "csg-bgl18-41a-cap15",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "F4:DB:E6:84:C5:20",
              "neighbourName": "csg-bgl18-41a-cap16",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "F4:DB:E6:61:B9:00",
          "apName": "csg-bgl18-41a-cap01",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -75,
          "memberId": "539afb61-e2fe-409b-a5d9-b5464073c81f",
          "nbrCoChannel": 86,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:4B:E0",
              "neighbourName": "csg-bgl18-62a-cap44",
              "neighbourRssi": -92
            },
            {
              "neighbourMac": "B4:DE:31:9D:3C:20",
              "neighbourName": "csg-bgl18-51a-cap21",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "70:DB:98:2C:71:A0",
              "neighbourName": "csg-bgl18-51a-cap02",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -92
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "F4:DB:E6:61:57:C0",
              "neighbourName": "csg-bgl18-42a-cap19",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:61:9E:20",
              "neighbourName": "csg-bgl18-41a-cap08",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "F4:DB:E6:84:C1:E0",
              "neighbourName": "csg-bgl18-42a-cap30",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "F4:DB:E6:84:CE:A0",
              "neighbourName": "csg-bgl18-41a-cap15",
              "neighbourRssi": -64
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "F4:DB:E6:85:71:20",
          "apName": "csg-bgl18-41a-cap18",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -80,
          "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:C1:E0",
              "neighbourName": "csg-bgl18-42a-cap30",
              "neighbourRssi": -80
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:44:8D:C0",
              "neighbourName": "csg-bgl18-42a-cap36",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "F4:DB:E6:61:53:00",
              "neighbourName": "csg-bgl18-41a-cap17",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "F4:DB:E6:84:CE:A0",
              "neighbourName": "csg-bgl18-41a-cap15",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:DB:98:07:A5:60",
          "apName": "csg-bgl18-52a-cap37",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -65,
          "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
          "nbrCoChannel": 50,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:6C:50",
              "neighbourName": "csg-bgl18-62a-cap37",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:DB:98:20:9D:00",
              "neighbourName": "csg-bgl18-52a-cap47",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:70:8B:B5:EA:A0",
              "neighbourName": "csg-bgl18-52a-cap28",
              "neighbourRssi": -83
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:DB:98:20:9C:60",
              "neighbourName": "csg-bgl18-52a-cap29",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "68:7D:B4:5F:6C:50",
              "neighbourName": "csg-bgl18-62a-cap37",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "F4:DB:E6:84:C1:E0",
          "apName": "csg-bgl18-42a-cap30",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -79,
          "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:85:71:20",
              "neighbourName": "csg-bgl18-41a-cap18",
              "neighbourRssi": -79
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 3,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A1:E0",
              "neighbourName": "csg-bgl18-32a-cap42",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "F4:DB:E6:44:8D:C0",
              "neighbourName": "csg-bgl18-42a-cap36",
              "neighbourRssi": -59
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:DB:98:13:D0:C0",
          "apName": "csg-bgl18-01a-cap15",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -64,
          "memberId": "133f0dc1-0b0d-4c04-9ba5-ef26d0c104b1",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -64
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:7D:B9:A5:45:E0",
              "neighbourName": "csg-bgl18-01a-cap07",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -67
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "D4:E8:80:19:9E:80",
          "apName": "csg-bgl18-82a-cap33",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -74,
          "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -74
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:83:80",
              "neighbourName": "csg-bgl18-81a-cap07",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "D4:E8:80:19:AA:80",
              "neighbourName": "csg-bgl18-81a-cap02",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "F4:DB:E6:84:CE:A0",
          "apName": "csg-bgl18-41a-cap15",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -89,
          "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:C5:20",
              "neighbourName": "csg-bgl18-41a-cap16",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "F4:DB:E6:44:8D:C0",
              "neighbourName": "csg-bgl18-42a-cap36",
              "neighbourRssi": -89
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:61:9E:20",
              "neighbourName": "csg-bgl18-41a-cap08",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "B4:DE:31:9D:3C:20",
              "neighbourName": "csg-bgl18-51a-cap21",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "F4:DB:E6:85:71:20",
              "neighbourName": "csg-bgl18-41a-cap18",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "F4:DB:E6:61:B9:00",
              "neighbourName": "csg-bgl18-41a-cap01",
              "neighbourRssi": -56
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "70:7D:B9:A6:EE:C0",
          "apName": "csg-bgl18-01a-cap17",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -65,
          "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -84
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -65
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:DB:98:13:D0:C0",
              "neighbourName": "csg-bgl18-01a-cap15",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 15
        },
        {
          "apMac": "70:DB:98:2C:79:C0",
          "apName": "csg-bgl18-02a-cap32",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -69,
          "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -69
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -60
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "68:7D:B4:5F:6C:50",
          "apName": "csg-bgl18-62a-cap37",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -64,
          "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
          "nbrCoChannel": 47,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:EA:20",
              "neighbourName": "csg-bgl18-61a-cap23",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -76
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:DB:98:07:A5:60",
              "neighbourName": "csg-bgl18-52a-cap37",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -66
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "D4:E8:80:19:8E:80",
          "apName": "csg-bgl18-82a-cap38",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -72,
          "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
          "nbrCoChannel": 94,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F8:0B:CB:E8:A2:A0",
              "neighbourName": "csg-bgl18-32a-cap37",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -72
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:8D:60",
              "neighbourName": "csg-bgl18-82a-cap29",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "D4:E8:80:19:8F:C0",
              "neighbourName": "csg-bgl18-82a-cap43",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "68:7D:B4:5F:6C:50",
              "neighbourName": "csg-bgl18-62a-cap37",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -63
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "F8:0B:CB:E8:A2:A0",
          "apName": "csg-bgl18-32a-cap37",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -75,
          "memberId": "92860f29-0f78-4128-8e1b-711fab79e74b",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:DB:98:10:CD:80",
              "neighbourName": "csg-bgl18-02a-cap37",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -75
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 4,
          "strongNeighbourList": [
            {
              "neighbourMac": "F4:DB:E6:84:5C:40",
              "neighbourName": "csg-bgl18-42a-cap32",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:70:8B:B5:E0:80",
              "neighbourName": "csg-bgl18-32a-cap29",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "F8:B7:E2:C3:02:00",
          "apName": "csg-bgl18-11a-cap02",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -71,
          "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -73
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 5,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -49
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "0C:D0:F8:94:A5:A0",
          "apName": "csg-bgl18-71a-cap01",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -62,
          "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
          "nbrCoChannel": 100,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -86
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -87
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 5,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -54
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "0C:D0:F8:94:72:60",
          "apName": "csg-bgl18-71a-cap10",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -46,
          "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
          "nbrCoChannel": 44,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -88
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -92
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 6,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -46
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "70:69:5A:78:64:60",
          "apName": "csg-bgl18-72a-cap28",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -51,
          "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
          "nbrCoChannel": 50,
          "onChannelNeighbourCount": 9,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -95
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -84
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -93
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -89
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -82
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 7,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -51
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 21
        },
        {
          "apMac": "F8:B7:E2:C2:E4:C0",
          "apName": "csg-bgl18-11a-cap13",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -41,
          "memberId": "49aba360-d4f9-431d-8213-3840fef4fe63",
          "nbrCoChannel": 31,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 7,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -25
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -56
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "00:5D:73:AE:59:A0",
          "apName": "csg-bgl18-12a-cap38",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -52,
          "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
          "nbrCoChannel": 42,
          "onChannelNeighbourCount": 1,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -52
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -25
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -55
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "70:69:5A:78:74:C0",
          "apName": "csg-bgl18-72a-cap30",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -45,
          "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
          "nbrCoChannel": 47,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -89
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -83
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -67
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -57
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "0C:D0:F8:94:AA:00",
          "apName": "csg-bgl18-71a-cap05",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -61,
          "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
          "nbrCoChannel": 97,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -72
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "00:5D:73:72:28:A0",
          "apName": "csg-bgl18-12a-cap37",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -50,
          "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
          "nbrCoChannel": 83,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -73
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -56
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:DB:98:10:CD:80",
              "neighbourName": "csg-bgl18-02a-cap37",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -29
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -48
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:69:5A:78:56:C0",
          "apName": "csg-bgl18-72a-cap33",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -48,
          "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
          "nbrCoChannel": 19,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -81
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -48
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 23
        },
        {
          "apMac": "00:5D:73:AE:21:A0",
          "apName": "csg-bgl18-12a-cap34",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -48
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:DB:98:2C:79:C0",
              "neighbourName": "csg-bgl18-02a-cap32",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -54
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "0C:D0:F8:94:8E:C0",
          "apName": "csg-bgl18-71a-cap24",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -54,
          "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
          "nbrCoChannel": 78,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "68:7D:B4:5F:EA:20",
              "neighbourName": "csg-bgl18-61a-cap23",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -73
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -94
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -88
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 8,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 26
        },
        {
          "apMac": "70:69:5A:78:51:C0",
          "apName": "csg-bgl18-72a-cap25",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -58,
          "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
          "nbrCoChannel": 81,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -91
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -84
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -58
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 9,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -64
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "0C:D0:F8:94:6D:20",
          "apName": "csg-bgl18-71a-cap02",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -53,
          "memberId": "dd770860-991d-4458-809c-4630e6c18609",
          "nbrCoChannel": 47,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -88
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 9,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -59
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:69:5A:76:C7:C0",
          "apName": "csg-bgl18-72a-cap29",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -60,
          "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
          "nbrCoChannel": 94,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -60
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 9,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -60
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "0C:D0:F8:94:97:60",
          "apName": "csg-bgl18-71a-cap03",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -48,
          "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
          "nbrCoChannel": 56,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -54
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:69:5A:78:6F:C0",
          "apName": "csg-bgl18-72a-cap32",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -57,
          "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
          "nbrCoChannel": 86,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -89
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -77
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -35
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "A0:F8:49:8B:C2:A0",
          "apName": "csg-bgl18-11a-cap10",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -57,
          "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 3,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -57
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -25
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "70:69:5A:78:64:80",
          "apName": "csg-bgl18-72a-cap31",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -51,
          "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
          "nbrCoChannel": 50,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -88
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -87
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -65
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -64
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "00:5D:73:AE:3F:C0",
          "apName": "csg-bgl18-12a-cap36",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -45,
          "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -49
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -29
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -24
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -49
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "70:69:5A:78:73:40",
          "apName": "csg-bgl18-72a-cap45",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -52,
          "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
          "nbrCoChannel": 33,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "68:7D:B4:5F:4B:E0",
              "neighbourName": "csg-bgl18-62a-cap44",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 10,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -52
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "70:69:5A:78:5B:A0",
          "apName": "csg-bgl18-72a-cap38",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
          "nbrCoChannel": 6,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -47
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 11,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -47
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "00:5D:73:72:2E:20",
          "apName": "csg-bgl18-12a-cap39",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -42,
          "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -48
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 11,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -48
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:69:5A:78:4E:A0",
          "apName": "csg-bgl18-72a-cap43",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -45,
          "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
          "nbrCoChannel": 50,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -85
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -62
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 12,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -42
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 27
        },
        {
          "apMac": "70:69:5A:78:4B:80",
          "apName": "csg-bgl18-72a-cap47",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -52,
          "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
          "nbrCoChannel": 44,
          "onChannelNeighbourCount": 10,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "D4:E8:80:19:AA:80",
              "neighbourName": "csg-bgl18-81a-cap02",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "68:7D:B4:5F:4B:E0",
              "neighbourName": "csg-bgl18-62a-cap44",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -52
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 12,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "70:EA:1A:C6:FF:60",
          "apName": "csg-bgl18-11a-cap17",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -35,
          "memberId": "b955254c-ed55-4229-a1af-10789663a572",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -35
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 12,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -24
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -23
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -35
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:69:5A:78:73:00",
          "apName": "csg-bgl18-72a-cap36",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -45,
          "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
          "nbrCoChannel": 28,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -83
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -83
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 12,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:69:5A:78:51:C0",
              "neighbourName": "csg-bgl18-72a-cap25",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -65
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "70:EA:1A:C6:FE:C0",
          "apName": "csg-bgl18-11a-cap19",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -36,
          "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -52
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 12,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -35
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -26
            },
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -52
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "70:EA:1A:C6:FA:40",
          "apName": "csg-bgl18-11a-cap24",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
          "nbrCoChannel": 17,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -72
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -21
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -68
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "70:EA:1A:C6:FF:A0",
          "apName": "csg-bgl18-11a-cap16",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -38,
          "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
          "nbrCoChannel": 28,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -66
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -21
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -17
            },
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -22
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -49
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:69:5A:78:72:00",
          "apName": "csg-bgl18-72a-cap48",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -44,
          "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
          "nbrCoChannel": 28,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -82
            },
            {
              "neighbourMac": "68:7D:B4:5F:EA:20",
              "neighbourName": "csg-bgl18-61a-cap23",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -76
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "00:5D:73:B6:96:C0",
          "apName": "csg-bgl18-12a-cap41",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -39,
          "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
          "nbrCoChannel": 83,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -75
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -26
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -26
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -32
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 15
        },
        {
          "apMac": "70:69:5A:78:70:C0",
          "apName": "csg-bgl18-72a-cap44",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -55,
          "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
          "nbrCoChannel": 69,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -84
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -58
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -30
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "0C:D0:F8:95:3F:60",
          "apName": "csg-bgl18-71a-cap16",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -61,
          "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
          "nbrCoChannel": 97,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -35
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "70:69:5A:78:5B:C0",
          "apName": "csg-bgl18-72a-cap39",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -58,
          "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
          "nbrCoChannel": 78,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -78
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:DB:98:20:9C:60",
              "neighbourName": "csg-bgl18-52a-cap29",
              "neighbourRssi": -81
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -60
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "0C:D0:F8:94:74:E0",
          "apName": "csg-bgl18-71a-cap22",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -46,
          "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
          "nbrCoChannel": 19,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -46
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 13,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -36
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 23
        },
        {
          "apMac": "A0:F8:49:8B:C2:E0",
          "apName": "csg-bgl18-11a-cap06",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -56,
          "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
          "nbrCoChannel": 89,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -70
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 14,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:7D:B9:A5:45:E0",
              "neighbourName": "csg-bgl18-01a-cap07",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -34
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -23
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -63
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "0C:D0:F8:95:1A:C0",
          "apName": "csg-bgl18-71a-cap14",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -47,
          "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
          "nbrCoChannel": 61,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -60
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 14,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "68:7D:B4:5F:E5:10",
              "neighbourName": "csg-bgl18-61a-cap14",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -32
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "00:5D:73:AE:3F:40",
          "apName": "csg-bgl18-12a-cap42",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -54,
          "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
          "nbrCoChannel": 78,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 14,
          "strongNeighbourList": [
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -23
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -62
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "70:EA:1A:C6:FB:E0",
          "apName": "csg-bgl18-11a-cap23",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -63
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 14,
          "strongNeighbourList": [
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -33
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -63
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "00:5D:73:AE:54:C0",
          "apName": "csg-bgl18-12a-cap43",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -41,
          "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -44
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 14,
          "strongNeighbourList": [
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "00:5D:73:72:28:A0",
              "neighbourName": "csg-bgl18-12a-cap37",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -29
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -56
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "70:69:5A:78:4F:A0",
          "apName": "csg-bgl18-72a-cap50",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -56,
          "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
          "nbrCoChannel": 64,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 15,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -31
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:EA:1A:C6:FD:E0",
          "apName": "csg-bgl18-11a-cap18",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -27,
          "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 6,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:DB:98:13:D0:C0",
              "neighbourName": "csg-bgl18-01a-cap15",
              "neighbourRssi": -73
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -27
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 15,
          "strongNeighbourList": [
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -25
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -63
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "0C:D0:F8:95:19:20",
          "apName": "csg-bgl18-71a-cap17",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -36,
          "memberId": "cdb8c59c-ec65-4811-a70b-a6a6d39f8f5a",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:7D:B9:A6:EE:C0",
              "neighbourName": "csg-bgl18-01a-cap17",
              "neighbourRssi": -80
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -64
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 15,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -28
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -58
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "70:EA:1A:C7:04:E0",
          "apName": "csg-bgl18-11a-cap15",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -39,
          "memberId": "ec50026a-6daf-4e03-9150-06ef37c43c49",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -73
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 15,
          "strongNeighbourList": [
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -29
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -29
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -39
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 19
        },
        {
          "apMac": "0C:D0:F8:95:24:80",
          "apName": "csg-bgl18-71a-cap19",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -42,
          "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
          "nbrCoChannel": 25,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -62
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 15,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -37
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "0C:D0:F8:94:A7:C0",
          "apName": "csg-bgl18-71a-cap23",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -47,
          "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
          "nbrCoChannel": 33,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -75
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -63
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -40
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -61
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "70:EA:1A:C7:03:80",
          "apName": "csg-bgl18-11a-cap22",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -35,
          "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 4,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -35
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -64
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "00:5D:73:AE:59:A0",
              "neighbourName": "csg-bgl18-12a-cap38",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -35
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -57
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "0C:D0:F8:94:9B:E0",
          "apName": "csg-bgl18-71a-cap08",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -57,
          "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
          "nbrCoChannel": 69,
          "onChannelNeighbourCount": 2,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -57
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "70:69:5A:78:64:60",
              "neighbourName": "csg-bgl18-72a-cap28",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -34
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -31
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -47
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -18
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -18
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "70:69:5A:78:72:C0",
          "apName": "csg-bgl18-72a-cap42",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -79
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -43
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -44
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "70:EA:1A:C6:FC:00",
          "apName": "csg-bgl18-11a-cap21",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -36,
          "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 5,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -69
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -39
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "0C:D0:F8:94:AC:A0",
          "apName": "csg-bgl18-71a-cap06",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -44,
          "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
          "nbrCoChannel": 36,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -73
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -74
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -95
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 16,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:6F:C0",
              "neighbourName": "csg-bgl18-72a-cap32",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -32
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -44
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 23
        },
        {
          "apMac": "B4:DE:31:0E:68:00",
          "apName": "csg-bgl18-12a-cap44",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -43,
          "memberId": "67272642-ee03-4e98-a2e6-04ce785ef82a",
          "nbrCoChannel": 17,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:AE:21:A0",
              "neighbourName": "csg-bgl18-12a-cap34",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 17,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "F8:B7:E2:C3:06:40",
              "neighbourName": "csg-bgl18-12a-cap45",
              "neighbourRssi": -33
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -41
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -43
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:69:5A:78:5F:20",
          "apName": "csg-bgl18-72a-cap49",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -52,
          "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
          "nbrCoChannel": 50,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "70:69:5A:76:C7:C0",
              "neighbourName": "csg-bgl18-72a-cap29",
              "neighbourRssi": -90
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -71
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 17,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -36
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -51
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "70:69:5A:78:73:40",
              "neighbourName": "csg-bgl18-72a-cap45",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:70:C0",
              "neighbourName": "csg-bgl18-72a-cap44",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:4E:A0",
              "neighbourName": "csg-bgl18-72a-cap43",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -63
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -66
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -27
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "0C:D0:F8:95:23:60",
          "apName": "csg-bgl18-71a-cap15",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -42,
          "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 8,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -70
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:69:5A:78:5B:A0",
              "neighbourName": "csg-bgl18-72a-cap38",
              "neighbourRssi": -76
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:74:C0",
              "neighbourName": "csg-bgl18-72a-cap30",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -77
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -49
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 17,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -56
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -34
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -58
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -34
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -65
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -64
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "F8:B7:E2:C3:06:40",
          "apName": "csg-bgl18-12a-cap45",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -33,
          "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -33
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "F8:B7:E2:C2:E4:C0",
              "neighbourName": "csg-bgl18-11a-cap13",
              "neighbourRssi": -71
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "F8:B7:E2:C3:02:00",
              "neighbourName": "csg-bgl18-11a-cap02",
              "neighbourRssi": -70
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 18,
          "strongNeighbourList": [
            {
              "neighbourMac": "A0:F8:49:8B:C2:E0",
              "neighbourName": "csg-bgl18-11a-cap06",
              "neighbourRssi": -23
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:40",
              "neighbourName": "csg-bgl18-12a-cap42",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C6:FD:E0",
              "neighbourName": "csg-bgl18-11a-cap18",
              "neighbourRssi": -37
            },
            {
              "neighbourMac": "00:5D:73:72:2E:20",
              "neighbourName": "csg-bgl18-12a-cap39",
              "neighbourRssi": -64
            },
            {
              "neighbourMac": "00:5D:73:B6:96:C0",
              "neighbourName": "csg-bgl18-12a-cap41",
              "neighbourRssi": -57
            },
            {
              "neighbourMac": "00:5D:73:AE:3F:C0",
              "neighbourName": "csg-bgl18-12a-cap36",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "00:5D:73:72:2D:00",
              "neighbourName": "csg-bgl18-12a-cap35",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:A0",
              "neighbourName": "csg-bgl18-11a-cap16",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:EA:1A:C6:FE:C0",
              "neighbourName": "csg-bgl18-11a-cap19",
              "neighbourRssi": -49
            },
            {
              "neighbourMac": "A0:F8:49:8B:C2:A0",
              "neighbourName": "csg-bgl18-11a-cap10",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "B4:DE:31:0E:68:00",
              "neighbourName": "csg-bgl18-12a-cap44",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "70:EA:1A:C7:04:E0",
              "neighbourName": "csg-bgl18-11a-cap15",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "70:EA:1A:C6:FB:E0",
              "neighbourName": "csg-bgl18-11a-cap23",
              "neighbourRssi": -23
            },
            {
              "neighbourMac": "70:EA:1A:C6:FC:00",
              "neighbourName": "csg-bgl18-11a-cap21",
              "neighbourRssi": -30
            },
            {
              "neighbourMac": "00:5D:73:AE:54:C0",
              "neighbourName": "csg-bgl18-12a-cap43",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "70:EA:1A:C7:03:80",
              "neighbourName": "csg-bgl18-11a-cap22",
              "neighbourRssi": -33
            },
            {
              "neighbourMac": "70:EA:1A:C6:FA:40",
              "neighbourName": "csg-bgl18-11a-cap24",
              "neighbourRssi": -39
            },
            {
              "neighbourMac": "70:EA:1A:C6:FF:60",
              "neighbourName": "csg-bgl18-11a-cap17",
              "neighbourRssi": -55
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 20
        },
        {
          "apMac": "0C:D0:F8:95:23:80",
          "apName": "csg-bgl18-71a-cap11",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -42,
          "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -69
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 18,
          "strongNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:95:26:E0",
              "neighbourName": "csg-bgl18-71a-cap18",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -44
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -53
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -68
            },
            {
              "neighbourMac": "0C:D0:F8:94:6D:20",
              "neighbourName": "csg-bgl18-71a-cap02",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:97:60",
              "neighbourName": "csg-bgl18-71a-cap03",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "0C:D0:F8:94:A5:A0",
              "neighbourName": "csg-bgl18-71a-cap01",
              "neighbourRssi": -46
            },
            {
              "neighbourMac": "70:69:5A:78:64:80",
              "neighbourName": "csg-bgl18-72a-cap31",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -42
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "68:7D:B4:5F:E5:10",
              "neighbourName": "csg-bgl18-61a-cap14",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -50
            },
            {
              "neighbourMac": "70:69:5A:78:56:C0",
              "neighbourName": "csg-bgl18-72a-cap33",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:AA:00",
              "neighbourName": "csg-bgl18-71a-cap05",
              "neighbourRssi": -59
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -69
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 20
        },
        {
          "apMac": "0C:D0:F8:95:26:E0",
          "apName": "csg-bgl18-71a-cap18",
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 1,
          "maxOnChannelNeighbourRssi": -38,
          "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
          "nbrCoChannel": 0,
          "onChannelNeighbourCount": 7,
          "onChannelNeighbourList": [
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:94:AC:A0",
              "neighbourName": "csg-bgl18-71a-cap06",
              "neighbourRssi": -72
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -38
            }
          ],
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "strongNeighbourCount": 18,
          "strongNeighbourList": [
            {
              "neighbourMac": "70:69:5A:78:73:00",
              "neighbourName": "csg-bgl18-72a-cap36",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "70:69:5A:78:72:00",
              "neighbourName": "csg-bgl18-72a-cap48",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "0C:D0:F8:94:72:60",
              "neighbourName": "csg-bgl18-71a-cap10",
              "neighbourRssi": -61
            },
            {
              "neighbourMac": "70:69:5A:78:4F:A0",
              "neighbourName": "csg-bgl18-72a-cap50",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:95:24:80",
              "neighbourName": "csg-bgl18-71a-cap19",
              "neighbourRssi": -27
            },
            {
              "neighbourMac": "0C:D0:F8:95:19:20",
              "neighbourName": "csg-bgl18-71a-cap17",
              "neighbourRssi": -38
            },
            {
              "neighbourMac": "70:69:5A:78:5B:C0",
              "neighbourName": "csg-bgl18-72a-cap39",
              "neighbourRssi": -67
            },
            {
              "neighbourMac": "0C:D0:F8:94:8E:C0",
              "neighbourName": "csg-bgl18-71a-cap24",
              "neighbourRssi": -55
            },
            {
              "neighbourMac": "70:69:5A:78:5F:20",
              "neighbourName": "csg-bgl18-72a-cap49",
              "neighbourRssi": -52
            },
            {
              "neighbourMac": "0C:D0:F8:95:3F:60",
              "neighbourName": "csg-bgl18-71a-cap16",
              "neighbourRssi": -26
            },
            {
              "neighbourMac": "70:69:5A:78:4B:80",
              "neighbourName": "csg-bgl18-72a-cap47",
              "neighbourRssi": -69
            },
            {
              "neighbourMac": "0C:D0:F8:95:1A:C0",
              "neighbourName": "csg-bgl18-71a-cap14",
              "neighbourRssi": -48
            },
            {
              "neighbourMac": "0C:D0:F8:94:A7:C0",
              "neighbourName": "csg-bgl18-71a-cap23",
              "neighbourRssi": -54
            },
            {
              "neighbourMac": "0C:D0:F8:94:74:E0",
              "neighbourName": "csg-bgl18-71a-cap22",
              "neighbourRssi": -45
            },
            {
              "neighbourMac": "0C:D0:F8:94:9B:E0",
              "neighbourName": "csg-bgl18-71a-cap08",
              "neighbourRssi": -62
            },
            {
              "neighbourMac": "70:69:5A:78:72:C0",
              "neighbourName": "csg-bgl18-72a-cap42",
              "neighbourRssi": -60
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:80",
              "neighbourName": "csg-bgl18-71a-cap11",
              "neighbourRssi": -43
            },
            {
              "neighbourMac": "0C:D0:F8:95:23:60",
              "neighbourName": "csg-bgl18-71a-cap15",
              "neighbourRssi": -33
            }
          ],
          "timestamp": "2022-03-31T02:00:00",
          "timestampMs": "1648692000000",
          "totalNeighbourCount": 23
        }
      ],
  "getPowerDistributionLatestDetail01_24ghz_Template": [
    {
      "apMac": "70:DB:98:10:CD:80",
      "apName": "csg-bgl18-02a-cap37",
      "avgOnChannelNeighbourPowerDbm": 7,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
      "powerDbm": 5,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "68:7D:B4:5F:E5:10",
      "apName": "csg-bgl18-61a-cap14",
      "avgOnChannelNeighbourPowerDbm": 9,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
      "powerDbm": 3,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 16
    },
    {
      "apMac": "F8:B7:E2:C3:02:00",
      "apName": "csg-bgl18-11a-cap02",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
      "powerDbm": 4,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:69:5A:78:73:40",
      "apName": "csg-bgl18-72a-cap45",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "00:5D:73:AE:3F:40",
      "apName": "csg-bgl18-12a-cap42",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "70:69:5A:78:5B:A0",
      "apName": "csg-bgl18-72a-cap38",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:69:5A:78:72:00",
      "apName": "csg-bgl18-72a-cap48",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "00:5D:73:AE:54:C0",
      "apName": "csg-bgl18-12a-cap43",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 18
    },
    {
      "apMac": "70:69:5A:78:64:80",
      "apName": "csg-bgl18-72a-cap31",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 11
    },
    {
      "apMac": "00:5D:73:AE:3F:C0",
      "apName": "csg-bgl18-12a-cap36",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "70:69:5A:78:5F:20",
      "apName": "csg-bgl18-72a-cap49",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "70:69:5A:78:72:C0",
      "apName": "csg-bgl18-72a-cap42",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "70:69:5A:78:4B:80",
      "apName": "csg-bgl18-72a-cap47",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 11
    },
    {
      "apMac": "00:5D:73:AE:59:A0",
      "apName": "csg-bgl18-12a-cap38",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 9
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "70:69:5A:76:C7:C0",
      "apName": "csg-bgl18-72a-cap29",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "00:5D:73:AE:21:A0",
      "apName": "csg-bgl18-12a-cap34",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 3,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "0C:D0:F8:94:AA:00",
      "apName": "csg-bgl18-71a-cap05",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 18
    },
    {
      "apMac": "0C:D0:F8:95:24:80",
      "apName": "csg-bgl18-71a-cap19",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "70:69:5A:78:6F:C0",
      "apName": "csg-bgl18-72a-cap32",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 8
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "70:EA:1A:C6:FC:00",
      "apName": "csg-bgl18-11a-cap21",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 10
    },
    {
      "apMac": "70:EA:1A:C6:FB:E0",
      "apName": "csg-bgl18-11a-cap23",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
      "powerDbm": -1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 18
    },
    {
      "apMac": "70:69:5A:78:70:C0",
      "apName": "csg-bgl18-72a-cap44",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 11
    },
    {
      "apMac": "0C:D0:F8:95:23:60",
      "apName": "csg-bgl18-71a-cap15",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 13
    },
    {
      "apMac": "0C:D0:F8:95:3F:60",
      "apName": "csg-bgl18-71a-cap16",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 12
    },
    {
      "apMac": "70:EA:1A:C7:03:80",
      "apName": "csg-bgl18-11a-cap22",
      "avgOnChannelNeighbourPowerDbm": 3,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "70:69:5A:78:56:C0",
      "apName": "csg-bgl18-72a-cap33",
      "avgOnChannelNeighbourPowerDbm": 3,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 8
    },
    {
      "apMac": "0C:D0:F8:94:8E:C0",
      "apName": "csg-bgl18-71a-cap24",
      "avgOnChannelNeighbourPowerDbm": 4,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 21
    },
    {
      "apMac": "0C:D0:F8:94:6D:20",
      "apName": "csg-bgl18-71a-cap02",
      "avgOnChannelNeighbourPowerDbm": 10,
      "avgStrongNeighbourPowerDbm": 1,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "dd770860-991d-4458-809c-4630e6c18609",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 23
    },
    {
      "apMac": "0C:D0:F8:94:72:60",
      "apName": "csg-bgl18-71a-cap10",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 20
    },
    {
      "apMac": "0C:D0:F8:94:A5:A0",
      "apName": "csg-bgl18-71a-cap01",
      "avgOnChannelNeighbourPowerDbm": 13,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 26
    },
    {
      "apMac": "00:5D:73:B6:96:C0",
      "apName": "csg-bgl18-12a-cap41",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 15
    },
    {
      "apMac": "70:EA:1A:C6:FF:A0",
      "apName": "csg-bgl18-11a-cap16",
      "avgOnChannelNeighbourPowerDbm": 4,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "70:69:5A:78:73:00",
      "apName": "csg-bgl18-72a-cap36",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
      "powerDbm": 0,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 16
    },
    {
      "apMac": "00:5D:73:72:2E:20",
      "apName": "csg-bgl18-12a-cap39",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 14
    },
    {
      "apMac": "0C:D0:F8:95:23:80",
      "apName": "csg-bgl18-71a-cap11",
      "avgOnChannelNeighbourPowerDbm": 1,
      "avgStrongNeighbourPowerDbm": 2,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
      "powerDbm": 1,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 17
    },
    {
      "apMac": "00:5D:73:72:28:A0",
      "apName": "csg-bgl18-12a-cap37",
      "avgOnChannelNeighbourPowerDbm": 2,
      "avgStrongNeighbourPowerDbm": 3,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 1,
      "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 9
    }
  ],
    "getPowerDistributionTrendDetail01_24ghz_Template": [
        {
          "apMac": "A0:F8:49:8B:C2:A0",
          "apName": "csg-bgl18-11a-cap10",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
          "powerDbm": 1,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "B4:DE:31:9D:33:60",
          "apName": "csg-bgl18-51a-cap17",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 3
        },
        {
          "apMac": "70:69:5A:78:51:C0",
          "apName": "csg-bgl18-72a-cap25",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
          "powerDbm": 3,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 19
        },
        {
          "apMac": "70:69:5A:76:C7:C0",
          "apName": "csg-bgl18-72a-cap29",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 21
        },
        {
          "apMac": "70:69:5A:78:74:C0",
          "apName": "csg-bgl18-72a-cap30",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
          "powerDbm": 3,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "F4:DB:E6:84:C1:E0",
          "apName": "csg-bgl18-42a-cap30",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
          "powerDbm": 17,
          "powerLevel": 3,
          "powerPercentage": 25,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "0C:D0:F8:95:26:E0",
          "apName": "csg-bgl18-71a-cap18",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "F4:DB:E6:61:B9:00",
          "apName": "csg-bgl18-41a-cap01",
          "avgOnChannelNeighbourPowerDbm": 14,
          "avgStrongNeighbourPowerDbm": 19,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "539afb61-e2fe-409b-a5d9-b5464073c81f",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "0C:D0:F8:95:19:20",
          "apName": "csg-bgl18-71a-cap17",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "cdb8c59c-ec65-4811-a70b-a6a6d39f8f5a",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "D4:E8:80:19:8F:C0",
          "apName": "csg-bgl18-82a-cap43",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 21,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "00:5D:73:AE:3F:40",
          "apName": "csg-bgl18-12a-cap42",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "098a0c87-1e65-4ee8-b69e-cc0f8eb91a07",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "F8:0B:CB:E8:A2:A0",
          "apName": "csg-bgl18-32a-cap37",
          "avgOnChannelNeighbourPowerDbm": 8,
          "avgStrongNeighbourPowerDbm": 11,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "92860f29-0f78-4128-8e1b-711fab79e74b",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "B4:DE:31:9D:3C:20",
          "apName": "csg-bgl18-51a-cap21",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "B4:DE:31:C4:BA:A0",
          "apName": "csg-bgl18-31a-cap13",
          "avgOnChannelNeighbourPowerDbm": 23,
          "avgStrongNeighbourPowerDbm": 14,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "F8:0B:CB:E8:A1:E0",
          "apName": "csg-bgl18-32a-cap42",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "F8:B7:E2:C3:02:00",
          "apName": "csg-bgl18-11a-cap02",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
          "powerDbm": 4,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "70:DB:98:20:9C:60",
          "apName": "csg-bgl18-52a-cap29",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 22,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:69:5A:78:64:80",
          "apName": "csg-bgl18-72a-cap31",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "00:5D:73:AE:59:A0",
          "apName": "csg-bgl18-12a-cap38",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ac635d4a-b15e-4fdd-9875-e79e2388251a",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "D4:E8:80:19:8D:60",
          "apName": "csg-bgl18-82a-cap29",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 14,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
          "powerDbm": 18,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "D4:E8:80:19:83:80",
          "apName": "csg-bgl18-81a-cap07",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
          "powerDbm": 10,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "00:5D:73:AE:54:C0",
          "apName": "csg-bgl18-12a-cap43",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "F4:DB:E6:84:C5:20",
          "apName": "csg-bgl18-41a-cap16",
          "avgOnChannelNeighbourPowerDbm": 18,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "47ef8e0e-0e0c-4447-a1d6-9d001c7c3db7",
          "powerDbm": 18,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "F4:DB:E6:61:57:C0",
          "apName": "csg-bgl18-42a-cap19",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "00:A7:42:AC:C6:60",
          "apName": "csg-bgl18-51a-cap13",
          "avgOnChannelNeighbourPowerDbm": 23,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "70:69:5A:78:64:60",
          "apName": "csg-bgl18-72a-cap28",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
          "powerDbm": 3,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:EA:1A:C6:FD:E0",
          "apName": "csg-bgl18-11a-cap18",
          "avgOnChannelNeighbourPowerDbm": 4,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
          "powerDbm": 1,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 19
        },
        {
          "apMac": "70:EA:1A:C7:03:80",
          "apName": "csg-bgl18-11a-cap22",
          "avgOnChannelNeighbourPowerDbm": 3,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 15
        },
        {
          "apMac": "B4:DE:31:0E:68:00",
          "apName": "csg-bgl18-12a-cap44",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "67272642-ee03-4e98-a2e6-04ce785ef82a",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "0C:D0:F8:94:A5:A0",
          "apName": "csg-bgl18-71a-cap01",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "0eda99bb-18d0-4806-88cd-d460eb878940",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "70:69:5A:78:6F:C0",
          "apName": "csg-bgl18-72a-cap32",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "B4:DE:31:BE:E3:A0",
          "apName": "csg-bgl18-32a-cap25",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "34c32ce0-e88a-4b90-a141-982cfb6c1ee1",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "F4:DB:E6:84:CE:A0",
          "apName": "csg-bgl18-41a-cap15",
          "avgOnChannelNeighbourPowerDbm": 18,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
          "powerDbm": 18,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "0C:D0:F8:95:23:80",
          "apName": "csg-bgl18-71a-cap11",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 20
        },
        {
          "apMac": "70:DB:98:13:D0:C0",
          "apName": "csg-bgl18-01a-cap15",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 8,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "133f0dc1-0b0d-4c04-9ba5-ef26d0c104b1",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "70:69:5A:78:4E:A0",
          "apName": "csg-bgl18-72a-cap43",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 26
        },
        {
          "apMac": "0C:D0:F8:94:AC:A0",
          "apName": "csg-bgl18-71a-cap06",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "B4:DE:31:BF:5D:80",
          "apName": "csg-bgl18-31a-cap20",
          "avgOnChannelNeighbourPowerDbm": 12,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "d4b47cf0-cb10-4280-92bf-286347b7e469",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "0C:D0:F8:94:8E:C0",
          "apName": "csg-bgl18-71a-cap24",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 27
        },
        {
          "apMac": "0C:D0:F8:94:97:60",
          "apName": "csg-bgl18-71a-cap03",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "F4:DB:E6:84:5C:40",
          "apName": "csg-bgl18-42a-cap32",
          "avgOnChannelNeighbourPowerDbm": 16,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:DB:98:07:A5:60",
          "apName": "csg-bgl18-52a-cap37",
          "avgOnChannelNeighbourPowerDbm": 8,
          "avgStrongNeighbourPowerDbm": 15,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 10
        },
        {
          "apMac": "0C:D0:F8:94:AA:00",
          "apName": "csg-bgl18-71a-cap05",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:7D:B9:A6:EE:C0",
          "apName": "csg-bgl18-01a-cap17",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 7,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
          "powerDbm": 8,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "A0:F8:49:8B:C2:E0",
          "apName": "csg-bgl18-11a-cap06",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 3,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
          "powerDbm": 1,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:DB:98:2C:64:40",
          "apName": "csg-bgl18-02a-cap31",
          "avgOnChannelNeighbourPowerDbm": 13,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:DB:98:07:AA:A0",
          "apName": "csg-bgl18-32a-cap44",
          "avgOnChannelNeighbourPowerDbm": 18,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:DB:98:2C:79:C0",
          "apName": "csg-bgl18-02a-cap32",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "68:7D:B4:5F:E5:10",
          "apName": "csg-bgl18-61a-cap14",
          "avgOnChannelNeighbourPowerDbm": 10,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
          "powerDbm": 3,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "0C:D0:F8:95:1A:C0",
          "apName": "csg-bgl18-71a-cap14",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "68:7D:B4:5F:EA:20",
          "apName": "csg-bgl18-61a-cap23",
          "avgOnChannelNeighbourPowerDbm": 6,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
          "powerDbm": 15,
          "powerLevel": 3,
          "powerPercentage": 25,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 20
        },
        {
          "apMac": "70:DB:98:20:9D:00",
          "apName": "csg-bgl18-52a-cap47",
          "avgOnChannelNeighbourPowerDbm": 9,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 12
        },
        {
          "apMac": "0C:D0:F8:94:74:E0",
          "apName": "csg-bgl18-71a-cap22",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "0C:D0:F8:94:72:60",
          "apName": "csg-bgl18-71a-cap10",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "0C:D0:F8:94:9B:E0",
          "apName": "csg-bgl18-71a-cap08",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "70:70:8B:B5:EA:A0",
          "apName": "csg-bgl18-52a-cap28",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 22,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
          "powerDbm": 23,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "0C:D0:F8:94:A7:C0",
          "apName": "csg-bgl18-71a-cap23",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
          "powerDbm": 2,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "68:7D:B4:5F:6C:50",
          "apName": "csg-bgl18-62a-cap37",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 7,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
          "powerDbm": 18,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "70:69:5A:78:4F:A0",
          "apName": "csg-bgl18-72a-cap50",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "00:5D:73:72:2E:20",
          "apName": "csg-bgl18-12a-cap39",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3f289ab4-9dfd-4ac1-945f-e013571d71d5",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:69:5A:78:70:C0",
          "apName": "csg-bgl18-72a-cap44",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 3,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a764c0c5-4107-46c8-88c3-40e3253428fb",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "F4:DB:E6:61:53:00",
          "apName": "csg-bgl18-41a-cap17",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 19,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "B4:DE:31:BE:EC:E0",
          "apName": "csg-bgl18-31a-cap07",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 23,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 2
        },
        {
          "apMac": "70:DB:98:10:CD:80",
          "apName": "csg-bgl18-02a-cap37",
          "avgOnChannelNeighbourPowerDbm": 10,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
          "powerDbm": 5,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 12
        },
        {
          "apMac": "D4:E8:80:19:B5:00",
          "apName": "csg-bgl18-82a-cap48",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
          "powerDbm": 21,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:EA:1A:C6:FB:E0",
          "apName": "csg-bgl18-11a-cap23",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "8965f9b7-e2f6-4fa5-852f-adbca53630a3",
          "powerDbm": -1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 16
        },
        {
          "apMac": "00:5D:73:72:28:A0",
          "apName": "csg-bgl18-12a-cap37",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 3,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "4f6653d4-b8f7-44da-a9fc-f2640b282688",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:DB:98:13:C9:C0",
          "apName": "csg-bgl18-02a-cap27",
          "avgOnChannelNeighbourPowerDbm": 11,
          "avgStrongNeighbourPowerDbm": 23,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 4
        },
        {
          "apMac": "70:EA:1A:C6:FF:A0",
          "apName": "csg-bgl18-11a-cap16",
          "avgOnChannelNeighbourPowerDbm": 4,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a53bfec8-778e-48e6-a76b-5d8aa764df56",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 15
        },
        {
          "apMac": "0C:D0:F8:95:24:80",
          "apName": "csg-bgl18-71a-cap19",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "f68be804-8872-4b2a-b471-4948f2822bd6",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "D4:E8:80:19:9E:80",
          "apName": "csg-bgl18-82a-cap33",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 8,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:69:5A:78:72:00",
          "apName": "csg-bgl18-72a-cap48",
          "avgOnChannelNeighbourPowerDbm": 3,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:EA:1A:C6:FC:00",
          "apName": "csg-bgl18-11a-cap21",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 21
        },
        {
          "apMac": "70:EA:1A:C6:FF:60",
          "apName": "csg-bgl18-11a-cap17",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "b955254c-ed55-4229-a1af-10789663a572",
          "powerDbm": 1,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "70:69:5A:78:73:00",
          "apName": "csg-bgl18-72a-cap36",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "00:5D:73:AE:21:A0",
          "apName": "csg-bgl18-12a-cap34",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 3,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 11
        },
        {
          "apMac": "68:7D:B4:5F:4B:E0",
          "apName": "csg-bgl18-62a-cap44",
          "avgOnChannelNeighbourPowerDbm": 5,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
          "powerDbm": 19,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "70:DB:98:2C:71:A0",
          "apName": "csg-bgl18-51a-cap02",
          "avgOnChannelNeighbourPowerDbm": 13,
          "avgStrongNeighbourPowerDbm": 23,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "f093a28e-db79-4f30-a192-fd9d569be79c",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "70:69:5A:78:72:C0",
          "apName": "csg-bgl18-72a-cap42",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "70:69:5A:78:56:C0",
          "apName": "csg-bgl18-72a-cap33",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 25
        },
        {
          "apMac": "F4:DB:E6:61:9E:20",
          "apName": "csg-bgl18-41a-cap08",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 19,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "2ae4cf9d-abd7-4cbb-8f76-0f1c593f731b",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "70:69:5A:78:5B:A0",
          "apName": "csg-bgl18-72a-cap38",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 26
        },
        {
          "apMac": "0C:D0:F8:95:3F:60",
          "apName": "csg-bgl18-71a-cap16",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "16284e25-d23e-4031-9a04-587209c336d5",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 18
        },
        {
          "apMac": "70:69:5A:78:4B:80",
          "apName": "csg-bgl18-72a-cap47",
          "avgOnChannelNeighbourPowerDbm": 6,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 23
        },
        {
          "apMac": "70:69:5A:78:5F:20",
          "apName": "csg-bgl18-72a-cap49",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 24
        },
        {
          "apMac": "D4:E8:80:19:AA:80",
          "apName": "csg-bgl18-81a-cap02",
          "avgOnChannelNeighbourPowerDbm": 0,
          "avgStrongNeighbourPowerDbm": 15,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 8
        },
        {
          "apMac": "F4:DB:E6:85:71:20",
          "apName": "csg-bgl18-41a-cap18",
          "avgOnChannelNeighbourPowerDbm": 17,
          "avgStrongNeighbourPowerDbm": 19,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:70:8B:B5:E0:80",
          "apName": "csg-bgl18-32a-cap29",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 22,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 6
        },
        {
          "apMac": "D4:E8:80:19:8E:80",
          "apName": "csg-bgl18-82a-cap38",
          "avgOnChannelNeighbourPowerDbm": 11,
          "avgStrongNeighbourPowerDbm": 15,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
          "powerDbm": 20,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "0C:D0:F8:94:6D:20",
          "apName": "csg-bgl18-71a-cap02",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "dd770860-991d-4458-809c-4630e6c18609",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:7D:B9:A5:45:E0",
          "apName": "csg-bgl18-01a-cap07",
          "avgOnChannelNeighbourPowerDbm": 20,
          "avgStrongNeighbourPowerDbm": 20,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "495db500-2b16-4f52-a6e7-50cf471dbac0",
          "powerDbm": 17,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 5
        },
        {
          "apMac": "0C:D0:F8:95:23:60",
          "apName": "csg-bgl18-71a-cap15",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
          "powerDbm": 1,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 23
        },
        {
          "apMac": "70:69:5A:78:5B:C0",
          "apName": "csg-bgl18-72a-cap39",
          "avgOnChannelNeighbourPowerDbm": 6,
          "avgStrongNeighbourPowerDbm": 1,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "70:EA:1A:C6:FE:C0",
          "apName": "csg-bgl18-11a-cap19",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
          "powerDbm": 3,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 14
        },
        {
          "apMac": "D4:E8:80:19:90:E0",
          "apName": "csg-bgl18-81a-cap21",
          "avgOnChannelNeighbourPowerDbm": 8,
          "avgStrongNeighbourPowerDbm": 0,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
          "powerDbm": 20,
          "powerLevel": 1,
          "powerPercentage": 100,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 7
        },
        {
          "apMac": "70:EA:1A:C6:FA:40",
          "apName": "csg-bgl18-11a-cap24",
          "avgOnChannelNeighbourPowerDbm": 1,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
          "powerDbm": 1,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 17
        },
        {
          "apMac": "00:5D:73:B6:96:C0",
          "apName": "csg-bgl18-12a-cap41",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "3b1eeb48-b238-4b21-b35c-07690962a130",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 15
        },
        {
          "apMac": "00:5D:73:AE:3F:C0",
          "apName": "csg-bgl18-12a-cap36",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
          "powerDbm": 2,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "F8:B7:E2:C2:E4:C0",
          "apName": "csg-bgl18-11a-cap13",
          "avgOnChannelNeighbourPowerDbm": 3,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "49aba360-d4f9-431d-8213-3840fef4fe63",
          "powerDbm": 1,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 9
        },
        {
          "apMac": "70:69:5A:78:73:40",
          "apName": "csg-bgl18-72a-cap45",
          "avgOnChannelNeighbourPowerDbm": 5,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
          "powerDbm": 0,
          "powerLevel": 7,
          "powerPercentage": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 22
        },
        {
          "apMac": "F8:B7:E2:C3:06:40",
          "apName": "csg-bgl18-12a-cap45",
          "avgOnChannelNeighbourPowerDbm": 3,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
          "powerDbm": 1,
          "powerLevel": 8,
          "powerPercentage": 1,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 20
        },
        {
          "apMac": "F4:DB:E6:44:8D:C0",
          "apName": "csg-bgl18-42a-cap36",
          "avgOnChannelNeighbourPowerDbm": 11,
          "avgStrongNeighbourPowerDbm": 19,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
          "powerDbm": 18,
          "powerLevel": 2,
          "powerPercentage": 50,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 13
        },
        {
          "apMac": "70:EA:1A:C7:04:E0",
          "apName": "csg-bgl18-11a-cap15",
          "avgOnChannelNeighbourPowerDbm": 2,
          "avgStrongNeighbourPowerDbm": 2,
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "coverageHoleCount": 0,
          "frequencyBand": 1,
          "memberId": "ec50026a-6daf-4e03-9150-06ef37c43c49",
          "powerDbm": 3,
          "powerLevel": 6,
          "powerPercentage": 3,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "slot": 0,
          "timestamp": "2022-03-30T20:30:00",
          "timestampMs": "1648672200000",
          "totalNeighbourCount": 18
        }
      ],
  });