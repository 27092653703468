define({
    "Template":
    {
        "request": {
          "key": "ntp",
          "type": "Application",
          "label": "ntp",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "95b960f7-89d2-4dd2-b6d5-ac38121e5abe"
            }
          ]
        },
        "data": {
          "response": {
            "id": "95b960f7-89d2-4dd2-b6d5-ac38121e5abe",
            "instanceId": 4890,
            "authEntityId": 4890,
            "displayName": "4890",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528178810793,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528178810793,
            "name": "ntp",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ntp",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [
              {
                "id": "b07f0b29-bfc9-461e-bb8b-07c6d24732c1",
                "instanceId": 14320,
                "authEntityId": 14320,
                "displayName": "14320",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "123",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "networkApplications": [
              {
                "id": "a9ac3641-4b6e-4aa6-807a-d6bee9b5830a",
                "instanceId": 11838,
                "authEntityId": 11838,
                "displayName": "11838",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "other",
                "applicationType": "DEFAULT",
                "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
                "createTime": 1528178810793,
                "encrypted": "false",
                "engineId": "3",
                "helpString": "Network Time Protocol",
                "ignoreConflict": false,
                "lastUpdateTime": 1528178810793,
                "longDescription": "Network Time Protocol (NTP) is a protocol for synchronizing the system clocks of distributed computer systems over packet-switched, variable-latency data networks. Usually the UDP port used is 123.",
                "name": "ntp",
                "nbarId": "31",
                "p2pTechnology": "false",
                "popularity": 9,
                "rank": 65535,
                "references": "http://www.ntp.org/",
                "selectorId": "123",
                "subCategory": "33353ae0-7f7c-49e7-af3e-c759f007d9d9",
                "trafficClass": "OPS_ADMIN_MGMT",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "440de62f-79d6-4672-bc98-56135289de8d",
                "instanceId": 14318,
                "authEntityId": 14318,
                "displayName": "14318",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,123,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "9c8db5d6-30fb-4481-b7f1-3cdeabb08690",
                "instanceId": 14319,
                "authEntityId": 14319,
                "displayName": "14319",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "6af07333-7132-42bb-817b-41f5ea0f5698"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "95b960f7-89d2-4dd2-b6d5-ac38121e5abe",
              "instanceId": 4890,
              "authEntityId": 4890,
              "displayName": "4890",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528178810793,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528178810793,
              "name": "ntp",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ntp",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [
                {
                  "id": "b07f0b29-bfc9-461e-bb8b-07c6d24732c1",
                  "instanceId": 14320,
                  "authEntityId": 14320,
                  "displayName": "14320",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "123",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "networkApplications": [
                {
                  "id": "a9ac3641-4b6e-4aa6-807a-d6bee9b5830a",
                  "instanceId": 11838,
                  "authEntityId": 11838,
                  "displayName": "11838",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "other",
                  "applicationType": "DEFAULT",
                  "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
                  "createTime": 1528178810793,
                  "encrypted": "false",
                  "engineId": "3",
                  "helpString": "Network Time Protocol",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528178810793,
                  "longDescription": "Network Time Protocol (NTP) is a protocol for synchronizing the system clocks of distributed computer systems over packet-switched, variable-latency data networks. Usually the UDP port used is 123.",
                  "name": "ntp",
                  "nbarId": "31",
                  "p2pTechnology": "false",
                  "popularity": 9,
                  "rank": 65535,
                  "references": "http://www.ntp.org/",
                  "selectorId": "123",
                  "subCategory": "33353ae0-7f7c-49e7-af3e-c759f007d9d9",
                  "trafficClass": "OPS_ADMIN_MGMT",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "440de62f-79d6-4672-bc98-56135289de8d",
                  "instanceId": 14318,
                  "authEntityId": 14318,
                  "displayName": "14318",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,123,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "9c8db5d6-30fb-4481-b7f1-3cdeabb08690",
                  "instanceId": 14319,
                  "authEntityId": 14319,
                  "displayName": "14319",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "6af07333-7132-42bb-817b-41f5ea0f5698"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });