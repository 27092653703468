define({
    "Application_Template":   [
    {
      "id": "052bef64-1e1c-4d81-85de-c5d89b48b53a",
      "instanceId": 87113,
      "authEntityId": 87113,
      "displayName": "87113",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219828,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219828,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "bb4ba27c-1d75-40f9-81da-8946d038b50f",
        "instanceId": 93114,
        "authEntityId": 93114,
        "displayName": "93114",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "74d6b9d7-d722-4f0f-80d9-cf00d4b5a214",
            "instanceId": 88135,
            "authEntityId": 88135,
            "displayName": "88135",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "68534684-3b91-4533-9f4f-7529db9c7726",
            "instanceId": 88136,
            "authEntityId": 88136,
            "displayName": "88136",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "80b2d124-08e8-4dab-95b1-56087249b516",
                "instanceId": 94115,
                "authEntityId": 94115,
                "displayName": "94115",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "90a68e0b-c3d4-4a24-a5fc-889765ca95dc",
        "instanceId": 95116,
        "authEntityId": 95116,
        "displayName": "network-management",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
          }
        ]
      }
    },
    {
      "id": "0847e7cc-7f9d-40c0-8bc6-9d3b2a42c96e",
      "instanceId": 87114,
      "authEntityId": 87114,
      "displayName": "87114",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219845,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219845,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "fff55b4e-e025-4c1c-9174-8f8e78ee483a",
        "instanceId": 93115,
        "authEntityId": 93115,
        "displayName": "93115",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "48748aec-0e32-4871-9d78-cb7c3e926fef",
            "instanceId": 88137,
            "authEntityId": 88137,
            "displayName": "88137",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "04fe73e9-3db4-453a-b3e0-f17497b8c380",
            "instanceId": 88138,
            "authEntityId": 88138,
            "displayName": "88138",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "d0bb60c4-ef92-4e0c-8e54-a3151fec8094",
                "instanceId": 94116,
                "authEntityId": 94116,
                "displayName": "94116",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "c0144620-6a4c-4254-b421-162fcab3bce0",
        "instanceId": 95117,
        "authEntityId": 95117,
        "displayName": "remote-access",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
          }
        ]
      }
    },
    {
      "id": "16b951ac-d3a5-4d9b-9d07-8db2b27182f6",
      "instanceId": 87101,
      "authEntityId": 87101,
      "displayName": "87101",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219457,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219457,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "3cc8dbeb-308e-480a-9611-a419b2c427d0",
        "instanceId": 93102,
        "authEntityId": 93102,
        "displayName": "93102",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "51bcea55-bb0e-41b6-bef1-3051a34722fe",
            "instanceId": 88112,
            "authEntityId": 88112,
            "displayName": "88112",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "ecdfc0c4-a131-48b1-8b7a-3b38eeee2fc8",
                "instanceId": 94103,
                "authEntityId": 94103,
                "displayName": "94103",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          },
          {
            "id": "2775a971-4099-4c54-906f-6f00e61d6405",
            "instanceId": 88111,
            "authEntityId": 88111,
            "displayName": "88111",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "ecd80f6c-5bbc-41d8-bbe7-0db591621fca",
        "instanceId": 95104,
        "authEntityId": 95104,
        "displayName": "database-apps",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "8718395e-683e-4a2d-a232-100202751271"
          }
        ]
      }
    },
    {
      "id": "1f6dae0b-eb02-46e5-b003-cbf76ea60a43",
      "instanceId": 87103,
      "authEntityId": 87103,
      "displayName": "87103",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219511,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219511,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "a3251245-a95a-4e16-9e2e-46d61a994982",
        "instanceId": 93104,
        "authEntityId": 93104,
        "displayName": "93104",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "2e0a4052-b03b-4511-8350-ca7e47f19ea2",
            "instanceId": 88115,
            "authEntityId": 88115,
            "displayName": "88115",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "dce60778-779e-4a2f-91ee-db6959bc66d4",
            "instanceId": 88116,
            "authEntityId": 88116,
            "displayName": "88116",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "928c790e-e709-4b9b-81c4-73b520cf693a",
                "instanceId": 94105,
                "authEntityId": 94105,
                "displayName": "94105",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "62e71996-b36f-4858-b267-00c5480aadfd",
        "instanceId": 95106,
        "authEntityId": 95106,
        "displayName": "email",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
          }
        ]
      }
    },
    {
      "id": "225b97b4-6c47-48f6-8313-14d4e2b58f5c",
      "instanceId": 87099,
      "authEntityId": 87099,
      "displayName": "87099",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219370,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219370,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "b883931d-b971-4e22-aace-a812b7c32e37",
        "instanceId": 93100,
        "authEntityId": 93100,
        "displayName": "93100",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "90b4c5cb-0840-41a6-9af5-5bbcca8b6505",
            "instanceId": 88107,
            "authEntityId": 88107,
            "displayName": "88107",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "46f4ea39-ccab-4831-9291-443612a9298b",
            "instanceId": 88108,
            "authEntityId": 88108,
            "displayName": "88108",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "fb3f1871-dd63-44ad-97da-84be7628d4f6",
                "instanceId": 94101,
                "authEntityId": 94101,
                "displayName": "94101",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "36f6d92e-c26a-4049-95b2-9f67df7ab44f",
        "instanceId": 95102,
        "authEntityId": 95102,
        "displayName": "consumer-misc",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
          }
        ]
      }
    },
    {
      "id": "31a4daea-e169-413a-b682-96ef9bcf0292",
      "instanceId": 87119,
      "authEntityId": 87119,
      "displayName": "87119",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971220036,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971220036,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "1564dd8d-c3ee-47de-9dca-1474be89a9c5",
        "instanceId": 93120,
        "authEntityId": 93120,
        "displayName": "93120",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "cb4c54c3-477b-4dab-87bd-8f04774a146e",
            "instanceId": 88147,
            "authEntityId": 88147,
            "displayName": "88147",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "adb16cc2-0022-4b33-bb91-093e3d1e47cd",
            "instanceId": 88148,
            "authEntityId": 88148,
            "displayName": "88148",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "99fa2f9f-3133-487c-9495-1fe4d84676ed",
                "instanceId": 94121,
                "authEntityId": 94121,
                "displayName": "94121",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 200,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "06d2703e-e591-467d-ad30-9bb3445e9b27",
        "instanceId": 95122,
        "authEntityId": 95122,
        "displayName": "streaming-media",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "961b652e-1019-451e-86da-f9ff537016d2"
          }
        ]
      }
    },
    {
      "id": "39bc7c71-4740-457b-9bdc-872b3a9ac32d",
      "instanceId": 87105,
      "authEntityId": 87105,
      "displayName": "87105",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219540,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219540,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "7b6bf09e-c229-4559-ba16-4052890365fb",
        "instanceId": 93106,
        "authEntityId": 93106,
        "displayName": "93106",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "44191c81-e48a-4c0d-9190-b8ea9cd8f934",
            "instanceId": 88119,
            "authEntityId": 88119,
            "displayName": "88119",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          },
          {
            "id": "7a2520c7-322f-488e-9d2c-c3215224c125",
            "instanceId": 88120,
            "authEntityId": 88120,
            "displayName": "88120",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "3c363915-f182-4baa-93d6-6464a0b9f268",
                "instanceId": 94107,
                "authEntityId": 94107,
                "displayName": "94107",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "d2badb42-6c22-4890-9a62-d0064db2b979",
        "instanceId": 95108,
        "authEntityId": 95108,
        "displayName": "file-sharing",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
          }
        ]
      }
    },
    {
      "id": "44082f76-61b1-4a93-b2bc-a2cda76d21b1",
      "instanceId": 87107,
      "authEntityId": 87107,
      "displayName": "87107",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219618,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219618,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "3f136f1b-7f57-4574-bb94-afe3c0feccd7",
        "instanceId": 93108,
        "authEntityId": 93108,
        "displayName": "93108",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "39146008-6ce0-4384-b020-b3a8622f4665",
            "instanceId": 88123,
            "authEntityId": 88123,
            "displayName": "88123",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          },
          {
            "id": "9189d7dc-82f0-4bf3-9c43-15e9405d0f74",
            "instanceId": 88124,
            "authEntityId": 88124,
            "displayName": "88124",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "81c65457-6ee3-47b4-856f-46e9d8f7f714",
                "instanceId": 94109,
                "authEntityId": 94109,
                "displayName": "94109",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 200,
                "loss": 1,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "43506a9a-8db4-41a6-af6e-ab8b882b915a",
        "instanceId": 95110,
        "authEntityId": 95110,
        "displayName": "general-media",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
          }
        ]
      }
    },
    {
      "id": "49d94f53-b2e3-476f-8207-d98c7ecf43f1",
      "instanceId": 87097,
      "authEntityId": 87097,
      "displayName": "87097",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219338,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219338,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "0843898e-8070-477f-9b74-95f0f2c84a97",
        "instanceId": 93098,
        "authEntityId": 93098,
        "displayName": "93098",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "3c9c2e20-7c19-4c2e-b2ae-3fb59d808263",
            "instanceId": 88103,
            "authEntityId": 88103,
            "displayName": "88103",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "0e449dc0-61b6-42b0-9b81-68fc4de7a885",
            "instanceId": 88104,
            "authEntityId": 88104,
            "displayName": "88104",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "53490e68-ea8a-428a-971f-3744e46efa86",
                "instanceId": 94099,
                "authEntityId": 94099,
                "displayName": "94099",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "48dee455-9f1f-4116-b436-5066159ff6e3",
        "instanceId": 95100,
        "authEntityId": 95100,
        "displayName": "consumer-gaming",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
          }
        ]
      }
    },
    {
      "id": "4be12cc1-a29b-462a-b2a4-6329049b1632",
      "instanceId": 87112,
      "authEntityId": 87112,
      "displayName": "87112",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219761,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219761,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "75b5a525-ac11-43bb-982f-349a591f0829",
        "instanceId": 93113,
        "authEntityId": 93113,
        "displayName": "93113",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "87a4dc3d-c04e-42a3-af0b-f14bd0cccf15",
            "instanceId": 88133,
            "authEntityId": 88133,
            "displayName": "88133",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "0dc34008-a00e-4da5-a072-9018fe04baee",
            "instanceId": 88134,
            "authEntityId": 88134,
            "displayName": "88134",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "44095f8a-68e5-48e1-930b-3719691de651",
                "instanceId": 94114,
                "authEntityId": 94114,
                "displayName": "94114",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "d472079b-06e6-44df-870b-7dbc554e4218",
        "instanceId": 95115,
        "authEntityId": 95115,
        "displayName": "network-control",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
          }
        ]
      }
    },
    {
      "id": "4e79d1e0-84be-4eef-b61c-bca5bb889285",
      "instanceId": 87106,
      "authEntityId": 87106,
      "displayName": "87106",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219552,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219552,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "572c1201-0b4f-47c4-8ba7-2626b7fac57a",
        "instanceId": 93107,
        "authEntityId": 93107,
        "displayName": "93107",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "61532fe2-9bdc-435f-9450-041dab61b3b1",
            "instanceId": 88121,
            "authEntityId": 88121,
            "displayName": "88121",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          },
          {
            "id": "9fd0d5d4-aec1-4699-9fd9-436eed4517a3",
            "instanceId": 88122,
            "authEntityId": 88122,
            "displayName": "88122",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "5db1a104-d5cb-49e9-98b9-5ddd21a56400",
                "instanceId": 94108,
                "authEntityId": 94108,
                "displayName": "94108",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "0da10faf-9787-44c8-a482-d8b1124d9601",
        "instanceId": 95109,
        "authEntityId": 95109,
        "displayName": "general-browsing",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
          }
        ]
      }
    },
    {
      "id": "579ded2f-1326-4970-80f8-70f19cadc393",
      "instanceId": 87100,
      "authEntityId": 87100,
      "displayName": "87100",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219441,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219441,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "f78965d0-bd9d-436d-aae5-3dc57da7090b",
        "instanceId": 93101,
        "authEntityId": 93101,
        "displayName": "93101",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "225b8f1f-4add-41ad-910f-acfc4e9a3ae7",
            "instanceId": 88109,
            "authEntityId": 88109,
            "displayName": "88109",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "4e0586c3-8372-4ff1-a4c0-a24fb65cf1de",
            "instanceId": 88110,
            "authEntityId": 88110,
            "displayName": "88110",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "64a8af3d-749f-4f0c-a41c-a04c0996ee25",
                "instanceId": 94102,
                "authEntityId": 94102,
                "displayName": "94102",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "88201496-e098-4836-965e-7f14a128cb4b",
        "instanceId": 95103,
        "authEntityId": 95103,
        "displayName": "consumer-social-networking",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
          }
        ]
      }
    },
    {
      "id": "62b6955e-cec0-4fb1-938b-7f440321d824",
      "instanceId": 87115,
      "authEntityId": 87115,
      "displayName": "87115",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219915,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219915,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "bffb587e-adbf-46d6-80e2-11448ba0777c",
        "instanceId": 93116,
        "authEntityId": 93116,
        "displayName": "93116",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "1f2e4085-a896-4af6-afcb-ade30959cf89",
            "instanceId": 88139,
            "authEntityId": 88139,
            "displayName": "88139",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "2866e8b1-18b5-4d9d-918e-ec9b4b274181",
            "instanceId": 88140,
            "authEntityId": 88140,
            "displayName": "88140",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "15702b6e-ff3d-46cf-ba94-a2c60869cf58",
                "instanceId": 94117,
                "authEntityId": 94117,
                "displayName": "94117",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "b85ff291-11bb-46c8-bcf6-7b14a4083ced",
        "instanceId": 95118,
        "authEntityId": 95118,
        "displayName": "saas-apps",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
          }
        ]
      }
    },
    {
      "id": "655e4235-fc42-42a5-9fdf-f7966aadda4e",
      "instanceId": 87111,
      "authEntityId": 87111,
      "displayName": "87111",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219747,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219747,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "01f07cf5-6c20-42c7-ae5c-8a79816de9ff",
        "instanceId": 93112,
        "authEntityId": 93112,
        "displayName": "93112",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "b0808afb-1f43-4b67-8963-b4739b50e12f",
            "instanceId": 88131,
            "authEntityId": 88131,
            "displayName": "88131",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "50fb7011-8ece-4017-9a83-e45e5e0dc844",
            "instanceId": 88132,
            "authEntityId": 88132,
            "displayName": "88132",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "f2655964-7c09-4631-a3c5-6bab8df4ad56",
                "instanceId": 94113,
                "authEntityId": 94113,
                "displayName": "94113",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "79d880a8-ce5d-4c83-b8eb-f2c0c88fe130",
        "instanceId": 95114,
        "authEntityId": 95114,
        "displayName": "naming-services",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
          }
        ]
      }
    },
    {
      "id": "6f9b49b2-ccfb-4fa0-b66c-f9fe569932e2",
      "instanceId": 87092,
      "authEntityId": 87092,
      "displayName": "87092",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219159,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219159,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "b0f4fb6b-e679-49c4-9880-2f99bbf8174e",
        "instanceId": 93093,
        "authEntityId": 93093,
        "displayName": "93093",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "6fb41be4-606f-4ffd-bd97-17b1ae5d09d4",
            "instanceId": 88093,
            "authEntityId": 88093,
            "displayName": "88093",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "68060e0d-9b9b-4041-91be-aa1407610973",
            "instanceId": 88094,
            "authEntityId": 88094,
            "displayName": "88094",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "e26a9950-c2b9-42f0-a693-cba481220783",
                "instanceId": 94094,
                "authEntityId": 94094,
                "displayName": "94094",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "51f8edcb-3e64-472a-9b97-e4e422476ef9",
        "instanceId": 95095,
        "authEntityId": 95095,
        "displayName": "authentication-services",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
          }
        ]
      }
    },
    {
      "id": "846338a7-6369-4239-adb1-d5c8d46ac406",
      "instanceId": 87096,
      "authEntityId": 87096,
      "displayName": "87096",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219320,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219320,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "4e6319e1-9d3f-4395-a091-fa4d0c988203",
        "instanceId": 93097,
        "authEntityId": 93097,
        "displayName": "93097",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "23b79bbf-bb52-428d-ba09-9cf842c7c70c",
            "instanceId": 88101,
            "authEntityId": 88101,
            "displayName": "88101",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "4c892d83-4fd5-410f-9548-e35f001852c5",
            "instanceId": 88102,
            "authEntityId": 88102,
            "displayName": "88102",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "6cdac08e-c98a-41bf-bad6-e11cc3ec7190",
                "instanceId": 94098,
                "authEntityId": 94098,
                "displayName": "94098",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "92d4d827-9a8e-4f49-adf4-2fed60f6ae87",
        "instanceId": 95099,
        "authEntityId": 95099,
        "displayName": "consumer-file-sharing",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
          }
        ]
      }
    },
    {
      "id": "8f880c0a-6b32-43d3-83cf-3bae56605e73",
      "instanceId": 87095,
      "authEntityId": 87095,
      "displayName": "87095",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219265,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219265,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "a28a6e6c-3f6e-4046-93a1-287753015417",
        "instanceId": 93096,
        "authEntityId": 93096,
        "displayName": "93096",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "b4bd1d55-512a-45ba-b749-1d8416a1feac",
            "instanceId": 88099,
            "authEntityId": 88099,
            "displayName": "88099",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "2f86e3c3-3ef8-4860-ae10-e73b591bea18",
            "instanceId": 88100,
            "authEntityId": 88100,
            "displayName": "88100",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "a8a7606c-9363-4d99-91b6-76825cba4794",
                "instanceId": 94097,
                "authEntityId": 94097,
                "displayName": "94097",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "c860d0a0-998b-47d1-bed4-afc6f2f9f83e",
        "instanceId": 95098,
        "authEntityId": 95098,
        "displayName": "consumer-browsing",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
          }
        ]
      }
    },
    {
      "id": "9aa2ff7a-4233-4fce-9eb0-7cf4c20114f1",
      "instanceId": 87109,
      "authEntityId": 87109,
      "displayName": "87109",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219669,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219669,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "c42bbe42-e0e5-4e12-b54f-13f8524b1adb",
        "instanceId": 93110,
        "authEntityId": 93110,
        "displayName": "93110",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "ad26142a-6485-4433-9672-a8353f822f5e",
            "instanceId": 88128,
            "authEntityId": 88128,
            "displayName": "88128",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "91fcc50a-7109-4b47-b65b-9d49548aab24",
                "instanceId": 94111,
                "authEntityId": 94111,
                "displayName": "94111",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          },
          {
            "id": "499c1276-df9d-4cf5-8b41-6ac0925f139a",
            "instanceId": 88127,
            "authEntityId": 88127,
            "displayName": "88127",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "b5192fbb-fa08-4ab1-979e-fe4cef1118b2",
        "instanceId": 95112,
        "authEntityId": 95112,
        "displayName": "tunneling",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
          }
        ]
      }
    },
    {
      "id": "9eecdc74-e389-44a6-91e2-6bfc2c6935e8",
      "instanceId": 87118,
      "authEntityId": 87118,
      "displayName": "87118",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219953,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219953,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "4332b9b5-908e-4552-ad2e-1e82052942dd",
        "instanceId": 93119,
        "authEntityId": 93119,
        "displayName": "93119",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "99f6dd2a-81c0-438f-bba0-e43e8b4140a4",
            "instanceId": 88145,
            "authEntityId": 88145,
            "displayName": "88145",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          },
          {
            "id": "f0b1b095-8b5f-477a-b8f8-bb40dfc7faef",
            "instanceId": 88146,
            "authEntityId": 88146,
            "displayName": "88146",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "119332e8-8e8c-45b9-951d-9aa23c1b4f56",
                "instanceId": 94120,
                "authEntityId": 94120,
                "displayName": "94120",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "77d8dac4-da17-4bb5-8d92-342aad946568",
        "instanceId": 95121,
        "authEntityId": 95121,
        "displayName": "software-updates",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
          }
        ]
      }
    },
    {
      "id": "a25ef946-d218-4434-9a00-e70c7a9f159c",
      "instanceId": 87108,
      "authEntityId": 87108,
      "displayName": "87108",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219644,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219644,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "806d1b4a-0ed4-4470-a372-1ea61946b667",
        "instanceId": 93109,
        "authEntityId": 93109,
        "displayName": "93109",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "57b3d995-3e6a-42e5-bb5c-8fcac3f26080",
            "instanceId": 88125,
            "authEntityId": 88125,
            "displayName": "88125",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "DEFAULT"
          },
          {
            "id": "a535e22f-004a-47e6-b4ae-6e8dcd924201",
            "instanceId": 88126,
            "authEntityId": 88126,
            "displayName": "88126",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "f81c38b6-047d-4e97-ae0a-f3c2c4d82498",
                "instanceId": 94110,
                "authEntityId": 94110,
                "displayName": "94110",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "SILVER",
                "secondaryPath": "BRONZE",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "695a3b11-1f27-4798-a780-d8d01074566b",
        "instanceId": 95111,
        "authEntityId": 95111,
        "displayName": "general-misc",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
          }
        ]
      }
    },
    {
      "id": "a2ca3119-5f60-4901-8df1-3b86e602f266",
      "instanceId": 87116,
      "authEntityId": 87116,
      "displayName": "87116",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219932,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219932,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "3af23f14-c602-40b4-92c4-b7ff57403441",
        "instanceId": 93117,
        "authEntityId": 93117,
        "displayName": "93117",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "e4b97772-66ee-44a4-abbc-d76a9b5cb9ff",
            "instanceId": 88141,
            "authEntityId": 88141,
            "displayName": "88141",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "86593453-147f-4ac9-a3e4-4b383d1892a7",
            "instanceId": 88142,
            "authEntityId": 88142,
            "displayName": "88142",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "893978cf-f6fc-4d55-a6e8-0f1dadc0980d",
                "instanceId": 94118,
                "authEntityId": 94118,
                "displayName": "94118",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "9c649b81-aa0c-4e1b-8b9e-70916b1ad473",
        "instanceId": 95119,
        "authEntityId": 95119,
        "displayName": "signaling",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "a3243a74-3864-4041-a927-0201030da17c"
          }
        ]
      }
    },
    {
      "id": "aa848d8b-3e06-4bf4-8c9b-36f78f578290",
      "instanceId": 87102,
      "authEntityId": 87102,
      "displayName": "87102",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219466,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219466,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "791955de-f89d-49bf-8b9c-9dbd675de859",
        "instanceId": 93103,
        "authEntityId": 93103,
        "displayName": "93103",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "38c3bc74-3a0c-4606-af23-fdfa44c34657",
            "instanceId": 88113,
            "authEntityId": 88113,
            "displayName": "88113",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "d93514d1-4c46-4278-a7a7-f9016c42c51c",
            "instanceId": 88114,
            "authEntityId": 88114,
            "displayName": "88114",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "527300c0-bd60-4a72-a1ca-8c0747b1fbdf",
                "instanceId": 94104,
                "authEntityId": 94104,
                "displayName": "94104",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "a61be43f-1e11-4c7e-9bdc-7b4b0d9c8c22",
        "instanceId": 95105,
        "authEntityId": 95105,
        "displayName": "desktop-virtualization-apps",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
          }
        ]
      }
    },
    {
      "id": "c3234dd7-bcd3-42a7-a890-dd940e3b047f",
      "instanceId": 87104,
      "authEntityId": 87104,
      "displayName": "87104",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219526,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219526,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "d4257acc-ff70-42f6-85fb-ce93a03ab773",
        "instanceId": 93105,
        "authEntityId": 93105,
        "displayName": "93105",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "4a652506-44ac-4167-abac-35126a7f7817",
            "instanceId": 88117,
            "authEntityId": 88117,
            "displayName": "88117",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "a6df4fb6-521f-480c-b4d7-f25f61bd951e",
            "instanceId": 88118,
            "authEntityId": 88118,
            "displayName": "88118",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "316d55c6-f70a-4150-a6fd-be9ae17639eb",
                "instanceId": 94106,
                "authEntityId": 94106,
                "displayName": "94106",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "fb47e6e1-168c-4be8-ba84-025f0fd8b407",
        "instanceId": 95107,
        "authEntityId": 95107,
        "displayName": "enterprise-ipc",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
          }
        ]
      }
    },
    {
      "id": "d8c5cfda-c2d0-4920-9e96-102ed86b3ad4",
      "instanceId": 87098,
      "authEntityId": 87098,
      "displayName": "87098",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219357,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219357,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "309b7696-39b5-4392-8ce0-32fbaf43a287",
        "instanceId": 93099,
        "authEntityId": 93099,
        "displayName": "93099",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "1f03a545-0a36-4e7b-93e0-15f2aa60c1d0",
            "instanceId": 88105,
            "authEntityId": 88105,
            "displayName": "88105",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_IRRELEVANT"
          },
          {
            "id": "e93ffbc2-9a68-4ede-ac0c-b1fd24b8150c",
            "instanceId": 88106,
            "authEntityId": 88106,
            "displayName": "88106",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "4ebbe18b-24e8-470c-84b0-d2bc3165111b",
                "instanceId": 94100,
                "authEntityId": 94100,
                "displayName": "94100",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": false,
                "jitter": 50,
                "latency": 200,
                "loss": 1,
                "primaryPath": "BRONZE",
                "secondaryPath": "SILVER",
                "tertiaryPath": "GOLD",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "5a3e69a2-8997-4f30-a13c-66c5ecfe7620",
        "instanceId": 95101,
        "authEntityId": 95101,
        "displayName": "consumer-media",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
          }
        ]
      }
    },
    {
      "id": "db3e9b84-9e8c-4446-b27b-89fb9391b379",
      "instanceId": 87110,
      "authEntityId": 87110,
      "displayName": "87110",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219730,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219730,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "e0acb52c-0e11-49bf-b05f-7c9207719ed8",
        "instanceId": 93111,
        "authEntityId": 93111,
        "displayName": "93111",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "d152010d-f0a8-404d-93e3-786526bfd4ac",
            "instanceId": 88129,
            "authEntityId": 88129,
            "displayName": "88129",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "5dd5bd69-bf64-4df5-bbab-291c41fc639d",
            "instanceId": 88130,
            "authEntityId": 88130,
            "displayName": "88130",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "b310f1d1-f6fc-4d82-a888-bcf6a44c429f",
                "instanceId": 94112,
                "authEntityId": 94112,
                "displayName": "94112",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 175,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "d01f78ad-fb08-4e65-9078-62b96c292bfc",
        "instanceId": 95113,
        "authEntityId": 95113,
        "displayName": "local-services",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "e4e23452-2fdf-4d92-88d8-a7d24426ab72"
          }
        ]
      }
    },
    {
      "id": "f0683c4c-741d-4715-9f29-0081ebdd9da5",
      "instanceId": 87094,
      "authEntityId": 87094,
      "displayName": "87094",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219251,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219251,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "cd0d15a8-7812-44fc-af0c-ae2e5aa41a0f",
        "instanceId": 93095,
        "authEntityId": 93095,
        "displayName": "93095",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "ff2498dd-640f-43a0-a048-0ed16d5d870a",
            "instanceId": 88097,
            "authEntityId": 88097,
            "displayName": "88097",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          },
          {
            "id": "9c0b694c-e173-49a0-8b19-331d86a06f9a",
            "instanceId": 88098,
            "authEntityId": 88098,
            "displayName": "88098",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "c83b511b-05ec-4264-88ec-d629314fde19",
                "instanceId": 94096,
                "authEntityId": 94096,
                "displayName": "94096",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 35,
                "latency": 150,
                "loss": 1,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "becd170d-c5a1-4ecd-8611-5043e596af7b",
        "instanceId": 95097,
        "authEntityId": 95097,
        "displayName": "collaboration-apps",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
          }
        ]
      }
    },
    {
      "id": "fb4c107c-531a-4e58-9f6d-5c1edaecb319",
      "instanceId": 87093,
      "authEntityId": 87093,
      "displayName": "87093",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219227,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219227,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "c341f2f1-aa7e-4529-b990-284cbd1aab5a",
        "instanceId": 93094,
        "authEntityId": 93094,
        "displayName": "93094",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "3bc8af73-01e6-44c2-b4ea-1257f5438197",
            "instanceId": 88096,
            "authEntityId": 88096,
            "displayName": "88096",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "784932e7-2f75-44c7-b6e5-a81c7aa38d23",
                "instanceId": 94095,
                "authEntityId": 94095,
                "displayName": "94095",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          },
          {
            "id": "94286ed5-f704-4e64-92aa-1ed976292c77",
            "instanceId": 88095,
            "authEntityId": 88095,
            "displayName": "88095",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "ea7a7518-215b-4a61-8d19-bd4690c5658f",
        "instanceId": 95096,
        "authEntityId": 95096,
        "displayName": "backup-and-storage",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
          }
        ]
      }
    },
    {
      "id": "fc6f1d0b-b970-4bfe-8204-c55305195f8b",
      "instanceId": 87117,
      "authEntityId": 87117,
      "displayName": "87117",
      "authEntityClass": -955578222,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517971219941,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1517971219941,
      "name": "Application-set-default-policy",
      "namespace": "policy:application:",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "policy",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "deletePolicyStatus": "NONE",
      "internal": true,
      "isEnabled": false,
      "isScopeStale": false,
      "priority": 100,
      "contractList": [

      ],
      "exclusiveContract": {
        "id": "e2139352-c509-426b-94d5-7103fcada269",
        "instanceId": 93118,
        "authEntityId": 93118,
        "displayName": "93118",
        "authEntityClass": -651574652,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "clause": [
          {
            "id": "4f718efb-2828-44c6-8e3b-083612269531",
            "instanceId": 88144,
            "authEntityId": 88144,
            "displayName": "88144",
            "authEntityClass": -2109301119,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "PATH_MGMT",
            "isAllVnc": false,
            "pathClauseElement": [
              {
                "id": "b90d5053-cd0d-48a0-b280-84b2d5507c59",
                "instanceId": 94119,
                "authEntityId": 94119,
                "displayName": "94119",
                "authEntityClass": 876141189,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "isSLAProtection": true,
                "jitter": 50,
                "latency": 225,
                "loss": 5,
                "primaryPath": "GOLD",
                "secondaryPath": "SILVER",
                "tertiaryPath": "BRONZE",
                "virtualNetworkContext": [

                ]
              }
            ]
          },
          {
            "id": "666be36d-bbe1-4abf-9757-91ee382f49ca",
            "instanceId": 88143,
            "authEntityId": 88143,
            "displayName": "88143",
            "authEntityClass": 1948742693,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "priority": 1,
            "type": "BUSINESS_RELEVANCE",
            "relevanceLevel": "BUSINESS_RELEVANT"
          }
        ]
      },
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "producer": {
        "id": "16e021ab-31ab-42a7-91f2-1c987e54a2c0",
        "instanceId": 95120,
        "authEntityId": 95120,
        "displayName": "software-development-tools",
        "authEntityClass": 1272677816,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "scalableGroup": [
          {
            "idRef": "fdbaf614-da09-4ba2-89bb-d1ca9e379597"
          }
        ]
      }
    }
  ],

  "Application_Global_Template":   {
      "type": "policy",
      "name": "PolicyAU_global_policy_configuration",
      "exclusiveContract": {
        "clause": [
          {
            "type": "APPLICATION_POLICY_KNOBS",
            "hostTrackingEnabled": false
          }
        ]
      },
      "isEnabled": true,
      "priority": 100,
      "policyScope": "PolicyAU",
      "advancedPolicyScope": {
        "name": "PolicyAU",
        "advancedPolicyScopeElement": [
          {
            "groupId": [
              "3c21067d-5fc7-5d98-ab80-e6461c4c0818"
            ]
          }
        ]
      }
    }
});
