define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/site/AssuranceSiteTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/connectivity_domain/AssuranceCDTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/connectivity_domain/FabricTransit',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/modelExtended/APModelExtended',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/modelExtended/WirelessController',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/modelExtended/SelectAPWlc',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/AssuranceNetworkDeviceDetailTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/ConnectivityDomainTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientEventHealthTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceMetricUtility',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceMetricData1',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueTimings',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/modelExtended/RogueManagementTemplate'
 ], function(SimLokiDatabaseActions, AssuranceSiteTemplate, AssuranceCDTemplate, FabricTransit, APModelExtended,
             WirelessController, SelectAPWlc,AssuranceNetworkDeviceDetailTemplate, DomainTemplate, ClientEventHealthTemplate, 
             SimAssuranceMetricUtility, UtilityFunctions, SimAssuranceMetricData1, HostIssueTimings, RogueManagementTemplate) {

  function getDeviceGroups(urlAction){
    //  var recordObj = SimLokiDatabaseActions.getAll('ConnectivityDomain');
    var siteDomains =[], i;
    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'domainType': "FABRIC_LAN"})
      var deviceGroups = [], i;
      for(i = 0, len = recordObj.length; i < len; i++){
          var tdeviceGroup =  JSON.parse(JSON.stringify( AssuranceCDTemplate.Assurance_CD_Template));
        //  tdeviceGroup.id = recordObj[i].isDefault ? 'UNKNOWN-FABRIC-DEVICE' : recordObj[i].name;
          var domainName= recordObj[i].name
             domainName= domainName.split('_').join(' ');
          tdeviceGroup.id = domainName;

          tdeviceGroup.properties.groupName[0].value = recordObj[i].name;
          deviceGroups.push(tdeviceGroup);
          }
      return deviceGroups;
  }
  function getAllDeviceGroups(urlAction){
    //  var recordObj = SimLokiDatabaseActions.getAll('ConnectivityDomain');
    var siteDomains =[], i;
    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', { 'domainType':{ '$in':['FABRIC_LAN','FABRIC_SITE'] }});
      var deviceGroups = [], i;
      for(i = 0, len = recordObj.length; i < len; i++){
          var tdeviceGroup =  JSON.parse(JSON.stringify( AssuranceCDTemplate.Assurance_CD_Template));
        //  tdeviceGroup.id = recordObj[i].isDefault ? 'UNKNOWN-FABRIC-DEVICE' : recordObj[i].name;
          tdeviceGroup.id = recordObj[i].name;
          tdeviceGroup.properties.groupName[0].value = recordObj[i].name;
          if(recordObj[i].domainType=="FABRIC_SITE") {
           tdeviceGroup.properties.groupType[0].value = "FABRIC_SITE";
          } else {
             var domainName= recordObj[i].name
             domainName= domainName.split('_').join(' ');
             tdeviceGroup.id = domainName;
          }
          deviceGroups.push(tdeviceGroup);
          }
      return deviceGroups;
  }
  function getDeviceGroupSite(urlAction){
        var tquery = urlAction.filter['query'];
        tquery = tquery.replace(/%27/g, '');
        var query=tquery.substr(tquery.indexOf('deviceGroups'),tquery.length)
        var domainName=query.substring(query.indexOf('(')+2,query.indexOf(')')-1)
          domainName= domainName.split(' ').join('_');
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': domainName})
        var i;
        var result=[]
        var siteDomains = record[0].siteSpecificDomain;

        for(i = 0, len = siteDomains.length; i < len; i++){

            var tsiteDomain =  JSON.parse(JSON.stringify( DomainTemplate.Assurance_Domain_Filter_Template));
            var refId = siteDomains[i].idRef;
            var siterecord = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': refId});
            tsiteDomain.inV =record[0].name;
            tsiteDomain.outV =siterecord[0].name;
            tsiteDomain.id = siterecord[0].name + ":memberof:" + record[0].name;
            result.push(tsiteDomain);
        }
       return result;
   }


  function getSiteDetailsWithMetrics(urlAction) {
      var recordObj = SimLokiDatabaseActions.getAll('site');
      var siteGroupData = [];
      for(var i = 0, len = recordObj.length; i < len; i++){
          var tSite =  JSON.parse(JSON.stringify( AssuranceSiteTemplate.Assurance_Site_Template ));
          var siteType ="";
          for(var j=0;j<recordObj[i].additionalInfo.length ;j++)  {
              if(recordObj[i].additionalInfo[j].nameSpace== "Location") {
                  siteType =recordObj[i].additionalInfo[j].attributes.type;

                  if(siteType == 'building'){
                      tSite.properties.latitude[0].value = recordObj[i].additionalInfo[j].attributes.latitude;
                      tSite.properties.longitude[0].value = recordObj[i].additionalInfo[j].attributes.longitude;
                  }
                  else {
                      delete tSite.properties.latitude;
                      delete tSite.properties.longitude;
                  }
              }
          }

          tSite.id = recordObj[i].id;
          tSite.properties.siteHierarchy[0].value = recordObj[i].groupNameHierarchy;
          tSite.properties.name[0].value  = recordObj[i].name;
          tSite.properties.siteType[0].value = siteType;
          siteGroupData.push(tSite);
      }

      var result = [];
      var metricsData= []

      siteGroupData.forEach(function(value){
          metricsData.push({"metrics":[100],"site":value});
      });
      result.push(siteGroupData);
      result.push(metricsData);

      return result;
  }

  function getAccessPointDetail(urlAction) {
      var t = urlAction.restPayload;
      t = t[0];
      t = t.replace(/'/g, '');
      t = t.replace(/"/g, '');

      var networkId = t.replace('networkDevices(','');
      networkId = networkId.replace(').memberOfAPG()','');
      networkId = networkId.trim();

      var t1 = JSON.parse(JSON.stringify(APModelExtended.Template));
      t1[0][0].id = networkId;
      t1[0][0].outV = networkId;
      return t1;
  }

  function getWLCDetail(urlAction) {
     var tquery = urlAction.filter['query'];
         tquery = tquery.replace(/%27/g, '');
        var query=tquery.substr(tquery.indexOf('networkDevices'),tquery.length)
        var tId=query.substring(query.indexOf('(')+2,query.indexOf(')')-1)
        var apDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : tId });
        var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':apDevice[0].associatedWlcIp} );
        var result=[];
        if(tquery.indexOf('select(\'ap\',\'wlc\')') >= 0) {
          var jsonObj=JSON.parse(JSON.stringify(SelectAPWlc.Template));
         if(apDevice && apDevice.length){
            jsonObj[0].ap.id = apDevice[0].id;
            jsonObj[0].ap.timestamp = new Date().getTime();
            jsonObj[0].ap.properties.nwDeviceRole[0].value = apDevice[0].role;
            jsonObj[0].ap.properties.managementIpAddr[0].value = apDevice[0].managementIpAddress;
            jsonObj[0].ap.properties.serialNumber[0].value = apDevice[0].serialNumber;
            jsonObj[0].ap.properties.nwDeviceName[0].value = apDevice[0].hostname;
            jsonObj[0].ap.properties.opState[0].value = "4";
            jsonObj[0].ap.properties.platformId[0].value = apDevice[0].platformId;
            jsonObj[0].ap.properties.nwDeviceId[0].value = apDevice[0].id;
            jsonObj[0].ap.properties.upTime[0].value = apDevice[0].upTime;
            jsonObj[0].ap.properties.nwDeviceFamily[0].value = apDevice[0].family;
            jsonObj[0].ap.properties.macAddress[0].value = apDevice[0].macAddress;
            jsonObj[0].ap.properties.softwareVersion[0].value = apDevice[0].softwareVersion;
            jsonObj[0].ap.properties.nwDeviceType[0].value = apDevice[0].type;
           }
           if(wlcDevice && wlcDevice.length){
            jsonObj[0].wlc.id = wlcDevice[0].id;
            jsonObj[0].wlc.timestamp = new Date().getTime();
            jsonObj[0].wlc.properties.nwDeviceRole[0].value = wlcDevice[0].role;
            jsonObj[0].wlc.properties.clientCount[0].value=wlcDevice[0].assuranceHealthScore;
            jsonObj[0].wlc.properties.managementIpAddr[0].value = wlcDevice[0].managementIpAddress;
            jsonObj[0].wlc.properties.nwDeviceFamily[0].value = wlcDevice[0].family;
            jsonObj[0].wlc.properties.macAddress[0].value = wlcDevice[0].macAddress;
            jsonObj[0].wlc.properties.nwDeviceName[0].value = wlcDevice[0].hostname;
            jsonObj[0].wlc.properties.platformId[0].value = wlcDevice[0].platformId;
            jsonObj[0].wlc.properties.softwareVersion[0].value = wlcDevice[0].softwareVersion;
            jsonObj[0].wlc.properties.serialNumber[0].value = wlcDevice[0].serialNumber;
            jsonObj[0].wlc.properties.nwDeviceId[0].value = wlcDevice[0].id;
            jsonObj[0].wlc.properties.nwDeviceType[0].value = wlcDevice[0].type;
           }
           return jsonObj;
        }
        else {
            if(wlcDevice && wlcDevice.length){
               var jsonObj=[];
               var obj =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.Assurance_NetworkDevice_Detail_Template ));
               obj.id = wlcDevice[0].id;
               obj.timestamp = new Date().getTime();
               obj.properties.nwDeviceRole[0].value = wlcDevice[0].role;
               obj.properties.managementIpAddr[0].value = wlcDevice[0].managementIpAddress;
               obj.properties.nwDeviceFamily[0].value = wlcDevice[0].family;
               obj.properties.macAddress[0].value = wlcDevice[0].macAddress;
               obj.properties.nwDeviceName[0].value = wlcDevice[0].hostname;
               obj.properties.platformId[0].value = wlcDevice[0].platformId;
               obj.properties.softwareVersion[0].value = wlcDevice[0].softwareVersion;
               obj.properties.serialNumber[0].value = wlcDevice[0].serialNumber;
               obj.properties.nwDeviceId[0].value = wlcDevice[0].id;
               obj.properties.nwDeviceType[0].value = wlcDevice[0].type;
               obj.properties.upTime[0].value = wlcDevice[0].upTime;
               obj.properties.connectedTime[0].value = wlcDevice[0].upTime;
               jsonObj.push(obj);
               return jsonObj;
            }
          }
    return [];
  }

 return {

      init: function(){
      },

      processGet: function(urlAction) {
           var query = urlAction.filter['query'];
           var query1 = urlAction.filter['q'];
           if(query != undefined) {
               if(query == 'deviceGroups()'){
                    return getAllDeviceGroups(urlAction);
               }else if( query.indexOf('deviceGroups()') >= 0) {
                     if(query.indexOf('FABRIC_TRANSIT') >= 0) {
                        return getDeviceGroups(urlAction);
                     }
               }else if( query.indexOf('deviceGroups(') >= 0){
                     if(query.indexOf('memberOf(') >= 0) {
                        return getDeviceGroupSite(urlAction);
                     }else{
                        return [];
                     }
               }
               if(query.indexOf("hasLabel('Site')") >= 0 && query.indexOf("hasId('within'") >= 0 &&
                    query.indexOf('toList()') > -1){
                    let ids = query.split('.')[3].split(',').map(e => e.replace(/['')]/g,""));
                    let sites = SimLokiDatabaseActions.getAll('site');
                    let recordObj = []
                    ids.map(i => {
                            sites.filter(e => {
                                if(e.id === i){
                                    recordObj.push(e)
                                }
                            })
                    })
                    var siteGroupData = [];
                    for(var i = 0, len = recordObj.length; i < len; i++){
                          var tSite =  JSON.parse(JSON.stringify( AssuranceSiteTemplate.Assurance_Site_Template ));
                          var siteType ="";
                          var time = UtilityFunctions.getTimeStamp()
                          for(var j=0;j<recordObj[i].additionalInfo.length ;j++)  {
                              if(recordObj[i].additionalInfo[j].nameSpace== "Location") {
                                  siteType =recordObj[i].additionalInfo[j].attributes.type;
                                  if(siteType == 'building'){
                                      tSite.properties.latitude[0].value = recordObj[i].additionalInfo[j].attributes.latitude;
                                      tSite.properties.longitude[0].value = recordObj[i].additionalInfo[j].attributes.longitude;
                                      tSite.id = recordObj[i].id;
                                      tSite.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(time)
                                      tSite.properties.siteHierarchy[0].value = recordObj[i].groupNameHierarchy;
                                      tSite.properties.name[0].value  = recordObj[i].name;
                                      tSite.properties.siteType[0].value = siteType;
                                      siteGroupData.push(tSite);
                                  }
                              }
                          }
                    }
                    return siteGroupData;
               }
               if( query.indexOf('belongToWLC(out)') >= 0) {
                    return getWLCDetail(urlAction);
               }
               var indx = query.indexOf("assignToNwIf()");
               var indx1 = query.indexOf("nwDeviceEndPoints()");
                    if(indx >= 0 && indx1 >= 0) {
                        return [];
                    }
               indx1 = query.indexOf("networkDevices(");
               indx = query.indexOf('networkDevices()');
               if(indx ==-1 && indx1 >= -1 && query.indexOf('BondTo') >= 0 && query.indexOf('NwDeviceInterface') >= 0) {
                    var isRadioOnly = false;
                     if(urlAction.url.indexOf("has('name','radio')") >-1) {
                        isRadioOnly = true;
                     }
                    return SimAssuranceMetricData1.getAPDeviceInterfaces(isRadioOnly);
               }
           } else if(query1 != undefined) {
               if(query1.indexOf("hasLabel('NetworkDevice')")>-1 && query1.indexOf("has('~bulktraverse'")>-1 && 
                query1.indexOf("hasLabel('NwDeviceInterface'")>-1 && query1.indexOf('AdjacentToNwIf')>-1) {
                    var records = SimLokiDatabaseActions.getAll('network-device');
                    var result = [{}];
                    records.forEach(device => {
                        result[0][device.id]=0;
                    });
                    return result;
                }
           }else if(urlAction.service.indexOf('rogue-service') >-1){
               if(urlAction.service.indexOf('allowedlistall') >-1) {
                    var accessDeviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Unified AP"});
                    var recordObj=[];
                       for(var i = 0; i < accessDeviceData.length; i++) {
                           var count = UtilityFunctions.getRandomIntForRangeValues(20000,50000);
                           var obj = {"macAddress":accessDeviceData[i].name,"lastModified":accessDeviceData[i].lastUpdateTime/1000-count};
                           recordObj.push(obj);
                        }
                 return recordObj;
               }
               if(urlAction.service.indexOf('allowedlist') >-1 && urlAction.action.count == true){
                   return {"response":0,"version":"1.0"}
                }
               if(urlAction.service.indexOf('getwhitelist')>-1){
                   var recordObj = SimLokiDatabaseActions.getAll('rogue-whitelist') 
                //    var result = [{"category": 1,"macaddress": "00:3A:99:EC:32:E0"}];
                   return recordObj
               }else if(urlAction.service.indexOf('iswhitelisted')>-1){
                   var macaddress =urlAction.service[3]
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-whitelist',{'macaddress':macaddress});
                   var recordObj1 = SimLokiDatabaseActions.getAll('rogue-whitelist')
                   if (recordObj.length !=0){
                    var result = {"isPresent": true,"whitelistSize": recordObj1.length};
                   }else{
                    var result = {"isPresent": false,"whitelistSize": 1};
                   }
                   return result;
               }else if(urlAction.service.indexOf('getroguecontainment')>-1){
                var result = [];
                return result;
               }else if (urlAction.service.indexOf('autocomplete') >-1 && urlAction.service.indexOf('threattype') >-1){
                   var tJson = SimLokiDatabaseActions.getAll('rogue-threattype')
                   return {'response':tJson}
               }else if(urlAction.service.indexOf('getCustomRuleList')>-1){
                   if(urlAction.filter.indexOf('deletedRule')>-1){
                       var result = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.deleteData.isDeleted':true}); 
                   }else if(urlAction.filter['isRuleDef'] == "false"){
                       var tJson = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.deleteData.isDeleted':false});
                       var result = []
                       for(i=0; i < tJson.length; i++) {
                           var tempObj = {"ruleDefinition":{"ruleName":tJson[i].rule.ruleDefinition.ruleName}}
                           result.push(tempObj)
                       }
                       return result
                   }else if(urlAction.filter['isRuleDef'] == "true" && urlAction.filter['associated_profile_count'] == "true"
                    && urlAction.filter.indexOf("ruleName") >-1){
                        var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':urlAction.filter['ruleName']});
                        // var result = []
                        // let ruleProfile_count = 0 //need to review
                        // tObj[0].ruleProfile_count
                        // result.push(tObj[0].rule)
                        tObj[0].ruleProfile_count = 0
                        return tObj
                   }
                   else{
                       var result = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.deleteData.isDeleted':false});
                   }
                   return result;
               }else if(urlAction.service.indexOf('getRuleProfileList')>-1){
                   if(urlAction.filter.indexOf('ruleName')>-1){
                       var result = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule-profile',{'rules.ruleName':urlAction.filter['ruleName']});
                   }else{
                       var result = SimLokiDatabaseActions.getAll('rogue-rule-profile')
                   }    
                   return result;
               }else if(urlAction.service.indexOf('getVendorsProfile')>-1){
                   var result = SimLokiDatabaseActions.getAll('rogue-allow-rule');
                   return result;
               }else if(urlAction.service.indexOf('IsValidRuleName') >-1){
                   var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':urlAction.service[3]})
                   if(tObj.length<=0){
                       return {"status":"the rule name "+urlAction.service[3]+ " is valid"}
                   }else{
                       return {"errorMessage":"{\"errorcode\":\"3503\",\"errorstr\":\"The case insensitive rule name is present in the system. Please use another rule name.\",\"translation\":[]}"}
                   }
               }else if(urlAction.service.indexOf('IsValidRuleName') >-1){
                   var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':urlAction.service[3]})
                   if(tObj.length<=0){
                       return {"status":"the rule name "+urlAction.service[3]+ " is valid"}
                   }else{
                       return {"errorMessage":"{\"errorcode\":\"3503\",\"errorstr\":\"The case insensitive rule name is present in the system. Please use another rule name.\",\"translation\":[]}"}
                   }
               }else if(urlAction.service.indexOf('getRule')>-1){
                   var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':urlAction.service[3]});
                   return tObj[0].rule
               }
               // Rogue Rule Profile
               else if(urlAction.service.indexOf('IsValidRuleProfile') >-1){
                   var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule-profile',{'profileName':urlAction.service[3]})
                   if(tObj.length<=0){
                       return {"status":"the rule name "+urlAction.service[3]+ " is valid"}
                   }else{
                       return {"errorMessage":"{\"errorcode\":\"3503\",\"errorstr\":\"The case insensitive rule name is present in the system. Please use another rule name.\",\"translation\":[]}"}
                   }
               }else if(urlAction.service.indexOf('getRuleProfile') >-1){
                   var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule-profile',{'profileName':urlAction.service[3]})
                   return tObj[0]
               }else if(urlAction.service.indexOf('getSiteIdForProfile') >-1){
                   return [{"siteID":"5c7c412e-8d5e-4c28-aea0-d616f0ea40eb"}]
               }
               // Rogue Allowed Rules
               else if(urlAction.service.indexOf('isValidVendorProfile')>-1){
                   return {"errorCode":"SUCCESS","message":"Successfully validated","threatLevel":urlAction.service[4],"vendorName":urlAction.service[3]}
               }
               else if(urlAction.service.indexOf('isallowedlisted')>-1) {
                   return {"isPresent":false,"whitelistSize":0}
               }
               else if(urlAction.service.indexOf('report')>-1 && urlAction.service.indexOf('counts')>-1) {
                   return {"response":{"rogue":73,"awips":0,"containment":0,"client":6},"version":"1.0"}
                }
                else if(urlAction.service.indexOf('getreport')>-1 && urlAction.service.indexOf('chart')>-1) {
                    return {"response":RogueManagementTemplate.GetReport_Chart_Template,"version":"1.0"}
                }
                else if (urlAction.service.indexOf('rogueapconfig') >-1 && urlAction.service.indexOf('limitExceededWlcIps') >-1){
                    return {"response":[],"version":"1.0"}
                }else if(urlAction.service.indexOf('site') >-1 && urlAction.service.indexOf('cloud') >-1){
                    var recObj = SimLokiDatabaseActions.getAll('site')
                    var data = []
                    for(var i=0;i<recObj.length;i++){
                        var tempObj={}
                        tempObj['groupNameHierarchy'] = recObj[i].groupHierarchy
                        tempObj['siteId'] = recObj[i].id
                        tempObj['siteName'] = recObj[i].name
                        tempObj['siteParentId'] = recObj[i].parentId
                        tempObj['siteType'] = recObj[i].additionalInfo[1].attributes.type
                        data.push(tempObj)
                    }
                    return {'response': data}
                }
                else if(urlAction.service.indexOf('report')>-1 && urlAction.service.indexOf('getlocationaffected')>-1) {
                    var grouphierarchy = "Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"
                    var SiteRecordObj =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy': grouphierarchy});
                    var apDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{siteId:SiteRecordObj[0].id});
                    var tJson = RogueManagementTemplate.report_getlocationaffected_Template;
                    tJson.parentId = SiteRecordObj[0].parentId
                    tJson.child[0].siteName  = SiteRecordObj[0].name
                    tJson.child[0].siteId = SiteRecordObj[0].id
                    tJson.child[0].groupNameHierarchy = grouphierarchy
                    return {"response":[tJson],"version":"1.0"}
                }
                else if(urlAction.service.indexOf('getreport')>-1 && urlAction.service.indexOf('history')>-1) {
                    var startTime = urlAction.filter['startTime'];
                    var endTime = urlAction.filter['endTime']
                    var dt = new Date(startTime), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

                    var hours = UtilityFunctions.getHours(startTime-30*60000, endTime);
                    hours = Math.floor(hours);

                    if(tMinute > 55) {
                        changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
                        changedTime.setUTCMinutes(0);
                    } else {
                        changedTime = new Date( dt.setUTCMinutes(tMinute));
                    }
                    var tempObj = [];
                    for(var i=0, incrementBy = 0;i<hours;i++, incrementBy += 60){
                        var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.Report_History_Template[0]));
                        var curTime = UtilityFunctions.incrementDateByMinutes(changedTime, incrementBy);
                        var t = new Date(curTime)
                        tObj.timestamp = t.getTime()
                        
                        tempObj.push(tObj);
                    }
                    return {"response":tempObj,"version":"1.0"}
                }
                else if(urlAction.service.indexOf('getreport') >-1 && urlAction.service.indexOf('detail') >-1){
                    var recObj = SimLokiDatabaseActions.getAll('rogue-management');
                    var retObj = []
                    for(var i=0;i<recObj.length;i++){
                        var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.Report_Detail_Template));
                        tObj.id = recObj[i].id;
                        tObj.macAddress = recObj[i].properties.ro_macAddress[0].value;
                        tObj.lastUpdated = recObj[i].timestamp
                        // tObj.updatedTime = recObj[i].timestamp
                        tObj.vendor = recObj[i].properties.ro_vendor[0].value;
                        tObj.threatType = recObj[i].properties.ro_threatType[0].value;
                        tObj.threatTypeInt = recObj[i].properties.ro_strongestThreatScore[0].value;
                        tObj.threatLevel = recObj[i].properties.ro_threatLevel[0].value;
                        tObj.apName = recObj[i].properties.ro_strongestDetectingApName[0].value;
                        tObj.detectingAPMac = recObj[i].properties.ro_strongestDetectingApMacAddress[0].value;
                        tObj.siteId = recObj[i].properties.ro_strongestDetectingApSiteId[0].value
                        tObj.rssi = recObj[i].properties.ro_strongestRssi[0].value
                        tObj.ssid = recObj[i].properties.ro_threatSsid[0].value
                        tObj.siteHierarchyName = "Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"
                        //Need to review this code
                        // tObj.containment = recObj[i].properties[0].
                        // tObj.state = recObj[i].properties[0].
                        // tObj.totalClients = recObj[i].properties[0].
                        retObj.push(tObj)
                    }
                    return {'response':retObj,'totalCount':recObj.length,"version":"1.0"}
                }
        }
      },

      processPost: function(urlAction) {
          //xists('2018-01-10T03:38:40.759+0000','2018-01-25T03:38:40.759+0000').interfaces().has('macAddr','6C:19:C0:BE:C9:17').bondToEdIf(in).endDevices().modifications().where(values('hostVersion').like('iOS*')).count()
          var clienthealthAggregation = false, isRogue = false;
          var restPayload = urlAction.restPayload;
          if (urlAction.service.indexOf('rogue-service') >-1){
              if(urlAction.service.indexOf('createwhitelist') >-1){
                  for(i=0; i < restPayload.length; i++) {
                    var macaddress = restPayload[i].macaddress;
                    var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macaddress});
                    if(jsonObj.length !=0){
                        restPayload[i].oldThreatScore = jsonObj[0].properties.ro_strongestThreatScore[0].value; //adding bcz inserting old record so that when we revert we can read this value
                        jsonObj[0].properties.ro_strongestThreatScore[0].value = 4001;
                        SimLokiDatabaseActions.update('rogue-management', jsonObj);
                    }
                    SimLokiDatabaseActions.insert('rogue-whitelist', restPayload[i]);
                  }
                  return "Success"
              }else if(urlAction.service.indexOf('addRule') >-1){
                  var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':restPayload.ruleName});
                  if(recordObj.length == 0){
                      var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.AddRule_Tempalte[0]));
                      tObj.rule.ruleDefinition = restPayload;
                      if(restPayload.threatLevel = "high"){
                          tObj.rule.threatScore = Math.floor(18000+UtilityFunctions.getRandomIntForRangeValues(4,99));
                      }else if(restPayload.threatLevel = "Potential"){
                          tObj.rule.threatScore = Math.floor(6500+UtilityFunctions.getRandomIntForRangeValues(1,99));
                      }else if(restPayload.threatLevel = "Informational"){
                          tObj.rule.threatScore = Math.floor(4000+UtilityFunctions.getRandomIntForRangeValues(1,99));;
                      }
                      tObj.rule.lastUpdateInfo.timeStamp =Math.floor(UtilityFunctions.getTimeStamp())/1000;
                      var threadTemp = {}
                      threadTemp.value = tObj.rule.threatScore;
                      threadTemp.name = restPayload.ruleName;
                      threadTemp.label = restPayload.ruleName;
                      threadTemp.isCustom = true;
                      threadTemp.isDeleted = false;
                    //   tObj.ruleProfiles
                      SimLokiDatabaseActions.insert('rogue-rule', tObj);
                      SimLokiDatabaseActions.insert('rogue-threattype', threadTemp)
                    }
                  return {"status":"the rule name "+restPayload.ruleName+" is created successfully"}
              }else if(urlAction.service.indexOf('addRuleProfile') >-1){
                  var profileObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule-profile',{'profileName':restPayload.profileName});
                  if(profileObj.length == 0){
                      var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.AddProfileRule_Template[0]));
                      tObj.lastUpdateInfo.timeStamp =Math.floor(UtilityFunctions.getTimeStamp())/1000;
                      tObj.profileName = restPayload.profileName;
                      tObj.rules[0].ruleName = restPayload.rules[0].ruleName;

                      // updating rule profile in Rule
                      var ruleObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-rule',{'rule.ruleDefinition.ruleName':restPayload.rules[0].ruleName});
                      ruleObj[0].ruleProfiles.push({profileName: restPayload.profileName})
                      
                      SimLokiDatabaseActions.insert('rogue-rule-profile', tObj);
                      SimLokiDatabaseActions.update('rogue-rule', ruleObj);
                      return {"status":"the Rule Profile name "+restPayload.profileName+" is created successfully"}
                  }
              }else if(urlAction.service.indexOf('addRuleProfileSiteIdMap') >-1){
                  return {"status":"the rule profile name TestProfile1 is associated to siteId "+restPayload.siteId+" successfully"}
              }else if(urlAction.service.indexOf('validateAllowedVendorsProfile')>-1){
                  var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.validateAllowedVendorsProfile_Template[0]));
                  var result = []
                  for(i=0; i < restPayload.length; i++) {
                      tObj.threatLevel = restPayload[i].threatLevel;
                      tObj.vendorName = restPayload[i].vendorName;
                      result.push(tObj)
                  }
                  return result
              }else if(urlAction.service.indexOf('addVendorsProfile')){
                  for(var i=0;i<restPayload.length;i++){
                      var profileObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-allow-rule',{'vendorName':restPayload[i].vendorName});
                      if(profileObj.length == 0){
                          restPayload[i].lastChanged = Math.floor(UtilityFunctions.getTimeStamp())/1000;
                          restPayload[i].sitesName = [];
                          for(var j=0;j<restPayload[i].sites.length;j++){
                              let siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': restPayload[i].sites[j]});
                              let groupNameHierarchy = siteObj.length ? siteObj[0].groupNameHierarchy : '';
                              restPayload[i].sitesName.push(groupNameHierarchy)
                            }
                            SimLokiDatabaseActions.insert('rogue-allow-rule', restPayload[i])
                        }
                    }
                    return {"response": "Success"}
              }
            return SimAssuranceModelExtended.processGet(urlAction);
         }
          if(restPayload != undefined && Array.isArray(restPayload) && restPayload.length || (urlAction.service.indexOf('clientHealthTrendline') > -1)) {
                var x = JSON.stringify(restPayload); x = x.replace(/'/g, ""); x = x.replace(/"/g, "");
                if((urlAction.service.indexOf('clientHealthTrendline') > -1) || restPayload[0].indexOf("hasLabel('clienthealthAggregation_5_min')")>-1) {
                    clienthealthAggregation = true;
                } else if(x.indexOf("hasLabel(NormalizedRogueAp)") > -1) {
                    isRogue = true;
                } else if(x.indexOf("hasLabel(Site)") > -1) {
                    isRogue = true;
                } else if(x.indexOf("hasLabel(AWIPSAttackDetectedBy)") > -1) {
                    isRogue = true;
                } else if(x.indexOf("hasLabel(RogueClient)") > -1) {
                    isRogue = true;
                }
          if(restPayload != undefined && restPayload && !clienthealthAggregation && !isRogue) {
              var t = restPayload, j, i;
              t = t[0];

              if( t.indexOf('memberOfAPG') >= 0) {
                 return getAccessPointDetail(urlAction);
              }

              if(t.indexOf(".valueMap('macAddr','ipv4').toList()") >-1){
                  return [[]]
              }
              t = t.replace(/'/g, '');
              t = t.replace(/"/g, '');
              var x = t.split('.');
              var indx = x.indexOf('has(macAddr');
              for(i=0; i < x.length; i++) {
                  if(x[i].indexOf('has(macAddr') >= 0) {
                      indx = i;
                      break;
                  }
              }

              if(indx >= 0) {
                   var arr = x[indx].split(',');
                   var macAddr = arr[1];
                   macAddr = macAddr.replace(')','');
                   if(x.indexOf('like(iOS*))') >= 0) {
                       var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id': macAddr});
                       var count = 0;
                       //if(objs.length && objs[0].subType == "iPhone 7") {
                       //    count = 1;
                       //}
                       if(objs.length && objs[0].hostVersion != undefined && objs[0].hostVersion.indexOf('iOS') >= 0) {
                           count = 1;
                       }
                       return [[count]];
                   }
              }
          }

          if(clienthealthAggregation) {
              //this is for Client 360 events health graph for 1.2.5 code
              /* ["g.V().hasLabel('clienthealthAggregation_5_min').has('~modified', between(new Date(1537243164183), new Date(1537329564183)))
              .has('groupId', 'nongroup').has('_NODE_IDS_','B8_27_EB_9D_24_27').aggregateBy().withHisto('~modificationtime', 300000).
              withTerms('channel', 'connectedDeviceId', 'apName', 'channelWidth', 'ssid', 'frequency').
              withMetrics('averageHealthScore', 'latest').withMetrics('__timestamp', 'latest').withMetrics('rssi', 'latest').
              withMetrics('snr', 'latest').withMetrics('dnsRequestCount', 'latest').withMetrics('dnsResponseCount', 'latest').
              withMetrics('txBytes', 'latest').withMetrics('rxBytes', 'latest').withMetrics('dataRate', 'latest').
              withMetrics('NEW', 'latest').withMetrics('disconnected', 'latest').valueMap().toList()"] */

              var payload = restPayload;
              var windowTime = 300000;//5 min
              var startTime = 0, endTime = 0, mac = 0;

              if(Array.isArray(payload)){ 
                payload = payload[0].split(".");
                for(var i=0; i<payload.length; i++) {
                    if(payload[i].startsWith("has(")) {
                      var v1 = payload[i].indexOf("(")+1;
                      var v2 = payload[i].length-v1-1;
                      var field = payload[i].substr(v1,v2).split(",");
                      if(field[0].indexOf("~modificationtime") > -1) {
                          windowTime = Number(field[1]);
                      } else if(field[0].indexOf("~modified") > -1) {
                          var index = field[1].lastIndexOf("(");
                          startTime = Number(field[1].substr(index+1, field[1].indexOf(")")-1-index));
                          index = field[2].indexOf("(");
                          endTime = Number(field[2].substr(index+1, field[2].indexOf(")")-1-index));
                      }else if(field[0].indexOf("_NODE_IDS_") > -1) {
                          mac = field[1].replace(/'/g, '');
                          mac = mac.replace(/"/g, '');
                          mac = mac.replace(/_/g, ':');
                      }
                    }
                }
              }else { 
                startTime = payload.startTime;
                endTime = payload.endTime;
                mac = payload.filters.macAddress;
              }

               var dt = new Date(startTime), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

                  if(tMinute > 55) {
                      changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
                      changedTime.setUTCMinutes(0);
                  } else {
                      if(windowTime > tMinute)
                          adjustedMinute = -1 * (tMinute % windowTime);
                      else
                          adjustedMinute =  windowTime - tMinute;

                      tMinute = tMinute + adjustedMinute;
                      changedTime = new Date( dt.setUTCMinutes(tMinute));
                  }

              var clientObj = SimLokiDatabaseActions.getFilteredRecordHandler("host", {'id': mac});


              var recordsLength = UtilityFunctions.getNumberOfRecordsNeedToGenerate(startTime, endTime, 5);

              var response =[];

              var incrementBy = 5, isDeviceDisconnectHandled = false, totalDisconnects = 1, disconnectPart = 1, preConnectedDevices = [], 
              isEmptySpecificTimeDef = false;
              for (var i=0; i<HostIssueTimings.Empty_Time.length; i++) {
                  if(HostIssueTimings.Empty_Time[i].hostId == clientObj[0].id) {
                      isDeviceDisconnectHandled = true;
                      totalDisconnects += HostIssueTimings.Empty_Time[i].noOfDisconnects;
                      preConnectedDevices = HostIssueTimings.Empty_Time[i].preConnectedDevices;
                      isEmptySpecificTimeDef = HostIssueTimings.Empty_Time[i].isEmptySpecificTime;
                      break;
                  }
              }
              //special case handling: if a gap is needed in the graph and diff devices are conneted
              var disconnectObj = {'isDeviceDisconnectHandled':isDeviceDisconnectHandled, 'isEmptySpecificTime':false, 
                  'disconnectPart':disconnectPart, 'totalDisconnects':totalDisconnects, 'preConnectedDevices':preConnectedDevices};
              var idx = recordsLength/totalDisconnects;

              for(var i=0; i<recordsLength;i++) {
                 var tObj = JSON.parse(JSON.stringify(ClientEventHealthTemplate.Template));
                  var randomizeAvgScore = i == 0 ? false : true;
                  var curTime = UtilityFunctions.incrementDateByMinutes(changedTime, incrementBy);
                  var currTime = startTime+i*windowTime;
                  var isEmptySpecificTime = false;
                  if (isDeviceDisconnectHandled) {
                      if(isEmptySpecificTimeDef && i>disconnectPart*idx && i<Math.floor(disconnectPart*idx)+24) {
                          isEmptySpecificTime = true;
                      }
                      disconnectObj.disconnectPart = disconnectPart;
                      disconnectObj.isEmptySpecificTime = isEmptySpecificTime;
                      if(i==Math.floor(disconnectPart*idx)+24) {
                        disconnectPart++;
                    }
                  }
                  
                  var res = SimAssuranceMetricUtility.getClientHealthAggregationAvgScore(clientObj, changedTime, curTime, randomizeAvgScore, disconnectObj);
                  tObj.modificationtime = [currTime];
                  tObj.__timestamp = [currTime+180000];
                  tObj.authDoneTimestamp=[startTime+100];
                  tObj.channel = [res.channel];

                  if(clientObj[0].hostName == 'Camera2') {
                    if(i > 160 && i < recordsLength) {
                        tObj.averageHealthScore = 1;
                    } else {
                        tObj.averageHealthScore = 10;
                    }                    
                  }
                  else tObj.averageHealthScore = [res.averageHealthScore];

                  tObj.rxBytes = [res.rxBytes];
                  tObj.connectedDeviceId = [clientObj[0].connectedNetworkDeviceId];
                  tObj.ssid = [res.ssid];
                  tObj.apName = [res.apName];
                  tObj.ipv4 = [clientObj[0].hostIpV4];
                  tObj.isPrivateMac = [false];
                  tObj.macAddr = [clientObj[0].hostMac];
                  tObj._NODE_IDS_ = [clientObj[0].hostMac];
                  tObj.frequency = [res.frequency];
                  tObj.channelWidth = [res.channelWidth];
                  tObj.rssi = [res.rssi];
                  tObj.dataRate = [res.dataRate];
                  tObj.txBytes = [res.txBytes];
                  //changes by me
                  tObj.bssid = ["10:F9:20:FD:A2:AF"];

                  //changes by me
                  //tObj.snr = [res.snr];
                  tObj.snr = i > recordsLength-5 ? [clientObj[0].snr] : [res.snr];

                  tObj.rxRetries = [0];
                  tObj.rxRetryPct = [0];
                  tObj.rxPackets = [res.rxPackets]
                  //added to map for client 360 health chart
                   tObj.port = [clientObj[0].port];
                   tObj.latestRootCause = ["SUCCESS"];

                  if (clientObj[0].subType.indexOf('Samsung') >= 0 && clientObj[0].userId == "Grace.Smith" && i > 263 && i < recordsLength || i > 263 && i < recordsLength && clientObj[0].clientConnection == "AP9120_3") {
                      tObj.averageHealthScore = 5;
                      //update below values too
                      tObj.rssi = [UtilityFunctions.getRandomIntForRangeValues(-80, -85)];
                      tObj.snr = [UtilityFunctions.getRandomIntForRangeValues(7, 8)];
                      var clientObj3 = SimLokiDatabaseActions.getFilteredRecordHandler("host", {'clientConnection': 'AP9120_3'});
                      tObj.apName = [clientObj3[0].clientConnection]
                      tObj.connectedDeviceId = [clientObj3[0].connectedNetworkDeviceId];
                      tObj.ipv4 = [clientObj3[0].hostIpV4];
                      tObj.macAddr = [clientObj3[0].hostMac];
                      tObj._NODE_IDS_ = [clientObj3[0].hostMac];
                  }
                   var txerror=0;
                   var rxerror=0;
                   if(clientObj[0].txLinkError>0 && i%30==0){
                    var tmin=(clientObj[0].txLinkError-2)<0?0:(clientObj[0].txLinkError-2);
                    var tmax=clientObj[0].txLinkError; 
                    txerror=UtilityFunctions.getRandomIntForRangeValues(tmin, tmax);
                   }

                   if(clientObj[0].rxLinkError>0 && i%55==0){
                    var rmin=(clientObj[0].rxLinkError-1)<=0?0:(clientObj[0].rxLinkError-1);
                   var rmax=clientObj[0].rxLinkError;
                   rxerror=UtilityFunctions.getRandomIntForRangeValues(tmin, tmax);
                   }
                   
                   tObj.txLinkError = [txerror];
                   tObj.rxLinkError =[rxerror];
                  
                   tObj.linkSpeed = [clientObj[0].linkSpeed];
                   tObj.remoteEndDuplexMode = [clientObj[0].remoteEndDuplexMode];
                   tObj.txRate = [res.txRate];
                   tObj.rxRate = [res.rxRate];
                   tObj.vlan =[clientObj[0].vlanId]
                   if(disconnectObj.isEmptySpecificTime) {
                    tObj.port = [null];
                    tObj.txLinkError = [0];
                    tObj.rxLinkError = [0];
                    tObj.linkSpeed = [null];
                    tObj.remoteEndDuplexMode = [null];
                    tObj.vlan =[null]
                   }

                   if(isDeviceDisconnectHandled && disconnectPart != totalDisconnects) {
                       var devName = disconnectObj.preConnectedDevices[disconnectPart-1].name;
                       var dev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'name':devName});
                       tObj.connectedDeviceId = dev[0]? dev[0].id: "";
                   }

                  var count = UtilityFunctions.getRandomIntForRangeValues(10, 30);
                  

                  tObj.dnsRequestCount = [count];
                  tObj.dnsResponseCount = [count-3];
                  incrementBy += 5;
                  response.push(tObj);
              }

             var data=[];
             data.push(response);              
             /*response format changed in shockwave release,Need to check any breakages with new format in rogue*/            
             return {"response": data};
             //return data;
          } 
            else if(isRogue) {
                //Handle calls relate to Rogue management of Assurance by Karan for 1.3.1

                var payload = restPayload;
                payload = payload[0].split('.');
                var grouphierarchy = "Global/North America/USA/California/Pleasanton/PLS06/Flr-PLS06-1"
                var SiteRecordObj =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy': grouphierarchy});
                var apDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{siteId:SiteRecordObj[0].id});
                var response = []
                
                if(payload[4] == "has('ro_strongestThreatScore', gt(10000))" && payload[8] == "withMetrics('~elementid', 'termcardinality')"){
                    var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.ro_strongestThreatScore_Template[0]));
                    var startTime = 0, endTime = 0, mac = 0;
                    
                    if(payload[3].startsWith("has(")) {
                        var v1 = payload[3].indexOf("(")+1;
                        var v2 = payload[3].length-v1-1;
                        var field = payload[3].substr(v1,v2).split(",");
                        if(field[0].indexOf("~modified") > -1) {
                            var index = field[1].lastIndexOf("(");
                            startTime = Number(field[1].substr(index+1, field[1].indexOf(")")-1-index));
                            index = field[2].indexOf("(");
                            endTime = Number(field[2].substr(index+1, field[2].indexOf(")")-1-index));
                        }
                    }
                    var dt = new Date(startTime), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

                    var hours = UtilityFunctions.getHours(startTime-30*60000, endTime);
                    hours = Math.floor(hours);

                    if(tMinute > 55) {
                        changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
                        changedTime.setUTCMinutes(0);
                    } else {
                        changedTime = new Date( dt.setUTCMinutes(tMinute));
                    }
                    var tempObj = [];
                    for(var i=0, incrementBy = 0;i<hours;i++, incrementBy += 60){
                        var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.ro_strongestThreatScore_Template[0]));
                        tObj.id = "AGGREGATE_METRIC_TERMCARDINALITY_"+(i+1)
                        var t =UtilityFunctions.getTimeStamp()
                        tObj.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t)
                        var curTime = UtilityFunctions.incrementDateByMinutes(changedTime, incrementBy);
                        var t = new Date(curTime)
                        tObj.properties.modificationtime[0].value = t.getTime()
                        if(i == (hours-2)){
                            tObj.properties.elementid_termcardinality[0].value = 7
                        }
                        if(i == (hours-1)){
                            tObj.properties.elementid_termcardinality[0].value = 1
                        }
                        if(hours > 20){
                            if(i ==9 || i == 15 || i == 19 || i == 22  ){
                                tObj.properties.elementid_termcardinality[0].value = 6
                            }
                        }
                        tempObj.push(tObj);
                    }
                    response.push(tempObj)
                    return response
                }else if(payload[4] == "has('siteType', 'floor')" && payload[9] == "select('FloorSiteVertex','BuildingSiteVertex')"){
                    var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.SiteHierarchy_Template[0]));
                    tObj[0].FloorSiteVertex = apDevice[0].siteId;
                    tObj[0].BuildingSiteVertex["id"] =  SiteRecordObj[0].parentId;
                    tObj[0].BuildingSiteVertex["~pmtimestamp"] = UtilityFunctions.getTimeStamp()
                    response.push(tObj)
                    var tObj1 = JSON.parse(JSON.stringify(RogueManagementTemplate.SiteHierarchy_Template[1]));
                    for(var i=0; i<tObj1.length;i++){
                        tObj1[i].ro_strongestDetectingApSiteId = [apDevice[0].siteId]
                    }
                    response.push(tObj1)
                    return response
                }else if(payload[2] == "hasLabel('Site')" && payload[4] == "valueMap(true,'siteHierarchy')"){
                    var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.ValueMapsiteHierarchy_Template[0]));
                    tObj[0].id = apDevice[0].siteId;
                    tObj[0]['~pmtimestamp'] = UtilityFunctions.getTimeStamp()
                    response.push(tObj)
                    return response
                }else if(restPayload[1] && 
                    restPayload[1].includes("select('RogueApVertex','RogueApDetectedByEdge','DetectingApVertex').by().by().by(valueMap('macAddress','nwDeviceName')).toList()"
                      ))
                    {
                    var v1 = payload[3].indexOf("(")+1;
                    var v2 = payload[3].length-v1-1;
                    var field = payload[3].substr(v1,v2).split(",");
                    var f1 = field[1].split("'");
                    var rogueMac=f1[1];
                    // var Rssi = rogueMac.split(':');
                    var Rssi = UtilityFunctions.getRandomIntForRangeValues(30, 70);
                    var tObj = JSON.parse(JSON.stringify(RogueManagementTemplate.Rogue_TreatLevel_Template[0]));
                    if(Rssi>50){
                        SSID = "lightning"
                    }else{
                        SSID = "CiscoLive"
                    }
                    
                    for(i=0;i<tObj.length;i++){
                            var t =UtilityFunctions.getTimeStamp();
                            tObj[i].RogueApVertex.id = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8"
                            tObj[i].RogueApVertex.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t)
                            tObj[i].RogueApVertex.properties.ro_macAddress[0].value = rogueMac
                            
                            tObj[i].RogueApDetectedByEdge.id = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8:RogueApDetectedBy:f543bf17-7e08-4a18-99c7-43e814c9eb38"
                            tObj[i].RogueApDetectedByEdge.properties.ro_rssi = "-"+Rssi;
                            tObj[i].RogueApDetectedByEdge.properties.ro_ssid = SSID
                            tObj[i].RogueApDetectedByEdge.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t)
                            tObj[i].RogueApDetectedByEdge.outV = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8"
                            tObj[i].DetectingApVertex.macAddress = apDevice[0].macAddress
                    }
                    response.push(tObj)
                    var tObj1 = JSON.parse(JSON.stringify(RogueManagementTemplate.Rogue_TreatLevel_Template[1]));
                    for(i=0;i<tObj1.length;i++){
                            var t =UtilityFunctions.getTimeStamp()
                            tObj1[i].RogueApVertex.id = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8"
                            tObj1[i].RogueApVertex.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t)
                            tObj1[i].RogueApVertex.properties.ro_macAddress[0].value = rogueMac
                            
                            tObj1[i].RogueApDetectedByEdge.id = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8:RogueApDetectedBy:f543bf17-7e08-4a18-99c7-43e814c9eb38"
                            tObj1[i].RogueApDetectedByEdge.properties.ro_rssi = "-"+Rssi;
                            tObj[i].RogueApDetectedByEdge.properties.ro_ssid = SSID
                            tObj1[i].RogueApDetectedByEdge.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t)
                            tObj1[i].RogueApDetectedByEdge.outV = "RogueAp_"+rogueMac.replace(/:/g, "_")+"__8"
                            tObj1[i].DetectingApVertex.macAddress = apDevice[0].macAddress
                    }
                    response.push(tObj1)
                    return response
                }else if(payload[10] == "has('ro_macAddress')" && payload[11] == "dedup()" && payload[12] == "as('RogueClientVertex')"){
                    response = [[],[]]
                    return response 
                }else if(payload[5].indexOf('SwitchPortTracedTo')>-1 && payload[2] == "hasLabel('NormalizedRogueAp')" && payload[16] == "select('EndDeviceVertex','NwDeviceInterfaceVertex','NetworkDeviceVertex')"){
                    var t =UtilityFunctions.getTimeStamp();
                    var v1 = payload[3].indexOf("(")+1;
                    var v2 = payload[3].length-v1-1;
                    var field = payload[3].substr(v1,v2).split(",");
                    var f1 = field[1].split("'");
                    var f2 = f1[1].split("__");
                    var f3 = f2[0].replace('NormalizedRogueAp_', '');
                    var rogueMac=f3[1];
                    var tempObj = JSON.parse(JSON.stringify(RogueManagementTemplate.RogueOnWire_Template[0]));
                    tempObj[0].EndDeviceVertex.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t);
                    var netDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'name':"PLS06-AP3800-01"});
                    tempObj[0].EndDeviceVertex.id = "ed_"+rogueMac.replace(/:/g, "_");
                    tempObj[0].NetworkDeviceVertex.id = netDeviceObj[0].id;
                    tempObj[0].NetworkDeviceVertex.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t);
                    tempObj[0].NetworkDeviceVertex.properties.managementIpAddr[0].value = netDeviceObj[0].managementIpAddress;
                    tempObj[0].NetworkDeviceVertex.properties.serialNumber[0].value = netDeviceObj[0].serialNumber;
                    tempObj[0].NetworkDeviceVertex.properties.nwDeviceId[0].value = netDeviceObj[0].id;
                    tempObj[0].NetworkDeviceVertex.properties.macAddress[0].value = netDeviceObj[0].macAddress;
                    tempObj[0].NetworkDeviceVertex.properties.lastBootTime[0].value = netDeviceObj[0].upTime;
                    response.push(tempObj)
                    return response
                }else if(payload[4]  == "has('ro_strongestThreatScore', '910002')" || payload[5]  == "has('ro_strongestThreatScore', '910002')" || 
                        payload[4]  == "has('ro_strongestThreatScore', '910001')" || payload[5]  == "has('ro_strongestThreatScore', '910001')" || 
                        payload[4]  == "has('ro_strongestThreatScore', '910006')" || payload[5]  == "has('ro_strongestThreatScore', '910006')" ||
                        payload[4]  == "has('ro_strongestThreatScore', '910007')" || payload[5]  == "has('ro_strongestThreatScore', '910007')" ){
                    if(payload[6] == "count()"){
                        // var macadd = payload[3].match(/([0-9A-F]{2}:?){6}/g);
                        var v1 = payload[3].indexOf("(")+1;
                        var v2 = payload[3].length-v1-1;
                        var field = payload[3].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var rogueMac=f1[1];
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':rogueMac})
                        if(jsonObj[0].properties.ro_strongestThreatScore[0].value == '910007'){
                            return [[45]]
                        }
                        // var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        return [[jsonObj.length]]
                    }else if(payload[8] == "hasLabel('AWIPSAttackDetectedBy')" && payload[11] == "as('AttackDetectedByVertex')" && payload[15] ==  "select('AttackDetectedByEdge','AttackDetectedByVertex','SiteVertex')"){
                        // var macadd = payload[4].match(/([0-9A-F]{2}:?){6}/g);
                        var v1 = payload[3].indexOf("(")+1;
                        var v2 = payload[3].length-v1-1;
                        var field = payload[3].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var rogueMac=f1[1];
                        // var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':rogueMac})
                        var tempObj1 = []
                        for(var i=0;i<2;i++){
                            var tempObj = JSON.parse(JSON.stringify(RogueManagementTemplate.AWIPS_AttackDetectedByEdge_Template[0]));
                            tempObj[0].AttackDetectedByEdge.id = jsonObj[0].id+':AWIPSAttackDetectedBy:'+jsonObj[0].properties.ro_strongestDetectingApSiteId[0].value;
                            tempObj[0].AttackDetectedByEdge.inV = jsonObj[0].properties.ro_strongestDetectingApSiteId[0].value;
                            tempObj[0].AttackDetectedByEdge.outV = jsonObj[0].id;
                            tempObj[0].AttackDetectedByEdge.timestamp = jsonObj[0].timestamp;
                            tempObj[0].AttackDetectedByVertex.macAddress[0] = jsonObj[0].properties.ro_strongestDetectingApMacAddress[0].value.toUpperCase();;
                            tempObj[0].AttackDetectedByVertex.nwDeviceName[0] = jsonObj[0].properties.ro_strongestDetectingApName[0].value;
                            tempObj1.push([tempObj[0]])
                        }
                        return tempObj1
                    }
                }else if(payload[2] == "hasLabel('AWIPSAttackDetectedBy')" && payload[9] == "select('AttackDetectedByEdge','AttackDetectedByVertex')"){
                    // var macadd = payload[4].match(/([0-9A-F]{2}:?){6}/g);
                    var v1 = payload[3].indexOf("(")+1;
                    var v2 = payload[3].length-v1-1;
                    var field = payload[3].substr(v1,v2).split(",");
                    var f1 = field[1].split("'");
                    var f2=f1[1].split(':')
                    // getting the stattime and endtime
                    var timePayload = payload[4].split(',')
                    var startTime = timePayload[1].match(/\d+/)
                    startTime = startTime[0]
                    startTime = parseInt(startTime)

                    var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':f2[0]})
                    var tempObj1 = []
                    for(var i=0;i<45;i++){
                        var tempObj = JSON.parse(JSON.stringify(RogueManagementTemplate.AWIPS_AttackDetectedByEdge_Template[0]));
                        tempObj[0].AttackDetectedByEdge.id = jsonObj[0].id+jsonObj[0].properties.ro_strongestThreatScore[0].value+':AWIPSAttackDetectedBy:'+jsonObj[0].properties.ro_strongestDetectingApSiteId[0].value;
                        tempObj[0].AttackDetectedByEdge.inV = jsonObj[0].properties.ro_strongestDetectingApSiteId[0].value;
                        tempObj[0].AttackDetectedByEdge.outV = jsonObj[0].id+jsonObj[0].properties.ro_strongestThreatScore[0].value;
                        tempObj[0].AttackDetectedByEdge.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(startTime);
                        tempObj[0].AttackDetectedByEdge.properties.ro_alarmId = 226000+i;
                        tempObj[0].AttackDetectedByVertex.macAddress[0] = jsonObj[0].properties.ro_strongestDetectingApMacAddress[0].value.toUpperCase();
                        tempObj[0].AttackDetectedByVertex.nwDeviceName[0] = jsonObj[0].properties.ro_strongestDetectingApName[0].value;
                        delete tempObj[0].SiteVertex
                        tempObj1.push(tempObj[0])
                    }
                    return [tempObj1]
                }else if(payload[4] == "valueMap('ro_cnt')"){
                    var v1 = payload[3].indexOf("(")+1;
                    var v2 = payload[3].length-v1-1;
                    var field = payload[3].substr(v1,v2).split(",");
                    var f1 = field[1].split("'");
                    var rogueMac=f1[1];
                    var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':rogueMac});
                    var tempObj = [[{"ro_cnt":[jsonObj[0].properties.ro_cnt[0].value]}],[{"RogueAp":{"ro_macAddress":[jsonObj[0].properties.ro_macAddress[0].value],"ro_cnt":[jsonObj[0].properties.ro_cnt[0].value]},"DetectedBy":{"ro_ssid":jsonObj[0].properties.ro_threatSsid[0].value}}]]
                    return tempObj
                }else if(payload[14] == "by(valueMap('ro_class'))"){
                    return [[{"RogueAp":{"ro_macAddress":["70:B3:17:4F:03:6C"]},"RogueApDetectedBy":{"ro_class":3}}]]
                }else if(payload[2] == "hasLabel('RogueClient')" && payload[6] == "withTerms('ro_normalizedID')"){
                    var recordObj = SimLokiDatabaseActions.getAll('rogue-management');
                    var data = []
                    for(var i=0;i<73;i++){
                        var tJson = {};
                        var mac_id = recordObj[i].id
                        mac_id = mac_id.split('NormalizedRogueAp_');
                        // if(i == 10 || i == 25){
                        //     tJson['elementid_cardinality'] = [2];
                        // }else{
                        //     tJson['elementid_cardinality'] = [0];
                        // }
                        tJson['elementid_cardinality'] = [1];
                        tJson['ro_normalizedID'] = [mac_id[1]];
                        data.push(tJson)
                    }
                    return [data]
                }
                // Rogue Management filters
                else {
                    
                    var startTime = 0, endTime = 0, mac = 0;
                    
                    if(payload[3].startsWith("has(")) {
                        var v1 = payload[3].indexOf("(")+1;
                        var v2 = payload[3].length-v1-1;
                        var field = payload[3].substr(v1,v2).split(",");
                        if(field[0].indexOf("~latest") > -1) {
                            var index = field[1].lastIndexOf("(");
                            startTime = Number(field[1].substr(index+1, field[1].indexOf(")")-1-index));
                            index = field[2].indexOf("(");
                            endTime = Number(field[2].substr(index+1, field[2].indexOf(")")-1-index));
                        }
                    }
                    var dt = new Date(startTime), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

                    if(tMinute > 55) {
                        changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
                        changedTime.setUTCMinutes(0);
                    } else {
                        changedTime = new Date( dt.setUTCMinutes(tMinute));
                    }
                    var incrementBy = 5
                    if(payload.length == 8){
                        var jsonObj=SimLokiDatabaseActions.getAll('rogue-management');
                        for(var i=0; i<jsonObj.length;i++) {
                            var curTime = UtilityFunctions.incrementDateByMinutes(changedTime, incrementBy);
                            jsonObj[i].timestamp = curTime
                            incrementBy += 5;
                        }
                    }
                    else if(payload[4] == "has('ro_strongestThreatScore',between(5001,10000))"){
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':5001});
                    }else if(payload[4] == "has('ro_strongestThreatScore',between(10001,910010))"){
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':10001});
                    }else if(payload[4].startsWith("has('ro_strongestThreatScore',")){
                        var threatScore = parseInt(payload[4].split(',')[1].replace(')',''))
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':threatScore});
                    }else if(payload[4].startsWith("has('ro_macAddress',")){
                        // var macadd = payload[4].match(/([0-9A-F]{2}:?){6}/g);
                        // jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        var v1 = payload[8].indexOf("(")+1;
                        var v2 = payload[8].length-v1-1;
                        var field = payload[8].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var macadd=f1[1];
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':macadd})
                    }else if(payload[4].startsWith("has('ro_strongestRssi',")){
                        var regex = /\d+/g;
                        var rssi = payload[4].match(regex)
                        var rs = -rssi[0]
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestRssi.value':rs.toString()});
                    }
                    else if(payload[4].startsWith("has('ro_threatSsid',")){
                        var ssid = payload[4].split(',');
                        var ssid1 = ssid[1].split('*');
                        if( ssid1 != null){
                            var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_threatSsid.value':{'$containsAny': ssid1[1]}});
                        }else{
                            var jsonObj = []
                        }
                    }else if(payload[3].startsWith("hasLabel('RogueClient')") && payload[14].startsWith("count()")){
                        // var macadd = payload[8].match(/([0-9A-F]{2}:?){6}/g);
                        // jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        var v1 = payload[8].indexOf("(")+1;
                        var v2 = payload[8].length-v1-1;
                        var field = payload[8].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var macadd=f1[1];
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':macadd})
                        clientCount = jsonObj[0].clientCount;
                        return [[clientCount]]
                    }else if(payload[3].startsWith("hasLabel('RogueClient')")){
                        // var macadd = payload[8].match(/([0-9A-F]{2}:?){6}/g);
                        // jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        
                        var v1 = payload[8].indexOf("(")+1;
                        var v2 = payload[8].length-v1-1;
                        var field = payload[8].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var macadd=f1[1];
                        // var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_macAddress.value':macadd[0]});
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':macadd})
                        
                        clientCount = jsonObj[0].clientCount;
                        var data = []
                        if(clientCount >0){
                             data = JSON.parse(JSON.stringify(RogueManagementTemplate.RogueClient_Template[0]));
                             for(var i=0;i<data.length;i++){
                                data[i].properties.ro_rogueMacAddress[0].value = jsonObj[0].properties.ro_macAddress[0].value.toUpperCase();;
                                data[i].properties.ro_gatewayMacAddress[0].value = jsonObj[0].properties.ro_macAddress[0].value;
                                data[i].timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(new Date())
                             }
                        }
                        return [data]
                    }else if(payload[13] == "select('NetworkDevice','NetworkDevice')" && payload[14] == "by(valueMap('macAddress','nwDeviceName'))"){
                        var v1 = payload[3].indexOf("(")+1;
                        var v2 = payload[3].length-v1-1;
                        var field = payload[3].substr(v1,v2).split(",");
                        var f1 = field[1].split("'");
                        var macadd=f1[1];
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'id':macadd});
                        var data = [{"NetworkDevice":{"macAddress":[jsonObj[0].properties.ro_strongestDetectingApMacAddress[0].value],"nwDeviceName":[jsonObj[0].properties.ro_strongestDetectingApName[0].value]}}];
                        return [data]
                        
                    }
                    else{
                        var jsonObj=SimLokiDatabaseActions.getAll('rogue-management');
                    }
                    if(payload[5].startsWith("has('ro_strongestThreatScore',")){
                        var threatScore = parseInt(payload[5].split(',')[1].replace(')',''))
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':threatScore});
                    }
                    if(payload[5] == "has('ro_strongestThreatScore',between(10001,910027))"){
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':{'$gt': 910002}});
                    }
                    if(payload[5] == "has('ro_strongestThreatScore',between(5001,10000))"){
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':5001});
                    }
                    if(payload[5] == "has('ro_strongestThreatScore',between(1901,5000))"){
                        var jsonObj = SimLokiDatabaseActions.getFilteredRecordHandler('rogue-management',{'properties.ro_strongestThreatScore.value':1901});
                    }
                    response.push(jsonObj)
                    var jsonObj1=[jsonObj.length];
                    response.push(jsonObj1)
                    var jsonObj2= [jsonObj.length];
                    response.push(jsonObj2)
                    var jsonObj3=JSON.parse(JSON.stringify(RogueManagementTemplate.RogueManagement_Template[3]));
                    for(var j=0; j<jsonObj3.length;j++) {
                        jsonObj3[j].id = apDevice[0].siteId
                        jsonObj3[j]['~pmtimestamp'] = UtilityFunctions.getTimeStamp();
                    }
                    response.push(jsonObj3)
                    return response
                    }
                }
                
            }

          return getSiteDetailsWithMetrics(urlAction);
      }
    }
});
