define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
], function (SimTaskDataGenerator) {

    return {
        init: function() {
        },

        doProcessRestRequest:function(urlAction) {
            return SimTaskDataGenerator.getTaskStatus(urlAction);
        }
    };
});
