define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions'
], function (SimTopologyData, SimLokiDatabaseActions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){

            if(urlAction.service.indexOf('controllerapp') >= 0) {
                return SimTopologyData.getControllerAppDetails(urlAction);
            }
            if(urlAction.url.indexOf("/topology/l2/Vlan31") > -1){
                if(urlAction.filter['nodeType'] == 'device'){
                 //get toplogy data for loop issue root cause analysis
                     var data = SimLokiDatabaseActions.getAll('physical-topology');
                     var nodes = [], links = [];
                     var length = data.length;
                     for(var i =0; i < length; i++) {
                        if(data[i].nodeType!=undefined){
                                nodes.push(data[i]);
                        }else {
                            links.push(data[i]);
                        }
                     }
                     return { nodes : nodes, links : links };
                }
            }
            if(urlAction.service.indexOf('vlan') >= 0 || urlAction.service.indexOf('l2') > -1) {
                return SimTopologyData.getVlanDetails(urlAction);
            }
            if(urlAction.service.indexOf('vrf') >= 0) {
                return SimTopologyData.getVrfDetails(urlAction);
            }
            if((urlAction.service.indexOf('isis') >= 0 || urlAction.service.indexOf('ospf') >= 0 || 
                urlAction.service.indexOf('eigrp') >= 0 || urlAction.service.indexOf('static') >= 0) && 
                urlAction.service.indexOf('l3') > -1) {
                    return SimTopologyData.getRoutingDetails(urlAction);
            }
            if (urlAction.service.indexOf('physical-topology') >= 0) {
                var topoData = SimTopologyData.getPhyTopology();
                if (urlAction.filter['nodeType'] == 'device') {
                    topoData.nodes = topoData.nodes.filter(e => { return ['wired', 'wireless'].indexOf(e.deviceType) == -1 });
                    topoData.nodes[0].customParam = topoData.nodes[1].customParam;
                    var nodeIds = topoData.nodes.map(e => e.id)
                    topoData.links = topoData.links.filter(e => { return nodeIds.indexOf(e.source) > -1 && nodeIds.indexOf(e.target) > -1 })
                    topoData.links.forEach(e => { e.startPortID = e.endPortID })
                }
                // filtering the the duplicate nodes
                let newNodes = []
                topoData.nodes.forEach(node => {
                    var newArray = newNodes.filter(function (item) {
                        return item.label == node.label;
                    });
                    (newArray.length == 0) ? newNodes.push(node) : "";
                })
                topoData.nodes = newNodes;
                
                if(['requiredFieldView','extraFieldView'].indexOf(urlAction.filter.resultView) > -1){
                    topoData = SimTopologyData.getUpdatedTopoData(topoData, urlAction.filter.resultView);
                }
                return topoData;
            }
            if(urlAction.service.indexOf('site-topology') >= 0) {
                return SimTopologyData.getSiteDetails(urlAction);
            }
        }
    };
});
