define({
    "Template":
    {
        "topClients": [
            {
                "measure": 11247.93877940869154,
                "clientName": "Grace.Smith",
                "clientIp": "10.30.100.27",
                "clientMacAddress": "6C:19:C0:BD:87:C9 "
            },
            {
                "measure": 10119.15912712708605,
                "clientName": "DR.Dogood",
                "clientIp": "10.30.100.47",
                "clientMacAddress": "A8:BE:27:36:70:12"
            },
            {
                "measure": 1581.35395045378263,
                "clientName": "john.zoidberg",
                "clientIp": "10.13.4.108",
                "clientMacAddress": "B8:27:EB:0C:57:C8"
            },
            {
                "measure": 1265.60008410267193,
                "clientName": "Gordon.Thomson ",
                "clientIp": "10.30.100.41",
                "clientMacAddress": "6C:19:C0:BD:87:C7"
            },
            {
                "measure": 1141.78466895470378,
                "clientName": " shaggy.rogers",
                "clientIp": " 10.30.100.19",
                "clientMacAddress": "B8:27:EB:72:11:FA"
            }
        ]
    }
});