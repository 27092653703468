define({
"Template":
{
  "name": "snmp_memory_high_80",
  "severity": "HIGH",
  "id": "AWWtUuu-AAQ7MfF7gU5u",
  "time": "2018-09-06T05:15:00.000+0000",
  "endTime": "2018-09-06T05:20:00.000+0000",
  "count": 2,
  "attributes": [
    {
      "key": "managementIpAddr",
      "value": "192.168.120.1"
    },
    {
      "key": "memory",
      "value": "97.0"
    },
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "entityId",
      "value": "192.168.120.1"
    },
    {
      "key": "threshold",
      "value": "95"
    },
    {
      "key": "message",
      "value": "This Device is experiencing high Memory utilization"
    },
    {
      "key": "type",
      "value": "Issue"
    },
    {
      "key": "priority",
      "value": "P1"
    },
    {
      "key": "category",
      "value": "Device"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})