define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInfoTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator'
], function (SimLokiDatabaseActions, DeviceInfoTemplate, UtilityFunctions, SimTaskDataGenerator) {

    function getGroupNameHierarchy(siteId){
        var groupNameHierarchy = '';
        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': siteId });
        if (recordObj && recordObj.length){
            groupNameHierarchy = recordObj[0].groupNameHierarchy;
        }
        return groupNameHierarchy;
    }

    return {
        init: function () {
            var devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':
                {'$in':['p1.edge1-sda1.local','FE1-9300-03.cisco.com','FE2-9300-04.cisco.com',
                'SJC06-C9300-01','SJC06-C9300-02']} });
            var tagObjs = [
                {"name":"TestTag","description":"Tag for p1.edge1-sda1.local","dynamicRulePresent":true,
                "dynamicRules":[{"memberType":"networkdevice","rules":{"name":"hostname","operation":"ILIKE","value":"%p1.edge1-sda1.local%"}}],
                "id":"207d545d-b5b2-c3c5-50b9-8a567fd39f47","systemTag":false},
                {"name":"nfvTag","description":"Tag for NFV","dynamicRulePresent":false,
                "id":"1ad49910-49a0-97ee-818a-b8d61a57a1f8","systemTag":false},
                {"name":"SDG_Agents","description":"Tag for Wide Area Bonjour","dynamicRulePresent":false,
                "id":"63f04080-86e2-4d8f-aae1-ce1cb1576028","systemTag":false},
            ];
            SimLokiDatabaseActions.insert('tag',tagObjs);
            devices.forEach(device => {
                var tJson = {"device":'devId',"tag":"'tagId'","associationType":"DYNAMIC", "tagName":"TestTag"};
                switch (device.hostname) {
                    case 'p1.edge1-sda1.local'://map to TestTag
                        tJson.device = device.id, tJson.tag = tagObjs[0].id, tJson.tagName = tagObjs[0].name;
                        break;
                    case 'FE2-9300-04.cisco.com'://map to SDG_Agents
                    case 'FE1-9300-03.cisco.com':
                    case 'SJC06-C9300-01':
                    case 'SJC06-C9300-02':
                        tJson.device = device.id, tJson.tag = tagObjs[2].id, tJson.tagName = tagObjs[2].name;
                        tJson.associationType = 'STATIC,MIXED';
                        break;
                    default://map to TestTag, if any
                        tJson.device = device.id, tJson.tag = tagObjs[0].id, tJson.tagName = tagObjs[0].name;
                        tJson.associationType = 'STATIC,MIXED';
                        break;
                }
                SimLokiDatabaseActions.insert('tag-device-mapping',tJson);
            });
        },

        getTag: function(urlAction) {
            var tagId;
            if(urlAction.action.id) {
                tagId = urlAction.action.id;
                tags = SimLokiDatabaseActions.getFilteredRecordHandler('tag', {'id': tagId});
                return tags[0];
            }
            
            var tags = SimLokiDatabaseActions.getAll('tag');
            if(urlAction.filter['name']) {
                tags = tags.filter(function(v){ return v.name.indexOf(urlAction.filter['name'].replace(/\.\*/g, ''))>-1 });
            }
            return tags;
        },


        getMemberTagData:function(urlAction) {
            var data = {};
            var deviceIdList = urlAction.filter['id'].split(',');
            var filterTags = urlAction.filter['tagNameHierarchy'];
            if(filterTags) {
                //filter check
                filterTags = filterTags.split(",");
            }
            for (var i=0; i<deviceIdList.length; i++){
                data[deviceIdList[i]] = [];
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping', {device:deviceIdList[i]});
                for(var j=0; j<records.length; j++) {
                    var tagObj = SimLokiDatabaseActions.getFilteredRecordHandler('tag', {id:records[j].tag});
                    if(!filterTags || (filterTags && tagObj && filterTags.indexOf(tagObj[0].name)>-1)) {
                        data[deviceIdList[i]].push(tagObj[0]);
                    }
                }
            }
            return data;
        },

        getCount: function(urlAction) {
            if(urlAction.url.indexOf('preview')>-1 && urlAction.url.indexOf('count')>-1) {
                // /api/v2/tag/member/preview/23fbbfa4-7f21-4d34-9110-7465e8b24bc4/count?memberType=networkdevice&memberAssociationType=DYNAMIC&__preventCache=1538728725264
                return this.getPreviewCount(urlAction);
            } else if(urlAction.filter['memberAssociationType']) {
                // /api/v2/tag/7fd37f61-9262-4d09-86f7-06f6b6c3e68a/member/count?memberType=networkdevice&memberAssociationType=STATIC,MIXED&level=0
                return getMemberAssociation(urlAction).length;
            } else {
                // /api/v2/tag/count?__preventCache=1538728745790
                var obj = SimLokiDatabaseActions.getAll('tag');
                return obj.length;
            }
        },

        devicesForTag: function(urlAction) {
            return getMemberAssociation(urlAction);
        },

		postPreview: function(urlAction) {
            var generatedUuid = UtilityFunctions.generate_uuid();
			var resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: generatedUuid});
            var deviceList = getDeviceList(urlAction);//devices matching the dynamic rule will be obtained
            var devices = [];
            deviceList.forEach(function (device) {
                devices.push(device.id);
            });
            if(urlAction.restPayload.members && urlAction.restPayload.members.networkdevice) {
                //if it is a manual tag mapping, push it to the device list
                var existingDevices = urlAction.restPayload.members.networkdevice;
                for(var i=0; i<existingDevices.length; i++) {
                    var record = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',
                    {'device':existingDevices[i], 'associationType':'STATIC,MIXED'});
                    if(record.length>0 && devices.indexOf(existingDevices[i]) == -1) {
                        devices.push(existingDevices[i]);
                    }
                }
            }
            SimLokiDatabaseActions.insert('tag-preview-count',{'taskId':generatedUuid, 'devices':devices});
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        save: function(urlAction) {
           var generatedUuid = UtilityFunctions.generate_uuid();
            var msg = 'Group is created successfully';
            var resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: generatedUuid, progress: msg});
            var obj = urlAction.restPayload;
            delete obj.type;

            var deviceList = getDeviceList(urlAction);
            mapDevicesToTag(generatedUuid, deviceList, obj.name);

            if(obj.dynamicRules && obj.dynamicRules.length>0) {
                delete obj.dynamicRules[0].rules.comparator;
                delete obj.dynamicRules[0].rules.operator;
                obj.dynamicRulePresent = true;
            } else {
                obj.dynamicRulePresent = false;
                delete obj.dynamicRules;
            }
            obj.systemTag = false;
            obj.id = generatedUuid; //tagId
            SimLokiDatabaseActions.insert('tag',obj);

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        assignTagsManually: function(urlAction) {
           var msg = 'Updated tags successfully';
            var resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: null, progress: msg});

            var memberToTags = urlAction.restPayload.memberToTags;
            Object.keys(memberToTags).forEach(function(key) {
                var tagIds = [];
                tagIds = memberToTags[key];
                mapTagsToDevice(tagIds, key);
            });

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        edit: function(urlAction) {
            var msg = 'Group is updated successfully';
            var obj = urlAction.restPayload;
            var resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: obj.id, progress: msg});

            var deviceList = getDeviceList(urlAction);
            mapDevicesToTag(obj.id, deviceList, obj.name);

            delete obj.type;
            if(obj.dynamicRules && obj.dynamicRules.length>0) {
                delete obj.dynamicRules[0].rules.comparator;
                delete obj.dynamicRules[0].rules.operator;
                obj.dynamicRulePresent = true;
            } else {
                obj.dynamicRulePresent = false;
                delete obj.dynamicRules;
            }
            obj.systemTag = false;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('tag', {id:obj.id});
            if(record && record.length) {
                SimLokiDatabaseActions.delete('tag', record);
            }
            SimLokiDatabaseActions.insert('tag',obj);

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        deleteTag: function(id) {
           var resultObj={};var msg="";
            var deviceList = getDevices(id);  
            if(deviceList && deviceList.length){
                msg = "Tag cannot be deleted as it is associated with 'networkdevice'.";
                resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: id, progress: msg});
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: true, failureReason: msg });
            }      
            else{  
                msg = 'Group is deleted successfully';
                resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: id, progress: msg});
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('tag', {id: id});
                if(record && record.length) {
                    SimLokiDatabaseActions.delete('tag', record);
                }
                record = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping', {'tag': id});
                if(record && record.length) {
                    SimLokiDatabaseActions.delete('tag-device-mapping', record);
                }

                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        }

            return resultObj;
        },

        assignTemplateTags: function(urlAction) {
            var msg = 'Tag(s) updated successfully';
            var resultObj = SimTaskDataGenerator.createTask('Tag Service',{data: null, progress: msg});
            //as of now not updating the tags to template, as there was no reference.
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        },

        getDeviceDtlsForTag: function(urlAction) {
            var tagId = urlAction.action.id;
            var deviceIds = getDevices(tagId);
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': {'$in': deviceIds } });
            return data;
        },

        getPreviewDevices: function(urlAction) {
            var id = urlAction.action.id, resultObj = [];
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('tag-preview-count', {'taskId': id});
            if(obj && obj.length) {
                var devices = obj[0].devices;
                var memberAssociationType = urlAction.filter['memberAssociationType'];
                for(var i=0; i<devices.length; i++) {
                    if(memberAssociationType && memberAssociationType == 'STATIC,MIXED') {
                        //mannually tagged device to be picked from DB only..
                        var filter = [ {'device':devices[i]},{'associationType':memberAssociationType}];
                        var tagRecord = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping', {'$and': filter} );
                        if(tagRecord && tagRecord.length>0) {
                            var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':devices[i]});
                            if(devRecord && devRecord.length>0) {
                                resultObj.push(devRecord[0]);
                            }
                        }
                    } else {
                        //dynamic values may change based on rule updation
                        var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':devices[i]});
                        if(devRecord && devRecord.length>0) {
                            resultObj.push(devRecord[0]);
                        }
                    }
                }
            }
            return resultObj;
        },

        getPreviewCount: function(urlAction) {
            var devices = this.getPreviewDevices(urlAction);
            return devices.length;
        }

    };

    function getDevices(tagId) {
        var deviceIds = [];
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping', {'tag':tagId});
        for(var i=0; i<records.length; i++) {
            deviceIds.push(records[i].device);
        }
        return deviceIds;
    }

    function getDeviceList(urlAction) {
        if(!urlAction.restPayload.dynamicRules || urlAction.restPayload.dynamicRules.length == 0) {
            //no condition
            return [];
        }
        var deviceList = SimLokiDatabaseActions.getAll('network-device');
        var rules = urlAction.restPayload.dynamicRules[0].rules;
        if(rules.items) {
            //more than one condition
            var filterObjArr = {};
            filterObjArr = getRules(filterObjArr, rules);
            Object.keys(filterObjArr).forEach(function (key) {
                var filters = filterObjArr[key];
                var devices = [];
                for(var i=0; i<filters.length; i++) {
                    var result = [];
                    var filterKey = Object.keys(filters[i])[0];
                    var filterValue = filters[i][filterKey].replace(/%/g, '');
                    result = applyRule(key, filterValue, filterKey, deviceList);
                    devices = arrayUnion(devices, result, function (item) {
                        return item.id;
                    });
                };
                deviceList = devices;
            });
        } else {
            //one condition only
            deviceList = applyRule(rules.name, rules.value.replace(/%/g, ''), rules.comparator, deviceList);
        }
        return deviceList;
    }

	function applyRule(filterName, filterValue, filterOperator, deviceList) {
		if(filterName != 'groupNameHierarchy') {
			switch(filterOperator) {
				case 'contains':
					deviceList = deviceList.filter(function(device){return device[filterName].indexOf(filterValue) > -1;});
					break;
				case 'equals':
					deviceList = deviceList.filter(function(device){return device[filterName] == filterValue;});
					break;
				case 'startsWith':
					deviceList = deviceList.filter(function(device){return device[filterName].startsWith(filterValue);});
					break;
				case 'endsWith':
					deviceList = deviceList.filter(function(device){return device[filterName].endsWith(filterValue);});
					break;
			};
		}
		return deviceList;
	}

	/* function getRules(filterJson, rule) {
        if(rule.name) {
			if(!filterJson[rule.name]) {
				var name = rule.name, operation = rule.comparator;
				var obj = {name : [{operation : rule.value}]};
				filterJson.push(obj);
			} else {
				var obj = filterJson[rule.name], operation = rule.comparator;;
				obj.push({operation : rule.value});
				filterJson[rule.name] = obj;
			}
		}
		if(rule.items) rule.items.forEach(getRules.bind(null,filterJson));
		return filterJson;
    } */

    function getRules(filterJson, rule) {
        if(rule.name) {
			if(!filterJson[rule.name]) {
				var name = rule.name, operation = rule.comparator;
				var filterObj = {};
				filterJson[name] = [], filterObj[operation] = rule.value;
				filterJson[name].push(filterObj);
				return filterJson;
			} else {
				var filterObj = {}, operation = rule.comparator;
				filterObj[operation] = rule.value;
				filterJson[rule.name].push(filterObj);
				return filterJson;
			}
		}
		if(rule.items) {
			for(var i=0; i<rule.items.length; i++) {
				filterJson = getRules(filterJson, rule.items[i]);
			}
		}
		return filterJson;
    }

    function mapDevicesToTag(tagId, deviceList, tagName) {
        var associationType = 'DYNAMIC';
        //remove the DYNAMIC records as the condition might have changed in case of edit
        //if it is first time, there would be no entry anyway!
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',
            {'$and': [ {'tag':tagId}, {'associationType':associationType} ]} );
        SimLokiDatabaseActions.delete('tag-device-mapping', record);
        if(tagName==undefined) {
            tagName = (SimLokiDatabaseActions.getFilteredRecordHandler('tag', {'id':tagId}))[0].name;
        }
        //add the mapping of devices to tag
        for(var j=0;j<deviceList.length; j++) {
            var obj = {'tag':tagId, 'device':deviceList[j].id, 'associationType':associationType, 'tagName':tagName};
            //there should and can be only 1 device-tag combination. Either dynamic or static. Not both. The latest will be used.
            record = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',
                {'$and': [ {'tag':tagId}, {'device':deviceList[j].id} ]} );
            SimLokiDatabaseActions.delete('tag-device-mapping', record);
            SimLokiDatabaseActions.insert('tag-device-mapping', obj);
        }
    }

    function mapTagsToDevice(tagList, deviceId) {
        var associationType = 'STATIC,MIXED';
        //add the mapping of device to tags. This is when manually tags are assinged to device.
        //remove all the mappings to this device first, as it can be an empty tagList, which means no tag should be assigned.
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping', {'device':deviceId});
        if(record && record.length) {
            for(var i=0; i<record.length; i++) {
                SimLokiDatabaseActions.delete('tag-device-mapping', record[i]);
            }
        }
        //add the mapping of tags to the device
        for(var j=0;j<tagList.length; j++) {
            var tagId = tagList[j];
            var tagName = (SimLokiDatabaseActions.getFilteredRecordHandler('tag', {'id':tagId}))[0].name;
            var obj = {'tag':tagId, 'device':deviceId, 'associationType':associationType, 'tagName':tagName};
            SimLokiDatabaseActions.insert('tag-device-mapping', obj);
        }
    }

    function getMemberAssociation(urlAction) {
        var tagId = urlAction.action.id;
        if(urlAction.filter['memberAssociationType'] == 'DYNAMIC') {
            records = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',
                {'$and': [ {'tag':tagId}, {'associationType':'DYNAMIC'} ]} );
        } else {
            records = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',
                {'$and': [ {'tag':tagId}, {'associationType':'STATIC,MIXED'} ]} );
        }
        return records;
    }

    function arrayUnion() {
        var args = Array.prototype.slice.call(arguments);
        var it = args.pop();
        return _.uniq(_.flatten(args, true), it);
    }

});
