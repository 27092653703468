define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SensorTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SensorResults',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testresults',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testSuite',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/sensorsidebar',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/DeviceTestDetails',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testresults/FailedResultCountByTag_testtype',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testresults/FailedResultCountByTag_site',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/dhcp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/dns',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/ping',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/radius',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/mailserver',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/outlookserver',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/webserver',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/filetransfer',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/IpslaSender',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/speed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/performance/onboarding',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ONBOARDING_DHCP_DNS_WebServer',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ONBOARDING_DHCP_DNS',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ONBOARDING_DHCP_DNS_Ping_Radius_FileTransfer_WebServer_MailServer',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ONBOARDING_DHCP_DNS_Ping',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SsidTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SensorRecommendationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ControlSensorDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testResultDetails',
    'core/../../../public/libs/node_modules/moment',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/sensorsummarylist',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/sensorListTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SensorTimeSlider',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/SensorFailurePercentByTest',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/kpiApAnalysis',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/kpiTrendAnalysis',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/sensorTestResultsListAnalysis',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/drillDownFailureStats',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/drillDownRfAnalysisByTest',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/heatMapAnalysis_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/testResultFailureStats',



], function (SimLokiDatabaseActions, DefaultConfig, UtilityFunctions, SensorTemplate, SensorResults,
    testresults, testSuite, sensorsidebar, DeviceTestDetails, TestResults_FailedResultCountByTag_testtype,
    TestResults_FailedResultCountByTag_site, dhcpPerformance, dnsPerformance, pingPerformance,
    radiusPerformance, mailserverPerformance, outlookserverPerformance, webserverPerformance, filetransferPerformance,
    IpslaSenderPerformance, speedPerformance, onboardingPerformance,
    ONBOARDING_DHCP_DNS_WebServer, ONBOARDING_DHCP_DNS,
    ONBOARDING_DHCP_DNS_Ping_Radius_FileTransfer_WebServer_MailServer, ONBOARDING_DHCP_DNS_Ping, SsidTemplate,
    SensorRecommendationTemplate, ControlSensorDetailTemplate, SimTaskDataGenerator, testResultDetailsTemplate, moment, sensorsummarylist,
    sensorListTemplate, SensorTimeSlider, SensorFailurePercentByTest, kpiApAnalysis, kpiTrendAnalysis, sensorTestResultsListAnalysis,
    drillDownFailureStats, drillDownRfAnalysisByTest, heatMapAnalysis_Template, testResultFailureStats
) {

        function getSensorSuite() {
            var sensorDetails = SensorTemplate.Sensor_Details_Template;
            var networkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': DefaultConfig.NetworkDevice.AP_FAMILY });
            if (networkObj && networkObj.length) {
                sensorDetails[0].sensors[0].macAddress = networkObj[0].macAddress;
                sensorDetails[0].sensors[0].name = networkObj[0].hostname;
                sensorDetails[0].startTime = UtilityFunctions.getTimeStamp() - 10000;
                sensorDetails[0].lastModifiedTime = UtilityFunctions.getTimeStamp() + 100000;
                var ssidObj = SimLokiDatabaseActions.getAll('wlan-ssid');
                if (ssidObj && ssidObj.length) {
                    sensorDetails[0].ssids[0].profileName = ssidObj[0].profileName;
                    sensorDetails[0].ssids[0].ssid = ssidObj[0].profileName;
                    sensorDetails[0].tests[0].wlans[0] = ssidObj[0].profileName;
                    var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': ssidObj[0].managedSites[0] });
                    if (siteObj && siteObj.length) {
                        sensorDetails[0].location = siteObj[0].id;
                        sensorDetails[0].siteHierarchy = siteObj[0].groupNameHierarchy;
                    }
                }
            }
            return sensorDetails;
        }

        function getSensorResults() {
            var sensorResults = SensorResults.Results_Detail_Template;
            return sensorResults;
        }

        function updatetestResults(jsonObj) {
            var updatedresults = {};
            var testResultDetails = {};
            var testResults = [];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler("sensor-test-results", { 'testSuiteId': jsonObj._id });
            if (record && record.length > 0) {
                var mostRecentObj = record[0].testResultDetails.mostRecent;
                var detailResultsObj = record[0].testResultDetails.detailResults;
                for (var i = 0; i < jsonObj.tests.length; i++) {
                    var testobj = {};
                    testobj["id"] = i + 1;
                    testobj["testType"] = jsonObj.tests[i].name.toLowerCase();
                    // testobj["status"]="pass";
                    testobj["status"] = "none";
                    for (var j = 0; j < mostRecentObj.testResults.length; j++) {
                        if (jsonObj.tests[i].name.toLowerCase() === mostRecentObj.testResults[j].testType.toLowerCase()) {
                            testobj["status"] = mostRecentObj.testResults[j].status;
                        }
                    }
                    testResults.push(testobj);

                }
                for (var j = 0; j < mostRecentObj.testResults.length; j++) {
                    if (mostRecentObj.testResults[j].testType.toLowerCase() === "onboarding" || mostRecentObj.testResults[j].testType.toLowerCase() === "dhcp") {
                        testResults.push(mostRecentObj.testResults[j]);
                    }
                }

                var mostRecent = {};
                var d = new Date();
                var t = d.setMinutes(d.getMinutes() - 240);
                mostRecent["lastRun"] = mostRecentObj.lastRun;
                mostRecent["testResults"] = testResults;

                var detailResults = {};
                for (var i = 0; i < jsonObj.tests.length; i++) {

                    detailResults[jsonObj.tests[i].name.toLowerCase()] = {};
                    for (key in detailResultsObj) {
                        if (key.toLowerCase() === jsonObj.tests[i].name.toLowerCase()) {
                            if (detailResultsObj[key].detailList) {
                                detailResults[key] = detailResultsObj[key];
                            }
                        }
                    }
                }

                for (key in detailResultsObj) {
                    if (key.toLowerCase() === "onboarding" || key.toLowerCase() === "dhcp") {
                        detailResults[key] = detailResultsObj[key];
                    }
                }

                testResultDetails["mostRecent"] = mostRecent;
                testResultDetails["detailResults"] = detailResults;
                //updatedresults["testSuiteId"]=jsonObj._id;
                record[0].testResultDetails = testResultDetails;

                SimLokiDatabaseActions.update("sensor-test-results", record[0]);

                return;

            }

        }

        return {

            init: function () {
                createTestSuite();
                createSensorSummaryList();
            },

            updateTestSuite: function (urlAction) {

                var resultObj = SimTaskDataGenerator.createTask('SPFService');
                if (urlAction.restPayload != undefined && urlAction.restPayload != '') {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-test-suite', { '_id': urlAction.restPayload._id });
                    if (recordObj != undefined && recordObj.length > 0) {
                        var jsonObj = urlAction.restPayload;
                        SimLokiDatabaseActions.update('sensor-test-suite', jsonObj);
                        updatetestResults(jsonObj);
                    }
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

                return resultObj;
                /*  if (operation == "put") {
                      var record = SimLokiDatabaseActions.getFilteredRecordHandler(type,{'_id': jsonObj._id});
                      if(record && record.length > 0)
                      {
                       SimLokiDatabaseActions.update(type, jsonObj);
                        updatetestResults(jsonObj);
                        return;
                      }
          
                  } */
            },

            processDelete: function (urlAction) {
                var resultObj = SimTaskDataGenerator.createTask('SPFService');


                var _id = urlAction.service[2];
                if (_id != undefined && _id != "") {
                    var testsuiteObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-test-suite', { '_id': _id });
                    if (testsuiteObj && testsuiteObj.length) {
                        SimLokiDatabaseActions.delete('sensor-test-suite', testsuiteObj);
                    }
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
                return resultObj;
            },

            processGet: function (urlAction) {
                if (urlAction.service.indexOf('testSuite') >= 0 && urlAction.service.indexOf('name') >= 0) {
                    return { "status": "No testsuite found for given Name" };
                }


                //sensor list
                console.log("assurance sensor file")
                console.log(urlAction);
                /*function sensorListCollection() {
                    var sensordevicelist = DeviceTestDetails.Template;
                    let sensorListResponseArr = [];
                    let sensorListTemp = JSON.parse(JSON.stringify(sensorListTemplate.sensorListTemplate));

                    for (key in sensordevicelist.sensorDeviceTestDetailsList) {

                        let sensorListResponse = JSON.parse(JSON.stringify(sensorListTemp.response[0]));

                        var tDeviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': sensordevicelist.sensorDeviceTestDetailsList[key].sensorName });
                        console.log("tDeviceDetail : ",tDeviceDetail);
                        sensorListResponse.name = tDeviceDetail[0].name;
                        sensorListResponse.ethernetMacAddress = tDeviceDetail[0].macAddress;
                        sensorListResponse.location = sensordevicelist.sensorDeviceTestDetailsList[key].location;
                        sensorListResponse.serialNumber = tDeviceDetail[0].serialNumber;
                        sensorListResponse.ipAddress = tDeviceDetail[0].managementIpAddress;
                        sensorListResponse.version = tDeviceDetail[0].softwareVersion;
                        sensorListResponse.radioMacAddress = tDeviceDetail[0].macAddress;
                        sensorListResponse.lastSeen = new Date();;
                        //sensorListResponse.type = tDeviceDetail[0].type;

                        if (tDeviceDetail.length > 0) {
                            if (tDeviceDetail[0].reachabilityStatus == 'Reachable') {
                                sensorListResponse.status = "RUNNING";
                            } else {
                                sensorListResponse.status = "UNREACHABLE";
                            }
                        } else {
                            sensorListResponse.status = "IDLE";
                        }
                        sensorListResponseArr.push(sensorListResponse);
                    }
                    sensorListTemp.response = [];
                    sensorListTemp.response.push(...sensorListResponseArr);
                    return sensorListTemp;
                }*/

                function percentage(partialValue, totalValue) {
                    return (100 * partialValue) / totalValue;
                }

                /*function createSensorSummaryList(){
                    console.log("createSensorSummaryList insert : ");
                    var sensorListTempCollection = sensorListCollection();
                    SimLokiDatabaseActions.insert('sensor-list-summary', sensorListTempCollection);
                }*/

                if (urlAction.service.indexOf('list') >= 0) {
                   let sensorListTempDB = SimLokiDatabaseActions.getAll('sensor-list-summary');

                   var sensorListTemp = {"version": "1.0","response": sensorListTempDB};

                    if (urlAction.filter['status'] == '') {
                        var returnedData = $.grep(sensorListTemp.response, function (element, index) {
                            return element.location.indexOf(urlAction.filter['siteHierarchy']) !== -1;
                        });

                        sensorListTemp.response = [];
                        sensorListTemp.response.push(...returnedData);
                        return sensorListTemp;
                    } else {
                        var returnedData = $.grep(sensorListTemp.response, function (element, index) {
                            return (element.status.toLowerCase() == urlAction.filter['status'] && element.location.indexOf(urlAction.filter['siteHierarchy']) !== -1);
                        });

                        sensorListTemp.response = [];
                        sensorListTemp.response.push(...returnedData);
                        sensorListTemp.response
                        return sensorListTemp;
                    }

                } else if (urlAction.url.indexOf('summary') >= 0) {

                   let sensorListTempDB = SimLokiDatabaseActions.getAll('sensor-list-summary');

                   var sensorListTemp = {"version": "1.0","response": sensorListTempDB};
                   
                    var returnedData = $.grep(sensorListTemp.response, function (element, index) {
                        return element.location.indexOf(urlAction.filter['siteHierarchy']) !== -1;
                    });

                    sensorListTemp.response = [];
                    sensorListTemp.response.push(...returnedData);

                    let sensorSummaryTemp = JSON.parse(JSON.stringify(sensorsummarylist.sensorListSummaryTemplate));


                    sensorSummaryTemp.response.count = sensorListTemp.response.length;

                    sensorSummaryTemp.response.details[0].count = sensorListTemp.response.filter((obj) => obj.status === sensorSummaryTemp.response.details[0].status).length;
                    sensorSummaryTemp.response.details[0].percentage = percentage(sensorSummaryTemp.response.details[0].count, sensorSummaryTemp.response.count);

                    sensorSummaryTemp.response.details[1].count = sensorListTemp.response.filter((obj) => obj.status === sensorSummaryTemp.response.details[1].status).length;
                    sensorSummaryTemp.response.details[1].percentage = percentage(sensorSummaryTemp.response.details[1].count, sensorSummaryTemp.response.count);

                    sensorSummaryTemp.response.details[2].count = sensorListTemp.response.filter((obj) => obj.status === sensorSummaryTemp.response.details[2].status).length;
                    sensorSummaryTemp.response.details[2].percentage = percentage(sensorSummaryTemp.response.details[2].count, sensorSummaryTemp.response.count);
                    //console.log('res :', res);
                    return sensorSummaryTemp;
                }

                if (urlAction.service.indexOf('testSuite') >= 0 && urlAction.service.indexOf('locations') >= 0) {
                    var tsites = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': 'Flr-SJC1-1' });

                   
                    var resp = [{"locationId":tsites[0].id,"locationType":"floor","allSensors":true,"siteHierarchy":tsites[0].groupNameHierarchy,"macAddressList":[],"testSuiteId":"5e502b2922b97b0020f812b1","testSuiteName":"Sensor_Test_01"}]
                    return resp
                }

                if(urlAction.service.indexOf('sensors') >= 0 && urlAction.service.indexOf('upgradelist') >= 0) {
                    return []
                }

                if (urlAction.service.indexOf('testSuite') >= 0) {
                    var d = new Date();
                    var t = d.setMinutes(d.getMinutes() - 30);
                    var tJson = getAllTestSuites();
                    for (var i = 0; i < tJson.length; i++) {
                        tJson[i].startTime = t;
                        tJson[i].lastModifiedTime = d.getTime();
                    }
                    var testsuitId = ((urlAction.url.split('?'))[0].split('testSuite/'))[1];
                    if (testsuitId != undefined) {
                        for (var i = 0; i < tJson.length; i++) {
                            if (testsuitId === tJson[i]._id) {
                                var tObj = tJson[i];
                                for (var k = 0; k < tObj.tests.length; k++) {
                                    tObj.tests[k].ssids = tObj.ssids;
                                }
                                return tObj;
                            }
                        }
                    }
                    else {
                        return tJson;
                    }
                }
                if (urlAction.service.indexOf('ssid') >= 0) {
                    var resJson = [];
                    var dbObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', { 'namespace': { '$in': 'wlan' } });
                    for (var m = 0; m < dbObj.length; m++) {
                        var jObj = dbObj[m];
                        for (i = 0; jObj.profileAttributes != undefined && i < jObj.profileAttributes.length; i++) {
                            if (jObj.profileAttributes[i].key == 'wireless.ssid') {
                                var tJson = JSON.parse(JSON.stringify(SsidTemplate.Ssid_Details_Template));
                                tJson.ssid = jObj.profileAttributes[i].value;
                                tJson.profileName = jObj.profileAttributes[i].value;//jObj.name;
                                resJson.push(tJson);
                            }
                        }
                    }
                    return { "version": "1.0", "response": resJson };
                }
                if (urlAction.service.indexOf('sensorresult') >=0 && urlAction.service.indexOf('sensorinfo') >=0){
                    let id,sensorListTempDB
                    id = urlAction.filter.uuid
                    if(id && id !=""){
                        sensorListTempDB = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary',{'id':id});
                    }else {
                        sensorListTempDB = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary',{'radioMacAddress':urlAction.filter.sensorMac});
                    }
                    return {"version": "1.0","response": sensorListTempDB};
                }
                if (urlAction.service.indexOf('sensors') >=0 && urlAction.service.indexOf('sensorneighbors') >=0){
                    return {"2ghzneighbors":[],"5ghzneighbors":[]};
                }

                /* if (urlAction.service.indexOf('sensorrecommendation') >= 0) {
                    var siteId = ((urlAction.url.split('?'))[0].split('sensorrecommendation/'))[1];
                    var totalAPs = SimLokiDatabaseActions.getAll('access-point');
                    var completeApList = [];
                    var sensorList = [];
                    var tObj = [];
                    var recommendedAPs = {};
                    var uncoveredAPList = [];
                    var clientCount = [];

                    var testSuite = SimLokiDatabaseActions.getAll('sensor-test-suite');

                    for (var j = 0; j < testSuite.length; j++) {

                        var senList = testSuite[j].sensors;
                        for (var k = 0; k < senList.length; k++) {

                            var sensorObj = {};
                            sensorObj["name"] = senList[0].name;
                            sensorObj["macAddress"] = senList[0].macAddress;
                            sensorObj["assigned"] = false;
                            sensorObj["status"] = null;
                            sensorObj["xorSensor"] = false;
                            sensorList.push(sensorObj);
                        }
                    }


                    for (var i = 0; i < totalAPs.length; i++) {
                        tObj = {};
                        var apDetails = totalAPs[i].attributes;
                        var reachabilityStatus = apDetails.reachabilityStatus;
                        if (apDetails.locationGroup === siteId) {
                            var clients_2_4 = this.getClientCount(apDetails.name, "2.4");
                            var clients_5 = this.getClientCount(apDetails.name, "5.0");
                            var totalClients = clients_2_4 + clients_5;
                            tObj["name"] = apDetails.name;
                            tObj["macAddress"] = apDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = totalAPs[i].status;
                            tObj["radio_2_4_ClientCount"] = clients_2_4;
                            tObj["radio_5_ClientCount"] = clients_5;
                            tObj["clientCount"] = totalClients;
                            tObj["deviceType"] = "AP-IN-LOCAL";
                            tObj["neighborAps"] = [];
                            tObj["usedByTestList"] = UtilityFunctions.getUsedbyTestSuiteList(testSuite, apDetails.name);
                            tObj["reachable"] = false //reachabilityStatus.toUpperCase()==="REACHABLE"?true:false;
                            tObj["supportedForSensorTest"] = true;

                            for (var k = 0; k < totalAPs.length; k++) {
                                if (apDetails.locationGroup === totalAPs[k].attributes.locationGroup && apDetails.macaddress != totalAPs[k].attributes.macaddress) {
                                    var neighborObj = {};
                                    neighborObj["bssid"] = totalAPs[k].attributes.macaddress;
                                    neighborObj["apname"] = totalAPs[k].attributes.name;
                                    neighborObj["radio"] = totalAPs[k].radios[0].metrics.numberOfClients;
                                    neighborObj["band"] = "2.4GHz";
                                    neighborObj["rssi"] = UtilityFunctions.getRandomIntForRangeValues(-30, -50);
                                    tObj["neighborAps"].push(neighborObj)
                                }
                            }
                            completeApList.push(tObj);
                        }
                        else {
                            tObj["name"] = apDetails.name;
                            tObj["macAddress"] = apDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = totalAPs[i].status;
                            uncoveredAPList.push(tObj);
                        }
                    };

                    var totalSensor = SimLokiDatabaseActions.getAll('sim-sensor');

                    for (var i = 0; i < totalSensor.length; i++) {
                        var sensorDetails = totalSensor[i].attributes;
                        var reachabilityStatus = sensorDetails.reachabilityStatus;
                        if (sensorDetails.locationGroup === siteId) {
                            tObj = {};
                            tObj["name"] = sensorDetails.name;
                            tObj["macAddress"] = sensorDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = sensorDetails.status;
                            tObj["radio_2_4_ClientCount"] = 0;
                            tObj["radio_5_ClientCount"] = 0;
                            tObj["clientCount"] = 0;
                            tObj["deviceType"] = sensorDetails.typeString;
                            tObj["neighborAps"] = [];
                            tObj["supportedForSensorTest"] = true;
                            tObj["usedByTestList"] = UtilityFunctions.getUsedbyTestSuiteList(testSuite, sensorDetails.name);
                            tObj["reachable"] = false //reachabilityStatus.toUpperCase()==="REACHABLE"?true:false;

                            for (var k = 0; k < totalAPs.length; k++) {
                                if (apDetails.locationGroup === totalAPs[k].attributes.locationGroup && apDetails.macaddress != totalAPs[k].attributes.macaddress && k < 2) {
                                    var neighborObj = {};
                                    neighborObj["bssid"] = totalAPs[k].attributes.macaddress;
                                    neighborObj["apname"] = totalAPs[k].attributes.name;
                                    neighborObj["radio"] = totalAPs[k].radios[0].metrics.numberOfClients;
                                    neighborObj["band"] = "2.4GHz";
                                    neighborObj["rssi"] = UtilityFunctions.getRandomIntForRangeValues(-30, -50);
                                    tObj["neighborAps"].push(neighborObj);
                                }
                            }
                        }
                        completeApList.push(tObj);
                    }

                    recommendedAPs["key"] = ((urlAction.url.split('?'))[0].split('sensorrecommendation/'))[1];
                    recommendedAPs["sensorList"] = sensorList;
                    recommendedAPs["uncoveredAPList"] = uncoveredAPList;
                    recommendedAPs["completeApList"] = completeApList;
                    // console.log("recommendedAPslist : ",recommendedAPs);
                    return recommendedAPs;
                }*/

                //api/na/v1/control/sensors/2c:d0:2d:8b:d3:60/detail
                if (urlAction.service.indexOf('sensors') >= 0 && urlAction.service.indexOf('detail') >= 0) {
                    var tJson = ControlSensorDetailTemplate.DetailTemplate;
                    return tJson;
                    /*var macAddr = (urlAction.url.split('/'))[5];
                    var list = SimLokiDatabaseActions.getAll('access-point');
                    var tObj = {};
                    for(var i=0;i<list.length;i++) {
                        if(list[i].attributes.macaddress==macAddr) {
                            //some of the field below dont exist. So how do we map??
                            tObj.instanceUuid = list[i].instanceUuid;
                            tObj.macAddress = list[i].macAddress;
                            tObj.model = list[i].model;
                            tObj.managementIpAddress = list[i].ipAddress;
                            tObj.hostname = list[i].hostname;
                            tObj.family = list[i].family;
                            tObj.series = list[i].series;
                            tObj.registerTime = list[i].registerTime;
                            tObj.lastUpdatedTime = list[i].lastUpdatedTime;
                            tObj.testConfigVersion = list[i].testConfigVersion;
                            tObj.sensorType = list[i].sensorType;
                            tObj.rawServiceDomain = list[i].rawServiceDomain;
                            tObj.xcoordinate = list[i].xcoordinate;
                            tObj.ycoordinate = list[i].ycoordinate;
                            tObj.zcoordinate = list[i].zcoordinate;
                            return tObj;
                        }
                    } */
                }
            },

            DashboardSensorSidebarDataCollection: function (urlAction) {
                var testSuiteId = urlAction.restPayload.testSuiteId;
                var testSuiteTestName = urlAction.restPayload.testName;
                var testStartTime = urlAction.restPayload.startTime;
                var testEndTime = urlAction.restPayload.endTime;
                var response = { testResultDetails: [], testResultStatusCounts: {} };

                var tJson = JSON.parse(JSON.stringify(sensorsidebar.Template));
                var i, j;


                var testresultStartTime = parseInt(UtilityFunctions.getCookievalue("testresultStartTime"));

                /* getting clicked interval index*/
                var intervalIndex = ((Math.abs(new Date(new Date(testStartTime)) - new Date(new Date(testEndTime)))) / 1000) / 60;

                var testresultInterval = (((Math.abs(new Date(new Date(testresultStartTime)) - new Date(new Date(testEndTime)))) / 1000) / 60) / intervalIndex;

                /*getting clicked test interval value by interval index */
                var sensorTestResultJson = SimLokiDatabaseActions.getAll('sensor-test-results');


                for (var i = 0; i < sensorTestResultJson.length; i++) {
                    if (sensorTestResultJson[i].testSuiteId === urlAction.restPayload.testSuiteId) {
                        var totalIntervalObj = sensorTestResultJson[i].testResultDetails.detailResults[testSuiteTestName].detailList;
                        var totalIntervalCount = Object.keys(totalIntervalObj).length;

                        var testresultIntervalIndex = totalIntervalCount - (testresultInterval - 1);
                        testresultIntervalIndex = "interval" + testresultIntervalIndex;

                        var resultCountDetails = sensorTestResultJson[i].testResultDetails.detailResults[testSuiteTestName].detailList[testresultIntervalIndex];
                        break;
                    }
                }

                /*create the sidebar data based on interval counts*/
                for (j = 0; j < tJson.testResultDetails.length; j++) {
                    if (testSuiteId == tJson.testResultDetails[j].id && testSuiteTestName == tJson.testResultDetails[j].testName) {
                        var totalCounts = resultCountDetails.resultCountDetails.passCount + resultCountDetails.resultCountDetails.failureCount + resultCountDetails.resultCountDetails.slowCount;
                        for (var i = 0; i < totalCounts; i++) {
                            response.testResultDetails.push(JSON.parse(JSON.stringify(tJson.testResultDetails[j])));
                        }
                        response.testResultStatusCounts = JSON.parse(JSON.stringify(tJson.testResultStatusCounts));
                        break;
                    }
                }

                /*change the result based interval pass,failure, slow count*/
                for (var p = 0; p < resultCountDetails.resultCountDetails.passCount; p++) {
                    response.testResultDetails[p].result = "pass";
                }

                var failStartNumber = resultCountDetails.resultCountDetails.passCount;
                var failTotalCount = resultCountDetails.resultCountDetails.passCount + resultCountDetails.resultCountDetails.failureCount;
                for (var f = failStartNumber; f < failTotalCount; f++) {
                    response.testResultDetails[f].result = "fail";
                }

                var slowStartNumber = failTotalCount;
                var slowTotalCount = failTotalCount + resultCountDetails.resultCountDetails.slowCount;

                for (var s = slowStartNumber; s < slowTotalCount; s++) {
                    response.testResultDetails[s].result = "slow";
                }

                return response;

            },
            processPost: function (urlAction) {
                if( urlAction.service.indexOf('control') && urlAction.service.indexOf('sensors')){

                    if( 'sshState' in urlAction.restPayload ){
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary', { 'radioMacAddress': urlAction.service[3] });
                        if (recordObj != undefined && recordObj.length > 0) {
                            recordObj[0].sshConfig.sshState = urlAction.restPayload.sshState;
                            recordObj[0].sshConfig.sshUserName = urlAction.restPayload.sshUserName;
                            recordObj[0].sshConfig.sshPassword = urlAction.restPayload.sshPassword;
                            recordObj[0].sshConfig.enablePassword = urlAction.restPayload.enablePassword;

                            SimLokiDatabaseActions.update('sensor-list-summary', recordObj);
                            var tJson = SimLokiDatabaseActions.getAll('sensor-test-results');
                            return tJson;
                        }

                    }else if( 'led' == urlAction.service[3] ){
                        urlAction.restPayload.sensorLEDConfigList.forEach((element, index, array) => {
                            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary', { 'radioMacAddress': element.sensorMac });
                            if (recordObj != undefined && recordObj.length > 0) {
                                recordObj[0].isLEDEnabled = element.isLEDEnabled;

                                SimLokiDatabaseActions.update('sensor-list-summary', recordObj);
                            }
                        });
                        var tJson = SimLokiDatabaseActions.getAll('sensor-test-results');
                        return tJson;
                    }else if( 'hostname' == urlAction.service[3] ){
                        urlAction.restPayload.sensorHostNameConfigList.forEach((element, index, array) => {
                            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary', { 'radioMacAddress': element.sensorMac });
                            if (recordObj != undefined && recordObj.length > 0) {
                                recordObj[0].name = element.hostName;

                                SimLokiDatabaseActions.update('sensor-list-summary', recordObj);
                                var tJson = SimLokiDatabaseActions.getAll('sensor-test-results');
                                return tJson;
                            }
                        });
                        var tJson = SimLokiDatabaseActions.getAll('sensor-test-results');
                        return tJson;
                    }
                }

                if (urlAction.service.indexOf('sidebar') >= 0) {
                    var testSuiteId = urlAction.restPayload.testSuiteId;
                    var testSuiteTestName = urlAction.restPayload.testName;
                    // var d = new Date();
                    //var t = d.setMinutes(d.getMinutes() - 240);//4 hour reducing..
                    var testStartTime = urlAction.restPayload.startTime;
                    var testEndTime = urlAction.restPayload.endTime;
                    //var t = UtilityFunctions.randomTimeBetweenDates(new Date(testStartTime), new Date(testEndTime));
                    var passCount = slowCount = failCount = 0;

                    if (testSuiteTestName == undefined || testSuiteTestName == "") {
                        var response = { testResultDetails: [], testResultStatusCounts: {} };
                        var tJson = JSON.parse(JSON.stringify(sensorsidebar.Template));
                        for (var i = 0; i < tJson.testResultDetails.length; i++) {
                            if (testSuiteId == tJson.testResultDetails[i].id) {
                                response.testResultDetails.push(JSON.parse(JSON.stringify(tJson.testResultDetails[i])));
                            }
                            response.testResultStatusCounts = JSON.parse(JSON.stringify(tJson.testResultStatusCounts));
                        }
                    } else {
                        var response = JSON.parse(JSON.stringify(this.DashboardSensorSidebarDataCollection(urlAction)));
                    }

                    /*add dynamic data in the response*/
                    for (var i = 0; i < response.testResultDetails.length; i++) {
                        if (response.testResultDetails[i].result == "pass") {
                            passCount = passCount + 1;
                            response.testResultDetails[i].activeRfScan = {};
                            response.testResultDetails[i].activeRfScan.activeScanRssi = "-48";
                            response.testResultDetails[i].activeRfScan.activeScanSnr = "43";
                            response.testResultDetails[i].activeRfScan.activeScanTxDataRate = "104000000";
                            response.testResultDetails[i].activeRfScan.activeScanRxDataRate = "156000000";
                            response.testResultDetails[i].activeRfScan.activeScanTxBytes = "0";
                            response.testResultDetails[i].activeRfScan.activeScanRxBytes = "0";
                            response.testResultDetails[i].activeRfScan.activeScanRetries = "0";
                            response.testResultDetails[i].activeRfScan.activeScanPacketLoss = "0";
                            response.testResultDetails[i].totalTime = null;
                        } else if (response.testResultDetails[i].result == "slow") {
                            slowCount = slowCount + 1;
                            response.testResultDetails[i].activeRfScan = {};
                            response.testResultDetails[i].activeRfScan.activeScanRssi = "-48";
                            response.testResultDetails[i].activeRfScan.activeScanSnr = "43";
                            response.testResultDetails[i].activeRfScan.activeScanTxDataRate = "104000000";
                            response.testResultDetails[i].activeRfScan.activeScanRxDataRate = "156000000";
                            response.testResultDetails[i].activeRfScan.activeScanTxBytes = "0";
                            response.testResultDetails[i].activeRfScan.activeScanRxBytes = "0";
                            response.testResultDetails[i].activeRfScan.activeScanRetries = "0";
                            response.testResultDetails[i].activeRfScan.activeScanPacketLoss = "0";
                            response.testResultDetails[i].totalTime = "11176";
                        } else {
                            failCount = failCount + 1;
                            response.testResultDetails[i].activeRfScan = null;
                            response.testResultDetails[i].details = [];
                            var failDetails = {};
                            failDetails.errorCode = "1141";
                            failDetails.title = "EAP Error";
                            failDetails.fullDescription = "802.1x/EAP authentication failed because username is not found";
                            failDetails.suggestedActions = [];
                            response.testResultDetails[i].details.push(failDetails);
                            response.testResultDetails[i].totalTime = null;
                        }

                        response.testResultDetails[i].channel = "0";
                        response.testResultDetails[i].url = "";
                        response.testResultDetails[i].ipAddress = null;
                        response.testResultDetails[i].performanceValues = null;
                        response.testResultDetails[i].testTime = UtilityFunctions.randomTimeBetweenDates(new Date(testStartTime), new Date(testEndTime));

                    }

                    response.testResultStatusCounts.totalTestCount = passCount + slowCount + failCount;
                    response.testResultStatusCounts.passCount = passCount;
                    response.testResultStatusCounts.slowCount = slowCount;
                    response.testResultStatusCounts.failDetails["1141"].count = failCount;

                    if (failCount == 0) {
                        response.testResultStatusCounts.failDetails = {};
                    }



                    if (testSuiteTestName == "onboarding") {
                        response.threshold = 10000;
                    } else {
                        response.threshold = 2000;
                    }

                    return response;
                }
                if (urlAction.service.indexOf('testresults') >= 0 && urlAction.service.indexOf('getTestSuites') >= 0) {
                    var d = new Date();
                    var t = d.setMinutes(d.getMinutes() - 30);
                    let ssid = urlAction.restPayload.ssid;
                    let band = urlAction.restPayload.band;
                    (band && band.length > 0) ? band[0] = band[0].replace(/ /g, '') : "";
                    var tJson1 = undefined;
                    var tJson2 = [];
                    var tJson = [];

                    tJson1 = getAllTestSuites();

                    //applying filter for ssid if selected
                    if (ssid && ssid.length > 0) {
                        for (let i = 0; i < tJson1.length; i++) {
                            let tObj = tJson1[i];
                            let ssids = tJson1[i].ssids;
                            for (let j = 0; j < ssids.length; j++) {
                                if (ssid[0] == ssids[j].ssid) {
                                    tJson2.push(tObj);
                                    break;
                                }
                            }
                        }
                    } else {
                        tJson2 = tJson1;
                    }

                    //applying filter for band if selected
                    if (band && band.length > 0) {
                        for (let i = 0; i < tJson2.length; i++) {
                            let tObj = tJson2[i];
                            let ssids = tJson2[i].ssids;
                            for (let j = 0; j < ssids.length; j++) {
                                if (band[0] == ssids[j].bands || ssids[j].bands.toUpperCase() == "BOTH") {
                                    tJson.push(tObj);
                                    break;
                                }
                            }
                        }
                    } else {
                        tJson = tJson2;
                    }

                    for (var i = 0; i < tJson.length; i++) {
                        tJson[i].startTime = t;
                        tJson[i].lastModifiedTime = d.getTime();
                    }
                    var testsuitId = ((urlAction.url.split('?'))[0].split('testSuite/'))[1];
                    if (testsuitId != undefined) {
                        for (var i = 0; i < tJson.length; i++) {
                            if (testsuitId === tJson[i]._id) {
                                var tObj = tJson[i];
                                for (var k = 0; k < tObj.tests.length; k++) {
                                    tObj.tests[k].ssids = tObj.ssids;
                                }
                                return tObj;
                            }
                        }
                    }
                    else {
                        return tJson;
                    }
                }
                if (urlAction.service.indexOf('testresults') >= 0) {
                    var testSuiteId = urlAction.restPayload.testSuiteId;
                    var response = { testResultDetails: [] };

                    if (urlAction.service.indexOf('getResultCountByStatus') >= 0) {
                        let ssid = urlAction.restPayload.ssid;
                        let band = urlAction.restPayload.band;
                        if (ssid && ssid.length > 0 || band && band.length > 0) {
                            // Random Default value relatively lesser than the overall value for filtered ssid
                            if (ssid.length > 0 && band.length > 0) {
                                return { "passCount": 8997, "failureCount": 105, "slowCount": 10 };
                            } else if (ssid.length > 0) {
                                return { "passCount": 9997, "failureCount": 155, "slowCount": 20 };
                            } else {
                                return { "passCount": 10997, "failureCount": 205, "slowCount": 24 };
                            }
                        }
                        else {
                            return { "passCount": 29615, "failureCount": 342, "slowCount": 33 };
                        }
                    }
                    else if (urlAction.service.indexOf('getFailedResultCountByTag') >= 0) {
                        var response = { "issueCount": 0, "failureCountByTagList": [] };
                        if (urlAction.filter['tag'] == 'testtype') {
                            response = TestResults_FailedResultCountByTag_testtype.Template;
                        } else if (urlAction.filter['tag'] == 'site') {
                            response = TestResults_FailedResultCountByTag_site.Template;
                        } else if (urlAction.filter['tag'] == 'apmac') {
                            let ssid = urlAction.restPayload.ssid;
                            let band = urlAction.restPayload.band;
                            var deviceTestDetails = undefined;
                            if ((ssid && ssid.length > 0) || (band && band.length > 0)) {
                                if ((ssid && ssid.length > 0) && (band && band.length > 0)) {
                                    deviceTestDetails = DeviceTestDetails.both_filters;
                                } else if (ssid && ssid.length > 0) {
                                    deviceTestDetails = DeviceTestDetails.ssid_filter;
                                } else {
                                    deviceTestDetails = DeviceTestDetails.band_filter;
                                }
                            } else {
                                deviceTestDetails = DeviceTestDetails.Template;
                            }
                            for (var i = 0; i < deviceTestDetails.sensorDeviceTestDetailsList.length; i++) {
                                response.failureCountByTagList.push({ "tagName": deviceTestDetails.sensorDeviceTestDetailsList[i].sensorName, "count": deviceTestDetails.sensorDeviceTestDetailsList[i].testResultDetails.failureCount });
                            }
                        }
                        response.failureCountByTagList = UtilityFunctions.sortData('count', 'desc', response.failureCountByTagList);
                        return response;
                    } else if (urlAction.service.indexOf('getPerformanceResults') >= 0) {
                        return getPerformanceResults(urlAction);
                    } else if (urlAction.service.indexOf('getDeviceTestDetails') >= 0) {
                        return getDeviceTestDetails(urlAction);
                    }

                    if (urlAction.restPayload.types != undefined && urlAction.restPayload.types.length) {
                        return getTestResultsBasedOnTypes(urlAction);
                    }

                    if (urlAction.service.indexOf('details') >= 0) {

                        var detailsList = testResultDetailsTemplate.Template.testResultDetails;
                        var detailsObj = {};
                        detailsObj["testResultDetails"] = [];
                        for (var d = 0; d < detailsList.length; d++) {
                            if (detailsList[d].testName === urlAction.restPayload.testName) {
                                detailsObj.testResultDetails.push(detailsList[d]);
                            }
                        }
                        var sensorObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-test-suite', { '_id': urlAction.restPayload.testSuiteId });
                        for (var i = 0; i < detailsObj.testResultDetails.length; i++) {
                            detailsObj.testResultDetails[i].id = urlAction.restPayload.testSuiteId;
                            var sensors = [];
                            for (var j = 0; j < sensorObj[0].sensors.length; j++) {
                                var obj = {};
                                obj["name"] = sensorObj[0].sensors[j].name;

                                deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': sensorObj[0].sensors[j].name });
                                obj["ssid"] = sensorObj[0].sensors[j].ssid;
                                obj["band"] = sensorObj[0].sensors[j].band;
                                obj["sensorId"] = deviceObj[0].id;
                                sensors.push(obj);
                            }
                            detailsObj.testResultDetails[i].sensors = sensors;
                            detailsObj.testResultDetails[i].testName = urlAction.restPayload.testName;
                        }

                        return detailsObj;
                    }

                    var tJson = SimLokiDatabaseActions.getAll('sensor-test-results');
                    var d = new Date();
                    return tJson;
                }

                if (urlAction.service.indexOf('sensorcoverage') >= 0) {
                    var tJson = '{"percentage":75}';
                    return tJson;
                }

                if (urlAction.service.indexOf('testSuite') >= 0 && urlAction.service.indexOf('teststatus') >= 0) {
                    return '"response": { "status": 500, "content": "Http Error Code:500", "contentType": "text/plain; charset=UTF-8" }';
                }

                if (urlAction.service.indexOf('testSuite') >= 0 && urlAction.service.indexOf('undeploy') >= 0) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-test-suite',{'_id': urlAction.restPayload[0]});
                    recordObj[0].schedule = null;
                    recordObj[0].locationInfoList = [];
                    recordObj[0].location = null;
                    recordObj[0].siteHierarchy = null;
                    recordObj[0].frequency = null;
                    recordObj[0].tests = [];
                    recordObj[0].startTime = 0;
                    recordObj[0].lastModifiedTime = 0;
                    recordObj[0].sensors = [];
                    recordObj[0].numAssociatedSensor = 0;
                    SimLokiDatabaseActions.update('sensor-test-suite', recordObj[0])
                    return urlAction.restPayload[0]
                }
                if (urlAction.service.indexOf('testSuite') >= 0 && urlAction.service.indexOf('resourceManagement') >= 0) {
                    return 134;
                }
                //Save action
                if (urlAction.service.indexOf('testSuite') >= 0) {
                    var t = new Date();
                    var tJson = urlAction.restPayload;
                    if(tJson.meta != undefined){
                        tJson._id = UtilityFunctions.generateId(25);
                        tJson.startTime = t.getTime();
                        tJson.lastModifiedTime = t.getTime();
                        tJson.tenantId = UtilityFunctions.generateId(25);
                        tJson.version = 1;
                        tJson.rssiThreshold = tJson.apCoverage[0].rssiThreshold;
                        tJson.numNeighborAPThreshold = tJson.apCoverage[0].numberOfApsToTest;
                        if(tJson.locationInfoList.length<=0){
                            var tsites = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': 'Flr-SJC1-1' });
                        }else{
                            var tsites = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': tJson.location });
                        }
                        tJson.siteHierarchy = tsites[0].groupNameHierarchy;
                        tJson.wlans = [];
                        var wlansTest = [];
                        var band = tJson.apCoverage[0].bands;
                        // var band = tJson.ssids[0].bands;
                        for (var i = 0; i < tJson.ssids.length; i++) {
                            wlansTest.push(tJson.ssids[i].ssid);
                        }
                        var tResults = JSON.parse(JSON.stringify(testresults.Result_Template));
                        var count = 3;
                        tJson.tests = {}
                        for (var i = 0; i < tJson.tests.length; i++) {
                            tJson.tests[i].connection = tJson.connection;
                            tJson.tests[i].wlans = wlansTest;
                            tJson.tests[i].frequency = tJson.frequency;
                            tJson.tests[i].scheduleInDays = tJson.scheduleInDays;
                            tJson.tests[i].bands = band;
                            tJson.tests[i].numberOfApsToTest = tJson.apCoverage[0].numberOfApsToTest;
                            tJson.tests[i].repeatCountOnFailure = 2;
                            tJson.tests[i].ssids = tJson.ssids;
                            tJson.tests[i].testSuiteId = null;
                            tJson.tests[i].type = null;
                            
                            tResults.testResultDetails.mostRecent.testResults.push({ "id": count, "testType": tJson.tests[i].name.toLowerCase(), "status": "none" });
                            count = count + 1;
                            tResults.testResultDetails.detailResults[tJson.tests[i].name.toLowerCase()] = { "detailList": {} };
                        }
                        // Fix the Warning issue.
                        tJson.showWlcUpgradeBanner = false;
                        tJson.radioAsSensorRemoved = true;
                        delete tJson.$loki;
                        delete tJson.meta;

                        SimLokiDatabaseActions.insert('sensor-test-suite', tJson);
                        // Adding result
                        tResults.testSuiteId = tJson._id;
                        SimLokiDatabaseActions.insert('sensor-test-results', tResults);
                    }else{
                        var testTemp = JSON.parse(JSON.stringify(testresults.Create_Test_Suite_Template));
                        testTemp._id = UtilityFunctions.generateId(25);
                        testTemp.name = tJson.name;
                        testTemp.connection = tJson.connection;
                        testTemp.apCoverage = tJson.apCoverage;
                        testTemp.modelVersion = tJson.modelVersion;
                        testTemp.ssids = tJson.ssids;
                        SimLokiDatabaseActions.insert('sensor-test-suite', testTemp);
                    }
                    return getAllTestSuites();
                }

                if (urlAction.service.indexOf('sensors') >= 0 && urlAction.service.indexOf('updatetestconfigversion') >= 0) {
                    return "";
                }

                if (urlAction.service.indexOf('sensorrecommendation') >= 0) {
                    var siteId = urlAction.restPayload.siteId;
                    var totalAPs = SimLokiDatabaseActions.getAll('access-point');
                    var completeApList = [];
                    var sensorList = [];
                    var tObj = [];
                    var recommendedAPs = {};
                    var uncoveredAPList = [];
                    var clientCount = [];

                    var testSuite = SimLokiDatabaseActions.getAll('sensor-test-suite');

                    for (var j = 0; j < testSuite.length; j++) {

                        var senList = testSuite[j].sensors;
                        for (var k = 0; k < senList.length; k++) {

                            var sensorObj = {};
                            sensorObj["name"] = senList[0].name;
                            sensorObj["macAddress"] = senList[0].macAddress;
                            sensorObj["assigned"] = false;
                            sensorObj["status"] = null;
                            sensorObj["xorSensor"] = false;
                            sensorList.push(sensorObj);
                        }
                    }


                    for (var i = 0; i < totalAPs.length; i++) {
                        tObj = {};
                        var apDetails = totalAPs[i].attributes;
                        var reachabilityStatus = apDetails.reachabilityStatus;
                        if (apDetails.locationGroup === siteId) {
                            var clients_2_4 = this.getClientCount(apDetails.name, "2.4");
                            var clients_5 = this.getClientCount(apDetails.name, "5.0");
                            var totalClients = clients_2_4 + clients_5;
                            tObj["name"] = apDetails.name;
                            tObj["macAddress"] = apDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = totalAPs[i].status;
                            tObj["radio_2_4_ClientCount"] = clients_2_4;
                            tObj["radio_5_ClientCount"] = clients_5;
                            tObj["clientCount"] = totalClients;
                            tObj["deviceType"] = "AP-IN-LOCAL";
                            tObj["neighborAps"] = [];
                            tObj["usedByTestList"] = UtilityFunctions.getUsedbyTestSuiteList(testSuite, apDetails.name);
                            tObj["reachable"] = false //reachabilityStatus.toUpperCase()==="REACHABLE"?true:false;
                            tObj["supportedForSensorTest"] = true;

                            for (var k = 0; k < totalAPs.length; k++) {
                                if (apDetails.locationGroup === totalAPs[k].attributes.locationGroup && apDetails.macaddress != totalAPs[k].attributes.macaddress) {
                                    var neighborObj = {};
                                    neighborObj["bssid"] = totalAPs[k].attributes.macaddress;
                                    neighborObj["apname"] = totalAPs[k].attributes.name;
                                    neighborObj["radio"] = totalAPs[k].radios[0].metrics.numberOfClients;
                                    neighborObj["band"] = "2.4GHz";
                                    neighborObj["rssi"] = UtilityFunctions.getRandomIntForRangeValues(-30, -50);
                                    tObj["neighborAps"].push(neighborObj)
                                }
                            }
                            completeApList.push(tObj);
                        }
                        else {
                            tObj["name"] = apDetails.name;
                            tObj["macAddress"] = apDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = totalAPs[i].status;
                            uncoveredAPList.push(tObj);
                        }
                    };

                    var totalSensor = SimLokiDatabaseActions.getAll('sim-sensor');

                    for (var i = 0; i < totalSensor.length; i++) {
                        var sensorDetails = totalSensor[i].attributes;
                        var reachabilityStatus = sensorDetails.reachabilityStatus;
                        if (sensorDetails.locationGroup === siteId) {
                            tObj = {};
                            tObj["name"] = sensorDetails.name;
                            tObj["macAddress"] = sensorDetails.macaddress;
                            tObj["assigned"] = false;
                            tObj["status"] = sensorDetails.status;
                            tObj["radio_2_4_ClientCount"] = 0;
                            tObj["radio_5_ClientCount"] = 0;
                            tObj["clientCount"] = 0;
                            tObj["deviceType"] = sensorDetails.typeString;
                            tObj["neighborAps"] = [];
                            tObj["supportedForSensorTest"] = true;
                            tObj["usedByTestList"] = UtilityFunctions.getUsedbyTestSuiteList(testSuite, sensorDetails.name);
                            tObj["reachable"] = false //reachabilityStatus.toUpperCase()==="REACHABLE"?true:false;

                            for (var k = 0; k < totalAPs.length; k++) {
                                if (apDetails.locationGroup === totalAPs[k].attributes.locationGroup && apDetails.macaddress != totalAPs[k].attributes.macaddress) {
                                    var neighborObj = {};
                                    neighborObj["bssid"] = totalAPs[k].attributes.macaddress;
                                    neighborObj["apname"] = totalAPs[k].attributes.name;
                                    neighborObj["radio"] = totalAPs[k].radios[0].metrics.numberOfClients;
                                    neighborObj["band"] = "2.4GHz";
                                    neighborObj["rssi"] = UtilityFunctions.getRandomIntForRangeValues(-30, -50);
                                    tObj["neighborAps"].push(neighborObj);
                                }
                            }
                        }
                        completeApList.push(tObj);
                    }

                    recommendedAPs["key"] = urlAction.restPayload.siteId;
                    recommendedAPs["sensorList"] = sensorList;
                    recommendedAPs["uncoveredAPList"] = uncoveredAPList;
                    recommendedAPs["completeApList"] = completeApList;
                    // console.log("recommendedAPslist : ",recommendedAPs);
                    return recommendedAPs;
                }

                 if(urlAction.service.indexOf('sensorresult') >=0 && urlAction.service.indexOf('timeslider') >=0 ){
                     var startTime = urlAction.restPayload.startTime;
                     var endTime = urlAction.restPayload.endTime;
                     var diffInHrs = UtilityFunctions.getHours(startTime,endTime);
                     var diff = urlAction.restPayload.attribute[0].condition[0].value[0];
                     var noOfRecLen =  Math.floor((diffInHrs * 60)/(diff/60000))
                   //  var dataList = [];
                     var fromtime = startTime
                    var Json = JSON.parse(JSON.stringify(SensorTimeSlider.TimeSliderTemplate))
                     var updatedTjson = [], templateLen = Json.testResultCountsByInterval.length;
                        for(var i=0; i<noOfRecLen; i++) {
                            var obj = Json.testResultCountsByInterval[i];
                            if(i>=templateLen) {
                                 obj = Json.testResultCountsByInterval[templateLen -1];
                            }
                            obj.startTime = fromtime;
                            let toTime = fromtime + diff;
                            obj.endTime = toTime;
                            fromtime = toTime;
                            updatedTjson.push(obj);
                        }
                        return {"testResultCountsByInterval":updatedTjson};

                 }

                 if(urlAction.service.indexOf('sensorresult') >=0 && urlAction.service.indexOf('heatMapAnalysis') >=0){
                    var GROUP_TYPE = urlAction.restPayload.attribute[0].condition[0].value[0];
                    var VIEW_TYPE = urlAction.restPayload.attribute[1].condition[0].value[0];

                    if(GROUP_TYPE === 'sensorName'){
                        var tJson = JSON.parse(JSON.stringify(heatMapAnalysis_Template.sensorName_Template))
                        var sensors = SimLokiDatabaseActions.getAll('sim-sensor');
                        if(VIEW_TYPE === 'LATEST'){
                            tJson.response.dataList[0].dataList = [];
                            for(let i=1; i<tJson.response.dataList.length; i++){
                               tJson.response.dataList[i].breakdown = sensors[i-1].attributes.name;
                               tJson.response.dataList[i].breakdownValue = sensors[i-1].attributes.macaddress;
                               tJson.response.dataList[i].dataList = [];
                            }
                            return tJson;
                        }
                        if(VIEW_TYPE === 'TREND'){
                            for(let i=1; i<tJson.response.dataList.length; i++){
                               tJson.response.dataList[i].breakdown = sensors[i-1].attributes.name;
                               tJson.response.dataList[i].breakdownValue = sensors[i-1].attributes.macaddress;
                            }
                            return tJson;
                        }

                    }else {
                        var Json = JSON.parse(JSON.stringify(heatMapAnalysis_Template.Sites_Template));

                           if(GROUP_TYPE === 'area' || GROUP_TYPE === 'apName'){
                                if(VIEW_TYPE === 'LATEST'){
                                   Json.response.dataList[0].dataList = [];
                                   Json.response.dataList[1].dataList = [];
                                   Json.response.dataList[1].breakdown = 'Global/San Jose'
                                   return Json;
                                }else if(VIEW_TYPE === 'TREND'){
                                    Json.response.dataList[1].breakdown = 'Global/San Jose'
                                    return Json;
                                }

                           }else if(GROUP_TYPE === 'building' ){
                                if(VIEW_TYPE === 'LATEST'){
                                   Json.response.dataList[0].dataList = [];
                                   Json.response.dataList[1].dataList = [];
                                   Json.response.dataList[1].breakdown = 'Global/San Jose/SJC01'
                                   return Json;
                                }else if(VIEW_TYPE === 'TREND'){
                                    Json.response.dataList[1].breakdown = 'Global/San Jose/SJC01'
                                    return Json;
                                }

                           }else if(GROUP_TYPE === 'floor'){
                                if(VIEW_TYPE === 'LATEST'){
                                   Json.response.dataList[0].dataList = [];
                                   Json.response.dataList[1].dataList = [];
                                   Json.response.dataList[1].breakdown = 'Global/San Jose/SJC01/Flr-SJC1-1'
                                   return Json;
                                }else if(VIEW_TYPE === 'TREND'){
                                    Json.response.dataList[1].breakdown = 'Global/San Jose/SJC01/Flr-SJC1-1';
                                    return Json;
                                }
                           }
                    }
                 }


                 if(urlAction.url.indexOf('/sensorresult/summary') >=0 ) {
                    var Json = JSON.parse(JSON.stringify(SensorTimeSlider.SensorOverAllSummary))
                    var sensors = SimLokiDatabaseActions.getAll('sim-sensor');
                    var sensortotCount = sensors.length;
                    var reachableStatus = sensors.map(obj => obj.attributes.reachabilityStatus);
                    var counts = {};reachableStatus.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
                    Json.sensorSummary.response.count = sensortotCount
                    Json.sensorSummary.response.details.map(detail => {
                        if(detail.status == 'RUNNING'){
                            detail.count = (counts['RUNNING']==undefined ? 0: counts['RUNNING'] )
                            detail.percentage = percentage(detail.count,sensortotCount)
                        }
                        if(detail.status == 'UNREACHABLE'){
                            detail.count = (counts['UNREACHABLE']==undefined ? 0: counts['UNREACHABLE'] )
                            detail.percentage = percentage(detail.count,sensortotCount)
                        }
                        if(detail.status == 'IDLE'){
                            detail.count = (counts['Reachable']==undefined ? 0: counts['Reachable'] )
                            detail.percentage = percentage(detail.count,sensortotCount)
                        }
                    })
                    return Json;
                 }

                 function percentage(partialValue, totalValue) {
                    return (100 * partialValue) / totalValue;
                 }

                 if(urlAction.url.indexOf('sensorresult/testResultFailureStats') >= 0) {
                    var GROUP_TYPE = urlAction.restPayload.attribute[0].condition[0].value[0];
                    var VIEW_TYPE = urlAction.restPayload.attribute[1].condition[0].value[0];
                    var TestType = (urlAction.restPayload.attribute[2] == undefined ) ? undefined : urlAction.restPayload.attribute[2].attrName;
                    var failureReasons, failureReasonsByLoc
                    var tJson = JSON.parse(JSON.stringify(testResultFailureStats.Template));
                    if(TestType != undefined){ // if(typeof TestType != "undefined"
                       var failureReasons = tJson.failureReasonsByTestType.failureReasons.filter(obj => obj.testCategory === TestType );
                       var failureReasonsByLoc = tJson.failureReasonsByLocation.failureReasons.filter(obj => obj.testCategory === TestType );
                       return {"failureReasonsByTestType": {"failureReasons": failureReasons},
                        "failureReasonsByLocation": {"failureReasons": failureReasonsByLoc }}
                    }else {
                        var Json = JSON.parse(JSON.stringify(SensorTimeSlider.testResultFailureStats))
                        return Json;
                    }
                 }

                 if(urlAction.url.indexOf('sensorresult/drillDownRfAnalysisByTest') >= 0) {

                    var attrName = (urlAction.restPayload.attribute[0] != undefined ? urlAction.restPayload.attribute[0].attrName : undefined)
                    var attrName1 = (urlAction.restPayload.attribute[2] != undefined ? urlAction.restPayload.attribute[2].attrName : undefined)

                    if((attrName != undefined && attrName === 'DATA_RATE') || (attrName1 != undefined && attrName1 === 'DATA_RATE')){
                        if(urlAction.restPayload.attribute[1].condition[0].value[0] == 'LATEST'){
                            var Json = JSON.parse(JSON.stringify(drillDownRfAnalysisByTest.DATA_RATE_LATEST))
                        }else if(urlAction.restPayload.attribute[1].condition[0].value[0] == 'TREND'){
                            var Json = JSON.parse(JSON.stringify(drillDownRfAnalysisByTest.DATA_RATE_TREND))
                        }
                       return Json
                    }
                    if((attrName != undefined && attrName === 'SNR') || (attrName1 != undefined && attrName1 === 'SNR') ){
                        if(urlAction.restPayload.attribute[1].condition[0].value[0] == 'LATEST'){
                            var Json = JSON.parse(JSON.stringify(drillDownRfAnalysisByTest.SNR_LATEST))
                        }else if(urlAction.restPayload.attribute[1].condition[0].value[0] == 'TREND'){
                            var Json = JSON.parse(JSON.stringify(drillDownRfAnalysisByTest.SNR_TREND))
                        }
                       return Json
                    }


                 }

                 if(urlAction.url.indexOf('sensorresult/drillDownFailurePercentByTest') >= 0) {

                    if(['ONBOARDING','ASSOC','AUTH','WEBAUTH','DHCP'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.Onboarding_FailurePercentByTest))
                        return Json
                    }
                    if(['RF_ASSESSMENT','DATA_RATE','SNR'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.RF_FailurePercentByTest))
                        return Json;
                    }
                    if(['NETWORK_SERVICES','DNS','RADIUS'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.NetorkServices__FailurePercentByTest))
                        return Json;
                    }
                    if(['PERFORMANCE','IPSLASENDER','SPEED','IPERF'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.Performance_FailurePercentByTest))
                        return Json;
                    }
                    if(['APP_CONNECTIVITY','FILETRANSFER','WEBSERVER','HOST_REACHABILITY'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.APPConnectivity_FailurePercentByTest))
                        return Json;
                    }
                    if(['EMAIL','MAILSERVER','OUTLOOKSERVER'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(SensorFailurePercentByTest.Email_FailurePercentByTest))
                        return Json;
                    }
                 }
                 if (urlAction.service.indexOf('sensors') >=0 && urlAction.service.indexOf('sensorneighbors') >=0){
                    var recordObj = SimLokiDatabaseActions.getAll('sensor-test-suite');
                    var twoghz=[];
                    var fiveghz=[];
                    for(i=0;i<recordObj.length;i++){
                        if(urlAction.restPayload.attribute[0].condition[0].value[0] != recordObj[i].sensors[0].macAddress){
                            var twghzObj = {};
                            var fighzObj = {};
                            twghzObj.hostname = recordObj[i].sensors[0].name;
                            twghzObj.bssid = recordObj[i].sensors[0].macAddress;
                            twghzObj.ssid = recordObj[i].ssids[0].ssid;
                            twghzObj.band = "2.4";
                            twghzObj.freq = recordObj[i].frequency;
                            twghzObj.radio = null;
                            twghzObj.rssi = recordObj[i].rssiThreshold;
                            twghzObj.numReportedRssi = 1;
                            twoghz.push(twghzObj);

                            fighzObj.hostname = recordObj[i].sensors[0].name;
                            fighzObj.bssid = recordObj[i].sensors[0].macAddress;
                            fighzObj.ssid = recordObj[i].ssids[0].ssid;
                            fighzObj.band = "5";
                            fighzObj.freq = recordObj[i].frequency;
                            fighzObj.radio = null;
                            fighzObj.rssi = recordObj[i].rssiThreshold;
                            fighzObj.numReportedRssi = 1;
                            fiveghz.push(fighzObj);
                        }
                        
                    }
                    return {"2ghzneighbors":twoghz,"5ghzneighbors":fiveghz};
                }

                 if(urlAction.url.indexOf('sensorresult/kpiApAnalysis') >= 0) {

                     var attrName = urlAction.restPayload.attribute[0].attrName;
                     var json = JSON.parse(JSON.stringify(kpiApAnalysis.Template))
                     var testFilter = [
                        {"ONBOARDING":{"numTests":[5358,4675,4305,12],"numFailedTest":[9,4,0,0]}},
                        {"ASSOC" : {"numTests":[1788,1559,1435,4],"numFailedTest":[5,2,0,0]}},
                        {"AUTH": {"numTests":[1787,1559,1435,4],"numFailedTest":[5,2,0,0]}},
                        {"DHCP": {"numTests":[1783,1557,1435,4],"numFailedTest":[0,0,0,0]}},
                        {"WEBAUTH":{"numTests":[1783,1557,1435,4],"numFailedTest":[0,0,0,0]}},
                        {"NETWORK_SERVICES" : {"numTests":[1782,1557,1435,4],"numFailedTest":[1,0,1,0]}},
                        {"DNS" : {"numTests":[1782,1557,1435,4],"numFailedTest":[0,0,0,0]}},
                        {"RADIUS":{"numTests":[1783,1547,1436,4],"numFailedTest":[0,0,0,1]}},
                        {"PERFORMANCE":{"numTests":[2669,2331,2160,0],"numFailedTest":[49,44,11,0]}},
                        {"IPSLASENDER" : {"numTests":[1781,1557,1435,4],"numFailedTest":[0,1,1,0]}},
                        {"SPEED" :{"numTests":[888,774,725,2],"numFailedTest":[49,43,10,0]}},
                        {"APP_CONNECTIVITY" : {"numTests":[4452,3888,3595,10],"numFailedTest":[23,26,25,0]}},
                        {"FILETRANSFER" : {"numTests":[888,774,725,2],"numFailedTest":[23,25,24,0]}},
                        {"WEBSERVER" : {"numTests":[1782,1557,1435,4],"numFailedTest":[0,1,0,0]}},
                        {"HOST_REACHABILITY" : {"numTests":[1782,1557,1435,4],"numFailedTest":[0,1,0,0]}},
                        {"EMAIL" : {"numTests":[1778,1548,1450,4],"numFailedTest":[0,1,5,4]}},
                        {"MAILSERVER" : {"numTests":[889,774,725,2],"numFailedTest":[1,5,0,2]}},
                        {"OUTLOOKSERVER" : {"numTests":[889,774,725,2],"numFailedTest":[5,0,0,2]}}
                     ]
                      var testRecord = testFilter.filter(obj => {
                            if(attrName == Object.keys(obj))
                            return obj
                      })
                      if(testRecord.length>0){
                        var ApList = json.response.apList.length
                          for(let i=0; i<ApList; i++) {
                            let ApName = json.response.apList[i].apName
                            let tDeviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': ApName });
                            let locationDetail = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': tDeviceDetail[0].siteId });
                            json.response.apList[i].apMac = tDeviceDetail[0].macAddress;
                            json.response.apList[i].numTest = testRecord[0][attrName].numTests[i];
                            json.response.apList[i].numFailedTest = testRecord[0][attrName].numFailedTest[i];
                            json.response.apList[i].location = locationDetail[0].groupNameHierarchy;
                            json.response.apList[i].deviceType = tDeviceDetail[0].platformId
                          }
                        return json;
                      }else {
                        return {"version":"1.0","response":{"apList":[]}}
                      }
                 }

                 if(urlAction.url.indexOf('sensorresult/drillDownFailureStats') >= 0) {
                    if(['ONBOARDING','ASSOC','AUTH','WEBAUTH','DHCP'].indexOf(urlAction.restPayload.attribute[0].attrName) > -1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.OnBoarding))
                         return Json;
                    }
                    if(['RF_ASSESSMENT','DATA_RATE','SNR'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.RF_ASSESMENT))
                        return Json;
                    }
                    if(['NETWORK_SERVICES','DNS','RADIUS'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.NetworkSevices))
                        return Json;
                    }
                    if(['PERFORMANCE','IPSLASENDER','SPEED','IPERF'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.Performances)) //added IPERF -1.4.0 release
                        return Json;
                    }
                    if(['APP_CONNECTIVITY','FILETRANSFER','WEBSERVER','HOST_REACHABILITY'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.AppConnectivity))
                        return Json;
                    }
                    if(['EMAIL','MAILSERVER','OUTLOOKSERVER'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(drillDownFailureStats.Email))
                        return Json;
                    }

                 }

                  if(urlAction.url.indexOf('sensorresult/sensorTestResultsListAnalysis') >= 0) {

                    if(['ONBOARDING','ASSOC','AUTH','WEBAUTH','DHCP','RF_ASSESSMENT','DATA_RATE','SNR','NETWORK_SERVICES',
                    'DNS',"RADIUS",'PERFORMANCE','IPSLASENDER','SPEED','APP_CONNECTIVITY',
                    'FILETRANSFER','WEBSERVER','HOST_REACHABILITY','IPERF'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(sensorTestResultsListAnalysis.PASS_TestResultsListAnalysis))
                    }

                    if(['EMAIL','MAILSERVER'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(sensorTestResultsListAnalysis.FAIL_TestResultsListAnalysis));
                    }

                    if(['OUTLOOKSERVER'].indexOf(urlAction.restPayload.attribute[0].attrName) >-1){
                         var Json = JSON.parse(JSON.stringify(sensorTestResultsListAnalysis.TotalFailure_TestResults));
                    }



                         let sensorDev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Wireless Sensor"});
                         let sensorNames = sensorDev.map(obj => obj.hostname);

                         let site = SimLokiDatabaseActions.getFilteredRecordHandler
                                       ('site',{'groupNameHierarchy':"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"});
                         let apsSJC01 = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                                                        {'$and': [{'siteId':site[0].id},{'family':'Unified AP'}]});
                         let apNames = apsSJC01.map(obj => obj.hostname);
                         let ssids = ['@CorpSSID','c9800AP11AX','c9800AP11AC'];



                         for(let i=0;i<Json.response.dataList.length;i++){
                            let randNum = Math.floor(Math.random()*(sensorNames.length));
                            let sensorPicked = sensorNames[randNum];
                            let randAP = Math.floor(Math.random()*(apNames.length));
                            let apPicked = apNames[randAP];
                            let randSSID = Math.floor(Math.random()*(ssids.length));
                            let ssidPicked  = ssids[randSSID]
                            Json.response.dataList[i].sensorName = sensorPicked;
                            Json.response.dataList[i].ssid = ssidPicked;
                            let sensorRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': sensorPicked});
                            Json.response.dataList[i].sensorMac = sensorRec[0].macAddress;
                            Json.response.dataList[i].sensorVersion = sensorRec[0].softwareVersion;
                            Json.response.dataList[i].serialNumber = sensorRec[0].serialNumber;
                            Json.response.dataList[i].sensorType = sensorRec[0].type;
                            Json.response.dataList[i].apName =  apPicked;
                            let APRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': apPicked});
                            Json.response.dataList[i].apMac = APRec[0].macAddress;
                            Json.response.dataList[i].testTime = new Date().getTime();
                         }
                        var band = urlAction.restPayload.band;
                        var ssid = urlAction.restPayload.ssid;
                        // Below code is more optimization filter data commenting bcz not tested in local -- karan
                        //  var bandFilteredData = Json.filter((item)=>item.response.dataList.band.some((e) => urlAction.restPayload.band.includes(e)));
                        // Not able to test in local so for now taking 2filters 
                        // for(varl=0;l<band.length;l++){
                        //     var fildata = Json.filter(e=>e.response.dataList.band === band[i])
                        // }
                        // for(varl=0;l<ssid.length;l++){
                        //     var fildata = Json.filter(e=>e.response.dataList.ssid === ssid[i])
                        // }
                        if(band.length >0){
                            Json = Json.filter(e=>e.response.dataList.band === band[0])
                        }
                        if(ssid.length>0){
                            Json = Json.filter(e=>e.response.dataList.ssid === ssid[0])
                        }
                        return Json;
                  }

                  if(urlAction.url.indexOf('sensors/list')>=0){
                      if(urlAction.restPayload.attribute[1].condition[0] != undefined){
                        var sensorListTempDB = SimLokiDatabaseActions.getFilteredRecordHandler('sensor-list-summary',{'status': urlAction.restPayload.attribute[1].condition[0].value[0]});
                      }else{
                        var sensorListTempDB = SimLokiDatabaseActions.getAll('sensor-list-summary')
                      }
                    
                    return {"version": "1.0","response": sensorListTempDB};
                  }

                  if(urlAction.url.indexOf('sensorresult/kpiTrendAnalysis') >=0) {
                    var startTime = urlAction.restPayload.startTime;
                    var endTime = urlAction.restPayload.endTime;
                    var diffInHrs = UtilityFunctions.getHours(startTime,endTime);
                    var diff = parseInt(urlAction.restPayload.attribute[1].condition[0].value[0]);
                    var noOfRecLen =  Math.floor((diffInHrs * 60)/(diff/60000));
                    var fromtime = startTime;
                    var attrName = (urlAction.restPayload.attribute[0] != undefined ? urlAction.restPayload.attribute[0].attrName : undefined); // from sensor dashboard
                    var attrName1 = (urlAction.restPayload.attribute[3] != undefined ? urlAction.restPayload.attribute[3].attrName : undefined); // from sensor360

                    var kpinames = {"ASSOC":"avg_onboarding_associationRequest",
                                    "AUTH": "handshake",
                                    "DHCP": "avg_onboarding_addressingTime",
                                    "DNS": "avg_dns_queryResponseTimeMillis",
                                    "WEBAUTH": "handshake",
                                    "RADIUS": "avg_radius_authTimeMillis",
                                    "IPSLASENDER" : "avg_ipsla_latency",
                                    "SPEED": "avg_speed_s2cRate",
                                    "FILETRANSFER": "avg_ftp_response_time",
                                    "WEBSERVER": "avg_responseTimeMillis",
                                    "HOST_REACHABILITY": "avg_reachabilityTimeMillis",
                                    "MAILSERVER": "avg_responseTimeMillis",
                                    "OUTLOOKSERVER": "avg_responseTimeMillis",
                                    "MAC_ADDRESS":"avg_onboarding_associationRequest",
                                    "FTP": "avg_radius_authTimeMillis",
                    }
                    var kpiValue,kpiFound = false;
                    var json = JSON.parse(JSON.stringify(kpiTrendAnalysis.Template));

                    for (var name in kpinames) {
                        if((attrName != undefined && attrName == name) || (attrName1 != undefined && attrName1 == name) ){
                            kpiFound = true
                           kpiValue  = kpinames[name];
                        }
                    }
                    if((kpiFound && (attrName !== 'IPSLASENDER'|| attrName !== 'SPEED')) ||
                       (kpiFound && (attrName1 !== 'IPSLASENDER'|| attrName1 !== 'SPEED'))){
                        let dataList = JSON.parse(JSON.stringify(json.response.dataList[0].dataList));
                        json.response.dataList[0].kpiName = kpiValue;
                        for(let i=0; i<dataList.length;i++) {
                            var updatedJson = [];
                            for(let j=0; j<noOfRecLen; j++){
                                let obj= dataList[i].dataList[j];
                                obj.timestamp = fromtime;
                                if(attrName == 'DHCP' || attrName == 'DNS' || attrName == 'WEBSERVER' ||
                                    attrName1 == 'DHCP' || attrName1 == 'DNS' || attrName1 == 'WEBSERVER')
                                    if(dataList[i].kpiType == "highest"){
                                        if(j==10){
                                            obj.kpiValue = 50
                                        }else{
                                            obj.kpiValue = obj.kpiValue+5
                                        }
                                    }
                                    if(dataList[i].kpiType == "lowest"){
                                        obj.kpiValue = obj.kpiValue-5;
                                    }
                                    if(dataList[i].kpiType == "current"){
                                        obj.kpiValue = obj.kpiValue+2
                                    }


                                let incTime = fromtime + parseInt(diff);
                                fromtime = incTime;
                                updatedJson.push(obj);
                            }
                            json.response.dataList[0].dataList[i].dataList = updatedJson;
                        }
                    }else if((kpiFound && (attrName == 'IPSLASENDER'|| attrName == 'SPEED')) ||
                             (kpiFound && (attrName1 == 'IPSLASENDER'|| attrName1 == 'SPEED'))){
                        if(attrName == 'IPSLASENDER' || attrName1 == 'IPSLASENDER' ){
                            var json = JSON.parse(JSON.stringify(kpiTrendAnalysis.kpiTrend_IPSLA));
                        }
                        if(attrName == 'SPEED'|| attrName1 == 'SPEED'){
                            var json = JSON.parse(JSON.stringify(kpiTrendAnalysis.KpiTrend_SPEED));
                        }
                        for(let i=0; i<json.response.dataList.length;i++){
                            for (let j=0; j<json.response.dataList[i].dataList.length; j++){
                                var data = json.response.dataList[i].dataList[j];
                                var updatedJson = [];
                                for(let k=0; k<data.length; k++){
                                    let obj= dataList[i].dataList[j].dataList[k];
                                    obj.timestamp = fromtime;
                                    let incTime = fromtime + parseInt(diff);
                                    fromtime = incTime;
                                    updatedJson.push(obj);
                                }
                                json.response.dataList[i].dataList[j].dataList = updatedJson;
                            }
                        }
                    }else{
                        json.response.dataList = []
                    }
                    return json
                  }
            },

            getClientCount: function (APName, frequency) {
                var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': APName });
                var client_List;
                if (obj.length > 0) {
                    client_List = SimLokiDatabaseActions.getFilteredRecordHandler('host', {
                        '$and': [{
                            'connectedNetworkDeviceId': obj[0].id
                        },
                        { 'frequency': frequency }]
                    });
                    return client_List.length;
                }


                return 0;
            }



        };

        function getTestResultsBasedOnTypes(urlAction) {
            var responseFileName = urlAction.restPayload.types.join('_');
            var testResultsJson = SimLokiDatabaseActions.getAll('sensor-test-results');
            for (var i = 0; i < testResultsJson.length; i++) {
                if (testResultsJson[i].testSuiteId === urlAction.restPayload.testSuiteId) {
                    tObj = testResultsJson[i].testResultDetails;
                    tObj.startTime = urlAction.restPayload.startTime;
                    tObj.endTime = urlAction.restPayload.endTime;

                    document.cookie = "testresultStartTime=" + tObj.startTime + "; path=/";
                    document.cookie = "testresultEndTime=" + tObj.endTime + "; path=/";

                    var lastRun = tObj.mostRecent.lastRun;
                    var lastDate = new Date(lastRun);
                    // var t=new Date();
                    // t.setHours(lastDate.getHours());
                    //t.setMinutes(lastDate.getMinutes());
                    /* if(t<new Date()){
                     tObj.mostRecent.lastRun=new Date(t);
                     }
                     else{
                      var t1=new Date(t);
                      t1.setDate(t1.getDate()-1);
                      tObj.mostRecent.lastRun=new Date(t1);;
                     } */
                    tObj.mostRecent.lastRun = new Date();
                    return tObj;
                }
            }
            return undefined;
        }

        function getDeviceTestDetails(urlAction) {
            var tJson = DeviceTestDetails.Template;

            for (key in tJson.sensorDeviceTestDetailsList) {
                var tDeviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': tJson.sensorDeviceTestDetailsList[key].sensorName });
                if (tDeviceDetail.length > 0) {
                    if (tDeviceDetail[0].reachabilityStatus == 'Reachable') {
                        tJson.sensorDeviceTestDetailsList[key].reachabilityStatus = true;
                    } else {
                        tJson.sensorDeviceTestDetailsList[key].reachabilityStatus = false;
                    }
                } else {
                    tJson.sensorDeviceTestDetailsList[key].reachabilityStatus = false;
                }
                tJson.sensorDeviceTestDetailsList[key].lastSeen = new Date();
            }

            return tJson;
        }

        function getPassCount(index) {
            let passArray = [3329, 2479, 2471, 200];
            return passArray[index];
        }

        function getFailureCount(index) {
            let failureArray = [1, 68, 67, 0];
            return failureArray[index];
        }

        function getSlowCount(index) {
            let slowArray = [19, 0, 0, 0];
            return slowArray[index];
        }

        function getPerformanceResults(urlAction) {
            var tType = urlAction.filter['testtype'];
            var endDate = new Date().getTime();;
            //var incrementBy = 0;

            var responseArray = [
                { testType: 'dhcp', responseFile: 'dhcp' }, { testType: 'dns', responseFile: 'dns' },
                { testType: 'ping', responseFile: 'ping' }, { testType: 'radius', responseFile: 'radius' },
                { testType: 'mailserver', responseFile: 'mailserver' },
                { testType: 'outlookserver', responseFile: 'outlookserver' },
                { testType: 'webserver', responseFile: 'webserver' },
                { testType: 'filetransfer', responseFile: 'filetransfer' },
                { testType: 'IpslaSender', responseFile: 'IpslaSender' },
                { testType: 'speed', responseFile: 'speed' },
                { testType: 'onboarding', responseFile: 'onboarding' }
            ];
            //var tType = tType != undefined ? tType + "Performance' + "";
            if (tType == undefined) {
                return;
            }

            tType = tType + "Performance";
            let ssid = urlAction.restPayload.ssid;
            let band = urlAction.restPayload.band;
            let index = -1;
            var res = eval(tType);

            /*if(urlAction.filter['testtype'] !="IpslaSender" && urlAction.filter['testtype'] !="speed") {
                 for(var i=0; i<res.Template.KpiPerformanceInterval[0].averagePerformanceList.length; i++){
                       var curtime = UtilityFunctions.incrementDateByMinutes(endDate, incrementBy);
                       res.Template.KpiPerformanceInterval[0].averagePerformanceList[i].endTime = curtime;
                       res.Template.KpiPerformanceInterval[0].maxPerformanceList[i].endTime = curtime;
                       res.Template.KpiPerformanceInterval[0].minPerformanceList[i].endTime = curtime;
                       incrementBy = incrementBy-(60);
                 }
             }*/
            if (tType == "onboardingPerformance") {
                index = 0;
            } else if (tType == "dnsPerformance") {
                index = 1;
            } else if (tType == "webserverPerformance") {
                index = 2;
            } else if (tType == "speedPerformance") {
                index = 3;
            }

            for (var j = 0; j < res.Template.KpiPerformanceInterval.length; j++) {
                var increment = 0;
                if (index >= 0) {
                    if (ssid && ssid.length > 0 || band && band.length > 0) {
                        let failureCount = 0;
                        if (ssid.length > 0 && band.length > 0) {
                            // tests = Math.round(totalLength*0.6);
                            res.Template.testResultDetails.passCount = getPassCount(index) - 120;
                            failureCount = getFailureCount(index);
                            res.Template.testResultDetails.failureCount = failureCount > 0 ? failureCount - 1 : failureCount;
                            res.Template.testResultDetails.slowCount = getSlowCount(index);
                        } else if (ssid.length > 0) {
                            // tests = Math.round(totalLength*0.7);
                            res.Template.testResultDetails.passCount = getPassCount(index) - 80;
                            failureCount = getFailureCount(index);
                            res.Template.testResultDetails.failureCount = failureCount > 0 ? failureCount - 1 : failureCount;
                            res.Template.testResultDetails.slowCount = getSlowCount(index);
                        } else {
                            // tests = Math.round(totalLength*0.8);
                            res.Template.testResultDetails.passCount = getPassCount(index) - 60;
                            failureCount = getFailureCount(index);
                            res.Template.testResultDetails.failureCount = failureCount > 0 ? failureCount - 1 : failureCount;
                            res.Template.testResultDetails.slowCount = getSlowCount(index);
                        }
                    } else {
                        res.Template.testResultDetails.passCount = getPassCount(index);
                        res.Template.testResultDetails.failureCount = getFailureCount(index);
                        res.Template.testResultDetails.slowCount = getSlowCount(index);
                    }
                }
                for (var i = 0; i < res.Template.KpiPerformanceInterval[j].averagePerformanceList.length; i++) {
                    var curtime = UtilityFunctions.incrementDateByMinutes(endDate, increment);
                    var millisec = moment(curtime).valueOf();
                    res.Template.KpiPerformanceInterval[j].averagePerformanceList[i].endTime = millisec;
                    res.Template.KpiPerformanceInterval[j].maxPerformanceList[i].endTime = millisec;
                    res.Template.KpiPerformanceInterval[j].minPerformanceList[i].endTime = millisec;
                    increment = increment - (60);
                }
            }
            return res != undefined ? res.Template : undefined;
        }

        function createTestSuite() {
            var tJson = JSON.parse(JSON.stringify(testSuite.Template));
            var tResults = JSON.parse(JSON.stringify(testresults.Template));
            for (var i = 0; i < tJson.length; i++) {
                tsites = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'additionalInfo.attributes.type': 'floor' });
                for (var t = 0; t < tsites.length; t++) {
                    if (tsites[t].name === "Flr-SJC1-1") {
                        tJson[i].location = tsites[t].id;
                        tJson[i].tenantId = tsites[t].parentId;
                    }
                }
                var senList = tJson[i].sensors;
                for (var k = 0; k < senList.length; k++) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-point', { 'attributes.name': senList[k].name });
                    if (recordObj.length > 0) {
                        tJson[i].sensors[k].macAddress = recordObj[0].attributes.macaddress;

                    }
                }
            }
            SimLokiDatabaseActions.insert('sensor-test-suite', tJson);
            SimLokiDatabaseActions.insert('sensor-test-results', tResults);
        }

        function sensorListCollection() {
            // Edited by Karan for 1.3.3
            var sensordevicelist = DeviceTestDetails.Template;
            var sensorListResponseArr = [];
            var sensorListTemp = JSON.parse(JSON.stringify(sensorListTemplate.sensorListTemplate));
            // var tDeviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': sensordevicelist.sensorDeviceTestDetailsList[key].sensorName });
            var tDeviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Wireless Sensor"});
            for(i=0;i<tDeviceDetail.length;i++){
                var sensorListResponse = JSON.parse(JSON.stringify(sensorListTemp.response[0]));
                sensorListResponse.name = tDeviceDetail[i].name;
                sensorListResponse.ethernetMacAddress = tDeviceDetail[i].macAddress;
                sensorListResponse.serialNumber = tDeviceDetail[i].serialNumber;
                sensorListResponse.ipAddress = tDeviceDetail[i].managementIpAddress;
                sensorListResponse.version = tDeviceDetail[i].softwareVersion;
                sensorListResponse.radioMacAddress = tDeviceDetail[i].macAddress;
                sensorListResponse.lastSeen = new Date();
                sensorListResponse.series = "Cisco Aironet 1800S Active Sensor";
                let time = tDeviceDetail[0].uptimeSeconds;
                time = time*1000
                let up_Time = new Date().getTime()-time
                /*var d = new Date();
                var t = d.setMinutes(d.getMinutes() - 30);*/
                var upTime = UtilityFunctions.getCustomFormattedTime(up_Time);
                sensorListResponse.uptime = upTime;
                sensorListResponse['uptimeLong'] = up_Time;
                sensorListResponse.template = "Sensor_Test_01"
                sensorListResponse['id'] = tDeviceDetail[i].id
                if (i>=2){
                    sensorListResponse.backhaulType = "Wireless"
                }
                if (tDeviceDetail[i].reachabilityStatus == 'Reachable') {
                    sensorListResponse.status = "RUNNING";
                } else if (tDeviceDetail[i].reachabilityStatus == 'Unreachable'){
                    sensorListResponse.status = "UNREACHABLE";
                } else {
                    sensorListResponse.status = "IDLE";
                }
                sensorListResponseArr.push(sensorListResponse);
            }
            return sensorListResponseArr;
        }

        function createSensorSummaryList(){
            var sensorListTempCollection = sensorListCollection();
            var tJson = JSON.parse(JSON.stringify(sensorListTempCollection));
            SimLokiDatabaseActions.insert('sensor-list-summary', tJson);
        }

        function getAllTestSuites() {
            var tJson = SimLokiDatabaseActions.getAll('sensor-test-suite');
            // console.log("Test Suite tJson : ",tJson);
            return tJson;
        }
    });
