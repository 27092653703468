define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGroupData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteBaseTemplate',
], function (SimGroupData, SimNetworkDeviceData, SimLokiDatabaseActions, SiteBaseTemplate) {

   //Case 1: when search for group -- devices tab search for SJC site..
   //https://172.20.50.41/apic/api/v1/group?groupType=SITE&sortBy=groupName&order=asc&groupName=%25SJ%25

   //Case 2: for each network device, find it is part of site or not..
   //https://172.20.50.41/apic/api/v1/group/member/6641798d-11d1-449b-a3a0-af1f5368c6e8?groupType=SITE

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    data = processRequest(urlAction);
                    break;
            }

            return data;
        }

    };

    function processGetRequest(urlAction){
        //api/v1/group/?sortBy=groupName&order=asc&additionalInfo.attributes.type=area&size=1000
       if(urlAction.filter['field'] == 'id,name,parentId,groupNameHierarchy,additionalInfo.nameSpace,additionalInfo.attributes' && urlAction.filter['groupNameHierarchy'] != undefined ) {
            var groupNameHierarchy = urlAction.filter['groupNameHierarchy'] ;
            var records = [];
            if(groupNameHierarchy.indexOf('*') > -1) {
                groupNameHierarchy = groupNameHierarchy.replace(/\*/g,"");
                records = SimLokiDatabaseActions.filterByRegx("site",'groupNameHierarchy',groupNameHierarchy);
            } else {
                records = SimLokiDatabaseActions.filterByRegx("site",'groupNameHierarchy',groupNameHierarchy);
            }
            return records;
        }
        if(urlAction.filter['field'] == 'name,id,parentId,additionalInfo.attributes.country,additionalInfo.attributes.latitude,additionalInfo.attributes.longitude,additionalInfo.attributes.type,groupNameHierarchy,additionalInfo.attributes.address' && urlAction.action['count'] != true){
            var Objdata =  SimGroupData.getSiteDetails(urlAction);
            Objdata = JSON.parse(JSON.stringify(Objdata));
            for(var i=0;i<Objdata.length;i++){
                var modAddInfo = [];
                for(j=0;j<Objdata[i].additionalInfo.length;j++){
                    if(Objdata[i].additionalInfo[j].nameSpace == 'Location'){
                        modAddInfo.push(Objdata[i].additionalInfo[j]);
                    }
                }
                Objdata[i].additionalInfo = modAddInfo;
            }
            return Objdata
        }
        if(urlAction.filter['groupName'] != undefined && urlAction.filter['groupName'] == 'global' || urlAction.filter['groupName'] != undefined && urlAction.filter['groupName'] == 'Global') {
           return SimGroupData.getGlobalSummary(urlAction);
        }
        if(urlAction.filter['groupType'] == 'SITE' && urlAction.filter['id'] != undefined &&
            urlAction.filter['id'] != '' ) {
            //Devices plugin passes device id, need to return site related information..
            return SimGroupData.getSiteDetailsForNetworkDevice(urlAction);
        }
        //"/api/v1/group?groupType=SITE&additionalInfo.nameSpace=com.wireless.managingwlc&additionalInfo.attributes.primaryWlc=ac807cf8-cea6-7688-7ebb-72d1f018ae55&_=1510732294902"
        if(urlAction.filter['groupType'] == 'SITE' && (urlAction.filter['additionalInfo.attributes.primaryWlc'] != undefined  || urlAction.filter['additionalInfo.attributes.secondaryWlc'] != undefined || urlAction.filter['additionalInfo.attributes.tertiaryWlc'] != undefined)){
            return SimGroupData.getWirelessSites(urlAction);
        }
        // for autosearch filter by Karan
        if(urlAction.service.indexOf('autoComplete') && urlAction.filter['groupType'] == 'SITE' && urlAction.filter['name'] != undefined ) {
            var record = SimGroupData.getSiteDetails(urlAction);
            var tJson = []
            var filterValue = urlAction.filter['name'].toString()
            if (filterValue != ".*.*" && filterValue.length > 0) {
                filterValue = filterValue.replace(/[^a-zA-Z0-9]/g, '') //regex for removing special char in string
                for(i=0;i< record.length;i++){
                    if(record[i].groupNameHierarchy.indexOf(filterValue) > -1){
                        tJson.push(record[i].groupNameHierarchy)
                    }
                }
            }
            else {
                for (var i = 0; i < record.length; i++) {
                    tJson.push(record[i].groupNameHierarchy);
                }
            }
            return tJson
        }

        //if(urlAction.filter['groupType'] == 'SITE' || urlAction.filter['additionalInfo.attributes.type'] == 'area'  || urlAction.filter['additionalInfo.attributes.type'] == 'building' ) {
        if(urlAction.filter['groupType'] == 'SITE' || urlAction.filter['additionalInfo.attributes.type']) {
            return SimGroupData.getSiteDetails(urlAction);
        }

        if(urlAction.action.id != '' && urlAction.service.indexOf("parent") >= 0) {
            return SimGroupData.getFloorParent(urlAction);
        }
        
        if(urlAction.filter['memberType'] == 'networkdevice'){
            return SimGroupData.getSiteDetails(urlAction); //For StealthWatch by Karan
        }

        if(urlAction.action.id != '') {
            // && urlAction.action.id == DefaultConfig.DEFAULT_UUID.GlobalGroup){
            //urlAction.action.id = '';
            return SimGroupData.getSiteDetails(urlAction); //delte me..
            //return [];
        }
        else if(urlAction.service.indexOf("group") >= 0 && urlAction.service.indexOf("undefined") >= 0) {
            // When group id is undefined
            var tjson = JSON.parse(JSON.stringify( SiteBaseTemplate.SITE_ERROR_DATA));
            return  tjson;
        }
        else {
            return [];
        }
    }

    function processPostRequest(urlAction){
        if(urlAction.service.length >= 2 && urlAction.service[1] == 'member'){
            //Inventory device did a put request to assign a site to particular device..
            var siteId = Object.keys(urlAction.restPayload.addMembersMap)[0];
            var deviceIds = urlAction.restPayload.addMembersMap[siteId];
            return SimNetworkDeviceData.UpdateSiteonDevices(siteId, deviceIds);
         }

        if(urlAction.restPayload.additionalInfo.length
             && urlAction.restPayload.additionalInfo[0].attributes != undefined) {
             //&& urlAction.restPayload.additionalInfo[0].attributes.type == 'area') {
            return SimGroupData.postSiteCreate(urlAction);
        }
    }

    function processRequest(urlAction) {
    }

    function processDeleteRequest(urlAction) {
        if(urlAction.service.length >= 2 && urlAction.service[1] == 'member'){
            return SimNetworkDeviceData.deleteSiteonDevice(urlAction.action.id);
        }
        return SimGroupData.deleteSite(urlAction);
    }

    function processPutRequest(urlAction) {
        return SimGroupData.updateSite(urlAction);
    }

});
