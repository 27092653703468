// simulation/lib/infrastructure/json-template/site
define({
    "Access_Point_Template":

    {
      "attributes": {
        "id": 140259,
        "instanceUuid": "7db2a957-9d8f-45f4-bbb7-56538e1ba0cb",
        "name": "AP00F2.8B27.B788",
        "ipAddress": "192.168.140.2",
        "macaddress": "00:f2:8b:27:b7:88",
        "baseRadioMacaddress": "2c:d0:2d:4b:41:80",
        "type": 69,
        "typeString": "AP9120_2",
        "status": "CLEARED",
        "adminStatus": "up",
        "mode": 0,
        "model": "C9120AXP-x",
        "wlc": "",
        "locationGroup": "c0a667a9-e6cb-800c-faf6-a326e9ad0518",
        "domain": "218fedf3-7353-4b9f-a38f-ee2908201137",
        "mneId": 140269,
        "generatesCoverageHeatmap": true,
        "generatesIdsHeatmap": false,
        "generatesAqHeatmap": false,
        "gpsAware": false,
        "lastUpdated": null,
        "siteName": "San Jose",
        "buildingName": "SJC06",
        "floorName": "Floor1",
        "outdoorAreaName": null,
        "heirarchyName": "Global>San Jose>SJC06>Floor1",
        "controllerIpAddress": "192.168.103.3",
        "reachabilityStatus": null,
        "masterAp": false,
				"children": null,
				"validationResult": null,
				"markedForReplace": false
      },
      "location": {
        "lattitude": 0.0,
        "longtitude": 0.0,
        "altitude": 0.0
      },
      /** Added Default position for Access points in the Floor View*/
      "position": {
       "x": 10.0,
        "y": 12.0,
        "z": 15.0
      },
      "metrics": {
        "status": "CLEARED",
        "adminStatus": "up",
        "dot11aClientCount": 0,
        "dot11bClientCount": 1,
        "alarmsCount": null,
        "isAssociated": true,
        "isReachable": true,
        "isCleanAirCapable": false,
        "isCleanAirEnable": false,
        "xorRadioBand": "B",
        "isXorAP": true,
        "framework": 2,
        "isMeshType": false,
        "lwappUpTimeString": "7 d 0 h 28 m 2 s",
        "apLocation": "default location",
        "apUpTimeString": "7 d 0 h 29 m 43 s",
        "mapLocation": "Global>San Jose>SJC06>Floor1",
        "softwareVersion": "8.5.110.0",
        "numberOfClients": 1,
        "productTypeString": "Cisco 2800I Unified Access Point",
        "productVersionString": "Unified AP",
        "healthScore": 6.0,
        "issueCount": 0,
        "monitoredBySensor": null,
        "coverageIssueCount": 0,
        "downIssueCount": 0,
        "poorRfConnectedIssueCount": 0
      },
      "radios": [
        {
          "attributes": {
            "id": 579581,
            "instanceUuid": "59511c3e-6752-42f6-9566-c7572b915ea2",
            "slotId": 1,
            "ifType": 2,
            "ifTypeString": "802.11a",
            "ifTypeSubband": "A",
            "channel": 144,
            "channelString": null,
            "ipAddress": null,
            "ifMode": "A",
						"powerListStr": "18,15,12,9,6,3,0,-3",
						"beamSelection": 0
          },
          "antenna": {
            "name": "C-ANT9101-5GHz",
            "description": null,
            "type": "external",
            "mode": "omni",
            "diversity": 255,
            "azimuthAngle": 1.57,
            "elevationAngle": 0.0,
            "gain": 12.0,
            "image": null
          },
          "metrics": {
            "status": "CLEARED",
            "adminStatus": "up",
            "opStatus": "up",
            "dot11AdminStatus": 1,
            "alarmCount": null,
            "txPowerLevel": 16,
            "txUtilization": 0,
            "rxUtilization": 0,
            "channelUtilization": 75,
            "isCleanAirEnabled": false,
            "isCleanAirCapable": false,
            "avgAQ": 0,
            "minAQ": 0,
            "numberOfClients": 1,
            "numberOfPoorClients": 0,
            "loadProfileState": 0,
            "coverageProfileState": 0,
            "noiseProfileState": 0,
            "interferenceProfileState": 0,
            "xorRadioBand": 1,
            "isBackhaul": false,
            "ifType11nCapable": false,
            "extensionChannelString": null,
            "channelBandwidth": null,
            "radioSubType": 1,
            "channelWidth": "40",
            "isAssociated": true,
            "isReachable": true,
            "framework": 2
          },
          "isSensor": false
        },
        {
          "attributes": {
            "id": 579582,
            "instanceUuid": "7d6846cd-ca95-4bac-8b24-08bcb6087e60",
            "slotId": 2,
            "ifType": 2,
            "ifTypeString": "802.11a",
            "ifTypeSubband": "A",
            "channel": 36,
            "channelString": "64",
            "ipAddress": null
          },
          "antenna": {
            "name": "C-ANT9101-2.4GHz",
            "description": null,
            "type": "external",
            "mode": "omni",
            "diversity": 255,
            "azimuthAngle": 1.57,
            "elevationAngle": 0.0,
            "gain": 10.0,
            "image": "null"
          },
          "metrics": {
            "status": "CLEARED",
            "adminStatus": "up",
            "opStatus": "up",
            "dot11AdminStatus": 1,
            "alarmCount": null,
            "txPowerLevel": 17,
            "txUtilization": 0,
            "rxUtilization": 0,
            "channelUtilization": 1,
            "isCleanAirEnabled": false,
            "isCleanAirCapable": false,
            "avgAQ": 0,
            "minAQ": 0,
            "numberOfClients": 0,
            "numberOfPoorClients": 0,
            "loadProfileState": 0,
            "coverageProfileState": 0,
            "noiseProfileState": 0,
            "interferenceProfileState": 0,
            "xorRadioBand": 2,
            "isBackhaul": false,
            "ifType11nCapable": false,
            "extensionChannelString": null,
            "channelBandwidth": null,
            "radioSubType": 1,
            "channelWidth": "20",
            "isAssociated": true,
            "isReachable": true,
            "framework": 2
          },
          "isSensor": false
        }
      ],
      "rxNeighbors": null,
      "tests": {
        "items": [

        ],
        "total": 0
      },

      "isAvailable": false,
      "isAutoAp": false,
      "isSensor": false,
      "isRadioSensor": false,
      "status":"success"
    },

    "Heat_Map_Template": {
        "type":"coverage",
        "band":"5",
        "aqValues":null,
        "heatmap2_4Url":'any', //changed by Ram
        "heatmap5Url":'any',  //changed by Ram
        "heatmap6Url": "any",
        "heatmapBothUrl":'any',  //changed by Ram
        "recomputationTaskId":null

    }


});
