define({
    "SAMSUNG_ANALYTICS_EVENTS": [ 
        
        {
            "name": "INTRA-WLC Roaming_1633595664263_1633595664330",
            "id": "f412af10-10ef-4cc4-92a0-1377dc4786b3",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1633595664263,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
              {
                "key": "WLC Name",
                "value": "CT3504-SF"
              },
              {
                "key": "IPv4",
                "value": "192.168.159.164"
              },
              {
                "key": "Mac Address",
                "value": "70:F3:5A:7C:7F:D0"
              },
              {
                "key": "WLAN",
                "value": "@CorpSSID_SF"
              },
              {
                "key": "Radio",
                "value": "1"
              },
              {
                "key": "VLAN ID/VNID",
                "value": "0"
              },
              {
                "key": "isPrivateMac",
                "value": "false"
              },
              {
                "key": "ROLE",
                "value": "LOCAL"
              },
              {
                "key": "RSSI",
                "value": "-13 dBm"
              },
              {
                "key": "SNR",
                "value": "76 dB"
              },
              {
                "key": "Frequency(GHz)",
                "value": "5.0"
              },
              {
                "key": "AP Mac",
                "value": "CC:16:7E:32:74:90"
              },
              {
                "key": "AP Name",
                "value": "APDeviceNamePlaceHolder"
              },
              {
                "key": "additionalInfo",
                "value": "AP:APDeviceNamePlaceHolder/WLC:CT3504-SF/WLAN:@CorpSSID_SF"
              },
              {
                "key": "startTime",
                "value": "1633595664263"
              },
              {
                "key": "endTime",
                "value": "1633595664330"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [
              {
                "name": "Association Start",
                "id": "1633595664263607_#_1",
                "eventName": "Association Start",
                "timestamp": 1633595664263,
                "index": 0,
                "eventType": 1,
                "reasonType": 1,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Client Association with AP"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "CC:16:7E:32:74:90"
                  },
                  {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                  },
                  {
                    "key": "RSSI",
                    "value": "-13 dBm"
                  },
                  {
                    "key": "SNR",
                    "value": "76 dB"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "Association Done",
                "id": "1633595664264637_#_2",
                "eventName": "Association Done",
                "timestamp": 1633595664264,
                "index": 1,
                "eventType": 2,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "CC:16:7E:32:74:90"
                  },
                  {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "KeyExchange",
                "id": "1633595664330200_#_5",
                "eventName": "KeyExchange",
                "timestamp": 1633595664330,
                "index": 2,
                "eventType": 5,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "CC:16:7E:32:74:90"
                  },
                  {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "Run",
                "id": "1633595664330312_#_9",
                "eventName": "Run",
                "timestamp": 1633595664330,
                "index": 3,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "CC:16:7E:32:74:90"
                  },
                  {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                  },
                  {
                    "key": "IPv4",
                    "value": "192.168.159.164"
                  },
                  {
                    "key": "VLAN ID",
                    "value": "0"
                  },
                  {
                    "key": "ROLE",
                    "value": "LOCAL"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              }
            ]
        },
        {
            "name": "INTRA-WLC Roaming_1624658040022_1624658040097",
            "id": "a5987bd5-1cb2-4e1f-94a0-973fb9a832d7",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1624658040022,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
                {
                    "key": "WLC Name",
                    "value": "WLCDeviceNamePlaceHolder"
                },
                {
                    "key": "Roam Type",
                    "value": "Full Auth"
                },
                {
                    "key": "IPv4",
                    "value": "ipAddressPlaceHolder"
                },
                {
                    "key": "Mac Address",
                    "value": "HostMacPlaceHolder"
                },
                {
                    "key": "WLAN",
                    "value": "ssidPlaceHolder"
                },
                {
                    "key": "Radio",
                    "value": "1"
                },
                {
                    "key": "VLAN ID/VNID",
                    "value": "70"
                },
                {
                    "key": "isPrivateMac",
                    "value": "true"
                },
                {
                    "key": "ROLE",
                    "value": "LOCAL"
                },
                {
                    "key": "RSSI",
                    "value": "-53 dBm"
                },
                {
                    "key": "BSSID",
                    "value": "10:B3:C6:24:B7:4E"
                },
                {
                    "key": "Frequency(GHz)",
                    "value": "5.0"
                },
                {
                    "key": "AP Mac",
                    "value": "ApMacPlaceHolder"
                },
                {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                },
                {
                    "key": "additionalInfo",
                    "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
                },
                {
                    "key": "startTime",
                    "value": "1624658040022"
                },
                {
                    "key": "endTime",
                    "value": "1624658040097"
                }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": true,
            "children": [
                {
                    "name": "Association Start",
                    "id": "1624658040022598_#_1",
                    "eventName": "Association Start",
                    "timestamp": 1624658040022,
                    "index": 0,
                    "eventType": 1,
                    "reasonType": 1169,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Assoc Request"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "RSSI",
                            "value": "-53 dBm"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Associating",
                    "id": "1624658040023056_#_1002",
                    "eventName": "Associating",
                    "timestamp": 1624658040023,
                    "index": 1,
                    "eventType": 1002,
                    "reasonType": 122,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Intra Roam"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Association Done",
                    "id": "1624658040023199_#_2",
                    "eventName": "Association Done",
                    "timestamp": 1624658040023,
                    "index": 2,
                    "eventType": 2,
                    "reasonType": 0,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Authentication Start",
                    "id": "1624658040024245_#_3",
                    "eventName": "Authentication Start",
                    "timestamp": 1624658040024,
                    "index": 3,
                    "eventType": 3,
                    "reasonType": 1144,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "L2 Auth Start"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Authenticating",
                    "id": "1624658040095351_#_1003",
                    "eventName": "Authenticating",
                    "timestamp": 1624658040095,
                    "index": 4,
                    "eventType": 1003,
                    "reasonType": 1152,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Key Exchange"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Authentication Done",
                    "id": "1624658040095605_#_4",
                    "eventName": "Authentication Done",
                    "timestamp": 1624658040095,
                    "index": 5,
                    "eventType": 4,
                    "reasonType": 0,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Mobility",
                    "id": "1624658040096370_#_6",
                    "eventName": "Mobility",
                    "timestamp": 1624658040096,
                    "index": 6,
                    "eventType": 6,
                    "reasonType": 0,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "ROLE",
                            "value": "LOCAL"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Run",
                    "id": "1624658040097280_#_9",
                    "eventName": "Run",
                    "timestamp": 1624658040097,
                    "index": 7,
                    "eventType": 9,
                    "reasonType": 0,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Client Onboarded"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "IPv4",
                            "value": "ipAddressPlaceHolder"
                        },
                        {
                            "key": "VLAN ID",
                            "value": "70"
                        },
                        {
                            "key": "ROLE",
                            "value": "LOCAL"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        },
                        {
                            "key": "Roam Type",
                            "value": "Full Auth"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                }
            ]
        },{
            "name": "INTRA-WLC Roaming_1633575783076_1633575783173",
            "id": "ca12c0d1-4627-4ac4-867e-330bb7fefc44",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1633575783076,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
              {
                "key": "WLC Name",
                "value": "CT3504-SF"
              },
              {
                "key": "IPv4",
                "value": "192.168.159.164"
              },
              {
                "key": "Mac Address",
                "value": "70:F3:5A:7C:7F:D0"
              },
              {
                "key": "WLAN",
                "value": "@CorpSSID_SF"
              },
              {
                "key": "Radio",
                "value": "1"
              },
              {
                "key": "VLAN ID/VNID",
                "value": "0"
              },
              {
                "key": "isPrivateMac",
                "value": "false"
              },
              {
                "key": "ROLE",
                "value": "LOCAL"
              },
              {
                "key": "RSSI",
                "value": "-12 dBm"
              },
              {
                "key": "SNR",
                "value": "77 dB"
              },
              {
                "key": "Frequency(GHz)",
                "value": "5.0"
              },
              {
                "key": "AP Mac",
                "value": "A8:B4:56:0D:8C:00"
              },
              {
                "key": "AP Name",
                "value": "AP9120_2"
              },
              {
                "key": "additionalInfo",
                "value": "AP:AP9120/WLC:CT3504-SF/WLAN:@CorpSSID_SF"
              },
              {
                "key": "startTime",
                "value": "1633575783076"
              },
              {
                "key": "endTime",
                "value": "1633575783173"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [
              {
                "name": "Association Start",
                "id": "1633575783076155_#_1",
                "eventName": "Association Start",
                "timestamp": 1633575783076,
                "index": 0,
                "eventType": 1,
                "reasonType": 1,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Client Association with AP"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "A8:B4:56:0D:8C:00"
                  },
                  {
                    "key": "AP Name",
                    "value": "AP9120_2"
                  },
                  {
                    "key": "RSSI",
                    "value": "-12 dBm"
                  },
                  {
                    "key": "SNR",
                    "value": "77 dB"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "Association Done",
                "id": "1633575783077206_#_2",
                "eventName": "Association Done",
                "timestamp": 1633575783077,
                "index": 1,
                "eventType": 2,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "A8:B4:56:0D:8C:00"
                  },
                  {
                    "key": "AP Name",
                    "value": "AP9120_2"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "KeyExchange",
                "id": "1633575783173326_#_5",
                "eventName": "KeyExchange",
                "timestamp": 1633575783173,
                "index": 2,
                "eventType": 5,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "A8:B4:56:0D:8C:00"
                  },
                  {
                    "key": "AP Name",
                    "value": "AP9120_2"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              },
              {
                "name": "Run",
                "id": "1633575783173430_#_9",
                "eventName": "Run",
                "timestamp": 1633575783173,
                "index": 3,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [
                  {
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                  },
                  {
                    "key": "Mac Address",
                    "value": "70:F3:5A:7C:7F:D0"
                  },
                  {
                    "key": "isPrivateMac",
                    "value": "false"
                  },
                  {
                    "key": "WLC Name",
                    "value": "CT3504-SF"
                  },
                  {
                    "key": "WLAN",
                    "value": "@CorpSSID_SF"
                  },
                  {
                    "key": "Radio",
                    "value": "1"
                  },
                  {
                    "key": "FREQUENCY",
                    "value": "5.0"
                  },
                  {
                    "key": "AP Mac",
                    "value": "A8:B4:56:0D:8C:00"
                  },
                  {
                    "key": "AP Name",
                    "value": "AP9120_2"
                  },
                  {
                    "key": "IPv4",
                    "value": "192.168.159.164"
                  },
                  {
                    "key": "VLAN ID",
                    "value": "0"
                  },
                  {
                    "key": "ROLE",
                    "value": "LOCAL"
                  }
                ],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
              }
            ]
        },
        {
            "name": "Client Sent Disassociation_1624658147097_1624658147097",
            "id": "8a9a2039-0d99-4387-861d-dbd7ccc9c94d",
            "eventName": "Client Sent Disassociation",
            "timestamp": 1624658147097,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
                {
                    "key": "WLC Name",
                    "value": "WLCDeviceNamePlaceHolder"
                },
                {
                    "key": "BSSID",
                    "value": "10:B3:C6:24:B7:4E"
                },
                {
                    "key": "Frequency(GHz)",
                    "value": "5.0"
                },
                {
                    "key": "Mac Address",
                    "value": "HostMacPlaceHolder"
                },
                {
                    "key": "Radio",
                    "value": "1"
                },
                {
                    "key": "WLAN",
                    "value": "ssidPlaceHolder"
                },
                {
                    "key": "AP Mac",
                    "value": "ApMacPlaceHolder"
                },
                {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                },
                {
                    "key": "isPrivateMac",
                    "value": "true"
                },
                {
                    "key": "additionalInfo",
                    "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
                },
                {
                    "key": "startTime",
                    "value": "1624658147097"
                },
                {
                    "key": "endTime",
                    "value": "1624658147097"
                }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [
                {
                    "name": "Client Sent Disassociation",
                    "id": "1624658147097186_#_17",
                    "eventName": "Client Sent Disassociation",
                    "timestamp": 1624658147097,
                    "index": 0,
                    "eventType": 17,
                    "reasonType": 1361,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Wi-Fi/Device Turned Off"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                }
            ]
        },
        {
            "name": "Onboarding -- Incomplete_1624658014563_1624658014564",
            "id": "f6652393-52f2-4658-abc7-08b2cafba8a2",
            "eventName": "Onboarding -- Incomplete",
            "timestamp": 1624658014563,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
                {
                    "key": "WLC Name",
                    "value": "WLCDeviceNamePlaceHolder"
                },
                {
                    "key": "RSSI",
                    "value": "-56 dBm"
                },
                {
                    "key": "BSSID",
                    "value": "10:B3:C6:24:B7:4E"
                },
                {
                    "key": "Frequency(GHz)",
                    "value": "5.0"
                },
                {
                    "key": "Mac Address",
                    "value": "HostMacPlaceHolder"
                },
                {
                    "key": "WLAN",
                    "value": "ssidPlaceHolder"
                },
                {
                    "key": "Radio",
                    "value": "1"
                },
                {
                    "key": "AP Mac",
                    "value": "ApMacPlaceHolder"
                },
                {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                },
                {
                    "key": "isPrivateMac",
                    "value": "true"
                },
                {
                    "key": "additionalInfo",
                    "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
                },
                {
                    "key": "startTime",
                    "value": "1624658014563"
                },
                {
                    "key": "endTime",
                    "value": "1624658014564"
                }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": true,
            "children": [
                {
                    "name": "Association Start",
                    "id": "1624658014563965_#_1",
                    "eventName": "Association Start",
                    "timestamp": 1624658014563,
                    "index": 0,
                    "eventType": 1,
                    "reasonType": 1169,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Assoc Request"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "RSSI",
                            "value": "-56 dBm"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Association Done",
                    "id": "1624658014564329_#_2",
                    "eventName": "Association Done",
                    "timestamp": 1624658014564,
                    "index": 1,
                    "eventType": 2,
                    "reasonType": 0,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                },
                {
                    "name": "Authentication Start",
                    "id": "1624658014564757_#_3",
                    "eventName": "Authentication Start",
                    "timestamp": 1624658014564,
                    "index": 2,
                    "eventType": 3,
                    "reasonType": 1144,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "L2 Auth Start"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                }
            ]
        },
        {
            "name": "Client Sent Disassociation_1624658008620_1624658008620",
            "id": "aa6c8b21-e211-44ae-aa2f-89be106d1fba",
            "eventName": "Client Sent Disassociation",
            "timestamp": 1624658008620,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [
                {
                    "key": "WLC Name",
                    "value": "WLCDeviceNamePlaceHolder"
                },
                {
                    "key": "BSSID",
                    "value": "10:B3:C6:24:B7:4E"
                },
                {
                    "key": "Frequency(GHz)",
                    "value": "5.0"
                },
                {
                    "key": "Mac Address",
                    "value": "HostMacPlaceHolder"
                },
                {
                    "key": "Radio",
                    "value": "1"
                },
                {
                    "key": "WLAN",
                    "value": "ssidPlaceHolder"
                },
                {
                    "key": "AP Mac",
                    "value": "ApMacPlaceHolder"
                },
                {
                    "key": "AP Name",
                    "value": "APDeviceNamePlaceHolder"
                },
                {
                    "key": "isPrivateMac",
                    "value": "true"
                },
                {
                    "key": "additionalInfo",
                    "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
                },
                {
                    "key": "startTime",
                    "value": "1624658008620"
                },
                {
                    "key": "endTime",
                    "value": "1624658008620"
                }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [
                {
                    "name": "Client Sent Disassociation",
                    "id": "1624658008620874_#_17",
                    "eventName": "Client Sent Disassociation",
                    "timestamp": 1624658008620,
                    "index": 0,
                    "eventType": 17,
                    "reasonType": 1364,
                    "subreasonType": 0,
                    "resultStatus": "CL_SUCCESS",
                    "failCategory": null,
                    "details": [
                        {
                            "key": "additionalInfo",
                            "value": "Airplane Mode Turned On"
                        },
                        {
                            "key": "Mac Address",
                            "value": "HostMacPlaceHolder"
                        },
                        {
                            "key": "isPrivateMac",
                            "value": "true"
                        },
                        {
                            "key": "WLC Name",
                            "value": "WLCDeviceNamePlaceHolder"
                        },
                        {
                            "key": "WLAN",
                            "value": "ssidPlaceHolder"
                        },
                        {
                            "key": "Radio",
                            "value": "1"
                        },
                        {
                            "key": "FREQUENCY",
                            "value": "5.0"
                        },
                        {
                            "key": "AP Mac",
                            "value": "ApMacPlaceHolder"
                        },
                        {
                            "key": "AP Name",
                            "value": "APDeviceNamePlaceHolder"
                        },
                        {
                            "key": "BSSID",
                            "value": "10:B3:C6:24:B7:4E"
                        }
                    ],
                    "issues": null,
                    "healthScore": 0,
                    "source": null,
                    "eventSource": "",
                    "hasPackets": null,
                    "hasAnomalyPackets": null,
                    "isPrivateMac": null
                }
            ]
        }
    ]
});