define({
    TrendAnalyticsTemplate: {
        "version": "v2",
        "data": [{
                "values": {
                    "failureTransactionCount": 0,
                    "successTransactionCount": 106,
                    "totalTransactionCount": 106
                },
                "time": 1678345200000
            },
            
        ],
        "page": null,
        "scope": {
            "startTime": 1678347660000,
            "endTime": 1678434060000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678434290805,
            "timeApiTime": 1678434060000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "failureTransactionCount",
                    "successTransactionCount",
                    "totalTransactionCount"
                ],
                "filters": [{
                    "key": "networkServiceType",
                    "value": "dns",
                    "operator": "eq"
                }],
                "target": "networkService",
                "queryType": "trend",
                "page": {
                    "limit": 300,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TrendAverageLatencyTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 11
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678906800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 13
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678910400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 12
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678914000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 14
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678917600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 10
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678921200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 11
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678924800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678928400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678932000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678935600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678939200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 12
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678942800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 11
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678946400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678950000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 10
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678953600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 11
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678957200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 20
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678960800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678964400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 8
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678968000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 8
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678971600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 9
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678975200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 15
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678978800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 14
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678982400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 10
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678986000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 11
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678989600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "averageLatency": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678993200000
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "averageLatency"
                ],
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "serverIpAddress",
                        "value": [
                            "8.8.8.8"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "networkService",
                "queryType": "trend",
                "page": {
                    "limit": 300,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 300
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TrendTotalTransactionAnalyticsTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 3071
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678906800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 3428
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678910400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 2717
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678914000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 1569
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678917600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 2294
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678921200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 1448
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678924800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 1621
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678928400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 2595
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678932000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 340
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678935600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 54
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678939200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 128
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678942800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 102
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678946400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 57
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678950000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 135
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678953600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 77
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678957200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 89
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678960800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 99
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678964400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 85
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678968000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 72
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678971600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 154
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678975200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 117
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678978800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 107
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678982400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 73
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678986000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 91
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678989600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "totalTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678993200000
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "totalTransactionCount"
                ],
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "serverIpAddress",
                        "value": [
                            "8.8.8.8"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "networkService",
                "queryType": "trend",
                "page": {
                    "limit": 300,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 300
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TrendfailureTransactionTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678906800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 5
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678910400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678914000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 6
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678917600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678921200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678924800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678928400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678932000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678935600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678939200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678942800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678946400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678950000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678953600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678957200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678960800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678964400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678968000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678971600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678975200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678978800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678982400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678986000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678989600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "failureTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678993200000
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "failureTransactionCount"
                ],
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "serverIpAddress",
                        "value": [
                            "8.8.8.8"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "networkService",
                "queryType": "trend",
                "page": {
                    "limit": 300,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 300
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TrendSuccessTransactionTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 3071
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678906800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 3423
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678910400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 2717
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678914000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 1563
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678917600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 2294
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678921200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 1448
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678924800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 1621
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678928400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 2595
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678932000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 340
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678935600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 54
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678939200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 128
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678942800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 102
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678946400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 57
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678950000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 135
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678953600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 77
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678957200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 89
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678960800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 99
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678964400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 85
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678968000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 72
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678971600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 154
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678975200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 117
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678978800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 107
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678982400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 73
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678986000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 91
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678989600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "successTransactionCount": 0
                            },
                            "serverIpAddress": "8.8.8.8"
                        }
                    ]
                },
                "time": 1678993200000
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "successTransactionCount"
                ],
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "serverIpAddress",
                        "value": [
                            "8.8.8.8"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "networkService",
                "queryType": "trend",
                "page": {
                    "limit": 300,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 300
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "ListAnalyticsTemplate":
    {
        "version": "v2",
        "data": [{
                "deviceType": "Cisco Catalyst 9136I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 7,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 34398,
                    "totalTransactionCount": 34398
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9136-1",
                "deviceUUID": "5f6613b8-5483-43f5-9553-084a2c39dc2a",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9164I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 9,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 800,
                    "totalTransactionCount": 800
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9164-3",
                "deviceUUID": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9166I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 11,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 620,
                    "totalTransactionCount": 620
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9166-1",
                "deviceUUID": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9166I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 17,
                    "failureTransactionCount": 1,
                    "successTransactionCount": 552,
                    "totalTransactionCount": 553
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9166-2",
                "deviceUUID": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst 9136I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 10,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 265,
                    "totalTransactionCount": 265
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9136-2",
                "deviceUUID": "951c06a1-c15d-433b-a116-ca9ba06e9ef2",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9164I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 14,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 154,
                    "totalTransactionCount": 154
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9164-5",
                "deviceUUID": "a78b29e3-6286-4270-9cde-ed1a13eadc6c",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9164I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 28,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 86,
                    "totalTransactionCount": 86
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9164-2",
                "deviceUUID": "af9f4990-f166-483d-9130-2063f3e7379a",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst Wireless 9164I Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 32,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 62,
                    "totalTransactionCount": 62
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-F1-9164-1",
                "deviceUUID": "ed05e209-a11b-4bd7-87ad-435377968978",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            },
            {
                "deviceType": "Cisco Catalyst 9120AXI Unified Access Point",
                "deviceSiteHierarchyGraphId": "e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665",
                "values": {
                    "averageLatency": 19,
                    "failureTransactionCount": 0,
                    "successTransactionCount": 33,
                    "totalTransactionCount": 33
                },
                "serverIpAddress": "8.8.8.8",
                "deviceFamily": "Unified AP",
                "deviceName": "SJC14-TME-AP7",
                "deviceUUID": "93c9aadd-f9d1-4f2f-a1a6-cfbe67ad257d",
                "deviceLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
            }
        ],
        "page": {
            "limit": 10,
            "offset": 0,
            "cursor": null,
            "pages": 1,
            "count": 42
        },
        "scope": {
            "startTime": 1678638000000,
            "endTime": 1678724400000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678724597750,
            "timeApiTime": 1678724400000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "serverIpAddress",
                    "failureTransactionCount",
                    "totalTransactionCount",
                    "successTransactionCount",
                    "averageLatency",
                    "deviceUUID"
                ],
                "groupBy": [
                    "serverIpAddress",
                    "deviceUUID"
                ],
                "filters": [{
                    "key": "networkServiceType",
                    "value": "dns",
                    "operator": "eq"
                }],
                "target": "networkService",
                "queryType": "list",
                "page": {
                    "limit": 42,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 42,
                    "orderBy": [{
                        "name": "totalTransactionCount",
                        "order": "desc"
                    }]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNAnalyticsTemplate":{
        "version": "v2",
        "data": [
            {
                "values": {
                    "failureTransactionCount": 8,
                    "responseCodeCount": 2
                },
                "responseCode": 255
            },
            {
                "values": {
                    "failureTransactionCount": 6,
                    "responseCodeCount": 2
                },
                "responseCode": 3
            },
            {
                "values": {
                    "failureTransactionCount": 4,
                    "responseCodeCount": 1
                },
                "responseCode": 5
            }
    ],
        "page": null,
        "scope": {
            "startTime": 1678781160000,
            "endTime": 1678867560000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678867759180,
            "timeApiTime": 1678867560000,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [{
                    "key": "networkServiceType",
                    "value": "dns",
                    "operator": "eq"
                }],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [{
                        "name": "averageLatency",
                        "order": "desc"
                    }]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNSiteTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "failureTransactionCount": 11,
                    "successTransactionCount": 1907,
                    "totalTransactionCount": 23,
                    "responseCodeCount": 2
                },
                "responseCode": 255,
                "serverIpAddress": "8.8.8.8",
                "siteName": "Global/San Jose/Building 14/Floor1",
                "siteUUID": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/"
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "failureTransactionCount"
                ],
                "groupBy": [
                    "siteUUID"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "failureTransactionCount",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNFailureTransactionTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "failureTransactionCount": 11
                },
                "serverIpAddress": "8.8.8.8"
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "failureTransactionCount"
                ],
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "failureTransactionCount",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNResponseCodeTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "failureTransactionCount": 6,
                    "responseCodeCount": 1
                },
                "responseCode": 255
            },
            {
                "values": {
                    "failureTransactionCount": 4,
                    "responseCodeCount": 1
                },
                "responseCode": 5
            },
            {
                "values": {
                    "failureTransactionCount": 1,
                    "responseCodeCount": 1
                },
                "responseCode": 3
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "responseCodeCount"
                ],
                "groupBy": [
                    "responseCode"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "responseCode",
                        "value": 0,
                        "operator": "gt"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "failureTransactionCount",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNAverageLatencyTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "averageLatency": 11
                },
                "serverIpAddress": "8.8.8.8"
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "averageLatency",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNSuccessTransactionTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "successTransactionCount": 19075
                },
                "serverIpAddress": "8.8.8.8"
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "successTransactionCount",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TopNTotalTransactionTemplate":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "totalTransactionCount": 19086
                },
                "serverIpAddress": "8.8.8.8"
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678907400000,
            "endTime": 1678993800000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678994040068,
            "timeApiTime": 1678993800000,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "serverIpAddress"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "topN",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5,
                    "orderBy": [
                        {
                            "name": "totalTransactionCount",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
})