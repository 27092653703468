define({
    "Chassis_View_Template":
    {
      "deviceSeries": "Cisco Catalyst 9300 Series Switches",
      "chassisModel": "C9300-48UXM,C9300-48UXM",
      "cardPorts": {
        "1": {
          "stackOrder": 1,
          "model": "C9300-48UXM",
          "ports": [],
          "modules": {},
          "fans": [
            {
              "name": "Switch 1 - FAN - T1 1",
              "description": "Switch 1  - FAN 1",
              "manufacturer": "",
              "operationalStateCode": null,
              "partNumber": "",
              "serialNumber": "",
              "vendorEquipmentType": "cevFanCat3kCT57xxFan"
            },
            {
              "name": "Switch 1 - FAN - T1 3",
              "description": "Switch 1  - FAN 3",
              "manufacturer": "",
              "operationalStateCode": null,
              "partNumber": "",
              "serialNumber": "",
              "vendorEquipmentType": "cevFanCat3kCT57xxFan"
            }
          ],
          "powerSupply": [
            {
              "name": "Switch 1 - Power Supply A",
              "description": "Switch 1 - Power Supply A",
              "manufacturer": "",
              "operationalStateCode": null,
              "partNumber": "PWR-C1-1100WAC    ",
              "serialNumber": "DTN2137V2BY",
              "vendorEquipmentType": "cevPowerSupplyCat3kCT57xxC1Pwr1100wAc"
            },
            {
              "name": "Switch 1 - Power Supply B",
              "description": "Switch 1 - Power Supply B",
              "manufacturer": "",
              "operationalStateCode": null,
              "partNumber": "",
              "serialNumber": "",
              "vendorEquipmentType": "cevPowerSupplyUnknown"
            }
          ]
        }
      }
    }
    });