define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/bonjour/SimBonjourData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (SimTaskDataGenerator, SimBonjourData, UtilityFunctions ) {

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction) {
        if(urlAction.filter['sortBy'] != undefined && urlAction.filter['sortBy'].indexOf(':') > -1) {
            // /api/v1/service-entry?filterBy=name~tv,macAddress~79&limit=15&offset=1&sortBy=reporterIP:asc
            var decorate = urlAction.filter['sortBy'].split(':');
            urlAction.filter['sortBy'] = decorate[0]; urlAction.filter['orderBy'] = decorate[1];
        }
        if(urlAction.service.indexOf('sd-mdns-preferences') > -1) {
            return SimBonjourData.getPreferenceDetail();
        } else if (urlAction.service.indexOf('dashboard') > -1 && urlAction.filter['top']) { //to be present before others
            return SimBonjourData.getDashboardDetails(urlAction);
        } else if (urlAction.service.indexOf('dashboard') > -1 && urlAction.service.indexOf('query-statistics') > -1) {
            return SimBonjourData.getDashboardGraphDetails(urlAction);
        } else if (urlAction.service.indexOf('service-policy') > -1) { // to be present before query domain check
            if(urlAction.service.indexOf('invalidCount') > -1) { return 0; }
            return SimBonjourData.getPolicy(urlAction);
        } else if (urlAction.service.indexOf('service-entry') > -1) {
            return SimBonjourData.getServiceEntry(urlAction);
        } else if (urlAction.service.indexOf('sdg-node') > -1) { // to be present before query domain check
            return SimBonjourData.getNodeInfo(urlAction);
        } else if (urlAction.service.indexOf('query-domain') > -1) {
            return SimBonjourData.getDomains(urlAction);
        } else if (urlAction.service.indexOf('service-type') > -1) {
            return SimBonjourData.getServiceTypes(urlAction);
        }
    }
    
    function processPostRequest(urlAction) {   
        var id = UtilityFunctions.generate_uuid();
        var resultObj = SimTaskDataGenerator.createTask('SDG Service', {'data':id});
        var restPayload = urlAction.restPayload, isError = false;
        restPayload.id = id;
        if(urlAction.service.indexOf('service-policy') > -1) {
            isError = SimBonjourData.addPolicy(restPayload);
        } else if(urlAction.service.indexOf('query-domain') > -1) {
            isError = SimBonjourData.addDomain(restPayload);
        } else if(urlAction.service.indexOf('service-type') > -1) {
            isError = SimBonjourData.addServiceType(restPayload);
        }
        ///api/v1/sdg-node/resync/ - not sure of action to perform. so perform nothing apart from returning task obj
        if(isError){
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: true , errorCode:200,
                failureReason:"Name already exists!"}); //to check the actual behaviour
        } else {
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            resultObj.statusCode= 202;
        }
        return resultObj;
    }

    function processPutRequest(urlAction) {
        var resultObj = SimTaskDataGenerator.createTask('SDG Service');
        var restPayload = urlAction.restPayload;
        if(urlAction.service.indexOf('service-policy') > -1) {
            SimBonjourData.editPolicy(restPayload);
        } else if(urlAction.service.indexOf('service-type') > -1) {
            SimBonjourData.editServiceType(restPayload);
        }        
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        resultObj.statusCode= 202;
        return resultObj;
    }

    function processDeleteRequest(urlAction) {
        var resultObj = SimTaskDataGenerator.createTask('SDG Service');
        var id = urlAction.action.id;
        if(urlAction.service.indexOf('service-policy') > -1) {
            SimBonjourData.deletePolicy(id);
        } else if(urlAction.service.indexOf('query-domain') > -1) {
            SimBonjourData.deleteDomain(id);
        } else if(urlAction.service.indexOf('service-type') > -1) {
            SimBonjourData.deleteServiceType(id);
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        resultObj.statusCode= 202;
        return resultObj;
    }

});
