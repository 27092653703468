define({
    "OnboardingBaselineAggregatedTemplate": {
        "data": {
            "onboarding36BaselineAggregated": {
                "__typename": "Onboarding36BaselineAggregatedConnection",
                "nodes": [
                    {
                        "__typename": "Onboarding36BaselineAggregatedType",
                        "aesSsid": "all",
                        "aesWlcId": "all",
                        "assocTime": 0.0045262363011133,
                        "authTime": 0.130743191650544,
                        "avgDistinctClientCount": 13,
                        "avgHasFailed": 0.264881553816765,
                        "avgOnboardingTimeSecs": 0.867706418700866,
                        "dhcpTime": 0.274214398111686,
                        "groupHierarchyArray": [],
                        "maxDistinctClientCount": 30,
                        "numAssocFailures": 0,
                        "numIssues": null,
                        "numOnboardings": 8402,
                        "numSsids": 5,
                        "numWlcIds": 1,
                        "siteId": "088f02fd-d341-40cd-9cf4-11b5f4ed0442",
                        "sumDistinctClientCount": 7813
                    }
                ]
            }
        }

    },

    "OnboardingBaselineNetworkStatsTemplate": {
        "data": {
            "onboarding36BaselineNetworkStats": {
                "__typename": "Onboarding36BaselineNetworkStatsConnection",
                "nodes": [
                    {
                        "__typename": "Onboarding36BaselineNetworkStatsType",
                        "issueBreakdown": [
                            {
                                "__typename": "Issue36CountTupleType",
                                "issueType": "anomalous-onboarding-fraction",
                                "numIssues": 2
                            }
                        ],
                        "numSites": 0,
                        "numSsids": 10,
                        "numWlcs": 0
                    }
                ]
            }
        }
    },
    "OnboardingBaselineBySiteDataTemplate": {
        "data": {
            "onboarding36BaselineBySite": {
                "__typename": "Onboarding36BaselineBySiteConnection",
                "nodes": [
                    {
                        "__typename": "Onboarding36BaselineType", // constant
                        "aesSsid": "Corporate", // depends on site
                        "aesWlcId": "10.41.54.185", //depends on site
                        "entityId": "0604320d0dc52be4b014", // unique identity for entity
                        "issueList": [
                            {
                                "__typename": "Issue36TupleType", // constant
                                "entityId": "0604320d0dc52be4b014", // by default null but for the issues it is same as entityId
                                "eventType": "anomalous-onboarding-fraction", // by default null but for the issue possible values are "anomalous-onboarding-fraction" "anomalous-onboarding"
                                "issueId": "a13acf07e8e25989a287" // by default null but for the issue it is unique called in the next call
                            }
                        ],
                        "kpi": "onboarding", // possible values are 'onboarding', 'dhcp_assoc_auth_time', 'assoc_failure'
                        "siteId": "85d5ddd6-76e2-4130-b403-2c3ff88f35ba" // siteId from query
                    }
                ]
            }
        }
    }, 
    "thresholdInTimeRangeByOnboardingIdDataTemplate": {
        "data": {
            "threshold36ByEntityId": {
                "__typename": "Threshold36ByEntityIdConnection",
                "nodes": [
                    {
                        "__typename": "Threshold36grouptype",
                        "id": "avgHasFailed", // this is differet
                        "thresholds": [
                            {
                                "__typename": "Threshold36type",
                                "entityId": "0604320d0dc52be4b014",
                                "eventType": "anomalous-onboarding-fraction", // this is different
                                "highValue": 0.214532041133511, // 0.1 to 0.2
                                "lowValue": 0.0530736311384594, // 0 to 0.1 -> 0.02 to 0.07
                                "metric": "avgHasFailed", // this is different
                                "predictionChanged": false,
                                "timestamp": "2021-05-01T18:30:00"
                            }
                        ]
                    }
                ]
            }
        }
    },
    "thresholdInTimeRangeByOnboardingIdEntityIdsIndex": {
        "0604320d0dc52be4b014": {
            "kpi": "onboarding",
            "entityId": "0604320d0dc52be4b014",
            "anomalous-onboarding-fraction_issueId" : "a1c338adfdc94e34b974",
            "anomalous-onboarding_issueId" : "a1128f3da344443ab656",
            "Ids": [
                {
                    "id": "avgHasFailed",
                    "eventType": "anomalous-onboarding-fraction",
                    "minHighValue": 0.2,
                    "maxHighValue": 0.3,
                    "minLowValue": 0,
                    "maxLowValue": 0.1
                },
                {
                    "id": "avgOnboardingTimeSecs", 
                    "eventType": "anomalous-onboarding", 
                    "minHighValue": 2.1,
                    "maxHighValue": 2.4,
                    "minLowValue": 1.8,
                    "maxLowValue": 1.9
                }
            ]
        },
        "07b20b966bdf348df2db": {
            "kpi": "dhcp_assoc_auth_time",
            "entityId": "07b20b966bdf348df2db",
            "dhcpTime-anomalous-onprem_issueId" : "a1128f3da344443cd767",
            "Ids": [
                {
                    "id": "assocTime", 
                    "eventType": "assocTime-anomalous-onprem", 
                    "minHighValue": 3.89962509332608,
                    "maxHighValue": 435.907924765261,
                    "minLowValue": 0.235240443313727,
                    "maxLowValue": 0.54979624221297
                },
                {
                    "id": "authTime", 
                    "eventType": "authTime-anomalous-onprem", 
                    "minHighValue": 2500.0,
                    "maxHighValue": 3700.0,
                    "minLowValue": 1000.0,
                    "maxLowValue": 2500.0
                },
                {
                    "id": "dhcpTime", 
                    "eventType": "dhcpTime-anomalous-onprem", 
                    "minHighValue": 1410.0,
                    "maxHighValue": 2000.0,
                    "minLowValue": 1000.0,
                    "maxLowValue": 1400.0
                }
            ]
        },
        "0ab20b966bdf348df2db": {
            "kpi": "assoc_failure",
            "entityId": "0ab20b966bdf348df2db",
            "Ids": [
                {
                    "id": "numAssocFailures", 
                    "eventType": "assocFailure-anomalous-onprem", 
                    "minHighValue": 0,
                    "maxHighValue": 0,
                    "minLowValue": 0,
                    "maxLowValue": 0
                }
            ]
        }
    },
    "eventInTimeRangeDataTemplate": {
        "data": {
            "event36InTimeRange": {
                "__typename": "Event36InTimeRangeConnection",
                "nodes":[
                    {
                        "__typename": "Event36", // constant
                        "eventId": "a0a0b5bd62082870684f", //uniqueId
                        "eventType": "anomalous-onboarding-fraction", // anomalous-onboarding-fraction or anomalous-onboarding
                        "issueEventSByEventId": {
                            "__typename": "IssueEvent36SConnection",
                            "nodes": [
                                {
                                    "__typename": "IssueEvent36",
                                    "issueId": "a13acf07e8e25989a287"
                                }
                            ],
                            "totalCount": 1
                        },
                        "timestamp": "2021-05-02T10:00:00"
                    }
                ]
            }
        }
    },

    "OnboardingAndParentsByOnboardingIdTemplate": {
        "data":{
            "onboarding36InTimeRange": {
                "__typename": "Onboarding36SConnection",
                "nodes" :[
                    {
                        "__typename": "Onboarding36",
                        "id": "0604320d0dc52be4b014",
                        "onboardings": [
                            {
                                "__typename": "Onboarding36",
                                "avgHasFailed": 0.0745341614906832, //
                                "avgOnboardingTimeSecs": 1.34827950310559,
                                "entityId": "0604320d0dc52be4b014",
                                "numDistinctClient": "104",
                                "numberOfOnboardings": "322",
                                "timestamp": "2021-05-03T18:30:00" // endtime to starttime 30 mins interval
                            }
                        ]
                    }
                ]
            }
        }
    }, 
    "OnboardingDhcpAssocAuthTimeAndParentsByIdTemplate": {
        "data":{
            "onboardingDhcpAssocAuthTime36InTimeRange": {
                "__typename": "OnboardingDhcpAssocAuthTime36SConnection",
                "nodes":[
                    {
                        "__typename": "OnboardingDhcpAssocAuthTime36",
                        "id": "07b20b966bdf348df2db",
                        "onboardings":[
                            {
                                "__typename": "OnboardingDhcpAssocAuthTime36",
                                "assocTime": 0.835135135135135,
                                "authTime": 164.354054054054,
                                "dhcpTime": 247.805405405405,
                                "entityId": "07b20b966bdf348df2db",
                                "numClients": "94",
                                "timestamp": "2021-05-03T18:30:00",
                                "failureDhcpTimeoutCount":0,
                                "numTotalAttempts":0
                            }
                        ]
                    }
                ]
            }
        }
    }, 
    "OnboardingDhcpAssocAuthFailureInTimeRangeTemplate": {
        "data":{
            "onboardingDhcpAssocAuthFailure36InTimeRange":{
                "__typename": "OnboardingDhcpAssocAuthFailure36SConnection",
                "nodes":[
                    {
                        "__typename": "OnboardingDhcpAssocAuthFailure36",
                        "id": "0ab20b966bdf348df2db",
                        "onboardings":[
                            {
                                "__typename": "OnboardingDhcpAssocAuthFailure36",
                                "entityId": "0ab20b966bdf348df2db",
                                "numAssocFailures": 0,
                                "numAuthFailures": null,
                                "numClients": "94",
                                "numDhcpFailures": null,
                                "timestamp": "2021-05-03T18:30:00"
                            }
                        ]
                    }
                ]
            }
        }
    }, 
    "OnboardingImpactedRadiosByFloorTemplate" : {
        "data": {
            "onboarding36ImpactedRadiosByFloor" : {
                "__typename" : "Onboarding36ImpactedRadiosByFloorConnection",
                "nodes" : [
                    {
                        "__typename" : "Onboarding36sImpactedRadiosByFloorBaselineDashboardTyp",
                        "numImpactedRadios" : 42,
                        "siteHierarchyArray" :["Global"]
                    }
                ]
            }
        }
    },
    "ClientImpactedDevicesByIssueListTemplate" : {
        "data" : {
            "client36ImpactedDevicesByIssue36List" : {
                "__typename" : "clientImpactedDevicesByIssueListConnection",
                "nodes" : [
                    {
                        "__typename": "Client36sImpactedDevicesBaselineDashboardType",
                        "deviceType": "",
                        "numDevices": ""
                    }
                ]
            }
        }
    }, 
    "OnboardingAndRadioStatsBaselineDashboardTemplate": {
        "data": {
            "onboarding36AndRadioStatsBaselineDashboard": {
                "__typename": "Onboarding36AndRadioStatsBaselineDashboardConnection",
                "nodes": [
                    {
                        "id": "0604320d0dc52be4b014",
                        "__typename": "Onboarding36sRadioBaselineDashboardReturnType",
                        "radioStats": [{
                            "aesApMac": "Test MAC",
                            "aesApName": "TestAP",
                            "entityId": "026a257f8b55695c0be5",
                            "frequencyBand": "5 GHz",
                            "numClients": "2",
                            "numFailedOnboardings": "308",
                            "numOnboardings": "327",
                            "numSuccessfulOnboardings": "19",
                            "siteHierarchyArray": ["SJC01"],
                            "slot": 1,
                            "timestamp": "2021-05-19T18:30:00",
                            "__typename": "Onboarding36RadioBaselineDashboardType"
                        }]
                    }
                ]
            }
        }
    }, 
    "OnboardingStatsByFloorAndDeviceDataTemplate" : {
        "data": {
            "onboarding36StatsByFloorAndDevice" : {
                "__typename" : "Onboarding36StatsByFloorAndDeviceConnection",
                "nodes" : [
                    {
                        'avgAssocTime': null,
                        "avgAuthTime": null,
                        "avgDhcpTime": null,
                        "avgHasFailed" : 0.00000157699530396371,
                        "avgOnboardingTimeSecs" : 1.96543905772788,
                        "bucket": 0,
                        "deviceType": null,
                        "floors": [],
                        "numAssocFailures": null,
                        "numAuthFailures": null,
                        "numDhcpFailures": null,
                        "numberOfFailedOnboardings": 2,
                        "numberOfOnboardings": 21477,
                        "numberOfSuccessfulOnboardings": 21475,
                        "siteId": null,
                        "__typename": "Onboarding36ByFloorDeviceType"
                    }
                ]
            }
        }
    },
    "FloorAndDeviceDataObjsTemplate" : {
        "buildingLevelObj" :{
            "__typename": "Onboarding36ByFloorDeviceType",
            "avgAssocTime": null, //not there in L3
            "avgAuthTime": null, //not there in L3
            "avgDhcpTime": null, //not there in L3
            "avgHasFailed" : 0.00000157699530396371,
            "avgOnboardingTimeSecs" : 1.96543905772788,
            "bucket": 0,
            "deviceType": null,
            "floors": [], // only in L1
            "numAssocFailures": null,
            "numAuthFailures": null,
            "numDhcpFailures": null,
            "numberOfFailedOnboardings": 2,
            "numberOfOnboardings": 21477,
            "numberOfSuccessfulOnboardings": 21475,
            "siteId": null
        }, 
        "floorLevelObj" : {
            "__typename": "Onboarding36FloorTupleType",
            "avgAssocTime": null,
            "avgAuthTime": null,
            "avgDhcpTime": null,
            "avgHasFailed": 0,
            "avgOnboardingTimeSecs": 1.68178576200616,
            "bucket": 0,
            "deviceType": null,
            "devices": [],
            "groupHierarchyArray": [], //only in L2
            "numAssocFailures": null,
            "numAuthFailures": null,
            "numDhcpFailures": null,
            "numberOfFailedOnboardings": 0,
            "numberOfOnboardings": 886,
            "numberOfSuccessfulOnboardings": 886,
            "siteId": "03ce3123-16bc-4c1f-80e9-68e787e911f1" // Site id of the floor
        },
        "deviceLevelObj" : {
            "__typename": "Onboarding36DeviceTupleType",
            "avgHasFailed": 0,
            "avgOnboardingAssocTime": null,
            "avgOnboardingAuthTime": null,
            "avgOnboardingDhcpTime": null,
            "avgOnboardingTimeSecs": 1.742,
            "bucket": 0,
            "deviceType": "Apple iPads",
            "numAssocFailures": null,
            "numAuthFailures": null,
            "numDhcpFailures": null,
            "numberOfFailedOnboardings": 0,
            "numberOfOnboardings": 2,
            "numberOfSuccessfulOnboardings": 2,
            "siteId": "03ce3123-16bc-4c1f-80e9-68e787e911f1" // Site id of the floor
        }

    }
})