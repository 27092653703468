define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/license-manage/SimLicenseManagerData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimLicenseTaskDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseTaskStatusTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator'
], function (SimLicenseManagerData, SimLicenseTaskDataGenerator, LicenseTaskStatusTemplate, SimTaskDataGenerator) {

  return {
    init: function () {
    },

    doProcessRestRequest: function (urlAction) {
      var data;
      switch (urlAction.method) {
        case "GET":
          return processGetRequest(urlAction);
        case "POST":
          return processPostRequest(urlAction);
        case "PUT":
          return processPutRequest(urlAction);
        case "DELETE":
          return processDeleteRequest(urlAction);
        default:
          break;
      }
      console.log("Something wrong, It should not have reached here..");
      return data;
    }
  };

  function processGetRequest(urlAction) {
    var data = {};
    if (urlAction.filter['autocompleteField'] != undefined && urlAction.filter['filterOn'] != undefined) {
      data = SimLicenseManagerData.getAutoCompleteData(urlAction);
      return formatResponse(data);
    } else if (urlAction.service.indexOf("overview") > -1 && urlAction.filter.deviceType) {
      data = SimLicenseManagerData.getOverviewDetails(urlAction);
      return formatResponse(data);
    } else if (urlAction.service.indexOf("usage") > -1) {
        if(urlAction.service.indexOf("compliance") > -1 && urlAction.service.indexOf("stats") > -1){
            data = SimLicenseManagerData.getLicenceUsagestats(urlAction);
            return formatResponseNew(data);
        }
      data = SimLicenseManagerData.getUsageDetails(urlAction);
      return formatResponse(data);
    } else if (urlAction.service.indexOf("purchase") > -1 && urlAction.filter.deviceType) {
      data = SimLicenseManagerData.getPurchaseDetails(urlAction);
      return formatResponse(data);
    } else if(urlAction.service.indexOf('applicense') > -1 && urlAction.service.indexOf("app") > -1){
            return {"response": {"licenseLevel": "dnaadvtg"}}
    } else if(urlAction.service.indexOf('applicense') > -1 && urlAction.service.indexOf("NFV Provisioning") > -1 && urlAction.service.indexOf("device") > -1){
      var response = {"response": {}};
      var deviceIds = urlAction.action.id!=undefined ? [urlAction.action.id] : urlAction.service[urlAction.service.length-1].split(",");
      deviceIds.forEach(devId => {
        response.response[devId] = {
          baseNetworkLicense: ["Advantage"],
          isAllowed: true,
          isNetworkLicenseCapable: true,
          licenseLevel: "dnaadvtg",
          reasonIfNotCapable: "NA"
        }
      });
      return response;
    } else if (urlAction.service.indexOf("device") > -1) {
      if (urlAction.service.indexOf('devicelicenselogs') > -1 || 
        urlAction.service.indexOf('devicelicenselogscount') > -1) {
        //View All License History for a device
        return SimLicenseManagerData.getLicenseHistory(urlAction);
      } else if (urlAction.action.count) {
        data = SimLicenseManagerData.getCountDetails(urlAction);
        return formatResponse(data);
      } /*else if(urlAction.service.indexOf('NFV Provisioning') > -1 ){
        data = []
        return formatResponse(data);
      }*/ else {
        data = SimLicenseManagerData.getAllLicenseDetails(urlAction);
        return formatResponse(data);
      }
    }else if(urlAction.service.indexOf('nagwaremessages')>-1 && urlAction.service.indexOf('non-compliance-status')>-1){
      return {"response": {"ooc_device_compliance_status": false, "ooc_license_compliance_status": false}, "version": "1.0.0"}
    }else if(urlAction.service.indexOf('callhome-banner-status')>-1){
      return {"connectionModeOutOfSync": false, "showCallHomeBanner": false}
    }else if (urlAction.service.indexOf('virtual-account')>-1 || urlAction.service.indexOf('virtual-accounts')>-1){
      //to be present before smart-account check
      data = SimLicenseManagerData.getVirtualAcc(urlAction);
      return formatResponse(data);
    } else if (urlAction.service.indexOf("smart-account") > -1) {
      data = SimLicenseManagerData.getSmartDetails(urlAction);
      return formatResponse(data);
    } else if(urlAction.service.indexOf("day0registration") > -1) {
      data = SimLicenseManagerData.getDay0registrationDetails(urlAction);
      data = formatResponse(data);
      return data;
    } else if(urlAction.service.indexOf("credentials") > -1 && urlAction.service.indexOf("cco")  > -1){
      data = SimLicenseManagerData.getCcoCred(urlAction);
      data = formatResponseNew(data);
      return data;
    } else if(urlAction.service.indexOf("credentials") > -1 && urlAction.service.indexOf("smart")  >-1){
      data = SimLicenseManagerData.getSmartCred();
      data = formatResponseNew(data);
      return data;
    } else if(urlAction.service.indexOf('taskinfo') > -1) {
      data = SimLicenseManagerData.getTaskInfo(urlAction);
      return data;
    } else if(urlAction.service.indexOf('licensereservation') > -1) {
      data = SimLicenseTaskDataGenerator.getTaskStatus(urlAction);
      return data;
    } else if(urlAction.service.indexOf('notificationcontroller') > -1 && urlAction.action.count) {
      data = SimLicenseManagerData.getLicenceNotifications(urlAction);
      return {"response":data.length};
    } else if(urlAction.service.indexOf('notificationcontroller') > -1 && urlAction.service.indexOf('notifications') > -1) {
      data = SimLicenseManagerData.getLicenceNotifications(urlAction);
      data = formatResponse(data);
      return data;
    } else if(urlAction.service.indexOf('compliance') > -1 && urlAction.service.indexOf("dnalicenseusage") > -1) {
      data = SimLicenseManagerData.getDashboardSummary(urlAction);
      data = formatResponse(data);
      return data;
    } else if(urlAction.service.indexOf('smart-agent') > -1 ) {
        if(urlAction.service.indexOf('registration') > -1 ) {
            data = SimLicenseManagerData.getSmartAgent();
            data = formatResponseNew(data);

        }else if(urlAction.service.indexOf('entitlement') > -1){
            data = SimLicenseManagerData.getSmartAgentEntitlement();
            data = formatResponseNew(data);
        }
        return data;
    } else if(urlAction.service.indexOf('eula ') > -1 ){
        data = {"responseJSON":{"eula":true},"failureReason":"","errorCode":""};
        return data;
    } else if(urlAction.service.indexOf('config') > -1 ){
        if(urlAction.service.indexOf('status') > -1 && urlAction.service.indexOf('mode') > -1 ){
            data = SimLicenseManagerData.getSsmConnectionStatus();
        }else if(urlAction.service.indexOf('mode') > -1 ){
            data = SimLicenseManagerData.getSsmConnectionMode();
            data = formatResponseNew(data);
        }
        return data;
    } else if(urlAction.service.indexOf('satellite') > -1 ){
        data = SimLicenseManagerData.getSatelliteSetting();
        data = formatResponseNew(data);
        return data;
    } else if(urlAction.service.indexOf('compliance') > -1 && urlAction.service.indexOf("licensePurchaseInformation") > -1) {
      data = SimLicenseManagerData.getLicensePurchaseInfo(urlAction);
      data = formatResponseNew(data);
      return data;
    }  else if(urlAction.service.indexOf('licenseinfo') > -1 && urlAction.service.indexOf("licenses") > -1) {
      data = SimLicenseManagerData.getLicenseViewInfo(urlAction);
      return data;
    } else if(urlAction.service.indexOf("cisco.dna.licenseManagement")>-1) {
      return LicenseTaskStatusTemplate.PERSISTENCE;
    }
    return {response: []};
  }

  function processPostRequest(urlAction) {
    var resultObj = SimTaskDataGenerator.createTask('SPFService');
    if(urlAction.service.indexOf('day0registration') > -1){
      return SimLicenseManagerData.postDay0Registration(urlAction);
    }
    //entitlementinfo/scheduleOnDemand - unable to see a ref cluster - refresh in Overview Tab
    SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
    return { "response": resultObj };
  }

  function processPutRequest(urlAction) {
    var data = {};
    if(urlAction.service.indexOf("managevatokens") > -1 && urlAction.service.indexOf("manage-sl-token-registration")  >-1 ){
      //Register/Deregister
      data = SimLicenseManagerData.registerDeregister(urlAction.restPayload);
    } else if(urlAction.service.indexOf("licensereservation") > -1) {
      data = SimLicenseManagerData.processOtherPutRequest(urlAction);
    }
    return data;
  }

  function processDeleteRequest(urlAction) {
    return {};
  }

  function formatResponse(data) {
    return {"response":data,"version":"1.0"};
  }

  function formatResponseNew(data) {
    return {"response":data,"version":"1.0.0"};
  }

});
