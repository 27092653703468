define({
    "GlobalGroupSummary_Template": {
        "systemGroup": true,
        "additionalInfo":[{
            "nameSpace": "System Settings",
            "attributes": {
                "group.count.total":"0",
                "hasChild":"FALSE",
                "group.count.direct":"0",
                "group.hierarchy.groupType":"SITE",
                "member.count.total":"0",
                "member.count.direct":"0"
            }
        }],
        "groupNameHierarchy": "Global",
        "name":"Global",
        "id":"80b4fcb8-6f56-4986-b520-3b6ce972464a",
        "groupHierarchy":"",
        "instanceTenantId":"SYS0",

    }
});
