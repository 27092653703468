var api = require("!../../../../../../../node_modules/.pnpm/engci-maven.cisco.com+style-loader@1.3.0_webpack@4.46.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../node_modules/.pnpm/engci-maven.cisco.com+css-loader@1.0.1_webpack@4.46.0_webpack@4.46.0/node_modules/css-loader/index.js??ref--6-1!./font-awesome.min.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};