define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
], function(SimLokiDatabaseActions){

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };
    function processGetRequest(urlAction) {

        if(urlAction.service.indexOf('backup') >-1){

            if(urlAction.service.indexOf('mounts') >-1 ){
              return {"version": "1.5.1", "response": {"mounts": []}}
            }
            if(urlAction.service.indexOf('progress') >-1){
                return {"version": "1.5.1", "response": []}
            }
            if(urlAction.service.indexOf('history') >-1){
                return {"version": "1.5.1", "response": []}
            }
            if(urlAction.service.indexOf('schedule') >-1 ){
              return {"version": "1.5.1", "response": []}
            }
            if(urlAction.service.indexOf('remote') >-1 && urlAction.service.indexOf('settings') >-1){
                return {"version": "1.5.1",
                "response": {"error": "Missing remote server settings '['remote_server_username', 'remote_server_dir', 'remote_server_password', 'remote_server_ip', 'remote_server_passphrase', 'remote_server_port']'.",
                "errorCode": "RESOURCE_NOT_FOUND_ERROR"},
                "statusCode":404}
            }
            return {"version": "1.5.1","response": []}
        }
        if(urlAction.service.indexOf('restore') >-1){
            if(urlAction.service.indexOf('progress') >-1){
                return {"version": "1.5.1", "response": []}
            }
            if(urlAction.service.indexOf('history') >-1){
                return {"version": "1.5.1", "response": []}
            }
        }
    }

    function processPostRequest(urlAction){
        if(urlAction.service.indexOf('backup') >-1 ){
            if(urlAction.service.indexOf('mounts') >-1){
                return {"version": "1.5.1", "response": []}
            }
            return {"version": "1.5.1", "response": []};
        }
    }

    function processPutRequest(urlAction){
        if(urlAction.service.indexOf('remote') >-1 && urlAction.service.indexOf('settings') >-1 ) {
            let restPay = urlAction.restPayload;
            return {"version": "1.5.1", "response": []}
        }
    }

});