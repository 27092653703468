define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/SearchUserFilter',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/SearchUserDetail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/SearchNetworkDeviceDetail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/ApplicationListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Netflix',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/DisneyWebPortal',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/WebEx',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Snmp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/ssh',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/CitrixStatic',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msservices',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Http',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/OutlookWebService',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/BinaryOverHttp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/EspnBrowsing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/AppleServices',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Skype',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MsLync',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Dns',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MsLiveAccounts',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Bing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MsOfficeWebApps',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Ntp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MsUpdate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/NetbiosNs',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MsOffice365',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Telnet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Youtube',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/GoogleServices',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/MedicalRecords',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/CapwapControl',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/hl7',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msolap',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Mssqlm',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Securehttp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/msteams',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Microsoftteams',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msteamsvideo',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msteamsappsharing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msteamsaudio',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/search/app/Msteamsmedia',

 ], function(SimLokiDatabaseActions, UtilityFunctions, SearchUserFilter,SearchUserDetail, SearchNetworkDeviceDetail, ApplicationListing, Netflix, DisneyWebPortal,WebEx, Snmp,
    ssh, CitrixStatic, Msservices, Http, OutlookWebService, BinaryOverHttp, EspnBrowsing, AppleServices, Skype, MsLync, Dns, MsLiveAccounts, Bing, MsOfficeWebApps, Ntp, MsUpdate, NetbiosNs,MsOffice365,Telnet,Youtube,GoogleServices,MedicalRecords,CapwapControl,
    hl7,Msolap,Mssqlm,Securehttp,msteams, Microsoftteams, Msteamsvideo, Msteamsappsharing, Msteamsaudio, Msteamsmedia) {

 var gAppList = [];

 return {

      init: function(){
      },
      getAutoCompleteSearch: function(urlAction) {
          var prefix = urlAction.filter['request'], result = [], limit = urlAction.filter['limit'];
          var obj = { "key": "", "type": "", "label": "", "collection": false,
                      "componentContent": [{"component":"fusion","additional":""}]};
        
          if(prefix != undefined && prefix != "" && isNaN(prefix)) {
              prefix = prefix.toLowerCase();
          }

          var devices = SimLokiDatabaseActions.getAll('network-device');
          for(var i = 0, ilimit=0; i < devices.length && ilimit<limit; i++) {
              var hostname = devices[i].hostname.toLowerCase();
              if(hostname.indexOf(prefix)>-1) {
                  var t = JSON.parse(JSON.stringify( obj ));
                  t.key  = devices[i].platformId;
                  t.type = 'NetworkDevice';
                  t.label = devices[i].hostname;
                  t.componentContent[0].additional = devices[i].id;
                  t.componentContent[0].origType = 'NetworkDevice';
                  result.push(t);
                  ilimit++;
              }
          }
          var siteInfo = SimLokiDatabaseActions.getAll('site');
          for (var i = 0, ilimit=0; i < siteInfo.length && ilimit<limit; i++) {
              var name = siteInfo[i].name.toLowerCase();
          if (name.indexOf(prefix)>-1) {
              var t = JSON.parse(JSON.stringify(obj));
              t.componentContent[0].additional = siteInfo[i].id;
              t.componentContent[0].origType = 'Site';
              t.key = siteInfo[i].name;
              t.type = 'Site';
              t.label = siteInfo[i].name;
              result.push(t);
              ilimit++;
          }
        }


          var users = SimLokiDatabaseActions.getAll('user');
          for(var i = 0, ilimit=0; i < users.length && ilimit<limit; i++) {
              var userid = users[i].id.toLowerCase();
              if(userid.indexOf(prefix)>-1) {
                  var t = JSON.parse(JSON.stringify( obj ));
                  t.key = users[i].id;
                  t.type = 'User';
                  t.componentContent[0].origType = 'User';
                  t.label = users[i].id;
                  t.componentContent[0].additional = users[i].id;
                  result.push(t);
                  ilimit++;
              }
          }

          var appList = SimLokiDatabaseActions.getAll('application');
          for(var i=0, ilimit=0; i<appList.length && ilimit<limit; i++) {
              var appName = appList[i].applicationName.toLowerCase();
              if(appName.indexOf(prefix)>-1) {
                  var t = JSON.parse(JSON.stringify(obj));
                  t.key = appList[i].applicationName;
                  t.label = appList[i].applicationName;
                  t.type = "Application";
                  t.componentContent[0].origType = 'Application';
                  result.push(t);
                  ilimit++;
              }
          }
          var appName = SimLokiDatabaseActions.getAll("application");
          for (var i = 0; i < appName.length; i++) {
            var childrenApps = appName[i].children ? appName[i].children : [];

            for (
              var j = 0, jlimit = 0;
              j < childrenApps.length && jlimit < limit;
              j++, jlimit++
            ) {
                var childAppName = childrenApps[j].applicationName.toLowerCase();
              if (childAppName.indexOf(prefix) > -1) {
                var t = JSON.parse(JSON.stringify(obj));
                t.key = childrenApps[j].applicationName;
                t.label = childrenApps[j].applicationName;
                t.type = "Application";
                t.componentContent[0].origType = "Application";
                result.push(t);
              }
            }
          }

           var hosts = SimLokiDatabaseActions.getAll('host');
           for (var i = 0, ilimit=0; i < hosts.length && ilimit<limit; i++) {
                var hostName = hosts[i].hostName.toLowerCase();
                if (hostName.indexOf(prefix)>-1) {
                    var t = JSON.parse(JSON.stringify(obj));
                    t.key = hosts[i].id;
                    t.type = 'Host';
                    t.componentContent[0].origType = 'Host';
                    t.label = hosts[i].hostName;
                    t.componentContent[0].additional = hosts[i].id;
                    result.push(t);
                    ilimit++;
                }
           }

            var virtualNetworks = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
            for (var i = 0, ilimit=0; i < virtualNetworks.length && ilimit<limit; i++) {
                var vnName = virtualNetworks[i].name.toLowerCase();
                if (vnName.indexOf(prefix)>-1) {
                    var t = JSON.parse(JSON.stringify(obj));
                    t.key = virtualNetworks[i].name;
                    t.type = 'VirtualNetwork';
                    t.componentContent[0].origType = 'VirtualNetwork';
                    t.label = virtualNetworks[i].name;
                    t.componentContent[0].additional = virtualNetworks[i].id;
                    result.push(t);
                    ilimit++;
                }
            }

            var applicationGroups = SimLokiDatabaseActions.getAll('scalablegroup');
            for (var i = 0, ilimit=0; i < applicationGroups.length && ilimit<limit; i++) {
                var apGrpName = applicationGroups[i].name.toLowerCase();
                if (apGrpName.indexOf(prefix)>-1) {
                    var t = JSON.parse(JSON.stringify(obj));
                    t.key = applicationGroups[i].name;
                    t.type = 'ApplicationGroup';
                    t.componentContent[0].origType = 'ApplicationGroup';
                    t.label = applicationGroups[i].name;
                    t.componentContent[0].additional = applicationGroups[i].id;
                    result.push(t);
                    ilimit++;
                }
            }
            
            var authTemplates = SimLokiDatabaseActions.getAll('siteprofile');
            for (var i = 0, ilimit=0; i < authTemplates.length && ilimit<limit; i++) {
                var authTemplate = authTemplates[i].name.toLowerCase();
                if (authTemplate.indexOf(prefix)>-1) {
                    var t = JSON.parse(JSON.stringify(obj));
                    t.key = authTemplates[i].name;
                    t.type = 'AuthTemplate';
                    t.componentContent[0].origType = 'AuthTemplate';
                    t.label = authTemplates[i].name;
                    t.componentContent[0].additional = authTemplates[i].id;
                    result.push(t);
                    ilimit++;
                }
            }

            var accessContracts = SimLokiDatabaseActions.getAll('access-contract');
            for (var i = 0, ilimit=0; i < accessContracts.length && ilimit<limit; i++) {
                var authTemplate = accessContracts[i].name.toLowerCase();
                if (authTemplate.indexOf(prefix)>-1) {
                    var t = JSON.parse(JSON.stringify(obj));
                    t.key = accessContracts[i].name;
                    t.type = 'AccessContract';
                    t.componentContent[0].origType = 'AccessContract';
                    t.label = accessContracts[i].name;
                    t.componentContent[0].additional = accessContracts[i].id;
                    result.push(t);
                    ilimit++;
                }
            }

          if(urlAction.filter.indexOf('type')>-1) result=result.filter(e=>e.type===urlAction.filter.type)
          return result;
      },

      getSearchLookup: function(urlAction) {
          var type = urlAction.restPayload.type, obj = {};
          var response = {"request": urlAction.restPayload, "result":[]},
            fusionTemplate = {"bookID":{"namespace":"fusion","name":"core","version":"1.0.1"}, "response":{}},
            assuranceTemplate = {"bookID":{"namespace": "assurance", "name":"assurance", "version":"1.0.1"}, "response":{}};
          switch(type) {
              case "NetworkDevice":
                  obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': urlAction.restPayload.label });
                  if(!obj || obj.length==0) { break; }
                  obj = obj[0];
                  response.data = {};
                  obj.deviceSupportLevel = "Supported", obj.instanceTenantId = "5d51c31c9fc34600c8b45705",
                  obj.label = "NetworkDevice", obj.isDeleted = "false",
                  obj.timestamp = UtilityFunctions.getGivenTimeInGivenFormat(undefined, "YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                  obj.communicationState = obj.reachabilityStatus.toUpperCase();
                  obj.deviceSeries = obj.series;
                  var connectedUpstreamLink = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'target':obj.id});
                  if(connectedUpstreamLink && connectedUpstreamLink.length) {
                      var upstreamDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':connectedUpstreamLink[0].source});
                      if(upstreamDevice.length>0) {
                        obj.nwDeviceName = upstreamDevice[0].hostName;
                        obj.nwDeviceId = upstreamDevice[0].id;
                        obj.nwDeviceRole = upstreamDevice[0].role;
                        obj.nwDeviceFamily = upstreamDevice[0].family;
                        obj.nwDeviceType = upstreamDevice[0].type;
                      }
                  }
                  obj.softwareType = obj.family;
                  obj.osType = obj.family;
                  response.data.response = obj;
                  fusionTemplate.response = obj;
                  assuranceTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  response.result.push(assuranceTemplate);
                  return response;

              case "User":
                   obj = SimLokiDatabaseActions.getFilteredRecordHandler('user',{'id': urlAction.restPayload.label });
                   if(!obj || obj.length==0) { break; }
                   obj = obj[0];
                   response.data = {};
                   response.data.response = obj;
                   fusionTemplate.response = obj;
                   response.result.push(fusionTemplate);
                   return response;

            case "Application":
                  obj = SimLokiDatabaseActions.getFilteredRecordHandler(
                      "application",
                      { applicationName: urlAction.restPayload.label }
                  );
                  obj = obj[0];
                  var appResponse = getApplicationDetail(urlAction);
                  response.data = {};
                  response.data.response = appResponse;
                  fusionTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  return response;

             case "Site":
                  obj = getSitesDetail(urlAction);
                  if(!obj || obj.length==0) { break; }
                  response.data = {};
                  response.data.response = obj;
                  fusionTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  return response;

             case "Host":
                  obj = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'hostName': urlAction.restPayload.label });
                  if(!obj || obj.length==0) { break; }
                  obj = obj[0];
                  var connectedUpstreamLink = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'target':obj.id});
                  if(connectedUpstreamLink && connectedUpstreamLink.length) {
                      var upstreamDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':connectedUpstreamLink[0].source});
                      obj.nwDeviceName = upstreamDevice[0].hostName;
                      obj.nwDeviceId = upstreamDevice[0].id;
                      obj.nwDeviceRole = upstreamDevice[0].role;
                      obj.nwDeviceFamily = upstreamDevice[0].family;
                      obj.nwDeviceType = upstreamDevice[0].type;
                  }
                  response.data = {};
                  response.data.response = obj;
                  fusionTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  return response;
                  
             case "ApplicationGroup":
                  obj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'name': urlAction.restPayload.label });
                  if(!obj || obj.length==0) { break; }
                  obj = obj[0];
                  response.data = {};
                  response.data.response = obj;
                  fusionTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  return response;

             case "AccessContract":
                  obj = SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'name': urlAction.restPayload.label });
                  if(!obj || obj.length==0) { break; }
                  obj = obj[0];
                  response.data = {};
                  response.data.response = obj;
                  fusionTemplate.response = obj;
                  response.result.push(fusionTemplate);
                  return response;
            
             default:
                  response.result.push(fusionTemplate);
                  return response;
          }
          if(Object.keys(obj).length === 0 && obj.constructor === Object) {
              //should ideally not enter here.. if it is entering here, it means we are checking the table against wrong field..
              response.result.push(fusionTemplate);
              return response;
          }
      }
  };

  function getApplicationDetail(urlAction) {
    var appName = urlAction.restPayload.label;
    appName = appName.toLowerCase();
    var response;
    for (var i = 0; i < ApplicationListing.Template.length; i++) {
      if (ApplicationListing.Template[i].key == appName) {
        /*&& ApplicationListing.Template[i].key == childAppName )*/ response =
          eval(ApplicationListing.Template[i].value);
      }
    }
    return response.Template.data.response;
  }

  function getSitesDetail(urlAction) {
      var name = urlAction.restPayload.label;
      var obj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': name });
      var t = {};
      if (obj.length) {
          t = {
              "id": obj[0].id,
              "label": urlAction.restPayload.type,
              "siteHierarchy": [obj[0].groupNameHierarchy],
              "name": obj[0].name,
              "siteType": [obj[0].additionalInfo[1].attributes.type],
              "parentId": obj[0].parentId,
              "systemGroup": false,
              "additionalInfo": [obj[0].additionalInfo],
              "groupTypeList": [obj[0].groupTypeList],
              "groupNameHierarchy": obj[0].groupNameHierarchy,
              "instanceTenantId": obj[0].id
          };
      }
      //return { "data": { "response": t } };
      return t;

  }

 function applicationNameMatches(prefix) {
    var applicationList = [];
    for (var i = 0; i < ApplicationListing.Template.length; i++) {
        if (ApplicationListing.Template[i].key.indexOf(prefix) >= 0) {
            applicationList.push(ApplicationListing.Template[i].value);
        }
    }
    //obj = SimLokiDatabaseActions.getFilteredRecordHandler('application',{'applicationName'
    return applicationList;
 }

});
