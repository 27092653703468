define({
  "Template":
   {
  "issueCount": 0,
  "testType": "outlookserver",
  "testResultDetails": {
    "passCount": 298,
    "failureCount": 2,
    "slowCount": 0
  },
  "worstPerformingSite": {
    "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "count": 300
  },
  "KpiPerformanceInterval": [
    {
      "kpi": "responseTimeMillis",
      "performanceThreshold": 2000,
      "averagePerformanceValue": 0.0,
      "minPerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 9.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 45.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 0.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 44.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 18.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 26.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 0.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 39.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 0.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 29.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 0.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 34.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 52.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 0.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 34.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 55.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 0.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 39.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 48.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 0.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 48.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 0.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 39.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 19.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 27.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 36.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 28.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 53.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 21.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 8.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 33.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 42.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 32.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 9.0,
      "endTime": 1527213600000
    }
      ],
      "averagePerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 0.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 69.1,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 0.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 88.9,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 88.9,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 89.8,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 90.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 109.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 0.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 99.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 49.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 68.9,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 59.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 0.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 88.909090909090908,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value":75.1,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 65.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 99.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 9.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 89.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 89.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 89.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 89.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 89.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 89.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 58.9,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 58.8,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 0.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 100.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 99.1,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 0.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 99.1,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 98.909090909090908,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 109.1,
      "endTime": 1527213600000
    }
      ],
      "maxPerformanceList": [
            {
      "intervalIndex": "interval-0",
      "value": 9.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 100.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 137.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 137.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 137.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 137.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 137.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 137.8,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 137.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 137.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 137.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 137.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 0.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 136.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 0.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 137.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 137.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 137.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 137.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 137.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 0.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 137.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 137.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 137.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 137.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 137.9,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 137.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 137.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 137.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 137.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 137.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 137.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 137.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 137.0,
      "endTime": 1527213600000
    }
      ]
    }
  ]
}
});
