define({
    "runningImageList":[  
        {  
           "name":"s2t54-adventerprisek9-mz.SPA.152-1.SY1a.bin",
           "imageUuid":"e73c44e1-e1b1-47ca-8fb9-8d97fff75bd4",
           "version":"15.2(1)SY1a",
           "softwareType":null,
           "imageType":"RELEASE",
           "isTaggedGolden":"false",
           "md5Checksum":null,
           "shaCheckSum":null,
           "createdTime":null,
           "applicationType":null,
           "feature":null,
           "fileServiceId":null,
           "imageSeries":null,
           "imageSource":null,
           "imageSourceType":null,
           "imageFamily":"s2t54",
           "vendor":null,
           "filesize":0,
           "deviceCount":0,
           "deviceUuidList":[],
           "smuCount":0,
           "imageAttributes":[  

           ],
           "isSMUApplicable":false,
           "goldenTagInheritedFromSiteUuid":null,
           "goldenTagInheritedFromSiteName":null,
           "importSupported":true,
           "reasonForNotSupportingImport":null,
           "tagList":[  
              {  
                 "taggedGolden":false,
                 "role":"ALL",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"CORE",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"DISTRIBUTION",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"BORDER ROUTER",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"UNKNOWN",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"ACCESS",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              }
           ],
           "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
           "imageCategory":"PHYSICAL",
           "imageIntegrityStatus":null,
           "ccorecommended":false,
           "ccolatest":false
        }
     ]
});