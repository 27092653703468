define({
"Template":
{
  "request": {
    "key": "hl7",
    "type": "Application",
    "label": "hl7",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "45aad9c9-2aec-4e10-bfad-1bcfb97aa605",
        "origType": "Application"
      }
    ]
  },
  "data": {
    "response": {
      "id": "45aad9c9-2aec-4e10-bfad-1bcfb97aa605",
      "instanceId": 4542,
      "displayName": "4542",
      "instanceVersion": 0,
      "createTime": 1565642661115,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1565642661115,
      "name": "hl7",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "isDeleted": false,
      "iseReserved": false,
      "propagateToAci": false,
      "pushed": false,
      "scalableGroupExternalHandle": "hl7",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
        "instanceId": 11011,
        "displayName": "11011",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [],
      "networkApplications": [
        {
          "id": "87bfe3bd-d2a9-4382-9a24-9fd67f5f8f11",
          "instanceId": 12493,
          "displayName": "12493",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationSubType": "NONE",
          "applicationType": "DEFAULT",
          "categoryId": "6c7e3431-3841-441a-9628-5d55707c15d5",
          "createTime": 1565642661115,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Health Level Seven protocol, information exchanging between health applications",
          "ignoreConflict": false,
          "lastUpdateTime": 1565642661115,
          "longDescription": "Health Level Seven is a protocol designated to exchange information between health applications. The protocol is messaged based and can give the client various information regarding his health.",
          "name": "hl7",
          "nbarId": "73",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.hl7.org/about/index.cfm?ref=nav",
          "selectorId": "73",
          "serverNames": [],
          "subCategory": "f87a2a34-4dc2-43bd-96d3-dc96a93dca63",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [],
      "parentScalableGroup": {
        "idRef": "9bbbb8fe-7dcb-4a17-86a2-907a02340a76"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "45aad9c9-2aec-4e10-bfad-1bcfb97aa605",
        "instanceId": 4542,
        "displayName": "4542",
        "instanceVersion": 0,
        "createTime": 1565642661115,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1565642661115,
        "name": "hl7",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "isDeleted": false,
        "iseReserved": false,
        "propagateToAci": false,
        "pushed": false,
        "scalableGroupExternalHandle": "hl7",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
          "instanceId": 11011,
          "displayName": "11011",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "87bfe3bd-d2a9-4382-9a24-9fd67f5f8f11",
            "instanceId": 12493,
            "displayName": "12493",
            "instanceVersion": 0,
            "applicationGroup": "other",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "6c7e3431-3841-441a-9628-5d55707c15d5",
            "createTime": 1565642661115,
            "encrypted": "false",
            "engineId": "13",
            "helpString": "Health Level Seven protocol, information exchanging between health applications",
            "ignoreConflict": false,
            "lastUpdateTime": 1565642661115,
            "longDescription": "Health Level Seven is a protocol designated to exchange information between health applications. The protocol is messaged based and can give the client various information regarding his health.",
            "name": "hl7",
            "nbarId": "73",
            "p2pTechnology": "false",
            "popularity": 6,
            "rank": 65535,
            "references": "http://www.hl7.org/about/index.cfm?ref=nav",
            "selectorId": "73",
            "serverNames": [],
            "subCategory": "f87a2a34-4dc2-43bd-96d3-dc96a93dca63",
            "trafficClass": "BULK_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [],
        "parentScalableGroup": {
          "idRef": "9bbbb8fe-7dcb-4a17-86a2-907a02340a76"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
}
})