define({
  "Template":
    {
  "issueCount": 0,
  "testType": "filetransfer",
  "testResultDetails": {
  "passCount": 1695,
  "failureCount": 3,
  "slowCount": 0
  },
  "worstPerformingSite": {
    "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "count": 1698
  },
  "KpiPerformanceInterval": [
    {
      "kpi": "ftp_response_time",
      "performanceThreshold": 2000,
      "averagePerformanceValue": 0.0,
      "minPerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 10.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 10.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 10.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 10.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 10.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 9.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 10.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 10.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 10.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 9.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 9.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 9.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 9.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 9.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 9.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 9.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 9.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 9.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 9.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 9.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 9.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 9.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 9.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 9.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 9.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 9.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 9.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 9.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 9.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 10.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 9.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 9.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 9.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 0.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 9.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 9.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 10.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 10.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 0.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 10.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 9.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 9.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 9.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 10.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 9.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 9.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 9.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 9.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 10.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 9.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 9.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 10.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 10.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 9.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 9.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 10.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 10.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 9.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 9.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 10.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 9.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 9.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 9.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 9.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 9.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 9.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 9.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 9.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 9.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 9.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 9.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 9.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 9.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 9.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 9.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 9.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 9.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 9.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 9.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 9.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 9.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 9.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 8.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 9.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 9.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 9.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 9.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 9.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 9.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 9.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 9.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 9.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 8.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 9.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 8.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 9.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 9.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 9.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 9.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 9.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 9.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 9.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 9.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 9.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 9.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 9.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 9.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 9.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 0.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 9.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 9.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 9.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 9.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 8.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 9.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 9.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 9.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 9.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 9.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 9.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 9.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 9.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 9.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 9.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 9.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 9.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 9.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 9.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 9.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 9.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 9.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 9.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 9.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 9.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 9.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 9.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 9.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 9.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 9.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 9.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 9.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 9.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 9.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 9.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 9.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 9.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 9.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 9.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 9.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 9.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 9.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 9.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 9.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 9.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 9.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 9.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 9.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 9.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 9.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 9.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 9.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 9.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 9.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 9.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 9.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 9.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 9.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 9.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 9.0,
      "endTime": 1526727600000
    }

      ],
      "averagePerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 10.428571428571429,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 11.8,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 10.3,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 12.2,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 10.3,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 10.2,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 10.2,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 13.2,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 11.3,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 9.555555555555555,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 10.6,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 9.8,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 10.1,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 11.4,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 9.636363636363637,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 9.8,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 10.5,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 13.4,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 14.6,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 15.4,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 10.090909090909092,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 10.4,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 12.545454545454545,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 9.8,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 10.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 10.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 11.6,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 10.8,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 12.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 11.9,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 23.3,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 9.6,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 11.181818181818182,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 16.4,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 15.666666666666666,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 10.666666666666666,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 10.2,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 10.2,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 9.1,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 10.4,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 10.3,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 9.916666666666666,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 10.1,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 10.6,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 10.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 10.7,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 9.909090909090908,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 10.2,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 10.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 9.9,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 10.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 10.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 10.4,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 9.6,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 9.7,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 10.3,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 10.333333333333334,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 9.8,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 10.7,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 10.2,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 9.9,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 10.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 10.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 14.3,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 9.9,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 9.7,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 9.545454545454545,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 13.6,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 9.7,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 9.636363636363637,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 9.7,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 10.11111111111111,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 9.454545454545455,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 14.8,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 9.8,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 9.7,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 9.909090909090908,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 9.7,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 9.9,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 9.8,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 9.909090909090908,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 11.2,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 10.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 9.9,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 10.2,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 10.4,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 10.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 11.125,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 9.8,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 9.727272727272727,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 9.8,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 9.5,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 11.2,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 9.5,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 9.545454545454545,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 9.9,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 9.636363636363637,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 9.7,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 9.545454545454545,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 12.7,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 10.4,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 10.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 9.636363636363637,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 9.9,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 9.636363636363637,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 9.7,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 9.9,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 10.1,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 8.9,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 9.636363636363637,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 10.1,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 10.2,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 9.8,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 9.272727272727273,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 9.7,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 9.8,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 10.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 9.2,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 9.75,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 9.9,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 10.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 9.636363636363637,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 9.4,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 9.9,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 9.9,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 10.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 9.6,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 9.6,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 9.5,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 10.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 9.8,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 9.818181818181818,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 10.3,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 9.9,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 9.9,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 10.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 10.6,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 9.9,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 9.9,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 9.7,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 11.4,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 9.9,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 10.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 10.4,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 10.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 9.8,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 9.727272727272727,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 10.1,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 9.666666666666666,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 10.181818181818182,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 9.9,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 9.7,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 9.363636363636363,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 9.222222222222221,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 11.6,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 9.6,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 14.7,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 9.6,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 9.333333333333334,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 10.9,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 9.9,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 9.7,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 9.727272727272727,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 10.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 9.9,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 9.8,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 9.7,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 9.636363636363637,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 9.9,
      "endTime": 1526727600000
    }
      ],
      "maxPerformanceList": [
            {
      "intervalIndex": "interval-0",
      "value": 11.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 27.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 11.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 29.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 11.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 12.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 11.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 23.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 19.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 10.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 17.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 11.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 13.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 24.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 11.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 10.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 18.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 45.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 41.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 47.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 11.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 14.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 37.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 11.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 11.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 11.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 27.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 18.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 29.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 26.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 66.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 11.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 24.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 53.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 43.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 17.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 11.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 11.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 11.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 11.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 11.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 11.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 11.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 12.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 11.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 17.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 11.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 11.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 10.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 10.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 11.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 10.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 12.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 10.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 11.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 12.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 11.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 11.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 17.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 11.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 11.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 12.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 13.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 54.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 11.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 11.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 10.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 50.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 11.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 11.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 10.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 11.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 10.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 61.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 11.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 11.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 11.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 11.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 11.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 11.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 12.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 21.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 11.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 11.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 11.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 13.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 11.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 20.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 11.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 11.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 11.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 10.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 24.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 10.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 11.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 11.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 11.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 11.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 11.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 34.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 18.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 13.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 11.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 11.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 11.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 11.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 11.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 11.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 11.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 11.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 11.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 12.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 11.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 10.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 11.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 11.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 11.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 10.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 11.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 11.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 11.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 11.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 10.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 11.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 11.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 11.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 11.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 11.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 11.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 12.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 11.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 12.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 12.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 11.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 11.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 12.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 16.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 11.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 11.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 10.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 23.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 11.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 11.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 12.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 11.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 12.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 11.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 13.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 10.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 12.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 12.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 11.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 10.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 10.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 29.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 10.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 59.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 11.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 10.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 19.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 11.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 11.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 11.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 11.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 11.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 11.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 11.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 11.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 12.0,
      "endTime": 1526727600000
    }
      ]
    }
  ]
}
});
