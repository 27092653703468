define({
    "Template": [
        {
          "id": "0be94a07-dd6c-41fd-9d76-bd5089329a4f",
          "instanceId": 103139,
          "displayName": "4 Class",
          "instanceVersion": 0,
          "createTime": 1557845244390,
          "deployed": false,
          "description": "",
          "isSeeded": false,
          "isStale": false,
          "lastUpdateTime": 1557845244390,
          "name": "SPP1-4Class",
          "provisioningState": "UNKNOWN",
          "resourceVersion": 0,
          "targetIdList": [
            
          ],
          "type": "spslaprofile",
          "cfsChangeInfo": [
            
          ],
          "customProvisions": [
            
          ],
          "classModelName": "FOUR_CLASS",
          "spProfileName": "SPP1-4Class",
          "spSlaPrfofileModelClass": [
            {
              "id": "d8965db7-6d2f-45a9-990b-882e23547f5b",
              "instanceId": 111131,
              "displayName": "111131",
              "instanceVersion": 0,
              "className": "CLASS1 DATA",
              "dscp": "26",
              "priority": false,
              "spBandwidth": "44",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "7fd90827-534b-4873-9b76-d7cb2e11ff48",
                  "instanceId": 112149,
                  "displayName": "112149",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "BROADCAST_VIDEO"
                },
                {
                  "id": "a1c4166a-d2c8-4b74-8083-cda7569672fb",
                  "instanceId": 112151,
                  "displayName": "112151",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_CONFERENCING"
                },
                {
                  "id": "ecb68d7a-7ce3-46e1-a84e-fd9e438f5255",
                  "instanceId": 112150,
                  "displayName": "112150",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "REAL_TIME_INTERACTIVE"
                },
                {
                  "id": "688793a1-4470-4eab-a952-0c89449f831d",
                  "instanceId": 112152,
                  "displayName": "112152",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_STREAMING"
                }
              ]
            },
            {
              "id": "711f8fa2-ed2b-4958-bca7-e198382f08e8",
              "instanceId": 111130,
              "displayName": "111130",
              "instanceVersion": 0,
              "className": "Voice",
              "dscp": "46",
              "priority": true,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "344af39f-5842-43a0-9b1a-4db2482c2703",
                  "instanceId": 112148,
                  "displayName": "112148",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "VOIP_TELEPHONY"
                }
              ]
            },
            {
              "id": "1eff9d36-fdd6-4841-870c-47b9e1a45a19",
              "instanceId": 111133,
              "displayName": "111133",
              "instanceVersion": 0,
              "className": "Default",
              "dscp": "0",
              "priority": false,
              "spBandwidth": "31",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "08807e55-e328-495e-a37e-a0e12e28c0d8",
                  "instanceId": 112157,
                  "displayName": "112157",
                  "instanceVersion": 0,
                  "queuingBandwidth": 1,
                  "trafficClass": "SCAVENGER"
                },
                {
                  "id": "02f7b9dd-551f-477e-9e49-0a05d530092d",
                  "instanceId": 112159,
                  "displayName": "112159",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "NETWORK_CONTROL"
                },
                {
                  "id": "805f1392-8447-43bf-82fa-dce1fa6a5556",
                  "instanceId": 112158,
                  "displayName": "112158",
                  "instanceVersion": 0,
                  "queuingBandwidth": 27,
                  "trafficClass": "BEST_EFFORT"
                }
              ]
            },
            {
              "id": "54a6a21c-9320-4420-aa68-4b96e1fdf538",
              "instanceId": 111132,
              "displayName": "111132",
              "instanceVersion": 0,
              "className": "CLASS2 DATA",
              "dscp": "18",
              "priority": false,
              "spBandwidth": "25",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "1a2ec299-9bed-4cae-926a-c7f14cabd466",
                  "instanceId": 112153,
                  "displayName": "112153",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "SIGNALING"
                },
                {
                  "id": "b85dd6c2-1a61-4a9c-84d1-4a277e850d5a",
                  "instanceId": 112155,
                  "displayName": "112155",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "TRANSACTIONAL_DATA"
                },
                {
                  "id": "8119c7c1-74e7-4deb-9c5d-6082100dc86a",
                  "instanceId": 112154,
                  "displayName": "112154",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "OPS_ADMIN_MGMT"
                },
                {
                  "id": "9332484c-8c3a-46bd-8cd0-2c63d50e3192",
                  "instanceId": 112156,
                  "displayName": "112156",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "BULK_DATA"
                }
              ]
            }
          ]
        },
        {
          "id": "306ae5a3-4666-4fff-b846-72e9fbd321ed",
          "instanceId": 103137,
          "displayName": "6 Class",
          "instanceVersion": 0,
          "createTime": 1557845244390,
          "deployed": false,
          "description": "",
          "isSeeded": false,
          "isStale": false,
          "lastUpdateTime": 1557845244390,
          "name": "SPP3-6Class",
          "provisioningState": "UNKNOWN",
          "resourceVersion": 0,
          "targetIdList": [
            
          ],
          "type": "spslaprofile",
          "cfsChangeInfo": [
            
          ],
          "customProvisions": [
            
          ],
          "classModelName": "SIX_CLASS",
          "spProfileName": "SPP3-6Class",
          "spSlaPrfofileModelClass": [
            {
              "id": "8d85cd93-3493-44ca-a213-8fdbfdf869c2",
              "instanceId": 111121,
              "displayName": "111121",
              "instanceVersion": 0,
              "className": "CLASS1 DATA",
              "dscp": "26",
              "priority": false,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "182869fc-1835-4957-b222-c46ae4fc8560",
                  "instanceId": 112128,
                  "displayName": "112128",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_STREAMING"
                }
              ]
            },
            {
              "id": "36000fc9-9097-4da6-8eff-5c5a52921e7f",
              "instanceId": 111120,
              "displayName": "111120",
              "instanceVersion": 0,
              "className": "Video",
              "dscp": "34",
              "priority": false,
              "spBandwidth": "34",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "b4df7f41-1250-4986-b8d4-08086d8d95ba",
                  "instanceId": 112125,
                  "displayName": "112125",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "REAL_TIME_INTERACTIVE"
                },
                {
                  "id": "23352f7a-6bde-436f-9f34-7fa18cd5a7a9",
                  "instanceId": 112127,
                  "displayName": "112127",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "BROADCAST_VIDEO"
                },
                {
                  "id": "0382e79b-2fe6-4d8a-bff9-2adaf562e478",
                  "instanceId": 112126,
                  "displayName": "112126",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_CONFERENCING"
                }
              ]
            },
            {
              "id": "7c36dc90-2068-4b62-9635-7c46c97216b2",
              "instanceId": 111123,
              "displayName": "111123",
              "instanceVersion": 0,
              "className": "CLASS3 DATA",
              "dscp": "10",
              "priority": false,
              "spBandwidth": "1",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "d57f0dcc-22e1-4b21-9e32-644924799789",
                  "instanceId": 112133,
                  "displayName": "112133",
                  "instanceVersion": 0,
                  "queuingBandwidth": 1,
                  "trafficClass": "SCAVENGER"
                }
              ]
            },
            {
              "id": "36d6202d-4a7d-427b-90b7-a83a7c1e4477",
              "instanceId": 111122,
              "displayName": "111122",
              "instanceVersion": 0,
              "className": "CLASS2 DATA",
              "dscp": "18",
              "priority": false,
              "spBandwidth": "25",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "eb837657-d1f0-4a8b-a159-269d28c6afb3",
                  "instanceId": 112129,
                  "displayName": "112129",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "SIGNALING"
                },
                {
                  "id": "eb754490-7f85-4046-b62d-baeac84525ed",
                  "instanceId": 112131,
                  "displayName": "112131",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "TRANSACTIONAL_DATA"
                },
                {
                  "id": "ff2893a4-92c1-421e-80be-c1cd8cd36ee3",
                  "instanceId": 112130,
                  "displayName": "112130",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "OPS_ADMIN_MGMT"
                },
                {
                  "id": "807477ce-8a7e-4864-8317-bf9d1d6f6f73",
                  "instanceId": 112132,
                  "displayName": "112132",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "BULK_DATA"
                }
              ]
            },
            {
              "id": "0e7b6368-a5e1-40b4-80ca-8afc4ed4f940",
              "instanceId": 111124,
              "displayName": "111124",
              "instanceVersion": 0,
              "className": "Default",
              "dscp": "0",
              "priority": false,
              "spBandwidth": "30",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "ccabd858-f125-44fe-8c6b-3e2312dcf352",
                  "instanceId": 112135,
                  "displayName": "112135",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "NETWORK_CONTROL"
                },
                {
                  "id": "544d66cb-9f60-4f69-8b09-16960911f754",
                  "instanceId": 112134,
                  "displayName": "112134",
                  "instanceVersion": 0,
                  "queuingBandwidth": 27,
                  "trafficClass": "BEST_EFFORT"
                }
              ]
            },
            {
              "id": "f24537a4-1b6a-4973-bb2b-690f6b5afec5",
              "instanceId": 111119,
              "displayName": "111119",
              "instanceVersion": 0,
              "className": "Voice",
              "dscp": "46",
              "priority": true,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "8e5662c7-4107-4def-bdb5-5af2b9bd40fa",
                  "instanceId": 112124,
                  "displayName": "112124",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "VOIP_TELEPHONY"
                }
              ]
            }
          ]
        },
        {
          "id": "36e06b49-7918-4892-8afd-bf5884d74aaa",
          "instanceId": 103136,
          "displayName": "8 Class",
          "instanceVersion": 0,
          "createTime": 1557845244389,
          "deployed": false,
          "description": "",
          "isSeeded": false,
          "isStale": false,
          "lastUpdateTime": 1557845244389,
          "name": "SPP4-8Class",
          "provisioningState": "UNKNOWN",
          "resourceVersion": 0,
          "targetIdList": [
            
          ],
          "type": "spslaprofile",
          "cfsChangeInfo": [
            
          ],
          "customProvisions": [
            
          ],
          "classModelName": "EIGHT_CLASS",
          "spProfileName": "SPP4-8Class",
          "spSlaPrfofileModelClass": [
            {
              "id": "26906828-d688-4681-a9ee-ae02eb0d788b",
              "instanceId": 111111,
              "displayName": "111111",
              "instanceVersion": 0,
              "className": "VOICE",
              "dscp": "46",
              "priority": true,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "4f140f8e-e1c9-4a6a-8168-ce500b568f31",
                  "instanceId": 112112,
                  "displayName": "112112",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "VOIP_TELEPHONY"
                }
              ]
            },
            {
              "id": "7b304108-b2d6-410c-90ce-b9efaa16e3c1",
              "instanceId": 111113,
              "displayName": "111113",
              "instanceVersion": 0,
              "className": "INTERACTIVE-VIDEO",
              "dscp": "34",
              "priority": false,
              "spBandwidth": "30",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "d52fd69a-ed9f-4e0b-985e-4a004154f430",
                  "instanceId": 112115,
                  "displayName": "112115",
                  "instanceVersion": 0,
                  "queuingBandwidth": 17,
                  "trafficClass": "REAL_TIME_INTERACTIVE"
                },
                {
                  "id": "60149979-8df6-49a3-8b84-aa54f4c76848",
                  "instanceId": 112116,
                  "displayName": "112116",
                  "instanceVersion": 0,
                  "queuingBandwidth": 13,
                  "trafficClass": "MULTIMEDIA_CONFERENCING"
                }
              ]
            },
            {
              "id": "42c7e6f4-e4ab-4b1d-a585-ef9b629764d7",
              "instanceId": 111112,
              "displayName": "111112",
              "instanceVersion": 0,
              "className": "STREAMING-VIDEO",
              "dscp": "26",
              "priority": false,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "e201b86b-7b24-497c-b00f-f313cdd3c61a",
                  "instanceId": 112113,
                  "displayName": "112113",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "BROADCAST_VIDEO"
                },
                {
                  "id": "2b50df70-b67c-4995-b0a4-f0ef25e2a97a",
                  "instanceId": 112114,
                  "displayName": "112114",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "MULTIMEDIA_STREAMING"
                }
              ]
            },
            {
              "id": "35b250fd-81e6-40b3-87f3-e3a0bdd4cd2b",
              "instanceId": 111115,
              "displayName": "111115",
              "instanceVersion": 0,
              "className": "CALL-SIGNALING",
              "dscp": "24",
              "priority": false,
              "spBandwidth": "4",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "0765b2a3-dcec-41ef-8c50-ad043c6f325b",
                  "instanceId": 112118,
                  "displayName": "112118",
                  "instanceVersion": 0,
                  "queuingBandwidth": 4,
                  "trafficClass": "SIGNALING"
                }
              ]
            },
            {
              "id": "357d4d03-2e93-4432-94e2-cdb3811ceaf3",
              "instanceId": 111114,
              "displayName": "111114",
              "instanceVersion": 0,
              "className": "NET CTRL-MGMT",
              "dscp": "48",
              "priority": false,
              "spBandwidth": "5",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "611f76a3-ed96-4c2d-8341-5a4f94166873",
                  "instanceId": 112117,
                  "displayName": "112117",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "NETWORK_CONTROL"
                }
              ]
            },
            {
              "id": "13a9f2c9-8f3f-4ae3-8278-2756b9289b64",
              "instanceId": 111117,
              "displayName": "111117",
              "instanceVersion": 0,
              "className": "DEFAULT",
              "dscp": "0",
              "priority": false,
              "spBandwidth": "25",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "1bff5ac5-43a3-4fab-aa58-cc3eb4493d7c",
                  "instanceId": 112122,
                  "displayName": "112122",
                  "instanceVersion": 0,
                  "queuingBandwidth": 25,
                  "trafficClass": "BEST_EFFORT"
                }
              ]
            },
            {
              "id": "5cdb3aa5-1ef5-48bf-92d8-fe74f6b6b2f9",
              "instanceId": 111116,
              "displayName": "111116",
              "instanceVersion": 0,
              "className": "CRITICAL-DATA",
              "dscp": "18",
              "priority": false,
              "spBandwidth": "25",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "92bea05b-3f24-4263-bfb9-eb7e6fdc333e",
                  "instanceId": 112119,
                  "displayName": "112119",
                  "instanceVersion": 0,
                  "queuingBandwidth": 16,
                  "trafficClass": "TRANSACTIONAL_DATA"
                },
                {
                  "id": "f53554aa-7de0-4f76-875c-473594f0ba06",
                  "instanceId": 112121,
                  "displayName": "112121",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "OPS_ADMIN_MGMT"
                },
                {
                  "id": "34ac6b29-e8c8-4551-a6b2-5a620daff5bb",
                  "instanceId": 112120,
                  "displayName": "112120",
                  "instanceVersion": 0,
                  "queuingBandwidth": 6,
                  "trafficClass": "BULK_DATA"
                }
              ]
            },
            {
              "id": "4cdc941c-3d8d-436c-a491-0874a04dfaee",
              "instanceId": 111118,
              "displayName": "111118",
              "instanceVersion": 0,
              "className": "SCAVENGER",
              "dscp": "8",
              "priority": false,
              "spBandwidth": "1",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "ef1077f7-fcb6-4d5d-99da-f3b7fea95608",
                  "instanceId": 112123,
                  "displayName": "112123",
                  "instanceVersion": 0,
                  "queuingBandwidth": 1,
                  "trafficClass": "SCAVENGER"
                }
              ]
            }
          ]
        },
        {
          "id": "760c5d6f-b48b-4294-b423-42f4f361963d",
          "instanceId": 103138,
          "displayName": "5 Class",
          "instanceVersion": 0,
          "createTime": 1557845244390,
          "deployed": false,
          "description": "",
          "isSeeded": false,
          "isStale": false,
          "lastUpdateTime": 1557845244390,
          "name": "SPP2-5Class",
          "provisioningState": "UNKNOWN",
          "resourceVersion": 0,
          "targetIdList": [
            
          ],
          "type": "spslaprofile",
          "cfsChangeInfo": [
            
          ],
          "customProvisions": [
            
          ],
          "classModelName": "FIVE_CLASS",
          "spProfileName": "SPP2-5Class",
          "spSlaPrfofileModelClass": [
            {
              "id": "58bfb0e0-68b1-4b20-bacf-dfbb5bdba0e2",
              "instanceId": 111125,
              "displayName": "111125",
              "instanceVersion": 0,
              "className": "Voice",
              "dscp": "46",
              "priority": true,
              "spBandwidth": "10",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "8afcd740-2aac-40c5-b38a-98af5ac4371e",
                  "instanceId": 112136,
                  "displayName": "112136",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "VOIP_TELEPHONY"
                }
              ]
            },
            {
              "id": "21782673-55bc-430f-be37-82cab8b64c34",
              "instanceId": 111127,
              "displayName": "111127",
              "instanceVersion": 0,
              "className": "CLASS2 DATA",
              "dscp": "18",
              "priority": false,
              "spBandwidth": "25",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "3c00ff88-2cbe-40af-8799-35aa908ffa44",
                  "instanceId": 112144,
                  "displayName": "112144",
                  "instanceVersion": 0,
                  "queuingBandwidth": 5,
                  "trafficClass": "BULK_DATA"
                },
                {
                  "id": "1254a973-21e4-47ba-99c0-7f6b35c5e0d0",
                  "instanceId": 112141,
                  "displayName": "112141",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "SIGNALING"
                },
                {
                  "id": "fbcf7044-c1c7-49c5-8a40-30514077b14f",
                  "instanceId": 112143,
                  "displayName": "112143",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "TRANSACTIONAL_DATA"
                },
                {
                  "id": "f7f5436a-c44e-4a5e-a67b-1da4f035b7ce",
                  "instanceId": 112142,
                  "displayName": "112142",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "OPS_ADMIN_MGMT"
                }
              ]
            },
            {
              "id": "863ecf25-4afb-4ebd-9367-235fc78f5fd8",
              "instanceId": 111126,
              "displayName": "111126",
              "instanceVersion": 0,
              "className": "CLASS1 DATA",
              "dscp": "26",
              "priority": false,
              "spBandwidth": "44",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "cef6fbf9-9bdb-42d7-8366-c5556b667943",
                  "instanceId": 112137,
                  "displayName": "112137",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "BROADCAST_VIDEO"
                },
                {
                  "id": "46d26f47-5cb1-41ce-aeb9-a63ef33e0ea1",
                  "instanceId": 112139,
                  "displayName": "112139",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_CONFERENCING"
                },
                {
                  "id": "9a76e20f-219f-45ee-8620-cf8432c2ce51",
                  "instanceId": 112138,
                  "displayName": "112138",
                  "instanceVersion": 0,
                  "queuingBandwidth": 14,
                  "trafficClass": "REAL_TIME_INTERACTIVE"
                },
                {
                  "id": "5a806103-bd6e-4585-b2e7-fae9ab6073cd",
                  "instanceId": 112140,
                  "displayName": "112140",
                  "instanceVersion": 0,
                  "queuingBandwidth": 10,
                  "trafficClass": "MULTIMEDIA_STREAMING"
                }
              ]
            },
            {
              "id": "8cbefbbf-9948-461d-97e8-dca2e41e9a4d",
              "instanceId": 111129,
              "displayName": "111129",
              "instanceVersion": 0,
              "className": "Default",
              "dscp": "0",
              "priority": false,
              "spBandwidth": "30",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "3d0ce82e-1713-43ee-8551-9fd62b0570cf",
                  "instanceId": 112147,
                  "displayName": "112147",
                  "instanceVersion": 0,
                  "queuingBandwidth": 3,
                  "trafficClass": "NETWORK_CONTROL"
                },
                {
                  "id": "10c62669-b4c5-4a2e-9c9f-12a3f799f505",
                  "instanceId": 112146,
                  "displayName": "112146",
                  "instanceVersion": 0,
                  "queuingBandwidth": 27,
                  "trafficClass": "BEST_EFFORT"
                }
              ]
            },
            {
              "id": "bc8f7980-ee13-45fe-8194-001e781c1bff",
              "instanceId": 111128,
              "displayName": "111128",
              "instanceVersion": 0,
              "className": "CLASS3 DATA",
              "dscp": "10",
              "priority": false,
              "spBandwidth": "1",
              "spSlaTrafficClassBandwidth": [
                {
                  "id": "020234ce-b06c-45e3-8933-803c3880fa4e",
                  "instanceId": 112145,
                  "displayName": "112145",
                  "instanceVersion": 0,
                  "queuingBandwidth": 1,
                  "trafficClass": "SCAVENGER"
                }
              ]
            }
          ]
        }
      ]

});