define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/snmp_cpu_high_80',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/switch_interface_input_output_errors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/radio_util_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/snmp_device_down',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session_edge',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/snmp_device_down_edge',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/switch_port_flaps',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_connectivity_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/snmp_map_cache_limit_reached',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/default_syslog_event_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/default_syslog_event_trigger_edge',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/snmp_memory_high_80',

    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_connectivity_trigger_ospf',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_connectivity_trigger_isis',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session_cp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session_dhcp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session_dns_pn',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_reachability_session_dns_vn',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/tcam_util_high',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/switch_port_flapping',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/fabric_wlc_reachability',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/ap_ilm_event',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/wlc_monitor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/wlc_reboot_crash_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/ap_down',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_connectivity_trigger_eigrp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/radio_util_trigger_ap',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/ap_cpu_threshold_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/device_time_drift',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/nwDevice_ntp_not_synched',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/infra_link_down',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/ap_flap',
    //'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/global_wireless_kairos_radio_media_app_throughput_drop_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/global_wireless_kairos_radio_cloud_app_throughput_drop_trigger',
    //'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/global_wireless_kairos_radio_social_app_throughput_drop_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/global_wireless_kairos_radio_throughput_drop_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/mac_flap_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_device_poe_err_disabled_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_device_poe_power_deny_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/network_device_controller_poe_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/switch_svl_down',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/default_trap_event_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device/switch_issue_power',
], function (snmp_cpu_high_80, switch_interface_input_output_errors, fabric_reachability_session, radio_util_trigger,snmp_device_down,
             fabric_reachability_session_edge, snmp_device_down_edge, switch_port_flaps, network_connectivity_trigger,
              snmp_map_cache_limit_reached, default_syslog_event_trigger, default_syslog_event_trigger_edge, snmp_memory_high_80,
               network_connectivity_trigger_ospf, network_connectivity_trigger_isis, fabric_reachability_session_cp, fabric_reachability_session_dhcp,
               fabric_reachability_session_dns_pn, fabric_reachability_session_dns_vn,tcam_util_high,switch_port_flapping,fabric_wlc_reachability,
               ap_ilm_event,wlc_monitor,wlc_reboot_crash_trigger,ap_down,network_connectivity_trigger_eigrp,radio_util_trigger_ap,
               ap_cpu_threshold_trigger,device_time_drift,nwDevice_ntp_not_synched,infra_link_down,ap_flap,
               global_wireless_kairos_radio_cloud_app_throughput_drop_trigger, global_wireless_kairos_radio_throughput_drop_trigger,
               mac_flap_trigger,network_device_poe_err_disabled_trigger,network_device_poe_power_deny_trigger,network_device_controller_poe_trigger,
               switch_svl_down, default_trap_event_trigger, switch_issue_power) {

    return {
        init: function() {
        },

        getDeviceIssue: function(issueName) {
            return eval(issueName);
        }
    };

});
