define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "24d881ef-455b-4b17-8e78-848b8aa9a11d",
    "name": "switch_port_flaps",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.11.255.2",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Interface InterfaceDownPlaceHolder is flapping on network device DeviceIPPlaceHolder",
    "groupId": "network_device_interface_flaps_grouping",
    "groupName": "Interface Is Flapping On Network Device",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "Unknown",
    "timestamp": 1604615400000,
    "description": "Router DeviceNamePlaceHolder Interface InterfaceDownPlaceHolder is Flapping",
    "suggestions": [
      {
        "message": "Verify that interface is error free",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify the interface errors",
            "command": "show interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "192.168.159.133"
      },
      {
        "key": "_name",
        "value": "switch_port_flaps"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "platformType",
        "value": "Switches and Hubs"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:192.168.159.133:TwoGigabitEthernet2/0/24"
      },
      {
        "key": "deviceName",
        "value": "C9300_SF13"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/San Francisco"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:192.168.159.133"
      },
      {
        "key": "interfaceName",
        "value": "TwoGigabitEthernet2/0/24"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9300 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "f05b5e7b-1587-4c5a-ad92-c2b3e75f893d"
      },
      {
        "key": "deviceRole",
        "value": "CORE"
      },
      {
        "key": "_suppression",
        "value": "7200"
      },
      {
        "key": "deviceOS",
        "value": "16.12.02"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "C9300_SF13"
      },
      {
        "key": "_eventTime",
        "value": "1604615400000"
      },
      {
        "key": "_mostRecent",
        "value": "1604615400000"
      },
      {
        "key": "entityId",
        "value": "192.168.159.133"
      },
      {
        "key": "eventUniqueId",
        "value": "192.168.159.133"
      },
      {
        "key": "linkflaps",
        "value": "3.0"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "buildingId",
        "value": "28a2dbdb-4c47-4eef-b24f-7bf64069a6e1"
      },
      {
        "key": "buildingName",
        "value": "Global/San Francisco/One Bush"
      },
      {
        "key": "areaId",
        "value": "fa9aaabd-67b3-4d6b-8bc0-136c76428a35"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/San Francisco/One Bush"
      },
      {
        "key": "deviceModel",
        "value": "FCW2146L02A"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2020-11-05T22:30:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
});