define({
  "Template": 
    {
    "sessionId": "3",
    "locationId": "a8034fcf-8cd2-dd7c-0d5d-63cbc89ce133",
    "locationName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "startTime": 1537994508000,
    "configDuration": 28800000,
    "actualDuration": 28793684,
    "allClients": false,
    "clientMacList": [
    "C:46:85:20:80:A3"
    ],
    "clientDetailsList": [
    {
    "macAddress": "7C:46:85:20:80:A3",
    "hostName": "oliverp-Google_Nexus",
    "userId": "oliverp",
    "hostIp": "10.13.4.106",
    "deviceType": "Android-Motorola"
    }
    ],
    "sessionStatus": "COMPLETED",
    "errorString": ""
    }            
});  