define({
    "Server_Template": {
        "groupUuid": "-1",
        "serverDetails": [ // server template updated for 2.1 release
        {
          "instanceType": "pan",
          "instanceUuid": "9eb2b7f4-3e2b-4e73-809d-d94f1600f2c7",
          "namespace": "global",
          "type": "pan.setting",
          "key": "aaa.server.pan.network",
          "version": 19,
          "value": [
            "dcloud-ise-ctf-inst-xxx.cisco.com"
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "banner",
          "instanceUuid": "2511445a-2278-422f-a3a5-37bb585a314c",
          "namespace": "global",
          "type": "banner.setting",
          "key": "device.banner",
          "version": 19,
          "value": [
            {
              "bannerMessage": "",
              "retainExistingBanner": true
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "timezone",
          "instanceUuid": "59019fbd-507d-420f-b69b-41ba2a5d7d0e",
          "namespace": "global",
          "type": "timezone.setting",
          "key": "timezone.site",
          "version": 19,
          "value": [
            'GMT'
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "aaa",
          "instanceUuid": "a5c6e6a6-5ee4-433d-802f-67e8bde22a5d",
          "namespace": "global",
          "type": "aaa.setting",
          "key": "aaa.endpoint.server.1",
          "version": 19,
          "value": [
            {
              "ipAddress": "10.1.30.71",
              "sharedSecret": "",
              "protocol": "RADIUS"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "interface",
          "instanceUuid": "227b7b64-5fa5-4cdc-9cbc-130b8a22527f",
          "namespace": "global",
          "type": "interface.setting",
          "key": "interface.info",
          "version": 19,
          "value": [
            {
              "interfaceName": "management",
              "vlanId": 0
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "aaa",
          "instanceUuid": "3bf2742e-0bb2-4d5b-83a1-7909834c51c5",
          "namespace": "global",
          "type": "aaa.setting",
          "key": "aaa.endpoint.server.2",
          "version": 19,
          "value": [
            {
              "ipAddress": "",
              "sharedSecret": "",
              "protocol": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "ip",
          "instanceUuid": "d9147a31-d41d-43c1-bd61-ad919ecaf1b0",
          "namespace": "global",
          "type": "ip.address",
          "key": "dhcp.server",
          "version": 19,
          "value": [
            "192.168.101.105"
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "syslog",
          "instanceUuid": "94c1f5c5-fec2-4e82-803e-1a1e4fe547b1",
          "namespace": "global",
          "type": "syslog.setting",
          "key": "syslog.server",
          "version": 19,
          "value": [
            {
              "ipAddresses": [
                ""
              ],
              "configureDnacIP": true
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "netflow",
          "instanceUuid": "da1174d2-8e7e-437e-a1e5-10a2e214f35c",
          "namespace": "global",
          "type": "netflow.setting",
          "key": "netflow.collector",
          "version": 19,
          "value": [
            {
              "ipAddress": "108.2.2.2",
              "port": 604,
              "configureDnacIP": true
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "reference",
          "instanceUuid": "ffd7e7d4-ab5a-4430-9673-038d8934e0ec",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.snmp_v3",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "e63b9f11-17cb-4717-b541-28b342f4e2ec"
              ],
              "type": "credential_snmp_v3",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "aaa",
          "instanceUuid": "7fb745ef-80bc-42c9-b843-23482759587f",
          "namespace": "global",
          "type": "aaa.setting",
          "key": "aaa.network.server.1",
          "version": 19,
          "value": [
            {
              "ipAddress": "10.1.30.71",
              "sharedSecret": "",
              "protocol": "RADIUS"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "ip",
          "instanceUuid": "a3084ef1-cc58-46b1-9bd0-060a21dbbb4d",
          "namespace": "global",
          "type": "ip.address",
          "key": "ntp.server",
          "version": 19,
          "value": [
            "192.168.101.105"
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        // {             //Writing and commenting by Karan in 1.4.0
        //   "instanceType": "eta_collector",
        //   "instanceUuid": "a2084ef1-cc58-46b1-9bd0-061a21dbbb4d",
        //   "namespace": "global",
        //   "type": "eta.setting",
        //   "key": "eta.collector",
        //   "version": 6,
        //   "value": [],
        //   "groupUuid": "-1",
        //   "inheritedGroupUuid": "",
        //   "inheritedGroupName": ""
        // },
        {
          "instanceType": "reference",
          "instanceUuid": "7177cb5c-5fcf-4e13-8b8c-fb4d186e7053",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.snmp_v2_write",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "391a9532-c054-4590-b619-ee43de460a45"
              ],
              "type": "credential_snmp_v2_write",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "reference",
          "instanceUuid": "e5a9bf67-4d45-4fba-9d31-15d020a33a27",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.http.read",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "b362e6fe-f3f7-4310-a366-4e47bcb5e04a"
              ],
              "type": "credential_http_read",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "snmp",
          "instanceUuid": "3e0610d8-68e5-4d8e-bd8f-e76e4e1cfb3f",
          "namespace": "global",
          "type": "snmp.setting",
          "key": "snmp.trap.receiver",
          "version": 19,
          "value": [
            {
              "ipAddresses": [
                ""
              ],
              "configureDnacIP": true
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "reference",
          "instanceUuid": "24a20f24-2046-4ce6-b186-2f15d2fc6e01",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.snmp_v2_read",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "acf9174c-9b41-4f6e-adf8-efb083833410"
              ],
              "type": "credential_snmp_v2_read",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "aaa",
          "instanceUuid": "abffc27c-6b36-4c4a-9a7c-017b5d8ea546",
          "namespace": "global",
          "type": "aaa.setting",
          "key": "aaa.network.server.2",
          "version": 19,
          "value": [
            {
              "ipAddress": "",
              "sharedSecret": "",
              "protocol": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "dns",
          "instanceUuid": "4b8dce42-213d-41a4-bdbc-1813f8b36bac",
          "namespace": "global",
          "type": "dns.setting",
          "key": "dns.server",
          "version": 19,
          "value": [
            {
              "domainName": "esa.cisco.com",
              "primaryIpAddress": "192.168.101.105",
              "secondaryIpAddress": "2.2.2.2"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "eapfastauthority",
          "instanceUuid": "0514b787-3c7c-4546-989f-4e52a5e01064",
          "namespace": "global",
          "type": "eapfast.authority",
          "key": "eapfast.authority.seeds",
          "version": 19,
          "value": [
            {
              "authorityId": "CB29F71F5BCC3B16A491C714334B8023",
              "seedKey": "040A5AB9C377BF3D3D1D663656ABA4B96FE03D05F1525561DE17D8982429FA5DB137930555C941C5A1DAC829F396A0F605A8409CD082B40FE41662AE1772D985",
              "seedId": "19"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "reference",
          "instanceUuid": "2fe35f5d-473c-4f61-8ff3-69f2e80772e6",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.cli",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "24b57716-450b-4fc6-8400-b1616c744d60"
              ],
              "type": "credential_cli",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "sp",
          "instanceUuid": "93a6fb8f-cdd5-43fa-b370-5022acda4a63",
          "namespace": "global",
          "type": "sp.setting",
          "key": "sp.info",
          "version": 19,
          "value": [
            {
              "wanProvider": "public",
              "spProfileName": "Internet",
              "slaProfileName": "4-class-model"
            },
            {
              "wanProvider": "private",
              "spProfileName": "MPLS",
              "slaProfileName": "4-class-model"
            },
            {
              "wanProvider": "public",
              "spProfileName": "ATT-profile",
              "slaProfileName": "4-class-model"
            },
            {
              "wanProvider": "private",
              "spProfileName": "comcast",
              "slaProfileName": "8-class-model"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "reference",
          "instanceUuid": "c1f471a4-4b8b-4598-9f4f-7cc4402edb23",
          "namespace": "global",
          "type": "reference.setting",
          "key": "credential.http.write",
          "version": 19,
          "value": [
            {
              "objReferences": [
                "f0703f0b-a909-40df-94f6-16a078262dad"
              ],
              "type": "credential_http_write",
              "url": ""
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "pan",
          "instanceUuid": "03251ae7-396b-4355-9c69-19736bffc8d0",
          "namespace": "global",
          "type": "pan.setting",
          "key": "aaa.server.pan.endpoint",
          "version": 19,
          "value": [
            "dcloud-ise-ctf-inst-xxx.cisco.com"
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        },
        {
          "instanceType": "securityandtrust",
          "instanceUuid": "99ee2da6-d425-4e13-a645-c297ee79066c",
          "namespace": "global",
          "type": "securityandtrust.setting",
          "key": "securityandtrust.revocationcheck",
          "version": 0,
          "value": [
          {
          "revocationCheckEnabled": true
          }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
          }
          


        ]

    }
});
