define({
  "HostHealthDetails":
  {
    "version": "1.0",
    "response": [
      {
        "siteId": "global",
        "scoreDetail": [
          {
            "scoreCategory": {
              "scoreCategory": "CLIENT_TYPE",
              "value": "ALL"
            },
            "scoreValue": 40,
            "clientCount": 306,
            "clientUniqueCount": 306,
            "starttime": 1516183200000,
            "endtime": 1516183500000,
            "scoreList": []
          },
          {
            "scoreCategory": {
              "scoreCategory": "CLIENT_TYPE",
              "value": "WIRED"
            },
            "scoreValue": 100,
            "clientCount": 56,
            "clientUniqueCount": 56,
            "starttime": 1516183200000,
            "endtime": 1516183500000,
            "scoreList": [
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "POOR"
                },
                "scoreValue": -1,
                "clientCount": 0,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": []
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "FAIR"
                },
                "scoreValue": -1,
                "clientCount": 0,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": []
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "GOOD"
                },
                "scoreValue": -1,
                "clientCount": 1,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": [
                  {
                    "scoreCategory": {
                      "scoreCategory": "DEVICE_TYPE",
                      "value": ""
                    },
                    "scoreValue": -1,
                    "clientCount": 1,
                    "clientUniqueCount": null,
                    "starttime": 1516183200000,
                    "endtime": 1516183500000,
                    "scoreList": []
                  }
                ]
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "IDLE"
                },
                "scoreValue": -1,
                "clientCount": 0,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": []
              }
            ]
          },
          {
            "scoreCategory": {
              "scoreCategory": "CLIENT_TYPE",
              "value": "WIRELESS"
            },
            "scoreValue": 90,
            "clientCount": 250,
            "clientUniqueCount": 250,
            "starttime": 1516183200000,
            "endtime": 1516183500000,
            "scoreList": [
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "POOR"
                },
                "scoreValue": -1,
                "clientCount": 4,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": [
                  {
                    "scoreCategory": {
                      "scoreCategory": "deviceType",
                      "value": "ALL"
                    },
                    "scoreValue": -1,
                    "clientCount": 1,
                    "clientUniqueCount": null,
                    "starttime": 1542271500000,
                    "endtime": 1542271800000,
                    "scoreList": []
                  },
                  {
                    "scoreCategory": {
                      "scoreCategory": "rootCause",
                      "value": "AAA"
                    },
                    "scoreValue": -1,
                    "clientCount": 1,
                    "clientUniqueCount": null,
                    "starttime": 1542271500000,
                    "endtime": 1542271800000,
                    "scoreList": []
                  }
                ]
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "FAIR"
                },
                "scoreValue": -1,
                "clientCount": 0,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": []
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "GOOD"
                },
                "scoreValue": 10,
                "clientCount": 246,
                "clientUniqueCount": 246,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": [
                  {
                    "scoreCategory": {
                      "scoreCategory": "DEVICE_TYPE",
                      "value": "Microsoft-Workstation"
                    },
                    "scoreValue": -1,
                    "clientCount": 1,
                    "clientUniqueCount": null,
                    "starttime": 1516183200000,
                    "endtime": 1516183500000,
                    "scoreList": []
                  },
                  {
                    "scoreCategory": {
                      "scoreCategory": "DEVICE_TYPE",
                      "value": "Linux-Workstation"
                    },
                    "scoreValue": -1,
                    "clientCount": 2,
                    "clientUniqueCount": null,
                    "starttime": 1516183200000,
                    "endtime": 1516183500000,
                    "scoreList": []
                  }
                ]
              },
              {
                "scoreCategory": {
                  "scoreCategory": "SCORE_TYPE",
                  "value": "IDLE"
                },
                "scoreValue": -1,
                "clientCount": 0,
                "clientUniqueCount": 0,
                "starttime": 1516183200000,
                "endtime": 1516183500000,
                "scoreList": []
              }
            ]
          }
        ]
      }
    ]
  }
});
