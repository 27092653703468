define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimWirelessData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimSiteProfileData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/DevicePkdMdfTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/SiteProfileTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig'
  ], function(SimLokiDatabaseActions, SimTaskDataGenerator, SimWirelessData, SimSiteProfileData, UtilityFunctions,
     DevicePkdMdfTemplate, SimNetworkDeviceData,SiteProfileTemplate, DefaultConfig) {

 return {
     init: function() {
     },
     doProcessRestRequest: function(urlAction) {
         /* console.log(urlAction.url);
         console.log(JSON.stringify(urlAction)); */
         var data;
         switch (urlAction.method) {
             case 'GET':
                 data = processGetRequest(urlAction);
                 break;
             case 'POST':
                 data = processPostRequest(urlAction);
                 break;
             case 'PUT':
                 data = processPutRequest(urlAction);
                 break;
             case 'DELETE':
                 data = processDeleteRequest(urlAction);
                 break;
             default:
                 console.log('Something wrong, it should not have reached here..');
                 break;
         }
         return data;
     }
 };

 function processGetRequest(urlAction) {
     //console.log("urlAction = " + JSON.stringify(urlAction));
     var jObj, data;
     var t = urlAction.filter['namespace'];
     if (t != undefined) {
         var x = urlAction.url.split('?');
         x = x[0].split('/');
         var siteId = "";
         if(x.indexOf('site')>-1) {
             siteId = x[x.indexOf('site')+1];
         } else if(x.indexOf('sites')>-1) {
            siteId = x[x.indexOf('sites')+1];//or urlAction.action.id ?
         }
         if(siteId!="") {
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile',
                 {'$and': [{'namespace': { '$in': t}}, {'sites.uuid':siteId}]});
         } else  {
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'namespace': { '$in': t}});
         }
     } else {
         var t = urlAction.url.split('/');// no need split, fetch the id from filter
         var id = t[4];
         id = id.split('?')[0];
         //console.log("id = " + id);

         if(urlAction.action.count ) {
             var count = 0;
             jObj = SimLokiDatabaseActions.getAll('siteprofile');
             if (jObj && jObj.length) {
                 count = jObj.length;
             }
             return count == undefined ? 0 : count;
         }
         if(urlAction.service.indexOf("site")>=0) {
             var record = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'sites.uuid': urlAction.action.id});
             var family, namespace;
             if(urlAction.filter['mdfId']) {
                 var mappingDetails = DefaultConfig.MDF_DEVICE_TYPE_MAPPING;
                 for (var i = 0; i < mappingDetails.length; i++) {
                     if (mappingDetails[i].mdfId == urlAction.filter['mdfId']) {
                         family = mappingDetails[i].family;
                     }
                 }
             }
             if(family) {
                 if(family=="Switches and Hubs") {
                     namespace="switching";
                 } else if(family=="Routers") {
                     namespace="routing";
                 } 
                 var records = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile',
                                {'$and': [{'sites.uuid': urlAction.action.id}, {'namespace':namespace}]});
                 //not handling specific device type check for now, against day0.templates
                 if(records.length>0) {
                     record = records;
                 }
             }
             //return record && record.length ? [record[0]] : [];
             return record;
         }

         if(urlAction.service.indexOf("attribute")>=0 && urlAction.filter[0] == 'key' && urlAction.filter[1] == 'value') {
             var key = urlAction.filter['key'];
             var value = urlAction.filter['value'];
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {
                 'profileAttributes.value': value

             });
             return (jObj != undefined && jObj.length > 0)?jObj:[];
         }

         if(urlAction.service.indexOf("attribute")>=0) {
             //console.log("siteprofile urlaction: ", urlAction );
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'profileAttributes.key': 'wireless.ssid'});
             //console.log('jObj ', jObj);
             var siteprofileArr = [];
             if(jObj != undefined && jObj.length > 0){
                jObj.forEach(function(element) {
                    element.profileAttributes.forEach(function(element) {
                        element.attribs.forEach(function(element) {
                            if(element.key == "wireless.fabric"){
                                element.value = "false";
                            }
                        });
                    });
                });
             }else{
                return [];
             }
             return jObj;
             /*let temp = JSON.parse('[{"siteProfileUuid":"2b86bdbe-757f-1f4d-c5a7-64167ecf3c8b","version":1,"name":"Test_WirelessProfile","namespace":"wlan","status":"draft","lastUpdatedBy":"admin","lastUpdatedDatetime":1569047636430,"profileAttributes":[{"key":"wireless.ssid","value":"test_network_WL","attribs":[{"key":"wireless.flexConnect","value":"false"},{"key":"wireless.authMode","value":"central"},{"key":"wireless.trafficSwitchingMode","value":"fabric"},{"key":"wireless.interfaceName","value":"management"},{"key":"wireless.fabric","value":"false"}]}]}]');
             //let temp = JSON.parse('[{"siteProfileUuid":"0b9b5603-8426-410f-b81f-35bc5342645b","version":1,"name":"Test_WirelessProfile","namespace":"wlan","status":"draft","lastUpdatedBy":"admin","lastUpdatedDatetime":1568785157456,"profileAttributes":[{"key":"wireless.ssid","value":"test_network_WL","attribs":[{"key":"wireless.flexConnect","value":"false"},{"key":"wireless.authMode","value":"central"},{"key":"wireless.trafficSwitchingMode","value":"fabric"},{"key":"wireless.interfaceName","value":"management"},{"key":"wireless.fabric","value":"false"}]}]}]');
             return temp;*/
         }

         if (urlAction.filter[0] == 'key' && urlAction.filter[1] == 'value') {
             //console.log("find siteprofile by key & values");
             var key = urlAction.filter['key'];
             var value = urlAction.filter['value'];
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {
                 'profileAttributes.value': value

             });
         } else if (urlAction.action.id != undefined) {
             //console.log("find siteprofile by Id");
             jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'id': id});
             //console.log("JSON Response = " + JSON.stringify(jObj));
             if (jObj[0] != undefined) {
                 //console.log("RETURE = " + JSON.stringify(jObj[0]));
                 //jObj[0].status = "final";
                 if(urlAction.filter.populated=='false' || urlAction.filter.populated==false) {
                     var tJson=SiteProfileTemplate.SiteProfile_Template;
                     tJson.siteProfileUuid = jObj[0].siteProfileUuid;
                     tJson.name = jObj[0].name;
                     tJson.namespace = jObj[0].namespace;
                     tJson.sites =jObj[0].sites;
                     //update other fields too..
                     return tJson;
                 }
                     if(jObj[0].namespace == "nfv" && urlAction.service.indexOf('device')>-1) {
                         //For NFV with siteprofileid and deviceId in the url, params will have the details of siteprofile
                         var obj = SimSiteProfileData.formNfvObj(jObj[0], (t[7].split('?'))[0]);
                         return obj;
                     }
                     return jObj[0];

             } else {
                 jObj = [];
             }
         }
     }

     if(urlAction.service.indexOf("siteprofile")>=0 && urlAction.action.count) {
         return (jObj && jObj.length ? jObj.length : 0);
     }

     if(urlAction.service.indexOf("exists")>=0) {

         return {"version":"1.0"};
     }
     if(urlAction.service.indexOf('mdf')>=0) {
         return DevicePkdMdfTemplate.Mdf_Template;
     }
     data = jObj && jObj.length ? jObj : [];
     //console.log("Response data = " + JSON.stringify(data));
     return data;
 }

function processPostRequest(urlAction) {
     if(urlAction.service.indexOf('validate') > -1) {
         // api/v1/siteprofile/validate -- No response to be sent exceept 200 OK. This is used during provisioning of NFV.
         return;
     } else if(urlAction.service.indexOf('payload')>-1 && urlAction.filter['doProvision']!=undefined) {
         // api/v1/siteprofile/payload?doProvision=false -- This is used during provisioning of NFV.
         return SimSiteProfileData.nfvProvision(urlAction, "postNfv");
     }

     var siteId = UtilityFunctions.generate_uuid();
     var resultObj = SimTaskDataGenerator.createTask('SPService',{siteId : siteId});

     if(urlAction.service.indexOf("site")>=0) {
         SimSiteProfileData.addSiteAttributes(urlAction);
     } else {
        if(urlAction.restPayload.namespace=="nfv") {
          SimWirelessData.NFVProfileCUD(siteId, "post", urlAction.restPayload);
        } else {
          SimWirelessData.wirelessSiteCUD(siteId, "post", urlAction.restPayload);
        }
     }
     SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
     return resultObj;
 }

 function processPutRequest(urlAction) {
     if(urlAction.service.indexOf('provision')>-1 && (urlAction.service.indexOf('nfv')>-1) || 
        (Array.isArray(urlAction.restPayload) && urlAction.restPayload.length>0 && urlAction.restPayload[0].namespace == 'nfv')){
         // api/v1/siteprofile/provision/nfv/device - purposely handled above the below code
         return SimSiteProfileData.nfvProvision(urlAction, "putNfv");
     }
     var t = urlAction.url.split('/');
     var id = t[4];
     id = id.split('?')[0];
     var recordObj = [];
     if (id != undefined) {
      if(urlAction.restPayload.namespace == "authentication"){
             var resultObj = SimTaskDataGenerator.createTask('SPService');
             SimSiteProfileData.updateProfileAttributes(urlAction);
             SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
             resultObj.statusCode = 202;
             return resultObj;
         } else {
             var siteId = UtilityFunctions.generate_uuid();
             var resultObj = SimTaskDataGenerator.createTask('SPService',{siteId :siteId});
             if(urlAction.restPayload.namespace=="nfv") {
                 SimWirelessData.NFVProfileCUD(siteId, "put", urlAction.restPayload);
             } else if(urlAction.restPayload.namespace=="nfv") {
                 SimWirelessData.switchingCUD(siteId, "put", urlAction.restPayload)  ;
             } else {
                 SimWirelessData.wirelessSiteCUD(siteId, "put", urlAction.restPayload);
             }
             SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
         return resultObj;
         }
     }
 }

 function processDeleteRequest(urlAction) {
     var t = urlAction.url.split('/');
     var id = t[4];
     id = id.split('?')[0];

     var siteIdToFetch;
     if(urlAction.service.indexOf("site") >= 1)
     {
      siteIdToFetch = urlAction.action.id;
     }

     var recordObj = [];
     if (id != undefined) {
         var siteId = UtilityFunctions.generate_uuid();
         var resultObj = SimTaskDataGenerator.createTask('SPService',{siteId :siteId});
         SimWirelessData.wirelessSiteCUD(siteId, "delete", { 'id': id, 'siteId': siteIdToFetch});
         SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
         return resultObj;
     }
 }
});
