define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/service-mapper/SimServiceMapper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/parseUrl'
], function (SimServiceMapper, parseUrl) {

    var urlMapping = {};

    return {
        init: function (restEndPoints) {
            urlMapping = new Object();
            for (var i = 0, len = restEndPoints.length; i < len; i++) {
                urlMapping[restEndPoints[i].name] = true;
            }
        },

        isRestUrlMocked: function (url) {
            //e.g. 1) /api/v1/xyz/count 2)/api/v1/xyz 3)api/xyz? 4)/api/customer-facing-service?type=DeviceInfo          
            //ignore the /api/v1/
            if(url.indexOf('.glb')>-1 || url.indexOf('.html')>-1 || (url.indexOf('kairos')>-1 && url.indexOf('dummy-token')>-1) ||
                url.includes("ec.walkme.com") ) {
                return false;   
            }
            var curStr = '';
            for (var i = 0, len = url.length; i < len && url[i] != '?'; i++) {
                if (url[i] == '/') {
                    if (urlMapping[curStr] != undefined)
                        return true;
                    curStr = '';
                } else {
                    curStr += url[i];
                }
            }

            if (urlMapping[curStr] != undefined)
                return true;

            return false;
        },

        /*
        From URL determine below things..
          1) service name
          2) action type --> e.g. count or 1234-5678 or id etc..
          3) filter --> e.g. based on condition.. 
          4) decoration --> sort by asc , limit & offset etc..
        */
        decodeUrl: function (method, url, restPayload, requestHeaders) {
            var urlAction = {
                url: '',
                method: '', restPayload: '',
                service: [], serviceName: '', isDefaultHandling: true,
                jsonTemplate: '', filter: {},
                action: {
                    id: '', count: false, summary: false, child: false
                },
                decoration: {
                    sortBy: false, order: 'asc', limit: 500, offset: 0
                }
            };

            var curStr = '';

            url = decodeURIComponent(url);

            if(url.indexOf("http")>-1) {
                var idx = url.indexOf("//")+2;
                url = url.substring(idx);
                idx = url.indexOf("/");
                url = url.substring(idx);
            }

            /* every rest call has a prefix /api/v1/xyz?ad, the first 8 character is ignored while decoding URL */
            for (var i = 0, len = url.length; i < len && url[i] != '?'; i++) {
                if (url[i] == '/') {
                    servicePatternFromUrl(curStr, urlAction);
                    curStr = '';
                } else {
                    curStr += url[i];
                }
            }
            servicePatternFromUrl(curStr, urlAction);

            //set filter e.g. limit, offset type etc..
            setFilters(urlAction, url);

            //set method is get/put/post/delete
            urlAction.method = method;

            //FIXME -- it needs correction..
            /* if (url.indexOf("image") >= 0 && url.indexOf("importation") <= -1) {
                urlAction.restPayload = restPayload;
            } else {
                urlAction.restPayload = JSON.parse(restPayload);
            } */
            //FIX for above
            if(restPayload && typeof restPayload == 'string') {
                urlAction.restPayload = JSON.parse(restPayload)
            } else {
                urlAction.restPayload = restPayload;
            }

            //just for debugging purpose..
            urlAction.url = url;

            // setting the headers
            urlAction.requestHeaders = requestHeaders;

            //determine actual service name, is default model handling sufficent or else which service model will handle it..
            SimServiceMapper.getServiceDBMapping(urlAction);

            return urlAction;
        }

    };

    function servicePatternFromUrl(curStr, urlAction) {
        if (curStr == '') {
            return;
        }
        //task id --> d5b5eac7-2bee-d6fb-5a12-1b1f43d350e3
        //6641798d-11d1-449b-a3a0-af1f5368c6e8
        if (curStr == 'count') {
            urlAction.action.count = true;
        }
        //else if( curStr.search(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/) == 0){
        else if (curStr.search(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) == 0) {
            urlAction.action.id = curStr;
        }
        else if (curStr == 'summary') {
            urlAction.action.summary = true;
        }
        else if (curStr == 'child') {
            urlAction.action.child = true;
        }
        else if (curStr == 'api' || curStr == 'v1' || curStr == 'v2' || curStr == 'data' || curStr == 'persistence' || curStr == 'system' || curStr == '/' || curStr == 'appstacks' || curStr == 'maglev') {
            //just ignore..
        }
        else {
            urlAction.service.push(curStr);
        }
    }

    function setFilters(urlAction, url) {
        var arrQueryValue = parseUrl.getUrlVars(url);

        for (var i = 0; i < arrQueryValue.length; i++) {
            var isnum = /^\d+$/.test(arrQueryValue[arrQueryValue[i]]);
            if (isnum) {
                //if(Number.isInteger(parseInt(arrQueryValue[i]))) {
                arrQueryValue[arrQueryValue[i]] = parseInt(arrQueryValue[arrQueryValue[i]]);
            }
        }

        urlAction.filter = arrQueryValue;
    }

});
