define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "c646a06b-e496-43f2-ac20-10b7534c71e9",
    "name": "global_wireless_kairos_radio_throughput_drop_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "a101720e0d3123c2b2ca",
    "groupBy": "a101720e0d3123c2b2ca",
    "category": "Application",
    "severity": "HIGH",
    "summary": "At least 97% drop in radio throughput from baseline for AP(s) in SiteNamePlaceHolder.",
    "groupId": "global_wireless_throughput_issues_grouping",
    "groupName": "Drop in total radio throughput",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1609748663417,
    "description": "Error: issue details are missing. Please check connectivity to AI Network Analytics cloud.",
    "suggestions": null,
    "additionalParams": [
      {
        "key": "groupType",
        "value": "ap_band"
      },
      {
        "key": "_name",
        "value": "global_wireless_kairos_radio_throughput_drop_trigger"
      },
      {
        "key": "_entity_type",
        "value": "global"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "groupBy",
        "value": "a101720e0d3123c2b2ca"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "no_suppression"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/SITE-dGLo"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "no_suppression"
      },
      {
        "key": "band",
        "value": "2.4 GHz"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "triggerSource",
        "value": "kairos"
      },
      {
        "key": "clientCount",
        "value": "44"
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Access Point"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "aes:7itR73rgmzCJxeZNuAY_GloQRb1lbKOdPT50zGJdxpzT"
      },
      {
        "key": "deviceRole",
        "value": "Access Point"
      },
      {
        "key": "isAIDriven",
        "value": "Yes"
      },
      {
        "key": "numAps",
        "value": "0"
      },
      {
        "key": "kairosIssueId",
        "value": "a101720e0d3123c2b2ca"
      },
      {
        "key": "deviceOS",
        "value": ""
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "aes:0A49ejoE15G92iLIoVHEiVO3YApAPlLY65-M9Q=="
      },
      {
        "key": "_eventTime",
        "value": "1609748663417"
      },
      {
        "key": "_mostRecent",
        "value": "1609748663417"
      },
      {
        "key": "entityId",
        "value": "a101720e0d3123c2b2ca"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "pctDeviation",
        "value": "97"
      },
      {
        "key": "buildingId",
        "value": "95db781b-72d9-45f9-ae9c-17a106308850"
      },
      {
        "key": "orgTriggerTime",
        "value": "1607713619252726"
      },
      {
        "key": "buildingName",
        "value": "Global/SITE-dGLo/BLD-ntSg"
      },
      {
        "key": "areaId",
        "value": "7395a533-88aa-47d5-bbca-08ab94e4cdbe"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/SITE-dGLo/BLD-ntSg"
      },
      {
        "key": "deviceModel",
        "value": ""
      },
      {
        "key": "category",
        "value": "Application"
      },
      {
        "key": "timestring",
        "value": "2021-01-04T08:24:23.417+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})