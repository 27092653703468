define({
  "Template": {
    "sensorDeviceTestDetailsList": [
      {
        "sensorMac": "38:ed:18:cf:d6:e0",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-AP1852E-B-K9",
        "neighborApCount": 15,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 6,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 22271,
          "failureCount": 150,
          "slowCount": 5
        }
      },
      {
        "sensorMac": "70:f3:5a:78:69:40",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-CAP3602I-A-K9",
        "neighborApCount": 17,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 7,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 4601,
          "failureCount": 50,
          "slowCount": 24
        }
      }, {
      "sensorMac": "dc:ce:c1:2e:bd:c0",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "deviceModel": "AIR-AP3802I-B-K9",
      "neighborApCount":1,
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "issueCount": 2,
      "testedApCount": 8,
      "reachabilityStatus": true,
      "lastSeen": 1549522553000,
      "testResultDetails": {
        "passCount": 2688,
        "failureCount": 130,
        "slowCount": 3
      }
     },
     {
      "sensorMac": "70:f3:5a:78:70:a0",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "deviceModel": "AIR-AP1800S-B-K9",
      "neighborApCount":3,
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "issueCount": 3,
      "testedApCount": 6,
      "reachabilityStatus": true,
      "lastSeen": 1549522553000,
      "testResultDetails": {
       "passCount": 1232,
        "failureCount": 12,
        "slowCount": 18
      }
     }
    ]
  },
  "band_filter" : {
    "sensorDeviceTestDetailsList": [
      {
        "sensorMac": "38:ed:18:cf:d6:e0",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-AP1852E-B-K9",
        "neighborApCount": 15,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 6,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 22271,
          "failureCount": 150,
          "slowCount": 5
        }
      },
      {
        "sensorMac": "70:f3:5a:78:69:40",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-CAP3602I-A-K9",
        "neighborApCount": 17,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 7,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 4601,
          "failureCount": 50,
          "slowCount": 24
        }
      }, {
      "sensorMac": "dc:ce:c1:2e:bd:c0",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "deviceModel": "AIR-AP3802I-B-K9",
      "neighborApCount":1,
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "issueCount": 2,
      "testedApCount": 8,
      "reachabilityStatus": true,
      "lastSeen": 1549522553000,
      "testResultDetails": {
        "passCount": 2688,
        "failureCount": 130,
        "slowCount": 3
      }
     }
    ]
  },
  "ssid_filter" : {
    "sensorDeviceTestDetailsList": [
      {
        "sensorMac": "38:ed:18:cf:d6:e0",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-AP1852E-B-K9",
        "neighborApCount": 15,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 6,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 22271,
          "failureCount": 150,
          "slowCount": 5
        }
      },
      {
      "sensorMac": "dc:ce:c1:2e:bd:c0",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "deviceModel": "AIR-AP3802I-B-K9",
      "neighborApCount":1,
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "issueCount": 2,
      "testedApCount": 8,
      "reachabilityStatus": true,
      "lastSeen": 1549522553000,
      "testResultDetails": {
        "passCount": 2688,
        "failureCount": 130,
        "slowCount": 3
      }
     }
    ]
  },
  "both_filters" : {
    "sensorDeviceTestDetailsList": [
      {
        "sensorMac": "38:ed:18:cf:d6:e0",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "deviceModel": "AIR-AP1852E-B-K9",
        "neighborApCount": 15,
        "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "issueCount": 2,
        "testedApCount": 6,
        "reachabilityStatus": true,
        "lastSeen": 1549522553000,
        "testResultDetails": {
          "passCount": 22271,
          "failureCount": 150,
          "slowCount": 5
        }
      },
      {
      "sensorMac": "dc:ce:c1:2e:bd:c0",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "deviceModel": "AIR-AP3802I-B-K9",
      "neighborApCount":1,
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "issueCount": 2,
      "testedApCount": 8,
      "reachabilityStatus": true,
      "lastSeen": 1549522553000,
      "testResultDetails": {
        "passCount": 2688,
        "failureCount": 130,
        "slowCount": 3
      }
     }
    ]
  }
});