define({
  "Template":
    {
         "time":"2021-05-25T08:33:12.750+0000",
         "interface_utilisation_20":"0",
         "interface_utilisation_21_40":"3",
         "interface_utilisation_41_60":"4",
         "interface_utilisation_61_80":"5",
         "interface_utilisation_81":"8"
      }
});
