define({
    "Ssid_Details_Template":
     {
      "bands": "BOTH",
      "ssid": "DEMO-FEW-GUEST",
      "profileName": "DEMO-FEW-GUEST",
      "authType": "WPA2_EAP",
      "authTypeRcvd": null,
      "psk": null,
      "username": null,
      "password": null,
      "eapMethod": null,
      "authProtocol": null,
      "numAps": 0,
      "numSensors": 0,
      "id": 0,
      "wlanId": 0,
      "wlc": "10.16.31.68",
      "validFrom": 0,
      "validTo": 0,
      "status": "ENABLED",
      "layer3webAuthsecurity": null,
      "layer3webAuthuserName": null,
      "layer3webAuthpassword": null,
      "layer3webAuthEmailAddress": null
    }

 });