define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTagMemberData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions'
], function (SimTagMemberData, SimLokiDatabaseActions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
           console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processPostRequest(urlAction){
        var taskObj;
        if(urlAction.url.indexOf('preview') > -1) {
            // /api/v2/tag/member/preview
            taskObj = SimTagMemberData.postPreview(urlAction);
        } else {
            // /api/v2/tag
            taskObj = SimTagMemberData.save(urlAction);
        }
        taskObj.statusCode = 202;
        return taskObj;
    }

    function processGetRequest(urlAction){
        if(urlAction.action.count){
            return SimTagMemberData.getCount(urlAction);
        } else if(urlAction.url.indexOf('member')>-1) {
            if(urlAction.service.indexOf('preview') > -1) {
                // /api/v2/tag/member/preview/7e913c4a-7dce-9a67-0998-927f446a9592?memberType=networkdevice&memberAssociationType=DYNAMIC
                return SimTagMemberData.getPreviewDevices(urlAction);
            } else if(urlAction.action.id) {
                // /api/v2/tag/4e44d32c-3878-4ebc-a4e3-4027fec1765c/member?memberType=networkdevice
                return SimTagMemberData.getDeviceDtlsForTag(urlAction);
            } else if(!urlAction.filter['memberAssociationType']) {
                // /api/v1/member/tag?type=TAG&id=8870764e-d52a-4a14-8c8b-bd905d2aa693,08408b22-8382-4930-8e57-2458ca9178e0
                return SimTagMemberData.getMemberTagData(urlAction);
            } else {
                // /api/v2/tag/6cb147b0-93bc-45c6-9380-c0d7bb56e4a0/member?memberType=networkdevice&memberAssociationType=STATIC,MIXED&level=0
                return SimTagMemberData.devicesForTag(urlAction);
            }
        } else {
            return SimTagMemberData.getTag(urlAction);
        }
    }

    function processPutRequest(urlAction){
        var taskObj;
        if(urlAction.url.indexOf('member')>-1) {
            if(urlAction.memberType && urlAction.memberType == 'Template') {
                //tags to template mapping
                taskObj = SimTagMemberData.assignTemplateTags(urlAction);
            } else {
                //Assigning Tags to device manually
                taskObj = SimTagMemberData.assignTagsManually(urlAction);
            }
        } else {
            //Edit tag details
            taskObj = SimTagMemberData.edit(urlAction);
        }
        taskObj.statusCode = 202;
        return taskObj;
    }

    function  processDeleteRequest(urlAction){
        var id = urlAction.action.id, taskObj;
        taskObj = SimTagMemberData.deleteTag(id);
        taskObj.statusCode = 202;
        return taskObj;
    }

});
