define({
  "All_License_Template":
  {
    "authorizationStatus": "NA",
    "daysToExpiry": "NA",
    "deviceName": "6807",
    "deviceType": "Switches and Hubs",
    "dnaLevel": "",
    "id": "e448d771-3547-490a-943f-a16fd1550a49",
    "ipAddress": "10.13.1.1",
    "isFeatureLicense": false,
    "isLicenseExpired": null,
    "isWireless": false,
    "lastUpdatedTime": "2018-10-26T09:01:16.509000",
    "licenseExpiry": "NA",
    "licenseMode": "NA",
    "licenseTerm": null,
    "macAddress": "18:9c:5d:5e:38:00",
    "model": "Cisco Catalyst 6800 Series Switches",
    "networkLicense": "NA",
    "operationInProgress": "NA",
    "registrationStatus": "NA",
    "reservationStatus": "NA",
    "site": "Global/SJC/SJ23/Flr1",
    "sntcStatus": "Invalid",
    "softwareVersion": "15.1",
    "totalApCount": null,
    "virtualAccount": "NA",
    "dlcStatus": "NA"
  },

  "AllLicence_PurchaseTemplate": {
    "totalOOCAdvDetail": {
      "fw": 0,
      "switches": 0,
      "routers": 0,
      "ise": 0,
      "wlc": 0
    },
    "totalPremierOOCCount": 0,
    "totalPremierLicensesExpiresIn": 0,
    "totalAdvCount": 45,
    "totalOOCEssDetail": {
      "fw": 0,
      "switches": 0,
      "routers": 0,
      "ise": 0,
      "wlc": 0
    },
    "totalEssDetail": {
      "fw": 0,
      "switches": 40,
      "routers": 10,
      "ise": 10,
      "wlc": 15
    },
    "totalAdvLicensesExpiresIn": 0,
    "totalPremierLicensesExpiresInDetail": {
      "switches": 0,
      "routers": 0
    },
    "totalOOCEssCount": 0,
    "totalAdvDetail": {
      "fw": 0,
      "switches": 20,
      "routers": 8,
      "ise": 5,
      "wlc": 12
    },
    "totalOOCAdvCount": 0,
    "totalPermierDetail": {
      "switches": 0,
      "routers": 0
    },
    "totalPremierOOCDetail": {
      "switches": 0,
      "routers": 0
    },
    "totalEssLicensesExpiresInDetail": {
      "fw": 0,
      "switches": 0,
      "routers": 0,
      "ise": 0,
      "wlc": 0
    },
    "totalEssCount": 75,
    "totalPremierCount": 0,
    "totalEssLicensesExpiresIn": 0,
    "totalAdvLicensesExpiresInDetail": {
      "fw": 0,
      "switches": 0,
      "routers": 0,
      "ise": 0,
      "wlc": 0
    }
},

"AllLicence_LicenceViewTemplate":{
  "response": [
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "WLC 5520 or WLC 8540 or WLC 3504 Access Point License",
      "inuse": 4,
      "id": "70d139d4-0f78-4303-b609-7f971e640e4b",
      "purchased": 7,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "Aironet DNA Advantage Term Licenses",
      "inuse": 2,
      "id": "bd2a9691-99c6-49ca-a120-44f54d1b15dc",
      "purchased": 4,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "Aironet DNA Essentials Term Licenses",
      "inuse": 3,
      "id": "60667adb-ac30-491b-82fc-aded2407e0bf",
      "purchased": 8,
      "balance": 5
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "AP Perpetual Networkstack Advantage",
      "inuse": 4,
      "id": "74d7f2d4-f159-4efe-b9a8-11c046c8db74",
      "purchased": 6,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "AP Perpetual Networkstack Essentials",
      "inuse": 1,
      "id": "4800ce01-97ef-4df4-9173-998483f0f90c",
      "purchased": 5,
      "balance": 4
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ASA5516 Threat Defense Malware Protection",
      "inuse": 4,
      "id": "7254e296-b741-4bac-a42b-02aaae811616",
      "purchased": 7,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ASA5516 Threat Defense Threat Protection",
      "inuse": 1,
      "id": "a7012839-04b1-43d5-8b6b-24e9232cbc5a",
      "purchased": 0,
      "balance": -1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ASA5516 Threat Defense URL Filtering",
      "inuse": 1,
      "id": "948e5ed4-d3f1-4be2-aea7-e1cc54af9013",
      "purchased": 0,
      "balance": -1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C3650-DNA-A-48",
      "inuse": 3,
      "id": "1553c938-aecb-4df2-8c75-117afe799616",
      "purchased": 5,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C3650-DNA-E-48",
      "inuse": 4,
      "id": "83ab5897-b94b-4534-b884-1b4d78a42e08",
      "purchased": 5,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C3850-DNA-A-48",
      "inuse": 3,
      "id": "333afe52-d0cc-4b71-a327-a131b006bc70",
      "purchased": 6,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C3850-DNA-E-48",
      "inuse": 0,
      "id": "b1b5df03-703a-4a39-a0e0-14ca5192e6d8",
      "purchased": 20,
      "balance": 20
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9200 DNA Essentials, 24-port Term licenses",
      "inuse": 5,
      "id": "a693d0ad-9059-4855-a7af-fa3c532e5b20",
      "purchased": 10,
      "balance": 5
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 24P DNA Advantage",
      "inuse": 6,
      "id": "3c897a5f-626e-4566-9741-4f232d844e5a",
      "purchased": 10,
      "balance": 4
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 24P DNA Essentials",
      "inuse": 8,
      "id": "424cca9a-0c3e-46e9-8037-0366e19e8e03",
      "purchased": 15,
      "balance": 7
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 24P Network Advantage",
      "inuse": 2,
      "id": "6dcc1534-0c4b-4b44-81a9-708bc7b9f76e",
      "purchased": 7,
      "balance": 5
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 24P Network Essentials",
      "inuse": 9,
      "id": "913c5d23-e486-4432-9b63-32e25f7ee635",
      "purchased": 12,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 48P DNA Advantage",
      "inuse": 4,
      "id": "0a375c43-fa01-4015-916d-45b7b9dfad18",
      "purchased": 8,
      "balance": 4
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 48P Network Advantage",
      "inuse": 3,
      "id": "53cdda2c-63ac-435b-8d90-33de32335bd1",
      "purchased": 4,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9300 48P Network Essentials",
      "inuse": 6,
      "id": "a1fbe92d-18ad-4760-9dfc-4d3a65291b5e",
      "purchased": 9,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9400 DNA Advantage",
      "inuse": 2,
      "id": "8c55c9b8-7869-4b79-bc8e-4e41a41e75c3",
      "purchased": 4,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9400 Network Advantage",
      "inuse": 0,
      "id": "9f38eb0e-a5b0-4706-9412-c38cd60a9af8",
      "purchased": 98,
      "balance": 98
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9400 Network Essentials",
      "inuse": 2,
      "id": "bc6f5056-a834-46f7-b3f2-c185ca883813",
      "purchased": 4,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9500 DNA Advantage",
      "inuse": 2,
      "id": "bff0fa2e-e570-495e-a50f-1bcfde2a9a35",
      "purchased": 3,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "C9500 Network Advantage",
      "inuse": 2,
      "id": "89656ddb-260a-4146-a785-e7b6a23eb7b4",
      "purchased": 6,
      "balance": 4
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "Cisco ISE Virtual Machine Small",
      "inuse": 0,
      "id": "b0d9930e-e2b7-4ad1-b7a4-a4458b7c0222",
      "purchased": 2,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "DNA Advantage Term License for ISR4300",
      "inuse": 1,
      "id": "1d9aaf8f-ef59-4447-93a5-a06118ca5de4",
      "purchased": 5,
      "balance": 4
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISE Advantage",
      "inuse": 0,
      "id": "ad4c040d-38a0-44e2-93c6-247b2be528fc",
      "purchased": 1,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISE Base Session Licenses",
      "inuse": 0,
      "id": "6d1607ae-9d02-4de1-b503-e6bbe1a9d950",
      "purchased": 2,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISE Essentials",
      "inuse": 1,
      "id": "a25fef7d-91b4-42a9-9afc-e6b23d245ff6",
      "purchased": 2,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISE Plus License",
      "inuse": 0,
      "id": "dbcade48-40d5-4914-b096-494376df32a2",
      "purchased": 1,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISE Premier",
      "inuse": 0,
      "id": "f727080c-fa72-40b3-86a8-bcdf24e5b7d5",
      "purchased": 1,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4331_AdvancedUCSuite",
      "inuse": 1,
      "id": "d150290e-daa2-4a91-ad57-31e0e3f2a0d7",
      "purchased": 2,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4331_Application",
      "inuse": 1,
      "id": "58a97212-aa98-4e75-b064-86a34040d2cb",
      "purchased": 3,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4331_FoundationSuite",
      "inuse": 0,
      "id": "e50b549a-463a-419a-9cdb-bc20fa3428a0",
      "purchased": 1,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4400_Application",
      "inuse": 0,
      "id": "085e8813-9f79-4e57-8f01-d3570ec9ecaf",
      "purchased": 6,
      "balance": 6
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4400_Hsec",
      "inuse": 0,
      "id": "714c364c-7bf7-4df7-bf08-9caefaa36a34",
      "purchased": 2,
      "balance": 2
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4400_Security",
      "inuse": 0,
      "id": "1f582ec8-b9c2-4c12-bf6a-9731ea969edb",
      "purchased": 3,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4400_UnifiedCommunication",
      "inuse": 0,
      "id": "173657d7-33c1-4c00-95fe-150d13c2edd5",
      "purchased": 3,
      "balance": 3
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4431_1G_Performance",
      "inuse": 0,
      "id": "974b8a64-0618-473f-873d-117833e10508",
      "purchased": 1,
      "balance": 1
    },
    {
      "virtualAccount": "EFT FIELD SEVT",
      "license": "ISR_4451_2G_Performance",
      "inuse": 0,
      "id": "b9995362-fe69-4b61-a973-c1043e8a9375",
      "purchased": 1,
      "balance": 1
    }
  ],
  "isTaskInProgress": false
},

"AllLicence_Usage_stats":{
    "saLicenseUsagePercentage": 20.0,
    "lastUpdateTime": "2021-03-16T07:51:15.087Z",
    "vaLicenseUsageStats": [
      {
        "vaLicenseUsagePercentage": 20.0,
        "vaName": "EFT FIELD SEVT"
      }
    ]
  }

});