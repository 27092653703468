define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWPdWc6E69KuApuoPELq",
        "name": "wireless_client_onboarding",
        "entityType": "Client",
        "entity": "B8:27:EB:CA:AA:88",
        "groupBy": "LA1-WLC5520-2",
        "category": "Onboarding",
        "severity": "HIGH",
        "priority": "P1",
        "summary": "Wireless client took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - Excessive time due to Association failures",
        "scope": "GLOBAL",
        "rootCause": "EXCESSIVE_ASSOC_FAIL",
        "timestamp": 1528426953529,
        "description": "This client is taking longer than expected time to connect to 'SSID@ToChange' SSID due to intermittent association failures.<ul><li>Onboarding took 123.4 seconds (expected time should be less than 10.0 seconds).</li><li>802.11 Association took 0 seconds (expected time should be less than 2.0 seconds).</li><li>Authentication and Key Exchange took 1.4 seconds (expected time should be less than 3.0 seconds)</li><li>IP Addressing took 1.2 seconds (expected time should be less than 5.0 seconds)</li><li>This client tried to onboard 2 times which took 120.8 seconds </li></ul><br>The client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP in 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC.",
        "suggestions": [
          {
            "message": "Verify the AP client count on the AP radio as clients beyond the configured maximum will be denied association.",
            "steps": [

            ]
          },
          {
            "message": "Check the RF conditions at the AP location. Since bad RF condition will leads to packet losses and higher retries.",
            "steps": [

            ]
          },
          {
            "message": "Check if client is roaming since a moving client may drop association messages.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the 192.168.139.168 AAA server is responsive during MAC Authentication (if applicable). As MAC Authentication occurs during the association phase",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "hostName",
            "value": "Grace.Smith-PC"
          },
          {
            "key": "groupType",
            "value": "WLC"
          },
          {
            "key": "assocTime",
            "value": "0"
          },
          {
            "key": "authTime",
            "value": "1355"
          },
          {
            "key": "groupBy",
            "value": "LA1-WLC5520-2"
          },
          {
            "key": "slot",
            "value": "0"
          },
          {
            "key": "type",
            "value": "issue"
          },
          {
            "key": "reasonType",
            "value": "cl_WLAN_CHANGE"
          },
          {
            "key": "previousApName",
            "value": ""
          },
          {
            "key": "ssid",
            "value": "LA-Corporate2"
          },
          {
            "key": "apName",
            "value": "LA2-AP3802-23"
          },
          {
            "key": "frequency",
            "value": "2.4"
          },
          {
            "key": "reasonCodes",
            "value": "1,0,0,51,0,0,0,1,176,0,1,0,0,89,0,147,191,0"
          },
          {
            "key": "vlan",
            "value": "100"
          },
          {
            "key": "hostType",
            "value": "WIRELESS"
          },
          {
            "key": "rootCause",
            "value": "EXCESSIVE_ASSOC_FAIL"
          },
          {
            "key": "aaaServerIp",
            "value": "192.168.139.168"
          },
          {
            "key": "resultType",
            "value": "cl_FAIL"
          },
          {
            "key": "dhcpTime",
            "value": "1190"
          },
          {
            "key": "hostOSType",
            "value": "Linux-Workstation"
          },
          {
            "key": "isRoaming",
            "value": "false"
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "totalTime",
            "value": "123380"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "previousWlcName",
            "value": ""
          },
          {
            "key": "apGroup",
            "value": "LA1"
          },
          {
            "key": "dhcpServerIp",
            "value": "192.168.101.105"
          },
          {
            "key": "apMac",
            "value": "38:90:A5:DF:3B:60"
          },
          {
            "key": "entityId",
            "value": "B8:27:EB:CA:AA:88"
          },
          {
            "key": "eventType",
            "value": "cl_EVENT_ASSOC_DONE"
          },
          {
            "key": "eventTypes",
            "value": "1,2,3,4,3,13,3,1,2,11,1,2,3,4,5,6,7,9"
          },
          {
            "key": "wlcName",
            "value": "LA1-WLC5520-2"
          },
          {
            "key": "eventTimestamps",
            "value": "1528426833807,0,3,61488,5070,23892,6736,22533,0,943,170,0,2,1124,229,0,1190,0"
          },
          {
            "key": "resultTypes",
            "value": "0,0,0,5,0,5,0,0,5,0,0,0,0,0,0,0,0,0"
          },
          {
            "key": "siteId",
            "value": "76599253-72e4-448d-b5ee-7ed967d726e7"
          },
          {
            "key": "_id",
            "value": "B8:27:EB:CA:AA:88"
          },
          {
            "key": "category",
            "value": "onboarding"
          },
          {
            "key": "timestring",
            "value": "2018-06-08T03:02:33.529+0000"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "macAddr",
            "value": "B8:27:EB:CA:AA:88"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
      }
    ]
  }
})