define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/discovery/DiscoveryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/GlobalCredentialTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/discovery/JobTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData'
    ], function(SimLokiDatabaseActions, UtilityFunctions, SimulationUtility, DiscoveryTemplate,
                GlobalCredentialTemplate, JobTemplate, DefaultConfig, SimNetworkDeviceData, SimDefaultDataGenerator,
                SimTopologyData){

    //Stores the object of the form {jobName,devicesList} for easy retrieval...
    //var discoveryCollection = [];
    var ipAddressToCreate;

    function getDiscoveryId() {
        var d = SimLokiDatabaseActions.getAll('discovery');
        var maxObj = d.reduce(function(prev, current) {return (prev.id > current.id) ? prev : current});
        return maxObj.id + 1;
    }

    function getDeviceAssociatedWithDevice(discoveryObj) {
        if(discoveryObj == undefined || discoveryObj.deviceIds == undefined)
            return undefined;

        if(discoveryObj.deviceIds.length) {
            var allDevices = SimLokiDatabaseActions.getAll('network-device');
            var tIds;
            if (typeof discoveryObj.deviceIds === 'string' || discoveryObj.deviceIds instanceof String)
                tIds = discoveryObj.deviceIds.split(',');

             //return allDevices.filter(function(v){return tIds.indexOf(v.id) >= 0}).map(a => a.id);
             return allDevices.filter(function(v){return tIds.indexOf(v.id) >= 0});
         }
         return [];
    }

    function createDefaultCredential(){

        var def = JSON.parse(JSON.stringify(DiscoveryTemplate.Discovery_Template));
        var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.CLI_Info_Template ));
        console.log(tJsonArr);
        var id = UtilityFunctions.generate_uuid();
        tJsonArr.password ="********";
        tJsonArr.enablePassword ="********";
        tJsonArr.username ="cisco";
        tJsonArr.description ="cisco";
        //tJsonArr.instanceUuid =id;
        tJsonArr.id =id;
        tJsonArr['type'] ="CLI";
        def.globalCredentialIdList.push(id);
        console.log(tJsonArr);
        SimLokiDatabaseActions.insert('global-credential', tJsonArr );


        var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Write_Info_Template ));
        console.log(tJsonArr);
        var id = UtilityFunctions.generate_uuid();
        tJsonArr.writeCommunity ="write";
        tJsonArr.description ="cisco";
        //tJsonArr.instanceUuid =id;
        tJsonArr.id =id;
        def.globalCredentialIdList.push(id);
        tJsonArr['type'] ="SNMPV2_WRITE_COMMUNITY";
        console.log(tJsonArr);
        SimLokiDatabaseActions.insert('global-credential', tJsonArr );

        var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Read_Info_Template ));
        console.log(tJsonArr);
        var id = UtilityFunctions.generate_uuid();
        tJsonArr.readCommunity ="read";
        tJsonArr.description ="cisco";
        //tJsonArr.instanceUuid =id;
        tJsonArr.id =id;
        tJsonArr['type'] ="SNMPV2_READ_COMMUNITY";
        def.globalCredentialIdList.push(id);
        console.log(tJsonArr);
        SimLokiDatabaseActions.insert('global-credential', tJsonArr );

        let netConf = JSON.parse(JSON.stringify(GlobalCredentialTemplate.NETCONF_Info_Template ));
        var id = UtilityFunctions.generate_uuid();
        netConf.netconfPort = 830;
        netConf.instanceUuid = id;
        netConf.id =id;
        netConf.description = null;
        //def.globalCredentialIdList.push(id);
        console.log(netConf);
        SimLokiDatabaseActions.insert('global-credential', netConf );
        
        var tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.HTTP_WRITE_Info_Template ));
        console.log(tJsonArr);
        var id = UtilityFunctions.generate_uuid();
        //tJsonArr.instanceUuid =id;
        tJsonArr.id =id;
        tJsonArr['type'] ="HTTP_WRITE";
        def.globalCredentialIdList.push(id);
        console.log(tJsonArr);
        SimLokiDatabaseActions.insert('global-credential', tJsonArr );

        var allDevices = SimLokiDatabaseActions.getAll('network-device');
        var dev ="";
        for (var i = 0; i < allDevices.length; i++) {
            if(i == 0){
                dev =  allDevices[0].id;
            }
            else {
                dev =  dev+","+allDevices[i].id;
            }
        }

        def.deviceIds = dev;
        def.numDevices = allDevices.length;

        def.discoveryCondition = "Complete";
        def.discoveryStatus = "Inactive";
        SimLokiDatabaseActions.insert('discovery', def);
        //discoveryCollection[def.name] =({'devicesList' : allDevices,'ipAddressList':def.ipAddressList});
        return {"id":def.id, "name":def.name,"jobStatus":"SUCCESS"};
    }


    return {
        init: function(){
            var discoveryInstance = createDefaultCredential();
            createDiscoveryJob(discoveryInstance);
            //createDefaultDiscovery();
        },

        createDiscovery :function (jsonObj){

            var taskResponse = createDiscovery(jsonObj);
            //if(jsonObj)
            if(!taskResponse.isError){
                return createDiscoveryJob(taskResponse);
            }
            return taskResponse;
            //createDeviceCrential(jsonObj);
        },

        deleteDiscovery : function(jsonObj){
            deleteDiscovery(jsonObj);
        },

        getJobDetail: function(urlAction) {
            var id = urlAction.service[1];
            var objs =[];
            if(id != undefined && !isNaN(Number(id))){
                objs = SimLokiDatabaseActions.getFilteredRecordHandler('discovery-job', {'discoveryid' : Number(id) });
                if(urlAction.count) {
                    return updateResponse(objs.length);
                }

                var discoveryDetail = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'id' : Number(id) });

                //EDIT Discovery should show the job status as RUNNING and discoveryCondition as In Progress, once..
                //The next time, it should be updated to SUCCESS and COMPLETE
                if(discoveryDetail!= undefined && discoveryDetail.length > 0){
                    var localDiscoveryCondition = discoveryDetail[0].discoveryCondition;
                    if(discoveryDetail[0].discoveryCondition == 'In Progress') {
                        discoveryDetail[0].discoveryCondition = 'Complete';
                        SimLokiDatabaseActions.update('discovery',discoveryDetail[0]);
                    }
                }

                if(objs!= undefined && objs.length > 0){
                    for(var index=0;index<objs.length; index++){
                        if(objs[index].jobStatus == 'RUNNING' &&  localDiscoveryCondition == 'Complete' ){
                            objs[index].jobStatus = 'SUCCESS';
                            SimLokiDatabaseActions.update('discovery-job',objs[index]);
                        }
                    }
                }
            }
            return updateResponse(objs);
        },

        putDiscovery: function(urlAction) {
            var discoveryObj;
            if(urlAction.restPayload.discoveryStatus == 'Active') {
                discoveryObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'id' : Number(urlAction.restPayload.id) });
                if(discoveryObj != undefined) {
                    discoveryObj = discoveryObj[0];
                }
            }
            else if (urlAction.restPayload.discoveryStatus == 'Edit'){
                discoveryObj = urlAction.restPayload;
            }
            var data = this.createDiscovery(discoveryObj);
            return updateResponse(data);

        },

        getDiscoveredDevices: function(urlAction) {
            if(urlAction.filter['taskId'] != undefined &&
                urlAction.service.indexOf('filter') <0 ) {

                var id = parseInt(urlAction.filter['taskId']);
                var jobObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery-job',{'taskId':id});
                if(jobObj && jobObj.length)  {
                    var discoveryId = jobObj[0].discoveryid;
                    discoveryObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'id' : discoveryId });
                    var filteredDevices = getDeviceAssociatedWithDevice(discoveryObj[0]);
                    return updateResponse(filteredDevices.length);
                }
            }

            var data = fetchDevicesCollection(urlAction);
            return updateResponse(data);
        },

        getDiscoveryDetail: function(urlAction) {
            var discoveryDetail = SimLokiDatabaseActions.getAll('discovery');
            if(urlAction!= undefined && urlAction.count) {
                return discoveryDetail.length;
            }
            var discoveries = SimLokiDatabaseActions.getAll('discovery-job');
            discoveries = UtilityFunctions.sortData('startTime','desc',discoveryDetail);
            var data = [];
            discoveries.forEach(discovery => {
                discoveryDetail.filter(dtl => { if(dtl.name==discovery.name) data.push(dtl); } );
            });
            return data;
            //return discoveryDetail;
        },

        getCredential: function(urlAction) {
            var data = getCredentialData(urlAction);
            return updateResponse(data);
        },

        postCredential: function(urlAction){
            var data = postCredentialData(urlAction);
            return updateResponse(data);
        },

        getCount: function (obj, type) {
            if(type == undefined) return;
            if(type == 'discoveryType') {
                var tData = {"CDP":0,"LLDP":0,"RANGE":0};
                obj.forEach(discoveryObj => {
                    if(tData[discoveryObj[type]] != undefined) {
                        tData[discoveryObj[type]]++;
                    } else {
                        tData[discoveryObj[type]] = 1;
                    }
                });
                return tData;
            } else if(type == 'discoveryCondition') {
                var tData = {"Completed":0,"InProgress":0,"Queued":0,"Aborted":0};
                var mapping = {'Complete':'Completed', 'In Progress':'InProgress', 'Queued':'Queued', 'Aborted':'Aborted'};
                obj.forEach(discoveryObj => {
                    if(tData[mapping[discoveryObj[type]]] != undefined) {
                        tData[mapping[discoveryObj[type]]]++;
                    } else if(tData[discoveryObj[type]] != undefined) {
                        tData[discoveryObj[type]]++;
                    } else {
                        tData[discoveryObj[type]] = 1;
                    }
                });
                return tData;
            }
        }

    };

    //Function to get different types of Credential Data
    function getCredentialData(urlAction) {
        var deviceObj;
        if(urlAction.filter.indexOf('credentialSubType') >= 0){
            deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', { 'credentialSubType' : urlAction.filter['credentialSubType'] });
        }
        return deviceObj != undefined ? deviceObj:[];
    }

    //Function to post different types of Credential Data
    function postCredentialData(urlAction){
        if(urlAction.restPayload != null && urlAction.restPayload.length > 0){
            var credentialData = urlAction.restPayload[0];
            var tJsonArr;
            var taskResponse = {isError: false};
            if(urlAction.service.indexOf('cli') >= 0){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.CLI_Info_Template ));
                tJsonArr.username = credentialData.username;
                tJsonArr.password = credentialData.password;
                tJsonArr.enablePassword = credentialData.enablePassword;
            }
            else if(urlAction.service.indexOf('snmpv2-read-community') >= 0){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Read_Info_Template ));
                tJsonArr.description = credentialData.description;
                tJsonArr.readCommunity = credentialData.readCommunity
            }
            else if(urlAction.service.indexOf('snmpv2-write-community') >= 0){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP_Write_Info_Template ));
                tJsonArr.description = credentialData.description;
                tJsonArr.writeCommunity = credentialData.writeCommunity
            }
            else if(urlAction.service.indexOf('snmpv3') >= 0){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP3_Info_Template ));
                tJsonArr.authPassword = credentialData.authPassword;
                tJsonArr.authType = credentialData.authType;
                tJsonArr.privacyPassword = credentialData.privacyPassword;
                tJsonArr.privacyType = credentialData.privacyType;
                tJsonArr.snmpMode = credentialData.snmpMode;
                tJsonArr.username = credentialData.username;
            }
            else if(urlAction.service.indexOf('snmp-property') >= 0){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.SNMP3_Info_Template ));
                tJsonArr.intValue = credentialData.intValue;
                tJsonArr.systemPropertyName = credentialData.systemPropertyName;
            }
            else if(urlAction.service.indexOf('http-read') >= 0 || urlAction.service.indexOf('http-write') >= 0 ){
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.HTTP_Info_Template ));
                tJsonArr.username = credentialData.username;
                tJsonArr.port = credentialData.port;
                tJsonArr.password =   credentialData.password;
                if(urlAction.service.indexOf('http-read') >= 0){
                    tJsonArr.credentialSubType = 'HTTP_READ';
                }
                if(urlAction.service.indexOf('http-write') >= 0){
                    tJsonArr.credentialSubType = 'HTTP_WRITE';
                }
            }
            else if(urlAction.service.indexOf('netconf') >= 0) {
                tJsonArr = JSON.parse(JSON.stringify(GlobalCredentialTemplate.NETCONF_Info_Template));
                tJsonArr.netconfPort = credentialData.netconfPort;
            }
            tJsonArr.id = UtilityFunctions.generate_uuid();
            tJsonArr.instanceUuid = tJsonArr.id;
            SimLokiDatabaseActions.insert('global-credential', tJsonArr );
        }
        return taskResponse;
    }

    function createDiscoveryJob(jsonObj){
        var tJsonArr = JSON.parse(JSON.stringify(JobTemplate.Job_Template ));
        tJsonArr[0].name = jsonObj.name;
        tJsonArr[0].taskId = UtilityFunctions.getTimeStamp();
        tJsonArr[0].startTime = UtilityFunctions.getTimeStamp();
        tJsonArr[0].endTime = UtilityFunctions.getTimeStamp()+(UtilityFunctions.getRandomIntForRangeValues(5,20)*1000);//5-20s delay
        tJsonArr[0].discoveryid = parseInt(jsonObj.id);
        tJsonArr[0].jobStatus = jsonObj.jobStatus;
        SimLokiDatabaseActions.insert('discovery-job', tJsonArr);
        return {...tJsonArr[0]};
    };

    function createDiscovery(jsonObj){
        var obj;
        var discoverid = 0;
        var t_Json;
        var taskResponse = {isError:false};

        if(jsonObj.id == undefined){
            obj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'name' : jsonObj.name});
        }
        else {
            obj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'id' : parseInt(jsonObj.id) });
        }
        if(obj.length == 0) {
            t_Json = JSON.parse(JSON.stringify(DiscoveryTemplate.Discovery_Template));
            console.log("jsonObj = "+JSON.stringify(jsonObj));
            console.log("t_Json = "+JSON.stringify(t_Json));
            discoverid = getDiscoveryId();
        } else {
            discoverid = parseInt(jsonObj.id);
            t_Json = obj[0];
            jsonObj.name = obj[0].name;
            //Only for CDP during EDIT, changing the ipaddress is not allowed
            if(jsonObj.ipAddressList != obj[0].ipAddressList && jsonObj.discoveryStatus != "Active" && jsonObj.discoveryType == "CDP" ){
                taskResponse = {errorCode:"BadRequest",failureReason:"IP Address cannot be changed",isError:true,progress:"Inventory service update discovery has failed."};
            }
            t_Json.discoveryCondition = "In Progress";
        }

        if(!taskResponse.isError ) {
            t_Json.id = discoverid;
            if(jsonObj.discoveryStatus != "Active"){
                //Incase of EDIT discovery Status, update the new set of input parameters in the db
                t_Json.name = jsonObj.name;
                t_Json.ipAddressList = jsonObj.ipAddressList;
                t_Json.discoveryType = jsonObj.discoveryType;
                t_Json.preferredMgmtIPMethod = jsonObj.preferredMgmtIPMethod;
                t_Json.globalCredentialIdList = jsonObj.globalCredentialIdList;
            }
            else {
                //Incase of Redoing the discovery, update only the discovery condition.
                jsonObj.name = obj[0].name;
                t_Json = obj[0];
            }
            taskResponse = fetchDevicesByRange(t_Json);
            if( obj.length == 0 && !taskResponse.isError){
                t_Json.instance = 1;
                SimLokiDatabaseActions.insert('discovery', t_Json);
                taskResponse.jobStatus = "SUCCESS";
            }
            else if(obj.length != 0 && !taskResponse.isError){
                SimLokiDatabaseActions.update('discovery',t_Json);
                taskResponse.jobStatus = "RUNNING";
            }
        }
        //Populating the id and name in taskResponse to update the discovery job
        taskResponse.id = discoverid;
        taskResponse.name = jsonObj.name;
        return taskResponse;
    }

    function deleteDiscovery(urlAction){
        var id = urlAction.service[1];

        if(id != undefined && !isNaN(Number(id))){
            var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', { 'id' : Number(id) });
            if(deviceObj && deviceObj.length)  {
                SimLokiDatabaseActions.delete('discovery', deviceObj);
            }
            var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery-job', { 'discoveryid' : Number(id) });
            if(deviceObj && deviceObj.length)  {
                SimLokiDatabaseActions.delete('discovery-job', deviceObj);
            }
        }
    }

    //Logic to compare and determine if the device ip falls in the range..
    function compareIPParts(everyIp,startIp,endIp,differingIndex){
        if( Number(startIp[differingIndex]) < Number(everyIp[differingIndex]) && Number(endIp[differingIndex]) > Number(everyIp[differingIndex]) ) {
            return true;
        }
        else if( (Number(startIp[differingIndex]) == Number(everyIp[differingIndex]) && (Number(differingIndex)+1 > 3 ||
                   Number(startIp[differingIndex+1]) >= Number(everyIp[differingIndex+1])) ) ||
                   ( Number(endIp[differingIndex]) == Number(everyIp[differingIndex]) && (Number(differingIndex)+1 > 3 ||                      Number(endIp[differingIndex+1]) <= Number(everyIp[differingIndex+1]) ) ) ) {
            return true;
        }
        return false;
    }

    //Function applies to discovery type Range.. Filters devices based on the range
    function filterNetworkDevices(networkObjs,startIp,endIp,differingIndex) {
        var filteredDevices = [];
        if(networkObjs != undefined && networkObjs.length > 0 && differingIndex <=3) {
            for(var index in networkObjs) {
                var everyIp = networkObjs[index].managementIpAddress.split('.');
                var result = compareIPParts(everyIp,startIp,endIp,differingIndex);
                if(result) {
                    if(differingIndex+1 <= 3 ){
                        result = compareIPParts(everyIp,startIp,endIp,differingIndex+1);
                    }
                    else {
                        result = true;
                    }
                }
                else {
                    result = false;
                }
                if(result == true) {
                    //url: "/api/v1/discovery/1/filter?recordsToReturn=25&startIndex=1&sortBy=pingStatus&sortOrder=asc"
                    filteredDevices.push(networkObjs[index]);
                }
            }
        }
        return filteredDevices;
    }

    //Formats the network devices response to display in the GUI..
    function formDiscoverResponse(filteredDevices){
        var filteredResponse = [];
        //if (typeof filteredDevices === 'string' || filteredDevices instanceof String)
        //    filteredDevices = filteredDevices.split(',');

        if(filteredDevices != undefined && filteredDevices.length > 0 && filteredDevices[0].id != undefined &&
               filteredDevices[0].id != '') {
            //Update other properties also...TODO
            for(var deviceIndex in filteredDevices){
                var t_Json = JSON.parse(JSON.stringify(DiscoveryTemplate.Discovery_Response_Template));
                t_Json.managementIpAddress = filteredDevices[deviceIndex].managementIpAddress;
                t_Json.id = filteredDevices[deviceIndex].id;
                t_Json.hostname = filteredDevices[deviceIndex].hostname;
                filteredResponse.push(t_Json);
            }
        }
        return filteredResponse;
    }

    //Function returns the discovery Obj if the discovery id is given..
    function getDiscoveryObjFromId(urlAction){
        var discoveryObj = null;
        for(var serviceIndex in urlAction.service){
            if(!isNaN(Number(urlAction.service[serviceIndex]))){
                //var obj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery-job', {'discoveryid' : Number(urlAction.service[serviceIndex])});
                //if(obj != undefined && obj.length > 0){
                //var jobName = obj[0].name;
                discoveryObj = SimLokiDatabaseActions.getFilteredRecordHandler('discovery', {'id' : Number(urlAction.service[serviceIndex]) });
                break;
            }
        }
        return discoveryObj!= undefined && discoveryObj.length > 0? discoveryObj[0]:undefined;
    }

    //Function to create Network Device
    function createNetworkDevice(ipAddress){
        var jsonObj={'ipAddress':''};
        jsonObj.id = UtilityFunctions.generate_uuid();
        jsonObj.instanceUuid = jsonObj.id;
        /*jsonObj.role = "Access";
        jsonObj.ipAddress = "";*/

        if(ipAddress != undefined && ipAddress.length > 0) {
            for(index = 0 ;index<ipAddress.length-1;index++) {
                jsonObj.ipAddress = jsonObj.ipAddress+ipAddress[index]+"."
            }
        }
        var deviceList = SimLokiDatabaseActions.getAll('network-device');
        jsonObj.ipAddress = jsonObj.ipAddress + ((Number(deviceList.length)) +1);

        //var deviceDetails =SimDefaultDataGenerator.inventoryDevice(jsonObj,jsonObj.ipAddress,jsonObj.id,"ACCESS");
        //var deviceDetails = SimulationUtility.createNetworkDevice(jsonObj, '');
        var tJsonObj = SimulationUtility.createNetworkDevice(jsonObj, '');
        var deviceDetails = SimDefaultDataGenerator.inventoryDevice(tJsonObj,tJsonObj.ipAddress,tJsonObj.id,"");

        deviceDetails[0].hostname = SimulationUtility.createRandomHostName(deviceDetails[0].serialnumber, 'Discovery');

        SimNetworkDeviceData.addDevice(deviceDetails);
        SimTopologyData.addDevice("",deviceDetails);
        SimNetworkDeviceData.addDeviceConfig(deviceDetails);
        //var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': jsonObj.ipAddress });
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': deviceDetails[0].managementIpAddress });

        return recordObj != undefined ? recordObj:null;
    }

    function handleRangeDiscovery(discoveryObj){
        var filteredDevices = [];
        if(discoveryObj!= undefined &&  discoveryObj.ipAddressList != undefined && discoveryObj.ipAddressList.split('-').length > 0) {
            var ipAddresses = discoveryObj.ipAddressList.split('-');
            var commonIpToFetch = '';
            var startIp = ipAddresses[0].split('.');
            ipAddressToCreate = startIp;
            var endIp = ipAddresses[1].split('.');
            var differingIndex =0;//Index at which the number changes in the IP octets
            var filteredDevices=[];
            if(startIp.length >0 && endIp.length > 0) {
                for(var ipIndex = 0;ipIndex<startIp.length;ipIndex++) {
                    if(startIp[ipIndex] != endIp[ipIndex]) {
                        if(startIp[ipIndex] > endIp[ipIndex]) {
                            taskResponse = {errorCode:"BadRequest",failureReason:"Beginning IP address is greater than ending IP address; ",isError:true,progress:"Inventory service update discovery has failed."};
                            return taskResponse;
                        }
                        differingIndex = ipIndex;
                        break;
                    }
                    else {
                        commonIpToFetch = commonIpToFetch+startIp[ipIndex]+'\\.';
                    }
                }
                if(differingIndex == 2){
                    var change = Number(endIp[differingIndex]) - Number(startIp[differingIndex]);
                    if( change > 16 ||
                        ((Number(endIp[differingIndex+1])+1+ (256-Number(startIp[differingIndex+1]))) > 256  &&
                        change == 16)){
                        taskResponse = {errorCode:"BadRequest",failureReason:"Total number of IP's in the request exceeded 4096 limit.",isError:true,progress:"Inventory service initiate discovery has failed."};
                        return taskResponse;
                    }
                }

                filteredDevices = SimLokiDatabaseActions.filterByRegx('network-device','managementIpAddress',commonIpToFetch);
                filteredDevices = filterNetworkDevices(filteredDevices,startIp,endIp,differingIndex);
            }
        }
        return filteredDevices;
    }

    function handleCDPDiscovery(discoveryObj){
        var filteredDevices = [];
        if(discoveryObj!= undefined &&  discoveryObj.ipAddressList != undefined &&
           discoveryObj.ipAddressList.split('.').length > 0) {
            var ipAddresses = discoveryObj.ipAddressList.split('.');
            ipAddressToCreate = ipAddresses;
            var commonIpToFetch= '';
            //Currently handled for Subnetmask /16, as in the real cluster..
            for(var ipIndex = 0;ipIndex<3;ipIndex++) {
                commonIpToFetch = commonIpToFetch+ipAddresses[ipIndex]+'\\.';
            }
            filteredDevices = SimLokiDatabaseActions.filterByRegx('network-device','managementIpAddress',commonIpToFetch);
            if(discoveryObj.ipFilterList != undefined && discoveryObj.ipFilterList.split('/').length >1){
                var subnetmask = Number(discoveryObj.ipFilterList.split('/')[1])/8;
                var commonIpToFilter = '';
                for(var ipIndex = 0;ipIndex<=(3-subnetmask);ipIndex++) {
                    commonIpToFilter = commonIpToFilter+ipAddresses[ipIndex]+'\\.';
                }
                //Remove the device entries which are in ipFilterList
                if(commonIpToFilter != ''){
                    for(var deviceIndex in filteredDevices){
                        if(filteredDevices[deviceIndex].managementIpAddress.startsWith(commonIpToFilter)){
                            filteredDevices.splice(deviceIndex,1);
                        }
                    }
                }
            }
        }
        return filteredDevices;
    }

    //Function to fetch the devices details from the network-device table,based on the range and cdp value given.
    function fetchDevicesByRange(discoveryObj){
        var taskResponse ={};
        if(discoveryObj!= undefined ) {
            var filteredDevices = [];
            var jobName = discoveryObj.name;
            if(discoveryObj.discoveryType == 'CDP'){
                filteredDevices = handleCDPDiscovery(discoveryObj);
            }
            else if(discoveryObj.discoveryType == 'Range'){
                filteredDevices = handleRangeDiscovery(discoveryObj);
            }
            else if(discoveryObj.discoveryType == 'Multi range'){
                var ipAddressList = discoveryObj.ipAddressList;
                var ipRanges = discoveryObj.ipAddressList.split(',');

                filteredDevices = getDeviceAssociatedWithDevice(discoveryObj);
                //filteredDevices = discoveryCollection[jobName].devicesList;//swarup -- write alternate code
                for(var ipIndex in ipRanges){
                    discoveryObj.ipAddressList = ipRanges[ipIndex];
                    var newSetOfDevices = handleRangeDiscovery(discoveryObj);
                    if(newSetOfDevices.isError != undefined && newSetOfDevices.isError){
                        return newSetOfDevices;
                    }
                    for(var newIndex in newSetOfDevices){
                        filteredDevices.push(newSetOfDevices[newIndex]);
                    }
                }
                discoveryObj.discoveryType = 'Range';
                discoveryObj.ipAddressList = ipAddressList;
            }

            if(filteredDevices != undefined) {
                if(filteredDevices.isError != undefined && filteredDevices.isError) {
                    return filteredDevices;
                }
                if( filteredDevices.length == 0) {
                    //To create a device, if there are no devices in the range specified.
                    filteredDevices = createNetworkDevice(ipAddressToCreate);
                    discoveryObj.deviceIds = filteredDevices.length ? filteredDevices[0].id: '';
                }
                discoveryObj.numDevices = (filteredDevices != undefined && filteredDevices.length >0) ? filteredDevices.length:0;

                //discoveryCollection[jobName] = {'devicesList' : filteredDevices, 'ipAddressList': discoveryObj.ipAddressList };//swarup -- alternate code..
            }
        }
        taskResponse = {isError: false , progress: discoveryObj.id};
        return taskResponse;
    }

    //Function to fetch the devicesList from the stored collection.
    //Handles count as well as returning teh devices collection.
    function fetchDevicesCollection(urlAction){
        var discoveryObj = getDiscoveryObjFromId(urlAction);
        if(discoveryObj == undefined ) {
            return urlAction.action.count ? 0 : [];
        }

        var jobName = discoveryObj.name;
        var filteredDevices = null;
        /*if(discoveryCollection[jobName] != undefined ) {
            filteredDevices = discoveryCollection[jobName].devicesList;
        }*///swarup -- write alternate code..
        filteredDevices = getDeviceAssociatedWithDevice(discoveryObj);
        if(urlAction.action.count || urlAction.action.summary){
                return filteredDevices.length;
        }

        var response = formDiscoverResponse(filteredDevices);
        var finalResponses = [];
        // --> /api/v1/discovery/1/filter?recordsToReturn=25&startIndex=1&sortBy=pingStatus&sortOrder=asc
        if(urlAction.filter.indexOf('recordsToReturn')>=0 && urlAction.filter.indexOf('startIndex')>=0){
            for(var pagingIndex=0;pagingIndex<response.length;pagingIndex++) {
                if( ( (Number(urlAction.filter['startIndex'])) -1 ) <= pagingIndex &&
                    //pagingIndex <= ( (Number(urlAction.filter['recordsToReturn']))-1) ){
                    (pagingIndex - (Number(urlAction.filter['startIndex']))-1) <= ( (Number(urlAction.filter['recordsToReturn']))-1) ){
                    finalResponses.push(response[pagingIndex]);
                }
            }

            return finalResponses;
        } else {
            return response;
        }

    }

    function updateResponse(data) {
        return {"response":data,"version":"1.0"};
    }

});
