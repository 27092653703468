define({
    "WIFI6_AT_BG": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "nonAxATE": 20.589019782173818,
                "nonAxBytes": 700220,
                "axBytes": 370520,
                "axAirtime": 17996,
                "nonAxAirtime": 33964,
                "axATE": 20.61653515486986,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "nonAxATE": 20.38571701355738,
                "nonAxBytes": 725340,
                "axBytes": 427040,
                "axAirtime": 20948,
                "nonAxAirtime": 35112,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 871350,
                "axBytes": 417620,
                "axAirtime": 20216,
                "nonAxAirtime": 42180,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "nonAxATE": 20.420317634173056,
                "nonAxBytes": 883878,
                "axBytes": 447450,
                "axAirtime": 21912,
                "nonAxAirtime": 42744,
                "axATE": 20.67841100505334,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "nonAxATE": 20.613121040346783,
                "nonAxBytes": 3717035,
                "axBytes": 494550,
                "axAirtime": 23992,
                "nonAxAirtime": 91348,
                "axATE": 40.690929193852085,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "nonAxATE": 20.587704395837758,
                "nonAxBytes": 722200,
                "axBytes": 387790,
                "axAirtime": 18836,
                "nonAxAirtime": 34960,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "nonAxATE": 20.484006734006734,
                "nonAxBytes": 1092982,
                "axBytes": 243350,
                "axAirtime": 11880,
                "nonAxAirtime": 45056,
                "axATE": 24.25830078125,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "nonAxATE": 20.532342088126764,
                "nonAxBytes": 2751887,
                "axBytes": 378370,
                "axAirtime": 18428,
                "nonAxAirtime": 71436,
                "axATE": 38.522411669186404,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "nonAxATE": 20.574596774193548,
                "nonAxBytes": 660970,
                "axBytes": 326560,
                "axAirtime": 15872,
                "nonAxAirtime": 31996,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "nonAxATE": 20.58667815808914,
                "nonAxBytes": 4024250,
                "axBytes": 334410,
                "axAirtime": 16244,
                "nonAxAirtime": 164524,
                "axATE": 24.45995720989035,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "nonAxATE": 20.537790697674417,
                "nonAxBytes": 5865077,
                "axBytes": 353250,
                "axAirtime": 17200,
                "nonAxAirtime": 117332,
                "axATE": 49.98701973886067,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "nonAxATE": 49.48755990978292,
                "nonAxBytes": 1674580,
                "axBytes": 5617036,
                "axAirtime": 113504,
                "nonAxAirtime": 60428,
                "axATE": 27.711987820215793,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "nonAxATE": 20.542620742736194,
                "nonAxBytes": 987530,
                "axBytes": 427040,
                "axAirtime": 20788,
                "nonAxAirtime": 47804,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "nonAxATE": 20.47029060716139,
                "nonAxBytes": 665680,
                "axBytes": 315570,
                "axAirtime": 15416,
                "nonAxAirtime": 32224,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "nonAxATE": 20.593822693202377,
                "nonAxBytes": 569910,
                "axBytes": 318710,
                "axAirtime": 15476,
                "nonAxAirtime": 27588,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "nonAxATE": 20.49636972912594,
                "nonAxBytes": 577760,
                "axBytes": 293590,
                "axAirtime": 14324,
                "nonAxAirtime": 27972,
                "axATE": 20.654940654940656,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "nonAxATE": 20.507494646680943,
                "nonAxBytes": 340690,
                "axBytes": 191540,
                "axAirtime": 9340,
                "nonAxAirtime": 16492,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "nonAxATE": 20.589453340695748,
                "nonAxBytes": 398780,
                "axBytes": 447450,
                "axAirtime": 21732,
                "nonAxAirtime": 19304,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "nonAxATE": 41.84129385964912,
                "nonAxBytes": 0,
                "axBytes": 3815926,
                "axAirtime": 91200,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "nonAxATE": 20.466040058185072,
                "nonAxBytes": 0,
                "axBytes": 731620,
                "axAirtime": 35748,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "nonAxATE": 20.059356136820927,
                "nonAxBytes": 213520,
                "axBytes": 199390,
                "axAirtime": 9940,
                "nonAxAirtime": 10336,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 638990,
                "axBytes": 86350,
                "axAirtime": 4180,
                "nonAxAirtime": 30932,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "nonAxATE": 0,
                "nonAxBytes": 469430,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 22740,
                "axATE": 20.64335971855761,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "nonAxATE": 0,
                "nonAxBytes": 36110,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 1748,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "nonAxATE": 20.488814114223352,
                "nonAxBytes": 2036919,
                "axBytes": 450590,
                "axAirtime": 21992,
                "nonAxAirtime": 65504,
                "axATE": 31.09610100146556,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "nonAxATE": 20.48763736263736,
                "nonAxBytes": 8196868,
                "axBytes": 447450,
                "axAirtime": 21840,
                "nonAxAirtime": 169240,
                "axATE": 48.433396360198536,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "nonAxATE": 20.666261644390442,
                "nonAxBytes": 411340,
                "axBytes": 408200,
                "axAirtime": 19752,
                "nonAxAirtime": 19912,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "nonAxATE": 20.439174589455487,
                "nonAxBytes": 828410,
                "axBytes": 378370,
                "axAirtime": 18512,
                "nonAxAirtime": 39396,
                "axATE": 21.027769316681898,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "nonAxATE": 20.369261477045907,
                "nonAxBytes": 9490940,
                "axBytes": 530660,
                "axAirtime": 26052,
                "nonAxAirtime": 200740,
                "axATE": 47.27976486998107,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "nonAxATE": 20.2438844680224,
                "nonAxBytes": 3733031,
                "axBytes": 274750,
                "axAirtime": 13572,
                "nonAxAirtime": 133628,
                "axATE": 27.935993953363067,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "nonAxATE": 17.065217391304348,
                "nonAxBytes": 284170,
                "axBytes": 23550,
                "axAirtime": 1380,
                "nonAxAirtime": 13760,
                "axATE": 20.651889534883722,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "nonAxATE": 20.38961038961039,
                "nonAxBytes": 1176358,
                "axBytes": 213520,
                "axAirtime": 10472,
                "nonAxAirtime": 54156,
                "axATE": 21.72165595686535,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "nonAxATE": 20.36756756756757,
                "nonAxBytes": 2132011,
                "axBytes": 301440,
                "axAirtime": 14800,
                "nonAxAirtime": 52076,
                "axATE": 40.94037560488517,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "nonAxATE": 20.319514661274013,
                "nonAxBytes": 763020,
                "axBytes": 401920,
                "axAirtime": 19780,
                "nonAxAirtime": 36936,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "nonAxATE": 20.54438982070561,
                "nonAxBytes": 598170,
                "axBytes": 284170,
                "axAirtime": 13832,
                "nonAxAirtime": 28956,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "nonAxATE": 20.574169143474737,
                "nonAxBytes": 995788,
                "axBytes": 304580,
                "axAirtime": 14804,
                "nonAxAirtime": 48604,
                "axATE": 20.487778783639207,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "nonAxATE": 20.69774919614148,
                "nonAxBytes": 401920,
                "axBytes": 128740,
                "axAirtime": 6220,
                "nonAxAirtime": 19496,
                "axATE": 20.615510874025443,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "nonAxATE": 20.528782894736842,
                "nonAxBytes": 2877152,
                "axBytes": 499260,
                "axAirtime": 24320,
                "nonAxAirtime": 77008,
                "axATE": 37.36172865156867,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "nonAxATE": 20.73665577342048,
                "nonAxBytes": 1544002,
                "axBytes": 456870,
                "axAirtime": 22032,
                "nonAxAirtime": 45604,
                "axATE": 33.856723094465394,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "nonAxATE": 20.391436761017555,
                "nonAxBytes": 3770518,
                "axBytes": 455300,
                "axAirtime": 22328,
                "nonAxAirtime": 110908,
                "axATE": 33.996808165326215,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "nonAxATE": 20.49745581146224,
                "nonAxBytes": 2538570,
                "axBytes": 306150,
                "axAirtime": 14936,
                "nonAxAirtime": 102008,
                "axATE": 24.88598933416987,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "nonAxATE": 20.503731343283583,
                "nonAxBytes": 1112666,
                "axBytes": 373660,
                "axAirtime": 18224,
                "nonAxAirtime": 53580,
                "axATE": 20.76644270250093,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "nonAxATE": 20.768982576898257,
                "nonAxBytes": 3156605,
                "axBytes": 386220,
                "axAirtime": 18596,
                "nonAxAirtime": 91572,
                "axATE": 34.47129035076224,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "nonAxATE": 20.47826086956522,
                "nonAxBytes": 320280,
                "axBytes": 160140,
                "axAirtime": 7820,
                "nonAxAirtime": 15504,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "nonAxATE": 20.557145886344358,
                "nonAxBytes": 711210,
                "axBytes": 387790,
                "axAirtime": 18864,
                "nonAxAirtime": 34428,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "nonAxATE": 20.521748681898067,
                "nonAxBytes": 340690,
                "axBytes": 186830,
                "axAirtime": 9104,
                "nonAxAirtime": 16548,
                "axATE": 20.587986463620982,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "nonAxATE": 20.581431215299197,
                "nonAxBytes": 1025210,
                "axBytes": 533800,
                "axAirtime": 25936,
                "nonAxAirtime": 49684,
                "axATE": 20.634610739876017,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "nonAxATE": 20.3340661441407,
                "nonAxBytes": 896470,
                "axBytes": 425470,
                "axAirtime": 20924,
                "nonAxAirtime": 43396,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "nonAxATE": 21.0877569460131,
                "nonAxBytes": 1750114,
                "axBytes": 373422,
                "axAirtime": 17708,
                "nonAxAirtime": 78316,
                "axATE": 22.346825680576128,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 17015181,
                "axBytes": 711210,
                "axAirtime": 34428,
                "nonAxAirtime": 345848,
                "axATE": 49.19843688556823,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "nonAxATE": 20.49223681965535,
                "nonAxBytes": 3702897,
                "axBytes": 480420,
                "axAirtime": 23444,
                "nonAxAirtime": 77056,
                "axATE": 48.054622612126245,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "nonAxATE": 20.814393939393938,
                "nonAxBytes": 207240,
                "axBytes": 186830,
                "axAirtime": 8976,
                "nonAxAirtime": 10032,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "nonAxATE": 20.670885080962112,
                "nonAxBytes": 3691147,
                "axBytes": 525950,
                "axAirtime": 25444,
                "nonAxAirtime": 74736,
                "axATE": 49.38914311710555,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "nonAxATE": 20.461514872738423,
                "nonAxBytes": 266900,
                "axBytes": 266900,
                "axAirtime": 13044,
                "nonAxAirtime": 12920,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "nonAxATE": 20.875964271213967,
                "nonAxBytes": 119320,
                "axBytes": 205670,
                "axAirtime": 9852,
                "nonAxAirtime": 5776,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "nonAxATE": 0,
                "nonAxBytes": 208810,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 10108,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "nonAxATE": 20.24504041146216,
                "nonAxBytes": 11743682,
                "axBytes": 551070,
                "axAirtime": 27220,
                "nonAxAirtime": 232744,
                "axATE": 50.45750696043722,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "nonAxATE": 20.53969483847896,
                "nonAxBytes": 843090,
                "axBytes": 689230,
                "axAirtime": 33556,
                "nonAxAirtime": 40812,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "nonAxATE": 20.732505643340858,
                "nonAxBytes": 372090,
                "axBytes": 367380,
                "axAirtime": 17720,
                "nonAxAirtime": 18012,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "nonAxATE": 20.42081101759755,
                "nonAxBytes": 1965408,
                "axBytes": 427040,
                "axAirtime": 20912,
                "nonAxAirtime": 69848,
                "axATE": 28.138357576451725,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "nonAxATE": 20.623903027643703,
                "nonAxBytes": 7830264,
                "axBytes": 752030,
                "axAirtime": 36464,
                "nonAxAirtime": 246628,
                "axATE": 31.74929042931054,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "nonAxATE": 20.350287047003945,
                "nonAxBytes": 888620,
                "axBytes": 453730,
                "axAirtime": 22296,
                "nonAxAirtime": 43084,
                "axATE": 20.625290130907064,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "nonAxATE": 20.61087628027963,
                "nonAxBytes": 920020,
                "axBytes": 507110,
                "axAirtime": 24604,
                "nonAxAirtime": 44544,
                "axATE": 20.65418462643678,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "nonAxATE": 20.549420849420848,
                "nonAxBytes": 1015790,
                "axBytes": 532230,
                "axAirtime": 25900,
                "nonAxAirtime": 49172,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "nonAxATE": 20.34298780487805,
                "nonAxBytes": 656260,
                "axBytes": 346970,
                "axAirtime": 17056,
                "nonAxAirtime": 31828,
                "axATE": 20.61895186628126,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "nonAxATE": 20.64488188976378,
                "nonAxBytes": 551070,
                "axBytes": 262190,
                "axAirtime": 12700,
                "nonAxAirtime": 26784,
                "axATE": 20.574596774193548,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "nonAxATE": 20.36556603773585,
                "nonAxBytes": 590320,
                "axBytes": 293590,
                "axAirtime": 14416,
                "nonAxAirtime": 28576,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "nonAxATE": 20.67273162556859,
                "nonAxBytes": 4035868,
                "axBytes": 345400,
                "axAirtime": 16708,
                "nonAxAirtime": 88112,
                "axATE": 45.80384056655166,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "nonAxATE": 18.05541035023523,
                "nonAxBytes": 865070,
                "axBytes": 138160,
                "axAirtime": 7652,
                "nonAxAirtime": 41940,
                "axATE": 20.62637100619933,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "nonAxATE": 0,
                "nonAxBytes": 489840,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 23712,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "nonAxATE": 0,
                "nonAxBytes": 535370,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 25916,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "nonAxATE": 0,
                "nonAxBytes": 722200,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 35068,
                "axATE": 20.59427398197787,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "nonAxATE": 19.625,
                "nonAxBytes": 803840,
                "axBytes": 1570,
                "axAirtime": 80,
                "nonAxAirtime": 38972,
                "axATE": 20.62609052653187,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "nonAxATE": 0,
                "nonAxBytes": 1086440,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 52652,
                "axATE": 20.63435387069817,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 299870,
                "axBytes": 106760,
                "axAirtime": 5168,
                "nonAxAirtime": 14516,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "nonAxATE": 20.53545881872062,
                "nonAxBytes": 781704,
                "axBytes": 401920,
                "axAirtime": 19572,
                "nonAxAirtime": 37664,
                "axATE": 20.75467289719626,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "nonAxATE": 20.564231738035264,
                "nonAxBytes": 819540,
                "axBytes": 653120,
                "axAirtime": 31760,
                "nonAxAirtime": 39852,
                "axATE": 20.564588979223124,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 1212680,
                "axBytes": 271610,
                "axAirtime": 13148,
                "nonAxAirtime": 48512,
                "axATE": 24.997526385224276,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "nonAxATE": 20.438854853911405,
                "nonAxBytes": 3009802,
                "axBytes": 346970,
                "axAirtime": 16976,
                "nonAxAirtime": 72472,
                "axATE": 41.53054972955072,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "nonAxATE": 20.388194444444444,
                "nonAxBytes": 2613519,
                "axBytes": 293590,
                "axAirtime": 14400,
                "nonAxAirtime": 61004,
                "axATE": 42.84176447446069,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "nonAxATE": 20.672024623803008,
                "nonAxBytes": 251200,
                "axBytes": 241780,
                "axAirtime": 11696,
                "nonAxAirtime": 9160,
                "axATE": 27.4235807860262,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "nonAxATE": 20.383389830508474,
                "nonAxBytes": 12907946,
                "axBytes": 601310,
                "axAirtime": 29500,
                "nonAxAirtime": 263440,
                "axATE": 48.997669298511994,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "nonAxATE": 20.60669220369536,
                "nonAxBytes": 725340,
                "axBytes": 365810,
                "axAirtime": 17752,
                "nonAxAirtime": 35128,
                "axATE": 20.648485538601687,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "nonAxATE": 20.586754966887415,
                "nonAxBytes": 591890,
                "axBytes": 310860,
                "axAirtime": 15100,
                "nonAxAirtime": 28652,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "nonAxATE": 20.494462025316455,
                "nonAxBytes": 379940,
                "axBytes": 155430,
                "axAirtime": 7584,
                "nonAxAirtime": 18460,
                "axATE": 20.581798483206935,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "nonAxATE": 20.58415705656233,
                "nonAxBytes": 789710,
                "axBytes": 599740,
                "axAirtime": 29136,
                "nonAxAirtime": 38228,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "nonAxATE": 20.718504966021953,
                "nonAxBytes": 409770,
                "axBytes": 158538,
                "axAirtime": 7652,
                "nonAxAirtime": 19836,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "nonAxATE": 20.379595846197024,
                "nonAxBytes": 407182,
                "axBytes": 290450,
                "axAirtime": 14252,
                "nonAxAirtime": 18368,
                "axATE": 22.168009581881535,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "nonAxATE": 20.233130611062844,
                "nonAxBytes": 595030,
                "axBytes": 558920,
                "axAirtime": 27624,
                "nonAxAirtime": 28864,
                "axATE": 20.61495288248337,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "nonAxATE": 20.554485945321524,
                "nonAxBytes": 802248,
                "axBytes": 427040,
                "axAirtime": 20776,
                "nonAxAirtime": 39168,
                "axATE": 20.48223039215686,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "nonAxATE": 20.548703750314623,
                "nonAxBytes": 852800,
                "axBytes": 326560,
                "axAirtime": 15892,
                "nonAxAirtime": 36404,
                "axATE": 23.42599714317108,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "nonAxATE": 20.53683549602344,
                "nonAxBytes": 12814397,
                "axBytes": 588750,
                "axAirtime": 28668,
                "nonAxAirtime": 256604,
                "axATE": 49.938414833751615,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "nonAxATE": 20.610473457675752,
                "nonAxBytes": 516530,
                "axBytes": 287310,
                "axAirtime": 13940,
                "nonAxAirtime": 25004,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "nonAxATE": 38.30442235173123,
                "nonAxBytes": 949850,
                "axBytes": 893872,
                "axAirtime": 23336,
                "nonAxAirtime": 46004,
                "axATE": 20.647117641944178,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "nonAxATE": 27.279368167500465,
                "nonAxBytes": 1147038,
                "axBytes": 588907,
                "axAirtime": 21588,
                "nonAxAirtime": 52856,
                "axATE": 21.701188133797487,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "nonAxATE": 20.544669772540423,
                "nonAxBytes": 1328691,
                "axBytes": 299870,
                "axAirtime": 14596,
                "nonAxAirtime": 41404,
                "axATE": 32.09088493865327,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "nonAxATE": 20.369603139991277,
                "nonAxBytes": 320280,
                "axBytes": 186830,
                "axAirtime": 9172,
                "nonAxAirtime": 15504,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "nonAxATE": 20.459946339593714,
                "nonAxBytes": 4840120,
                "axBytes": 427040,
                "axAirtime": 20872,
                "nonAxAirtime": 130984,
                "axATE": 36.95199413668845,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "nonAxATE": 20.44539286495951,
                "nonAxBytes": 748890,
                "axBytes": 373660,
                "axAirtime": 18276,
                "nonAxAirtime": 36252,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "nonAxATE": 21.167180277349768,
                "nonAxBytes": 219800,
                "axBytes": 54950,
                "axAirtime": 2596,
                "nonAxAirtime": 10652,
                "axATE": 20.634622606083365,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "nonAxATE": 20.527444948186528,
                "nonAxBytes": 995380,
                "axBytes": 507110,
                "axAirtime": 24704,
                "nonAxAirtime": 48184,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "nonAxATE": 20.596699111299195,
                "nonAxBytes": 12672242,
                "axBytes": 584040,
                "axAirtime": 28356,
                "nonAxAirtime": 264400,
                "axATE": 47.92829803328291,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "nonAxATE": 20.675128113454893,
                "nonAxBytes": 13162410,
                "axBytes": 693940,
                "axAirtime": 33564,
                "nonAxAirtime": 260028,
                "axATE": 50.61920254741797,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "nonAxATE": 20.457497533706018,
                "nonAxBytes": 11666319,
                "axBytes": 497690,
                "axAirtime": 24328,
                "nonAxAirtime": 225776,
                "axATE": 51.6720953511445,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "nonAxATE": 20.473918575063614,
                "nonAxBytes": 629570,
                "axBytes": 321850,
                "axAirtime": 15720,
                "nonAxAirtime": 30476,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "nonAxATE": 20.301724137931036,
                "nonAxBytes": 748890,
                "axBytes": 400350,
                "axAirtime": 19720,
                "nonAxAirtime": 36252,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "nonAxATE": 20.50576629151035,
                "nonAxBytes": 12588620,
                "axBytes": 590320,
                "axAirtime": 28788,
                "nonAxAirtime": 275808,
                "axATE": 45.64269346791971,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "nonAxATE": 20.431977252843396,
                "nonAxBytes": 744180,
                "axBytes": 373660,
                "axAirtime": 18288,
                "nonAxAirtime": 36084,
                "axATE": 20.623545061523114,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "nonAxATE": 20.015784361340458,
                "nonAxBytes": 427040,
                "axBytes": 164850,
                "axAirtime": 8236,
                "nonAxAirtime": 20672,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "nonAxATE": 20.71549492133041,
                "nonAxBytes": 4164306,
                "axBytes": 416050,
                "axAirtime": 20084,
                "nonAxAirtime": 96612,
                "axATE": 43.1034033039374,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "nonAxATE": 20.510560965573994,
                "nonAxBytes": 1205760,
                "axBytes": 598170,
                "axAirtime": 29164,
                "nonAxAirtime": 58436,
                "axATE": 20.633855842288998,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "nonAxATE": 20.55236270753512,
                "nonAxBytes": 753600,
                "axBytes": 386220,
                "axAirtime": 18792,
                "nonAxAirtime": 36480,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 507110,
                "axBytes": 53380,
                "axAirtime": 2584,
                "nonAxAirtime": 24548,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "nonAxATE": 20.429407937540663,
                "nonAxBytes": 717490,
                "axBytes": 376800,
                "axAirtime": 18444,
                "nonAxAirtime": 34732,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "nonAxATE": 20.532960387057756,
                "nonAxBytes": 1046338,
                "axBytes": 543220,
                "axAirtime": 26456,
                "nonAxAirtime": 46592,
                "axATE": 22.45746050824176,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "nonAxATE": 20.465148188803514,
                "nonAxBytes": 5025594,
                "axBytes": 298300,
                "axAirtime": 14576,
                "nonAxAirtime": 123860,
                "axATE": 40.574794122396256,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "nonAxATE": 20.537790697674417,
                "nonAxBytes": 481990,
                "axBytes": 240210,
                "axAirtime": 11696,
                "nonAxAirtime": 23332,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "nonAxATE": 20.478840924541128,
                "nonAxBytes": 934150,
                "axBytes": 481990,
                "axAirtime": 23536,
                "nonAxAirtime": 45220,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "nonAxATE": 20.743914157591288,
                "nonAxBytes": 1520553,
                "axBytes": 518100,
                "axAirtime": 24976,
                "nonAxAirtime": 46800,
                "axATE": 32.490448717948716,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "nonAxATE": 20.722049689440993,
                "nonAxBytes": 909030,
                "axBytes": 453730,
                "axAirtime": 21896,
                "nonAxAirtime": 44004,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "nonAxATE": 19.74112426035503,
                "nonAxBytes": 378370,
                "axBytes": 106760,
                "axAirtime": 5408,
                "nonAxAirtime": 18360,
                "axATE": 20.60838779956427,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "nonAxATE": 19.74112426035503,
                "nonAxBytes": 656260,
                "axBytes": 106760,
                "axAirtime": 5408,
                "nonAxAirtime": 31768,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 587180,
                "axBytes": 1570,
                "axAirtime": 76,
                "nonAxAirtime": 28484,
                "axATE": 20.614380002808595,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 11178203,
                "axBytes": 1570,
                "axAirtime": 76,
                "nonAxAirtime": 226852,
                "axATE": 49.27531165693932,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "nonAxATE": 0,
                "nonAxBytes": 71934538,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 1402492,
                "axATE": 51.29051573912721,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "nonAxATE": 0,
                "nonAxBytes": 158570,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 7676,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "nonAxATE": 0,
                "nonAxBytes": 54950,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2660,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "nonAxATE": 0,
                "nonAxBytes": 20410,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 988,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 1949238,
                "axBytes": 266900,
                "axAirtime": 12920,
                "nonAxAirtime": 70288,
                "axATE": 27.73215911677669,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "nonAxATE": 20.41989198919892,
                "nonAxBytes": 890190,
                "axBytes": 453730,
                "axAirtime": 22220,
                "nonAxAirtime": 43092,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "nonAxATE": 20.328685258964143,
                "nonAxBytes": 3076198,
                "axBytes": 346970,
                "axAirtime": 17068,
                "nonAxAirtime": 72456,
                "axATE": 42.4560836921718,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "nonAxATE": 20.460211533618736,
                "nonAxBytes": 565200,
                "axBytes": 324990,
                "axAirtime": 15884,
                "nonAxAirtime": 27360,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "nonAxATE": 20.604734945959855,
                "nonAxBytes": 923160,
                "axBytes": 480420,
                "axAirtime": 23316,
                "nonAxAirtime": 44688,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "nonAxATE": 20.37611970684039,
                "nonAxBytes": 750460,
                "axBytes": 400350,
                "axAirtime": 19648,
                "nonAxAirtime": 36328,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "nonAxATE": 20.530330548492554,
                "nonAxBytes": 10128238,
                "axBytes": 452160,
                "axAirtime": 22024,
                "nonAxAirtime": 225588,
                "axATE": 44.89706012731174,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "nonAxATE": 20.615838762214985,
                "nonAxBytes": 1493302,
                "axBytes": 405060,
                "axAirtime": 19648,
                "nonAxAirtime": 50436,
                "axATE": 29.60785946546118,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "nonAxATE": 20.665145665145666,
                "nonAxBytes": 843090,
                "axBytes": 471000,
                "axAirtime": 22792,
                "nonAxAirtime": 40812,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "nonAxATE": 20.539331532748143,
                "nonAxBytes": 962410,
                "axBytes": 486700,
                "axAirtime": 23696,
                "nonAxAirtime": 46588,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "nonAxATE": 20.68635893902859,
                "nonAxBytes": 2014158,
                "axBytes": 240210,
                "axAirtime": 11612,
                "nonAxAirtime": 49280,
                "axATE": 40.871712662337664,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "nonAxATE": 20.54973821989529,
                "nonAxBytes": 533800,
                "axBytes": 266900,
                "axAirtime": 12988,
                "nonAxAirtime": 25908,
                "axATE": 20.603674540682416,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "nonAxATE": 20.4928802963649,
                "nonAxBytes": 13542630,
                "axBytes": 708070,
                "axAirtime": 34552,
                "nonAxAirtime": 260972,
                "axATE": 51.893038333614335,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "nonAxATE": 20.645909882034424,
                "nonAxBytes": 802270,
                "axBytes": 427040,
                "axAirtime": 20684,
                "nonAxAirtime": 38836,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "nonAxATE": 20.41960922787194,
                "nonAxBytes": 1609533,
                "axBytes": 346970,
                "axAirtime": 16992,
                "nonAxAirtime": 55652,
                "axATE": 28.921386473082727,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "nonAxATE": 20.28910205169198,
                "nonAxBytes": 577760,
                "axBytes": 304580,
                "axAirtime": 15012,
                "nonAxAirtime": 27968,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "nonAxATE": 20.33912943344081,
                "nonAxBytes": 5849292,
                "axBytes": 353250,
                "axAirtime": 17368,
                "nonAxAirtime": 195412,
                "axATE": 29.933125908337257,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "nonAxATE": 20.068575817423742,
                "nonAxBytes": 745750,
                "axBytes": 365810,
                "axAirtime": 18228,
                "nonAxAirtime": 36100,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "nonAxATE": 20.493985154850268,
                "nonAxBytes": 5708122,
                "axBytes": 640560,
                "axAirtime": 31256,
                "nonAxAirtime": 117432,
                "axATE": 48.60789222699094,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 972782,
                "axBytes": 320280,
                "axAirtime": 15504,
                "nonAxAirtime": 46528,
                "axATE": 20.907453576341126,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "nonAxATE": 20.580980452994105,
                "nonAxBytes": 1231590,
                "axBytes": 265330,
                "axAirtime": 12892,
                "nonAxAirtime": 55128,
                "axATE": 22.34055289508054,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "nonAxATE": 20.520385522118985,
                "nonAxBytes": 1150810,
                "axBytes": 591890,
                "axAirtime": 28844,
                "nonAxAirtime": 55708,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "nonAxATE": 20.60625,
                "nonAxBytes": 5209789,
                "axBytes": 560490,
                "axAirtime": 27200,
                "nonAxAirtime": 125376,
                "axATE": 41.55331961459928,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "nonAxATE": 20.42723315669947,
                "nonAxBytes": 6510130,
                "axBytes": 431750,
                "axAirtime": 21136,
                "nonAxAirtime": 136868,
                "axATE": 47.56502615658883,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "nonAxATE": 20.653042048390887,
                "nonAxBytes": 646840,
                "axBytes": 351680,
                "axAirtime": 17028,
                "nonAxAirtime": 31364,
                "axATE": 20.623644943247037,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "nonAxATE": 20.601567825494207,
                "nonAxBytes": 800700,
                "axBytes": 362670,
                "axAirtime": 17604,
                "nonAxAirtime": 38760,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "nonAxATE": 20.816080544526375,
                "nonAxBytes": 574620,
                "axBytes": 293590,
                "axAirtime": 14104,
                "nonAxAirtime": 27816,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "nonAxATE": 20.7183147632312,
                "nonAxBytes": 4041084,
                "axBytes": 595030,
                "axAirtime": 28720,
                "nonAxAirtime": 173756,
                "axATE": 23.25723428255715,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "nonAxATE": 20.602537831021436,
                "nonAxBytes": 1031490,
                "axBytes": 522810,
                "axAirtime": 25376,
                "nonAxAirtime": 49932,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "nonAxATE": 20.678824721377914,
                "nonAxBytes": 649980,
                "axBytes": 326560,
                "axAirtime": 15792,
                "nonAxAirtime": 31464,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "nonAxATE": 20.775708429869898,
                "nonAxBytes": 381510,
                "axBytes": 466290,
                "axAirtime": 22444,
                "nonAxAirtime": 18468,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "nonAxATE": 20.39154342055366,
                "nonAxBytes": 390706,
                "axBytes": 215090,
                "axAirtime": 10548,
                "nonAxAirtime": 18996,
                "axATE": 20.567803748157505,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "nonAxATE": 20.568742293464858,
                "nonAxBytes": 4134653,
                "axBytes": 400350,
                "axAirtime": 19464,
                "nonAxAirtime": 113176,
                "axATE": 36.5329486816993,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "nonAxATE": 30.031677118507798,
                "nonAxBytes": 805410,
                "axBytes": 747068,
                "axAirtime": 24876,
                "nonAxAirtime": 38988,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "nonAxATE": 20.469682689286838,
                "nonAxBytes": 588750,
                "axBytes": 260620,
                "axAirtime": 12732,
                "nonAxAirtime": 28500,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "nonAxATE": 20.578531073446328,
                "nonAxBytes": 747320,
                "axBytes": 364240,
                "axAirtime": 17700,
                "nonAxAirtime": 36176,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "nonAxATE": 20.521748681898067,
                "nonAxBytes": 373660,
                "axBytes": 186830,
                "axAirtime": 9104,
                "nonAxAirtime": 18088,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "nonAxATE": 20.394803871625065,
                "nonAxBytes": 320280,
                "axBytes": 320280,
                "axAirtime": 15704,
                "nonAxAirtime": 15504,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "nonAxATE": 20.583379578246394,
                "nonAxBytes": 2292155,
                "axBytes": 296730,
                "axAirtime": 14416,
                "nonAxAirtime": 47620,
                "axATE": 48.13429231415372,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "nonAxATE": 20.68208430913349,
                "nonAxBytes": 496120,
                "axBytes": 565200,
                "axAirtime": 27328,
                "nonAxAirtime": 24016,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "nonAxATE": 20.704692556634303,
                "nonAxBytes": 467860,
                "axBytes": 255910,
                "axAirtime": 12360,
                "nonAxAirtime": 22664,
                "axATE": 20.64331097776209,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "nonAxATE": 20.51572996886777,
                "nonAxBytes": 6315483,
                "axBytes": 500830,
                "axAirtime": 24412,
                "nonAxAirtime": 148636,
                "axATE": 42.48959202346673,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "nonAxATE": 20.666959192628347,
                "nonAxBytes": 392500,
                "axBytes": 188400,
                "axAirtime": 9116,
                "nonAxAirtime": 19000,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 238640,
                "axBytes": 53380,
                "axAirtime": 2584,
                "nonAxAirtime": 11552,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "nonAxATE": 0,
                "nonAxBytes": 251200,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 12176,
                "axATE": 20.630749014454665,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "nonAxATE": 0,
                "nonAxBytes": 877630,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 42484,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "nonAxATE": 0,
                "nonAxBytes": 345400,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 16720,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "nonAxATE": 20.368616144975288,
                "nonAxBytes": 295160,
                "axBytes": 296730,
                "axAirtime": 14568,
                "nonAxAirtime": 14288,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "nonAxATE": 20.517241379310345,
                "nonAxBytes": 1283841,
                "axBytes": 373660,
                "axAirtime": 18212,
                "nonAxAirtime": 33180,
                "axATE": 38.69321880650995,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "nonAxATE": 20.409099598547122,
                "nonAxBytes": 825820,
                "axBytes": 427040,
                "axAirtime": 20924,
                "nonAxAirtime": 39976,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "nonAxATE": 20.56378132118451,
                "nonAxBytes": 11678226,
                "axBytes": 613870,
                "axAirtime": 29852,
                "nonAxAirtime": 242520,
                "axATE": 48.15366155368629,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "nonAxATE": 20.603354558161204,
                "nonAxBytes": 1232450,
                "axBytes": 530660,
                "axAirtime": 25756,
                "nonAxAirtime": 59660,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "nonAxATE": 20.55160411622276,
                "nonAxBytes": 540080,
                "axBytes": 271610,
                "axAirtime": 13216,
                "nonAxAirtime": 26144,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "nonAxATE": 20.673721509289408,
                "nonAxBytes": 4915506,
                "axBytes": 431750,
                "axAirtime": 20884,
                "nonAxAirtime": 109092,
                "axATE": 45.058354416455835,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 1418902,
                "axBytes": 351680,
                "axAirtime": 17024,
                "nonAxAirtime": 57364,
                "axATE": 24.735060316574856,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "nonAxATE": 20.61629468031549,
                "nonAxBytes": 3756094,
                "axBytes": 491410,
                "axAirtime": 23836,
                "nonAxAirtime": 117560,
                "axATE": 31.950442327322218,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "nonAxATE": 20.52234980520812,
                "nonAxBytes": 800700,
                "axBytes": 400350,
                "axAirtime": 19508,
                "nonAxAirtime": 38760,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "nonAxATE": 20.675352112676055,
                "nonAxBytes": 587180,
                "axBytes": 293590,
                "axAirtime": 14200,
                "nonAxAirtime": 28424,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "nonAxATE": 20.748898678414097,
                "nonAxBytes": 640560,
                "axBytes": 320280,
                "axAirtime": 15436,
                "nonAxAirtime": 31008,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "nonAxATE": 20.310749533208856,
                "nonAxBytes": 983584,
                "axBytes": 304580,
                "axAirtime": 14996,
                "nonAxAirtime": 46648,
                "axATE": 21.085234093637457,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "nonAxATE": 20.53169148529973,
                "nonAxBytes": 810706,
                "axBytes": 215090,
                "axAirtime": 10476,
                "nonAxAirtime": 38072,
                "axATE": 21.29402185333053,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "nonAxATE": 20.508232711306256,
                "nonAxBytes": 747320,
                "axBytes": 373660,
                "axAirtime": 18220,
                "nonAxAirtime": 36244,
                "axATE": 20.619136960600375,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "nonAxATE": 20.61173184357542,
                "nonAxBytes": 872920,
                "axBytes": 442740,
                "axAirtime": 21480,
                "nonAxAirtime": 42256,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "nonAxATE": 20.406416434187168,
                "nonAxBytes": 9269983,
                "axBytes": 643700,
                "axAirtime": 31544,
                "nonAxAirtime": 201784,
                "axATE": 45.94012904888395,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "nonAxATE": 20.683301797540206,
                "nonAxBytes": 1430270,
                "axBytes": 874490,
                "axAirtime": 42280,
                "nonAxAirtime": 69372,
                "axATE": 20.617396067577697,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "nonAxATE": 20.768252353090816,
                "nonAxBytes": 109900,
                "axBytes": 326560,
                "axAirtime": 15724,
                "nonAxAirtime": 5320,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "nonAxATE": 20.768252353090816,
                "nonAxBytes": 67510,
                "axBytes": 326560,
                "axAirtime": 15724,
                "nonAxAirtime": 3288,
                "axATE": 20.532238442822383,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "nonAxATE": 0,
                "nonAxBytes": 158570,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 7680,
                "axATE": 20.647135416666668,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "nonAxATE": 0,
                "nonAxBytes": 138160,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 6688,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "nonAxATE": 20.50514386754541,
                "nonAxBytes": 967120,
                "axBytes": 510250,
                "axAirtime": 24884,
                "nonAxAirtime": 46876,
                "axATE": 20.63145319566516,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "nonAxATE": 20.583818915284624,
                "nonAxBytes": 560490,
                "axBytes": 367380,
                "axAirtime": 17848,
                "nonAxAirtime": 27284,
                "axATE": 20.542808972291454,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "nonAxATE": 20.54973821989529,
                "nonAxBytes": 899624,
                "axBytes": 266900,
                "axAirtime": 12988,
                "nonAxAirtime": 41552,
                "axATE": 21.650558336542165,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "nonAxATE": 20.51273605621432,
                "nonAxBytes": 2504813,
                "axBytes": 373660,
                "axAirtime": 18216,
                "nonAxAirtime": 72716,
                "axATE": 34.44651796028384,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "nonAxATE": 20.46163215590743,
                "nonAxBytes": 1681181,
                "axBytes": 1007940,
                "axAirtime": 49260,
                "nonAxAirtime": 75940,
                "axATE": 22.138280221227284,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "nonAxATE": 31.786354302781408,
                "nonAxBytes": 1764032,
                "axBytes": 1362236,
                "axAirtime": 42856,
                "nonAxAirtime": 56904,
                "axATE": 31.000140587656404,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "nonAxATE": 20.2319587628866,
                "nonAxBytes": 438030,
                "axBytes": 133450,
                "axAirtime": 6596,
                "nonAxAirtime": 21204,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "nonAxATE": 20.38961038961039,
                "nonAxBytes": 1017672,
                "axBytes": 213520,
                "axAirtime": 10472,
                "nonAxAirtime": 41784,
                "axATE": 24.35554279149914,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "nonAxATE": 20.403026813620663,
                "nonAxBytes": 915310,
                "axBytes": 496120,
                "axAirtime": 24316,
                "nonAxAirtime": 44308,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "nonAxATE": 20.607054963084497,
                "nonAxBytes": 1298390,
                "axBytes": 703360,
                "axAirtime": 34132,
                "nonAxAirtime": 62852,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "nonAxATE": 20.581946594427244,
                "nonAxBytes": 1020500,
                "axBytes": 850940,
                "axAirtime": 41344,
                "nonAxAirtime": 49400,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "nonAxATE": 20.6858826717247,
                "nonAxBytes": 13617187,
                "axBytes": 610730,
                "axAirtime": 29524,
                "nonAxAirtime": 302484,
                "axATE": 45.01787532563706,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "nonAxATE": 20.636457973019716,
                "nonAxBytes": 828960,
                "axBytes": 715920,
                "axAirtime": 34692,
                "nonAxAirtime": 40200,
                "axATE": 20.62089552238806,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "nonAxATE": 20.271978021978022,
                "nonAxBytes": 5732888,
                "axBytes": 295160,
                "axAirtime": 14560,
                "nonAxAirtime": 211888,
                "axATE": 27.056218379521255,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "nonAxATE": 20.721798308929674,
                "nonAxBytes": 3478638,
                "axBytes": 401920,
                "axAirtime": 19396,
                "nonAxAirtime": 105044,
                "axATE": 33.11600852975896,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "nonAxATE": 20.368326871458397,
                "nonAxBytes": 10712750,
                "axBytes": 546360,
                "axAirtime": 26824,
                "nonAxAirtime": 252936,
                "axATE": 42.35359932947465,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "nonAxATE": 20.463008937437934,
                "nonAxBytes": 653120,
                "axBytes": 329700,
                "axAirtime": 16112,
                "nonAxAirtime": 31680,
                "axATE": 20.616161616161616,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "nonAxATE": 0,
                "nonAxBytes": 492980,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 23864,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "nonAxATE": 0,
                "nonAxBytes": 1240705,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 38140,
                "axATE": 32.53028316727845,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "nonAxATE": 20.669109663409337,
                "nonAxBytes": 2893581,
                "axBytes": 456870,
                "axAirtime": 22104,
                "nonAxAirtime": 81304,
                "axATE": 35.589651185673524,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 386220,
                "axBytes": 200960,
                "axAirtime": 9728,
                "nonAxAirtime": 18724,
                "axATE": 20.627002777184362,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "nonAxATE": 20.490369994931577,
                "nonAxBytes": 2295035,
                "axBytes": 323420,
                "axAirtime": 15784,
                "nonAxAirtime": 70792,
                "axATE": 32.41941179794327,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "nonAxATE": 20.452853598014887,
                "nonAxBytes": 1122550,
                "axBytes": 560490,
                "axAirtime": 27404,
                "nonAxAirtime": 54340,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 913740,
                "axBytes": 480420,
                "axAirtime": 23256,
                "nonAxAirtime": 44312,
                "axATE": 20.620599386170788,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "nonAxATE": 20.599653003686836,
                "nonAxBytes": 3428401,
                "axBytes": 379940,
                "axAirtime": 18444,
                "nonAxAirtime": 83820,
                "axATE": 40.90194464328322,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "nonAxATE": 20.551368504561683,
                "nonAxBytes": 910600,
                "axBytes": 621720,
                "axAirtime": 30252,
                "nonAxAirtime": 44080,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "nonAxATE": 20.50710718401844,
                "nonAxBytes": 3576618,
                "axBytes": 427040,
                "axAirtime": 20824,
                "nonAxAirtime": 86968,
                "axATE": 41.12567841044982,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "nonAxATE": 20.574596774193548,
                "nonAxBytes": 533800,
                "axBytes": 285740,
                "axAirtime": 13888,
                "nonAxAirtime": 25840,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "nonAxATE": 20.39803049555273,
                "nonAxBytes": 5374350,
                "axBytes": 642130,
                "axAirtime": 31480,
                "nonAxAirtime": 131344,
                "axATE": 40.91812340114508,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "nonAxATE": 20.455839416058396,
                "nonAxBytes": 1080160,
                "axBytes": 560490,
                "axAirtime": 27400,
                "nonAxAirtime": 52288,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 372090,
                "axBytes": 367380,
                "axAirtime": 17784,
                "nonAxAirtime": 18012,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "nonAxATE": 20.686864555334814,
                "nonAxBytes": 450590,
                "axBytes": 708070,
                "axAirtime": 34228,
                "nonAxAirtime": 21812,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "nonAxATE": 20.390386959171238,
                "nonAxBytes": 5397867,
                "axBytes": 535370,
                "axAirtime": 26256,
                "nonAxAirtime": 123320,
                "axATE": 43.77122121310412,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "nonAxATE": 20.424978317432785,
                "nonAxBytes": 956130,
                "axBytes": 565200,
                "axAirtime": 27672,
                "nonAxAirtime": 46284,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "nonAxATE": 20.59016393442623,
                "nonAxBytes": 858790,
                "axBytes": 427040,
                "axAirtime": 20740,
                "nonAxAirtime": 41572,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "nonAxATE": 20.602397189501964,
                "nonAxBytes": 741040,
                "axBytes": 398780,
                "axAirtime": 19356,
                "nonAxAirtime": 35872,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "nonAxATE": 20.7273163706193,
                "nonAxBytes": 965550,
                "axBytes": 518100,
                "axAirtime": 24996,
                "nonAxAirtime": 46740,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "nonAxATE": 20.505394662123795,
                "nonAxBytes": 1017360,
                "axBytes": 288880,
                "axAirtime": 14088,
                "nonAxAirtime": 49248,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "nonAxATE": 20.56245105716523,
                "nonAxBytes": 15751767,
                "axBytes": 1050330,
                "axAirtime": 51080,
                "nonAxAirtime": 316496,
                "axATE": 49.769245108942926,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "nonAxATE": 20.56367110453649,
                "nonAxBytes": 15575957,
                "axBytes": 667250,
                "axAirtime": 32448,
                "nonAxAirtime": 318412,
                "axATE": 48.91761931082999,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 22254282,
                "axBytes": 852510,
                "axAirtime": 41268,
                "nonAxAirtime": 447748,
                "axATE": 49.702694372727514,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "nonAxATE": 20.48257493680029,
                "nonAxBytes": 3919476,
                "axBytes": 453730,
                "axAirtime": 22152,
                "nonAxAirtime": 91904,
                "axATE": 42.64750174094708,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "nonAxATE": 20.79650624710782,
                "nonAxBytes": 703204,
                "axBytes": 359530,
                "axAirtime": 17288,
                "nonAxAirtime": 33864,
                "axATE": 20.76553271911174,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "nonAxATE": 20.60943702634428,
                "nonAxBytes": 778720,
                "axBytes": 456870,
                "axAirtime": 22168,
                "nonAxAirtime": 37696,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "nonAxATE": 20.655135568318418,
                "nonAxBytes": 4721157,
                "axBytes": 618580,
                "axAirtime": 29948,
                "nonAxAirtime": 94412,
                "axATE": 50.00589967377028,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "nonAxATE": 20.504411423264454,
                "nonAxBytes": 354820,
                "axBytes": 353250,
                "axAirtime": 17228,
                "nonAxAirtime": 17176,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "nonAxATE": 20.665395787944806,
                "nonAxBytes": 453730,
                "axBytes": 455300,
                "axAirtime": 22032,
                "nonAxAirtime": 21964,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "nonAxATE": 20.60910247093023,
                "nonAxBytes": 777150,
                "axBytes": 453730,
                "axAirtime": 22016,
                "nonAxAirtime": 37620,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "nonAxATE": 20.348026315789475,
                "nonAxBytes": 555780,
                "axBytes": 309290,
                "axAirtime": 15200,
                "nonAxAirtime": 26904,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 1056074,
                "axBytes": 240210,
                "axAirtime": 11628,
                "nonAxAirtime": 42840,
                "axATE": 24.651587301587302,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "nonAxATE": 20.499231950844855,
                "nonAxBytes": 1320734,
                "axBytes": 160140,
                "axAirtime": 7812,
                "nonAxAirtime": 39292,
                "axATE": 33.61330550748244,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "nonAxATE": 20.82264216366158,
                "nonAxBytes": 480420,
                "axBytes": 240210,
                "axAirtime": 11536,
                "nonAxAirtime": 23256,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "nonAxATE": 20.57502005883926,
                "nonAxBytes": 651550,
                "axBytes": 307720,
                "axAirtime": 14956,
                "nonAxAirtime": 31540,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "nonAxATE": 20.760893128116194,
                "nonAxBytes": 343830,
                "axBytes": 383080,
                "axAirtime": 18452,
                "nonAxAirtime": 16644,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "nonAxATE": 20.69335458558117,
                "nonAxBytes": 475710,
                "axBytes": 337550,
                "axAirtime": 16312,
                "nonAxAirtime": 23028,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 292020,
                "axBytes": 160140,
                "axAirtime": 7752,
                "nonAxAirtime": 14136,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "nonAxATE": 20.350970285021226,
                "nonAxBytes": 1716229,
                "axBytes": 268470,
                "axAirtime": 13192,
                "nonAxAirtime": 43428,
                "axATE": 39.51895090724878,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "nonAxATE": 20.7251356238698,
                "nonAxBytes": 279460,
                "axBytes": 229220,
                "axAirtime": 11060,
                "nonAxAirtime": 13584,
                "axATE": 20.57273262661955,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "nonAxATE": 20.521667451719264,
                "nonAxBytes": 384650,
                "axBytes": 174270,
                "axAirtime": 8492,
                "nonAxAirtime": 18620,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "nonAxATE": 20.301724137931036,
                "nonAxBytes": 160140,
                "axBytes": 80070,
                "axAirtime": 3944,
                "nonAxAirtime": 7752,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "nonAxATE": 20.52234980520812,
                "nonAxBytes": 3601166,
                "axBytes": 400350,
                "axAirtime": 19508,
                "nonAxAirtime": 83728,
                "axATE": 43.01029524173514,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "nonAxATE": 20.669187317784257,
                "nonAxBytes": 1992428,
                "axBytes": 453730,
                "axAirtime": 21952,
                "nonAxAirtime": 54720,
                "axATE": 36.41133040935672,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "nonAxATE": 20.535077288941736,
                "nonAxBytes": 210380,
                "axBytes": 138160,
                "axAirtime": 6728,
                "nonAxAirtime": 10184,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "nonAxATE": 20.493362831858406,
                "nonAxBytes": 5347475,
                "axBytes": 463150,
                "axAirtime": 22600,
                "nonAxAirtime": 106084,
                "axATE": 50.407931450548624,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "nonAxATE": 20.72134766749856,
                "nonAxBytes": 430180,
                "axBytes": 431750,
                "axAirtime": 20836,
                "nonAxAirtime": 20824,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 240210,
                "axBytes": 241780,
                "axAirtime": 11704,
                "nonAxAirtime": 11628,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 270040,
                "axBytes": 273180,
                "axAirtime": 13224,
                "nonAxAirtime": 13072,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 406630,
                "axBytes": 400350,
                "axAirtime": 19380,
                "nonAxAirtime": 19684,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "nonAxATE": 20.560874277456648,
                "nonAxBytes": 301440,
                "axBytes": 455300,
                "axAirtime": 22144,
                "nonAxAirtime": 14592,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "nonAxATE": 20.2565270188221,
                "nonAxBytes": 492980,
                "axBytes": 266900,
                "axAirtime": 13176,
                "nonAxAirtime": 23932,
                "axATE": 20.599197726892864,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "nonAxATE": 20.4979505085775,
                "nonAxBytes": 835240,
                "axBytes": 540080,
                "axAirtime": 26348,
                "nonAxAirtime": 40432,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "nonAxATE": 20.788681284743667,
                "nonAxBytes": 1136680,
                "axBytes": 538510,
                "axAirtime": 25904,
                "nonAxAirtime": 55360,
                "axATE": 20.53251445086705,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "nonAxATE": 20.541643218908273,
                "nonAxBytes": 3050150,
                "axBytes": 438030,
                "axAirtime": 21324,
                "nonAxAirtime": 81904,
                "axATE": 37.240549912092206,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "nonAxATE": 20.73136195995785,
                "nonAxBytes": 11893895,
                "axBytes": 629570,
                "axAirtime": 30368,
                "nonAxAirtime": 242932,
                "axATE": 48.95977063540415,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 1381878,
                "axBytes": 346970,
                "axAirtime": 16796,
                "nonAxAirtime": 45156,
                "axATE": 30.602311985118256,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "nonAxATE": 20.447983870967743,
                "nonAxBytes": 467860,
                "axBytes": 507110,
                "axAirtime": 24800,
                "nonAxAirtime": 22648,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 306150,
                "axBytes": 320280,
                "axAirtime": 15504,
                "nonAxAirtime": 14820,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "nonAxATE": 20.657894736842106,
                "nonAxBytes": 397210,
                "axBytes": 400350,
                "axAirtime": 19380,
                "nonAxAirtime": 19228,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "nonAxATE": 20.554485945321524,
                "nonAxBytes": 213520,
                "axBytes": 213520,
                "axAirtime": 10388,
                "nonAxAirtime": 10336,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "nonAxATE": 20.479989868287742,
                "nonAxBytes": 332840,
                "axBytes": 323420,
                "axAirtime": 15792,
                "nonAxAirtime": 16112,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "nonAxATE": 20.382400484946455,
                "nonAxBytes": 383080,
                "axBytes": 403490,
                "axAirtime": 19796,
                "nonAxAirtime": 18544,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "nonAxATE": 20.49477840040754,
                "nonAxBytes": 699776,
                "axBytes": 321850,
                "axAirtime": 15704,
                "nonAxAirtime": 34060,
                "axATE": 20.54539048737522,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "nonAxATE": 20.5505012996658,
                "nonAxBytes": 2167980,
                "axBytes": 221370,
                "axAirtime": 10772,
                "nonAxAirtime": 92668,
                "axATE": 23.395131005309278,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "nonAxATE": 20.852139163140574,
                "nonAxBytes": 177410,
                "axBytes": 177410,
                "axAirtime": 8508,
                "nonAxAirtime": 8636,
                "axATE": 20.5430754979157,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "nonAxATE": 20.67054500918555,
                "nonAxBytes": 1456574,
                "axBytes": 135020,
                "axAirtime": 6532,
                "nonAxAirtime": 43264,
                "axATE": 33.667113535502956,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "nonAxATE": 20.73039129671872,
                "nonAxBytes": 480420,
                "axBytes": 472570,
                "axAirtime": 22796,
                "nonAxAirtime": 23312,
                "axATE": 20.608270418668496,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "nonAxATE": 20.505277044854882,
                "nonAxBytes": 464720,
                "axBytes": 466290,
                "axAirtime": 22740,
                "nonAxAirtime": 22496,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "nonAxATE": 20.71035553047404,
                "nonAxBytes": 295160,
                "axBytes": 293590,
                "axAirtime": 14176,
                "nonAxAirtime": 14288,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "nonAxATE": 20.838110667996013,
                "nonAxBytes": 679810,
                "axBytes": 334410,
                "axAirtime": 16048,
                "nonAxAirtime": 32908,
                "axATE": 20.657894736842106,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "nonAxATE": 20.62748417488591,
                "nonAxBytes": 1301150,
                "axBytes": 560490,
                "axAirtime": 27172,
                "nonAxAirtime": 62980,
                "axATE": 20.659733248650365,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": -0.16
    }
})