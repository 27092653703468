define({
    "User_Template":
     {
  "bookID": {
    "namespace": "ndp",
    "name": "core",
    "version": "1.0.0"
  },
  "requestString": "hugh.jackman",
  "result": {
    "templateName": "specific_user",
    "outputTokens": [
      {
        "value": "hugh.jackman",
        "propMap": {},
        "tag": "user",
        "type": "string",
        "instance": true,
        "name": "userid",
        "primary": true,
        "keyNames": [
          "userId"
        ],
        "altEntityKeys": [],
        "membershipResult": {
          "altKeys": [],
          "key": [
            "hugh.jackman"
          ],
          "keyNames": [
            "userId"
          ],
          "resourceTag": "User",
          "resourceName": "R1"
        },
        "tokenID": 7224,
        "traversalSourceTokenID": 0
      }
    ],
    "when": {
      "moment": {
        "value": "2018-01-17T13:53:16.151Z[Etc/UTC]",
        "logicalValue": "now",
        "propMap": {},
        "tag": "zoned-date-time",
        "type": "string",
        "instance": true,
        "name": "start",
        "tokenID": 7222,
        "traversalSourceTokenID": 0
      }
    },
    "others": [],
    "properties": {}
  },
  "processingTime": 363,
  "inputTokens": {
    "tokens": [
      {
        "value": "hugh.jackman",
        "propMap": {},
        "tag": "user",
        "type": "string",
        "instance": true,
        "keyNames": [
          "userId"
        ],
        "altEntityKeys": [],
        "membershipResult": {
          "altKeys": [],
          "key": [
            "hugh.jackman"
          ],
          "keyNames": [
            "userId"
          ],
          "resourceTag": "User",
          "resourceName": "R1"
        },
        "tokenID": 7199,
        "traversalSourceTokenID": 0
      }
    ]
  },
  "rankedInputTokens": {
    "tokens": [
      {
        "value": "hugh.jackman",
        "propMap": {},
        "tag": "user",
        "type": "string",
        "instance": true,
        "keyNames": [
          "userId"
        ],
        "altEntityKeys": [],
        "membershipResult": {
          "altKeys": [],
          "key": [
            "hugh.jackman"
          ],
          "keyNames": [
            "userId"
          ],
          "resourceTag": "User",
          "resourceName": "R1"
        },
        "tokenID": 7199,
        "traversalSourceTokenID": 0
      }
    ]
  },
  "otherInterpretations": [],
  "entities": [
    {
      "tag": "user",
      "key": "userId",
      "value": "hugh.jackman",
      "content": {
        "id": "hugh.jackman",
        "label": "hugh.jackman",
        "timestamp": "2017-12-14T06:52:17.165+0000",
        "isDeleted": false,
        "type": "vertex",
        "properties": {
          "userId": [
            {
              "value": "hugh.jackman"
            }
          ]
        },
        "tokenID": 7199,
        "href": "/dna/assurance/home#user/hugh.jackman",
        "category": "Users",
        "icon": "fa fa-user"
      }
    }
  ],
  "TN": 9
} 
});
