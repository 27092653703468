define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/AuditPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function(SimLokiDatabaseActions, AuditPolicyTemplate, UtilityFunctions){
  return {
      init: function(){
          var i;
          for(i=0; i < AuditPolicyTemplate.AuditPolicy_Template.length; i++) {
              var tJson = JSON.parse(JSON.stringify( AuditPolicyTemplate.AuditPolicy_Template[i]));
              tJson.createdDateTime = UtilityFunctions.getTimeStamp();
              SimLokiDatabaseActions.insert( 'audit', tJson );
          }
      }
  };
});
