define({
  msteamsMeetingSessionsQualityList: [
    {
      sessionId: "04723a8d-41eb-4ed9-991c-64ab44cb415a",
      sessionTitle: "Grace Smith participated in a peer-to-peer call",
      callerEmail: "gracesmith2@cisco.com",
      callerDisplayName: "Grace Smith2",
      isMeetingOrganizer: true,
      calleeEmail: "gracesmith3@cisco.com",
      calleeDisplayName: "Grace Smith3",
      starTime: 1667776322285,
      endTime: 1667778044818,
      duration: 3600000,
      numOfParticipants: 2,
      meetingType: "peerToPeer",
      meetingStatus: "end",
      sessionScore: 6,
      audioMediaScore: 6,
      videoMediaScore: 6,
      shareMediaScore: 6,
      networkSessionScore: 10,
      audioIn: {
        averageAudioDegradation: 1000,
        averageVideoFrameRate: 72,
        averageJitter: 1,
        maxJitter: 3,
        averagePacketLossRate: 5.0,
        maxPacketLossRate: 6.1798,
        averageRoundTripTime: 2,
        maxRoundTripTime: 20,
        packetUtilization: 83226,
        averageBandwidthEstimate: 4276583,
        streamScore: 9,
      },
      audioOut: {
        averageAudioDegradation: 1000,
        averageVideoFrameRate: 72,
        averageJitter: 1,
        maxJitter: 2,
        averagePacketLossRate: 0,
        maxPacketLossRate: 0,
        averageRoundTripTime: 2,
        maxRoundTripTime: 19,
        packetUtilization: 85832,
        averageBandwidthEstimate: 1499999,
        streamScore: 9,
      },
      videoIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.520113,
        averageJitter: 0,
        maxJitter: 4,
        averagePacketLossRate: 5.0,
        maxPacketLossRate: 7.7586,
        averageRoundTripTime: 2,
        maxRoundTripTime: 15,
        packetUtilization: 49587,
        averageBandwidthEstimate: 4276583,
        streamScore: 14,
      },
      videoOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.520113,
        averageJitter: 0,
        maxJitter: 4,
        averagePacketLossRate: 0,
        maxPacketLossRate: 7.7586,
        averageRoundTripTime: 2,
        maxRoundTripTime: 15,
        packetUtilization: 49587,
        averageBandwidthEstimate: 4276583,
        streamScore: 14,
      },
      shareIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.551605,
        averageJitter: 0,
        maxJitter: 1,
        averagePacketLossRate: 3.3286999999999995,
        maxPacketLossRate: 4.6914,
        averageRoundTripTime: 2,
        maxRoundTripTime: 15,
        packetUtilization: 286919,
        averageBandwidthEstimate: 4276583,
        streamScore: 9,
      },
      shareOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.551605,
        averageJitter: 0,
        maxJitter: 1,
        averagePacketLossRate: 3.3286999999999995,
        maxPacketLossRate: 4.6914,
        averageRoundTripTime: 2,
        maxRoundTripTime: 15,
        packetUtilization: 286919,
        averageBandwidthEstimate: 4276583,
        streamScore: 9,
      },
    },
    {
      sessionId: "161b0314-c2d9-43a9-9446-447518886e2e",
      sessionTitle: "Grace Smith  participated in a peer-to-peer call",
      callerEmail: "gracesmith2@cisco.com",
      callerDisplayName: "Grace Smith2",
      isMeetingOrganizer: true,
      calleeEmail: "gracesmith3@cisco.com",
      calleeDisplayName: "GraceSmith3",
      starTime: 1668029793708,
      endTime: 1668031516124,
      duration: 1800000,
      numOfParticipants: 2,
      meetingType: "peerToPeer",
      meetingStatus: "end",
      sessionScore: 9,
      audioMediaScore: 9,
      videoMediaScore: 9,
      shareMediaScore: 9,
      networkSessionScore: 9,
      audioIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: null,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.3459000000000003,
        maxPacketLossRate: 10,
        averageRoundTripTime: 2,
        maxRoundTripTime: 22,
        packetUtilization: 83123,
        averageBandwidthEstimate: 4242600,
        streamScore: 7,
      },
      audioOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: null,
        averageJitter: 0,
        maxJitter: 1,
        averagePacketLossRate: 0,
        maxPacketLossRate: 0,
        averageRoundTripTime: 2,
        maxRoundTripTime: 21,
        packetUtilization: 85958,
        averageBandwidthEstimate: 2880727,
        streamScore: 10,
      },
      videoIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.513512,
        averageJitter: 0,
        maxJitter: 5,
        averagePacketLossRate: 3.2403,
        maxPacketLossRate: 7.8431,
        averageRoundTripTime: 2,
        maxRoundTripTime: 22,
        packetUtilization: 51560,
        averageBandwidthEstimate: 4248543,
        streamScore: 7,
      },
      videoOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.513512,
        averageJitter: 0,
        maxJitter: 5,
        averagePacketLossRate: 3.2403,
        maxPacketLossRate: 7.8431,
        averageRoundTripTime: 2,
        maxRoundTripTime: 22,
        packetUtilization: 51560,
        averageBandwidthEstimate: 4248543,
        streamScore: 7,
      },
      shareIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.513568,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.343,
        maxPacketLossRate: 4.4624999999999995,
        averageRoundTripTime: 2,
        maxRoundTripTime: 24,
        packetUtilization: 286890,
        averageBandwidthEstimate: 4220868,
        streamScore: 7,
      },
      shareOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.513568,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.343,
        maxPacketLossRate: 4.4624999999999995,
        averageRoundTripTime: 2,
        maxRoundTripTime: 24,
        packetUtilization: 286890,
        averageBandwidthEstimate: 4220868,
        streamScore: 7,
      },
    },
    {
      sessionId: "54e98917-9fc4-422a-8ce2-c9986bc98fdc",
      sessionTitle: "Grace Smith  participated in a peer-to-peer call",
      callerEmail: "gracesmith2@cisco.com",
      callerDisplayName: "Grace Smith2",
      isMeetingOrganizer: true,
      calleeEmail: "gracesmith3@cisco.com",
      calleeDisplayName: "Grace Smith3",
      starTime: 1667742720913,
      endTime: 1667744443380,
      duration: 3600000,
      numOfParticipants: 2,
      meetingType: "peerToPeer",
      meetingStatus: "end",
      sessionScore: 9,
      audioMediaScore: 9,
      videoMediaScore: 9,
      shareMediaScore: 9,
      networkSessionScore: 9,
      audioIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: null,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.2420999999999998,
        maxPacketLossRate: 6.7485,
        averageRoundTripTime: 2,
        maxRoundTripTime: 20,
        packetUtilization: 83212,
        averageBandwidthEstimate: 4732743,
        streamScore: 7,
      },
      audioOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: null,
        averageJitter: 1,
        maxJitter: 2,
        averagePacketLossRate: 0,
        maxPacketLossRate: 0,
        averageRoundTripTime: 2,
        maxRoundTripTime: 22,
        packetUtilization: 85913,
        averageBandwidthEstimate: 2861196,
        streamScore: 10,
      },
      videoIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.500584,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.3840000000000003,
        maxPacketLossRate: 7.6923,
        averageRoundTripTime: 2,
        maxRoundTripTime: 11,
        packetUtilization: 60772,
        averageBandwidthEstimate: 4732743,
        streamScore: 7,
      },
      videoOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 7.500584,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.3840000000000003,
        maxPacketLossRate: 7.6923,
        averageRoundTripTime: 2,
        maxRoundTripTime: 11,
        packetUtilization: 60772,
        averageBandwidthEstimate: 4732743,
        streamScore: 7,
      },
      shareIn: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.545694,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.3354000000000004,
        maxPacketLossRate: 4.4077,
        averageRoundTripTime: 2,
        maxRoundTripTime: 11,
        packetUtilization: 286918,
        averageBandwidthEstimate: 4732744,
        streamScore: 7,
      },
      shareOut: {
        averageAudioDegradation: null,
        averageVideoFrameRate: 13.545694,
        averageJitter: 0,
        maxJitter: 2,
        averagePacketLossRate: 3.3354000000000004,
        maxPacketLossRate: 4.4077,
        averageRoundTripTime: 2,
        maxRoundTripTime: 11,
        packetUtilization: 286918,
        averageBandwidthEstimate: 4732744,
        streamScore: 7,
      },
    },
  ],
  registrationInfo: {
    version: "1.0",
    response: {
      registerStatus: "REGISTERED",
      state: null,
      cloudUrl: "https://prd-int.tesseractinternal.com",
      clientId: "0dad53da-0152-4592-af10-b255087df12a",
    },
  },
  msTeamsMeetingDetails :{
	"version": "1.0",
	"response": [
		{
			"collToolsAppName": "ms-teams-app-sharing",
			"exporterDetails": [
				{
					"identifier": "563f30b3-b70e-4a78-8740-f678572bcbf3",
					"name": "ASR1K_Site2.cisco.com"
				}
			],
			"minHealthScore": 9,
			"avgHealthScore": 9,
			"healthTrend": [
				{
					"time": "2022-11-21T11:06:12.455+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 17897158.0,
					"rate": 159085.85
				},
				{
					"time": "2022-11-21T11:10:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 33959005.0,
					"rate": 452786.73
				},
				{
					"time": "2022-11-21T11:15:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 40709274.0,
					"rate": 271395.16
				},
				{
					"time": "2022-11-21T11:20:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 22776905.0,
					"rate": 303692.07
				},
				{
					"time": "2022-11-21T11:21:12.523+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 22776905.0,
					"rate": 303692.07
				}
			]
		},
		{
			"collToolsAppName": "ms-teams-video",
			"exporterDetails": [
				{
					"identifier": "563f30b3-b70e-4a78-8740-f678572bcbf3",
					"name": "ASR1K_Site2.cisco.com"
				}
			],
			"minHealthScore": 9,
			"avgHealthScore": 9,
			"healthTrend": [
				{
					"time": "2022-11-21T11:06:12.455+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 2640.0,
					"rate": 33.55
				},
				{
					"time": "2022-11-21T11:10:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 6124.0,
					"rate": 81.65
				},
				{
					"time": "2022-11-21T11:15:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": null,
					"rate": null
				},
				{
					"time": "2022-11-21T11:20:00.000+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": null,
					"rate": null
				},
				{
					"time": "2022-11-21T11:21:12.523+0000",
					"healthScore": null,
					"packetLoss": null,
					"clientPacketLoss": null,
					"serverPacketLoss": null,
					"jitter": null,
					"clientJitter": null,
					"serverJitter": null,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": null,
					"rate": null
				}
			]
		},
		{
			"collToolsAppName": "ms-teams-audio",
			"exporterDetails": [
				{
					"identifier": "563f30b3-b70e-4a78-8740-f678572bcbf3",
					"name": "ASR1K_Site2.cisco.com"
				}
			],
			"minHealthScore": 9,
			"avgHealthScore": 9,
			"healthTrend": [
				{
					"time": "2022-11-21T11:06:12.455+0000",
					"healthScore": 6,
					"packetLoss": null,
					"clientPacketLoss": 0.0,
					"serverPacketLoss": 3.56,
					"jitter": 679.0,
					"clientJitter": 714.0,
					"serverJitter": 468.0,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 2114347.0,
					"rate": 28183.12
				},
				{
					"time": "2022-11-21T11:10:00.000+0000",
					"healthScore": 6,
					"packetLoss": null,
					"clientPacketLoss": 0.0,
					"serverPacketLoss": 3.47,
					"jitter": 616.0,
					"clientJitter": 629.0,
					"serverJitter": 534.0,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 3727595.0,
					"rate": 49701.27
				},
				{
					"time": "2022-11-21T11:15:00.000+0000",
					"healthScore": 6,
					"packetLoss": null,
					"clientPacketLoss": 0.0,
					"serverPacketLoss": 3.22,
					"jitter": 652.0,
					"clientJitter": 681.0,
					"serverJitter": 482.0,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 3457672.0,
					"rate": 46102.29
				},
				{
					"time": "2022-11-21T11:20:00.000+0000",
					"healthScore": 6,
					"packetLoss": null,
					"clientPacketLoss": 0.0,
					"serverPacketLoss": 3.16,
					"jitter": 697.0,
					"clientJitter": 750.0,
					"serverJitter": 556.0,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 1360094.0,
					"rate": 18134.59
				},
				{
					"time": "2022-11-21T11:21:12.523+0000",
					"healthScore": 6,
					"packetLoss": null,
					"clientPacketLoss": 0.0,
					"serverPacketLoss": 3.16,
					"jitter": 697.0,
					"clientJitter": 750.0,
					"serverJitter": 556.0,
					"latency": null,
					"applicationDelay": null,
					"clientNetworkDelay": null,
					"serverNetworkDelay": null,
					"dscp": null,
					"usage": 1360094.0,
					"rate": 18134.59
				}
			]
		}
	]
}
});