define({
    "CiscoIse_Template": [{
        "id": "0e9a902d-18a8-4521-9db4-3c862871ca69",
        "ipAddress": "172.28.28.105",
        "subscriberName": "dnac-ise",
        "keystoreFileId": "71e4f929-6753-4e20-b3cc-5026c2007839",
        "keystoreFilePassPhrase": "dy1btpHFGumn4Or4O8ig22UaD8UCLTyZ1iVj1Q8lxWmkvb26TKrSiUrvNHXSYLOYVWMzUH211Qi+lkRACKRwPQd9S5gMz/mR",
        "truststoreFileId": "c874662c-c701-4ceb-9770-82196ee70e59",
        "truststoreFilePassPhrase": "k8pXz3/oERiUFkWAPt1zldsSxEm2w76VdL1ZObvPyxQ52wYetplG0G5eRAg2JTnzuoJSNPx5yYvRNwCONjK9lgYBpKlwqHqA",
        "serverState": "ACTIVE",
        "username": "ersadmin",
        "password": "DppILCXdCD1ePS89HE8EMm9SDuz4oLdvUqvbLSXwb9mwfJ9yxoAmh8QoEzxUbGWQH3p+6xDRBA9eXGsAcpDkc0F+zokxfXNR"
    }],

    "EXTERNAL_SUBSYS_ISE": {
        "DNAC-Cluster":{
            "Status":"Ok",
            "Group":"ISE",
            "Label":{"hostname":"ise","ip":"dcloud-ise-ctf-inst-xxx.cisco.com"},
            "Family":"ExternalSystems",
            "Id":1,
            "TooltipInfo":[{"_id":"1651880038","tenantId":"TNT0","severity":3,"timestamp":"1617801940090",
                "source":"ISE ERS Client","actualState":"success",
                "description":"ISE PAN ERS connection : Primary : ERS reachability with ISE - Success","group":"ISE",
                "family":"External Subsystem","drUsability":"No",
                "context":"aXNlLTMwLTcwLmlibmcubG9jYWwxMC4xNi4zMC43MElORk9Db25uZWN0aXZpdHlJU0U",
                "state":"SUCCESS","eventInstanceIdentity":{"subDomain":"ISE","domain":"Connectivity","namespace":"SystemRawEvent",
                "id":"SYSTEM-EXTERNAL-ISE-PAN-ERS","type":"SYSTEM","tags":"ISE_ERS",
                "event_instance_id":{"hostname":"ise","ip":"dcloud-ise-ctf-inst-xxx.cisco.com"}}
            }]
        }
    },

    "EXTERNAL_SUBSYS_IPAM": {
        "DNAC-Cluster":{"Status":"Warning","TooltipInfo":"No data available"}
    }

});
