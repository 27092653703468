define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-11T01:25:00.000+0000",
        "count": 7,
        "nestedItems": [
          {
            "itemType": "<0",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 0
          },
          {
            "itemType": "0-20",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 5
          },
          {
            "itemType": ">20-40",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 2
          },
          {
            "itemType": ">40-60",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 0
          },
          {
            "itemType": ">60-80",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 0
          },
          {
            "itemType": ">80",
            "timestamp": "2020-05-11T01:25:00.000+0000",
            "count": 0
          }
        ]
      }
    ]
  }
}
})