define({
"Template":
        {
          "id": "00:22:BD:DC:3D:49",
          "connectionStatus": "CONNECTED",
          "hostType": "WIRED",
          "userId": null,
          "hostName": "0022BDDC3D49",
          "hostOs": "VIDEO_SURVEILLANCE_IP_CAMERA",
          "hostVersion": null,
          "subType": "VIDEO_SURVEILLANCE_IP_CAMERA",
          "firmwareVersion": null,
          "deviceVendor": null,
          "deviceForm": null,
          "salesCode": null,
          "countryCode": null,
          "lastUpdated": 1589274660000,
          "healthScore": [
            {
              "healthType": "OVERALL",
              "reason": "",
              "score": 10.0
            },
            {
              "healthType": "ONBOARDED",
              "reason": "",
              "score": 4.0
            },
            {
              "healthType": "CONNECTED",
              "reason": "",
              "score": 6.0
            }
          ],
          "hostMac": "00:22:BD:DC:3D:49",
          "hostIpV4": "192.168.0.100",
          "authType": null,
          "vlanId": 1,
          "vnid": null,
          "upnId": null,
          "upnName": null,
          "ssid": null,
          "frequency": null,
          "channel": "0.0",
          "apGroup": null,
          "location": "RAJ-SJC20-nyq1",
          "clientConnection": "stack-nyq1",
          "connectedDevice": [
            {
              "type": null,
              "name": null,
              "id": "214752f0-f9bf-4071-847d-f0507ca166a0",
              "ip address": null,
              "band": null
            }
          ],
          "issueCount": 0,
          "rssi": "0.0",
          "rssiThreshold": "-72.0",
          "rssiIsInclude": "true",
          "avgRssi": "0.0",
          "snr": "0.0",
          "snrThreshold": "9.0",
          "snrIsInclude": "true",
          "avgSnr": "0.0",
          "dataRate": "0.0",
          "txBytes": "19741.0",
          "rxBytes": "143941.0",
          "dnsResponse": "0.0",
          "dnsRequest": "0.0",
          "onboarding": {
            "averageRunDuration": "989.0",
             "maxRunDuration": "989.0",
             "averageAssocDuration": "100.0",
             "maxAssocDuration": "100.0",
             "averageAuthDuration": "488.0",
             "maxAuthDuration": "488.0",
             "averageDhcpDuration": "300.0",
             "maxDhcpDuration": "200.0",
             "aaaServerIp": "192.168.139.168",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372
          },
          "clientType": "OLD",
          "onboardingTime": 0,
          "port": "GigabitEthernet3/0/22",
          "iosCapable": false,
          "usage": 163682.0,
          "linkSpeed": 1.0E+8,
          "linkThreshold": "I_1.0",
          "remoteEndDuplexMode": "Full Duplex",
          "txLinkError": 0.0,
          "rxLinkError": 0.0,
          "txRate": 526.4266666666666,
          "rxRate": 3838.4266666666667,
          "rxRetryPct": "0.0",
          "versionTime": 1588788720000,
          "dot11Protocol": "Unclassified",
          "slotId": 0,
          "dot11ProtocolCapability": "Unclassified"
        }
})