define({
  "HOST_LISTING": {
    "version": "1.0",
    "response": [
      {
        "id": "7C:7A:91:90:BB:B3",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Jacob",
        "hostName": "Jacob-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527812205552,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 4
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 5
          }
        ],
        "hostMac": "7C:7A:91:90:BB:B3",
        "hostIpV4": "169.254.0.91",
        "hostIpV6": [
          "fe80::4aa:4af3:6ff8:6ae3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP37",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-47.0",
        "avgRssi": "-47.0",
        "snr": "48.0",
        "avgSnr": "48.0",
        "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
        "dataRate": "1200.0",
        "txBytes": "258854.0",
        "rxBytes": "179821.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"300",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 15232
      },
      {
        "id": "0C:75:BD:05:10:31",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "csgsensor.gen",
        "hostName": null,
        "hostOs": "Sensor-Client-AP",
        "hostVersion": null,
        "subType": "Sensor-Client-AP",
        "lastUpdated": 1527940142741,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 0
          }
        ],
        "hostMac": "0C:75:BD:05:10:31",
        "hostIpV4": null,
        "hostIpV6": [
          "fe80::7a46:35ff:ff20:5ad6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": null,
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP24",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-89.0",
        "avgRssi": "-80.0",
        "snr": "8.0",
        "avgSnr": "8.0",
        "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
        "dataRate": "15.0",
        "txBytes": "19682.0",
        "rxBytes": "2629.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "10000",
          "maxRunDuration": "10000",
          "averageAssocDuration": "5000",
          "maxAssocDuration": "5000",
          "averageAuthDuration": "5000",
          "maxAuthDuration": "5000",
          "averageDhcpDuration": "5000",
          "maxDhcpDuration": "5000",
          "maxRoamingDuration":"10000",
          "aaaServerIp": "10.13.1.113",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "0.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 16547
      },
      {
        "id": "00:21:5C:43:6E:61",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": null,
        "hostName": null,
        "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
        "hostVersion": null,
        "subType": "Linux-Workstation",
        "lastUpdated": 1527940089269,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 0
          }
        ],
        "hostMac": "00:21:5C:43:6E:61",
        "hostIpV4": null,
        "hostIpV6": [
          "fe80::4aa:4bf3:6ff8:6ae3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": null,
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "default-group",
        "location": null,
        "clientConnection": "req01-12l-ap1",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-80.0",
        "avgRssi": "-80.0",
        "snr": "6.0",
        "avgSnr": "6.0",
        "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
        "dataRate": "12.0",
        "txBytes": "0.0",
        "rxBytes": "1236.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "30000",
          "maxRunDuration": "30000",
          "averageAssocDuration": "15000",
          "maxAssocDuration": "15000",
          "averageAuthDuration": "15000",
          "maxAuthDuration": "15000",
          "averageDhcpDuration": "15000",
          "maxDhcpDuration": "15000",
          "maxRoamingDuration":"30000",
          "aaaServerIp": "10.13.1.113",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "0.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 15243
      },
      {
        "id": "58:EF:68:27:43:2F",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Scooby",
        "hostName": "Scooby-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527925142974,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 9
          }
        ],
        "hostMac": "58:EF:68:27:43:2F",
        "hostIpV4": "10.41.50.247",
        "hostIpV6": [
          "fe80::7b46:35ff:ff20:5bd6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP25",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-48.0",
        "avgRssi": "-48.0",
        "snr": "49.0",
        "avgSnr": "50.0",
        "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
        "dataRate": "2000.0",
        "txBytes": "10493.0",
        "rxBytes": "10317.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"200",
          "aaaServerIp": "10.13.1.113",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768365,
          "assocDoneTime": 1527204732979,
          "dhcpDoneTime": 1527204738352,
          "maxAuthDurationDuration": "1.5"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 18769
      },
      {
        "id": "58:AC:78:DF:BD:20",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "eF+Bbt2A",
        "hostName": null,
        "hostOs": "Sensor-Client-AP",
        "hostVersion": null,
        "subType": "Sensor-Client-AP",
        "lastUpdated": 1527940160989,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 7
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 7
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 7
          }
        ],
        "hostMac": "58:AC:78:DF:BD:20",
        "hostIpV4": "10.41.50.106",
        "hostIpV6": [
          "fe80::4aa:4cf3:6ff8:6be3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-3_TYPICAL_8811a",
        "location": null,
        "clientConnection": "SJC23-32A-AP24",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-48.0",
        "avgRssi": "-48.0",
        "snr": "42.0",
        "avgSnr": "42.0",
        "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
        "dataRate": "780.0",
        "txBytes": "50212.0",
        "rxBytes": "7243.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "2000",
          "maxRunDuration": "2000",
          "averageAssocDuration": "1000",
          "maxAssocDuration": "1000",
          "averageAuthDuration": "1000",
          "maxAuthDuration": "1000",
          "averageDhcpDuration": "1000",
          "maxDhcpDuration": "1000",
          "maxRoamingDuration":"2000",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768365,
          "assocDoneTime": 1527204732979,
          "dhcpDoneTime": 1527204738352,
          "maxAuthDurationDuration": "1.5"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 19087
      },
      {
        "id": "78:4F:43:93:A0:BF",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Charles",
        "hostName": "Charles-iPad",
        "hostOs": "Apple-iPad",
        "hostVersion": null,
        "subType": "Apple-Device",
        "lastUpdated": 1527928262172,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 9
          }
        ],
        "hostMac": "78:4F:43:93:A0:BF",
        "hostIpV4": "10.41.49.117",
        "hostIpV6": [
          "fe80::7c46:35ff:ff20:5ca6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-3_TYPICAL_8811a",
        "location": "San Jose/SJ23/SJ23-3",
        "clientConnection": "SJC23-32A-AP39",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-29.0",
        "avgRssi": "-29.0",
        "snr": "50.0",
        "avgSnr": "50.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1030.0",
        "txBytes": "1216872.0",
        "rxBytes": "267007.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"20",
          "aaaServerIp": "10.13.1.113",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768365,
          "assocDoneTime": 1527204732979,
          "dhcpDoneTime": 1527204738352,
          "maxAuthDurationDuration": "1.5"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 18700
      },
      {
        "id": "08:11:96:78:2D:28",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "dwill",
        "hostName": "dwill-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Windows7-Workstation",
        "lastUpdated": 1527935425271,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 9
          }
        ],
        "hostMac": "08:11:96:78:2D:28",
        "hostIpV4": "10.130.29.43",
        "hostIpV6": [
          "fe80::4aa:4df3:6ff8:6be3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "220",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "default-group",
        "location": null,
        "clientConnection": "req01-31l-ap10",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-46.0",
        "avgRssi": "-46.0",
        "snr": "38.0",
        "avgSnr": "38.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1200.0",
        "txBytes": "1298.0",
        "rxBytes": "3439.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"300",
          "aaaServerIp": "10.42.3.31",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 10234
      },
      {
        "id": "3C:15:C2:B9:BF:5E",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Kylo",
        "hostName": "Kylo-iPad",
        "hostOs": "Apple-iPad",
        "hostVersion": null,
        "subType": "Apple-Device",
        "lastUpdated": 1527714010378,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 3
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 0
          }
        ],
        "hostMac": "3C:15:C2:B9:BF:5E",
        "hostIpV4": "10.130.29.11",
        "hostIpV6": [
          "fe80::7d46:35ff:ff20:5dd6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "220",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "default-group",
        "location": null,
        "clientConnection": "req01-31l-ap3",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-68.0",
        "avgRssi": "-68.0",
        "snr": "16.0",
        "avgSnr": "16.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "145.0",
        "txBytes": "0.0",
        "rxBytes": "200.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "7000",
          "maxRunDuration": "7000",
          "averageAssocDuration": "3500",
          "maxAssocDuration": "3500",
          "averageAuthDuration": "3500",
          "maxAuthDuration": "3500",
          "averageDhcpDuration": "3500",
          "maxDhcpDuration": "3500",
          "maxRoamingDuration":"7000",
          "aaaServerIp": "10.13.1.113",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 14352
      },
      {
        "id": "5C:E0:C5:CE:55:B8",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Creed",
        "hostName": "Creed-PC",
        "hostOs": null,
        "hostVersion": null,
        "subType": "Intel-Device",
        "lastUpdated": 1527799746799,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 8
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 4
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 6
          }
        ],
        "hostMac": "5C:E0:C5:CE:55:B8",
        "hostIpV4": "10.41.50.121",
        "hostIpV6": [
          "fe80::4aa:4ef3:6ff8:6ce3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP30",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-56.0",
        "avgRssi": "-56.0",
        "snr": "35.0",
        "avgSnr": "35.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "700.0",
        "txBytes": "82.0",
        "rxBytes": "218.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"180",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 13420
      },
      {
        "id": "BC:83:85:E6:36:73",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Amy",
        "hostName": "Amy-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527939380371,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 0
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 1
          }
        ],
        "hostMac": "BC:83:85:E6:36:73",
        "hostIpV4": "10.41.49.10",
        "hostIpV6": [
          "fe80::7e46:35ff:ff20:5ed6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-21A-AP15",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-90.0",
        "avgRssi": "-90.0",
        "snr": "0.0",
        "avgSnr": "0.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "14.0",
        "txBytes": "430.0",
        "rxBytes": "424.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "900",
          "maxRunDuration": "900",
          "averageAssocDuration": "450",
          "maxAssocDuration": "450",
          "averageAuthDuration": "450",
          "maxAuthDuration": "450",
          "averageDhcpDuration": "450",
          "maxDhcpDuration": "450",
          "maxRoamingDuration":"900",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 16023
      },
      {
        "id": "48:F8:B3:72:08:42",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "James",
        "hostName": "James-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Linksys-Device",
        "lastUpdated": 1527886012383,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 10
          }
        ],
        "hostMac": "48:F8:B3:72:08:42",
        "hostIpV4": "10.41.50.234",
        "hostIpV6": [
          "fe80::4aa:4ff3:6ff8:6de3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP46",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-32.0",
        "avgRssi": "-32.0",
        "snr": "60.0",
        "avgSnr": "60.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1270.0",
        "txBytes": "52569.0",
        "rxBytes": "19115.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"50",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 15436
      },
      {
        "id": "3C:F8:62:2D:4D:17",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": null,
        "hostName": "mu5Y13Ij",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527939880971,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 0
          }
        ],
        "hostMac": "3C:F8:62:2D:4D:17",
        "hostIpV4": null,
        "hostIpV6": [
          "fe80::7f46:35ff:ff20:5fd6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-1_TYPICAL_0808f",
        "location": "San Jose/SJ23/SJ23-1",
        "clientConnection": "SJC23-11A-AP19",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-76.0",
        "avgRssi": "-76.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "snr": "6.0",
        "avgSnr": "6.0",
        "dataRate": "20.0",
        "txBytes": "881.0",
        "rxBytes": "349.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "25000",
          "maxRunDuration": "25000",
          "averageAssocDuration": "10000",
          "maxAssocDuration": "10500",
          "averageAuthDuration": "10000",
          "maxAuthDuration": "10500",
          "averageDhcpDuration": "10000",
          "maxDhcpDuration": "10500",
          "maxRoamingDuration":"25000",
          "aaaServerIp": "10.42.136.32",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633709,
        "port": null,
        "usage": 10564
      },
      {
        "id": "48:F8:B3:A7:DE:84",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Adonis",
        "hostName": "Adonis-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Linksys-Device",
        "lastUpdated": 1527909191584,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 8
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 9
          }
        ],
        "hostMac": "48:F8:B3:A7:DE:84",
        "hostIpV4": "10.41.50.102",
        "hostIpV6": [
          "fe80::4aa:4aa3:6ff8:6ee3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP47",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-22.0",
        "avgRssi": "-22.0",
        "snr": "49.0",
        "avgSnr": "49.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1250.0",
        "txBytes": "259187.0",
        "rxBytes": "62305.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"50",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 20123
      },
      {
        "id": "E0:94:67:D3:18:28",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "3IbXzPDo",
        "hostName": null,
        "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
        "hostVersion": null,
        "subType": "Linux-Workstation",
        "lastUpdated": 1527908110761,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "E0:94:67:D3:18:28",
        "hostIpV4": "10.41.48.36",
        "hostIpV6": [
          "fe80::7f46:35af:ff20:5fa6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP47",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-39.0",
        "avgRssi": "-39.0",
        "snr": "56.0",
        "avgSnr": "56.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1100.0",
        "txBytes": "1982.0",
        "rxBytes": "20952.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"1000",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 28012
      },
      {
        "id": "C8:D7:19:1D:5A:A1",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Hakeem",
        "hostName": "Hakeem-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Linksys-Device",
        "lastUpdated": 1527916970363,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "C8:D7:19:1D:5A:A1",
        "hostIpV4": "10.41.50.211",
        "hostIpV6": [
          "fe80::4aa:4ab3:6ff8:6fe3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP47",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-40.0",
        "avgRssi": "-40.0",
        "snr": "55.0",
        "avgSnr": "55.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1500.0",
        "txBytes": "46379.0",
        "rxBytes": "13638.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"100",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768565,
          "assocDoneTime": 1527204734979,
          "dhcpDoneTime": 1527204737352,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 100763
      },
      {
        "id": "78:4F:43:97:C3:B1",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Bender",
        "hostName": "Bender-PC",
        "hostOs": null,
        "hostVersion": null,
        "subType": "Workstation",
        "lastUpdated": 1527938780274,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 8
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 8
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "78:4F:43:97:C3:B1",
        "hostIpV4": "10.41.48.234",
        "hostIpV6": [
          "fe80::7f46:35bf:ff20:5fb6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-21A-AP40",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-45.0",
        "avgRssi": "-45.0",
        "snr": "42.0",
        "avgSnr": "42.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "840.0",
        "txBytes": "0.0",
        "rxBytes": "0.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"1000",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "3.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 24132
      },
      {
        "id": "3C:A9:F4:1D:BD:DC",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Philip",
        "hostName": "Philip-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527905489095,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "3C:A9:F4:1D:BD:DC",
        "hostIpV4": "10.41.48.40",
        "hostIpV6": [
          "fe80::4aa:4ac3:6fa8:6af3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP25",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-41.0",
        "avgRssi": "-41.0",
        "snr": "48.0",
        "avgSnr": "48.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1280.0",
        "txBytes": "82806.0",
        "rxBytes": "57537.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"350",
          "aaaServerIp": "10.42.136.30",
          "dhcpServerIp": "10.13.4.1",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 12098
      },
      {
        "id": "10:4A:7D:01:93:4D",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "Benjamin",
        "hostName": "Benjamin-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Microsoft-Workstation",
        "lastUpdated": 1527919932940,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "10:4A:7D:01:93:4D",
        "hostIpV4": "97.115.46.117",
        "hostIpV6": [
          "fe80::7f46:35cf:ff20:5fc6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-2_TYPICAL_e527b",
        "location": "San Jose/SJ23/SJ23-2",
        "clientConnection": "SJC23-22A-AP18",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-48.0",
        "avgRssi": "-48.0",
        "snr": "55.0",
        "avgSnr": "56.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1250.0",
        "txBytes": "835.0",
        "rxBytes": "8302.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "1000",
          "maxRunDuration": "1000",
          "averageAssocDuration": "500",
          "maxAssocDuration": "500",
          "averageAuthDuration": "500",
          "maxAuthDuration": "500",
          "averageDhcpDuration": "500",
          "maxDhcpDuration": "500",
          "maxRoamingDuration":"380",
          "aaaServerIp": "10.42.136.32",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633909,
        "port": null,
        "usage": 104532
      },
      {
        "id": "5C:E0:C5:72:0F:33",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "William",
        "hostName": "William-PC",
        "hostOs": "MSFT 5.0",
        "hostVersion": null,
        "subType": "Windows7-Workstation",
        "lastUpdated": 1527919681802,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 9
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 8
          }
        ],
        "hostMac": "5C:E0:C5:72:0F:33",
        "hostIpV4": "10.130.29.30",
        "hostIpV6": [
          "fe80::4aa:4ad3:6ff8:6aa3"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "220",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "default-group",
        "location": null,
        "clientConnection": "req01-32l-ap4",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "-30.0",
        "avgRssi": "-30.0",
        "snr": "55.0",
        "avgSnr": "55.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "1025.0",
        "txBytes": "45517.0",
        "rxBytes": "26922.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "500",
          "maxRunDuration": "500",
          "averageAssocDuration": "300",
          "maxAssocDuration": "300",
          "averageAuthDuration": "300",
          "maxAuthDuration": "300",
          "averageDhcpDuration": "300",
          "maxDhcpDuration": "300",
          "maxRoamingDuration":"500",
          "aaaServerIp": "10.42.136.32",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633989,
        "port": null,
        "usage": 16545
      },
      {
        "id": "00:42:5A:0A:22:41",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRELESS",
        "userId": "csgsensor.gen",
        "hostName": null,
        "hostOs": "Sensor-Client-AP",
        "hostVersion": null,
        "subType": "Sensor-Client-AP",
        "lastUpdated": 1527939980373,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 0
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 1
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 0
          }
        ],
        "hostMac": "00:42:5A:0A:22:41",
        "hostIpV4": null,
        "hostIpV6": [
          "fe80::7f46:35df:ff20:5fd6"
        ],
        "authType": "WPA/WPA2",
        "vlanId": "110",
        "ssid": "alpha",
        "frequency": "5.0",
        "channel": null,
        "apGroup": "SJ23-3_TYPICAL_8811a",
        "location": "San Jose/SJ23/SJ23-3",
        "clientConnection": "SJC23-32A-AP29",
        "connectedDevice": [],
        "issueCount": 0,
        "rssi": "0.0",
        "avgRssi": "0.0",
        "snr": "0.0",
        "avgSnr": "0.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "0.0",
        "txBytes": "0.0",
        "rxBytes": "0.0",
        "rxRetryPct": "0.0",
        "dnsSuccess": "-1.0",
        "dnsFailure": "-1.0",
        "onboarding": {
          "averageRunDuration": "31500",
          "maxRunDuration": "41500",
          "averageAssocDuration": "43500",
          "maxAssocDuration": "23750",
          "averageAuthDuration": "234500",
          "maxAuthDuration": "23000",
          "averageDhcpDuration": "23000",
          "maxDhcpDuration": "23000",
          "maxRoamingDuration":"31500",
          "aaaServerIp": "10.42.136.32",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 1527204768595,
          "assocDoneTime": 1527204734999,
          "dhcpDoneTime": 1527204737359,
          "maxAuthDurationDuration": "1.0"
        },
        "onboardingTime": 1527169633989,
        "port": null,
        "usage": 14232
      }
    ],
    "page": {
      "pageSize": 20,
      "offset": 0,
      "totalCount": 20
    }
  },
  //Not using this data for now, it may required in future
  "Additional_HostData":{
    "tracked": "No",
    "duid": "",
    "identifier": "",
    "salesCode": "XAA",
    "countryCode": "China",
    "l3VirtualNetwork": null,
    "l2VirtualNetwork": null,
    "vnid": null,
    "upnId": "0",
    "upnName": null,
    "sgt": "0.0",
    "linkSpeed": 0,
    "linkThreshold": "I_1.0",
    "remoteEndDuplexMode": null,
    "versionTime": null,
    "slotId": 1,
    "privateMac": true,
    "aaaServerTransaction": null,
    "aaaServerFailedTransaction": null,
    "aaaServerSuccessTransaction": null,
    "aaaServerLatency": null,
    "aaaServerMABLatency": null,
    "aaaServerEAPLatency": null,
    "dhcpServerTransaction": null,
    "dhcpServerFailedTransaction": null,
    "dhcpServerSuccessTransaction": null,
    "dhcpServerLatency": null,
    "dhcpServerDOLatency": null,
    "dhcpServerRALatency": null,
    "maxRoamingDuration": "96.0",
    "upnOwner": null,
    "connectedUpn": null,
    "connectedUpnOwner": null,
    "connectedUpnId": null,
    "isGuestUPNEndpoint": null,
    "sessionDuration": "217494.0",
    "intelCapable": false,
    "hwModel": null,
    "powerType": null,
    "modelName": null,
    "bridgeVMMode": "NA",
    "dhcpNakIp": null,
    "dhcpDeclineIp": null,
    "portDescription": null
  }
});
