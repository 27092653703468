define([
    'core/../../../public/libs/node_modules/lokijs/src/lokijs',
    'core/../../../public/libs/node_modules/lokijs/src/loki-indexed-adapter'
], function (loki, lokiia) {

  return {
      createLokiIndexAdapter: function(dbIndx, dbInfo, onCompletionCallback) {
          /*
            Lokijs supports persistance using a concept of Adapters.
            It has multiple adapter e.g. 1)LocalStorageAdapter 2)IndexedAdapter 3)LokiNativescriptAdapter etc..
            We are using Loki IndexAdapter feature in this file.
            Loki documetation states: 1 IndexAdapter, can have one or multiple databases.
            Each db will have one corresponding collection. Actual data manipulation happens on collection.
            More information on Loki can be found at: https://github.com/techfort/LokiJS
          */
          var invDbIa = new lokiia(dbInfo.adapterName);

          // Need to store these items, so that on callback these values can be access by autoloadCallback
          var tCollectionName = dbInfo.collectionName;
          //var tForceDelete    = false; //dbInfo.forceDelete; //original comment on 8-oct-2018 by swarup
          var tForceDelete    = dbInfo.forceDelete;//added on 8-oct-2018

          var tIndx           = dbIndx;

          var invDbHandle  = new loki ( dbInfo.dbName, {
                                     autoload: true,
                                     autoloadCallback : function() {
                                         var collectionHandler = loadHandler(invDbHandle, tCollectionName, tForceDelete);
                                         onCompletionCallback(tIndx, collectionHandler);
                                     },
                                     autosave: true,
                                     autosaveInterval: 2000,
                                     adapter: invDbIa });
          }
      };

      function loadHandler(invDbHandle, collectionName, bDeleteExisting) {
          var invDevCollection = invDbHandle.getCollection(collectionName);

          if(bDeleteExisting && invDevCollection != null) {
              invDbHandle.removeCollection(collectionName);
          }
          if (invDevCollection === null) {
              // if database did not exist it will be empty so It will intitialize here
              invDevCollection = invDbHandle.addCollection(collectionName);
          }
          //else {
          //    console.log(invDevCollection);
          //}
          return invDevCollection;
      }
});
