define({
 "MetricData":
 {
  "values": [
    {
      "value": 100,
      "time": "2018-06-11T09:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T10:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T10:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T10:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T10:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T10:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T11:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T12:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T13:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T13:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T13:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T13:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T13:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T14:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T15:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T15:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T15:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T15:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T15:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T16:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T17:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T17:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T17:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T17:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T17:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T18:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T19:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T20:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T20:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T20:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T20:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T20:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T21:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T22:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T22:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T22:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T22:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T22:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-11T23:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T00:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T00:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T00:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T00:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T00:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T01:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T02:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T03:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T03:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T03:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T03:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T03:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T04:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T05:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T05:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T05:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T05:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T05:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T06:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T07:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T07:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T07:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T07:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T07:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:40:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T08:50:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T09:00:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T09:10:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T09:20:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T09:30:00.000+0000"
    },
    {
      "value": 100,
      "time": "2018-06-12T09:40:00.000+0000"
    }
  ],
  "request": {
    "entity": {
      "managementIpAddr": "192.168.120.1"
    },
    "name": "fabricIfAvailabiltiy",
    "field": "ifavail",
    "dimensions": [
      {
        "name": "o_interfaceName",
        "value": "GigabitEthernet1/0/22"
      }
    ],
    "window": "10 min",
    "timeRange": {
      "start": "2018-06-11T09:40:59.379Z",
      "end": "2018-06-12T09:40:59.379Z"
    }
  },
  "pagination": {
    "links": [
      {
        "rel": "self",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/entities/metrics?page=1&pageSize=4000",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"192.168.120.1\"},\"name\":\"fabricIfAvailabiltiy\",\"field\":\"ifavail\",\"dimensions\":[{\"name\":\"o_interfaceName\",\"value\":\"GigabitEthernet1/0/22\"}],\"window\":\"10 min\",\"timeRange\":{\"start\":\"2018-06-11T09:40:59.379Z\",\"end\":\"2018-06-12T09:40:59.379Z\"},\"values\":[]}"
      },
      {
        "rel": "first",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/entities/metrics?page=1&pageSize=4000",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"192.168.120.1\"},\"name\":\"fabricIfAvailabiltiy\",\"field\":\"ifavail\",\"dimensions\":[{\"name\":\"o_interfaceName\",\"value\":\"GigabitEthernet1/0/22\"}],\"window\":\"10 min\",\"timeRange\":{\"start\":\"2018-06-11T09:40:59.379Z\",\"end\":\"2018-06-12T09:40:59.379Z\"},\"values\":[]}"
      },
      {
        "rel": "last",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/entities/metrics?page=1&pageSize=4000",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"192.168.120.1\"},\"name\":\"fabricIfAvailabiltiy\",\"field\":\"ifavail\",\"dimensions\":[{\"name\":\"o_interfaceName\",\"value\":\"GigabitEthernet1/0/22\"}],\"window\":\"10 min\",\"timeRange\":{\"start\":\"2018-06-11T09:40:59.379Z\",\"end\":\"2018-06-12T09:40:59.379Z\"},\"values\":[]}"
      }
    ],
    "page": 1,
    "pageSize": 4000,
    "order": "ASC",
    "totalResults": 134
  }
}
})
