define({
    "runningImageList":[  
        {  
           "name":"Cisco Wireless Sensor",
           "imageUuid":"8cf6b737-2ff2-4235-a688-44211d55b1ee",
           "version":"8.5.257.0",
           "softwareType":null,
           "imageType":null,
           "isTaggedGolden":"false",
           "md5Checksum":null,
           "shaCheckSum":null,
           "createdTime":null,
           "applicationType":null,
           "feature":null,
           "fileServiceId":null,
           "imageSeries":null,
           "imageSource":null,
           "imageSourceType":null,
           "imageFamily":"AP1800S",
           "vendor":null,
           "filesize":0,
           "deviceCount":1,
           "deviceUuidList":[  
              "19069536-a377-46dc-ab54-d46a2f900a3d"
           ],
           "smuCount":0,
           "imageAttributes":[  

           ],
           "isSMUApplicable":false,
           "goldenTagInheritedFromSiteUuid":null,
           "goldenTagInheritedFromSiteName":null,
           "importSupported":true,
           "reasonForNotSupportingImport":null,
           "tagList":[  

           ],
           "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
           "imageCategory":"PHYSICAL",
           "imageIntegrityStatus":null,
           "ccorecommended":false,
           "ccolatest":false
        }
     ]
});