define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-08T06:30:00.000+0000",
        "count": 570,
        "nestedItems": []
      }
    ]
  }
}
})