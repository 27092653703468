define({
    "Verify_Template": {
                "resultTaskUuid":"",
                "parentTaskUuid":"",
                "validationBindingUuid":"",
                "validatorUuid":"",
                "validatorName":"",
                "validatorType":"BULK_JAVA_BEAN",
                "operationName":"",
                "statusCode":"0",
                "resultStatus":"SUCCESS",
                "detail":"Validator executed successfully",
                "progress":"100",
                "createdTime":"20-Dec-2017 09:57:22",
                "updatedTime":"20-Dec-2017 09:57:25",
                "deviceUuid":"",
                "resultDetail":[]
      }
});
