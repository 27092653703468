define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/task/TaskTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (TaskTemplate, UtilityFunctions) {

    var licenseTaskId_mapping = {};

    return {

        init: function () {
            licenseTaskId_mapping = new Object();
        },

        createTask: function (isError, options) {
            var taskId = UtilityFunctions.generate_uuid();
            var startTime = UtilityFunctions.getTimeStamp();
            licenseTaskId_mapping[taskId] = JSON.parse(JSON.stringify(TaskTemplate.LICENSE_TASK_TEMPLATE));

            if (options != undefined) {
                licenseTaskId_mapping[taskId].data[0] = options;
            }
            if(isError) {
                licenseTaskId_mapping[taskId].isError = isError;
            }

            var tJson = {'taskId':'', 'url':'/api/v1/licensemanager/licensereservation/'};
            tJson.taskId = taskId;
            tJson.url = tJson.url + taskId;
            return tJson;
        },

        getTaskStatus: function (urlAction) {
            var url = urlAction.url;
            var arr = url.split('/');
            var taskId = arr[5];

            if (taskId.indexOf("?") >= 0) {
                taskId = taskId.substr(0, taskId.indexOf("?"));
            }

            if (licenseTaskId_mapping[taskId] != undefined) {
                var result = this.getTaskResponse(taskId);
                return result;
            } else {
                var tJson = JSON.parse(JSON.stringify(TaskTemplate.LICENSE_TASK_TEMPLATE));
                return tJson;
            }
        },

        getTaskResponse: function (taskId) {
            if (licenseTaskId_mapping[taskId].isError) {
                return {"response": "ERROR sequence item 0: expected str instance, NoneType found"};
            } else {
                return licenseTaskId_mapping[taskId];
            }
        }
    };
});
