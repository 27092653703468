define({
  "ImageTypeMapping": {
  "Cisco 4451 Series Integrated Services Router": {deviceTypeOrdinal:284389362,softwareType:"IOS-XE",
    runningImagePath:"Cisco_4451_Series_Integrated_Services_Router.js", productFamily:"Routers" },
  "Cisco Catalyst 36xx stack-able ethernet switch": {deviceTypeOrdinal:999901008,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_36xx_stack_able_ethernet_switch.js", productFamily:"Switches and Hubs" },
  "Cisco Catalyst 38xx stack-able ethernet switch": {deviceTypeOrdinal:999919705,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_38xx_stack_able_ethernet_switch.js", productFamily:"Switches and Hubs" },
  "Cisco 5520 Series Wireless Controllers": {deviceTypeOrdinal:286284588,softwareType:"Cisco Controller",
    runningImagePath:"Cisco_5520_Series_Wireless_Controllers.js", productFamily: "Wireless Controller" },
  "Cisco Catalyst 9200 Switch Stack": {deviceTypeOrdinal:223415874,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_9200_Switch_Stack.js", productFamily:"Switches and Hubs" },
  "Cisco Catalyst 9300 Switch": {deviceTypeOrdinal:286315874,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_9300_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco Catalyst 9404R Switch": {deviceTypeOrdinal:296315874,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_9404R_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco Catalyst 9500 Switch": {deviceTypeOrdinal:286315863,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_9500_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco Catalyst 3650-24PD-E Switch": {deviceTypeOrdinal:284850621,softwareType:"IOS-XE",
    runningImagePath:"Cisco_Catalyst_3650_24PD_E.js", productFamily:"Switches and Hubs" },
  "Cisco ASR 1001-X Router": {deviceTypeOrdinal:284932298,softwareType:"IOS-XE",
    runningImagePath:"Cisco_ASR_1001_X_Router.js", productFamily:"Routers" },
  "Cisco ASR 1002-X Router": {deviceTypeOrdinal:284146581,softwareType:"IOS-XE",
    runningImagePath:"Cisco_ASR_1002_X_Router.js", productFamily:"Routers" },
  "Cisco Nexus 7000 10-Slot Switch": {deviceTypeOrdinal:281943928,softwareType:"NX-OS",
    runningImagePath:"Cisco_Nexus_7000_10_Slot_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco Nexus 7000 9-Slot Switch": {deviceTypeOrdinal:283748960,softwareType:"NX-OS",
    runningImagePath:"Cisco_Nexus_7000_9_Slot_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco IE-4000-4TC4G-E Industrial Ethernet Switch": {deviceTypeOrdinal:286254959,softwareType:"IOS-XE",
    runningImagePath:"Cisco_IE_4000_4TC4G_E_Industrial_Ethernet_Switch.js", productFamily:"Switches and Hubs" },
  "Cisco ASR 1002 Router": {deviceTypeOrdinal:281782371,softwareType:"IOS-XE",
    runningImagePath:"Cisco_ASR_1002_Router.js", productFamily:"Routers" },
    "Cisco ASR 1004 Router": {deviceTypeOrdinal:281782372,softwareType:"IOS-XE",
     runningImagePath:"Cisco_ASR_1004_Router.js", productFamily:"Routers" },
    "Cisco Catalyst 6880-X-LE Switch": {
      deviceTypeOrdinal: 999991784, softwareType: "IOS",
      runningImagePath: "Cisco_Catalyst_6880_X_LE_Switch.js", productFamily:"Switches and Hubs"
    },
    "Cisco Catalyst C9500H-24Y4C Switch": {
      deviceTypeOrdinal: 286319595, softwareType: "IOS-XE",
      runningImagePath: "Cisco_Catalyst_C9500H_24Y4C_Switch.js", productFamily:"Switches and Hubs"
    },
    "Cisco 1800S Unified Access Point Sensor": {
      deviceTypeOrdinal: 887700080, softwareType: "Wireless Sensor",
      runningImagePath: "Cisco_1800S_Unified_Access_Point_Sensor.js", productFamily:"Wireless Sensor"
    },
    "Cisco Catalyst 6840-X Switch": {
      deviceTypeOrdinal: 286287226, softwareType: "IOS",
      runningImagePath: "Cisco_Catalyst_6840_X_Switch.js", productFamily:"Switches and Hubs"
    },
    "Cisco Catalyst 6807-XL Switch": {
      deviceTypeOrdinal: 284855059, softwareType: "IOS",
      runningImagePath: "Cisco_Catalyst_6807_XL_Switch.js", productFamily:"Switches and Hubs"
    },
    "NFVIS": {
      deviceTypeOrdinal: 101010115, softwareType: "NFVIS",
      runningImagePath: "NFVIS.js", productFamily:"Routers"
    },
    "Cisco 5400 Enterprise Network Compute System": {
      deviceTypeOrdinal: 286308693, softwareType: "NFVIS",
      runningImagePath: "Cisco_5400_Enterprise_Network_Compute_System.js", productFamily:"Routers"
    },
    "Cisco Catalyst 9800-40 Wireless Controller": {
      deviceTypeOrdinal:286316412,softwareType:"Cisco Controller",
      runningImagePath:"Cisco_Catalyst_9800_40_Wireless_Controller.js", productFamily: "Wireless Controller"
    },
    "Cisco Catalyst 9800-L-F Wireless Controller": {
      deviceTypeOrdinal:286321399,softwareType:"Cisco Controller",
      runningImagePath:"Cisco_Catalyst_9800_L_F_Wireless_Controller.js", productFamily: "Wireless Controller"
    },
    "Cisco Catalyst 9800-CL Wireless Controller for Cloud": {
      deviceTypeOrdinal:286322605,softwareType:"Cisco Controller",
      runningImagePath:"Cisco_Catalyst_9800_CL_Wireless_Controller_for_Cloud.js", productFamily: "Wireless Controller"
    },
    "Cisco Aironet 1800S Active Sensor": {
      deviceTypeOrdinal: 887700079, softwareType: "Wireless Sensor",
      runningImagePath: "Cisco_Aironet_1800S_Active_Sensor", productFamily:"Wireless Sensor"
    },
    "Cisco Catalyst IE-3400-8P2S Rugged Switch": {
      deviceTypeOrdinal:286320758, softwareType:"IOS-XE",
      runningImagePath:"Cisco_Catalyst_IE_3400_8P2S_Rugged_Switch.js", productFamily:"Switches and Hubs"
    },
    "Cisco Catalyst IE-3300-8P2S Rugged Switch": {
      deviceTypeOrdinal:286320759, softwareType:"IOS-XE",
      runningImagePath:"Cisco_Catalyst_IE_3300_8P2S_Rugged_Switch.js", productFamily:"Switches and Hubs"
    },
    "Nexus 7700 10-Slot Switch": {
      deviceTypeOrdinal:123456789, softwareType:"IOS-XE",
      runningImagePath:"Nexus_7700_10_Slot_Switch.js", productFamily:"Switches and Hubs"
    },
    "Cisco Mobility Express for Catalyst 9115AX Access Points": {
      deviceTypeOrdinal:286321056, softwareType:"Cisco Controller",
      runningImagePath:"Cisco_Mobility_Express_for_Catalyst_9115AX_Access_Points.js", productFamily:"Unified AP"
    }
  }
});