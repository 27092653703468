define({
    "getViewTemplate":
    {
        "version": "1.0",
        "response": [{
            "name": "DHCP_1668665866355_1668665866355",
            "id": "3f40062f-fb43-4e84-9cc9-9359ba26f0e7",
            "eventName": "DHCP",
            "timestamp": 1668665866355,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665866355"
            }, {
                "key": "endTime",
                "value": "1668665866355"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668665866355000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668665866355,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668665866003_1668665866346",
            "id": "ed58d116-0d67-402b-acb2-159451c551c5",
            "eventName": "Onboarding",
            "timestamp": 1668665866003,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665866003"
            }, {
                "key": "endTime",
                "value": "1668665866346"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668665866003000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668665866003,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668665866003000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668665866003,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668665866003000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668665866003,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668665866342000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668665866342,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668665866342000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668665866342,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668665866343000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668665866343,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668665866344000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668665866344,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668665866345000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668665866345,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668665866346000_#_9",
                "eventName": "Run",
                "timestamp": 1668665866346,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668665864905_1668665864906",
            "id": "3381ece2-e041-4636-a870-197e06fb9062",
            "eventName": "Associating",
            "timestamp": 1668665864905,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665864905"
            }, {
                "key": "endTime",
                "value": "1668665864906"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668665864905000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668665864905,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668665864905000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668665864905,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668665864906000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668665864906,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668665864191_1668665864191",
            "id": "b19c74ec-55b2-423c-8c6d-a60843b27d28",
            "eventName": "Delete",
            "timestamp": 1668665864191,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665864191"
            }, {
                "key": "endTime",
                "value": "1668665864191"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668665864191000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668665864191,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668665367160_1668665367160",
            "id": "aa325c98-6c2b-49c1-baeb-64eae75fcc69",
            "eventName": "DHCP",
            "timestamp": 1668665367160,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665367160"
            }, {
                "key": "endTime",
                "value": "1668665367160"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668665367160000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668665367160,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "INTRA-WLC Roaming_1668665364778_1668665364795",
            "id": "76fff816-1b7e-4028-b7d4-90d2f5a70f33",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1668665364778,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668665364778"
            }, {
                "key": "endTime",
                "value": "1668665364795"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668665364778000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668665364778,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668665364778000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668665364778,
                "index": 1,
                "eventType": 1002,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668665364778000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668665364778,
                "index": 2,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668665364780000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668665364780,
                "index": 3,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668665364793000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668665364793,
                "index": 4,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668665364793000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668665364793,
                "index": 5,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668665364794000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668665364794,
                "index": 6,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668665364795000_#_9",
                "eventName": "Run",
                "timestamp": 1668665364795,
                "index": 7,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668664065870_1668664065870",
            "id": "b138d435-1d5b-4596-956a-a46b57e7f55f",
            "eventName": "DHCP",
            "timestamp": 1668664065870,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668664065870"
            }, {
                "key": "endTime",
                "value": "1668664065870"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668664065870000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668664065870,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668664063545_1668664063570",
            "id": "fd20161b-ac5c-4e4b-a73b-0373237436b5",
            "eventName": "Onboarding",
            "timestamp": 1668664063545,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668664063545"
            }, {
                "key": "endTime",
                "value": "1668664063570"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668664063545000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668664063545,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668664063545000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668664063545,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668664063545000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668664063545,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668664063566000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668664063566,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668664063566000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668664063566,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668664063568000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668664063568,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668664063568000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668664063568,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668664063569000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668664063569,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668664063570000_#_9",
                "eventName": "Run",
                "timestamp": 1668664063570,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668664062403_1668664062403",
            "id": "23289713-0f2d-4721-b4c2-43b9193a703b",
            "eventName": "Associating",
            "timestamp": 1668664062403,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668664062403"
            }, {
                "key": "endTime",
                "value": "1668664062403"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668664062403000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668664062403,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668664062403000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668664062403,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668664062403000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668664062403,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668664062060_1668664062060",
            "id": "2fdc73fe-6342-47c3-8586-597ac57493ce",
            "eventName": "Delete",
            "timestamp": 1668664062060,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668664062060"
            }, {
                "key": "endTime",
                "value": "1668664062060"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668664062060000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668664062060,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668662264737_1668662264737",
            "id": "82602900-6aab-4869-86c4-61eca7fccae3",
            "eventName": "DHCP",
            "timestamp": 1668662264737,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668662264737"
            }, {
                "key": "endTime",
                "value": "1668662264737"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668662264737000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668662264737,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668662261504_1668662261845",
            "id": "f4742844-5da5-4d24-b0c3-ec48abdc0839",
            "eventName": "Onboarding",
            "timestamp": 1668662261504,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668662261504"
            }, {
                "key": "endTime",
                "value": "1668662261845"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668662261504000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668662261504,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668662261504000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668662261504,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668662261505000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668662261505,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668662261840000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668662261840,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668662261841000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668662261841,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668662261842000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668662261842,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668662261843000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668662261843,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668662261844000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668662261844,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668662261845000_#_9",
                "eventName": "Run",
                "timestamp": 1668662261845,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668662260373_1668662260373",
            "id": "67d4c00d-7eac-44cb-ba2e-6f8b499513cf",
            "eventName": "Associating",
            "timestamp": 1668662260373,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668662260373"
            }, {
                "key": "endTime",
                "value": "1668662260373"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668662260373000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668662260373,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668662260373000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668662260373,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668662260373000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668662260373,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668662259886_1668662259886",
            "id": "40d45d56-08ac-4a25-a104-d68b237c4b84",
            "eventName": "Delete",
            "timestamp": 1668662259886,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668662259886"
            }, {
                "key": "endTime",
                "value": "1668662259886"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668662259886000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668662259886,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668660459228_1668660459228",
            "id": "d212feab-45ac-4bde-8f5b-1c3f87f3c7e2",
            "eventName": "DHCP",
            "timestamp": 1668660459228,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668660459228"
            }, {
                "key": "endTime",
                "value": "1668660459228"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668660459228000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668660459228,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668660459145_1668660459201",
            "id": "337e08ea-4fc0-4f77-ba71-0add824a6d37",
            "eventName": "Onboarding",
            "timestamp": 1668660459145,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668660459145"
            }, {
                "key": "endTime",
                "value": "1668660459201"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668660459145000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668660459145,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668660459145000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668660459145,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668660459145000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668660459145,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668660459197000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668660459197,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668660459197000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668660459197,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668660459198000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668660459198,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668660459199000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668660459199,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668660459200000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668660459200,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668660459201000_#_9",
                "eventName": "Run",
                "timestamp": 1668660459201,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668660458068_1668660458068",
            "id": "ec9277da-6280-4a47-b13e-f090fa0235d6",
            "eventName": "Associating",
            "timestamp": 1668660458068,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668660458068"
            }, {
                "key": "endTime",
                "value": "1668660458068"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668660458068000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668660458068,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668660458068000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668660458068,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668660458068000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668660458068,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668660457732_1668660457732",
            "id": "4ea0f2c4-46c5-4874-954e-53044e7358e8",
            "eventName": "Delete",
            "timestamp": 1668660457732,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668660457732"
            }, {
                "key": "endTime",
                "value": "1668660457732"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668660457732000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668660457732,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668658657225_1668658657225",
            "id": "607b86d8-dcc3-4594-81f0-0d40f70b82db",
            "eventName": "DHCP",
            "timestamp": 1668658657225,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668658657225"
            }, {
                "key": "endTime",
                "value": "1668658657225"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668658657225000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668658657225,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668658657166_1668658657219",
            "id": "168870ff-3717-4060-856e-dca6d22495b7",
            "eventName": "Onboarding",
            "timestamp": 1668658657166,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668658657166"
            }, {
                "key": "endTime",
                "value": "1668658657219"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668658657166000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668658657166,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668658657166000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668658657166,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668658657166000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668658657166,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668658657215000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668658657215,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668658657215000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668658657215,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668658657217000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668658657217,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668658657217000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668658657217,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668658657218000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668658657218,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668658657219000_#_9",
                "eventName": "Run",
                "timestamp": 1668658657219,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668658655917_1668658655917",
            "id": "179a034f-6a29-4b8e-a07e-104b8bc73f40",
            "eventName": "Associating",
            "timestamp": 1668658655917,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668658655917"
            }, {
                "key": "endTime",
                "value": "1668658655917"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668658655917000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668658655917,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668658655917000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668658655917,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668658655917000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668658655917,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668658655576_1668658655576",
            "id": "163fbd80-a95a-4aeb-857f-7f163d6f3b02",
            "eventName": "Delete",
            "timestamp": 1668658655576,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668658655576"
            }, {
                "key": "endTime",
                "value": "1668658655576"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668658655576000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668658655576,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668656855264_1668656855264",
            "id": "a22c53ae-1f25-46aa-93e7-5185cbce416d",
            "eventName": "DHCP",
            "timestamp": 1668656855264,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668656855264"
            }, {
                "key": "endTime",
                "value": "1668656855264"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668656855264000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668656855264,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668656855220_1668656855257",
            "id": "57559d4e-eaa9-4a6b-ac84-c607ce5da6ec",
            "eventName": "Onboarding",
            "timestamp": 1668656855220,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668656855220"
            }, {
                "key": "endTime",
                "value": "1668656855257"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668656855220000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668656855220,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668656855220000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668656855220,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668656855221000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668656855221,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668656855253000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668656855253,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668656855254000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668656855254,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668656855255000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668656855255,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668656855256000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668656855256,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668656855256000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668656855256,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668656855257000_#_9",
                "eventName": "Run",
                "timestamp": 1668656855257,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668656854114_1668656854115",
            "id": "f388f87a-b320-490c-9493-3452ef0d9dfc",
            "eventName": "Associating",
            "timestamp": 1668656854114,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668656854114"
            }, {
                "key": "endTime",
                "value": "1668656854115"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668656854114000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668656854114,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668656854114000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668656854114,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668656854115000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668656854115,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668656853435_1668656853435",
            "id": "d0b8e39e-1a97-4550-bccd-6f9c6814c721",
            "eventName": "Delete",
            "timestamp": 1668656853435,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668656853435"
            }, {
                "key": "endTime",
                "value": "1668656853435"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668656853435000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668656853435,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668655104021_1668655104021",
            "id": "e8ae77a8-da34-4aef-8162-a17541d900ce",
            "eventName": "DHCP",
            "timestamp": 1668655104021,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655104021"
            }, {
                "key": "endTime",
                "value": "1668655104021"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668655104021000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655104021,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "INTRA-WLC Roaming_1668655102469_1668655102523",
            "id": "59b3f533-7bd2-4908-af4d-35693ce0f9e4",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1668655102469,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655102469"
            }, {
                "key": "endTime",
                "value": "1668655102523"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668655102469000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668655102469,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668655102470000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668655102470,
                "index": 1,
                "eventType": 1002,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668655102470000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668655102470,
                "index": 2,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668655102471000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668655102471,
                "index": 3,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668655102521000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668655102521,
                "index": 4,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668655102521000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668655102521,
                "index": 5,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668655102522000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668655102522,
                "index": 6,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668655102523000_#_9",
                "eventName": "Run",
                "timestamp": 1668655102523,
                "index": 7,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668655052499_1668655052499",
            "id": "8b6d5486-a892-44da-805f-52435cadcd35",
            "eventName": "DHCP",
            "timestamp": 1668655052499,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655052499"
            }, {
                "key": "endTime",
                "value": "1668655052499"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668655052499000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655052499,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668655052435_1668655052491",
            "id": "bf350891-b53a-4674-8515-899337623a37",
            "eventName": "Onboarding",
            "timestamp": 1668655052435,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655052435"
            }, {
                "key": "endTime",
                "value": "1668655052491"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668655052435000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668655052435,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668655052436000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668655052436,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668655052436000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668655052436,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668655052487000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668655052487,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668655052487000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668655052487,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668655052488000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668655052488,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668655052489000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668655052489,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668655052490000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655052490,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668655052491000_#_9",
                "eventName": "Run",
                "timestamp": 1668655052491,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668655052392_1668655052392",
            "id": "b819c852-b7f2-435f-b394-cf092a010243",
            "eventName": "Delete",
            "timestamp": 1668655052392,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655052392"
            }, {
                "key": "endTime",
                "value": "1668655052392"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668655052392000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655052392,
                "index": 1,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655052392000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655052392,
                "index": 2,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655052392000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655052392,
                "index": 3,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668655052392000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668655052392,
                "index": 4,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668655030681_1668655030681",
            "id": "7b04c9d3-8050-40da-a61e-256159b80d50",
            "eventName": "DHCP",
            "timestamp": 1668655030681,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655030681"
            }, {
                "key": "endTime",
                "value": "1668655030681"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668655030681000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655030681,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668655030492_1668655030493",
            "id": "62076559-bbaf-4560-a61a-0daad09b8651",
            "eventName": "Delete",
            "timestamp": 1668655030492,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655030492"
            }, {
                "key": "endTime",
                "value": "1668655030493"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668655030492000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655030492,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655030492000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655030492,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655030492000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655030492,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668655030493000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668655030493,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668655005099_1668655005099",
            "id": "040a54ae-beb9-4424-93fd-2d6820dda62d",
            "eventName": "DHCP",
            "timestamp": 1668655005099,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655005099"
            }, {
                "key": "endTime",
                "value": "1668655005099"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668655005099000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655005099,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668655003655_1668655003759",
            "id": "65497793-7a2d-477e-b1db-679f36550d03",
            "eventName": "Onboarding",
            "timestamp": 1668655003655,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655003655"
            }, {
                "key": "endTime",
                "value": "1668655003759"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668655003655000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668655003655,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668655003655000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668655003655,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668655003655000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668655003655,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668655003755000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668655003755,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668655003755000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668655003755,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668655003756000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668655003756,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668655003757000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668655003757,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668655003758000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668655003758,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668655003759000_#_9",
                "eventName": "Run",
                "timestamp": 1668655003759,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668655003566_1668655003567",
            "id": "6f187d24-8b71-4277-884a-f739d9c2a5e4",
            "eventName": "Delete",
            "timestamp": 1668655003566,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668655003566"
            }, {
                "key": "endTime",
                "value": "1668655003567"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668655003566000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655003566,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655003566000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655003566,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668655003566000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668655003566,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668655003567000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668655003567,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668653347771_1668653347771",
            "id": "9b300d01-ee0d-44b5-8496-ab8f3e939c19",
            "eventName": "DHCP",
            "timestamp": 1668653347771,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668653347771"
            }, {
                "key": "endTime",
                "value": "1668653347771"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668653347771000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668653347771,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668653347627_1668653347717",
            "id": "278478ad-d967-4db6-83b6-546ff4f92871",
            "eventName": "Onboarding",
            "timestamp": 1668653347627,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668653347627"
            }, {
                "key": "endTime",
                "value": "1668653347717"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668653347627000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668653347627,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668653347627000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668653347627,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668653347627000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668653347627,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668653347713000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668653347713,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668653347713000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668653347713,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668653347715000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668653347715,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668653347716000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668653347716,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668653347716000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668653347716,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668653347717000_#_9",
                "eventName": "Run",
                "timestamp": 1668653347717,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668653345486_1668653345486",
            "id": "f7de3d55-8604-460a-a3d9-4c1e1a5a94fb",
            "eventName": "Associating",
            "timestamp": 1668653345486,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668653345486"
            }, {
                "key": "endTime",
                "value": "1668653345486"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668653345486000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668653345486,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668653345486000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668653345486,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668653345486000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668653345486,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668653343137_1668653343137",
            "id": "65cb1532-32bb-449b-9477-e6c0b0aac027",
            "eventName": "Delete",
            "timestamp": 1668653343137,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668653343137"
            }, {
                "key": "endTime",
                "value": "1668653343137"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668653343137000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668653343137,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668651542680_1668651542680",
            "id": "48b24a10-9d5e-498d-a5b2-e6bde8c99f59",
            "eventName": "DHCP",
            "timestamp": 1668651542680,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668651542680"
            }, {
                "key": "endTime",
                "value": "1668651542680"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668651542680000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668651542680,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668651542655_1668651542672",
            "id": "c89c519b-8065-43a1-bb73-878afa0a45e6",
            "eventName": "Onboarding",
            "timestamp": 1668651542655,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668651542655"
            }, {
                "key": "endTime",
                "value": "1668651542672"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668651542655000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668651542655,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668651542656000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668651542656,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668651542656000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668651542656,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668651542668000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668651542668,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668651542668000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668651542668,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668651542669000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668651542669,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668651542670000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668651542670,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668651542671000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668651542671,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668651542672000_#_9",
                "eventName": "Run",
                "timestamp": 1668651542672,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668651536259_1668651539265",
            "id": "81179048-9b62-419b-a9b9-196e43c921c3",
            "eventName": "Authenticating",
            "timestamp": 1668651536259,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668651536259"
            }, {
                "key": "endTime",
                "value": "1668651539265"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668651536259000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668651536259,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668651536259000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668651536259,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668651536260000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668651536260,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668651539265000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668651539265,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668651539265000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668651539265,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668651535150_1668651535150",
            "id": "8def9107-8a0f-4afd-95b9-78cee08c3998",
            "eventName": "Associating",
            "timestamp": 1668651535150,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-24 dBm"
            }, {
                "key": "SNR",
                "value": "71 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668651535150"
            }, {
                "key": "endTime",
                "value": "1668651535150"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668651535150000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668651535150,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-24 dBm"
                }, {
                    "key": "SNR",
                    "value": "71 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668651535150000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668651535150,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668651535150000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668651535150,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668651532985_1668651532985",
            "id": "a86a25e8-741d-44c0-ac72-4aa20c383c32",
            "eventName": "Delete",
            "timestamp": 1668651532985,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668651532985"
            }, {
                "key": "endTime",
                "value": "1668651532985"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668651532985000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668651532985,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668649732417_1668649732417",
            "id": "d267e926-6e10-45ad-b8dc-903e998d28d7",
            "eventName": "DHCP",
            "timestamp": 1668649732417,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668649732417"
            }, {
                "key": "endTime",
                "value": "1668649732417"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668649732417000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668649732417,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668649732393_1668649732411",
            "id": "0b36f359-0fce-4559-863a-02c2c6267438",
            "eventName": "Onboarding",
            "timestamp": 1668649732393,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668649732393"
            }, {
                "key": "endTime",
                "value": "1668649732411"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668649732393000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668649732393,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668649732394000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668649732394,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668649732394000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668649732394,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668649732406000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668649732406,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668649732407000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668649732407,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668649732408000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668649732408,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668649732409000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668649732409,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668649732410000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668649732410,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668649732411000_#_9",
                "eventName": "Run",
                "timestamp": 1668649732411,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668649729263_1668649732271",
            "id": "d2d478f3-c9e2-4d03-98d8-c308894dc0ca",
            "eventName": "Authenticating",
            "timestamp": 1668649729263,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668649729263"
            }, {
                "key": "endTime",
                "value": "1668649732271"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668649729263000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668649729263,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668649729263000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668649729263,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668649729264000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668649729264,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668649732270000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668649732270,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668649732271000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668649732271,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668649728168_1668649728168",
            "id": "cf6a4fbe-c9f1-4afa-88a6-0f60e70bda7e",
            "eventName": "Associating",
            "timestamp": 1668649728168,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668649728168"
            }, {
                "key": "endTime",
                "value": "1668649728168"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668649728168000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668649728168,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668649728168000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668649728168,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668649728168000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668649728168,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668649725847_1668649725847",
            "id": "3bfa682e-29bf-4e92-9590-df7972de0921",
            "eventName": "Delete",
            "timestamp": 1668649725847,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668649725847"
            }, {
                "key": "endTime",
                "value": "1668649725847"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668649725847000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668649725847,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668647924849_1668647924849",
            "id": "7a6dc3f0-68e8-4f53-8aa2-c803e9e4290a",
            "eventName": "DHCP",
            "timestamp": 1668647924849,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668647924849"
            }, {
                "key": "endTime",
                "value": "1668647924849"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668647924849000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668647924849,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668647924808_1668647924842",
            "id": "9443df82-f5ae-464c-bea0-0bc0aebbdfaa",
            "eventName": "Onboarding",
            "timestamp": 1668647924808,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668647924808"
            }, {
                "key": "endTime",
                "value": "1668647924842"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668647924808000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668647924808,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668647924808000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668647924808,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668647924808000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668647924808,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668647924837000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668647924837,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668647924837000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668647924837,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668647924839000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668647924839,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668647924840000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668647924840,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668647924841000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668647924841,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668647924842000_#_9",
                "eventName": "Run",
                "timestamp": 1668647924842,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668647921473_1668647924480",
            "id": "fa89bd75-be98-4037-bc43-9d13d70a368f",
            "eventName": "Authenticating",
            "timestamp": 1668647921473,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668647921473"
            }, {
                "key": "endTime",
                "value": "1668647924480"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668647921473000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668647921473,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668647921473000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668647921473,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668647921473000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668647921473,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668647924480000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668647924480,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668647924480000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668647924480,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668647920223_1668647920223",
            "id": "ba7fd6ea-8c61-4a76-a115-7c8b27156e43",
            "eventName": "Associating",
            "timestamp": 1668647920223,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668647920223"
            }, {
                "key": "endTime",
                "value": "1668647920223"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668647920223000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668647920223,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668647920223000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668647920223,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668647920223000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668647920223,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668647918714_1668647918714",
            "id": "b103273b-e43b-4b28-b08b-98529ce4dd69",
            "eventName": "Delete",
            "timestamp": 1668647918714,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668647918714"
            }, {
                "key": "endTime",
                "value": "1668647918714"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668647918714000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668647918714,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668646117916_1668646117916",
            "id": "e6a70676-94f7-4fa9-8d7a-e6be1a4afdf8",
            "eventName": "DHCP",
            "timestamp": 1668646117916,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668646117916"
            }, {
                "key": "endTime",
                "value": "1668646117916"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668646117916000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668646117916,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668646117890_1668646117908",
            "id": "9538294b-ab34-45f5-89b6-48373bf056bd",
            "eventName": "Onboarding",
            "timestamp": 1668646117890,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668646117890"
            }, {
                "key": "endTime",
                "value": "1668646117908"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668646117890000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668646117890,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668646117890000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668646117890,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668646117891000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668646117891,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668646117903000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668646117903,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668646117904000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668646117904,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668646117905000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668646117905,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668646117906000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668646117906,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668646117907000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668646117907,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668646117908000_#_9",
                "eventName": "Run",
                "timestamp": 1668646117908,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668646114741_1668646117749",
            "id": "d14ad2e5-f6ae-41eb-b178-e75d21089cef",
            "eventName": "Authenticating",
            "timestamp": 1668646114741,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668646114741"
            }, {
                "key": "endTime",
                "value": "1668646117749"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668646114741000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668646114741,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668646114741000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668646114741,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668646114742000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668646114742,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668646117748000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668646117748,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668646117749000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668646117749,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668646113649_1668646113650",
            "id": "6bf1bff6-c7eb-44f9-aab7-33fc18943bef",
            "eventName": "Associating",
            "timestamp": 1668646113649,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668646113649"
            }, {
                "key": "endTime",
                "value": "1668646113650"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668646113649000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668646113649,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668646113649000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668646113649,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668646113650000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668646113650,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668646111551_1668646111551",
            "id": "ca8d3fef-080f-4705-9c26-b3c83f60cbe4",
            "eventName": "Delete",
            "timestamp": 1668646111551,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668646111551"
            }, {
                "key": "endTime",
                "value": "1668646111551"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668646111551000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668646111551,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668644311290_1668644311290",
            "id": "4d2e9340-bc98-499f-a65f-6222d400ea0d",
            "eventName": "DHCP",
            "timestamp": 1668644311290,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668644311290"
            }, {
                "key": "endTime",
                "value": "1668644311290"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668644311290000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668644311290,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668644311266_1668644311282",
            "id": "c9fc4bbe-ee64-4cd3-98c4-4c0b163f5dac",
            "eventName": "Onboarding",
            "timestamp": 1668644311266,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668644311266"
            }, {
                "key": "endTime",
                "value": "1668644311282"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668644311266000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668644311266,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668644311266000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668644311266,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668644311267000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668644311267,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668644311278000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668644311278,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668644311279000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668644311279,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668644311280000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668644311280,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668644311281000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668644311281,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668644311282000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668644311282,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668644311282000_#_9",
                "eventName": "Run",
                "timestamp": 1668644311282,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668644307458_1668644310465",
            "id": "d56372e9-e4ec-4a65-91bb-caa7ffed6a7e",
            "eventName": "Authenticating",
            "timestamp": 1668644307458,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668644307458"
            }, {
                "key": "endTime",
                "value": "1668644310465"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668644307458000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668644307458,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668644307458000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668644307458,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668644307458000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668644307458,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668644310465000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668644310465,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668644310465000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668644310465,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668644306384_1668644306384",
            "id": "c65ebfd9-c1bd-47fa-95c7-38d5b57b1147",
            "eventName": "Associating",
            "timestamp": 1668644306384,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668644306384"
            }, {
                "key": "endTime",
                "value": "1668644306384"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668644306384000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668644306384,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668644306384000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668644306384,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668644306384000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668644306384,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668644305389_1668644305389",
            "id": "681a5825-8acf-4eb5-a63b-0dd84834c50d",
            "eventName": "Delete",
            "timestamp": 1668644305389,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668644305389"
            }, {
                "key": "endTime",
                "value": "1668644305389"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668644305389000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668644305389,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668642506660_1668642506660",
            "id": "fb24152f-0d31-4ed2-b782-0f869e7d67ae",
            "eventName": "DHCP",
            "timestamp": 1668642506660,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668642506660"
            }, {
                "key": "endTime",
                "value": "1668642506660"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668642506660000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668642506660,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668642505024_1668642505041",
            "id": "43929cdc-bbc7-4185-bd2d-ca2a516f74cb",
            "eventName": "Onboarding",
            "timestamp": 1668642505024,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668642505024"
            }, {
                "key": "endTime",
                "value": "1668642505041"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668642505024000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668642505024,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668642505024000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668642505024,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668642505025000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668642505025,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668642505037000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668642505037,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668642505037000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668642505037,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668642505039000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668642505039,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668642505040000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668642505040,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668642505040000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668642505040,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668642505041000_#_9",
                "eventName": "Run",
                "timestamp": 1668642505041,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668642501857_1668642504882",
            "id": "2a52efaf-9a00-4192-b03b-2ac13c311e46",
            "eventName": "Authenticating",
            "timestamp": 1668642501857,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to 4 way Key Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668642501857"
            }, {
                "key": "endTime",
                "value": "1668642504882"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668642501857000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668642501857,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668642501858000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668642501858,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668642501858000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668642501858,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668642504882000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668642504882,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668642504882000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668642504882,
                "index": 4,
                "eventType": 11,
                "reasonType": 103,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "4 way Key Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668642501839_1668642501840",
            "id": "fa7ee463-842e-4a87-9e0c-febc4d443540",
            "eventName": "Delete",
            "timestamp": 1668642501839,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668642501839"
            }, {
                "key": "endTime",
                "value": "1668642501840"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668642501839000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668642501839,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668642501839000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668642501839,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668642501840000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668642501840,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668642501840000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668642501840,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authentication Done_1668642066261_1668642066261",
            "id": "5cca0231-f16c-4920-a3c1-c43184af05f3",
            "eventName": "Authentication Done",
            "timestamp": 1668642066261,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668642066261"
            }, {
                "key": "endTime",
                "value": "1668642066261"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Authentication Done",
                "id": "1668642066261000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668642066261,
                "index": 0,
                "eventType": 4,
                "reasonType": 1113,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "KM Received EAP Packet"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668641776174_1668641776174",
            "id": "2ee3a699-3d48-4ed9-91c9-ee18c553d93f",
            "eventName": "DHCP",
            "timestamp": 1668641776174,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641776174"
            }, {
                "key": "endTime",
                "value": "1668641776174"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668641776174000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668641776174,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668641773157_1668641773225",
            "id": "f70c16d8-b467-42cb-9320-e2665049a9c1",
            "eventName": "Onboarding",
            "timestamp": 1668641773157,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641773157"
            }, {
                "key": "endTime",
                "value": "1668641773225"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668641773157000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668641773157,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668641773157000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668641773157,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668641773158000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668641773158,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668641773220000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668641773220,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668641773221000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668641773221,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668641773222000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668641773222,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668641773223000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668641773223,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668641773224000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668641773224,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668641773225000_#_9",
                "eventName": "Run",
                "timestamp": 1668641773225,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668641773135_1668641773135",
            "id": "72eabf7e-b709-4978-967d-0c186aa00bb1",
            "eventName": "Associating",
            "timestamp": 1668641773135,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641773135"
            }, {
                "key": "endTime",
                "value": "1668641773135"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668641773135000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668641773135,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668641773135000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668641773135,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668641773135000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668641773135,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668641771187_1668641771187",
            "id": "f4bb29c7-2216-4d7a-a5a1-29e0b0d66bb6",
            "eventName": "Associating",
            "timestamp": 1668641771187,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641771187"
            }, {
                "key": "endTime",
                "value": "1668641771187"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668641771187000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668641771187,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668641771187000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668641771187,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668641771187000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668641771187,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668641770119_1668641770120",
            "id": "3c5257f1-2ec6-47d2-9999-1664dc1bdcc4",
            "eventName": "Delete",
            "timestamp": 1668641770119,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641770119"
            }, {
                "key": "endTime",
                "value": "1668641770120"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668641770119000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668641770119,
                "index": 1,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668641770119000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668641770119,
                "index": 2,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668641770120000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668641770120,
                "index": 3,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668641770120000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668641770120,
                "index": 4,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668641558290_1668641558290",
            "id": "6204ba44-53a4-4da5-b2d0-9f4a5624a73c",
            "eventName": "DHCP",
            "timestamp": 1668641558290,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641558290"
            }, {
                "key": "endTime",
                "value": "1668641558290"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668641558290000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668641558290,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668641557481_1668641557481",
            "id": "4bef7f70-0c09-493f-9cf7-06b8e8c272f8",
            "eventName": "Delete",
            "timestamp": 1668641557481,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "SAE Commit received in Associated State"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to SAE Auth in Associated State/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668641557481"
            }, {
                "key": "endTime",
                "value": "1668641557481"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668641557481000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668641557481,
                "index": 0,
                "eventType": 11,
                "reasonType": 1346,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "SAE Auth in Associated State"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "SAE Commit received in Associated State"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668640392666_1668640392666",
            "id": "32f1c430-b448-4dd2-a6d2-1f58b4337369",
            "eventName": "DHCP",
            "timestamp": 1668640392666,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668640392666"
            }, {
                "key": "endTime",
                "value": "1668640392666"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668640392666000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668640392666,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668640586128_1668640586128",
            "id": "a793c848-a6f6-4c22-9759-f103d234be98",
            "eventName": "Reported Errors",
            "timestamp": 1668640586128,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668640586128000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668640586128,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668640586128_1668640586128",
            "id": "e3b00b06-a978-4930-80de-03941db67da7",
            "eventName": "Roam Report",
            "timestamp": 1668640586128,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668640586128000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668640586128,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668640392643_1668640392659",
            "id": "d4638972-862f-466c-bda0-f79a1c4d7b4a",
            "eventName": "Onboarding",
            "timestamp": 1668640392643,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668640392643"
            }, {
                "key": "endTime",
                "value": "1668640392659"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668640392643000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668640392643,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668640392643000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668640392643,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668640392643000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668640392643,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668640392655000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668640392655,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668640392655000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668640392655,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668640392656000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668640392656,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668640392657000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668640392657,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668640392658000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668640392658,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668640392659000_#_9",
                "eventName": "Run",
                "timestamp": 1668640392659,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668640390908_1668640391962",
            "id": "67424977-97ca-498c-a70c-9ae231d81f60",
            "eventName": "Delete",
            "timestamp": 1668640390908,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "Delete Reason Description",
                "value": "SAE Commit received in Associated State"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to SAE Auth in Associated State/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668640390908"
            }, {
                "key": "endTime",
                "value": "1668640391962"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668640390908000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668640390908,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668640390908000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668640390908,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668640390908000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668640390908,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668640391962000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668640391962,
                "index": 3,
                "eventType": 11,
                "reasonType": 1346,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "SAE Auth in Associated State"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "SAE Commit received in Associated State"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668640390877_1668640390878",
            "id": "93f55d7c-a726-4b6f-96cd-eee65cc936a7",
            "eventName": "Delete",
            "timestamp": 1668640390877,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668640390877"
            }, {
                "key": "endTime",
                "value": "1668640390878"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668640390877000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668640390877,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668640390877000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668640390877,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668640390877000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668640390877,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668640390878000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668640390878,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668638879247_1668638879247",
            "id": "ad5d44cc-866b-4d60-912c-566a5aedf9be",
            "eventName": "DHCP",
            "timestamp": 1668638879247,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668638879247"
            }, {
                "key": "endTime",
                "value": "1668638879247"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668638879247000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668638879247,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668640084581_1668640084581",
            "id": "78c4c7aa-03b8-4e24-9f44-c6257fd93bd7",
            "eventName": "Roam Report",
            "timestamp": 1668640084581,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -50 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-50 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -43
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668640084581000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668640084581,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -50 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-50 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -43
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668640084581_1668640084581",
            "id": "5707534c-3f1c-41e9-943f-8f1a573897d4",
            "eventName": "Reported Errors",
            "timestamp": 1668640084581,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668640084581000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668640084581,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668638879213_1668638879241",
            "id": "11762ebf-3caa-4726-9446-f9caea49dc94",
            "eventName": "Onboarding",
            "timestamp": 1668638879213,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668638879213"
            }, {
                "key": "endTime",
                "value": "1668638879241"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668638879213000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668638879213,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668638879214000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668638879214,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668638879214000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668638879214,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668638879236000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668638879236,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668638879237000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668638879237,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668638879238000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668638879238,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668638879239000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668638879239,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668638879240000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668638879240,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668638879241000_#_9",
                "eventName": "Run",
                "timestamp": 1668638879241,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668638878849_1668638878849",
            "id": "126c71df-1194-4f0f-a194-c975b2ca7d11",
            "eventName": "Delete",
            "timestamp": 1668638878849,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "SAE Commit received in Associated State"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to SAE Auth in Associated State/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668638878849"
            }, {
                "key": "endTime",
                "value": "1668638878849"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668638878849000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668638878849,
                "index": 0,
                "eventType": 11,
                "reasonType": 1346,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "SAE Auth in Associated State"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "SAE Commit received in Associated State"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668637854340_1668637854340",
            "id": "5b10e3b7-cf6a-4bf8-bc29-b20183f87d15",
            "eventName": "DHCP",
            "timestamp": 1668637854340,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668637854340"
            }, {
                "key": "endTime",
                "value": "1668637854340"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668637854340000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668637854340,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668638783382_1668638783382",
            "id": "5fc3851e-3673-48ed-970a-b62badfc1972",
            "eventName": "Reported Errors",
            "timestamp": 1668638783382,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668638783382000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668638783382,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668638783382_1668638783382",
            "id": "a7f07a31-84e6-4256-9506-b4850c12bcaa",
            "eventName": "Roam Report",
            "timestamp": 1668638783382,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-57 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -43
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668638783382000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668638783382,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-57 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -43
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668637854311_1668637854331",
            "id": "0ce0fe8c-b096-451e-bd1f-17c91735119e",
            "eventName": "Onboarding",
            "timestamp": 1668637854311,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668637854311"
            }, {
                "key": "endTime",
                "value": "1668637854331"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668637854311000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668637854311,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668637854311000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668637854311,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668637854312000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668637854312,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668637854327000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668637854327,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668637854327000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668637854327,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668637854329000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668637854329,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668637854330000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668637854330,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668637854330000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668637854330,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668637854331000_#_9",
                "eventName": "Run",
                "timestamp": 1668637854331,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668637854257_1668637854257",
            "id": "904e9f84-1044-4bb9-bdae-e786385e881d",
            "eventName": "Delete",
            "timestamp": 1668637854257,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668637854257"
            }, {
                "key": "endTime",
                "value": "1668637854257"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668637854257000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668637854257,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668637854257000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668637854257,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668637854257000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668637854257,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668637854257000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668637854257,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668636724246_1668636724246",
            "id": "1d93d0cb-bc28-4cab-81f1-a9db1923ec6c",
            "eventName": "DHCP",
            "timestamp": 1668636724246,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668636724246"
            }, {
                "key": "endTime",
                "value": "1668636724246"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668636724246000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668636724246,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668636981696_1668636981696",
            "id": "943b499d-b174-45e2-8dc5-880df0941f5a",
            "eventName": "Reported Errors",
            "timestamp": 1668636981696,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:10",
                    "bssid": "12:3F:0B:F2:B1:15",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668636981696000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668636981696,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:10",
                        "bssid": "12:3F:0B:F2:B1:15",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668636981696_1668636981696",
            "id": "b2d80950-1c46-4cdb-9ead-ca86b796c694",
            "eventName": "Roam Report",
            "timestamp": 1668636981696,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-57 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -45
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668636981696000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668636981696,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-57 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -45
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668636722624_1668636722654",
            "id": "fc86a760-17fc-42df-a057-7e57957920c2",
            "eventName": "Onboarding",
            "timestamp": 1668636722624,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668636722624"
            }, {
                "key": "endTime",
                "value": "1668636722654"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668636722624000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668636722624,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668636722625000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668636722625,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668636722625000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668636722625,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668636722650000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668636722650,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668636722650000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668636722650,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668636722652000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668636722652,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668636722652000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668636722652,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668636722653000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668636722653,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668636722654000_#_9",
                "eventName": "Run",
                "timestamp": 1668636722654,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668636720623_1668636720623",
            "id": "52f08b63-0d29-45c9-9027-5afd23a7db06",
            "eventName": "Associating",
            "timestamp": 1668636720623,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668636720623"
            }, {
                "key": "endTime",
                "value": "1668636720623"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668636720623000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668636720623,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668636720623000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668636720623,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668636720623000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668636720623,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668636718945_1668636718945",
            "id": "c8470f88-50da-4c78-ac61-52273d187379",
            "eventName": "Associating",
            "timestamp": 1668636718945,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668636718945"
            }, {
                "key": "endTime",
                "value": "1668636718945"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668636718945000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668636718945,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668636718945000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668636718945,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668636718945000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668636718945,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668636716737_1668636716737",
            "id": "9ecc70a5-e883-4093-bf4c-b0bb39938841",
            "eventName": "Delete",
            "timestamp": 1668636716737,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668636716737"
            }, {
                "key": "endTime",
                "value": "1668636716737"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668636716737000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668636716737,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668634916404_1668634916404",
            "id": "78128196-ccba-4b50-97b4-fcff61ce95f4",
            "eventName": "DHCP",
            "timestamp": 1668634916404,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668634916404"
            }, {
                "key": "endTime",
                "value": "1668634916404"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668634916404000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668634916404,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668635179062_1668635179062",
            "id": "a0f9f951-bdbc-4759-9907-34d2e7cc1385",
            "eventName": "Reported Errors",
            "timestamp": 1668635179062,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668635179062000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668635179062,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668635179062_1668635179062",
            "id": "dd415f36-2097-4042-8d6f-9eb7dd7f4d6b",
            "eventName": "Roam Report",
            "timestamp": 1668635179062,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-57 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -44
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668635179062000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668635179062,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-57 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -44
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668634916378_1668634916397",
            "id": "7cda00f6-a148-46f7-9678-b0b2f496ff01",
            "eventName": "Onboarding",
            "timestamp": 1668634916378,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668634916378"
            }, {
                "key": "endTime",
                "value": "1668634916397"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668634916378000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668634916378,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668634916378000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668634916378,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668634916379000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668634916379,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668634916393000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668634916393,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668634916393000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668634916393,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668634916394000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668634916394,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668634916395000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668634916395,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668634916396000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668634916396,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668634916397000_#_9",
                "eventName": "Run",
                "timestamp": 1668634916397,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668634914497_1668634914497",
            "id": "bf6a1d11-a809-4280-ba08-7e687c03da38",
            "eventName": "Associating",
            "timestamp": 1668634914497,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668634914497"
            }, {
                "key": "endTime",
                "value": "1668634914497"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668634914497000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668634914497,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668634914497000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668634914497,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668634914497000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668634914497,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668634912586_1668634912586",
            "id": "68574c4a-f2a7-409c-8e03-1f8697395bd4",
            "eventName": "Delete",
            "timestamp": 1668634912586,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668634912586"
            }, {
                "key": "endTime",
                "value": "1668634912586"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668634912586000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668634912586,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668633111588_1668633111588",
            "id": "5fe02273-e91d-4cc2-a8e8-0c1069498a09",
            "eventName": "DHCP",
            "timestamp": 1668633111588,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668633111588"
            }, {
                "key": "endTime",
                "value": "1668633111588"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668633111588000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668633111588,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668633377100_1668633377100",
            "id": "679a3d6b-1304-462b-91e0-bcc80f513295",
            "eventName": "Roam Report",
            "timestamp": 1668633377100,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-57 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -45
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668633377100000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668633377100,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -57 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-57 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -45
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668633377100_1668633377100",
            "id": "c6f537e6-d35a-4583-9264-bae8302577fd",
            "eventName": "Reported Errors",
            "timestamp": 1668633377100,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668633377100000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668633377100,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668633111545_1668633111580",
            "id": "52fabbd2-1430-4568-ba7c-dfdc1c774892",
            "eventName": "Onboarding",
            "timestamp": 1668633111545,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668633111545"
            }, {
                "key": "endTime",
                "value": "1668633111580"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668633111545000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668633111545,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668633111546000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668633111546,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668633111546000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668633111546,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668633111576000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668633111576,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668633111576000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668633111576,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668633111578000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668633111578,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668633111579000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668633111579,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668633111579000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668633111579,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668633111580000_#_9",
                "eventName": "Run",
                "timestamp": 1668633111580,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668633109421_1668633109421",
            "id": "63233a2c-e620-4164-b72a-c04503398a5c",
            "eventName": "Delete",
            "timestamp": 1668633109421,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668633109421"
            }, {
                "key": "endTime",
                "value": "1668633109421"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668633109421000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668633109421,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668631308801_1668631308801",
            "id": "766b30b8-dcb9-43ce-b606-36cba91c7180",
            "eventName": "DHCP",
            "timestamp": 1668631308801,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631308801"
            }, {
                "key": "endTime",
                "value": "1668631308801"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668631308801000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668631308801,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668631575165_1668631575165",
            "id": "ff41bad0-14f0-477e-893a-da3b51aeed60",
            "eventName": "Reported Errors",
            "timestamp": 1668631575165,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668631575165000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668631575165,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668631575164_1668631575164",
            "id": "cdd93879-dc7f-4b58-a6a4-f99e59a0a73f",
            "eventName": "Roam Report",
            "timestamp": 1668631575164,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -56 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-56 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -44
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668631575164000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668631575164,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -56 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-56 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -44
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668631308734_1668631308785",
            "id": "d2affdd5-8301-4f08-80d7-ab23ccb5f820",
            "eventName": "Onboarding",
            "timestamp": 1668631308734,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631308734"
            }, {
                "key": "endTime",
                "value": "1668631308785"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668631308734000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668631308734,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668631308735000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668631308735,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668631308735000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668631308735,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668631308781000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668631308781,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668631308781000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668631308781,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668631308783000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668631308783,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668631308784000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668631308784,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668631308784000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668631308784,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668631308785000_#_9",
                "eventName": "Run",
                "timestamp": 1668631308785,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668631306340_1668631306340",
            "id": "c1e4924f-b307-42dd-97c6-06ecb404da1f",
            "eventName": "Associating",
            "timestamp": 1668631306340,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-26 dBm"
            }, {
                "key": "SNR",
                "value": "69 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631306340"
            }, {
                "key": "endTime",
                "value": "1668631306340"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668631306340000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668631306340,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-26 dBm"
                }, {
                    "key": "SNR",
                    "value": "69 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668631306340000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668631306340,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668631306340000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668631306340,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668631303007_1668631306016",
            "id": "9b380777-e2eb-4802-803a-c0ad00f70014",
            "eventName": "Authenticating",
            "timestamp": 1668631303007,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-25 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "70 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631303007"
            }, {
                "key": "endTime",
                "value": "1668631306016"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668631303007000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668631303007,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-25 dBm"
                }, {
                    "key": "SNR",
                    "value": "70 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668631303008000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668631303008,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668631303008000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668631303008,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668631306015000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668631306015,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668631306016000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668631306016,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668631301896_1668631301896",
            "id": "be620134-9ab8-450b-ab77-2ced9c8e8210",
            "eventName": "Associating",
            "timestamp": 1668631301896,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "65 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631301896"
            }, {
                "key": "endTime",
                "value": "1668631301896"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668631301896000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668631301896,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "65 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668631301896000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668631301896,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668631301896000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668631301896,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668631301266_1668631301266",
            "id": "e45de549-aee6-499e-b16c-ad5ba71d468d",
            "eventName": "Delete",
            "timestamp": 1668631301266,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668631301266"
            }, {
                "key": "endTime",
                "value": "1668631301266"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668631301266000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668631301266,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668629501027_1668629501027",
            "id": "b3ae12c0-1b76-46bd-ab0a-36d8b1d59af9",
            "eventName": "DHCP",
            "timestamp": 1668629501027,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668629501027"
            }, {
                "key": "endTime",
                "value": "1668629501027"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668629501027000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668629501027,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668629822476_1668629822476",
            "id": "057d78ac-6e6b-4194-9a84-36345914487d",
            "eventName": "Reported Errors",
            "timestamp": 1668629822476,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629822476000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668629822476,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668629822475_1668629822475",
            "id": "2349b1db-c3fc-48aa-9478-19946c0d0157",
            "eventName": "Roam Report",
            "timestamp": 1668629822475,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -50 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-50 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629822475000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668629822475,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -50 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-50 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668629772427_1668629772427",
            "id": "ca41dda6-cfe8-47ad-b522-0eceb435fbc4",
            "eventName": "Roam Report",
            "timestamp": 1668629772427,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629772427000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668629772427,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668629750588_1668629750588",
            "id": "87ba5704-e0db-4e77-be50-3fffd20d3d2a",
            "eventName": "Reported Errors",
            "timestamp": 1668629750588,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629750588000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668629750588,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668629750588_1668629750588",
            "id": "99561f0d-e68d-4269-ada7-ee060908ddf9",
            "eventName": "Roam Report",
            "timestamp": 1668629750588,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629750588000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668629750588,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668629723699_1668629723699",
            "id": "e95fde49-34bb-4383-9fd8-ad7464ae293a",
            "eventName": "Reported Errors",
            "timestamp": 1668629723699,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629723699000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668629723699,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668629723699_1668629723699",
            "id": "3ff07d15-a860-4cfc-a809-d68cd30912e7",
            "eventName": "Roam Report",
            "timestamp": 1668629723699,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668629723699000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668629723699,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668629501001_1668629501019",
            "id": "d19ffbe7-64c8-43f2-adc9-7e39caafe44e",
            "eventName": "Onboarding",
            "timestamp": 1668629501001,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668629501001"
            }, {
                "key": "endTime",
                "value": "1668629501019"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668629501001000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668629501001,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668629501001000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668629501001,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668629501001000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668629501001,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668629501015000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668629501015,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668629501015000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668629501015,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668629501016000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668629501016,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668629501017000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668629501017,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668629501018000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668629501018,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668629501019000_#_9",
                "eventName": "Run",
                "timestamp": 1668629501019,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668629496191_1668629499218",
            "id": "e5f8a9ab-58f5-4e5f-9e04-efb24a7d9599",
            "eventName": "Authenticating",
            "timestamp": 1668629496191,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668629496191"
            }, {
                "key": "endTime",
                "value": "1668629499218"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668629496191000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668629496191,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668629496191000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668629496191,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668629496192000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668629496192,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668629499217000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668629499217,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668629499218000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668629499218,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668629494753_1668629494754",
            "id": "6f574c27-c52f-4b80-a0c2-6b5d244f5968",
            "eventName": "Associating",
            "timestamp": 1668629494753,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668629494753"
            }, {
                "key": "endTime",
                "value": "1668629494754"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668629494753000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668629494753,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668629494754000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668629494754,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668629494754000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668629494754,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668629494138_1668629494138",
            "id": "5fd5fafd-60ec-48e4-bdd4-83006dcb88eb",
            "eventName": "Delete",
            "timestamp": 1668629494138,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668629494138"
            }, {
                "key": "endTime",
                "value": "1668629494138"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668629494138000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668629494138,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668627695187_1668627695187",
            "id": "2e0ef498-5c8f-477a-9f37-131b88bf14f1",
            "eventName": "DHCP",
            "timestamp": 1668627695187,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668627695187"
            }, {
                "key": "endTime",
                "value": "1668627695187"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668627695187000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668627695187,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668628067689_1668628067689",
            "id": "36bbf1d1-b3c0-4856-8a81-ece4e8623170",
            "eventName": "Roam Report",
            "timestamp": 1668628067689,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -50
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -58
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668628067689000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668628067689,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -50
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -58
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668628067689_1668628067689",
            "id": "190eeeab-697e-4843-8386-17123a19cd9c",
            "eventName": "Reported Errors",
            "timestamp": 1668628067689,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668628067689000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668628067689,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668627693200_1668627693216",
            "id": "eae472a3-da18-47e9-a2ce-2d5fffd81f11",
            "eventName": "Onboarding",
            "timestamp": 1668627693200,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668627693200"
            }, {
                "key": "endTime",
                "value": "1668627693216"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668627693200000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668627693200,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668627693201000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668627693201,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668627693201000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668627693201,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668627693212000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668627693212,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668627693212000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668627693212,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668627693214000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668627693214,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668627693215000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668627693215,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668627693215000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668627693215,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668627693216000_#_9",
                "eventName": "Run",
                "timestamp": 1668627693216,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668627690051_1668627693060",
            "id": "0c04729e-e18a-43ec-a7ab-7946f88ff0d7",
            "eventName": "Authenticating",
            "timestamp": 1668627690051,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668627690051"
            }, {
                "key": "endTime",
                "value": "1668627693060"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668627690051000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668627690051,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668627690051000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668627690051,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668627690051000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668627690051,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668627693060000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668627693060,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668627693060000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668627693060,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668627688902_1668627688903",
            "id": "12f9a3c1-8bed-4d42-9404-b6035f5d1fe2",
            "eventName": "Associating",
            "timestamp": 1668627688902,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668627688902"
            }, {
                "key": "endTime",
                "value": "1668627688903"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668627688902000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668627688902,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668627688903000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668627688903,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668627688903000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668627688903,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668627687973_1668627687973",
            "id": "b2fa770f-a53d-4666-9f9f-4e276bc08539",
            "eventName": "Delete",
            "timestamp": 1668627687973,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668627687973"
            }, {
                "key": "endTime",
                "value": "1668627687973"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668627687973000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668627687973,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668625888521_1668625888521",
            "id": "b3251a33-f44a-4653-b64f-dffe8c2148c1",
            "eventName": "DHCP",
            "timestamp": 1668625888521,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625888521"
            }, {
                "key": "endTime",
                "value": "1668625888521"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668625888521000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668625888521,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668626262668_1668626262668",
            "id": "ea376e2a-eaee-496a-beaa-bc21cd1e757b",
            "eventName": "Reported Errors",
            "timestamp": 1668626262668,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668626262668000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668626262668,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668626262667_1668626262667",
            "id": "4d8b1f0c-bdee-43c8-8d6a-9b0436228ddc",
            "eventName": "Roam Report",
            "timestamp": 1668626262667,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -43
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668626262667000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668626262667,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -43
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668625887377_1668625887402",
            "id": "15087c0b-0a23-4e7f-9a88-34150f2d787c",
            "eventName": "Onboarding",
            "timestamp": 1668625887377,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625887377"
            }, {
                "key": "endTime",
                "value": "1668625887402"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668625887377000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668625887377,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668625887377000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668625887377,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668625887378000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668625887378,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668625887389000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668625887389,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668625887390000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668625887390,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668625887391000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668625887391,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668625887392000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668625887392,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668625887402000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668625887402,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668625887402000_#_9",
                "eventName": "Run",
                "timestamp": 1668625887402,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668625882670_1668625885678",
            "id": "8166802f-0ce1-4923-bf56-d0e0b6d15392",
            "eventName": "Authenticating",
            "timestamp": 1668625882670,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625882670"
            }, {
                "key": "endTime",
                "value": "1668625885678"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668625882670000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668625882670,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668625882670000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668625882670,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668625882671000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668625882671,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668625885677000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668625885677,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668625885678000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668625885678,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668625881010_1668625881010",
            "id": "760f878f-e188-4d1c-ba29-73ead8613153",
            "eventName": "Associating",
            "timestamp": 1668625881010,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625881010"
            }, {
                "key": "endTime",
                "value": "1668625881010"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668625881010000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668625881010,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668625881010000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668625881010,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668625881010000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668625881010,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668625877609_1668625877610",
            "id": "a13e5e56-1203-472c-93ac-3c01ffd3cecd",
            "eventName": "Associating",
            "timestamp": 1668625877609,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625877609"
            }, {
                "key": "endTime",
                "value": "1668625877610"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668625877609000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668625877609,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668625877610000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668625877610,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668625877610000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668625877610,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668625876822_1668625876822",
            "id": "82ebd972-50e3-410f-95c9-97710d1286ea",
            "eventName": "Delete",
            "timestamp": 1668625876822,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668625876822"
            }, {
                "key": "endTime",
                "value": "1668625876822"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668625876822000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668625876822,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668624076758_1668624076758",
            "id": "cc2c1548-36e2-4224-a715-ffc88f162d54",
            "eventName": "DHCP",
            "timestamp": 1668624076758,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668624076758"
            }, {
                "key": "endTime",
                "value": "1668624076758"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668624076758000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668624076758,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668624452429_1668624452429",
            "id": "907af033-eafd-48e8-b175-98310a53153e",
            "eventName": "Roam Report",
            "timestamp": 1668624452429,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -49
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668624452429000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668624452429,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -49
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668624452429_1668624452429",
            "id": "c7a62e17-cbe1-4aa0-ae4e-f90723e10b6d",
            "eventName": "Reported Errors",
            "timestamp": 1668624452429,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668624452429000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668624452429,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668624076129_1668624076752",
            "id": "0cbda188-87d5-45c2-bbe7-34ca62bd108f",
            "eventName": "Onboarding",
            "timestamp": 1668624076129,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668624076129"
            }, {
                "key": "endTime",
                "value": "1668624076752"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668624076129000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668624076129,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668624076129000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668624076129,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668624076130000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668624076130,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668624076747000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668624076747,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668624076748000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668624076748,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668624076749000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668624076749,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668624076750000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668624076750,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668624076751000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668624076751,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668624076752000_#_9",
                "eventName": "Run",
                "timestamp": 1668624076752,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668624074331_1668624074337",
            "id": "f2bef361-aaec-4a4e-8530-7fe82c87376b",
            "eventName": "Associating",
            "timestamp": 1668624074331,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668624074331"
            }, {
                "key": "endTime",
                "value": "1668624074337"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668624074331000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668624074331,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668624074331000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668624074331,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668624074337000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668624074337,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668623425194_1668623425194",
            "id": "d4f84f5f-87ed-4b31-8b29-83520a4273fd",
            "eventName": "DHCP",
            "timestamp": 1668623425194,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668623425194"
            }, {
                "key": "endTime",
                "value": "1668623425194"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668623425194000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668623425194,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668623425129_1668623425158",
            "id": "ce50001f-13a7-432c-889d-f57c26320e80",
            "eventName": "Onboarding",
            "timestamp": 1668623425129,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668623425129"
            }, {
                "key": "endTime",
                "value": "1668623425158"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668623425129000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668623425129,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668623425130000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668623425130,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668623425130000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668623425130,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668623425154000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668623425154,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668623425154000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668623425154,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668623425156000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668623425156,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668623425157000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668623425157,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668623425157000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668623425157,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668623425158000_#_9",
                "eventName": "Run",
                "timestamp": 1668623425158,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668623423453_1668623423453",
            "id": "e4422065-f5fd-449b-b74f-5c323d6a8e24",
            "eventName": "Associating",
            "timestamp": 1668623423453,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668623423453"
            }, {
                "key": "endTime",
                "value": "1668623423453"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668623423453000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668623423453,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668623423453000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668623423453,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668623423453000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668623423453,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668622155104_1668622155104",
            "id": "9c0584de-8eff-434a-b16f-3926f6a01027",
            "eventName": "DHCP",
            "timestamp": 1668622155104,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668622155104"
            }, {
                "key": "endTime",
                "value": "1668622155104"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668622155104000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668622155104,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668622644880_1668622644880",
            "id": "3e84fc40-3374-4c13-892d-c59a1c42e545",
            "eventName": "Roam Report",
            "timestamp": 1668622644880,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668622644880000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668622644880,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668622644880_1668622644880",
            "id": "f3e1311a-6b9a-4ca7-9190-15bbbe8ecbcb",
            "eventName": "Reported Errors",
            "timestamp": 1668622644880,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668622644880000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668622644880,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668622154786_1668622155097",
            "id": "291a15b0-70e6-4503-b74f-19ef487ba939",
            "eventName": "Onboarding",
            "timestamp": 1668622154786,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668622154786"
            }, {
                "key": "endTime",
                "value": "1668622155097"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668622154786000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668622154786,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668622154786000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668622154786,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668622154786000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668622154786,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668622155092000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668622155092,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668622155092000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668622155092,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668622155094000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668622155094,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668622155095000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668622155095,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668622155095000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668622155095,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668622155097000_#_9",
                "eventName": "Run",
                "timestamp": 1668622155097,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668622151400_1668622154421",
            "id": "85b22425-9d53-430a-a8b0-37817d8c4985",
            "eventName": "Authenticating",
            "timestamp": 1668622151400,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668622151400"
            }, {
                "key": "endTime",
                "value": "1668622154421"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668622151400000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668622151400,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668622151400000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668622151400,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668622151400000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668622151400,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668622154421000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668622154421,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668622154421000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668622154421,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668622150097_1668622150097",
            "id": "adbead5c-b4c3-48b8-90ee-446ddc8a08fd",
            "eventName": "Associating",
            "timestamp": 1668622150097,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668622150097"
            }, {
                "key": "endTime",
                "value": "1668622150097"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668622150097000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668622150097,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668622150097000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668622150097,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668622150097000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668622150097,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668622148523_1668622148523",
            "id": "bcc2e0ed-f7ac-4b70-b4f5-867c99678483",
            "eventName": "Delete",
            "timestamp": 1668622148523,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668622148523"
            }, {
                "key": "endTime",
                "value": "1668622148523"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668622148523000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668622148523,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authentication Done_1668620465947_1668620465947",
            "id": "6147c240-5ade-45fb-83ae-4951510b4333",
            "eventName": "Authentication Done",
            "timestamp": 1668620465947,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620465947"
            }, {
                "key": "endTime",
                "value": "1668620465947"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Authentication Done",
                "id": "1668620465947000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668620465947,
                "index": 1,
                "eventType": 4,
                "reasonType": 1113,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "KM Received EAP Packet"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668620837973_1668620837973",
            "id": "193f946b-b1d6-4826-95b1-2bad03756e37",
            "eventName": "Reported Errors",
            "timestamp": 1668620837973,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668620837973000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668620837973,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668620837972_1668620837972",
            "id": "fb7a5bc7-c61d-4b0a-b9c0-b9f7d4f3600a",
            "eventName": "Roam Report",
            "timestamp": 1668620837972,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -49 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-49 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668620837972000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668620837972,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -49 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-49 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668620350949_1668620350949",
            "id": "2ce2290e-042c-4c98-9cd8-06656860afa8",
            "eventName": "DHCP",
            "timestamp": 1668620350949,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620350949"
            }, {
                "key": "endTime",
                "value": "1668620350949"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668620350949000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668620350949,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668620348449_1668620348467",
            "id": "4a36037a-527a-48ac-a2ec-6de684140079",
            "eventName": "Onboarding",
            "timestamp": 1668620348449,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620348449"
            }, {
                "key": "endTime",
                "value": "1668620348467"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668620348449000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668620348449,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668620348449000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668620348449,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668620348453000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668620348453,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668620348462000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668620348462,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668620348462000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668620348462,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668620348464000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668620348464,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668620348465000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668620348465,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668620348465000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668620348465,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668620348467000_#_9",
                "eventName": "Run",
                "timestamp": 1668620348467,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668620345282_1668620348309",
            "id": "97b1228b-c7e6-4390-a8e7-02c61f6a9cda",
            "eventName": "Authenticating",
            "timestamp": 1668620345282,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620345282"
            }, {
                "key": "endTime",
                "value": "1668620348309"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668620345282000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668620345282,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668620345282000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668620345282,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668620345283000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668620345283,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668620348309000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668620348309,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668620348309000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668620348309,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668620344192_1668620344193",
            "id": "4c0874cc-dd25-435c-b8bb-43576c3ce931",
            "eventName": "Associating",
            "timestamp": 1668620344192,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620344192"
            }, {
                "key": "endTime",
                "value": "1668620344193"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668620344192000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668620344192,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668620344193000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668620344193,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668620344193000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668620344193,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668620342363_1668620342363",
            "id": "bc4f2927-9cbf-40db-8aec-22ae16e8a602",
            "eventName": "Delete",
            "timestamp": 1668620342363,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668620342363"
            }, {
                "key": "endTime",
                "value": "1668620342363"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668620342363000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668620342363,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668618544140_1668618544140",
            "id": "038923ca-f434-41b5-ae27-c46d6f4743e0",
            "eventName": "DHCP",
            "timestamp": 1668618544140,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668618544140"
            }, {
                "key": "endTime",
                "value": "1668618544140"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668618544140000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668618544140,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668619031375_1668619031375",
            "id": "135ef724-a2ac-40f0-aa35-2a2094f93d17",
            "eventName": "Roam Report",
            "timestamp": 1668619031375,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -49
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668619031375000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668619031375,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -49
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668619031375_1668619031375",
            "id": "f01ee2dc-633e-464c-a3bb-55ae8b6f7d5a",
            "eventName": "Reported Errors",
            "timestamp": 1668619031375,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668619031375000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668619031375,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668618541604_1668618541623",
            "id": "49190611-d21a-40ec-8765-dc9e4265efae",
            "eventName": "Onboarding",
            "timestamp": 1668618541604,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668618541604"
            }, {
                "key": "endTime",
                "value": "1668618541623"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668618541604000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668618541604,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668618541604000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668618541604,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668618541604000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668618541604,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668618541618000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668618541618,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668618541619000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668618541619,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668618541620000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668618541620,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668618541621000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668618541621,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668618541621000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668618541621,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668618541623000_#_9",
                "eventName": "Run",
                "timestamp": 1668618541623,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668618538443_1668618541451",
            "id": "db0fbe1f-797e-45c4-b708-e227b5387ce5",
            "eventName": "Authenticating",
            "timestamp": 1668618538443,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668618538443"
            }, {
                "key": "endTime",
                "value": "1668618541451"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668618538443000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668618538443,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668618538443000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668618538443,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668618538444000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668618538444,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668618541451000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668618541451,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668618541451000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668618541451,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668618537353_1668618537354",
            "id": "192aa9a0-52e9-4596-acad-eefa7b38ee07",
            "eventName": "Associating",
            "timestamp": 1668618537353,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668618537353"
            }, {
                "key": "endTime",
                "value": "1668618537354"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668618537353000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668618537353,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668618537354000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668618537354,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668618537354000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668618537354,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668618536209_1668618536209",
            "id": "36c36ae7-a23d-46fa-94ad-8695e33298e2",
            "eventName": "Delete",
            "timestamp": 1668618536209,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668618536209"
            }, {
                "key": "endTime",
                "value": "1668618536209"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668618536209000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668618536209,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authentication Done_1668616865899_1668616865899",
            "id": "f9a44211-65e5-4354-88c7-52761189460e",
            "eventName": "Authentication Done",
            "timestamp": 1668616865899,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616865899"
            }, {
                "key": "endTime",
                "value": "1668616865899"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Authentication Done",
                "id": "1668616865899000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668616865899,
                "index": 1,
                "eventType": 4,
                "reasonType": 1113,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "KM Received EAP Packet"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668617225167_1668617225167",
            "id": "582be63e-26aa-4d79-b7b1-f330947aed07",
            "eventName": "Reported Errors",
            "timestamp": 1668617225167,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668617225167000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668617225167,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668617225166_1668617225166",
            "id": "1c8ecbec-a159-464a-a6dc-1af4e1732596",
            "eventName": "Roam Report",
            "timestamp": 1668617225166,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -43
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668617225166000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668617225166,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -43
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668616735423_1668616735423",
            "id": "ee4cb5e3-fb44-4af2-8298-82badadeea05",
            "eventName": "DHCP",
            "timestamp": 1668616735423,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616735423"
            }, {
                "key": "endTime",
                "value": "1668616735423"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668616735423000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668616735423,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668616735389_1668616735417",
            "id": "101058d0-91b8-4c18-94b5-306a1497b3f1",
            "eventName": "Onboarding",
            "timestamp": 1668616735389,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616735389"
            }, {
                "key": "endTime",
                "value": "1668616735417"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668616735389000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668616735389,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668616735389000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668616735389,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668616735389000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668616735389,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668616735413000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668616735413,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668616735413000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668616735413,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668616735415000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668616735415,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668616735415000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668616735415,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668616735416000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668616735416,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668616735417000_#_9",
                "eventName": "Run",
                "timestamp": 1668616735417,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668616731954_1668616734961",
            "id": "2d94e1ad-5ac3-4023-bf98-129b4186560f",
            "eventName": "Authenticating",
            "timestamp": 1668616731954,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616731954"
            }, {
                "key": "endTime",
                "value": "1668616734961"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668616731954000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668616731954,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668616731954000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668616731954,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668616731955000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668616731955,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668616734961000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668616734961,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668616734961000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668616734961,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668616730868_1668616730868",
            "id": "ecd5cf47-7289-4e21-b1cf-6c06539d70a8",
            "eventName": "Associating",
            "timestamp": 1668616730868,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616730868"
            }, {
                "key": "endTime",
                "value": "1668616730868"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668616730868000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668616730868,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668616730868000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668616730868,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668616730868000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668616730868,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668616729060_1668616729060",
            "id": "be850794-cfb3-4e99-b5db-466a61a6f9b6",
            "eventName": "Delete",
            "timestamp": 1668616729060,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668616729060"
            }, {
                "key": "endTime",
                "value": "1668616729060"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668616729060000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668616729060,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668614928359_1668614928359",
            "id": "dffe2dca-4e5f-4fcf-a428-cd7340869f8d",
            "eventName": "DHCP",
            "timestamp": 1668614928359,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668614928359"
            }, {
                "key": "endTime",
                "value": "1668614928359"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668614928359000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668614928359,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668616493350_1668616493350",
            "id": "3565cb2f-65e7-4a77-89bd-e8535e123b28",
            "eventName": "Roam Report",
            "timestamp": 1668616493350,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -48
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668616493350000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668616493350,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -48
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668616493350_1668616493350",
            "id": "83dc1acb-eeca-4126-81c1-9bc5fe2b318a",
            "eventName": "Reported Errors",
            "timestamp": 1668616493350,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668616493350000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668616493350,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668616278392_1668616278392",
            "id": "f7cd3e6a-dfbf-435c-b13e-fe9bdb32822c",
            "eventName": "Reported Errors",
            "timestamp": 1668616278392,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668616278392000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668616278392,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668616278392_1668616278392",
            "id": "825d0bb2-2aff-4fae-bf3e-915196738d2b",
            "eventName": "Roam Report",
            "timestamp": 1668616278392,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668616278392000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668616278392,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668615112813_1668615112813",
            "id": "ca4ba6b9-d244-4792-9efa-7075c9102b18",
            "eventName": "Reported Errors",
            "timestamp": 1668615112813,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:70",
                    "bssid": "12:3F:0B:F2:B3:7A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668615112813000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668615112813,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:70",
                        "bssid": "12:3F:0B:F2:B3:7A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668615112812_1668615112812",
            "id": "403b3b20-f4ae-4b3a-9b49-41eb86712155",
            "eventName": "Roam Report",
            "timestamp": 1668615112812,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668615112812000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668615112812,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668614928335_1668614928353",
            "id": "679a4de6-0bcd-49e1-98af-3ba683d820a8",
            "eventName": "Onboarding",
            "timestamp": 1668614928335,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668614928335"
            }, {
                "key": "endTime",
                "value": "1668614928353"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668614928335000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668614928335,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668614928335000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668614928335,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668614928336000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668614928336,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668614928348000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668614928348,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668614928348000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668614928348,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668614928350000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668614928350,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668614928351000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668614928351,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668614928351000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668614928351,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668614928353000_#_9",
                "eventName": "Run",
                "timestamp": 1668614928353,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668614925101_1668614928108",
            "id": "3836802b-22b4-482d-8bac-d25cc8a30d5b",
            "eventName": "Authenticating",
            "timestamp": 1668614925101,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668614925101"
            }, {
                "key": "endTime",
                "value": "1668614928108"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668614925101000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668614925101,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668614925101000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668614925101,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668614925102000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668614925102,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668614928108000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668614928108,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668614928108000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668614928108,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668614923917_1668614923917",
            "id": "00080774-07b1-4b6c-8e99-ed492f19c9e8",
            "eventName": "Associating",
            "timestamp": 1668614923917,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668614923917"
            }, {
                "key": "endTime",
                "value": "1668614923917"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668614923917000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668614923917,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668614923917000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668614923917,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668614923917000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668614923917,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668614921927_1668614921927",
            "id": "322c1154-8037-44fb-93da-f3b4e8152053",
            "eventName": "Delete",
            "timestamp": 1668614921927,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668614921927"
            }, {
                "key": "endTime",
                "value": "1668614921927"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668614921927000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668614921927,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authentication Done_1668613265841_1668613265841",
            "id": "e6288a19-c8d0-4d2e-9ed8-86f13bf95a7a",
            "eventName": "Authentication Done",
            "timestamp": 1668613265841,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613265841"
            }, {
                "key": "endTime",
                "value": "1668613265841"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Authentication Done",
                "id": "1668613265841000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668613265841,
                "index": 1,
                "eventType": 4,
                "reasonType": 1113,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "KM Received EAP Packet"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668613599431_1668613599431",
            "id": "e792e6b8-5e0c-4c44-85d2-21f71a003227",
            "eventName": "Reported Errors",
            "timestamp": 1668613599431,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668613599431000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668613599431,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668613599431_1668613599431",
            "id": "bbefa64a-f3ac-44ba-8960-3f58b5c7f751",
            "eventName": "Roam Report",
            "timestamp": 1668613599431,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -43
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668613599431000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668613599431,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -43
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668613121552_1668613121552",
            "id": "74ef2ebb-4e51-4186-a16f-5c83eabb0bb2",
            "eventName": "DHCP",
            "timestamp": 1668613121552,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613121552"
            }, {
                "key": "endTime",
                "value": "1668613121552"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668613121552000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668613121552,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668613121478_1668613121546",
            "id": "252034e9-e25d-40a7-8e08-6cfd42e34665",
            "eventName": "Onboarding",
            "timestamp": 1668613121478,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613121478"
            }, {
                "key": "endTime",
                "value": "1668613121546"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668613121478000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668613121478,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668613121478000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668613121478,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668613121478000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668613121478,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668613121542000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668613121542,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668613121542000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668613121542,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668613121543000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668613121543,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668613121544000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668613121544,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668613121545000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668613121545,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668613121546000_#_9",
                "eventName": "Run",
                "timestamp": 1668613121546,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668613118074_1668613121084",
            "id": "f46247e8-df16-40bc-b372-f3ba4f9ed981",
            "eventName": "Authenticating",
            "timestamp": 1668613118074,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "61 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613118074"
            }, {
                "key": "endTime",
                "value": "1668613121084"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668613118074000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668613118074,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "61 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668613118074000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668613118074,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668613118074000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668613118074,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668613121083000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668613121083,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668613121084000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668613121084,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668613116736_1668613116737",
            "id": "6a41cb18-0d00-41ff-9bea-6b3fbb8218db",
            "eventName": "Associating",
            "timestamp": 1668613116736,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613116736"
            }, {
                "key": "endTime",
                "value": "1668613116737"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668613116736000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668613116736,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668613116736000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668613116736,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668613116737000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668613116737,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668613114762_1668613114762",
            "id": "0c0f6310-8701-4827-b077-e5f8deef0c99",
            "eventName": "Delete",
            "timestamp": 1668613114762,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668613114762"
            }, {
                "key": "endTime",
                "value": "1668613114762"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668613114762000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668613114762,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668611314530_1668611314530",
            "id": "55efd94c-1e9a-444d-8323-c47a0919d09c",
            "eventName": "DHCP",
            "timestamp": 1668611314530,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668611314530"
            }, {
                "key": "endTime",
                "value": "1668611314530"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668611314530000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668611314530,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668612574515_1668612574515",
            "id": "8004b818-8ac4-49af-9a90-690eee3bcc8c",
            "eventName": "Reported Errors",
            "timestamp": 1668612574515,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668612574515000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668612574515,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668612574515_1668612574515",
            "id": "ab5d5621-2945-4d9b-b6c6-de45a9e6da3a",
            "eventName": "Roam Report",
            "timestamp": 1668612574515,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -55 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-55 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668612574515000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668612574515,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -55 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-55 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668611442865_1668611442865",
            "id": "f9040575-f8cf-4bc3-8a4e-9eb20f24cf5a",
            "eventName": "Roam Report",
            "timestamp": 1668611442865,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -55 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-55 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -49
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -48
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668611442865000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668611442865,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -55 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-55 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -49
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -48
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668611442865_1668611442865",
            "id": "3f8dd912-a14b-4c2c-8f65-8ac3bdfb529e",
            "eventName": "Reported Errors",
            "timestamp": 1668611442865,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668611442865000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668611442865,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668611314493_1668611314525",
            "id": "e3641566-2ae6-4529-b077-8ae97afd9dad",
            "eventName": "Onboarding",
            "timestamp": 1668611314493,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668611314493"
            }, {
                "key": "endTime",
                "value": "1668611314525"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668611314493000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668611314493,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668611314493000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668611314493,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668611314493000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668611314493,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668611314520000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668611314520,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668611314521000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668611314521,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668611314522000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668611314522,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668611314523000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668611314523,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668611314523000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668611314523,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668611314525000_#_9",
                "eventName": "Run",
                "timestamp": 1668611314525,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668611310882_1668611313889",
            "id": "821e516d-4c9b-45ae-9b21-3c4f75449391",
            "eventName": "Authenticating",
            "timestamp": 1668611310882,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "61 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668611310882"
            }, {
                "key": "endTime",
                "value": "1668611313889"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668611310882000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668611310882,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "61 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668611310882000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668611310882,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668611310882000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668611310882,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668611313889000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668611313889,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668611313889000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668611313889,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668611309785_1668611309786",
            "id": "1f6e6802-28c8-481a-b54d-1cd8faba46d8",
            "eventName": "Associating",
            "timestamp": 1668611309785,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668611309785"
            }, {
                "key": "endTime",
                "value": "1668611309786"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668611309785000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668611309785,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668611309785000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668611309785,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668611309786000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668611309786,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668611308597_1668611308597",
            "id": "8aadf188-6e05-4488-8068-9815389fc6cb",
            "eventName": "Delete",
            "timestamp": 1668611308597,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668611308597"
            }, {
                "key": "endTime",
                "value": "1668611308597"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668611308597000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668611308597,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authentication Done_1668609665788_1668609665788",
            "id": "5b7038fe-e01d-45ee-9edb-4b903410ddfa",
            "eventName": "Authentication Done",
            "timestamp": 1668609665788,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609665788"
            }, {
                "key": "endTime",
                "value": "1668609665788"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Authentication Done",
                "id": "1668609665788000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668609665788,
                "index": 1,
                "eventType": 4,
                "reasonType": 1113,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "KM Received EAP Packet"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668609636623_1668609636623",
            "id": "58b1100c-e25d-44bf-af07-3cf0363bf652",
            "eventName": "Roam Report",
            "timestamp": 1668609636623,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668609636623000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668609636623,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668609636623_1668609636623",
            "id": "4a2c543b-3013-46ab-937a-e1b2bcce9cc4",
            "eventName": "Reported Errors",
            "timestamp": 1668609636623,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:E0",
                    "bssid": "12:3F:0B:F2:B1:E7",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668609636623000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668609636623,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:E0",
                        "bssid": "12:3F:0B:F2:B1:E7",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668609508844_1668609508844",
            "id": "31da722c-6c04-45fb-a60c-14c390d73f5d",
            "eventName": "DHCP",
            "timestamp": 1668609508844,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609508844"
            }, {
                "key": "endTime",
                "value": "1668609508844"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668609508844000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668609508844,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668609507581_1668609507624",
            "id": "61e3b4bc-0a3d-4cfe-99dc-8a07dc257e16",
            "eventName": "Onboarding",
            "timestamp": 1668609507581,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609507581"
            }, {
                "key": "endTime",
                "value": "1668609507624"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668609507581000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668609507581,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668609507581000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668609507581,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668609507582000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668609507582,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668609507620000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668609507620,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668609507620000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668609507620,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668609507622000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668609507622,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668609507623000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668609507623,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668609507623000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668609507623,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668609507624000_#_9",
                "eventName": "Run",
                "timestamp": 1668609507624,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668609504445_1668609507453",
            "id": "7c7cdda2-ecc2-4765-9dc2-c7da92e705c4",
            "eventName": "Authenticating",
            "timestamp": 1668609504445,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609504445"
            }, {
                "key": "endTime",
                "value": "1668609507453"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668609504445000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668609504445,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668609504446000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668609504446,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668609504446000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668609504446,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668609507452000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668609507452,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668609507453000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668609507453,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668609503361_1668609503361",
            "id": "4ff81ae2-ca4e-41ee-83ad-bf4f046717f7",
            "eventName": "Associating",
            "timestamp": 1668609503361,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609503361"
            }, {
                "key": "endTime",
                "value": "1668609503361"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668609503361000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668609503361,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668609503361000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668609503361,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668609503361000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668609503361,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668609502446_1668609502446",
            "id": "114abb9f-aa06-4c20-be34-df6eae5ca3fb",
            "eventName": "Delete",
            "timestamp": 1668609502446,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668609502446"
            }, {
                "key": "endTime",
                "value": "1668609502446"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668609502446000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668609502446,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668607709507_1668607709507",
            "id": "e61b65d8-1b3f-480d-bfd4-3a6dc7a2b3ab",
            "eventName": "DHCP",
            "timestamp": 1668607709507,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668607709507"
            }, {
                "key": "endTime",
                "value": "1668607709507"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668607709507000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668607709507,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668607831853_1668607831853",
            "id": "aec6b8f8-5f1a-46d5-8b8c-c989c6412382",
            "eventName": "Roam Report",
            "timestamp": 1668607831853,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-48 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -50
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668607831853000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668607831853,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -48 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-48 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -50
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668607831853_1668607831853",
            "id": "71d0d90e-a911-4091-af4d-a09190338e6c",
            "eventName": "Reported Errors",
            "timestamp": 1668607831853,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668607831853000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668607831853,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668607701929_1668607701967",
            "id": "d4c5dc21-bb16-4f96-8bb0-9f7a73acbad9",
            "eventName": "Onboarding",
            "timestamp": 1668607701929,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668607701929"
            }, {
                "key": "endTime",
                "value": "1668607701967"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668607701929000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668607701929,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668607701929000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668607701929,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668607701929000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668607701929,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668607701963000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668607701963,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668607701963000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668607701963,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668607701965000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668607701965,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668607701965000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668607701965,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668607701966000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668607701966,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668607701967000_#_9",
                "eventName": "Run",
                "timestamp": 1668607701967,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668607699887_1668607699888",
            "id": "5bfc93fe-bf0f-47d8-9256-ac12f4bd479a",
            "eventName": "Associating",
            "timestamp": 1668607699887,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668607699887"
            }, {
                "key": "endTime",
                "value": "1668607699888"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668607699887000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668607699887,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668607699887000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668607699887,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668607699888000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668607699888,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668606206016_1668606206016",
            "id": "25abd153-18cd-477c-859c-11931c6dffeb",
            "eventName": "DHCP",
            "timestamp": 1668606206016,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668606206016"
            }, {
                "key": "endTime",
                "value": "1668606206016"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668606206016000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668606206016,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668606205965_1668606205986",
            "id": "fcf66b52-71a3-43a6-9a73-451fc2f37ca4",
            "eventName": "Onboarding",
            "timestamp": 1668606205965,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668606205965"
            }, {
                "key": "endTime",
                "value": "1668606205986"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668606205965000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668606205965,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668606205965000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668606205965,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668606205965000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668606205965,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668606205982000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668606205982,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668606205982000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668606205982,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668606205983000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668606205983,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668606205984000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668606205984,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668606205985000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668606205985,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668606205986000_#_9",
                "eventName": "Run",
                "timestamp": 1668606205986,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668606200864_1668606203872",
            "id": "6e1af85b-dd15-4572-b4f8-0d08408f7c00",
            "eventName": "Authenticating",
            "timestamp": 1668606200864,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668606200864"
            }, {
                "key": "endTime",
                "value": "1668606203872"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668606200864000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668606200864,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668606200864000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668606200864,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668606200865000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668606200865,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668606203872000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668606203872,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668606203872000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668606203872,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668606199764_1668606199764",
            "id": "8782769b-7db5-474c-8289-1b5b92b19419",
            "eventName": "Associating",
            "timestamp": 1668606199764,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668606199764"
            }, {
                "key": "endTime",
                "value": "1668606199764"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668606199764000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668606199764,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668606199764000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668606199764,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668606199764000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668606199764,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668606199137_1668606199137",
            "id": "2dca1050-e13d-49f5-9399-db97ac5042b8",
            "eventName": "Delete",
            "timestamp": 1668606199137,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668606199137"
            }, {
                "key": "endTime",
                "value": "1668606199137"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668606199137000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668606199137,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668604400144_1668604400144",
            "id": "8e1d049f-2feb-4f7e-8e5c-1a0c29d9c27b",
            "eventName": "DHCP",
            "timestamp": 1668604400144,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604400144"
            }, {
                "key": "endTime",
                "value": "1668604400144"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668604400144000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668604400144,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668606029067_1668606029067",
            "id": "5e5e2e9d-7bc3-4a76-ad8e-28a049fd7efd",
            "eventName": "Reported Errors",
            "timestamp": 1668606029067,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668606029067000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668606029067,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668606029067_1668606029067",
            "id": "776ee6f2-bd6a-4a36-9f18-83b121438cbc",
            "eventName": "Roam Report",
            "timestamp": 1668606029067,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-43 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -49
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -52
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668606029067000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668606029067,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -43 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-43 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -49
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -52
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668604398296_1668604398313",
            "id": "2158439e-e0b3-4c48-a68b-643ffeca0fa6",
            "eventName": "Onboarding",
            "timestamp": 1668604398296,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604398296"
            }, {
                "key": "endTime",
                "value": "1668604398313"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668604398296000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668604398296,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668604398296000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668604398296,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668604398297000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668604398297,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668604398309000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668604398309,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668604398309000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668604398309,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668604398311000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668604398311,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668604398311000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668604398311,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668604398312000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668604398312,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668604398313000_#_9",
                "eventName": "Run",
                "timestamp": 1668604398313,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668604398271_1668604398272",
            "id": "49707303-d317-4f95-8c22-f958b81cff76",
            "eventName": "Delete",
            "timestamp": 1668604398271,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604398271"
            }, {
                "key": "endTime",
                "value": "1668604398272"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668604398271000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668604398271,
                "index": 1,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668604398271000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668604398271,
                "index": 2,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668604398272000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668604398272,
                "index": 3,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668604398272000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668604398272,
                "index": 4,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668604397237_1668604397237",
            "id": "dfc28523-4244-44d7-b4a2-28f4e3663bd9",
            "eventName": "DHCP",
            "timestamp": 1668604397237,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604397237"
            }, {
                "key": "endTime",
                "value": "1668604397237"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668604397237000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668604397237,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668604397120_1668604397210",
            "id": "6b99db3d-cbc9-40c2-8d3f-c420602d89e2",
            "eventName": "Onboarding",
            "timestamp": 1668604397120,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "64 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604397120"
            }, {
                "key": "endTime",
                "value": "1668604397210"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668604397120000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668604397120,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "64 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668604397120000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668604397120,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668604397120000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668604397120,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668604397206000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668604397206,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668604397206000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668604397206,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668604397207000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668604397207,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668604397208000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668604397208,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668604397209000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668604397209,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668604397210000_#_9",
                "eventName": "Run",
                "timestamp": 1668604397210,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668604396411_1668604396411",
            "id": "d4a31cea-d11c-41a1-94a4-31147a78d2f1",
            "eventName": "Delete",
            "timestamp": 1668604396411,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "SAE Commit received in Associated State"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to SAE Auth in Associated State/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668604396411"
            }, {
                "key": "endTime",
                "value": "1668604396411"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668604396411000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668604396411,
                "index": 0,
                "eventType": 11,
                "reasonType": 1346,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "SAE Auth in Associated State"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "SAE Commit received in Associated State"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668603646341_1668603646341",
            "id": "678e1c83-0b8b-470c-9f33-1e683ae6cbf3",
            "eventName": "DHCP",
            "timestamp": 1668603646341,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668603646341"
            }, {
                "key": "endTime",
                "value": "1668603646341"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668603646341000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668603646341,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668604221327_1668604221327",
            "id": "ad0d2e40-7ebd-4779-b2b1-442cb9568fbe",
            "eventName": "Reported Errors",
            "timestamp": 1668604221327,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:10",
                    "bssid": "12:3F:0B:F2:B1:15",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668604221327000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668604221327,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:10",
                        "bssid": "12:3F:0B:F2:B1:15",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668604221327_1668604221327",
            "id": "9546d5d3-16c0-4119-80ed-dea305018e9a",
            "eventName": "Roam Report",
            "timestamp": 1668604221327,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -49 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A7"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-49 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "rssiValue": -52
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668604221327000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668604221327,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -49 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A7"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-49 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "rssiValue": -52
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668603646314_1668603646334",
            "id": "9a3b269d-6dfc-430e-8068-0c054ba14274",
            "eventName": "Onboarding",
            "timestamp": 1668603646314,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SNR",
                "value": "61 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668603646314"
            }, {
                "key": "endTime",
                "value": "1668603646334"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668603646314000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668603646314,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "61 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668603646314000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668603646314,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668603646314000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668603646314,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668603646330000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668603646330,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668603646330000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668603646330,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668603646331000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668603646331,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668603646332000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668603646332,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668603646333000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668603646333,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668603646334000_#_9",
                "eventName": "Run",
                "timestamp": 1668603646334,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668603642658_1668603642658",
            "id": "ca3288b6-c87d-450d-bd11-b07cec479f9c",
            "eventName": "Associating",
            "timestamp": 1668603642658,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668603642658"
            }, {
                "key": "endTime",
                "value": "1668603642658"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668603642658000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668603642658,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668603642658000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668603642658,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668603642658000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668603642658,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668603640902_1668603640902",
            "id": "2df7903c-5f08-4b38-ba8f-b27de1769ea9",
            "eventName": "Delete",
            "timestamp": 1668603640902,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668603640902"
            }, {
                "key": "endTime",
                "value": "1668603640902"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668603640902000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668603640902,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668601841445_1668601841445",
            "id": "9a1fc2f4-7212-405f-b1a9-9e6ee0e4a9a2",
            "eventName": "DHCP",
            "timestamp": 1668601841445,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601841445"
            }, {
                "key": "endTime",
                "value": "1668601841445"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668601841445000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668601841445,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668602413563_1668602413563",
            "id": "8ac54040-3291-408e-8564-0fe7ff8b0f1a",
            "eventName": "Roam Report",
            "timestamp": 1668602413563,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -52
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668602413563000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668602413563,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -52
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668602413563_1668602413563",
            "id": "99b54f91-e7c6-4933-8a37-e83b99482622",
            "eventName": "Reported Errors",
            "timestamp": 1668602413563,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668602413563000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668602413563,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668601840151_1668601840177",
            "id": "e715228b-0aad-44b9-916b-4dbf4265a63b",
            "eventName": "Onboarding",
            "timestamp": 1668601840151,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601840151"
            }, {
                "key": "endTime",
                "value": "1668601840177"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668601840151000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601840151,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668601840151000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668601840151,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668601840152000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668601840152,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668601840163000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668601840163,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668601840163000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668601840163,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668601840165000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668601840165,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668601840166000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668601840166,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668601840176000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668601840176,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668601840177000_#_9",
                "eventName": "Run",
                "timestamp": 1668601840177,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668601838508_1668601839741",
            "id": "42233b7f-6c7e-483e-85b6-bb5c1aa1c834",
            "eventName": "Delete",
            "timestamp": 1668601838508,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "Delete Reason Description",
                "value": "SAE Commit received in Associated State"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to SAE Auth in Associated State/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601838508"
            }, {
                "key": "endTime",
                "value": "1668601839741"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668601838508000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601838508,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668601838508000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668601838508,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668601838509000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668601838509,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668601839741000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601839741,
                "index": 3,
                "eventType": 11,
                "reasonType": 1346,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "SAE Auth in Associated State"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "SAE Commit received in Associated State"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668601836512_1668601836512",
            "id": "55303790-f5e1-497c-91dc-013f74c12305",
            "eventName": "Associating",
            "timestamp": 1668601836512,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-29 dBm"
            }, {
                "key": "SNR",
                "value": "66 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "2.4"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601836512"
            }, {
                "key": "endTime",
                "value": "1668601836512"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668601836512000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601836512,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-29 dBm"
                }, {
                    "key": "SNR",
                    "value": "66 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668601836512000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668601836512,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668601836512000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601836512,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "FREQUENCY",
                    "value": "2.4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668601834143_1668601834143",
            "id": "2b4d979b-b3e6-4e28-992a-c34ce9cce0a4",
            "eventName": "Associating",
            "timestamp": 1668601834143,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601834143"
            }, {
                "key": "endTime",
                "value": "1668601834143"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668601834143000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601834143,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668601834143000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668601834143,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668601834143000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601834143,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Authenticating_1668601831045_1668601834051",
            "id": "1ac0b40b-110e-4a63-8b8f-11f17533def3",
            "eventName": "Authenticating",
            "timestamp": 1668601831045,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Authentication Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SubReasonType",
                "value": "Auth Key Exchange Timeout"
            }, {
                "key": "Delete Reason Description",
                "value": "Client deleted as it was not able to complete 4-way key handshake."
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to MIC Validation/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601831045"
            }, {
                "key": "endTime",
                "value": "1668601834051"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668601831045000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601831045,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668601831045000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668601831045,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668601831045000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668601831045,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668601834051000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668601834051,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 1326,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "SubReasonType",
                    "value": "Auth Key Exchange Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668601834051000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601834051,
                "index": 4,
                "eventType": 11,
                "reasonType": 1260,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "MIC Validation"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client deleted as it was not able to complete 4-way key handshake."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668601829936_1668601829936",
            "id": "d6fa6bb6-1770-4735-9aa9-d3efb24659f7",
            "eventName": "Associating",
            "timestamp": 1668601829936,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601829936"
            }, {
                "key": "endTime",
                "value": "1668601829936"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668601829936000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668601829936,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668601829936000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668601829936,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668601829936000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601829936,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668601827746_1668601827746",
            "id": "52a9560e-42af-45ff-8c56-9bb602d5195e",
            "eventName": "Delete",
            "timestamp": 1668601827746,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668601827746"
            }, {
                "key": "endTime",
                "value": "1668601827746"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668601827746000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668601827746,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668600027008_1668600027008",
            "id": "b4bc3f8b-af33-4607-a9c2-9ad58c4b8e7b",
            "eventName": "DHCP",
            "timestamp": 1668600027008,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668600027008"
            }, {
                "key": "endTime",
                "value": "1668600027008"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668600027008000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668600027008,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668600607754_1668600607754",
            "id": "4937ab8e-f931-469a-b2bd-76b75036eda6",
            "eventName": "Roam Report",
            "timestamp": 1668600607754,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -59
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -46
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668600607754000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668600607754,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -59
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -46
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668600607754_1668600607754",
            "id": "7ec0c546-6d33-4de9-9bee-559cfcc70172",
            "eventName": "Reported Errors",
            "timestamp": 1668600607754,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668600607754000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668600607754,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668600026986_1668600026999",
            "id": "b5db451c-becd-466e-ac3e-70f576778dfd",
            "eventName": "Onboarding",
            "timestamp": 1668600026986,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668600026986"
            }, {
                "key": "endTime",
                "value": "1668600026999"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668600026986000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668600026986,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668600026986000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668600026986,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668600026986000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668600026986,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668600026996000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668600026996,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668600026996000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668600026996,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668600026996000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668600026996,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668600026999000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668600026999,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668600026999000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668600026999,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668600026999000_#_9",
                "eventName": "Run",
                "timestamp": 1668600026999,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668600025668_1668600025668",
            "id": "48bba6f4-1e98-4dad-82a6-6a7f2ac9335e",
            "eventName": "Associating",
            "timestamp": 1668600025668,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668600025668"
            }, {
                "key": "endTime",
                "value": "1668600025668"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668600025668000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668600025668,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668600025668000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668600025668,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668600025668000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668600025668,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668600024600_1668600024600",
            "id": "a724b5ef-93ee-415b-b93a-1ec881e7fb2c",
            "eventName": "Delete",
            "timestamp": 1668600024600,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668600024600"
            }, {
                "key": "endTime",
                "value": "1668600024600"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668600024600000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668600024600,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668598223871_1668598223871",
            "id": "8208b7f3-3680-4b4b-ac0e-1213f3a250a9",
            "eventName": "DHCP",
            "timestamp": 1668598223871,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668598223871"
            }, {
                "key": "endTime",
                "value": "1668598223871"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668598223871000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668598223871,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668598797154_1668598797154",
            "id": "68f6f074-409d-4070-aff5-e4f90d6f8c70",
            "eventName": "Reported Errors",
            "timestamp": 1668598797154,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668598797154000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668598797154,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668598797154_1668598797154",
            "id": "584d15ef-7ac1-4e23-96a7-0845a6d55f86",
            "eventName": "Roam Report",
            "timestamp": 1668598797154,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668598797154000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668598797154,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668598223834_1668598223862",
            "id": "5aae056d-7d84-405e-91fa-5cf8479c563c",
            "eventName": "Onboarding",
            "timestamp": 1668598223834,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668598223834"
            }, {
                "key": "endTime",
                "value": "1668598223862"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668598223834000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668598223834,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668598223835000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668598223835,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668598223835000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668598223835,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668598223858000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668598223858,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668598223858000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668598223858,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668598223859000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668598223859,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668598223860000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668598223860,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668598223861000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668598223861,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668598223862000_#_9",
                "eventName": "Run",
                "timestamp": 1668598223862,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668598222383_1668598222384",
            "id": "93922d37-4a08-47bd-88a2-72a6b4616664",
            "eventName": "Associating",
            "timestamp": 1668598222383,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668598222383"
            }, {
                "key": "endTime",
                "value": "1668598222384"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668598222383000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668598222383,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668598222384000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668598222384,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668598222384000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668598222384,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668598221467_1668598221467",
            "id": "08f77d80-e330-4ca0-a1f4-c9f22d50200b",
            "eventName": "Delete",
            "timestamp": 1668598221467,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668598221467"
            }, {
                "key": "endTime",
                "value": "1668598221467"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668598221467000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668598221467,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668596422553_1668596422553",
            "id": "ba13a3df-201a-479f-98af-1bbfff46ff73",
            "eventName": "DHCP",
            "timestamp": 1668596422553,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668596422553"
            }, {
                "key": "endTime",
                "value": "1668596422553"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668596422553000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668596422553,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668598145560_1668598145560",
            "id": "eb842489-3a15-41dd-8f03-226f4e094a1f",
            "eventName": "Roam Report",
            "timestamp": 1668598145560,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -46 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-46 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668598145560000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668598145560,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -46 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-46 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668598145560_1668598145560",
            "id": "72115852-a7ef-4c28-b2e6-0e03efe059e1",
            "eventName": "Reported Errors",
            "timestamp": 1668598145560,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668598145560000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668598145560,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668596875518_1668596875518",
            "id": "99f85233-e3bf-4891-a2d0-fbcee2e91c2d",
            "eventName": "Roam Report",
            "timestamp": 1668596875518,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668596875518000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668596875518,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668596875518_1668596875518",
            "id": "d94874f9-c4e0-447b-9969-48240927d910",
            "eventName": "Reported Errors",
            "timestamp": 1668596875518,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668596875518000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668596875518,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668596421078_1668596421097",
            "id": "c546c5ac-dbe0-4f1a-afc4-1c55e08c0065",
            "eventName": "Onboarding",
            "timestamp": 1668596421078,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SNR",
                "value": "61 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668596421078"
            }, {
                "key": "endTime",
                "value": "1668596421097"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668596421078000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668596421078,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "61 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668596421079000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668596421079,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668596421079000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668596421079,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668596421093000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668596421093,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668596421093000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668596421093,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668596421094000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668596421094,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668596421095000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668596421095,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668596421096000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668596421096,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668596421097000_#_9",
                "eventName": "Run",
                "timestamp": 1668596421097,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668596419990_1668596419990",
            "id": "655a1c2f-61cb-4c2d-b886-17e4a5b8615c",
            "eventName": "Associating",
            "timestamp": 1668596419990,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668596419990"
            }, {
                "key": "endTime",
                "value": "1668596419990"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668596419990000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668596419990,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668596419990000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668596419990,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668596419990000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668596419990,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668596419344_1668596419344",
            "id": "959ee32e-fb28-4c3e-8536-bd043aa941df",
            "eventName": "Delete",
            "timestamp": 1668596419344,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668596419344"
            }, {
                "key": "endTime",
                "value": "1668596419344"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668596419344000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668596419344,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668594730309_1668594730309",
            "id": "ccfec733-5dcc-4a1e-99ff-7ec0af076ddc",
            "eventName": "DHCP",
            "timestamp": 1668594730309,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594730309"
            }, {
                "key": "endTime",
                "value": "1668594730309"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668594730309000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668594730309,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668595068912_1668595068912",
            "id": "6a43d03d-6158-44a1-b3a1-3fb6081abf68",
            "eventName": "Roam Report",
            "timestamp": 1668595068912,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668595068912000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668595068912,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668595068912_1668595068912",
            "id": "7c9feb0d-9c77-4ed1-868b-d06ddd91f2fc",
            "eventName": "Reported Errors",
            "timestamp": 1668595068912,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668595068912000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668595068912,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "INTRA-WLC Roaming_1668594730283_1668594730299",
            "id": "5f77d3ac-cf77-48c9-a3c3-0f6c8a80ebf6",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1668594730283,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594730283"
            }, {
                "key": "endTime",
                "value": "1668594730299"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668594730283000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668594730283,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668594730283000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668594730283,
                "index": 1,
                "eventType": 1002,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668594730283000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668594730283,
                "index": 2,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668594730285000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668594730285,
                "index": 3,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668594730297000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668594730297,
                "index": 4,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668594730297000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668594730297,
                "index": 5,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668594730298000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668594730298,
                "index": 6,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668594730299000_#_9",
                "eventName": "Run",
                "timestamp": 1668594730299,
                "index": 7,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668594618702_1668594618702",
            "id": "496f455d-84de-4146-9d39-a6d058e01a90",
            "eventName": "DHCP",
            "timestamp": 1668594618702,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594618702"
            }, {
                "key": "endTime",
                "value": "1668594618702"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668594618702000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668594618702,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668594618659_1668594618694",
            "id": "a0919fd1-421c-4cd1-997d-ae9ee17853fb",
            "eventName": "Onboarding",
            "timestamp": 1668594618659,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594618659"
            }, {
                "key": "endTime",
                "value": "1668594618694"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668594618659000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668594618659,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668594618659000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668594618659,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668594618660000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668594618660,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668594618689000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668594618689,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668594618689000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668594618689,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668594618691000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668594618691,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668594618692000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668594618692,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668594618692000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668594618692,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668594618694000_#_9",
                "eventName": "Run",
                "timestamp": 1668594618694,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668594617563_1668594617564",
            "id": "bd7fe349-e853-4b37-b13c-ddc6b3209184",
            "eventName": "Associating",
            "timestamp": 1668594617563,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594617563"
            }, {
                "key": "endTime",
                "value": "1668594617564"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668594617563000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668594617563,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668594617563000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668594617563,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668594617564000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668594617564,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668594617181_1668594617181",
            "id": "c32a8570-ac89-40ec-adbb-3607744dacd4",
            "eventName": "Delete",
            "timestamp": 1668594617181,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668594617181"
            }, {
                "key": "endTime",
                "value": "1668594617181"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668594617181000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668594617181,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668592816299_1668592816299",
            "id": "3d38069c-c9c5-4a87-83ae-c6e65bdfbf37",
            "eventName": "DHCP",
            "timestamp": 1668592816299,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668592816299"
            }, {
                "key": "endTime",
                "value": "1668592816299"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668592816299000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668592816299,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668593262098_1668593262098",
            "id": "002358e1-3183-4270-8d96-e333bd8cc9ad",
            "eventName": "Reported Errors",
            "timestamp": 1668593262098,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "C6:9E:28:75:62:20",
                    "bssid": "C6:9E:28:75:62:23",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668593262098000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668593262098,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "C6:9E:28:75:62:20",
                        "bssid": "C6:9E:28:75:62:23",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668593262098_1668593262098",
            "id": "251ef2d5-257f-4b24-b520-3c3ddb9b36c1",
            "eventName": "Roam Report",
            "timestamp": 1668593262098,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668593262098000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668593262098,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668592816218_1668592816285",
            "id": "d0cfb63b-2696-4c6d-9239-45fa81f4b17a",
            "eventName": "Onboarding",
            "timestamp": 1668592816218,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668592816218"
            }, {
                "key": "endTime",
                "value": "1668592816285"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668592816218000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668592816218,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668592816218000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668592816218,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668592816218000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668592816218,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668592816281000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668592816281,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668592816281000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668592816281,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668592816283000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668592816283,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668592816284000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668592816284,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668592816284000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668592816284,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668592816285000_#_9",
                "eventName": "Run",
                "timestamp": 1668592816285,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668592814871_1668592814871",
            "id": "37400cd2-0a3d-4c94-8c90-d38496547a45",
            "eventName": "Associating",
            "timestamp": 1668592814871,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668592814871"
            }, {
                "key": "endTime",
                "value": "1668592814871"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668592814871000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668592814871,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668592814871000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668592814871,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668592814871000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668592814871,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668592814035_1668592814035",
            "id": "f8b4b7ac-5617-4358-89f7-f36531127168",
            "eventName": "Delete",
            "timestamp": 1668592814035,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668592814035"
            }, {
                "key": "endTime",
                "value": "1668592814035"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668592814035000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668592814035,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668591013059_1668591013059",
            "id": "81a8f74b-5a28-46d0-baa8-71cb5375f348",
            "eventName": "DHCP",
            "timestamp": 1668591013059,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668591013059"
            }, {
                "key": "endTime",
                "value": "1668591013059"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668591013059000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668591013059,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668591455913_1668591455913",
            "id": "9d6c7673-1d3d-4754-b189-42fe349857b4",
            "eventName": "Roam Report",
            "timestamp": 1668591455913,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668591455913000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668591455913,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668591455913_1668591455913",
            "id": "3914a11d-6c64-4909-abad-25f3adf3b89f",
            "eventName": "Reported Errors",
            "timestamp": 1668591455913,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668591455913000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668591455913,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668591013013_1668591013054",
            "id": "6c5307d8-747e-481a-b19e-afca857f35f9",
            "eventName": "Onboarding",
            "timestamp": 1668591013013,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668591013013"
            }, {
                "key": "endTime",
                "value": "1668591013054"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668591013013000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668591013013,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668591013014000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668591013014,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668591013014000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668591013014,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668591013050000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668591013050,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668591013050000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668591013050,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668591013051000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668591013051,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668591013052000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668591013052,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668591013053000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668591013053,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668591013054000_#_9",
                "eventName": "Run",
                "timestamp": 1668591013054,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668591011755_1668591011755",
            "id": "ab98e18b-51d1-4a59-8f40-258e3a0f463e",
            "eventName": "Associating",
            "timestamp": 1668591011755,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668591011755"
            }, {
                "key": "endTime",
                "value": "1668591011755"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668591011755000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668591011755,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668591011755000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668591011755,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668591011755000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668591011755,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668591010877_1668591010877",
            "id": "7906d738-b6fa-49db-af13-341d0ca5ad36",
            "eventName": "Delete",
            "timestamp": 1668591010877,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668591010877"
            }, {
                "key": "endTime",
                "value": "1668591010877"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668591010877000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668591010877,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668589210704_1668589210704",
            "id": "d270fc17-3623-458c-bfe2-d1ed884c3ce4",
            "eventName": "DHCP",
            "timestamp": 1668589210704,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668589210704"
            }, {
                "key": "endTime",
                "value": "1668589210704"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668589210704000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668589210704,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668589648873_1668589648873",
            "id": "97cd68a3-560a-43bf-901b-4147ab6b6355",
            "eventName": "Roam Report",
            "timestamp": 1668589648873,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668589648873000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668589648873,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668589648873_1668589648873",
            "id": "0af3c509-aa37-4c07-915c-53ce0ebb708b",
            "eventName": "Reported Errors",
            "timestamp": 1668589648873,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668589648873000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668589648873,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668589210672_1668589210693",
            "id": "53db00d1-34e5-4df7-afc4-f14c235f4e32",
            "eventName": "Onboarding",
            "timestamp": 1668589210672,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-32 dBm"
            }, {
                "key": "SNR",
                "value": "61 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668589210672"
            }, {
                "key": "endTime",
                "value": "1668589210693"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668589210672000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668589210672,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-32 dBm"
                }, {
                    "key": "SNR",
                    "value": "61 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668589210672000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668589210672,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668589210672000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668589210672,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668589210689000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668589210689,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668589210689000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668589210689,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668589210690000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668589210690,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668589210691000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668589210691,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668589210692000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668589210692,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668589210693000_#_9",
                "eventName": "Run",
                "timestamp": 1668589210693,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668589209569_1668589209569",
            "id": "53eaf7bb-0bca-4839-ae0a-f0392d1ff567",
            "eventName": "Associating",
            "timestamp": 1668589209569,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668589209569"
            }, {
                "key": "endTime",
                "value": "1668589209569"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668589209569000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668589209569,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668589209569000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668589209569,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668589209569000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668589209569,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668589208721_1668589208721",
            "id": "c4f077a2-486e-4244-b839-c80e215eeb53",
            "eventName": "Delete",
            "timestamp": 1668589208721,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668589208721"
            }, {
                "key": "endTime",
                "value": "1668589208721"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668589208721000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668589208721,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668587408424_1668587408424",
            "id": "cb3773d1-8244-4787-8b09-97b877612c4b",
            "eventName": "DHCP",
            "timestamp": 1668587408424,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668587408424"
            }, {
                "key": "endTime",
                "value": "1668587408424"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668587408424000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668587408424,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668587842098_1668587842098",
            "id": "87e29312-9cc6-42e5-8254-4853425d6a99",
            "eventName": "Roam Report",
            "timestamp": 1668587842098,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -55
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668587842098000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668587842098,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -55
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668587842098_1668587842098",
            "id": "e7089f6d-5182-4bdc-9a32-99769aebb676",
            "eventName": "Reported Errors",
            "timestamp": 1668587842098,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:10",
                    "bssid": "12:3F:0B:F2:B1:15",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668587842098000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668587842098,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:10",
                        "bssid": "12:3F:0B:F2:B1:15",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668587408395_1668587408412",
            "id": "96c1ac90-a02c-4799-83d0-095b76a17dd6",
            "eventName": "Onboarding",
            "timestamp": 1668587408395,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668587408395"
            }, {
                "key": "endTime",
                "value": "1668587408412"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668587408395000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668587408395,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668587408395000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668587408395,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668587408396000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668587408396,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668587408407000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668587408407,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668587408408000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668587408408,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668587408409000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668587408409,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668587408410000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668587408410,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668587408410000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668587408410,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668587408412000_#_9",
                "eventName": "Run",
                "timestamp": 1668587408412,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668587408350_1668587408350",
            "id": "946d90a6-30f8-46ec-aae6-f4a7d2b00541",
            "eventName": "Delete",
            "timestamp": 1668587408350,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client initiated a deauthentication or disassociation."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Deauth or Disassoc Request/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668587408350"
            }, {
                "key": "endTime",
                "value": "1668587408350"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Client Sent Disassociation",
                "id": "1668587408350000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668587408350,
                "index": 0,
                "eventType": 17,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668587408350000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668587408350,
                "index": 1,
                "eventType": 17,
                "reasonType": 1176,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Disconnect Req"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Client Sent Disassociation",
                "id": "1668587408350000_#_17",
                "eventName": "Client Sent Disassociation",
                "timestamp": 1668587408350,
                "index": 2,
                "eventType": 17,
                "reasonType": 1135,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Delete Client"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668587408350000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668587408350,
                "index": 3,
                "eventType": 11,
                "reasonType": 1025,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Deauth or Disassoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client initiated a deauthentication or disassociation."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668586432144_1668586432144",
            "id": "6d2c5885-dd6f-4ccd-b3ed-875a9ddfca69",
            "eventName": "DHCP",
            "timestamp": 1668586432144,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668586432144"
            }, {
                "key": "endTime",
                "value": "1668586432144"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668586432144000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668586432144,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "INTRA-WLC Roaming_1668586432082_1668586432135",
            "id": "39a9829c-ce5f-4647-a731-afa6f8ef40a4",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1668586432082,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668586432082"
            }, {
                "key": "endTime",
                "value": "1668586432135"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668586432082000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668586432082,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668586432082000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668586432082,
                "index": 1,
                "eventType": 1002,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668586432083000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668586432083,
                "index": 2,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668586432084000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668586432084,
                "index": 3,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668586432134000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668586432134,
                "index": 4,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668586432134000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668586432134,
                "index": 5,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668586432135000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668586432135,
                "index": 6,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668586432135000_#_9",
                "eventName": "Run",
                "timestamp": 1668586432135,
                "index": 7,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668585954873_1668585954873",
            "id": "fb1e1bb1-179d-40cf-af90-5b962116036a",
            "eventName": "DHCP",
            "timestamp": 1668585954873,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668585954873"
            }, {
                "key": "endTime",
                "value": "1668585954873"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668585954873000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668585954873,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668586035116_1668586035116",
            "id": "fd85e737-8b0b-4494-8261-47c181feced3",
            "eventName": "Reported Errors",
            "timestamp": 1668586035116,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "F2:9C:3E:F1:15:60",
                    "bssid": "F2:9C:3E:F1:15:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:46:60",
                    "bssid": "A6:18:88:C0:46:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668586035116000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668586035116,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "F2:9C:3E:F1:15:60",
                        "bssid": "F2:9C:3E:F1:15:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:46:60",
                        "bssid": "A6:18:88:C0:46:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668586035115_1668586035115",
            "id": "c3e98ff5-5092-4815-b78a-aef27dfc1b70",
            "eventName": "Roam Report",
            "timestamp": 1668586035115,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -53
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -46
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668586035115000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668586035115,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -53
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -46
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668585954845_1668585954866",
            "id": "e970b583-64dc-44d7-a542-8c8c5b23b37d",
            "eventName": "Onboarding",
            "timestamp": 1668585954845,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668585954845"
            }, {
                "key": "endTime",
                "value": "1668585954866"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668585954845000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668585954845,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668585954845000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668585954845,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668585954846000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668585954846,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668585954863000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668585954863,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668585954863000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668585954863,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668585954863000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668585954863,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668585954865000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668585954865,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668585954865000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668585954865,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668585954866000_#_9",
                "eventName": "Run",
                "timestamp": 1668585954866,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668585953758_1668585953759",
            "id": "9901ad1c-d54f-42be-abda-9af11b16e99c",
            "eventName": "Associating",
            "timestamp": 1668585953758,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668585953758"
            }, {
                "key": "endTime",
                "value": "1668585953759"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668585953758000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668585953758,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668585953759000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668585953759,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668585953759000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668585953759,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668585953475_1668585953475",
            "id": "a38b6128-7ccc-4e98-adde-e1f153d224d9",
            "eventName": "Delete",
            "timestamp": 1668585953475,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668585953475"
            }, {
                "key": "endTime",
                "value": "1668585953475"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668585953475000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668585953475,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668584153305_1668584153305",
            "id": "f00bec55-62d5-4cf1-8151-63929a7028af",
            "eventName": "DHCP",
            "timestamp": 1668584153305,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668584153305"
            }, {
                "key": "endTime",
                "value": "1668584153305"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668584153305000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668584153305,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668584228232_1668584228232",
            "id": "f069cbf0-c14d-4910-9c03-10f8e7325b70",
            "eventName": "Reported Errors",
            "timestamp": 1668584228232,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668584228232000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668584228232,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668584228232_1668584228232",
            "id": "6d34a40f-9b27-4a11-8b84-62fcfedafe33",
            "eventName": "Roam Report",
            "timestamp": 1668584228232,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -46
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668584228232000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668584228232,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -46
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668584153267_1668584153298",
            "id": "f4f12714-f572-484c-8a02-5b6cf2bc0442",
            "eventName": "Onboarding",
            "timestamp": 1668584153267,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-30 dBm"
            }, {
                "key": "SNR",
                "value": "63 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668584153267"
            }, {
                "key": "endTime",
                "value": "1668584153298"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668584153267000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668584153267,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-30 dBm"
                }, {
                    "key": "SNR",
                    "value": "63 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668584153268000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668584153268,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668584153268000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668584153268,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668584153294000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668584153294,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668584153294000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668584153294,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668584153295000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668584153295,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668584153296000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668584153296,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668584153297000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668584153297,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668584153298000_#_9",
                "eventName": "Run",
                "timestamp": 1668584153298,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668584151840_1668584151840",
            "id": "54128dc2-4a3f-4153-b490-92fe9e6a9b0f",
            "eventName": "Associating",
            "timestamp": 1668584151840,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668584151840"
            }, {
                "key": "endTime",
                "value": "1668584151840"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668584151840000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668584151840,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668584151840000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668584151840,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668584151840000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668584151840,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668584151305_1668584151305",
            "id": "60dee40c-6039-4ebd-8abf-f9552422efbc",
            "eventName": "Delete",
            "timestamp": 1668584151305,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668584151305"
            }, {
                "key": "endTime",
                "value": "1668584151305"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668584151305000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668584151305,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668582354101_1668582354101",
            "id": "6bbe05fe-6e05-4174-bb0e-e0021b257ed4",
            "eventName": "DHCP",
            "timestamp": 1668582354101,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668582354101"
            }, {
                "key": "endTime",
                "value": "1668582354101"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668582354101000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668582354101,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668582422610_1668582422610",
            "id": "1f8f24c8-37d9-494b-af60-307025bed8bc",
            "eventName": "Reported Errors",
            "timestamp": 1668582422610,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:50",
                    "bssid": "12:3F:0B:F2:B3:5A",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:60",
                    "bssid": "12:3F:0B:F2:B3:60",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668582422610000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668582422610,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:50",
                        "bssid": "12:3F:0B:F2:B3:5A",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:60",
                        "bssid": "12:3F:0B:F2:B3:60",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668582422610_1668582422610",
            "id": "dbcb43c3-71c2-4103-aeaa-22c0448b7680",
            "eventName": "Roam Report",
            "timestamp": 1668582422610,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -46 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Missed Beacons,11v recommendation"
            }, {
                "key": "RSSI",
                "value": "-46 dBm"
            }, {
                "key": "Candidate APs",
                "value": []
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668582422610000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668582422610,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -46 dBm | AP: TME-lab-9166i-4 | Reason Code: Missed Beacons,11v recommendation | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Missed Beacons,11v recommendation"
                }, {
                    "key": "RSSI",
                    "value": "-46 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": []
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Onboarding_1668582351109_1668582351128",
            "id": "8cc61444-6a80-44bd-aa4b-50a5c5c97e74",
            "eventName": "Onboarding",
            "timestamp": 1668582351109,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "2"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-31 dBm"
            }, {
                "key": "SNR",
                "value": "62 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668582351109"
            }, {
                "key": "endTime",
                "value": "1668582351128"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668582351109000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668582351109,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-31 dBm"
                }, {
                    "key": "SNR",
                    "value": "62 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668582351109000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668582351109,
                "index": 1,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668582351109000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668582351109,
                "index": 2,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668582351124000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668582351124,
                "index": 3,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668582351124000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668582351124,
                "index": 4,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668582351126000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668582351126,
                "index": 5,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP Start",
                "id": "1668582351127000_#_100",
                "eventName": "DHCP Start",
                "timestamp": 1668582351127,
                "index": 6,
                "eventType": 100,
                "reasonType": 1161,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "DHCP",
                "id": "1668582351127000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668582351127,
                "index": 7,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668582351128000_#_9",
                "eventName": "Run",
                "timestamp": 1668582351128,
                "index": 8,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Associating_1668582350014_1668582350014",
            "id": "f44235ff-9f49-4ca9-a6a5-eecd1892fbe7",
            "eventName": "Associating",
            "timestamp": 1668582350014,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": "Association Failed",
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "RSSI",
                "value": "-27 dBm"
            }, {
                "key": "SNR",
                "value": "68 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668582350014"
            }, {
                "key": "endTime",
                "value": "1668582350014"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Association Start",
                "id": "1668582350014000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668582350014,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-27 dBm"
                }, {
                    "key": "SNR",
                    "value": "68 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668582350014000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668582350014,
                "index": 1,
                "eventType": 1002,
                "reasonType": 1123,
                "subreasonType": 0,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Association Failure"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Deleted",
                "id": "1668582350014000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668582350014,
                "index": 2,
                "eventType": 11,
                "reasonType": 173,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Invalid EAPOL(Extensible Authentication Protocol Over LANs) Key PMK(Pairwise Master Key)"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Delete_1668582349146_1668582349146",
            "id": "d7ef0395-9d90-40f1-860b-7cd9a261585f",
            "eventName": "Delete",
            "timestamp": 1668582349146,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "Delete Reason Description",
                "value": "Client is deleted due to session time out."
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "additionalInfo",
                "value": "Due to Session Timeout/AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668582349146"
            }, {
                "key": "endTime",
                "value": "1668582349146"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Deleted",
                "id": "1668582349146000_#_11",
                "eventName": "Deleted",
                "timestamp": 1668582349146,
                "index": 0,
                "eventType": 11,
                "reasonType": 1045,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Session Timeout"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "Delete Reason Description",
                    "value": "Client is deleted due to session time out."
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "DHCP_1668581898896_1668581898896",
            "id": "f6175557-fb59-4df9-be57-6f98a5aadbd6",
            "eventName": "DHCP",
            "timestamp": 1668581898896,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "SubReasonType",
                "value": "IP Learn DHCP"
            }, {
                "key": "IPv6",
                "value": "fe80::ac11:28c6:6b:8c40"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "DHCP Server",
                "value": "192.168.151.254"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668581898896"
            }, {
                "key": "endTime",
                "value": "1668581898896"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "DHCP",
                "id": "1668581898896000_#_7",
                "eventName": "DHCP",
                "timestamp": 1668581898896,
                "index": 0,
                "eventType": 7,
                "reasonType": 1162,
                "subreasonType": 191,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "SubReasonType",
                    "value": "IP Learn DHCP"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "IPv6",
                    "value": "fe80::ac11:28c6:6b:8c40"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "DHCP Server",
                    "value": "192.168.151.254"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "INTRA-WLC Roaming_1668581898867_1668581898884",
            "id": "ff651dbc-931d-41b5-9ccf-ea60ae7b6e24",
            "eventName": "INTRA-WLC Roaming",
            "timestamp": 1668581898867,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "WLC Name",
                "value": "MBCC-IMPACT-SSO-1.demo.local"
            }, {
                "key": "IPv4",
                "value": "192.168.151.181"
            }, {
                "key": "Mac Address",
                "value": "E8:84:A5:2C:40:4D"
            }, {
                "key": "WLAN",
                "value": "TME-psk"
            }, {
                "key": "Radio",
                "value": "1"
            }, {
                "key": "VLAN ID/VNID",
                "value": "51"
            }, {
                "key": "isPrivateMac",
                "value": "false"
            }, {
                "key": "ROLE",
                "value": "LOCAL"
            }, {
                "key": "RSSI",
                "value": "-28 dBm"
            }, {
                "key": "SNR",
                "value": "67 dB"
            }, {
                "key": "Frequency(GHz)",
                "value": "5.0"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "additionalInfo",
                "value": "AP:TME-lab-9166i-4/WLC:MBCC-IMPACT-SSO-1.demo.local/WLAN:TME-psk"
            }, {
                "key": "startTime",
                "value": "1668581898867"
            }, {
                "key": "endTime",
                "value": "1668581898884"
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": false,
            "children": [{
                "name": "Association Start",
                "id": "1668581898867000_#_1",
                "eventName": "Association Start",
                "timestamp": 1668581898867,
                "index": 0,
                "eventType": 1,
                "reasonType": 1169,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Assoc Request"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "RSSI",
                    "value": "-28 dBm"
                }, {
                    "key": "SNR",
                    "value": "67 dB"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Associating",
                "id": "1668581898867000_#_1002",
                "eventName": "Associating",
                "timestamp": 1668581898867,
                "index": 1,
                "eventType": 1002,
                "reasonType": 122,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Intra Roam"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Association Done",
                "id": "1668581898868000_#_2",
                "eventName": "Association Done",
                "timestamp": 1668581898868,
                "index": 2,
                "eventType": 2,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Start",
                "id": "1668581898869000_#_3",
                "eventName": "Authentication Start",
                "timestamp": 1668581898869,
                "index": 3,
                "eventType": 3,
                "reasonType": 1144,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "L2 Auth Start"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authenticating",
                "id": "1668581898882000_#_1003",
                "eventName": "Authenticating",
                "timestamp": 1668581898882,
                "index": 4,
                "eventType": 1003,
                "reasonType": 1152,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Key Exchange"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Authentication Done",
                "id": "1668581898882000_#_4",
                "eventName": "Authentication Done",
                "timestamp": 1668581898882,
                "index": 5,
                "eventType": 4,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Mobility",
                "id": "1668581898883000_#_6",
                "eventName": "Mobility",
                "timestamp": 1668581898883,
                "index": 6,
                "eventType": 6,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }, {
                "name": "Run",
                "id": "1668581898884000_#_9",
                "eventName": "Run",
                "timestamp": 1668581898884,
                "index": 7,
                "eventType": 9,
                "reasonType": 0,
                "subreasonType": 0,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Client Onboarded"
                }, {
                    "key": "Mac Address",
                    "value": "E8:84:A5:2C:40:4D"
                }, {
                    "key": "isPrivateMac",
                    "value": "false"
                }, {
                    "key": "WLC Name",
                    "value": "MBCC-IMPACT-SSO-1.demo.local"
                }, {
                    "key": "WLAN",
                    "value": "TME-psk"
                }, {
                    "key": "Radio",
                    "value": "1"
                }, {
                    "key": "FREQUENCY",
                    "value": "5.0"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "IPv4",
                    "value": "192.168.151.181"
                }, {
                    "key": "VLAN ID",
                    "value": "51"
                }, {
                    "key": "ROLE",
                    "value": "LOCAL"
                }],
                "issues": null,
                "healthScore": 0,
                "source": null,
                "eventSource": "",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Roam Report_1668580926632_1668580926632",
            "id": "c95a6905-d6e3-4033-8cce-1ffb1b705e48",
            "eventName": "Roam Report",
            "timestamp": 1668580926632,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_SUCCESS",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
            }, {
                "key": "AP Name",
                "value": "TME-lab-9166i-4"
            }, {
                "key": "AP Mac",
                "value": "10:F9:20:FD:A2:A0"
            }, {
                "key": "BSSID",
                "value": "10:F9:20:FD:A2:AF"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "Reason Code",
                "value": "Intel Proprietary"
            }, {
                "key": "RSSI",
                "value": "-51 dBm"
            }, {
                "key": "Candidate APs",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "rssiValue": -56
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "rssiValue": -47
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668580926632000_#_Device Analytics - Intel",
                "eventName": "Roam Report",
                "timestamp": 1668580926632,
                "index": null,
                "eventType": 4,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_SUCCESS",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "RSSI: -51 dBm | AP: TME-lab-9166i-4 | Reason Code: Intel Proprietary | Type: Device Analytics - Intel"
                }, {
                    "key": "AP Name",
                    "value": "TME-lab-9166i-4"
                }, {
                    "key": "AP Mac",
                    "value": "10:F9:20:FD:A2:A0"
                }, {
                    "key": "BSSID",
                    "value": "10:F9:20:FD:A2:AF"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "Reason Code",
                    "value": "Intel Proprietary"
                }, {
                    "key": "RSSI",
                    "value": "-51 dBm"
                }, {
                    "key": "Candidate APs",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "rssiValue": -56
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "rssiValue": -47
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }, {
            "name": "Device Analytics - Intel - Reported Errors_1668580926632_1668580926632",
            "id": "a89f6531-3a10-495a-a630-f2757b0a5e75",
            "eventName": "Reported Errors",
            "timestamp": 1668580926632,
            "index": null,
            "eventType": null,
            "reasonType": null,
            "subreasonType": null,
            "resultStatus": "CL_FAIL",
            "failCategory": null,
            "details": [{
                "key": "additionalInfo",
                "value": "Type: Device Analytics - Intel"
            }, {
                "key": "Type",
                "value": "Device Analytics - Intel"
            }, {
                "key": "APs (Invalid IEs)",
                "value": [{
                    "apMacAddress": "12:3F:0B:F2:B3:40",
                    "bssid": "12:3F:0B:F2:B3:4D",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B1:D0",
                    "bssid": "12:3F:0B:F2:B1:DB",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "A6:18:88:C0:56:70",
                    "bssid": "A6:18:88:C0:56:78",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "C6:9E:28:75:52:D0",
                    "bssid": "C6:9E:28:75:52:D8",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }, {
                    "apMacAddress": "12:3F:0B:F2:B3:90",
                    "bssid": "12:3F:0B:F2:B3:90",
                    "type": "Invalid IE",
                    "frameType": "Beacon",
                    "ies": "49"
                }]
            }, {
                "key": "APs (Missing Response)",
                "value": [{
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A7",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:A0",
                    "type": "Missing Response",
                    "frameType": "FT"
                }, {
                    "apName": "TME-lab-9166i-4",
                    "apMacAddress": "10:F9:20:FD:A2:A0",
                    "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                    "bssid": "10:F9:20:FD:A2:AF",
                    "type": "Missing Response",
                    "frameType": "FT"
                }]
            }],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "eventSource": null,
            "hasPackets": null,
            "hasAnomalyPackets": null,
            "isPrivateMac": null,
            "children": [{
                "name": "Device Analytics - Intel",
                "id": "1668580926632000_#_Device Analytics - Intel",
                "eventName": "Reported Errors",
                "timestamp": 1668580926632,
                "index": null,
                "eventType": 1,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": "CL_FAIL",
                "failCategory": null,
                "details": [{
                    "key": "additionalInfo",
                    "value": "Type: Device Analytics - Intel"
                }, {
                    "key": "Type",
                    "value": "Device Analytics - Intel"
                }, {
                    "key": "APs (Invalid IEs)",
                    "value": [{
                        "apMacAddress": "12:3F:0B:F2:B3:40",
                        "bssid": "12:3F:0B:F2:B3:4D",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B1:D0",
                        "bssid": "12:3F:0B:F2:B1:DB",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "A6:18:88:C0:56:70",
                        "bssid": "A6:18:88:C0:56:78",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "C6:9E:28:75:52:D0",
                        "bssid": "C6:9E:28:75:52:D8",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }, {
                        "apMacAddress": "12:3F:0B:F2:B3:90",
                        "bssid": "12:3F:0B:F2:B3:90",
                        "type": "Invalid IE",
                        "frameType": "Beacon",
                        "ies": "49"
                    }]
                }, {
                    "key": "APs (Missing Response)",
                    "value": [{
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A7",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:A0",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }, {
                        "apName": "TME-lab-9166i-4",
                        "apMacAddress": "10:F9:20:FD:A2:A0",
                        "apUUID": "ec181be5-6343-40f7-8b0f-a3424f18d948",
                        "bssid": "10:F9:20:FD:A2:AF",
                        "type": "Missing Response",
                        "frameType": "FT"
                    }]
                }],
                "healthScore": null,
                "source": null,
                "eventSource": "intel",
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }]
        }],
        "totalCount": 322
    }
})