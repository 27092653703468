define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWQLj9xq0pjH_lTLEl_b",
        "name": "dhcp_reachability_issue",
        "entityType": "Client",
        "entity": "00:0C:29:75:B8:F6",
        "groupBy": "10.13.1.2",
        "category": "Onboarding",
        "severity": "HIGH",
        "priority": "P2",
        "summary": "This client MacAddress@ToChange has failed to obtain an IPv4 address from DHCP server.",
        "scope": "GLOBAL",
        "rootCause": "Unknown",
        "timestamp": 1529202330046,
        "description": "This client MacAddress@ToChange has not been assigned an IPv4 address by DHCP server.",
        "suggestions": [
          {
            "message": "Verify that the DHCP server is reachable.",
            "steps": [

            ]
          },
          {
            "message": "Verify the DHCP scope is configured correctly and has adequate free IP addresses.",
            "steps": [

            ]
          },
          {
            "message": "Verify the IP helper address is configured correctly on the first hop switch 10.13.1.2.",
            "steps": [

            ]
          },
          {
            "message": "Initiate a Path Trace tool to verify the DHCP server can reach the first hop switch 10.13.1.2.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "hostName",
            "value": ""
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "entityId",
            "value": "00:0C:29:75:B8:F6"
          },
          {
            "key": "groupBy",
            "value": "10.13.1.2"
          },
          {
            "key": "type",
            "value": "Issue"
          },
          {
            "key": "priority",
            "value": "P1"
          },
          {
            "key": "switchIp",
            "value": "10.13.1.2"
          },
          {
            "key": "connectedInterface",
            "value": "GigabitEthernet1/0/9"
          },
          {
            "key": "vlan",
            "value": "110"
          },
          {
            "key": "serverIp",
            "value": ""
          },
          {
            "key": "siteId",
            "value": ""
          },
          {
            "key": "_id",
            "value": "00:0C:29:75:B8:F6"
          },
          {
            "key": "category",
            "value": "onboarding"
          },
          {
            "key": "timestring",
            "value": "2018-06-17T02:25:30.046+0000"
          },
          {
            "key": "macAddr",
            "value": "00:0C:29:75:B8:F6"
          },
          {
            "key": "status",
            "value": "active"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": null
      }
    ]
  }
})