define({
"WorkFlow_Template":
{
  "id": "92bcf304-7150-4b7b-9730-a06a80da2de6",
  "name": "spf-provide-cfs-design",
  "workflowStatus": "SUCCESS",
  "startTime": "2018-08-25, 02:22:19.618",
  "endTime": "2018-08-25, 02:22:38.188",
  "duration": "0 hrs 0 min 18 secs 570 msc",
  "owner": "spf-service-manager-service",
  "beginSteps": [
    "PreProcess-Cfs-Step"
  ],
  "steps": [
    {
      "name": "PreProcess-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Preprocessing of business intent"
      ],
      "step.next": [
        {
          "name": "Validate-Cfs-Step"
        }
      ],
      "tasks": [
        {
          "name": "Preprocessing of business intent",
          "type": "spf.cfsPreProcessorTaskAdapter",
          "targets": [
            {
              "id": "Default",
              "targetStatus": "SUCCESS",
              "startTime": "2018-08-25, 02:22:19.647",
              "endTime": "2018-08-25, 02:22:20.006",
              "duration": "0 hrs 0 min 0 secs 359 msc",
              "type": "DEFAULT",
              "onFailure": "ROLLBACK_ON_ERROR",
              "statusMessage": "Completed execution of PreProcessor CFS task",
              "childWorkflows": [

              ],
              "displayName": "308308"
            }
          ],
          "task.next": [

          ]
        }
      ]
    },
    {
      "name": "Validate-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Validation of business intent"
      ],
      "step.next": [
        {
          "name": "Process-Cfs-Step"
        }
      ],
      "tasks": [
        {
          "name": "Validation of business intent",
          "type": "spf.cfsValidatorTaskAdapter",
          "targets": [
            {
              "id": "Default",
              "targetStatus": "SUCCESS",
              "startTime": "2018-08-25, 02:22:20.031",
              "endTime": "2018-08-25, 02:22:20.184",
              "duration": "0 hrs 0 min 0 secs 153 msc",
              "type": "DEFAULT",
              "onFailure": "ROLLBACK_ON_ERROR",
              "statusMessage": "Completed execution of Validate CFS task",
              "userDisplayMessages": [
                {
                  "time": 1535163740088,
                  "message": "Validating the service parameters"
                },
                {
                  "time": 1535163740110,
                  "message": "Successfully validated user intent"
                }
              ],
              "childWorkflows": [

              ],
              "displayName": "308308"
            }
          ],
          "task.next": [

          ]
        }
      ]
    },
    {
      "name": "Process-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Saving the business intent"
      ],
      "step.next": [
        {
          "name": "PostProcess-Cfs-Step"
        }
      ],
      "tasks": [

      ]
    },
    {
      "name": "PostProcess-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Post processing of business intent"
      ],
      "step.next": [
        {
          "name": "Resolve-Target-Cfs-Step"
        }
      ],
      "tasks": [

      ]
    },
    {
      "name": "Resolve-Target-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Identification of devices to be provisioned"
      ],
      "step.next": [
        {
          "name": "Translate-Cfs-Step"
        }
      ],
      "tasks": [

      ]
    },
    {
      "name": "Translate-Cfs-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Conversion of business intent to network intent"
      ],
      "step.next": [
        {
          "name": "Decision-Step"
        }
      ],
      "tasks": [
        {
          "name": "Conversion of business intent to network intent",
          "type": "spf.cfsTranslatorTaskAdapter",
          "targets": [
            {
              "id": "Default",
              "targetStatus": "SUCCESS",
              "startTime": "2018-08-25, 02:22:25.304",
              "endTime": "2018-08-25, 02:22:26.195",
              "duration": "0 hrs 0 min 0 secs 891 msc",
              "type": "DEFAULT",
              "onFailure": "ROLLBACK_ON_ERROR",
              "statusMessage": "Completed execution of Translate CFS task",
              "userDisplayMessages": [
                {
                  "time": 1535163745323,
                  "message": "Translating user intent to device intent"
                },
                {
                  "time": 1535163746152,
                  "message": "Completed the translation of user intent to device intent"
                }
              ],
              "childWorkflows": [

              ],
              "displayName": "308308"
            }
          ],
          "task.next": [

          ]
        }
      ]
    },
    {
      "name": "Decision-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Decision to identify stacked business intent or network intent"
      ],
      "step.next": [
        {
          "name": "Cfs-Aggregator-Step"
        }
      ],
      "tasks": [

      ]
    },
    {
      "name": "Cfs-Aggregator-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Deployment of network intent"
      ],
      "step.next": [
        {
          "name": "Cfs-Status-Update-Step"
        }
      ],
      "tasks": [
        {
          "name": "Deployment of network intent",
          "type": "spf.cfsAggregratorTaskAdapter",
          "targets": [
            {
              "id": "Default",
              "targetStatus": "SUCCESS",
              "startTime": "2018-08-25, 02:22:26.392",
              "endTime": "2018-08-25, 02:22:26.705",
              "duration": "0 hrs 0 min 0 secs 313 msc",
              "type": "DEFAULT",
              "onFailure": "ROLLBACK_ON_ERROR",
              "statusMessage": "Completed execution of Aggregate CFS task",
              "userDisplayMessages": [
                {
                  "time": 1535163746601,
                  "message": "Deploying network intent to 1 device."
                }
              ],
              "childWorkflows": [
                {
                  "id": "91210ab9-6a59-4677-a663-26ab405cfeee",
                  "name": "spf-provide-rfs-design",
                  "workflowStatus": "SUCCESS",
                  "startTime": "2018-08-25, 02:22:26.606",
                  "endTime": "2018-08-25, 02:22:37.868",
                  "duration": "0 hrs 0 min 11 secs 262 msc",
                  "owner": "spf-service-manager-service",
                  "parentTask": "Deployment of network intent",
                  "parentWorkflow": "92bcf304-7150-4b7b-9730-a06a80da2de6",
                  "beginSteps": [
                    "Provide-Rfs-Step-1"
                  ],
                  "steps": [
                    {
                      "name": "Provide-Rfs-Step-1",
                      "stepStatus": "SUCCESS",
                      "beginTasks": [

                      ],
                      "step.next": [
                        {
                          "name": "Provide-Rfs-Step-2"
                        }
                      ],
                      "tasks": [

                      ]
                    },
                    {
                      "name": "Provide-Rfs-Step-2",
                      "stepStatus": "SUCCESS",
                      "beginTasks": [

                      ],
                      "step.next": [
                        {
                          "name": "Provide-Rfs-Step-3"
                        }
                      ],
                      "tasks": [

                      ]
                    },
                    {
                      "name": "Provide-Rfs-Step-3",
                      "stepStatus": "SUCCESS",
                      "beginTasks": [

                      ],
                      "step.next": [
                        {
                          "name": "Provide-Rfs-Step-Default"
                        }
                      ],
                      "tasks": [

                      ]
                    },
                    {
                      "name": "Provide-Rfs-Step-Default",
                      "stepStatus": "SUCCESS",
                      "beginTasks": [
                        "Conversion from network intent to device intent"
                      ],
                      "step.next": [
                        {
                          "name": "Rfs-Merge-Step"
                        }
                      ],
                      "tasks": [
                        {
                          "name": "Conversion from network intent to device intent",
                          "type": "spf.rfsTranslatorTaskAdapter",
                          "targets": [
                            {
                              "id": "d902672a-c0d9-4009-80d4-e8b5e219d56d",
                              "targetStatus": "SUCCESS",
                              "startTime": "2018-08-25, 02:22:26.700",
                              "endTime": "2018-08-25, 02:22:37.060",
                              "duration": "0 hrs 0 min 10 secs 360 msc",
                              "type": "MANAGED_DEVICE_UUID",
                              "onFailure": "CONTINUE_ON_ERROR",
                              "statusMessage": "Completed modularizing the network intent to device intent.",
                              "userDisplayMessages": [
                                {
                                  "time": 1535163746710,
                                  "message": "Started modularizing the network intent to device intent"
                                },
                                {
                                  "time": 1535163756999,
                                  "message": "Completed modularizing the network intent to device intent."
                                }
                              ],
                              "childWorkflows": [

                              ],
                              "displayName": "Device@ToChange"
                            }
                          ],
                          "task.next": [
                            {
                              "name": "Deployment of configuration"
                            }
                          ]
                        },
                        {
                          "name": "Deployment of configuration",
                          "type": "spf.rfsDeployerTaskAdapter",
                          "targets": [
                            {
                              "id": "d902672a-c0d9-4009-80d4-e8b5e219d56d",
                              "targetStatus": "SUCCESS",
                              "startTime": "2018-08-25, 02:22:37.089",
                              "endTime": "2018-08-25, 02:22:37.518",
                              "duration": "0 hrs 0 min 0 secs 429 msc",
                              "type": "MANAGED_DEVICE_UUID",
                              "onFailure": "CONTINUE_ON_ERROR",
                              "statusMessage": "DeployRFS execution status is SUCCESS for cfs ff7b7be0-525f-4b08-b2c7-572b207cf1e7 in target d902672a-c0d9-4009-80d4-e8b5e219d56d",
                              "userDisplayMessages": [
                                {
                                  "time": 1535163757118,
                                  "message": "Deploying configuration on the device Device@ToChange (172.20.98.138)."
                                },
                                {
                                  "time": 1535163757396,
                                  "message": "Deployed configuration on the device."
                                }
                              ],
                              "childWorkflows": [

                              ],
                              "displayName": "Device@ToChange"
                            }
                          ],
                          "task.next": [

                          ]
                        }
                      ]
                    },
                    {
                      "name": "Rfs-Merge-Step",
                      "stepStatus": "SUCCESS",
                      "beginTasks": [
                        "Determination of network intent deployment status"
                      ],
                      "step.next": [

                      ],
                      "tasks": [

                      ]
                    }
                  ]
                }
              ],
              "displayName": "308308"
            }
          ],
          "task.next": [

          ],
          "workflows": [
            {
              "id": "91210ab9-6a59-4677-a663-26ab405cfeee",
              "name": "spf-provide-rfs-design",
              "workflowStatus": "SUCCESS",
              "owner": null
            }
          ]
        }
      ]
    },
    {
      "name": "Cfs-Status-Update-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Network intent deployment status update"
      ],
      "step.next": [
        {
          "name": "Template-Deployment-Step"
        }
      ],
      "tasks": [

      ]
    },
    {
      "name": "Template-Deployment-Step",
      "stepStatus": "SUCCESS",
      "beginTasks": [
        "Prepare-Template-Deployment-Data-Task"
      ],
      "step.next": [

      ],
      "tasks": [
        {
          "name": "Deployment of advanced configuration (templates)",
          "type": "tp.triggerTemplateDeployment",
          "targets": [
            {
              "id": "Default",
              "targetStatus": "SUCCESS",
              "startTime": "2018-08-25, 02:22:38.144",
              "endTime": "2018-08-25, 02:22:38.160",
              "duration": "0 hrs 0 min 0 secs 016 msc",
              "type": "DEFAULT",
              "onFailure": "CONTINUE_ON_ERROR",
              "statusMessage": "Child Workflow :CLIProvisioningWorkflowDesign Successfully Triggered for the template deployment",
              "userDisplayMessages": [
                {
                  "time": 1535163758155,
                  "message": "No templates to be deployed"
                }
              ],
              "childWorkflows": [

              ],
              "displayName": "308308"
            }
          ],
          "task.next": [

          ]
        }
      ]
    }
  ]
}
})