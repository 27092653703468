define({

	"Template": {

		"key": "d9568fb0-faf3-4f43-9c7f-cb54f3784588",

		"sensorList": [],

		"uncoveredAPList": [{

			"name": "AP00EB.D510.3F18",

			"macAddress": "2c:d0:2d:8b:d3:60",

			"assigned": false,

			"status": null

		}, {

			"name": "AP0081.C424.3CE2",

			"macAddress": "4c:77:6d:ae:0c:60",

			"assigned": false,

			"status": null

		}, {

			"name": "AP00F2.8B27.B788",

			"macAddress": "78:72:5d:1c:72:a0",

			"assigned": false,

			"status": null

		}],

		"message": "Success",

		"completeApList": [{

			"name": "AP00EB.D510.3F18",

			"macAddress": "2c:d0:2d:8b:d3:60",

			"assigned": false,

			"status": null,

			"neighborAps": [{

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-20"

			}, {

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-36"

			}, {

				"ssid": null,

				"bssid": "78:72:5d:1c:72:a0",

				"apname": "AP00F2.8B27.B788",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-26"

			}],

			"radio_2_4_ClientCount": 0,

			"radio_5_ClientCount": 0,

			"clientCount": 7,

			"deviceType": "AP-IN-LOCAL"

		}, {

			"name": "AP00F2.8B27.B788",

			"macAddress": "78:72:5d:1c:72:a0",

			"assigned": false,

			"status": null,

			"neighborAps": [{

				"ssid": null,

				"bssid": "2c:d0:2d:8b:d3:60",

				"apname": "AP00EB.D510.3F18",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-26"

			}, {

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-29"

			}, {

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-27"

			}],

			"radio_2_4_ClientCount": 0,

			"radio_5_ClientCount": 0,

			"clientCount": 15,

			"deviceType": "AP-IN-LOCAL"

		}, {

			"name": "AP0081.C424.3CE2",

			"macAddress": "4c:77:6d:ae:0c:60",

			"assigned": false,

			"status": null,

			"neighborAps": [{

				"ssid": null,

				"bssid": "2c:d0:2d:8b:d3:60",

				"apname": "AP00EB.D510.3F18",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-17"

			}, {

				"ssid": null,

				"bssid": "2c:d0:2d:8b:d3:60",

				"apname": "AP00EB.D510.3F18",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-21"

			}, {

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-15"

			}, {

				"ssid": null,

				"bssid": "4c:77:6d:ae:0c:60",

				"apname": "AP0081.C424.3CE2",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-12"

			}, {

				"ssid": null,

				"bssid": "78:72:5d:1c:72:a0",

				"apname": "AP00F2.8B27.B788",

				"radio": "1",

				"band": "2.4GHz",

				"frequency": null,

				"rssi": "-23"

			}],

			"radio_2_4_ClientCount": 0,

			"radio_5_ClientCount": 0,

			"clientCount": 10,

			"deviceType": "AP-IN-LOCAL"

		}]

	}

});