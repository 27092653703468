define({
    "aiRfProfileName_template":
    [
        {
          "aiRfProfileName": "RRM_RF_PROFILE",
          "associatedBuildings": [
            {
              "groupNameHierarchy": "Global/North America/USA/California/San Jose/SJC01",
              "groupId": 29059,
              "name": "SJC01",
              "instanceUUID": "f8ffb07d-4cc4-4912-9a3c-6905d4153941"
            }
          ]
        }
    ],
    
})