define({
    "Template":
    {
        "request": {
          "key": "ms-lync",
          "type": "Application",
          "label": "ms-lync",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "3823c185-3db1-4fd5-a8b2-076ef5239ef1"
            }
          ]
        },
        "data": {
          "response": {
            "id": "3823c185-3db1-4fd5-a8b2-076ef5239ef1",
            "instanceId": 4776,
            "authEntityId": 4776,
            "displayName": "4776",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265513401,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265513401,
            "name": "ms-lync",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ms-lync",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "7bda6d62-b5aa-4436-8c92-6df41b089de2",
                "instanceId": 11724,
                "authEntityId": 11724,
                "displayName": "11724",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "ms-lync-group",
                "applicationType": "DEFAULT",
                "categoryId": "47900b90-73a8-4019-94eb-bd0beda354e0",
                "createTime": 1528265513401,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Skype provides cost effective and collaborative tools for businesses",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265513401,
                "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                      collaboration platform that brings together an experience inspired by                      Skype with enterprise-grade security, compliance and control. It offers                      features including presence, IM, voice and video calls, and online meetings.                      Since it is built right in to Microsoft Office, initiating chats,                      calls and meetings is an integrated experience within Office.",
                "name": "ms-lync",
                "nbarId": "1466",
                "p2pTechnology": "false",
                "popularity": 10,
                "rank": 65535,
                "references": "http://www.skype.com/en/business/",
                "selectorId": "531",
                "subCategory": "e85d31e2-84a9-4494-83a1-066b4f0331bb",
                "trafficClass": "MULTIMEDIA_CONFERENCING",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "27b3b6eb-e4fe-41d4-bae1-fdd098019a3a",
                "instanceId": 13979,
                "authEntityId": 13979,
                "displayName": "13979",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,3478,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "5677a434-8dfa-414e-9dd7-e240d069daa7",
                "instanceId": 13980,
                "authEntityId": 13980,
                "displayName": "13980",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,3478,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "8fc94ad9-1b81-4511-8ab6-e284aee3a79d"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "3823c185-3db1-4fd5-a8b2-076ef5239ef1",
              "instanceId": 4776,
              "authEntityId": 4776,
              "displayName": "4776",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265513401,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265513401,
              "name": "ms-lync",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ms-lync",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "7bda6d62-b5aa-4436-8c92-6df41b089de2",
                  "instanceId": 11724,
                  "authEntityId": 11724,
                  "displayName": "11724",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "ms-lync-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "47900b90-73a8-4019-94eb-bd0beda354e0",
                  "createTime": 1528265513401,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Skype provides cost effective and collaborative tools for businesses",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265513401,
                  "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                      collaboration platform that brings together an experience inspired by                      Skype with enterprise-grade security, compliance and control. It offers                      features including presence, IM, voice and video calls, and online meetings.                      Since it is built right in to Microsoft Office, initiating chats,                      calls and meetings is an integrated experience within Office.",
                  "name": "ms-lync",
                  "nbarId": "1466",
                  "p2pTechnology": "false",
                  "popularity": 10,
                  "rank": 65535,
                  "references": "http://www.skype.com/en/business/",
                  "selectorId": "531",
                  "subCategory": "e85d31e2-84a9-4494-83a1-066b4f0331bb",
                  "trafficClass": "MULTIMEDIA_CONFERENCING",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "27b3b6eb-e4fe-41d4-bae1-fdd098019a3a",
                  "instanceId": 13979,
                  "authEntityId": 13979,
                  "displayName": "13979",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,3478,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "5677a434-8dfa-414e-9dd7-e240d069daa7",
                  "instanceId": 13980,
                  "authEntityId": 13980,
                  "displayName": "13980",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,3478,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "8fc94ad9-1b81-4511-8ab6-e284aee3a79d"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });