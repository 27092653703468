define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWKd7Lu5UVFRaeYF-Mtk",
        "name": "global_wireless_client_auth_fail_aaa_rej_trigger",
        "entityType": "Client",
        "entity": "B8:27:EB:D8:BB:5E",
        "groupBy": "LA1-WLC5520-3",
        "category": "Onboarding",
        "severity": "HIGH",
        "priority": "P3",
        "summary": "Wireless client failed to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - AAA Server Rejected Client",
        "scope": "GLOBAL",
        "rootCause": "AUTH_AAA_REJECTED",
        "timestamp": 1523067722512,
        "description": "This client failed to complete authentication during onboarding because the Unknown AAA server rejected the client. This client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP in Site@ToChange. The AP was connected to Wlc@ToChange WLC.",
        "suggestions": [
          {
            "message": "Verify whether the client provided the correct credentials and if the client, AAA and the authentication database are in sync. Has there been any recent configuration changes or batch password updates.",
            "steps": [

            ]
          },
          {
            "message": "Verify if there has been any failure on the AAA Server.",
            "steps": [

            ]
          },
          {
            "message": "Verify if the client software has gone through a recent update since there may have been a change in client behavior.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "assocTime",
            "value": "0"
          },
          {
            "key": "type",
            "value": "issue"
          },
          {
            "key": "reasonType",
            "value": "cl_AAA_AUTH_FAIL"
          },
          {
            "key": "previousApName",
            "value": "LA2-AP3702-39"
          },
          {
            "key": "apName",
            "value": "LA2-AP1815-33"
          },
          {
            "key": "frequency",
            "value": "2.4"
          },
          {
            "key": "reasonCodes",
            "value": "51,0,0,0,0,124,0,0"
          },
          {
            "key": "hostType",
            "value": "WIRELESS"
          },
          {
            "key": "rootCause",
            "value": "AUTH_AAA_REJECTED"
          },
          {
            "key": "resultType",
            "value": "cl_FAIL"
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "totalTime",
            "value": "0"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "apMac",
            "value": "04:DA:D2:91:F6:C0"
          },
          {
            "key": "eventType",
            "value": "cl_EVENT_AUTH_DONE"
          },
          {
            "key": "wlcName",
            "value": "LA1-WLC5520-3"
          },
          {
            "key": "resultTypes",
            "value": "5,0,0,0,0,5,0,0"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "hostName",
            "value": "Grace.Smith-PC"
          },
          {
            "key": "groupType",
            "value": "WLC"
          },
          {
            "key": "authTime",
            "value": "0"
          },
          {
            "key": "groupBy",
            "value": "LA1-WLC5520-3"
          },
          {
            "key": "slot",
            "value": "0"
          },
          {
            "key": "ssid",
            "value": "LA-Topsecret3"
          },
          {
            "key": "vlan",
            "value": "0"
          },
          {
            "key": "_id",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "tenantid",
            "value": "TNT0"
          },
          {
            "key": "aaaServerIp",
            "value": ""
          },
          {
            "key": "dhcpTime",
            "value": "0"
          },
          {
            "key": "hostOSType",
            "value": "Linux-Workstation"
          },
          {
            "key": "isRoaming",
            "value": "false"
          },
          {
            "key": "previousWlcName",
            "value": ""
          },
          {
            "key": "apGroup",
            "value": "LA1"
          },
          {
            "key": "dhcpServerIp",
            "value": ""
          },
          {
            "key": "entityId",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "eventTypes",
            "value": "4,3,3,3,3,4,14,11"
          },
          {
            "key": "eventTimestamps",
            "value": "1523067722512,5071,30628,30624,30672,0,0,20448"
          },
          {
            "key": "siteId",
            "value": "f4fa7637-85d5-4301-9336-5c3d81c7c058"
          },
          {
            "key": "category",
            "value": "onboarding"
          },
          {
            "key": "macAddr",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "timestring",
            "value": "2018-04-07T02:22:02.512+0000"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": "USA/LA/Level21"
      }
    ]
  }
})