define([
        'core/../../../public/libs/node_modules/moment'
    ],
    function (moment) {

    function addAlphanumericValue(val, incrementBy, delimiter, isHexaDecimal) {
        val = Array.from(val);
        var num = incrementBy, indx = val.length - 1;
        if(incrementBy <= 0) {
            return val;
        }
        var carry = 0;
        while(num || carry) {
            var i = Math.round(num % 10);
            num = Math.round(num / 10);
            if(val[indx] == delimiter) {
                indx -= 1;
            }
            var t = val[indx].charCodeAt(0);
            var isGotoElse = false;
            var curVal;
            //if(val[indx].charCodeAt(indx) == 90 || val[indx].charCodeAt(indx) == 122) {
            if(isHexaDecimal) {
                if( (t >= 65 && t <= 70 && t + i >= 70) ) {
                    carry = t + i - 70;
                    curVal = carry ? carry + 65 : 70;
                } else if( (t >= 97 && t <= 102 && t + i >= 102) ) {
                    carry = t + i - 102;
                    curVal = carry ? carry + 97 : 102;
                } else {
                    isGotoElse = true;
                }
            } else {
                if( (t >= 65 && t <= 90 && t + i >= 90) ) {
                    carry = t + i - 90;
                    curVal = carry ? carry + 65 : 90;
                } else if( (t >= 97 && t <= 122 && t + i >= 122) ) {
                    carry = t + i - 122;
                    curVal = carry ? carry + 97 : 122;
                } else {
                    isGotoElse = true;
                }
            }
            
            if(isGotoElse) {
                var isNum = false;
                if(t >= 48 && t <= 57) {
                    t -= 48;
                    isNum = true;
                }
                curVal = t + i + carry;
                carry = 0;

                if(isNum && curVal >= 10) {
                    carry = Math.round(curVal / 10);
                    curVal = Math.round( curVal % 10);
                }
                if(isNum) {
                    curVal += 48;
                    if(curVal > 57) {
                        carry = curVal - 57;
                        curVal = curVal - carry;
                    }
                }
            }
            val[indx] = String.fromCharCode(curVal);
            indx--;
        }

        return val.join('');
    }

        // this file contains helper routines for the simulation code
        return {
            generateId: function(size) {
                var id = "";
                var hexChar = "abcdef0123456789";
                for (var i = 0; i < size; i++) {
                    id += hexChar.charAt(Math.floor(Math.random() * hexChar.length));
                }
                return id;
            },

            // generates a uuid
            generate_uuid: function () {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                }

                return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
            },

            getRandomIntForRangeValues(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            },

            incrementIpAddrres: function(ipAddress, incrementBy) {
                if( incrementBy == 0) {
                    return ipAddress;
                }
                return addAlphanumericValue(ipAddress, incrementBy, '.');
                //return ipAddress;
            },

            incrementIpv6Addrres: function(ipAddress, incrementBy) {
                if( incrementBy == 0) {
                    return ipAddress;
                }
                return addAlphanumericValue(ipAddress, incrementBy, ':', true);
                //return ipAddress;
            },

            incrementMacAddrres: function(macAddress, incrementBy) {
                if( incrementBy == 0) {
                    return macAddress;
                }
                return addAlphanumericValue(macAddress, incrementBy, ':');
                //return macAddress;
            },

            incrementSerialNo: function(serialNo, incrementBy) {
                if( incrementBy == 0) {
                    return serialNo;
                }
                return addAlphanumericValue(serialNo, incrementBy, '');
                //return serialNo;
            },

            getTimeStamp: function () {
                //get timestamp in miliseconds
                return new Date().getTime();
            },

            // removes duplicates from an array
            remove_duplicates: function(list) {
                var seen = {};
                return list.filter(function(item) {
                    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
                });
            },

           getHours: function(startTime, endTime){
                var start = moment(startTime);
                var end = moment(endTime);
                var duration = moment.duration(end.diff(start));
                var hours = duration.asHours();
                return hours;
            },

            getMinuteToMilliSeconds: function(minutes) {
                return 60000 * minutes;
            },

            getDateInTimestamp_SubtractedByDays_FromCurrentDate(noofday) {
                var d = new Date();
                var d1 = new Date().setDate(d.getDate() - noofday);
                return d1;
            },

            //helper function to get current Time in valid  Format
            getCurrentDateAndTimeInValidFormat: function(){
                var ts = new Date();
                var time = ts.toISOString();
                time =time.replace("T" ," ");
                time =time.replace("Z" ,"");
                return time;
            },

            //helper function to get the given Time in valid  Format
            getGivenDateAndTimeInValidFormat: function(timeStamp){
                var ts = new Date(timeStamp);
                var time = ts.toISOString() +"+0000";
                //time =time.replace("T" ," ");
                time =time.replace("Z" ,"");
                return time;
            },

            incrementDateByMinutes: function(tDate, IncrementMinuteBy) {
                var dt = new Date(tDate), changedTime;
                changedTime = new Date( dt.setUTCMinutes(IncrementMinuteBy));

                var year  = changedTime.getFullYear(), month = changedTime.getMonth();
                var date  = changedTime.getDate(), hour = changedTime.getHours();
                var minute = changedTime.getMinutes();

                var adjustedTime = moment([year,month,date,hour,minute,0,0]).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
                return adjustedTime;
            },

            getNumberOfRecordsNeedToGenerate: function(stDate, endDate, windowTime) {
                var s = new Date(stDate), e = new Date(endDate);
                var iLen, totalMinute;

                //var dayDiff = Math.abs(e.getDate() - s.getDate());
                var dayDiff = 0;
                var hourDiff = Math.abs(e.getHours() - s.getHours());
                var minDiff = Math.abs(e.getMinutes() - s.getMinutes());
                minDiff=minDiff>0?minDiff:0;

                if(s.getMonth() == 1 && s.getDate() >= 28 && s.getDate() <= 29) {
                    dayDiff = e.getDate();
                }
                else if( (s.getDate() >= 30 && s.getDate() <= 31) && ( e.getDate() != 30 && e.getDate() != 31) ) {
                    dayDiff = e.getDate();
                } else {
                   // dayDiff = Math.abs(e.getDate() - s.getDate());
                    dayDiff = this.date_diff_indays(stDate,endDate);
                }

                if(dayDiff > 0) {
                    if(hourDiff == 0) {
                        hourDiff = 24;
                    }
                    hourDiff = 24 - s.getHours() + e.getHours();
                } else {
                    dayDiff = 1;
                }



                totalMinute = dayDiff * hourDiff * 60 + minDiff;

                iLen = Math.ceil(totalMinute / windowTime);
                return iLen;
            },

            date_diff_indays : function(date1, date2) {
                var dt1 = new Date(date1);
                var dt2 = new Date(date2);
                return Math.abs((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
            },

            getAdjustStartDateForDataGeneration: function(startTime, endTime, orderBy, timeWindow) {
                //var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc';
                //var stDate = tOrder == 'desc' ? urlAction.filter['endTime'] : urlAction.filter['startTime'];

                var tOrder = orderBy != undefined ? orderBy.toLowerCase():'asc';
                var stDate = tOrder == 'desc' ? endTime : startTime;

                var dt = new Date(stDate), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

                if(tMinute > 55) {
                    changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
                    changedTime.setUTCMinutes(0);
                } else {
                    if(tMinute % timeWindow!=0){
                    if(timeWindow > tMinute)
                        adjustedMinute = -1 * (tMinute % timeWindow);
                    else
                        adjustedMinute =  timeWindow - tMinute;

                    tMinute = tMinute + adjustedMinute;
                }
                    changedTime = new Date( dt.setUTCMinutes(tMinute));
              }

                return changedTime;
            },

            /*generateTime : function(arr_of_obj,fieldName,window) {
                var d = new Date();
                var year =d.getFullYear();
                var month =d.getMonth();
                var date = d.getDate();
                var hour = d.getHours();
                var minute = d.getMinutes();
                var remainder = minute%window;
                    minute = minute-remainder;
                // for sorting
                var date_sort_asc = function (element1, element2) {

                  date1 = new Date(element1[fieldName]);
                  date2 =new Date(element2[fieldName]);
                  if (date1 > date2) return 1;
                  if (date1 < date2) return -1;
                  return 0;
                };
                arr_of_obj.forEach(function (element) {
                        var time = moment([year,month,date,hour,minute,0,0]).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
                        if(minute==0 && hour==0) {
                           minute =60-window;
                           hour =23;
                           date = date-1;
                        } else if(minute==0) {
                           minute =60-window;
                           hour =hour-1;
                        } else {
                           minute =minute-window;
                        }
                      element[fieldName] = time.toString();
                });
                return arr_of_obj.sort(date_sort_asc);
            },*/

            getTimeInVerifyFormat:function() {
                var time = moment().utc().format("DD-MMM-YYYY HH:mm:ss");
                return time;
            },

            getGivenTimeInVerifyFormat:function(timeInMils) {
                var time = moment(timeInMils).utc().format("DD-MMM-YYYY HH:mm:ss");
                return time;
            },

            getGivenTimeInGivenFormat: function(timeInMils, formatReqd) {
                if(timeInMils == undefined || timeInMils == null) {
                    timeInMils = new Date().getTime();
                }
                if(formatReqd == undefined) {
                    formatReqd = "YYYY-MM-DD HH:mm:ss";
                }
                var time = moment(timeInMils).utc().format(formatReqd);
                return time;
            },

            generateData : function(recordObject,no_of_records) {
                var arr_of_obj =[];
                 for(var i=0;i<no_of_records;i++) {
                    arr_of_obj.push(recordObject);
                 }
              return arr_of_obj;
            },

            isObjectEmpty: function(obj) {
                for(var key in obj) {
                    if(obj.hasOwnProperty(key))
                        return false;
                    }
                    return true;
            },

            getCustomFormattedTime: function(timeInMils) {
                var time = moment(timeInMils).utc().format("ddd MMM DD HH:mm:ss UTC YYYY");
                return time;
            },

            getTimeCommaFormatted: function(timeInMils) {
                var time = moment(timeInMils).utc().format("YYYY-MM-DD, HH:mm:ss.SSS");
                return time;
            },

            getDiffTimeFormatted: function(time1, time2){
                //this is to check the time diff within a day
                var diff = time2 - time1;
                var mSecDiff, secDiff, minDiff, hrsDiff;
                hrsDiff = Math.floor(diff / (60*60*1000));
                diff = diff % (60*60*1000);
                minDiff = Math.floor(diff / (60*1000));
                diff = diff % (60*1000);
                secDiff = Math.floor(diff / 1000);
                mSecDiff = diff % 1000;
                return hrsDiff + " hrs " + minDiff + " min " + secDiff + " secs " + mSecDiff + " msc";
            },

            sleep: function(ms) {
                var start = Date.now();
                while (true) {
                    var clock = (Date.now() - start);
                    if (clock >= ms) break;
                }
            },

            sortData: function(sortBy, orderBy, data) {
                if(sortBy != undefined) {
                    return data.sort(function(a, b) {
                        if(a[sortBy] == undefined) {
                            return 1;
                        }
                        if(b[sortBy] == undefined) {
                            return 1;
                        }
                        if(orderBy == undefined || orderBy == 'asc') {
                            if (a[sortBy] < b[sortBy]) return -1;
                            if (a[sortBy] > b[sortBy]) return 1;
                            return 0;
                        } else {
                            if (a[sortBy] > b[sortBy]) return -1;
                            if (a[sortBy] < b[sortBy]) return 1;
                            return 0;
                        }
                   });
                }
                return data;
            },

            sortSecondLevelData: function (property, orderBy, array) {
                //sample usage: sortSecondLevelData('price.item', 'asc', array); this will sort based on item
                property = property.split('.');
                var l = property.length;
                return array.sort( function(a, b) {
                    var i = 0;
                    while( i < l) {
                        a = a[property[i]];
                        b = b[property[i]];
                        i++;
                    }
                    if(orderBy == undefined || orderBy == 'asc') {
                        return a < b ? -1 : 1;
                    } else {
                        return a < b ? 1 : -1;
                    }                    
                });
            },
            
           incrementMinutes: function(tDate, IncrementMinuteBy) {
               var dt = new Date(tDate), changedTime;
               var min = dt.getUTCMinutes();
                changedTime = new Date( dt.setUTCMinutes(min+IncrementMinuteBy));
               var year  = changedTime.getFullYear(), month = changedTime.getMonth();
                var date  = changedTime.getDate(), hour = changedTime.getHours();
                var minute = changedTime.getMinutes();
                var adjustedTime = moment([year,month,date,hour,minute,0,0]).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
                return adjustedTime;
            },

            versionCompare: function (version1, version2) {
                if (version1 === version2) { return 0; }

                var version1Arr = version1.split('.'), version2Arr = version2.split('.');
                var len = Math.min(version1Arr.length, version2Arr.length);

                for (var i = 0; i < len; i++) {
                    if (parseInt(version1Arr[i]) > parseInt(version2Arr[i])) { return 1; }
                    if (parseInt(version1Arr[i]) < parseInt(version2Arr[i])) { return -1; }
                }
                // If one's a prefix of the other, the longer one is greater.
                if (version1Arr.length > version2Arr.length) { return 1; }
                else if (version1Arr.length < version2Arr.length) { return -1; }
                else { return 0; }
            },

            getUsedbyTestSuiteList: function (testSuite, deviceName) {
				var testSuiteList=[];
					for(let i=0; i<testSuite.length; i++) {
						let sensors = testSuite[i].sensors;
						for(let j=0; j<sensors.length; j++) {
							if(deviceName==sensors[j].name) {
							    if(testSuiteList.indexOf(testSuite[i].name)==-1){
								    testSuiteList.push(testSuite[i].name);
								}
							}
						}
					}
				return testSuiteList;
            },

            getCookievalue: function (name){
                var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
                if (match){
                    return match[2];
                }
            },

            randomTimeBetweenDates : function(start, end) {
                var diff =  end.getTime() - start.getTime();
                var new_diff = diff * Math.random();
                var date = new Date(start.getTime() + new_diff);
                return date;
            },

            calculateRecordlen : function(startTime,endTime){
                var MS_PER_DAY = 1000 * 60 * 60 * 24;
                var utc1 = Date.UTC(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
                var utc2 = Date.UTC(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());
                var record={timeWindow:0,recordLen:0}
                var numberOfDays = Math.floor((utc2 - utc1) / MS_PER_DAY);
                if (numberOfDays < 1) {
                    var hours = Math.abs(endTime - startTime) / 36e5;
                    if (hours === 3) {
                        record.timeWindow = 1;
                        record.recordLen = hours * 60;
                    }
                }
                else if (numberOfDays === 1) {
                    record.timeWindow = 5;
                    record.recordLen = (24 * 60) /  record.timeWindow;
                }
                else if (numberOfDays === 7) {
                    record.timeWindow = 35;
                    record.recordLen = (numberOfDays * 24 * 60) /  record.timeWindow;
                }
    
                return record;
    
            },

            validateIPv4Address : function(ipaddress) {  
                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
                  return true;
                }  
                return false;
            },

            validateMacAddress : function(macAddres) {
                var regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
                if(regex.test(macAddres)){
                    return true
                }
                return false
            },

            getIPv4Address : function(cmdStr) {
                var r = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/; //if it is more than 4 octets, it will take the first for. this needs to be handled later..
                var ipAddr = cmdStr.match(r);
                ipAddr = ipAddr!=undefined && ipAddr.length>0 ? ipAddr[0] : undefined;
                var isValidIp = this.validateIPv4Address(ipAddr);//validate the ip address
                return isValidIp ? ipAddr : undefined;
            },

            getInterfaceName : function(cmdStr) {
                /* possible command syntaxes: 
                sh int GigabitEthernet0/0/1, show run interface GigabitEthernet1/13, show power inline GigabitEthernet1/0/14 
                to be careful abt: show controller ethernet-controller GigabitEthernet1/1/1 */
                var validInterfaceNames = ['ethernet', 'loopback', 'vlan', 'mgnt', 'port-channel', 'tunnel'];
                //TenGigabitEthernet, FortyGigabitEthernet, GigabitEthernet, Loopback0, Loopback1021, Vlan, Ethernet, mgmt, Port-channel1, Port-channel10, Tunnel0
                var arr = cmdStr.split(' ');
                var cmdStrArr = arr.map(function(v) { return v.toLowerCase(); });
                for(var i=0; i<cmdStrArr.length; i++) {
                    if (new RegExp(validInterfaceNames.join("|")).test(cmdStrArr[i]) && 
                        ((cmdStrArr[i].indexOf('port-channel')==-1 && cmdStrArr[i].indexOf('-')==-1) || cmdStrArr[i].indexOf('port-channel')>-1) ) {
                        return arr[i];
                    }
                } 
                return undefined;               
            },

            //useful mainly when it is a string array
            convertArrayToCommaSeperated:function(array) {
                return Array.isArray(array) ? array.join() : array;
            },
            
            //generate random number between a range with fixed decimal length
            getRandomFloatForRangeValues(min, max, length) {
                if(length!= undefined && length>-1) {
                    return (Math.random() * (max - min) + min).toFixed(length);
                } else {
                    return (Math.random() * (max - min) + min);
                }
            },

            getSpecificWeekday: function(d, weekDay) {
                d = new Date(d);
                var day = d.getDay(),
                //diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
                //diff = d.getDate() - day + (day == 0 ? weekDay-7:weekDay);
                diff = d.getDate() - day + weekDay - 7;
                return new Date(d.setDate(diff));
            },

            filterBasedOnOffset: function(offsetReq, limitReq, data) {
                var offset = 1, limit = data.length, response = [];
                if(offsetReq) offset = offsetReq;
                if(limitReq) {
                    limit = limitReq;
                    limit = limit == -1 ? 1: limit;
                }
                for (var i = offset-1; i < data.length && i<offset-1+limit; i++) {
                    response.push(data[i]);
                }
                return response;
            },
            decorateData: function (filter, data) {
                var sortBy = filter['sortBy'];
                var orderBy = filter['order'];
                if(sortBy != undefined) {
                    return data.sort(function(a, b) {
                        if(a[sortBy] == undefined) {
                            return 1;
                        }
                        if(b[sortBy] == undefined) {
                            return 1;
                        }
                        if(orderBy == undefined || orderBy == 'asc') {
                            if (a[sortBy] < b[sortBy]) return -1;
                            if (a[sortBy] > b[sortBy]) return 1;
                            return 0;
                        } else {
                            if (a[sortBy] > b[sortBy]) return -1;
                            if (a[sortBy] < b[sortBy]) return 1;
                            return 0;
                        }
                   });
                }
                return data;
            },

            /** 
             * Method to merge recursively and deep clone
             * Ex usage tested: 
             * let x = {'id':123,'name':'Person1','subjects':{'name':'maths','id':1}}
             * let y = {'school':'School1','subjects':{'marks':100}}
             * let z = {'isCompleted':true}
             * deepCloneAndMerge(x,y,z);
             * output will be:
             * {"id":123,"name":"Person1","subjects":{"name":"maths","id":1,"marks":100},"school":"School1","isCompleted":true}
             **/
            deepCloneAndMerge(...objs) {  
                let finalObj = {};
                objs.forEach(obj => {
                    $.extend(true, finalObj, obj);
                });
                return finalObj;
            },

            generateSerialNo() {
                let inputStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", size = 11;
                return this.generateRandomIdFromGiven(inputStr, 11);
            },

            generateMacAddr() {
                let inputStr = "abcdef0123456789", format = "$$:$$:$$:$$:$$:$$";
                return this.generateRandomIdFromGiven(inputStr,undefined, format);
            },

            //usage hint:
            //generateRandomIdFromGiven(inputStr,undefined, format)
            //generateRandomIdFromGiven(inputStr, 11)
            //generateRandomIdFromGiven()
           generateRandomIdFromGiven(inputStr, size, format) {
                if(!inputStr) inputStr = "abcdef0123456789";
                if(!size && !format) size = 5;

                let inputLen = inputStr.length;
                size = format!=undefined ? (format.split("$")).length-1 : size;
                var id = "", char = "";
                for (var i = 0; i < size; i++) {
                    char = inputStr.charAt(Math.floor(Math.random() * inputLen));
                    if(format) {
                        id = id.replace("$",char);
                    } else {
                        id += char;
                    }
                }
                return id;
           },
            /*to get last day of month based on noOfMonths
            getLastDayOfGivenMonths(2) -> ["31-03-2021",28-02-2021"]
            */
           getLastDayOfGivenMonths(noOfMonths){
                let date = new Date()
                let listOfLastday = []
                let counter = 1, max=noOfMonths;

                for(no = counter; counter>no-max; counter--) {
                    let lastDay = new Date(date.getFullYear(), date.getMonth()+counter, 0);
                    let dateFormat = moment(lastDay.getTime()).format("YYYY-MM-DD");
                    listOfLastday.push(dateFormat)
                }
                return listOfLastday
           }

        };
    });
