define([ 
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/HostService',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/HostWirelessConnectionInfo',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/HostWiredConnectionInfo',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/app_experience/AppExperience',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/ConnectionHistory',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/userPrivateNetworkTemplate',
   ], function (SimLokiDatabaseActions, HostService, HostWirelessConnectionInfo, HostWiredConnectionInfo,
    AppExperience, ConnectionHistory, HostIssueListing, UtilityFunctions, SimulationUtility, userPrivateNetworkTemplate) {

        function getHostResponse(tHosts, totalCountSimulated) {
            var resultObj = { page: { pageSize: 0, offset: 0, totalCount: 0 }, response: [], version: "1.0" };
            resultObj.page.pageSize = tHosts.length;

            //workaround for the client table filter issue - whenever the number of results             
            //is <=25 (ie. one page), then return the actual count instead of an 
            //inflated count
            //resultObj.page.totalCount = tHosts.length<=25?tHosts.length:totalCountSimulated;
            resultObj.page.totalCount=totalCountSimulated;
            //CAUTION -- FIXME
            //In UI some places e.g. path trace attribute hostIp is used, hence functionality to work temp hack..
            /*for(var i = 0; i < tHosts.length; i++) {
                tHosts[i].hostIp = tHosts[i].hostIpV4;
            }*/

            resultObj.response = tHosts;
            return resultObj;
        }

        function getHostId(urlAction) {
            var id = '';
            var i;
            for (i = 0; i < urlAction.service.length; i++) {
                if (urlAction.service[i] == 'host') {
                    if (i + 1 <= urlAction.service.length) {
                        id = urlAction.service[i + 1];
                        break;
                    }
                }
            }

            return id;
        }

        function getHostService(hostId, urlAction) {
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': hostId });
            if (obj.length>0) {
                if (obj[0].hostType == 'WIRELESS' && obj[0].healthScore[0].score >= 4 && obj[0].healthScore[0].score <= 10) {
                    var hostService = JSON.parse(JSON.stringify(HostService.HOST_SERVICE));
                    return hostService;
                }else if(obj[0].hostType == 'WIRED' && obj[0].healthScore[0].score >= 4 && obj[0].healthScore[0].score <= 10) {
                    var hostService = JSON.parse(JSON.stringify(HostService.HOST_SERVICE_WIRED));
                    hostService.response.services[3].description = obj[0].port
                    return hostService;
                }
            }
            return { "version": "1.0", "response": { "services": [] } };
        }

        function getConnectionHistory(hostId, urlAction) {
            //var obj = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id':hostId} );
            var t = JSON.parse(JSON.stringify(ConnectionHistory.Template));
            return t;
        }

        function getCustomIssueAfter5Min(customIssues) {
            var matchingIssues=[];
            var currDate = UtilityFunctions.getTimeStamp();
            for(var i=0;i<customIssues.length; i++) {
                var delayedTime = UtilityFunctions.incrementMinutes(customIssues[i].timestamp, 5);
                if(new Date(currDate) >= new Date(delayedTime)) {
                    matchingIssues.push(customIssues[i]);
                }
            }
            return matchingIssues;
        }

        return {

            init: function () {
            },
            getHostRelatedData: function (urlAction) {
               
                var id = getHostId(urlAction);

                if (urlAction.service.indexOf('service') >= 0) {
                    //var hostService = JSON.parse(JSON.stringify( HostService.HOST_SERVICE ));
                    //return hostService;
                    return getHostService(id, urlAction);
                }

                if (urlAction.service.indexOf('connection-history') >= 0) {
                    return getConnectionHistory(id, urlAction);
                }

                if (urlAction.service.indexOf('connection-info') >= 0) {
                    var tHost = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': id });
                    if (tHost.length == 0) {
                        var t = JSON.parse(JSON.stringify(HostWiredConnectionInfo.HOST_Connection_Info));
                        return t;
                    } else if (tHost[0].hostType != 'WIRELESS') {
                        var t = JSON.parse(JSON.stringify(HostWiredConnectionInfo.HOST_Connection_Info));
                        t.response[0].nwDeviceMac = tHost[0].connectedNetworkDeviceId;
                        t.response[0].timestamp = new Date().getTime();
                        return t;
                    }

                    var t = JSON.parse(JSON.stringify(HostWirelessConnectionInfo.HOST_Connection_Info));
                    t.response[0].nwDeviceMac = tHost[0].connectedNetworkDeviceId;
                    t.response[0].timestamp = new Date().getTime();
                    t.response[0].band = tHost[0].frequency;

                    if (tHost[0].subType != null) {
                        if (tHost[0].subType.indexOf('iPad') >= 0 || tHost[0].subType.indexOf('Samsung') >= 0) {
                            t.response[0].spatialStream = 2;
                        }
                    }

                    return t;
                }

                if (urlAction.service.indexOf('app-experience') >= 0) {
                    return AppExperience.AppExperience_Template;
                }

                if(urlAction.service.indexOf('upn-info') > -1){
                    let tJson = JSON.parse(JSON.stringify(userPrivateNetworkTemplate.Template));
                    let hostMac = id;
                    let hostRec = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': hostMac });
                    let userRec = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'userId':hostRec[0].userId});
                    let upnUserRec = [];
                    tJson.response[0].id = hostMac;
                    tJson.response[0].regUpnName = hostRec[0].upnName
                    tJson.response[0].regUpnId = hostRec[0].upnId
                    tJson.response[0].regUpnOwner = hostRec[0].regUpnOwner
                    tJson.response[0].curUpnName = hostRec[0].upnName
                    tJson.response[0].curUpnId = hostRec[0].upnId
                    tJson.response[0].curUpnOwner = hostRec[0].regUpnOwner;
                    for(let k=0; k<userRec.length; k++){
                        let obj = {"mac": "","deviceOwner": "","deviceName": "","deviceType": "",
                        "deviceStatus": "","regUpnName": "","regUpnId": ""}
                        obj.mac = userRec[k].hostMac;
                        obj.deviceOwner = userRec[k].userId
                        obj.deviceName = userRec[k].hostName
                        obj.deviceType = userRec[k].subType
                        obj.deviceStatus = userRec[k].connectionStatus
                        obj.regUpnName = userRec[k].upnName
                        obj.regUpnId = userRec[k].upnId
                        upnUserRec.push(obj)
                    }
                    tJson.response[0].upnAllDevice = upnUserRec
                    return tJson;
                }

                if (id != '') {
                    var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': id });
                    return { "response": objs, "version": "1.0", page: null };
                }

            },

            processHostDetail: function (urlAction) {                
                if(JSON.stringify(urlAction.restPayload).indexOf(`{"filters":{"kpi":[{"kpiAttr":"USER_NAME","condition":[{"name":"within","value":`)>-1) {
                    let hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host', {userId:urlAction.restPayload.filters.kpi[0].condition[0].value[0]})
                    return {page: null,response: hosts, version: "1.0"}
                }

                var hosts=getFilteredHosts(urlAction);
                
                if(JSON.stringify(urlAction.restPayload).includes('{"kpiAttr":"HOST_NAME","condition":[{"name":"within","value":[')){
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='HOST_NAME')].condition[0].value[0].includes(e.hostName))
                }                

                if(JSON.stringify(urlAction.restPayload).includes('{"kpiAttr":"HEALTH_SCORE","condition":[{"name":"gte"')){
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='HEALTH_SCORE')].condition[0].value[0]<e.healthScore[0].score)
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='HEALTH_SCORE')].condition[1].value[0]>e.healthScore[0].score)
                }

                if(JSON.stringify(urlAction.restPayload).includes('{"kpiAttr":"ONBOARDING_SCORE","condition":[{"name":"gte"')){
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='ONBOARDING_SCORE')].condition[0].value[0]<e.healthScore[0].score)
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='ONBOARDING_SCORE')].condition[1].value[0]>e.healthScore[0].score)
                }

                if(JSON.stringify(urlAction.restPayload).includes('{"kpiAttr":"CONNECTED_SCORE","condition":[{"name":"gte"')){
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='CONNECTED_SCORE')].condition[0].value[0]<e.healthScore[0].score)
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='CONNECTED_SCORE')].condition[1].value[0]>e.healthScore[0].score)
                }

                if(JSON.stringify(urlAction.restPayload).includes('{"kpiAttr":"USAGE","condition":[{"name":"decr","value":[]},{"name":"gte",')){
                    hosts.response = hosts.response.filter(e=>urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='USAGE')].condition[1].value[0]<e.usage
                                                            && urlAction.restPayload.filters.kpi[urlAction.restPayload.filters.kpi.findIndex(e=>e.kpiAttr=='USAGE')].condition[2].value[0]>e.usage)
                }
                if(urlAction.restPayload.filters && urlAction.restPayload.filters.kpi) {
                    //slotId check
                    var kpis = urlAction.restPayload.filters.kpi;
                    kpis.forEach(e => {
                        if(e.kpiAttr=='SLOT_ID') {
                            var slot = e.condition[0].value[0];
                            hosts.response = hosts.response.filter(host => {
                                if(slot==0) return host.frequency=='2.4'
                                else if(slot==1) return host.frequency=='5.0'
                                else return false;//slot 2
                            })
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                        }else if(e.kpiAttr=='AAA_SERVER_IP'){
                            hosts.response = hosts.response.filter(h=>h.aaaServerIp==e.condition[0].value)
                            for(i=0;i<hosts.response.length;i++){
                                hosts.response[i].aaaServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[i].aaaServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)
                                //hosts.response[i].aaaServerIp=serverIp
                            }
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                        }else if(e.kpiAttr=='DHCP_SERVER_IP'){
                            hosts.response = hosts.response.filter(h=>h.dhcpServerIp==e.condition[0].value)
                            for(i=0;i<hosts.response.length;i++){
                                hosts.response[i].dhcpServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[i].dhcpServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)
                                //hosts.response[i].dhcpServerIp=serverIp
                            }
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                        }else if(e.kpiAttr=="SITE_ID"){
                            hosts.response = hosts.response.filter(h=>h.location==e.condition[0].value)
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                            for(i=0;i<hosts.response.length;i++){
                                hosts.response[i].dhcpServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[i].aaaServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[i].dhcpServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)
                                hosts.response[i].aaaServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)    
                            }
                            //hosts.response[i].aaaServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                            //hosts.response[i].aaaServerLatency=UtilityFunctions.getRandomIntForRangeValues(5,10)

                        }else if(e.kpiAttr=="AAASERVER"){
                            hosts.response = hosts.response.filter(h=>h.aaaServerIp==e.condition[0].value)
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                            for(j=0;j<hosts.response.length;j++){
                                hosts.response[j].aaaServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[j].aaaServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)
                            }
                        }else if(e.kpiAttr=="DHCPSERVER"){
                            hosts.response = hosts.response.filter(h=>h.dhcpServerIp==e.condition[0].value)
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                            for(j=0;j<hosts.response.length;j++){
                                hosts.response[j].dhcpServerTransaction=UtilityFunctions.getRandomIntForRangeValues(6,15)
                                hosts.response[j].dhcpServerLatency=UtilityFunctions.getRandomIntForRangeValues(6,9)
                            }
                        } else if(e.kpiAttr == "RSSI"){
                            e.condition.map(condition => {
                                if(condition.name == 'lte'){
                                    hosts.response = hosts.response.filter(host => {
                                        return parseInt(host.rssi) <= parseInt(condition.value[0])
                                    })
                                } else if(condition.name == 'lt'){
                                    hosts.response = hosts.response.filter(host => {
                                        return parseInt(host.rssi) < parseInt(condition.value[0])
                                    })
                                } else if(condition.name == 'gte'){
                                    hosts.response = hosts.response.filter(host => {
                                        return parseInt(host.rssi) >= parseInt(condition.value[0])
                                    })
                                }                                
                            })                            
                            hosts.page.pageSize = hosts.response.length;
                            hosts.page.totalCount = hosts.response.length;
                        }
                    });
                }

                return hosts; 
           },
           getTopnclientcount: function(urlAction){
            var deviceIssues = SimLokiDatabaseActions.getAll('issue');
            var devType=urlAction.restPayload.filters.devType[0];
            var customIssues = SimLokiDatabaseActions.getAll('customIssue');
            deviceIssues = deviceIssues.concat(getCustomIssueAfter5Min(customIssues));
            var hosts=getFilteredHosts(urlAction).response;
            var chartList=urlAction.restPayload.chartList;
            var tempObj={};
            var deviceRootCause = [];
            for(var i=0;i<chartList.length;i++)
            {
                tempObj[chartList[i]]=[];
            }
            var ssids=[],siteIds=[],frequencies=[],hostTypes=[],apNames=[],hostoslist=[],deviceNames=[],dhcp=[],aaa=[];
            for(var i=0;i<hosts.length;i++){
              ssids.push(hosts[i].ssid);
              siteIds.push(hosts[i].location);
              hostTypes.push(hosts[i].subType);
              frequencies.push(hosts[i].frequency);
              dhcp.push(hosts[i].dhcpServerIp)
              aaa.push(hosts[i].aaaServerIp)
              if(hosts[i].hostType==="WIRED"){
                deviceNames.push(hosts[i].clientConnection);
              }
               apNames.push(hosts[i].clientConnection);
              hostoslist.push(hosts[i].hostOs);

               deviceRootCause.push (deviceIssues.filter(function( obj ) {
                    return obj.entity === hosts[i].id;
                })
                
                
                
                .map(function( obj ) {
                    return obj.rootCause;
                }));

             }

            /*for(var i=0;i<deviceRootCause.length;i++){
                rootCause.push(deviceRootCause[i][0]);
            }*/

            var rootCause = [].concat.apply([],deviceRootCause);


             var  ssidcount = {}; ssids.forEach(function(i) { ssidcount[i] = (ssidcount[i]||0) + 1;});
             var  sitecount = {}; siteIds.forEach(function(i) { sitecount[i] = (sitecount[i]||0) + 1;});
             var  hostTypecount = {}; hostTypes.forEach(function(i) { hostTypecount[i] = (hostTypecount[i]||0) + 1;});
             var  frequencycount = {}; frequencies.forEach(function(i) { frequencycount[i] = (frequencycount[i]||0) + 1;});
             var  apcount = {}; apNames.forEach(function(i) { apcount[i] = (apcount[i]||0) + 1;});
             var  deviceCount = {}; deviceNames.forEach(function(i) { deviceCount[i] = (deviceCount[i]||0) + 1;});
             var  hostoscount = {}; hostoslist.forEach(function(i) { hostoscount[i] = (hostoscount[i]||0) + 1;});
             var  rootCauseCount = {}; rootCause.forEach(function(i) { rootCauseCount[i] = (rootCauseCount[i]||0) + 1;});
             var dhcpCount={}; dhcp.forEach(function(i) { dhcpCount[i] = (dhcpCount[i]||0) + 1;});
             var aaaCount={}; aaa.forEach(function(i) { aaaCount[i] = (aaaCount[i]||0) + 1;})
            tempObj.HOST_OS=getTopCount(hostoscount,"HOST_OS");
            tempObj.SSID=getTopCount(ssidcount,"SSID");
            tempObj.HOST_TYPE=getTopCount(hostTypecount,"HOST_TYPE");
            if(devType==="WIRED"){
                tempObj.DEVICE_NAME=getTopCount(deviceCount,"DEVICE_NAME");
            }
            tempObj.AP_NAME=getTopCount(apcount,"AP_NAME");
            tempObj.FREQUENCY=getTopCount(frequencycount,"FREQUENCY");
            tempObj.SITE_ID=getTopCount(sitecount,"SITE_ID");
            tempObj.DHCPSERVER=getTopCount(dhcpCount,"DHCPSERVER");
            tempObj.AAASERVER=getTopCount(aaaCount,"AAASERVER");

            if( chartList[0] == "LATEST_ONBOARDING_FAIL_CATEGORY"){
                  var onboardingFailJson = JSON.parse(UtilityFunctions.getCookievalue("onBoardingFailList"));
                  for(var i=0;i<onboardingFailJson.length;i++){
                    if(onboardingFailJson[i].value1 > 0){
                        tempObj[chartList[0]].push({"id": onboardingFailJson[i].breakdown,"name": onboardingFailJson[i].breakdown,"count": onboardingFailJson[i].value1});
                    }
                  }
            }
         /*  else if(chartList[0] == "AAA_ROOTCAUSE"){
                var onboardingFailJson = JSON.parse(UtilityFunctions.getCookievalue("onBoardingFailList"));
                for(var i=0;i<onboardingFailJson.length;i++){
                    if(onboardingFailJson[i].value1 > 0 && onboardingFailJson[i].breakdown==="AAA"){
                        tempObj[chartList[0]].push({"id": onboardingFailJson[i].breakdown,"name": onboardingFailJson[i].breakdown,"count": onboardingFailJson[i].value1});
                    }
                  }
            }*/
            
            /**
             * Fix for extra data was showing in client dashboard's top locations, 
             * top failure reasons
             */
            else if(chartList[0] == "AAA_ROOTCAUSE" || chartList[0] == "DHCP_ROOTCAUSE" || chartList[0] == "ASSOC_ROOTCAUSE"){
                for (const [key, value] of Object.entries(rootCauseCount)) {
                  tempObj[chartList[0]].push({"id": key,"name": key,"count": value});
                }
            }

            var resp= { "version":"1.0","response":tempObj}
             return resp;
           }
        };
        function getCountdetails(arr,obj){
            var resp=[];
           for(var i=0;i<arr.length;i++){
                resp[i]={"id":"","name":"","count":""};
                resp[i].id=arr[i];
                resp[i].name=arr[i];
                resp[i].count=obj[arr[i]];
            }
            return resp;
        }
        function getTopCount(obj,filterName){
            var maxVal=0;
            var maxKeys=[];
            var props = Object.keys(obj).map(function(key) {
                return { id:key, name: key, count: this[key],totalCount:this[key] };
              }, obj);
            props.sort(function(p1, p2) { return p2.count - p1.count; });

          /*if(filterName==="HOST_OS"|| filterName==="HOST_TYPE" ){
                maxKeys = props.slice(0, 2);
            }
            else if(filterName==="SITE_ID"){
                maxKeys = props.slice(0, 3);
            }   */
           // else{
                maxKeys = props.slice(0, 3);
           // }
          
           
            return maxKeys;
        }

          function getFilteredHosts(urlAction){

            if(urlAction.restPayload.filters.userId != undefined || urlAction.restPayload.filters.macAddress != undefined) {
                var userId = '', filter;
                if(urlAction.restPayload.filters.userId != undefined) {
                    userId = urlAction.restPayload.filters.userId;
                    filter = {'userId': userId};
                } else  if(urlAction.restPayload.filters.macAddress != undefined) {
                    filter = {'id': urlAction.restPayload.filters.macAddress};
                }

                var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', filter);
                
                if(objs.length == 0) {
                     //FIXME -- temporary hack -- CAUTION -- NEED to Delete..
                     var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id':'B8:27:EB:CA:AA:88'} );
                }

                if(objs.length) {
                    //CAUTION -- Just a hack
                    window.currentClientMacId = objs[0].id;
                    for (var i = 0; i < objs.length; i++) {
                        objs[i].lastUpdated=new Date();
                       /* var noOfDay = UtilityFunctions.getRandomIntForRangeValues(1, 3);
                        var d = new Date();
                        objs[i].lastUpdated = new Date().setDate(d.getDate() - noOfDay);*/
                       }
                }

                return {"version":"1.0", "response": objs ,"page":null };
            }
            var tObjs = SimLokiDatabaseActions.getAll('host');


                if(tObjs.length) {
                    //CAUTION -- Just a hack
                   for (var i = 0; i < tObjs.length; i++) {
                       var stDate=new Date(urlAction.restPayload.startTime);
                       var utDate=stDate.setHours(stDate.getHours()+1);
                       tObjs[i].lastUpdated=utDate;
                       //var utDate=stDate.setDate(stDate.getDate()*Math.random(1,3));
                       //tObjs[i].lastUpdated=new Date(utDate).setHours(stDate.getHours()*Math.random());
                    }
                }
            var tSimulatedTotal = tObjs.length;

            var siteId = 'global';
            if(urlAction.restPayload.filters.typeIdList.length>0) {
                siteId = SimulationUtility.validSiteId(urlAction.restPayload.filters.typeIdList[0]);
            }
            if(siteId == 'global') {
                tSimulatedTotal = 306
            } else {
                var hosts = [];
                var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': siteId });
                var siteLocation = siteObj[0].groupNameHierarchy;
                var hostsList = SimLokiDatabaseActions.getAll('host');
                for (var i = 0; i < hostsList.length; i++) {
                    var hostLocation = hostsList[i].location;
                    if (hostLocation.indexOf(siteLocation) == 0) {
                        hosts.push(hostsList[i]);
                    }
                }
                var tObjs = hosts;
                var tSimulatedTotal = tObjs.length;
            }

            if (UtilityFunctions.isObjectEmpty(urlAction.restPayload.filters)) {
                tObjs = tObjs.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                return getHostResponse(tObjs, tSimulatedTotal);
            }

            if ((urlAction.restPayload.filters.scoreType && urlAction.restPayload.filters.scoreType.length == 0) && urlAction.restPayload.filters.devType.length == 0
                && urlAction.restPayload.filters.kpi.length == 0 &&
                (!urlAction.restPayload.filters.ssidList || urlAction.restPayload.filters.ssidList.length < 1) &&
                (!urlAction.restPayload.filters.clientConnectionList || urlAction.restPayload.filters.clientConnectionList.length < 1) &&
                (!urlAction.restPayload.filters.apGroupList || urlAction.restPayload.filters.apGroupList.length < 1) &&
                (!urlAction.restPayload.filters.frequency)) {
                tObjs = tObjs.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                return getHostResponse(tObjs, tSimulatedTotal);
            }

            var iWired = urlAction.restPayload.filters.devType.indexOf('WIRED');
            var iWireless = urlAction.restPayload.filters.devType.indexOf('WIRELESS');
            var idle = urlAction.restPayload.filters.scoreType ? urlAction.restPayload.filters.scoreType.indexOf('IDLE') : 0;

            if (idle < 0) {
                tObjs = tObjs.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                tSimulatedTotal = tObjs.length;
            }

            if (iWired >= 0 && iWireless == -1) {
                tObjs = tObjs.filter(function (itm) { return (itm.hostType == 'WIRED'); });
                tSimulatedTotal = tObjs.length;
                if (urlAction.restPayload.filters.typeIdList.length == 0 || 
                     urlAction.restPayload.filters.typeIdList[0] === "__global__") {
                    tSimulatedTotal = 56
                }
            }
            else if (iWired == -1 && iWireless >= 0) {
                tObjs = tObjs.filter(function (itm) { return (itm.hostType == 'WIRELESS'); });
                tSimulatedTotal = tObjs.length;
                if (urlAction.restPayload.filters.typeIdList.length == 0 || 
                     urlAction.restPayload.filters.typeIdList[0] === "__global__") {
                    tSimulatedTotal = 250;
                }
            }

            //since the call doesnt have details of onboarding or connectivity, we shall consider overall healthscore
              if (urlAction.restPayload.filters.scoreType) {
                  if (urlAction.restPayload.filters.scoreType.indexOf('POOR') >= 0) {
                      tObjs = tObjs.filter(function (itm) {
                          return parseInt(itm.healthScore[0].score) > 0 &&
                              parseInt(itm.healthScore[0].score) <= 3;
                      });
                  } else if (urlAction.restPayload.filters.scoreType.indexOf('FAIR') >= 0) {
                      tObjs = tObjs.filter(function (itm) {
                          return parseInt(itm.healthScore[0].score) >= 4 &&
                              parseInt(itm.healthScore[0].score) <= 7;
                      });
                  } else if (urlAction.restPayload.filters.scoreType.indexOf('GOOD') >= 0) {
                      //from graph selection, ONBOARDED is sent
                      tObjs = tObjs.filter(function (itm) {
                          return parseInt(itm.healthScore[0].score) >= 8 &&
                              parseInt(itm.healthScore[0].score) <= 10;
                      });
                  }
                  else if (urlAction.restPayload.filters.scoreType.indexOf('ONBOARDED') >= 0) {
                      tObjs = tObjs.filter(function (itm) {
                          return parseInt(itm.healthScore[0].score) >= 4 &&
                              parseInt(itm.healthScore[0].score) <= 10;
                      });
                  }
                  else if (urlAction.restPayload.filters.scoreType.indexOf('NODATA') >= 0) {
                      tObjs = tObjs.filter(function (itm) { return parseInt(itm.healthScore[0].score) == 0; });
                  }
                  /*  else if (urlAction.restPayload.filters.scoreType.indexOf('Errors') >= 0) {
                        tObjs = tObjs.filter(function (itm) {
                            return parseInt(itm.healthScore[0].score) >= 4 &&
                                parseInt(itm.healthScore[0].score) <= 6 && itm.hostType === "WIRED"
                        });
                    }*/

                  else if (urlAction.restPayload.filters.scoreType.indexOf('IDLE') >= 0) {
                      tObjs = tObjs.filter(function (itm) { return parseInt(itm.healthScore[0].score) == 0; });
                  }
              }
            


            var isFilterApplied = false;//filter from table selected
            if (urlAction.restPayload.filters.ssidList && urlAction.restPayload.filters.ssidList.length > 0) {
                isFilterApplied = true;
                tObjs = tObjs.filter(function (itm) { return (urlAction.restPayload.filters.ssidList.indexOf(itm.ssid) > -1 && (itm.hostType==="WIRELESS")); });
            }
            if (urlAction.restPayload.filters.clientConnectionList && urlAction.restPayload.filters.clientConnectionList.length > 0) {
                isFilterApplied = true;
                tObjs = tObjs.filter(function (itm) { return urlAction.restPayload.filters.clientConnectionList.indexOf(itm.clientConnection) > -1; });
            }
            if (urlAction.restPayload.filters.apGroupList && urlAction.restPayload.filters.apGroupList.length > 0) {
                isFilterApplied = true;
                tObjs = tObjs.filter(function (itm) { return urlAction.restPayload.filters.apGroupList.indexOf(itm.apGroup) > -1; });
            }
         
            var frequencyIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    frequencyIndex=k;
                }
            }
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
                    || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                isFilterApplied = true;
                 //  tObjs = tObjs.filter(function (itm) { return (Number(frequency) == Number(itm.frequency) || (itm.hostType === "WIRED")); });
                 if(frequency!="" || frequency != undefined){
                tObjs = tObjs.filter(function(itm){ return (Number(frequency)==Number(itm.frequency) && (itm.hostType==="WIRELESS")); });
                 }

            }



            if ((urlAction.restPayload.filters.scoreType && urlAction.restPayload.filters.scoreType.length) || isFilterApplied) {
                tSimulatedTotal = tObjs.length;
            }

            var kpiAttrList=[];
            for(var i = 0; i < urlAction.restPayload.filters.kpi.length; i++) {
                if(iWired>-1 && (urlAction.restPayload.filters.kpi[i].kpiAttr=='FREQUENCY' || 
                  urlAction.restPayload.filters.kpi[i].kpiAttr=='SSID' || urlAction.restPayload.filters.kpi[i].kpiAttr=='DOT11_PROTOCOL')) {
                    continue;
                }
                kpiAttrList.push(urlAction.restPayload.filters.kpi[i].kpiAttr);
            } 
            if(kpiAttrList.indexOf('SSID')>=0){
                var KPIIndex=kpiAttrList.indexOf('SSID');
                var ssidList1=urlAction.restPayload.filters.kpi[KPIIndex].condition[0].value;
                if(ssidList1.length>0){
                tObjs = tObjs.filter(function (itm) { return (ssidList1.indexOf(itm.ssid) > -1 && (itm.hostType==="WIRELESS")); });
            }
            }

            if(kpiAttrList.indexOf('DOT11_PROTOCOL') > -1){
                var KPIIndex=kpiAttrList.indexOf('DOT11_PROTOCOL');
                var protocolList=urlAction.restPayload.filters.kpi[KPIIndex].condition[0].value;
                var isString = typeof(protocolList[0]) == "string";
                protocolList = (SimulationUtility.PROTOCOLS.filter(obj => {
                    return isString ? protocolList.indexOf(obj.value.toString())>-1 : protocolList.indexOf(obj.value)>-1 })
                ).map( obj2 => obj2.dnacVal );
                if(protocolList.length>0){
                    tObjs = tObjs.filter(function (itm) { return (protocolList.indexOf(itm.dot11Protocol) > -1 && (itm.hostType==="WIRELESS")); });
                }
            } else if(kpiAttrList.indexOf('DOT11_PROTOCOL_CAPABILITY') > -1){
                //DOT11_PROTOCOL_CAPABILITY holds higher level wrt DOT11_PROTOCOL. Hence writing in else clause, even if both are present.
                var KPIIndex=kpiAttrList.indexOf('DOT11_PROTOCOL_CAPABILITY');
                var protocolCapList=urlAction.restPayload.filters.kpi[KPIIndex].condition[0].value;
                var isString = typeof(protocolCapList[0]) == "string";
                protocolCapList = (SimulationUtility.PROTOCOLS.filter(obj => {
                    return isString ? protocolCapList.indexOf(obj.capabilityVal.toString())>-1 : protocolCapList.indexOf(obj.capabilityVal)>-1 })
                ).map( obj2 => obj2.capability );
                if(protocolCapList.length>0){
                    tObjs = tObjs.filter(function (itm) { return (protocolCapList.indexOf(itm.dot11ProtocolCapability) > -1 && (itm.hostType==="WIRELESS")); });
                }
            }

            if(kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT')>=0 || kpiAttrList.indexOf('FAILED_ROAMING_COUNT')>=0){
                tObjs = getFilterBasedOnRoamingKpi(tObjs,urlAction,kpiAttrList);
                for (var i = 0; i < urlAction.restPayload.filters.kpi.length && tObjs.length; i++) {
                    var kpiAttr = urlAction.restPayload.filters.kpi[i].kpiAttr;
                    if((urlAction.restPayload.filters.kpi[i].kpiAttr=='FREQUENCY' || 
                  urlAction.restPayload.filters.kpi[i].kpiAttr=='SSID')) {
                    continue;
                }
                else if((kpiAttr==='HOST_NAME')||(kpiAttr==="HOST_TYPE")||(kpiAttr==="USER_NAME")||
                (kpiAttr==="IPV4")||(kpiAttr==="AP_NAME")||(kpiAttr==="SITE_ID")||(kpiAttr==="HOST_OS")||
                (kpiAttr==="DEVICE_NAME")||(kpiAttr==="IPV6")||(kpiAttr==="MAC_ADDRESS")||
                (kpiAttr==="AUTHTYPE")||(kpiAttr==="PORT")){
                    var compareArr=urlAction.restPayload.filters.kpi[i].condition[0].value;
                    var compareAttr=urlAction.restPayload.filters.kpi[i].condition[0].name;
                    var kpiName="";
                    if(kpiAttr==="HOST_NAME")kpiName="hostName";
                    if(kpiAttr==="HOST_TYPE")kpiName="subType";
                    if(kpiAttr==="USER_NAME")kpiName="userId";
                    if(kpiAttr==="IPV4")kpiName="hostIpV4";
                    if(kpiAttr==="AP_NAME")kpiName="clientConnection";
                    if(kpiAttr==="SITE_ID")kpiName="location";
                    if(kpiAttr==="HOST_OS")kpiName="hostOs";
                    if(kpiAttr==="MAC_ADDRESS")kpiName="hostMac";
                    if(kpiAttr==="AUTHTYPE")kpiName="authType";
                    if(kpiAttr==="IPV6")kpiName="hostIpV6";
                    if(kpiAttr==="DEVICE_NAME")kpiName="clientConnection";
                    if(kpiAttr==="PORT")kpiName="port";
                    if(compareAttr == "decr"){
                        urlAction.filter['sortBy'] = kpiName;
                        urlAction.filter['order'] = "desc";
                    } else if(compareAttr == "incr"){
                        urlAction.filter['sortBy'] = kpiName;
                        urlAction.filter['order'] = "asc";
                    } else {
                        tObjs=getFilterBasedOnKpiString(tObjs,kpiName,compareAttr,compareArr);
                    }
                }
                else if((kpiAttr==='HEALTH_SCORE')||(kpiAttr==='MAX_RUN_DURATION')||(kpiAttr==='MAX_ASSOC_DURATION')
                ||(kpiAttr==='MAX_AUTH_DURATION')||(kpiAttr==='MAX_DHCP_DURATION')||(kpiAttr==='USAGE')||(kpiAttr==='RSSI')
                ||(kpiAttr==='AVG_RSSI')||(kpiAttr==='SNR')||(kpiAttr==='AVG_SNR')||(kpiAttr==='TX_BYTES')||(kpiAttr==='RX_BYTES')
                ||(kpiAttr==='DATARATE')||(kpiAttr==='ONBOARDING_SCORE')||(kpiAttr==='CONNECTED_SCORE')){
                    var conditions=urlAction.restPayload.filters.kpi[i].condition;
                    var compareMinValue, compareMaxValue;
                    for(var n=0;n<conditions.length;n++){
                        if(conditions[n].name==="gte"){
                            compareMinValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="lte"){
                            compareMaxValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="gt"){
                            compareMinValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="lt"){
                            compareMaxValue=conditions[n].value[0];
                        } 
                        if(conditions[n].name==="decr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "desc";
                        } else if(conditions[n].name==="incr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "asc";
                        }
                    }
                    var kpiName=""; 
                    if(kpiAttr==="HEALTH_SCORE")kpiName="healthScore.overall";
                    if(kpiAttr==="ONBOARDING_SCORE")kpiName="healthScore.onboarding";
                    if(kpiAttr==="CONNECTED_SCORE")kpiName="healthScore.connected";
                    if(kpiAttr==="MAX_RUN_DURATION")kpiName="onboarding.maxRunDuration";
                    if(kpiAttr==="MAX_ASSOC_DURATION")kpiName="onboarding.maxAssocDuration";
                    if(kpiAttr==="MAX_AUTH_DURATION")kpiName="onboarding.maxAuthDuration";
                    if(kpiAttr==="MAX_DHCP_DURATION")kpiName="onboarding.maxDhcpDuration";
                    if(kpiAttr==="USAGE")kpiName="usage";
                    if(kpiAttr==="RSSI")kpiName="rssi";
                    if(kpiAttr==="AVG_RSSI")kpiName="avgRssi";
                    if(kpiAttr==="SNR")kpiName="snr";
                    if(kpiAttr==="AVG_SNR")kpiName="avgSnr";
                    if(kpiAttr==="TX_BYTES")kpiName="txBytes";
                    if(kpiAttr==="RX_BYTES")kpiName="rxBytes";
                    if(kpiAttr==="DATARATE")kpiName="dataRate";

                    if(urlAction.filter['sortBy'] == "toFill") {
                        urlAction.filter['sortBy'] = kpiName;
                    } else {
                        tObjs=getFilterBasedOnKpiRange(tObjs,kpiName,compareMinValue,compareMaxValue);
                    }
                    
                }
                else if((kpiAttr==='ONBOARDING_EVENTTIME')||(kpiAttr==='ASSOC_DONE_TIMESTAMP')||(kpiAttr==='AUTH_DONE_TIMESTAMP')
                        ||(kpiAttr==='DHCP_DONE_TIMESTAMP')||((kpiAttr==='LAST_UPDATED') && (urlAction.restPayload.filters.kpi[i].condition.length>1))){
                            var conditions=urlAction.restPayload.filters.kpi[i].condition;
                    var compareStartDate=0, compareEndDate=0;
                    for(var n=0;n<conditions.length;n++){
                        if(conditions[n].name==="gte"){
                            compareStartDate=conditions[n].value[0];
                        }
                        if(conditions[n].name==="lte"){
                            compareEndDate=conditions[n].value[0];
                        }
                        if(conditions[n].name==="decr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "desc";
                        } else if(conditions[n].name==="incr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "asc";
                        }
                    }
                    var kpiName=""; 
                    if(kpiAttr==="ONBOARDING_EVENTTIME")kpiName="onboardingTime";
                    if(kpiAttr==="ASSOC_DONE_TIMESTAMP")kpiName="onboarding.assocDoneTime";
                    if(kpiAttr==="AUTH_DONE_TIMESTAMP")kpiName="onboarding.authDoneTime";
                    if(kpiAttr==="DHCP_DONE_TIMESTAMP")kpiName="onboarding.dhcpDoneTime";
                    if(kpiAttr==="LAST_UPDATED")kpiName="lastUpdated";
                    
                    if(urlAction.filter['sortBy'] == "toFill") {
                        urlAction.filter['sortBy'] = kpiName;
                    } else {
                        tObjs=getFilterBasedOnKpiDate(tObjs,kpiName,compareStartDate,compareEndDate);
                    }
                  }              
                }
              }
              else{

            //code to filter based on dhcp, rssi and association time related
            for (var i = 0; i < urlAction.restPayload.filters.kpi.length && tObjs.length; i++) {
                if(iWired>-1 || (urlAction.restPayload.filters.kpi[i].kpiAttr=='FREQUENCY' || 
                  urlAction.restPayload.filters.kpi[i].kpiAttr=='SSID' || urlAction.restPayload.filters.kpi[i].kpiAttr=="DOT11_PROTOCOL")) {
                    continue;
                }
                var kpiAttr = urlAction.restPayload.filters.kpi[i].kpiAttr;
                if(kpiAttr==="LATEST_ONBOARDING_FAIL_CATEGORY"){
                    var kpiValueToCompare="",kpiOperation="";
                     if(urlAction.restPayload.filters.kpi[i].condition==undefined || urlAction.restPayload.filters.kpi[i].condition.length<=0){
                         kpiValueToCompare = "ASSOC,AAA,DHCP,OTHER";
                         kpiOperation = "within";
                    }
                    else if(urlAction.restPayload.filters.kpi[i] && urlAction.restPayload.filters.kpi[i].condition[0] && urlAction.restPayload.filters.kpi[i].condition[0].value.indexOf("OTHER")>-1){
                        kpiValueToCompare = "ASSOC,AAA,DHCP,OTHER";
                         kpiOperation = "within";
                    }
                    else if(urlAction.restPayload.filters.kpi[i].condition.length>0){
                      kpiValueToCompare =urlAction.restPayload.filters.kpi[i].condition[0].value.toString();
                      kpiOperation = urlAction.restPayload.filters.kpi[i].condition[0].name;
                }

                    if(kpiOperation == "decr"){
                        urlAction.filter['sortBy'] = kpiAttr;
                        urlAction.filter['order'] = "desc";
                    } else if(kpiOperation == "incr"){
                        urlAction.filter['sortBy'] = kpiAttr;
                        urlAction.filter['order'] = "asc";
                    } else {
                        tObjs = getFilterBasedOnKpi(tObjs, kpiAttr, kpiValueToCompare, kpiOperation);
                    }                    
                }
                else if(kpiAttr==="AAA_ROOTCAUSE"){
                     var kpiValueToCompare="",kpiOperation="";
                    var deviceIssues = SimLokiDatabaseActions.getAll('issue');
                    var customIssues = SimLokiDatabaseActions.getAll('customIssue');
                    deviceIssues = deviceIssues.concat(getCustomIssueAfter5Min(customIssues));
                    if(urlAction.restPayload.filters.kpi[i].condition.length>0){
                       var objs=[],deviceRootCause=[]; 
                       kpiValueToCompare =urlAction.restPayload.filters.kpi[i].condition[0].value;
                       for(var k=0;k<kpiValueToCompare.length;k++){
                         deviceRootCause.push (deviceIssues.filter(function( obj ) {
                            return obj.rootCause === kpiValueToCompare[k];
                            }));
                        }
                        for(var m=0;m<deviceRootCause.length;m++){
                        var devicesWithIssues=deviceRootCause[m];
                        for(var k=0;k<tObjs.length;k++){
                            for(var l=0;l<devicesWithIssues.length;l++){
                            if(tObjs[k].id===devicesWithIssues[l].entity){
                                objs.push(tObjs[k]);
                            }
                            }      
                        } 
                      }
                      function getUnique(arr, comp) {
                         const unique = arr.map(e => e[comp])
                         .map((e, i, final) => final.indexOf(e) === i && i)
                         .filter(e => arr[e]).map(e => arr[e]);
                         return unique;
                      }
                      var objs1=getUnique(objs,'id');
                      tObjs=objs1; 
                       }   
                }
                else if((urlAction.restPayload.filters.kpi[i].kpiAttr=='FREQUENCY' || 
                  urlAction.restPayload.filters.kpi[i].kpiAttr=='SSID')) {
                    continue;
                }
                else if((kpiAttr==='HOST_NAME')||(kpiAttr==="HOST_TYPE")||(kpiAttr==="USER_NAME")||
                (kpiAttr==="IPV4")||(kpiAttr==="AP_NAME")||(kpiAttr==="SITE_ID")||(kpiAttr==="HOST_OS")||
                (kpiAttr==="DEVICE_NAME")||(kpiAttr==="IPV6")||(kpiAttr==="MAC_ADDRESS")||
                (kpiAttr==="AUTHTYPE")||(kpiAttr==="PORT")){
                    var compareArr=urlAction.restPayload.filters.kpi[i].condition[0].value;
                    var compareAttr=urlAction.restPayload.filters.kpi[i].condition[0].name;
                    var kpiName="";
                    if(kpiAttr==="HOST_NAME")kpiName="hostName";
                    if(kpiAttr==="HOST_TYPE")kpiName="subType";
                    if(kpiAttr==="USER_NAME")kpiName="userId";
                    if(kpiAttr==="IPV4")kpiName="hostIpV4";
                    if(kpiAttr==="IPV6") kpiName="hostIpV6";
                    if(kpiAttr==="MAC_ADDRESS")kpiName="hostMac";
                    if(kpiAttr==="AP_NAME")kpiName="clientConnection";
                    if(kpiAttr==="SITE_ID")kpiName="location";
                    if(kpiAttr==="HOST_OS")kpiName="hostOs";
                    if(kpiAttr==="DEVICE_NAME")kpiName="clientConnection";
                    if(kpiAttr==="AUTHTYPE")kpiName="authType";
                    if(kpiAttr==="PORT")kpiName="port";
                    if(compareAttr == "decr"){
                        urlAction.filter['sortBy'] = kpiName;
                        urlAction.filter['order'] = "desc";
                    } else if(compareAttr == "incr"){
                        urlAction.filter['sortBy'] = kpiName;
                        urlAction.filter['order'] = "asc";
                    } else {
                        if(urlAction.restPayload.filters.caller && (urlAction.restPayload.filters.caller=="NETWORK_SERVICE_AAA_TOP_SITE"||urlAction.restPayload.filters.caller=="NETWORK_SERVICE_DHCP_TOP_SITE")){
                            let sites=SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':compareArr[0]})
                            if(sites[0]!=undefined){
                            compareArr[0]=sites[0].groupNameHierarchy
                            //return tObjs
                            }
                            tObjs=getFilterBasedOnKpiString(tObjs,kpiName,compareAttr,compareArr);
                        }
                        else{
                            tObjs=getFilterBasedOnKpiString(tObjs,kpiName,compareAttr,compareArr);
                        }
                    }
                }
                else if((kpiAttr==='HEALTH_SCORE')||(kpiAttr==='MAX_RUN_DURATION')||(kpiAttr==='MAX_ASSOC_DURATION')
                ||(kpiAttr==='MAX_AUTH_DURATION')||(kpiAttr==='MAX_DHCP_DURATION')||(kpiAttr==='USAGE')||(kpiAttr==='RSSI')
                ||(kpiAttr==='AVG_RSSI')||(kpiAttr==='SNR')||(kpiAttr==='AVG_SNR')||(kpiAttr==='TX_BYTES')||(kpiAttr==='RX_BYTES')
                ||(kpiAttr==='DATARATE')||(kpiAttr==='ONBOARDING_SCORE')||(kpiAttr==='CONNECTED_SCORE')){
                    var conditions=urlAction.restPayload.filters.kpi[i].condition;
                    var compareMinValue, compareMaxValue;
                    for(var n=0;n<conditions.length;n++){
                        if(conditions[n].name==="gte"){
                            compareMinValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="lte"){
                            compareMaxValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="gt"){
                            compareMinValue=conditions[n].value[0];
                        }
                        if(conditions[n].name==="lt"){
                            compareMaxValue=conditions[n].value[0];
                        } 
                        if(conditions[n].name == "decr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "desc";
                        } else if(conditions[n].name == "incr"){
                            urlAction.filter['sortBy'] = "toFill";
                            urlAction.filter['order'] = "asc";
                        }
                    }
                    var kpiName=""; 
                    if(kpiAttr==="HEALTH_SCORE")kpiName="healthScore.overall";
                    if(kpiAttr==="ONBOARDING_SCORE")kpiName="healthScore.onboarding";
                    if(kpiAttr==="CONNECTED_SCORE")kpiName="healthScore.connected";
                    if(kpiAttr==="MAX_RUN_DURATION")kpiName="onboarding.maxRunDuration";
                    if(kpiAttr==="MAX_ASSOC_DURATION")kpiName="onboarding.maxAssocDuration";
                    if(kpiAttr==="MAX_AUTH_DURATION")kpiName="onboarding.maxAuthDuration";
                    if(kpiAttr==="MAX_DHCP_DURATION")kpiName="onboarding.maxDhcpDuration";
                    if(kpiAttr==="USAGE")kpiName="usage";
                    if(kpiAttr==="RSSI")kpiName="rssi";
                    if(kpiAttr==="AVG_RSSI")kpiName="avgRssi";
                    if(kpiAttr==="SNR")kpiName="snr";
                    if(kpiAttr==="AVG_SNR")kpiName="avgSnr";
                    if(kpiAttr==="TX_BYTES")kpiName="txBytes";
                    if(kpiAttr==="RX_BYTES")kpiName="rxBytes";
                    if(kpiAttr==="DATARATE")kpiName="dataRate";

                    if(urlAction.filter['sortBy'] == "toFill") {
                        urlAction.filter['sortBy'] = kpiName;
                    } else {
                        tObjs=getFilterBasedOnKpiRange(tObjs,kpiName,compareMinValue,compareMaxValue);
                    }
                }
                else if((kpiAttr==='ONBOARDING_EVENTTIME')||(kpiAttr==='ASSOC_DONE_TIMESTAMP')||(kpiAttr==='AUTH_DONE_TIMESTAMP')
                ||(kpiAttr==='DHCP_DONE_TIMESTAMP')||((kpiAttr==='LAST_UPDATED')&& (urlAction.restPayload.filters.kpi[i].condition.length>1))){
                    var conditions=urlAction.restPayload.filters.kpi[i].condition;
                        var compareStartDate=0, compareEndDate=0;
                        for(var n=0;n<conditions.length;n++){
                            if(conditions[n].name==="gte"){
                                compareStartDate=conditions[n].value[0];
                            }
                            if(conditions[n].name==="lte"){
                                compareEndDate=conditions[n].value[0];
                            }
                            if(conditions[n].name==="decr"){
                                urlAction.filter['sortBy'] = "toFill";
                                urlAction.filter['order'] = "desc";
                            } else if(conditions[n].name==="incr"){
                                urlAction.filter['sortBy'] = "toFill";
                                urlAction.filter['order'] = "asc";
                            }
                        }
                        var kpiName=""; 
                        if(kpiAttr==="ONBOARDING_EVENTTIME")kpiName="onboardingTime";
                        if(kpiAttr==="ASSOC_DONE_TIMESTAMP")kpiName="onboarding.assocDoneTime";
                        if(kpiAttr==="AUTH_DONE_TIMESTAMP")kpiName="onboarding.authDoneTime";
                        if(kpiAttr==="DHCP_DONE_TIMESTAMP")kpiName="onboarding.dhcpDoneTime";
                        if(kpiAttr==="LAST_UPDATED")kpiName="lastUpdated";
                        
                        if(urlAction.filter['sortBy'] == "toFill") {
                            urlAction.filter['sortBy'] = kpiName;
                        } else {
                            tObjs=getFilterBasedOnKpiDate(tObjs,kpiName,compareStartDate,compareEndDate);
                        }
                    }
                else{
                    for (var j = 0; j < urlAction.restPayload.filters.kpi[i].condition.length; j++) {
                    if (!Number.isNaN(parseInt(urlAction.restPayload.filters.kpi[i].condition[j].value[0]))) {
                        var kpiValueToCompare = parseInt(urlAction.restPayload.filters.kpi[i].condition[j].value[0]);
                        var kpiOperation = urlAction.restPayload.filters.kpi[i].condition[j].name;
                        if(kpiOperation == "decr"){
                            urlAction.filter['sortBy'] = kpiAttr;
                            urlAction.filter['order'] = "desc";
                        } else if(kpiOperation == "incr"){
                            urlAction.filter['sortBy'] = kpiAttr;
                            urlAction.filter['order'] = "asc";
                        } else {
                            tObjs = getFilterBasedOnKpi(tObjs, kpiAttr, kpiValueToCompare, kpiOperation);
                        }
                    }
                  
                }
                }
            }
        }

           var poorObjs = tObjs.filter(function (itm) {
                return parseInt(itm.healthScore[0].score) > 0 &&
                    parseInt(itm.healthScore[0].score) <= 3;
            });
            poorCount=poorObjs.length;

            if (urlAction.restPayload.filters.typeIdList.length == 0 || 
                 urlAction.restPayload.filters.typeIdList[0] === "__global__") {

            var hostsList = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostType': "WIRELESS" });
            var tempWireless=250; var tempWired=56; var allSsids=[];

           // var poorCount=0;
            for (var i = 0; i < hostsList.length; i++) {
                if(hostsList[i].ssid!=undefined || hostsList[i].ssid!=null)
                allSsids.push(hostsList[i].ssid);
            }

            var ssids = [...new Set(allSsids)];


            var frequencyIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    frequencyIndex=k;
                }
            }
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
                    || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                              tempWired=0
                if(frequency==="2.4"){
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                   tempWireless=34;
                }
                else if (frequency === "5.0" || frequency === "5") {
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                    tempWireless=216;
                }
            }
            var KPIIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    KPIIndex=k;
                }
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    var frequency=urlAction.restPayload.filters.kpi[k].condition[0].value;
                    if(frequency==="2.4"){
                        hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                       tempWireless=34;
                    }
                    else if (frequency === "5.0" || frequency === "5") {
                        hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                        tempWireless=216;
                    }
                }
            }


            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0||
                (KPIIndex>=0)){
               tempWired=0;
               if(KPIIndex > -1) ssidList1=urlAction.restPayload.filters.kpi[KPIIndex].condition[0].value;
                var s = [], totalsum = 0, index = -1, count=0;
                for(var k=0;k<ssids.length;k++){s[k]=0};
                for(var j=0;j<hostsList.length;j++){
                    for(var k=0;k<ssids.length;k++){
                        if(hostsList[j].ssid===ssids[k]){
                            s[k]++;
                        }
                    }
                }
              for (var i = 0; i < ssids.length; i++) {
                    if (ssids[i] === "c9800AP11AX") {
                        index = i;
                        continue;
                    }
                    s[i] += Math.round(tempWireless * 0.06);
                    totalsum += s[i];

                }
                if (index >= 0) {
                    s[index] = tempWireless - totalsum;
                }
                else {
                    s[s.length - 1] = tempWireless - totalsum;
                }
                if(urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0){
                for(var k=0;k<urlAction.restPayload.filters.ssidList.length;k++){
                for(var z=0;z<ssids.length;z++){
                    if(urlAction.restPayload.filters.ssidList[k]===ssids[z]){
                        count=count+s[z];
                    }
                }
            }
        }
        if(KPIIndex>=0){
            if(ssidList1.length>0){
                for(var k=0;k<ssidList1.length;k++){
                    for(var z=0;z<ssids.length;z++){
                        if(ssidList1[k]===ssids[z]){
                            count=count+s[z];
                        }
                    }
            }
            }
        }
        
          tempWireless=count;
             
            }


             tSimulatedTotal=tempWireless;

             var poorCount1=0;
             if(urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.ssidList != undefined && urlAction.restPayload.filters.ssidList.length > 0){
                var ssids=urlAction.restPayload.filters.ssidList;
                if(ssids.indexOf("c9800AP11AX")>=0 && frequency==="5.0"){
                    poorCount1=poorCount+4;
                }
                else{
                    poorCount1=poorCount;
                }

            }
            else{
                if(frequency==="2.4"){
                    poorCount1=poorCount1;
                   }   else{
                    poorCount1=13;
                    }
               }

           if (urlAction.restPayload.filters.devType.indexOf('WIRED') >= 0) {
                    var scoreArray = {
                        "poorCount": 1,
                        "goodCount":55,
                        "fairCount":0,
                        "onboardedCount": 55,
                        "idleCount": 0,
                        "totalCount": 56
                    }
                    tSimulatedTotal = getScoreCount(urlAction, scoreArray);
                }
                else if (urlAction.restPayload.filters.devType.indexOf('WIRELESS') >= 0) {
                    var frequencyIndex=-1;
                    for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                        if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                            frequencyIndex=k;
                        }
                    }
                    if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
                    || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                        if (frequency === "2.4") {
                            var fairCount=Math.round(0.09* tempWireless);
                            var goodCount=tempWireless-fairCount-poorCount1;  
                            var scoreArray = {
                                "poorCount": poorCount1,
                                "goodCount": goodCount,
                                "fairCount": fairCount,
                                "onboardedCount": tempWireless,
                                "idleCount": 0,
                                "totalCount": tempWireless
                            }
                            tSimulatedTotal = getScoreCount(urlAction, scoreArray)
                        }
                        else if (frequency === "5.0" || frequency === "5") {
                            var fairCount=Math.round(0.05* tempWireless);
                            var goodCount=tempWireless-fairCount-poorCount1-1;  
                            var scoreArray = {
                                "poorCount": poorCount1,
                                "goodCount": goodCount,
                                "fairCount": fairCount,
                                "onboardedCount": tempWireless-poorCount1,
                                "idleCount": 1,
                                "totalCount": tempWireless
                            }
                            tSimulatedTotal = getScoreCount(urlAction, scoreArray);
                        }
                    }
                    else {
                        var fairCount=Math.round(0.05* tempWireless);
                        var goodCount=tempWireless-fairCount-poorCount1-1;  
                        var scoreArray = {
                            "poorCount": poorCount1,
                            "goodCount": goodCount,
                            "fairCount": fairCount,
                            "onboardedCount": 233,
                            "idleCount": 1,
                            "totalCount": tempWireless
                        }
                        tSimulatedTotal = getScoreCount(urlAction, scoreArray);
                    }
                }
                else {
                    var frequencyIndex=-1;
                    for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                        if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                            frequencyIndex=k;
                        }
                    }
                    if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
                    || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                        if (frequency === "2.4") {
                            var fairCount=Math.round(0.09* tempWireless);
                            var goodCount=tempWireless-fairCount-poorCount1;  
                            var scoreArray = {
                                "poorCount": poorCount1,
                                "goodCount": goodCount,
                                "fairCount": fairCount,
                                "onboardedCount": 87,
                                "idleCount": 0,
                                "totalCount": tempWireless
                            }
                            tSimulatedTotal = getScoreCount(urlAction, scoreArray);

                        }
                        else if (frequency === "5.0" || frequency === "5") {
                            var fairCount=Math.round(0.05* tempWireless);
                            var goodCount=tempWireless-fairCount-poorCount1-1;  
                            var scoreArray = {
                                "poorCount": poorCount1,
                                "goodCount": goodCount,
                                "fairCount": fairCount,
                                "onboardedCount": 254,
                                "idleCount": 1,
                                "totalCount": tempWireless
                            }
                            tSimulatedTotal = getScoreCount(urlAction, scoreArray);
                        }
                    }
                    else {
                        var fairCount=Math.round(0.05* (tempWired+tempWireless));
                        var goodCount=tempWireless+tempWired-fairCount-poorCount1-1;  
                        var scoreArray = {
                            "poorCount": poorCount1,
                            "goodCount": goodCount,
                            "fairCount": fairCount,
                            "onboardedCount": 288,
                            "idleCount": 1,
                            "totalCount": tempWireless+tempWired
                        }
                        tSimulatedTotal = getScoreCount(urlAction, scoreArray);
                    }

                }

                if (urlAction.restPayload.filters.ssidList && urlAction.restPayload.filters.ssidList.length > 0) {
               //     tSimulatedTotal = calculateSsidCount(urlAction);
                }

                if (urlAction.restPayload.filters.kpi && urlAction.restPayload.filters.kpi.length > 0) {
                    var kpiAttrList=[];
                    for(var i = 0; i < urlAction.restPayload.filters.kpi.length; i++) {
                        kpiAttrList.push(urlAction.restPayload.filters.kpi[i].kpiAttr);
                    }   

                    if(kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT')>=0 || kpiAttrList.indexOf('FAILED_ROAMING_COUNT')>=0){
                        tSimulatedTotal = calculateRoamingCount(urlAction);
                    }

                    else{
                    for(var i = 0; i < urlAction.restPayload.filters.kpi.length; i++) {
                        var kpiAttr = urlAction.restPayload.filters.kpi[i].kpiAttr;
                       
                     if(kpiAttr==="LATEST_ONBOARDING_FAIL_CATEGORY"){
                        var kpiValueToCompare="",kpiOperation="";
                        if(urlAction.restPayload.filters.kpi[i].condition==undefined || urlAction.restPayload.filters.kpi[i].condition.length<=0){
                         kpiValueToCompare = "ASSOC,AAA,DHCP,OTHER";
                         kpiOperation = "within";
                      }
                       else if(urlAction.restPayload.filters.kpi[i].condition.length>0){
                      kpiValueToCompare =urlAction.restPayload.filters.kpi[i].condition[0].value[0];
                      kpiOperation = urlAction.restPayload.filters.kpi[i].condition[0].name;
                           }
                                  console.log(kpiOperation + kpiValueToCompare)
                                //  tSimulatedTotal = tObjs.length;
                                tSimulatedTotal = calculateKpiCount(urlAction, kpiAttr, kpiValueToCompare, kpiOperation);
                        }
                      else if(kpiAttr!="FREQUENCY" && kpiAttr!="SSID" && kpiAttr!="DOT11_PROTOCOL" && kpiAttr!="HEALTH_SCORE")  {
                         for (var j = 0; j < urlAction.restPayload.filters.kpi[i].condition.length; j++) {
                            if(urlAction.restPayload.filters.kpi[i].condition[j].kpiAttr!="FREQUENCY" && urlAction.restPayload.filters.kpi[i].condition[j].kpiAttr!="SSID" ){
                            if (!Number.isNaN(parseInt(urlAction.restPayload.filters.kpi[i].condition[j].value[0]))) {
                                var kpiValueToCompare = parseInt(urlAction.restPayload.filters.kpi[i].condition[j].value[0]);
                                var kpiOperation = urlAction.restPayload.filters.kpi[i].condition[j].name;
                                console.log(kpiOperation + kpiValueToCompare)
                                //  tSimulatedTotal = tObjs.length;
                                tSimulatedTotal = calculateKpiCount(urlAction, kpiAttr, kpiValueToCompare, kpiOperation);
                            }
                        }
                        }
                    }
                    }
                }
            

                    if (tSimulatedTotal == 0 || tSimulatedTotal == undefined) {
                        tSimulatedTotal = tObjs.length;
                    }
                    else if (tSimulatedTotal < tObjs.length) {
                        tSimulatedTotal = tObjs.length;
                    }
                }

            }
            else {
                tSimulatedTotal = tObjs.length;
            }

            //to get the client details in Physical Neighbor Topology -- this should not ideally overlap the above
            if (urlAction.restPayload.filters.kpi.length > 0 && urlAction.restPayload.filters.kpi[0].kpiAttr && (urlAction.restPayload.filters.kpi[0].kpiAttr == "CONNECTED_DEVICE_ID" ||
                urlAction.restPayload.filters.kpi[0].kpiAttr == "SLOT_ID")) {
                var freq = "5.0", connectedDevId = "";
                var apDevId = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': 'AP4800'}))[0].id;
               //this change is partial as connected device id is null so taking the id of AP4800
                for (var i = 0; i < urlAction.restPayload.filters.kpi.length; i++) {
                    var kpiAttr = urlAction.restPayload.filters.kpi[i].kpiAttr;
                    if (kpiAttr == "CONNECTED_DEVICE_ID") {
                        connectedDevId = (urlAction.restPayload.filters.kpi[i].condition[0].value[0])?urlAction.restPayload.filters.kpi[i].condition[0].value[0]:apDevId;
                    } else if (kpiAttr == "SLOT_ID" && urlAction.restPayload.filters.kpi[i].condition[0].value[0] == "0") {
                        freq = "2.4";
                    }
                }
                /*when we have only CONNECTED_DEVICE_ID from restpayload,
                and if that device has clients of freq 2.4 then tObjs = [], so adding this check*/
                if(urlAction.restPayload.filters.kpi[0].kpiAttr == "CONNECTED_DEVICE_ID" && urlAction.restPayload.filters.kpi[0].kpiAttr !== "SLOT_ID" ){
                    tObjs = SimLokiDatabaseActions.getFilteredRecordHandler('host',{ 'connectedNetworkDeviceId': connectedDevId });
                    tSimulatedTotal = tObjs.length;
                }else {
                    tObjs = SimLokiDatabaseActions.getFilteredRecordHandler('host',
                    { '$and': [{ 'connectedNetworkDeviceId': connectedDevId }, { 'frequency': freq }] });
                    tSimulatedTotal = tObjs.length;
                }

            }
            tObjs = JSON.parse(JSON.stringify(tObjs));
            tObjs.forEach(obj1 => {
                SimulationUtility.PROTOCOLS.forEach(obj2 => {
                    if(obj1.dot11Protocol == obj2.dnacVal) {
                        obj1.dot11Protocol = obj2.text;
                        return;
                    }
                });
            });
            return getHostResponse(tObjs, tSimulatedTotal);

        }


        function getScoreCount(urlAction, scoreArray) {
            var tSimulatedTotal=0
            if(urlAction.restPayload.filters.scoreType) {
                if (urlAction.restPayload.filters.scoreType.indexOf('POOR') >= 0) {
                    tSimulatedTotal = scoreArray.poorCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('GOOD') >= 0) {
                    tSimulatedTotal = scoreArray.goodCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('FAIR') >= 0) {
                    tSimulatedTotal = scoreArray.fairCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('Errors') >= 0) {
                    tSimulatedTotal = scoreArray.fairCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('ONBOARDED') >= 0) {
                    tSimulatedTotal = scoreArray.onboardedCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('IDLE') >= 0) {
                    tSimulatedTotal = scoreArray.idleCount;
                }
                else if (urlAction.restPayload.filters.scoreType.indexOf('NODATA') >= 0) {
                    tSimulatedTotal = scoreArray.idleCount;
                }
                else {
                    tSimulatedTotal = scoreArray.totalCount;
                }
            }            
            return tSimulatedTotal
        }

        function compareValues(dbOrginalValue, ValueToBeCompare, compareOperator) {
            var result = false;
            if (compareOperator == 'gte') {
                if (dbOrginalValue >= ValueToBeCompare) {
                    result = true;
                }
            } else if (compareOperator == 'lte' || compareOperator == 'within') {
                if (dbOrginalValue <= ValueToBeCompare) {
                    result = true;
                }
            }
            else if (compareOperator == 'lt') {
                if (dbOrginalValue < ValueToBeCompare) {
                    result = true;
                }
            }
            else if (compareOperator == 'gt') {
                if (dbOrginalValue > ValueToBeCompare) {
                    result = true;
                }
            }
            return result;
        }

        function calculateSsidCount(urlAction) {
            var wirelessObjList = SimLokiDatabaseActions.getFilteredRecordHandler("host", { 'hostType': 'WIRELESS' });
            var frequencyIndex=-1,ssidIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    frequencyIndex=k;
                }
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    ssidIndex=k;
                }
            }
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
            || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
             var frequency="" ;
             if(frequencyIndex>-1){
               frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
             }
             else{
                    frequency = urlAction.restPayload.filters.frequency;
                }
                if (frequency === "2.4") {
                    var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "2.4"); });
                    var temp1 = 34;
                }
                else if (frequency === "5.0") {
                    var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "5.0" || itm.frequency === "5"); });
                    var temp1 = 216;
                }
            }
            else {
                var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                var temp1 = 250;
            }
            var allSsids = [], ssidCount = [], totalsum = 0;

            for (var i = 0; i < wirelessObjs.length; i++) {
                allSsids.push(wirelessObjs[i].ssid);
            }
            var ssids = [...new Set(allSsids)];

            for (var j = 0; j < ssids.length; j++) {
                ssidCount[j] = 0;
            }
            var totalCount = 0;
            for (var i = 0; i < wirelessObjs.length; i++) {
                for (var j = 0; j < ssids.length; j++) {
                    if (wirelessObjs[i].ssid === ssids[j]) {
                        ssidCount[j] += 1;
                        totalCount += 1;
                    }
                }
            }
            temp1 = temp1 - parseInt(totalCount);

            var s = [], totalsum = 0, index = -1;
            for (var i = 0; i < ssids.length; i++) {
                if (ssids[i] === "c9800AP11AX") {
                    index = i;
                    continue;
                }
                ssidCount[i] += Math.round(temp1 * 0.06);
                totalsum += ssidCount[i];
            }

            if (index >= 0) {
                ssidCount[index] = temp1 - totalsum;
            }
            else {
                ssidCount[s.length - 1] = temp1 - totalsum;
            }




            var ssidTotalCount = 0;
            if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
              || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                var ssidList=[];
                if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                    ssidList=  urlAction.restPayload.filters.ssidList;
                }
                else if(ssidIndex>-1){
                    ssidList= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                } 
                for (var i = 0; i < ssidList.length; i++) {
                    for (var j = 0; j < ssids.length; j++) {
                        if (ssidList[i] === ssids[j]) {
                            ssidTotalCount += ssidCount[j];
                        }
                    }
                }
                return ssidTotalCount;
            }

        }

        function calculateRoamingCount(urlAction){
            var clients=getClientCountwithFilter(urlAction);
            var tempWireless=getTempWirelessCount(urlAction);
            var wirelessObjs=clients.wirelessObjs;
            var KPIAttrName="ROAMING";
            var detailList=[];
            var kpiAttrList=[];
            for(var i = 0; i < urlAction.restPayload.filters.kpi.length; i++) {
                kpiAttrList.push(urlAction.restPayload.filters.kpi[i].kpiAttr);
            } 
            wirelessObjs1=wirelessObjs;
            if(kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT')>=0){
                var index=kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT');
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="gt" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                    wirelessObjs=wirelessObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration)>0});
                }
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                    wirelessObjs=wirelessObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration)===0});
                }  
               }
            if(kpiAttrList.indexOf('FAILED_ROAMING_COUNT')>=0){
                var index=kpiAttrList.indexOf('FAILED_ROAMING_COUNT');
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="gt" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                    wirelessObjs=wirelessObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) === 0});
                } 
             }
            if(kpiAttrList.indexOf('ROAMING_SPEED')>=0){
                var index=kpiAttrList.indexOf('ROAMING_SPEED');
                for (var x = 0; x < 6; x++) {
                detailList[x] = 0;
                }
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="1"){
                    KPIAttrName="ROAMING_FAST";
                    wirelessObjs=wirelessObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) <= 150});
                    for (var i = 0; i < wirelessObjs.length; i++) {
                        if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) <= 10 && parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) > 0) {
                           detailList[0]+= 1;
                        }
                        else if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) <= 20 && parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) > 10) {
                            detailList[1]+= 1;
                        }
                        else if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) <= 50 && parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) > 20) {
                            detailList[2]+= 1;
                        }
                        else if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) < 100 && parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) > 50) {
                            detailList[3]+= 1;
                        }
                        else if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) < 150 && parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) > 100) {
                           detailList[4]+= 1;
                        }
                        else if (parseInt(wirelessObjs[i].onboarding.maxRoamingDuration) >= 150) {
                            detailList[5]+= 1;
                        }
                    }
                 }
                else if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="2"){
                    KPIAttrName="ROAMING_SLOW"
                    wirelessObjs=wirelessObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) >= 300});
                    for (var i = 0; i < wirelessObjs.length; i++) {
                    if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) <= 300 && parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 0) {
                        detailList[0]+= 1;
                    }
                    else if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) <= 500 && parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 300) {
                        detailList[1]+= 1;
                    }
                    else if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) <= 1000 && parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 500) {
                        detailList[2]+= 1;
                    }
                    else if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) <= 2000 && parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 1000) {
                        detailList[3]+= 1;
                    }
                    else if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) <= 3000 && parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 2000) {
                        detailList[4]+= 1;
                    }
                    else if (parseInt(parseInt(wirelessObjs[i].onboarding.maxRoamingDuration)) > 0) {
                        detailList[5]+= 1;
                    }
                }
                } 
               }              
              else if(kpiAttrList.indexOf('ROAMING_ROOTCAUSE')>=0){
                KPIAttrName="ROAMING_ROOTCAUSE";
                var index=kpiAttrList.indexOf('ROAMING_ROOTCAUSE');
                for (var x = 0; x < 2; x++) {
                    detailList[x] = 0;
                    }
                    var successWirelessObjs=wirelessObjs.filter(function(itm){return (itm.healthScore[0].score > 3 && parseInt(itm.onboarding.maxRoamingDuration) >0)});
                    var failureWirelessObjs=wirelessObjs.filter(function(itm){return (itm.healthScore[0].score <= 3 && parseInt(itm.onboarding.maxRoamingDuration) >0)});
                    detailList[0]=successWirelessObjs.length;
                    detailList[1]=failureWirelessObjs.length;
                }
                else{
                    for (var x = 0; x < 6; x++) {
                        detailList[x] = 0;
                        }
                        for (var i = 0; i < wirelessObjs1.length; i++) {
                     if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) <= 300 && parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) > 0) {
                        detailList[0]+= 1;
                    }
                    else if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) <= 500 && parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) > 300) {
                        detailList[1]+= 1;
                    }
                    else if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) <= 1000 && parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) > 500) {
                        detailList[2]+= 1;
                    }
                    else if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) <= 3000 && parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) > 1000) {
                        detailList[3]+= 1;
                    }
                    else if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) > 3000) {
                        detailList[4]+= 1;
                    }
                    else if (parseInt(wirelessObjs1[i].onboarding.maxRoamingDuration) === 0) {
                        detailList[5]+= 1;
                    }
                }
                }
                if(KPIAttrName==="ROAMING_ROOTCAUSE"){
                    var totalWirelessObjs = detailList[0] + detailList[1]; 
                    var temp1=tempWireless-totalWirelessObjs;
                    detailList[0]+=temp1;
                    if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                        return detailList[1];
                    }
                    if(urlAction.restPayload.filters.kpi[index].condition[0].name==="neq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                        return detailList[0];
                    } 
                  }
                else{
                    var temp1=tempWireless;
                   
                    if(KPIAttrName==="ROAMING_FAST"){
                        temp1=parseInt(temp1/3);
                    }
                    else if(KPIAttrName==="ROAMING_SLOW"){
                        temp1=temp1-parseInt(temp1/3);
                    }

                    tempWireless=temp1;
                    
                    var totalWirelessObjs = detailList[0]+ detailList[1]+ detailList[2] + detailList[3] + detailList[4] + detailList[5];
                    var s1 = (detailList[1] + Math.floor(temp1 * 0.04)) < 10 ? (detailList[1] + Math.floor(temp1 * 0.04)) : 10;
                    var s2 = (detailList[2] + Math.floor(temp1 * 0.02)) < 4 ? (detailList[2] + Math.floor(temp1 * 0.02)) : 4;
                    var s3 = (detailList[3] + Math.floor(temp1 * 0.02)) < 4 ? (detailList[3] + Math.floor(temp1 * 0.02)) : 4;
                    var s4 = (detailList[4] + Math.floor(temp1 * 0.03)) < 5 ? (detailList[4] + Math.floor(temp1 * 0.03)) : 5;
                    var s5 = detailList[5];
                    
                    s1=detailList[1]>s1?detailList[1]:s1;
                    s2=detailList[2]>s2?detailList[2]:s2;
                    s3=detailList[3]>s3?detailList[3]:s3;
                    s4=detailList[4]>s4?detailList[4]:s4;
                   
                    var s0 = tempWireless - (s1 + s2 + s3 + s4 + s5);
                   
                    detailList[0]=s0;
                    detailList[1]=s1;
                    detailList[2]=s2;
                    detailList[3]=s3;
                    detailList[4]=s4;
                    detailList[5]=s5;

                    if(kpiAttrList.indexOf('MAX_ROAMING_DURATION')>=0){
                var index=kpiAttrList.indexOf('MAX_ROAMING_DURATION');
                var compareValue1="";
                var index=kpiAttrList.indexOf('MAX_ROAMING_DURATION');
                var compareValue=urlAction.restPayload.filters.kpi[index].condition[0].name+urlAction.restPayload.filters.kpi[index].condition[0].value[0];
                if(urlAction.restPayload.filters.kpi[index].condition.length>1){
                compareValue1=urlAction.restPayload.filters.kpi[index].condition[1].name+urlAction.restPayload.filters.kpi[index].condition[1].value[0];
                }

                if(compareValue==="lte300" || compareValue==="lte10"){
                    return detailList[0];
                }
                else if((compareValue==="gte301" && compareValue1==="lte500") || 
                (compareValue==="gte11" && compareValue1==="lte20")){
                    return detailList[1];
                }
                else if((compareValue==="gte501" && compareValue1==="lte1000") || 
                (compareValue==="gte21" && compareValue1==="lte50")){
                    return detailList[2];
                }
                else if((compareValue==="gte1001" && compareValue1==="lte3000") || 
                (compareValue==="gte51" && compareValue1==="lte100") ||
                (compareValue==="gte1001" && compareValue1==="lte2000")){
                    return detailList[3];
                }
                else if((compareValue==="gt3000" && KPIAttrName==="ROAMING") || 
                (compareValue==="gte101" && compareValue1==="lte150") ||
                (compareValue==="gte2001" && compareValue1==="lte3000")){
                    return detailList[4];
                }
                else if((compareValue==="gt3000" && KPIAttrName==="ROAMING_SLOW") ||
                (compareValue==="gt150")){
                    return detailList[5];
                }
             }
                else if(kpiAttrList.indexOf('FAILED_ROAMING_COUNT')>=0){
                    var index=kpiAttrList.indexOf('FAILED_ROAMING_COUNT');
                    if(urlAction.restPayload.filters.kpi[index].condition[0].name==="gt" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                        return detailList[5];
                    } 
                 }

                }
             }




        function calculateKpiCount(urlAction, kpiAttr, kpiValueToCompare, kpiOperator) {
            var attrTable = [
                { kpiAttr: "RSSI", countVal: { "lte-72": 0.02, "lt-65": 0.03, "lt-55": 0.33, "lt-45": 0.37, "gte-45": 0.25 } },
                { kpiAttr: "SNR", countVal: { "lt10": 0.01, "lt15": 0.02, "lt20": 0.05, "lt40": 0.50, "gte40": 0.42 } },
                { kpiAttr: "AAA", countVal: { "lt1000": 0.98, "lt2000": 0.02, "lt3000": 0.01, "lt4000": 0.01, "lt5000": 0.04, "gte5000": 0.02 } },
                { kpiAttr: "DATARATE", countVal: { "lte29": 0.03, "lte150": 0.06, "lte300": 0.18, "lte500": 0.15, "lte1000": 0.15, "gte1001": 0.43 } },
                { kpiAttr: "DHCP", countVal: { "lt1000": 0.98, "lt2000": 0.02, "lt3000": 0.01, "lt4000": 0.01, "lt5000": 0.04, "gte5000": 0.02 } },
                { kpiAttr: "ASSOCIATION", countVal: { "lt1000": 0.98, "lt2000": 0.02, "lt3000": 0.01, "lt4000": 0.01, "lt5000": 0.04, "gte5000": 0.02 } },
                { kpiAttr: "ONBOARDING", countVal: { "lt2000": 0.98, "lt4000": 0.02, "lt6000": 0.01, "lt8000": 0.01, "lt10000": 0.04, "gte10000": 0.02 } },
             ];

            if(kpiOperator==="within" && kpiAttr==="LATEST_ONBOARDING_FAIL_CATEGORY" )
            {  
                var frequencyIndex=-1;
                for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                    if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                        frequencyIndex=k;
                    }
                }
                if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
                || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                 if(frequency==="2.4"){
                    if(kpiValueToCompare==="AAA"|| kpiValueToCompare==="AAA,DHCP" || kpiValueToCompare==="DHCP"|| kpiValueToCompare==="ASSOC" || kpiValueToCompare==="ASSOC,AAA,DHCP,OTHER"){
                        return 0;
                    }
                    else{
                        return 2;
                    }
                 }
                 if(frequency==="5.0"||frequency==="5"){
                    if(kpiValueToCompare==="AAA"){
                        return 2;
                    }
                    else if(kpiValueToCompare==="DHCP"){
                        return 1;
                    }
                    else if(kpiValueToCompare==="ASSOC"){
                        return 0;
                    }
                    else if(kpiValueToCompare==="AAA,DHCP"){
                        return 3;
                    }
                    else if(kpiValueToCompare==="ASSOC,AAA,DHCP,OTHER"){
                        return 3;
                    }
                    else{
                        return 13;
                    }
                 }
                }
              else{
               
                    if(kpiValueToCompare==="AAA"){
                        return 2;
                    }
                    else if(kpiValueToCompare==="DHCP"){
                        return 1;
                    }
                    else if(kpiValueToCompare==="AAA,DHCP"){
                        return 3;
                    }
                    else if(kpiValueToCompare==="ASSOC"){
                        return 0;
                    }
                    else if(kpiValueToCompare==="ASSOC,AAA,DHCP,OTHER"){
                        return 3;
                    }
                    else{
                        return 15;
                    }
                }
               
            

    }

            for (i = 0; i < attrTable.length; i++) {
                var valType = "";
                var index = 0;
                if (attrTable[i].kpiAttr == kpiAttr) {
                    var funcName = "get" + kpiAttr + "Count";
                    var actualCount = eval(funcName)(urlAction);
                    var valType = kpiOperator + kpiValueToCompare;
                    var j = 0;
                    for (key in attrTable[i].countVal) {
                        if (key === valType) {
                            index = j;
                        }
                        j = j + 1;
                    }
                    return (actualCount[index]);
                }
            }

        }


        /*  for(i = 0; i < attrTable.length; i++) {
              if(attrTable[i].kpiAttr == kpiAttr) {
                   var valType=kpiOperator+kpiValueToCompare;
                   if(urlAction.restPayload.filters.scoreType.indexOf('GOOD') >= 0) {
                      if(valType==="lte-72" || valType==="lte10" || valType==="gte5000" || valType==="gte10000")
                      return 0;
                  }
                  if(urlAction.restPayload.filters.scoreType.indexOf('POOR') >= 0) {
                      if(valType==="gte-45" || valType==="gte40" || valType==="lte1000" || valType==="lte2000")
                      return 0;
                  }
                  if(attrTable[i].countVal[valType])
                  return (attrTable[i].countVal[valType]);
                  else
                  return 1;
                }

              }*/

        function getAAACount(urlAction) {
            var detailList = getOnboardingCountList(urlAction, "AAA", "maxAuthDuration");
            return detailList;
        }

        function getDHCPCount(urlAction) {
            var detailList = getOnboardingCountList(urlAction, "DHCP", "maxDhcpDuration");
            return detailList;
        }

        function getASSOCIATIONCount(urlAction) {
            var detailList = getOnboardingCountList(urlAction, "ASSOCIATION", "maxAssocDuration");
            return detailList;
        }

        function getONBOARDINGCount(urlAction) {
            var detailList = getOnboardingCountList(urlAction, "ONBOARDING", "maxRunDuration");
            return detailList;
        }

        function getOnboardingCountList(urlAction, onboardingType, typeName) {
            var detailList = onboardingActualCount(urlAction, onboardingType, typeName);
            var totalWirelessObjs = detailList[0] + detailList[1] + detailList[2] + detailList[3] + detailList[4] + detailList[5] + detailList[6];
            var totalWiredObjs = detailList[7] + detailList[8] + detailList[9] + detailList[10] + detailList[11] + detailList[12] + detailList[13];
            var tempWireless=getTempWirelessCount(urlAction);
            var temp1=tempWireless;
            var s1 = (detailList[1] + Math.floor(temp1 * 0.04)) < 10 ? (detailList[1] + Math.floor(temp1 * 0.04)) : 10;
            var s2 = (detailList[2] + Math.floor(temp1 * 0.02)) < 4 ? (detailList[2] + Math.floor(temp1 * 0.02)) : 4;
            var s3 = (detailList[3] + Math.floor(temp1 * 0.02)) < 4 ? (detailList[3] + Math.floor(temp1 * 0.02)) : 4;
            var s4 = (detailList[4] + Math.floor(temp1 * 0.03)) < 5 ? (detailList[4] + Math.floor(temp1 * 0.03)) : 5;
            var s5 = (detailList[5] + Math.floor(temp1 * 0.03)) < 5 ? (detailList[5] + Math.floor(temp1 * 0.03)) : 5;
            var s6 = detailList[6];

            s1=detailList[1]>s1?detailList[1]:s1;
            s2=detailList[2]>s2?detailList[2]:s2;
            s3=detailList[3]>s3?detailList[3]:s3;
            s4=detailList[4]>s4?detailList[4]:s4;
            s5=detailList[5]>s5?detailList[5]:s5;
            s6=detailList[6]>s6?detailList[6]:s6;


            var s0 = temp1 - (s1 + s2 + s3 + s4 + s5 + s6);

            detailList[0] = s0;
            detailList[1] = s1;
            detailList[2] = s2;
            detailList[3] = s3;
            detailList[4] = s4;
            detailList[5] = s5;
            detailList[6] = s6;

            var temp1 = 56;

            var s8 = (detailList[8] + Math.floor(temp1 * 0.04)) < 3 ? (detailList[8] + Math.floor(temp1 * 0.04)) : 3;
            var s9 = (detailList[9] + Math.floor(temp1 * 0.02)) < 1 ? (detailList[9] + Math.floor(temp1 * 0.02)) : 1;
            var s10 = (detailList[10] + Math.floor(temp1 * 0.02)) < 1 ? (detailList[10] + Math.floor(temp1 * 0.02)) : 1;
            var s11 = (detailList[11] + Math.floor(temp1 * 0.02)) < 4 ? (detailList[11] + Math.floor(temp1 * 0.02)) : 4;
            var s12 = (detailList[12] + Math.floor(temp1 * 0.02)) < 3 ? (detailList[12] + Math.floor(temp1 * 0.02)) : 3;
            var s13 = detailList[13] ;

            s8=detailList[8]>s8?detailList[8]:s8;
            s9=detailList[9]>s9?detailList[9]:s9;
            s10=detailList[10]>s10?detailList[10]:s10;
            s11=detailList[11]>s11?detailList[11]:s11;
            s12=detailList[12]>s12?detailList[12]:s12;
            s13=detailList[13]>s13?detailList[13]:s13;

            var frequencyIndex=-1; var ssidIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    frequencyIndex=k;
                }
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    ssidIndex=k;
                }
            }

            var s7 = temp1 - (s8 + s9 + s10 + s11 + s12+ s13);
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
            || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined) 
            || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)
            || (urlAction.restPayload.filters.ssidList && urlAction.restPayload.filters.ssidList.length > 0)) {
                s7=s8=s9=s10=s11=s12=0
            }

            detailList[7] = s7;
            detailList[8] = s8;
            detailList[9] = s9;
            detailList[10] = s10;
            detailList[11] = s11;
            detailList[12] = s12;
            detailList[13] = s13;
            var detailList1=[];
            if (urlAction.restPayload.filters.devType.indexOf('WIRED') >= 0) {
                detailList1 = detailList.slice(7)
            }
            else if (urlAction.restPayload.filters.devType.indexOf('WIRELESS') >= 0) {
                detailList1 = detailList.slice(0, 7)
            }
            else {
                detailList1 = [];
                detailList1[0] = detailList[0] + detailList[7];
                detailList1[1] = detailList[1] + detailList[8];
                detailList1[2] = detailList[2] + detailList[9];
                detailList1[3] = detailList[3] + detailList[10];
                detailList1[4] = detailList[4] + detailList[11];
                detailList1[5] = detailList[5] + detailList[12];
                detailList1[6] = detailList[6] + detailList[13];
            }

            return detailList1;
        }

        function getClientCountwithFilter(urlAction){
            var wirelessObjList = SimLokiDatabaseActions.getFilteredRecordHandler("host", { 'hostType': 'WIRELESS' });
            var wiredObjList = SimLokiDatabaseActions.getFilteredRecordHandler("host", { 'hostType': 'WIRED' });
            var frequencyIndex=-1; var ssidIndex=-1;
            for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                    frequencyIndex=k;
                }
                if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    ssidIndex=k;
                }
            }
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
            || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        }
                if (frequency === "2.4") {
                    var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "2.4"); });
                    var wiredObjs = wiredObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                }
                else if (frequency === "5.0" || frequency === "5") {
                    var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "5.0" || itm.frequency === "5"); });
                    var wiredObjs = wiredObjList.filter(function (itm) { return (itm.healthScore[0].score > 0) });
                }
                if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
              || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                  var ssids=[];
                    if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                        ssids=  urlAction.restPayload.filters.ssidList;
                    }
                    else if(ssidIndex>-1){
                        ssids= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                    } 
                    var tempList=[];
                    if(ssids.length>0){
                    for(var i=0;i<ssids.length;i++){
                        for(var j=0;j<wirelessObjs.length;j++){
                            if(wirelessObjs[j].ssid===ssids[i]){
                                tempList.push(wirelessObjs[j]);
                            }
                        }
                    }
                    wirelessObjs=tempList;
                }
                 }
            }
            else {
                var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });
                var wiredObjs = wiredObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });

                if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
                || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                    var ssids=[];
                    if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                        ssids=  urlAction.restPayload.filters.ssidList;
                    }
                    else if(ssidIndex>-1){
                        ssids= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                    }
                    var tempList=[];
                    if(ssids.length>0){
                    for(var i=0;i<ssids.length;i++){
                        for(var j=0;j<wirelessObjs.length;j++){
                            if(wirelessObjs[j].ssid===ssids[i]){
                                tempList.push(wirelessObjs[j]);
                            }
                        }
                    }
                    wirelessObjs=tempList;
                }
                 }
            }

                var wiredObjs = wiredObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });
            var clients={wirelessObjs:[],wiredObjs:[]};
            clients.wirelessObjs=wirelessObjs;
            clients.wiredObjs=wiredObjs;
            return clients;

        }



        function onboardingActualCount(urlAction, onboardingType, typeName) {
           
            var clients=getClientCountwithFilter(urlAction);
            var wirelessObjs=clients.wirelessObjs;
            var wiredObjs=clients.wiredObjs;

            var detailList = [];
            for (var x = 0; x <= 13; x++) {
                detailList[x] = 0;
            }

            for (var i = 0; i < wirelessObjs.length; i++) {
                if (onboardingType === "ONBOARDING") {
                    if (wirelessObjs[i].onboarding[typeName] < 2000 && wirelessObjs[i].onboarding[typeName] > 0) {
                        detailList[0] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 4000 && wirelessObjs[i].onboarding[typeName] >= 2000) {
                        detailList[1] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 6000 && wirelessObjs[i].onboarding[typeName] >= 4000) {
                        detailList[2] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 8000 && wirelessObjs[i].onboarding[typeName] >= 6000) {
                        detailList[3] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 10000 && wirelessObjs[i].onboarding[typeName] >= 8000) {
                        detailList[4] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] >= 10000) {
                        detailList[5] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] === "0") {
                        detailList[6] += 1;
                    }
                }
                else {
                    if (wirelessObjs[i].onboarding[typeName] < 1000 && wirelessObjs[i].onboarding[typeName] > 0) {
                        detailList[0] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 2000 && wirelessObjs[i].onboarding[typeName] >= 1000) {
                        detailList[1] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 3000 && wirelessObjs[i].onboarding[typeName] >= 2000) {
                        detailList[2] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 4000 && wirelessObjs[i].onboarding[typeName] >= 3000) {
                        detailList[3] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] < 5000 && wirelessObjs[i].onboarding[typeName] >= 4000) {
                        detailList[4] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] >= 5000 ) {
                        detailList[5] += 1;
                    }
                    else if (wirelessObjs[i].onboarding[typeName] === "0") {
                        detailList[6] += 1;
                    }
                }
            }
            for (var i = 0; i < wiredObjs.length; i++) {
                if (onboardingType === "ONBOARDING") {
                    if (wiredObjs[i].onboarding[typeName] < 2000 && wiredObjs[i].onboarding[typeName] > 0) {
                        detailList[7] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 4000 && wiredObjs[i].onboarding[typeName] >= 2000) {
                        detailList[8] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 6000 && wiredObjs[i].onboarding[typeName] >= 4000) {
                        detailList[9] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 8000 && wiredObjs[i].onboarding[typeName] >= 6000) {
                        detailList[10] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 10000 && wiredObjs[i].onboarding[typeName] >= 8000) {
                        detailList[11] += 1;
                    }
                    if (wiredObjs[i].onboarding[typeName] >= 10000 ) {
                        detailList[12] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] === "0") {
                        detailList[13] += 1;
                    }
                }
                else {
                    if (wiredObjs[i].onboarding[typeName] < 1000 && wiredObjs[i].onboarding[typeName] > 0) {
                        detailList[7] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 2000 && wiredObjs[i].onboarding[typeName] >= 1000) {
                        detailList[8] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 3000 && wiredObjs[i].onboarding[typeName] >= 2000) {
                        detailList[9] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 4000 && wiredObjs[i].onboarding[typeName] >= 3000) {
                        detailList[10] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] < 5000 && wiredObjs[i].onboarding[typeName] >= 4000) {
                        detailList[11] += 1;
                    }
                    if (wiredObjs[i].onboarding[typeName] >= 5000 ) {
                        detailList[12] += 1;
                    }
                    else if (wiredObjs[i].onboarding[typeName] === "0") {
                        detailList[13] += 1;
                    }
                }
            }

            return detailList;
        }

        function getDATARATECount(urlAction){
            var wirelessObjs=getWirelessObjsAfterFilter(urlAction);

            var detailList = [];
            for (var x = 0; x < 6; x++) {
                detailList[x] = 0;
            }
            for (var i = 0; i < wirelessObjs.length; i++) {
                if (wirelessObjs[i].dataRate <= 29 && wirelessObjs[i].dataRate > 0) {
                    detailList[0] += 1;
                }
                else if (wirelessObjs[i].dataRate <= 150 && wirelessObjs[i].dataRate >= 30) {
                    detailList[1] += 1;
                }
                else if (wirelessObjs[i].dataRate <= 300 && wirelessObjs[i].dataRate >= 151) {
                    detailList[2] += 1;
                }
                else if (wirelessObjs[i].dataRate <= 500 && wirelessObjs[i].dataRate >= 301) {
                    detailList[3] += 1;
                }
                else if (wirelessObjs[i].dataRate <= 1000 && wirelessObjs[i].dataRate >= 501) {
                    detailList[4] += 1;
                }
                else if (wirelessObjs[i].dataRate >= 1001) {
                    detailList[5] += 1;
                }
            }
            var totalDataRateObjs = detailList[0] + detailList[1] + detailList[2] + detailList[3] + detailList[4]+detailList[5];
            var tempWireless=getTempWirelessCount(urlAction);
            var temp1 = tempWireless - totalDataRateObjs;
            var s0 = parseInt(temp1 * 0.03);
            var s1 = parseInt(temp1 * 0.06);
            var s2 = parseInt(temp1 * 0.18);
            var s3 = parseInt(temp1 * 0.15);
            var s4 = parseInt(temp1 * 0.15);
            s5 = temp1 - (s1 + s2 + s3 + s4 + s0);
            detailList[0] += s0;
            detailList[1] += s1;
            detailList[2] += s2;
            detailList[3] += s3;
            detailList[4] += s4;
            detailList[5] += s5;

            return detailList;

        }


        function getRSSICount(urlAction) {
            var wirelessObjs=getWirelessObjsAfterFilter(urlAction);

            var detailList = [];
            for (var x = 0; x < 5; x++) {
                detailList[x] = 0;
            }
            for (var i = 0; i < wirelessObjs.length; i++) {
                if (wirelessObjs[i].avgRssi <= -72) {
                    detailList[0] += 1;
                }
                else if (wirelessObjs[i].avgRssi <= -65 && wirelessObjs[i].avgRssi >= -71) {
                    detailList[1] += 1;
                }
                else if (wirelessObjs[i].avgRssi <= -55 && wirelessObjs[i].avgRssi >= -65) {
                    detailList[2] += 1;
                }
                else if (wirelessObjs[i].avgRssi <= -45 && wirelessObjs[i].avgRssi >= -55) {
                    detailList[3] += 1;
                }
                else if (wirelessObjs[i].avgRssi >= -45) {
                    detailList[4] += 1;
                }
            }
            var totalRssiObjs = detailList[0] + detailList[1] + detailList[2] + detailList[3] + detailList[4];

            var tempWireless=getTempWirelessCount(urlAction);
	        var temp1 = tempWireless - totalRssiObjs;
            var s0 = parseInt(temp1 * 0.02);
            var s1 = parseInt(temp1 * 0.03);
            var s2 = parseInt(temp1 * 0.33);
            var s3 = parseInt(temp1 * 0.37);
            var s4 = temp1 - (s0 + s1 + s2 + s3);
            detailList[0] += s0;
            detailList[1] += s1;
            detailList[2] += s2;
            detailList[3] += s3;
            detailList[4] += s4;

            return detailList;
        }

        function getWirelessObjsAfterFilter(urlAction){
            var wirelessObjList = SimLokiDatabaseActions.getFilteredRecordHandler("host", { 'hostType': 'WIRELESS' });
            var wirelessObjs=[];
            var frequencyIndex=-1; var ssidIndex=-1;
              for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                  if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                      frequencyIndex=k;
                  }
                  if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    ssidIndex=k;
                  }
              }
            if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
            || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
             var frequency="" ;
             if(frequencyIndex>-1){
               frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
             }
             else{
                    frequency = urlAction.restPayload.filters.frequency;
                }
                if (frequency === "2.4") {
                    wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "2.4"); });
                }
                else if (frequency === "5.0") {
                    wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0 && itm.frequency === "5.0" || itm.frequency === "5"); });
                }
                if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
              || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                    var ssids=[];
                    if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                        ssids=  urlAction.restPayload.filters.ssidList;
                    }
                    else if(ssidIndex>-1){
                        ssids= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                    }
                    var tempList=[];
                    if(ssids.length>0){
                    for(var i=0;i<ssids.length;i++){
                        for(var j=0;j<wirelessObjs.length;j++){
                            if(wirelessObjs[j].ssid===ssids[i]){
                                tempList.push(wirelessObjs[j]);
                            }
                        }
                    }
                    wirelessObjs=tempList;
                }
                 }
                }
         else {
            var wirelessObjs = wirelessObjList.filter(function (itm) { return (itm.healthScore[0].score > 0); });

            if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
              || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                var ssids=[];
                if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                    ssids=  urlAction.restPayload.filters.ssidList;
                }
                else if(ssidIndex>-1){
                    ssids= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                }
                var tempList=[];
                if(ssids.length>0){
                for(var i=0;i<ssids.length;i++){
                    for(var j=0;j<wirelessObjs.length;j++){
                        if(wirelessObjs[j].ssid===ssids[i]){
                            tempList.push(wirelessObjs[j]);
                        }
                    }
                }
                wirelessObjs=tempList;
            }
            }
            }
            return wirelessObjs;
        }

        function getTempWirelessCount(urlAction){
              //=================================
              var hostsList = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostType': "WIRELESS" });;
              var tempWireless=250; var allSsids=[];
              for (var i = 0; i < hostsList.length; i++) {
                  if(hostsList[i].ssid!=undefined || hostsList[i].ssid!=null)
                  allSsids.push(hostsList[i].ssid);
              }

              var ssids = [...new Set(allSsids)];
              var hostsList = hostsList.filter(function (itm) { return (itm.healthScore[0].score > 0); });
              var frequencyIndex=-1; var ssidIndex=-1;
              for(var k=0;k<urlAction.restPayload.filters.kpi.length;k++){
                  if(urlAction.restPayload.filters.kpi[k].kpiAttr==="FREQUENCY"){
                      frequencyIndex=k;
                  }
                  if(urlAction.restPayload.filters.kpi[k].kpiAttr==="SSID"){
                    ssidIndex=k;
                  }
              }
              if ((urlAction.restPayload.filters != undefined && urlAction.restPayload.filters.frequency != undefined && urlAction.restPayload.filters.frequency != "")
              || (frequencyIndex>-1 && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != "" && urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0] != undefined)) {
                     var frequency="" ;
                     if(frequencyIndex>-1){
                       frequency =urlAction.restPayload.filters.kpi[frequencyIndex].condition[0].value[0];
                     }
                     else{
                            frequency = urlAction.restPayload.filters.frequency;
                        };
                  if(frequency==="2.4"){
                      hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                     tempWireless=34;
                  }
                  else if(frequency==="5.0"){
                      hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                      tempWireless=216;
                  }
              }
              var s = [], totalsum = 0, index = -1, count=0;
              if((urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssidList!=undefined && urlAction.restPayload.filters.ssidList.length >0)
              || (ssidIndex>-1 && urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value.length> 0)) {
                 for(var k=0;k<ssids.length;k++){s[k]=0};
                  for(var j=0;j<hostsList.length;j++){
                      for(var k=0;k<ssids.length;k++){
                          if(hostsList[j].ssid===ssids[k]){
                              s[k]++;
                          }
                      }
                  }
                }

                  for (var i = 0; i < ssids.length; i++) {
                      if (ssids[i] === "c9800AP11AX") {
                          index = i;
                          continue;
                      }
                      else{
                      s[i] += Math.round(tempWireless * 0.06);
                      totalsum += s[i];
                      }


                  }
                  if (index >= 0) {
                      s[index] = tempWireless - totalsum;
                  }
                  else {
                      s[s.length - 1] = tempWireless - totalsum;
                  }
                  var ssidList1=[];
                  if(urlAction.restPayload.filters.ssidList !=undefined && urlAction.restPayload.filters.ssidList.length>0){
                   ssidList1=  urlAction.restPayload.filters.ssidList;
                  }
                  else if(ssidIndex>-1){
                    ssidList1= urlAction.restPayload.filters.kpi[ssidIndex].condition[0].value;
                  } 
                  if(ssidList1.length>0){
                  for(var k=0;k<ssidList1.length;k++){
                  for(var z=0;z<ssids.length;z++){
                      if(ssidList1[k]===ssids[z]){
                          count=count+s[z];
                      }
                  }
                  }
                  tempWireless=count;
                }

               

               return tempWireless;
        }

        function getSNRCount(urlAction) {
            var wirelessObjs = getWirelessObjsAfterFilter(urlAction);

            var detailList = [];
            for (var x = 0; x < 5; x++) {
                detailList[x] = 0;
            }
            for (var i = 0; i < wirelessObjs.length; i++) {
                if (wirelessObjs[i].snr <= 10) {
                    detailList[0] += 1;
                }
                else if (wirelessObjs[i].snr <= 15 && wirelessObjs[i].snr >= 10) {
                    detailList[1] += 1;
                }
                else if (wirelessObjs[i].snr <= 20 && wirelessObjs[i].snr >= 15) {
                    detailList[2] += 1;
                }
                else if (wirelessObjs[i].snr <= 40 && wirelessObjs[i].snr >= 20) {
                    detailList[3] += 1;
                }
                else if (wirelessObjs[i].snr >= 40) {
                    detailList[4] += 1;
                }
            }
            var totalSnrObjs = detailList[0] + detailList[1] + detailList[2] + detailList[3] + detailList[4];

            var tempWireless=getTempWirelessCount(urlAction);

            var temp1 = tempWireless - totalSnrObjs;

            var s0 = parseInt(temp1 * 0.01)
            var s1 = parseInt(temp1 * 0.02);
            var s2 = parseInt(temp1 * 0.05);
            var s3 = parseInt(temp1 * 0.50);
            var s4 = temp1 - (s0 + s1 + s2 + s3);
            detailList[0] += s0;
            detailList[1] += s1;
            detailList[2] += s2;
            detailList[3] += s3;
            detailList[4] += s4;

            return detailList;

        }

        function getFilterBasedOnRoamingKpi(tObjs,urlAction,kpiAttrList){
            if(kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT')>=0){
                var index=kpiAttrList.indexOf('SUCCESSFUL_ROAMING_COUNT');
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="gt" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration)>0});
                }
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="0"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration)===0});
                }  
               }
               if(kpiAttrList.indexOf('ROAMING_SPEED')>=0){
                var index=kpiAttrList.indexOf('ROAMING_SPEED');
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="1"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) <= 150});
                } 
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq" && urlAction.restPayload.filters.kpi[index].condition[0].value[0]==="2"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) >= 300});
                } 
               }
               if(kpiAttrList.indexOf('MAX_ROAMING_DURATION')>=0){
                var index=kpiAttrList.indexOf('MAX_ROAMING_DURATION');
                var compareValue=[];
                compareValue[0]=parseInt(urlAction.restPayload.filters.kpi[index].condition[0].value[0]);
                if(urlAction.restPayload.filters.kpi[index].condition.length>1){
                compareValue[1]=parseInt(urlAction.restPayload.filters.kpi[index].condition[1].value[0]);
                }
                for(var k=0;k<compareValue.length;k++){
                if(urlAction.restPayload.filters.kpi[index].condition[k].name==="lt"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) < compareValue[k]});
                }
                else if(urlAction.restPayload.filters.kpi[index].condition[k].name==="lte"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) <= compareValue[k]});
                }
                else if(urlAction.restPayload.filters.kpi[index].condition[k].name==="gt"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) > compareValue[k]});
                }
                else if(urlAction.restPayload.filters.kpi[index].condition[k].name==="gte"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) >= compareValue[k]});
                }
                else if(urlAction.restPayload.filters.kpi[index].condition[k].name==="eq"){
                    tObjs=tObjs.filter(function(itm){return parseInt(itm.onboarding.maxRoamingDuration) === compareValue[k]});
                }
               }
             }
               if(kpiAttrList.indexOf('ROAMING_ROOTCAUSE')>=0){
                var index=kpiAttrList.indexOf('ROAMING_ROOTCAUSE');
                if(urlAction.restPayload.filters.kpi[index].condition[0].name==="eq"){
                    tObjs=tObjs.filter(function(itm){return (itm.healthScore[0].score > 3 && parseInt(itm.onboarding.maxRoamingDuration) >0)});
                }
                else if(urlAction.restPayload.filters.kpi[index].condition[0].name==="neq"){
                    tObjs=tObjs.filter(function(itm){return (itm.healthScore[0].score <= 3 && parseInt(itm.onboarding.maxRoamingDuration) >0)});
                }
            }
             return  tObjs;
        }

        function compareMinMax(val,min,max){
            var result=false;
            if((min!=undefined) && (max!= undefined)){
             result=(val<=max && val>=min)?true:false;
            }
            else if(min!=undefined){
                result=(val>=min)?true:false;
            }
            else if(max!= undefined){
                result=(val<=max)?true:false;
            }
            return result;
        }

        function getFilterBasedOnKpiRange(tObjs,kpiName,compareMinValue,compareMaxValue){
            objs=[];
            for(var k=0;k<tObjs.length;k++){
                if(kpiName.indexOf("healthScore")>=0){
                    var fieldName=kpiName.split('.')[1];
                    if(fieldName==="overall"){
                    if(compareMinMax(parseInt(tObjs[k].healthScore[0].score),compareMinValue,compareMaxValue)){    
                    //if(tObjs[k].healthScore[0].score<=compareMaxValue && tObjs[k].healthScore[0].score>=compareMinValue){
                        objs.push(tObjs[k]) 
                    }
                    }
                    else if(fieldName==="onboarding"){
                        if(compareMinMax(parseInt(tObjs[k].healthScore[1].score),compareMinValue,compareMaxValue)){    
                    //    if(tObjs[k].healthScore[1].score<=compareMaxValue && tObjs[k].healthScore[1].score>=compareMinValue){
                            objs.push(tObjs[k]) 
                        }
                    }
                    else if(fieldName==="connected"){
                        if(compareMinMax(parseInt(tObjs[k].healthScore[2].score),compareMinValue,compareMaxValue)){ 
                      //  if(tObjs[k].healthScore[2].score<=compareMaxValue && tObjs[k].healthScore[2].score>=compareMinValue){
                            objs.push(tObjs[k]) 
                        }
                    }
              }
                else if(kpiName.indexOf('onboarding')>=0){
                    var fieldName=kpiName.split('.')[1];
                    if(compareMinMax(parseInt(tObjs[k].onboarding[fieldName]),compareMinValue,compareMaxValue)){ 
                    //if(tObjs[k].onboarding[fieldName]<=compareMaxValue && tObjs[k].onboarding[fieldName]>=compareMinValue){
                        objs.push(tObjs[k]) 
                    }
                }
                else{
                    if(compareMinMax(parseInt(tObjs[k][kpiName]),compareMinValue,compareMaxValue)){ 
                   // if(parseInt(tObjs[k][kpiName])<=compareMaxValue && parseInt(tObjs[k][kpiName])>=compareMinValue){
                        objs.push(tObjs[k]); 
                    }
                 }
            }
            return objs;   
        }

        function getFilterBasedOnKpiDate(tObjs,kpiName,compareStartDate,compareEndDate){
            objs=[];
            for(var k=0;k<tObjs.length;k++){
                if(kpiName.indexOf("onboarding")>=0){
                    var fieldName=kpiName.split('.')[1];
                    if(fieldName==="assocDoneTime"){
                        if(tObjs[k].onboarding.assocDoneTime>=compareStartDate && tObjs[k].onboarding.assocDoneTime<=compareEndDate){
                            objs.push(tObjs[k]) 
                        }
                        }
                    else if(fieldName==="authDoneTime"){
                        if(tObjs[k].onboarding.authDoneTime>=compareStartDate && tObjs[k].onboarding.authDoneTime<=compareEndDate){
                            objs.push(tObjs[k]) 
                        }
                    }
                    else if(fieldName==="dhcpDoneTime"){
                        if(tObjs[k].onboarding.dhcpDoneTime>=compareStartDate && tObjs[k].onboarding.dhcpDoneTime<=compareEndDate){
                            objs.push(tObjs[k]) 
                        }
                    }
                }
                else{
                    if(tObjs[k][kpiName]>=compareStartDate && tObjs[k][kpiName]<=compareEndDate){
                        objs.push(tObjs[k]); 
                    }
            }
        }
            return objs;  
        }

        function getFilterBasedOnKpiString(tObjs,kpiName,compareAttr,compareArr){
            var objs=[]
            if(compareArr.length>0){
            for(var k=0;k<tObjs.length;k++){
                if(compareAttr==="within"){
                  for(var j=0;j<compareArr.length;j++){  
                  if(tObjs[k][kpiName]===compareArr[j]){
                    objs.push(tObjs[k])
                    break;
                  }
                  if(kpiName==="hostIpV6"){
                    if(tObjs[k][kpiName][0]===compareArr[j]){
                        objs.push(tObjs[k])
                        break;
                      }  
                  }
                }
             }
             if(compareAttr==="like"){
                for(var j=0;j<compareArr.length;j++){
                    compareArr[j]=compareArr[j].replace(".*","").replace(".*","");
                    if(kpiName==="port" && compareArr[j].indexOf("GigabitEthernet")===-1 && compareArr[j].indexOf("Gi")>-1){
                        compareArr[j]=compareArr[j].replace("Gi","GigabitEthernet") 
                    } 
                    if(tObjs[k][kpiName].indexOf(compareArr[j])>-1){
                        objs.push(tObjs[k])
                        break;
                    } 
                }     
             }
        }
        return objs;
    }
    else{
        return tObjs;
    }
        
    }



        function getFilterBasedOnKpi(tObjs, kpiAttr, kpiValueToCompare, kpiOperator) {
            var attrTable = [
                { kpiAttr: "RSSI", attrInDB: ["avgRssi"] },
                { kpiAttr: "SNR", attrInDB: ["snr"] },
                { kpiAttr: "AAA", attrInDB: ["onboarding", "maxAuthDuration"] },
                { kpiAttr: "DHCP", attrInDB: ["onboarding", "maxDhcpDuration"] },
                { kpiAttr: "ASSOCIATION", attrInDB: ["onboarding", "maxAssocDuration"] },
                { kpiAttr: "ONBOARDING", attrInDB: ["onboarding", "maxRunDuration"] },
                { kpiAttr: "FREQUENCY", attrInDB: ["frequency"] }
            ];
            var field1 = undefined, field2 = field2 = undefined, i;
            for (var i = 0; i < attrTable.length; i++) {
                if (attrTable[i].kpiAttr == kpiAttr) {
                    field1 = attrTable[i].attrInDB[0];
                    if (attrTable[i].attrInDB.length == 2) {
                        field2 = attrTable[i].attrInDB[1];
                    }
                    break;
                }
            }

            var resultObjs = [];

            if(kpiOperator==="within" && kpiAttr==="LATEST_ONBOARDING_FAIL_CATEGORY"){
            if( kpiValueToCompare==="AAA"){
                for (var i = 0; i < tObjs.length; i++) {
                    if(tObjs[i].onboarding.maxAuthDuration== 0){
                        resultObjs.push(tObjs[i])
                    }

                }
            }
            else if( kpiValueToCompare==="AAA,DHCP"){
                for (var i = 0; i < tObjs.length; i++) {
                    if(tObjs[i].onboarding.maxAuthDuration== 0 || tObjs[i].onboarding.maxDhcpDuration== 0){
                        resultObjs.push(tObjs[i])
                    }

                }
            }
            else if( kpiValueToCompare==="DHCP"){
                for (var i = 0; i < tObjs.length; i++) {
                    if(tObjs[i].onboarding.maxDhcpDuration== 0){
                        resultObjs.push(tObjs[i])
                    }

                }
            }
            else if( kpiValueToCompare==="ASSOC"){
                for (var i = 0; i < tObjs.length; i++) {
                    if(tObjs[i].onboarding.maxAssocDuration == 0){
                        resultObjs.push(tObjs[i])
                    }

                }
            }
            else   if( kpiValueToCompare==="ASSOC,AAA,DHCP,OTHER"){
                for (var i = 0; i < tObjs.length; i++) {
                    if(tObjs[i].onboarding.maxRunDuration== 0){
                        resultObjs.push(tObjs[i])
                    }

                }
            }
            else{
                resultObjs=tObjs;
            }
              return resultObjs;
            }


            for (var i = 0; i < tObjs.length; i++) {
                var dbOriginalValue = 0;
                if (field2 != undefined) {
                    dbOriginalValue = tObjs[i][field1][field2];
                } else {
                    dbOriginalValue = tObjs[i][field1];

                }
                dbOriginalValue = dbOriginalValue != undefined ? parseFloat(dbOriginalValue) : 0;
                var comparewith = kpiValueToCompare;

                 if (compareValues(dbOriginalValue, comparewith, kpiOperator)) {
                    resultObjs.push(tObjs[i]);
                }
            }

            return resultObjs;
        }

    });
