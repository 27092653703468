define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/HealthScoreSettingsTemplate',

    ],function(UtilityFunctions,SimLokiDatabaseActions, DefaultConfig, HealthScoreSettingsTemplate) {

    
    return {
        init: function() {
            createRouterHealthScoreSettingData();
            createSwitchHealthScoreSettingData();
            createWLCHealthScoreSettingData();
            createUnifiedAPHealthScoreSettingData();
        }
    };
    // added by Karan for HealthScore Threshold Setting for 1.3.3 in Assurance --> Manage--> Health Score Settings Page
    function createRouterHealthScoreSettingData() {
        var RouterTemp = DefaultConfig.HealthScoreSetting.Router_HealthScoreSetting
        for(var i=0; i<RouterTemp.length;i++) {
            var tJson=JSON.parse(JSON.stringify(HealthScoreSettingsTemplate.RouterKPIList_TEMPLATE[0]));
            tJson.kpi = RouterTemp[i].kpi;
            tJson.displayKey = RouterTemp[i].displayKey;
            tJson.innerData.poorValue = RouterTemp[i].poorValue;
            tJson.innerData.poorDefaultValue = RouterTemp[i].poorDefaultValue;
            tJson.innerData.poorMinValue = RouterTemp[i].poorMinValue;
            tJson.innerData.poorMaxValue = RouterTemp[i].poorMaxValue;
            tJson.innerData.goodValue = RouterTemp[i].goodValue;
            tJson.innerData.goodDefaultValue = RouterTemp[i].goodDefaultValue;
            tJson.innerData.goodMinValue = RouterTemp[i].goodMinValue;
            tJson.innerData.goodMaxValue = RouterTemp[i].goodMaxValue;
            tJson.innerData.goodValueString = RouterTemp[i].goodValueString;
            tJson.innerData.poorValueString = RouterTemp[i].poorValueString;
            tJson.innerData.fairValueString = RouterTemp[i].fairValueString;
            SimLokiDatabaseActions.insert('health-threshold-setting', tJson)
        }
    };
    function createSwitchHealthScoreSettingData() {
        var wlcTemp = DefaultConfig.HealthScoreSetting.WLC_HealthScoreSetting
        for(var i=0; i<wlcTemp.length;i++) {
            var tJson=JSON.parse(JSON.stringify(HealthScoreSettingsTemplate.RouterKPIList_TEMPLATE[0]));
            tJson.deviceType = "Wireless Controller";
            tJson.deviceRole = "Wireless Controller";
            tJson.kpi = wlcTemp[i].kpi;
            tJson.displayKey = wlcTemp[i].displayKey;
            tJson.innerData.poorValue = wlcTemp[i].poorValue;
            tJson.innerData.poorDefaultValue = wlcTemp[i].poorDefaultValue;
            tJson.innerData.poorMinValue = wlcTemp[i].poorMinValue;
            tJson.innerData.poorMaxValue = wlcTemp[i].poorMaxValue;
            tJson.innerData.goodValue = wlcTemp[i].goodValue;
            tJson.innerData.goodDefaultValue = wlcTemp[i].goodDefaultValue;
            tJson.innerData.goodMinValue = wlcTemp[i].goodMinValue;
            tJson.innerData.goodMaxValue = wlcTemp[i].goodMaxValue;
            tJson.innerData.goodValueString = wlcTemp[i].goodValueString;
            tJson.innerData.poorValueString = wlcTemp[i].poorValueString;
            tJson.innerData.fairValueString = wlcTemp[i].fairValueString;
            SimLokiDatabaseActions.insert('health-threshold-setting', tJson)
        }
    };
    function createWLCHealthScoreSettingData() {
        var SwitchTemp = DefaultConfig.HealthScoreSetting.Switch_HealthScoreSetting
        for(var i=0; i<SwitchTemp.length;i++) {
            var tJson=JSON.parse(JSON.stringify(HealthScoreSettingsTemplate.RouterKPIList_TEMPLATE[0]));
            tJson.deviceType = "Switches and Hubs";
            tJson.deviceRole = "Switches and Hubs";
            tJson.kpi = SwitchTemp[i].kpi;
            tJson.displayKey = SwitchTemp[i].displayKey;
            tJson.innerData.poorValue = SwitchTemp[i].poorValue;
            tJson.innerData.poorDefaultValue = SwitchTemp[i].poorDefaultValue;
            tJson.innerData.poorMinValue = SwitchTemp[i].poorMinValue;
            tJson.innerData.poorMaxValue = SwitchTemp[i].poorMaxValue;
            tJson.innerData.goodValue = SwitchTemp[i].goodValue;
            tJson.innerData.goodDefaultValue = SwitchTemp[i].goodDefaultValue;
            tJson.innerData.goodMinValue = SwitchTemp[i].goodMinValue;
            tJson.innerData.goodMaxValue = SwitchTemp[i].goodMaxValue;
            tJson.innerData.goodValueString = SwitchTemp[i].goodValueString;
            tJson.innerData.poorValueString = SwitchTemp[i].poorValueString;
            tJson.innerData.fairValueString = SwitchTemp[i].fairValueString;
            SimLokiDatabaseActions.insert('health-threshold-setting', tJson)
        }
    };
    function createUnifiedAPHealthScoreSettingData() {
        var UnifiedAPTemp = DefaultConfig.HealthScoreSetting.UnifiedAP_HealthScoreSetting
        for(var i=0; i<UnifiedAPTemp.length;i++) {
            var tJson=JSON.parse(JSON.stringify(HealthScoreSettingsTemplate.RouterKPIList_TEMPLATE[0]));
            tJson.deviceType = "Unified AP";
            tJson.deviceRole = "Unified AP";
            tJson.kpi = UnifiedAPTemp[i].kpi;
            tJson.displayKey = UnifiedAPTemp[i].displayKey;
            if(UnifiedAPTemp[i].kpi == "rf_noise_2_4GThreshold" || UnifiedAPTemp[i].kpi == "rf_noise_5GThreshold"){
                tJson.innerData.unit = "dBm"
            }
            tJson.innerData.poorValue = UnifiedAPTemp[i].poorValue;
            tJson.innerData.poorDefaultValue = UnifiedAPTemp[i].poorDefaultValue;
            tJson.innerData.poorMinValue = UnifiedAPTemp[i].poorMinValue;
            tJson.innerData.poorMaxValue = UnifiedAPTemp[i].poorMaxValue;
            tJson.innerData.goodValue = UnifiedAPTemp[i].goodValue;
            tJson.innerData.goodDefaultValue = UnifiedAPTemp[i].goodDefaultValue;
            tJson.innerData.goodMinValue = UnifiedAPTemp[i].goodMinValue;
            tJson.innerData.goodMaxValue = UnifiedAPTemp[i].goodMaxValue;
            tJson.innerData.goodValueString = UnifiedAPTemp[i].goodValueString;
            tJson.innerData.poorValueString = UnifiedAPTemp[i].poorValueString;
            tJson.innerData.fairValueString = UnifiedAPTemp[i].fairValueString;
            SimLokiDatabaseActions.insert('health-threshold-setting', tJson)
        }
    }
});
