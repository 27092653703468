define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/ApplicationHealthList',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrend',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendDisney',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendwebx',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendSsl',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendNetflix',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendMsservices',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendHttp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendDns',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendSnmp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendcitrix',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendOutlook',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendmsoffice',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendntp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendnetbios',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendmslync',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendbinaryoverhttp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendespnbrowsing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendskype',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendbing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendmsoffice365',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendmedicalrecords',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/Healthtrendcapwapcontrol',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendMsTeams',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/app_experience/AppExperience'


], function (ApplicationHealthList,Healthtrend, HealthtrendDisney, Healthtrendwebx,
    HealthtrendSsl, HealthtrendNetflix, HealthtrendMsservices, HealthtrendHttp, HealthtrendDns, HealthtrendSnmp, Healthtrendcitrix, HealthtrendOutlook, Healthtrendmsoffice, Healthtrendntp,
    Healthtrendnetbios, Healthtrendmslync, Healthtrendbinaryoverhttp, Healthtrendespnbrowsing, Healthtrendskype, Healthtrendbing, Healthtrendmsoffice365,Healthtrendmedicalrecords,
    Healthtrendcapwapcontrol, HealthtrendMsTeams, UtilityFunctions, SimLokiDatabaseActions, AppExperience) {

        var defaultList = ["citrix-static", "snmp", "apple-services", "ms-live-accounts", "dns", "ntp", "netbios-ns","microsoft-teams"];

        return {
            init: function () {

            },

            getApplicationResponse: function (applicationName) {
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'applicationName': applicationName });
                if (record.length == 0){
                    record = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'children.applicationName': applicationName });
                }                    
                var data = [];
                var application = JSON.parse(JSON.stringify(record[0]));
                data.push(application);                
                var resData = { "version": "1.0", "response": data };
                return resData;
            },

            getApplication: function (urlAction, applicationName) {
                var response, healthScore;
                var currTime = UtilityFunctions.getTimeStamp();
                for (var i = 0; i < ApplicationHealthList.Template.length; i++) {
                    if (ApplicationHealthList.Template[i].key == applicationName) {
                        response = eval(ApplicationHealthList.Template[i].value);
                        break;
                    }
                }

                if (response == undefined) {
                    response = JSON.parse(JSON.stringify(Healthtrend.Template));
                } else {
                    response = JSON.parse(JSON.stringify(response.Template));
                }
                for (var i = 0; i < response.response.length; i++) {
                    healthScore = Math.floor(response.response[i].healthScore);
                    response.response[i].healthScore = healthScore
                    if(healthScore==null) {
                        response.response[i].applicationDelay = null;
                        response.response[i].clientNetworkDelay = null;
                        response.response[i].serverNetworkDelay = null;
                        response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(80,100,2);
                    } if(healthScore<4) {
                        response.response[i].applicationDelay = UtilityFunctions.getRandomIntForRangeValues(10,30);
                        /* response.response[i].clientNetworkDelay = null;
                        response.response[i].serverNetworkDelay = null; */
                        response.response[i].clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(0,1);
                        response.response[i].serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(60,120);
                        response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(40,90,2);
                    } if (healthScore < 9) {
                            if (applicationName == 'MedicalRecords') {
                            response.response[i].applicationDelay = UtilityFunctions.getRandomIntForRangeValues(2, 5);
                            response.response[i].clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(1, 1);
                            response.response[i].serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(250, 300);
                            response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(1, 0.5, 2);
                        } else {
                            response.response[i].applicationDelay = UtilityFunctions.getRandomIntForRangeValues(30, 80);
                            response.response[i].clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(0, 3);
                            response.response[i].serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                            response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(0, 0.8, 2);
                        }
                    } else if (healthScore < 7) {
                        response.response[i].applicationDelay = UtilityFunctions.getRandomIntForRangeValues(56, 190);
                        response.response[i].clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(5, 10);
                        response.response[i].serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(3, 5);
                        response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(1.5, 25, 2);
                    } else {
                        response.response[i].applicationDelay = UtilityFunctions.getRandomIntForRangeValues(30, 80);
                        response.response[i].clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(0, 3);
                        response.response[i].serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(0, 5);
                        response.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(0, 0.8, 2);
                    }
                }

                var data = this.packWithCurrDateTimeDefWindow(urlAction, response);

                if(applicationName == 'webex-meeting') {
                    for (var i = 0; i < data.response.length; i++) {
                        var tTimeInMs = data.response[i].timeInMills;
                        if( !( 
                            (tTimeInMs>currTime-(165*60*1000) && tTimeInMs<currTime-(105*60*1000)) ||
                            (tTimeInMs>currTime-(220*60*1000) && tTimeInMs<currTime-(190*60*1000)) ||
                            (tTimeInMs>currTime-(240*60*1000) && tTimeInMs<currTime-(225*60*1000))
                        ) && i != 0) {
                            response.response[i].healthScore = null;
                            data.response[i].applicationDelay = null;
                            /* data.response[i].clientNetworkDelay = null;
                            data.response[i].serverNetworkDelay = null; */
                            data.response[i].packetLoss = UtilityFunctions.getRandomFloatForRangeValues(80,100,2);
                        }
                    }
                }
                return data;
            },

            packWithCurrDateTimeDefWindow: function (urlAction, metricData) {
                var tWindowTime = 5;
                var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);

                if (hours > 24) {
                    tWindowTime = 60;
                }
                var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime);

                var iLen = 0;
                var data = [];
                if (metricData.response != undefined && metricData.response.length) {

                    iLen = (hours * 60) / tWindowTime;

                    if (iLen > metricData.response.length) {
                        iLen = metricData.response.length;
                    }

                }
                //var fromTime = urlAction.filter["startTime"];
                var toTime = urlAction.filter["endTime"];

                var timeToSet = UtilityFunctions.getGivenDateAndTimeInValidFormat(toTime);
                for (var i = 0; i <= iLen - 1; i++) {

                    toTime = toTime - incrementBy;

                    timeToSet = UtilityFunctions.getGivenDateAndTimeInValidFormat(toTime);

                    if (metricData.response != undefined) {

                        metricData.response[i].time = timeToSet;
                        metricData.response[i].timeInMills = toTime;
                        data.push(metricData.response[i]);

                    }

                }
                return { "version": "1.0", "response": data };

            }

        };

        /* function getBorderRouterDetail(appData) {
            var appLocation = "Global/" + appData.sourceLocation;
            var location = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy':appLocation});
            var parentLocations = [], parentId = location[0].parentId;
            parentLocations.push(location[0].id);
            while (parentId) {
                parentLocations.push(parentId);
                location = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':parentId});
                parentId = location.length>0?location[0].parentId:undefined;
            }
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
              {'$and': [{'role':'BORDER ROUTER'}, {'siteId':{'$in':parentLocations}}]
            });

            return records.length>0?records[0]:null;
        } */

    });
