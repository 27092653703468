define({
  "Template": [
    {
      "key": 'ssh',
      "value":'ssh'

    },

    {
      "key": 'disney-web-portal',
      "value":'DisneyWebPortal'

    },
    {
      "key": 'webex-meeting',
      "value":'WebEx'

    },
    {
      "key": 'ms-office-365',
      "value":'MsOffice365'

    },


    {
      "key": 'netflix',
      "value":'Netflix'

    },
    {
      "key": 'ssl',
      "value":'Ssl'

    },
    {
      "key": 'citrix-static',
      "value":'CitrixStatic'

    },

{
    "key": 'snmp',
    "value":'Snmp'

  },
  {
    "key": 'ms-services',
    "value":'Msservices'

  },
  {
    "key": 'http',
    "value":'Http'
  },
  {
    "key": 'outlook-web-service',
    "value":'OutlookWebService'
  },
  {
    "key": 'binary-over-http',
    "value":'BinaryOverHttp'
  },
  {
    "key": 'espn-browsing',
    "value":'EspnBrowsing'
  },
  {
    "key": 'apple-services',
    "value":'AppleServices'
  },
  {
    "key": 'skype',
    "value":'Skype'
  },
  {
    "key": 'ms-lync',
    "value":'MsLync'
  },
  {
    "key": 'dns',
    "value":'Dns'
  },
  {
    "key": 'ms-live-accounts',
    "value":'MsLiveAccounts'
  },

  {
    "key": 'bing',
    "value":'Bing'
  },
  {
    "key": 'ms-office-web-apps',
    "value":'MsOfficeWebApps'
  },
  {
    "key": 'ntp',
    "value":'Ntp'
  },
  {
    "key": 'ms-update',
    "value":'MsUpdate'
  },
  {
    "key": 'netbios-ns',
    "value":'NetbiosNs'
  },
  {
    "key": 'telnet',
    "value":'Telnet'

  },
  {
    "key": 'youtube',
    "value":'Youtube'

  },
  {
    "key": 'google-services',
    "value":'GoogleServices'

  },
  {
    "key": 'medicalrecords',
    "value":'MedicalRecords'

  },
  {
    "key": 'capwap-control',
    "value":'CapwapControl'

  },
  {
    "key": 'hl7',
    "value":'hl7'

  },
  {
    "key": 'ms-olap',
    "value":'Msolap'

  },
  {
    "key": 'ms-sql-m',
    "value":'Mssqlm'

  },
  {
    "key": 'secure-http',
    "value":'Securehttp'

  },
  {
    "key": 'microsoft-teams',
    "value":'Microsoftteams'

  },               
  {
    "key": 'ms-teams-audio',
    "value":'Msteamsaudio'

  },
  {
    "key": 'ms-teams-video',
    "value":'Msteamsvideo'

  },
  {
    "key": 'ms-teams-app-sharing',
    "value":'Msteamsappsharing'

  },
  {
    "key": 'ms-teams',
    "value":'msteams'
  },
  {
    "key": 'ms-teams-media',
    "value":'Msteamsmedia'
  },


  ]
});
