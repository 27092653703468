define({ 
    "deviceInfoTemplate":  
       {         
            "managementIpAddr": ["10.16.51.137"],
            "HAPrimaryPowerStatus":["Present, Ok"],
            "redundancyMode":["Disabled"],
            "communicationState":["REACHABLE"],
            "nwDeviceRole":["ACCESS"],
            "featureFlagList":[["AP_OOB_IMGDWNLD_1","AP_SENSOR_1","ASR_2","AWIPS_2","BLE_1","CPU_MEM_RADIO_MONITOR_1","FLEX_AP_TO_AP_DISTRIBUTION_4","FRA_1","ISSU_1","MESH_1","MESH_SUB_FEATURE_FAST_TEARDOWN_1","MESH_SUB_FEATURE_SERIAL_BACKHAUL_1","POWER_POLICY_1","RLAN_1","RLAN_SUB_FEATURE_AUTH_FALLBACK_1","RLAN_SUB_FEATURE_FABRIC_1","ROGUE_2","SDAVC_FLEX_FABRIC_1","SNIFFER_RADIO_1","SPLIT_TUNNEL_1","UPN_1","WLAN_RADIO_1","WPA3_2"]],
            "osType":["IOS-XE"],
            "ringStatus":[false],
            "ip_addr_managementIpAddr":["10.16.31.42"],
		    "stackType":["NA"],
		    "nwDeviceType":["Cisco Catalyst 9800-40 Wireless Controller"],
		    "HALastResetReason":["PowerOn"],
		    "haStatus":["Non-redundant"],
		    "serialNumber":["TTM224105LK"],
		    "nwDeviceName":["SJC23-C9800-01.ibng.local"],
            "deviceGroupHierarchyId":["\/99fb1c48-e0d4-425e-a20e-e5ef4a9a9d2e\/08815035-d909-4c75-af52-1f5d15fa43c7\/"],
            "platformId":["C9800-40-K9"],
            "redundancyPeerState":["DISABLED"],
		    "nwDeviceId":["2a00ed5b-f73d-4621-8319-4e82e01afdff"],
		    "redundancyState":["ACTIVE"],
		    "siteHierarchyGraphId":["\/00d38152-9d4f-41f9-a8e9-af3f811d4920\/3d0243ca-266f-4a31-8a4e-3c672bb57d14\/185634f7-b7fd-4720-b7c9-db62ffe87c0c\/830e5d99-4709-4e4f-be34-46591612677b\/7a3005fe-286f-4dc6-a316-a3ff18fb4e4a\/29f01208-db31-4db8-ba8d-e80c04a34edd\/"],
		    "nwDeviceFamily":["Wireless Controller"],
		    "macAddress":["70:6D:15:35:85:0B"],
            "maintenanceMode":[false],
            "HASecondaryPowerStatus":["NA"],
            "deviceSeries": ["Cisco Catalyst 9115AXE Series Unified Access Points"],
            "collectionStatus": ["SUCCESS"],
			"lastBootTime": [1680961028590.0],
            "softwareVersion": ["17.2.1.11"],
            "tagIdList": [[]]
        },
});
