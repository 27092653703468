define([
], function () {

    function BitArray(size, value) {

        if (value === undefined) value = 0;
        this.size = size;

        this.bitArray = new Array(this.size);
        for (var i = 0; i < this.bitArray.length; i++) {
            this.bitArray[i] = 0;
        }

        BitArray.prototype.set = function (position, value) {
            if (position >= 0 && this.bitArray.length > position) {
                this.bitArray[position] = value;
            }
        }

        BitArray.prototype.get = function (position) {
            if (position >= 0 && this.bitArray.length > position) {
                return this.bitArray[position];
            }
        }

        BitArray.prototype.setArray = function (value) {
            for (var i = 0; i < this.bitArray.length; i++) {
                this.bitArray[i] = value;
            }
        }

        // Iterate over each bit
        BitArray.prototype.each = function (method) {
            for (var i = 0; i < this.bitArray.length; i++) method(this.get(i), i);
        }

        BitArray.prototype.isAllbitSetaValue = function (value) {
            var length = this.bitArray.length;
            if (length == 0) {
                return false;
            }

            for (var i = 0; i < length; i++) {
                if (this.bitArray[i] != value) {
                    return false;
                }
            }
            //console.log(this.bitArray);
            return true;
        }
    }

    return BitArray;
});
