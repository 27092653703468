define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "a6f46cef-c79c-454a-887e-8b6e06d7d1ca",
    "globalParentId": "Unknown",
    "name": "infra_link_down",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "78541e81-9deb-4b5e-9c5d-cb59411121d1",
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "Interface \"InterfaceDownPlaceHolder\" is down on network device \"DeviceNamePlaceHolder\"",
    "groupId": "infra_link_down_grouping",
    "groupName": "Interface Connecting Network Devices is Down",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "Unknown",
    "timestamp": 1619561329110,
    "description": "Interface InterfaceDownPlaceHolder connecting the following two network devices is down:  Local Node: DeviceNamePlaceHolder (C9300-24U) - InterfaceDownPlaceHolder.  Peer Node: SFO13-D9300-2 (C9300-24U) - InterfaceDownPlaceHolder.",
    "suggestions": [
      {
        "message": "Ensure the link speed, duplex, auto-negotiation and media type are supported and match on both sides of link",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the errors and link information of the affected interface on Local Node",
            "command": "show interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the errors and link information of the affected interface on Peer Node",
            "command": "show interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the error, oversize, undersize, underrun, overrun, collision frames of the affected interface on Local Node",
            "command": "show controller ethernet-controller InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the error, oversize, undersize, underrun, overrun, collision frames of the affected interface on Peer Node",
            "command": "show controller ethernet-controller InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check the condition of the copper cable by running TDR (Time Domain Reflector) test. (Warning: Running this step might be disruptive to the connection)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test on Local Node, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test on Peer Node, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Verify DOM status on both sides of the link, if the affected link is optical",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check transceiver detail on Local Node",
            "command": "show interface transceiver detail",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check transceiver detail on Peer Node",
            "command": "show interface transceiver detail",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Verify configuration is compliant on both sides of the link ",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check running configuration of the affected interface on Local Node",
            "command": "show running-config interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check running configuration of the affected interface on Peer Node",
            "command": "show running-config interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check the result of TDR (Time Domain Reflector) test",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of TDR test on Local Node",
            "command": "show cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of TDR test on Peer Node",
            "command": "show cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Configure the interfaces in local loopback. With local loopback the TX is connected to the RX at the port level inside the switch. Failure of the port to come up and ping itself with a local loop indicates a problem with the switch."
      },
      {
        "message": "Connect the faulty port to be tested to a spare port on the same switch, with the same cable and connectors/SFP. Configure the spare and faulty ports in two different VRFs, in the same subnet and test ping. Ping failure indicates cable/SFP problem."
      },
      {
        "message": "Check for compatibility issues between the two devices connected by this link."
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "owningEntityId",
        "value": "78541e81-9deb-4b5e-9c5d-cb59411121d1"
      },
      {
        "key": "managementIpAddr",
        "value": "10.0.100.101"
      },
      {
        "key": "_name",
        "value": "infra_link_down"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:78541e81-9deb-4b5e-9c5d-cb59411121d1:GigabitEthernet1/0/13"
      },
      {
        "key": "deviceName",
        "value": "SF-D9300-1"
      },
      {
        "key": "isFabricLink",
        "value": "false"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/San Francisco"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:78541e81-9deb-4b5e-9c5d-cb59411121d1"
      },
      {
        "key": "interfaceName",
        "value": "GigabitEthernet1/0/13"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9300 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "78541e81-9deb-4b5e-9c5d-cb59411121d1"
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "1800"
      },
      {
        "key": "deviceOS",
        "value": "16.8.1a"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "SF-D9300-1"
      },
      {
        "key": "_eventTime",
        "value": "1619561329110"
      },
      {
        "key": "_mostRecent",
        "value": "1619561329110"
      },
      {
        "key": "entityId",
        "value": "78541e81-9deb-4b5e-9c5d-cb59411121d1"
      },
      {
        "key": "eventUniqueId",
        "value": "78541e81-9deb-4b5e-9c5d-cb59411121d1"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "buildingId",
        "value": "ed2f2b27-6803-4360-9d31-0a1590450f8c"
      },
      {
        "key": "isInfraLink",
        "value": "1"
      },
      {
        "key": "buildingName",
        "value": "Global/San Francisco/Pier 39"
      },
      {
        "key": "deviceType2",
        "value": "Switches and Hubs"
      },
      {
        "key": "areaId",
        "value": "fa9aaabd-67b3-4d6b-8bc0-136c76428a35"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/San Francisco/Pier 39"
      },
      {
        "key": "sIfAdminStatus",
        "value": ""
      },
      {
        "key": "deviceModel",
        "value": "FCW2239G0XC"
      },
      {
        "key": "category",
        "value": "Connectivity"
      },
      {
        "key": "timestring",
        "value": "2021-04-27T22:08:49.110+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  },
  "poorRFImpactedInfo": null
}
})