define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWPXy33D69KuApuoPDCV",
        "name": "ios_client_disassoc_single",
        "entityType": "Client",
        "entity": "A8:BE:27:36:70:11",
        "groupBy": "Unknown",
        "category": "Connectivity",
        "severity": "MEDIUM",
        "priority": "P2",
        "summary": "Apple iOS Client Disconnected from \"SSID@ToChange\" SSID on \"AP@ToChange\" AP - Client Internal Event",
        "scope": "GLOBAL",
        "rootCause": "IOS_DISASSOC_FAIL_INTERNAL",
        "timestamp": 1528333818673,
        "description": "This Apple iOS client, running Apple iOS '11.4', got disconnected from the network because of reasons internal to the client, such as software or operating system actions. This client was connected to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP in location 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC. Detailed information about these iOS internal errors are not known at this point.",
        "suggestions": [
          {
            "message": "Disable and re-enable the Wi-Fi network on the client device as it may reset Apple's client networking stack.",
            "steps": [

            ]
          },
          {
            "message": "Restart the client device as it may reset the Apple's client networking stack.",
            "steps": [

            ]
          },
          {
            "message": "Verify that the client is running the latest Apple iOS version as the issue may have been fixed in later release.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "hostName",
            "value": "Grace.Smith-iPhone"
          },
          {
            "key": "subReasonType",
            "value": "cl_ST_MISC_REASON"
          },
          {
            "key": "ipv6Address",
            "value": ""
          },
          {
            "key": "channel",
            "value": "1"
          },
          {
            "key": "ipv4Address",
            "value": "10.30.100.29"
          },
          {
            "key": "type",
            "value": "issue"
          },
          {
            "key": "reasonType",
            "value": "cl_RECVD_DISASSOC_FROM_CLIENT"
          },
          {
            "key": "ssid",
            "value": "LA-Corporate2"
          },
          {
            "key": "apName",
            "value": "LA1-AP3802-21"
          },
          {
            "key": "frequency",
            "value": "2.4"
          },
          {
            "key": "hostType",
            "value": "WIRELESS"
          },
          {
            "key": "rootCause",
            "value": "IOS_DISASSOC_FAIL_INTERNAL"
          },
          {
            "key": "hostOSType",
            "value": "iPhone 7"
          },
          {
            "key": "hostVersion",
            "value": "11.4"
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "entityId",
            "value": "A8:BE:27:36:70:11"
          },
          {
            "key": "eventType",
            "value": "cl_EVENT_RECV_DISASSOC"
          },
          {
            "key": "wlcName",
            "value": "LA1-WLC5520-2"
          },
          {
            "key": "serverIp",
            "value": ""
          },
          {
            "key": "siteId",
            "value": "b34dc4cf-2cbd-4504-87b4-cfab950d515c"
          },
          {
            "key": "category",
            "value": "Connectivity"
          },
          {
            "key": "timestring",
            "value": "2018-06-07T01:10:18.673+0000"
          },
          {
            "key": "macAddr",
            "value": "A8:BE:27:36:70:11"
          },
          {
            "key": "status",
            "value": "active"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": "USA/LA/Level16"
      }
    ]
  }
})