define({
    "Template":

{
    "request": {
      "key": "telnet",
      "type": "Application",
      "label": "telnet",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "d7b43df8-5d1d-4e87-949f-ccafc319490c"
        }
      ]
    },
    "data": {
      "response": {
        "id": "d7b43df8-5d1d-4e87-949f-ccafc319490c",
        "instanceId": 15270,
        "authEntityId": 15270,
        "displayName": "15270",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528178811611,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528178811611,
        "name": "telnet",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "telnet",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "0f7f239e-f643-4c56-b8f9-85c04d57e696",
            "instanceId": 17438,
            "authEntityId": 17438,
            "displayName": "17438",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "23",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "dc7c8071-f88d-4bb3-9e93-5cf29675bb03",
            "instanceId": 16212,
            "authEntityId": 16212,
            "displayName": "16212",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
            "createTime": 1528178811611,
            "encrypted": "false",
            "engineId": "3",
            "helpString": "Telnet - virtual text-oriented terminal over network",
            "ignoreConflict": false,
            "lastUpdateTime": 1528178811611,
            "longDescription": "Telnet is a cross-platform interactive text-based protocol used to connect remote clients over a TCP/IP network. The telnet client connects to a host and becomes a Network Virtual Terminal (NVT) allowing the user to communicate remotely with the host. Typically, the protocol uses TCP port 23.",
            "name": "telnet",
            "nbarId": "42",
            "p2pTechnology": "false",
            "popularity": 9,
            "rank": 65535,
            "references": "http://www.faqs.org/rfcs/rfc854.html",
            "selectorId": "23",
            "subCategory": "64fcf00a-d41e-42d4-a940-376c8a730293",
            "trafficClass": "OPS_ADMIN_MGMT",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "41e25fb7-847e-4d57-91d8-b19d5f70dbbb",
            "instanceId": 17437,
            "authEntityId": 17437,
            "displayName": "17437",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "23",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "33478431-d4ae-4aa5-9549-832177513fad"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "d7b43df8-5d1d-4e87-949f-ccafc319490c",
          "instanceId": 15270,
          "authEntityId": 15270,
          "displayName": "15270",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528178811611,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528178811611,
          "name": "telnet",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "telnet",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [
            {
              "id": "0f7f239e-f643-4c56-b8f9-85c04d57e696",
              "instanceId": 17438,
              "authEntityId": 17438,
              "displayName": "17438",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "23",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "networkApplications": [
            {
              "id": "dc7c8071-f88d-4bb3-9e93-5cf29675bb03",
              "instanceId": 16212,
              "authEntityId": 16212,
              "displayName": "16212",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp",
              "applicationGroup": "other",
              "applicationType": "DEFAULT",
              "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
              "createTime": 1528178811611,
              "encrypted": "false",
              "engineId": "3",
              "helpString": "Telnet - virtual text-oriented terminal over network",
              "ignoreConflict": false,
              "lastUpdateTime": 1528178811611,
              "longDescription": "Telnet is a cross-platform interactive text-based protocol used to connect remote clients over a TCP/IP network. The telnet client connects to a host and becomes a Network Virtual Terminal (NVT) allowing the user to communicate remotely with the host. Typically, the protocol uses TCP port 23.",
              "name": "telnet",
              "nbarId": "42",
              "p2pTechnology": "false",
              "popularity": 9,
              "rank": 65535,
              "references": "http://www.faqs.org/rfcs/rfc854.html",
              "selectorId": "23",
              "subCategory": "64fcf00a-d41e-42d4-a940-376c8a730293",
              "trafficClass": "OPS_ADMIN_MGMT",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "41e25fb7-847e-4d57-91d8-b19d5f70dbbb",
              "instanceId": 17437,
              "authEntityId": 17437,
              "displayName": "17437",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "23",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "33478431-d4ae-4aa5-9549-832177513fad"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});