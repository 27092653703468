define({
    "Template": [
  {
    "~pmtimestamp": 1544840378006,
    "siteHierarchy": [
      "Global/MLPTS"
    ],
    "label": "Site",
    "name": [
      "MLPTS"
    ],
    "siteType": [
      "area"
    ],
    "id": "33755632-e8d2-48d1-8a3d-63d392e99b14"
  },
  {
    "~pmtimestamp": 1544840378006,
    "siteHierarchy": [
      "Global/MLPTS/SJC23_Dec18"
    ],
    "label": "Site",
    "latitude": [
      37.418675
    ],
    "name": [
      "SJC23_Dec18"
    ],
    "longitude": [
      -121.919446
    ],
    "siteType": [
      "building"
    ],
    "id": "61156221-47b4-49f5-9d73-66d7e85c0936"
  }
]
});