define({
    "ONBOARDING_MAPPINGS": {
        //3 items below listed are added for baseline
        "932eec26-db6e-40a5-a7d7-d2dfec91e8e7": {
            "dnacIssueId":"932eec26-db6e-40a5-a7d7-d2dfec91e8e7",
            "kairosId":"a1128f3da344443cd767",//"a1119232256982aa4247",
            "eventEntityId":"0625904349027df26431",
            "defDaysBack":"-2",
            "issueTimings":{
                "-2":["05:00:00","05:30:00","12:00:00","15:00:00","18:00:00"],
                "-1":["02:30:00","05:00:00","05:30:00","06:00:00"],
                "length":8
            },
            "issueDateTimes": [],
            "templateFile": "AI_EXCESSIVE_TIME"
        },
        "ec9881d1-c12c-44bf-ad31-23d9df7c8aa0": {
            "dnacIssueId":"ec9881d1-c12c-44bf-ad31-23d9df7c8aa0",
            "kairosId":"a1c338adfdc94e34b974",//"a198acd938cdf278341c",
            "eventEntityId":"06aa489325abd983979e", //06873d194e0135357e3a //068f430aed1960b1e418
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["17:00:00","18:00:00","18:30:00","19:00:00","19:30:00","20:00:00","20:30:00","21:00:00","21:30:00","22:00:00","22:30:00","23:00:00"],
                "length":12
            },
            "additionalIssueTimings":{
                "-2":["11:30:00","12:30:00"]
            },
            "issueDateTimes":[],
            "templateFile":"AI_FAILURE_CONNECT"
        },
        "3daf3e83-fa98-4294-90b5-c67c75258bb1": {
            "dnacIssueId":"3daf3e83-fa98-4294-90b5-c67c75258bb1",
            "kairosId":"a1128f3da344443ab656",//"a1cb44dd724b4059d40e",
            "eventEntityId":"0734121bc9dfa2fd2eea",
            "radioEntityId":"0734121bc9dfa2fd2eea",
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["17:00:00","18:30:00"],
                "length":2
            },
            "issueDateTimes": [],
            "templateFile": "AI_EXCESSIVE_TIME_IPADDRESS"
        }
    }
})