define({
  "runningImageList": [
    {
      "name": "cat9k_iosxe.17.09.01.SPA.bin",
      "imageUuid": "c7c3adac-1a1a-416f-9e8b-21704458702b",
      "version": "17.9.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "fdb9c92bae37f9130d0ee6761afe2919",
      "shaCheckSum": "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2022-08-01 19:31:12.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "59fbd410-1a1a-4a6b-a3a0-fa8071f39733",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.09.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 1018097396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 1,
      "imageAttributes": [
        {
            "propertyName": "MEDIA",
            "propertyValue": "5"
        },{
            "propertyName": "COMPRESSION_CODE",
            "propertyValue": "-1"
        },{
            "propertyName": "deviceMNEId",
            "propertyValue": "23030007"
        },{
            "propertyName": "metadata_trans_id",
            "propertyValue": "330079917"
        },{
            "propertyName": "MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },{
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "release_fcs_date",
            "propertyValue": "01/Aug/2022"
        },{
            "propertyName": "BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
        },{
            "propertyName": "image_size",
            "propertyValue": "901741775"
        },{
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
        },{
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FEATURE",
            "propertyValue": ""
        },{
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "min_dram",
            "propertyValue": "8192"
        },{
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },{
            "propertyName": "Description",
            "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },{
            "propertyName": "release_doc_name",
            "propertyValue": "Release Notes for Cupertino-17.9.1"
        },{
            "propertyName": "min_flash",
            "propertyValue": "16384"
        },{
            "propertyName": "image_guid",
            "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
        },{
            "propertyName": "software_type_id",
            "propertyValue": "282046477"
        },{
            "propertyName": "GAIA_FEATURE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FULL_VERSION",
            "propertyValue": "17.09.01.0.178"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R",
            "C9404R-WS",
            "C9404R-48UBNDLA-RF",
            "C9404R-UL",
            "C9404R=",
            "C9404R-1A",
            "C9404R-10A",
            "C9404R-48U-BNDL-A",
            "C9404R-P",
            "C9404R-48U-BNDL-1E",
            "C9404R-48U-BDL-EDU",
            "C9404R-48U-BNDL-E",
            "C9404R-48U-BNDL-1A"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true,
      "ccoreverseSync": true
    },{
      "name": "cat9k_iosxe.17.06.01.SPA.bin",
      "imageUuid": "c7c3adac-11ab-416f-9e8b-21704458702b",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "fdb9c92bae37f9130d0ee6761afe2919",
      "shaCheckSum": "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2021-09-14 19:31:12.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "59fbd410-1111-4a6b-a3a0-fa8071f39733",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.06.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 1018097396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 1,
      "imageAttributes": [
        {
            "propertyName": "MEDIA",
            "propertyValue": "5"
        },{
            "propertyName": "COMPRESSION_CODE",
            "propertyValue": "-1"
        },{
            "propertyName": "deviceMNEId",
            "propertyValue": "23030007"
        },{
            "propertyName": "metadata_trans_id",
            "propertyValue": "330079917"
        },{
            "propertyName": "MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },{
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "release_fcs_date",
            "propertyValue": "05/Mar/2021"
        },{
            "propertyName": "BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
        },{
            "propertyName": "image_size",
            "propertyValue": "901741775"
        },{
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
        },{
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FEATURE",
            "propertyValue": ""
        },{
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "min_dram",
            "propertyValue": "8192"
        },{
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },{
            "propertyName": "Description",
            "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },{
            "propertyName": "release_doc_name",
            "propertyValue": "Release Notes for Amsterdam-17.3.3"
        },{
            "propertyName": "min_flash",
            "propertyValue": "16384"
        },{
            "propertyName": "image_guid",
            "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
        },{
            "propertyName": "software_type_id",
            "propertyValue": "282046477"
        },{
            "propertyName": "GAIA_FEATURE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FULL_VERSION",
            "propertyValue": "17.03.03.0.4762"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R",
            "C9404R-WS",
            "C9404R-48UBNDLA-RF",
            "C9404R-UL",
            "C9404R=",
            "C9404R-1A",
            "C9404R-10A",
            "C9404R-48U-BNDL-A",
            "C9404R-P",
            "C9404R-48U-BNDL-1E",
            "C9404R-48U-BDL-EDU",
            "C9404R-48U-BNDL-E",
            "C9404R-48U-BNDL-1A"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true,
      "ccoreverseSync": true
    },
    {
      "name": "cat9k_iosxe.17.03.03.SPA.bin",
      "imageUuid": "c7c3adac-1111-416f-9e8b-21704458702b",
      "version": "17.3.3",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "89c98b1ed44cf6cb1190eca977edb9a5",
      "shaCheckSum": "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
      "createdTime": "2021-03-09 19:31:12.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "59fbd410-1111-4a6b-a3a0-fa8071f39733",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 901741775,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 1,
      "imageAttributes": [
        {
            "propertyName": "MEDIA",
            "propertyValue": "5"
        },{
            "propertyName": "COMPRESSION_CODE",
            "propertyValue": "-1"
        },{
            "propertyName": "deviceMNEId",
            "propertyValue": "23030007"
        },{
            "propertyName": "metadata_trans_id",
            "propertyValue": "330079917"
        },{
            "propertyName": "MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },{
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "release_fcs_date",
            "propertyValue": "05/Mar/2021"
        },{
            "propertyName": "BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
        },{
            "propertyName": "image_size",
            "propertyValue": "901741775"
        },{
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
        },{
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FEATURE",
            "propertyValue": ""
        },{
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "min_dram",
            "propertyValue": "8192"
        },{
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },{
            "propertyName": "Description",
            "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },{
            "propertyName": "release_doc_name",
            "propertyValue": "Release Notes for Amsterdam-17.3.3"
        },{
            "propertyName": "min_flash",
            "propertyValue": "16384"
        },{
            "propertyName": "image_guid",
            "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
        },{
            "propertyName": "software_type_id",
            "propertyValue": "282046477"
        },{
            "propertyName": "GAIA_FEATURE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FULL_VERSION",
            "propertyValue": "17.03.03.0.4762"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R",
            "C9404R-WS",
            "C9404R-48UBNDLA-RF",
            "C9404R-UL",
            "C9404R=",
            "C9404R-1A",
            "C9404R-10A",
            "C9404R-48U-BNDL-A",
            "C9404R-P",
            "C9404R-48U-BNDL-1E",
            "C9404R-48U-BDL-EDU",
            "C9404R-48U-BNDL-E",
            "C9404R-48U-BNDL-1A"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true,
      "ccoreverseSync": true
    },
    {
      "name": "cat9k_iosxe.17.03.01.SPA.bin",
      "imageUuid": "6e8af397-efef-5555-6354-9326a22a6910",
      "version": "17.3.1",//Amsterdam-17.3.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "b90c6ef495af2ed6f9dcfe98455622eb",
      "shaCheckSum": "25c2fd958e35fb0b5e62d2617610548bd361df234bda52803ce6027bce26b7d9404df558e85195767b893fbf4d90315914a2d8c5e68eebec30371eef01168533",
      "createdTime": "2020-10-16 14:27:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-1111-90f1-ac23-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 892195673,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "09/Aug/2020"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9300-48UXM VID: V01, SN: FOC2222Z12U"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122197"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "20205AFC7D135C514E68161F51B4F5FA9733EDE4"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141160119"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "892195673"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.17.02.01.SPA.bin",
      "imageUuid": "6e8af397-aaaa-5555-6354-9326a22a6910",
      "version": "17.2.1",//Amsterdam-17.2.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "4ed2c1ed0eba5d0347770e64e25f6548",
      "shaCheckSum": "b22eb5905d0b364e355750248042dd79a551070728165ca72203020dd831df1ed578c9e6a18b81ba9e7d4ce52502311970e3f0afb34af9fad72e03f70ba301e9",
      "createdTime": "2020-04-28 19:27:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-678f-90f1-ac23-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 871066119,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "28/Mar/2020"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9300-48UXM VID: V01, SN: FOC2222Z12U"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122197"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "20085AFC7D135C514E68161F51B4F5FA9733EDE4"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141160119"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "810146146"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.16.12.03.SPA.bin",
      "imageUuid": "6e8af397-bbbb-5555-6354-9326a22a6910",
      "version": "16.12.3",//	Gibraltar-16.12.3
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "e578d84cc4bd2f4d1f0cdedbf9bd2604",
      "shaCheckSum": "5da3254765935ff2d382d676f9e8d4135e35dd47c5d402bfba0037e1e1f998649cf334de906426eda739621ac7b9e7e997801628bc6deb5c16bcf141bd1d8476",
      "createdTime": "2020-04-28 19:30:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-678f-90f1-ac23-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 805013186,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "12/Mar/2020"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9300-48UXM VID: V01, SN: FOC2222Z12U"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122197"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "20085AFC7D135C514E68161F51B4F5FA9733EDE4"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141160119"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "810146146"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false
    },
    {
      "name": "cat9k_iosxe.17.01.01.SPA.bin",
      "imageUuid": "6e8af397-e123-5555-6354-9326a22a6910",
      "version": "17.1.1",//Amsterdam-17.1.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7fac63beb33ebfb34c3a688a194780f3",
      "shaCheckSum": "7dc3d644aca4872cd3e2ffe8cb28064fafc04c76ce087639fe1d11f28e5a1209a5c2f7b113c1425122738e65cef9eb62cc37ae9a72bb7d20b65809ad39024a99",
      "createdTime": "2019-11-23 10:51:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-678f-90f1-ac23-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 810146146,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "23/Nov/2019"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9300-48UXM VID: V01, SN: FOC2222Z12U"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122197"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "20085AFC7D135C514E68161F51B4F5FA9733EDE4"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141160119"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "810146146"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.16.12.01s.SPA.bin",
      "imageUuid": "6e8af397-e123-5555-803b-9326a22a6910",
      "version": "16.12.1s",//Gibraltar-16.12.1c
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "a2ce6b31960ecfe4fcf4dc4e29355827",
      "shaCheckSum": "260d3cedd73b98d87f5dc3d83b23906cf247f6d62d7b5ea22edce5ff555adca539341d0773c1081647bebade18e6f94ac973c6e4f94767017bb29b52b8197204",
      "createdTime": "2019-09-04 10:51:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-678f-90f1-ac23-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 810146146,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "31/Aug/2019"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9404R VID: V01, SN: FOC2222Z12U"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122197"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "20085AFC7D135C514E68161F51B4F5FA9733EDE4"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141160119"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT9400/9500H Universal"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "286320244"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "810146146"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286320244",
          "productName": "Cisco Catalyst 9404R Switch",
          "productId": [
            "C9404R"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false
    }
  ]
});