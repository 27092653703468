define({
  "Template":
  {
    "request": {
      "key": "ssh",
      "type": "Application",
      "label": "ssh",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "1cbf280a-b637-44a7-aea9-148707494f2a"
        }
      ]
    },
    "data": {
      "response": {
        "id": "1cbf280a-b637-44a7-aea9-148707494f2a",
        "instanceId": 15207,
        "authEntityId": 15207,
        "displayName": "15207",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528265514675,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528265514675,
        "name": "ssh",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "ssh",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "e18fa017-ef1b-49de-8e58-0345e729293d",
            "instanceId": 17259,
            "authEntityId": 17259,
            "displayName": "17259",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "22",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "6e251d35-8697-48f7-b40f-209a9d2d75fb",
            "instanceId": 16149,
            "authEntityId": 16149,
            "displayName": "16149",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "211a6ea4-35b5-4cd7-a0dc-af56b821e903",
            "createTime": 1528265514675,
            "encrypted": "true",
            "engineId": "3",
            "helpString": "Secure Shell",
            "ignoreConflict": false,
            "lastUpdateTime": 1528265514675,
            "longDescription": "Secure Shell Protocol (SSH) is a protocol used to secure login and other secure network services over an unsecure network. The protocol based on a client-server architecture has three steps for the connection: First the server has to be authenticated to the client over a reliable transport connection (usually TCP/IP), then the client side is authenticated-only then the connection is established and the client-server encrypted connection can transfer data between them. Typically the protocol uses TCP port 22.",
            "name": "ssh",
            "nbarId": "40",
            "p2pTechnology": "false",
            "popularity": 9,
            "rank": 65535,
            "references": "http://www.ietf.org/rfc/rfc4251.txt",
            "selectorId": "22",
            "subCategory": "db4366f1-0b1d-4107-9351-e15c7f9eb701",
            "trafficClass": "OPS_ADMIN_MGMT",
            "tunnel": "true"
          }
        ],
        "networkIdentity": [
          {
            "id": "a99077cc-cc1a-48f6-aafc-fdcc4dd5db62",
            "instanceId": 17258,
            "authEntityId": 17258,
            "displayName": "17258",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "22",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "09ff5418-d9d6-4948-bf22-0fc48234bcb9"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "1cbf280a-b637-44a7-aea9-148707494f2a",
          "instanceId": 15207,
          "authEntityId": 15207,
          "displayName": "15207",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528265514675,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528265514675,
          "name": "ssh",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "ssh",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [
            {
              "id": "e18fa017-ef1b-49de-8e58-0345e729293d",
              "instanceId": 17259,
              "authEntityId": 17259,
              "displayName": "17259",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "22",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "networkApplications": [
            {
              "id": "6e251d35-8697-48f7-b40f-209a9d2d75fb",
              "instanceId": 16149,
              "authEntityId": 16149,
              "displayName": "16149",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp",
              "applicationGroup": "other",
              "applicationType": "DEFAULT",
              "categoryId": "211a6ea4-35b5-4cd7-a0dc-af56b821e903",
              "createTime": 1528265514675,
              "encrypted": "true",
              "engineId": "3",
              "helpString": "Secure Shell",
              "ignoreConflict": false,
              "lastUpdateTime": 1528265514675,
              "longDescription": "Secure Shell Protocol (SSH) is a protocol used to secure login and other secure network services over an unsecure network. The protocol based on a client-server architecture has three steps for the connection: First the server has to be authenticated to the client over a reliable transport connection (usually TCP/IP), then the client side is authenticated-only then the connection is established and the client-server encrypted connection can transfer data between them. Typically the protocol uses TCP port 22.",
              "name": "ssh",
              "nbarId": "40",
              "p2pTechnology": "false",
              "popularity": 9,
              "rank": 65535,
              "references": "http://www.ietf.org/rfc/rfc4251.txt",
              "selectorId": "22",
              "subCategory": "db4366f1-0b1d-4107-9351-e15c7f9eb701",
              "trafficClass": "OPS_ADMIN_MGMT",
              "tunnel": "true"
            }
          ],
          "networkIdentity": [
            {
              "id": "a99077cc-cc1a-48f6-aafc-fdcc4dd5db62",
              "instanceId": 17258,
              "authEntityId": 17258,
              "displayName": "17258",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "22",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "09ff5418-d9d6-4948-bf22-0fc48234bcb9"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});