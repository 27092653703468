define({
    "commandsTemplate": {
      "pwd": {},
      "sh_run_int": {},
      "show_controller_ethernet_controller": {},
      "sh_eigrp_address_family_ipv4_neighbors": {},
      "show_interface_GigabitEthernet": {},
      "show_interfaces_transceiver_detail": {},
      "show_ip_route": {},
      "show_ip_cef": {},
      "show_controllers_cpu_interface": {},
      "show_isis_neighbors": {},
      "show_ospf_neighbor": {},
      "show_platform_qos_queue_stats_internal_cpu_policer": {},
      "show_proc_cpu_history": {},
      "show_80211b_cleanair_device_ap":{},
      "sh_ip_bgp_neighbors": {},
      "show_processes_memory_platform_sorted": {},
      "show_memory_platform": {},
      "show_env_power": {},
      "show_environment_power": {},
      "show_running_config" : {},
      "show_interface_TenGigabitEthernet" : {},
      "show_power_inline" : {},
      "show_isis_neighbor": {},
      "show_int_GigabitEthernet": {},
      "show_ip_arp": {},
      "show_interface_Tunnel": {},
      "ping": {},
      "show_ip_ospf_neighbor": {},
      "show_run_sec_ospf": {},
      "show_ip_interface_TenGigabitEthernet": {},
      "show_int_TenGigabitEthernet": {},
      "show_fabric_map_server_summary": {},
      "show_fabric_map_server_detailed": {},
      "show_run_": {},
      "show_run_sec_lisp": {},
      "show_lisp_session": {},
      "sh_int_GigabitEthernet": {},
      "show_lisp_service_ipv4_summary": {},
      "show_stack_power_detail": {},
      "show_tech_support": {},
      "show_power_inline_police": {},
      "show_platform_software_status_control_processor_brief": {},
      "show_network_assurance_summary": {},
      "show_run_interface_GigabitEthernet": {},
      "test_cable_diagnostics_tdr_interface_GigabitEthernet": {},
      "show_cable_diagnostics_tdr_interface_GigabitEthernet": {},
      "show_power_inline_GigabitEthernet": {},
      "show_ip_eigrp_neighbors": {},
      "show_80211a_cleanair_device_ap": {},
      "show_ntp_status": {},
      "show_ntp_associations": {},
      "show_clock": {},
      "show_interface_TwoGigabitEthernet": {},
      "show_running_config_interface_TwoGigabitEthernet": {},
      "dir": {},
      "where": {},
      "systat": {},
      "show_config": {},
      "show_interfaces": {},
      "cd": {},
      "help": {},
       "traceroute":{},
      "show": {},
      "show_voltage": {},
      "show_cable_diagnostics_tdr_interface_TwoGigabitEthernet": {},
      "show_power_inline_TwoGigabitEthernet": {},
      "test_cable_diagnostics_tdr_interface_TwoGigabitEthernet": {},
      "show_interface_Two_GigabitEthernet_summary": {},
      "show_run_sec_eigrp": {},
      "show_run_sec_domain": {},
      "show_running_config_s_ntp": {},
      "sh_power_inline": {},
      "show_cdp_neighbors": {},
      "show_cdp_neighbor_GigabitEthernet":{},
      "show_switch":{}



    }
})
