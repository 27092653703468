define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
 ], function(SimLokiDatabaseActions) {

 return {
      init: function(){
      },

      getUserDetail: function(urlAction) {
          var id = '';
          if(urlAction.filter['userId'] != undefined) {
              id = urlAction.filter['userId'];
          }
          if(urlAction.url.indexOf('query=users().has(userId') >= 0 ){
              var t = urlAction.url.split(',');
              if(t.length == 2) {
                  t = t[1];
                  t = t.replace(')', '');
                  t = t.replace(/'/g, '');
                  t = t.replace(/"/g, '');
                  id = t.trim();
              }
          }

          if(id != '') {
              var objs = SimLokiDatabaseActions.getFilteredRecordHandler('user', {'id': id });
              return objs;
          }
      }

  };

});
