define({

    "PNP_SETTINGS_TEMPLATE": {
        "version": 1,
        "aaaCredentials": {
          "username": "",
          "password": ""
        },
        "taskTimeOuts": {
          "configTimeOut": 10,
          "imageDownloadTimeOut": 120,
          "generalTimeOut": 20
        },
        "savaMappingList": [],
        "acceptEula": true,
        "defaultProfile": {
          "ipAddresses": [
            "192.168.139.162"
          ],
          "fqdnAddresses": [],
          "port": 443,
          "cert": "-----BEGIN CERTIFICATE-----\nMIIC9zCCAd+gAwIBAgIJANxexwM3R9t4MA0GCSqGSIb3DQEBCwUAMBIxEDAOBgNV\nBAMMB2t1YmUtY2EwHhcNMTgxMDE2MjAyMzU4WhcNMjEwNzEyMjAyMzU4WjASMRAw\nDgYDVQQDDAdrdWJlLWNhMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA\nxoPJORASAivsyHUqSG/lyVUVDe5Xapa6fGEjJeIlW8pM4NW8CvVVFikJdUBOjM4w\nkM5YMWt9RM82fsXhFDKy9rTCdzwblAKA3mjxaqPMxQ0o2IY7GNROWcR9QQWfSODt\nngZ2kfzV1R+AA8wxaxOhHa/1vAuHBCGqt79j18AQDYaHQjMzds14PRFUOgEbXvXC\nH0DT9ETPJGF1TujIY6ZN/0213Am3DEtSQ2pxdgo6AS4odVGLR2BiwB+27EV1gTVV\nviPjoD0rUrAiVebQECmg2J3DBgbE/cSA7sNVxR/zmc5pnB1JAqVIBsCKk9qU7mF9\nNaqP85huJZGu2QwQ1Pi8VwIDAQABo1AwTjAdBgNVHQ4EFgQUQn7s7KUbarzzfiBU\nR4I/isU53LQwHwYDVR0jBBgwFoAUQn7s7KUbarzzfiBUR4I/isU53LQwDAYDVR0T\nBAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEARVu/fR8heEWYPImLlDaJXptDXsa5\n0+Sowx7nem0dtsndGd8ks0r3nesbhpcTU1cj9l+k7PB47RV+oH77abcG9nUc14Og\njp+IXLKGeMyPPimw7hmVX8FE7S6JIS9TH0hADyS1sr4VpI5+pU+ym1cM3+ZyfotQ\nG6DzO690w7e6d615wUSfCHFwwr9cKlLGQ7msWx/3bIM6Mt+7VNg16wdSFwtGuu5q\nqIMVm3b7oR2c8FO1Zg0hogZFVt46MgV1l9SfwtKFPjqI0EkWoS8Gp/xi+JQgKlTQ\nsyIGV9pBuLs1WvEUaiG59YPU7YFQWl/wmuXX1JzoXMW7xfVUagfHJxJ0wQ==\n-----END CERTIFICATE-----",
          "proxy": false
        }
    }
});