define({
  "Template":
  {
  "version": "1.0",
  "response": [
  {
    "id": "z9HE0GgB05goupzQJbVs",
    "name": "connected_misbehaving_dual_band_capable_client_preferring_2_4ghz",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entityName": "Unknown",
    "entity": "B8:27:EB:A2:2B:F1",
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "MEDIUM",
    "summary": "Dual Band capable client prefers 2.4 GHz over 5 GHz",
    "scope": "LOCALIZED",
    "priority": "P4",
    "rootCause": "client_poor_band_selection",
    "timestamp": 1549690561905,
    "description": "This dual band capable client is consistently chooses to connect to 2.4 GHz radio even though a 5 GHz radio is available, which can provide a better experience. This client is connected to SSID \"SSID@ToChange\" on Band@ToChange GHz radio on AP \"AP@ToChange\" in Unknown. The AP is connected to WLC \"Wlc@ToChange\".",
    "suggestions": [
      {
        "message": "Update the client driver to the latest version. Typically, older drivers tend to prefer 2.4 GHz, while newer drivers tend to prefer 5 GHz."
      },
      {
        "message": "Consider enabling band select on the WLC 'Wlc@ToChange' unless you have real time traffic (voice or video), or have a high client density environment."
      },
      {
        "message": "If 5 GHz option is available on the client software, configure 5 GHz as the preferred band frequency."
      },
      {
        "message": "Consider making the SSID 'SSID@ToChange', a 5 GHz only WLAN."
      }
    ],
    "additionalParams": [
      {
        "key": "hostName",
        "value": "nam-raspberry90"
      },
      {
        "key": "groupId",
        "value": "nongroup"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "ssid",
        "value": "Scalability"
      },
      {
        "key": "apName",
        "value": "Icap-row11-rack16"
      },
      {
        "key": "frequency",
        "value": "2.4"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "Wireless"
      },
      {
        "key": "rootCause",
        "value": "client_poor_band_selection"
      },
      {
        "key": "hostOSType",
        "value": "Linux-Workstation"
      },
      {
        "key": "rssi",
        "value": "-45"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "entityId",
        "value": "B8:27:EB:A2:2B:F1"
      },
      {
        "key": "apMac",
        "value": "70:69:5A:51:58:60"
      },
      {
        "key": "priority",
        "value": "P4"
      },
      {
        "key": "wlcName",
        "value": "namlab-3504-215"
      },
      {
        "key": "osCategory",
        "value": "Unknown"
      },
      {
        "key": "deviceCategory",
        "value": "Unclassified"
      },
      {
        "key": "siteId",
        "value": "79284156-3bd0-4c10-ac36-23ff5336acce"
      },
      {
        "key": "_id",
        "value": "B8:27:EB:A2:2B:F1"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2019-02-09T05:36:01.905+0000"
      },
      {
        "key": "macAddr",
        "value": "B8:27:EB:A2:2B:F1"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "San Jose/SJC04/Lab252"
  }
 ]
}
})