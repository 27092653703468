define({
  "Template": {
  "version": "1.0",
  "timeRange": {
    "startTimestamp": 1537405200000,
    "endTimestamp": 1537407001000
  },
  "response": [

  ],
  "count": {
    "wired": 0,
    "wireless": 5,
    "totalCount": 5,
    "buildingCount": 1,
    "floorCount": 1,
    "topAPsCount": {
      "AMS-AP3802-14": 6,
      "LA1-AP3802-11": 5,
      "LA1-AP4800-1": 1
    },
    "topRadiosCount": {
      "2.4GHz": 11,
      "5.0GHz": 1
    },
    "topSSIDsCount": {
      "LA-Corporate1": 6,
      "AMS-Corporate1": 6
    },
    "topOSsCount": {
      "": 12
    },
    "topLocationsCount": {
      "Global/North America/USA/California/San Jose": 5
    },
    "topDeviceTypeCount": {
      "": 12
    }
  },
  "impactedLocations": [
    {
      "siteId": "c773bc73-1832-43a2-b27a-46e99959852c",
      "siteType": "FLOOR",
      "siteQualifiedName": "Global/North America/USA/California/San Jose",
      "area": "Global/North America/USA/California/San Jose",
      "building": "SJC01",
      "floor": "Flr-SJC1-1",
      "clientCount": 1,
      "apsImpacted": [
        "04:DA:D2:91:F6:C0"
      ],
      "timestamp": 1537406758614
    }
  ]
},

"Host_Template":{
      "id": "AWX0l0nAWD9O_0nZUeoU",
      "hostType": "WIRELESS",
      "hostName": "Grace.Smith-iPad",
      "deviceCategory": "",
      "osCategory": "",
      "apMac": "38:0E:4D:65:56:20",
      "apName": "AMS-AP3802-14",
      "wlcName": "LA1-WLC5520-1",
      "aaaServerIp": "192.168.139.168",
      "hostOs": "Apple-iPad",
      "ssid": "LA-Corporate3",
      "vlanId": "Unknown",
      "connectedInterface": "Unknown",
      "macAddress": "6C:19:C0:BD:87:C9",
      "frequency": "2.4GHz",
      "failedAttempts": 3,
      "location": {
        "siteId": "f4fa7637-85d5-4301-9336-5c3d81c7c058",
        "siteType": "FLOOR",
        "siteQualifiedName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "area": "Global/North America/USA/California/San Jose",
        "building": "SJC01",
        "floor": "Flr-SJC1-1",
        "clientCount": 1,
        "apsImpacted": [
          "04:DA:D2:91:F6:C0"
        ],
        "timestamp": 1537406758614
      },
      "timestamp": 1537406758614,
      "rssi": -59
},
"ImpactedApsTemplate":{
  "version": "1.0",
  "timeRange": {
    "startTimestamp": 1612906915367,
    "endTimestamp": 1612908716367
  },
  "count": null,
  "impactedLocations": [
    {
      "siteId": "646a58ca-55ed-47ed-b51d-fae01b08a19e",
      "siteType": "FLOOR",
      "siteQualifiedName": "Global/San Jose/Building 24/Floor 1",
      "area": "Global/San Jose",
      "building": "Building 24",
      "floor": "Floor 1",
      "clientCount": 0,
      "apsImpacted": [
        "70:B3:17:85:51:C0"
      ],
      "timestamp": 0
    }
  ]
}
})
