define({
    "Assurance_NetworkDevice_Detail_Template": {
        id:"",
        label: "NetworkDevice",
        timestamp:"2017-10-14T10:26:58.392+0000",
        isDeleted:false,
        type:"vertex",
        properties:{
           nwDeviceRole:[{"value":""}],
           lifeStart:[{"value":1507976754248}],
           managementIpAddr:[{"value":""}],
           nwDeviceFamily:[{"value":""}],
           macAddress:[{"value":""}],
           nwDeviceName:[{"value":""}],
           platformId:[{"value":""}],
           softwareVersion:[{"value":""}],
           nwDeviceId:[{"value":""}],
           nwDeviceType:[{"value":""}],
           serialNumber:[{"value":""}],
           subMode:[{value: "None"}],
           mode:[{value: "local"}],
           upTime:[{value: "1524825371"}],
           HALastResetReason:[{value: "Planned Reset"}],
           HAPrimaryPowerStatus:[{value: "Present, OK"}],
           redundancyMode:[{value: "Disabled"}],
           redundancyUnit:[{value: "Primary"}],
           redundancyPeerState:[{value: "N/A"}],
           redundancyState:[{value: "ACTIVE"}],
           HASysUpTime:[{value: "5432792"}],
           HASecondaryPowerStatus:[{value: "Absent/Failed"}],
           resetReason:[{value: "Discovery Request"}],
           opState:[{value: "4"}],
           ringStatus: [{value: false}],
           stackType: [{value: "NA"}], //NA STACK
           haStatus: [{value: "Non-redundant"}], //sso
           connectedTime:[{value: "1532030528"}],
           communicationState: [{"value": "REACHABLE"}],
           collectionStatus: [{"value": "SUCCESS"}],
           deviceSeries: [{"value": "Cisco Catalyst 9300 Series Switches"}],
           osType:[{"value": "IOS-XE"}],
          opState: [{value: "4"}],
          upTime: [{value: "1614738398"}],
          adminState: [{value: "1"}],
          connectivityStatus: [{value: 100}],
          apType: [{value: "Standard"}],
          connectedTime: [{value: "1615203733"}],
          flexGroup: [{value: "default-flex-profile"}]
          }

    },
    "NetworkDevice_SysUpTime_Template":
    [{"sysUptime":["32 days, 18:40:35"],"~pmtimestamp":1559125779468,"~label":"NetworkDeviceMetric","id":"nwdMetric_d67b62cd-bc57-43b0-b3e1-5ae7a8a9835b"}],

    "deviceInfoTemplate":
    {
      "managementIpAddr": "10.16.51.137",
      "communicationState": "REACHABLE",
      "opState": "4",
      "mode": "local",
      "resetReason": "Discovery Request",
      "nwDeviceRole": "ACCESS",
      "protocol": "4",
      "adminState": "1",
      "ringStatus": false,
      "connectedTime": "1588360229",
      "stackType": "NA",
      "icapCapability": "142",
      "subMode": "WIPS",
      "nwDeviceType": "Cisco Catalyst 9115AXE Unified Access Point",
      "serialNumber": "FJC2336103Z",
      "nwDeviceName": "AP7069.5A76.7FD4",
      "platformId": "C9115AXE-B",
      "nwDeviceId": "",
      "upTime": "1588323969",
      "nwDeviceFamily": "Unified AP",
      "macAddress": "2C:4F:52:41:61:60",
      "collectionStatus": "Managed",
      "deviceSeries": "Cisco Catalyst 9115AXE Series Unified Access Points",
      "lastBootTime": 0.0,
      "softwareVersion": "17.2.1.11",
      "connectivityStatus": 100.0,
      "linkStatus": "up",
      "frequency":"2.4",
      
      "apInterfaceName": "GigabitEthernet0",
      "apType": "Standard",
      "connectedWlcName": "SJC23-C9800-01.ibng.local",
      "connectedWlcUuid": "2a00ed5b-f73d-4621-8319-4e82e01afdff",
      "device": "C9300-Edge01.ibng.local",
      "deviceGroupHierarchyId": "/38db5860-d15d-4fac-a3a3-bb56e47de15b/3e2bd891-53f1-4639-ab39-1817dc4b6dec/",
      "ethernetMac": "70:69:5A:76:7F:D4",
      "flexGroup": "default-flex-profile",
      "floorId": "38d14cd6-ba69-41fd-a5bf-18b4c250e2a5",
      "homeApEnabled": "false",
      "ip_addr_managementIpAddr": "10.16.51.203",
      "ledFlashEnabled": true,
      "ledFlashSeconds": 0,
      "location": "Global/North_America/United_States/California/San_Jose/Bldg23/DEMO-SUITE-01",
      "maintenanceMode": false,
      "nwDeviceIdApCdp": "b2bc1973-692b-430d-9661-d819b006a3ad",
      "policyTagName": "default-policy-tag",
      "port": "GigabitEthernet1/0/12",
      "powerMode": "HIGH_POWER",
      "powerStatus": "PoE / Full Power",
      "powerType": "PoE+",
      "radioMode": "1",
      "regulatoryDomain": "US  - United States",
      "rfTagName": "default-rf-tag",
      "siteHierarchy": "Global/North_America/United_States/California/San_Jose/Bldg-23/DEMO-SUITE-02",
      "siteHierarchyGraphId": "/00d38152-9d4f-41f9-a8e9-af3f811d4920/3d0243ca-266f-4a31-8a4e-3c672bb57d14/185634f7-b7fd-4720-b7c9-db62ffe87c0c/830e5d99-4709-4e4f-be34-46591612677b/f2173628-f936-4f1a-bc03-a97381de7a6c/0b8957dd-7eb7-486b-a363-5db4817b8c47/38d14cd6-ba69-41fd-a5bf-18b4c250e2a5/",
      "siteTagName": "default-site-tag",
      "slot": "0",
      "switchIpAddress": "10.16.50.9",
      "switchPlatformId": "C9300-24U",
      "tagIdList": []
    },
    "apRfDetails":
    [{"slotId":"1","radioMode":"1","channel":"116","channelWidth":"40","band":"5",
       "rfProfile":"Typical_Client_Density_rf_5gh","dfsRadarSupression":null,"statsOnServingRadio":null,"statsOnCiscoIp":null,
       "statsTriggered":null},{"slotId":"0","radioMode":"1","channel":"1","channelWidth":"20","band":"2.4","rfProfile":"Typical_Client_Density_rf_24gh",
       "dfsRadarSupression":null,"statsOnServingRadio":null,"statsOnCiscoIp":null,"statsTriggered":null}],
    "StackDetails":
    {
        "switchNumber": "2",
        "switchNumberPeer": "3",
        "serialNumber": "FCW2138G00Y",
        "productID": "C9300-24UX",
        "macAddress": "f8:b7:e2:4c:f0:80",
        "role": "MASTER",
        "state": "READY",
        "priority": 9,
        "upTime": null,
        "links": [
              {
                "sourcePort": "2",
                "destinationPort": "1",
                "stackPortType": "BACKSTACK",
                "isDADport": false
              }
        ]
    },
    "infotopDetails":
    {
      "healthScore": null,
      "deviceModel": "C9300-24P",
      "ipAddress": "172.25.33.60",
      "location": "Global/RAJ-SJC20-nyq1",
      "softwareVersion": "16.12.3sprd3",
      "role": "ACCESS",
      "stackStatus": {
        "mode": "NORMAL",                     //"NORMAL",rinsStatus:"false"
        "ringStatus": "false",
        "domain": null,
        "countMembers": 0
      },
      "haStatus": "Non-redundant", //Non-redundant
      "upTime": "20:39:52.39"
    },
    "Assurance_NetworkDevice_New_Detail_Template":
      {
        "id": "f539702b-1d37-4d87-88e2-a9985f9367a1",
        "label": "NetworkDevice",
        "timestamp": "2020-05-28T12:53:19.216+0000",
        "isDeleted": false,
        "type": "vertex",
        "properties": {
          "managementIpAddr": [{"value": "172.25.33.60"}],
          "haStatus": [{"value": "Non-redundant"}],
          "serialNumber": [{"value": "FOC2207Z08E"}],
          "communicationState": [{"value": "REACHABLE"}],
          "nwDeviceName": [{"value": "stack-nyq1"}],
          "platformId": [{"value": "C9300-24P"}],
          "nwDeviceId": [{"value": "f539702b-1d37-4d87-88e2-a9985f9367a1"}],
          "nwDeviceRole": [{"value": "ACCESS"}],
          "nwDeviceFamily": [{"value": "Switches and Hubs"}],
          "macAddress": [{"value": "EC:1D:8B:9A:54:00"}],
          "collectionStatus": [{"value": "SUCCESS"}],
          "deviceSeries": [{"value": "Cisco Catalyst 9300 Series Switches"}],
          "osType": [{"value": "IOS-XE"}],
          "ringStatus": [{"value": true}],
          "lastBootTime": [{"value": 1597860129202}],
          "stackType": [{"value": "NA"}],
          "softwareVersion": [{"value": "16.12.3sprd3"}],
          "nwDeviceType": [{"value": "Cisco Catalyst 9300 Switch"}],
          "HAPrimaryPowerStatus": [{"value": "Present, Ok"}],
          "redundancyMode": [{"value": "Disabled"}],
          "clientCount": [{"value": "0"}],
          "haStatus": [{"value": "Non-redundant"}],
          "redundancyPeerState": [{"value": "DISABLED"}],
          "redundancyState": [{"value": "ACTIVE"}],
          "HASecondaryPowerStatus": [{"value": "NA"}],
          "HALastResetReason":[{"value": "Reload Command"}],
          "policyTagName": [{value: "default-policy-tag"}],
          "rfTagName": [{value: "default-rf-tag"}],
          "siteTagName": [{value: "default-site-tag"}],
          "opState": [{value: "4"}],
          "upTime": [{value: "1614738398"}],
          "adminState": [{value: "1"}],
          "connectivityStatus": [{value: 100}],
          "apType": [{value: "Standard"}],
          "flexGroup": [{value: "default-flex-profile"}],
          "icapCapability": [{value: "158"}],
          "connectedTime": [{value: "1615275719"}],
          "mode": [{"value": "local"}],
          "resetReason": [{"value": "Reboot Crash"}],
          "protocol":[{"value":"4"}],
          "ip_addr_managementIpAddr":[{"value":"10.70.7.91"}],
          "subMode":[{"value":"WIPS"}],
          "location":[{"value":"default location"}],
          "homeApEnabled": [{"value": "false"}],
          "ledFlashEnabled": [{"value": "true"}],
          "ledFlashSeconds": [{"value": "0"}],
          "maintenanceMode":[{"value": false}],
          "powerMode": [{"value": "HIGH_POWER"}],
          "powerType":[{"value": "PoE+"}],
          "regulatoryDomain":[{"value": "US  - United States"}],
          "tagIdList":[{"value": []}],
          "deviceGroupHierarchyId":[{"value": "/38db5860-d15d-4fac-a3a3-bb56e47de15b/3e2bd891-53f1-4639-ab39-1817dc4b6dec/"}],
          "ethernetMac": [{"value": "70:69:5A:76:7F:D4"}],
          "siteHierarchyGraphId":[{"value": "/00d38152-9d4f-41f9-a8e9-af3f811d4920/3d0243ca-266f-4a31-8a4e-3c672bb57d14/185634f7-b7fd-4720-b7c9-db62ffe87c0c/830e5d99-4709-4e4f-be34-46591612677b/f2173628-f936-4f1a-bc03-a97381de7a6c/0b8957dd-7eb7-486b-a363-5db4817b8c47/38d14cd6-ba69-41fd-a5bf-18b4c250e2a5/"}]
        }
      },
      "AssuranceStack_Stack_Links":[
        {
          "sourcePort": "TenGigabitEthernet1/0/16",
          "destinationPort": "TenGigabitEthernet2/0/16",
          "stackPortType": "SVL",
          "isDADport": false
        },
        {
          "sourcePort": "TenGigabitEthernet1/0/13",
          "destinationPort": null,
          "stackPortType": "DAD",
          "isDADport": true
        },
        {
          "sourcePort": "TenGigabitEthernet1/0/15",
          "destinationPort": "TenGigabitEthernet2/0/15",
          "stackPortType": "SVL",
          "isDADport": false
        }
      ]



});
