define({
  "Template": {
    "issueCount": 0,
    "failureCountByTagList": [
      {
        "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "count": 342
      },
   /*   {
        "tagName": "Global/San Jose/Campus_Bldg_23/DNA-DEMO-SUITE2",
        "count": 378
      } */
    ]
  }
});