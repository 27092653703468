define({
    "Connectivty_Domain_Template": {
        id: "",
        instanceId: 1,
        displayName: 1,
        deployPending: "NONE",
        instanceVersion: 1,
        deployed: false,
        isStale: false,
        lastUpdateTime: 1492605753823,
        name: "",
        namespace: "",
        provisioningState: "DEFINED",
        resourceVersion: 0,
        type: "ConnectivityDomain",
        version: 3,
        cfsChangeInfo: [],
        domainType: "FABRIC_LAN",
        enableMonitoring: false,
        isDefault: false,
        deviceInfo: [],
        multicastInfo: [],
        virtualNetwork: [],
        authenticationProfileId: '', //1.3.3 uses fabricAuthenticationProfile
        fabricAuthenticationProfile: '',
        siteSpecificDomain: [],
        transitMetaData:[],
        wirelessMulticastFeature:false,
        "customProvisions": [],
        "extranetPolicy": [],
        "isIpv6Compatible": true,
        "isNativeMulticastEnabled": false,
        "isReprovisionRequired": false,
        "isSeeded": false,
        "l2FloodingIndex": -1,
        "migrationStatus": [],
        "targetIdList": [],
        "authenticationProfiles": [],
        "fabricSiteBorderInfo": [],
        "fabricZone": [],
        "hasFabricZones": false,
        "inheritedDeviceInfo": [],
        "instanceCreatedOn": 1641862406347,
        "instanceUpdatedOn": 1641862406347,
        "isBorderConvergenceEnabled": false,
        "isDefault": false,
        "isSummarizationOnBorder": false,
        "remoteDeviceInfo": []
    },

     "Site_Specific_Domain": [
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC22_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC22_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC22_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC23_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC23_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC23_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
	  {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Francisco/SFO11_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "a2663895-0399-6cd0-8e79-4e3c0ce51292",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Francisco/SFO11_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Francisco/SFO11_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "c1e8e7dd-8e9a-7e29-569f-5c3399b92a21",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Francisco/SFO12_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "a2663895-0399-6cd0-8e79-4e3c0ce51292",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Francisco/SFO12_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Francisco/SFO12_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "c1e8e7dd-8e9a-7e29-569f-5c3399b92a21",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Francisco/SFO13_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "a2663895-0399-6cd0-8e79-4e3c0ce51292",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Francisco/SFO13_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Francisco/SFO13_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "c1e8e7dd-8e9a-7e29-569f-5c3399b92a21",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Francisco/SFO15_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "a2663895-0399-6cd0-8e79-4e3c0ce51292",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Francisco/SFO15_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Francisco/SFO15_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "c1e8e7dd-8e9a-7e29-569f-5c3399b92a21",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC04_SanJose_Fabric",
        "description": "",
        "domainType": "FABRIC_SITE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC04_SanJose_Fabric",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC04_SanJose_Fabric",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      },
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
        "description": "",
        "domainType": "FABRIC_ZONE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true,
        "parentFabric":"Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric"
      }
    ],

    "AUTHENTICATION_PROFILE_TEMPLATE": {
        "id": "a676cff3-77ea-4f0e-af80-d985dac920e4",
        "instanceId": 9646637,
        "instanceCreatedOn": 1634695092636,
			  "instanceUpdatedOn": 1634695092636,
        "bpduGuardEnabled": true,
        "profileUuid": "63ab7dfc-f208-4066-b15a-838e14f994bf",
        "displayName": "18123b3b[Low Impact,63ab7dfc-f208-4066-b15a-838e14f994bf]",
        "instanceVersion": 0,
        "deploymentMode": "OPEN",
        "dot1xToMabFallbackTimeout": "21",
        "hostMode": "multi_auth",
        "name": "Low Impact",
        "order": "dot1x",
        "priority": "mab",
        "profileUuid": "63ab7dfc-f208-4066-b15a-838e14f994bf",
        "profileVersion": 1,
        "type": "WIRED_EASYCONNECT",
        "wakeOnLan": false,
        "webauthBypassMap": [],
        "preAuthAcl": {
          "id": "1c14eed0-52b2-4763-aace-d82986408cb7",
          "instanceId": 9645636,
          "displayName": "9645636",
          "instanceVersion": 1,
          "aclDescription": "",
          "aclName": "IPV4_PRE_AUTH_ACL",
          "contract": [
            {
              "id": "0cbd7691-479a-4c59-90f1-e991d92d17ca",
              "action": "PERMIT",
              "port": "bootpc",
              "protocol": "udp"
            },
            {
              "id": "df648eec-bbf6-4580-9c20-717c6533832a",
              "action": "PERMIT",
              "port": "domain",
              "protocol": "udp"
            }
          ],
          "defaultAction": "DENY",
          "enabled": true
        }
    },

    "Assurance_Domain_Filter_Template":
                  {
                    "id": "",
                    "label": "MemberOf",
                    "type": "edge",
                    "inVLabel": "DeviceGroup",
                    "outVLabel": "DeviceGroup",
                    "timestamp": "",
                    "isDeleted": false,
                    "inV": "",
                    "outV": ""
                  },
    "Fabric_SiteSummary" :
                          [
                            {
                              "instanceId": 0,
                              "displayName": "0",
                              "instanceTenantId": "5b1878b3d80d23008a11134c",
                              "deployPending": "NONE",
                              "instanceVersion": 0,
                              "fabricSiteSummary": [
                                {
                                  "instanceId": 0,
                                  "displayName": "0",
                                  "instanceTenantId": "5b1878b3d80d23008a11134c",
                                  "deployPending": "NONE",
                                  "instanceVersion": 0,
                                  "fabricSiteName": "Global/Westcoast/San_Jose/SJC04_BayArea",
                                  "fabricSiteUuid": "31b70aec-646b-49e3-9361-8dd7d7170f1d",
                                  "siteId": "fb747af4-364d-4372-a04c-b04c5013ec75",
                                  "siteName": "SJC04"
                                }
                              ]
                            }
                          ],
     "Fabric_DomainSummary" :
                          [
                            {
                              "instanceId": 0,
                              "displayName": "0",
                              "instanceVersion": 0,
                              "fabricDomainSummary": [
                                {
                                  "instanceId": 0,
                                  "displayName": "0",
                                  "fabricBorderCount":0,
                                  "fabricDomainId": "81ca7d4c-c579-4830-a5aa-27e0e8331405",
                                  "fabricEdgeCount": 0,
                                  "fabricEndpointProxyCount": 0,
                                  "fabricMapServerCount": 0,
                                  "fabricSiteCount": 0,
                                  "fabricSubtendedCount": 0,
                                  "fabricTotalDeviceCount": 0,
                                  "fabricTransitMapServerCount": 0,
                                  "isReprovisionRequired": false
                                }
                              ]
                            }
                          ],
    
    "Fabric_DomainSummary_1_3_3" :[
      {
        "instanceId": 0,
        "displayName": "0",
        "instanceVersion": 0,
        "summaries": [
          {
            "id": "578acb39-bc94-4649-9156-75980e081cf2",
            "instanceId": 16473,
            "displayName": "16473",
            "instanceVersion": 0,
            "domainType": "FABRIC_LAN",
            "isReprovisionRequired": false,
            "name": "Default LAN Fabric",
            "borderCount": 0,
            "deviceCount": 0,
            "edgeCount": 0,
            "endpointProxyCount": 0,
            "fabricSiteCount": 0,
            "mapServerCount": 0,
            "subtendedCount": 0,
            "siteConnectivityDomainSummary": []
          }
        ]
      }
    ],

    "Fabric_SiteConnectivityDomainSummary_Template_1_3_3" :{
      "id": "fc084d78-128d-4585-900c-4d966a6563fc",
      "instanceId": 1250261,
      "displayName": "1250261",
      "instanceVersion": 0,
      "domainType": "FABRIC_SITE",
      "isReprovisionRequired": false,
      "name": "Global/San_Jose_San_Jose_Fabric_Dummy",
      "siteId": "75367b98-97bd-42a5-8e69-542f998b3fbf",
      "siteName": "San Jose",
      "siteNameHierarchy": "Global/San Jose",
      "fabricSignificantDeviceInfoSummary": [],
      "transitConnectivityDomainSummary": []
    },
                          
    "Device_Info_Lan_FabricSummary" :   [{
                                          "instanceId": 0,
                                          "displayName": "0",
                                          "instanceTenantId": "5b1878b3d80d23008a11134c",
                                          "deployPending": "NONE",
                                          "instanceVersion": 0,
                                          "deviceInfoLanFabricSummary": [
                                            {
                                              "instanceId": 0,
                                              "displayName": "0",
                                              "instanceTenantId": "5b1878b3d80d23008a11134c",
                                              "deployPending": "NONE",
                                              "instanceVersion": 0,
                                              "deviceInfoUuid": "09d86ed9-ccb7-4a77-ba40-978ea82d199a",
                                              "deviceIpAddress": "10.4.30.10",
                                              "deviceName": "p2-border1.sda-pod2.local",
                                              "fabricSiteName": "Global/Westcoast/San_Jose/SJC04_BayArea",
                                              "networkDeviceId": "5ab25a07-72f0-47f4-a3fe-436a550c7e4d",
                                              "roles": [

                                              ],
                                              "siteId": "fb747af4-364d-4372-a04c-b04c5013ec75",
                                              "transitNetworks": []
                                            }
                                          ]
                                        }],


    "Device_Info_Summary" :  [
                                {
                                  "instanceId": 0,
                                  "displayName": "0",
                                  "instanceTenantId": "5b1878b3d80d23008a11134c",
                                  "deployPending": "NONE",
                                  "instanceVersion": 0,
                                  "deviceInfoSummary": [
                                    {
                                      "instanceId": 0,
                                      "displayName": "0",
                                      "instanceTenantId": "5b1878b3d80d23008a11134c",
                                      "deployPending": "NONE",
                                      "instanceVersion": 0,
                                      "deviceName": "WLC-1",
                                      "deviceinfoUuid": "de6d8521-4902-4ba7-8941-989bd99e3289",
                                      "networkDeviceId": "184773cc-b4a0-4857-afcd-ea4ce1269595",
                                      "roles": [
                                        "ENDPOINT_PROXY"
                                      ]
                                    }
                                  ]
                                }
                              ],
    "Virtual_NetworkContext_Info_Summary" : [
                                                {
                                                "instanceId": 0,
                                                "displayName": "0",
                                                "instanceVersion": 0,
                                                "virtualNetworkContextSummary": [
                                                    {
                                                      "instanceId": 0,
                                                      "displayName": "0",
                                                      "instanceVersion": 0,
                                                      "virtualNetworkContextName": "DEFAULT_VN",
                                                      "virtualNetworkContextType": "DEFAULT",
                                                      "virtualNetworkContextUuid": "1f20d936-d45e-453f-ad90-1295fac6316e",                                                      
                                                      "extranetPolicies": [],
                                                      "hasExtranetPolicy": false,
                                                      "scalableGroupListSize": 0,
                                                      "scalableGroups": [],
                                                      "siteInfo": [],
                                                      "vncSummaryPoolInfo": []
                                                    }
                                                ]
                                                }
                                             ],
    "CF_DeviceInfoSummary" : [
      {
        "instanceId": 0,
        "displayName": "0",
        "instanceVersion": 0,
        "summaries": [
          {
            "id": "6358a4a6-40de-48f4-bc22-9697a6287cdf",
            "instanceId": 7805810,
            "displayName": "f5ba231e[45f7f1b5-0c7e-40ad-ae16-e889a1522f61]",
            "instanceVersion": 0,
            "deviceIpAddress": "10.4.200.56",//if it is really reqd, we shall update usinf network device table
            "deviceName": "A-9300b.sda-lab.local",
            "networkDeviceId": "45f7f1b5-0c7e-40ad-ae16-e889a1522f61",
            "roles": [
              "EDGENODE"
            ]
          }
        ]
      }
    ],
    "VirtualNetworkContextSummary_2_2_3":{
      "id": "e264e30d-418a-4cdc-b90a-3fd17f36e3f5",
      "response": [
        {
          "instanceId": 0,
          "displayName": "0",
          "instanceVersion": 0,
          "summaries": [
            {
              "id": "5d316729-007c-4555-a330-9941e6794390",
              "instanceId": 10014,
              "displayName": "10014",
              "instanceVersion": 0,
              "hasExtranetPolicy": false,
              "l3Instance": 4098,
              "multicastSiteCount": 0,
              "name": "DEFAULT_VN",
              "segmentCount": 0,
              "siteCount": 0,
              "siteList": [],
              "virtualNetworkContextType": "DEFAULT",
              "zoneCount": 0,
              "zoneList": [],
              "transitConnectivityDomainSummary": [],
              "vncSummaryPoolInfo": [],
              "iptransitHandoffSummary": [],
              "vnStatus": "ACTIVE"
            }
          ]
        }
      ],
      "version": "1.0"
    },

    /* "ZONE": [
      {
        "type": "ConnectivityDomain",
        "name": "Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
        "description": "",
        "domainType": "FABRIC_ZONE",
        "siteId": "fe534483-ce58-b84c-c633-7339c56d3877",
        "virtualNetwork": [
          {
            "name": "DEFAULT_VN-Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
            "isDefault": true,
            "isInfra": false,
            "virtualNetworkContextId": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
            "type": "VirtualNetwork"
          },
          {
            "name": "INFRA_VN-Global/North_America/USA/California/San_Jose/SJC01/Flr-SJC1-1",
            "isDefault": false,
            "isInfra": true,
            "virtualNetworkContextId": "3e20abad-6bb3-79f7-e27a-4edd1944d3d5",
            "type": "VirtualNetwork"
          }
        ],
        "isDefault": false,
        "enableMonitoring": true
      }
    ] */

});
