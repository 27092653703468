define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/siteAnalytics/SiteAnalyticsTemplate'
], function (UtilityFunctions, SiteAnalyticsTemplate) {
    return {
        init: function () {
        },
        getconfigurations: function (urlAction) {
            if(urlAction.service.indexOf('assurance') >-1 && urlAction.service.indexOf('configurations') >-1){
                var resp = {"version": "2.0","data":[] ,"page": null,"scope": null,"instrumentation": null,"message": null}
                var tempData = JSON.parse(JSON.stringify(SiteAnalyticsTemplate.config_slaThreshold_entityType_SITE_PCT));
                for(var i=0;i<tempData.length;i++){
                    if(tempData[i].slaType == 'SITE_PCT'){
                        var t =UtilityFunctions.getTimeStamp()
                        tempData[i].values.lastUpdateTimestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(t) 
                    }
                }
                resp.data =tempData;
               return resp;
            }
        }
    }
});