define([

], function () {
    return {
        init: function () {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method){
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction){
        //need to write code here
        return {"version":"1.0"}
    }
    function processPostRequest(urlAction){

    }
    function processDeleteRequest(urlAction){

    }
});