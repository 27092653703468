define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomain',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/parseUrl',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/SimDataGenerationInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimPolicyAnalyticsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimPolicyEndpointAnalytics',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/SimISESyncher'

], function (SimScheduledCaptureData, SimLokiDatabaseActions, UtilityFunctions, SimTaskDataGenerator, SimConnectivityDomain, parseUrl, SimDataGenerationInit,
    SimPolicyAnalyticsData, SimPolicyEndpointAnalytics, ISESyncher) {

    var defLocation = "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1";
    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction, paramNamesAndValues) {           
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction, paramNamesAndValues);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction, paramNamesAndValues);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction, paramNamesAndValues);
                    break;

                default:
                    break;
            }

            return data;
        },

        processFabricRequest: function(urlAction, paramNamesAndValues) {
            let resp;
            resp = SimConnectivityDomain.processFabricRequest(urlAction, paramNamesAndValues);
            return resp;
        }
    };

    function processGetRequest(urlAction) {
        //Transit PubSub/List changes - start
        if(urlAction.service.indexOf('lazy-load') > -1) {
            var data = SimConnectivityDomain.lazyLoad(urlAction);
            return data;
        }
        //Transit PubSub/List changes - end
        if (urlAction.service.indexOf('magellan') > -1 || urlAction.service.indexOf('pxgridsecuritygroup')> -1 || urlAction.url.includes('appstacks')) {
            var data = SimPolicyAnalyticsData.processGet(urlAction)
            return data
        }
        if (urlAction.service.indexOf('anomaly') >= 0) {
            var tObj = [];
            tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {
                '$and': [
                    { "name": "anomaly" }, { "siteId": urlAction.filter['siteId'] }, { "isGlobal": urlAction.filter['isGlobal'] },
                    { "isEnable": urlAction.filter['isEnable'] }, { "isDisable": urlAction.filter['isDisable'] },
                    { "isClientAnomalyDetectionPacketTrace": urlAction.filter['isClientAnomalyDetectionPacketTrace'] }]
            });
            return tObj;
        } else if (urlAction.service.indexOf('ap-statistic') >= 0) {
            var tObj = [];
            tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', { '$and': [{ "name": "ap-statistic" }, { "siteId": urlAction.filter['siteId'] }] });
            return tObj;
        }
        // Added by Karan for StealthWatch
        else if (urlAction.service.indexOf('eta') >= 0) {
            var tObj = [];
            return tObj;
        }
        //airsense/spectrum:Ram
        if (urlAction.service.indexOf('airsense') > -1
            && urlAction.service.indexOf('spectrum') > -1
        ) {
            return { "taskId": "77f1c62d-fef9-4e26-a896-aa7dea786d2b", "url": "/api/v1/task/77f1c62d-fef9-4e26-a896-aa7dea786d2b" }
        } else if (urlAction.service.indexOf('customer-facing-service') > -1) {
            var arrQueryValue = parseUrl.getUrlVars(urlAction.url);
            if (urlAction.action.count == true) {
                var result = SimConnectivityDomain.getCfsCount(urlAction.url, arrQueryValue);
                return result;
            }
            else {
                var result = SimConnectivityDomain.getCfsDetails(urlAction.url, arrQueryValue);
                if (urlAction.filter.count && urlAction.filter.roles) {
                    var roleCount = 0;
                    if (result[0].summaries) {
                        var textarray = urlAction.filter.roles.split(',');
                        for (i = 0; i < result[0].summaries.length; i++) {
                            for (j = 0; j < textarray.length; j++) {
                                if (result[0].summaries[i] && result[0].summaries[i]['roles'] == textarray[j]) {
                                    roleCount++;
                                }
                            }
                        }
                    }
                    return [{ "instanceId": 0, "instanceVersion": 0, "count": roleCount, "displayName": "0" }];
                }
                if(urlAction.filter['count'] == 'true' && result.response && result.response.length>0 && result.response[0].summaries) {
                    result.response[0].count = result.response[0].summaries.length;
                }
                return result;
            }
        } else if (urlAction.service.indexOf('device-config-status') > -1) {
            var arrQueryValue = parseUrl.getUrlVars(urlAction.url);
            var filter = urlAction.filter;
            if (urlAction.action.count) {
                var result = SimConnectivityDomain.getDeviceConfigStatusCount(filter);
                return result;
            } else {
                var result = SimConnectivityDomain.getDeviceConfigStatus(filter);
                return result;
            }
        }else if (urlAction.service.indexOf('audit') > -1) {
            if (urlAction.action.count == false) {
                var  result = SimConnectivityDomain.getAudit(urlAction.url);
                return result;
            }else{
                var  result = SimConnectivityDomain.getAuditCount(urlAction.url);
                return result;
            }
        }
        //For Policy-->Group Based Access Control by Karan 1.3.1
        if (urlAction.service.indexOf('aca-controller-service') > -1 && (urlAction.service.indexOf('dnacAuthorDetails') > -1 ||
        urlAction.service.indexOf('details') > -1)) {       //urlAction.service.indexOf('details')-added for policy-virtualNetwork fix in 1.4.0
            var result = SimConnectivityDomain.getACAControllerService()
            return result     
        }else if(urlAction.service.indexOf('aca-controller-service') > -1 && urlAction.service.indexOf('migration') > -1){
            if(urlAction.service.indexOf('status') > -1 && urlAction.filter["__preventCache"] != undefined){
                var result = SimConnectivityDomain.getACAControllerServiceStatus()
                return result
            }else if(urlAction.service.indexOf('log') > -1)  {
                /* var filter_value = urlAction.filter["entityId"] 
                if(filter_value ==1){
                    // var result = SimConnectivityDomain.getGBACConfigurationScalableGroup()
                }else if(filter_value ==2){
                    // var result = SimConnectivityDomain.getGBACConfigurationContracts()
                }else if(filter_value ==3){
                    // var result = SimConnectivityDomain.getGBACConfigurationContracts()
                }return result */
                return {"migrationLogCount":0,"migrationLogList":[]};
            }else if(urlAction.service.indexOf('status') > -1){
                var result = SimConnectivityDomain.getGBACConfigurationStatus()         
                return result
            }
        }else if(urlAction.service.indexOf('aca-controller-service') > -1 && urlAction.service.indexOf('migration') > -1){
            var result = SimConnectivityDomain.getACAControllerServiceStatus()         
            return result
        //below fix for 1.4.0 to load Policy -->VirtualNetwork page by Karan 
        }else if(urlAction.service.indexOf('aca-controller-service') > -1 && urlAction.service.indexOf('getAcaVnSummary') > -1){
            var data = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
            var result = [];
            for (i=0;i < data.length; i++){
                data[i].sgCount = data[i].scalableGroupList.length;
                result.push(data[i])
            }
            return {"vnCount": data.length, "acaVnSummaryList": result}
        }else if(urlAction.service.indexOf('aca-controller-service') > -1 && urlAction.service.indexOf('customview') > -1){
            return []
        }
        // else if(urlAction.service.indexOf('reasoner') > -1){
        //     var result = {"response":["mac_flap_trigger"],"version":"1.0"}        
        //     return result
        // }
        if (urlAction.service.indexOf('dcs') > -1 || urlAction.service.indexOf('endpoint-analytics') > -1 ){
            var data = SimPolicyEndpointAnalytics.processGet(urlAction)
            return data
        }
        // Added this call in Guardian if we send any other response, Save Buttons in Policy's are not seen, only schedule later button can be seen
        if(urlAction.service.indexOf('itsmPackage')>-1 && urlAction.service.indexOf('isEnabled')>-1){
            // console.log('***** Not Allways fixing the call will work, Some Times Breaking the Call Works tooo, So Please dont Fix it *****')
            return "false"
            
        }

        if (urlAction.service.indexOf('platform') > -1 || urlAction.service.indexOf('details') > -1 ){
            return {"multiDnacFeatureEnabled":false,"multiDnacRole":"AUTHOR","acaIseCompatibility":["MULTI_DNAC","MULTI_DNAC_TRANSIT","BASE"],
            "dnacDeployment":"STANDALONE","iseAvailabilityStatus":"AVAILABLE","pxGridNodeStatus":"AVAILABLE",
            "workflow":{"importStatus":"COMPLETED","selectedMode":"ACA","message":"","lastUpdatedTime":"2022-05-20T04:51:11.572Z"},
            "iseVersion":{"releaseVersion":"3.1.0.518","patchVersion":"0"},"requiredIseVersions":[{"releaseVersion":"2.4.0.357","patchVersion":"7"},
            {"releaseVersion":"2.6.0.156","patchVersion":"1"}],"lastUpdateTime":"2022-05-20T14:05:44.433124Z",
            "syncStatusFlag":{"enableRuntimeSync":true,"syncRuntimeInProgress":false},"clusterReadyToPromote":true};
        }
    }
    function processPostRequest(urlAction, paramNamesAndValues) {
        //airsense/spectrum:Ram
        if (urlAction.service.indexOf('airsense') > -1
            && urlAction.service.indexOf('spectrum') > -1
        ) {
            if (urlAction.restPayload[0].isEnable === 'yes') {
                document.cookie = "spectrum-running=true; max-age=600";
                document.cookie = "show-spectrum-graph=true; max-age=600";
            }
            if (urlAction.restPayload[0].isDisable === 'yes') {
                document.cookie = "spectrum-running=false;expires=Thu, 01 Jan 1970 00:00:01 GMT;" //delete show-spectrum cookie;cookie value doesn't matter
                document.cookie = "show-spectrum-graph=true; max-age=300";
            }
            return { "taskId": "77f1c62d-fef9-4e26-a896-aa7dea786d2b", "url": "/api/v1/task/77f1c62d-fef9-4e26-a896-aa7dea786d2b" }
        } else if (urlAction.service.indexOf('airsense') > -1 && urlAction.service.indexOf('ap-statistic') > -1) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var aps = [];
            for (var i = 0; i < urlAction.restPayload.length; i++) {
                var apStatsType = urlAction.restPayload[i].apStatisticsType, apName = urlAction.restPayload[i].apName;
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {
                    '$and':
                        [{ "apStatisticsType": apStatsType }, { 'apName': apName }]
                });
                records[0].namespace = records[0].namespace.replace('a:Disabled', 'a:Enable').replace('op:DELETE', 'op:CREATE');
                records[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.update('scheduled-capture-settings_126', records);
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'hostname': records[0].apName });
                record[0].isAPAutoCapConfigured = true;
                SimLokiDatabaseActions.update('network-device', record);
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        } else if (urlAction.service.indexOf('airsense') > -1 && urlAction.service.indexOf('anomaly') > -1) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var restPayload = urlAction.restPayload[0];
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', { "site": restPayload.siteId });
            records[0].isGlobal = restPayload.isGlobal;
            records[0].isEnable = restPayload.isEnable;
            records[0].isDisable = restPayload.isDisable;
            records[0].isClientAnomalyDetectionPacketTrace = restPayload.isClientAnomalyDetectionPacketTrace;
            records[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
            if (records[0].isClientAnomalyDetectionPacketTrace == 'yes') {
                records[0].namespace = 'f:anomaly adpt:yes s:Global a:Enable op:CREATE';
                var siteId = (getSite(defLocation)).id;
                record = SimLokiDatabaseActions.getFilteredRecordHandler('issue_capture_settings_126', { 'siteId': siteId });
                record[0].isIssueAutoPcktCap = false;
                SimLokiDatabaseActions.update('issue_capture_settings_126', record);
            }
            SimLokiDatabaseActions.update('scheduled-capture-settings_126', records);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        }else if (urlAction.service.indexOf('customer-facing-service') > -1) {
            if(urlAction.service.indexOf('policy') > -1 && (urlAction.service.indexOf("scratchpad") > -1 || urlAction.restPayload.length>1)){
                var result = SimConnectivityDomain.CfsPost(urlAction.url, urlAction.restPayload);
            } else {
                var result = SimConnectivityDomain.CfsPost(urlAction.url, urlAction.restPayload[0]);
            }
            return result;
        }else if (urlAction.service.indexOf('cfs-intent') > -1) {
            if( urlAction.service.indexOf('scalablegroup') > -1 ){
                var result = SimConnectivityDomain.CfsIntentScalableGroup(urlAction.url, urlAction.restPayload);
                return result;
            }else if( urlAction.service.indexOf('contract') > -1 && (urlAction.service.indexOf('access') > -1 || urlAction.service.indexOf('ipaccess') > -1) ){
                var result = SimConnectivityDomain.CfsIntentContractAccess(urlAction.url, urlAction.restPayload);
                return result;
            }else if( urlAction.service.indexOf('policy') > -1 && urlAction.service.indexOf('access') > -1 ){
                var result = SimConnectivityDomain.CfsIntent(urlAction.url, urlAction.restPayload);
                return result;
            } else if( urlAction.service.indexOf('contract') > -1 && urlAction.service.indexOf('copy') > -1 ){
                var result = SimConnectivityDomain.CfsDelete(urlAction.url, urlAction.restPayload);
                return result;
            }else if( urlAction.service.indexOf('policy') > -1 && urlAction.service.indexOf('copy') > -1 ){
                var result = SimConnectivityDomain.CfsIntentCopyPolicy(urlAction.url, urlAction.restPayload);
                return result;
            }else if( urlAction.service.indexOf('policy') > -1 && urlAction.service.indexOf('ipaccess') > -1 ){
                var result = SimConnectivityDomain.CfsPost(urlAction.url, urlAction.restPayload); //need to work on 2.1 Karan
                return result;
                // return [];   
            }else{
                return [];
            }
        }else if (urlAction.service.indexOf('magellan') > -1) {
            var data = SimPolicyAnalyticsData.processPost(urlAction)
            return data
        }else if (urlAction.service.indexOf('dcs') > -1 || urlAction.service.indexOf('endpoint-analytics') > -1) {
            var data = SimPolicyEndpointAnalytics.processPost(urlAction)
            return data
        }else
            return []

    }

    function processPutRequest(urlAction, paramNamesAndValues) {
        if (urlAction.service.indexOf('customer-facing-service') > -1) {
            var result;
            /* FIXME -- ideally restpayload should be send as it is, but lot of code is written which might broke if payload sent as it is
               However it will be good, after 1.3.0 release, we change it to send whole payload as it is..
               Segment update need the array, hence for it below exception..
            */
            if (urlAction.service.indexOf('Segment') >= 0) {
                result = SimConnectivityDomain.CfsPut(urlAction.url, urlAction.restPayload);
            } else {
                result = SimConnectivityDomain.CfsPut(urlAction.url, urlAction.restPayload[0], paramNamesAndValues);
            }
            return result;
        }else{
            if (urlAction.service.indexOf('pushSGs') >= 0){

                var resultObj = SimTaskDataGenerator.createTask('NCCS');

                var scalableObj =  SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'pushed': false});
                for (var i = 0; i < scalableObj.length; i++) {
                    scalableObj[i].pushed = true
                    scalableObj[i].syncStatus = "SYNCED"
                    SimLokiDatabaseActions.update('scalablegroup', scalableObj[i])
                }

                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });

                result = {"response":{...resultObj},"version":"1.0"}
                result.response.statusCode = 202;
                result.statusCode = 202;

                // sync SGs with ISE
                let data = SimLokiDatabaseActions.getAll('scalablegroup')
                let sample = {"generationId":0,"dirty":false,"value":9,"isTagFromRange":"false","numericRepresentation":"9/0009","icon":0,"readOnly":"false","propogateToApic":"false","defaultSGACLs":[],"name":"Auditors","id":"934557f0-8c01-11e6-996c-525400b48521","description":"Auditor Security Group"}
                let res = data.filter(e=>!(e.isDeleted)).map((e,i)=>{return {...sample, ...{value:i, id:e.id,name:e.name, description:e.description, numericRepresentation:e.securityGroupTag}}})
                ISESyncher.sync('sg',res)

                return result;

            } else if (urlAction.service.indexOf('deploy') >= 0){
                var resultObj = SimTaskDataGenerator.createTask('NCCS');

                var accessObj =  SimLokiDatabaseActions.getFilteredRecordHandler('access-contract',{'pushed': false});
                for (var i = 0; i < accessObj.length; i++) {
                    accessObj[i].pushed = true
                    accessObj[i].syncStatus = "SYNCED"
                    SimLokiDatabaseActions.update('access-contract', accessObj[i])
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });

                result = {"response":{...resultObj},"version":"1.0"}
                result.response.statusCode = 202;
                result.statusCode = 202;

                //convert data to optimal format and sync with ISE
                let policies = SimLokiDatabaseActions.getAll('access-policy')
                let opt = policies.reduce(
                    (acc,e)=>{
                        if(!acc[e.producerId]) acc[e.producerId]={}
                        if(!acc[e.producerId][e.consumerId]) acc[e.producerId][e.consumerId]={} 
                        acc[e.producerId][e.consumerId].contractName=e.contractName
                        acc[e.producerId][e.consumerId].name=e.name
                        acc[e.producerId][e.consumerId].id=e.id
                        return acc },{})
                // console.log('d', opt)
                ISESyncher.sync('policies', opt)
                
                return result;
            } else if(urlAction.service.indexOf("virtualnetworkcontext")>=0){
                var resultObj = SimTaskDataGenerator.createTask('NCCS');
                var t = urlAction.url.split('/');
                // var jsonObj = {id: ''};
                jsonObj = urlAction.restPayload;
                let type = t[4];
                for(var i = 0;i<urlAction.restPayload.length;i++){
                    SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj[i]);
                }
                //Below code commneted by Karan for 1.4
                //test commit
                // for(var i = 0;i<urlAction.restPayload.length;i++){
                //     var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':urlAction.restPayload[i].id});
                //     vnObj[0].scalableGroup = [];
                //     vnObj[0].scalableGroupList = [];
                //     var sgId = urlAction.restPayload[i].scalableGroup[0].idRef;
                //     var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'id':sgId});
                //     var vndata = {}
                //     vndata.id = urlAction.restPayload[i].scalableGroup[0].idRef;
                //     vndata.instanceId = vnObj[0].id;
                //     vndata.displayName = vnObj[0].displayName;
                //     vndata.instanceVersion = vnObj[0].instanceVersion;
                //     vndata.name = vnObj[0].name;
                //     recordObj[0].acaScalableGroupVNInfo.push(vndata)
                //     recordObj[0].virtualNetworkNames = recordObj[0].virtualNetworkNames+'","'+vnObj[0].name;
                //     recordObj[0].virtualNetworksCount = recordObj[0].acaScalableGroupVNInfo.length;
                //     SimLokiDatabaseActions.update('scalablegroup',recordObj)
                //     vnObj[0].scalableGroup.push({'idRef':sgId});
                //     vnObj[0].scalableGroupList.push({'idRef':sgId});
                //     SimLokiDatabaseActions.update('virtualnetworkcontext', vnObj);

                // }
                // if(urlAction.restPayload[0].scalableGroup[0] != undefined){
                //     var sgId = urlAction.restPayload[0].scalableGroup[0].idRef;
                //     var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'id':sgId});
                    
                //     // recordObj[0].acaScalableGroupVNInfo.push(urlAction.restPayload[0]);
                //     // SimLokiDatabaseActions.update('scalablegroup', recordObj);
                    
                // }
                result = {"response":{...resultObj},"version":"1.0"}
                result.response.statusCode = 202;
                result.statusCode = 202;
                return result;
            }else if (urlAction.service.indexOf('dcs') > -1 || urlAction.service.indexOf('endpoint-analytics') > -1) {
                var data = SimPolicyEndpointAnalytics.processPut(urlAction)
                return data
            }else{
                return [];
            }
        }
    }

    function processDeleteRequest(urlAction, paramNamesAndValues) {
        var t = urlAction.url.split('/');
        var jsonObj = {id: ''};
        jsonObj.id = t[6].split('?')[0];;
        if(t[5] == "virtualnetworkcontext" && t[7] == undefined){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            let type = t[5];
            SimDataGenerationInit.cfsCUDoperation(type, 'delete', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
        }else if(urlAction.service.indexOf('customer-facing-service') > -1){
            var result = SimConnectivityDomain.CfsDelete(urlAction, paramNamesAndValues);
            return result;
        }if (urlAction.service.indexOf('dcs') > -1 || urlAction.service.indexOf('endpoint-analytics') > -1) {
            var data = SimPolicyEndpointAnalytics.processDelete(urlAction)
            return data
        }
        return SimScheduledCaptureData.deleteSettings(t[7], t[6]);

    }

    function getSite(siteName) {
        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'groupNameHierarchy': defLocation });
        return site[0];
    }

});
