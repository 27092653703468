define({
    "AllDevices_GraphQl_Template":
        {
            "data": {
                "allDevices": {
                    "items": [],
                    "__typename": "allDevicesResponse"
                }
            }
        },
    "advisoryCountObject": {
        "advisoryCountList": [
            {
                "deviceName": "p1.edge1-sda1.local",
                "advisoryCount": 5
            },
            {
                "deviceName": "BLD1-FLR2-ACCESS",
                "advisoryCount": 15
            },
            {
                "deviceName": "BLD2-FLR1-ACCESS",
                "advisoryCount": 15
            },
            {
                "deviceName": "BLD2-FLR2-ACCESS",
                "advisoryCount": 10
            },
            {
                "deviceName": "SJC01-Stack-01",
                "advisoryCount": 10
            },
            {
                "deviceName": "SJC01-C9300-01",
                "advisoryCount": 5
            },
            {
                "deviceName": "SJC01-C9300-02",
                "advisoryCount": 10
            },
            {
                "deviceName": "C9404R-border_cp_sda.local",
                "advisoryCount": 10
            },
            {
                "deviceName": "CAMPUS-CORE2",
                "advisoryCount": 10
            },
            {
                "deviceName": "BLD1-FLR1-DST1",
                "advisoryCount": 5
            },
            {
                "deviceName": "BLD1-FLR2-DST1",
                "advisoryCount": 10
            },
            {
                "deviceName": "BLD2-FLR1-DST1",
                "advisoryCount": 5
            },
            {
                "deviceName": "BLD2-FLR2-DST1",
                "advisoryCount": 15
            },
            {
                "deviceName": "WAN_EDGE1",
                "advisoryCount": 10
            },
            {
                "deviceName": "WAN_EDGE2",
                "advisoryCount": 15
            },
            {
                "deviceName": "WLC-9800",
                "advisoryCount": 5
            },
            {
                "deviceName": "WLC-FABRIC-01",
                "advisoryCount": 10
            }

        ]
    }
});