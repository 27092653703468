define({
"Template":{
  "version": "1.0",
  "response": {
    "id": "7361b985-7f9b-48da-83b1-980ab8654f3b",
    "name": "switch_svl_down",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.4.111.254",
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "StackWise Virtual Link has failed on Network Device DeviceNamePlaceHolder",
    "groupId": "stack_interface_failure",
    "groupName": "Stack Interface failure on Network Device",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "Unknown",
    "timestamp": 1617299308661,
    "description": "Stack Interface \"InterfaceDownPlaceHolder\" and Peer Interface \"PeerInterfacePlaceHolder\" on Network Device \"DeviceNamePlaceHolder\" have failed",
    "suggestions": [
      {
        "message": "Ensure the link speed, duplex, auto-negotiation and media type are supported and match on both sides of link",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the errors and link information of the affected interface on Local Node",
            "command": "show interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the errors and link information of the affected interface on Peer Node",
            "command": "show interface PeerInterfacePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the condition of the copper cable by running TDR (Time Domain Reflector) test. (Warning: Running this step might be disruptive to the connection)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test on Local Node, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test on Peer Node, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface PeerInterfacePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify DOM status on both sides of the link, if the affected link is optical",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check transceiver detail on Local Node",
            "command": "show interface InterfaceDownPlaceHolder transceiver detail",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check transceiver detail on Peer Node",
            "command": "show interface PeerInterfacePlaceHolder transceiver detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify configuration is compliant on both sides of the link ",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check running configuration of the affected interface on Local Node",
            "command": "show running-config interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check running configuration of the affected interface on Peer Node",
            "command": "show running-config interface PeerInterfacePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the result of TDR (Time Domain Reflector) test",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of TDR test on Local Node (Warning: This command might fail for some interface types)",
            "command": "show cable-diagnostics tdr interface InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of TDR test on Peer Node (Warning: This command might fail for some interface types)",
            "command": "show cable-diagnostics tdr interface PeerInterfacePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Configure the interfaces in local loopback. With local loopback the TX is connected to the RX at the port level inside the switch. Failure of the port to come up and ping itself with a local loop indicates a problem with the switch."
      },
      {
        "message": "Connect the faulty port to be tested to a spare port on the same switch, with the same cable and connectors/SFP. Configure the spare and faulty ports in two different VRFs, in the same subnet and test ping. Ping failure indicates cable/SFP problem."
      },
      {
        "message": "Check for compatibility issues between the two devices connected by this link."
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "owningEntityId",
        "value": "10.4.111.254"
      },
      {
        "key": "managementIpAddr",
        "value": "10.4.111.254"
      },
      {
        "key": "_name",
        "value": "switch_svl_down"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10.4.111.254:TenGigabitEthernet2/0/16"
      },
      {
        "key": "deviceName",
        "value": "D4-C9500.cisco.local"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/Milpitas"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10.4.111.254"
      },
      {
        "key": "interfaceName",
        "value": "GigabitEthernet1/0/5"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9500 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "26afeb20-e550-465a-b46a-c703251370ba"
      },
      {
        "key": "deviceRole",
        "value": "DISTRIBUTION"
      },
      {
        "key": "_suppression",
        "value": "1800"
      },
      {
        "key": "deviceOS",
        "value": "17.3.1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "D4-C9500.cisco.local"
      },
      {
        "key": "_eventTime",
        "value": "1617299308661"
      },
      {
        "key": "_mostRecent",
        "value": "1617299308661"
      },
      {
        "key": "entityId",
        "value": "10.4.111.254"
      },
      {
        "key": "eventUniqueId",
        "value": "10.4.111.254"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "buildingId",
        "value": "a7c562a3-f0c3-48e1-88ca-0948dcf9b385"
      },
      {
        "key": "buildingName",
        "value": "Global/Milpitas/SJC23"
      },
      {
        "key": "connectedInterface",
        "value": "GigabitEthernet1/0/5"
      },
      {
        "key": "areaId",
        "value": "c37b31ab-03b8-49c7-a742-3d1565d9c969"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/Milpitas/SJC23"
      },
      {
        "key": "sIfAdminStatus",
        "value": ""
      },
      {
        "key": "deviceModel",
        "value": "FCW2243F1SF"
      },
      {
        "key": "category",
        "value": "Connectivity"
      },
      {
        "key": "timestring",
        "value": "2021-04-01T17:48:28.661+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})