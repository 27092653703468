define({
     "ApplicationPolicySummary_Template": {
          "instanceId": 0,
          "displayName": "0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "namespace": "policy:application:p1",
          "policyId": "47570593-2c3c-47f7-8881-40e7299874bc",
          "policyName": "p1",
          "queuingProfileId": "92f229e7-4603-4faa-bfb8-dd87b90ad6b4",
          "queuingProfileName": "CVD_QUEUING_PROFILE",
          "staleTypes": [
            "POLICY"
          ],
          "status": "DEPLOYED",
          "version": 1,
          "applicationPolicyScopeSummary": {
            "instanceId": 0,
            "displayName": "0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "type": "SINGLE_SITE",
            "applicationPolicyScopeDeviceSummary": {
              "instanceId": 0,
              "displayName": "0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "aborted": 0,
              "excluded": 0,
              "failed": 0,
              "success": 0,
              "total": 1
            },
            "applicationPolicyScopeSiteSummary": {
              "instanceId": 0,
              "displayName": "0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "deletedSitesUuids": [

              ],
              "total": 1
            }
          }
        },
         "Application_Dashbaord_Template": [
                {
                  "instanceId": 0,
                  "displayName": "0",
                  "instanceTenantId": "5b9611a1ab53ed008e4bb74c",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "deployedSites": 1,
                  "draftPolicies": 0,
                  "erroredPolicies": 0,
                  "excludedDevices": 0,
                  "failedDevices": 0,
                  "scheduledPolicies": 0,
                  "stalePolicies": 0,
                  "successfullDevices": 3,
                  "successfullPolicies": 1,
                  "totalDevices": 3,
                  "totalPolicies": 1,
                  "totalSites": 0
                }
                ]


    });