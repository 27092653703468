define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/data/SimLokiDatabaseData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/utilities/loki/SimLokiIndexAdapter',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/BitArray'
], function (SimLokiDatabaseData, SimLokiIndexAdapter, BitArray) {
  var lokiDbCollection = [];
  var lokiDbCount = 0;

  return {
      init: function(dbParameters, clientCallback) {
          lokiDbCollection = [];
          lokiDbCount = 0;

          SimLokiDatabaseData.Services.forEach(function(service){
              if(service.enable == false) {
                   console.log(service.service-name + ' is not enabled');
                  return; //The return just skips current iteration only, it is not a exit from function..
              }

              lokiDbCollection.push(service);
              lokiDbCount += service['database-info'].length;
          });

          var dbStatusArray = new BitArray(lokiDbCount, 0);
          var dbCounter = 0;

          lokiDbCollection.forEach(function(service){

              // Note: current assumption is each service will have only one database, however if a single service requires
              //         multiple databases, to take care of such requirement in future, dbNames and CollectionNames are used as array.

              service['database-info'].forEach(function(database) {

                  if( dbParameters != undefined && dbParameters.KEEP_EXISTING_DATA != undefined) {
                      database.forceDelete = ! dbParameters.KEEP_EXISTING_DATA;
                  } else {
                      database.forceDelete = false;
                  }

                  //a callback is passed, which will be set on being called, when all bit are set, tell to client, db creation finished.
                  SimLokiIndexAdapter.createLokiIndexAdapter(dbCounter, database, function(dbFinishedIndex, collectionHandler){
                      //console.log(database['collectionName'] + ' ' +  database.collectionName);
                      dbStatusArray.set(dbFinishedIndex, 1);
                      setCollectionHandler(database['collectionName'], collectionHandler);
                      if(dbStatusArray.isAllbitSetaValue(1) ) {
                          clientCallback();
                      }
                  });

                  dbCounter++;
              });
          });
      },

      getAllServicesDbDetails: function() {
          return lokiDbCollection;
      },

      getCollectionHandlerByCollectionName: function(collectionName) {
          for(var i = 0, len = lokiDbCollection.length; i < len; i++) {
              var dbInfo = lokiDbCollection[i]['database-info'];
              for(var j = 0, len1 = dbInfo.length; j < len1; j++) {
                  if(dbInfo[j]['collectionName'] == collectionName) {
                      return dbInfo[j]['collectionHandler'];
                  }
              };
          }
      },

      getDatabseInfoByServiceName: function(serviceName) {
          for(var i = 0, len = lokiDbCollection.length; i < len; i++) {
              if(lokiDbCollection[i]['service-name'] == serviceName) {
                  return lokiDbCollection[i]['database-info'];
              }
          }
          return [];
      }

  };

  function setCollectionHandler(collectionName, collectionHandler) {

      for(var i = 0, len = lokiDbCollection.length; i < len; i++) {
          var dbInfo = lokiDbCollection[i]['database-info'];
          for(var j = 0, len1 = dbInfo.length; j < len1; j++) {
              if(dbInfo[j]['collectionName'] == collectionName) {
                  dbInfo[j]['collectionHandler'] = collectionHandler;
                  return;
              }
          }
      }
  }

});
