define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceCacheData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalytics/ClientCountTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalytics/APDownCountTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalytics/APInterferenceTemplate',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalytics/WanUtilisationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalytics/WanAvailabilityTemplate',
 ], function(SimLokiDatabaseActions, SimAssuranceCacheData, ClientCountTemplate, APDownCountTemplate, APInterferenceTemplate,UtilityFunctions,WanUtilisationTemplate,WanAvailabilityTemplate) {

 return {
      init: function(){
      },

      getHealthAnalyticsData: function(urlAction) {
          if(urlAction.filter['fields'] != undefined) {
              var summaryData = SimAssuranceCacheData.getCacheData(urlAction);

              if(urlAction.filter['fields'] == 'byClientCount') {
                var dateDiff = new Date(urlAction.filter['endTime']) - new Date(urlAction.filter['startTime'])
                    console.log("DateDifference: "+dateDiff);
                    //this is for Latest
                    if(dateDiff<400000){
                    return getClientCount(summaryData);
                    }else{
                    //this is for trend
                    var apClientConnected = getClientCount(summaryData);
                    var apClientConnectedVal = apClientConnected.values;
                    console.log("apClientConnected.values: "+apClientConnected.values);
                    for(var i=5;i<apClientConnected.values.length;i++){
                        apClientConnected.values[i].count = apClientConnected.values[i].count +10;
                    }
                    console.log("apClientConnected.values after the change: "+apClientConnectedVal);
                    return apClientConnected;
                    }
              } else if(urlAction.filter['fields'] == 'byapupdown') {
                  return getAPDownCount(urlAction,summaryData);

              } else if(urlAction.filter['fields'] == 'apInterference') {
                    var apInterferenceData = getAPInterference(urlAction,summaryData);
                    var dateDiff = new Date(urlAction.filter['endTime']) - new Date(urlAction.filter['startTime'])
                    console.log("DateDifference: "+dateDiff);
                    if(dateDiff > 300000){
                        console.log("For Trending: ");
                        for(var i=0;i < apInterferenceData.values.length;i++){
                            apInterferenceData.values[i].count =  apInterferenceData.values[i].count -30;
                        }
                    }
                   
                    return apInterferenceData;
                  
                  
              } else if(urlAction.filter['fields'] == 'wifi6Readiness') {
                  return getWifi6ReadinessData(urlAction);
              }  else if(urlAction.filter['fields'] == 'apProtocolDistribution') {
                  return getApProtocolDistData(urlAction);
              } else if(urlAction.filter['fields'] == 'bydevicereachabilitystatus') {
                  return getDeviceReachabilityStatus(urlAction,summaryData);
              } else if (urlAction.filter['fields'] == 'bywanutilisationstatus'){
                  return getWanUtilisationStatus(urlAction);
              }else if (urlAction.filter['fields'] == 'bywanavailabilitystatus'){
                  return getWanAvailabilityStatus(urlAction);
              }
          }
      }
 };

    function getClientCount(summaryData) {
      //var obj = {"id":"","name":"","count":0};
      var tJson = JSON.parse(JSON.stringify(ClientCountTemplate.Template));
      tJson.values = summaryData.ap_clientedConnected;
      return tJson;
    }

    function getAPDownCount(urlAction,summaryData) {
          var tJson = JSON.parse(JSON.stringify(APDownCountTemplate.Template));
         // tJson.records[0].UpCount = summaryData['WIRELESS'].apTotalCount;
          return generateData(urlAction, tJson,summaryData);
    }

    function sortByProperty (property) {

        return function (x, y) {

            return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));

        };

    };

     function getAPInterference(urlAction,summaryData) {
          var frequency = "2.4";
          if(urlAction.restPayload.radioType==5) {
               frequency="5.0";
          }
          //var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Unified AP"});
          var tJson = JSON.parse(JSON.stringify(APInterferenceTemplate.Template));
          var apList = [];
          if(frequency==="2.4"){
            apList=summaryData.highInterfaceApList24;
          }
          else{
              apList=summaryData.highInterfaceApList5;
          }

          tJson.values=apList;

          return tJson;
     }

    function getWifi6ReadinessData(urlAction) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':"Unified AP"});
        return {
            "type":"wifi6Readiness",
            "records":[
                {"name":"legacy","count":(records.filter(apDev => apDev.protocol_wifi6 == "1").length).toString()},
                {"name":"11n","count":(records.filter(apDev => apDev.protocol_wifi6 == "2").length).toString()},
                {"name":"11ac","count":(records.filter(apDev => apDev.protocol_wifi6 == "4").length).toString()},
                {"name":"11ax","count":(records.filter(apDev => apDev.protocol_wifi6 == "5").length).toString()},
                {"name":"protocol unknown","count":(records.filter(apDev => apDev.protocol_wifi6 == "0").length).toString()},
                {"name":"enabled","count":(records.filter(apDev => apDev.protocol_wifi6 == "5").length).toString()},
                {"name":"partially enabled","count":"0"},
                {"name":"disabled","count":"0"},
                {"name":"11ax6", "count":(records.filter(apDev => apDev.protocol_wifi6 == "7").length).toString()},
                {"name":"6E-enabled", "count":(records.filter(apDev => apDev.protocol_wifi6 == "7").length).toString()},
                {"name":"6E-disabled", "count":(records.filter(apDev => apDev.protocol_wifi6 == "8").length).toString()}   //Need to review
            ]}
    }

    function getApProtocolDistData(urlAction) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':"Unified AP"});
        var startTime = urlAction.filter.startTime, endTime = urlAction.filter.endTime;
        var customTime = startTime + (300000 - (startTime%300000));//nearest 5min
        var tJson = {
            "type":null,
            "records":[
                {
                    "time": UtilityFunctions.getGivenTimeInGivenFormat(customTime, 'YYYY-MM-DDTHH:mm:00.000ZZ'),
                    "milliseconds":customTime,
                    "legacy":(records.filter(apDev => apDev.protocol_wifi6 == "1").length).toString(),
                    "11n":(records.filter(apDev => apDev.protocol_wifi6 == "2").length).toString(),
                    "11ac":(records.filter(apDev => apDev.protocol_wifi6 == "4").length).toString(),
                    "11ax":(records.filter(apDev => apDev.protocol_wifi6 == "5").length).toString(),
                    "11ax6":(records.filter(apDev => apDev.protocol_wifi6 == "7").length).toString(),
                    "Unknown":(records.filter(apDev => apDev.protocol_wifi6 == "0").length).toString()
                }
            ]
        }
        if(endTime-startTime > (2*60*60*1000)) {
            //trend call (can be 3hrs, 24hrs, or 7 days). time window is 5 min for the 3 cases.
            //for now, keeping same data throughout the time
            var data = {"type":null, "records":[]};
            tJson = JSON.parse(JSON.stringify(tJson.records[0]));
            var timeWindow = 300000;//5min
            startTime = customTime;
            while(startTime<endTime) {
                tJson = JSON.parse(JSON.stringify(tJson));
                tJson.time = UtilityFunctions.getGivenTimeInGivenFormat(startTime, 'YYYY-MM-DDTHH:mm:00.000ZZ');
                tJson.milliseconds = startTime;
                tJson.timestamp = UtilityFunctions.getGivenTimeInGivenFormat(startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
                data.records.push(tJson);
                startTime += timeWindow;
            }
            return data;
        } else {
            return tJson;
        }
    }

    function generateData(urlAction, tJson,summaryData) {
        var tWindowTime = 5;
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
        if (hours > 24) {
            tWindowTime = 60;
        }
        var incrementBy = tWindowTime;
        var response = [];
        var fromTime = urlAction.filter["startTime"];
        var endTime= urlAction.filter["endTime"];
        var iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(fromTime, endTime, tWindowTime);

        if(iLen==0) {
            iLen=1;
        }
        console.log(summaryData);
        var timeToSet = new Date(fromTime);
        for (var i=0; i < iLen; i++) {
            var responseObj={};
            responseObj.time = timeToSet;
            timeToSet = UtilityFunctions.incrementDateByMinutes(fromTime,incrementBy);
            responseObj.UpCount = summaryData['AP'].GoodCount+summaryData['AP'].FairCount
            responseObj.DownCount = summaryData['AP'].BadCount;
            response.push(responseObj);
            incrementBy += tWindowTime;
        }

         response[iLen-1].time=new Date(endTime);
         tJson.records=response;

        return tJson;

    }

    function getDeviceReachabilityStatus(urlAction,summaryData){
        let fromTime = urlAction.filter["startTime"];
        let endTime= urlAction.filter["endTime"];
        let tWindowTime = 5;
        let hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
        /*if(hours == 24){
            tWindowTime = 5;
        }*/
        //let incrementBy = 0;
        var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime);
        let response = [];
        let iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(fromTime, endTime, tWindowTime);
        let tJson = JSON.parse(JSON.stringify(APDownCountTemplate.DeviceRechabilityStatusTemplate));
        //let devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family': {'$containsNone':["Unified AP"]}});
        let devices = summaryData.network_devices;
        devices = devices.filter(dev => dev.family !== "Unified AP")
        let reachable = devices.filter(e => e.reachabilityStatus === 'Reachable').length
        let unReachable = devices.filter(e => e.reachabilityStatus === 'UnReachable').length
        if(iLen==1) {
           iLen=2;
        }
        let timeToSet = new Date(fromTime);
        for (var i=0; i < iLen; i++) {
            var responseObj={};
            timeToSet = UtilityFunctions.incrementDateByMinutes(endTime,incrementBy);
            responseObj.time = UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
            responseObj.ReachableCount = `${reachable}`
            responseObj.UnreachableCount = `${unReachable}`; //'0'
            response.push(responseObj);
            fromTime = fromTime + incrementBy;
        }
        tJson.records = response;
        return tJson;
    }
    function getWanUtilisationStatus(urlAction){
        
        let fromTime = urlAction.filter["startTime"];
        let endTime= urlAction.filter["endTime"];
        let tWindowTime = 10;
        let hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
        
        var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime);
        let response = [];
        let iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(fromTime, endTime, tWindowTime);
        
        let timeToSet = new Date(fromTime);
        for (var i=0; i < iLen; i++) {
        let tJson = JSON.parse(JSON.stringify(WanUtilisationTemplate.Template));
         tJson.time= UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
         tJson.interface_utilisation_20 = UtilityFunctions.getRandomIntForRangeValues(0,0)
         tJson.interface_utilisation_21_40 = UtilityFunctions.getRandomIntForRangeValues(3,3)
         tJson.interface_utilisation_41_60 = UtilityFunctions.getRandomIntForRangeValues(4,4)
         tJson.interface_utilisation_61_80 = UtilityFunctions.getRandomIntForRangeValues(5,5)
         tJson.interface_utilisation_81 = UtilityFunctions.getRandomIntForRangeValues(8,8)
         timeToSet = UtilityFunctions.incrementDateByMinutes(endTime,incrementBy);
            
            response.push(tJson);
            fromTime = fromTime + incrementBy;
        }
        //return tJson;
    return {"type":null,"records":response}
    }
     function getWanAvailabilityStatus(urlAction) {
         let fromTime = urlAction.filter["startTime"];
         let endTime = urlAction.filter["endTime"];
         let tWindowTime = 10;
         let hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);

         var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime);
         let response = [];
         let iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(fromTime, endTime, tWindowTime);

         let timeToSet = new Date(fromTime);
         for (var i = 0; i < iLen; i++) {
             let tJson = JSON.parse(JSON.stringify(WanAvailabilityTemplate.Template));
             tJson.time = UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
             timeToSet = UtilityFunctions.incrementDateByMinutes(endTime, incrementBy);
             response.push(tJson);
             fromTime = fromTime + incrementBy;
         }
         return { "type": null, "records": response }
     }
});
