define({
  "App_Template":
  [
    {
      "applicationName": "ms-services",
      "applicationGroup": "control-plane",
      "Category":  "other",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 98908.873,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2.1,
      "maxLatency": 5,
      "averagePacketLoss": 0.027494363275613275,
      "maxPacketLoss": 1,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 62.00107323232324,
      "maxApplicationDelay": 65,
      "domainName": null,
      "businessRelevance": "default",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 1068219936,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
      "expectedDscp": "AF",
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.016304347826087,
      "maxServerNetworkDelay": 5.0

    },
    {
      "applicationName": "ntp",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 97951.56,
      "averageUtilization": null,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 3,
      "maxLatency": 6,
      "averagePacketLoss": 10,
      "maxPacketLoss": 5,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 4,
      "averageApplicationDelay": 18,
      "maxApplicationDelay": 34,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 1057871904,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
      "expectedDscp": "AF",
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "ms-office-365",
      "applicationGroup": "data",
      "Category": "business-and-productivity-tools",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 97949.620,
      "averageUtilization": 0,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 400,
      "maxLatency": 600,
      "averagePacketLoss": 4,
      "maxPacketLoss": 8,
      "protocol": null,
      "servicePort": "123",
      "serviceId": null,
      "healthScore": 2,
      "averageApplicationDelay": 8,
      "maxApplicationDelay": 13,
      "domainName": "",
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.31.255.1",
      "exporterUUID": null,
      "serverAddress": "91.189.89.199",
      "clientSiteId": "d79826b6-7d2c-4073-8217-7517052253ec",
      "dscpPreservation": false,
      "usage": 9571199904,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.4764492753623184,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "netbios-ns",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 46848.04,
      "averageUtilization": null,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2.67,
      "maxLatency": 4,
      "averagePacketLoss": 2.6754535798987,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 4,
      "averageApplicationDelay": 13,
      "maxApplicationDelay": 22,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 505958016,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "netflix",
      "applicationGroup": "control-plane",
      "Category":  "consumer-streaming",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 46221.812,
      "averageUtilization": null,
      "trafficClass": "multimedia-streaming",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.234,
      "maxLatency": 1,
      "averagePacketLoss": 9.7834601449275363,
      "maxPacketLoss": 4,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 109.50724637681162,
      "maxApplicationDelay": 134,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 499199904,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 2.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.15040726817042605,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "ms-office-web-apps",
      "applicationGroup": "control-plane",
      "Category": "business-and-productivity-tools",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 24884.259,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 3.0987643545766,
      "maxLatency": 5,
      "averagePacketLoss": 8.0987643545766,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 10,
      "averageApplicationDelay": 47.73717948717948,
      "maxApplicationDelay": 56,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 268747104,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 2.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.0778985507246377,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "espn-browsing",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 22933.716,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.345,
      "maxLatency": 2,
      "averagePacketLoss": 4.032,
      "maxPacketLoss": 1,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 6,
      "averageApplicationDelay": 0,
      "maxApplicationDelay": 0,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 247686624,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 2.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "webex-meeting",
      "applicationGroup": "control-plane",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 22650.208,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 450,
      "maxLatency": 680,
      "averagePacketLoss": 7.75489130434782615,
      "maxPacketLoss": 15,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 2,
      "averageApplicationDelay": 5.275362318840575,
      "maxApplicationDelay": 8,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 8571199904,
      "interfaceName": "GigabitEthernet0/0/1",
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 2.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
     {
      "applicationName": "MedicalRecords",
      "applicationGroup": "control-plane",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 3146.200,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 180,
      "maxLatency": 200,
      "averagePacketLoss": 7.75489130434782615,
      "maxPacketLoss": 10,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 8,
      "averageApplicationDelay": 5.275362318840575,
      "maxApplicationDelay": 10,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 30971199904,
      "interfaceName": "GigabitEthernet0/0/1",
	  "averageClientNetworkDelay": 2.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 1.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "apple-services",
      "applicationGroup": "data",
      "Category":  "consumer-internet",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 13745.08,
      "averageUtilization": null,
      "trafficClass": "bulk-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.234,
      "maxLatency": 1,
      "averagePacketLoss": 2,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 27,
      "maxApplicationDelay": 56,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 148448832,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.0,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "ms-live-accounts",
      "applicationGroup": "data",
      "Category": "consumer-internet",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 12456.712,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.234,
      "maxLatency": 1,
      "averagePacketLoss": 6.19765432133345,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 26,
      "maxApplicationDelay": 34,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 134535360,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "google-services",
      "applicationGroup": "control-plane",
      "Category":  "other",
      "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
      "numberOfClients": null,
      "averageRate": 11518.80,
      "averageUtilization": 81.32243270990269,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.23,
      "maxLatency": 2,
      "averagePacketLoss": 10.05296296296296297,
      "maxPacketLoss": 11.11111111111111,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 0,
      "maxApplicationDelay": 0,
      "domainName": "www.google.com",
      "businessRelevance": "default",
      "exporterIpAddress": "10.13.0.3",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 124407552,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.0,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "skype",
      "applicationGroup": "control-plane",
      "Category":  "consumer-messaging",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 7570.136,
      "averageUtilization": null,
      "trafficClass": "multimedia-conferencing",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.234,
      "maxJitter": 5,
      "averageLatency": 0.5,
      "maxLatency": 1,
      "averagePacketLoss": 8.90972222222444446,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 10,
      "averageApplicationDelay": 5.988194444444443,
      "maxApplicationDelay": 9,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 81753984,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "ms-lync",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 3108.8310,
      "averageUtilization": null,
      "trafficClass": "multimedia-conferencing",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2,
      "maxLatency": 5,
      "averagePacketLoss": 5.2155777777777776,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 4.430555555555555,
      "maxApplicationDelay": 11,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 33572064,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "dns",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 1766.076,
      "averageUtilization": null,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 3.6745382987634676,
      "maxLatency": 0,
      "averagePacketLoss": 3.6745382987634676,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 10,
      "averageApplicationDelay": 0,
      "maxApplicationDelay": 0,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 19074432,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "http",
      "applicationGroup": "data",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 1276.73,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 4.65,
      "maxLatency": 5,
      "averagePacketLoss": 4.65124907933501675,
      "maxPacketLoss": 22,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 61.46296296296296,
      "maxApplicationDelay": 69,
      "domainName": null,
      "businessRelevance": "default",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 13786176,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 3.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "ms-update",
      "applicationGroup": "data",
      "Category": "software-updates",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 517.491,
      "averageUtilization": null,
      "trafficClass": "bulk-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.2345796422235,
      "maxLatency": 2,
      "averagePacketLoss": 0.2345796422235,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 10.541666666666668,
      "maxApplicationDelay": 17,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 5588544,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "youtube",
      "applicationGroup": "data",
      "Category": "consumer-streaming",
      "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
      "numberOfClients": null,
      "averageRate": 199.039,
      "averageUtilization": 1.4052918393421472,
      "trafficClass": "multimedia-streaming",
      "dscpMarking": "CS1",
      "expectedDscp": "AF41",
      "averageJitter": 1.123,
      "maxJitter": 5,
      "averageLatency": 0.5,
      "maxLatency": 1,
      "averagePacketLoss": 0,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 0,
      "maxApplicationDelay": 0,
      "domainName": "",
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.13.0.3",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 2149824,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "telnet",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
      "numberOfClients": null,
      "averageRate": 151.458,
      "averageUtilization": 100,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2.89759865466878,
      "maxLatency": 0,
      "averagePacketLoss": 2.148,
      "maxPacketLoss": 12.5,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 0,
      "maxApplicationDelay": 0,
      "domainName": "",
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.13.0.3",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "unknownSiteId",
      "dscpPreservation": false,
      "usage": 1638528,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "disney-web-portal",
      "applicationGroup": "control-plane",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 722222.22,
      "averageUtilization": 0.01,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 344.70041322314046,
      "maxLatency": 500,
      "averagePacketLoss": 1.0648037190082649,
      "maxPacketLoss": 100,
      "protocol": null,
      "servicePort": "8080",
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 39.229683195592294,
      "maxApplicationDelay": 60,
      "domainName": "www.disney.com",
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 8371199904,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "binary-over-http",
      "applicationGroup": "data",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 599074.07,
      "averageUtilization": null,
      "trafficClass": "bulk-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.236249999999998,
      "maxLatency": 5,
      "averagePacketLoss": 2.236249999999998,
      "maxPacketLoss": 20,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 119.20833333333333,
      "maxApplicationDelay": 140,
      "domainName": null,
      "businessRelevance": "default",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 6944255328,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },

    {
      "applicationName": "ssh",
      "Category": "net-admin",
      "applicationGroup": "control-plane",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 262962.9629,
      "averageUtilization": 0.09,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.680807595182596,
      "maxLatency": 3,
      "averagePacketLoss": 8.680807595182596,
      "maxPacketLoss": 10,
      "protocol": null,
      "servicePort": "22",
      "serviceId": null,
      "healthScore": 9,
      "averageApplicationDelay": 11.924630924630927,
      "maxApplicationDelay": 807,
      "domainName": "",
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 3053117760,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "outlook-web-service",
      "applicationGroup": "data",
      "Category": "email",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 260185.185,
      "averageUtilization": null,
      "trafficClass": "bulk-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.234,
      "maxLatency": 1,
      "averagePacketLoss": 7.90358695652173915,
      "maxPacketLoss": 10,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 5,
      "averageApplicationDelay": 21.20289855072464,
      "maxApplicationDelay": 30,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 3013157664,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 3.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "citrix-static",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 187962.962,
      "averageUtilization": null,
      "trafficClass": "multimedia-streaming",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2.30489134934782615,
      "maxLatency": 120,
      "averagePacketLoss": 6.30489134934782615,
      "maxPacketLoss": 4,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 10,
      "averageApplicationDelay": 44.2142857,
      "maxApplicationDelay": 67,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 2183386848,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "snmp",
      "applicationGroup": "control-plane",
      "Category": "net-admin",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 138888.88,
      "averageUtilization": null,
      "trafficClass": "ops-admin-mgmt",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 4.0129456789327115,
      "maxLatency": 4,
      "averagePacketLoss": 4.0129456789327115,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 4,
      "averageApplicationDelay": 72,
      "maxApplicationDelay": 80,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 1607039904,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "bing",
      "applicationGroup": "control-plane",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 118518.518,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 1.345,
      "maxLatency": 5,
      "averagePacketLoss": 5,
      "maxPacketLoss": 0,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 10,
      "averageApplicationDelay": 38.4,
      "maxApplicationDelay": 67,
      "domainName": null,
      "businessRelevance": "business-irrelevant",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 1369140288,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "ssl",
      "applicationGroup": "data",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "numberOfClients": null,
      "averageRate": 10090.25,
      "averageUtilization": null,
      "trafficClass": "transactional-data",
      "dscpMarking": "DF",
      "expectedDscp": "AF",
      "averageJitter": 1.0,
      "maxJitter": 5,
      "averageLatency": 2.500510799963925,
      "maxLatency": 2,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "protocol": null,
      "servicePort": null,
      "serviceId": null,
      "healthScore": 8,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 60,
      "domainName": null,
      "businessRelevance": "default",
      "exporterIpAddress": "10.30.255.1",
      "exporterUUID": null,
      "serverAddress": "192.168.139.169",
      "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
      "dscpPreservation": false,
      "usage": 1170843936,
      "interfaceName": "GigabitEthernet0/0/1",
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null,
	  "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0
    },
    {
      "applicationName": "capwap-control",
      "applicationGroup": "data",
      "Category": "browsing",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "averageRate": 630.08,
      "averageUtilization": null,
      "trafficClass": "network-control",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5.0,
      "averageLatency": 1.234,
      "maxLatency": 5,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "healthScore": null,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 65.214285714285715,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": null,
      "expectedDscp": "AF",
      "usage": 23628.0,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "control-plane",
      "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0,
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null
    },
    {
      "applicationName": "hl7",
      "applicationGroup": "data",
      "Category": "business-and-productivity-tools",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "averageRate": 630.08,
      "averageUtilization": null,
      "trafficClass": "network-control",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5.0,
      "averageLatency": 1.234,
      "maxLatency": 5,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "healthScore": 7,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 65.214285714285715,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": null,
      "expectedDscp": "AF",
      "usage": 23628.0,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "control-plane",
      "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0,
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null
    },
	{
      "applicationName": "ms-olap",
      "applicationGroup": "data",
      "Category": "business-and-productivity-tools",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "averageRate": 630.08,
      "averageUtilization": null,
      "trafficClass": "network-control",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5.0,
      "averageLatency": 1.234,
      "maxLatency": 5,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "healthScore": 9,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 65.214285714285715,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": null,
      "expectedDscp": "AF",
      "usage": 23628.0,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "control-plane",
      "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0,
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null
    },
	{
      "applicationName": "ms-sql-m",
      "applicationGroup": "data",
      "Category": "business-and-productivity-tools",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "averageRate": 630.08,
      "averageUtilization": null,
      "trafficClass": "network-control",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5.0,
      "averageLatency": 1.234,
      "maxLatency": 5,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "healthScore": 8,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 65.214285714285715,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": null,
      "expectedDscp": "AF",
      "usage": 23628.0,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "control-plane",
      "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0,
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null
    },
	{
      "applicationName": "secure-http",
      "applicationGroup": "data",
      "Category": "internet-privacy",
      "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "averageRate": 630.08,
      "averageUtilization": null,
      "trafficClass": "network-control",
      "dscpMarking": "DF",
      "averageJitter": 1.0,
      "maxJitter": 5.0,
      "averageLatency": 1.234,
      "maxLatency": 5,
      "averagePacketLoss": 2.500510799963925,
      "maxPacketLoss": 8,
      "healthScore": 9,
      "averageApplicationDelay": 56.214285714285715,
      "maxApplicationDelay": 65.214285714285715,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": null,
      "expectedDscp": "AF",
      "usage": 23628.0,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "control-plane",
      "averageClientNetworkDelay": 4.9088164251207729,
      "maxClientNetworkDelay": 5.0,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 5.0,
      "exporterName": "AP0081.C424.3CE2",
      "exporterUUID": null
    },
    {
      "applicationName": "microsoft-teams",
      "Category": "video",
      "sourceLocation": "Israel/Site 2",
      "averageRate": 418078.58,
      "averageUtilization": null,
      "trafficClass": "multimedia-conferencing",
      "dscpMarking": "DF",
      "averageJitter": 24876.9880952381,
      "maxJitter": null,
      "averageLatency": 37.263888888888886,
      "maxLatency": null,
      "averagePacketLoss": 2.4867548000252038,
      "maxPacketLoss": null,
      "healthScore": 8,
      "averageApplicationDelay": 211.37594505004145,
      "maxApplicationDelay": null,
      "domainName": null,
      "businessRelevance": "business-relevant",
      "exporterIpAddress": null,
      "serverAddress": "192.168.139.16",
      "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
      "expectedDscp": "null",
      "usage": 10089467271,
      "interfaceName": "GigabitEthernet0/0/1",
      "applicationGroup": "video",
      "averageClientNetworkDelay": 0.6140211640211639,
      "maxClientNetworkDelay": null,
      "averageServerNetworkDelay": 4.6032608695652173,
      "maxServerNetworkDelay": 36.55555555555556,
      "exporterName": "ASR1K_Site2.cisco.com",
      "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
      ,
      "children": [{
        "applicationName": "ms-teams-video",
        "applicationGroup": "video",
        "Category": "video",
        "sourceLocation": "Israel/Site 2",
        "averageRate": 197552.02,
        "averageUtilization": null,
        "trafficClass": "multimedia-conferencing",
        "dscpMarking": "DF",
        "averageJitter": 903.6462626262626,
        "maxJitter": null,
        "averageLatency": 36.12592592592593,
        "maxLatency": null,
        "averagePacketLoss": 3.118524208949623,
        "maxPacketLoss": null,
        "healthScore": 9,
        "averageApplicationDelay": 1.041904761904762,
        "maxApplicationDelay": null,
        "domainName": null,
        "businessRelevance": "business-relevant",
        "exporterIpAddress": null,
        "serverAddress": "192.168.139.16",
        "exporterSiteId": null,
        "expectedDscp": "AF",
        "usage": 595686947,
        "interfaceName": "GigabitEthernet0/0/1",
        "averageClientNetworkDelay": 0.9037037037037037,
        "maxClientNetworkDelay": null,
        "averageServerNetworkDelay": 35.27777777777778,
        "maxServerNetworkDelay": null,
        "exporterName": "ASR1K_Site2.cisco.com",
        "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"

      },
      {
      "applicationName": "ms-teams-app-sharing",
        "applicationGroup": "video",
        "Category": "video",
        "sourceLocation": "Israel/Site 2",
        "averageRate": 197552.02,
        "averageUtilization": null,
        "trafficClass": "multimedia-conferencing",
        "dscpMarking": "DF",
        "averageJitter": 24876.9880952381,
        "maxJitter": null,
        "averageLatency": 37.263888888888886,
        "maxLatency": null,
        "averagePacketLoss": 3.1767523842791685,
        "maxPacketLoss": null,
        "healthScore": 9,
        "averageApplicationDelay": 3.895604395604395,
        "maxApplicationDelay": null,
        "domainName": null,
        "businessRelevance":"business-relevant",
        "exporterIpAddress": null,
        "serverAddress": "192.168.139.16",
        "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
        "expectedDscp": "AF",
        "usage": 595686947,
        "interfaceName": "GigabitEthernet0/0/1",
        "averageClientNetworkDelay": 0.7083333333333334,
        "maxClientNetworkDelay": null,
        "averageServerNetworkDelay": 36.55555555555556,
        "maxServerNetworkDelay": null,
        "exporterName": "ASR1K_Site2.cisco.com",
        "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"

      },
      {
        "applicationName": "ms-teams-audio",
          "applicationGroup": "video",
          "Category": "video",
          "sourceLocation": "Israel/Site 2",
          "averageRate": 138495.47,
          "averageUtilization": null,
          "trafficClass": "multimedia-conferencing",
          "dscpMarking": "DF",
          "averageJitter": 714.2783605283605,
          "maxJitter": null,
          "averageLatency": 34.57698412698412,
          "maxLatency": null,
          "averagePacketLoss": 1.4903522704422738,
          "maxPacketLoss": null,
          "healthScore": 8,
          "averageApplicationDelay": 3.1085470085470086,
          "maxApplicationDelay": null,
          "domainName": null,
          "businessRelevance":"business-relevant",
          "exporterIpAddress": null,
          "serverAddress": "192.168.139.16",
          "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
          "expectedDscp": "AF",
          "usage": 595686947,
          "interfaceName": "GigabitEthernet0/0/1",
          "averageClientNetworkDelay": 0.6140211640211639,
          "maxClientNetworkDelay": null,
          "averageServerNetworkDelay": 34,
          "maxServerNetworkDelay": null,
          "exporterName": "ASR1K_Site2.cisco.com",
          "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"

        },
        {
          "applicationName": "ms-teams-media",
            "applicationGroup": "video",
            "Category": "video",
            "sourceLocation": "Israel/Site 2",
            "averageRate": 4336.95,
            "averageUtilization": null,
            "trafficClass": "multimedia-conferencing",
            "dscpMarking": "DF",
            "averageJitter": null,
            "maxJitter": null,
            "averageLatency": null,
            "maxLatency": null,
            "averagePacketLoss": null,
            "maxPacketLoss": null,
            "healthScore": null,
            "averageApplicationDelay": null,
            "maxApplicationDelay": null,
            "domainName": null,
            "businessRelevance":"business-relevant",
            "exporterIpAddress": null,
            "serverAddress": "192.168.139.16",
            "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
            "expectedDscp": "AF",
            "usage": 42089787,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": null,
            "maxClientNetworkDelay": null,
            "averageServerNetworkDelay": null,
            "maxServerNetworkDelay": null,
            "exporterName": "ASR1K_Site2.cisco.com",
            "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"

          },
          {
            "applicationName": "ms-teams",
              "applicationGroup": "video",
              "Category": "video",
              "sourceLocation": "Israel/Site 2",
              "averageRate": 26836.35,
              "averageUtilization": null,
              "trafficClass": "multimedia-conferencing",
              "dscpMarking": "DF",
              "averageJitter": 714.2783605283605,
              "maxJitter": null,
              "averageLatency": 13.7090574634228,
              "maxLatency": null,
              "averagePacketLoss": 2.161390336429749,
              "maxPacketLoss": null,
              "healthScore": 8,
              "averageApplicationDelay": 211.37594505004145,
              "maxApplicationDelay": null,
              "domainName": null,
              "businessRelevance":"business-relevant",
              "exporterIpAddress": null,
              "serverAddress": "192.168.139.16",
              "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
              "expectedDscp": "AF",
              "usage": 3752396416,
              "interfaceName": "GigabitEthernet0/0/1",
              "averageClientNetworkDelay": 0.2613226966765956,
              "maxClientNetworkDelay": null,
              "averageServerNetworkDelay": 13.48774553031089,
              "maxServerNetworkDelay": null,
              "exporterName": "ASR1K_Site2.cisco.com",
              "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"

            },
  ]
    }

  ],
  "page": {
    "pageSize": 25,
    "offset": 1,
    "totalCount": 25
  }
});
