define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventsViewer/SimAssuranceEventsViewerTemplate',
], function (SimLokiDatabaseActions, UtilityFunctions, SimAssuranceEventsViewerTemplate) {

    function getCustomIssueAfter5Min(customIssues) {
        var matchingIssues = [];
        var currDate = UtilityFunctions.getTimeStamp();
        for (var i = 0; i < customIssues.length; i++) {
            var delayedTime = UtilityFunctions.incrementMinutes(customIssues[i].timestamp, 5);
            if (new Date(currDate) >= new Date(delayedTime)) {
                matchingIssues.push(customIssues[i]);
            }
        }
        return matchingIssues;
    }

    return {
        init: function () {
            //         var tJsonArr = JSON.parse(JSON.stringify( SimAssuranceEventsViewerTemplate.Template));
            //         SimLokiDatabaseActions.insert( 'eventsViewer', tJsonArr );
        },

        generateEventsViewerData: function (urlAction) {
            var data = {};
            var entityId = urlAction.filter['entityId'];
            var hostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'entity': urlAction.filter['entityId'].toUpperCase() });
            if(hostIssues.length==0) hostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'deviceId': urlAction.filter['entityId'] });
            var customHostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'entity': urlAction.filter['entityId'].toUpperCase() });
            if(customHostIssues.length==0) customHostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'deviceId': urlAction.filter['entityId'] });
            hostIssues = hostIssues.concat(getCustomIssueAfter5Min(customHostIssues));

            var deviceRecord;
            var hostIssues, hostIssues1, hostIssues3;
            var issueType = undefined;
            var deviceType = undefined;
            var issuePresent = undefined;
            var value, wlcData;
            var entityType = urlAction.filter['entityType'];

            // Getting Device Record...
            if (entityType == "switch") {
            //in 2.x /api/assurance/v1/events/deviceEventsView?entityId=172.21.227.129&offset=1&limit=3000&entityType=switch so changing id to managementIpAddress
                deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "managementIpAddress": urlAction.filter['entityId'].toLowerCase() });
                if(deviceRecord.length==0) deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "id": urlAction.filter['entityId'] });
            }
            if (entityType == "access_point") {
                deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "macAddress": urlAction.filter['entityId'].toLowerCase() });
                if(deviceRecord.length==0) deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "id": urlAction.filter['entityId'] });
            }

            if (deviceRecord == undefined || deviceRecord.length == 0) {
                return data;
            } else {
                wlcData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': deviceRecord[0].associatedWlcIp });
            }

            // Finding Device Type...
            if (deviceRecord.length > 0) {
                deviceType = deviceRecord[0].family;
                hostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'deviceName': deviceRecord[0].hostname });
                var customHostIssues = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'deviceName': deviceRecord[0].hostname });
                hostIssues = hostIssues.concat(getCustomIssueAfter5Min(customHostIssues));

                if (deviceType == 'Unified AP') {
                    issuePresent = false;
                    // To avoid shallow cloning or in simple words to avoid copy by reference JSON.parse(JSON.stringify()) is required...
                    value = JSON.parse(JSON.stringify(SimAssuranceEventsViewerTemplate.Template));
                    for (let i = 0; i < hostIssues.length; i++) {
                        if (hostIssues[i].name == 'ap_down') {
                            if (hostIssues[i].issueNamekey == 'ap_down') {
                                issueType = 'ap_down';
                                issuePresent = true;
                            }
                        }
                    }
                } else if (deviceType == 'Switches and Hubs') {
                    issuePresent = false;
                    value = JSON.parse(JSON.stringify(SimAssuranceEventsViewerTemplate.Template));
                    for (let i = 0; i < hostIssues.length; i++) {
                        if (hostIssues[i].name == 'switch_port_flaps') {
                            if (hostIssues[i].issueNamekey == 'switch_port_flapping') {
                                issueType = 'switch_port_flapping';
                                issuePresent = true;
                            }
                        }
                        if (hostIssues[i].name == 'default_trap_event_trigger') {
                            if (hostIssues[i].issueNamekey == 'default_trap_event_trigger') {
                                issueType = 'default_trap_event_trigger';
                                issuePresent = true;
                            }
                        }
                        //device events for loop issue
                        if (hostIssues[i].name == 'mac_flap_trigger') {
                            if (hostIssues[i].issueNamekey == 'mac_flap_trigger') {
                                issueType = 'mac_flap_trigger';
                                issuePresent = true;
                            }
                        }
                        if (hostIssues[i].name == 'network_device_poe_err_disabled_trigger') {
                            if (hostIssues[i].issueNamekey == 'network_device_poe_err_disabled_trigger') {
                                issueType = 'network_device_poe_err_disabled_trigger';
                                issuePresent = true;
                            }
                        }
                        if (hostIssues[i].name == 'network_device_poe_power_deny_trigger') {
                            if (hostIssues[i].issueNamekey == 'network_device_poe_power_deny_trigger') {
                                issueType = 'network_device_poe_power_deny_trigger';
                                issuePresent = true;
                            }
                        }
                        if (hostIssues[i].name == 'network_device_controller_poe_trigger') {
                            if (hostIssues[i].issueNamekey == 'network_device_controller_poe_trigger') {
                                issueType = 'network_device_controller_poe_trigger';
                                issuePresent = true;
                            }
                        }

                    }
                }
            }

            // Issue Present? - Check...
            if (issuePresent == false && deviceType == 'Unified AP') {
                issueType = 'notAnIssue';
            }
            else if (issuePresent == undefined || issuePresent == false) {
                return data;
            }

            var recordsCount = 0;
            var moreThanOneDay = false;
            var preset = [
                [100], 
                [370, 365, 260, 140, 100], 
                [9090, 8900, 8600, 6700, 5932, 5850, 4800, 4700, 3650, 1600, 1400, 1300, 1270, 1205, 1190, 880, 850, 800, 680, 600]
            ];

            var preset_timeWindowArray = undefined;
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            var timeWindow = 5;
            moreThanOneDay = hours > 24;
            if (deviceType == 'Unified AP') {
                if (moreThanOneDay) {
                    timeWindow = 0;
                    recordsCount = 20;
                    preset_timeWindowArray = preset[2];
                } else if (hours == 24) {
                    preset_timeWindowArray = preset[1];
                    recordsCount = 5;
                } else if (hours == 3) {
                    preset_timeWindowArray = preset[0];
                    recordsCount = 1;
                } else {
                    preset_timeWindowArray = [];
                    recordsCount = 0;
                }
            }
            else if (deviceType == 'Switches and Hubs') {
                if (moreThanOneDay) {
                    timeWindow = 30;
                    recordsCount = hours * 2;
                } else if(hours<3){
                    recordsCount =  60; //hours * 60;
                } else {
                    recordsCount = hours * 12;
                }
            }
            var time = urlAction.filter['endTime'] - i * 60 * 1000;
            var arrayResponse = [];
            var dataPayload = { "version": "1.0", "response": [], "totalCount": 0 };
            dataPayload.version = value ? value.version : "1.0";
            dataPayload.totalCount = recordsCount != 0 ? recordsCount : 0;

            // Switches and Hubs - Events Viewer Builder...
            function updateTimestampForSwitch(deviceValue, number, eventTimeStamp, deviceType) {
                deviceValue.name = deviceValue.name.replace(/[0-9]{13}$/, eventTimeStamp)
                    .replace(/[0-9]{13}_/, eventTimeStamp - 5000 + '_')
                deviceValue.id = 'f7eb3995-5278-45a5-98e3-efefc6aa' + number;
                if (deviceValue.timestamp) {
                    deviceValue.timestamp = eventTimeStamp;
                }
                arrayResponse.push(JSON.parse(JSON.stringify(deviceValue)));
                return deviceValue;
            }

            // Access Point - Events Viewer Builder...
            function updateTimestampForAccessPoint(deviceValue, recordsCount, issueType) {
                var number = 1000;
                var eventTimeStamp;
                for (let i = 0; i < recordsCount; i++) {
                    number = number + i;
                    if (issueType == 'ap_down') {
                        if (i > 5) {
                            if (i == 7) {
                                deviceValue = issueValue[deviceType][1];
                            } else if (i == 9) {
                                deviceValue = issueValue[deviceType][2];
                            } else {
                                deviceValue = issueValue[deviceType][0];
                            }
                        } else {
                            deviceValue = issueValue[deviceType][0];
                        }
                    } else {
                        deviceValue = issueValue[deviceType][0];
                    }
                    var details = deviceValue["details"];
                    details.forEach(function (detail) {
                        if (detail.key == "WLC UUID") {
                            detail.value = wlcData[0].instanceUuid;
                        } else if (detail.key == "WLC Name") {
                            detail.value = wlcData[0].hostname;
                        }
                    });
                    deviceValue.name = deviceValue.name.replace(/[0-9]{13}$/, eventTimeStamp)
                        .replace(/[0-9]{13}_/, eventTimeStamp - 5000 + '_');
                    deviceValue.id = 'c4b83bfb-8e61-4f13-9597-278bbabe' + number;
                    timeWindow = preset_timeWindowArray[i];
                    eventTimeStamp = time - timeWindow * 60 * 1000; // calculating for different preset random time intervals...
                    if (deviceValue.timestamp) {
                        deviceValue.timestamp = eventTimeStamp;
                    }
                    arrayResponse.push(JSON.parse(JSON.stringify(deviceValue)));
                }
            }

            // Events Viewer Builder Call - based on device type and appropriate device value...
            if (deviceType == 'Unified AP') {
                issueValue = value.response['ap_down'];
                deviceValue = issueValue[deviceType];
                updateTimestampForAccessPoint(deviceValue, recordsCount, issueType);
            }
            else if (deviceType == 'Switches and Hubs') {
                for (let i = 0; i < recordsCount; i++) {
                    let issueValue;
                    let number = 1001 + i;
                    var localDeviceValue = {};
                    issueValue = value.response[issueType];
                    if(moreThanOneDay &&  (i >= 100 && i<= 105)){
                        deviceValue = issueValue[deviceType][1];
                    }
                    else if (!moreThanOneDay && (i >= 30 && i<= 60)) {
                        deviceValue = issueValue[deviceType][1];
                    }
                     else {
                        deviceValue = issueValue[deviceType][0];
                    }

                //     if (!moreThanOneDay && i % 2 == 0)
                //         deviceValue = issueValue[deviceType][0];
                //     else if (!moreThanOneDay && i % 2 == 1)
                //         deviceValue = issueValue[deviceType][1];
                //    else {
                //        // Condition arrives for more than 24 hrs and less than 24 hrs missing data(which will not be passed for populating) on graph
                //        //deviceValue = issueValue[deviceType][i % 2];
                //    }
                    var eventTimeStamp = time - i * timeWindow * 60 * 1000
                    if (moreThanOneDay) {
                        updateTimestampForSwitch(deviceValue, number, eventTimeStamp, deviceType);
                    }
                    else {
                        let j = i + 1;
                        if ((i % 3) < 2) {
                            updateTimestampForSwitch(deviceValue, number, eventTimeStamp, deviceType);
                        }
                    }
                }
            }

            dataPayload.response = arrayResponse;
            return dataPayload;
        }
    }
});
