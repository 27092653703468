define({
    
    "getPcDistributionDetailRankConnection_endpoints_onboarding": [
        {
            "groupingFieldValue": "Windows Workstations",
            "countEntities": 1053
        },
        {
            "groupingFieldValue": "Apple iPhones",
            "countEntities": 803
        },
        {
            "groupingFieldValue": "Devices",
            "countEntities": 785
        },
        {
            "groupingFieldValue": "Workstations",
            "countEntities": 275
        },
        {
            "groupingFieldValue": "Apple iPads",
            "countEntities": 234
        },
        {
            "groupingFieldValue": "Android Devices",
            "countEntities": 162
        },
        {
            "groupingFieldValue": "Samsung Devices",
            "countEntities": 55
        },
        {
            "groupingFieldValue": "Apple Workstations",
            "countEntities": 43
        },
        {
            "groupingFieldValue": "Linux Workstations",
            "countEntities": 30
        },
        {
            "groupingFieldValue": "Apple Devices",
            "countEntities": 27
        }
    ],

    "getPcDistributionDetailRankConnection_endpoints_authTime": {
        "data": {
            "getPcDistribution36DetailRank": {
                "__typename": "GetPcDistribution36DetailRankConnection",
                "nodes": [
                    {
                        "__typename": "PcDistribution36GetPcRankType",
                        "listSiteId": [
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 821,
                                "groupingFieldValue": "Windows Workstations"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 621,
                                "groupingFieldValue": "Apple iPhones"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 169,
                                "groupingFieldValue": "Apple iPads"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 142,
                                "groupingFieldValue": "Devices"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 66,
                                "groupingFieldValue": "Android Devices"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 65,
                                "groupingFieldValue": "Workstations"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 22,
                                "groupingFieldValue": "Samsung Devices"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 20,
                                "groupingFieldValue": "Apple Workstations"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 6,
                                "groupingFieldValue": "Linux Workstations"
                            },
                            {
                                "__typename": "PcSiteRank",
                                "countEntities": 4,
                                "groupingFieldValue": "Apple Devices"
                            }
                        ]
                    }
                ]
            }
        }
    },

    "getPcRecord36ClusterWithDeviceFamily": {
        "data": {
            "getPcRecord36ClusterWithDeviceFamily": {
                "__typename": "GetPcRecord36ClusterWithDeviceFamilyConnection",
                "nodes": [
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Microsoft Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Nokia Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4a999b7295f6beeffe3",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4a999b7295f6beeffe3",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4a999b7295f6beeffe3",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4a999b7295f6beeffe3",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-22T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Cisco Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Microsoft Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Nokia Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4669baf10dcdca8f6e7",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-23T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Microsoft Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Nokia Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-24T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-25T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-26T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Microsoft Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Cisco Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Microsoft Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a45970135fc1c5a5daa4",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a48672cdb76f8a70b5b0",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-27T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4d341f036a20043fb34",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a4173360e80bb3634418",
                        "pcId": "a6f49bfdadbdea8f6aad",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Android Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPads",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple iPhones",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Apple Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "HP Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Intel Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linksys Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Linux Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Samsung Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "TP-Link Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Video Devices",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Windows Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    },
                    {
                        "__typename": "PcRecord36GetPcClusterWithDeviceFamilyType",
                        "deviceFamily": "Workstations",
                        "ncubeId": "a689583e07ac5ca2eb17",
                        "pcId": "a614a8f1cbb6c39a5f6b",
                        "timestamp": "2021-04-28T00:00:00"
                    }
                ]
            }
        }
    },

    "DetailRangeTemplate": {
        "data": {
            "getPcDistribution36DetailRange": {
                "__typename": "GetPcDistribution36DetailRangeConnection",
                "nodes": [
                    {
                        "__typename": "PcDistribution36type",
                        "countEntities": 50,
                        "groupingFieldValue": "Android Devices",
                        "kpiStringCount": "{\"Android\": 203}",
                        "ncubeId": "a47fd22e02c95f5b584b",
                        "pcId": "a689583e07ac5ca2eb17",
                        "percentiles": [
                            {
                                "__typename": "DistributionPercentile",
                                "percentile": 10,
                                "value": null
                            },
                            {
                                "__typename": "DistributionPercentile",
                                "percentile": 25,
                                "value": null
                            },
                            {
                                "__typename": "DistributionPercentile",
                                "percentile": 50,
                                "value": null
                            },
                            {
                                "__typename": "DistributionPercentile",
                                "percentile": 75,
                                "value": null
                            },
                            {
                                "__typename": "DistributionPercentile",
                                "percentile": 90,
                                "value": null
                            }
                        ],
                        "timestamp": "2021-04-22T00:00:00"
                    }
                ]
            }
        }
    },

    "EndpointDtls_Android_Devices" : [
        {"countEntities": 50,"kpiStringCount": "{\"Android\": 203}"},
        {"countEntities": 42,"kpiStringCount": "{\"Android\": 151}"},
        {"countEntities": 50,"kpiStringCount": "{\"Android\": 139}"},
        {"countEntities": 7,"kpiStringCount": "{\"Android\": 33}"},
        {"countEntities": 9,"kpiStringCount": "{\"Android\": 36}"},
        {"countEntities": 51,"kpiStringCount": "{\"Android\": 111}"},
        {"countEntities": 66,"kpiStringCount": "{\"Android\": 197}"}
    ],

    "EndpointDtls_Apple_Devices" : [
        {"countEntities": 1,"kpiStringCount": "{\"Android\": 1}"},
        {"countEntities": 2,"kpiStringCount": "{\"Android\": 2}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 5}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 3}"},
        {"countEntities": 1,"kpiStringCount": "{\"Android\": 1}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 5}"},
        {"countEntities": 4,"kpiStringCount": "{\"Android\": 11}"}
    ],

    "EndpointDtls_Linux_Workstations" : [
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 8}"},
        {"countEntities": 10,"kpiStringCount": "{\"Android\": 15}"},
        {"countEntities": 10,"kpiStringCount": "{\"Android\": 14}"},
        {"countEntities": 9,"kpiStringCount": "{\"Android\": 24}"},
        {"countEntities": 8,"kpiStringCount": "{\"Android\": 21}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 3}"},
        {"countEntities": 1,"kpiStringCount": "{\"Android\": 1}"}
    ],

    "EndpointDtls_Samsung_Devices" : [
        {"countEntities": 1,"kpiStringCount": "{\"Android\": 1}"},
        {"countEntities": 4,"kpiStringCount": "{\"Android\": 4}"},
        {"countEntities": 2,"kpiStringCount": "{\"Android\": 2}"},
        {"countEntities": 2,"kpiStringCount": "{\"Android\": 2}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 4}"},
        {"countEntities": 3,"kpiStringCount": "{\"Android\": 3}"},
        {"countEntities": 6,"kpiStringCount": "{\"Android\": 5, \"Samsung-Device\": 1}"}
    ]

});