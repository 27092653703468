define({
"Template":
{
  "version": "1.0",
  "response": [{
    "id": "932eec26-db6e-40a5-a7d7-d2dfec91e8e7",
    "name": "global_wireless_kairos_excessive_onboarding_time_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entityName": "Unknown",
    "entity": "a1119232256982aa4247",
    "groupBy": "a1119232256982aa4247",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Excessive time to connect - At least 37% increase in time on SSID-SSID@ToChange in Site@ToChange.",
    "groupId": "global_wireless_client_onboarding_issues_grouping",
    "groupName": "Global Wireless Client Onboarding Issues",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1607586826015,
    "description": "Error: issue details are missing. Please check connectivity to AI Network Analytics cloud.",
    "suggestions": null,
    "additionalParams": [
      {
        "key": "groupType",
        "value": "site_ssid"
      },
      {
        "key": "_name",
        "value": "global_wireless_kairos_excessive_onboarding_time_trigger"
      },
      {
        "key": "_entity_type",
        "value": "global"
      },
      {
        "key": "eventSource",
        "value": "Client"
      },
      {
        "key": "groupBy",
        "value": "a1119232256982aa4247"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "no_suppression"
      },
      {
        "key": "ssid",
        "value": "SSID-Lgok"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/SITE-dGLo"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "no_suppression"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "triggerSource",
        "value": "kairos"
      },
      {
        "key": "clientCount",
        "value": "67"
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceRole",
        "value": "Wireless"
      },
      {
        "key": "isAIDriven",
        "value": "Yes"
      },
      {
        "key": "kairosIssueId",
        "value": "a1119232256982aa4247"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "_eventTime",
        "value": "1607586826015"
      },
      {
        "key": "_mostRecent",
        "value": "1607586826015"
      },
      {
        "key": "entityId",
        "value": "a1119232256982aa4247"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "pctDeviation",
        "value": "37"
      },
      {
        "key": "buildingId",
        "value": "85d5ddd6-76e2-4130-b403-2c3ff88f35ba"
      },
      {
        "key": "orgTriggerTime",
        "value": "1607713619248136"
      },
      {
        "key": "buildingName",
        "value": "Global/SITE-dGLo/BLD-26sw"
      },
      {
        "key": "areaId",
        "value": "cd0db796-7aa6-4f20-9146-49f5740357fd"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/SITE-dGLo/BLD-26sw"
      },
      {
        "key": "category",
        "value": "Onboarding"
      },
      {
        "key": "timestring",
        "value": "2020-12-10T07:53:46.015+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }]
}
})