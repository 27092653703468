define({
"Template":
{
  "request": {
    "key": "capwap-control",
    "type": "Application",
    "label": "capwap-control",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "242a3aa7-5a94-4ab4-ac57-d9a0e7667fde",
        "origType": "Application"
      }
    ]
  },
  "data": {
    "response": {
      "id": "242a3aa7-5a94-4ab4-ac57-d9a0e7667fde",
      "instanceId": 4211,
      "displayName": "4211",
      "instanceVersion": 0,
      "createTime": 1558647995068,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1558647995068,
      "name": "capwap-control",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "pushed": false,
      "scalableGroupExternalHandle": "capwap-control",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "2e1c4d7a-1490-4bce-9367-699e04e82c66",
        "instanceId": 11011,
        "displayName": "11011",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a5a199e5-78d3-48b3-a426-b34bf6bc9ea4",
          "instanceId": 13463,
          "displayName": "13463",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "5246",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "620a8e00-d819-483a-b7f1-499aed010aa3",
          "instanceId": 12168,
          "displayName": "12168",
          "instanceVersion": 0,
          "appProtocol": "udp",
          "applicationGroup": "capwap-group",
          "applicationSubType": "NONE",
          "applicationType": "DEFAULT",
          "categoryId": "68a7c377-6024-4758-bdd3-dd80bf29e748",
          "createTime": 1558647995068,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Control And Provisioning of Wireless Access Points Control Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1558647995068,
          "longDescription": "Control And Provisioning of Wireless Access Points (CAPWAP) is a protocol used for Access Controllers (AC) to manage and control Wireless Termination Points (WTPs). CAPWAP is designed to centralize WLANs. CAPWAP control represents the control traffic passed from the WTP to AC or vice versa.",
          "name": "capwap-control",
          "nbarId": "1221",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc5415",
          "selectorId": "5246",
          "serverNames": [],
          "subCategory": "3a0cafb3-d511-45a5-b475-4cae88312a80",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "85ef902c-1ff7-4e64-a857-473268e4641e",
          "instanceId": 13462,
          "displayName": "13462",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "5246",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "3b5d5a85-693f-4e9e-9dde-9963570f79f1"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "242a3aa7-5a94-4ab4-ac57-d9a0e7667fde",
        "instanceId": 4211,
        "displayName": "4211",
        "instanceVersion": 0,
        "createTime": 1558647995068,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1558647995068,
        "name": "capwap-control",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "pushed": false,
        "scalableGroupExternalHandle": "capwap-control",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "2e1c4d7a-1490-4bce-9367-699e04e82c66",
          "instanceId": 11011,
          "displayName": "11011",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "a5a199e5-78d3-48b3-a426-b34bf6bc9ea4",
            "instanceId": 13463,
            "displayName": "13463",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "5246",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "620a8e00-d819-483a-b7f1-499aed010aa3",
            "instanceId": 12168,
            "displayName": "12168",
            "instanceVersion": 0,
            "appProtocol": "udp",
            "applicationGroup": "capwap-group",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "68a7c377-6024-4758-bdd3-dd80bf29e748",
            "createTime": 1558647995068,
            "encrypted": "true",
            "engineId": "3",
            "helpString": "Control And Provisioning of Wireless Access Points Control Protocol",
            "ignoreConflict": false,
            "lastUpdateTime": 1558647995068,
            "longDescription": "Control And Provisioning of Wireless Access Points (CAPWAP) is a protocol used for Access Controllers (AC) to manage and control Wireless Termination Points (WTPs). CAPWAP is designed to centralize WLANs. CAPWAP control represents the control traffic passed from the WTP to AC or vice versa.",
            "name": "capwap-control",
            "nbarId": "1221",
            "p2pTechnology": "false",
            "popularity": 6,
            "rank": 65535,
            "references": "http://tools.ietf.org/html/rfc5415",
            "selectorId": "5246",
            "serverNames": [],
            "subCategory": "3a0cafb3-d511-45a5-b475-4cae88312a80",
            "trafficClass": "NETWORK_CONTROL",
            "tunnel": "true"
          }
        ],
        "networkIdentity": [
          {
            "id": "85ef902c-1ff7-4e64-a857-473268e4641e",
            "instanceId": 13462,
            "displayName": "13462",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "5246",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "3b5d5a85-693f-4e9e-9dde-9963570f79f1"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
}
})