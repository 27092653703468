define([

], function () {
    return {
        init: function () {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method){
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction){
        //need to write code here
        return {"response":{"kgvFileName":"Cisco_KnownGoodValues.tar","kgvFileMostRecentPublishedDate":"2021.11.04.19.13.55","kgvFileHashOverData":"SHA-256:c1c7afbfcdbe1bcd25fa3b592dcdbb4ae2101e33294c359fed61b236a364a1c3","userId":"IVM_INTERNAL_SCHEDULER_SYNC_KGV","uploadMode":"mode:remote","uploadStartTime":"20211105031043597","uploadFinishedTime":"20211105031111434","recordsProcessed":636124},"version":"1.0"}
    }
    function processPostRequest(urlAction){

    }
    function processDeleteRequest(urlAction){

    }
});