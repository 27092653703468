define({
"Template":
{
  "name": "fabric_reachability_session",
  "severity": "HIGH",
  "id": "AWXMpscZCj-ipzc8r5Pk",
  "time": "2018-09-12T07:20:06.546+0000",
  "endTime": "2018-09-12T07:20:06.546+0000",
  "count": 1,
  "attributes": [
    {
      "key": "destIp",
      "value": "192.168.101.105"
    },
    {
      "key": "managementIpAddr",
      "value": "192.168.130.2"
    },
    {
      "key": "type",
      "value": "issue"
    },
    {
      "key": "uuid",
      "value": "8d65b376-c222-4d4f-89c2-5acd33b4a9e3"
    },
    {
      "key": "sourceRole",
      "value": "Co-located Fabric Border and Control Plane"
    },
    {
      "key": "scope",
      "value": ""
    },
    {
      "key": "rootCause",
      "value": "7.1.1.13_192.168.101.105_DhcpUnderlay"
    },
    {
      "key": "sessionType",
      "value": "DhcpUnderlay"
    },
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "destMgmtIp",
      "value": "NA"
    },
    {
      "key": "entityId",
      "value": "192.168.130.2"
    },
    {
      "key": "vrf",
      "value": ""
    },
    {
      "key": "sessionIdentifier",
      "value": "7.1.1.13_192.168.101.105_DhcpUnderlay"
    },
    {
      "key": "sourceIp",
      "value": "7.1.1.13"
    },
    {
      "key": "category",
      "value": "connected"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})