define({
    "WIFI6_LATENCY_VIDEO": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "nonAxLatency": 40478,
                "axLatency": 137670,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "nonAxLatency": 69228,
                "axLatency": 245293,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "nonAxLatency": 35436,
                "axLatency": 180668,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "nonAxLatency": 40978,
                "axLatency": 187923,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "nonAxLatency": 37174,
                "axLatency": 138266,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "nonAxLatency": 40124,
                "axLatency": 169733,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "nonAxLatency": 36655,
                "axLatency": 176416,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "nonAxLatency": 200722,
                "axLatency": 121898,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "nonAxLatency": 32685,
                "axLatency": 313491,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "nonAxLatency": 42548,
                "axLatency": 130241,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "nonAxLatency": 45908,
                "axLatency": 312964,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "nonAxLatency": 41540,
                "axLatency": 458128,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "nonAxLatency": 34231,
                "axLatency": 165214,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "nonAxLatency": 38388,
                "axLatency": 144012,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "nonAxLatency": 31236,
                "axLatency": 469318,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "nonAxLatency": 59018,
                "axLatency": 225552,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "nonAxLatency": 45664,
                "axLatency": 181364,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "nonAxLatency": 105099,
                "axLatency": 104794,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "nonAxLatency": 0,
                "axLatency": 740844,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "nonAxLatency": 0,
                "axLatency": 1382443,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "nonAxLatency": 275083,
                "axLatency": 113630,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "nonAxLatency": 434365,
                "axLatency": 119212,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "nonAxLatency": 117715,
                "axLatency": 0,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "nonAxLatency": 764191,
                "axLatency": 0,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "nonAxLatency": 34384,
                "axLatency": 189274,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "nonAxLatency": 165101,
                "axLatency": 796550,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "nonAxLatency": 40699,
                "axLatency": 159218,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "nonAxLatency": 37057,
                "axLatency": 175551,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "nonAxLatency": 39153,
                "axLatency": 206438,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "nonAxLatency": 37853,
                "axLatency": 266851,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "nonAxLatency": 177668,
                "axLatency": 46739,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "nonAxLatency": 36337,
                "axLatency": 191693,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "nonAxLatency": 37885,
                "axLatency": 140555,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "nonAxLatency": 72865,
                "axLatency": 165117,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "nonAxLatency": 42567,
                "axLatency": 190653,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "nonAxLatency": 34201,
                "axLatency": 202668,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "nonAxLatency": 540499,
                "axLatency": 98279,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "nonAxLatency": 36918,
                "axLatency": 243347,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "nonAxLatency": 90384,
                "axLatency": 130924,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "nonAxLatency": 38526,
                "axLatency": 268916,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "nonAxLatency": 31570,
                "axLatency": 116437,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "nonAxLatency": 32046,
                "axLatency": 302690,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "nonAxLatency": 33839,
                "axLatency": 170668,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "nonAxLatency": 39446,
                "axLatency": 269515,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "nonAxLatency": 41282,
                "axLatency": 205013,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "nonAxLatency": 50715,
                "axLatency": 191519,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "nonAxLatency": 36604,
                "axLatency": 227460,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "nonAxLatency": 32002,
                "axLatency": 177821,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "nonAxLatency": 62146,
                "axLatency": 220966,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "nonAxLatency": 33343,
                "axLatency": 212350,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "nonAxLatency": 41508,
                "axLatency": 124238,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "nonAxLatency": 84237,
                "axLatency": 83518,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "nonAxLatency": 34344,
                "axLatency": 208455,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "nonAxLatency": 38393,
                "axLatency": 136136,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "nonAxLatency": 782437,
                "axLatency": 745808,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "nonAxLatency": 773188,
                "axLatency": 0,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "nonAxLatency": 990607,
                "axLatency": 359812,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "nonAxLatency": 0,
                "axLatency": 482073,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "nonAxLatency": 0,
                "axLatency": 153390,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "nonAxLatency": 36995,
                "axLatency": 147279,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "nonAxLatency": 39480,
                "axLatency": 156053,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "nonAxLatency": 29567,
                "axLatency": 275001,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "nonAxLatency": 32864,
                "axLatency": 204527,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "nonAxLatency": 36015,
                "axLatency": 145787,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "nonAxLatency": 51877,
                "axLatency": 241023,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "nonAxLatency": 35395,
                "axLatency": 323832,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "nonAxLatency": 29623,
                "axLatency": 267936,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "nonAxLatency": 30792,
                "axLatency": 192978,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "nonAxLatency": 56131,
                "axLatency": 162317,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "nonAxLatency": 102199,
                "axLatency": 141902,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "nonAxLatency": 67715,
                "axLatency": 143495,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "nonAxLatency": 238474,
                "axLatency": 161747,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "nonAxLatency": 301925,
                "axLatency": 129308,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "nonAxLatency": 55695,
                "axLatency": 150203,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "nonAxLatency": 365920,
                "axLatency": 124057,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "nonAxLatency": 29947,
                "axLatency": 155650,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "nonAxLatency": 29013,
                "axLatency": 145945,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "nonAxLatency": 34276,
                "axLatency": 199338,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "nonAxLatency": 35455,
                "axLatency": 140574,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "nonAxLatency": 50878,
                "axLatency": 241554,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "nonAxLatency": 735912,
                "axLatency": 144599,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "nonAxLatency": 31201,
                "axLatency": 184909,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "nonAxLatency": 33104,
                "axLatency": 210003,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "nonAxLatency": 33039,
                "axLatency": 191860,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "nonAxLatency": 31361,
                "axLatency": 168783,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "nonAxLatency": 31625,
                "axLatency": 101326,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "nonAxLatency": 390599,
                "axLatency": 100060,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "nonAxLatency": 41666,
                "axLatency": 131349,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "nonAxLatency": 198588,
                "axLatency": 184811,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "nonAxLatency": 40500,
                "axLatency": 125190,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "nonAxLatency": 37402,
                "axLatency": 178961,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "nonAxLatency": 40351,
                "axLatency": 161818,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "nonAxLatency": 30784,
                "axLatency": 119790,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "nonAxLatency": 79278,
                "axLatency": 60028,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "nonAxLatency": 54070,
                "axLatency": 108689,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "nonAxLatency": 34472,
                "axLatency": 152573,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "nonAxLatency": 32667,
                "axLatency": 168718,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "nonAxLatency": 30999,
                "axLatency": 164748,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "nonAxLatency": 39780,
                "axLatency": 178705,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "nonAxLatency": 646368,
                "axLatency": 154705,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "nonAxLatency": 31984,
                "axLatency": 210919,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "nonAxLatency": 33266,
                "axLatency": 256331,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "nonAxLatency": 0,
                "axLatency": 358208,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "nonAxLatency": 0,
                "axLatency": 351339,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "nonAxLatency": 32311,
                "axLatency": 210024,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "nonAxLatency": 33426,
                "axLatency": 216979,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "nonAxLatency": 32175,
                "axLatency": 223104,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "nonAxLatency": 87229,
                "axLatency": 224625,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "nonAxLatency": 37552,
                "axLatency": 148037,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "nonAxLatency": 35333,
                "axLatency": 170201,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "nonAxLatency": 31983,
                "axLatency": 204086,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "nonAxLatency": 33521,
                "axLatency": 272405,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "nonAxLatency": 195782,
                "axLatency": 258113,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "nonAxLatency": 29457,
                "axLatency": 206799,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "nonAxLatency": 36937,
                "axLatency": 191720,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "nonAxLatency": 33325,
                "axLatency": 170366,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "nonAxLatency": 35764,
                "axLatency": 206662,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "nonAxLatency": 34341,
                "axLatency": 182322,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "nonAxLatency": 36768,
                "axLatency": 237457,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "nonAxLatency": 35422,
                "axLatency": 210598,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "nonAxLatency": 128689,
                "axLatency": 155878,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "nonAxLatency": 171921,
                "axLatency": 132882,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "nonAxLatency": 56609,
                "axLatency": 152916,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "nonAxLatency": 136508,
                "axLatency": 174947,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "nonAxLatency": 65317,
                "axLatency": 17671,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "nonAxLatency": 804118,
                "axLatency": 144649,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "nonAxLatency": 1185501,
                "axLatency": 0,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "nonAxLatency": 1145731,
                "axLatency": 0,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "nonAxLatency": 75706,
                "axLatency": 273074,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "nonAxLatency": 34111,
                "axLatency": 169129,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "nonAxLatency": 32615,
                "axLatency": 205950,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "nonAxLatency": 31845,
                "axLatency": 209836,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "nonAxLatency": 31821,
                "axLatency": 175436,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "nonAxLatency": 30372,
                "axLatency": 309269,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "nonAxLatency": 30411,
                "axLatency": 180268,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "nonAxLatency": 31191,
                "axLatency": 340474,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "nonAxLatency": 31790,
                "axLatency": 429343,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "nonAxLatency": 43452,
                "axLatency": 277049,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "nonAxLatency": 32503,
                "axLatency": 225580,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "nonAxLatency": 30947,
                "axLatency": 204331,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "nonAxLatency": 30868,
                "axLatency": 264676,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "nonAxLatency": 33783,
                "axLatency": 251253,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "nonAxLatency": 32082,
                "axLatency": 243642,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "nonAxLatency": 31829,
                "axLatency": 181881,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "nonAxLatency": 35280,
                "axLatency": 199889,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "nonAxLatency": 29442,
                "axLatency": 241745,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "nonAxLatency": 35056,
                "axLatency": 91702,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "nonAxLatency": 34998,
                "axLatency": 158435,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "nonAxLatency": 31032,
                "axLatency": 270769,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "nonAxLatency": 35912,
                "axLatency": 217524,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "nonAxLatency": 42625,
                "axLatency": 244311,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "nonAxLatency": 34674,
                "axLatency": 178111,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "nonAxLatency": 306020,
                "axLatency": 217427,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "nonAxLatency": 30071,
                "axLatency": 334473,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "nonAxLatency": 32963,
                "axLatency": 210532,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "nonAxLatency": 40300,
                "axLatency": 185596,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "nonAxLatency": 30837,
                "axLatency": 226118,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "nonAxLatency": 32743,
                "axLatency": 214013,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "nonAxLatency": 34282,
                "axLatency": 187045,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "nonAxLatency": 35373,
                "axLatency": 183891,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "nonAxLatency": 32473,
                "axLatency": 179953,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "nonAxLatency": 61257,
                "axLatency": 287786,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "nonAxLatency": 33781,
                "axLatency": 198731,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "nonAxLatency": 33285,
                "axLatency": 195461,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "nonAxLatency": 29857,
                "axLatency": 235314,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "nonAxLatency": 84283,
                "axLatency": 155820,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "nonAxLatency": 38604,
                "axLatency": 152269,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "nonAxLatency": 49351,
                "axLatency": 112068,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "nonAxLatency": 30803,
                "axLatency": 289066,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "nonAxLatency": 34307,
                "axLatency": 199041,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "nonAxLatency": 28104,
                "axLatency": 269691,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "nonAxLatency": 99086,
                "axLatency": 185709,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "nonAxLatency": 309614,
                "axLatency": 0,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "nonAxLatency": 470039,
                "axLatency": 0,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "nonAxLatency": 937838,
                "axLatency": 0,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "nonAxLatency": 166853,
                "axLatency": 66058,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "nonAxLatency": 39027,
                "axLatency": 120228,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "nonAxLatency": 32315,
                "axLatency": 233283,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "nonAxLatency": 34305,
                "axLatency": 186962,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "nonAxLatency": 34103,
                "axLatency": 262248,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "nonAxLatency": 77475,
                "axLatency": 205617,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "nonAxLatency": 38463,
                "axLatency": 262721,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "nonAxLatency": 33988,
                "axLatency": 161354,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "nonAxLatency": 34579,
                "axLatency": 225931,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "nonAxLatency": 34420,
                "axLatency": 176545,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "nonAxLatency": 58149,
                "axLatency": 199692,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "nonAxLatency": 33016,
                "axLatency": 166662,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "nonAxLatency": 30239,
                "axLatency": 316219,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "nonAxLatency": 32835,
                "axLatency": 193162,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "nonAxLatency": 33449,
                "axLatency": 373113,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "nonAxLatency": 32594,
                "axLatency": 169646,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "nonAxLatency": 31399,
                "axLatency": 145864,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "nonAxLatency": 41233,
                "axLatency": 196053,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "nonAxLatency": 270978,
                "axLatency": 235063,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "nonAxLatency": 238678,
                "axLatency": 0,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "nonAxLatency": 102075,
                "axLatency": 0,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "nonAxLatency": 380527,
                "axLatency": 0,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "nonAxLatency": 33147,
                "axLatency": 149785,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "nonAxLatency": 25626,
                "axLatency": 607581,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "nonAxLatency": 33703,
                "axLatency": 184352,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "nonAxLatency": 32328,
                "axLatency": 179464,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "nonAxLatency": 66224,
                "axLatency": 56857,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "nonAxLatency": 47776,
                "axLatency": 161093,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "nonAxLatency": 248494,
                "axLatency": 302174,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "nonAxLatency": 33178,
                "axLatency": 171162,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "nonAxLatency": 194601,
                "axLatency": 148097,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "nonAxLatency": 37606,
                "axLatency": 172654,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "nonAxLatency": 34972,
                "axLatency": 231081,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "nonAxLatency": 0,
                "axLatency": 177879,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "nonAxLatency": 0,
                "axLatency": 329147,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "nonAxLatency": 60885,
                "axLatency": 255615,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "nonAxLatency": 61467,
                "axLatency": 205530,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "nonAxLatency": 39841,
                "axLatency": 135666,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "nonAxLatency": 30887,
                "axLatency": 233618,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "nonAxLatency": 42288,
                "axLatency": 116198,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "nonAxLatency": 30318,
                "axLatency": 208495,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "nonAxLatency": 105526,
                "axLatency": 302302,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "nonAxLatency": 63285,
                "axLatency": 297396,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "nonAxLatency": 250568,
                "axLatency": 199347,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "nonAxLatency": 61827,
                "axLatency": 180941,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "nonAxLatency": 36108,
                "axLatency": 161914,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "nonAxLatency": 51431,
                "axLatency": 180600,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "nonAxLatency": 30855,
                "axLatency": 156556,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "nonAxLatency": 32520,
                "axLatency": 206555,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "nonAxLatency": 56543,
                "axLatency": 174076,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "nonAxLatency": 34723,
                "axLatency": 145035,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "nonAxLatency": 29427,
                "axLatency": 228906,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "nonAxLatency": 192368,
                "axLatency": 144070,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "nonAxLatency": 969070,
                "axLatency": 112968,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "nonAxLatency": 35212,
                "axLatency": 296281,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "nonAxLatency": 39648,
                "axLatency": 232599,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "nonAxLatency": 29684,
                "axLatency": 258244,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "nonAxLatency": 37307,
                "axLatency": 206020,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "nonAxLatency": 32871,
                "axLatency": 275247,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "nonAxLatency": 195432,
                "axLatency": 205780,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "nonAxLatency": 1024442,
                "axLatency": 184271,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "nonAxLatency": 0,
                "axLatency": 112919,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "nonAxLatency": 0,
                "axLatency": 236948,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "nonAxLatency": 35872,
                "axLatency": 245497,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "nonAxLatency": 33318,
                "axLatency": 187146,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "nonAxLatency": 31113,
                "axLatency": 330033,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "nonAxLatency": 215738,
                "axLatency": 102101,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "nonAxLatency": 189639,
                "axLatency": 98019,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "nonAxLatency": 43245,
                "axLatency": 90539,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "nonAxLatency": 33046,
                "axLatency": 251727,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "nonAxLatency": 31713,
                "axLatency": 356180,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "nonAxLatency": 70923,
                "axLatency": 265973,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "nonAxLatency": 631444,
                "axLatency": 242088,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "nonAxLatency": 37864,
                "axLatency": 222574,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "nonAxLatency": 34486,
                "axLatency": 299767,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "nonAxLatency": 44843,
                "axLatency": 148081,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "nonAxLatency": 36230,
                "axLatency": 253663,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "nonAxLatency": 38361,
                "axLatency": 309313,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "nonAxLatency": 44024,
                "axLatency": 294311,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "nonAxLatency": 33773,
                "axLatency": 345562,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "nonAxLatency": 37282,
                "axLatency": 174013,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "nonAxLatency": 42575,
                "axLatency": 230817,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "nonAxLatency": 40961,
                "axLatency": 98526,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "nonAxLatency": 179871,
                "axLatency": 93987,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "nonAxLatency": 373725,
                "axLatency": 101554,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "nonAxLatency": 38615,
                "axLatency": 131781,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "nonAxLatency": 42231,
                "axLatency": 156965,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "nonAxLatency": 40353,
                "axLatency": 136365,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "nonAxLatency": 166546,
                "axLatency": 137778,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "nonAxLatency": 58413,
                "axLatency": 198881,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "nonAxLatency": 211759,
                "axLatency": 65775,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "nonAxLatency": 33894,
                "axLatency": 433371,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "nonAxLatency": 33051,
                "axLatency": 289254,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "nonAxLatency": 32301,
                "axLatency": 259660,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "nonAxLatency": 33791,
                "axLatency": 196378,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "nonAxLatency": 37517,
                "axLatency": 156653,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "nonAxLatency": 31857,
                "axLatency": 246006,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "nonAxLatency": 48109,
                "axLatency": 107604,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "nonAxLatency": 43718,
                "axLatency": 118020,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "nonAxLatency": 130348,
                "axLatency": 140718,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "nonAxLatency": 40044,
                "axLatency": 135375,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "nonAxLatency": 38514,
                "axLatency": 193208,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "nonAxLatency": 37503,
                "axLatency": 182550,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "nonAxLatency": 88390,
                "axLatency": 118372,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "nonAxLatency": 82721,
                "axLatency": 108882,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "nonAxLatency": 134244,
                "axLatency": 119291,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "nonAxLatency": 48648,
                "axLatency": 218374,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "nonAxLatency": 44158,
                "axLatency": 132877,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "nonAxLatency": 163611,
                "axLatency": 113014,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "nonAxLatency": 45582,
                "axLatency": 190779,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "nonAxLatency": 35950,
                "axLatency": 296825,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "nonAxLatency": 36639,
                "axLatency": 285677,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": null
    }
})