define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ApplicationGroupTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupSummaryTemplate',

    ],function(UtilityFunctions, ScalableGroupIseTemplate, ApplicationGroupTemplate,SimLokiDatabaseActions, ScalableGroupSummaryTemplate) {

    var SCALABLEGROUP_USER_DEVICE = [{
        "name": "Lights",
        "desc": "Lights Security Group"
    }, {
        "name": "HVAC",
        "desc": "HVAC Security Group"
    }, {
        "name": "Development_Servers",
        "desc": "Development Servers Security Group"
    }, {
        "name": "Doctor",
        "desc": "grp3"
    }, {
        "name": "Guest",
        "desc": "Guest Services Security Group"
    }, {
        "name": "Developers",
        "desc": "Developer Security Group"
    }, {
        "name": "EMR",
        "desc": "grp6"
    }, {
        "name": "TrustSec_Devices",
        "desc": "TrustSec Devices Security Group"
    }, {
        "name": "Airport_Security",
        "desc": "grp10"
    }, {
        "name": "Production_Servers",
        "desc": "Production Servers Security Group"
    }, {
        "name": "Vending_Machines",
        "desc": "grp11"
    }, {
        "name": "Contractors",
        "desc": "Contractor Security Group"
    }, {
        "name": "Staff",
        "desc": "grp4"
    }, {
        "name": "BYOD",
        "desc": "BYOD Security Group"
    }, {
        "name": "Nurse",
        "desc": "grp2"
    }, {
        "name": "ACI_WebServer",
        "desc": "grp7"
    }, {
        "name": "ACI_AppServer",
        "desc": "grp5"
    }, {
        "name": "Production_Users",
        "desc": "Production User Security Group"
    }, {
        "name": "Ticketing",
        "desc": "grp8"
    }, {
        "name": "Point_of_Sale_Systems",
        "desc": "Point of Sale Security Group"
    }, {
        "name": "Test_Servers",
        "desc": "Test Servers Security Group"
    }, {
        "name": "AccessPoint",
        "desc": "AccessPoint Security Group"
    }, {
        "name": "Quarantined_Systems",
        "desc": "Quarantine Security Group"
    }, {
        "name": "PCI_Servers",
        "desc": "PCI Servers Security Group"
    }, {
        "name": "Baggage",
        "desc": "grp9"
    }, {
        "name": "Finance",
        "desc": "grp1"
    },{
        "name": "CompanyB",
        "desc": "grp1"
    },{
        "name": "CompanyA",
        "desc": "grp1"
    },{
        "name": "HR",
        "desc": "grp1"
    },{
        "name": "Guests",
        "desc": "grp1"
    },{
        "name": "Employees",
        "desc": "grp1"
    },{
        "name": "Auditors",
        "desc": "grp1"
    },{
        "name": "Camera",
        "desc": "grp1"
    },{
        "name": "ANY",
        "desc": "grp1"
    },
    {
        "name": "AP_MedicalRecords_EPG",
        "desc": "AP Security Group"
    },
    {
        "name": "Contractor_Nurses",
        "desc": "Contractor Security Group"
    },
    {
        "name": "Badge-Readers",
        "desc": "Badge Readers Group"
    },
    {
        "name": "No-Auth-Devices",
        "desc": "No Auth Devices Group"
    }];

    var SG_IP_RENDERED = [{
        "name": "fromIPACL",
        "desc": ""
    }];
    var SG_TCP_UDP = [];
    /*var SG_TCP_UDP = [{
        "name": "vkontakte",
        "ports": "692",
        "protocol": "UDP"
    }, {
        "name": "isi-gl",
        "ports": "2811",
        "protocol": "UDP"
    }, {
        "name": "dnsix",
        "ports": "167",
        "protocol": "UDP"
    }, {
        "name": "hmmp-ind",
        "ports": "20500",
        "protocol": "UDP"
    }, {
        "name": "call-of-duty",
        "ports": "2811",
        "protocol": "UDP"
    }, {
        "name": "namp",
        "ports": "167",
        "protocol": "UDP"
    }, {
        "name": "hyperwave-isp",
        "ports": "2811",
        "protocol": "UDP"
    }, {
        "name": "gsiftp",
        "ports": "692",
        "protocol": "UDP"
    }];
*/
    return {
        init: function() {
            createDefaultScalableGroup();
        }
    };

    function createDefaultScalableGroup() {
        var scalablegroupCollection = [];
        var scalablegroupCollectionSummary = [];
        var ctr = 1;
        for (var i = 0; i < SCALABLEGROUP_USER_DEVICE.length; i++) {
            var sample = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_USER_DEVICE_Template[0]));
            sample.id = UtilityFunctions.generate_uuid();
            sample.createTime = UtilityFunctions.getTimeStamp();
            sample.lastUpdateTime = UtilityFunctions.getTimeStamp();
            sample.name = SCALABLEGROUP_USER_DEVICE[i].name;
            sample.description = SCALABLEGROUP_USER_DEVICE[i].desc;
            sample.securityGroupTag = ctr++;
            // scalablegroupCollection.push(sample);
        }
        for (var i = 0; i < SG_IP_RENDERED.length; i++) {
            var sample = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_IP_RENDERED_Template[0]));
            sample.id = UtilityFunctions.generate_uuid();
            sample.createTime = UtilityFunctions.getTimeStamp();
            sample.lastUpdateTime = UtilityFunctions.getTimeStamp();
            sample.name = SG_IP_RENDERED[i].name;
            sample.description = SG_IP_RENDERED[i].desc[i];
            sample.securityGroupTag = ctr++;
            // scalablegroupCollection.push(sample);
        }
        for (var i = 0; i < SG_TCP_UDP.length; i++) {
            var sample = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_APPLICATION_Template[0]));
            sample.id = UtilityFunctions.generate_uuid();
            sample.createTime = UtilityFunctions.getTimeStamp();
            sample.lastUpdateTime = UtilityFunctions.getTimeStamp();
            sample.name = SG_TCP_UDP[i].name;
            sample.description = SG_TCP_UDP[i].desc;
            sample.networkIdentity[0].ports = SG_TCP_UDP[i].ports;
            sample.networkIdentity[0].protocol = SG_TCP_UDP[i].protocol;
            sample.securityGroupTag = ctr++;

            // scalablegroupCollection.push(sample);
        }
        // for 1.3.1 code by Karan for ScalableGroup Summary
        
        for (var i = 0; i < ScalableGroupSummaryTemplate.ScalableGroupSummaryTemplate_Template[0].acaScalableGroupSummary.length; i++) {
            var sample = JSON.parse(JSON.stringify(ScalableGroupSummaryTemplate.ScalableGroupSummaryTemplate_Template[0].acaScalableGroupSummary[i]));
            // sample.id = UtilityFunctions.generate_uuid();
            // scalablegroupCollectionSummary.push(sample);
            scalablegroupCollection.push(sample);
        }

       /*  for (var i = 0; i < AppListTemplate.Application_Template.length; i++) {
            var sample = JSON.parse(JSON.stringify(AppListTemplate.Application_Template[i]));

            sample.createTime = UtilityFunctions.getTimeStamp();
            sample.lastUpdateTime = UtilityFunctions.getTimeStamp();
            scalablegroupCollection.push(sample);
        } */



        for (var i = 0; i < ApplicationGroupTemplate.ScalableGroup_Application_Group_Template.length; i++) {
            var sample = JSON.parse(JSON.stringify(ApplicationGroupTemplate.ScalableGroup_Application_Group_Template[i]));
          //  sample.id = UtilityFunctions.generate_uuid();
            sample.createTime = UtilityFunctions.getTimeStamp();
            sample.lastUpdateTime = UtilityFunctions.getTimeStamp();
          //  sample.name ="general-misc";
            // scalablegroupCollection.push(sample);
        }


        var tJson = JSON.parse(JSON.stringify(scalablegroupCollection));
        // var tJsons = JSON.parse(JSON.stringify(scalablegroupCollectionSummary));
        console.log("final sg = "+tJson);
        SimLokiDatabaseActions.insert('scalablegroup', tJson);
        // SimLokiDatabaseActions.insert('scalablegroupsummary', tJsons);
    }
});
