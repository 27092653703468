define({
    "DeviceCategories_Template" :
        {
        "key": "device.categories",
        "attribs": [
          {
            "key": "device.family",
            "value": "Routers",
            "attribs": [
              {
                "key": "device.series",
                "value": "Cisco Network Functions Virtualization",
                "attribs": [
                  {
                    "key": "device.type",
                    "value": "Cisco 5400 Enterprise Network Compute System",
                    "attribs": [
                      {
                        "key": "hierarchy.id",
                        "value": "a7cbff5e-039c-42e8-bef2-41e580c06ce7"
                      }
                    ]
                  },
                  {
                    "key": "device.type",
                    "value": "Cisco Integrated Services Virtual Router",
                    "attribs": [
                      {
                        "key": "hierarchy.id",
                        "value": "f34f4b31-fd6b-4e41-b244-2939fce01e91",
                        "attribs": [
                          {
                            "key": "isrv.throughput",
                            "value": "ISRv-small"
                          },
                          {
                            "key": "isrv.config.memory",
                            "value": "4096"
                          },
                          {
                            "key": "isrv.config.image.profile",
                            "value": "ISRv-small"
                          },
                          {
                            "key": "nfv.isrv.order",
                            "value": "1"
                          },
                          {
                            "key": "nfv.router.type",
                            "value": "ISRV"
                          },
                          {
                            "key": "parent.hierarchy.id",
                            "value": "a7cbff5e-039c-42e8-bef2-41e580c06ce7"
                          },
                          {
                            "key": "isrv.config.storage",
                            "value": "8192"
                          },
                          {
                            "key": "isrv.config.cpu",
                            "value": "2"
                          },
                          {
                            "key": "isrv.config.latency",
                            "value": "true"
                          },
                          {
                            "key": "isrv.config.image.uuid",
                            "value": "d62fe288-d39c-4390-a382-7ac7eaea69a3"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "key": "underlay.lanConnectivity",
                "value": "L2",
                "attribs": [
                  {
                    "key": "underlay.vlan.name",
                    "value": "100",
                    "attribs": [
                      {
                        "key": "underlay.vlan.description",
                        "value": "ewre"
                      },
                      {
                        "key": "underlay.vlan.porttype",
                        "value": "access"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }

      });