define({
  "Template":
 {
  "version": "1.0",
  "response": {
    "nodes": [
        {
        "role": "ACCESS",
        "name": "AP",
        "id": "AP",
        "description": "AP Group",
        "deviceType": null,
        "platformId": null,
        "family": "APGROUP",
        "ip": null,
        "softwareVersion": null,
        "userId": null,
        "nodeType": null,
        "radioFrequency": null,
        "clients": 0,
        "count": 0,
        "healthScore": 0.00,
        "level": 2,
        "fabricGroup": null,
        "connectedDevice": null
      }
    ],
    "links": [


    ]
  }
},

  "InterfaceTemplate": {
    "clientMacAddress": "9C:DC:71:39:13:C0",
    "connectedDeviceIntName": "GigabitEthernet1/0/2",
    "duplex": "FullDuplex",
    "portMode": "trunk",
    "adminStatus": "UP"
  }

});
