define({

    "CANCEL_TEMPLATE": {
        "table_store": [
            {
                "device_name": "C9300.cisco.com",
                "device_uuid": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
                "device_ipaddress": "172.26.197.70",
                "operation": "Return License",
                "error_msg": null,
                "status": "Success"
            }
        ],
        "title": "Return License",
        "createdDate": "2018-12-19T05:19:04.973000",
        "license_level": "Return License",
        "device_type": "Cisco Catalyst 9300 Switch",
        "device_family": "Switches and Hubs",
        "status": "Success",
        "operation": "Return License"
    },

    "REGISTER_TEMPLATE": {
        "table_store": [
            {
                "device_name": "C9300.cisco.com",
                "device_uuid": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
                "device_ipaddress": "172.26.197.70",
                "operation": "sl_activated",
                "error_msg": null,
                "status": "Success"
            }
        ],
        "title": "Smart License Registration",
        "createdDate": "2018-12-19T05:29:36.878000",
        "license_level": "NA",
        "device_type": "Cisco Catalyst 9300 Switch",
        "device_family": "Switches and Hubs",
        "status": "Success",
        "operation": "sl_activated"
    },

    "DEREGISTER_TEMPLATE": {
        "table_store": [
            {
                "device_name": "C9300.cisco.com",
                "device_uuid": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
                "device_ipaddress": "172.26.197.70",
                "operation": "sl_deactivated",
                "error_msg": null,
                "status": "Success"
            }
        ],
        "title": "Smart License Deregistration",
        "createdDate": "2018-12-19T05:34:02.525000",
        "license_level": "NA",
        "device_type": "Cisco Catalyst 9300 Switch",
        "device_family": "Switches and Hubs",
        "status": "Success",
        "operation": "sl_deactivated"
    },

    "ENABLE_REG_TEMPLATE": {
        "table_store": [
            {
                "device_name": "C9300.cisco.com",
                "device_uuid": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
                "device_ipaddress": "172.26.197.70",
                "operation": "Install SLR",
                "error_msg": null,
                "status": "Success"
            }
        ],
        "title": "License Reservation",
        "createdDate": "2018-12-19T05:49:36.333000",
        "license_level": "License Reservation",
        "device_type": "Cisco Catalyst 9300 Switch",
        "device_family": "Switches and Hubs",
        "status": "Success",
        "operation": "Install SLR"
    },

    "PROGRESS_REQUESTCODES": {
        "deviceFamily": "Switches and Hubs",
        "progress": "Request code fetch completed",
        "deviceIP": "172.26.197.70",
        "request_code": "CM-ZC9300-48UXM:FCW2152L03D-AK9A6sMTr-77",
        "licenseLevel": "REQUEST_CODES",
        "deviceId": "50323275",
        "mode": null,
        "deviceType": "Cisco Catalyst 9300 Switch",
        "deviceName": "C9300.cisco.com",
        "operation": "REQUEST_CODES",
        "parentTask": null,
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec"
    },

    "PROCESS_AUTORIZATIONCODES": {
        "deviceFamily": "Switches and Hubs",
        "progress": "1 reservation request processed successfully",
        "deviceIP": "172.26.197.70",
        "state": "SPECIFIC",
        "request_code": "CM-ZC9300-48UXM:FCW2152L03D-AK9A6sMTr-77",
        "licenseLevel": "AUTHORIZATION_CODES",
        "lic_ref_id": 50400,
        "deviceId": "50323275",
        "mode": null,
        "deviceType": "Cisco Catalyst 9300 Switch",
        "deviceName": "C9300.cisco.com",
        "operation": "SPECIFIC",
        "parentTask": null,
        "vaname": "EFT FIELD SEVT",
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec"
    },

    "PROCESS_INSTALL_LICENSE": {
        "deviceFamily": "Switches and Hubs",
        "progress": "License installed successfully with license feature sync completed",
        "deviceIP": "172.26.197.70",
        "deviceType": "Cisco Catalyst 9300 Switch",
        "deviceName": "C9300.cisco.com",
        "licenseLevel": "Install SLR",
        "operation": "Install SLR",
        "parentTask": null,
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
        "deviceId": "50323275",
        "mode": null
    },

    "PROCESS_CANCEL": {
        "deviceFamily": "Switches and Hubs",
        "progress": "License Cancel or return successfully done with license feature sync completed",
        "deviceIP": "172.26.197.70",
        "deviceType": "Cisco Catalyst 9300 Switch",
        "deviceName": "C9300.cisco.com",
        "licenseLevel": "Return License",
        "operation": "Return License",
        "parentTask": null,
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec",
        "deviceId": "50323275",
        "mode": null
    },

    "PROCESS_REGISTER": {
        "deviceFamily": "Switches and Hubs",
        "progress": "Device registration successful with CSSM, with license feature sync completed",
        "deviceIP": "172.26.197.70",
        "licenseLevel": "NA",
        "deviceId": "50323275",
        "mode": "SL",
        "deviceType": "Cisco Catalyst 9300 Switch",
        "virtual_account_name": "EFT FIELD SEVT",
        "deviceName": "C9300.cisco.com",
        "operation": "sl_activated",
        "parentTask": null,
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec"
    },

    "PROCESS_DEREGISTER": {
        "deviceFamily": "Switches and Hubs",
        "progress": "SLDeregistration Successful with CSSM, with license feature sync completed",
        "deviceIP": "172.26.197.70",
        "licenseLevel": "NA",
        "deviceId": "50323275",
        "mode": "SL",
        "deviceType": "Cisco Catalyst 9300 Switch",
        "virtual_account_name": "",
        "deviceName": "C9300.cisco.com",
        "operation": "sl_deactivated",
        "parentTask": null,
        "deviceUUId": "2c9b4251-5a2b-478e-b473-0a92b142aaec"
    },

    "PERSISTENCE": [
        {"key":"overviewStats","plugin":"cisco.dna.licenseManagement","value":"[object Object]"}
    ]//this is persistence call response

});