define({
"FAIL_TestResultsListAnalysis":
{
  "version": "1.0",
  "response": {
    "dataList": [
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579170821598,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 48.0,
        "test": "outlookserver",
        "testResult": "Connection Failure",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579170688591,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -43.0,
        "snr": 45.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579170905310,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579170843808,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -38.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579171122982,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 44.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579170947506,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 6,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -43.0,
        "snr": 43.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579170959386,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 17,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -32.0,
        "snr": 55.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579171192549,
        "txRate": 7.8E8,
        "rxRate": 6000000.0,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -33.0,
        "snr": 56.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579172462431,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 18,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -38.0,
        "snr": 48.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579171620538,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 3,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 51.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579172033187,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 43.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579171635014,
        "txRate": 1.17E8,
        "rxRate": 1.2E7,
        "txRetries": 23,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -44.0,
        "snr": 44.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579172836831,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -44.0,
        "snr": 44.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579172837037,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -40.0,
        "snr": 45.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579172107560,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 5,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 54.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579172811766,
        "txRate": 7.8E8,
        "rxRate": 3.51E8,
        "txRetries": 3,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 50.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579172109816,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 12,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -33.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579172109966,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 8,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 53.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579171073472,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 46.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579172206497,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 4,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 50.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579172298137,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 10,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 58.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579172674995,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 51.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579172374450,
        "txRate": 1.3E8,
        "rxRate": 7.8E7,
        "txRetries": 5,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 48.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579172672480,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 44.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579172900530,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 6,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 40.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579172674260,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 5,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 50.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579173330626,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -47.0,
        "snr": 39.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579173054205,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 6,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 55.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579173163862,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -32.0,
        "snr": 55.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579173167291,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 8,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579173407284,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 51.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579173395666,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 19,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 51.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579173545545,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 24,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -44.0,
        "snr": 42.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579173556669,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 10,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -29.0,
        "snr": 52.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579173695059,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 13,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -25.0,
        "snr": 55.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579173695205,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 6,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 58.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579173932540,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -40.0,
        "snr": 51.0,
        "test": "outlookserver",
        "testResult": "Success",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579173878734,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -35.0,
        "snr": 51.0,
        "test": "mailserver",
        "testResult": "Connection Failure",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579173893867,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 3,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 44.0,
        "test": "mailserver",
        "testResult": "Server Unreachable",
        "errorCode": "6003.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579173999196,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 16,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 55.0,
        "test": "mailserver",
        "testResult": "Success",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579173999196,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 16,
        "channel": 0,
        "testCount": null
      }
    ]
  }
},
"PASS_TestResultsListAnalysis":
{
  "version": "1.0",
  "response": {
    "dataList": [
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -40.0,
        "snr": 51.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579161783624,
        "txRate": 4.68E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:61",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Deauthenticated because Sensor is leaving",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579162405533,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 53.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579162542836,
        "txRate": 5.26E8,
        "rxRate": 5.85E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -42.0,
        "snr": 47.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579161772290,
        "txRate": 7.02E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579162036232,
        "txRate": 7.8E7,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -43.0,
        "snr": 42.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579162097375,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:01",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 53.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579162042995,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -43.0,
        "snr": 46.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579161996203,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -35.0,
        "snr": 52.0,
        "test": "dns",
        "testResult": "Undefined Error",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579162249730,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -32.0,
        "snr": 55.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579162159522,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:01",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -35.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579162270429,
        "txRate": 1.04E8,
        "rxRate": 5.2E7,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -32.0,
        "snr": 51.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579162241465,
        "txRate": 1.3E8,
        "rxRate": 7.8E7,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 55.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579162213177,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:01",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 51.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579162297345,
        "txRate": 7.8E7,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 55.0,
        "test": "dns",
        "testResult": "Undefined Error",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579162298244,
        "txRate": 4.68E8,
        "rxRate": 5.85E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -42.0,
        "snr": 49.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579162649735,
        "txRate": 4.68E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -42.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579162500830,
        "txRate": 4.68E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -33.0,
        "snr": 56.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579162557018,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:60",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -35.0,
        "snr": 55.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579163726423,
        "txRate": 7.02E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 51.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579162621522,
        "txRate": 4.8E7,
        "rxRate": 1.2E7,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -44.0,
        "snr": 47.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579162773937,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e1",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 50.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579162860301,
        "txRate": 7.8E7,
        "rxRate": 1.2E7,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -40.0,
        "snr": 46.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579163047318,
        "txRate": 5.85E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 50.0,
        "test": "dns",
        "testResult": "DNS: Name resolution failure",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579163264125,
        "txRate": 7.8E7,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:01",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -35.0,
        "snr": 49.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579163554956,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 3,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 50.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579163505538,
        "txRate": 7.8E7,
        "rxRate": 1.2E7,
        "txRetries": 12,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 50.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579163594819,
        "txRate": 4.8E7,
        "rxRate": 6000000.0,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 50.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579163649021,
        "txRate": 4.68E8,
        "rxRate": 5.85E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -38.0,
        "snr": 52.0,
        "test": "dns",
        "testResult": "Server Unreachable",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579163509385,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -30.0,
        "snr": 59.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579164027968,
        "txRate": 7.02E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 53.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579163755406,
        "txRate": 5.85E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:61",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 53.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579165117798,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:61",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -47.0,
        "snr": 40.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579164319156,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 53.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579163811905,
        "txRate": 5.85E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579164073621,
        "txRate": 3.51E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6e",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -33.0,
        "snr": 56.0,
        "test": "dns",
        "testResult": "Server Unreachable",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579165116101,
        "txRate": 4.68E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -32.0,
        "snr": 54.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579164290644,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 52.0,
        "test": "dns",
        "testResult": "No DHCP ACK Received",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579164593435,
        "txRate": 7.02E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -38.0,
        "snr": 46.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579164823720,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 1,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ee",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID_PSK",
        "band": "5 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -43.0,
        "snr": 48.0,
        "test": "dns",
        "testResult": "Success",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579164821616,
        "txRate": 5.26E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "www.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 49.0,
        "test": "dns",
        "testResult": "Deauthenticated because Sensor is leaving",
        "errorCode": "0.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579164823293,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 3,
        "channel": 0,
        "testCount": null
      }
    ]
  }
},
"TotalFailure_TestResults":
{
  "version": "1.0",
  "response": {
    "dataList": [
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -33.0,
        "snr": 52.0,
        "test": "outlookserver",
        "testResult": "OWA: DNS Resolve Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579172109966,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 8,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:ef",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -36.0,
        "snr": 53.0,
        "test": "mailserver",
        "testResult": "OWA: DNS Resolve Fail",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579171073472,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -39.0,
        "snr": 46.0,
        "test": "mailserver",
        "testResult": "OWA: DNS Resolve Fail",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579172206497,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 4,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -34.0,
        "snr": 50.0,
        "test": "mailserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718912,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.124.dummy.com",
        "radioMac": "70:f3:5a:7c:87:a0",
        "ethernetMac": "70:f3:5a:7c:08:60",
        "serialNumber": "KWC214106R7",
        "testTime": 1579172298137,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 10,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7b:d8:a0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "f4:db:e6:87:21:6f",
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP4800.90A4",
        "apModel": "AIR-AP4800-B-K9",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 58.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719091,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.125.dummy.com",
        "radioMac": "70:f3:5a:7b:d8:a0",
        "ethernetMac": "70:f3:5a:7a:5c:98",
        "serialNumber": "KWC214106B2",
        "testTime": 1579172674995,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 0,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:83:00",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -31.0,
        "snr": 51.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718985,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.123.dummy.com",
        "radioMac": "70:f3:5a:7c:83:00",
        "ethernetMac": "70:f3:5a:7c:07:38",
        "serialNumber": "KWC214106Q6",
        "testTime": 1579172374450,
        "txRate": 1.3E8,
        "rxRate": 7.8E7,
        "txRetries": 5,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8b:e0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:0f",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "5 GHz",
        "server": "aer-mx-01.cisco.com",
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -37.0,
        "snr": 48.0,
        "test": "mailserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "6004.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718787,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.121.dummy.com",
        "radioMac": "70:f3:5a:7c:8b:e0",
        "ethernetMac": "70:f3:5a:7c:09:70",
        "serialNumber": "KWC214106S5",
        "testTime": 1579172672480,
        "txRate": 7.8E8,
        "rxRate": 7.8E8,
        "txRetries": 2,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:78:6b:60",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 44.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Enabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719072,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.122.dummy.com",
        "radioMac": "70:f3:5a:78:6b:60",
        "ethernetMac": "70:f3:5a:78:01:40",
        "serialNumber": "KWC212100QQ",
        "testTime": 1579172900530,
        "txRate": 1.3E8,
        "rxRate": 1.3E8,
        "txRetries": 6,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:8a:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2f:95:00",
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP9120.CF6C",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -41.0,
        "snr": 40.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": false,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005719003,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.120.dummy.com",
        "radioMac": "70:f3:5a:7c:8a:c0",
        "ethernetMac": "70:f3:5a:7c:09:28",
        "serialNumber": "KWC214106RW",
        "testTime": 1579172674260,
        "txRate": 1.04E8,
        "rxRate": 1.3E8,
        "txRetries": 5,
        "channel": 0,
        "testCount": null
      },
      {
        "sensorMac": "70:f3:5a:7c:87:c0",
        "sensorName": "AP1800S_Sensor_01",
        "bssid": "08:4f:f9:2e:db:e0",
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP9120.B848",
        "apModel": "C9120AXI-B",
        "ssid": "@CorpSSID",
        "band": "2.4 GHz",
        "server": null,
        "port": 0,
        "protocol": null,
        "direction": null,
        "rssi": -44.0,
        "snr": 42.0,
        "test": "outlookserver",
        "testResult": "OWA: Connection Fail",
        "errorCode": "9006.0",
        "sensorStatus": null,
        "ssh": "Disabled",
        "led": true,
        "sensorLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "backhaulType": "WIRED",
        "lastSeen": 1580005718894,
        "sensorType": "FARALLON",
        "sensorVersion": "8.8.500.64",
        "sensorIp": "10.13.5.126.dummy.com",
        "radioMac": "70:f3:5a:7c:87:c0",
        "ethernetMac": "70:f3:5a:7c:08:68",
        "serialNumber": "KWC214106R8",
        "testTime": 1579173556669,
        "txRate": 1.17E8,
        "rxRate": 1.3E8,
        "txRetries": 10,
        "channel": 0,
        "testCount": null
      }

    ]
  }
}


})