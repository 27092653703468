define({
    "NetworkApplicationCategory_Template": [  
   {  
      "id":"14119c02-3825-4f8e-99f2-17398113b839",
      "instanceId":4011,
      "authEntityId":4011,
      "displayName":"64929a2c[browsing]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"browsing",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"14e740a0-1120-4a23-be3d-30c00715b4e8",
      "instanceId":4038,
      "authEntityId":4038,
      "displayName":"64929a2c[inter-process-rpc]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"inter-process-rpc",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"19ed80f2-1a50-49a0-9898-f60bdb2e02d8",
      "instanceId":4043,
      "authEntityId":4043,
      "displayName":"64929a2c[social-networking]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"social-networking",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"1c2b35ce-7cd2-4aba-afba-6b4844bfcd0c",
      "instanceId":4053,
      "authEntityId":4053,
      "displayName":"64929a2c[industrial-protocols]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"industrial-protocols",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"22c5a62a-855b-415c-b206-c2a64f90eda4",
      "instanceId":4009,
      "authEntityId":4009,
      "displayName":"64929a2c[other]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"other",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"2d40695b-9522-4b3c-babd-7c90c29720a7",
      "instanceId":4040,
      "authEntityId":4040,
      "displayName":"64929a2c[file-sharing]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"file-sharing",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"4db3c8a6-6277-4cbb-8907-ef9873c4800d",
      "instanceId":4045,
      "authEntityId":4045,
      "displayName":"64929a2c[email]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"email",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"54143219-698f-49d0-8439-648e10a82d90",
      "instanceId":4034,
      "authEntityId":4034,
      "displayName":"64929a2c[consumer-internet]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"consumer-internet",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"700f78b5-70a6-412e-ac82-03cfd964a31b",
      "instanceId":4026,
      "authEntityId":4026,
      "displayName":"64929a2c[instant-messaging]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"instant-messaging",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"7184277a-0389-434f-ad37-0f507c769809",
      "instanceId":4059,
      "authEntityId":4059,
      "displayName":"64929a2c[anonymizers]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"anonymizers",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"89ff5fa9-8515-43ab-bea7-df988bef8b1e",
      "instanceId":4022,
      "authEntityId":4022,
      "displayName":"64929a2c[backup-and-storage]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"backup-and-storage",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"a2924cce-7c53-4b93-858d-f812cee16599",
      "instanceId":4028,
      "authEntityId":4028,
      "displayName":"64929a2c[consumer-messaging]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"consumer-messaging",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"a33dff43-1f86-4e2c-a8fd-17437efd3e16",
      "instanceId":4052,
      "authEntityId":4052,
      "displayName":"64929a2c[epayement]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"epayement",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"b1b80cb3-4351-4c3a-bd14-fb449d4c17e8",
      "instanceId":4030,
      "authEntityId":4030,
      "displayName":"64929a2c[software-updates]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"software-updates",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"b8953a8e-c1ac-44bb-9a34-6689b509db54",
      "instanceId":4015,
      "authEntityId":4015,
      "displayName":"64929a2c[net-admin]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"net-admin",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"bbd8c9df-ab9f-4533-92ac-9d33d2324c31",
      "instanceId":4018,
      "authEntityId":4018,
      "displayName":"64929a2c[business-and-productivity-tools]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"business-and-productivity-tools",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"cd962657-6b52-49f9-9ca9-18e2414945c2",
      "instanceId":4024,
      "authEntityId":4024,
      "displayName":"64929a2c[consumer-streaming]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"consumer-streaming",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"cffaa525-5f44-4624-b3ac-54821df4deb4",
      "instanceId":4047,
      "authEntityId":4047,
      "displayName":"64929a2c[database]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"database",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"d38d784c-adba-4525-988e-ac17022ae28a",
      "instanceId":4041,
      "authEntityId":4041,
      "displayName":"64929a2c[gaming]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"gaming",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"df03e948-e29b-49f7-b13c-107915620263",
      "instanceId":4020,
      "authEntityId":4020,
      "displayName":"64929a2c[voice-and-video]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"voice-and-video",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"e69c581a-7c56-4821-9327-9cda680035ce",
      "instanceId":4013,
      "authEntityId":4013,
      "displayName":"64929a2c[consumer-file-sharing]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"consumer-file-sharing",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"e7664ec0-c553-48ca-af9b-8f0db6f6c5a1",
      "instanceId":4035,
      "authEntityId":4035,
      "displayName":"64929a2c[layer3-over-ip]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"layer3-over-ip",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   },
   {  
      "id":"f504b4a9-93db-426b-8e35-f1f535df9c99",
      "instanceId":4055,
      "authEntityId":4055,
      "displayName":"64929a2c[trojan]",
      "authEntityClass":1687329324,
      "deployPending":"NONE",
      "instanceVersion":0,
      "deployed":false,
      "isStale":false,
      "name":"trojan",
      "provisioningState":"UNKNOWN",
      "resourceVersion":0,
      "type":"networkapplicationcategory",
      "cfsChangeInfo":[  

      ]
   }
]

});
