//Cisco Catalyst 9300L Switch Stack
define({
    "runningImageList": [
      {
        "name": "cat9k_iosxe.17.09.01.SPA.bin",
        "imageUuid": "c7c3adac-bbbb-416f-9e8b-21704458702b",
        "version": "17.9.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "true",
        "md5Checksum": "fdb9c92bae37f9130d0ee6761afe2919",
        "shaCheckSum": "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
        "createdTime": "2022-08-01 14:31:12.0",
        "applicationType": null,
        "feature": "",
        "fileServiceId": "59fbd410-539c-1111-a3a0-fa8071f39733",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.09.01.SPA.bin",
        "imageSourceType": "Imported from File System",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 18097396,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 1,
        "imageAttributes": [
          {
              "propertyName": "MEDIA",
              "propertyValue": "5"
          },{
              "propertyName": "COMPRESSION_CODE",
              "propertyValue": "-1"
          },{
              "propertyName": "deviceMNEId",
              "propertyValue": "23030007"
          },{
              "propertyName": "metadata_trans_id",
              "propertyValue": "330079917"
          },{
              "propertyName": "MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "image_description",
              "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
          },{
              "propertyName": "DEFAULT_BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "release_fcs_date",
              "propertyValue": "01/Aug/2022"
          },{
              "propertyName": "BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "encryption_software_indicator",
              "propertyValue": "Y"
          },{
              "propertyName": "image_size",
              "propertyValue": "901741775"
          },{
              "propertyName": "field_notices",
              "propertyValue": "Field Notices"
          },{
              "propertyName": "DEFAULT_MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FEATURE",
              "propertyValue": ""
          },{
              "propertyName": "DEFAULT_RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "min_dram",
              "propertyValue": "8192"
          },{
              "propertyName": "Description",
              "propertyValue": "Cisco IOS Software, IOS-XE Software"
          },{
              "propertyName": "release_doc_name",
              "propertyValue": "Release Notes for Amsterdam-17.9.1"
          },{
              "propertyName": "min_flash",
              "propertyValue": "16384"
          },{
              "propertyName": "image_guid",
              "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
          },{
              "propertyName": "software_type_id",
              "propertyValue": "282046477"
          },{
              "propertyName": "GAIA_FEATURE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FULL_VERSION",
              "propertyValue": "17.09.01.0.178"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
              "taggedGolden": true,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccorecommended": false,
        "ccolatest": true,
        "ccoreverseSync": true
      },{
        "name": "cat9k_iosxe.17.06.01.SPA.bin",
        "imageUuid": "c7c3adac-fed2-416f-9e8b-21704458702b",
        "version": "17.6.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "fdb9c92bae37f9130d0ee6761afe2919",
        "shaCheckSum": "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
        "createdTime": "2021-08-14 18:31:12.0",
        "applicationType": null,
        "feature": "",
        "fileServiceId": "59fbd410-539c-1532-a3a0-fa8071f39733",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.06.01.SPA.bin",
        "imageSourceType": "Imported from File System",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 18097396,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 1,
        "imageAttributes": [
          {
              "propertyName": "MEDIA",
              "propertyValue": "5"
          },{
              "propertyName": "COMPRESSION_CODE",
              "propertyValue": "-1"
          },{
              "propertyName": "deviceMNEId",
              "propertyValue": "23030007"
          },{
              "propertyName": "metadata_trans_id",
              "propertyValue": "330079917"
          },{
              "propertyName": "MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "image_description",
              "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
          },{
              "propertyName": "DEFAULT_BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "release_fcs_date",
              "propertyValue": "05/Mar/2021"
          },{
              "propertyName": "BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "encryption_software_indicator",
              "propertyValue": "Y"
          },{
              "propertyName": "image_size",
              "propertyValue": "901741775"
          },{
              "propertyName": "field_notices",
              "propertyValue": "Field Notices"
          },{
              "propertyName": "DEFAULT_MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FEATURE",
              "propertyValue": ""
          },{
              "propertyName": "DEFAULT_RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "min_dram",
              "propertyValue": "8192"
          },{
              "propertyName": "Description",
              "propertyValue": "Cisco IOS Software, IOS-XE Software"
          },{
              "propertyName": "release_doc_name",
              "propertyValue": "Release Notes for Amsterdam-17.3.3"
          },{
              "propertyName": "min_flash",
              "propertyValue": "16384"
          },{
              "propertyName": "image_guid",
              "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
          },{
              "propertyName": "software_type_id",
              "propertyValue": "282046477"
          },{
              "propertyName": "GAIA_FEATURE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FULL_VERSION",
              "propertyValue": "17.03.03.0.4762"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccorecommended": false,
        "ccolatest": true,
        "ccoreverseSync": true
      },
      {
        "name": "cat9k_iosxe.17.03.03.SPA.bin",
        "imageUuid": "c7c3adac-fe12-416f-9e8b-21704458702b",
        "version": "17.3.3",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "89c98b1ed44cf6cb1190eca977edb9a5",
        "shaCheckSum": "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
        "createdTime": "2021-03-15 18:31:12.0",
        "applicationType": null,
        "feature": "",
        "fileServiceId": "59fbd410-539c-1532-a3a0-fa8071f39733",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
        "imageSourceType": "Imported from File System",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 901741775,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 1,
        "imageAttributes": [
          {
              "propertyName": "MEDIA",
              "propertyValue": "5"
          },{
              "propertyName": "COMPRESSION_CODE",
              "propertyValue": "-1"
          },{
              "propertyName": "deviceMNEId",
              "propertyValue": "23030007"
          },{
              "propertyName": "metadata_trans_id",
              "propertyValue": "330079917"
          },{
              "propertyName": "MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "image_description",
              "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
          },{
              "propertyName": "DEFAULT_BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "release_fcs_date",
              "propertyValue": "05/Mar/2021"
          },{
              "propertyName": "BOOTROM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "encryption_software_indicator",
              "propertyValue": "Y"
          },{
              "propertyName": "image_size",
              "propertyValue": "901741775"
          },{
              "propertyName": "field_notices",
              "propertyValue": "Field Notices"
          },{
              "propertyName": "DEFAULT_MINFLASHSIZE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FEATURE",
              "propertyValue": ""
          },{
              "propertyName": "DEFAULT_RAM",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "min_dram",
              "propertyValue": "8192"
          },{
              "propertyName": "Description",
              "propertyValue": "Cisco IOS Software, IOS-XE Software"
          },{
              "propertyName": "release_doc_name",
              "propertyValue": "Release Notes for Amsterdam-17.3.3"
          },{
              "propertyName": "min_flash",
              "propertyValue": "16384"
          },{
              "propertyName": "image_guid",
              "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
          },{
              "propertyName": "software_type_id",
              "propertyValue": "282046477"
          },{
              "propertyName": "GAIA_FEATURE",
              "propertyValue": "UNKNOWN"
          },{
              "propertyName": "FULL_VERSION",
              "propertyValue": "17.03.03.0.4762"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccorecommended": false,
        "ccolatest": true,
        "ccoreverseSync": true
      },
      {
        "name": "cat9k_iosxe.17.03.01.SPA.bin",
        "imageUuid": "c56af3d1-8989-4d1a-8e1a-343acac2356a",
        "version": "17.3.1",//Amsterdam-17.3.1
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "b90c6ef495af2ed6f9dcfe98455622eb",
        "shaCheckSum": "25c2fd958e35fb0b5e62d2617610548bd361df234bda52803ce6027bce26b7d9404df558e85195767b893fbf4d90315914a2d8c5e68eebec30371eef01168533",
        "createdTime": "2020-10-16 14:14:00.00",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "d15d786c-3213-43e8-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 892195673,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "min_flash",
            "propertyValue": "16384"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A394EEF98D81"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "09/Aug/2020"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "892195673"
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "8192"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "153426088"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "542549"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "286322029"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat9k_iosxe.17.02.01.SPA.bin",
        "imageUuid": "c56af3d1-9898-4d1a-8e1a-343acac2356a",
        "version": "17.2.1",//Amsterdam-17.2.1
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "51ca433438ef3cd2cf330595a90abc45",
        "shaCheckSum": "7dc3d644aca4872cd3e2ffe8cb28064fafc04c76ce087639fe1d11f28e5a1209a5c2f7b113c1425122738e65cef9eb62cc37ae9a72bb7d20b65809ad39024a99",
        "createdTime": "2020-04-29 14:11:00.00",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 835079148 ,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "min_flash",
            "propertyValue": "16384"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A394EEF98D81"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "28/Mar/2020"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "835079148 "
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "8192"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9500/9600 Universal"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "153426088"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "542549"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "286322029"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "cat9k_iosxe.17.01.01.SPA.bin",
        "imageUuid": "c56af3d1-8901-4d1a-8e1a-343b51b2356a",
        "version": "17.1.1",//Amsterdam-17.1.1
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "51ca433438ef3cd2cf330595a90abc45",
        "shaCheckSum": "7dc3d644aca4872cd3e2ffe8cb28064fafc04c76ce087639fe1d11f28e5a1209a5c2f7b113c1425122738e65cef9eb62cc37ae9a72bb7d20b65809ad39024a99",
        "createdTime": "2019-11-23 10:00:00.00",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "CAT9K",
        "vendor": "CISCO",
        "filesize": 835079148 ,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "min_flash",
            "propertyValue": "16384"
          },
          {
            "propertyName": "image_guid",
            "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A394EEF98D81"
          },
          {
            "propertyName": "release_fcs_date",
            "propertyValue": "23/Nov/2019"
          },
          {
            "propertyName": "image_size",
            "propertyValue": "835079148 "
          },
          {
            "propertyName": "min_dram",
            "propertyValue": "8192"
          },
          {
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9500/9600 Universal"
          },
          {
            "propertyName": "metadata_trans_id",
            "propertyValue": "153426088"
          },
          {
            "propertyName": "deviceMNEId",
            "propertyValue": "542549"
          },
          {
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
          },
          {
            "propertyName": "mdf_id",
            "propertyValue": "286322029"
          }
        ],
        "isSMUApplicable": true,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286322029",
            "productName": "Cisco Catalyst 9300L Switch Stack",
            "productId": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-24UXG-4X"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      }
    ]
});