define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/PolicyAnalytics/PolicyEndPointAnalyticsTemplate'

    ],function(UtilityFunctions,SimLokiDatabaseActions, PolicyEndPointAnalyticsTemplate ) {

    
    return {
        init: function() {
            createEndpointAnalyticsData();
        }
    };
    // added by Karan for Policy-->EndPointAnalytics
    function createEndpointAnalyticsData() {
        var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.logicalgroup_tag_Template ));
        SimLokiDatabaseActions.insert('policy-endpoint-tag', data);
        var data1 = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.activeEndpointsFilter_Template ));
        SimLokiDatabaseActions.insert('policy-endpoint-inventory', data1);
        var data2 = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.logicalGroup_deviceTypeLables_Template.data ));
        SimLokiDatabaseActions.insert('policy-endpoint-lable', data2);
    }
});
