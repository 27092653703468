define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issueSettings/issueSettings',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimAssuranceDataGenerator'
 ], function(SimLokiDatabaseActions, UtilityFunctions, IssueSettings, SimAssuranceDataGenerator) {

 return {
      init: function(){
         var tJsonArr = JSON.parse(JSON.stringify( IssueSettings.Template));
         SimLokiDatabaseActions.insert( 'issueSettings', tJsonArr );

         var issueDetails = JSON.parse(JSON.stringify( IssueSettings.IssueDetailsTemplate));
         SimLokiDatabaseActions.insert( 'assuranceIssueDetails', issueDetails );
      },

      getCategories: function() {
        return  {"version":"1.0","response":{"thresholdCategory":[
                    {"categoryDisplayName":"Routers","categoryDisplayKey":"Routers"},
                    {"categoryDisplayName":"Switches And Hubs","categoryDisplayKey":"Switches_And_Hubs"},
                    {"categoryDisplayName":"Wireless Controllers","categoryDisplayKey":"Wireless_Controllers"},
                    {"categoryDisplayName":"Unified AP","categoryDisplayKey":"Unified_AP"},
                    {"categoryDisplayName":"Wired Clients","categoryDisplayKey":"Wired_Clients"},
                    {"categoryDisplayName":"Wireless Clients","categoryDisplayKey":"Wireless_Clients"},
                    {"categoryDisplayName":"Applications","categoryDisplayKey":"Applications"},
                    {"categoryDisplayName":"Sensors","categoryDisplayKey":"Sensors"}]}}
      },

      getIssues : function(urlAction) {
        var issueSettingsData =  SimLokiDatabaseActions.getFilteredRecordHandler('issueSettings', { 'role': urlAction.filter.role })
        return issueSettingsData[0].value
      },

      updateIssue: function(urlAction) {
            var restPayload=urlAction.restPayload;
            var issueSettingsData =  SimLokiDatabaseActions.getFilteredRecordHandler('issueSettings', { 'role': urlAction.restPayload.role })
            var isIssueStatusChanged = false, changeObjs = [], kpis = [], thresholds = [], isTDefs = [];
            issueSettingsData[0].value.response.categories.forEach(function (categoryItem){
                var issuesList = categoryItem.issues;
                issuesList.forEach(function (issue){
                    if(issue.name == restPayload.issueName){
                        issue.enabled = restPayload.enabled;
                        issue.priority = restPayload.priority;
                        let isDefault = true;
                        if(issue.priority!=issue.defaultPriority) {
                            isDefault = false;
                        }
                        else if(issue.enabled=='false'){
                            isDefault = false;
                        }
                        if(restPayload.kpiList.length != 0){
//                            let issueDetails =  SimLokiDatabaseActions.getFilteredRecordHandler('assuranceIssueDetails', {'$and': [ { 'role': urlAction.restPayload.role}, {'kpi': urlAction.restPayload.kpiList[0].kpi }]});
                            for(var i=0; i<restPayload.kpiList.length; i++) {
                                kpis[i] = restPayload.kpiList[i].kpi;
                                thresholds[i] = restPayload.kpiList[i].issueThreshold.tValue;
                                if(restPayload.kpiList[i].issueThreshold.tDefaultValue!=restPayload.kpiList[i].issueThreshold.tValue) {
                                    isDefault = false;
                                    isTDefs[i] = false;
                                } else {
                                    isTDefs[i] = true;
                                }
                            }
                        }
                        var currIssueStatus = issue.status;
                        if(!isDefault){
                            issue.status = 'Custom';
                        } else {
                            issue.status = 'Default';
                        }
                        let date = new Date();
                        issue.lastUpdatedTime = date.getTime();

                        var changeObj ={};
                        changeObj['defPriority'] = issue.defaultPriority;
                        changeObj['issueName'] = urlAction.restPayload.issueName;
                        changeObj['isEnabled'] = issue.enabled;
                        changeObj['priority'] = issue.priority;
                        changeObj['role'] = urlAction.restPayload.role;
                        changeObj['status'] = issue.status;
                        changeObj['timestamp'] = new Date();
                        if(kpis.length>0) {
                            for(var i=0;i<kpis.length;i++) {
                                var obj = JSON.parse(JSON.stringify(changeObj));
                                obj['kpi'] = kpis[i];
                                obj['threshold'] = thresholds[i];
                                obj['isTDefs'] = isTDefs[i];
                                changeObjs.push(obj);
                            }
                        } else {
                            changeObj['isTDefs'] = isDefault;
                            changeObjs.push(changeObj);
                        }

                        return false;
                    }
                })
            })
            SimLokiDatabaseActions.update('issueSettings',issueSettingsData);
            if(restPayload.kpiList.length != 0){
                var issueSettingsDetails =  SimLokiDatabaseActions.getFilteredRecordHandler('assuranceIssueDetails', { 'role': urlAction.restPayload.role});
                for(var i=0; i<restPayload.kpiList.length; i++) {
                    issueSettingsDetails = issueSettingsDetails.filter(function(v){
                        return v.kpi && v.kpi.split(',').indexOf(restPayload.kpiList[i].kpi)>-1 });
                }
                if(issueSettingsDetails.length>0){
                    for(var i=0; i<restPayload.kpiList.length; i++) {
                        for(var j=0; j<issueSettingsDetails[0].value.kpiList.length; j++) {
                            if(issueSettingsDetails[0].value.kpiList[j].kpi == restPayload.kpiList[i].kpi) {
                                issueSettingsDetails[0].value.kpiList[j].issueThreshold.tValue = restPayload.kpiList[i].issueThreshold.tValue;
                            }
                        }
                    }
                }
                SimLokiDatabaseActions.update('assuranceIssueDetails',issueSettingsDetails);
            }

            //update custom settings table
            var priority = updateCustomisedSetting(changeObjs, restPayload);
            if(priority!=undefined) {
                updatePriorityForExistingIssues(changeObjs[0].issueName, changeObjs[0].role, priority);
            }
            //creation of new issues is only for issues with threshold updated. 
            //(As per discussion, we will reduce the threshold so that existing issue also satisfying the updated value of threshold)
            SimAssuranceDataGenerator.deleteCustomIssue(changeObjs[0].issueName, changeObjs[0].role);
            SimAssuranceDataGenerator.createCustomIssue(changeObjs[0].issueName, changeObjs[0].role, changeObjs[0].isEnabled);

            return {"code":4,"type":"ok","message":"KPI update succeeded "}
      },

      getIssueDetails: function(urlAction) {
            var issueSettingsDetails =  SimLokiDatabaseActions.getFilteredRecordHandler('assuranceIssueDetails', {'$and': [ { 'role': urlAction.filter.deviceRole}, {'kpi': urlAction.filter.kpi }]});
            return issueSettingsDetails[0].value
      }
  }

  function updateCustomisedSetting(changeObjs, restPayload) {
    var records = SimLokiDatabaseActions.getAll('issueSettingCustomised');
    if(records.length && records.length>0) {
        var filteredRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', 
            {'$and': [{'role': changeObjs[0].role}, {'issueName': changeObjs[0].issueName}] });
        if(filteredRecord.length==0) {
            //no existing record for this issue
            SimLokiDatabaseActions.insert('issueSettingCustomised', changeObjs);
        } else {
            for(var i=0; i<filteredRecord.length; i++) {
                //update priorities of existing records with same/different kpi, of the same issue
                filteredRecord[i].isEnabled = changeObjs[0].isEnabled;
                filteredRecord[i].priority = changeObjs[0].priority;
                filteredRecord[i].timestamp = changeObjs[0].timestamp;
            }
            SimLokiDatabaseActions.update('issueSettingCustomised', filteredRecord);

            if(restPayload.kpiList && restPayload.kpiList.length>0) {
                for(var i=0; i<changeObjs.length; i++) {
                    filteredRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', 
                        {'$and': [{'role': changeObjs[0].role}, {'issueName': changeObjs[0].issueName}, {'kpi':changeObjs[i].kpi}] });
                    if(filteredRecord.length>0) {
                        if(changeObjs[i].isTDefs) {
                            //back to default value
                            SimLokiDatabaseActions.delete('issueSettingCustomised', filteredRecord);
                        } else {
                            //update existing kpi value
                            filteredRecord[0].threshold = changeObjs[i].threshold;
                            filteredRecord[0].timestamp = changeObjs[i].timestamp;
                            SimLokiDatabaseActions.update('issueSettingCustomised', filteredRecord);
                        }
                    } else {
                        //issue with this kpi doesnt exist
                        SimLokiDatabaseActions.insert('issueSettingCustomised', changeObjs[i]);
                    }
                }
            }

            //only priority is updated on issue with only priority and no threshold is updating back to default
            filteredRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', 
                {'$and': [{'role': changeObjs[0].role}, {'issueName': changeObjs[0].issueName}] });
            if(filteredRecord.length==1 && changeObjs[0].isTDefs) {
                SimLokiDatabaseActions.delete('issueSettingCustomised', filteredRecord);
            }
        }
    } else {
        //first insertion to the table
        SimLokiDatabaseActions.insert('issueSettingCustomised', changeObjs);
    }

    var filteredRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', {'issueName': changeObjs[0].issueName});
    if(filteredRecord.length>0) {
        return filteredRecord[0].priority;
    } else {
        return undefined;//return 'P2';
    }
  }

  function updatePriorityForExistingIssues(issueName, role, priority) {
      //var records1 = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': [{'role': role}, {'issueName': issueName}] });
      var records1 = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'issueName': issueName});
      if(records1.length>0) {
        records1.forEach(function (issue) {
            issue.priority = priority;
        });
      }
      SimLokiDatabaseActions.update('issue', records1);
      /* var records2 = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', {'$and': [{'role': role}, {'issueName': issueName}] });
      if(records2.length>0) {
        records2.forEach(function (issue) {
            issue.priority = priority;
        });
      }
      SimLokiDatabaseActions.update('customIssue', records2); */
  }

});
