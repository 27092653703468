define({
  "Template": {
  "version": "1.0",
  "response": [
  {
    "id": "AWjCK9oUsIPgh8_7P9q8",
    "name": "global_wireless_client_auth_fail_client_timeout_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entity": "7C:46:85:20:80:EA",
    "groupBy": "2572a60e-c5d0-4e9a-a332-28881db6bdc8",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless client failed to connect (SSID: SSID@ToChange, AP: AP00F2.8B27.B788, Band: Band@ToChange GHz, Site: Site@ToChange) - Failed to authenticate due to Client Timeout",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "AUTH_CL_TIMEOUT_OR_RF",
    "timestamp": 1549445706593,
    "description": "This client failed to complete authentication during onboarding because the WLC did not receive a response from the client during the authentication message exchanges. This client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP00F2.8B27.B788' AP in Site@ToChange. The AP is connected to 'Wlc@ToChange' WLC. This problem can happen if the wireless client doesn't respond or if there is an RF issue. The  AAA server is responding so its not a server side issue.",
    "suggestions": [
      {
        "message": "Verify the client profile for this 'SSID@ToChange' SSID to see if the profile parameters are compatible with the AAA server configuration.",
        "steps": [

        ]
      },
      {
        "message": "Verify whether the client software has gone through a recent update since this may be a recent change of behavior.",
        "steps": [

        ]
      },
      {
        "message": "Consider introducing Low RSSI Threshold to the setup since this will force the client to join an AP with a stronger signal. Also check the AP location for RF Issues. Make sure the AP is in client's line of sight.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "Interface:7C:46:85:20:80:EA:7C:46:85:20:80:EA:AUTH_CL_TIMEOUT_OR_RF:2572a60e-c5d0-4e9a-a332-28881db6bdc8:false"
      },
      {
        "key": "hostName",
        "value": "android-52b4ae5b798ca7ef"
      },
      {
        "key": "groupType",
        "value": "Site"
      },
      {
        "key": "assocTime",
        "value": "0"
      },
      {
        "key": "authTime",
        "value": "0"
      },
      {
        "key": "groupBy",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "slot",
        "value": "1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_4_WAY_KEY_TIMEOUT"
      },
      {
        "key": "previousApName",
        "value": "AP4800.8DEC"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP4800.922C"
      },
      {
        "key": "frequency",
        "value": "5.0"
      },
      {
        "key": "reasonCodes",
        "value": "233,0,103,0,0,0"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "AUTH_CL_TIMEOUT_OR_RF"
      },
      {
        "key": "aaaServerIp",
        "value": ""
      },
      {
        "key": "resultType",
        "value": "cl_FAIL"
      },
      {
        "key": "__suppression__",
        "value": "1800"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Android-Motorola"
      },
      {
        "key": "isRoaming",
        "value": "false"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "0"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "iCap_AP"
      },
      {
        "key": "dhcpServerIp",
        "value": "10.13.4.1"
      },
      {
        "key": "apMac",
        "value": "F4:DB:E6:87:39:E0"
      },
      {
        "key": "entityId",
        "value": "7C:46:85:20:80:EA"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_L2KEY_DONE"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "eventTypes",
        "value": "7,13,5,14,14,11"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "osCategory",
        "value": "Android"
      },
      {
        "key": "deviceCategory",
        "value": "Motorola"
      },
      {
        "key": "eventTimestamps",
        "value": "1549445652825,50500,3268,0,20436,10216"
      },
      {
        "key": "resultTypes",
        "value": "0,0,5,0,0,0"
      },
      {
        "key": "siteId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "_id",
        "value": "7C:46:85:20:80:EA"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-06T09:35:06.593+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "7C:46:85:20:80:EA"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
  }]
}
})