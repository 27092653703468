define({

    "PREFERENCE_TEMPLATE": {
      "staleEntryPruningInterval": 86400,
      "packetLogPruningInterval": 86400,
      "keepaliveInterval": 30,
      "deadDeclareCount": 4,
      "md5Password": "",
      "securityEnabled": false,
      "npPushEnabled": false
    },

    "SERVICE_ENTRY_TEMPLATE" : [
      {
        "id": "9151764a-e820-4cd3-bed4-714a73f31057",
        "type": "Apple TV,",
        "name": "sevtatv",
        "reporterIp": "3.3.3.13",
        "ttl": 4500,
        "ipAddress": "192.168.11.13",
        "macAddress": "10:40:f3:ed:a5:79",
        "associatedToQueryDomain": "SJC22",
        "associatedToServicePolicy": "AppleTV",
        "active": true,
        "instanceSuffix": ""
      },{
        "id": "9151764a-e820-0987-bed4-714a73f31057",
        "type": "iChat,Printer,",
        "name": "sevChatPrinter",
        "reporterIp": "3.3.3.20",
        "ttl": 4500,
        "ipAddress": "192.168.11.20",
        "macAddress": "10:40:f3:ed:a5:20",
        "associatedToQueryDomain": "SJC06",
        "associatedToServicePolicy": "CommunicationService",
        "active": true,
        "instanceSuffix": ""
      }
    ],

    "DOMAIN_TEMPLATE" : [
      {
        "id": "0e09a17e-11a1-47e7-b262-8432c53b78cc",
        "name": "SJC22",
        "description": null,
        "parent": "565c3252-29b7-48a6-805c-4faddff0c538",
        "sdgNodes": 2,
        "policies": 1,
        "subDomains": 0
      },{
        "id": "0e09a17e-11a1-1234-b262-8432c53b78cc",
        "name": "SJC06",
        "description": null,
        "parent": "565c3252-29b7-48a6-805c-4faddff0c538",
        "sdgNodes": 2,
        "policies": 1,
        "subDomains": 0
      },{
        "id": "565c3252-29b7-48a6-805c-4faddff0c538",
        "name": "San Jose",
        "description": null,
        "parent": "",
        "sdgNodes": 0,
        "policies": 0,
        "subDomains": 2
      }
    ],

    "SERVICE_POLICY" : [
      {
        "id": "f6e87349-8829-47de-b88b-7f619971aa22",
        "name": "AppleTV",
        "description": "",
        "serviceTypeId": null,
        "serviceTypeName": null,
        "serviceTypeList": [
          {
            "id": "23895a74-8b0a-4437-af5c-ba48617547de",
            "name": "File Transfer Protocol",
            "pointerTypes": null
          },
          {
            "id": "aaa1cbe0-a844-4959-bb7a-98ac751f9b8d",
            "name": "Apple Filing Protocol",
            "pointerTypes": null
          },
          {
            "id": "c8d40989-1928-4f28-a93e-e47b8581b165",
            "name": "Apple TV",
            "pointerTypes": null
          }
        ],
        "sourceConditions": [
          {
            "sourceIp": "3.3.3.13",
            "network": "192.168.11.0",
            "mask": "24",
            "sourceId": null,
            "sourceName": null,
            "sourceType": null,
            "vlanCount": null,
            "interfaceName": null
          }
        ],
        "queryConditions": [
          {
            "sourceIp": "3.3.3.9",
            "network": "192.168.11.0",
            "mask": "24",
            "sourceId": null,
            "sourceName": null,
            "sourceType": null,
            "vlanCount": null,
            "interfaceName": null
          }
        ],
        "selectiveSourceCondition": {
          "macAddress": []
        },
        "domainId": "0e09a17e-11a1-47e7-b262-8432c53b78cc",
        "domainName": "SJC22",
        "isActive": true,
        "serviceCount": 1,
        "invalidEntries": null,
        "operationalStatus": null
      },
      {
        "id": "f6e87349-8829-1111-b88b-7f619971aa22",
        "name": "CommunicationService",
        "description": "",
        "serviceTypeId": null,
        "serviceTypeName": null,
        "serviceTypeList": [
          {
            "id": "23895a74-8b0a-4437-af5c-ba48617547de",
            "name": "File Transfer Protocol",
            "pointerTypes": null
          },
          {
            "id": "351ea75b-6fc6-4b06-917a-9aff9caf48b6",
            "name": "iChat",
            "pointerTypes": null
          },
          {
            "id": "0a7bb3b4-288f-4df0-9466-c6fd86d431b1",
            "name": "Printer",
            "pointerTypes": null
          },
          {
            "id": "cb7ebe9f-e3c4-4460-b968-9e01f1072fe4",
            "name": "AirPort Base Station",
            "pointerTypes": null
          }
        ],
        "sourceConditions": [
          {
            "sourceIp": "3.3.3.20",
            "network": "192.168.11.0",
            "mask": "24",
            "sourceId": null,
            "sourceName": null,
            "sourceType": null,
            "vlanCount": null,
            "interfaceName": null
          }
        ],
        "queryConditions": [
          {
            "sourceIp": "3.3.3.21",
            "network": "192.168.11.0",
            "mask": "24",
            "sourceId": null,
            "sourceName": null,
            "sourceType": null,
            "vlanCount": null,
            "interfaceName": null
          }
        ],
        "selectiveSourceCondition": {
          "macAddress": []
        },
        "domainId": "0e09a17e-11a1-1234-b262-8432c53b78cc",
        "domainName": "SJC06",
        "isActive": true,
        "serviceCount": 1,
        "invalidEntries": null,
        "operationalStatus": null
      }
    ],

    "NODE_TEMPLATE" : {
      "id": "098c54c3-9fe8-497b-8cc0-bb073166a37e",
      "ipAddress": "3.3.3.9",
      "active": true,
      "serviceCount": 0,
      "networks": [
        /* {
            "network": "3.3.3.9",
            "prefix": "32",
            "name": "Loopback0"
        }, */
      ],
      "serviceFilters": [
        "AppleTV"
      ],
      "subDomains": [
        "SJC22"
      ],
      "sdgRole": [
        "Query" //Source or Query
      ],
      "reachability": "Reachable",
      "syncState": "Successful",
      "syncStartTime": "1970-01-01T00:00:00.000+0000",
      "syncEndTime": "2019-10-15T07:11:54.318+0000",
      "syncStatusMessage": null
    },

    "SERVICE_TYPE_TEMPLATE" : [
      {
        "id": "351ea75b-6fc6-4b06-917a-9aff9caf48b6",
        "name": "iChat",
        "pointerTypes": "_ichat._tcp.local.,_presence._tcp.local."
      },
      {
        "id": "0a7bb3b4-288f-4df0-9466-c6fd86d431b1",
        "name": "Printer",
        "pointerTypes": "_ipp._tcp.local.,_printer._tcp.local.,_ipps._tcp.local."
      },
      {
        "id": "aaa1cbe0-a844-4959-bb7a-98ac751f9b8d",
        "name": "Apple Filing Protocol",
        "pointerTypes": "_afpovertcp._tcp.local."
      },
      {
        "id": "79a4c338-81c8-4358-9d76-ee6c1c435d5c",
        "name": "iTunes",
        "pointerTypes": "_raop._tcp.local."
      },
      {
        "id": "1c697cff-2262-497a-8fe9-046fa968846f",
        "name": "Secure Shell",
        "pointerTypes": "_ssh._tcp.local."
      },
      {
        "id": "cb7ebe9f-e3c4-4460-b968-9e01f1072fe4",
        "name": "AirPort Base Station",
        "pointerTypes": "_airport._tcp.local."
      },
      {
        "id": "23895a74-8b0a-4437-af5c-ba48617547de",
        "name": "File Transfer Protocol",
        "pointerTypes": "_ftp._tcp.local."
      },
      {
        "id": "c8d40989-1928-4f28-a93e-e47b8581b165",
        "name": "Apple TV",
        "pointerTypes": "_airplay._tcp.local.,_raop._tcp.local."
      },
      {
        "id": "1d200a7c-cf2f-4397-8b5d-03d7b9de9435",
        "name": "Network File System",
        "pointerTypes": "_nfs._tcp.local."
      }
    ],

    "SOURCE_QUERY_MAPPINGS": [
      {"source":"FE2-9300-04.cisco.com", "query":"FE1-9300-03.cisco.com", "policyUsed":"AppleTV", "subdomain":"SJC22"},
      {"source":"SJC06-C9300-02", "query":"SJC06-C9300-01", "policyUsed":"CommunicationService", "subdomain":"SJC06"}
    ]

});