define({
"Template":{
  "name": "ap_cpu_threshold_trigger",
  "severity": "HIGH",
  "id": "AWamay2JbhIriG8x4kxn",
  "time": "2018-10-24T14:05:00.000+0000",
  "endTime": "2018-10-24T14:05:00.000+0000",
  "count": 1,
  "attributes": [
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "cpuTotalUsage",
      "value": "96.0"
    },
    {
      "key": "threshold",
      "value": "90.0"
    },
    {
      "key": "entityId",
      "value": "DC:CE:C1:2E:BD:C0"
    },
    {
      "key": "type",
      "value": "Issue"
    },
    {
      "key": "priority",
      "value": "P1"
    },
    {
      "key": "apName",
      "value": "AP-D0"
    },
    {
      "key": "macAddress",
      "value": "DC:CE:C1:2E:BD:C0"
    },
    {
      "key": "category",
      "value": "Utilization"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})