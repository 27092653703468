define({
    "Floor_Template": {
      "siteName":"","siteId":"","buildingName":"","buildingId":"","areaName":null,"areaId":null,"parentSiteId":"","parentSiteName":"","siteType":"floor","healthyNetworkDevicePercentage":null,"healthyClientsPercentage":null,"overallNumberOfIssues":null,"overallBandwithUsage":null,"lowestGroupCategoryNetwork":"Wireless","lowestGroupPercentageNetwork":50,"lowestGroupCategoryClient":"Wireless","lowestGroupPercentageClient":100,"clientHealthWired":null,"clientHealthWireless":null,"numberOfClients":1,"clientNumberOfIssues":null,"clientBandwithUsage":null,"networkNumberOfIssues":null,"networkBandwithUsage":null,"numberOfNetworkDevice":0,"networkHealthAverage":null,"networkHealthAccess":null,"networkHealthCore":null,"networkHealthDistribution":null,"networkHealthRouter":null,"networkHealthWireless":null,"networkHealthOthers":null,"numberOfWiredClients":0,"numberOfWirelessClients":0,"wiredGoodClients":null,"wirelessGoodClients":0,"applicationHealth":0,"applicationGoodCount":0,"applicationTotalCount":0, "applicationBytesTotalCount":0
    },
    "Building_Template": {
      "siteName":"","siteId":"","buildingName":null,"buildingId":null,"areaName":"","areaId":"","parentSiteId":"","parentSiteName":"","siteType":"building","healthyNetworkDevicePercentage":null,"healthyClientsPercentage":null,"overallNumberOfIssues":33,"overallBandwithUsage":null,"lowestGroupCategoryNetwork":"Wireless","lowestGroupPercentageNetwork":68,"lowestGroupCategoryClient":"Wireless","lowestGroupPercentageClient":66,"clientHealthWired":null,"clientHealthWireless":null,"numberOfClients":0,"clientNumberOfIssues":11,"clientBandwithUsage":null,"networkNumberOfIssues":22,"networkBandwithUsage":null,"numberOfNetworkDevice":0,"networkHealthAverage":null,"networkHealthAccess":null,"networkHealthCore":null,"networkHealthDistribution":null,"networkHealthRouter":null,"networkHealthWireless":null,"networkHealthOthers":null,"numberOfWiredClients":0,"numberOfWirelessClients":0,"wiredGoodClients":0,"wirelessGoodClients":0,"applicationHealth":0,"applicationGoodCount":0,"applicationTotalCount":0, "applicationBytesTotalCount":0
    },
    "Area_Template": {
      "siteName":"","siteId":"","buildingName":null,"buildingId":null,"areaName":null,"areaId":null,"parentSiteId":"","parentSiteName":"","siteType":"area","healthyNetworkDevicePercentage":null,"healthyClientsPercentage":null,"overallNumberOfIssues":25,"overallBandwithUsage":null,"lowestGroupCategoryNetwork":null,"lowestGroupPercentageNetwork":null,"lowestGroupCategoryClient":null,"lowestGroupPercentageClient":null,"clientHealthWired":null,"clientHealthWireless":null,"numberOfClients":0,"clientNumberOfIssues":35,"clientBandwithUsage":null,"networkNumberOfIssues":45,"networkBandwithUsage":null,"numberOfNetworkDevice":0,"networkHealthAverage":null,"networkHealthAccess":null,"networkHealthCore":null,"networkHealthDistribution":null,"networkHealthRouter":null,"networkHealthWireless":null,"networkHealthOthers":null,"numberOfWiredClients":0,"numberOfWirelessClients":0,"wiredGoodClients":null,"wirelessGoodClients":44,"applicationHealth":0,"applicationGoodCount":0,"applicationTotalCount":0, "applicationBytesTotalCount":0
    }
});
