define({
  "Template": {
    success: true,
    result: "show 802.11b cleanair device ap DeviceNamePlaceHolder\n"
            +"DC    = Duty Cycle (%)\n"
            +"ISI   = Interference Severity Index (1-Low Interference, 100-High Interference)\n"
            +"RSSI  = Received Signal Strength Index (dBm)\n"
            +"DevID = Device ID\n\n"
            +"No  ClusterID/BSSID    DevID  Type           AP Name                 ISI  RSSI  DC   Channel \n"
            +"--- ------------------ ------ ------------- ------------------------ ---- ----- ---- ---------- \n"
            +"1   4e:db:bd:00:37:66  0x6001 BLE Beacon     DeviceNamePlaceHolder    2    -73   0    unknown\n"
            +"2                      0x6009 BLE Beacon     DeviceNamePlaceHolder    2    -67   0    unknown\n"
            +"3   4e:db:bd:00:43:57  0x6002 BLE Beacon     DeviceNamePlaceHolder    2    -82   0    unknown\n"
            +"4   4e:db:bd:00:35:79  0x6003 BLE Beacon     DeviceNamePlaceHolder    2    -86   0    unknown\n"
            +"5                      0x6007 BLE Beacon     DeviceNamePlaceHolder    2    -73   0    unknown\n"
            +"6   4e:db:bd:00:35:74  0x6004 BLE Beacon     DeviceNamePlaceHolder    2    -68   0    unknown\n"
            +"7                      0x6008 BLE Beacon     DeviceNamePlaceHolder    2    -73   0    unknown\n"
            +"8   4e:db:bd:00:5c:f2  0x6006 BLE Beacon     DeviceNamePlaceHolder    2    -67   0    unknown\n"
            +"9   4e:db:bd:00:53:97  0x6012 BLE Beacon     DeviceNamePlaceHolder    2    -61   0    unknown\n"
            +"10  4e:db:bd:00:53:9a  0x6014 BLE Beacon     DeviceNamePlaceHolder    2    -61   0    unknown\n"
            +"11  4e:db:bd:00:54:0f  0x6073 BLE Beacon     DeviceNamePlaceHolder    2    -81   0    unknown\n"
            +"12  4e:db:bd:00:5b:88  0x6078 Video camera   DeviceNamePlaceHolder    30   -65   100  1,2,3,4\n"
            +"13  4e:db:bd:00:54:14  0x6079 BLE Beacon     DeviceNamePlaceHolder    2    -76   0    unknown\n"
            +"14  4e:db:bd:00:5e:66  0x6c21 BLE Beacon     DeviceNamePlaceHolder    --   -91   0    unknown\n"
            +"15  4e:db:bd:00:5e:9e  0x6c41 BT Discovery   DeviceNamePlaceHolder    2    -90   1    6\n\n"
            +"(Cisco Controller) >"

  }
})