define({
    "runningImageList":[  
        {  
           "name":"c6848x-adventerprisek9-mz.SPA.155-1.SY1.bin",
           "imageUuid":"48f616c6-0406-4a0c-bfd7-f3c9c2089f45",
           "version":"15.5.1-SY1",
           "softwareType":null,
           "imageType":"SYSTEM_SW",
           "isTaggedGolden":"false",
           "md5Checksum":"d6bee3f8ba01ff0f34344b5acdcc2032",
           "shaCheckSum":"51f02b487abf173f2ad34f039855aa49ac372524be845cd153d837427d089837d633bdcd4bb4232ecd203f6e0e94ffdd73135bc2e16f3d1a8dd1c271b705157a",
           "createdTime":"",
           "applicationType":"UNKNOWN",
           "feature":null,
           "fileServiceId":null,
           "imageSeries":null,
           "imageSource":"CCO",
           "imageSourceType":"Imported from CCO",
           "imageFamily":"c6848x",
           "vendor":"CISCO",
           "filesize":100546008,
           "deviceCount":0,
           "deviceUuidList":[  
               
           ],
           "smuCount":0,
           "imageAttributes":[  
              {  
                 "propertyName":"release_fcs_date",
                 "propertyValue":"08/Mar/2018"
              },
              {  
                 "propertyName":"image_description",
                 "propertyValue":"ADV ENT SERVICES FULL ENCRYPT"
              },
              {  
                 "propertyName":"release_doc_url",
                 "propertyValue":"https://www.cisco.com/c/en/us/td/docs/switches/lan/catalyst6500/ios/15-5SY/release_notes/release_notes_15_5_SY.html"
              },
              {  
                 "propertyName":"release_doc_name",
                 "propertyValue":"Release Notes for 15.5(1)SY1"
              },
              {  
                 "propertyName":"mdf_id",
                 "propertyValue":"286287226"
              },
              {  
                 "propertyName":"metadata_trans_id",
                 "propertyValue":"127779075"
              },
              {  
                 "propertyName":"deviceMNEId",
                 "propertyValue":"1523522"
              },
              {  
                 "propertyName":"image_size",
                 "propertyValue":"100546008"
              },
              {  
                 "propertyName":"image_guid",
                 "propertyValue":"CF75295F51C47D9566B720969CD2622E3D936EA3"
              },
              {  
                 "propertyName":"min_dram",
                 "propertyValue":"2048"
              },
              {  
                 "propertyName":"min_flash",
                 "propertyValue":"2048"
              },
              {  
                 "propertyName":"udi",
                 "propertyValue":"PID: C6840-X-LE-40G VID: V01, SN: FDO1931L007"
              },
              {  
                 "propertyName":"encryption_software_indicator",
                 "propertyValue":"Y"
              }
           ],
           "isSMUApplicable":false,
           "goldenTagInheritedFromSiteUuid":null,
           "goldenTagInheritedFromSiteName":null,
           "importSupported":true,
           "reasonForNotSupportingImport":null,
           "tagList":[  
              {  
                 "taggedGolden":false,
                 "role":"ALL",
                 "inheritedSiteName":"",
                 "inheritedSiteUuid":""
              },
              {  
                 "taggedGolden":false,
                 "role":"CORE",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"DISTRIBUTION",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"BORDER ROUTER",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"UNKNOWN",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              },
              {  
                 "taggedGolden":false,
                 "role":"ACCESS",
                 "inheritedSiteName":null,
                 "inheritedSiteUuid":null
              }
           ],
           "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
           "imageCategory":"PHYSICAL",
           "imageIntegrityStatus":null,
           "ccorecommended":false,
           "ccolatest":true
        }
     ]
});