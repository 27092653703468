define({
    "getServiceTemplate":
    {
        "version": "1.0",
        "response": {
            "services": [{
                "name": "DHCP",
                "healthStatus": "SUCCESS",
                "description": "DHCP IP assignment Status"
            }, {
                "name": "AAA",
                "healthStatus": "UNKNOWN",
                "description": "AAA Authentication Status"
            }]
        }
    }
})