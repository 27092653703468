define({
data:[
  {
    "id": "05258780-6173-4f17-a39a-799adb29c988",
    "instanceId": 143160,
    "displayName": "143160",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "c5e20d59-309b-481a-a0b8-bc73a84af4dc",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "33444388-ae96-4048-8d87-44da40bb4512",
      "instanceId": 149161,
      "displayName": "149161",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "b919dcfc-ee29-49f0-be3e-00a63cc938e5",
          "instanceId": 144161,
          "displayName": "144161",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "02e91df2-b21b-4568-8925-9334229fd31f",
      "instanceId": 150162,
      "displayName": "enterprise-ipc",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "09ae1013-ad09-48e3-8794-cbd529b11a0d"
        }
      ]
    }
  },
  {
    "id": "137d70a9-3f5d-4fc4-812c-c9a3bd95bd6e",
    "instanceId": 143158,
    "displayName": "143158",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "e99a29a4-9ade-44e0-9e3c-c32ca3ca9f53",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "590ca7e7-10cc-4804-b573-0d9392534432",
      "instanceId": 149159,
      "displayName": "149159",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "e949c537-fef6-4860-b0dd-2c9a2002fe7b",
          "instanceId": 144159,
          "displayName": "144159",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "f4e67736-2884-47c1-bf94-761ad787e365",
      "instanceId": 150160,
      "displayName": "desktop-virtualization-apps",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "0e415af5-5380-4e1f-a099-df104f8376c4"
        }
      ]
    }
  },
  {
    "id": "30ec97da-8e4c-41b6-983d-34c401b7ac3b",
    "instanceId": 143151,
    "displayName": "143151",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "eacbd24c-54a2-4930-85ba-94544923190b",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "38c6222a-de36-4e4c-af52-785847dd4df5",
      "instanceId": 149152,
      "displayName": "149152",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "f847dd93-2ee7-4648-9f97-e277ceeea0d3",
          "instanceId": 144152,
          "displayName": "144152",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "e2798b63-648d-463d-9183-a33a6a5cd98d",
      "instanceId": 150153,
      "displayName": "consumer-browsing",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "e3e4ff66-c71d-4b78-a51e-e946e2626066"
        }
      ]
    }
  },
  {
    "id": "3fd55b0f-59fe-4652-a49d-7c0dac039337",
    "instanceId": 143149,
    "displayName": "143149",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "f8b8dedb-aaf4-4f2d-8258-d99c0b959688",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "ffb92f01-d8bd-4c44-b0d2-98da43946fa0",
      "instanceId": 149150,
      "displayName": "149150",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "0be08b38-564b-49ad-b96f-d827245d4fc7",
          "instanceId": 144150,
          "displayName": "144150",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "6c46506f-580c-4818-a5d1-75cb8475323b",
      "instanceId": 150151,
      "displayName": "backup-and-storage",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "a3035dbd-7631-4694-b4be-b812804fe29c"
        }
      ]
    }
  },
  {
    "id": "50462e3a-b145-48ff-a35a-42951e62e6fc",
    "instanceId": 143168,
    "displayName": "143168",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "6bf09509-b748-4717-bc73-4b88985abd9e",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "2f158706-6e96-4ad7-a25a-b727582566f5",
      "instanceId": 149169,
      "displayName": "149169",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "e0e777db-6d96-459b-a5df-19898b4fca6a",
          "instanceId": 144169,
          "displayName": "144169",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "4723c992-23ce-4328-b2e2-7248c0860ad5",
      "instanceId": 150170,
      "displayName": "network-control",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "3b5d5a85-693f-4e9e-9dde-9963570f79f1"
        }
      ]
    }
  },
  {
    "id": "5acba0f5-3ebb-48f2-a94c-812a50d9d01c",
    "instanceId": 143163,
    "displayName": "143163",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "c231ce9e-af3c-494b-8090-ea0840cb66dd",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "e4b40db0-3078-472a-8be0-c00170f66026",
      "instanceId": 149164,
      "displayName": "149164",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "b4fb3380-7894-45a0-b7d2-7a2e9d50dcee",
          "instanceId": 144164,
          "displayName": "144164",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "5f9da859-e1ce-49c5-bae5-169f14da8eeb",
      "instanceId": 150165,
      "displayName": "general-media",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "6a08c17a-fd06-49a1-8618-f5f0ad09617a"
        }
      ]
    }
  },
  {
    "id": "5d6bd052-019a-4751-b3af-9102ade3c019",
    "instanceId": 143171,
    "displayName": "143171",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "6c8e0232-f22a-4b9c-a6bd-0f92372a46d9",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "44018595-5a44-4fa2-b1d2-4cec9343ed55",
      "instanceId": 149172,
      "displayName": "149172",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "8964ab8b-17e8-4efe-a22b-1c769ded97e5",
          "instanceId": 144172,
          "displayName": "144172",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "8b346bfa-223d-42a6-922d-3da21a1ccc52",
      "instanceId": 150173,
      "displayName": "saas-apps",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "1f2a8c60-b1ac-4cb4-a04e-a9bc7a74c645"
        }
      ]
    }
  },
  {
    "id": "678aacab-012a-4be4-afce-37f786f57c3b",
    "instanceId": 143150,
    "displayName": "143150",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "a6958e7b-ddf2-49e1-867a-f0a3a5dc00e0",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "2dac3a08-1184-4287-be54-01da76c6e7ca",
      "instanceId": 149151,
      "displayName": "149151",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "8b91b74c-9951-4e5b-be7e-cae7470f5bf3",
          "instanceId": 144151,
          "displayName": "144151",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "7f28ac91-1543-4916-a51d-9b26f8f3fea7",
      "instanceId": 150152,
      "displayName": "collaboration-apps",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "71963c26-c178-4eb0-8482-87589981dc22"
        }
      ]
    }
  },
  {
    "id": "6951d6e3-1dc5-4173-9c59-92f20c9fd3f8",
    "instanceId": 143165,
    "displayName": "143165",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "92ab27df-3646-4125-8f9e-9c628defc9bf",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "92f03c95-f186-48ec-950a-b311dc49330b",
      "instanceId": 149166,
      "displayName": "149166",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "01c70bce-0231-4e5b-b159-d5a7825429c1",
          "instanceId": 144166,
          "displayName": "144166",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "adee6d11-6d97-4ab0-9ccf-411c9d02e182",
      "instanceId": 150167,
      "displayName": "tunneling",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "2f7091f9-a4c2-466d-9308-8ca7c540d6a1"
        }
      ]
    }
  },
  {
    "id": "722725f7-bc14-40a0-91b1-2cc3443e72f4",
    "instanceId": 143173,
    "displayName": "143173",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "aaf13804-9453-469f-b8d2-affbf62c2d78",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "0806489c-eb01-4ec3-9701-336f1bd7ce05",
      "instanceId": 149174,
      "displayName": "149174",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "26fc0aaf-e79d-40d4-9771-798e1831f9a0",
          "instanceId": 144174,
          "displayName": "144174",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "46aec5df-d69e-4170-a7df-30eec79b1013",
      "instanceId": 150175,
      "displayName": "software-development-tools",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "a709a3a6-cab9-4ea2-ab21-76a42b245413"
        }
      ]
    }
  },
  {
    "id": "727801aa-ed07-470b-9031-1ebc438a7313",
    "instanceId": 143170,
    "displayName": "143170",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "a5bb1dac-9a85-469e-9f55-bb696e540360",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "61195901-4534-4cd5-bd16-af60738b1646",
      "instanceId": 149171,
      "displayName": "149171",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "2ffec6e0-577a-4312-b94a-3c384008cd5a",
          "instanceId": 144171,
          "displayName": "144171",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "428b7413-6b87-4bd1-a55a-e8ca134ea56f",
      "instanceId": 150172,
      "displayName": "remote-access",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "a9f1fed0-666b-4295-b5b8-cbad05280c34"
        }
      ]
    }
  },
  {
    "id": "787ea270-7c38-4b1f-99f0-b7f684bf805b",
    "instanceId": 143154,
    "displayName": "143154",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "99f2f053-ea67-484b-a06f-fe807becb220",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "783fb657-bc5f-4282-b651-c719aced72e2",
      "instanceId": 149155,
      "displayName": "149155",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "508120ce-14c9-421e-a9fb-70f77e84b988",
          "instanceId": 144155,
          "displayName": "144155",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "b300daab-f641-4b07-a2c9-4294e5d52801",
      "instanceId": 150156,
      "displayName": "consumer-media",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "d05dde20-4015-4943-8618-34543cd707d9"
        }
      ]
    }
  },
  {
    "id": "8b5ab445-33f6-4339-91f9-5b7abbcf80a9",
    "instanceId": 143174,
    "displayName": "143174",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "4aeeb3b3-5f12-4ba4-9a54-906102d2e5c5",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "e591ed3d-8ad2-4f3a-b0df-8f48b1a18854",
      "instanceId": 149175,
      "displayName": "149175",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "53e57a6c-dd9f-4eb9-aff9-586e4d83d22a",
          "instanceId": 144175,
          "displayName": "144175",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "abc230c6-4e01-4276-be57-40d955f31fcf",
      "instanceId": 150176,
      "displayName": "software-updates",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "c3a25005-d233-473c-bbeb-39f57f2cb927"
        }
      ]
    }
  },
  {
    "id": "97427eb4-d3ad-4736-b308-5c8e48d09acf",
    "instanceId": 143155,
    "displayName": "143155",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "b23a8fac-7ff8-4a5d-9485-c686004bd590",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "31f7a178-363f-4649-a01a-c41e53f7675f",
      "instanceId": 149156,
      "displayName": "149156",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "d52236d1-53ea-43c4-b630-47ed8ec0a4f6",
          "instanceId": 144156,
          "displayName": "144156",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "b1f70aa6-0493-44cd-a4c3-42319dc007ea",
      "instanceId": 150157,
      "displayName": "consumer-misc",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "39234e05-0e78-442d-b6f9-c1d631887bfa"
        }
      ]
    }
  },
  {
    "id": "9c69e22c-4d17-4974-b2e6-1f7aa010a28f",
    "instanceId": 143169,
    "displayName": "143169",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "e7f82e16-aa44-49fa-a5bf-137078858966",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "3d76639d-555f-4e31-9c8d-657c79aa078e",
      "instanceId": 149170,
      "displayName": "149170",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "3663de6e-428c-4d78-8d96-18f74b126619",
          "instanceId": 144170,
          "displayName": "144170",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "a86d3751-f2b9-4613-b138-b6d6858552f9",
      "instanceId": 150171,
      "displayName": "network-management",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "749bd452-5650-48a8-8ed7-e7ce19142ec4"
        }
      ]
    }
  },
  {
    "id": "aa8fd4ff-1702-4be4-8b0e-ee70af9abb81",
    "instanceId": 143167,
    "displayName": "143167",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "8b4d2ea2-fd1d-4fb5-8aa5-d8ab5bf720cd",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "02e39184-7831-4cc1-afa8-cec1332c443b",
      "instanceId": 149168,
      "displayName": "149168",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "db3c1165-4b46-4991-8f66-bc323e4eb729",
          "instanceId": 144168,
          "displayName": "144168",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "c0dcda56-7dec-4b08-91e2-d0324a4deee3",
      "instanceId": 150169,
      "displayName": "naming-services",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "2c6a1b58-3ab2-4a05-803f-0e5c705fd38d"
        }
      ]
    }
  },
  {
    "id": "b257a782-4f43-401d-837d-2b419d082acb",
    "instanceId": 143175,
    "displayName": "143175",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "1dc34a5e-2540-4a55-b146-20b66f59dc41",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "388dbc23-bf1d-43e9-a83b-d1745d7a6448",
      "instanceId": 149176,
      "displayName": "149176",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "b6fa1c76-7361-4185-bb35-882d8a6d6a9d",
          "instanceId": 144176,
          "displayName": "144176",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "89bc63ed-a876-4c08-a0ba-9e076776859f",
      "instanceId": 150177,
      "displayName": "streaming-media",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "6ff8fe47-821d-426e-80b6-1a3c8a791fec"
        }
      ]
    }
  },
  {
    "id": "b2d572cf-3de1-495b-b4f7-2cdfd224c3f4",
    "instanceId": 143161,
    "displayName": "143161",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "c60c7fd4-db0f-4c01-81a9-f4b362d49ba6",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "b64ea8cb-776b-4776-83c6-f03c05c13fad",
      "instanceId": 149162,
      "displayName": "149162",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "1dfaa823-f8c4-4d54-bf1c-9b03458dba49",
          "instanceId": 144162,
          "displayName": "144162",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "dd0fc4ad-fa33-40d4-a35c-3ecaea4bd326",
      "instanceId": 150163,
      "displayName": "file-sharing",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "8e691693-deb3-4a2b-9519-ec0433e87cc0"
        }
      ]
    }
  },
  {
    "id": "b63c8da0-ba90-4316-a96f-268c9eab26d2",
    "instanceId": 143159,
    "displayName": "143159",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "bfdb7c6f-f587-46bd-b317-2fb86b3744d1",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "3fede53d-0049-4211-9493-d3982928f4fb",
      "instanceId": 149160,
      "displayName": "149160",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "f48c8662-101a-4abc-bbbb-cc08d971fda0",
          "instanceId": 144160,
          "displayName": "144160",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "81a60f36-689c-4db0-8a58-3618cbdb58a3",
      "instanceId": 150161,
      "displayName": "email",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "ce92b850-baf7-49f4-81de-557819697f39"
        }
      ]
    }
  },
  {
    "id": "be7d23ea-a435-4561-af93-41c5f40b1510",
    "instanceId": 143164,
    "displayName": "143164",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "3d058bb8-01fa-419c-8367-7cecdadc64d5",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "b1878c1b-d31d-45c2-9d96-26aaf464f495",
      "instanceId": 149165,
      "displayName": "149165",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "11e76020-9b0f-4df9-992c-e77cacdd9de1",
          "instanceId": 144165,
          "displayName": "144165",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "a5bdc5c2-6e01-4e1a-8ab7-5fbb47e53095",
      "instanceId": 150166,
      "displayName": "general-misc",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "1a8ef982-ff60-440d-8af8-ecb91994f2b9"
        }
      ]
    }
  },
  {
    "id": "c197c027-a26e-45d0-b88d-164ee7d69031",
    "instanceId": 143172,
    "displayName": "143172",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "889b8ade-c7d4-4be7-aa14-50c4b3c86632",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "3407058c-8f82-4ff3-a054-0ed194340e37",
      "instanceId": 149173,
      "displayName": "149173",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "a9e881db-13b4-42db-9263-27d95cd33d4d",
          "instanceId": 144173,
          "displayName": "144173",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "d8bc53b2-e7a2-402d-a60a-e3aa9826e0d6",
      "instanceId": 150174,
      "displayName": "signaling",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "dbff627d-85e6-468f-89fb-61f67c4e5d71"
        }
      ]
    }
  },
  {
    "id": "d3381c80-ace2-4a67-97bc-ab73defa31e2",
    "instanceId": 143153,
    "displayName": "143153",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "02a9ce50-1350-4a05-bbc0-a94aa92cb195",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "059988f1-816f-40b3-bb5f-ee6cad4fc4cb",
      "instanceId": 149154,
      "displayName": "149154",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "9cd0f23b-c5e5-4779-aeb0-d1fd890f25cd",
          "instanceId": 144154,
          "displayName": "144154",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "a7895851-940e-42de-b8c1-1cef4b9731de",
      "instanceId": 150155,
      "displayName": "consumer-gaming",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "d4ff51f0-d79e-48ea-9b97-7cfb90d1f2c1"
        }
      ]
    }
  },
  {
    "id": "d941239c-84d9-4765-8f3f-ae73d9f7a98d",
    "instanceId": 143166,
    "displayName": "143166",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "ab1418be-48fc-42de-86b3-2267f5f5abb6",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "825e3bf8-d934-4ae3-a76b-4e572642da83",
      "instanceId": 149167,
      "displayName": "149167",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "0a1593f0-0f0a-4370-af68-9ef3a345e880",
          "instanceId": 144167,
          "displayName": "144167",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "ccf3c787-cde2-4d4b-b11c-3b4f69365247",
      "instanceId": 150168,
      "displayName": "local-services",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "39f44ead-d03f-4a0e-9e07-16af7d48dd93"
        }
      ]
    }
  },
  {
    "id": "ee14c9fd-c708-4282-8e77-b7826a11badd",
    "instanceId": 143148,
    "displayName": "143148",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "847f09b6-e7fe-4d6c-8bac-51843d47f660",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "d53dd4e7-c98b-4ead-b18e-fc4e3fa83f15",
      "instanceId": 149149,
      "displayName": "149149",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "cf90513a-10ae-444b-a142-9c9951412099",
          "instanceId": 144149,
          "displayName": "144149",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "77e88c15-dec5-4993-ad7d-6a3ce66641ca",
      "instanceId": 150150,
      "displayName": "authentication-services",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "78cade45-70c4-46aa-ae97-c93920a515ba"
        }
      ]
    }
  },
  {
    "id": "f5cc80a5-52e0-4795-be5d-002496fb1fde",
    "instanceId": 143157,
    "displayName": "143157",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "0f75b78b-ee7d-4f66-9445-c05820a0e3cb",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "d6d6743c-882d-4648-b053-25ba68f4ab71",
      "instanceId": 149158,
      "displayName": "149158",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "53b8590a-6a57-4080-af60-a86ec1f14fb7",
          "instanceId": 144158,
          "displayName": "144158",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_RELEVANT"
        }
      ]
    },
    "producer": {
      "id": "2964bf79-e28e-474c-91a3-c91920526fe0",
      "instanceId": 150159,
      "displayName": "database-apps",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "6b928b72-3dde-4d71-9ce2-b3c8af65a1d4"
        }
      ]
    }
  },
  {
    "id": "fb193854-78ae-48c4-9b1b-71a1530c09dd",
    "instanceId": 143156,
    "displayName": "143156",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "b3e57663-f8fa-4758-b22f-cbd7ed9d81b6",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "3f7d06bf-bdb7-4b2d-b42b-c8f44c240c41",
      "instanceId": 149157,
      "displayName": "149157",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "db265ed2-7493-4d9a-b2b0-e10c7b60b437",
          "instanceId": 144157,
          "displayName": "144157",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "f721ba4f-1b16-4567-b2c1-f92d6621c2bb",
      "instanceId": 150158,
      "displayName": "consumer-social-networking",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "4a63686f-3bab-4f96-be74-64e5e4e7b5fc"
        }
      ]
    }
  },
  {
    "id": "fe2e4318-fda7-4d73-961d-9a71449b368b",
    "instanceId": 143162,
    "displayName": "143162",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "b6b89649-a6d2-442d-8c98-bde3bf4885d2",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "299916eb-0836-40d9-8c5e-38581ff9cd44",
      "instanceId": 149163,
      "displayName": "149163",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "4193e749-5894-4c29-8fe6-a5608652df06",
          "instanceId": 144163,
          "displayName": "144163",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "DEFAULT"
        }
      ]
    },
    "producer": {
      "id": "b20970ad-f284-4be9-ba43-3c6a046a9025",
      "instanceId": 150164,
      "displayName": "general-browsing",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "586a2141-2e8e-4d3b-b269-96c454c62dab"
        }
      ]
    }
  },
  {
    "id": "ff30ffc8-45d1-4ff6-b165-cd35a25ecc42",
    "instanceId": 143152,
    "displayName": "143152",
    "instanceVersion": 0,
    "deletePolicyStatus": "NONE",
    "identitySource": {
      "id": "652bd129-87bf-4047-a50f-f9329fdcac60",
      "type": "NBAR"
    },
    "isEnabled": false,
    "name": "Application-set-default-policy",
    "namespace": "policy:application:",
    "priority": 100,
    "qualifier": "application",
    "type": "policy",
    "exclusiveContract": {
      "id": "022928dc-e116-491e-8a13-25f6a388747b",
      "instanceId": 149153,
      "displayName": "149153",
      "instanceVersion": 0,
      "clause": [
        {
          "id": "0ba6dee7-7a41-448d-b43f-8ced2fa8a767",
          "instanceId": 144153,
          "displayName": "144153",
          "instanceVersion": 0,
          "priority": 1,
          "type": "BUSINESS_RELEVANCE",
          "relevanceLevel": "BUSINESS_IRRELEVANT"
        }
      ]
    },
    "producer": {
      "id": "99649b62-6d40-44ea-a78d-fd37233dfecb",
      "instanceId": 150154,
      "displayName": "consumer-file-sharing",
      "instanceVersion": 0,
      "scalableGroup": [
        {
          "idRef": "da44d9db-2863-480a-a6d7-0144bf158455"
        }
      ]
    }
  }
]})