define({
    "Template":

    {
  "version": "1.0",
  "response": [
    {
      "time": "2018-05-31T06:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.3,
      "latency": 2.0,
      "applicationDelay": 12.5
    },
    {
      "time": "2018-05-31T06:20:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 883.0,
      "applicationDelay": 30.0
    },
    {
      "time": "2018-05-31T06:15:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.51,
      "latency": 738.0,
      "applicationDelay": 40.0
    },
    {
      "time": "2018-05-31T06:10:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.08,
      "latency": 411.5,
      "applicationDelay": 25.0
    },
    {
      "time": "2018-05-31T06:05:00.000+0000",
      "healthScore": 8.38,
      "packetLoss": 0.11,
      "latency": 722.75,
      "applicationDelay": 12.12
    },
    {
      "time": "2018-05-31T06:00:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.08,
      "latency": 294.5,
      "applicationDelay": 28.5
    },
    {
      "time": "2018-05-31T05:55:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.17,
      "latency": 315.25,
      "applicationDelay": 23.5
    },
    {
      "time": "2018-05-31T05:50:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.23,
      "latency": 272.5,
      "applicationDelay": 29.75
    },
    {
      "time": "2018-05-31T05:45:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.91,
      "latency": 486.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-31T05:40:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.65,
      "latency": 480.0,
      "applicationDelay": 59.5
    },
    {
      "time": "2018-05-31T05:35:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.68,
      "latency": 399.5,
      "applicationDelay": 61.5
    },
    {
      "time": "2018-05-31T05:30:00.000+0000",
      "healthScore": 9.0,
      "packetLoss": 0.0,
      "latency": 233.0,
      "applicationDelay": 23.5
    },
    {
      "time": "2018-05-31T05:25:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.35,
      "latency": 207.0,
      "applicationDelay": 28.75
    },
    {
      "time": "2018-05-31T05:20:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.45,
      "latency": 178.25,
      "applicationDelay": 24.5
    },
    {
      "time": "2018-05-31T05:15:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.7,
      "latency": 431.5,
      "applicationDelay": 51.5
    },
    {
      "time": "2018-05-31T05:10:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.14,
      "latency": 315.25,
      "applicationDelay": 28.75
    },
    {
      "time": "2018-05-31T05:05:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.18,
      "latency": 539.5,
      "applicationDelay": 50.5
    },
    {
      "time": "2018-05-31T05:00:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.39,
      "latency": 502.5,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-31T04:55:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.56,
      "latency": 477.0,
      "applicationDelay": 49.5
    },
    {
      "time": "2018-05-31T04:50:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 5.21,
      "latency": 263.0,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-31T04:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.17,
      "latency": 225.0,
      "applicationDelay": 29.75
    },
    {
      "time": "2018-05-31T04:40:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.09,
      "latency": 218.5,
      "applicationDelay": 25.75
    },
    {
      "time": "2018-05-31T04:35:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.09,
      "latency": 348.0,
      "applicationDelay": 23.5
    },
    {
      "time": "2018-05-31T04:30:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.08,
      "latency": 280.0,
      "applicationDelay": 31.0
    },
    {
      "time": "2018-05-31T04:25:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.17,
      "latency": 588.5,
      "applicationDelay": 51.5
    },
    {
      "time": "2018-05-31T04:20:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 4.41,
      "latency": 356.33,
      "applicationDelay": 30.0
    },
    {
      "time": "2018-05-31T04:15:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.43,
      "latency": 173.25,
      "applicationDelay": 27.5
    },
    {
      "time": "2018-05-31T04:10:00.000+0000",
      "healthScore": 8.67,
      "packetLoss": 0.34,
      "latency": 108.5,
      "applicationDelay": 19.33
    },
    {
      "time": "2018-05-31T04:05:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 0.6,
      "latency": 394.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-31T04:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.33,
      "latency": 183.75,
      "applicationDelay": 21.25
    },
    {
      "time": "2018-05-31T03:55:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.24,
      "latency": 199.75,
      "applicationDelay": 27.75
    },
    {
      "time": "2018-05-31T03:50:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.21,
      "latency": 264.75,
      "applicationDelay": 29.25
    },
    {
      "time": "2018-05-31T03:45:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.17,
      "latency": 472.0,
      "applicationDelay": 55.5
    },
    {
      "time": "2018-05-31T03:40:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.25,
      "latency": 178.75,
      "applicationDelay": 31.25
    },
    {
      "time": "2018-05-31T03:35:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.4,
      "latency": 254.75,
      "applicationDelay": 30.75
    },
    {
      "time": "2018-05-31T03:30:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.64,
      "latency": 442.0,
      "applicationDelay": 60.0
    },
    {
      "time": "2018-05-31T03:25:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.2,
      "latency": 421.0,
      "applicationDelay": 53.5
    },
    {
      "time": "2018-05-31T03:20:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.41,
      "latency": 281.5,
      "applicationDelay": 27.0
    },
    {
      "time": "2018-05-31T03:15:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.35,
      "latency": 297.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-31T03:10:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 0.18,
      "latency": 281.0,
      "applicationDelay": 60.5
    },
    {
      "time": "2018-05-31T03:05:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.18,
      "latency": 200.5,
      "applicationDelay": 31.75
    },
    {
      "time": "2018-05-31T03:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.15,
      "latency": 208.5,
      "applicationDelay": 40.75
    },
    {
      "time": "2018-05-31T02:55:00.000+0000",
      "healthScore": 9.25,
      "packetLoss": 0.0,
      "latency": 118.25,
      "applicationDelay": 25.5
    },
    {
      "time": "2018-05-31T02:50:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 1.17,
      "latency": 532.5,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-31T02:45:00.000+0000",
      "healthScore": 7.83,
      "packetLoss": 3.78,
      "latency": 564.0,
      "applicationDelay": 12.0
    },
    {
      "time": "2018-05-31T02:40:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.12,
      "latency": 802.0,
      "applicationDelay": 20.33
    },
    {
      "time": "2018-05-31T02:35:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.18,
      "latency": 2153.67,
      "applicationDelay": 33.67
    },
    {
      "time": "2018-05-31T02:30:00.000+0000",
      "healthScore": 7.83,
      "packetLoss": 0.5,
      "latency": 598.83,
      "applicationDelay": 19.67
    },
    {
      "time": "2018-05-31T02:25:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.48,
      "latency": 662.5,
      "applicationDelay": 53.5
    },
    {
      "time": "2018-05-31T02:20:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.61,
      "latency": 474.75,
      "applicationDelay": 21.25
    },
    {
      "time": "2018-05-31T02:15:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.12,
      "latency": 213.25,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-31T02:10:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.42,
      "latency": 381.5,
      "applicationDelay": 17.5
    },
    {
      "time": "2018-05-31T02:05:00.000+0000",
      "healthScore": 7.5,
      "packetLoss": 0.2,
      "latency": 144.5,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-31T02:00:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.37,
      "latency": 1214.33,
      "applicationDelay": 39.33
    },
    {
      "time": "2018-05-31T01:55:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.28,
      "latency": 1054.5,
      "applicationDelay": 47.0
    },
    {
      "time": "2018-05-31T01:50:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 1.06,
      "latency": 1324.5,
      "applicationDelay": 43.0
    },
    {
      "time": "2018-05-31T01:45:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 1.03,
      "latency": 625.5,
      "applicationDelay": 27.5
    },
    {
      "time": "2018-05-31T01:40:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.7,
      "latency": 265.0,
      "applicationDelay": 24.5
    },
    {
      "time": "2018-05-31T01:35:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.35,
      "latency": 376.5,
      "applicationDelay": 55.5
    },
    {
      "time": "2018-05-31T01:30:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.34,
      "latency": 549.5,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-31T01:25:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.24,
      "latency": 143.75,
      "applicationDelay": 26.5
    },
    {
      "time": "2018-05-31T01:20:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 5.47,
      "latency": 526.67,
      "applicationDelay": 15.0
    },
    {
      "time": "2018-05-31T01:15:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.78,
      "latency": 337.75,
      "applicationDelay": 25.5
    },
    {
      "time": "2018-05-31T01:10:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.67,
      "latency": 340.5,
      "applicationDelay": 51.5
    },
    {
      "time": "2018-05-31T01:05:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 1.01,
      "latency": 468.0,
      "applicationDelay": 60.0
    },
    {
      "time": "2018-05-31T01:00:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 1.01,
      "latency": 445.5,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-31T00:55:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.57,
      "latency": 482.0,
      "applicationDelay": 58.5
    },
    {
      "time": "2018-05-31T00:50:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.93,
      "latency": 240.0,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-31T00:45:00.000+0000",
      "healthScore": 5.5,
      "packetLoss": 0.56,
      "latency": 578.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-31T00:40:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.51,
      "latency": 273.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-31T00:35:00.000+0000",
      "healthScore": 5.33,
      "packetLoss": 9.13,
      "latency": 181.0,
      "applicationDelay": 35.67
    },
    {
      "time": "2018-05-31T00:30:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.35,
      "latency": 176.0,
      "applicationDelay": 29.0
    },
    {
      "time": "2018-05-31T00:25:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.28,
      "latency": 198.0,
      "applicationDelay": 23.75
    },
    {
      "time": "2018-05-31T00:20:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.33,
      "latency": 253.25,
      "applicationDelay": 28.0
    },
    {
      "time": "2018-05-31T00:15:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.47,
      "latency": 105.75,
      "applicationDelay": 29.0
    },
    {
      "time": "2018-05-31T00:10:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.19,
      "latency": 244.0,
      "applicationDelay": 31.75
    },
    {
      "time": "2018-05-31T00:05:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 384.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-31T00:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 321.5,
      "applicationDelay": 52.0
    },
    {
      "time": "2018-05-30T23:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T23:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T23:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:15:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.28,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T23:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T23:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T23:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T22:45:00.000+0000",
      "healthScore": 8.75,
      "packetLoss": 0.2,
      "latency": 0.25,
      "applicationDelay": 0.25
    },
    {
      "time": "2018-05-30T22:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T22:05:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 9.38,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T22:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T21:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T21:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T21:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T21:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T21:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T21:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 2.0
    },
    {
      "time": "2018-05-30T21:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 4.0
    },
    {
      "time": "2018-05-30T21:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T21:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T21:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T21:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 8.0
    },
    {
      "time": "2018-05-30T20:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-30T20:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-30T20:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T20:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T20:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T20:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T20:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T20:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T20:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T20:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T20:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.25,
      "applicationDelay": 0.25
    },
    {
      "time": "2018-05-30T19:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T19:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T19:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T18:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T18:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T18:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T18:30:00.000+0000",
      "healthScore": 8.75,
      "packetLoss": 0.36,
      "latency": 0.25,
      "applicationDelay": 0.25
    },
    {
      "time": "2018-05-30T18:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T18:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T17:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T17:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T17:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T16:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T16:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T15:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 3.5
    },
    {
      "time": "2018-05-30T15:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T15:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T15:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T15:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T15:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T15:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T14:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T14:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T14:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T14:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T14:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T14:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T14:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T14:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.25,
      "applicationDelay": 0.25
    },
    {
      "time": "2018-05-30T14:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T13:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T13:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T13:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T13:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T13:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T12:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-30T12:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T12:10:00.000+0000",
      "healthScore": 8.5,
      "packetLoss": 2.56,
      "latency": 0.25,
      "applicationDelay": 0.25
    },
    {
      "time": "2018-05-30T12:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-30T11:55:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.9,
      "latency": 281.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-30T11:50:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.34,
      "latency": 148.25,
      "applicationDelay": 29.5
    },
    {
      "time": "2018-05-30T11:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.23,
      "latency": 209.0,
      "applicationDelay": 28.5
    },
    {
      "time": "2018-05-30T11:40:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.33,
      "latency": 170.75,
      "applicationDelay": 27.25
    },
    {
      "time": "2018-05-30T11:35:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.24,
      "latency": 111.0,
      "applicationDelay": 24.5
    },
    {
      "time": "2018-05-30T11:30:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.19,
      "latency": 151.75,
      "applicationDelay": 35.25
    },
    {
      "time": "2018-05-30T11:25:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.35,
      "latency": 140.0,
      "applicationDelay": 37.75
    },
    {
      "time": "2018-05-30T11:20:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.34,
      "latency": 210.0,
      "applicationDelay": 39.75
    },
    {
      "time": "2018-05-30T11:15:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.15,
      "latency": 171.5,
      "applicationDelay": 28.0
    },
    {
      "time": "2018-05-30T11:10:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.2,
      "latency": 415.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-30T11:05:00.000+0000",
      "healthScore": 7.83,
      "packetLoss": 1.83,
      "latency": 110.33,
      "applicationDelay": 19.5
    },
    {
      "time": "2018-05-30T11:00:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.29,
      "latency": 389.5,
      "applicationDelay": 57.5
    },
    {
      "time": "2018-05-30T10:55:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.34,
      "latency": 289.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T10:50:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 1.0,
      "latency": 368.5,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T10:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.58,
      "latency": 182.0,
      "applicationDelay": 30.75
    },
    {
      "time": "2018-05-30T10:40:00.000+0000",
      "healthScore": 9.0,
      "packetLoss": 0.0,
      "latency": 252.0,
      "applicationDelay": 29.25
    },
    {
      "time": "2018-05-30T10:35:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.29,
      "latency": 161.5,
      "applicationDelay": 25.25
    },
    {
      "time": "2018-05-30T10:30:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.21,
      "latency": 385.5,
      "applicationDelay": 66.5
    },
    {
      "time": "2018-05-30T10:25:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.77,
      "latency": 338.0,
      "applicationDelay": 54.5
    },
    {
      "time": "2018-05-30T10:20:00.000+0000",
      "healthScore": 7.0,
      "packetLoss": 0.34,
      "latency": 226.5,
      "applicationDelay": 55.5
    },
    {
      "time": "2018-05-30T10:15:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.34,
      "latency": 484.0,
      "applicationDelay": 51.5
    },
    {
      "time": "2018-05-30T10:10:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.18,
      "latency": 179.0,
      "applicationDelay": 30.25
    },
    {
      "time": "2018-05-30T10:05:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.23,
      "latency": 198.0,
      "applicationDelay": 29.0
    },
    {
      "time": "2018-05-30T10:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.36,
      "latency": 193.0,
      "applicationDelay": 27.75
    },
    {
      "time": "2018-05-30T09:55:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.49,
      "latency": 436.5,
      "applicationDelay": 57.5
    },
    {
      "time": "2018-05-30T09:50:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.45,
      "latency": 348.0,
      "applicationDelay": 55.5
    },
    {
      "time": "2018-05-30T09:45:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.46,
      "latency": 565.0,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-30T09:40:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.66,
      "latency": 340.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T09:35:00.000+0000",
      "healthScore": 9.25,
      "packetLoss": 0.0,
      "latency": 111.25,
      "applicationDelay": 32.5
    },
    {
      "time": "2018-05-30T09:30:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.45,
      "latency": 458.5,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T09:25:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.62,
      "latency": 357.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T09:20:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.37,
      "latency": 335.0,
      "applicationDelay": 58.5
    },
    {
      "time": "2018-05-30T09:15:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.2,
      "latency": 367.5,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T09:10:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 0.26,
      "latency": 210.25,
      "applicationDelay": 28.75
    },
    {
      "time": "2018-05-30T09:05:00.000+0000",
      "healthScore": 7.75,
      "packetLoss": 1.79,
      "latency": 1140.5,
      "applicationDelay": 28.0
    },
    {
      "time": "2018-05-30T09:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.15,
      "latency": 240.0,
      "applicationDelay": 30.25
    },
    {
      "time": "2018-05-30T08:55:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.1,
      "latency": 205.75,
      "applicationDelay": 34.25
    },
    {
      "time": "2018-05-30T08:50:00.000+0000",
      "healthScore": 7.67,
      "packetLoss": 0.21,
      "latency": 206.0,
      "applicationDelay": 35.33
    },
    {
      "time": "2018-05-30T08:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 442.0,
      "applicationDelay": 60.5
    },
    {
      "time": "2018-05-30T08:40:00.000+0000",
      "healthScore": 7.67,
      "packetLoss": 3.55,
      "latency": 82.33,
      "applicationDelay": 20.5
    },
    {
      "time": "2018-05-30T08:35:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 474.0,
      "applicationDelay": 55.5
    },
    {
      "time": "2018-05-30T08:30:00.000+0000",
      "healthScore": 5.67,
      "packetLoss": 3.02,
      "latency": 256.0,
      "applicationDelay": 36.67
    },
    {
      "time": "2018-05-30T08:25:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.17,
      "latency": 186.5,
      "applicationDelay": 32.0
    },
    {
      "time": "2018-05-30T08:20:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.36,
      "latency": 191.75,
      "applicationDelay": 28.25
    },
    {
      "time": "2018-05-30T08:15:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.18,
      "latency": 338.5,
      "applicationDelay": 59.5
    },
    {
      "time": "2018-05-30T08:10:00.000+0000",
      "healthScore": 8.83,
      "packetLoss": 0.39,
      "latency": 46.17,
      "applicationDelay": 20.0
    },
    {
      "time": "2018-05-30T08:05:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.21,
      "latency": 189.5,
      "applicationDelay": 35.25
    },
    {
      "time": "2018-05-30T08:00:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 2.04,
      "latency": 165.25,
      "applicationDelay": 30.5
    },
    {
      "time": "2018-05-30T07:55:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.57,
      "latency": 272.0,
      "applicationDelay": 56.5
    },
    {
      "time": "2018-05-30T07:50:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.27,
      "latency": 154.25,
      "applicationDelay": 26.5
    },
    {
      "time": "2018-05-30T07:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.46,
      "latency": 143.0,
      "applicationDelay": 30.75
    },
    {
      "time": "2018-05-30T07:40:00.000+0000",
      "healthScore": 8.5,
      "packetLoss": 0.0,
      "latency": 257.5,
      "applicationDelay": 56.5
    },
    {
      "time": "2018-05-30T07:35:00.000+0000",
      "healthScore": 6.5,
      "packetLoss": 0.2,
      "latency": 276.5,
      "applicationDelay": 58.5
    },
    {
      "time": "2018-05-30T07:30:00.000+0000",
      "healthScore": 7.67,
      "packetLoss": 2.36,
      "latency": 474.0,
      "applicationDelay": 16.5
    },
    {
      "time": "2018-05-30T07:25:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 1.23,
      "latency": 295.0,
      "applicationDelay": 58.5
    },
    {
      "time": "2018-05-30T07:20:00.000+0000",
      "healthScore": 8.25,
      "packetLoss": 0.17,
      "latency": 139.75,
      "applicationDelay": 28.5
    },
    {
      "time": "2018-05-30T07:15:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.43,
      "latency": 155.5,
      "applicationDelay": 30.25
    },
    {
      "time": "2018-05-30T07:10:00.000+0000",
      "healthScore": 9.25,
      "packetLoss": 0.0,
      "latency": 137.25,
      "applicationDelay": 28.5
    },
    {
      "time": "2018-05-30T07:05:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.4,
      "latency": 328.5,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T07:00:00.000+0000",
      "healthScore": 9.25,
      "packetLoss": 0.0,
      "latency": 141.75,
      "applicationDelay": 29.5
    },
    {
      "time": "2018-05-30T06:55:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.38,
      "latency": 355.5,
      "applicationDelay": 44.5
    },
    {
      "time": "2018-05-30T06:50:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.77,
      "latency": 284.0,
      "applicationDelay": 53.5
    },
    {
      "time": "2018-05-30T06:45:00.000+0000",
      "healthScore": 8.0,
      "packetLoss": 0.0,
      "latency": 440.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T06:40:00.000+0000",
      "healthScore": 5.67,
      "packetLoss": 1.91,
      "latency": 224.0,
      "applicationDelay": 42.67
    }
  ]
}

});