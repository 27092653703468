define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/ConnectivityDomainTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/virtualnetwork/VirtualNetworkTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInfoTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVirtualNetworkData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVirtualNetworkContextData'
], function (SimLokiDatabaseActions, DefaultConfig, ConnectivityDomainTemplate, SimDefaultDataGenerator,
            UtilityFunctions, VirtualNetworkTemplate,DeviceInfoTemplate,SimVirtualNetworkData,SimNetworkDeviceData,SimTopologyData,
            SimVirtualNetworkContextData) {
      function createVNwithFD(fdId, fdName){
          var vnIds = [];
          var vncontextid = '', vnName = '', isVNdefault = false;
          //read vnc..
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext');
          if (! recordObj || recordObj.length == 0) {
              return [];
          }

          for(var i = 0, len = recordObj.length; i < len; i++){

              var tVnJson =  JSON.parse(JSON.stringify( VirtualNetworkTemplate.VirtualNetwork_Template));
              var vnid = UtilityFunctions.generate_uuid();
              var tName = recordObj[i].name + '-' + fdName;
              tVnJson.id = vnid;
              tVnJson.name = tName;
              tVnJson.namespace = fdId;
              tVnJson.virtualNetworkContextId = recordObj[i].id;

              tVnJson.isDefault = recordObj[i].isDefault ? true : false;
              tVnJson.isInfra = recordObj[i].virtualNetworkContextType=="INFRA" ? true : false;
              var vncObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':tVnJson.virtualNetworkContextId})
              tVnJson.siteCount = vncObj[0].siteCount;
              tVnJson.siteList = vncObj[0].siteList;
              tVnJson.l3Instance = vncObj[0].l3Instance;
              tVnJson.virtualNetworkContextName = vncObj[0].name;
              tVnJson.virtualNetworkContextType = vncObj[0].virtualNetworkContextType;
              tVnJson.createtime = UtilityFunctions.getTimeStamp();
              tVnJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
              tVnJson.instanceUpdatedOn =UtilityFunctions.getTimeStamp();
              tVnJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
              SimLokiDatabaseActions.insert('VirtualNetwork', tVnJson );
              vnIds.push(vnid);
          }
          return vnIds;
      }

      function createVNwithSiteName(fdId, vnList){
          var vnIds = [];
          for(var i = 0, len = vnList.length; i < len; i++){
              var tVnJson =  JSON.parse(JSON.stringify( VirtualNetworkTemplate.VirtualNetwork_Template));
              var vnid = UtilityFunctions.generate_uuid();
              var tName = vnList[i].name;
              tVnJson.id = vnid;
              tVnJson.name = tName;
              tVnJson.namespace = fdId;
              tVnJson.virtualNetworkContextId = vnList[i].virtualNetworkContextId;
              tVnJson.isDefault = vnList[i].isDefault;
              tVnJson.isInfra = vnList[i].isInfra;
              var vncObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':tVnJson.virtualNetworkContextId})
              tVnJson.siteCount = vncObj[0].siteCount;
              tVnJson.siteList = vncObj[0].siteList;
              tVnJson.l3Instance = vncObj[0].l3Instance;
              tVnJson.virtualNetworkContextName = vncObj[0].name;
              tVnJson.virtualNetworkContextType = vncObj[0].virtualNetworkContextType;
              tVnJson.createtime = UtilityFunctions.getTimeStamp();
              tVnJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
              tVnJson.instanceUpdatedOn =UtilityFunctions.getTimeStamp();
              tVnJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
              SimLokiDatabaseActions.insert('VirtualNetwork', tVnJson );
              vnIds.push(vnid);
          }
          return vnIds;
      }
      
        function updateAuthenticationDetails(fdId) {
            var authenticationProfiles = [];
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'namespace':'authentication'});
            records.forEach(record => {
                var tJson =  JSON.parse(JSON.stringify(ConnectivityDomainTemplate.AUTHENTICATION_PROFILE_TEMPLATE));
                var randomText = UtilityFunctions.generateId(8);
                var profileAttribs = {};
                function createAttribs(finalObj,array) {
                    if(array==undefined) { return; }
                    array.forEach(attrib => {
                        var key = attrib.key, val = attrib.value;
                        if(attrib.attribs && val) {
                            //array - so has val as well. add val along with the key
                            finalObj[key+"#"+val] = attrib.attribs;
                        } else if(attrib.attribs) {
                            createAttribs(finalObj, attrib.attribs);
                        } else {
                            finalObj[key] = val;
                        }
                    });
                }
                createAttribs(profileAttribs, record.profileAttributes);

                tJson.id = record.id,
                tJson.displayName = randomText + '[' + record.name + fdId +']',
                tJson.name = record.name,
                tJson.profileUuid = fdId;
                if(Object.keys(profileAttribs).length > 0 ) {
                    tJson.deploymentMode = profileAttribs['authentication.wired.deployment_mode'].toUpperCase(),
                    tJson.dot1xToMabFallbackTimeout = profileAttribs["authentication.wired.dot1x_to_mab_fallback_tmout"].toString(),
                    tJson.hostMode = profileAttribs["authentication.wired.hostmode"],                
                    tJson.order = profileAttribs["authentication.wired.order"],
                    tJson.priority = profileAttribs["authentication.wired.priority"],
                    tJson.type = profileAttribs["authentication.type"].replace(".","_").toUpperCase(),
                    tJson.wakeOnLan = profileAttribs["authentication.wired.wakeonlan"] == "true" ? true : false,
                    tJson.webauthBypassMap = 
                        profileAttribs["authentication.wired.webauth_bypass_map"]==undefined ? [] : 
                        profileAttribs["authentication.wired.webauth_bypass_map"];
                    if(profileAttribs["authentication.wired.preauth"]) {
                        tJson.preAuthAcl = {};                
                        tJson.preAuthAcl.aclDescription = profileAttribs["authentication.wired.preauth.acl_description"],
                        tJson.preAuthAcl.aclName = profileAttribs["authentication.wired.preauth.acl_name"],
                        tJson.preAuthAcl.contract = [], len = 0;
                        Object.keys(profileAttributes).forEach(function(key) {
                            if(key.contains('authentication.wired.preauth.contractentry')) {
                                var tempObj = {};
                                createAttribs(tempObj, profileAttributes[key].attribs);
                                var tempObj2 = {
                                    'id': UtilityFunctions.generate_uuid(),
                                    'action': tempObj['authentication.wired.preauth.contract.action'],
                                    'port': tempObj['authentication.wired.preauth.contract.port'],
                                    'protocol':tempObj['authentication.wired.preauth.contract.protocol']
                                }
                                tJson.preAuthAcl.contract.push(tempObj2);
                            }
                        });
                        tJson.preAuthAcl.defaultAction = profileAttribs["authentication.wired.preauth.default_action"].toUpperCase(),
                        tJson.preAuthAcl.enabled = profileAttribs["authentication.wired.preauth.enabled"]=="true" ? true : false;
                    } else {
                        delete tJson.preAuthAcl
                    }
                } else {
                    //No authentication
                    delete tJson.deploymentMode, tJson.dot1xToMabFallbackTimeout,
                    tJson.hostMode, tJson.order, tJson.priority,tJson.preAuthAcl;
                    tJson.type = "WIRED_DOT1X", tJson.wakeOnLan = false, tJson.webauthBypassMap = [];
                }
                authenticationProfiles.push(tJson);
            });
            return authenticationProfiles;
        }

      function createSiteSpecificDomain(siteSpecificDomain) {
       var siteObjectList=[];
       for(var i=0;i<siteSpecificDomain.length;i++) {
              if (siteSpecificDomain[i].idRef!=undefined) {
                    siteObjectList.push({ 'idRef' : siteSpecificDomain[i].idRef });
              continue;
              }
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Connectivty_Domain_Template));
            var id = UtilityFunctions.generate_uuid();
                tJson.id = id;
                tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                tJson.namespace = id;
                tJson.name = siteSpecificDomain[i].name;
                tJson.domainType= siteSpecificDomain[i].domainType;
                tJson.siteId=siteSpecificDomain[i].siteId;
                if(siteSpecificDomain[i].parentFabric)
                    tJson.parentFabricSimRef=(SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'name':siteSpecificDomain[i].parentFabric}))[0].id;
                var vnIds = createVNwithSiteName(id,siteSpecificDomain[i].virtualNetwork);
                for(var j = 0, len = vnIds.length; j < len; j++){
                    tJson.virtualNetwork.push({ 'idRef' : vnIds[j] });
                }
                //tJson.authenticationProfiles = updateAuthenticationDetails(id);
                tJson.authenticationProfiles = {"__isArray": true,"__link":"/api/v2/data/lazy-load/com.cisco.dnac.model.ConnectivityDomain/"+siteSpecificDomain[i].siteId+"/authenticationProfiles"};
                SimLokiDatabaseActions.insert('ConnectivityDomain', tJson);
                siteObjectList.push({ 'idRef' : id });
       }
       return siteObjectList;
      }

      function sdaTransitType(jsonObj) {
         var sdaDeviceList=[];
            for (var i = 0; i < jsonObj.deviceInfo.length; i++) {
                    var id = jsonObj.deviceInfo[i].id == undefined ? jsonObj.deviceInfo[i].idRef : jsonObj.deviceInfo[i].id;

                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': id});
                    if (recordObj && recordObj.length) {
                        if(jsonObj.deviceInfo[i].roles != undefined) {
                            recordObj[0].roles = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].roles));
                        }
                        if(jsonObj.deviceInfo[i].deviceSettings != undefined) {
                            recordObj[0].deviceSettings = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].deviceSettings))
                        }

                        recordObj[0].connectivityDomain.length = 0;

                        //to handle displaying of device,site in transit details, on addition of transit
                        //however, only if the site is added to fabric, it lists the details in the transit
                        //recordObj[0].connectivityDomain.push(jsonObj.id);
                        var siteId = jsonObj.deviceInfo[i].siteId;
                        var siteSpCd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'siteId': siteId});
                        if(siteSpCd.length>0) {
                            recordObj[0].connectivityDomain.push(siteSpCd[0].id);
                        }

                        recordObj[0].deviceInterfaceInfo.length = 0;
                        if(jsonObj.deviceInfo[i].deviceInterfaceInfo != undefined &&
                           jsonObj.deviceInfo[i].deviceInterfaceInfo.length) {
                            recordObj[0].deviceInterfaceInfo = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].deviceInterfaceInfo));
                        }

                        if(jsonObj.deviceInfo[i].transitNetworks!=undefined) {
                            recordObj[0].transitNetworks =JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].transitNetworks));
                        }
                        SimLokiDatabaseActions.update('DeviceInfo', recordObj);
                         sdaDeviceList.push({ 'idRef' : id });
                     }
            }
         return sdaDeviceList;
      }

    function exceptionCodeForDemo(jsonObj) {

        //For demo purpose SFO11 two AP will be hidden, once 9300 device is made 'Embedded wireless', that time
        //these two ap will be connect to this 'Embedded wireless' device. The function does this workaround or feature..

        //in DeviceInfo field name for device name is name
        var tObj= jsonObj.deviceInfo.filter(function (itm) { return (itm.name === "SFO11-C9300-Edge-01") });
        if( tObj && tObj.length == 0) {
            return false;
        }

        var tNwDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId':tObj[0].siteId});
        var tArr = tNwDevices.filter(function(itm) { return (itm.family == "Unified AP") });
        if(tArr && tArr.length <= 1) {
            //if there is 0 or 1 ap, don't do anything, just return from the function..
            return false;
        }

        var tApIds = [tArr[0].id, tArr[1].id ];
        var tTopoArr = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology',{'id':{'$in':tApIds}});

        var bNeedToAddedToTopology = false;
        if(tObj[0].roles.indexOf("MAPSERVER") >= 0 &&
              tObj[0].roles.indexOf("BORDERNODE") >= 0 && tObj[0].roles.indexOf("ENDPOINT_PROXY") >= 0 ) {
            bNeedToAddedToTopology = true;
        }

        if(bNeedToAddedToTopology == false || (bNeedToAddedToTopology && tTopoArr == 2) ) {
            //ap are already added in topology and it is already embedded wireless, don't do anything and return..
            //OR
            // device is not yet made embedded device hence exit..
            return false;
        }

        var sourceDeviceId = tObj[0].networkDeviceId;
        var siteId = tObj[0].siteId;

        //topology code need field in the form of swvrsion and platformid, hence adapting to that change..
        tArr[0].swversion = tArr[0].softwareVersion;
        tArr[1].swversion = tArr[1].softwareVersion;
        tArr[0].platformid = tArr[0].platformId;
        tArr[1].platformid = tArr[1].platformId;

        //add two ap to topology, link with 'embedded wireless' device and update site related information
        SimTopologyData.addDevice(sourceDeviceId, [ tArr[0] ]);
        SimTopologyData.addDevice(sourceDeviceId, [ tArr[1] ]);
        SimTopologyData.updateSiteDetails(tArr[0].id, siteId);
        SimTopologyData.updateSiteDetails(tArr[1].id, siteId);

        var tDevInfo = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id':tObj[0].id});

        //In json-template/wireless/WlanTemplate.js, profileId is defined for SFO11
        profileId = "4bead126-9c3a-4553-a34f-cc2d10cc1777";
        tDevInfo.profileId = profileId;
        tDevInfo.profileVersion = "1";
        SimLokiDatabaseActions.update('DeviceInfo', tDevInfo);

        SimNetworkDeviceData.createWlan(tObj[0].networkDeviceId, profileId, siteId);
        return true;
    }

    function createPortChannelInterface () {
        //Port channel is created for extended node and devices connected to external node
        var deviceInfoList = SimLokiDatabaseActions.getAll('DeviceInfo');
        var devices = SimLokiDatabaseActions.getAll('network-device');
        let topology = SimLokiDatabaseActions.getAll('physical-topology');
        
        var extendedDevices = deviceInfoList.filter(e => e.roles.indexOf("SUBTENDED_NODE") >= 0).map(e => e.networkDeviceId);
        var portChDevices = {};
        topology = topology.filter(e => e.topology_type=='link' && (extendedDevices.indexOf(e.source) > -1 || 
            extendedDevices.indexOf(e.target) > -1));
        topology.forEach(e => {
            portChDevices[e.source] = portChDevices[e.source] == undefined ? 1 : portChDevices[e.source]+1;
            portChDevices[e.target] = portChDevices[e.target] == undefined ? 1 : portChDevices[e.target]+1;
        });
        devices = devices.filter(e => Object.keys(portChDevices).indexOf(e.id) > -1);
        devices.forEach(e => {
            var tPortChannelInterfaceObjs = getPortChannelInterfaceTemplate(e, portChDevices[e.id]);
            SimLokiDatabaseActions.insert('device-interface', tPortChannelInterfaceObjs);
        });       

        var deviceInterfaceList = SimLokiDatabaseActions.getAll('device-interface');

        for(var i = 0, len = deviceInfoList.length; i < len; i++) {
            if(Object.keys(portChDevices).indexOf(deviceInfoList[i].networkDeviceId) > -1) {
                var id = deviceInfoList[i].deviceInterfaceInfoContainer.idRef;
                var portChannelObj = {};
                portChannelObj.id = id;
                portChannelObj.name = "DeviceIntfContainer_" + deviceInfoList[i].networkDeviceId;
                portChannelObj.networkDeviceId = deviceInfoList[i].networkDeviceId;
                var ifaceArr = deviceInterfaceList.filter(function (itm) { return (itm.deviceId == deviceInfoList[i].networkDeviceId) })
                portChannelObj.interfaceInfo = getPortChannelInterface(deviceInfoList[i], ifaceArr, portChDevices[deviceInfoList[i].networkDeviceId]);
                SimLokiDatabaseActions.insert('interface-port-channel', portChannelObj);
            }
        }
        console.log("Created port channels: "+JSON.stringify(portChDevices));
    }
    
    function getPortChannelInterface(deviceInfoObj, ifaceArr, noOfPortChIntf) {
        var arr = []
        for(var i=0; i<noOfPortChIntf; i++) {
            var obj = {}, idx = i+1;
            obj.id = UtilityFunctions.generate_uuid();
            obj.instanceId = 596596;
            obj.instanceVersion = 1;
            obj.interfaceName = "Port-channel"+(idx);
            obj.interfaceType = "PORTCHANNEL";
            obj.pagpMode = "DESIRABLE";
            obj.portChannelType =  "L2_LAYER";
            obj.protocol = "PAGP";
            obj.segment = [];
            obj.status = ifaceArr[idx].status;
            obj.connectedDeviceType = "EXTENDED_NODE";    
            obj.memberInterfaces = [{"id":ifaceArr[idx].id,
                "displayName":"ed1b3da0[e2d743df-6642-4690-95c8-fc0fad71220b,"+ ifaceArr[idx].portName,
                "interfaceName":ifaceArr[idx].portName,"instanceVersion":0,"instanceId":597597}];
            arr.push(obj);
        }        
        return arr;
    }

    function getPortChannelInterfaceTemplate(nwDeviceObj, noOfPortChIntf) {
        let portChannels = [];
        for(var i=0; i<noOfPortChIntf; i++) {
            var t = JSON.parse(JSON.stringify(DeviceInfoTemplate.PortChannel_Interface_Template));
            t.series = nwDeviceObj.series;
            t.pid = nwDeviceObj.platformId;
            t.deviceId = nwDeviceObj.id;
            var id =  UtilityFunctions.generate_uuid();
            t.id = id;
            t.instanceUuid = id;
            t.portName = "Port-channel"+(i+1);
            portChannels.push(t);
        }
        return portChannels;
    }

    function getFabricDeviceCounts(fabricLanRecord, connectivityDomainObjs, isLoadDevices) {
        var countDetails={"siteConnectivityDomainSummary":[]},fabricDevices=0,mapServerNodes=0,borderNodes=0;
        var edgeNodes=0,endPointProxyNodes=0,subtendedNode=0,tansitMapServerNodes=0, totalDevices=0;
        var siteList=fabricLanRecord.siteSpecificDomain;
        var transitCount = {}
        siteList = siteList.map(siteObj => siteObj.idRef);
        var fabricSiteRecords = connectivityDomainObjs.filter(fabricSiteObj => siteList.indexOf(fabricSiteObj.id) > -1 );
        if(fabricLanRecord.domainType=="FABRIC_SITE") fabricSiteRecords = [fabricLanRecord];
        fabricSiteRecords.forEach(fabricSiteObj => {
            var deviceList = fabricSiteObj.deviceInfo;
            var fabricSignificantDeviceInfoSummary = [], dummyId = UtilityFunctions.generateId(8), isTransitPresent = false;
            var transitConnectivityDomainSummary = [];
            deviceList = deviceList.map(deviceObj => deviceObj.idRef);
            var deviceInfoRecords = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': {'$in':deviceList}});
            deviceInfoRecords.forEach(deviceInfo => {
                if(deviceInfo.roles.indexOf('BORDERNODE')>-1) borderNodes++;
                if(deviceInfo.roles.indexOf('MAPSERVER')>-1) mapServerNodes++;
                if(deviceInfo.roles.indexOf('EDGENODE')>-1) edgeNodes++;
                if(deviceInfo.roles.indexOf('ENDPOINT_PROXY')>-1) endPointProxyNodes++;
                if(deviceInfo.roles.indexOf('SUBTENDED_NODE')>-1) subtendedNode++;
                if(deviceInfo.roles.indexOf('TRANSIT_MAPSERVER')>-1) tansitMapServerNodes++;
                if(isLoadDevices == "true") {
                    var deviceInfoSummary = {
                        "id": deviceInfo.id,
                        "instanceId": UtilityFunctions.getRandomIntForRangeValues(7805000, 7806000),
                        "displayName": dummyId+"["+deviceInfo.networkDeviceId+"]",
                        "instanceVersion": 0,
                        "deviceIpAddress": deviceInfo.managementIpAddress,
                        "deviceName": deviceInfo.name,
                        "networkDeviceId": deviceInfo.networkDeviceId,
                        "roles": deviceInfo.roles
                    };
                    fabricSignificantDeviceInfoSummary.push(deviceInfoSummary);
                }
                if(tansitMapServerNodes > 0) {
                    isTransitPresent = true;
                    var transitIdRefs = deviceInfo.transitNetworks;
                    transitIdRefs = transitIdRefs.map(e => e.idRef);
                    var tCD = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', 
                        {'id': {'$in': transitIdRefs} });
                    tCD.forEach(function (transitRecord, index) {
                        var transitObj;
                        if(transitCount[transitRecord.id] == undefined) {
                            transitObj = {
                                'id': transitRecord.id, "name": transitRecord.name, "transitType": transitRecord.transitType,
                                "instanceId": 7805819, "displayName": "7805819", "instanceVersion": 0,
                                "domainType": "TRANSIT", "isReprovisionRequired": false, "deviceCount": 0, "deviceInfoSummary": []
                            };
                            transitCount[transitRecord.id] = 1;
                        } else {
                            transitObj = transitRecord.id;//this clause is required to show multiple connectivities of Fabric sites to Fabric Transit, if present.
                            transitCount[transitRecord.id] += 1;
                        }
                        transitConnectivityDomainSummary.push(transitObj);
                    });
                }
            });
            totalDevices += deviceInfoRecords.length;
            var siteSummaryObj = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Fabric_SiteConnectivityDomainSummary_Template_1_3_3));
            var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': fabricSiteObj.siteId});
            if(site[0].name=="SJC04" && transitConnectivityDomainSummary.length==0) {
                //prepopulate border configuration for sdwan integration usecase (portion of requirement)
                var sdwanTransit = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'transitType':'SDWAN'});
                var transitObj = {
                    'id': sdwanTransit[0].id, "name": sdwanTransit[0].name, "transitType": 'SDWAN',
                    "instanceId": 7805819, "displayName": "7805819", "instanceVersion": 0,
                    "domainType": "TRANSIT", "isReprovisionRequired": false, "deviceCount": 0, "deviceInfoSummary": []
                };
                transitConnectivityDomainSummary.push(transitObj);
            }
            
            siteSummaryObj.id = fabricSiteObj.id;
            siteSummaryObj.name = fabricSiteObj.name;
            siteSummaryObj.siteId = fabricSiteObj.siteId
            siteSummaryObj.siteName = site[0].name;
            siteSummaryObj.siteHierarchy = site[0].groupNameHierarchy;
            siteSummaryObj.fabricSignificantDeviceInfoSummary = fabricSignificantDeviceInfoSummary;
            siteSummaryObj.transitConnectivityDomainSummary = transitConnectivityDomainSummary;
            countDetails.siteConnectivityDomainSummary.push(siteSummaryObj);
        });
        countDetails.borderCount = borderNodes;
        countDetails.deviceCount = totalDevices;
        countDetails.edgeCount = edgeNodes;
        countDetails.endpointProxyCount = endPointProxyNodes;
        countDetails.fabricSiteCount = fabricLanRecord.siteSpecificDomain.length;
        countDetails.mapServerCount = mapServerNodes;
        countDetails.subtendedCount = subtendedNode;
        countDetails.transitMapServerCount = tansitMapServerNodes;
        return countDetails;
    }

    function getFabricZoneInfo(zoneArr) {
        let fabZoneDtls = [], time = UtilityFunctions.getTimeStamp();
        zoneArr.forEach(zone => {
            let border = 0, edge = 0, map = 0;
            let devInfoIds = zone.deviceInfo.map(e=>e.idRef);
            let devInfo = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id':{'$in':devInfoIds}} );
            let devCount = devInfo.forEach(dev => {
                if(dev.roles.indexOf("EDGENODE")> -1) edge++;
                if(dev.roles.indexOf("BORDERNODE")> -1) border++;
                if(dev.roles.indexOf("MAPSERVER")> -1) map++;
            });
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':zone.siteId});
            tJson = {'instanceVersion':0, 'lastUpdateTime':time, 'fabricAPCount':0, 'peerNetworksCount':0, 'subtendedCount':0,
                'transitNetworksCount':0,'fabricSignificantDeviceInfoSummary':[], 'fabricZoneSummary':[],
                'transitConnectivityDomainSummary':[]};
            tJson.id = zone.id;
            tJson.instanceId = 83268257;
            tJson.displayName = '83268257';
            tJson.isDefault = zone.isDefault;
            tJson.name = zone.name;
            tJson.domainType = zone.domainType;
            tJson.isReprovisionRequired = zone.isReprovisionRequired;
            tJson.borderCount = border;
            tJson.edgeCount = edge;
            tJson.mapServerCount = map;
            tJson.fabricDeviceCount = border+edge+map;
            tJson.fabricSiteUuid = zone.siteId;
            tJson.siteId = zone.siteId;
            tJson.siteName = siteRec[0].name;
            tJson.siteNameHierarchy = siteRec[0].groupNameHierarchy;
            fabZoneDtls.push(tJson);
        });
        return fabZoneDtls;
    }

    return {
        init: function () {
            //get device idref based on some configuration..
            var deviceList = [];
            var devices = SimDefaultDataGenerator.getNetworkDevices();
            var len = devices.length;
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Connectivty_Domain_Template));
            tJson.id = DefaultConfig.DEFAULT_UUID.ConnectivityDomain;
            tJson.instanceId = 81082;
            tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
            tJson.namespace = DefaultConfig.DEFAULT_UUID.ConnectivityDomain;
            tJson.isDefault = true;
            tJson.name = DefaultConfig.DEFAULT_NAME.ConnectivityDomain;
          //  tJson.deviceInfo = deviceList;
            SimLokiDatabaseActions.insert('ConnectivityDomain', tJson);
            this.createDefaultFabricSite();
            createPortChannelInterface();
        },

        createDefaultFabricSite : function () {

           //Adding Fabric Domain

            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Connectivty_Domain_Template));
            tJson.name = "SanJose_Fabric";
            this.CUDonCD("post",tJson);
            // Adding fabric site
         //  var defaultConObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': DefaultConfig.DEFAULT_UUID.ConnectivityDomain});
          var defaultConObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': "SanJose_Fabric"});
          var siteList= ['SJC01','SJC06','SJC22','SJC23',"SFO11","SFO12","SFO13","SFO15","SJC04","Flr-SJC1-1"];
          var siteSpecficObj = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Site_Specific_Domain));
          //let siteType = "building";

            for(var j=0;j<siteList.length;j++) {
                var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:siteList[j]});
                //Fabric zone change - start
                /* for (var k=0; k<siteRecord[0].additionalInfo.length; k++) {
                    if (siteRecord[0].additionalInfo[k].nameSpace == "Location") {
                        siteType = siteRecord[0].additionalInfo[k].attributes.type;
                    }
                }
                if(siteType=="floor") siteSpecficObj = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.ZONE)); */
                //Fabric zone change - end

                siteSpecficObj[j].siteId= siteRecord[0].id;

                for(var i=0;i<siteSpecficObj[j].virtualNetwork.length;i++) {
                    var vnContext= SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', {'isDefault':siteSpecficObj[j].virtualNetwork[i].isDefault});
                    siteSpecficObj[j].virtualNetwork[i].virtualNetworkContextId= vnContext[0].id;
                }

            }

            defaultConObject[0].siteSpecificDomain= siteSpecficObj;
            console.log(siteSpecficObj);
            this.CUDonCD("put",defaultConObject[0]);


            // Adding transit type domain
            var transitDomainList = DefaultConfig.Transit_Domain;

            for(var i=0;i<transitDomainList.length;i++) {
                var transitDeviceInfo=[];
                var namesList =transitDomainList[i].deviceInfo;
                if(transitDomainList[i].transitType != "SDA") {
                    this.CUDonCD("post",transitDomainList[i]);
                    //continue;
                    transitDeviceInfo = namesList.map(e => { return {"name":e} });
                } else {
                    for(var j=0;j<namesList.length;j++) {
                        var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo',{'name':namesList[j]});
                        deviceObj= JSON.parse(JSON.stringify(deviceObj)); // for clone
                        deviceObj[0].roles =transitDomainList[i].roles;
                        deviceObj[0].transitNetworks = undefined;
                        transitDeviceInfo.push(deviceObj[0]);
                    }
                    transitDomainList[i].deviceInfo=transitDeviceInfo;
                    this.CUDonCD("post",transitDomainList[i]);
                }

                //Above transitNetwork was sent as undefined, as connectivity domain id was not known
                //Now Connectivity domain id is known, id is being used to update in DeviceInfo
                //Existing code is change to make functionality to work..
                var tCD = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'name':transitDomainList[i].name  });
                if(tCD && tCD.length) {
                    for(var k=0; k < transitDeviceInfo.length; k++) {
                        var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'name': transitDeviceInfo[k].name });
                        if(tObj && tObj.length) {
                            tObj[0].transitNetworks.push({"idRef":tCD[0].id});
                            SimLokiDatabaseActions.update("DeviceInfo", tObj);
                        }
                    }
                }
            }

            // Adding device to fabric
           var fabricSiteList =DefaultConfig.DEFAULT_FABRIC_DEVICE_LIST;

           this.addingDeviceToFabric(fabricSiteList);
           var defaultSiteFabricObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': fabricSiteList[0].siteName});

        /*    var defaultSiteFabricObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': fabricSiteList[0].siteName});

            var profileObject = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'siteProfileUuid': "55c4d9e1-7350-4333-a6d9-ab5eab79f20e"});
            defaultSiteFabricObject[0].authenticationProfileId=profileObject[0].siteProfileUuid;
            this.CUDonCD("put",defaultSiteFabricObject[0]); */

            // Adding segment
            var segmentList =DefaultConfig.Default_Segment;
            for(var i=0;i<segmentList.length;i++) {
                var segment =segmentList[i].segment;
                var ipPoolObject = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolName': segmentList[i].ipPoolName});
                if(ipPoolObject && ipPoolObject.length) {
                 segment.ipPoolId=ipPoolObject[0].id;
                 segment.ippools = ipPoolObject[0].ipPoolCidr;
                 segment.gateway = ipPoolObject[0].gateways[0]
                }
                segment.connectivityDomain={'idRef' : defaultSiteFabricObject[0].id };
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', { 'name' :segmentList[i].VNname  });

                recordObj =JSON.parse(JSON.stringify(recordObj));
                recordObj[0].segment.push(segment);
                SimVirtualNetworkData.AssociateVnToSegment(recordObj[0]);
            }
           /* // creating extended node
            var deviceInterface = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Interface_Info));
            var deviceInterfaceDetails = DefaultConfig.Default_Interface;
            var segmentObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'name' :deviceInterfaceDetails.name});
            deviceInterface.segment.push({'idRef' : segmentObj[0].id});
            deviceInterface.authenticationProfileId=profileObject[0].siteProfileUuid;
            var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'name': deviceInterfaceDetails.deviceName});
            deviceObj= JSON.parse(JSON.stringify(deviceObj)); // for clone
            deviceObj[0].deviceInterfaceInfo.push(deviceInterface);
            SimNetworkDeviceData.CreateOrUpdateDeviceInfo("DeviceInfo", "put", deviceObj[0]); */

        },

        addingDeviceToFabric : function(fabricSiteList) {
            for( var j=0;j< fabricSiteList.length; j++ ) {
              console.log(fabricSiteList[j].siteName);
              var defaultSiteFabricObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': fabricSiteList[j].siteName});

                defaultSiteFabricObject =JSON.parse(JSON.stringify(defaultSiteFabricObject));
                console.log(defaultSiteFabricObject);
               var deviceList = fabricSiteList[j].deviceList;

                var deviceInfo = [];

                    for(var i=0;i<deviceList.length;i++){
                        var deviceName= deviceList[i].deviceName;
                        var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'name': deviceName});
                        if(deviceObj.length == 0) {
                            console.log('DefaultConfig is not in good shape, error for --> ' + deviceName);
                            continue;
                        }

                        deviceObj =JSON.parse(JSON.stringify(deviceObj));
                        deviceObj[0].connectivityDomain.length = 0;
                        deviceObj[0].connectivityDomain.push(defaultSiteFabricObject[0].id);
                        deviceObj[0].roles =deviceList[i].roles;
                        if(deviceList[i].roles.indexOf("BORDERNODE")>=0) {
                             var deviceSettings = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Settings_Template));
                           /* var transit = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': deviceList[i].name});
                            var deviceSettings = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Settings_Template));
                            if(transit!=undefined) {
                               deviceObj[0].transitNetworks.push({ 'idRef' : transit[0].id });
                            } */

                            if(deviceList[i].transitType=="IP") {
                                var ipPoolObject = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'ipPoolName': deviceList[i].ipPoolName});
                                if(ipPoolObject && ipPoolObject.length) {
                                deviceSettings.externalConnectivityIpPool=ipPoolObject[0].id;
                                }
                            } else {
                                deviceSettings.externalConnectivityIpPool = "";
                            }

                           /* if(deviceList[i].transitType=="IP") {
                               for(var j=0;j<defaultSiteFabricObject[0].virtualNetwork.length;j++) {

                                  deviceSettings.extConnectivitySettings[0].l3Handoff.push({ "virtualNetwork": defaultSiteFabricObject[0].virtualNetwork[j]});
                               }

                            } else {
                              deviceSettings.extConnectivitySettings =[];
                            }  */
                            deviceObj[0].deviceSettings=deviceSettings;
                        }
                    deviceInfo.push(deviceObj[0]);
                    }
                    defaultSiteFabricObject[0].deviceInfo=deviceInfo;

                     var profileObject = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'siteProfileUuid': "55c4d9e1-7350-4333-a6d9-ab5eab79f20e"});
                     if(fabricSiteList[j].siteName=="Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric") {
                      defaultSiteFabricObject[0].authenticationProfileId=profileObject[0].siteProfileUuid;//1.3.3 uses fabricAuthenticationProfile
                      defaultSiteFabricObject[0].fabricAuthenticationProfile=profileObject[0];
                     }
                    this.CUDonCD("put",defaultSiteFabricObject[0]);

            }

        },

        CUDonCD: function (operation, jsonObj, paramNamesAndValues) {
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Connectivty_Domain_Template));
            if (operation == 'post') {
                var id = UtilityFunctions.generate_uuid();
                var time = UtilityFunctions.getTimeStamp();
                tJson.id = id;
                tJson.instanceId = 1250260;//81082;
                tJson.createTime = time;
                tJson.lastUpdateTime = time;
                tJson.namespace = id;
                tJson.isDefault = jsonObj.isDefault;
                tJson.name = jsonObj.name;
                tJson.deviceInfo = [];
             //   tJson.authenticationProfileId = jsonObj.authenticationProfileId;
                tJson.domainType = jsonObj.domainType;
                // this will not be applicable for fabric domain
              /*  tJson.authenticationProfileId = jsonObj.authenticationProfileId;
                var vnIds = createVNwithFD(id, jsonObj.name);
                for(var i = 0, len = vnIds.length; i < len; i++){
                    tJson.virtualNetwork.push({ 'idRef' : vnIds[i] });
                } */
                //Transit type creation
                if(jsonObj.domainType=='TRANSIT') {

                    if(jsonObj.transitType=="SDA") {
                      tJson.deviceInfo = sdaTransitType(jsonObj);
                      tJson.isPubSub = jsonObj.isPubSub==undefined ? false : jsonObj.isPubSub;
                     } else {
                     var data = jsonObj.transitMetaData[0];
                     data.id = UtilityFunctions.generate_uuid();
                     tJson.transitMetaData.push(data);

                     }
                   tJson.transitType = jsonObj.transitType;
                }
                 // Fabric Site creation
                if(jsonObj.siteSpecificDomain!=undefined && jsonObj.siteSpecificDomain.length>0){
                    tJson.siteSpecificDomain= createSiteSpecificDomain(jsonObj.siteSpecificDomain);;
                }
               SimLokiDatabaseActions.insert('ConnectivityDomain', tJson);
            }
            else if (operation == 'put') {
                var recordFdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': jsonObj.id})

                if(jsonObj.domainType=='TRANSIT') {
                   recordFdObj[0].name = jsonObj.name;
                   if(recordFdObj[0].transitType!=jsonObj.transitType && jsonObj.transitType=="SDA") {
                        recordFdObj[0].transitMetaData =[];
                   } else if(recordFdObj[0].transitType!=jsonObj.transitType && jsonObj.transitType=="IP") {

                         var data = jsonObj.transitMetaData[0];
                         data.id = UtilityFunctions.generate_uuid();
                         recordFdObj[0].transitMetaData.push(data);
                         var deviceList = recordFdObj[0].deviceInfo;
                         for(var i=0;i<deviceList.length;i++) {
                             var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': deviceList[i].idRef});
                             recordObj[0].roles = [];
                             SimLokiDatabaseActions.update('DeviceInfo', recordObj);
                         }
                   } else if(recordFdObj[0].transitType==jsonObj.transitType && jsonObj.transitType=="IP") {
                       recordFdObj[0].transitMetaData[0].name= jsonObj.transitMetaData[0].name;
                       recordFdObj[0].transitMetaData[0].value= jsonObj.transitMetaData[0].value;
                   }
                   recordFdObj[0].transitType = jsonObj.transitType;
                }
               

                if (recordFdObj && recordFdObj.length) {
                    recordFdObj[0].deviceInfo.length = 0;
                }
                recordFdObj[0].authenticationProfileId = jsonObj.authenticationProfileId;//1.3.3 using fabricAuthenticationProfile instead
                recordFdObj[0].fabricAuthenticationProfile = jsonObj.fabricAuthenticationProfile;
                if(jsonObj.fabricAuthenticationProfile && jsonObj.fabricAuthenticationProfile.idRef) {
                    recordFdObj[0].fabricAuthenticationProfile.id = jsonObj.fabricAuthenticationProfile.idRef;
                }
                if(jsonObj.authenticationProfiles) {
                    recordFdObj[0].authenticationProfiles = jsonObj.authenticationProfiles;
                }
                for (var i = 0; i < jsonObj.deviceInfo.length; i++) {
                    var id = jsonObj.deviceInfo[i].id == undefined ? jsonObj.deviceInfo[i].idRef : jsonObj.deviceInfo[i].id;

                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': id});
                    if (recordObj && recordObj.length) {
                        if(jsonObj.deviceInfo[i].roles != undefined) {
                            recordObj[0].roles = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].roles));
                        }
                        if(jsonObj.deviceInfo[i].deviceSettings != undefined) {
                            recordObj[0].deviceSettings = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].deviceSettings))
                        }

                        recordObj[0].connectivityDomain.length = 0;
                        //Transit PubSub/List changes - start
                        //recordObj[0].connectivityDomain.push(jsonObj.id);
                        var siteId = jsonObj.deviceInfo[i].siteId;
                        var siteSpCd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'siteId': siteId});
                        if(siteSpCd.length>0) {
                            recordObj[0].connectivityDomain.push(siteSpCd[0].id);
                        }
                        //Transit PubSub/List changes - end

                        recordObj[0].deviceInterfaceInfo.length = 0;
                        if(jsonObj.deviceInfo[i].deviceInterfaceInfo != undefined &&
                           jsonObj.deviceInfo[i].deviceInterfaceInfo.length) {
                            recordObj[0].deviceInterfaceInfo = JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].deviceInterfaceInfo));
                        }

                        if(jsonObj.deviceInfo[i].transitNetworks!=undefined) {
                            recordObj[0].transitNetworks =JSON.parse(JSON.stringify(jsonObj.deviceInfo[i].transitNetworks));
                        }
                        //Transit PubSub/List changes - start
                        if(jsonObj.domainType=='TRANSIT' && JSON.stringify(recordObj[0].transitNetworks).indexOf(jsonObj.id)==-1) {
                            recordObj[0].transitNetworks.push({'idRef':jsonObj.id});
                        }
                        //Transit PubSub/List changes - end
                        SimLokiDatabaseActions.update('DeviceInfo', recordObj);

                        recordFdObj[0].deviceInfo.push({idRef: id});
                    }
                }
                recordFdObj[0].multicastInfo = jsonObj.multicastInfo;
                recordFdObj[0].wirelessMulticastFeature =jsonObj.wirelessMulticastFeature;
                recordFdObj[0].enableMonitoring = jsonObj.enableMonitoring;
                if(jsonObj.siteSpecificDomain!=undefined && jsonObj.siteSpecificDomain.length>0){
                    var siteIdList = createSiteSpecificDomain(jsonObj.siteSpecificDomain);
                    recordFdObj[0].siteSpecificDomain= siteIdList;
                }

                //Actions on VN of Fabric Site
                if(jsonObj.domainType=='FABRIC_SITE' && jsonObj.virtualNetwork.length > 0) {
                    //add VN
                    for(var i = 0, len = jsonObj.virtualNetwork.length; i < len; i++){
                        if(jsonObj.virtualNetwork[i].idRef==undefined) {
                            var idx = jsonObj.virtualNetwork[i].name.indexOf("-Global");
                            var vnfdLinkid = SimVirtualNetworkContextData.createVNwithFD(
                                jsonObj.virtualNetwork[i].virtualNetworkContextId, 
                                jsonObj.virtualNetwork[i].name.substring(0,idx), false, recordFdObj[0].id,
                                recordFdObj[0].name);
                            var Virtual_NetworkContext_Id = jsonObj.virtualNetwork[i].virtualNetworkContextId;
                            recordFdObj[0].virtualNetwork.push({ 'idRef' : vnfdLinkid });
                        }
                    }
                    //delete VN
                    //NOTE: delete anchor VN when its referencing in other site is still active - not part of use case, not handled for now.
                    var idxVnDel = -1;
                    for(var i=0; i<recordFdObj[0].virtualNetwork.length; i++) {
                        var isFound = false
                        for(var j=0; j<jsonObj.virtualNetwork.length; j++){
                            if(recordFdObj[0].virtualNetwork[i].idRef == jsonObj.virtualNetwork[j].idRef || 
                              jsonObj.virtualNetwork[j].name!=undefined) {
                                //record present or is newly added
                                isFound = true;
                                break;//go for next check
                            }
                        }
                        if(!isFound) {
                            idxVnDel = i; break;
                        }
                    }
                    if(idxVnDel != -1) {
                        var idRef = recordFdObj[0].virtualNetwork[idxVnDel].idRef;
                        var vnRecord = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork',{'id':idRef});
                        recordFdObj[0].virtualNetwork.splice(idxVnDel,1);
                        SimLokiDatabaseActions.delete('VirtualNetwork',vnRecord);
                    }
                }
                //Updating the SiteId to the VirtualNetworkContext Table --ShockWave
                if(Virtual_NetworkContext_Id != undefined){
                    var VnContextObjData = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':Virtual_NetworkContext_Id});
                    VnContextObjData[0].siteList.push(jsonObj.siteId);
                    VnContextObjData[0].siteCount = VnContextObjData[0].siteList.length;
                    SimLokiDatabaseActions.update('virtualnetworkcontext', VnContextObjData);
                    var VnDataObjData =  SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork',{'virtualNetworkContextId':Virtual_NetworkContext_Id});
                    VnDataObjData[0].siteList.push(jsonObj.siteId);
                    VnDataObjData[0].siteCount = VnContextObjData[0].siteList.length;
                    VnDataObjData[0].virtualNetworkContextName = VnContextObjData[0].name;
                    VnDataObjData[0].virtualNetworkContextType = VnContextObjData[0].virtualNetworkContextType;
                    VnDataObjData[0].connectivityDomainId = recordFdObj[0].id
                    SimLokiDatabaseActions.update('VirtualNetwork', VnDataObjData);
                }
                
               SimLokiDatabaseActions.update('ConnectivityDomain', recordFdObj);

               //DemoSpecific use case handling
               exceptionCodeForDemo(jsonObj);
            }
            else if (operation == 'delete') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': jsonObj.id});
                if (recordObj && recordObj.length) {
                    if(recordObj[0].domainType=="FABRIC_SITE") {
                       var fabricDomain = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                                                     'siteSpecificDomain.idRef':  { '$contains' : jsonObj.id }
                                                                                });
                         if (fabricDomain && fabricDomain.length) {
                                  for(var i = fabricDomain[0].siteSpecificDomain.length; i--;) {
                                      if(fabricDomain[0].siteSpecificDomain[i].idRef === jsonObj.id) {
                                          fabricDomain[0].siteSpecificDomain.splice(i, 1);
                                      }
                                  }
                           SimLokiDatabaseActions.update('ConnectivityDomain', fabricDomain);

                         }
                    } else if(recordObj[0].domainType=="FABRIC_LAN") {
                        var isError = false;
                        if(recordObj[0].siteSpecificDomain.length > 0) {
                            isError = true;
                        } else {
                            SimLokiDatabaseActions.delete('ConnectivityDomain', recordObj);
                        }
                        return isError;
                    } else if(recordObj[0].domainType=="TRANSIT") {
                        //to handle deletion of role on deletion of transit domain
                        var devObjs = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo');
                        var devMatching = [];
                        devObjs.forEach(e => {
                            if(e.transitNetworks) {
                                e.transitNetworks.forEach(e2 => {
                                    if(e2.idRef==jsonObj.id) {
                                        devMatching.push(e.id);
                                    }
                                });
                            }
                        });
                        devObjs = devObjs.filter(e => devMatching.indexOf(e.id) > -1);
                        devObjs.forEach(e=>{
                            e.transitNetworks = [];
                            var idx = e.roles.indexOf("TRANSIT_MAPSERVER");
                            e.roles.splice(idx,1);
                            if(e.roles.length==0) e.connectivityDomain = [DefaultConfig.DEFAULT_UUID.ConnectivityDomain];
                        })
                        SimLokiDatabaseActions.update('DeviceInfo', devObjs);
                    }
                    SimLokiDatabaseActions.delete('ConnectivityDomain', recordObj);
                }
            }
        },

        getFabricSummary : function(domainId) {

          var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Fabric_SiteSummary));
          var summaryValue = JSON.parse(JSON.stringify(tJson[0].fabricSiteSummary[0]));
           tJson[0].fabricSiteSummary=[];
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': domainId});
          if (recordObj && recordObj.length) {
           var  siteSpecificDomainList =recordObj[0].siteSpecificDomain;
            for(var i=0;i<siteSpecificDomainList.length;i++) {
               var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': siteSpecificDomainList[i].idRef});
               var summary = JSON.parse(JSON.stringify(summaryValue));
               summary.fabricSiteName =siteObj[0].name;
               summary.fabricSiteUuid =siteObj[0].id;
               summary.siteId =siteObj[0].siteId;
               var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteObj[0].siteId});
               summary.siteName =site[0].name;
               tJson[0].fabricSiteSummary.push(summary);
            }

          }
          console.log(tJson);
         return tJson;

        },

        getFabricDomainSummary : function() {

            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Fabric_DomainSummary));
            var summaryValue = JSON.parse(JSON.stringify(tJson[0].fabricDomainSummary[0]));
            tJson[0].fabricDomainSummary=[];
            var recordObj = SimLokiDatabaseActions.getAll('ConnectivityDomain');
           if (recordObj && recordObj.length) {
            for(var i=0;i<recordObj.length;i++){
                var summary = JSON.parse(JSON.stringify(summaryValue));
                if(recordObj[i].domainType==="FABRIC_LAN"){
                    var fabricDevices=0,mapServerNodes=0,borderNodes=0;
                    var edgeNodes=0,endPointProxyNodes=0,subtendedNode=0,tansitMapServerNodes=0;
                    var siteList=recordObj[i].siteSpecificDomain;
                    if(siteList.length>0){
                        for(var k=0;k<siteList.length;k++){
                            var siteObj= recordObj.filter(function (itm) { return (itm.id===siteList[k].idRef); });
                            deviceList=siteObj[0].deviceInfo;
                            for(var j=0;j<deviceList.length;j++){
                               var deviceInfo=SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': deviceList[j].idRef});
                               if(deviceInfo[0].roles.indexOf('BORDERNODE')>-1) borderNodes++;
                               if(deviceInfo[0].roles.indexOf('MAPSERVER')>-1) mapServerNodes++;
                               if(deviceInfo[0].roles.indexOf('EDGENODE')>-1) edgeNodes++;
                               if(deviceInfo[0].roles.indexOf('ENDPOINT_PROXY')>-1) endPointProxyNodes++;
                               if(deviceInfo[0].roles.indexOf('SUBTENDED_NODE')>-1) subtendedNode++;
                               if(deviceInfo[0].roles.indexOf('TRANSIT_MAPSERVER')>-1) tansitMapServerNodes++;
                            }
                            summary.fabricSiteCount=siteList.length;
                            summary.fabricTotalDeviceCount=deviceList.length;
                            summary.fabricBorderCount=borderNodes;
                            summary.fabricEdgeCount=edgeNodes;
                            summary.fabricEndpointProxyCount=endPointProxyNodes;
                            summary.fabricMapServerCount=mapServerNodes;
                            summary.fabricSubtendedCount=subtendedNode;
                            summary.fabricTransitMapServerCount=tansitMapServerNodes;
                      }
                    }
                }
                summary.fabricDomainId =recordObj[i].id;
                tJson[0].fabricDomainSummary.push(summary);
            }
          }
            console.log(tJson);
           return tJson;
  
          },
        
        getConnectivityDomainSummary : function(filter) {
            //api/v2/data/customer-facing-service/summary/ConnectivityDomain?domainType=FABRIC_LAN,TRANSIT
            //api/v2/data/customer-facing-service/summary/ConnectivityDomain?connectivityDomainId=578acb39-bc94-4649-9156-75980e081cf2&loadAssociatedCDs=true&loadDevices=true
            //api/v2/data/customer-facing-service/summary/ConnectivityDomain?connectivityDomainId=578acb39-bc94-4649-9156-75980e081cf2&loadAssociatedCDs=true&loadDevices=false
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Fabric_DomainSummary_1_3_3));
            var tJsonSummary = JSON.parse(JSON.stringify(tJson[0].summaries[0]));
            tJson[0].summaries = [];
            var domainType = filter['domainType'] ? filter['domainType'].split(',') : [];
            var connectivityDoaminId = filter['connectivityDomainId'];
            var isLoadDevices = filter['loadDevices'] ? filter['loadDevices'] : "false";
            isLoadDevices = filter['loadAssociatedCDs']==true ? true : isLoadDevices;
            var connectivityDomainObjs = SimLokiDatabaseActions.getAll('ConnectivityDomain');
            var records = [];
            if(domainType.length>0) {
                records = connectivityDomainObjs.filter(domainObj => domainType.indexOf(domainObj.domainType) > -1);
            } else if(connectivityDoaminId != undefined) {
                records = connectivityDomainObjs.filter(domainObj => domainObj.id==connectivityDoaminId);
            }
            for(var i=0; i<records.length;i++) {
                var summary = {}
                if(records[i].domainType==="FABRIC_LAN" || records[i].domainType==="FABRIC_SITE"){
                    summary = JSON.parse(JSON.stringify(tJsonSummary));                 
                    var countDetails = getFabricDeviceCounts(records[i], connectivityDomainObjs, isLoadDevices);
                    summary.instanceId = connectivityDomainObjs[i].isDefault==true ? 16473 : 1250260;                    
                    summary.borderCount = countDetails.borderCount;
                    summary.deviceCount = countDetails.deviceCount;
                    summary.edgeCount = countDetails.edgeCount;
                    summary.endpointProxyCount = countDetails.endpointProxyCount;
                    summary.fabricSiteCount = records[i].siteSpecificDomain.length;
                    summary.mapServerCount = countDetails.mapServerCount;
                    summary.subtendedCount = countDetails.subtendedCount;
                    summary.siteConnectivityDomainSummary = countDetails.siteConnectivityDomainSummary;
                } else {
                    summary.instanceId = 7805819,
                    summary.instanceVersion = 0,
                    summary.createTime = records[i].createTime;
                    summary.lastUpdateTime = records[i].lastUpdateTime;
                    summary.domainType = "TRANSIT",
                    summary.isReprovisionRequired = false,
                    summary.isConflicted = false,
                    summary.isDefault = records[i].isDefault!=undefined ? records[i].isDefault : false;
                    summary.isPubSub = records[i].isPubSub!=undefined ? records[i].isPubSub : false;
                    summary.isRemote = records[i].isRemote!=undefined ? records[i].isRemote : false;
                    summary.isShared = records[i].isShared!=undefined ? records[i].isShared : false;
                    summary.isStale = records[i].isStale!=undefined ? records[i].isStale : false;
                    if(records[i].transitMetaData.length>0) 
                        summary.peerASN = records[i].transitMetaData[0].value;//BGP's value, present mainly for Transit IP
                    summary.deviceCount = records[i].deviceInfo.length,
                    summary.transitType = records[i].transitType,
                    summary.deviceInfoSummary = [];
                    summary.connectedBorderDeviceInfoSummary = [];
                    var siteConnDomObjs = connectivityDomainObjs.filter (e => e.domainType=="FABRIC_SITE"), sitesTransit=[];
                    records[i].deviceInfo.forEach(e3 => {
                        siteConnDomObjs.forEach(e1 => {
                            e1.deviceInfo.forEach(e2 => {
                                if(e2.idRef==e3.idRef && sitesTransit.indexOf(e1.id)==-1) sitesTransit.push(e1.id);
                            });
                        });
                    });
                    summary.siteCount = sitesTransit.length;
                }
                if(records[i].domainType==="FABRIC_SITE") {
                    var siteRec = SimLokiDatabaseActions.getFilteredRecordHandler("site",{"id":records[i].siteId});
                    summary.domainType = "FABRIC_SITE";
                    //summary.fabricZoneSummary = [];
                    summary.siteId = siteRec[0].id;
                    summary.siteName = siteRec[0].name;
                    summary.siteNameHierarchy = siteRec[0].groupNameHierarchy;
                    summary.fabricDeviceCount = countDetails.deviceCount;
                    var lanConnDomObjs = connectivityDomainObjs.filter (e => e.domainType=="FABRIC_LAN");
                    lanConnDomObjs.forEach(e1 => {
                        summary.lanConnectivityDomainUuid =  
                            (e1.siteSpecificDomain.filter(e2 => e2.idRef==records[i].id)).length>0 ? e1.id : summary.lanConnectivityDomainUuid;
                    });
                    /* var transitConnDomObjs = connectivityDomainObjs.filter (e => e.domainType=="TRANSIT"), transits=[];
                    records[i].deviceInfo.forEach(e3 => {
                        transitConnDomObjs.forEach(e1 => {
                            e1.deviceInfo.forEach(e2 => {
                                if(e2.idRef==e3.idRef && transits.indexOf(e1.id)==-1) transits.push(e1.id);
                            });
                        });
                    });
                    summary.transitNetworksCount = transits.length; */
                    summary.transitNetworksCount = countDetails.siteConnectivityDomainSummary[0].transitConnectivityDomainSummary.length;
                    summary.transitConnectivityDomainSummary = countDetails.siteConnectivityDomainSummary[0].transitConnectivityDomainSummary;
                    summary.peerNetworksCount = 0; //not sure what this means, hence assigning zero.
                    
                } 
                let fabZoneDtls = getFabricZoneInfo(SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', 
                    {'$and':[{'parentFabricSimRef':records[i].id}, {'domainType':'FABRIC_ZONE'} ]} ));
                summary.fabricZoneSummary = fabZoneDtls;
                summary.displayName = summary.instanceId.toString();
                summary.id = records[i].id;
                summary.name = records[i].name;
                summary.fabricDomainId =records[i].id;
                // added by parul
                if(fabZoneDtls.length!=0)
               {
                 summary.fabricDeviceCount = fabZoneDtls.reduce((result,x) => result + x.fabricDeviceCount, 0) -  fabZoneDtls.reduce((result,x) => result + x.edgeCount, 0)
               }
                tJson[0].summaries.push(summary);
            }
            if (domainType.indexOf("FABRIC_ZONE")>-1) {
                tJson[0].summaries = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'domainType':'FABRIC_ZONE'});
                return {'response':tJson,'version':'1.0'};
            }

            var data1 = {"id":UtilityFunctions.generate_uuid(),"response":tJson}
            return data1;
        },

        getLanFabricSummary : function(filter) {

          var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Device_Info_Lan_FabricSummary));
          var summaryValue = JSON.parse(JSON.stringify(tJson[0].deviceInfoLanFabricSummary[0]));
           tJson[0].deviceInfoLanFabricSummary=[];
          var domainId = filter["fLanDeviceList"];
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': domainId});
          var siteDomain =[];
          var roleValue =  filter['roles'];
          var roleList = roleValue.split(",");

          if (recordObj && recordObj.length) {
           var  siteSpecificDomainList =recordObj[0].siteSpecificDomain;
                for(var i=0;i<siteSpecificDomainList.length;i++) {

                  var fabricSiteId = siteSpecificDomainList[i].idRef;
                  var fabricSiteDomain = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': fabricSiteId});
                  var deviceList = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {
                                                                                             '$and': [ {
                                                                                                      'roles': { '$containsAny' : roleList}
                                                                                                      },
                                                                                                      {
                                                                                                        'connectivityDomain': { '$contains' : fabricSiteId}
                                                                                                      }
                                                                                                    ]
                                                                                        });
                    for(var j=0;j<deviceList.length;j++) {
                       var summary = JSON.parse(JSON.stringify(summaryValue));
                     summary.siteId=deviceList[j].siteId;
                     summary.deviceInfoUuid =deviceList[j].id;
                     summary.deviceIpAddress = deviceList[j].managementIpAddress;//as a exception ip addrees needed
                     summary.deviceName = deviceList[j].name;
                     summary.fabricSiteName =fabricSiteDomain[0].name;
                     summary.networkDeviceId =deviceList[j].networkDeviceId;
                     summary.roles= deviceList[j].roles;

                     var transitIdList =  deviceList[j].transitNetworks;

                     for(var k=0;k<transitIdList.length;k++) {
                        var fabricTransitDomain = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': transitIdList[k].idRef});
                        if(fabricTransitDomain.length>0) {
                         summary.transitNetworks.push(fabricTransitDomain[0].name);
                        }

                     }
                     tJson[0].deviceInfoLanFabricSummary.push(summary);
                    }
                }
          }
          console.log(tJson);
         return tJson;

        },

        getDeviceInfoSummary : function(filter) {

          var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Device_Info_Summary));
          var summaryValue = JSON.parse(JSON.stringify(tJson[0].deviceInfoSummary[0]));
           tJson[0].deviceInfoSummary=[];
          var roleValue =  filter['roles'];
          var roleList = roleValue.split(",");

          var deviceList = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {
                                                                                             '$and': [ {
                                                                                                      'roles': { '$containsAny' : roleList}
                                                                                                      },
                                                                                                      {
                                                                                                        'siteId': filter['siteDeviceList']
                                                                                                      }
                                                                                                    ]
                                                                                        });
          if(roleList.indexOf("MAPSERVER")>=0)  {

                return deviceList
          }

         if (deviceList && deviceList.length) {

                    for(var j=0;j<deviceList.length;j++) {
                       var summary = JSON.parse(JSON.stringify(summaryValue));

                     summary.deviceinfoUuid =deviceList[j].id;
                  //   summary.deviceIpAddress =
                     summary.deviceName = deviceList[j].name;
                     summary.networkDeviceId =deviceList[j].networkDeviceId;
                     summary.roles= deviceList[j].roles;
                     tJson[0].deviceInfoSummary.push(summary);
                    }
         }

         return tJson;
        },

        getDeviceInfoSummary_1_3_3 : function(siteId, roles) {
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.CF_DeviceInfoSummary));
            var summaryValue = JSON.parse(JSON.stringify(tJson[0].summaries[0]));
            tJson[0].summaries=[];
            roles = roles != undefined ? roles.split(",") : undefined;
            var deviceList, filter = {'$and':[]};
            if(roles!=undefined) {
                filter['$and'].push({'roles': {'$containsAny':roles} });
            } if(siteId!=undefined) {
                filter['$and'].push({'siteId': siteId});
            }
            if(filter['$and'].length > 0) {
                deviceList = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', filter);  
            } else {
                deviceList = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo');
            }
            if (deviceList && deviceList.length) {  
                for(var j=0; j<deviceList.length; j++) {
                    var summary = JSON.parse(JSON.stringify(summaryValue));
                    summary.id =deviceList[j].id;
                    summary.displayName = UtilityFunctions.generateId(8)+'['+deviceList[j].networkDeviceId+']';
                    summary.deviceName = deviceList[j].name;
                    summary.networkDeviceId =deviceList[j].networkDeviceId;
                    summary.roles= deviceList[j].roles;
                    summary.isInCD = summary.roles.length>0;
                    tJson[0].summaries.push(summary);
                }
           }
  
           return tJson;
          },

        getVirtualNetworkContextSummary :function(filter) {

          var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Virtual_NetworkContext_Info_Summary));
          var summaryValue = JSON.parse(JSON.stringify(tJson[0].virtualNetworkContextSummary[0]));
          tJson[0].virtualNetworkContextSummary=[];

           var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext');

            if (recordObj && recordObj.length) {

                    for(var j=0;j<recordObj.length;j++) {
                       var summary = JSON.parse(JSON.stringify(summaryValue));

                     summary.virtualNetworkContextUuid =recordObj[j].id;
                     summary.virtualNetworkContextName =recordObj[j].name;
                     summary.virtualNetworkContextType =recordObj[j].virtualNetworkContextType;

                     tJson[0].virtualNetworkContextSummary.push(summary);
                    }
         }
         return tJson;
        },

        getVirtualNetworkContextSummaryForId: function(vncUuid) {
            var data = [];
            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.Virtual_NetworkContext_Info_Summary[0].virtualNetworkContextSummary[0]));  
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', {'id':vncUuid});
            if (recordObj && recordObj.length) {
                recordObj.forEach(record => {
                    tJson = JSON.parse(JSON.stringify(tJson));
                    tJson.virtualNetworkContextUuid = record.id;
                    tJson.virtualNetworkContextName = record.name;
                    tJson.virtualNetworkContextType = record.virtualNetworkContextType;
                    //field introduced for Anchor VN - pools added in anchor VN (primary), will be filled in this field
                    tJson.vncSummaryPoolInfo = record.vncSummaryPoolInfo==undefined? [] : record.vncSummaryPoolInfo;
                    data.push(tJson);
                });  
            }              
            return data;
        },

        getVirtualNetworkContextSummary_2_2_3 :function(filter) {

            var tJson = JSON.parse(JSON.stringify(ConnectivityDomainTemplate.VirtualNetworkContextSummary_2_2_3));
            var summaryValue = JSON.parse(JSON.stringify(tJson.response[0].summaries[0]));
            tJson.response[0].summaries=[];
  
            if(filter['virtualNetworkContextType'] != undefined){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', {'virtualNetworkContextType' : { '$containsAny' :  filter["virtualNetworkContextType"]}});
            }else if(filter['id'] != undefined){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':filter['id']})
            }
            else{
                var recordObj = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
            }

            if (recordObj && recordObj.length) {

                    for(var j=0;j<recordObj.length;j++) {
                        var summary = JSON.parse(JSON.stringify(summaryValue));

                    summary.id =recordObj[j].id;
                    summary.name =recordObj[j].name;
                    summary.virtualNetworkContextType =recordObj[j].virtualNetworkContextType;
                    summary.instanceId = recordObj[j].instanceId;
                    summary.l3Instance = recordObj[j].l3Instance
                    summary.siteCount = recordObj[j].siteCount;
                    summary.siteList = recordObj[j].siteList;
                    if(recordObj[j].isDefault == true || recordObj[j].name == 'INFRA_VN'){
                        summary.segmentCount = 1;
                    }
                    summary.vncSummaryPoolInfo = recordObj[j].vncSummaryPoolInfo==undefined? [] : recordObj[j].vncSummaryPoolInfo; //fix for ippools to load in achor VN usecase

                    tJson.response[0].summaries.push(summary);
                    }
            }
           return tJson;
        },
        
        updateAuthenticationDetails: function(fdId) {
            var authenticationProfiles = [];
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'namespace':'authentication'});
            records.forEach(record => {
                var tJson =  JSON.parse(JSON.stringify(ConnectivityDomainTemplate.AUTHENTICATION_PROFILE_TEMPLATE));
                var randomText = UtilityFunctions.generateId(8);
                var profileAttribs = {};
                function createAttribs(finalObj,array) {
                    if(array==undefined) { return; }
                    array.forEach(attrib => {
                        var key = attrib.key, val = attrib.value;
                        if(attrib.attribs && val) {
                            //array - so has val as well. add val along with the key
                            finalObj[key+"#"+val] = attrib.attribs;
                        } else if(attrib.attribs) {
                            createAttribs(finalObj, attrib.attribs);
                        } else {
                            finalObj[key] = val;
                        }
                    });
                }
                createAttribs(profileAttribs, record.profileAttributes);
                tJson.profileUuid = UtilityFunctions.generate_uuid();
                tJson.id = record.id;
                tJson.displayName = randomText + '[' + record.name +','+ tJson.profileUuid +']';
                tJson.name = record.name;                

                if(Object.keys(profileAttribs).length > 0 ) {
                    tJson.deploymentMode = profileAttribs['authentication.wired.deployment_mode'].toUpperCase(),
                    tJson.dot1xToMabFallbackTimeout = profileAttribs["authentication.wired.dot1x_to_mab_fallback_tmout"].toString(),
                    tJson.hostMode = profileAttribs["authentication.wired.hostmode"],                
                    tJson.order = profileAttribs["authentication.wired.order"],
                    tJson.priority = profileAttribs["authentication.wired.priority"],
                    tJson.type = profileAttribs["authentication.type"].replace(".","_").toUpperCase(),
                    tJson.wakeOnLan = profileAttribs["authentication.wired.wakeonlan"] == "true" ? true : false,
                    tJson.webauthBypassMap = 
                        profileAttribs["authentication.wired.webauth_bypass_map"]==undefined ? [] : 
                        profileAttribs["authentication.wired.webauth_bypass_map"];
                    if(profileAttribs["authentication.wired.preauth"]) {
                        tJson.preAuthAcl = {};                
                        tJson.preAuthAcl.aclDescription = profileAttribs["authentication.wired.preauth.acl_description"],
                        tJson.preAuthAcl.aclName = profileAttribs["authentication.wired.preauth.acl_name"],
                        tJson.preAuthAcl.contract = [], len = 0;
                        Object.keys(profileAttributes).forEach(function(key) {
                            if(key.contains('authentication.wired.preauth.contractentry')) {
                                var tempObj = {};
                                createAttribs(tempObj, profileAttributes[key].attribs);
                                var tempObj2 = {
                                    'id': UtilityFunctions.generate_uuid(),
                                    'action': tempObj['authentication.wired.preauth.contract.action'],
                                    'port': tempObj['authentication.wired.preauth.contract.port'],
                                    'protocol':tempObj['authentication.wired.preauth.contract.protocol']
                                }
                                tJson.preAuthAcl.contract.push(tempObj2);
                            }
                        });
                        tJson.preAuthAcl.defaultAction = profileAttribs["authentication.wired.preauth.default_action"].toUpperCase(),
                        tJson.preAuthAcl.enabled = profileAttribs["authentication.wired.preauth.enabled"]=="true" ? true : false;
                    } else {
                        delete tJson.preAuthAcl
                    }
                } else {
                    //No authentication
                    delete tJson.deploymentMode, tJson.dot1xToMabFallbackTimeout,
                    tJson.hostMode, tJson.order, tJson.priority,tJson.preAuthAcl;
                    tJson.type = "WIRED_DOT1X", tJson.wakeOnLan = false, tJson.webauthBypassMap = [];
                }
                authenticationProfiles.push(tJson);
            });
            return authenticationProfiles;
        }
    };
});
