define({
    "WIFI6_LATENCY_VOICE": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "nonAxLatency": 268944,
                "axLatency": 208545,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "nonAxLatency": 355565,
                "axLatency": 230715,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "nonAxLatency": 397527,
                "axLatency": 256505,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "nonAxLatency": 428257,
                "axLatency": 238290,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "nonAxLatency": 394538,
                "axLatency": 241763,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "nonAxLatency": 376780,
                "axLatency": 227421,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "nonAxLatency": 401788,
                "axLatency": 245096,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "nonAxLatency": 409069,
                "axLatency": 251017,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "nonAxLatency": 388892,
                "axLatency": 233231,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "nonAxLatency": 361818,
                "axLatency": 201907,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "nonAxLatency": 239162,
                "axLatency": 263328,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "nonAxLatency": 138601,
                "axLatency": 176534,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "nonAxLatency": 350633,
                "axLatency": 231804,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "nonAxLatency": 339538,
                "axLatency": 209413,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "nonAxLatency": 419770,
                "axLatency": 280696,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "nonAxLatency": 416889,
                "axLatency": 234733,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "nonAxLatency": 402719,
                "axLatency": 281618,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "nonAxLatency": 242667,
                "axLatency": 262321,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "nonAxLatency": 5144,
                "axLatency": 195192,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "nonAxLatency": 3324,
                "axLatency": 244752,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "nonAxLatency": 321986,
                "axLatency": 341678,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "nonAxLatency": 197664,
                "axLatency": 5933,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "nonAxLatency": 126346,
                "axLatency": 234,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "nonAxLatency": 166815,
                "axLatency": 4248,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "nonAxLatency": 368054,
                "axLatency": 222789,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "nonAxLatency": 290307,
                "axLatency": 258928,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "nonAxLatency": 271436,
                "axLatency": 304490,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "nonAxLatency": 445967,
                "axLatency": 260851,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "nonAxLatency": 385511,
                "axLatency": 253259,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "nonAxLatency": 392715,
                "axLatency": 231982,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "nonAxLatency": 59196,
                "axLatency": 85255,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "nonAxLatency": 207778.5,
                "axLatency": 242257,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "nonAxLatency": 298108,
                "axLatency": 184973,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "nonAxLatency": 188487.5,
                "axLatency": 261767,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "nonAxLatency": 295084,
                "axLatency": 219608,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "nonAxLatency": 332657,
                "axLatency": 215624,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "nonAxLatency": 264703,
                "axLatency": 173891,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "nonAxLatency": 302157,
                "axLatency": 221151,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "nonAxLatency": 332979,
                "axLatency": 253597,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "nonAxLatency": 460351,
                "axLatency": 281983,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "nonAxLatency": 246440,
                "axLatency": 155413,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "nonAxLatency": 450126,
                "axLatency": 223932,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "nonAxLatency": 506866,
                "axLatency": 232771,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "nonAxLatency": 468843,
                "axLatency": 226872,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "nonAxLatency": 434330,
                "axLatency": 227710,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "nonAxLatency": 500761,
                "axLatency": 278376,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "nonAxLatency": 463289,
                "axLatency": 258960,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "nonAxLatency": 420536,
                "axLatency": 224060,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "nonAxLatency": 351205,
                "axLatency": 176941,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "nonAxLatency": 80321,
                "axLatency": 84587,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "nonAxLatency": 252431,
                "axLatency": 285190,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "nonAxLatency": 367206,
                "axLatency": 373415,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "nonAxLatency": 263853,
                "axLatency": 247250,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "nonAxLatency": 270034,
                "axLatency": 289875,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "nonAxLatency": 117870,
                "axLatency": 4569,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "nonAxLatency": 127125,
                "axLatency": 15016,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "nonAxLatency": 110035,
                "axLatency": 125825,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "nonAxLatency": 106201,
                "axLatency": 135665,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "nonAxLatency": 83196,
                "axLatency": 103620,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "nonAxLatency": 421767,
                "axLatency": 218376,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "nonAxLatency": 474638,
                "axLatency": 252191,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "nonAxLatency": 508878,
                "axLatency": 266964,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "nonAxLatency": 485851,
                "axLatency": 243379,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "nonAxLatency": 421270,
                "axLatency": 210146,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "nonAxLatency": 488828,
                "axLatency": 257485,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "nonAxLatency": 379631,
                "axLatency": 246706,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "nonAxLatency": 468956,
                "axLatency": 243106,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "nonAxLatency": 409267,
                "axLatency": 219247,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "nonAxLatency": 258800,
                "axLatency": 312417,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "nonAxLatency": 297575,
                "axLatency": 266940,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "nonAxLatency": 273634,
                "axLatency": 286699,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "nonAxLatency": 341763,
                "axLatency": 358159,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "nonAxLatency": 273472,
                "axLatency": 401811,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "nonAxLatency": 302203,
                "axLatency": 300891,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "nonAxLatency": 225495,
                "axLatency": 218486,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "nonAxLatency": 435362,
                "axLatency": 218195,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "nonAxLatency": 118217,
                "axLatency": 124653,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "nonAxLatency": 485491,
                "axLatency": 247786,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "nonAxLatency": 447123,
                "axLatency": 220756,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "nonAxLatency": 331773,
                "axLatency": 204072,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "nonAxLatency": 153177,
                "axLatency": 278,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "nonAxLatency": 418052,
                "axLatency": 212640,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "nonAxLatency": 532388,
                "axLatency": 263115,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "nonAxLatency": 456589,
                "axLatency": 240109,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "nonAxLatency": 455824,
                "axLatency": 235264,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "nonAxLatency": 294783,
                "axLatency": 152935,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "nonAxLatency": 181305,
                "axLatency": 273082,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "nonAxLatency": 254225,
                "axLatency": 262758,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "nonAxLatency": 270608,
                "axLatency": 294273,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "nonAxLatency": 262342,
                "axLatency": 281111,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "nonAxLatency": 356384,
                "axLatency": 223306,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "nonAxLatency": 360720,
                "axLatency": 222240,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "nonAxLatency": 253929,
                "axLatency": 150909,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "nonAxLatency": 223399,
                "axLatency": 190691,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "nonAxLatency": 299207,
                "axLatency": 131319,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "nonAxLatency": 363678,
                "axLatency": 196980,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "nonAxLatency": 399633,
                "axLatency": 245618,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "nonAxLatency": 407002,
                "axLatency": 218505,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "nonAxLatency": 330422,
                "axLatency": 224370,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "nonAxLatency": 146625,
                "axLatency": 95791,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "nonAxLatency": 459786,
                "axLatency": 215902,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "nonAxLatency": 93175,
                "axLatency": 101627,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "nonAxLatency": 90201,
                "axLatency": 115168,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "nonAxLatency": 89056,
                "axLatency": 119192,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "nonAxLatency": 433769,
                "axLatency": 241841,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "nonAxLatency": 421057,
                "axLatency": 224333,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "nonAxLatency": 443920,
                "axLatency": 227338,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "nonAxLatency": 459335,
                "axLatency": 222554,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "nonAxLatency": 394184,
                "axLatency": 193570,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "nonAxLatency": 445343,
                "axLatency": 215277,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "nonAxLatency": 416010,
                "axLatency": 215271,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "nonAxLatency": 438414,
                "axLatency": 222960,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "nonAxLatency": 118781,
                "axLatency": 754,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "nonAxLatency": 509416,
                "axLatency": 242400,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "nonAxLatency": 423874,
                "axLatency": 233254,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "nonAxLatency": 471713,
                "axLatency": 237028,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "nonAxLatency": 497879,
                "axLatency": 256764,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "nonAxLatency": 440432,
                "axLatency": 227887,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "nonAxLatency": 177185,
                "axLatency": 140433,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "nonAxLatency": 534135,
                "axLatency": 271091,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "nonAxLatency": 368539,
                "axLatency": 327782,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "nonAxLatency": 279605,
                "axLatency": 354203,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "nonAxLatency": 280448,
                "axLatency": 292481,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "nonAxLatency": 315783,
                "axLatency": 287461,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "nonAxLatency": 16915,
                "axLatency": 136176,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "nonAxLatency": 170277,
                "axLatency": 310940,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "nonAxLatency": 128988,
                "axLatency": 2830,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "nonAxLatency": 139530,
                "axLatency": 3072,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "nonAxLatency": 461711,
                "axLatency": 271153,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "nonAxLatency": 410193,
                "axLatency": 221048,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "nonAxLatency": 479250,
                "axLatency": 245701,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "nonAxLatency": 427018,
                "axLatency": 223910,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "nonAxLatency": 428609,
                "axLatency": 225655,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "nonAxLatency": 431647,
                "axLatency": 267353,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "nonAxLatency": 465271,
                "axLatency": 223314,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "nonAxLatency": 479004,
                "axLatency": 228560,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "nonAxLatency": 517585,
                "axLatency": 255083,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "nonAxLatency": 361302,
                "axLatency": 231850,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "nonAxLatency": 459120,
                "axLatency": 228106,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "nonAxLatency": 487750,
                "axLatency": 252296,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "nonAxLatency": 97193,
                "axLatency": 113058,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "nonAxLatency": 458082,
                "axLatency": 245462,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "nonAxLatency": 473246,
                "axLatency": 244581,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "nonAxLatency": 477712,
                "axLatency": 244413,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "nonAxLatency": 450865,
                "axLatency": 217468,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "nonAxLatency": 465617,
                "axLatency": 238601,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "nonAxLatency": 259631,
                "axLatency": 225435,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "nonAxLatency": 408763,
                "axLatency": 223488,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "nonAxLatency": 409749,
                "axLatency": 218645,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "nonAxLatency": 361292,
                "axLatency": 196013,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "nonAxLatency": 441049,
                "axLatency": 234855,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "nonAxLatency": 422454,
                "axLatency": 218963,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "nonAxLatency": 108882,
                "axLatency": 1401,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "nonAxLatency": 487382,
                "axLatency": 241960,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "nonAxLatency": 505769,
                "axLatency": 242888,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "nonAxLatency": 419583,
                "axLatency": 214197,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "nonAxLatency": 503646,
                "axLatency": 249905,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "nonAxLatency": 503052,
                "axLatency": 253697,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "nonAxLatency": 263207,
                "axLatency": 265607,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "nonAxLatency": 487158,
                "axLatency": 240594,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "nonAxLatency": 474100,
                "axLatency": 235814,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "nonAxLatency": 559783,
                "axLatency": 284569,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "nonAxLatency": 472408,
                "axLatency": 240930,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "nonAxLatency": 508285,
                "axLatency": 258818,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "nonAxLatency": 524242,
                "axLatency": 259104,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "nonAxLatency": 271596,
                "axLatency": 276582,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "nonAxLatency": 292171,
                "axLatency": 296113,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "nonAxLatency": 308122,
                "axLatency": 311260,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "nonAxLatency": 446632,
                "axLatency": 232947,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "nonAxLatency": 436304,
                "axLatency": 245111,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "nonAxLatency": 499737,
                "axLatency": 253333,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "nonAxLatency": 112602,
                "axLatency": 431,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "nonAxLatency": 120141,
                "axLatency": 243,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "nonAxLatency": 147957,
                "axLatency": 1010,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "nonAxLatency": 155240,
                "axLatency": 1368,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "nonAxLatency": 286370,
                "axLatency": 280559,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "nonAxLatency": 291417,
                "axLatency": 295261,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "nonAxLatency": 470542,
                "axLatency": 254688,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "nonAxLatency": 422662,
                "axLatency": 245362,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "nonAxLatency": 444124,
                "axLatency": 235830,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "nonAxLatency": 364884,
                "axLatency": 224397,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "nonAxLatency": 530232,
                "axLatency": 245765,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "nonAxLatency": 454106,
                "axLatency": 222970,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "nonAxLatency": 431591,
                "axLatency": 236461,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "nonAxLatency": 443789,
                "axLatency": 235531,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "nonAxLatency": 307965,
                "axLatency": 171946,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "nonAxLatency": 454042,
                "axLatency": 223577,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "nonAxLatency": 483938,
                "axLatency": 224239,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "nonAxLatency": 433610,
                "axLatency": 218697,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "nonAxLatency": 543536,
                "axLatency": 274178,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "nonAxLatency": 424105,
                "axLatency": 221673,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "nonAxLatency": 403485,
                "axLatency": 218061,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "nonAxLatency": 291008,
                "axLatency": 180211,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "nonAxLatency": 126101,
                "axLatency": 251,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "nonAxLatency": 120700,
                "axLatency": 288,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "nonAxLatency": 115464,
                "axLatency": 305,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "nonAxLatency": 145951,
                "axLatency": 2382,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "nonAxLatency": 459885,
                "axLatency": 223856,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "nonAxLatency": 411053,
                "axLatency": 224413,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "nonAxLatency": 459029,
                "axLatency": 218171,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "nonAxLatency": 420165,
                "axLatency": 215675,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "nonAxLatency": 61001,
                "axLatency": 79697,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "nonAxLatency": 304166,
                "axLatency": 147475,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "nonAxLatency": 117315,
                "axLatency": 1625,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "nonAxLatency": 497025,
                "axLatency": 251107,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "nonAxLatency": 368450,
                "axLatency": 244809,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "nonAxLatency": 435861,
                "axLatency": 222317,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "nonAxLatency": 74220,
                "axLatency": 99521,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "nonAxLatency": 69202,
                "axLatency": 111111,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "nonAxLatency": 76254,
                "axLatency": 109191,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "nonAxLatency": 433449,
                "axLatency": 226570,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "nonAxLatency": 445305,
                "axLatency": 214591,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "nonAxLatency": 404738,
                "axLatency": 202746,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "nonAxLatency": 476950,
                "axLatency": 267781,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "nonAxLatency": 260807,
                "axLatency": 301718,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "nonAxLatency": 448205,
                "axLatency": 243010,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "nonAxLatency": 508586,
                "axLatency": 270468,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "nonAxLatency": 642310,
                "axLatency": 322396,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "nonAxLatency": 572820,
                "axLatency": 283733,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "nonAxLatency": 465925,
                "axLatency": 258788,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "nonAxLatency": 419672,
                "axLatency": 222329,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "nonAxLatency": 481170,
                "axLatency": 245052,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "nonAxLatency": 292452,
                "axLatency": 147710,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "nonAxLatency": 479873,
                "axLatency": 234248,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "nonAxLatency": 502469,
                "axLatency": 255216,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "nonAxLatency": 479368,
                "axLatency": 233515,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "nonAxLatency": 475729,
                "axLatency": 237849,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "nonAxLatency": 283535,
                "axLatency": 292129,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "nonAxLatency": 134569,
                "axLatency": 319,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "nonAxLatency": 455411,
                "axLatency": 246002,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "nonAxLatency": 345575,
                "axLatency": 184020,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "nonAxLatency": 487810,
                "axLatency": 252538,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "nonAxLatency": 376680,
                "axLatency": 243270,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "nonAxLatency": 407427,
                "axLatency": 236623,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "nonAxLatency": 273873,
                "axLatency": 220457,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "nonAxLatency": 63627,
                "axLatency": 79618,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "nonAxLatency": 73912,
                "axLatency": 98556,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "nonAxLatency": 78384,
                "axLatency": 107003,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "nonAxLatency": 466086,
                "axLatency": 240487,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "nonAxLatency": 383663,
                "axLatency": 204358,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "nonAxLatency": 466116,
                "axLatency": 246099,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "nonAxLatency": 283496,
                "axLatency": 288081,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "nonAxLatency": 272881,
                "axLatency": 281668,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "nonAxLatency": 300994,
                "axLatency": 315495,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "nonAxLatency": 514080,
                "axLatency": 276613,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "nonAxLatency": 416947,
                "axLatency": 226637,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "nonAxLatency": 460801,
                "axLatency": 240462,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "nonAxLatency": 163636,
                "axLatency": 27614,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "nonAxLatency": 424771,
                "axLatency": 223182,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "nonAxLatency": 487463,
                "axLatency": 250849,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "nonAxLatency": 293527,
                "axLatency": 320623,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "nonAxLatency": 459353,
                "axLatency": 251389,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "nonAxLatency": 496261,
                "axLatency": 264256,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "nonAxLatency": 481308,
                "axLatency": 265424,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "nonAxLatency": 306162,
                "axLatency": 185774,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "nonAxLatency": 487857,
                "axLatency": 241575,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "nonAxLatency": 503726,
                "axLatency": 249966,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "nonAxLatency": 275373,
                "axLatency": 312963,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "nonAxLatency": 257108,
                "axLatency": 283208,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "nonAxLatency": 131958,
                "axLatency": 230709,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "nonAxLatency": 288158,
                "axLatency": 295345,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "nonAxLatency": 295409,
                "axLatency": 304160,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "nonAxLatency": 260631,
                "axLatency": 283721,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "nonAxLatency": 296400,
                "axLatency": 317874,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "nonAxLatency": 267904,
                "axLatency": 304097,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "nonAxLatency": 256550,
                "axLatency": 302343,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "nonAxLatency": 357580,
                "axLatency": 231728,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "nonAxLatency": 425240,
                "axLatency": 243776,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "nonAxLatency": 495879,
                "axLatency": 273906,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "nonAxLatency": 454350,
                "axLatency": 238068,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "nonAxLatency": 459297,
                "axLatency": 235533,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "nonAxLatency": 511159,
                "axLatency": 263923,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "nonAxLatency": 279742,
                "axLatency": 292122,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "nonAxLatency": 259097,
                "axLatency": 273891,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "nonAxLatency": 273758,
                "axLatency": 316141,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "nonAxLatency": 288330,
                "axLatency": 314404,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "nonAxLatency": 277782,
                "axLatency": 302057,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "nonAxLatency": 283965,
                "axLatency": 288356,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "nonAxLatency": 254693,
                "axLatency": 289766,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "nonAxLatency": 235248,
                "axLatency": 255395,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "nonAxLatency": 271796,
                "axLatency": 293487,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "nonAxLatency": 304714,
                "axLatency": 338406,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "nonAxLatency": 282443,
                "axLatency": 328060,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "nonAxLatency": 303101,
                "axLatency": 311279,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "nonAxLatency": 326811,
                "axLatency": 336736,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "nonAxLatency": 507048,
                "axLatency": 263552,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "nonAxLatency": 459824,
                "axLatency": 255116,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": null
    }
})