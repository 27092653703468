define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimCommandRunnerData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/command-runner/models/SimCommandRunnerHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/CommandSupportedTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/PnpDeviceInfoTemplate'
], function (SimLokiDatabaseActions, SimTaskDataGenerator, SimCommandRunnerData,
    UtilityFunctions, SimulationUtility, SimCommandRunnerHelper, CommandSupportedTemplate, PnpDeviceInfoTemplate) {

        var CMD_RESPONSE_PATH = 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/';
        var idCmdMapping = new Object();
        //var idDeviceMapping = new Object();

        function filterCommand(cmd) {
            var tCmd = cmd;
            if (cmd.indexOf('ping') >= 0) {
                tCmd = 'ping';
            } else if (cmd.indexOf('traceroute') >= 0) {
                tCmd = 'traceroute';
            } else if (cmd.indexOf('sh_run_int') >= 0) {
                tCmd = 'sh_run_int';
            } else if (cmd.indexOf('show_running_config_interface_') >= 0) {
                tCmd = 'show_running_config_interface_TwoGigabitEthernet';
            } else if (cmd.indexOf('show_running_config_s_ntp') >= 0) {
                tCmd = 'show_running_config_s_ntp';
            } else if (cmd.indexOf('show_running_config') >= 0) {
                tCmd = 'show_running_config';
            } else if (cmd.indexOf('show_run_interface_GigabitEthernet') >= 0) {
                tCmd = 'show_run_interface_GigabitEthernet';
            } else if (cmd.indexOf('show_run_interface_') >= 0) {
                tCmd = 'show_run_interface_GigabitEthernet';
            } else if (cmd.indexOf('show_run_sec_domain') >= 0) {
                tCmd = 'show_run_sec_domain';
            } else if (cmd.indexOf('show_run_sec_lisp') >= 0) {
                tCmd = 'show_run_sec_lisp';
            } else if (cmd.indexOf('show_run_sec_ospf') >= 0) {
                tCmd = 'show_run_sec_ospf';
            } else if (cmd.indexOf('show_run_sec_eigrp') >= 0) {
                tCmd = 'show_run_sec_eigrp';
            } else if (cmd.indexOf('show_run_') >= 0) {
                tCmd = 'show_run_';
            } else if (cmd.indexOf('show_ip_route') >= 0) {
                tCmd = 'show_ip_route';
            } else if (cmd.indexOf('show_ip_cef') >= 0) {
                tCmd = 'show_ip_cef';
            } else if (cmd.indexOf('sh_ip_bgp_neighbors') >= 0) {
                tCmd = 'sh_ip_bgp_neighbors';
            } else if (cmd.indexOf('show_ip_arp') >= 0) {
                tCmd = 'show_ip_arp';
            } else if (cmd.indexOf('show_ip_interface_TenGigabitEthernet') >= 0) {
                tCmd = 'show_ip_interface_TenGigabitEthernet';
            } else if (cmd.indexOf('show_ip_interface_') >= 0) {
                tCmd = 'show_ip_interface_TenGigabitEthernet';
            } else if (cmd.indexOf('sh_int_GigabitEthernet') >= 0) {
                tCmd = 'sh_int_GigabitEthernet';
            } else if (cmd.indexOf('sh_int_') >= 0) {
                tCmd = 'sh_int_GigabitEthernet';
            } else if (cmd.indexOf('show_int_TenGigabitEthernet') >= 0) {
                //might need to revist for the response: show int TenGigabitEthernet1/0/23 | inc errors|runts|discard|drop|MTU
                tCmd = 'show_int_TenGigabitEthernet';
            } else if (cmd.indexOf('show_int_GigabitEthernet') >= 0) {
                tCmd = 'show_int_GigabitEthernet';
            } else if (cmd.indexOf('show_interface_') >= 0 && cmd.indexOf('_summary') >= 0) {
                tCmd = 'show_interface_Two_GigabitEthernet_summary';
            } else if (cmd.indexOf('show_interface_TwoGigabitEthernet') >= 0) {
                tCmd = 'show_interface_TwoGigabitEthernet';
            } else if (cmd.indexOf('show_interface_GigabitEthernet') >= 0) {
                tCmd = 'show_interface_GigabitEthernet';
            } else if (cmd.indexOf('show_interface_TenGigabitEthernet') >= 0) {
                tCmd = 'show_interface_TenGigabitEthernet';
            } else if (cmd.indexOf('show_interface_Tunnel') >= 0) {
                tCmd = 'show_interface_Tunnel';
            } else if (cmd.indexOf('show_interface_') >= 0) {
                tCmd = 'show_interface_GigabitEthernet';
            } else if (cmd.indexOf('show_controller_ethernet_controller') >= 0) {
                tCmd = 'show_controller_ethernet_controller';
            } else if (cmd.indexOf('show_802.11b_cleanair_device_ap') >= 0) {
                tCmd = 'show_80211b_cleanair_device_ap';
            } else if (cmd.indexOf('show_802.11a_cleanair_device_ap') >= 0) {
                tCmd = 'show_80211a_cleanair_device_ap';
            } else if (cmd.indexOf('show_power_inline_') >= 0 && cmd.indexOf('detail') >= 0) {
                tCmd = 'show_power_inline_TwoGigabitEthernet';
            } else if (cmd.indexOf('show_power_inline_GigabitEthernet') >= 0) {
                tCmd = 'show_power_inline_GigabitEthernet';
            } else if (cmd.indexOf('show_cable_diagnostics_tdr_interface_') >= 0) {
                tCmd = 'show_cable_diagnostics_tdr_interface_TwoGigabitEthernet';
            } else if (cmd.indexOf('test_cable_diagnostics_tdr_interface_') >= 0) {
                tCmd = 'test_cable_diagnostics_tdr_interface_GigabitEthernet';
            } else if (cmd.indexOf('sh_power_inline') >= 0) {
                tCmd = 'sh_power_inline';
            } else if (cmd.indexOf('show_cdp_neighbor_GigabitEthernet') >= 0) {
                tCmd = 'show_cdp_neighbor_GigabitEthernet';
            }

            return tCmd;
        }

        return {
            init: function () {
                SimCommandRunnerHelper.init();
            },

            doProcessRestRequest: function (urlAction) {
                console.log(urlAction.url);
                console.log(urlAction);
                var data;
                switch (urlAction.method) {
                    case 'GET':
                        data = processGetRequest(urlAction);
                        break;

                    case 'POST':
                        data = processPostRequest(urlAction);
                        break;

                    default:
                        break;
                }

                return data;
            }
        };


        function processGetRequest(urlAction) {

            if (urlAction.service.indexOf('cli') >= 0 && urlAction.service.indexOf('legit-reads') >= 0) {
                return CommandSupportedTemplate.Template;
            }

            if(urlAction.service.indexOf('command') > -1 ) {
                let type = urlAction.filter.type;
                let arg = urlAction.filter.arguments;
                if(type.indexOf("ping")> -1){
                    if(arg != ""){
                        let Json = JSON.parse(JSON.stringify(CommandSupportedTemplate.pingTemplate.result));
                        Json = Json.replace(/IpPlaceHolder/g,arg);
                        return Json;
                    }else {
                        let Json = JSON.parse(JSON.stringify(CommandSupportedTemplate.pingErrorTemp.result));
                        Json.statusCode = 400;
                        return Json;
                    }
                }else if(type.indexOf("traceroute")> -1){
                    let Json = JSON.parse(JSON.stringify(CommandSupportedTemplate.traceRouteTemplate.response));
                    let ip = arg.split(".");
                    let ip1PlaceHolder = `${ip[0]}.${ip[1]}.0.10`
                    let ip2PlaceHolder = `${ip[0]}.${ip[1]}.0.20`
                    Json = Json.replace(/IpPlaceHolder/g,arg);
                    Json = Json.replace(/Ip1placeHolder/g,ip1PlaceHolder);
                    Json = Json.replace(/Ip2placeHolder/g,ip2PlaceHolder);
                    return Json;
                }else if(type.indexOf("snmpget")> -1){
                    if(arg.indexOf("-V") >-1){
                        return "NET-SNMP version: 5.7.3";
                    }else if(arg.indexOf("-H") >-1){
                        let Json = JSON.parse(JSON.stringify(CommandSupportedTemplate.snmpHTemplate.response));
                        return Json;
                    }else return `Timeout : No Response from ${arg}`;
                }else{
                    return "Command not supported"
                }
            }

            //handling response for PnP workflow related - by using static fileId
            var t = urlAction.url.split("/");
            var fileId = t.length >= 4 ? t[4] : "";
            fileId = (fileId.split('?'))[0];
            if (fileId != "" && fileId == "be16bbf9-f527-49f6-a1b8-49c3dba59f8f") {
                return PnpDeviceInfoTemplate.PNP_FILE_CONTENT;
            }

            if (urlAction.service.indexOf('file') >= 0) {
                // Added for sensor
                if (urlAction.service.indexOf('namespace') >= 0) {
                    return '{"response":[],"version":"1.0"}';
                }
                let t = getCommandResponse(fileId);
                t = JSON.stringify(t);
                return t;
            }
        }

        //Fetch the command Response
        function getCommandResponse(fileid) {
            /*var fileid = urlAction.url.split("/");
            if(fileid.length > 3){
                fileid = fileid[4];
                //var cmd = idCmdMapping[fileid];
                //var deviceId = idDeviceMapping[deviceid];
                var cmd = idCmdMapping[fileid].cmds;
                var deviceId = idCmdMapping[fileid].deviceids;
                deviceId = deviceId[0];
    
                var finalResponse;
    
                if(cmd != undefined && deviceId != undefined){
                    var orgCmd = cmd[0];
                    cmd = cmd[0];
                    cmd = cmd.replace(/ /g,"_");
                    cmd = cmd.replace(/-/g,"_");
    
                    cmd = filterCommand(cmd);
    
                    var response = SimCommandRunnerHelper.getCommandResponse(cmd, deviceId);
    
                    var results = {BLACKLISTED:{},FAILURE:{},SUCCESS:{}};
                    if(response.Template.success) {
                        results.SUCCESS[orgCmd] = response.Template.result;
                    } else {
                        results.FAILURE[orgCmd] = response.Template.result;
                    }
    
                    finalResponse = [{deviceUuid:deviceId,commandResponses:results}];
    
                    return finalResponse;
                }
            }*/

           /* var t = urlAction.url.split("/");
            var fileid = t.length >= 4 ? t[4] : "";*/
            if (fileid == "") {
                return {};
            }
            var cmds = idCmdMapping[fileid].cmds;
            var deviceIds = idCmdMapping[fileid].deviceids;
            var i, j, len1 = deviceIds.length, len2 = cmds.length;
            var results = [];
            for (i = 0; i < len1; i++) {
                var result = { deviceUuid: deviceIds[i], commandResponses: { BLACKLISTED: {}, FAILURE: {}, SUCCESS: {} } };
                for (j = 0; j < len2; j++) {
                    var orgCmd = cmds[j];
                    var cmd = cmds[j];
                    var cmdParams = getCommandParams(cmd);
                    cmd = cmd.replace(/\| /g, "");
                    cmd = cmd.replace(/ /g, "_");
                    cmd = cmd.replace(/-/g, "_");
                    cmd = filterCommand(cmd);
                    var deviceId = deviceIds[i];
                    var response = SimCommandRunnerHelper.getCommandResponse(cmd, deviceId, cmdParams);

                    //var result = { deviceUuid: deviceId, commandResponses: { BLACKLISTED: {}, FAILURE: {}, SUCCESS: {} } };
                    if (response.Template.success) {
                        result.commandResponses.SUCCESS[orgCmd] = response.Template.result;
                    } else {
                        //either the command is wrong or we need to handle the response for this command..
                        result.commandResponses.BLACKLISTED[orgCmd] = response.Template.result;
                    }
                    //results.push(result);
                }
                results.push(result);
            }
            setTimeout(()=>{
                delete idCmdMapping[fileid];
                },20000)
            return results;
        }

        function processPostRequest(urlAction) {
            if (urlAction.restPayload.commands != undefined && urlAction.restPayload.deviceUuids != undefined
                && urlAction.restPayload.deviceUuids.length > 0) {
                return startCommandExecution(urlAction);
            }
        }

        // Start the command Execution by saving the command required to be run , with the fileid
        function startCommandExecution(urlAction) {
            var resultObj = SimTaskDataGenerator.createTask("Command Runner Service");
            var fileid = UtilityFunctions.generate_uuid();

            //idCmdMapping[fileid] = urlAction.restPayload.commands;
            //idDeviceMapping[deviceid] = urlAction.restPayload.deviceUuids[0];
            idCmdMapping[fileid] = { cmds: urlAction.restPayload.commands, deviceids: urlAction.restPayload.deviceUuids };

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {
                isError: false,
                progress: "{\"fileId\":\"" + fileid + "\"}"
            });
            resultObj.statusCode= 202;
            return resultObj;
        }

        function getCommandParams(command) {
            var params = {};
            var ipAddr = UtilityFunctions.getIPv4Address(command);
            var ifName = UtilityFunctions.getInterfaceName(command);
            if(ipAddr != undefined) {
                params['ipAddr'] = ipAddr;
            }
            if(ifName != undefined) {
                params['ifName'] = ifName;
            }
            return params;
        }

    });
