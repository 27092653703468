define({
  "Mdf_Template": [
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCSC-C220-M4S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2178"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286280198",
              "icon": null,
              "id": "286280198",
              "name": "Cisco UCS C220 M4 Rack Server",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "101010103",
          "name": "Cisco UCS C-Series Servers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E160S-M3/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2415"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309626",
              "icon": null,
              "id": "286309626",
              "name": "Cisco UCS E160S M3 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E160D-M2/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2183"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286281321",
              "icon": null,
              "id": "286281321",
              "name": "Cisco UCS E160D M2 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E180D-M3/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2562"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319178",
              "icon": null,
              "id": "286319178",
              "name": "Cisco UCS E180D M3 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E140S-M2/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1864"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286231776",
              "icon": null,
              "id": "286231776",
              "name": "Cisco UCS E140S M2 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E180D-M2/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2184"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286281332",
              "icon": null,
              "id": "286281332",
              "name": "Cisco UCS E180D M2 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "UCS-E1120D-M3/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2563"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319189",
              "icon": null,
              "id": "286319189",
              "name": "Cisco UCS E1120D M3 Software",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284467266",
          "name": "Cisco UCS E-Series Software",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": null,
      "id": "101010101",
      "name": "Cisco UCS Series",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CSP-5436",
                "CSP-5444",
                "CSP-5456"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3900"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284340347",
              "icon": null,
              "id": "284340347",
              "name": "Cisco Cloud Services Platform 5400",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CSP-5216",
                "CSP-5228"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2397"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284340346",
              "icon": null,
              "id": "284340346",
              "name": "Cisco Cloud Services Platform 5200",
              "source": null,
              "supportLevel": null
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322764",
          "name": "Cisco Cloud Services Platform 5000",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": null,
      "id": "286322333",
      "name": "Cisco Cloud Services Platform",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.29671.1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2885851",
              "icon": null,
              "id": "2885851",
              "name": "Meraki Dashboard",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "2885850",
          "name": "Meraki Dashboard",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "9888752",
      "name": "Meraki Dashboard",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR84"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141235",
              "icon": null,
              "id": "146141235",
              "name": "MR84",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR18"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141268",
              "icon": null,
              "id": "146141268",
              "name": "MR18",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR30H"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141293",
              "icon": null,
              "id": "146141293",
              "name": "MR30H",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR34"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141248",
              "icon": null,
              "id": "146141248",
              "name": "MR34",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR53"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141254",
              "icon": null,
              "id": "146141254",
              "name": "MR53",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR16"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141288",
              "icon": null,
              "id": "146141288",
              "name": "MR16",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR32"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141249",
              "icon": null,
              "id": "146141249",
              "name": "MR32",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR58"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141253",
              "icon": null,
              "id": "146141253",
              "name": "MR58",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR42"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141256",
              "icon": null,
              "id": "146141256",
              "name": "MR42",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR11"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141295",
              "icon": null,
              "id": "146141295",
              "name": "MR11",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR26"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141258",
              "icon": null,
              "id": "146141258",
              "name": "MR26",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR62"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141257",
              "icon": null,
              "id": "146141257",
              "name": "MR62",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR14"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141294",
              "icon": null,
              "id": "146141294",
              "name": "MR14",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141278",
              "icon": null,
              "id": "146141278",
              "name": "MR24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR33"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141250",
              "icon": null,
              "id": "146141250",
              "name": "MR33",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR12"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141298",
              "icon": null,
              "id": "146141298",
              "name": "MR12",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR66"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141247",
              "icon": null,
              "id": "146141247",
              "name": "MR66",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR72"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141237",
              "icon": null,
              "id": "146141237",
              "name": "MR72",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR52"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141255",
              "icon": null,
              "id": "146141255",
              "name": "MR52",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MR74"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "146141236",
              "icon": null,
              "id": "146141236",
              "name": "MR74",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "146141297",
          "name": "Meraki Access Point",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "146141296",
      "name": "Meraki Access Point",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX50"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1126",
              "icon": null,
              "id": "1126",
              "name": "MX50",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX65W"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1131",
              "icon": null,
              "id": "1131",
              "name": "MX65W",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX60W"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1114",
              "icon": null,
              "id": "1114",
              "name": "MX60W",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX90"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1129",
              "icon": null,
              "id": "1129",
              "name": "MX90",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX64W"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1124",
              "icon": null,
              "id": "1124",
              "name": "MX64W",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX80"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1115",
              "icon": null,
              "id": "1115",
              "name": "MX80",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX600"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1118",
              "icon": null,
              "id": "1118",
              "name": "MX600",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX400"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1117",
              "icon": null,
              "id": "1117",
              "name": "MX400",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX64"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1130",
              "icon": null,
              "id": "1130",
              "name": "MX64",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX65"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1125",
              "icon": null,
              "id": "1125",
              "name": "MX65",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX70"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1127",
              "icon": null,
              "id": "1127",
              "name": "MX70",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "Z1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1134",
              "icon": null,
              "id": "1134",
              "name": "Z1",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX250"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1132",
              "icon": null,
              "id": "1132",
              "name": "MX250",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX60"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1113",
              "icon": null,
              "id": "1113",
              "name": "MX60",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX100"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1116",
              "icon": null,
              "id": "1116",
              "name": "MX100",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX84"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1128",
              "icon": null,
              "id": "1128",
              "name": "MX84",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "Z3"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1135",
              "icon": null,
              "id": "1135",
              "name": "Z3",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MX450"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "1133",
              "icon": null,
              "id": "1133",
              "name": "MX450",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "1112",
          "name": "Meraki Security Appliances",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "1111",
      "name": "Meraki Security Appliances",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS225-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2251",
              "icon": null,
              "id": "2251",
              "name": "MS225-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-24X"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2267",
              "icon": null,
              "id": "2267",
              "name": "MS350-24X",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS320-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2260",
              "icon": null,
              "id": "2260",
              "name": "MS320-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS42P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2246",
              "icon": null,
              "id": "2246",
              "name": "MS42P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS225-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2252",
              "icon": null,
              "id": "2252",
              "name": "MS225-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS425-32"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2271",
              "icon": null,
              "id": "2271",
              "name": "MS425-32",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2233",
              "icon": null,
              "id": "2233",
              "name": "MS220-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2242",
              "icon": null,
              "id": "2242",
              "name": "MS120-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2241",
              "icon": null,
              "id": "2241",
              "name": "MS120-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS320-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2229",
              "icon": null,
              "id": "2229",
              "name": "MS320-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS420-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2232",
              "icon": null,
              "id": "2232",
              "name": "MS420-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS22P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2244",
              "icon": null,
              "id": "2244",
              "name": "MS22P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS410-32"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2269",
              "icon": null,
              "id": "2269",
              "name": "MS410-32",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS410-16"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2268",
              "icon": null,
              "id": "2268",
              "name": "MS410-16",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2248",
              "icon": null,
              "id": "2248",
              "name": "MS220-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2265",
              "icon": null,
              "id": "2265",
              "name": "MS350-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS42"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2245",
              "icon": null,
              "id": "2245",
              "name": "MS42",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS22"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2243",
              "icon": null,
              "id": "2243",
              "name": "MS22",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2225",
              "icon": null,
              "id": "2225",
              "name": "MS220-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-8FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2237",
              "icon": null,
              "id": "2237",
              "name": "MS120-8FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2263",
              "icon": null,
              "id": "2263",
              "name": "MS350-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS250-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2257",
              "icon": null,
              "id": "2257",
              "name": "MS250-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2240",
              "icon": null,
              "id": "2240",
              "name": "MS120-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS225-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2250",
              "icon": null,
              "id": "2250",
              "name": "MS225-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-8P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2234",
              "icon": null,
              "id": "2234",
              "name": "MS220-8P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2238",
              "icon": null,
              "id": "2238",
              "name": "MS120-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2226",
              "icon": null,
              "id": "2226",
              "name": "MS220-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-8"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2235",
              "icon": null,
              "id": "2235",
              "name": "MS120-8",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2262",
              "icon": null,
              "id": "2262",
              "name": "MS350-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-8"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2224",
              "icon": null,
              "id": "2224",
              "name": "MS220-8",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS420-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2231",
              "icon": null,
              "id": "2231",
              "name": "MS420-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2239",
              "icon": null,
              "id": "2239",
              "name": "MS120-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS320-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2261",
              "icon": null,
              "id": "2261",
              "name": "MS320-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS320-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2228",
              "icon": null,
              "id": "2228",
              "name": "MS320-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS250-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2256",
              "icon": null,
              "id": "2256",
              "name": "MS250-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS320-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2259",
              "icon": null,
              "id": "2259",
              "name": "MS320-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS225-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2253",
              "icon": null,
              "id": "2253",
              "name": "MS225-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS425-16"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2270",
              "icon": null,
              "id": "2270",
              "name": "MS425-16",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS250-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2254",
              "icon": null,
              "id": "2254",
              "name": "MS250-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS220-48LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2247",
              "icon": null,
              "id": "2247",
              "name": "MS220-48LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS250-24P"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2255",
              "icon": null,
              "id": "2255",
              "name": "MS250-24P",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-48"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2264",
              "icon": null,
              "id": "2264",
              "name": "MS350-48",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS350-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2266",
              "icon": null,
              "id": "2266",
              "name": "MS350-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS250-48FP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2258",
              "icon": null,
              "id": "2258",
              "name": "MS250-48FP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS120-8LP"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2236",
              "icon": null,
              "id": "2236",
              "name": "MS120-8LP",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MS225-24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "2249",
              "icon": null,
              "id": "2249",
              "name": "MS225-24",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "2223",
          "name": "Meraki Switches",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "2222",
      "name": "Meraki Switches",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MV21"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "3335",
              "icon": null,
              "id": "3335",
              "name": "MV21",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "MV71"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "3336",
              "icon": null,
              "id": "3336",
              "name": "MV71",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "3334",
          "name": "Meraki Cameras",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "3333",
      "name": "Meraki Cameras",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.25461.2.3.29"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "102010103",
              "icon": null,
              "id": "102010103",
              "name": "Palo Alto Firewall",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.12356.101.1.90060"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "102010104",
              "icon": null,
              "id": "102010104",
              "name": "Fortinet Firewall",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.17163.1.1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "102010105",
              "icon": null,
              "id": "102010105",
              "name": "Riverbed WAN Optimization",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.41916.3.2.12"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "102010107",
              "icon": null,
              "id": "102010107",
              "name": "Viptela vEdge",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "102010102",
          "name": "NFV-ThirdParty Devices",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": null,
      "id": "102010101",
      "name": "NFV-ThirdParty Devices",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "nfvos"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "101010115",
              "icon": null,
              "id": "101010115",
              "name": "NFVIS",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "101010114",
          "name": "NFVIS",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": null,
      "id": "101010113",
      "name": "NFVIS",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1301"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283451283",
              "icon": null,
              "id": "283451283",
              "name": "Cisco Virtual Wide Area Application Services (vWAAS)",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "282125945",
          "name": "Cisco Wide Area Virtualization Engines",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_content_distri_man_sf.gif",
      "id": "268437639",
      "name": "Content Networking",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ESR-6300-CON-K9",
                "ESR-6300-NCP-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2865"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2864"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323493",
              "icon": null,
              "id": "286323493",
              "name": "Cisco 6300 Embedded Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323433",
          "name": "Cisco 6300 Series Embedded Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ENCS5408/K9",
                "ENCS5412/K9",
                "ENCS5406/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2375"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2377"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2376"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286308693",
              "icon": null,
              "id": "286308693",
              "name": "Cisco 5400 Enterprise Network Compute System",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2408"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286308662",
              "icon": null,
              "id": "286308662",
              "name": "Cisco Integrated Services Virtual Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ENCS5104-64/K9",
                "ENCS5104-200/K9",
                "ENCS5104-400/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2565"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286318576",
              "icon": null,
              "id": "286318576",
              "name": "Cisco 5100 Enterprise Network Compute System",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286308504",
          "name": "Cisco Network Functions Virtualization",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C8200-1N-4T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2961"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324472",
              "icon": null,
              "id": "286324472",
              "name": "Cisco Catalyst 8200 Edge Platform",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324420",
          "name": "Cisco Catalyst 8200 Series Edge Platforms",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CSR1000V"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1537"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "289897988",
              "icon": null,
              "id": "289897988",
              "name": "Cisco Cloud Services Router 1000V",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "289897987",
          "name": "Cisco Cloud Services Router 1000V Series",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR1831-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3027"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326179",
              "icon": null,
              "id": "286326179",
              "name": "Cisco Catalyst IR1831 Rugged Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR1821-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3026"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326192",
              "icon": null,
              "id": "286326192",
              "name": "Cisco Catalyst IR1821 Rugged Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR1833-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3028"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326183",
              "icon": null,
              "id": "286326183",
              "name": "Cisco Catalyst IR1833 Rugged Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR1835-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3029"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326188",
              "icon": null,
              "id": "286326188",
              "name": "Cisco Catalyst IR1835 Rugged Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286326122",
          "name": "Cisco Catalyst IR1800 Rugged Series Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C8300-2N2S-6T",
                "C8300-1N1S-6T",
                "C8300-1N1S-4T2X",
                "C8300-2N2S-4T2X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2989"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2990"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2964"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2963"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324476",
              "icon": null,
              "id": "286324476",
              "name": "Cisco Catalyst 8300 Edge Platform",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324424",
          "name": "Cisco Catalyst 8300 Series Edge Platforms",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1113-8PMWE",
                "C1113-8P",
                "C1161-8P",
                "C1121-8PLTEP",
                "C1121X-8P",
                "C1117-4PLTELAWZ",
                "C1113-8PLTELAWZ",
                "C1117-4PLTELA",
                "C1111-8PLTELA",
                "C941-4PLTEPWD",
                "C1109-4PLTE2P",
                "C941-4PLTEPWH",
                "C941-4PLTEPWF",
                "C1113-8PLTELA",
                "C1117-4PM",
                "C941-4PLTEPWQ",
                "C1117-4PLTEEAWA",
                "C1121-8PLTEPWE",
                "C941-4PLTEPWN",
                "C1111-4PWZ",
                "C1117-4PMWE",
                "C1117-4PLTEEAWE",
                "C1113-8PLTEEAWB",
                "C1113-8PLTEEAWE",
                "C941-4PLTEPWR",
                "C1121-8PLTEPWB",
                "C1111-8PWE-RF",
                "C1117-4PWZ",
                "C1111-8PWZ",
                "C1111-4PWR",
                "C1111-4PWQ",
                "C1117-4PMLTEEAWE",
                "C1111-8PLTEEA",
                "C1113-8PWA",
                "C1113-8PMLTEEA",
                "C1113-8PWB",
                "C1161-8PLTEP",
                "C1113-8PWE",
                "C1121-8PLTEPWQ",
                "C1113-8PLTEEA",
                "C1113-8PLTELAWA",
                "C1116-4PWE",
                "C1121-8PLTEPWZ",
                "C1117-4PWA",
                "C1111-4P",
                "C1111-8PWB",
                "C1111-8PWA",
                "C1111-8PLTELAWF",
                "C1117-4PWE",
                "C1111-8P",
                "C1111-8PLTELAWD",
                "C1111-8PLTEEAWR",
                "C1111-4PLTEEA-RF",
                "C1111-4PWN",
                "C1111-8PWR",
                "C1116-4PLTEEA-RF",
                "C1111-8PWQ",
                "C1111-8PWN",
                "C1121-8P",
                "C1111-4PWF",
                "C1111-8PLTEEAWA",
                "C1111-4PWE",
                "C1111-8PLTEEAWB",
                "C1111-4PWH",
                "C1113-8PWZ",
                "C1111-8PWH",
                "C1111-4PWB",
                "C1111-4PWA",
                "C1111-8PLTEEAWE",
                "C1161X-8PLTEP",
                "C1109-4PLTE2PWE",
                "C1111-8PWF",
                "C1111-4PWD",
                "C1111-8PWE",
                "C1111-4PLTEEA",
                "C1161X-8P",
                "C1111-4PLTELA",
                "C1111-8PLTEEA-RF",
                "C1112-8P",
                "C1111-8PLTELAWZ",
                "C1116-4P",
                "C1117-4PMLTEEA",
                "C1112-8PWE",
                "C1111-8PLTELAWQ",
                "C1112-8PLTEEA",
                "C1113-8PM",
                "C1111-8PLTELAWH",
                "C1116-4PLTEEA",
                "C1112-8PLTEEAWE",
                "C1116-4PLTEEAWE",
                "C1111-8PLTELAWN",
                "C1121X-8PLTEP"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2450"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2452"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2451"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2619"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2454"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2453"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2456"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2455"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2458"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2457"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2459"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2443"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2445"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2444"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2447"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2446"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2449"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2448"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2551"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2550"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2553"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2552"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2555"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2554"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2557"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2556"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2558"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2540"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2549"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2542"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2541"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2544"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2543"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2546"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2545"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2548"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2547"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2539"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2538"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2533"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2532"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2535"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2534"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2537"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2536"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2528"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2527"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2529"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2520"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2641"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2640"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2522"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2643"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2521"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2642"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2524"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2645"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2523"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2644"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2526"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2647"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2525"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2646"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2517"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2638"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2516"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2637"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2519"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2518"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2639"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2630"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2751"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2750"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2511"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2632"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2753"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2510"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2631"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2752"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2513"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2634"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2755"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2512"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2633"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2754"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2515"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2636"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2514"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2635"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2756"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2461"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2460"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2463"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2462"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2506"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2627"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2748"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2505"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2626"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2747"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2508"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2629"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2507"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2628"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2749"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2509"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2464"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2621"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2620"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2623"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2625"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2746"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2624"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2745"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315006",
              "icon": null,
              "id": "286315006",
              "name": "Cisco 1100 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286313472",
          "name": "Cisco 1000 Series Integrated Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C927-4PLTEGB",
                "C927-4PMLTEGB",
                "C921-4P",
                "C921-4PLTEGB",
                "C927-4PLTEAU",
                "C921J-4P",
                "C926-4PLTEGB",
                "C927-4P",
                "C941J-4P",
                "C926-4P",
                "C921-4PLTEAU",
                "C921-4PLTEAS",
                "C931J-4P",
                "C931-4P",
                "C927-4PM"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2715"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2717"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2716"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2727"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2708"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2719"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2707"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2718"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2709"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2711"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2722"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2721"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2713"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2712"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2723"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315020",
              "icon": null,
              "id": "286315020",
              "name": "Cisco 900 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C951-4P",
                "C959-2PLTEVZ",
                "C959_2PLTEIN",
                "C959-2PLTEGB",
                "C959-2PLTEUS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2824"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2821"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2820"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2823"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2822"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323690",
              "icon": null,
              "id": "286323690",
              "name": "Cisco 950 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286277337",
          "name": "Cisco 900 Series Integrated Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR829GW-LTE-GA-ZK9",
                "IR829M-2LTE-EA-BK9",
                "IR829M-2LTE-EA-AK9",
                "IR829B-2LTE-EA-EK9",
                "IR829B-2LTE-EA-BK9",
                "IR829M-2LTE-EA-EK9",
                "IR829B-LTE-EA-BK9",
                "IR829B-LTE-EA-EK9",
                "IR829GW-LTE-NA-AK9",
                "IR829B-LTE-LA-ZK9",
                "IR829GW-LTE-ST-AK9",
                "IR829GW-LTE-LA-ZK9",
                "IR829M-LTE-EA-EK9",
                "IR829-2LTE-EA-EK9",
                "IR829-2LTE-EA-BK9",
                "IR829B-LTE-EA-AK9",
                "IR829-2LTE-EA-AK9",
                "IR829B-2LTE-EA-AK9",
                "IR829M-LTE-LA-ZK9",
                "IR829GW-LTE-GA-EK9",
                "IR829GW-LTE-VZ-AK9",
                "IR829M-LTE-EA-BK9",
                "IR829M-LTE-EA-AK9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2111"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2110"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2109"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2609"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2410"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2674"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2673"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2610"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2676"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2675"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2403"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2108"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2107"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286287074",
              "icon": null,
              "id": "286287074",
              "name": "Cisco 829 Industrial Integrated Services Routers",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR807G-LTE-NA-K9",
                "IR807G-LTE-GA-K9",
                "IR807G-LTE-VZ-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2560"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2561"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2559"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286318183",
              "icon": null,
              "id": "286318183",
              "name": "Cisco 807 Industrial Integrated Services Routers",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IR809G-LTE-ST-K9",
                "IR809G-LTE-VZ-K9",
                "IR809G-LTE-NA-K9",
                "IR809G-LTE-LA-K9",
                "IR809G-LTE-GA-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2104"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2103"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2106"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2105"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2426"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286287094",
              "icon": null,
              "id": "286287094",
              "name": "Cisco 809 Industrial Integrated Services Routers",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286287045",
          "name": "Cisco 800 Series Industrial Integrated Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1100TGX-1N24P32A",
                "C1100TG-1N32A",
                "C1100TG-1N24P32A"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2971"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2973"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2972"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326249",
              "icon": null,
              "id": "286326249",
              "name": "Cisco 1100 Terminal Services Gateway",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286326458",
          "name": "Cisco Terminal Services Gateways",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1101-4PLTEPWZ",
                "C1121X-8PLTEPWE",
                "C1101-4PLTEPWQ",
                "C1101-4PLTEPWR",
                "C1121-8PLTEP",
                "C1111-8PLTELA-DNA",
                "C1111-8P-RF",
                "C1101-4PLTEPWH",
                "C1111-8PJ",
                "C941J-4PSEC",
                "C1111-4PLTELA-DNA",
                "C1121X-8PLTEPWA",
                "C1121X-8PLTEPWB",
                "C1101-4PLTEPWN",
                "C921J-4PSEC",
                "C1101-4PLTEPWA",
                "C1101-4PLTEPWB",
                "C1101-4PLTEPWD",
                "C1111-4PJ",
                "C1101-4PLTEPWE",
                "C1101-4PLTEPWF",
                "C1127X-8PLTEP",
                "C1113-8PLTEEAWA",
                "C1121-8PLTEPWE",
                "C1111-8PLTEEA-DNA",
                "C1121-8PLTEPWB",
                "C1121-8PLTEPWQ",
                "C1111X-8P",
                "C1121X-8PLTEPWZ",
                "IR1101-K9",
                "C1121-8PLTEPWZ",
                "C1111-8PLTELAWA",
                "C1111-8PLTEPWE",
                "C1111-4P-RF",
                "C1111-8PLTELAWE",
                "C1111-8P-DNA",
                "C1111-8PWS",
                "C1118-8P",
                "C931J-4PSEC",
                "C1101-4PLTEP",
                "C1118-8PLTEEA",
                "C1161X-8PLTEP",
                "C1161X-8P",
                "C1111-4P-DNA",
                "C1111-4PLTEEA-DNA",
                "C1111-8PLTELAWS",
                "C1101-4P",
                "C1111-8PLTEEAWE-RF",
                "C1121X-8PLTEP"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2661"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319772",
              "icon": null,
              "id": "286319772",
              "name": "Cisco 1101 Industrial Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286319700",
          "name": "Cisco 1100 Series Industrial Integrated Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4331BR-V/K9",
                "ISR4331-AX/K9-WS",
                "ISR4331-DNA-PM",
                "ISR4331R-V/K9",
                "ISR4331-SEC/K9",
                "C1-CISCO4331/K9-RF",
                "ISR4331-DC/K9",
                "ISR4331/K9-WS",
                "ISR4331BR/K9",
                "ISR4331-VSEC/K9-RF",
                "ISR4431-VSEC/K9-RF",
                "ISR4331-V/K9-WS",
                "SI-ISR4331-IWAN/K9",
                "ISR4331-SEC/K9-WS",
                "ISR4331-V/K9",
                "ISR4331R/K9",
                "C1-CISCO4331/K9",
                "ISR4331-SEC/K9-RF",
                "ISR4331-AX/K9",
                "ISR4331-B/K9",
                "ISR4331R-VSEC/K9",
                "ISR4331/K9",
                "ISR4331R-AXV/K9",
                "ISR4431/K9-RF",
                "ISR4331-AX/K9-RF",
                "ISR4331R-AX/K9",
                "ISR4331-AXV/K9",
                "ISR4331-DNA",
                "ISR4331R-SEC/K9",
                "ISR4331-VSEC/K9",
                "ISR4431-SEC/K9-RF",
                "ISR4431-AX/K9-RF",
                "ISR4331-VSEC/K9-WS",
                "ISR4331-V/K9-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2068"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "285018115",
              "icon": null,
              "id": "285018115",
              "name": "Cisco 4331 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4321-SEC/K9",
                "ISR4321R-SEC/K9",
                "C1-CISCO4321T-P/K9",
                "C1-CISCO4331T-P/K9",
                "ISR4321BR-V/K9",
                "ISR4321-B/K9",
                "ISR4321R-VSEC/K9",
                "ISR4321-AXV/K9-RF",
                "ISR4321-VSEC/K9-RF",
                "ISR4321-AX/K9-RF",
                "C1-CISCO4321/K9",
                "ISR4321-SEC/K9-RF",
                "ISR4321R-AXV/K9",
                "ISR4321-V/K9-RF",
                "ISR4321-VSEC/K9",
                "SPIAD4321",
                "ISR4321-AXV/K9-WS",
                "ISR4321-VSEC/K9-WS",
                "ISR4321-AX/K9-WS",
                "ISR4321-V/K9",
                "ISR4321R/K9",
                "ISR4321-AX/K9",
                "ISR4321-SEC/K9-WS",
                "ISR4321/K9",
                "ISR4321-AXV/K9",
                "ISR4321BR/K9",
                "SI-ISR4321-IWAN/K9",
                "ISR4321R-AX/K9",
                "ISR4321R-V/K9",
                "C1-CISCO4351T-P/K9",
                "ISR4321-V/K9-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2093"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286006221",
              "icon": null,
              "id": "286006221",
              "name": "Cisco 4321 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4221/K9-RF",
                "C1-CISCO4221T-P/K9",
                "ISR4221/K9",
                "C1-CISCO4221/K9",
                "ISR4221-SEC/K9-RF",
                "ISR4221T-P/K9",
                "ISR4221-SEC/K9",
                "C1-CISCO4221J/K9",
                "ISR4221X/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2395"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2396"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286310700",
              "icon": null,
              "id": "286310700",
              "name": "Cisco 4221 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4431-V/K9-RF",
                "ISR4431-AX/K9",
                "SI-ISR4431-IWAN/K9",
                "ISR4431-SEC/K9-WS",
                "ISR4431-V/K9-WS",
                "ISR4431-VSEC/K9",
                "ISR4431/K9",
                "ISR4431-SEC/K9",
                "ISR4431-AXV/K9-RF",
                "ISR4431-AX/K9-WS",
                "ISR4431/K9-WS",
                "ISR4431-AXV/K9",
                "ISR4431-V/K9",
                "C1-CISCO4431/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1935"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284358776",
              "icon": null,
              "id": "284358776",
              "name": "Cisco 4431 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4461/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2680"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320564",
              "icon": null,
              "id": "286320564",
              "name": "Cisco 4461 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4351-SEC/K9-WS",
                "ISR4351-AX/K9",
                "ISR4351-VSEC/K9",
                "C1-CISCO4351/K9",
                "ISR4351-VSEC/K9-WS",
                "SI-ISR4351-IWAN/K9",
                "ISR4351-V/K9",
                "ISR4351-VSEC/K9-RF",
                "ISR4351-AX/K9-RF",
                "ISR4351/K9",
                "ISR4351-AXV/K9",
                "ISR4351-SEC/K9",
                "ISR4351-SEC/K9-RF",
                "ISR4351-AX/K9-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1999"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "285018114",
              "icon": null,
              "id": "285018114",
              "name": "Cisco 4351 Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ISR4451-X-VSEC/K9",
                "ISR4451-X-AX/K9",
                "ISR4451-UCSE-S/K9",
                "ISR4451-X/K9-RF",
                "ISR4451-X-V/K9-WS",
                "ISR4451-DNA",
                "ISR4451-X-AX/K9-RF",
                "ISR4451-X/K9-WS",
                "ISR4451-X/K9",
                "ISR4451-X-V/K9",
                "ISR4451-X-AXV/K9",
                "ISR4451-XSEC/K9-RF",
                "SI-ISR4451-IWAN/K9",
                "C1-CISCO4451/K9",
                "ISR4451XVSEC/K9-RF",
                "ISR4451-UCSE-D/K9",
                "ISR4451-X-SECK9-WS",
                "ISR4451X-AX-AKC/K9",
                "ISR4451-X-V/K9-RF",
                "ISR4451-X-SEC/K9",
                "ISR4451-X-AX/K9-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1707"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284389362",
              "icon": null,
              "id": "284389362",
              "name": "Cisco 4451-X Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286281708",
          "name": "Cisco 4000 Series Integrated Services Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C8500L-8G4X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2968"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324574",
              "icon": null,
              "id": "286324574",
              "name": "Cisco Catalyst 8500L Edge Platform",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324570",
          "name": "Cisco Catalyst 8500L Series Edge Platforms",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C8500-12X4QC",
                "C8500-12X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2818"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2819"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323412",
              "icon": null,
              "id": "286323412",
              "name": "Cisco Catalyst 8500 Edge Platform",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324372",
          "name": "Cisco Catalyst 8500 Series Edge Platforms",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C892FSP-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1620"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303296",
              "icon": null,
              "id": "284303296",
              "name": "Cisco C892FSP Integrated Services Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C891F-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1858"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "285003591",
              "icon": null,
              "id": "285003591",
              "name": "Cisco C891F Integrated Services Routers",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "268438003",
          "name": "Cisco 800 Series Routers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASR1006X-CB",
                "ASR1006-X",
                "C1-ASR1006X/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2143"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285163",
              "icon": null,
              "id": "286285163",
              "name": "Cisco ASR 1006-X Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1-ASR1002-HX/K9",
                "ASR1002-HX-DNA",
                "ASR1002-HX",
                "ASR1002HX-6GE-2TE"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2252"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286288594",
              "icon": null,
              "id": "286288594",
              "name": "Cisco ASR 1002-HX Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1-ASR1009X/K9",
                "ASR1009X-CB",
                "ASR1009-X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2144"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285153",
              "icon": null,
              "id": "286285153",
              "name": "Cisco ASR 1009-X Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASR1001X-25GSEC-WS",
                "ASR1001X-25GVPN-WS",
                "ASR1001X-10G-VPN",
                "ASR1001X-20GVPN-WS",
                "ASR1001X-5G-SEC",
                "ASR1001X-10G-K9",
                "ASR1001X-2.5GK9-RF",
                "ASR1001X-2.5G-VPN",
                "C1-ASR1001-X/K9",
                "ASR1001X-5G-K9-RF",
                "ASR1001X-20G-VPN",
                "ASR1001X-20GVPN-RF",
                "ASR1001X-AIS-AX",
                "ASR1001X-20G-K9-RF",
                "ASR1001-5G-AES-AX",
                "ASR1001X-5G-VPN",
                "ASR1001X-20G-K9",
                "ASR1001X-10G-SEC",
                "ASR1001X-2.5G-K9",
                "ASR1001-X",
                "ASR1001X-5G-K9",
                "ASR1001-X-DNA",
                "ASR1001X-2.5G-SEC",
                "ASR1001X-20G-K9-WS",
                "ASR1001X-20G-SEC",
                "ASR1001-XA",
                "ASR1001X-AES-AX"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1861"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284932298",
              "icon": null,
              "id": "284932298",
              "name": "Cisco ASR 1001-X Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASR1002X-5G-VPNK9",
                "ASR1002X-5G-K9-WS",
                "ASR1002X-SB",
                "ASR1002X-CB-RF",
                "ASR1002X-10G-HA-K9",
                "ASR1002X-20G-SECK9",
                "ASR1002X-10G-K9-WS",
                "ASR1002X-10G-VPNK9",
                "ASR1002X-5G-HA-K9",
                "ASR1002X-20G-HA-K9",
                "ASR1002X-36G-SECK9",
                "ASR1002X-36G-K9",
                "ASR1002X-36G-HA-K9",
                "ASR1002X-5G-SECK9",
                "ASR1002X-36G-SHAK9",
                "ASR1002X-CB",
                "ASR1002X-10G-K9",
                "ASR1002X-20G-VPNK9",
                "ASR1002-X=",
                "ASR1002X-36G-VPNK9",
                "ASR1002-10G/K9-WS",
                "ASR1002X-10G-K9-RF",
                "ASR1002X-10G-SHAK9",
                "ASR1002X-20G-SHAK9",
                "ASR1002X10GHAK9-RF",
                "ASR1002X-AIS-AX-RF",
                "ASR1002X-SB-WS",
                "ASR1002X10GVPNK9RF",
                "ASR1002-10G/K9",
                "ASR1002-X-RF",
                "ASR1002X-36G-NB",
                "C1-ASR1002-X/K9",
                "ASR1002X-AES-AX",
                "ASR1002X-5G-K9",
                "ASR1002-X",
                "ASR1002X5GVPNK9-WS",
                "ASR1002X-10G-SECK9",
                "ASR1002X-5G-SHAK9",
                "ASR1002X-20G-K9",
                "ASR1002X-AIS-AX",
                "ASR1002X-CB-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1525"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284146581",
              "icon": null,
              "id": "284146581",
              "name": "Cisco ASR 1002-X Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASR1001-HX",
                "ASR1001-HX-WS",
                "C1-ASR1001-HX/K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2348"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286288843",
              "icon": null,
              "id": "286288843",
              "name": "Cisco ASR 1001-HX Router",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "281716314",
          "name": "Cisco ASR 1000 Series Aggregation Services Routers",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16-Router_SF.gif",
      "id": "268437899",
      "name": "Routers",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24PC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1749"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776529",
              "icon": null,
              "id": "284776529",
              "name": "Cisco Catalyst 2960-Plus 24PC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24TC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1757"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776614",
              "icon": null,
              "id": "284776614",
              "name": "Cisco Catalyst 2960-Plus 24TC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1752"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776534",
              "icon": null,
              "id": "284776534",
              "name": "Cisco Catalyst 2960-Plus 24TC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+48PST-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1748"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776528",
              "icon": null,
              "id": "284776528",
              "name": "Cisco Catalyst 2960-Plus 48PST-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24PC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1754"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776580",
              "icon": null,
              "id": "284776580",
              "name": "Cisco Catalyst 2960-Plus 24PC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+48PST-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1753"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776560",
              "icon": null,
              "id": "284776560",
              "name": "Cisco Catalyst 2960-Plus 48PST-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+48TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1751"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776533",
              "icon": null,
              "id": "284776533",
              "name": "Cisco Catalyst 2960-Plus 48TC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24LC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1750"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776530",
              "icon": null,
              "id": "284776530",
              "name": "Cisco Catalyst 2960-Plus 24LC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+48TC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1756"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776613",
              "icon": null,
              "id": "284776613",
              "name": "Cisco Catalyst 2960-Plus 48TC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960+24LC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1755"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284776612",
              "icon": null,
              "id": "284776612",
              "name": "Cisco Catalyst 2960-Plus 24LC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284769133",
          "name": "Cisco Catalyst 2960-Plus Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4010-4S24P"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2368"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286307998",
              "icon": null,
              "id": "286307998",
              "name": "Cisco IE-4010-4S24P Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4010-16S12P"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2369"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286307987",
              "icon": null,
              "id": "286307987",
              "name": "Cisco IE-4010-16S12P Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286307930",
          "name": "Cisco Industrial Ethernet 4010 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-8FT"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2881"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323818",
              "icon": null,
              "id": "286323818",
              "name": "Cisco Catalyst IE-3400H-8FT Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-16T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2885"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323840",
              "icon": null,
              "id": "286323840",
              "name": "Cisco Catalyst IE-3400H-16T Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-24FT"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2883"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323827",
              "icon": null,
              "id": "286323827",
              "name": "Cisco Catalyst IE-3400H-24FT Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-8T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2884"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323831",
              "icon": null,
              "id": "286323831",
              "name": "Cisco Catalyst IE-3400H-8T Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-24T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2886"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323836",
              "icon": null,
              "id": "286323836",
              "name": "Cisco Catalyst IE-3400H-24T Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400H-16FT"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2882"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323823",
              "icon": null,
              "id": "286323823",
              "name": "Cisco Catalyst IE-3400H-16FT Heavy Duty Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323814",
          "name": "Cisco Catalyst IE3400 Heavy Duty Series",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6509-FW-IDS-K9",
                "WS-C6509-NV",
                "WS-C6509",
                "WS-C6509-1300AC-RF",
                "WS-C6509-2500AC=",
                "WS-C6509-S1",
                "WS-C6509-2500AC-RF",
                "WS-C6509-PFC2",
                "WS-C6509-WS",
                "WS-C6509-E",
                "WS-C6509-S720-6724",
                "WS-C6509-FW-VPN-K9",
                "WS-C6509-RF",
                "WS-C6509-1300AC="
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.283"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.44"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "268438000",
              "icon": null,
              "id": "268438000",
              "name": "Cisco Catalyst 6509 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6509-NEBFAN-RF",
                "WS-C6509-NEB",
                "WS-C6509-NEB-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.310"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.47"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "268438001",
              "icon": null,
              "id": "268438001",
              "name": "Cisco Catalyst 6509-NEB Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6506-PFC2",
                "WS-C6506-1000AC-RF",
                "WS-C6506-1300AC-RF",
                "WS-C6506-RF",
                "WS-C6506",
                "WS-C6506-S1",
                "WS-C6506-FWM-K9",
                "WS-C6506-1000AC=",
                "WS-C6506-IPSEC-K9",
                "WS-C6506-1300AC=",
                "WS-C6506-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.282"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.45"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "268437999",
              "icon": null,
              "id": "268437999",
              "name": "Cisco Catalyst 6506 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6503-FWM-K9",
                "WS-C6503-PFC2",
                "WS-C6503"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.449"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.56"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "277696480",
              "icon": null,
              "id": "277696480",
              "name": "Cisco Catalyst 6503 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "VS-C6509VE-S72010G",
                "WS-C6509-V-E-WS",
                "VS-C6509VE-SUP2T",
                "WS-C6509-V-E",
                "C6509VE-ACE20-6716"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.832"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281633522",
              "icon": null,
              "id": "281633522",
              "name": "Cisco Catalyst 6509-V-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6509-E-FWM-K9",
                "WS-C6509-NEB-A",
                "WS-C6509-NEB-A-RF",
                "WS-C6509-EFWMK9-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.61"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.534"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279264000",
              "icon": null,
              "id": "279264000",
              "name": "Cisco Catalyst 6509-NEB-A Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WSC6504EACE20K9-RF",
                "WS-C6504E-S32P-GE",
                "WS-C6504-E-WS",
                "VS-C6504E-SUP2T",
                "WS-C6504-E-VPN+-K9",
                "WS-C6504E-S32-10GE",
                "WSC6504ES3210GE-RF",
                "WS-C6504-E-WISM-RF",
                "WS-C6504-E",
                "WS-C6504-E-WISM",
                "VS-C6504E-S720-10G",
                "VSC6504ES72010G-WS",
                "VS-C6504E-SUP2T-WS",
                "WS-C6504E-VPN/K9",
                "WS-C6504E-S32-GE",
                "WS-C6504-EACEK9-RF",
                "WS-C6504E-S32P10GE",
                "WS-C6504E-S32GE-RF",
                "WS-C6504-E-6716-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.64"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.657"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "280357776",
              "icon": null,
              "id": "280357776",
              "name": "Cisco Catalyst 6504-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C6513-S32-GE-RF",
                "VS-C6513-S720-10G",
                "VS-C6513S72010G-RF",
                "WS-C6513-VPN+-K9",
                "WS-6513XL-2FWM-K9",
                "WS-C6513=",
                "WS-C6513-VPN/K9",
                "WS-C6513",
                "WS-C6513-XL-FWM-K9",
                "WS-C6513-S32-10GE",
                "WS-C6513-XL-FWM-K8",
                "WS-C6513XLFWMK9-RF",
                "WS-C6513-FWM-K9-RF",
                "WS-C6513-S32-GE",
                "WS-C6513-S32P-GE",
                "WS-C65134KWACUS-RF",
                "WS-C6513-FWM-K8",
                "WS-C6513-FWM-K9",
                "WS-C6513-S32P10GE",
                "WS-C6513S3210GE-RF",
                "WS-C6513-WS",
                "WS-C6513-2500AC-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.50"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.400"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "270638922",
              "icon": null,
              "id": "270638922",
              "name": "Cisco Catalyst 6513 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.896"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281716502",
              "icon": "VSS_latest_16X16.gif",
              "id": "281716502",
              "name": "Cisco Catalyst 6500 Virtual Switching System",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": "Cat600016TGrn.gif",
          "id": "270638920",
          "name": "Cisco Catalyst 6500 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N9K-C9508"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1467"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "285956854",
              "icon": null,
              "id": "285956854",
              "name": "Cisco Nexus 9508 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N77-C7710",
                "N77-C7710="
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1353"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284809079",
              "icon": null,
              "id": "284809079",
              "name": "Cisco Nexus 7700 10-Slot Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N9K-C9516"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1506"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286195224",
              "icon": null,
              "id": "286195224",
              "name": "Cisco Nexus 9516 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N77-C7706=",
                "N77-C7706"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1354"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286120747",
              "icon": null,
              "id": "286120747",
              "name": "Cisco Nexus 7700 6-Slot Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N9K-C9504"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1507"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286195223",
              "icon": null,
              "id": "286195223",
              "name": "Cisco Nexus 9504 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N77-C7702",
                "N77-C7702="
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1648"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286284485",
              "icon": null,
              "id": "286284485",
              "name": "Cisco Nexus 7700 2-Slot Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "N77-C7718=",
                "N77-C7718"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1352"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284809080",
              "icon": null,
              "id": "284809080",
              "name": "Cisco Nexus 7700 18-Slot Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "281823825",
          "name": "Data Center Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9300-24UX",
                "c9300-24P",
                "C9300-24UB",
                "C9300-48UXM",
                "C9300-48T",
                "C9300-48U",
                "C9300-24S",
                "C9300-24T",
                "C9300-24U",
                "C9300-48P",
                "C9300-48S",
                "C9300-24UXB",
                "C9300-48UB",
                "C9300-48H",
                "C9300-48UN",
                "C9300-24H"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2494"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315874",
              "icon": null,
              "id": "286315874",
              "name": "Cisco Catalyst 9300 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9300L-48UXG-4X",
                "C9300L-48T-4G",
                "C9300L-24T-4G",
                "C9300L-48UXG-2Q",
                "C9300L-24P-4X",
                "C9300L-48P-4X",
                "C9300L-48PF-4G",
                "C9300L-48T-4X",
                "C9300L-24T-4X",
                "C1117-4PLTELA",
                "C9300L-48P-4G",
                "C9300L-24P-4G",
                "C9300L-24UXG-2Q",
                "C9300L-48PF-4X",
                "C9300L-48UX-4X",
                "C9300L-24UXG-4X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2804"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286322029",
              "icon": null,
              "id": "286322029",
              "name": "Cisco Catalyst 9300L Switch Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286313806",
          "name": "Cisco Catalyst 9300 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9404R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2499"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320244",
              "icon": null,
              "id": "286320244",
              "name": "Cisco Catalyst 9404R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9410R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2501"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315703",
              "icon": null,
              "id": "286315703",
              "name": "Cisco Catalyst 9410R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9407R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2500"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315691",
              "icon": null,
              "id": "286315691",
              "name": "Cisco Catalyst 9407R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9404R",
                "C9410R",
                "C9407R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2664"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "854321692",
              "icon": null,
              "id": "854321692",
              "name": "Cisco Catalyst 9400 SVL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286315233",
          "name": "Cisco Catalyst 9400 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3000-4TC-E",
                "IE-3000-4TC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.958"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "282118651",
              "icon": null,
              "id": "282118651",
              "name": "Cisco IE 3000-4TC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3000-8TC-E",
                "IE-3000-8TC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.959"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "282118652",
              "icon": null,
              "id": "282118652",
              "name": "Cisco IE 3000-8TC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "282082952",
          "name": "Cisco IE 3000 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-16TC-B",
                "IE-2000-16TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1474"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303208",
              "icon": null,
              "id": "284303208",
              "name": "Cisco IE 2000-16TC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1715"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303212",
              "icon": null,
              "id": "284303212",
              "name": "Cisco IE 2000-16TC-G-N Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-8TC-G-B",
                "IE-2000-8TC-G-E",
                "IE-2000-8TC-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1473"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303205",
              "icon": null,
              "id": "284303205",
              "name": "Cisco IE 2000-8TC-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1844"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286226965",
              "icon": null,
              "id": "286226965",
              "name": "Cisco IE 2000-8T67P Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1843"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286226974",
              "icon": null,
              "id": "286226974",
              "name": "Cisco IE 2000-24T67 Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-16TC-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1475"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303209",
              "icon": null,
              "id": "284303209",
              "name": "Cisco IE 2000-16TC-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-4TS-G-B",
                "IE-2000-4TS-L",
                "IE-2000-4TS-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1470"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303198",
              "icon": null,
              "id": "284303198",
              "name": "Cisco IE 2000-4TS-G Industrial Ethernet Switch ",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1842"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286226951",
              "icon": null,
              "id": "286226951",
              "name": "Cisco IE 2000-16T67 Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1845"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286226971",
              "icon": null,
              "id": "286226971",
              "name": "Cisco IE 2000-16T67P Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-4T-B",
                "IE-2000-4T-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1469"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303197",
              "icon": null,
              "id": "284303197",
              "name": "Cisco IE 2000-4T Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-8TC-G-N"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1714"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303207",
              "icon": null,
              "id": "284303207",
              "name": "Cisco IE 2000-8TC-G-N Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-8TC-L",
                "IE-2000-8TC-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1472"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303204",
              "icon": null,
              "id": "284303204",
              "name": "Cisco IE 2000-8TC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-16TC-G-X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1520"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303211",
              "icon": null,
              "id": "284303211",
              "name": "Cisco IE 2000-16TC-G-X Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-4TS-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1468"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303195",
              "icon": null,
              "id": "284303195",
              "name": "Cisco IE 2000-4TS Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-4T-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1471"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284303199",
              "icon": null,
              "id": "284303199",
              "name": "Cisco IE 2000-4T-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-16TC-G-E",
                "IE-2000-16TC-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1729"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1730"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284815848",
              "icon": null,
              "id": "284815848",
              "name": "Cisco IE 2000-16PTC-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-4S-TS-G",
                "IE-2000-4S-TS-G-B",
                "IE-2000-4S-TS-G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1758"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1759"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286225425",
              "icon": null,
              "id": "286225425",
              "name": "Cisco IE 2000-4S-TS-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1841"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286226353",
              "icon": null,
              "id": "286226353",
              "name": "Cisco IE 2000-8T67 Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-2000-16PTC-G-NX"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.12.3.1.3.1731"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1731"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286291314",
              "icon": null,
              "id": "286291314",
              "name": "Cisco IE 2000-16PTC-G-NX Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284299840",
          "name": "Cisco IE 2000 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-24P-L",
                "WS-C3850-24P",
                "WS-C3850-24PW-S",
                "WS-C3850-24P-E",
                "WS-C3850-24P-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1642"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919702",
              "icon": null,
              "id": "999919702",
              "name": "Cisco Catalyst 3850 24P 10/100/1000 PoE+ Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-24T-L",
                "WS-C3850-24T-E",
                "WS-C3850-24T-S",
                "WS-C3850-24T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1644"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919704",
              "icon": null,
              "id": "999919704",
              "name": "Cisco Catalyst 3850 24 10/100/1000 PoE+ Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1879"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919707",
              "icon": null,
              "id": "999919707",
              "name": "Cisco Catalyst 3850 24 100/1000 SFP Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-24XS-L",
                "WS-C3850-24XS-E",
                "C3850-24XS-E++",
                "WS-C3850-24XS-S",
                "WS-C3850-24XS",
                "WS-C3850-24XS-E-RF",
                "C1-WSC385024XSS-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2163"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286280288",
              "icon": null,
              "id": "286280288",
              "name": "Cisco Catalyst 3850-24XS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1745"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919705",
              "icon": null,
              "id": "999919705",
              "name": "Cisco Catalyst38xx stack-able ethernet switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WSC385012X48UWS-WS",
                "WS-C385012X48UE-RF",
                "C3850-12X48U-E++",
                "WS-C3850-12X48U-E",
                "WS-C3850-12X48U-EJ"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2165"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285479",
              "icon": null,
              "id": "286285479",
              "name": "Cisco Catalyst WS-C3850-12X48U-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-48P-S",
                "WS-C3850-48P-L",
                "WS-C3850-48P",
                "WS-C3850-48P-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1641"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284455436",
              "icon": null,
              "id": "284455436",
              "name": "Cisco Catalyst 3850 48P 10/100/1000 PoE+ Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-12XS",
                "WS-C3850-12XS-E-RF",
                "C3850-12XS-E++",
                "WS-C3850-12XS-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2162"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286280248",
              "icon": null,
              "id": "286280248",
              "name": "Cisco Catalyst 3850-12XS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-12S-E",
                "WS-C3850-12S-S",
                "WS-C3850-12S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1880"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919706",
              "icon": null,
              "id": "999919706",
              "name": "Cisco Catalyst 3850 12 100/1000 SFP Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-48U-E++",
                "WS-C3850E-12X48U-E",
                "C1-WS3850-48U/K9",
                "WS-C3850-48U-E-WS",
                "WS-C3850E-12X48U",
                "WS-C3850-48U-E",
                "WS-C3850-48U",
                "UA-C3850-48U-E",
                "WS-C3850-48U-E-RF",
                "WS-C3850-48XS-E-WS",
                "WS-C385012X48UE-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1768"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284455439",
              "icon": null,
              "id": "284455439",
              "name": "Cisco Catalyst 3850-48U-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C3850-48XS-E++",
                "WS-C3850-48XS-E",
                "WS-C3850-48T-E",
                "WS-C3850-48T",
                "WS-C3850-48XS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2164"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286280328",
              "icon": null,
              "id": "286280328",
              "name": "Cisco Catalyst 3850-48XS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-48T-L",
                "WS-C3850-48F-L",
                "WS-C3850-48T-E",
                "WS-C3850-48F-S",
                "WS-C3850-48F-E",
                "WS-C3850-48F",
                "WS-C3850-48T-S",
                "WS-C3850-48T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1643"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919703",
              "icon": null,
              "id": "999919703",
              "name": "Cisco Catalyst 3850 48 10/100/1000 PoE+ Ports Layer 2/Layer 3 Ethernet Stackable Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-24XU-E-WS",
                "WS-C3850-24U",
                "WS-C3850E-24XU-E",
                "WS-C3850-24U-E-RF",
                "UA-C3850-24U-E",
                "WS-C3850-24U-E",
                "WS-C3850-24U-E-WS",
                "WS-C3850-24U-E++"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1767"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284455438",
              "icon": null,
              "id": "284455438",
              "name": "Cisco Catalyst 3850-24U-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3850-24XU-EJ",
                "WS-C3850-24XU-E",
                "WS-C3850-24XU-E-RF",
                "WS-C3850-24XU-E++",
                "WS-C3850-24XU"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2166"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285449",
              "icon": null,
              "id": "286285449",
              "name": "Cisco Catalyst 3850-24XU-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "999919900",
          "name": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C6880-X-LE++",
                "C6816-X-LE-WS",
                "C6832-X-LE-RF",
                "C6880-X++",
                "C6816-X-LE++",
                "C6880-X-LE",
                "C1-C6880-X-LE",
                "C1-C6824-X-LE-40G",
                "C6832-X-LE++",
                "C6880-X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1936"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284855056",
              "icon": null,
              "id": "284855056",
              "name": "Cisco Catalyst 6880-X Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1-C6807XL-S2T-BUN",
                "C6807-XL-S2T-BUN",
                "C6807-XL-WS",
                "C6807-XL-S2TBUN-RF",
                "C6824-X-LE-40G",
                "C6807-XL",
                "C6807-XL-S6T-BUN",
                "C6816-X-LE-RF",
                "C6807-XL-S2TBUN-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1765"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284855059",
              "icon": null,
              "id": "284855059",
              "name": "Cisco Catalyst 6807-XL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C6840-X-LE-40G-WS",
                "C6840-X-LE-40G",
                "C1-C6840-X-LE-40G",
                "C6840-X-LE-40G-RF",
                "C6832-X-LE",
                "C1-C6832-X-LE"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2276"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286287226",
              "icon": null,
              "id": "286287226",
              "name": "Cisco Catalyst 6840-X Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1934"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999991181",
              "icon": "VSS_latest_16X16.gif",
              "id": "999991181",
              "name": "Cisco Catalyst 68xx Virtual Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1784"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999991784",
              "icon": null,
              "id": "999991784",
              "name": "Cisco Catalyst 6880-X-LE Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "273430542",
          "name": "Cisco Catalyst 6800 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3010-16S-8PC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1319"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283467538",
              "icon": null,
              "id": "283467538",
              "name": "Cisco IE-3010-16S-8PC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3010-24TC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1320"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283467537",
              "icon": null,
              "id": "283467537",
              "name": "Cisco IE-3010-24TC Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "283454831",
          "name": "Cisco IE 3010 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CDB-8P"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2399"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286311788",
              "icon": null,
              "id": "286311788",
              "name": "Cisco Catalyst CDB-8P Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CDB-8U"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2398"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286311798",
              "icon": null,
              "id": "286311798",
              "name": "Cisco Catalyst CDB-8U Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286311772",
          "name": "Cisco Catalyst Digital Building Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000FE-24P-4G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3022"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325756",
              "icon": null,
              "id": "286325756",
              "name": "Cisco Catalyst 1000FE-24P-4G-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000-48P-4X-L",
                "C1000-12MP-2X-L",
                "C1000-24FP-4X-L",
                "C1000-48FP-4X-L",
                "C1000-48T-4X-L",
                "C1000-24MP-4X-L",
                "C1000-24T-4X-L",
                "C1000-24P-4X-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2897"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324612",
              "icon": null,
              "id": "286324612",
              "name": "Cisco Catalyst 1000 Switch 10G Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000FE-48P-4G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3024"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325748",
              "icon": null,
              "id": "286325748",
              "name": "Cisco Catalyst 1000FE-48P-4G-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000-8FP-2G-L",
                "C1000-16FP-2G-L",
                "C1000-8T-E-2G-L",
                "C1000-8P-E-2G-L",
                "C1000-48PP-4G-L",
                "C1000-16T-2G-L",
                "C1000-24P-4G-L",
                "C1000-8P-2G-L",
                "C1000-8FP-E-2G-L",
                "C1000-16P-E-2G-L",
                "C1000-48P-4G-L",
                "C1000-24FP-4G-L",
                "C1000-48FP-4G-L",
                "C1000-16T-E-2G-L",
                "C1000-8T-2G-L",
                "C1000-48T-4G-L",
                "C1000-24PP-4G-L",
                "C1000-16P-2G-L",
                "C1000-24T-4G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2959"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324608",
              "icon": null,
              "id": "286324608",
              "name": "Cisco Catalyst 1000 Switch 1G Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000FE-48T-4G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3023"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325732",
              "icon": null,
              "id": "286325732",
              "name": "Cisco Catalyst 1000FE-48T-4G-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1000FE-24T-4G-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3021"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325752",
              "icon": null,
              "id": "286325752",
              "name": "Cisco Catalyst 1000FE-24T-4G-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323793",
          "name": "Cisco Catalyst 1000 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ESS-3300-CON"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2682"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320889",
              "icon": null,
              "id": "286320889",
              "name": "Cisco Embedded Services ESS-3300-CON Switch",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ESS-3300-NCP"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2681"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320893",
              "icon": null,
              "id": "286320893",
              "name": "Cisco Embedded Services ESS-3300-NCP Switch",
              "source": null,
              "supportLevel": null
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286320599",
          "name": "Cisco Embedded Service 3300 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960CPD-8TT-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1278"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283654734",
              "icon": null,
              "id": "283654734",
              "name": "Cisco Catalyst 2960CPD-8TT-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960CPD-8PT-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1315"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283654733",
              "icon": null,
              "id": "283654733",
              "name": "Cisco Catalyst 2960CPD-8PT-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960C-8TC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1364"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283915793",
              "icon": null,
              "id": "283915793",
              "name": "Cisco Catalyst 2960C-8TC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960CG-8TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1316"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283733642",
              "icon": null,
              "id": "283733642",
              "name": "Cisco Catalyst 2960CG-8TC-L Compact Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960C-8PC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1366"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283915794",
              "icon": null,
              "id": "283915794",
              "name": "Cisco Catalyst 2960C-8PC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960C-12PC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1367"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283915795",
              "icon": null,
              "id": "283915795",
              "name": "Cisco Catalyst 2960C-12PC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960C-8TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1365"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283915790",
              "icon": null,
              "id": "283915790",
              "name": "Cisco Catalyst 2960C-8TC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": "Cat600016TGrn.gif",
          "id": "283603212",
          "name": "Cisco Catalyst 2960-C Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4507R",
                "WS-C4507R-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.501"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "277241600",
              "icon": null,
              "id": "277241600",
              "name": "Cisco Catalyst 4507R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4510R-1S5-10GE",
                "C1-C4510RE-S7+96V+",
                "WS-C4510R",
                "WS-C4510R-2S5-10GE",
                "WS-C4510R-RF",
                "ME-4510-FTTH-BDL",
                "WS-C4510R-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.537"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "278748183",
              "icon": null,
              "id": "278748183",
              "name": "Cisco Catalyst 4510R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C1-C4510RE-SDA",
                "WS-4510E-S8+96SFP",
                "WS-C4510R+E",
                "WS-C4510RE+96V++",
                "WS-C4510RE+96",
                "C1-C4510RE-S8+96V+",
                "WS-4510RE-S7+96V++",
                "WS-C4510RE-S8+96V+",
                "C1-C4510R+E",
                "WS-C4510R+E++",
                "WS4510E-S8+96SFP++",
                "C4510+1S7ES-C",
                "WS-C4510RE-S7+96V+",
                "ME-C4510E-S7+96SFP",
                "C1-C4510RE-DNA"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1287"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283609966",
              "icon": null,
              "id": "283609966",
              "name": "Cisco Catalyst 4510R plus E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C4503-1S7LES-C",
                "C4503-S6L-C",
                "C1-C4503-E",
                "WS-C4503-E++",
                "WS-C4503E+48V+",
                "WS-C4503E-S8L+10GB",
                "C1-C4503E-S7L+48V+",
                "WS-C4503E-S6L-1300",
                "WS-C4503E-S7L+48V+",
                "WS-C4503E-S7L+10GB",
                "WS-C4503E-S6L-48V+",
                "C1-C4503E+48V+",
                "WSC4503ES7L48V+-RF",
                "WS-C4503-E",
                "WS-C4503-S2+48",
                "WS-C4503-E-S2+48V",
                "WS-C4503-E-S2+48",
                "WS-C4503E-48V++",
                "WS-C4503E-S6L-48VE",
                "C4503-S6L-C-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.874"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281549964",
              "icon": null,
              "id": "281549964",
              "name": "Cisco Catalyst 4503-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4503-2800AC",
                "WS-C4503-S2+48GB",
                "C4503-1S7ES-C",
                "WS-C4503-RF",
                "WS-C4503",
                "WS-XC4R",
                "WS-C4503-S2+48-RF",
                "WS-C4503-1400DC",
                "WS-C4503-900AC",
                "WS-C4503-1500AC",
                "WS-C4503-1400AC",
                "C4503-1S7-C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.503"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.58"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "277241554",
              "icon": null,
              "id": "277241554",
              "name": "Cisco Catalyst 4503 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4507R+E",
                "WS4507E-8L+96SFP++",
                "C1-C4507RE-DNA",
                "ME-4507E-S7L+96SFP",
                "WS-C4507RE+96",
                "WS-4507E-S8L+96SFP",
                "WS-C4507RE+96V+",
                "C1-C4507RE+96V+",
                "C4507+2S6L-C",
                "C1-C4507RE-SDA",
                "WS-C4507RE+96V++",
                "WS-C4507RES7L+96V+",
                "WS-4507RE-S7L+96V+",
                "WS-C4507RE-S7L+96",
                "C1-C4507R+E",
                "WSC4507RES7L+96-WS",
                "C4507+1S7ES-C",
                "WS-C4507RE-S7+10GB",
                "C4507+1S7LES-C",
                "C4507+2S7LES-C",
                "C4507+2S7ES-C",
                "WS-C4507R+E++",
                "C4507+1S6L-C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1286"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283609932",
              "icon": null,
              "id": "283609932",
              "name": "Cisco Catalyst 4507R plus E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4507R-E",
                "WS-C4507RE-2S2+240",
                "WS-C4507RES6L-96V+",
                "WS-C4507RES6L+96V+",
                "C4507-1S6L-C",
                "WS-C4507R-E-S+96",
                "WS-C4507R-E-S2+96V",
                "WS-XC7R",
                "WS-C4507S7L-XG96V",
                "WS-C4507RE-1S2+240",
                "C4507-2S6-C",
                "WS-C4507R-E-S2+240",
                "WS-C4507R-E-S2+96",
                "C4507-2S6L-C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.876"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281550274",
              "icon": null,
              "id": "281550274",
              "name": "Cisco Catalyst 4507R-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ME-4510-GB-BDL",
                "WS-C4510R-E",
                "WS-C4510RE-S6-96V+",
                "C4510-1S6-C",
                "WSC4510RES696V+-RF",
                "WS-XC10R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.877"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281550283",
              "icon": null,
              "id": "281550283",
              "name": "Cisco Catalyst 4510R-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1732"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999991026",
              "icon": null,
              "id": "999991026",
              "name": "Cisco Catalyst 4500 Virtual Switching System",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C4506-1S7LES-C-RF",
                "WS-C4506E+96-RF",
                "WS-C4506-E++",
                "C4506-1S7ES-C",
                "C1-C4506E+96V+",
                "WS-C4506E-S6L-96VE",
                "WS-450096V-RF",
                "ME-4506E-S7L+96SFP",
                "WS-C4506E-S6L-2800",
                "WS-C4506E-S7L+96V+",
                "WS-450096V-WS",
                "WS-C4506E+96V+",
                "WS-C4506E-GE-96V",
                "C1-C4506E-S7L+96V+",
                "WS-C4506-RF",
                "WSC4506ES6L4200-RF",
                "WS-C4506E-S6L-4200",
                "WS-4506E-S8L+96SFP",
                "WS-C4506E-S7L+10GB",
                "WS-C4506-WS",
                "C1-C4506-E",
                "WS4506E-8L+96SFP++",
                "WS-C4506",
                "WS-C4506-E",
                "WS-C4506E-96V++",
                "C1-C4506E-DNA",
                "WS-C4506E-S6L-96V+",
                "WS-450096V",
                "WSC4506ES6L2800-RF",
                "WSC4506ES6L96V+-RF",
                "C1-C4506E-SDA",
                "C4506-S6L-C",
                "WS-C4506-S2+96",
                "WS-C4506E-S7L+96",
                "WS-C4506E-S8L+10GB",
                "C4506-S6L-C-RF",
                "WS-C4506E-10GE-96V",
                "WS-C4506E+96",
                "C4506-1S7LES-C",
                "WS-C4506E-S6L-1300"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.875"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281755878",
              "icon": null,
              "id": "281755878",
              "name": "Cisco Catalyst 4506-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4506-1400DC",
                "C4506-1S7-C",
                "WS-C4506-900AC",
                "WS-C4506-S4-AP",
                "WS-C4506-S4-96",
                "WS-C4506-S2+96-RF",
                "ME-4506-GB-BDL",
                "WS-C4506-1500AC",
                "WS-C4506-1400AC",
                "WS-C4506-2800AC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.5.59"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.502"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "277241586",
              "icon": null,
              "id": "277241586",
              "name": "Cisco Catalyst 4506 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": "Cat600016TGrn.gif",
          "id": "277241601",
          "name": "Cisco Catalyst 4500 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3300-8T2X-E",
                "IE-3300-8T2X",
                "IE-3300-8T2X-A"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3007"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325143",
              "icon": null,
              "id": "286325143",
              "name": "Cisco Catalyst IE-3300-8T2X Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3300-8U2X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3008"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325211",
              "icon": null,
              "id": "286325211",
              "name": "Cisco Catalyst IE-3300-8U2X Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3300-8P2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2686"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320755",
              "icon": null,
              "id": "286320755",
              "name": "Cisco Catalyst IE-3300-8P2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3300-8T2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2685"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320751",
              "icon": null,
              "id": "286320751",
              "name": "Cisco Catalyst IE-3300-8T2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286320607",
          "name": "Cisco Catalyst IE3300 Rugged Series",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960CX-8TC",
                "WS-C2960CX-8TC-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2137"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278476",
              "icon": null,
              "id": "286278476",
              "name": "Cisco Catalyst 2960CX-8TC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960CX-8PC-L",
                "WS-C2960CX-8PC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2191"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286282780",
              "icon": null,
              "id": "286282780",
              "name": "Cisco Catalyst 2960CX-8PC-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286278154",
          "name": "Cisco Catalyst 2960-CX Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-8S4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2071"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254961",
              "icon": null,
              "id": "286254961",
              "name": "Cisco IE-4000-8S4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-8GT4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2075"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254965",
              "icon": null,
              "id": "286254965",
              "name": "Cisco IE-4000-8GT4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-8GS4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2076"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254966",
              "icon": null,
              "id": "286254966",
              "name": "Cisco IE-4000-8GS4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-8T4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2070"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254960",
              "icon": null,
              "id": "286254960",
              "name": "Cisco IE-4000-8T4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-4T4P4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2072"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254962",
              "icon": null,
              "id": "286254962",
              "name": "Cisco IE-4000-4T4P4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-16T4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2073"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254963",
              "icon": null,
              "id": "286254963",
              "name": "Cisco IE-4000-16T4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-4TC4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2069"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254959",
              "icon": null,
              "id": "286254959",
              "name": "Cisco IE-4000-4TC4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-4S8P4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2074"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254964",
              "icon": null,
              "id": "286254964",
              "name": "Cisco IE-4000-4S8P4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-4GC4GP4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2077"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254967",
              "icon": null,
              "id": "286254967",
              "name": "Cisco IE-4000-4GC4GP4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-16GT4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2078"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254968",
              "icon": null,
              "id": "286254968",
              "name": "Cisco IE-4000-16GT4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-8GT8GP4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2079"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254969",
              "icon": null,
              "id": "286254969",
              "name": "Cisco IE-4000-8GT8GP4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-4000-4GS8GP4G-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2080"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286254970",
              "icon": null,
              "id": "286254970",
              "name": "Cisco IE-4000-4GS8GP4G-E Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286253316",
          "name": "Cisco IE 4000 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9200L-48PL-4X",
                "C9200L-24P-4X",
                "C9200L-48P-4X",
                "C9200L-48T-4G",
                "C9200L-24P-4G",
                "C9200L-24T-4G",
                "C9200L-48PXG-4X",
                "C9200L-24PXG-4XA++",
                "C9200L-24PXG-2Y",
                "C9200L-48P-4X-A++",
                "C9200L-48P-4G",
                "C9200L-48PXG-2Y",
                "C9200L-24PXG-4X",
                "C9200L-48PL-4G",
                "C9200L-48T-4X",
                "C9200L-24T-4X",
                "C9200L-48PXG-4XA++"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2694"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320849",
              "icon": null,
              "id": "286320849",
              "name": "Cisco Catalyst 9200L Switch Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9200-24PB",
                "C9200-48PB"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2965"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324614",
              "icon": null,
              "id": "286324614",
              "name": "Cisco Catalyst 9200-PB Switch Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9200-24T",
                "C9200-24PXG",
                "C9200-48PL",
                "C9200-24P",
                "C9200-48T",
                "C9200-48PXG",
                "C9200-48P"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2695"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320865",
              "icon": null,
              "id": "286320865",
              "name": "Cisco Catalyst 9200 Switch Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286319845",
          "name": "Cisco Catalyst 9200 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4500X-24X-ES",
                "WS-C4500X16SFP+-WS",
                "WS-C4500X-F-16SFP+",
                "WS-C4500X-16SFP++",
                "C1-C4500X-16SFP+",
                "WS-C4500X16SFP+-RF",
                "WS-C4500X-16",
                "WS-C4500X-16SFP+"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1605"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284275047",
              "icon": null,
              "id": "284275047",
              "name": "Cisco Catalyst 4500X-16 SFP+ Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C4500X32SFP+-WS",
                "WS-C4500X-32",
                "C4KX-PWR-750ACF-RF",
                "C1-C4500X-32SFP+",
                "WS-C4500X-32SFP++"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1606"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284275051",
              "icon": null,
              "id": "284275051",
              "name": "Cisco Catalyst 4500X-32 SFP+ Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284222178",
          "name": "Cisco Catalyst 4500-X Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560-12PC-S-M",
                "WS-C3560-12PC",
                "WS-C3560-12PC-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1015"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "282202859",
              "icon": null,
              "id": "282202859",
              "name": "Cisco Catalyst 3560-12PC-S Compact Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": "Cat600016TGrn.gif",
          "id": "278542537",
          "name": "Cisco Catalyst 3560 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2280"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286289929",
              "icon": null,
              "id": "286289929",
              "name": "Cisco Catalyst 3650-12X48UZ-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48PS-E",
                "WS-C3650-48PS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1826"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284846124",
              "icon": null,
              "id": "284846124",
              "name": "Cisco Catalyst 3650-48PS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2278"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286289899",
              "icon": null,
              "id": "286289899",
              "name": "Cisco Catalyst 3650-12X48UR-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-24PS",
                "WS-C3650-24PS-S",
                "WS-C3650-24PS-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1825"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284846122",
              "icon": null,
              "id": "284846122",
              "name": "Cisco Catalyst 3650-24PS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48TD",
                "WS-C3650-48TD-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1828"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850620",
              "icon": null,
              "id": "284850620",
              "name": "Cisco Catalyst 3650-48TD-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-8X24UQ-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2279"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286289959",
              "icon": null,
              "id": "286289959",
              "name": "Cisco Catalyst 3650-8X24UQ-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2290"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286290773",
              "icon": null,
              "id": "286290773",
              "name": "Cisco Catalyst 3650-48FQM-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1831"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850773",
              "icon": null,
              "id": "284850773",
              "name": "Cisco Catalyst 3650 Stack Module",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2281"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286289808",
              "icon": null,
              "id": "286289808",
              "name": "Cisco Catalyst 3650-12X48UQ-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-24TS-E",
                "WS-C3650-24TS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1823"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284846093",
              "icon": null,
              "id": "284846093",
              "name": "Cisco Catalyst 3650-24TS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48TS",
                "WS-C3650-48TS-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1824"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284846105",
              "icon": null,
              "id": "284846105",
              "name": "Cisco Catalyst 3650-48TS-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-24PDM-S",
                "WS-C3650-48FQM-S",
                "WS-C3650-24PDM-L",
                "WS-C3650-12X48UQ-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2066"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323966",
              "icon": null,
              "id": "286323966",
              "name": "Cisco Catalyst 3650 Switch Stack",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-24PD",
                "WS-C3650-24PD-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1829"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850621",
              "icon": null,
              "id": "284850621",
              "name": "Cisco Catalyst 3650-24PD-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2291"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286290556",
              "icon": null,
              "id": "286290556",
              "name": "Cisco Catalyst 3650-24PDM-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-24TD-E",
                "WS-C3650-24TD"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1827"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850617",
              "icon": null,
              "id": "284850617",
              "name": "Cisco Catalyst 3650-24TD-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48PD-E",
                "WS-C3650-48PD"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1830"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850622",
              "icon": null,
              "id": "284850622",
              "name": "Cisco Catalyst 3650-48PD-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48PQ",
                "WS-C3650-48PQ-E"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1881"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850740",
              "icon": null,
              "id": "284850740",
              "name": "Cisco Catalyst 3650-48PQ-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3650-48TQ-E",
                "WS-C3650-48TQ"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1882"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284850743",
              "icon": null,
              "id": "284850743",
              "name": "Cisco Catalyst 3650-48TQ-E Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284838822",
          "name": "Cisco Catalyst 3650 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9606R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2871"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "854321690",
              "icon": null,
              "id": "854321690",
              "name": "Cisco Catalyst 9600 SVL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9606R"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2648"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286322137",
              "icon": null,
              "id": "286322137",
              "name": "Cisco Catalyst 9606R Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286321767",
          "name": "Cisco Catalyst 9600 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48LPS-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1803"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832160",
              "icon": null,
              "id": "284832160",
              "name": "Cisco Catalyst 2960XR-48LPS-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48TS-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1804"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832158",
              "icon": null,
              "id": "284832158",
              "name": "Cisco Catalyst 2960XR-48TS-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1698"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795739",
              "icon": null,
              "id": "284795739",
              "name": "Cisco Catalyst 2960X-48TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24TD-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1801"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832162",
              "icon": null,
              "id": "284832162",
              "name": "Cisco Catalyst 2960XR-24TD-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48LPD-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1798"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832482",
              "icon": null,
              "id": "284832482",
              "name": "Cisco Catalyst 2960XR-48LPD-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1811"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832153",
              "icon": null,
              "id": "284832153",
              "name": "Cisco Catalyst 2960XR-24TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48FPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1812"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832152",
              "icon": null,
              "id": "284832152",
              "name": "Cisco Catalyst 2960XR-48FPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48LPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1696"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795740",
              "icon": null,
              "id": "284795740",
              "name": "Cisco Catalyst 2960X-48LPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1208"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963399",
              "icon": null,
              "id": "279963399",
              "name": "Cisco Catalyst 29xx Stack-able Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48FPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1690"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795755",
              "icon": null,
              "id": "284795755",
              "name": "Cisco Catalyst 2960X-48FPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48FPD-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1797"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832484",
              "icon": null,
              "id": "284832484",
              "name": "Cisco Catalyst 2960XR-48FPD-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1814"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284830839",
              "icon": null,
              "id": "284830839",
              "name": "Cisco Catalyst 2960XR-48TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24PS-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1805"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832159",
              "icon": null,
              "id": "284832159",
              "name": "Cisco Catalyst 2960XR-24PS-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24PS-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1702"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999991175",
              "icon": null,
              "id": "999991175",
              "name": "Cisco Catalyst 2960X-24PS-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48LPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1808"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832155",
              "icon": null,
              "id": "284832155",
              "name": "Cisco Catalyst 2960XR-48LPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1694"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795742",
              "icon": null,
              "id": "284795742",
              "name": "Cisco Catalyst 2960X-24TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1704"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795661",
              "icon": null,
              "id": "284795661",
              "name": "Cisco Catalyst 2960X-24TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1809"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832154",
              "icon": null,
              "id": "284832154",
              "name": "Cisco Catalyst 2960XR-48TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1692"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795753",
              "icon": null,
              "id": "284795753",
              "name": "Cisco Catalyst 2960X-48TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48LPS-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1701"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999991174",
              "icon": null,
              "id": "999991174",
              "name": "Cisco Catalyst 2960X-48LPS-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24PD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1693"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795752",
              "icon": null,
              "id": "284795752",
              "name": "Cisco Catalyst 2960X-24PD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1816"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284830825",
              "icon": null,
              "id": "284830825",
              "name": "Cisco Catalyst 2960XR-24TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1703"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795663",
              "icon": null,
              "id": "284795663",
              "name": "Cisco Catalyst 2960X-48TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24TS-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1806"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832157",
              "icon": null,
              "id": "284832157",
              "name": "Cisco Catalyst 2960XR-24TS-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24PD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1810"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284830791",
              "icon": null,
              "id": "284830791",
              "name": "Cisco Catalyst 2960XR-24PD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24PS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1815"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284830859",
              "icon": null,
              "id": "284830859",
              "name": "Cisco Catalyst 2960XR-24PS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1699"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795737",
              "icon": null,
              "id": "284795737",
              "name": "Cisco Catalyst 2960X-24TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48FPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1695"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795741",
              "icon": null,
              "id": "284795741",
              "name": "Cisco Catalyst 2960X-48FPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48TD-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1799"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832463",
              "icon": null,
              "id": "284832463",
              "name": "Cisco Catalyst 2960XR-48TD-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48FPS-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1802"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832161",
              "icon": null,
              "id": "284832161",
              "name": "Cisco Catalyst 2960XR-48FPS-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24PS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1697"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795736",
              "icon": null,
              "id": "284795736",
              "name": "Cisco Catalyst 2960X-24PS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48FPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1807"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832156",
              "icon": null,
              "id": "284832156",
              "name": "Cisco Catalyst 2960XR-48FPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-24PD-I"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1800"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832464",
              "icon": null,
              "id": "284832464",
              "name": "Cisco Catalyst 2960XR-24PD-I Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-48LPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1691"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795754",
              "icon": null,
              "id": "284795754",
              "name": "Cisco Catalyst 2960X-48LPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960X-24PSQ-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1700"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284795738",
              "icon": null,
              "id": "284795738",
              "name": "Cisco Catalyst 2960X-24PSQ-L Cool Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960XR-48LPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1813"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284832151",
              "icon": null,
              "id": "284832151",
              "name": "Cisco Catalyst 2960XR-48LPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284805782",
          "name": "Cisco Catalyst 2960-X/XR Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-48Y4C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2568"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319589",
              "icon": null,
              "id": "286319589",
              "name": "Cisco Catalyst C9500-48Y4C Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-32QC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2567"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319592",
              "icon": null,
              "id": "286319592",
              "name": "Cisco Catalyst C9500-32QC Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-48Y4C",
                "C9500-32QC",
                "C9500-32C",
                "C9500-24Y4C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2688"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320497",
              "icon": null,
              "id": "286320497",
              "name": "Cisco Catalyst C9500 SVL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-40X",
                "C9500-24Q",
                "C9500-12Q",
                "C9500-16X"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2593"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315863",
              "icon": null,
              "id": "286315863",
              "name": "Cisco Catalyst 9500 Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-24Y4C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2573"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319595",
              "icon": null,
              "id": "286319595",
              "name": "Cisco Catalyst C9500-24Y4C Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9500-32C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2566"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286319599",
              "icon": null,
              "id": "286319599",
              "name": "Cisco Catalyst C9500-32C Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286314263",
          "name": "Cisco Catalyst 9500 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3200-8P2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2684"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320748",
              "icon": null,
              "id": "286320748",
              "name": "Cisco Catalyst IE-3200-8P2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3200-8T2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2683"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320745",
              "icon": null,
              "id": "286320745",
              "name": "Cisco Catalyst IE-3200-8T2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286320603",
          "name": "Cisco Catalyst IE3200 Rugged Series",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CMICR-4PC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2869"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325841",
              "icon": null,
              "id": "286325841",
              "name": "Cisco Catalyst Micro Switch CMICR-4PC",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CMICR-4PS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2868"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325837",
              "icon": null,
              "id": "286325837",
              "name": "Cisco Catalyst Micro Switch CMICR-4PS",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "CMICR-4PT"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2978"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286325846",
              "icon": null,
              "id": "286325846",
              "name": "Cisco Catalyst Micro Switch CMICR-4PT",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286325800",
          "name": "Cisco Catalyst Micro Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C3560CX-8TC-S++",
                "WS-C3560CX-8TCS-RF",
                "WS-C3560CX-8TC-S",
                "WS-C3560CX-8TC",
                "WS-C3560CX-8TCS++",
                "WS-C3560CX-8TCS-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2135"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278397",
              "icon": null,
              "id": "286278397",
              "name": "Cisco Catalyst 3560CX-8TC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-8PC-S",
                "WS-C3560CX-8PCS-RF",
                "WS-C3560CX-8PCS++",
                "C3560CX-8PC-S++",
                "WS-C3560CX-8PC",
                "WS-C3560CX-8PCS-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2136"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278385",
              "icon": null,
              "id": "286278385",
              "name": "Cisco Catalyst 3560CX-8PC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-8PTS-WS",
                "WS-C3560CX-8PT",
                "WS-C3560CX-8PTS-RF",
                "WS-C3560CX-8PT-S",
                "WS-C3560CX-8PTS++"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2130"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278454",
              "icon": null,
              "id": "286278454",
              "name": "Cisco Catalyst 3560CX-8PT-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-12PC",
                "WS-C3560CX12PCS-WS",
                "WS-C3560CX-12PC-S",
                "WS-C3560CX-12PCS++",
                "WS-C3560CX12PCS-RF",
                "C3560CX-12PC-S++"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2134"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278409",
              "icon": null,
              "id": "286278409",
              "name": "Cisco Catalyst 3560CX-12PC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-12PD-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2277"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999919801",
              "icon": null,
              "id": "999919801",
              "name": "Cisco Catalyst 35xx Stack-able Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-8XPDS++",
                "WS-C3560CX-8XPD",
                "WS-C3560CX8XPDS-WS",
                "WS-C3560CX-8XPD-S",
                "WS-C3560CX8XPDS-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2131"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278465",
              "icon": null,
              "id": "286278465",
              "name": "Cisco Catalyst 3560CX-8XPD-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C3560CX-12TC-S++",
                "WS-C3560CX-12TCS++",
                "WS-C3560CX-12TC",
                "WS-C3560CX-12TC-S",
                "WS-C3560CX12TCS-WS",
                "WS-C3560CX12TCS-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2133"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278421",
              "icon": null,
              "id": "286278421",
              "name": "Cisco Catalyst 3560CX-12TC-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C3560CX-12PDS++",
                "WS-C3560CX-12PD-S",
                "WS-C3560CX12PDS-WS",
                "C3560CX-12PD-S++",
                "WS-C3560CX12PDS-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2132"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286278443",
              "icon": null,
              "id": "286278443",
              "name": "Cisco Catalyst 3560CX-12PD-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286278165",
          "name": "Cisco Catalyst 3560-CX Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-48TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2366"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309092",
              "icon": null,
              "id": "286309092",
              "name": "Cisco Catalyst 2960L-48TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-48PQ-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2498"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316746",
              "icon": null,
              "id": "286316746",
              "name": "Cisco Catalyst 2960L-48PQ-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-24PQ-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2497"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316724",
              "icon": null,
              "id": "286316724",
              "name": "Cisco Catalyst 2960L-24PQ-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-16TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2362"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309122",
              "icon": null,
              "id": "286309122",
              "name": "Cisco Catalyst 2960L-16TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-24PS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2365"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309072",
              "icon": null,
              "id": "286309072",
              "name": "Cisco Catalyst 2960L-24PS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-16PS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2363"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309132",
              "icon": null,
              "id": "286309132",
              "name": "Cisco Catalyst 2960L-16PS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-8PS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2361"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309112",
              "icon": null,
              "id": "286309112",
              "name": "Cisco Catalyst 2960L-8PS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-8TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2360"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309102",
              "icon": null,
              "id": "286309102",
              "name": "Cisco Catalyst 2960L-8TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-48PS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2367"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309082",
              "icon": null,
              "id": "286309082",
              "name": "Cisco Catalyst 2960L-48PS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-24TQ-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2495"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316735",
              "icon": null,
              "id": "286316735",
              "name": "Cisco Catalyst 2960L-24TQ-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-48TQ-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2496"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316757",
              "icon": null,
              "id": "286316757",
              "name": "Cisco Catalyst 2960L-48TQ-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960L-24TS-LL"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2364"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286309062",
              "icon": null,
              "id": "286309062",
              "name": "Cisco Catalyst 2960L-24TS-LL Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286308458",
          "name": "Cisco Catalyst 2960-L Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400-8P2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2687"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320758",
              "icon": null,
              "id": "286320758",
              "name": "Cisco Catalyst IE-3400-8P2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-3400-8T2S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2872"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323343",
              "icon": null,
              "id": "286323343",
              "name": "Cisco Catalyst IE-3400-8T2S Rugged Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286320656",
          "name": "Cisco Catalyst IE3400 Rugged Series",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-24TS-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1257"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963389",
              "icon": null,
              "id": "279963389",
              "name": "Cisco Catalyst 2960S-24TS-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960G-8TC-L",
                "WS-C2960G-8TC-L-RF",
                "WS-C2960G-8TC-L-WS",
                "WS-C2960G-8TC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.799"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "280836706",
              "icon": null,
              "id": "280836706",
              "name": "Cisco Catalyst 2960G-8TC Compact Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48FPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1258"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963383",
              "icon": null,
              "id": "279963383",
              "name": "Cisco Catalyst 2960S-48FPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-24TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1267"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963387",
              "icon": null,
              "id": "279963387",
              "name": "Cisco Catalyst 2960S-24TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48TS-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1256"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963388",
              "icon": null,
              "id": "279963388",
              "name": "Cisco Catalyst 2960S-48TS-S Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-24PD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1261"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963385",
              "icon": null,
              "id": "279963385",
              "name": "Cisco Catalyst 2960S-24PD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48LPD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1259"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963384",
              "icon": null,
              "id": "279963384",
              "name": "Cisco Catalyst 2960S-48LPD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960G-48TCL-WS",
                "WS-C2960G-48TC",
                "WS-C2960G-48TC-L",
                "WS-C2960G-48TCL-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.697"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "280590240",
              "icon": null,
              "id": "280590240",
              "name": "Cisco Catalyst 2960G-48TC Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48FPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1263"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963390",
              "icon": null,
              "id": "279963390",
              "name": "Cisco Catalyst 2960S-48FPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1260"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963380",
              "icon": null,
              "id": "279963380",
              "name": "Cisco Catalyst 2960S-48TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-24TD-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1262"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963381",
              "icon": null,
              "id": "279963381",
              "name": "Cisco Catalyst 2960S-24TD-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48LPS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1264"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963391",
              "icon": null,
              "id": "279963391",
              "name": "Cisco Catalyst 2960S-48LPS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960G-24TCL-RF",
                "WS-C2960G-24TC",
                "WS-C2960G-24TC-L",
                "WS-C2960G-24TCL-WS",
                "WS-C2960G-24TC-RF"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.696"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963492",
              "icon": null,
              "id": "279963492",
              "name": "Cisco Catalyst 2960G-24TC Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-48TS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1266"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963386",
              "icon": null,
              "id": "279963386",
              "name": "Cisco Catalyst 2960S-48TS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "WS-C2960S-24PS-L"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1265"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "279963392",
              "icon": null,
              "id": "279963392",
              "name": "Cisco Catalyst 2960S-24PS-L Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "279935165",
          "name": "Cisco Catalyst 2960 Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1872"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892488",
              "icon": null,
              "id": "284892488",
              "name": "Cisco IE-2000U-16TC-G-X Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1869"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892485",
              "icon": null,
              "id": "284892485",
              "name": "Cisco IE-2000U-4TS-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1868"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892484",
              "icon": null,
              "id": "284892484",
              "name": "Cisco IE-2000U-4T-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1840"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892489",
              "icon": null,
              "id": "284892489",
              "name": "Cisco IE-2000U-16TC-GP Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1870"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892486",
              "icon": null,
              "id": "284892486",
              "name": "Cisco IE-2000U-8TC-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1871"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284892487",
              "icon": null,
              "id": "284892487",
              "name": "Cisco IE-2000U-16TC-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1839"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "285974825",
              "icon": null,
              "id": "285974825",
              "name": "Cisco IE-2000U-4S-G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284887815",
          "name": "Cisco Industrial Ethernet 2000U Series Switches",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-5000-12S12P-10G"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2233"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286286911",
              "icon": null,
              "id": "286286911",
              "name": "Cisco IE-5000-12S12P-10G Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IE-5000-16S12P"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2296"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286289541",
              "icon": null,
              "id": "286289541",
              "name": "Cisco IE-5000-16S12P Industrial Ethernet Switch",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286286883",
          "name": "Cisco IE 5000 Series Switches",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16-Layer_3_blue_SF.gif",
      "id": "268438038",
      "name": "Switches and Hubs",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5515K7",
                "ASA5515K7sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1443"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1448"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143185",
              "icon": null,
              "id": "284143185",
              "name": "Cisco ASA 5515-X Adaptive Security Appliance with No Payload Encryption",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5555sc",
                "ASA5555"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1410"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1414"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143131",
              "icon": null,
              "id": "284143131",
              "name": "Cisco ASA 5555-X Adaptive Security Appliance",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5515",
                "ASA5515sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1420"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1421"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143128",
              "icon": null,
              "id": "284143128",
              "name": "Cisco ASA 5515-X Adaptive Security Appliance",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5525",
                "ASA5525sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1408"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1412"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143129",
              "icon": null,
              "id": "284143129",
              "name": "Cisco ASA 5525-X Adaptive Security Appliance",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5545",
                "ASA5545sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1409"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1413"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143130",
              "icon": null,
              "id": "284143130",
              "name": "Cisco ASA 5545-X Adaptive Security Appliance",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5525K7",
                "ASA5525K7sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1449"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1444"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284143190",
              "icon": null,
              "id": "284143190",
              "name": "Cisco ASA 5525-X Adaptive Security Appliance with No Payload Encryption",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "279513386",
          "name": "Cisco ASA 5500-X Series Next-Generation Firewalls",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5506"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2114"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286283326",
              "icon": null,
              "id": "286283326",
              "name": "Cisco ASA 5506-X with FirePOWER Services",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5506H"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2241"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286286701",
              "icon": null,
              "id": "286286701",
              "name": "Cisco ASA 5506H-X with FirePOWER Services",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5506W"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2117"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285101",
              "icon": null,
              "id": "286285101",
              "name": "Cisco ASA 5506W-X with FirePOWER Services",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5508",
                "ASA5508sc"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2120"
                },
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2121"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285773",
              "icon": null,
              "id": "286285773",
              "name": "Cisco ASA 5508-X with FirePOWER Services",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ASA5516"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2242"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286285782",
              "icon": null,
              "id": "286285782",
              "name": "Cisco ASA 5516-X with FirePOWER Services",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286270920",
          "name": "Cisco ASA 5500-X with FirePOWER Services",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1902"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286119613",
              "icon": null,
              "id": "286119613",
              "name": "Cisco Adaptive Security Virtual Appliance (ASAv)",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "278875415",
          "name": "Adaptive Security Appliances (ASA)",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_security_vpn_sf.gif",
      "id": "268438162",
      "name": "Security and VPN",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "VG450"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2693"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320713",
              "icon": null,
              "id": "286320713",
              "name": "Cisco VG450 Analog Voice Gateway",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": "16x16_Voice_Gateway_sf.gif",
          "id": "270675370",
          "name": "Cisco VG Series Gateways",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_voice_telephony_sf.gif",
      "id": "278875240",
      "name": "Voice and Telephony",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "DN-APL-TTA-M"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3025"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286326115",
              "icon": null,
              "id": "286326115",
              "name": "Cisco DNA Traffic Telemetry Appliance",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286326102",
          "name": "Cisco DNA Traffic Telemetry Appliances",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_network_mngt_sf.gif",
      "id": "268439477",
      "name": "Network Management",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat6kWsSup720Base"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "280829702",
              "icon": null,
              "id": "280829702",
              "name": "Catalyst 6500 Series Supervisor Engine 720 / MSFC3",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup7e"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283289791",
              "icon": null,
              "id": "283289791",
              "name": "Cisco Catalyst 4500 Supervisor Engine 7-E",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevModuleC9400SUP1XL"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316710",
              "icon": null,
              "id": "286316710",
              "name": "Cisco Catalyst 9400 Supervisor Engine-1XL",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevModuleC9400SUP1XLY"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320394",
              "icon": null,
              "id": "286320394",
              "name": "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat6kVsSup2t10g"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283933147",
              "icon": null,
              "id": "283933147",
              "name": "Catalyst 6500 Series Supervisor Engine 2T",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup9e"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286315046",
              "icon": null,
              "id": "286315046",
              "name": "Cisco Catalyst 4500E Supervisor Engine 9-E",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup6LE"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "282703071",
              "icon": null,
              "id": "282703071",
              "name": "Cisco Catalyst 4500 Supervisor Engine 6L-E",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup8e"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284867339",
              "icon": null,
              "id": "284867339",
              "name": "Cisco Catalyst 4500E Supervisor Engine 8-E",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevModuleC9400SUP1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316172",
              "icon": null,
              "id": "286316172",
              "name": "Cisco Catalyst 9400 Supervisor Engine-1",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup6e"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "281550478",
              "icon": null,
              "id": "281550478",
              "name": "Cisco Catalyst 4500 Supervisor Engine 6-E",
              "source": null,
              "supportLevel": null
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "cevCat4kWsx45Sup7LE"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "283904596",
              "icon": null,
              "id": "283904596",
              "name": "Cisco Catalyst 4500 Supervisor Engine 7L-E",
              "source": null,
              "supportLevel": null
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "268437743",
          "name": "Cisco Network Modules",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_interface_module_sf.gif",
      "id": "268437717",
      "name": "Cisco Interfaces and Modules",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2170"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286284588",
              "icon": null,
              "id": "286284588",
              "name": "Cisco 5520 Series Wireless Controllers",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "282585015",
          "name": "Cisco 5500 Series Wireless LAN Controllers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9130AXE-Z",
                "C9130AXI-S",
                "C9130AXI-T",
                "C9130AXI-Z",
                "C9130AXI-B1",
                "C9130AXE-B1",
                "C9130AXE-EWC-S",
                "C9130AXE-EWC-T",
                "C9130AXE-EWC-Z",
                "C9130AXI-EWC-Z",
                "C9130AXI-EWC-R",
                "C9130AXI-EWC-Q",
                "C9130AXE-E1",
                "C9130AXI-EWC-T",
                "C9130AXI-EWC-S",
                "C9130AXI-EWC-I",
                "C9130AXI-EWC-H",
                "C9130AXI-EWC-G",
                "C9130AXI-EWC-N",
                "C9130AXI-B-EDU",
                "C9130AXI-E1",
                "C9130AXI-EWC-K",
                "C9130AXI-EWC-B",
                "C9130AXI-EWC-A",
                "C9130AXI-EWC-F",
                "C9130AXE-EWC-A",
                "C9130AXI-EWC-E",
                "C9130AXE-EWC-B",
                "C9130AXI-EWC-D",
                "C9130AXI-EWC-C",
                "C9130AXE-B-EDU",
                "C9130AXE-EWC-E",
                "C9130AXE-EWC-F",
                "C9130AXE-EWC-C",
                "C9130AXE-EWC-D",
                "C9130AXE-EWC-I",
                "C9130AXE-EWC-G",
                "C9130AXE-EWC-H",
                "C9130AXE-EWC-N",
                "C9130AXE-EWC-K",
                "C9130AXE-EWC-Q",
                "C9130AXE-EWC-R",
                "C9130AXE-I",
                "C9130AXI-E",
                "C9130AXI-F",
                "C9130AXE-G",
                "C9130AXI-C",
                "C9130AXE-H",
                "C9130AXI-D",
                "C9130AXI-I",
                "C9130AXE-N",
                "C9130AXE-K",
                "C9130AXI-G",
                "C9130AXI-H",
                "C9130AXE-Q",
                "C9130AXE-R",
                "C9130AXI-N",
                "C9130AXI-K",
                "C9130AXI-Q",
                "C9130AXI-R",
                "C9130AXE-S",
                "C9130AXE-T",
                "C9130AXE-A",
                "C9130AXE-B",
                "C9130AXE-E",
                "C9130AXI-A",
                "C9130AXE-F",
                "C9130AXI-B",
                "C9130AXE-C",
                "C9130AXE-D",
                "C9136I-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2878"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323998",
              "icon": null,
              "id": "286323998",
              "name": "Cisco Embedded Wireless Controller on Catalyst 9130AX Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9115AXI-H",
                "C9115AXE-EWC-B",
                "C9115AXI-I",
                "C9115AXE-EWC-C",
                "C9115AXI-F",
                "C9115AXE-EWC-D",
                "C9115AXI-G",
                "C9115AXE-K",
                "C9115AXE-EWC-E",
                "C9115AXI-D",
                "C9115AXE-H",
                "C9115AXI-EWC-Z",
                "C9115AXE-EWC-F",
                "C9115AXI-E",
                "C9115AXE-I",
                "C9115AXE-EWC-G",
                "C9115AXI-B",
                "C9115AXE-F",
                "C9115AXE-EWC-H",
                "C9115AXI-C",
                "C9115AXE-G",
                "C9115AXE-EWC-I",
                "C9115AXE-D",
                "C9115AXI-A",
                "C9115AXE-E",
                "C9115AXE-B",
                "C9115AXE-C",
                "C9115AXE-A",
                "C9115AXE-EWC-A",
                "C9115AXE-EWC-R",
                "C9115AXE-EWC-S",
                "C9115AXE-Z",
                "C9115AXE-EWC-T",
                "C9115AXI-T",
                "C9115AXI-R",
                "C9115AXI-S",
                "C9115AXE-T",
                "C9115AXI-Q",
                "C9115AXE-EWC-K",
                "C9115AXI-N",
                "C9115AXE-R",
                "C9115AXE-S",
                "C9115AXE-EWC-N",
                "C9115AXE-Q",
                "C9115AXE-N",
                "C9115AXI-K",
                "C9115AXE-EWC-Q",
                "C9115AXE-EWC-Z",
                "C9115AXI-EWC-A",
                "C9115AXI-EWC-B",
                "C9115AXI-EWC-C",
                "C9115AXI-Z",
                "C9115AXI-EWC-D",
                "C9115AXI-EWC-E",
                "C9115AXI-EWC-F",
                "C9115AXI-EWC-G",
                "C9115AXI-EWC-H",
                "C9115AXI-EWC-I",
                "C9115AXI-EWC-K",
                "C9115AXI-EWC-N",
                "C9115AXI-EWC-Q",
                "C9115AXI-EWC-R",
                "C9115AXI-EWC-S",
                "C9115AXI-EWC-T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2840"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286321056",
              "icon": null,
              "id": "286321056",
              "name": "Cisco Embedded Wireless Controller on Catalyst 9115AX Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9120AXE-D",
                "C9120AXE-EWC-Q",
                "C9120AXI-A",
                "C9120AXE-E",
                "C9120AXI-B",
                "C9120AXE-F",
                "C9120AXE-EWC-S",
                "C9120AXI-C",
                "C9120AXE-G",
                "C9120AXE-EWC-R",
                "C9120AXE-A",
                "C9120AXE-B",
                "C9120AXE-C",
                "C9120AXE-EWC-N",
                "C9120AXE-EWC-I",
                "C9120AXE-EWC-H",
                "C9120AXE-EWC-K",
                "C9120AXE-EWC-E",
                "C9120AXE-EWC-D",
                "C9120AXE-EWC-G",
                "C9120AXE-EWC-F",
                "C9120AXE-T",
                "C9120AXE-EWC-A",
                "C9120AXI-Q",
                "C9120AXI-R",
                "C9120AXI-EWC-T",
                "C9120AXE-EWC-C",
                "C9120AXI-S",
                "C9120AXE-EWC-B",
                "C9120AXI-EWC-R",
                "C9120AXE-Q",
                "C9120AXI-EWC-S",
                "C9120AXI-N",
                "C9120AXE-R",
                "C9120AXE-S",
                "C9120AXI-EWC-Q",
                "C9120AXI-H",
                "C9120AXI-I",
                "C9120AXE-N",
                "C9120AXI-K",
                "C9120AXI-D",
                "C9120AXE-H",
                "C9120AXI-EWC-Z",
                "C9120AXI-E",
                "C9120AXE-I",
                "C9120AXI-F",
                "C9120AXI-G",
                "C9120AXE-K",
                "C9120AXP-EWC-K",
                "C9120AXP-Z",
                "C9120AXP-EWC-I",
                "C9120AXP-EWC-N",
                "C9120AXP-Q",
                "C9120AXP-EWC-S",
                "C9120AXP-R",
                "C9120AXP-EWC-T",
                "C9120AXP-S",
                "C9120AXP-EWC-Q",
                "C9120AXP-T",
                "C9120AXP-EWC-R",
                "C9120AXP-N",
                "C9120AXP-EWC-Z",
                "C9120AXP-I",
                "C9120AXP-K",
                "C9120AXP-E",
                "C9120AXP-F",
                "C9120AXP-G",
                "C9120AXP-H",
                "C9120AXP-A",
                "C9120AXP-EWC-C",
                "C9120AXP-B",
                "C9120AXP-EWC-D",
                "C9120AXP-C",
                "C9120AXP-EWC-A",
                "C9120AXP-D",
                "C9120AXP-EWC-B",
                "C9120AXP-EWC-G",
                "C9120AXP-EWC-H",
                "C9120AXP-EWC-E",
                "C9120AXP-EWC-F",
                "C9120AXI-EWC-F",
                "C9120AXI-EWC-G",
                "C9120AXI-EWC-D",
                "C9120AXI-EWC-E",
                "C9120AXI-EWC-B",
                "C9120AXI-EWC-C",
                "C9120AXI-EWC-A",
                "C9120AXI-EWC-N",
                "C9120AXI-Z",
                "C9120AXI-T",
                "C9120AXI-EWC-K",
                "C9120AXE-Z",
                "C9120AXI-EWC-H",
                "C9120AXI-EWC-I",
                "C9120AXE-EWC-Z",
                "C9120AXE-EWC-T"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2850"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323294",
              "icon": null,
              "id": "286323294",
              "name": "Cisco Embedded Wireless Controller on Catalyst 9120AX Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9105AXI-EWC-Z",
                "C9105AXI-B",
                "C9105AXI-EWC-S",
                "C9105AXI-A",
                "C9105AXI-EWC-T",
                "C9105AXI-EWC-Q",
                "C9105AXI-EWC-R",
                "C9105AXI-EWC-K",
                "C9105AXI-I",
                "C9105AXI-H",
                "C9105AXI-G",
                "C9105AXI-EWC-N",
                "C9105AXI-F",
                "C9105AXI-EWC-G",
                "C9105AXI-E",
                "C9105AXI-EWC-H",
                "C9105AXI-D",
                "C9105AXI-EWC-I",
                "C9105AXI-C",
                "C9105AXI-R",
                "C9105AXI-EWC-C",
                "C9105AXI-Q",
                "C9105AXI-EWC-D",
                "C9105AXI-EWC-E",
                "C9105AXI-EWC-F",
                "C9105AXI-N",
                "C9105AXI-EWC-A",
                "C9105AXI-K",
                "C9105AXI-EWC-B",
                "C9105AXI-Z",
                "C9105AXI-T",
                "C9105AXI-S"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.3005"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324858",
              "icon": null,
              "id": "286324858",
              "name": "Cisco Embedded Wireless Controller on Catalyst 9105AX Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9117AXI-EWC-Z",
                "C9117AXI-S",
                "C9117AXI-EWC-K",
                "C9117AXI-R",
                "C9117AXI-Q",
                "C9117AXI-EWC-I",
                "C9117AXI-EWC-H",
                "C9117AXI-N",
                "C9117AXI-EWC-N",
                "C9117AXI-EWC-S",
                "C9117AXI-Z",
                "C9117AXI-EWC-R",
                "C9117AXI-EWC-Q",
                "C9117AXI-T",
                "C9117AXI-EWC-T",
                "C9117AXI-C",
                "C9117AXI-B",
                "C9117AXI-A",
                "C9117AXI-K",
                "C9117AXI-EWC-C",
                "C9117AXI-EWC-B",
                "C9117AXI-I",
                "C9117AXI-EWC-A",
                "C9117AXI-H",
                "C9117AXI-G",
                "C9117AXI-EWC-G",
                "C9117AXI-F",
                "C9117AXI-EWC-F",
                "C9117AXI-E",
                "C9117AXI-EWC-E",
                "C9117AXI-D",
                "C9117AXI-EWC-D"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2841"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286321061",
              "icon": null,
              "id": "286321061",
              "name": "Cisco Embedded Wireless Controller on Catalyst 9117AX Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322207",
          "name": "Cisco Embedded Wireless Controller on Catalyst Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1295"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286600534",
              "icon": null,
              "id": "286600534",
              "name": "Cisco Flex 7510 Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286585015",
          "name": "Cisco Flex 7500 Series Wireless LAN Controller",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2171"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286284599",
              "icon": null,
              "id": "286284599",
              "name": "Cisco 8540 Series Wireless Controllers",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1615"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "287600534",
              "icon": null,
              "id": "287600534",
              "name": "Cisco Flex 8510 Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "287585015",
          "name": "Cisco 8500 Series Wireless LAN Controller",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP2802E-E-K9",
                "AIR-AP2802E-Z-K9",
                "AIR-AP2802E-C-K9",
                "AIR-AP2802E-K-K9",
                "AIR-AP2802E-A-K9",
                "AIR-AP2802E-G-K9",
                "AIR-AP2802E-I-K9",
                "AIR-AP2802E-Q-K9",
                "AIR-AP2802E-UXK9",
                "AIR-AP2802I-R-K9",
                "AIR-AP2802E-S-K9",
                "AIR-AP2802I-T-K9",
                "AIR-AP2802I-D-K9",
                "AIR-AP2802I-N-K9",
                "AIR-AP2802I-UXK9",
                "AIR-AP2802I-B-K9",
                "AIR-AP2802I-H-K9",
                "AIR-AP2802I-F-K9",
                "AIR-AP2802I-K-K9",
                "AIR-AP2802E-D-K9",
                "AIR-AP2802I-Z-K9",
                "AIR-AP2802E-B-K9",
                "AIR-AP2802E-F-K9",
                "AIR-AP2802E-H-K9",
                "AIR-AP2802I-A-K9",
                "AIR-AP2802E-N-K9",
                "AIR-AP2802I-S-K9",
                "AIR-AP2802E-R-K9",
                "AIR-AP2802E-T-K9",
                "AIR-AP2802I-E-K9",
                "AIR-AP2802I-Q-K9",
                "AIR-AP2802I-C-K9",
                "AIR-AP2802I-I-K9",
                "AIR-AP2802I-G-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2370"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286304960",
              "icon": null,
              "id": "286304960",
              "name": "Cisco Aironet 2800",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1840-B-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2730"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286321945",
              "icon": null,
              "id": "286321945",
              "name": "Cisco Aironet 1840",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1852E-D-K9",
                "AIR-AP1852I-Q-K9C",
                "AIR-AP1852I-K-K9",
                "AIR-AP1852E-B-K9",
                "AIR-AP1852E-F-K9",
                "AIR-AP1852I-I-K9",
                "AIR-AP1852E-H-K9",
                "AIR-AP1852I-G-K9C",
                "AIR-AP1852I-G-K9",
                "AIR-AP1852E-R-K9C",
                "AIR-AP1852I-Z-K9",
                "AIR-AP1852I-Q-K9",
                "AIR-AP1852I-S-K9",
                "AIR-AP1852I-R-K9C",
                "AIR-AP1852E-N-K9",
                "AIR-AP1852E-UXK9",
                "AIR-AP1852I-A-K9",
                "AIR-AP1852I-E-K9",
                "AIR-AP1852E-T-K9",
                "AIR-AP1852I-C-K9",
                "AIR-AP1852I-B-K9C",
                "AIR-AP1852E-R-K9",
                "AIR-AP1852E-Z-K9",
                "AIR-AP1852E-E-K9",
                "AIR-AP1852E-C-K9",
                "AIR-AP1852I-E-K9C",
                "AIR-AP1852I-F-K9",
                "AIR-AP1852E-I-K9",
                "AIR-AP1852I-H-K9",
                "AIR-AP1852I-N-K9",
                "AIR-AP1852E-A-K9",
                "AIR-AP1852I-R-K9",
                "AIR-AP1852I-T-K9",
                "AIR-AP1852E-T-K9C",
                "AIR-AP1852E-Q-K9",
                "AIR-AP1852I-B-K9",
                "AIR-AP1852E-K-K9",
                "AIR-AP1852I-D-K9",
                "AIR-AP1852I-UXK9",
                "AIR-AP1852E-S-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2250"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286288455",
              "icon": null,
              "id": "286288455",
              "name": "Cisco Aironet 1850",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ESW-6300-CON",
                "ESW-6300-ME"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2966"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286324462",
              "icon": null,
              "id": "286324462",
              "name": "Cisco Mobility Express for 6300 Embedded Services Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IW-6300-ME",
                "IW-6300H-DCW",
                "IW-6300H-AC",
                "IW-6300H-DC"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2898"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323797",
              "icon": null,
              "id": "286323797",
              "name": "Cisco Mobility Express for Catalyst IW6300 Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1815I-E-K9",
                "AIR-AP1815I-F-K9",
                "AIR-AP1815I-G-K9",
                "AIR-AP1815I-Z-K9",
                "AIR-AP1815I-K-K9",
                "AIR-AP1815I-I-K9",
                "AIR-AP1815I-H-K9",
                "AIR-AP1815I-UXK9",
                "AIR-AP1815I-A-K9",
                "AIR-AP1815I-D-K9",
                "AIR-AP1815I-B-K9",
                "AIR-AP1815I-C-K9",
                "AIR-AP1815I-S-K9",
                "AIR-AP1815I-N-K9",
                "AIR-AP1815I-T-K9",
                "AIR-AP1815I-R-K9",
                "AIR-AP1815I-Q-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2489"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286305324",
              "icon": null,
              "id": "286305324",
              "name": "Cisco Aironet 1815",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP3802I-C-K9",
                "AIR-AP3802I-Z-K9",
                "AIR-AP3802E-S-K9",
                "AIR-AP3802I-A-K9",
                "AIR-AP3802E-Q-K9",
                "AIR-AP3802I-E-K9",
                "AIR-AP3802I-G-K9",
                "AIR-AP3802E-K-K9",
                "AIR-AP3802I-K-K9",
                "AIR-AP3802E-E-K9",
                "AIR-AP3802E-G-K9",
                "AIR-AP3802E-I-K9",
                "AIR-AP3802I-I-K9",
                "AIR-AP3802E-C-K9",
                "AIR-AP3802I-S-K9",
                "AIR-AP3802E-A-K9",
                "AIR-AP3802I-Q-K9",
                "AIR-AP3802I-D-K9",
                "AIR-AP3802E-T-K9",
                "AIR-AP3802I-B-K9",
                "AIR-AP3802E-R-K9",
                "AIR-AP3802P-G-K9",
                "AIR-AP3802I-F-K9",
                "AIR-AP3802I-H-K9",
                "AIR-AP3802E-N-K9",
                "AIR-AP3802E-Z-K9",
                "AIR-AP3802I-N-K9",
                "AIR-AP3802I-UXK9",
                "AIR-AP3802E-F-K9",
                "AIR-AP3802E-H-K9",
                "AIR-AP3802I-T-K9",
                "AIR-AP3802E-UXK9",
                "AIR-AP3802E-D-K9",
                "AIR-AP3802E-B-K9",
                "AIR-AP3802I-R-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2373"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286304981",
              "icon": null,
              "id": "286304981",
              "name": "Cisco Aironet 3800",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-OEAP1810-R-K9",
                "AIR-AP1810W-N-K9",
                "AIR-OEAP1810-N-K9",
                "AIR-OEAP1810-Z-K9",
                "AIR-OEAP1810-T-K9",
                "AIR-AP1810W-A-K9",
                "AIR-AP1810W-S-K9",
                "AIR-OEAP1810-D-K9",
                "AIR-OEAP1810-H-K9",
                "AIR-OEAP1810-F-K9",
                "AIR-AP1810W-Q-K9",
                "AIR-OEAP1810-B-K9",
                "AIR-OEAP1810-UXK9",
                "AIR-AP1810W-C-K9",
                "AIR-AP1810W-I-K9",
                "AIR-AP1810W-G-K9",
                "AIR-AP1810W-E-K9",
                "AIR-AP1810W-K-K9",
                "AIR-OEAP1810-Q-K9",
                "AIR-AP1810W-Z-K9",
                "AIR-OEAP1810-K-K9",
                "AIR-OEAP1810-S-K9",
                "AIR-OEAP1810-G-K9",
                "AIR-OEAP1810-I-K9",
                "AIR-AP1810W-R-K9",
                "AIR-OEAP1810-C-K9",
                "AIR-AP1810W-T-K9",
                "AIR-OEAP1810-E-K9",
                "AIR-OEAP1810-A-K9",
                "AIR-AP1810W-H-K9",
                "AIR-AP1810W-UXK9",
                "AIR-AP1810W-B-K9",
                "AIR-AP1810W-D-K9",
                "AIR-AP1810W-F-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2372"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286304970",
              "icon": null,
              "id": "286304970",
              "name": "Cisco Aironet 1810W",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP4800-B-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2670"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286320207",
              "icon": null,
              "id": "286320207",
              "name": "Cisco Aironet 4800",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1832I-N-K9",
                "AIR-AP1832I-UXK9",
                "AIR-AP1832I-K-K9",
                "AIR-AP1832I-I-K9",
                "AIR-AP1832I-H-K9",
                "AIR-AP1832I-S-K9",
                "AIR-AP1832I-R-K9",
                "AIR-AP1832I-Q-K9",
                "AIR-AP1832I-Z-K9",
                "AIR-AP1832I-T-K9",
                "AIR-AP1832I-B-K9",
                "AIR-AP1832I-C-K9",
                "AIR-AP1832I-A-K9",
                "AIR-AP1832I-E-K9",
                "AIR-AP1832I-D-K9",
                "AIR-AP1832I-F-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2371"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286288446",
              "icon": null,
              "id": "286288446",
              "name": "Cisco Aironet 1830",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1542I-S-K9",
                "AIR-AP1542D-E-K9",
                "AIR-AP1542D-G-K9",
                "AIR-AP1542D-A-K9",
                "AIR-AP1542D-Q-K9",
                "AIR-AP1542I-H-K9",
                "AIR-AP1542I-D-K9",
                "AIR-AP1542I-F-K9",
                "AIR-AP1542D-K-K9",
                "AIR-AP1542D-I-K9",
                "AIR-AP1542I-N-K9",
                "AIR-AP1542D-S-K9",
                "AIR-AP1542D-M-K9",
                "AIR-AP1542I-T-K9",
                "AIR-AP1542D-F-K9",
                "AIR-AP1542I-R-K9",
                "AIR-AP1542D-H-K9",
                "AIR-AP1542D-D-K9",
                "AIR-AP1542D-B-K9",
                "AIR-AP1542I-A-K9",
                "AIR-AP1542I-K-K9",
                "AIR-AP1542I-I-K9",
                "AIR-AP1542I-G-K9",
                "AIR-AP1542I-M-K9",
                "AIR-AP1542D-N-K9",
                "AIR-AP1542D-T-K9",
                "AIR-AP1542I-Q-K9",
                "AIR-AP1542D-R-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2490"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286311839",
              "icon": null,
              "id": "286311839",
              "name": "Cisco Aironet 1540",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2374"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286305312",
              "icon": null,
              "id": "286305312",
              "name": "Cisco Aironet 1560",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286287880",
          "name": "Cisco Mobility Express",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1631"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "288600534",
              "icon": null,
              "id": "288600534",
              "name": "Cisco Virtual Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "288585015",
          "name": "Cisco VIRTUAL Series Wireless LAN Controller",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9800-40-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2530"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286316412",
              "icon": null,
              "id": "286316412",
              "name": "Cisco Catalyst 9800-40 Wireless Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9800-L-C-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2860"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286323158",
              "icon": null,
              "id": "286323158",
              "name": "Cisco Catalyst 9800-L-C Wireless Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9800-80-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2669"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286321396",
              "icon": null,
              "id": "286321396",
              "name": "Cisco Catalyst 9800-80 Wireless Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9800-L-F-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2861"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286321399",
              "icon": null,
              "id": "286321399",
              "name": "Cisco Catalyst 9800-L-F Wireless Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322524",
          "name": "Cisco Catalyst 9800 Series Wireless Controllers",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9800-CL-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2391"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286322605",
              "icon": null,
              "id": "286322605",
              "name": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286313582",
          "name": "Cisco Catalyst 9800 Wireless Controllers for Cloud",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [
            "C1-AIR-CT3504-K9",
            "AIR-CT3504-K9-RF",
            "C1-AIR-CT3504-K9++",
            "WLC3504-DNA-E3Y-5",
            "AIR-CT3504-CA-K9++",
            "AIR-CT3504-CA-K9",
            "AIR-CT3504-K9++",
            "AIR-CT3504-K9"
          ],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2427"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "286312601",
              "icon": null,
              "id": "286312601",
              "name": "Cisco 3504 Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286312526",
          "name": "Cisco 3500 Series Wireless LAN Controller",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-CT5760"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.2067"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "999901005",
              "icon": null,
              "id": "999901005",
              "name": "Cisco 57xx Stack-able Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            },
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-CT5760-CA-K9",
                "AIR-CT5760-HAK9-WS",
                "AIR-CT5760250K9-RF",
                "AIR-CT5760-CA-K9++",
                "AIR-CT5760-RK-MNT",
                "AIR-CT5760-HA-K9",
                "AIR-CT5760100K9-WS",
                "AIR-CT5760-00-K9",
                "AIR-CT5760-CAK9-RF",
                "C1-AIR-CT5760-K9",
                "AIR-CT5760-CALPEK9",
                "AIR-CT5760HA-K9-RF",
                "AIR-CT5760-50K9-RF",
                "AIR-CT5760-25-K9",
                "AIR-CT5760-25K9-RF",
                "AIR-CT5760-K9",
                "C1-AIR-CT5760-K9++",
                "AIR-CT5760",
                "AIR-CT5760-25K9-WS"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.9.1.1645"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "284397235",
              "icon": null,
              "id": "284397235",
              "name": "Cisco 5760 Wireless LAN Controller",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "284380447",
          "name": "Cisco 5760 Series Wireless LAN Controller",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "978875243",
      "name": "Wireless Controller",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.16"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700016",
              "icon": null,
              "id": "887700016",
              "name": "Cisco 1140 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600016",
          "name": "Cisco 1140 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IW3702-4E-B-K9",
                "IW3702-2E-E-K9",
                "IW3702-2E-B-K9",
                "IW3702-4E-E-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.60"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700060",
              "icon": null,
              "id": "887700060",
              "name": "Cisco IW3700 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600060",
          "name": "Cisco IW3700 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9130AXE"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.102"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700102",
              "icon": null,
              "id": "887700102",
              "name": "Cisco Catalyst 9130AXE Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323624",
          "name": "Cisco Catalyst 9130AXE Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.27"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700027",
              "icon": null,
              "id": "887700027",
              "name": "Cisco 3600I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600027",
          "name": "Cisco 3600I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.38"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700038",
              "icon": null,
              "id": "887700038",
              "name": "Cisco 1530E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600038",
          "name": "Cisco 1530E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.6"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700006",
              "icon": null,
              "id": "887700006",
              "name": "Cisco 1130 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600006",
          "name": "Cisco 1130 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1815W-B-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.71"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700071",
              "icon": null,
              "id": "887700071",
              "name": "Cisco 1815W Series Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600071",
          "name": "Cisco 1815W Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9105AXI-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.106"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700106",
              "icon": null,
              "id": "887700106",
              "name": "Cisco Catalyst 9105AXI Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324892",
          "name": "Cisco Catalyst 9105AXI Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.22"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700022",
              "icon": null,
              "id": "887700022",
              "name": "Cisco 1550 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600022",
          "name": "Cisco 1550 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-1800I-D-K9C",
                "AIR-1800I-E-K9C",
                "AIR-1800I-A-K9",
                "AIR-1800I-D-K9",
                "AIR-1800I-E-K9",
                "AIR-1800I-B-K9",
                "AIR-1800I-F-K9C",
                "AIR-1800I-F-K9",
                "AIR-1800I-A-K9C",
                "AIR-1800I-B-K9C"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.78"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700078",
              "icon": null,
              "id": "887700078",
              "name": "Cisco 1800I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600078",
          "name": "Cisco 1800I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.73"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700062",
              "icon": null,
              "id": "887700062",
              "name": "Cisco 1815I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600062",
          "name": "Cisco 1815I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IW-6300H-DC-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.97"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700097",
              "icon": null,
              "id": "887700097",
              "name": "Cisco Catalyst IW6300 DC Heavy Duty Unified  Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323217",
          "name": "Cisco Catalyst IW6300 DC Heavy Duty Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.7"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700007",
              "icon": null,
              "id": "887700007",
              "name": "Cisco 1240 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600007",
          "name": "Cisco 1240 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.11"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700011",
              "icon": null,
              "id": "887700011",
              "name": "Cisco 1250 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600011",
          "name": "Cisco 1250 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.63"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700073",
              "icon": null,
              "id": "887700073",
              "name": "Cisco 1810 OEAP Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600073",
          "name": "Cisco 1810 OEAP Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.2"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700002",
              "icon": null,
              "id": "887700002",
              "name": "Cisco 5312 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600002",
          "name": "Cisco 5312 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1840I-B-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.96"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700096",
              "icon": null,
              "id": "887700096",
              "name": "Cisco 1840I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286321620",
          "name": "Cisco 1840I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.26"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700026",
              "icon": null,
              "id": "887700026",
              "name": "Cisco 802AGN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600026",
          "name": "Cisco 802AGN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.35"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700035",
              "icon": null,
              "id": "887700035",
              "name": "Cisco 702I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600035",
          "name": "Cisco 702I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.15"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700015",
              "icon": null,
              "id": "887700015",
              "name": "Cisco 801GN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600015",
          "name": "Cisco 801GN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9120AXP-B "
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.95"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700095",
              "icon": null,
              "id": "887700095",
              "name": "Cisco Catalyst 9120AXP Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322983",
          "name": "Cisco Catalyst 9120AXP Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9117AXI-B "
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.89"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700089",
              "icon": null,
              "id": "887700089",
              "name": "Cisco Catalyst 9117AXI Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322356",
          "name": "Cisco Catalyst 9117AXI Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.66"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700066",
              "icon": null,
              "id": "887700066",
              "name": "Cisco 3800P Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600066",
          "name": "Cisco 3800P Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.44"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700044",
              "icon": null,
              "id": "887700044",
              "name": "Cisco 702W Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600044",
          "name": "Cisco 702W Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.55"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700055",
              "icon": null,
              "id": "887700055",
              "name": "Cisco 1850E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600055",
          "name": "Cisco 1850E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.33"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700033",
              "icon": null,
              "id": "887700033",
              "name": "Cisco 1600E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600033",
          "name": "Cisco 1600E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.57"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700057",
              "icon": null,
              "id": "887700057",
              "name": "Cisco 1830I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600057",
          "name": "Cisco 1830I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700001",
              "icon": null,
              "id": "887700001",
              "name": "Cisco 5212 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600001",
          "name": "Cisco 5212 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9120AXE-B "
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.94"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700094",
              "icon": null,
              "id": "887700094",
              "name": "Cisco Catalyst 9120AXE Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322979",
          "name": "Cisco Catalyst 9120AXE Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.68"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700068",
              "icon": null,
              "id": "887700068",
              "name": "Cisco 2800E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600068",
          "name": "Cisco 2800E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9120AXI-B "
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.93"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700093",
              "icon": null,
              "id": "887700093",
              "name": "Cisco Catalyst 9120AXI Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322988",
          "name": "Cisco Catalyst 9120AXI Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.12"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700012",
              "icon": null,
              "id": "887700012",
              "name": "Cisco 1505 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600012",
          "name": "Cisco 1505 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.23"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700023",
              "icon": null,
              "id": "887700023",
              "name": "Cisco 602I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600023",
          "name": "Cisco 602I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.74"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700063",
              "icon": null,
              "id": "887700063",
              "name": "Cisco 1562E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600063",
          "name": "Cisco 1562E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.34"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700034",
              "icon": null,
              "id": "887700034",
              "name": "Cisco 702E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600034",
          "name": "Cisco 702E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.24"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700024",
              "icon": null,
              "id": "887700024",
              "name": "Cisco 3500P Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600024",
          "name": "Cisco 3500P Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.13"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700013",
              "icon": null,
              "id": "887700013",
              "name": "Cisco 3201 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600013",
          "name": "Cisco 3201 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.56"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700056",
              "icon": null,
              "id": "887700056",
              "name": "Cisco 1850I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600056",
          "name": "Cisco 1850I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.4"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700004",
              "icon": null,
              "id": "887700004",
              "name": "Cisco UNKNOWN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600004",
          "name": "Cisco UNKNOWN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.36"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700036",
              "icon": null,
              "id": "887700036",
              "name": "Cisco 3600P Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600036",
          "name": "Cisco 3600P Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.20"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700020",
              "icon": null,
              "id": "887700020",
              "name": "Cisco 1260 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600020",
          "name": "Cisco 1260 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.14"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700014",
              "icon": null,
              "id": "887700014",
              "name": "Cisco 1520 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600014",
          "name": "Cisco 1520 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.65"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700065",
              "icon": null,
              "id": "887700065",
              "name": "Cisco 3800I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600065",
          "name": "Cisco 3800I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.25"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700025",
              "icon": null,
              "id": "887700025",
              "name": "Cisco 802GN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600025",
          "name": "Cisco 802GN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.9"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700009",
              "icon": null,
              "id": "887700009",
              "name": "Cisco 1310 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600009",
          "name": "Cisco 1310 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.42"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700042",
              "icon": null,
              "id": "887700042",
              "name": "Cisco 2700E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600042",
          "name": "Cisco 2700E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.32"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700032",
              "icon": null,
              "id": "887700032",
              "name": "Cisco 1600I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600032",
          "name": "Cisco 1600I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.8"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700008",
              "icon": null,
              "id": "887700008",
              "name": "Cisco 1200 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600008",
          "name": "Cisco 1200 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.53"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700053",
              "icon": null,
              "id": "887700053",
              "name": "Cisco 1570E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600053",
          "name": "Cisco 1570E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.43"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700043",
              "icon": null,
              "id": "887700043",
              "name": "Cisco 2700I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600043",
          "name": "Cisco 2700I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.31"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700031",
              "icon": null,
              "id": "887700031",
              "name": "Cisco 802HAGN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600031",
          "name": "Cisco 802HAGN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "ESW-6300-CON-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.100"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700100",
              "icon": null,
              "id": "887700100",
              "name": "Cisco 6300 Embedded Services Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323641",
          "name": "Cisco 6300 Embedded Services Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.54"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700054",
              "icon": null,
              "id": "887700054",
              "name": "Cisco 1570I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600054",
          "name": "Cisco 1570I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.10"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700010",
              "icon": null,
              "id": "887700010",
              "name": "Cisco 1500 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600010",
          "name": "Cisco 1500 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9115AXE-B "
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.91"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700091",
              "icon": null,
              "id": "887700091",
              "name": "Cisco Catalyst 9115AXE Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322348",
          "name": "Cisco Catalyst 9115AXE Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.75"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700067",
              "icon": null,
              "id": "887700067",
              "name": "Cisco 1562I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600067",
          "name": "Cisco 1562I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.69"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700069",
              "icon": null,
              "id": "887700069",
              "name": "Cisco 2800I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600069",
          "name": "Cisco 2800I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.21"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700021",
              "icon": null,
              "id": "887700021",
              "name": "Cisco 1040 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600021",
          "name": "Cisco 1040 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.37"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700037",
              "icon": null,
              "id": "887700037",
              "name": "Cisco 1530I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600037",
          "name": "Cisco 1530I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.64"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700064",
              "icon": null,
              "id": "887700064",
              "name": "Cisco 3800E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600064",
          "name": "Cisco 3800E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9105AXW-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.107"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700107",
              "icon": null,
              "id": "887700107",
              "name": "Cisco Catalyst 9105AXW Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286324896",
          "name": "Cisco Catalyst 9105AXW Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.76"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700070",
              "icon": null,
              "id": "887700070",
              "name": "Cisco 1562D Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600070",
          "name": "Cisco 1562D Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IW-6300H-DCW-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.99"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700099",
              "icon": null,
              "id": "887700099",
              "name": "Cisco Catalyst IW6300 DCW Heavy Duty Unified  Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323884",
          "name": "Cisco Catalyst IW6300 DCW Heavy Duty Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.3"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700003",
              "icon": null,
              "id": "887700003",
              "name": "Cisco MIMO Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600003",
          "name": "Cisco MIMO Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.30"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700030",
              "icon": null,
              "id": "887700030",
              "name": "Cisco 2600E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600030",
          "name": "Cisco 2600E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9130AXI"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.101"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700101",
              "icon": null,
              "id": "887700101",
              "name": "Cisco Catalyst 9130AXI Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323627",
          "name": "Cisco Catalyst 9130AXI Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.81"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700081",
              "icon": null,
              "id": "887700081",
              "name": "Cisco 1542D Series Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600081",
          "name": "Cisco 1542D Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.28"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700028",
              "icon": null,
              "id": "887700028",
              "name": "Cisco 3600E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600028",
          "name": "Cisco 3600E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.41"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700041",
              "icon": null,
              "id": "887700041",
              "name": "Cisco 3700P Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600041",
          "name": "Cisco 3700P Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.17"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700017",
              "icon": null,
              "id": "887700017",
              "name": "Cisco 801AGN Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600017",
          "name": "Cisco 801AGN Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.52"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700052",
              "icon": null,
              "id": "887700052",
              "name": "Cisco 1700E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600052",
          "name": "Cisco 1700E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "C9115AXI-B"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.90"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700090",
              "icon": null,
              "id": "887700090",
              "name": "Cisco Catalyst 9115AXI Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286322352",
          "name": "Cisco Catalyst 9115AXI Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.39"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700039",
              "icon": null,
              "id": "887700039",
              "name": "Cisco 3700E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600039",
          "name": "Cisco 3700E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.82"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700082",
              "icon": null,
              "id": "887700082",
              "name": "Cisco 1542I Series Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600082",
          "name": "Cisco 1542I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.19"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700019",
              "icon": null,
              "id": "887700019",
              "name": "Cisco 3500E Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600019",
          "name": "Cisco 3500E Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "IW-6300H-AC-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.98"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700098",
              "icon": null,
              "id": "887700098",
              "name": "Cisco Catalyst IW6300 AC Heavy Duty Unified  Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "286323880",
          "name": "Cisco Catalyst IW6300 AC Heavy Duty Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.18"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700018",
              "icon": null,
              "id": "887700018",
              "name": "Cisco 3500I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600018",
          "name": "Cisco 3500I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.51"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700051",
              "icon": null,
              "id": "887700051",
              "name": "Cisco 1700I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600051",
          "name": "Cisco 1700I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.62"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700061",
              "icon": null,
              "id": "887700061",
              "name": "Cisco 1810W Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600061",
          "name": "Cisco 1810W Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [
                "AIR-AP1815M-B-K9"
              ],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.80"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700080",
              "icon": null,
              "id": "887700080",
              "name": "Cisco 1815M Series Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600080",
          "name": "Cisco 1815M Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.67"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700075",
              "icon": null,
              "id": "887700075",
              "name": "Cisco 4800 Series Unified Access Points",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600075",
          "name": "Cisco 4800 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.5"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700005",
              "icon": null,
              "id": "887700005",
              "name": "Cisco 1100 Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600005",
          "name": "Cisco 1100 Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.29"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700029",
              "icon": null,
              "id": "887700029",
              "name": "Cisco 2600I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600029",
          "name": "Cisco 2600I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.72"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700072",
              "icon": null,
              "id": "887700072",
              "name": "Cisco 1815T Series Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600072",
          "name": "Cisco 1815T Series Unified Access Points",
          "source": null,
          "supportLevel": null
        },
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.40"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700040",
              "icon": null,
              "id": "887700040",
              "name": "Cisco 3700I Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600040",
          "name": "Cisco 3700I Series Unified Access Points",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "885500001",
      "name": "Unified AP",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "1.3.6.1.4.1.14179.2.2.1.1.22.999"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "897700009",
              "icon": null,
              "id": "897700009",
              "name": "Cisco Unmanaged Unified Access Point",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "897700004",
          "name": "Cisco Unmanaged Series Unified Access Points",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": "16x16_accesspoint_sf.gif",
      "id": "897700001",
      "name": "Unmanaged AP",
      "source": null,
      "supportLevel": null
    },
    {
      "maxProblemSeverity": null,
      "problems": [],
      "associatedProductIds": [],
      "associatedSystemObjectIds": [],
      "bootstrapParserRule": null,
      "childElements": [
        {
          "maxProblemSeverity": null,
          "problems": [],
          "associatedProductIds": [],
          "associatedSystemObjectIds": [],
          "bootstrapParserRule": null,
          "childElements": [
            {
              "maxProblemSeverity": null,
              "problems": [],
              "associatedProductIds": [],
              "associatedSystemObjectIds": [
                {
                  "maxProblemSeverity": null,
                  "problems": [],
                  "source": null,
                  "value": "FS.1"
                }
              ],
              "bootstrapParserRule": null,
              "childElements": [],
              "ciscoMdfId": "887700079",
              "icon": null,
              "id": "887700079",
              "name": "Cisco Aironet 1800S Active Sensor",
              "source": null,
              "supportLevel": "SUPPORTED"
            }
          ],
          "ciscoMdfId": null,
          "icon": null,
          "id": "886600079",
          "name": "Cisco Aironet 1800S Active Sensor",
          "source": null,
          "supportLevel": null
        }
      ],
      "ciscoMdfId": null,
      "icon": null,
      "id": "885500002",
      "name": "Wireless Sensor",
      "source": null,
      "supportLevel": null
    }
  ]
});