define({
"Template":{
  "version": "1.0",
  "response": {
    "id": "AWi8U62NsIPgh8_7NoOr",
    "name": "ap_cpu_threshold_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "DC:CE:C1:2E:BD:C0",
    "groupBy": "Unknown",
    "category": "Utilization",
    "severity": "HIGH",
    "summary": "AP DeviceNamePlaceHolder is experiencing high CPU utilization.",
    "scope": "GLOBAL",
    "priority": null,
    "rootCause": "Unknown",
    "timestamp": 1549347300000,
    "description": "CPU utilization for the AP DeviceNamePlaceHolder has exceeded the 90% threshold. This issue is potentially impacting 0 client(s).",
    "suggestions": [
      {
        "message": "Connect to WLC, collect and examine the AP crash log file. To collect the log files, go to Management > Tech Support > AP Crash Log.",
        "steps": [

        ]
      },
      {
        "message": "Reboot the AP. If the CPU utilization rises again, you will get a warning message.",
        "steps": [

        ]
      },
      {
        "message": "If the above does not resolve the problem, from the WLC, use the show tech-support command to collect the WLC and AP configuration information, save the output, and then contact Cisco TAC for further assistance.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:DC:CE:C1:2E:BD:C0"
      },
      {
        "key": "sourceDeviceType",
        "value": "1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "cpuTotalUsage",
        "value": "98.0"
      },
      {
        "key": "threshold",
        "value": "90"
      },
      {
        "key": "entityId",
        "value": "DC:CE:C1:2E:BD:C0"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "apName",
        "value": "AP-D0"
      },
      {
        "key": "macAddress",
        "value": "DC:CE:C1:2E:BD:C0"
      },
      {
        "key": "category",
        "value": "Utilization"
      },
      {
        "key": "timestring",
        "value": "2019-02-05T06:15:00.000+0000"
      },
      {
        "key": "__suppression__",
        "value": "1800"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})