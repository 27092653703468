define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-04T04:45:00.000+0000",
        "count": 525,
        "nestedItems": [
          {
            "itemType": "POWER_STATE_ON",
            "timestamp": "2020-05-04T04:45:00.000+0000",
            "count": 505
          },
          {
            "itemType": "POWER_STATE_ERROR_DISABLE",
            "timestamp": "2020-05-04T04:45:00.000+0000",
            "count": 9
          },
          {
            "itemType": "POWER_STATE_FAULTY",
            "timestamp": "2020-05-04T04:45:00.000+0000",
            "count": 7
          },
          {
            "itemType": "POWER_STATE_POWER_DENY",
            "timestamp": "2020-05-04T04:45:00.000+0000",
            "count": 4
          }
        ]
      }
    ]
  }
}
})