define({
  "TIMESTAMP_CLIENTCOUNT_FREQUENCY": 
  {
    "version": 1.0,
    "response": {
      "detailList": [{
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }, {
        "breakdown": null,
        "bucket": null,
        "value1": 0,
        "value2": 0,
        "scoreList": [{
          "breakdown": "5.0",
          "bucket": null,
          "value1": 162,
          "value2": 0,
          "scoreList": []
        }, {
          "breakdown": "2.4",
          "bucket": null,
          "value1": 88,
          "value2": 0,
          "scoreList": []
        }]
      }],
      "legendList": []
    }
  },
  "NAMES": [{"name":"5.0","avg":166},{"name":"2.4","avg":88}]
});
