define({
  "Template": {
    "issueCount": 9,
    "failureCountByTagList": [
      {
        "tagName": "speed",
        "count": 0
      },
      {
        "tagName": "mailserver",
        "count": 1
      },
      {
        "tagName": "onboarding",
        "count": 1
      },
      {
        "tagName": "dhcp",
        "count": 145
      },
      {
        "tagName": "dns",
        "count": 68
      },
      {
        "tagName": "webserver",
        "count": 67
      },
      {
        "tagName": "radius",
        "count": 46
      },
      {
        "tagName": "filetransfer",
        "count": 3
      },
      {
        "tagName": "IpslaSender",
        "count": 9
      },
      {
        "tagName": "ping",
        "count": 2
      }
    ]
  }
});