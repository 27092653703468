define({
    "VirtualNetworkContext_Template": {
        id: "",
        instanceId: 81139,
        authEntityId: 81139,
        displayName: '0',
        authEntityClass: 1139238238,
        deployPending: "NONE",
        instanceVersion: 3,
        createTime: 0,
        deployed: false,
        isStale: false,
        isSeeded: false,
        customProvisions: [],
        instanceCreatedOn: 1631175011908,
        instanceUpdatedOn: 1631175011908,
        lastUpdateTime: 0,
        name: "",
        namespace: "VirtualNetworkContext",
        provisioningState: "DEFINED",
        type: "virtualnetworkcontext",
        version: 1,
        cfsChangeInfo: [],
        scope: "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
        virtualNetworkContextType: "ISOLATED",
        scalableGroup: [],
        isDefault: false,
        l3Instance: 4101,
        siteCount:0,
        siteList: [],
        transitConnectivityDomainSummary: [],
        zoneCount: 0,
        sdWanVrfName: "",
        vnStatus: "ACTIVE"
    }
});
